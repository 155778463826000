import { Box, Link, Typography } from '@material-ui/core';
import ExpandMore from '@material-ui/icons/ExpandMore';
import type { Column, Query, QueryResult } from 'material-table';
import { Link as RouterLink } from 'react-router-dom';
import type { AdminRequestEntry } from '../../services/model/admin-request-entry';
import type { Player } from '../../features/players/players';
import JsonEditor from '../JsonEditor';
import MaterialTable from '../MaterialTable';
import { PrettyAdminRequest } from './PrettyAdminRequest';
import { UserService } from '../../services/user';
import { useCallback } from 'react';
import { AdminRequestsService } from '../../services/admin-requests';
import { pushAppNotification } from '../../shared/hooks/useAppNotification';

type Props = {
  player?: Player;
  blockId?: string
};

export const AdminRequests = ({ player, blockId }: Props) => {

  const columns: Column<AdminRequestEntry>[] = [
    {
      title: 'User',
      sorting: false,
      render: entry => (<>
        {entry.adminPlayerId && UserService.canRead('player') ? (
          <Link component={RouterLink} to={`/players/${entry.adminPlayerId}`}>
            {entry.adminDisplayName || entry.mythicalId}
          </Link>
        ) : <>{entry.adminDisplayName || entry.mythicalId}</>}
      </>)
    },
    {
      title: 'Timestamp',
      field: 'timestamp',
      sorting: false,
      render: entry => <>{entry.timestamp.toLocaleString()}</>
    },
    {
      title: 'Action',
      sorting: false,
      render: entry => <PrettyAdminRequest entry={entry} />
    }
  ];

  const allowSearch = () => {
    return !player && !blockId;
  };

  const getTablePage = useCallback(async (query: Query<AdminRequestEntry>): Promise<QueryResult<AdminRequestEntry>> => {
    return new Promise(resolve => {
      const searchPath = player ? `/players/${player.externalId}` : blockId ? `/blocks/${blockId}` : (query.search || undefined);

      AdminRequestsService.getAdminRequests({
        path: searchPath,
        page: query.page + 1,
        pageSize: query.pageSize
      }).then(result => {
        resolve({
          data: result.items,
          page: query.page,
          totalCount: result.totalCount
        });
      }).catch(err => {
        console.log("Failed to get admin requests: ", err);
        pushAppNotification({ type: 'error', message: `Failed to admin requests: ${err.message}` });
      });
    });
  }, [player, blockId]);

  const renderDetailPanel = (entry: AdminRequestEntry) => {
    if (entry.meta) {
      return (
        <JsonEditor
          readOnly
          title="Request body"
          json={entry.meta}
          // eslint-disable-next-line @typescript-eslint/no-empty-function
          onJsonChange={() => { }}
        />
      );
    } else {
      return (
        <Box p={2}>
          <Typography color="textSecondary">
            No details available for delete requests.
          </Typography>
        </Box>
      );
    }
  };

  return (
    <MaterialTable
      title="Admin requests"
      columns={columns}
      data={getTablePage}
      options={{
        debounceInterval: 500,
        search: allowSearch(),
        detailPanelColumnAlignment: 'right',
        pageSize: 20,
        pageSizeOptions: [10, 20, 50]
      }}
      detailPanel={[
        entry => ({
          icon: ExpandMore,
          tooltip: 'View details',
          render: renderDetailPanel
        })
      ]}
    />
  );
}

import * as React from 'react';
import { DiffsPanelContainer } from './DiffsPanelContainer';
import type { DiffItem } from '../../types';
import type { MigrationActions } from '../../../../redux/catalog-migration/reducer';
import type { Column } from 'material-table';

interface DiffsPanelsProps<T extends DiffItem> {
  itemsChanged: T[];
  itemsAdded: T[];
  itemsRemoved: T[];
  expandedPanel: number;
  disableRemove?: boolean;
  selectedIds: MigrationActions;
  columns: Column<T>[];
  onUpdateChange(update: string[]): void;
  onAddChange(add: string[]): void;
  onRemoveChange(remove: string[]): void;
  setExpandedPanel(value: React.SetStateAction<number>): void;
}

export const DiffsPanels = <T extends DiffItem>({
  itemsChanged,
  itemsAdded,
  itemsRemoved,
  expandedPanel,
  disableRemove,
  selectedIds,
  columns,
  onUpdateChange,
  onAddChange,
  onRemoveChange,
  setExpandedPanel
}: DiffsPanelsProps<T>) => (
  <>
    <DiffsPanelContainer
      title="Changed"
      diffType="change"
      items={itemsChanged}
      columns={columns}
      selectedIds={selectedIds.update}
      onSelectionChange={onUpdateChange}
      expanded={expandedPanel === 1}
      onExpand={() => setExpandedPanel(expandedPanel === 1 ? 0 : 1)}
      />
    <DiffsPanelContainer
      title="Added"
      diffType="add"
      items={itemsAdded}
      columns={columns}
      selectedIds={selectedIds.add}
      onSelectionChange={onAddChange}
      expanded={expandedPanel === 2}
      onExpand={() => setExpandedPanel(expandedPanel === 2 ? 0 : 2)}
      />
    {!disableRemove && (
      <DiffsPanelContainer
        title="Removed"
        diffType="remove"
        items={itemsRemoved}
        columns={columns}
        selectedIds={selectedIds.remove}
        onSelectionChange={onRemoveChange}
        expanded={expandedPanel === 3}
        onExpand={() => setExpandedPanel(expandedPanel === 3 ? 0 : 3)}
        />
    )}
  </>
);

/* eslint-disable @typescript-eslint/no-non-null-asserted-optional-chain */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
import type { CallEffect, SelectEffect, PutEffect } from 'redux-saga/effects';
import { call, fork, put, select, takeLatest } from 'redux-saga/effects';
import type { RootState } from '../reducers';
import { reqLogin, reqLogout, setAuthenticated, setOAuthClient, setToken } from './actions';
import type { AnyAction } from 'redux';
import type { PayloadAction } from 'typesafe-actions';

function* reqLoginSaga(): Generator<SelectEffect | CallEffect<AnyAction | unknown>, void, RootState> {
  const state = yield select();
  yield call(state?.authentication?.oAuthClient?.userManager.signinRedirect()!)
}

function* reqLogoutSaga(): Generator<SelectEffect | CallEffect<AnyAction | unknown>, void, RootState> {
  const state = yield select();
  //keycloak doesn't have logout redirect setup, so if we don't specify where to go, it wont know. 
  yield call(state?.authentication?.oAuthClient?.userManager.signoutRedirect({post_logout_redirect_uri: window.location.href})!);
}

function* setOAuthClientSaga(action: ReturnType<typeof setOAuthClient>): Generator<SelectEffect | PutEffect<PayloadAction<"SET_TOKEN", {
  token: string | null;
}>> | PutEffect<PayloadAction<"SET_AUTHENTICATED", {
  authenticated: boolean;
}>>, void, RootState & string>  {
  const { oauthClient } = action.payload;
  const user = oauthClient.user;
  if (oauthClient && user) {
    try {
      const state = yield select();
      const authenticated = state.authentication.authenticated
      // Update token      
      yield put(setToken(user.access_token || null));
      // Update authentication status
      if (authenticated !== !!user) {
        yield put(setAuthenticated(!!user));
      }
    // eslint-disable-next-line no-empty
    } catch (e) { }
  } else {
    yield put(setAuthenticated(false));
    yield put(setToken(null));
  }
}

function* watchsetOAuthClientSaga(){
  yield takeLatest(setOAuthClient, setOAuthClientSaga);
}
function* watchReqLoginSaga() {
  yield takeLatest(reqLogin, reqLoginSaga);
}
function* watchReqLogoutSaga() {
  yield takeLatest(reqLogout, reqLogoutSaga);
}

export default function* authenticationSagas() {
  yield fork(watchReqLoginSaga);
  yield fork(watchReqLogoutSaga);
  yield fork(watchsetOAuthClientSaga)
}
import type { ApiError } from '../api';
import { api } from '../api';

export class RewardPath {  
  season_number?: number = undefined;
  path_id = '';
  path_name = '';  

  constructor(json?: any) {
    if (json) {
      this.path_id = json.pathId;
      this.path_name = json.name;
    }
  }

  toJson(): any {
    return {      
      pathId: this.path_id,
      name: this.path_name
    };
  }

  clone(): RewardPath {
    return new RewardPath(this.toJson());
  }
}


export class SeasonRewardPathService {
  static async getSeasonRewardPath(seasonNumber: number): Promise<RewardPath> {
    return api.get({ url: `/players/seasons/${seasonNumber}/reward-path` })
      .then(rewardPath => new RewardPath(rewardPath))
      .catch((e: ApiError) => {
        e.message = `Failed to get season reward path. ${e.message}`;
        throw e;
      });
  }

  static async createSeasonRewardPath(seasonNumber: number, rewardPathJson: any): Promise<RewardPath> {
    return api.post({ url: `/players/seasons/${seasonNumber}/reward-path`, body: rewardPathJson })
      .then(rewardPath => new RewardPath(rewardPath))
      .catch((e: ApiError) => {
        e.message = `Failed to create season reward path. ${e.message}`;
        throw e;
      });
  }

  static async updateSeasonRewardPath(seasonNumber: number, pathId: string, rewardPath: RewardPath): Promise<RewardPath> {
    return api.put({ url: `/players/seasons/${seasonNumber}/reward-path/${pathId}`, body: rewardPath.toJson() })
      .then(rewardPath => new RewardPath(rewardPath))
      .catch((e: ApiError) => {
        e.message = `Failed to update season reward path. ${e.message}`;
        throw e;
      });
  }

  static async deleteSeasonRewardPath(seasonNumber: number, pathId: string): Promise<null> {
    return api.delete({ url: `/players/seasons/${seasonNumber}/reward-path/${pathId}` })
      .catch((e: ApiError) => {
        e.message = `Failed to delete season reward path. ${e.message}`;
        throw e;
      });
  }
}
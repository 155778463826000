import { Box, LinearProgress, Typography } from '@material-ui/core';

interface MigrationProgressProps {
  step: CatalogMigrationStep
}

export const MigrationProgress = ({
  step
}: MigrationProgressProps) => (
  <Box mt={2}>
    <Typography>{step.message}</Typography>
    <LinearProgress variant="determinate" color="secondary" value={step.progress} />
  </Box>
);
import { api } from '../../services/api';
import type { Role, RolesResponse, UserRoles } from "./rolesPermissions";

export const getAllRoles = async (): Promise<Role[]> => {
  return api.get<RolesResponse>({url: '/roles'})
    .then(response => response.roles);
};

export const createRole = async (role: Role): Promise<Role> => {
  return api.post<Role>({url: '/roles', body: role});
};

export const updateRole = async (role: Role): Promise<Role> => {
  return api.put<Role>({url: '/roles', body: role});
};

export const deleteRole = async (id: string): Promise<null> => {
  return api.delete({url: `/roles/${id}`});
};

export const getUserRoles = async (mythicalId: string): Promise<UserRoles> => {
  return api.get<UserRoles>({url: `/roles/users/${mythicalId}`});
};

export const updateUserRoles = async (userRoles: UserRoles): Promise<UserRoles> => {
  return api.put<UserRoles>({url: '/roles/users', body: userRoles});
};

export const getAllRoleNames = async (): Promise<string[]> => {
  return api.get<string[]>({url: `/roles/names`});
};

export const getAllTargets = async (): Promise<string[]> => {
  return api.get<string[]>({url: `/roles/targets`});
};

import { createAction, createAsyncAction } from 'typesafe-actions';
import type { BlankoAbilities, BlankoProgression } from '../../services/model/blanko';

export const getBlankoProgressionsAsync = createAsyncAction(
  'GET_BLANKO_PROGRESSIONS_REQUEST',
  'GET_BLANKO_PROGRESSIONS_SUCCESS',
  'GET_BLANKO_PROGRESSIONS_FAILURE'
)<void, BlankoProgression[], string>();

export const getBlankoProgressionAsync = createAsyncAction(
  'GET_BLANKO_PROGRESSION_REQUEST',
  'GET_BLANKO_PROGRESSION_SUCCESS',
  'GET_BLANKO_PROGRESSION_FAILURE'
)<string, BlankoProgression, string>();

export const createBlankoProgressionAsync = createAsyncAction(
  'CREATE_BLANKO_PROGRESSION_REQUEST',
  'CREATE_BLANKO_PROGRESSION_SUCCESS',
  'CREATE_BLANKO_PROGRESSION_FAILURE'
)<any, BlankoProgression, string>();

export const updateBlankoProgressionAsync = createAsyncAction(
  'UPDATE_BLANKO_PROGRESSION_REQUEST',
  'UPDATE_BLANKO_PROGRESSION_SUCCESS',
  'UPDATE_BLANKO_PROGRESSION_FAILURE'
)<BlankoProgression, BlankoProgression, string>();

export const deleteBlankoProgressionAsync = createAsyncAction(
  'DELETE_BLANKO_PROGRESSION_REQUEST',
  'DELETE_BLANKO_PROGRESSION_SUCCESS',
  'DELETE_BLANKO_PROGRESSION_FAILURE'
)<string, string, string>();

export const getBlankoClassesAsync = createAsyncAction(
  'GET_BLANKO_CLASSES_REQUEST',
  'GET_BLANKO_CLASSES_SUCCESS',
  'GET_BLANKO_CLASSES_FAILURE'
)<void, { classes: string[], abilities: BlankoAbilities }, string>();

export const setBlankoProgression = createAction('SET_BLANKO_PROGRESSION', (progression: BlankoProgression) => progression)();
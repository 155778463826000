import { PaginatedResponse } from './model/pagination';
import type { ApiError } from './api';
import { api } from './api';
import { AdminRequestEntry } from './model/admin-request-entry';

export interface AdminRequestsPageRequest {
  path?: string;
  page: number;
  pageSize: number;
}

export class AdminRequestsService {
  static async getAdminRequests(request: AdminRequestsPageRequest): Promise<PaginatedResponse<AdminRequestEntry>> {
    return api.get({ url: '/logs/admin-requests', query: request })
      .then(entries => new PaginatedResponse(entries, entry => new AdminRequestEntry(entry)))
      .catch((e: ApiError) => {
        e.message = `Failed to get admin request history. ${e.message}`;
        throw e;
      });
  }
}
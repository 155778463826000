import { Button, Card, CardContent, CardHeader, CircularProgress, FormControl, Grid, InputLabel, MenuItem } from '@material-ui/core';
import type { FormikHelpers } from 'formik';
import { Field, Formik } from 'formik';
import { useMemo, useCallback } from 'react';
import { UserService } from '../../../services/user';
import * as yup from 'yup';
import { CheckboxWithLabel, Select, Switch, TextField } from 'formik-material-ui';
import { updateBrawlSettings } from '../../../features/blocks/BlockApiService';
import { pushAppNotification } from '../../../shared/hooks/useAppNotification';

interface BrawlSettingsFormProps {
  blockId: string
  brawlMaxPlayers: number,
  brawlMinPlayers: number,
  brawlWeight: number,
  brawlEliminationsPercentage: number,
  brawlPlaylist: string,
  blockMaxPlayers: number,
  blockMinPlayers: number,
  brawlIsNonEliminationCompatible: boolean,
  skillLevelThreshold: number
}

interface FormValues {
  brawlMaxPlayers: number,
  brawlMinPlayers: number,
  brawlWeight: number,
  brawlEliminationsPercentage: number,
  brawlPlaylist: string,
  blockMaxPlayers: number,
  blockMinPlayers: number,
  brawlIsNonEliminationCompatible: boolean,
  skillLevelThreshold: number
}

const formSchema = yup.object().shape({
  brawlEliminationsPercentage: yup.number().max(1)
    .min(0)
    .label("Brawl Eliminations Percentage"),
  brawlMaxPlayers: yup.number().moreThan(yup.ref('brawlMinPlayers'), "Max players must be higher than min players")
    .moreThan(1)
    .max(yup.ref('blockMaxPlayers'), "Max players must not be higher than the max players of the block itself")
    .label("Max Players"),
  brawlMinPlayers: yup.number().lessThan(yup.ref('brawlMaxPlayers'), "Min players must be lower than max players")
    .moreThan(0)
    .min(yup.ref('blockMinPlayers'), "Min players must not be lower than the min players of the block itself")
    .label("Min players"),
  skillLevelThreshold: yup.number().max(1).min(0).label("SKill Level Threshold")
}, [['brawlMaxPlayers', 'brawlMinPlayers']]);


export const BrawlSettingsForm = (props: BrawlSettingsFormProps) => {
  const formValues = useMemo((): FormValues => {
    return {
      brawlMaxPlayers: props.brawlMaxPlayers,
      brawlMinPlayers: props.brawlMinPlayers,
      brawlWeight: props.brawlWeight,
      brawlEliminationsPercentage: props.brawlEliminationsPercentage,
      brawlPlaylist: props.brawlPlaylist,
      blockMaxPlayers: props.blockMaxPlayers,
      blockMinPlayers: props.blockMinPlayers,
      brawlIsNonEliminationCompatible : props.brawlIsNonEliminationCompatible || false,
      skillLevelThreshold: props.skillLevelThreshold
    };
  }, [props]);

  const onSubmit = useCallback(async (values: FormValues, helpers: FormikHelpers<FormValues>) => {
    try {
      await updateBrawlSettings(
        props.blockId, 
        values.brawlMaxPlayers, 
        values.brawlMinPlayers, 
        values.brawlWeight, 
        values.brawlPlaylist, 
        values.brawlEliminationsPercentage,
        values.brawlIsNonEliminationCompatible,
        values.skillLevelThreshold);
      pushAppNotification({ type: 'success', message: 'Brawl Settings updated' });
    } catch (e) {
      pushAppNotification({ type: 'error', message: 'Brawl Settings failed to update' });
    }
  }, [props]);

  const readOnly = !UserService.canUpdate('blocks');

  return (
    <Formik<FormValues>
      initialValues={formValues}
      enableReinitialize
      validationSchema={formSchema}
      validateOnMount={true}
      onSubmit={onSubmit}
    >
      {({ submitForm, isSubmitting }) => {
        return (
          <Card>
            <CardHeader title="Blanko Brawl Settings" />
            <CardContent>
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <FormControl fullWidth>
                    <InputLabel>Playlist</InputLabel>
                    <Field
                      component={Select}
                      name="brawlPlaylist"
                      >
                      <MenuItem key="NONE" value="NONE">None</MenuItem>
                      <MenuItem key="TEAMS" value="TEAMS">Teams</MenuItem>
                      <MenuItem key="QUALIFIERS" value="QUALIFIERS">Qualifiers</MenuItem>
                      <MenuItem key="FINALES" value="FINALES">Finales</MenuItem>
                    </Field>
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <FormControl fullWidth>
                    <InputLabel>Weight</InputLabel>
                    <Field
                      component={Select}
                      name="brawlWeight"
                      >
                      {Array.from({ length: 9 }).map((_, i) => (
                        <MenuItem key={i} value={i + 1}>{i + 1}</MenuItem>
                        ))}
                    </Field>
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <Field
                    component={TextField}
                    name="brawlMinPlayers"
                    type="number"
                    label="Min Brawl Players"
                    fullWidth
                    inputProps={{
                        step: 1,
                        min: 0,
                        max: 100,
                    }}
                    />
                </Grid>
                <Grid item xs={12}>
                  <Field
                    component={TextField}
                    name="brawlMaxPlayers"
                    type="number"
                    label="Max Brawl Players"
                    fullWidth
                    inputProps={{
                        step: 1,
                        min: 0,
                        max: 100,
                    }}
                    />
                </Grid>
                <Grid item xs={12}>
                  <Field
                    component={TextField}
                    name="brawlEliminationsPercentage"
                    type="number"
                    label="Eliminations Percentage"
                    fullWidth
                    inputProps={{
                        step: 0.1,
                        min: 0.0,
                        max: 1.0,
                    }}
                    />
                </Grid>
                <Grid item xs={12}>
                  <Field
                    component={TextField}
                    name="skillLevelThreshold"
                    type="number"
                    label="Skill Level Threshold"
                    fullWidth
                    inputProps={{
                        step: 0.1,
                        min: 0.0,
                        max: 1.0,
                    }}
                    />
                </Grid>
                <Grid item xs={12}>
                  <Field
                    component={CheckboxWithLabel}
                    type="checkbox"
                    name="brawlIsNonEliminationCompatible"
                    Label={{ label: 'Non Elimination Brawl' }}
                  />
                </Grid>
                {!readOnly && (
                  <Grid item xs={12}>
                    <Button
                      variant="contained"
                      color="primary"
                      disabled={isSubmitting}
                      onClick={submitForm}
                      >
                      {isSubmitting ? (
                        <CircularProgress size={25} />
                        ) : 'Save'}
                    </Button>
                  </Grid>
                  )}
              </Grid>
            </CardContent>
          </Card>
        );
      }}
    </Formik>
  );
}
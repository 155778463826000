import { Box, Button, Card, CardContent, CardHeader, Grid } from '@material-ui/core';
import { UserService } from '../../../services/user';
import { PotentialReward } from '../live-events-types';
import PotentialRewardControl from './PotentialRewardControl';

interface Props {
  title: string;
  showAdd?: boolean;
  editMode?: boolean;
  potentialRewards: PotentialReward[];
  onAdd: () => void;
  onDelete: (potentialReward: PotentialReward) => void;
}

const PotentialRewardListEditor = (props: Props) => {

  const readOnly: boolean = !props.editMode || !UserService.canUpdate('liveEvents');

  const cardAction = props.showAdd ? (
    <Button variant="contained" color="primary" size="small" onClick={props.onAdd}>
      Add Potential Reward
    </Button>
  ) : null;

  return (
    <Card>
      <CardHeader title={props.title} titleTypographyProps={{ variant: 'subtitle1' }} action={cardAction} />
      <CardContent>
        {props.potentialRewards.length === 0 ? <Grid item >No Potential Rewards.</Grid> : null}
        {props.potentialRewards.map((potentialReward: PotentialReward, index: number) => (
          <Grid container key={index}>
            <Box pb={1} width="100%">
              <PotentialRewardControl readOnly={readOnly} potentialReward={potentialReward} onDelete={props.onDelete} />
            </Box>
          </Grid>
        ))}
      </CardContent>
    </Card>
  )
}

export default PotentialRewardListEditor;

import type { Theme} from '@material-ui/core';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, makeStyles, createStyles } from '@material-ui/core';

import { RushForm } from '.';
import { useFormik } from 'formik';
import { rushValidationSchema } from '..';
import { createBlankoRush } from '../gemRushApiService';
import { format } from 'date-fns';
import type { BlankoGemRush } from '../blankoGemRush';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    form: {
      display: 'flex',
      flexDirection: 'column',
      margin: 'auto',
      width: 'fit-content',
    }
  }),
);

interface AddRushDialogProps {
  onClose: () => void
  show: boolean
}
export const AddRushDialog = ({
  onClose,
  show}: AddRushDialogProps) => {
  const classes = useStyles();
  const date = format(new Date(), 'yyyy-MM-dd\'T\'HH:mm:ss')
  const initialRushValues: BlankoGemRush = {
    id: '',
    name: '',
    startDate: date,
    endDate: date,
    active: false,
  }
  const formikRushes = useFormik({
    validationSchema: rushValidationSchema,
    initialValues: initialRushValues,
    onSubmit: async (values: Partial<BlankoGemRush>) => {
      delete values.id
      values.startDate = values.startDate && new Date(values.startDate).toISOString()
      values.endDate = values.endDate && new Date(values.endDate).toISOString()
      await createBlankoRush(values)
      onClose()
    },
  });
  return (
    <>
      <Dialog
        open={show}
        fullWidth
        onClose={onClose}
        aria-labelledby="add-dialog-title"
      >
        <form onSubmit={formikRushes.handleSubmit} className={classes.form}>
          <DialogTitle id="add-dialog-title">Add Gem Rush</DialogTitle>
          <DialogContent className={classes.form}>
            <RushForm {...formikRushes}/>
          </DialogContent>
          <DialogActions>
            <Button 
              type="submit"
              variant="contained"
              disabled={!(formikRushes.isValid && formikRushes.dirty)}
              color="primary"
            >
              Save
            </Button>
            <Button onClick={onClose} color="primary">
              Close
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </>
  );
}

import DataMapEditor from '../../../components/DataMapEditor';
import type { PlayerDataField } from '../players';
import { Player, PlayersService } from '../players';
import { UserService } from '../../../services/user';
import { pushAppNotification } from "../../../shared/hooks/useAppNotification";

type Props = {
  player: Player;
  setPlayer: (player: Player) => void;
}

export const PlayerData = ({player, setPlayer}: Props) => {
  const onSave = (data: { [key: string]: PlayerDataField }) => {
    const newPlayer = new Player({
      externalId: player.externalId,
      email: player.email,
      displayName: player.displayName,
      deviceIds: player.deviceIds,
      playerData: data
    });

    PlayersService.updatePlayer(newPlayer)
      .then(updatedPlayer => {
        pushAppNotification({type: 'success', message: `Updated player data`});
        setPlayer(updatedPlayer);
      })
      .catch(err => pushAppNotification({type: 'error', message: `Failed to update player data: ${err.message}`}));
  };

  return (
    <DataMapEditor data={player.playerData} onSave={onSave} readOnly={!UserService.canUpdate('playerData')}/>
  );
};

import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';
import type { SyntheticEvent } from 'react';
import { useCallback, useState } from 'react';
import { Catalog, CatalogsService } from '../../../services/catalogs';
import { pushAppNotification } from "../../../shared/hooks/useAppNotification";
import { arrayReplaceOrPush } from "../../../redux/utils-ts";

type Props = {
  open: boolean;
  onClose: () => void;
  catalogs: Catalog[];
  setCatalogs: (catalogs: Catalog[]) => void;
};

export const AddCatalogDialog = ({open, onClose, catalogs, setCatalogs}: Props) => {
  const [catalogName, setCatalogName] = useState('');
  const [catalogNameError, setCatalogNameError] = useState('');
  const [isAddingCatalog, setIsAddingCatalog] = useState(false);

  const onDialogExited = useCallback(() => {
    setCatalogName('');
    setCatalogNameError('');
    setIsAddingCatalog(false);
  }, []);

  const handleNameChange = useCallback((event: SyntheticEvent) => {
    const newCatalogName = (event.target as HTMLInputElement).value;
    setCatalogName(newCatalogName);
    if (catalogs && catalogs.find(v => v.name === newCatalogName.trim())) {
      setCatalogNameError('Name already taken');
    } else {
      setCatalogNameError('');
    }
  }, [catalogs]);

  const handleSubmit = useCallback((event: SyntheticEvent) => {
    setIsAddingCatalog(true);
    event.preventDefault();
    CatalogsService.addCatalog(new Catalog({
      name: catalogName.trim(),
      primaryCatalog: false
    }))
      .then(catalog => {
        pushAppNotification({ type: 'success', message: 'Catalog added' });
        setCatalogs(arrayReplaceOrPush(catalogs, catalog, v => v.name === catalog.name));
        onDialogExited();
        onClose();
      })
      .catch(err => {
        pushAppNotification({type: 'error', message: `Failed to add catalog: ${err.message}`});
      })
      .finally(() => setIsAddingCatalog(false));
  }, [catalogName, catalogs, onClose, onDialogExited, setCatalogs]);

  return (
    <Dialog
      open={open}
      onClose={onClose}
      onExited={onDialogExited}
      fullWidth
      maxWidth="sm"
    >
      <form onSubmit={handleSubmit}>
        <DialogTitle>Add Catalog</DialogTitle>
        <DialogContent>
          <TextField
            label="Name"
            fullWidth
            error={!!catalogNameError}
            helperText={catalogNameError}
            autoFocus
            value={catalogName}
            onChange={handleNameChange}
          />
        </DialogContent>
        <DialogActions>
          <Button type="submit" color="primary" variant="contained" disabled={isAddingCatalog}>
            {isAddingCatalog ? <CircularProgress size={20}/> : 'Add'}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

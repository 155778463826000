import { Box, Button, CircularProgress, Divider, Grid, Tab, Tabs, Typography } from '@material-ui/core';
import { useCallback, useEffect, useState } from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { useDispatch } from 'react-redux';
import { setAppNotification } from '../../../redux/app/actions';
import { setCatalogName } from '../../../redux/catalog/actions';
import type { ApiError } from '../../../services/api';
import { CurrencyService } from '../../../services/currency';
import type { ItemDefinition } from '../../../services/item-definitions';
import { ItemDefinitionsService } from '../../../services/item-definitions';
import type { CurrencyList } from '../../../services/model/currency';
import type { ShopItem, ShopTabItem, ShopVersion , MarketplaceItemDataTag } from '../shop';
import { getMarketplaceItemDataTags, getShopVersion } from '../shopsApi';
import { ShopItemDataEditor } from './ShopItemDataEditor';
import { ShopTabEditor } from './ShopTabEditor';
import type { EditorItem } from './ShopTabItemEditor';
import { ShopTabsEditor } from './ShopTabsEditor';
import { pushAppNotification } from "../../../shared/hooks/useAppNotification";

export interface ShopVersionEditorProps {
  shopVersionId: string;
}

export const ShopVersionEditor = (props: ShopVersionEditorProps) => {
  const dispatch = useDispatch();

  const [shopVersion, setShopVersion] = useState<ShopVersion | null>(null);
  const [showTabsEditor, setShowTabsEditor] = useState(false);
  const [selectedTab, setSelectedTab] = useState(0);
  const [currencyList, setCurrencyList] = useState<CurrencyList | null>(null);
  const [catalogItems, setCatalogItems] = useState<ItemDefinition[] | null>(null);
  const [editItem, setEditItem] = useState<EditorItem | null>(null);
  const [marketplaceItemDataTags, setMarketplaceItemDataTags] = useState<MarketplaceItemDataTag[]>([]);
  useEffect(() => {
    getMarketplaceItemDataTags()
      .then(tags => setMarketplaceItemDataTags(tags))
      .catch(err => pushAppNotification({type: 'error', message: `Failed to fetch marketplace item data tags: ${err.message}`}));
  }, []);

  useEffect(() => {
    setShopVersion(null);
    setShowTabsEditor(false);
    setEditItem(null);
    getShopVersion(props.shopVersionId, true).then(version => {
      setShopVersion(version);
      CurrencyService.getOrLoadCurrencies().then(setCurrencyList);
      ItemDefinitionsService.getItemDefinitions(version.catalogName).then(setCatalogItems);
      dispatch(setCatalogName(version.catalogName));
    }).catch((e: ApiError) => dispatch(setAppNotification({ type: 'error', message: `Failed to load shop version. ${e.message}` })));
  }, [dispatch, props.shopVersionId]);

  useEffect(() => {
    if (shopVersion && (selectedTab >= shopVersion.tabs.length)) {
      setSelectedTab(0);
    }
  }, [shopVersion, selectedTab]);

  const onShopItemUpdate = useCallback((newTabItem: ShopTabItem, newItem?: ShopItem) => {
    if (shopVersion) {
      const newItems = shopVersion.items.slice();
      if (newItem) {
        const index = newItems.findIndex(v => v.id === newItem.id);
        if (index > -1) {
          newItems[index] = newItem;
        }
      }

      const newTabs = shopVersion.tabs.map(tab => {
        const newTabItems = tab.items.slice();
        const index = newTabItems.findIndex(v => v.id === newTabItem.id);
        if (index > -1) {
          newTabItems[index] = newTabItem;
        }
        return { ...tab, items: newTabItems };
      });

      setShopVersion({ ...shopVersion, items: newItems, tabs: newTabs });
    }
  }, [shopVersion]);

  if (!shopVersion || !currencyList || !catalogItems) {
    return (
      <Box textAlign="center">
        <CircularProgress />
      </Box>
    );
  }

  if (showTabsEditor) {
    return (<>
      <ShopTabsEditor
        shopVersionId={shopVersion.id}
        tabs={shopVersion.tabs}
        onTabsUpdate={tabs => setShopVersion({ ...shopVersion, tabs })}
      />
      <Box mt={2}>
        <Button color="primary" variant="contained" onClick={() => setShowTabsEditor(false)}>
          Back to Shop
        </Button>
      </Box>
    </>);
  }

  if (editItem) {
    return <ShopItemDataEditor
      item={editItem.itemDetail?.shopItem}
      tabItem={editItem.tabItem}
      catalogItem={editItem.itemDetail?.catalogItem}
      onUpdate={onShopItemUpdate}
      onCancel={() => setEditItem(null)}
      marketplaceItemDataTags={marketplaceItemDataTags}
    />
  }

  return (<>
    <Box mt={2}>
      <Grid container alignItems="center">
        <Grid item xs></Grid>
        <Grid item xs="auto">
          <Button color="primary" variant="contained" onClick={() => setShowTabsEditor(true)}>
            Manage Tabs
          </Button>
        </Grid>
      </Grid>
    </Box>

    {shopVersion.tabs.length < 1 && (
      <Box mt={2} textAlign="center">
        <Typography color="textSecondary">
          No tabs available yet.
        </Typography>
      </Box>
    )}

    {shopVersion.tabs.length > 0 && (<>
      <Tabs
        value={selectedTab}
        onChange={(event, tab) => setSelectedTab(tab)}
        variant="scrollable"
        scrollButtons="auto"
      >
        {shopVersion.tabs.map(tab => (
          <Tab key={tab.position} label={tab.name} />
        ))}
      </Tabs>
      <Divider />
      {selectedTab < shopVersion.tabs.length && (
        <Box pt={1}>
          <DndProvider backend={HTML5Backend}>
            <ShopTabEditor
              shopVersion={shopVersion}
              tab={selectedTab}
              catalogItems={catalogItems}
              currencyList={currencyList}
              onShopVersionUpdate={setShopVersion}
              onEditItem={setEditItem}
              marketplaceItemDataTags={marketplaceItemDataTags}
            />
          </DndProvider>
        </Box>
      )}
    </>)}
  </>);
}

import type { Role } from "./rolesPermissions";
import { normalizeRoles } from "./rolesPermissions";
import type { Column } from "material-table";
import { useCallback, useEffect, useMemo, useState } from "react";
import type { ApiError } from "../../services/api";
import { deleteRole, getAllRoles } from "./rolesPermissionsApi";
import { pushAppNotification } from "../../shared/hooks/useAppNotification";
import { UserService } from "../../services/user";
import { Box, Button, CircularProgress, Grid, Typography } from "@material-ui/core";
import { AdminTable } from "../../shared";
import { RoleEditor } from "./RoleEditor";
import { Create, Delete, FileCopy } from "@material-ui/icons";
import type { RowMenuAction } from "../../shared/components/AdminTable";
import ConfirmationDialog from "../../components/ConfirmationDialog";
import type { RouteComponentProps } from "react-router";
import { history } from '../../redux/store'

type Props = RouteComponentProps<{ roleId?: string; }>;

const columns: Column<Role>[] = [
  {
    title: 'Role name',
    field: 'name',
    sorting: true,
    defaultSort: 'asc',
  },
  {
    title: 'API Level',
    field: 'apiLevel',
    sorting: true,
  },
];

export const RolesPage = (props: Props) => {
  const [loading, setLoading] = useState(true);
  const [roles, setRoles] = useState<Role[]>([]);
  const [roleToDelete, setRoleToDelete] = useState<Role | undefined>();
  const fetchAllRoles = useCallback(() => {
    getAllRoles()
      .then(roles => {
        setRoles(normalizeRoles(roles));
        setLoading(false);
      })
      .catch((e: ApiError) => pushAppNotification({
        type: 'error',
        message: `Failed to load roles. ${e.message}`
      }));
  }, []);
  useEffect(fetchAllRoles, [fetchAllRoles]);

  const roleId = props.match.params.roleId;
  const addMode = props.location.pathname.endsWith('/roles/new');
  const cloneMode = props.location.pathname.includes('/roles/clone/');
  const showEditor = !!roleId || addMode;

  const role = useMemo<Role | undefined>(() => {
    if (roles && roleId) {
      return roles.filter(role => role.id === roleId)[0];
    }
    return undefined;
  }, [roleId, roles]);

  const roleNotFound = useMemo(() => roleId && !role && roles.length,
    [roleId, roles, role]);

  const handleCloseDeleteDialog = useCallback(() => {
    setRoleToDelete(undefined);
  }, []);
  const handleCloseEditor = useCallback(() => {
    history.push('/roles');
  }, []);
  const onEdit = useCallback((role: Role) => {
    history.push(`/roles/edit/${role.id}`);
  }, []);
  const onClone = useCallback((role: Role) => {
    history.push(`/roles/clone/${role.id}`);
  }, []);
  const onAdd = useCallback(() => {
    history.push('/roles/new');
  }, []);
  const onRemove = useCallback((role: Role) => {
    setRoleToDelete(role);
  }, []);

  const onConfirmRemove = useCallback(() => {
    if (roleToDelete && roleToDelete.id) {
      deleteRole(roleToDelete.id)
        .then(() => {
          pushAppNotification({
            type: 'success',
            message: `Deleted role ${roleToDelete.name}`
          });
          fetchAllRoles();
        })
        .catch((e: ApiError) => pushAppNotification({
          type: 'error',
          message: `Failed to delete role. ${e.message}`
        }));
      setRoleToDelete(undefined);
    }
  }, [fetchAllRoles, roleToDelete]);

  const menuActions: RowMenuAction<Role>[] = [];
  if (UserService.canUpdate('roles')) {
    menuActions.push({type: 'button', icon: Create, label: 'Edit', onClick: onEdit});
  }
  if (UserService.canCreate('roles')) {
    menuActions.push({type: 'button', icon: FileCopy, label: 'Clone', onClick: onClone});
  }
  if (UserService.canDelete('roles')) {
    menuActions.push({type: 'button', icon: Delete, label: 'Delete', onClick: onRemove});
  }

  if (loading && !addMode) {
    return <Box textAlign="center">
      <CircularProgress/>
    </Box>;
  }

  if (roleNotFound) {
    return <Box textAlign="center">
      Role with id {roleId} not found.
    </Box>;
  }

  return <>
    {showEditor || <>
      <Box mb={2}>
        <Grid container spacing={2} alignItems="center">
          <Grid item xs>
            <Typography variant="h6">
              Roles
            </Typography>
          </Grid>
          {UserService.canCreate('roles') && (
            <Grid item>
              <Button
                onClick={onAdd}
                variant="contained"
                color="primary"
              >
                Add Role
              </Button>
            </Grid>
          )}
        </Grid>
      </Box>
      <AdminTable
        storageId="roles-permissions"
        title=""
        columns={columns}
        data={roles}
        menuActions={menuActions}
      />
    </>}
    {showEditor && <RoleEditor
      role={role}
      cloneMode={cloneMode}
      onClose={handleCloseEditor}
      onDataChange={fetchAllRoles}
    />}
    {roleToDelete &&
      <ConfirmationDialog
        open={!!roleToDelete}
        title={`Delete role ${roleToDelete.name}?`}
        onClose={handleCloseDeleteDialog}
        onConfirm={onConfirmRemove}
      />
    }
  </>;
};

import { useCallback, useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { cmSetMigrationActions } from '../../../../redux/catalog-migration/actions';
import type { MigrationActions } from '../../../../redux/catalog-migration/reducer';
import { useTypedSelector } from '../../../../redux/reducers';
import { TextLanguageDiff } from '../../types';
import { TextLanguages } from './TextLanguages';

export const TextLanguagesContainer = () => {
  const dispatch = useDispatch();

  const sourceTextLanguages = useTypedSelector(state => state.catalogMigration.source.textLanguages);
  const targetTextLanguages = useTypedSelector(state => state.catalogMigration.target.textLanguages);
  const actions = useTypedSelector(state => state.catalogMigration.actions.textLanguages);

  const [sourceItems, setSourceItems] = useState<TextLanguageDiff[] | null>(null);
  const [targetItems, setTargetItems] = useState<TextLanguageDiff[] | null>(null);

  const onSelectionChange = useCallback(
    (actions: MigrationActions) => dispatch(cmSetMigrationActions({ id: 'textLanguages', actions })),
    [dispatch]
  );

  useEffect(() => {
    if (!sourceTextLanguages || !targetTextLanguages) {
      return;
    }
    setSourceItems(sourceTextLanguages.map(x => new TextLanguageDiff(x)));
    setTargetItems(targetTextLanguages.map(x => new TextLanguageDiff(x)));
  }, [sourceTextLanguages, targetTextLanguages]);

  return (<TextLanguages
    sourceItems={sourceItems}
    targetItems={targetItems}
    actions={actions}
    onSelectionChange={onSelectionChange} />);
}
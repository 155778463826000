import type { Theme } from '@material-ui/core';
import { Grid, makeStyles } from '@material-ui/core';
import { useCallback, useEffect, useRef, useState } from 'react';
import { uploadLiveEventImage } from '../live-events-api';
import { LiveEvent } from '../live-events-types';
import { EditorItem, LiveEventImageItem } from './LiveEventImageItem';

const useStyles = makeStyles((theme: Theme) => ({
  addButton: {
    borderWidth: 3,
    borderStyle: 'dashed',
    borderColor: theme.palette.secondary.main,
    borderRadius: 10,
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: theme.palette.type === 'dark' ? 'rgba(255, 255, 255, 0.1)' : 'rgba(0, 0, 0, 0.1)'
    }
  }
}));

interface LiveEventImagesEditorProps {
  liveEvent: LiveEvent;
  onChange: (imageUrls: string[]) => void;
}

export const LiveEventImagesEditor = (props: LiveEventImagesEditorProps) => {
  const classes = useStyles();
  const gridRef = useRef<HTMLDivElement | null>(null);

  const { liveEvent, onChange } = props;

  const [items, setItems] = useState<EditorItem[]>([]);

  const buildEditorItem = useCallback((imageUrl: string, isNewImageItem: boolean): EditorItem => {
    return {
      key: isNewImageItem ? 'new' : imageUrl,
      imageUrl,
      isNewImageItem,
    }
  }, []);

  const buildNewImageItem = useCallback((): EditorItem => {
    return buildEditorItem('', true);
  }, [buildEditorItem]);

  useEffect(() => {
    const editorItems: EditorItem[] = [];

    if (liveEvent.imageUrls) {
      liveEvent.imageUrls.forEach(imageUrl => {
        editorItems.push(buildEditorItem(imageUrl, false));
      });
    }

    // add the new image item at the end
    editorItems.push(buildNewImageItem());

    setItems(editorItems);
  }, [liveEvent, buildEditorItem, buildNewImageItem]);

  const handleRemoveItem = useCallback((index: number) => {
    const newItems = items.slice();
    newItems.splice(index, 1);
    setItems(newItems);
    onChange(newItems.filter(i => !i.isNewImageItem).map(i => i.imageUrl));
  }, [items, onChange]);

  const handleUpdateItem = useCallback((index: number, imageUrl: string) => {
    const newItems = items.slice();
    newItems[index].imageUrl = imageUrl;
    const isNewImageItem = index === newItems.length - 1;
    if (isNewImageItem) {
      // remove the current newImageItem
      newItems.splice(index, 1);
      // add the image item
      newItems.push(buildEditorItem(imageUrl, false));
      // add a new newImageItem
      newItems.push(buildNewImageItem());
    }
    setItems(newItems);
    onChange(newItems.filter(i => !i.isNewImageItem).map(i => i.imageUrl));
  }, [items, buildEditorItem, buildNewImageItem, onChange]);

  const handleMoveItem = useCallback((fromIndex: number, toIndex: number) => {
    const newItems = items.slice();
    const t = newItems[toIndex];
    newItems[toIndex] = newItems[fromIndex];
    newItems[fromIndex] = t;
    setItems(newItems);
    onChange(newItems.filter(i => !i.isNewImageItem).map(i => i.imageUrl));
    return true;
  }, [items, onChange]);

  return (<>
    <Grid ref={e => { gridRef.current = e; }} container spacing={1}>
      {items.map((item, index) => (
        <Grid key={item.key} item>
          <LiveEventImageItem
            index={index}
            item={item}
            uploadLiveEventImage={(file) => uploadLiveEventImage(liveEvent, file) }
            onRemove={() => handleRemoveItem(index)}
            moveItem={fromIndex => handleMoveItem(fromIndex, index)}
            onUpdatedItem={linkImage => handleUpdateItem(index, linkImage)}
          />
        </Grid>
      ))}
    </Grid>
  </>);
}

import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton, InputAdornment, TextField } from '@material-ui/core';
import { format } from 'date-fns';
import type { ChangeEvent } from 'react';
import { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import { setAppNotification } from '../../../redux/app/actions';
import type { ApiError } from '../../../services/api';
import type { ShopItem, ShopVersion } from '../shop';
import { createShopVersion, createShopVersionFull, getShopVersion, updateShopVersion } from '../shopsApi';
import { CatalogSelect } from './CatalogSelect';
import { AdminKeyboardDateTimePicker } from "../../../components/AdminKeyboardDateTimePicker";
import type { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';

export interface ShopVersionDialogProps {
  shopId: string;
  shopVersion: ShopVersion | null;
  isCloning: boolean;
  onClose: () => void;
  onShopVersionUpdate: (shopVersion: ShopVersion) => void;
}

export const ShopVersionDialog = (props: ShopVersionDialogProps) => {
  const dispatch = useDispatch();

  const { onShopVersionUpdate, isCloning } = props;

  const [open, setOpen] = useState(true);
  const [startDate, setStartDate] = useState(props.shopVersion?.startDate ? format(new Date(props.shopVersion.startDate), 'yyyy-MM-dd\'T\'HH:mm:ss') : '');
  const [preloadStartDate, setPreloadStartDate] = useState(props.shopVersion?.preloadStartDate ? format(new Date(props.shopVersion.preloadStartDate), 'yyyy-MM-dd\'T\'HH:mm:ss') : '');
  const [name, setName] = useState(props.shopVersion?.name || '');
  const [nameError, setNameError] = useState('');
  const [catalogName, setCatalogName] = useState(props.shopVersion?.catalogName || '');

  const onNameChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    const newName = e.target.value;
    setName(newName);
    setNameError(newName.trim() === '' ? 'A name is required' : '');
  }, []);

  const onDateChange = useCallback((date: MaterialUiPickersDate, value?: string | null | undefined) => {
    try{
      if(date?.toString() != 'Invalid Date') {
        const isoDate = date?.toISOString() || '';
        setStartDate(isoDate);
      } else {
        setStartDate('');
      }
    } catch {
      setStartDate('');
    }
  },[]);

  const onSubmit = useCallback(() => {
    if (props.shopVersion && !isCloning) {
      updateShopVersion({ ...props.shopVersion, name, startDate, preloadStartDate, catalogName }).then(updatedVersion => {
        onShopVersionUpdate(updatedVersion);
        dispatch(setAppNotification({ type: 'success', message: 'Shop version updated' }));
        setOpen(false);
      }).catch((e: ApiError) => dispatch(setAppNotification({ type: 'error', message: `Error updating shop version. ${e.message}` })));
    } else {
      let promise: Promise<ShopVersion>;
      if (props.shopVersion && isCloning) {
        promise = getShopVersion(props.shopVersion.id, true).then(shopVersion => {
          const shopItemMap: { [key: string]: ShopItem } = {};
          shopVersion.items.forEach(item => shopItemMap[item.id] = item);
          shopVersion.tabs.forEach(tab => {
            tab.items.forEach(tabItem => {
              if (tabItem.shopItemId) {
                tabItem.itemId = shopItemMap[tabItem.shopItemId].itemId;
              }
            });
          });
 
          return createShopVersionFull({ ...shopVersion, name, catalogName, startDate, preloadStartDate });
        });
      } else {
        promise = createShopVersion(props.shopId, name, catalogName, startDate, preloadStartDate);
      }

      promise.then(newVersion => {
        onShopVersionUpdate(newVersion);
        dispatch(setAppNotification({ type: 'success', message: 'Shop version added' }));
        setOpen(false);
      }).catch((e: ApiError) => { console.error(e); dispatch(setAppNotification({ type: 'error', message: `Error adding shop version. ${e.message}` })); });
    }
  }, [dispatch, onShopVersionUpdate, props.shopId, props.shopVersion, name, startDate, preloadStartDate, catalogName, isCloning]);

  return (
    <Dialog
      open={open}
      onClose={() => setOpen(false)}
      onExited={props.onClose}
      fullWidth
      maxWidth="sm"
    >
      <DialogTitle>{props.shopVersion ? (isCloning ? 'Clone' : 'Edit') : 'Add'} Shop Version</DialogTitle>
      <DialogContent>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <TextField
              label="Name"
              fullWidth
              error={Boolean(nameError)}
              helperText={nameError}
              value={name}
              onChange={onNameChange}
            />
          </Grid>
          <Grid item xs={12}>
            <AdminKeyboardDateTimePicker
              label="Start Date"
              name="startDate"
              clearable
              fullWidth
              InputLabelProps={{ shrink: true }}
              value={startDate}
              onChange={onDateChange}
            />
          </Grid>
          {startDate && (
            <Grid item xs={12}>
              <AdminKeyboardDateTimePicker
                label="Preload Start Date"
                name="preloadStartDate"
                clearable
                fullWidth
                InputLabelProps={{ shrink: true }}
                value={preloadStartDate}
                onChange={date => setPreloadStartDate(date?.toISOString() || '')}
              />
            </Grid>
          )}
          <Grid item xs={12}>
            <CatalogSelect
              catalogName={catalogName}
              onCatalogNameChange={setCatalogName}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          color="primary"
          variant="contained"
          onClick={onSubmit}
          disabled={name === '' || Boolean(nameError)}
        >
          {props.shopVersion ? 'Save' : 'Add'}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

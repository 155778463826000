import { zodResolver } from "@hookform/resolvers/zod";
import { Box, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core';
import _ from 'lodash';
import fp from 'lodash/fp';
import { useEffect } from 'react';
import type { Control } from "react-hook-form";
import { useForm } from "react-hook-form";
import { z } from 'zod';
import ImageUpload from '../../components/ImageUpload';
import { ControlledTextField } from "./ControlledTextField";
import { useCreateArtist, useUpdateArtist, useUploadArtistImage } from './hooks';

type NameFieldProps = {
  control: Control<Artist, unknown>;
  isSubmitting: boolean;
}

type ArtistFormProps = {
  show: boolean
  artist?: Artist
  onClose: () => void
}

type Artist = z.infer<typeof Artist>;

const Artist = z.object({
  id: z.string().or(z.undefined()),
  name: z.string({
    required_error: "A name is required"
  }).min(1, "A name is required").transform(fp.trim),
  imageFilename: z.string({
    required_error: "An image is required"
  }).min(1, "An image is required"),
  imageUrl: z.string().optional()
});

const NameField = ({ control, isSubmitting }: NameFieldProps) => <ControlledTextField<Artist>
  label="Name"
  name="name"
  control={control} 
  required={true}
  InputProps={{ readOnly: isSubmitting }} 
/>

const artistHasId = (artist: Artist): artist is Required<Artist> => artist.id !== undefined;

export const ArtistForm = ({ show, artist, onClose }: ArtistFormProps) => {
  const { handleSubmit, control, setValue, formState, watch } = useForm<Artist>({
    defaultValues: {
      id: artist?.id,
      name: artist?.name ?? "",
      imageFilename: artist?.imageFilename ?? "",
      imageUrl: artist?.imageUrl ?? "",
    },
    resolver: zodResolver(Artist),
    mode: "onChange"
  });

  const isAddMode = artist === undefined;
  const imageUrl = watch('imageUrl');

  const { mutate: uploadImage, data: imageFileName, isLoading: isImageUploading } = useUploadArtistImage();
  const { mutate: createArtist, isLoading: isCreateArtistLoading } = useCreateArtist();
  const { mutate: updateArtist, isLoading: isUpdateArtistLoading } = useUpdateArtist();

  const isSubmitting = isImageUploading || isCreateArtistLoading || isUpdateArtistLoading;

  useEffect(() => {
    if (!_.isString(imageFileName)) return;
    setValue('imageFilename', imageFileName, {
      shouldDirty: true,
      shouldTouch: true,
      shouldValidate: true
    });
  }, [imageFileName, setValue]);

  const onSubmit = handleSubmit(
    _.flow(
      fp.cond([
        [fp.constant(isAddMode), createArtist], 
        [
          fp.stubTrue, 
          (changedArtist) => {
            if (!artistHasId(changedArtist)) return;
            updateArtist(changedArtist);
          }
        ]
      ]),
      onClose
    ));

  return (
    <Dialog
      open={show}
      onClose={onClose}
      onExited={onClose}
      fullWidth
      >
      <form onSubmit={onSubmit}>
        <DialogTitle>New artist</DialogTitle>
        <DialogContent>
          <NameField control={control} isSubmitting={isSubmitting} />
          <Box mt={2}>
            <ImageUpload imageUrl={imageUrl} onFileChange={uploadImage} />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button disabled={!formState.isValid || isSubmitting} variant="contained" color="primary" type="submit">
            {isSubmitting ? (
              <CircularProgress size={25} />
            ) : `${isAddMode ? 'Create' : 'Update'} artist`}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}

import type { Reducer } from 'redux';
import { createReducer } from 'typesafe-actions';
import { toggleSidebar } from './actions';

export interface SidebarState {
  mobileOpen: boolean;
}

const initialState: SidebarState = {
  mobileOpen: false
};

const sidebarReducer: Reducer<SidebarState> = createReducer(initialState)
  .handleAction(toggleSidebar, state => ({
    ...state,
    mobileOpen: !state.mobileOpen
  }));

export default sidebarReducer;
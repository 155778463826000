import { FormControl, InputLabel, MenuItem, Select } from '@material-ui/core';
import * as React from 'react';

interface CatalogSelectorProps {
  catalogName?: string;
  catalogNames: string[];
  handleChange(event: React.ChangeEvent<{
    name?: string | undefined;
    value: unknown;
  }>): void;
}

export const CatalogSelector = ({
  catalogName,
  catalogNames,
  handleChange
}: CatalogSelectorProps) => (
  <FormControl>
    <InputLabel>Catalog</InputLabel>
    <Select
      value={catalogName || catalogNames[0]}
      onChange={handleChange}
    >
      {catalogNames.map(catalogName => (
        <MenuItem key={catalogName} value={catalogName}>{catalogName}</MenuItem>
      ))}
    </Select>
  </FormControl>
);

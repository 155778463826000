import {Grid, Theme,
  Box,
  Button,
  createStyles,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  makeStyles,
  CircularProgress} from '@material-ui/core';
import {useCallback, useEffect, useMemo, useState} from 'react';
import { ChallengeTemplatesService, ChallengeTrigger, CustomType } from '../../services/player-challenges/challenge-templates';
import { usePushNotification } from '../../contexts/AppNotificationContext';
import _ from 'lodash';
import JsonEditor from '../../components/JsonEditor';
import { ChallengeTriggerTypeEnum } from '../../services/player-challenges/challenges';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    form: {
      display: 'flex',
      flexDirection: 'column',
      margin: 'auto',
      width: 'fit-content',
      marginBottom: 8,
    },
    formControl: {
      marginTop: theme.spacing(2),
      minWidth: 400,
    },
  }),
);

interface EditGameChallengeDialogProps {
  onSave: () => void,
  onCancel: () => void,
}

export const EditGameChallengeDialog = ({ onSave, onCancel }: EditGameChallengeDialogProps) => {
  const pushNotification = usePushNotification();
  const classes = useStyles();

  const [gameChallenge, setGameChallenge] = useState<ChallengeTrigger>();
  const [templateParameters, setTemplateParameters] = useState<string>();
  const [customTypes, setCustomTypes] = useState<string>();
  const [jsonIsValid, setJsonIsValid] = useState(true);
  const [isSaving, setIsSaving] = useState(false);

  const originalTemplateParameters = useMemo(() => {
    if (!gameChallenge || !gameChallenge.template)
      return '[]';

    // the template parameters are already in DB form
    const templateParameters = gameChallenge.template.parameters;
    return JSON.stringify(templateParameters, null, 2);
  }, [gameChallenge]);

  const originalCustomTypes = useMemo(() => {
    if (!gameChallenge || !gameChallenge.custom_types)
      return '';
    // we want our custom types in DB form (NOT using_these_names)
    const customTypes = gameChallenge.custom_types.map((custom_type: any) => custom_type.toJson());
    return JSON.stringify(customTypes, null, 2);
  }, [gameChallenge]);

  useEffect(() => {
    ChallengeTemplatesService.getLatestChallengeTriggers()
      .then((triggers) => {
        // find the GAME_CHALLENGE
        const gc = triggers.find(t => t.trigger_type === ChallengeTriggerTypeEnum.GAME_CHALLENGE);
        if (gc) {
          setGameChallenge(gc);

          // the template parameters are already in DB form
          const templateParameters = gc.template ? gc.template.parameters : [];
          setTemplateParameters(JSON.stringify(templateParameters, null, 2));

          // we want our custom types in DB form (NOT using_these_names)
          const customTypes = gc.custom_types.map((custom_type: any) => custom_type.toJson());
          setCustomTypes(JSON.stringify(customTypes, null, 2));
        } else {
          pushNotification({type: 'error', message: `No Game Challenge defined`});
        }
      })
      .catch((err: { message: any; }) => pushNotification({type: 'error', message: `Failed to load Game Challenge: ${err.message}`}));
  }, [pushNotification]);

  const saveGameChallenge = useCallback(() => {
    if (!jsonIsValid || isSaving || !gameChallenge || !templateParameters || !customTypes)
      return;

    setIsSaving(true);

    // bump the trigger version
    gameChallenge.version = gameChallenge.version ? (gameChallenge.version + 1) : 1;

    // The template params and custom types are in DB format, need to prepare them
    // to convert back to our admin-side format with underscores.
    if (gameChallenge.template) {
      // template parameters do not need any special conversion
      gameChallenge.template.parameters = JSON.parse(templateParameters);
    }
    const dbCustomTypes = JSON.parse(customTypes);
    gameChallenge.custom_types = dbCustomTypes.map((customType: any) => new CustomType(customType))

    ChallengeTemplatesService.saveChallengeTrigger(gameChallenge)
      .then(() => {
        pushNotification({type: 'success', message: `Saved Game Challenge`});
        onSave();
      })
      .catch((err: { message: any; }) => pushNotification({type: 'error', message: `Failed to save Game Challenge: ${err.message}`}))
      .finally(() => setIsSaving(false));

  }, [customTypes, gameChallenge, jsonIsValid, isSaving, templateParameters, onSave, pushNotification]);

  const isDirty = useCallback(() => {
    const templateChanged = originalTemplateParameters.replace(/\s/g, '') !== templateParameters?.replace(/\s/g, '');
    const customTypesChanged = originalCustomTypes.replace(/\s/g, '') !== customTypes?.replace(/\s/g, '');
    return templateChanged || customTypesChanged;
  }, [customTypes, originalCustomTypes, originalTemplateParameters, templateParameters]);

  const loading = !gameChallenge || templateParameters === undefined || customTypes === undefined;
  const canSave = !loading && !isSaving && jsonIsValid && isDirty();

  return (
    <>
      <Dialog
        open={true}
        fullWidth
        onClose={onCancel}
        aria-labelledby="add-dialog-title"
        maxWidth="md"
      >
        <DialogTitle id="add-dialog-title">Edit Game Challenge</DialogTitle>
        <DialogContent className={classes.form}>
          {loading &&
            <Box textAlign="center">
              <CircularProgress/>
            </Box>
          }
          {!loading &&
            <Grid container spacing={2}>
              <Grid item>
                <Box width={425}>
                  <JsonEditor
                    title="Template Parameters"
                    json={templateParameters}
                    onJsonChange={value => setTemplateParameters(value)}
                    onValidityChange={ valid => setJsonIsValid(valid) }
                    // onBlur={() => this.props.onUpdate({ ...this.props.row, value: this.state.value })}
                    focus
                  />
                </Box>
              </Grid>
              <Grid item>
                <Box width={425}>
                  <JsonEditor
                    title="Custom Types"
                    json={customTypes}
                    onJsonChange={value => setCustomTypes(value)}
                    onValidityChange={ valid => setJsonIsValid(valid) }
                    // onBlur={() => this.props.onUpdate({ ...this.props.row, value: this.state.value })}
                    focus
                  />
                </Box>
              </Grid>
            </Grid>
          }
        </DialogContent>
        <DialogActions>
          <Button
            onClick={saveGameChallenge}
            variant="contained"
            disabled={!canSave}
            color="primary"
          >
            OK
          </Button>
          <Button onClick={onCancel} color="primary">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

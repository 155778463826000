import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import CircularProgress from '@material-ui/core/CircularProgress';
import FormControl from '@material-ui/core/FormControl';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import MenuIcon from '@material-ui/icons/Menu';
import WarningIcon from '@material-ui/icons/Warning';
import type { FieldArrayRenderProps, FieldProps, FormikHelpers } from 'formik';
import { Field, FieldArray, Form, Formik } from 'formik';
import { CheckboxWithLabel, Select, TextField } from 'formik-material-ui';
import ChipInput from "material-ui-chip-input";
import * as React from 'react';
import type { IAnnotation } from 'react-ace';
import AceEditor from 'react-ace';
import type { ConnectedProps } from 'react-redux';
import { connect } from 'react-redux';
import { AdminKeyboardDateTimePicker } from '../../components/AdminKeyboardDateTimePicker';
import { loadCurrenciesAsync } from '../../redux/currencies/actions';
import type { RootState } from '../../redux/reducers';
import type { ApiError } from '../../services/api';
import type { DropTable } from '../../services/drop-tables';
import type { ItemGrantType, ItemType } from '../../services/item-definitions';
import { ContainedItem, ItemDefinition } from '../../services/item-definitions';
import { TitleService } from '../../services/title-data';
import { UserService } from '../../services/user';
import { openAdminConfirmationDialog } from '../../shared/hooks/useAdminConfirmationDialog';
import { pushAppNotification } from '../../shared/hooks/useAppNotification';
import { createValidator, Validators } from '../../utils/forms';
import type { FormContainedItem } from './FormContainedItem';
import { ItemContentsForm } from './ItemContentsForm';
import ItemSelectDialog from './ItemSelectDialog';
import { EntityNotes } from "../../shared/components/EntityNotes";
import { createItemNote, deleteItemNote, getItemNotes, updateItemNote } from "../../features/items/item-apis";
import { ItemNote } from "../../features/items/item-types";
import { Info } from "@material-ui/icons";
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';

const mapStateToProps = (state: RootState) => ({
  currencyList: state.currencies.list,
  darkMode: state.app.darkMode,
  userEmail: state.authentication?.oAuthClient?.user.profile.email,
});
const mapDispatch = { requestLoadCurrencies: loadCurrenciesAsync.request };
const connector = connect(mapStateToProps, mapDispatch);

export interface ItemDefFormValues {
  itemId: string;
  itemClass: string;
  itemName: string;
  imageUrl: string;
  starterPack: boolean;
  itemGrantType: ItemGrantType;
  displayName: string;
  description: string;
  mashable: boolean;
  subtitle: string;
  consumable: boolean;
  searchable: boolean;
  withdrawable: boolean;
  consumableUsageCount: string;
  consumableUsagePeriod: string;
  consumableUsagePeriodUnit: string;
  bundle: boolean;
  container: boolean;
  containerLocked: boolean;
  containerKey: string;
  contents: FormContainedItem[];
  currencyContents: {
    code: string;
    name: string;
    amount: string;
  }[];
  notes: string;
  tags: string[];
  releaseDate: string | null;
  pipelineGenerated: boolean;
}

type Props = ConnectedProps<typeof connector> & {
  catalogName: string;
  itemType?: ItemType;
  item?: ItemDefinition;
  onSave?: (item: ItemDefinition) => void;
};

interface State {
  customDataValid: boolean;
  initialFormValues: ItemDefFormValues;
  isBundleOrContainer: boolean;
  contentsDialogOpen: boolean;
  containerKeyDialogOpen: boolean;
  itemIsMashable: boolean;
  overridePipelineGenerated: boolean;
}

const consumableValidators = createValidator<ItemDefFormValues>({
  consumableUsageCount: Validators.blank(Validators.integer(1))
});

const notBlankValidator = Validators.notBlank();
const consumableUsagePeriodValidator = Validators.integer(3);

const contentValidators = createValidator<FormContainedItem>({
  quantity: Validators.integer(1)
});

const containerLockValidators = createValidator<ItemDefFormValues>({
  containerKey: Validators.notBlank()
});

const formValidator = createValidator<ItemDefFormValues>({
  itemId: Validators.notBlank(),
  currencyContents: Validators.array(createValidator({
    amount: Validators.blank(Validators.integer(0))
  }))
}, values => {
  const newErrors: any = {};

  if (values.consumable) {
    let consumableErrors = consumableValidators(values);
    if (!consumableErrors) {
      const countError = notBlankValidator(values.consumableUsageCount);
      const periodError = notBlankValidator(values.consumableUsagePeriod);
      if (typeof countError === 'string' && typeof periodError === 'string') {
        consumableErrors = { consumableUsageCount: countError, consumableUsagePeriod: periodError };
      } else if (!periodError && values.consumableUsagePeriodUnit === 'second' && consumableUsagePeriodValidator(values.consumableUsagePeriod)) {
        consumableErrors = { consumableUsagePeriod: 'Must be 3 seconds or longer' };
      }
    }
    Object.assign(newErrors, consumableErrors);
  }
  if (values.bundle || values.container) {
    if (values.contents.length > 0) {
      const contentsErrors = values.contents.map(item => contentValidators(item));
      if (contentsErrors.find(v => !!v)) {
        newErrors.contents = contentsErrors;
      }
    }

    if (values.container && values.containerLocked) {
      Object.assign(newErrors, containerLockValidators(values));
    }
  }
  return newErrors;
});

const timeUnits: { name: string, value: number; }[] = [
  { name: 'second', value: 1 },
  { name: 'minute', value: 60 },
  { name: 'hour', value: 3600 },
  { name: 'day', value: 86400 },
  { name: 'week', value: 604800 },
  { name: 'month', value: 2592000 },
  { name: 'year', value: 31536000 }
];

class ItemDefinitionForm extends React.Component<Props, State> {
  private customData = '';
  private setSubmitting: ((submitting: boolean) => void) | undefined;

  constructor(props: Props) {
    super(props);
    if (this.props.item && this.props.item.customData) {
      this.customData = JSON.stringify(this.props.item.customData, null, '\t');
    }

    this.state = {
      customDataValid: false,
      initialFormValues: this.itemToFormValues(this.props.item),
      isBundleOrContainer: this.props.item ? (this.props.item.bundle || this.props.item.container) : false,
      contentsDialogOpen: false,
      containerKeyDialogOpen: false,
      itemIsMashable: true,
      overridePipelineGenerated: false,
    };
  }

  private itemToFormValues(item?: ItemDefinition): ItemDefFormValues {
    if (!item) {
      return {
        itemId: '',
        itemClass: '',
        itemName: '',
        imageUrl: '',
        starterPack: false,
        searchable: false,
        withdrawable: false,
        mashable: true,
        itemGrantType: 'DEFAULT',
        displayName: '',
        description: '',
        subtitle: '',
        consumable: false,
        consumableUsageCount: '',
        consumableUsagePeriod: '',
        consumableUsagePeriodUnit: timeUnits[0].name,
        bundle: this.props.itemType === 'bundle',
        container: this.props.itemType === 'container',
        containerLocked: false,
        containerKey: '',
        contents: [],
        currencyContents: this.props.currencyList.getCurrencies().map(currency => ({ code: currency.code, name: currency.name, amount: '' })),
        notes: '',
        tags: [],
        releaseDate: null,
        pipelineGenerated: false,
      };
    }

    const f: ItemDefFormValues = {
      itemId: item.itemId,
      itemClass: item.itemClass || '',
      itemName: item.itemName || '',
      imageUrl: item.imageUrl || '',
      mashable: true,
      starterPack: item.starterPack,
      searchable: item.token?.searchable || false,
      withdrawable: item.token?.withdrawable || false,
      itemGrantType: item.itemGrantType,
      displayName: item.displayName || '',
      description: item.description || '',
      subtitle: item.subtitle || '',
      consumable: item.consumable,
      consumableUsageCount: '',
      consumableUsagePeriod: '',
      consumableUsagePeriodUnit: timeUnits[0].name,
      bundle: item.bundle,
      container: item.container,
      containerLocked: item.containerLocked,
      containerKey: item.containerLocked ? item.containerKeyItemId : '',
      contents: [],
      currencyContents: this.props.currencyList.getCurrencies().map(currency => ({
        code: currency.code,
        name: currency.name,
        amount: typeof item.currencyContents[currency.code] === 'number' ? item.currencyContents[currency.code].toString() : '0'
      })),
      notes: item.notes,
      tags: item.tags,
      releaseDate: item.releaseDate,
      pipelineGenerated: item.pipelineGenerated,
    };

    if (item.consumable) {
      if (typeof item.consumableUsageCount === 'number') {
        f.consumableUsageCount = item.consumableUsageCount.toString();
      }
      if (typeof item.consumableUsagePeriod === 'number') {
        const period = item.consumableUsagePeriod;
        const unit = timeUnits.slice().reverse().find(v => period % v.value === 0) || timeUnits[0];
        f.consumableUsagePeriod = (period / unit.value).toString();
        f.consumableUsagePeriodUnit = unit.name;
      }
    }

    if (item.bundle || item.container) {
      const items = item.bundle ? item.itemBundleContents.slice() : item.itemContainerContents.slice();
      items.sort((a, b) => (a.index > b.index) ? 1 : -1);
      f.contents = items.map((item, i) => ({
        id: item.id,
        quantity: item.quantity.toString(),
        type: item.type,
        index: i
      }));
    }

    return f;
  }

  componentDidMount() {
    this.props.requestLoadCurrencies();
    if (this.props.item?.itemClass === 'Blanko') {
      TitleService.getTitle().then(data => {
        try {
          const exclusionList = JSON.parse(data.titleData.MashupSettings).MashupExcludedBlankoDnaIdList as string[];
          if (this.props.item) {
            const itemIsMashable = exclusionList.indexOf(this.props.item.itemId) < 0;
            this.setState({
              itemIsMashable,
              initialFormValues: { ...this.state.initialFormValues, mashable: itemIsMashable }
            });
          }
        } catch (e) {
          // Mashup settings not available. Fail silently for now
        }
      });
    }
  }

  componentDidUpdate(prevProps: Props) {
    if (prevProps.item !== this.props.item) {
      if (this.props.item && this.props.item.customData) {
        this.customData = JSON.stringify(this.props.item.customData, null, '\t');
      }

      this.setState({ initialFormValues: this.itemToFormValues(this.props.item) });
      if (this.setSubmitting) {
        this.setSubmitting(false);
      }
    } else if (prevProps.currencyList !== this.props.currencyList) {
      this.setState({ initialFormValues: this.itemToFormValues(this.props.item) });
    }
  }

  onMoveItem = (arrayHelpers: FieldArrayRenderProps, dragIndex: number, hoverIndex: number) => {
    arrayHelpers.swap(dragIndex, hoverIndex);
  };

  onCustomDataChange = (value: string) => {
    this.customData = value;
  };

  onCustomDataValidate = (annotations: IAnnotation[]) => {
    let valid = true;
    for (let i = 0; i < annotations.length; i++) {
      if (annotations[i].type === 'error') {
        valid = false;
        break;
      }
    }

    this.setState({ customDataValid: valid });
  };

  onSubmit = (values: ItemDefFormValues, helpers: FormikHelpers<ItemDefFormValues>) => {
    let customData: any = null;

    try {
      customData = JSON.parse(this.customData);
    } catch (e) {
      // TODO: Handle invalid JSON
    }

    const item = new ItemDefinition();
    item.itemId = values.itemId;
    item.itemClass = values.itemClass;
    item.itemName = values.itemName;
    item.catalogName = this.props.catalogName;
    item.displayName = values.displayName;
    item.description = values.description;
    item.subtitle = values.subtitle;
    item.imageUrl = values.imageUrl;
    item.imageThumbnailUrl = this.props.item ? this.props.item.imageThumbnailUrl : '';
    item.stackable = this.props.item ? this.props.item.stackable : false;
    item.tradable = this.props.item ? this.props.item.tradable : false;
    item.itemGrantType = values.itemGrantType;
    item.tokenForCharacter = this.props.item ? this.props.item.tokenForCharacter : false;
    item.notes = values.notes.trim();
    item.tags = values.tags;
    item.starterPack = values.starterPack;
    item.releaseDate = values.releaseDate;
    item.pipelineGenerated = values.pipelineGenerated;

    item.limitedEdition = this.props.item ? this.props.item.limitedEdition : false;
    if (item.limitedEdition && this.props.item) {
      item.initialLimitedEditionCount = this.props.item.initialLimitedEditionCount;
    }
    item.customData = customData;
    item.consumable = values.consumable;
    if (item.consumable) {
      if (values.consumableUsageCount.trim()) {
        item.consumableUsageCount = parseInt(values.consumableUsageCount);
      }
      if (values.consumableUsagePeriod.trim()) {
        const timeUnit = timeUnits.find(v => v.name === values.consumableUsagePeriodUnit) || timeUnits[0];
        item.consumableUsagePeriod = parseInt(values.consumableUsagePeriod) * timeUnit.value;
      }
    }

    if (values.bundle || values.container) {
      const contents = values.contents.map((contentValues, index) => {
        const containedItem = new ContainedItem();
        containedItem.type = contentValues.type;
        containedItem.id = contentValues.id;
        containedItem.quantity = parseInt(contentValues.quantity);
        containedItem.index = index;
        return containedItem;
      });

      if (values.bundle) {
        item.bundle = true;
        item.itemBundleContents = contents;
      } else if (values.container) {
        item.container = true;
        item.itemContainerContents = contents;
        item.containerLocked = values.containerLocked;
        if (item.containerLocked) {
          item.containerKeyItemId = values.containerKey;
        }
      }

      values.currencyContents.forEach(currencyItem => {
        const amount = parseInt(currencyItem.amount);
        if (amount > 0) {
          item.currencyContents[currencyItem.code] = amount;
        }
      });
    }

    this.setSubmitting = helpers.setSubmitting;
    const onSave = this.props.onSave;

    if (onSave) {
      if (this.props.item?.starterPack !== values.starterPack && values.starterPack) {
        openAdminConfirmationDialog({
          title: "Starter Pack is selected!",
          details: `Are you sure you want to save this? This will grant the item to ALL players on login. Item grant type is ${values.itemGrantType}. ${values.itemGrantType === "DEFAULT" ?
            "This item will be granted multiple times." : "This item will be granted one time."}`,
          action: "Save",
          onConfirm: () => {
            this.checkForMashable(item, values.mashable);
            onSave(item);
          },
          onCancel: () => helpers.setSubmitting(false)
        });
      } else {
        this.checkForMashable(item, values.mashable);
        onSave(item);
      }
    }
  };

  checkForMashable(item: ItemDefinition, mashable: boolean) {
    if (this.state.itemIsMashable !== mashable && item.itemClass === 'Blanko') {
      this.setState({ itemIsMashable: mashable });
      TitleService.getTitle().then(data => {
        try {
          const json = JSON.parse(data.titleData.MashupSettings);
          const currentList: string[] = json.MashupExcludedBlankoDnaIdList;
          if (mashable) {
            const index = currentList.indexOf(item.itemId);
            if (index > -1) {
              currentList.splice(index, 1);
            }
            json.MashupExcludedBlankoDnaIdList = currentList;
          } else {
            json.MashupExcludedBlankoDnaIdList.push(item.itemId);
          }
          TitleService.updateClientTitle({ MashupSettings: JSON.stringify(json) })
            .catch((e: ApiError) => pushAppNotification({ type: 'error', message: `Error updating mashup settings in title data. ${e.message}` }));
        } catch (e) {
          pushAppNotification({ type: 'error', message: 'Error finding/updating mashup settings in title data' });
        }
      });
    }
  }

  onContentsRemove(arrayHelpers: FieldArrayRenderProps, id: string, type: string) {
    const values = arrayHelpers.form.values as ItemDefFormValues;
    const index = values.contents.findIndex(v => v.id === id && v.type === type);
    if (index > - 1) {
      arrayHelpers.remove(index);
    }
  }

  onContentsAdd(arrayHelpers: FieldArrayRenderProps, items: (ItemDefinition | DropTable)[]) {
    items.forEach((item, i) => {
      const formItem: FormContainedItem = item instanceof ItemDefinition ? {
        id: item.itemId,
        quantity: '1',
        type: 'item',
        index: i
      } : {
        id: item.id,
        quantity: '1',
        type: 'droptable',
        index: i
      };
      arrayHelpers.unshift(formItem);
    });
  }

  renderTimeUnits(plural: boolean) {
    return timeUnits.map(v => (
      <MenuItem key={v.name} value={v.name}>{v.name + (plural ? 's' : '')}</MenuItem>
    ));
  }

  render() {
    if (this.props.currencyList.isEmpty()) {
      return (
        <Box textAlign="center">
          <CircularProgress />
        </Box>
      );
    }

    const userCannotModify = (this.props.item && !UserService.canUpdate('catalogItems')) || (!this.props.item && !UserService.canCreate('catalogItems'));
    const readOnly = userCannotModify || (this.props.item?.pipelineGenerated && !this.state.overridePipelineGenerated);
    return (<>
      <Formik<ItemDefFormValues>
        initialValues={this.state.initialFormValues}
        enableReinitialize
        validate={formValidator}
        onSubmit={this.onSubmit}
      >
        {({ submitForm, isSubmitting, values, errors, setFieldValue }) => (
          <Form>
            <Grid container spacing={3}>
              <Grid item xs={12} md={6} xl={4}>
                <Card>
                  <CardHeader title="Properties" />
                  <CardContent>
                    <Grid container spacing={1}>
                      <Grid item xs={12}>
                        <Field
                          component={TextField}
                          name="itemId"
                          type="text"
                          label="Item ID"
                          fullWidth
                          required
                          InputProps={{
                            readOnly: !!this.props.item
                          }}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <Field
                          component={TextField}
                          name="itemClass"
                          type="text"
                          label="Item Class"
                          fullWidth
                          InputProps={{ readOnly }}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <Field
                          component={TextField}
                          name="itemName"
                          type="text"
                          label="Item Name"
                          fullWidth
                          InputProps={{ readOnly }}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <Field
                          component={TextField}
                          name="imageUrl"
                          type="text"
                          label="Image URL"
                          fullWidth
                          InputProps={{ readOnly }}
                        />
                      </Grid>
                      {this.props.item && this.props.item.imageThumbnailUrl && (
                        <Grid item xs={12}>
                          <img src={this.props.item.imageThumbnailUrl} width={128} height={128} alt={this.props.item.itemId} />
                        </Grid>
                      )}
                      <Grid item xs={12}>
                        <FormControl fullWidth>
                          <InputLabel>Item Grant Type</InputLabel>
                          <Field
                            component={Select}
                            name="itemGrantType"
                            inputProps={{ readOnly }}
                          >
                            <MenuItem value="DEFAULT">DEFAULT</MenuItem>
                            <MenuItem value="UNIQUE">UNIQUE</MenuItem>
                          </Field>
                        </FormControl>
                      </Grid>
                      {values.container && (<>
                        <Grid item xs={12}>
                          <Field
                            component={CheckboxWithLabel}
                            type="checkbox"
                            name="containerLocked"
                            Label={{ label: 'Locked' }}
                            disabled={isSubmitting || readOnly}
                          />
                        </Grid>
                        {values.containerLocked && (<>
                          <Grid item xs={12}>
                            <Field
                              component={TextField}
                              name="containerKey"
                              type="text"
                              label="Key"
                              fullWidth
                              InputProps={{
                                readOnly: true,
                                endAdornment: readOnly ? undefined : (
                                  <InputAdornment position="end">
                                    <Tooltip title="Select item...">
                                      <IconButton onClick={() => this.setState({ containerKeyDialogOpen: true })}>
                                        <MenuIcon />
                                      </IconButton>
                                    </Tooltip>
                                  </InputAdornment>
                                )
                              }}
                            />
                          </Grid>
                          {this.state.containerKeyDialogOpen && (
                            <ItemSelectDialog
                              onSelect={items => items.length > 0 && setFieldValue('containerKey', items[0] instanceof ItemDefinition ? items[0].itemId : '')}
                              onClose={() => this.setState({ containerKeyDialogOpen: false })}
                            />
                          )}
                        </>)}
                      </>)}
                      {this.props.item?.itemClass === 'Blanko' && (
                        <Grid item xs={12}>
                          <Field
                            component={CheckboxWithLabel}
                            type="checkbox"
                            name="mashable"
                            Label={{ label: 'Mashable' }}
                            disabled={isSubmitting || readOnly}
                          />
                        </Grid>
                      )}
                      <Grid item xs={12}>
                        <AdminKeyboardDateTimePicker
                          id="releaseDate"
                          name="releaseDate"
                          label={"Release Date"}
                          InputLabelProps={{ shrink: true }}
                          /* eslint-disable-next-line @typescript-eslint/no-empty-function */
                          onChange={() => { }}
                          value={values.releaseDate}
                          fullWidth
                          // makes the calendar button unclickable
                          readOnly
                          // makes the date text readonly
                          InputProps={{ readOnly: true }}
                        />
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={12} md={6} xl={4}>
                {(values.pipelineGenerated || this.state.overridePipelineGenerated) &&
                  <>
                    <Card>
                      <CardHeader title={<><Info /> Pipeline generated item</>} />
                      <CardContent>
                        This item is generated by the Unity pipeline.{' '}
                        {!userCannotModify && <>
                          Manual edits on this page may be
                          overwritten when changes are published via the pipeline.
                          <br />
                          <br />
                          <Button
                            onClick={() => {
                              setFieldValue("pipelineGenerated", false);
                              this.setState({ overridePipelineGenerated: true });
                            }}
                            variant="contained"
                            color="primary"
                            disabled={this.state.overridePipelineGenerated}
                          >Edit anyway</Button>
                        </>}
                      </CardContent>
                    </Card>
                    <br />
                  </>}
                <Card>
                  <CardHeader title="Game properties" />
                  <CardContent>
                    <Grid container spacing={1}>
                      <Grid item xs={12}>
                        <Field
                          component={TextField}
                          name="displayName"
                          type="text"
                          label="Display name"
                          fullWidth
                          InputProps={{ readOnly }}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <Field
                          component={TextField}
                          name="subtitle"
                          type="text"
                          label="Subtitle"
                          fullWidth
                          InputProps={{ readOnly }}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <Field
                          component={TextField}
                          name="description"
                          type="text"
                          label="Description"
                          fullWidth
                          multiline
                          rows={4}
                          InputProps={{ readOnly }}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <FieldArray
                          name="tags"
                          render={arrayHelpers => (
                            <Field
                              name="tags"
                              component={(() => (
                                <ChipInput
                                  label="Tags"
                                  value={values.tags}
                                  onAdd={chip => arrayHelpers.push(chip)}
                                  onDelete={(chip, index) => arrayHelpers.remove(index)}
                                  fullWidth
                                />
                              )) as React.ComponentType<FieldProps>}
                            />
                          )}
                        />
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={12} md={6} xl={4}>
                <Card>
                  <CardHeader title="Consumable" />
                  <CardContent>
                    <Grid container spacing={1}>
                      <Grid item xs={12}>
                        <Field
                          component={CheckboxWithLabel}
                          type="checkbox"
                          name="consumable"
                          Label={{ label: 'Consumable' }}
                          disabled={isSubmitting || readOnly}
                        />
                      </Grid>
                      {values.consumable && (<>
                        <Grid item xs={12}>
                          <Field
                            component={TextField}
                            name="consumableUsageCount"
                            type="string"
                            label="Use count"
                            fullWidth
                            InputProps={{ readOnly }}
                          />
                        </Grid>
                        <Grid item xs={8}>
                          <Field
                            component={TextField}
                            name="consumableUsagePeriod"
                            type="string"
                            label="Use period"
                            fullWidth
                            InputProps={{ readOnly }}
                          />
                        </Grid>
                        <Grid item xs={4}>
                          <FormControl fullWidth>
                            <InputLabel> </InputLabel>
                            <Field
                              component={Select}
                              name="consumableUsagePeriodUnit"
                              inputProps={{ readOnly }}
                            >
                              {this.renderTimeUnits(parseInt(values.consumableUsagePeriod) !== 1)}
                            </Field>
                          </FormControl>
                        </Grid>
                      </>)}
                    </Grid>
                  </CardContent>
                </Card>
                <Box mt={2}>
                  <Card>
                    <CardHeader title="Starter Pack" />
                    <CardContent>
                      <Grid container spacing={1}>
                        <Grid>
                          <Field
                            component={CheckboxWithLabel}
                            type="checkbox"
                            name="starterPack"
                            Label={{ label: 'Starter Pack' }}
                            disabled={isSubmitting || readOnly}
                          />
                        </Grid>
                      </Grid>
                    </CardContent>
                  </Card>
                </Box>
              </Grid>
              {UserService.canRead('itemNotes') &&
                <Grid item xs={12}>
                  {this.props.item?.itemId &&
                    <EntityNotes<ItemNote>
                      entityId={this.props.item.itemId}
                      permissionEntityType='itemNotes'
                      title='Item notes'
                      entityIdField='itemId'
                      noteField='note'
                      authorField='authorEmail'
                      dateField='createdAt'
                      getNotes={getItemNotes}
                      createNote={itemNote => {
                        itemNote.authorEmail = this.props.userEmail;
                        return createItemNote(itemNote);
                      }}
                      updateNote={itemNote => {
                        delete itemNote.updatedAt;
                        delete itemNote.createdAt;
                        return updateItemNote(itemNote);
                      }}
                      deleteNote={itemNote => deleteItemNote(itemNote.id as string)}
                    />}
                </Grid>}
              {(values.bundle || values.container) && (<>
                <Grid item xs={12} md={6} xl={4}>
                  <Card>
                    <CardHeader title="Currency Contents" />
                    <CardContent>
                      <Grid container spacing={1}>
                        {values.currencyContents.map((item, index) => (
                          <Grid key={item.code} item xs={12}>
                            <Field
                              component={TextField}
                              name={`currencyContents[${index}].amount`}
                              type="text"
                              label={item.name}
                              fullWidth
                              InputProps={{ readOnly }}
                            />
                          </Grid>
                        ))}
                      </Grid>
                    </CardContent>
                  </Card>
                </Grid>
                <Grid item xs={12} xl={8}>
                  <Card>
                    <Box p={2}>
                      <Grid container spacing={2} alignItems="center">
                        <Grid item>
                          <Typography variant="h5">
                            Contents
                          </Typography>
                        </Grid>
                        <Grid item xs>
                          {typeof errors.contents === 'string' && (
                            <Tooltip title={errors.contents} placement="top">
                              <WarningIcon style={{ display: 'block' }} color="error" />
                            </Tooltip>
                          )}
                        </Grid>
                        {!readOnly && (
                          <Grid item>
                            <Button
                              variant="contained"
                              color="primary"
                              disabled={isSubmitting}
                              onClick={() => this.setState({ contentsDialogOpen: true })}
                            >
                              Add
                            </Button>
                          </Grid>
                        )}
                      </Grid>
                    </Box>
                    <CardContent>
                      <DndProvider backend={HTML5Backend}>
                        <FieldArray name="contents" render={arrayHelpers => (<>
                          <ItemContentsForm contents={values.contents}  moveItem={(drag: number, hover: number) => this.onMoveItem(arrayHelpers, drag, hover)} onRemove={(id: string, type: string) => this.onContentsRemove(arrayHelpers, id, type)} readOnly={readOnly} />
                          {this.state.contentsDialogOpen && (
                            <ItemSelectDialog
                              multi
                              includeDropTables
                              containerId={this.props.item ? this.props.item.itemId : undefined}
                              addedItems={values.contents.filter(v => v.type === 'item').map(v => v.id)}
                              addedDropTables={values.contents.filter(v => v.type === 'droptable').map(v => v.id)}
                              onSelect={items => this.onContentsAdd(arrayHelpers, items)}
                              onClose={() => this.setState({ contentsDialogOpen: false })}
                            />)}
                        </>)} />
                      </DndProvider>
                    </CardContent>
                  </Card>
                </Grid>
              </>)}
              <Grid item xs={12}>
                <Card>
                  <CardHeader title={(
                    <Grid container spacing={2} alignItems="center">
                      <Grid item>
                        Custom Data
                      </Grid>
                      {!this.state.customDataValid && (
                        <Grid item>
                          <Tooltip title="Invalid JSON" placement="top">
                            <WarningIcon style={{ display: 'block' }} color="error" />
                          </Tooltip>
                        </Grid>
                      )}
                    </Grid>
                  )} />
                  <CardContent>
                    <AceEditor
                      mode="json"
                      theme={this.props.darkMode ? 'dracula' : 'chrome'}
                      value={this.customData}
                      onChange={this.onCustomDataChange}
                      style={{
                        width: '100%',
                        height: 300
                      }}
                      setOptions={{
                        useWorker: true
                      }}
                      onValidate={this.onCustomDataValidate}
                      readOnly={readOnly}
                    />
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
            {!readOnly && (
              <Box py={2}>
                <Button
                  variant="contained"
                  color="primary"
                  disabled={!this.state.customDataValid || isSubmitting}
                  onClick={submitForm}
                >
                  {isSubmitting ? (
                    <CircularProgress size={25} />
                  ) : 'Save'}
                </Button>
              </Box>
            )}
          </Form>
        )}
      </Formik>
    </>);
  }
}

export default connector(ItemDefinitionForm);

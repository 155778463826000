import {useCallback, useMemo, useState} from "react";
import {Localization, Options, Query, QueryResult} from "material-table";
import {PaginatedRequest, paginatedRequestFromQuery, PaginatedResponse} from "../services/model/pagination";
import {getCurrentPageFromURL, resetHistoryPage} from "./paging";
import {useHistory, useLocation} from "react-router";
import MaterialTable from "../components/MaterialTable";
import {Player} from "../features/players/players";

export const MaterialTableCallback = (props: {
  filters: string[],
  tableRef: any,
  options: Options,
  localization?: Localization,
  columns: any,
  menuActions?: any,
  title: string,
  player?: Player,
  storageId?: string,
  blockId?: string,
  data: (r: PaginatedRequest) => Promise<PaginatedResponse<any>>
}) => {

  const location = useLocation();
  const history = useHistory();
  const [pageLoaded, setPageLoaded] = useState<boolean>(false);
  const [updateIncoming, setUpdateIncoming] = useState<boolean>(false);
  const [filters, setFilters] = useState<string[]>(props.filters);

  const getMaterialTableData = useCallback(async (query: Query<any>): Promise<QueryResult<any>> => {
    return new Promise(resolve => {

      if (!pageLoaded){
        setPageLoaded(true);
        query.page = getCurrentPageFromURL(location.search);
      }

      if (updateIncoming){
        if (props.filters.length != filters.length){
          setFilters(props.filters);
          query.page = 0;
          setUpdateIncoming(false);
        }
      }

      const request = paginatedRequestFromQuery(query);
      request.search = query.search.trim();
      request.name = request.search;

      if (props.player){
        request.playerId = props.player.externalId;
      }

      if (props.blockId){
        request.blockId = props.blockId;
      }

      if (props.filters.length > 0){
        for (let i = 0; i < props.filters.length; i++){
          request[props.filters[i]] = true;
        }
        resetHistoryPage(location.search, history);
      }

      const getData = props.data(request);
      getData.then(result => {
        resolve({
          data: result.items,
          page: request.page - 1,
          totalCount: result.totalCount
        });
      });
    });
  }, [props, pageLoaded, history, location.search, updateIncoming, filters]);

  useMemo(() => {
    if (props.tableRef.current) {
      if (props.filters){
        setUpdateIncoming(true);
      }
      (props.tableRef.current as any).onQueryChange();
    }
  }, [props.tableRef, props.filters]);

  return (<>
    <MaterialTable
      title={props.title}
      tableRef={props.tableRef}
      columns={props.columns}
      data={getMaterialTableData}
      options={props.options}
      localization={props.localization}
      menuActions={props.menuActions}
      storageId={props.storageId}
      useRouter
    />
  </>)
}
import { Box, Button, Checkbox, CircularProgress, FormControlLabel, Grid, Typography } from '@material-ui/core';
import { format } from 'date-fns';
import type { FormikHelpers } from 'formik';
import { Field, Formik } from 'formik';
import { TextField } from 'formik-material-ui';
import { useEffect, useState } from 'react';
import config from '../../../config';
import { MythicalAccount } from '../../../services/model/mythical-account';
import { MythicalAccountsService } from '../../../services/mythical-accounts';
import { UserService } from '../../../services/user';
import { createValidator, Validators } from '../../../utils/forms';
import { pushAppNotification } from "../../../shared/hooks/useAppNotification";
import { openAdminConfirmationDialog } from "../../../shared/hooks/useAdminConfirmationDialog";

interface FormValues {
  firstName: string;
  lastName: string;
  displayName: string;
  email: string;
  dob: string;
  locale: string;
}

const formValidator = createValidator<FormValues>({
  firstName: Validators.notBlank(),
  lastName: Validators.notBlank(),
  email: Validators.email(),
  displayName: Validators.notBlank()
});

type Props = {
  accountId: string;
  displayName?: string;
}

const accountToFormValues = (account: MythicalAccount): FormValues => {
  return {
    firstName: account.firstName,
    lastName: account.lastName,
    email: account.email,
    displayName: account.displayName,
    dob: account.attributes.birthdate || '',
    locale: account.attributes.locale || ''
  };
};

export const MythicalAccountForm = ({accountId, displayName}: Props) => {
  const [isSendingVerificationEmail, setIsSendingVerificationEmail] = useState(false);
  const [verificationEmailSent, setVerificationEmailSent] = useState(false);
  const [isSendingPasswordResetEmail, setIsSendingPasswordResetEmail] = useState(false);
  const [passwordResetEmailSent, setPasswordResetEmailSent] = useState(false);
  const [initialFormValues, setInitialFormValues] = useState<FormValues | undefined>();
  const [account, setAccount] = useState<MythicalAccount | undefined>();
  const [accountFetchError, setAccountFetchError] = useState<Error | undefined>();
  useEffect(() => {
    MythicalAccountsService.getMythicalAccount(accountId)
      .then(mythAcct => {
        const mergedAcct = {...mythAcct, displayName: displayName || ''};
        setAccount(mergedAcct);
        setInitialFormValues(accountToFormValues(mergedAcct));
      })
      .catch(err => {
        setAccountFetchError(err);
        pushAppNotification({type: 'error', message: `Failed to get mythical account: ${err.message}`});
      });
  }, [accountId, displayName]);

  const sendVerificationEmail = () => {
    if (account) {
      openAdminConfirmationDialog({
        title: `Send verification email to ${account.email}?`, action: 'Send email',
        onConfirm: () => {
          setIsSendingVerificationEmail(true);
          MythicalAccountsService.sendVerificationEmail(account)
            .then(() => setVerificationEmailSent(true))
            .catch(err => pushAppNotification({
              type: 'error',
              message: `Failed to send verification email: ${err.message}`
            }))
            .then(() => setIsSendingVerificationEmail(false));
        }
      });
    }
  };

  const sendPasswordResetEmail = () => {
    if (account) {
      openAdminConfirmationDialog({
        title: `Send password reset email to ${account.email}?`, action: 'Send email',
        onConfirm: () => {
          setIsSendingPasswordResetEmail(true);
          MythicalAccountsService.sendPasswordResetEmail(account)
            .then(() => setPasswordResetEmailSent(true))
            .catch(err => pushAppNotification({
              type: 'error',
              message: `Failed to send password reset email: ${err.message}`
            }))
            .then(() => setIsSendingPasswordResetEmail(false));
        }
      });
    }
  };

  const formatTosDate = (timestamp: string): string => {
    let formattedDate = '';
    if (timestamp) {
      const date = new Date(timestamp);
      formattedDate = format(date, 'yyyy/MM/dd HH:mm:ss');
    }
    return formattedDate ? ` (${formattedDate})` : '';
  };

  const onSubmit = (values: FormValues, helpers: FormikHelpers<FormValues>) => {
    if (account) {
      const updatedAccount = new MythicalAccount({
        id: account.id,
        displayName: values.displayName.trim(),
        firstName: values.firstName.trim(),
        lastName: values.lastName.trim(),
        email: values.email.trim()
      });

      MythicalAccountsService.updateMythicalAccount(updatedAccount)
        .then(() => pushAppNotification({ type: 'success', message: 'Account updated' }))
        .catch(err => pushAppNotification({ type: 'error', message: `Failed to update account: ${err.message}` }))
        .finally(() =>  helpers.setSubmitting(false));
    }
  };

  if (!account || !initialFormValues) {
    if (accountFetchError) {
      return (
        <Box py={2}>
          <Typography color="error">
            {(accountFetchError as any).code === 40400 ? 'Mythical account not found. This may indicate that the player' +
              ' has not yet logged into the launcher or game.' : accountFetchError.message}
          </Typography>
        </Box>
      );
    }
    return (
      <Grid item xs={12}>
        <Box p={2} textAlign="center">
          <CircularProgress/>
        </Box>
      </Grid>
    );
  }
  const readOnly = !UserService.canUpdate('mythicalAccount');
  return (
    <Formik<FormValues>
      initialValues={initialFormValues}
      enableReinitialize
      validate={formValidator}
      onSubmit={onSubmit}
    >
      {form => (
        <Grid container spacing={1}>
          <Grid container item xs={12} spacing={1}>
            <Grid item xs={12}>
              <Field
                name="displayName"
                component={TextField}
                label="Display Name"
                fullWidth
                InputProps={{readOnly}}>
              </Field>
            </Grid>
            <Grid item xs={6}>
              <Field
                name="firstName"
                component={TextField}
                label="First name"
                fullWidth
                InputProps={{readOnly}}
              />
            </Grid>
            <Grid item xs={6}>
              <Field
                name="lastName"
                component={TextField}
                label="Last name"
                fullWidth
                InputProps={{readOnly}}
              />
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Field
              name="email"
              component={TextField}
              label="Email"
              fullWidth
              InputProps={{readOnly}}
            />
          </Grid>
          <Grid item xs={6}>
            <Field
              name="dob"
              component={TextField}
              label="Date of birth"
              fullWidth
              InputProps={{readOnly: true}}
            />
          </Grid>
          <Grid item xs={6}>
            <Field
              name="locale"
              component={TextField}
              label="Country"
              fullWidth
              InputProps={{readOnly: true}}
            />
          </Grid>
          <Grid container item xs={12} spacing={1} alignItems="center">
            <Grid item xs={account.emailVerified || readOnly ? 12 : true}>
              <FormControlLabel
                control={<Checkbox checked={account.emailVerified}/>}
                label="Email verified"
              />
            </Grid>
            {!account.emailVerified && !readOnly && config.apis.mythical.authProvider !== 'fusionauth' && (
              <Grid item xs="auto">
                {isSendingVerificationEmail ? (
                  <CircularProgress size={30}/>
                ) : verificationEmailSent ? (
                  <Typography color="secondary">Verification email sent</Typography>
                ) : (
                  <Button variant="contained" color="primary" onClick={sendVerificationEmail}>
                    Send verification email
                  </Button>
                )}
              </Grid>
            )}
          </Grid>
          {account.emailVerified && !readOnly && config.apis.mythical.authProvider !== 'fusionauth' && (
            <Grid container item xs={12} spacing={1} alignItems="center">
              <Grid item xs>
                <Typography variant="subtitle1">
                  Password reset
                </Typography>
              </Grid>
              <Grid item xs="auto">
                {isSendingPasswordResetEmail ? (
                  <CircularProgress size={30}/>
                ) : passwordResetEmailSent ? (
                  <Typography color="secondary">Reset password email sent</Typography>
                ) : (
                  <Button variant="contained" color="primary" onClick={sendPasswordResetEmail}>
                    Send password reset email
                  </Button>
                )}
              </Grid>
            </Grid>
          )}
          <Grid item xs={12}>
            <FormControlLabel
              control={<Checkbox checked={!!account.attributes.mythical_tos_agreement_date}/>}
              label={`Mythical ToS${formatTosDate(account.attributes.mythical_tos_agreement_date)}`}
            />
          </Grid>
          {/* Figure out what the Blankos ToS is, and if it's important.
             So far, the only reference is in alpha player pre-reg finalize */}
          {/* <Grid item xs={12}>
            <FormControlLabel
              control={<Checkbox checked={!!account.attributes.bp_tos_agreement_date} />}
              label={'Blankos ToS' + this.formatTosDate(account.attributes.bp_tos_agreement_date)}
            />
          </Grid> */}
          {!readOnly && (
            <Grid item xs={12}>
              <Box mt={2}>
                <Button
                  variant="contained"
                  color="primary"
                  disabled={form.isSubmitting}
                  onClick={form.submitForm}
                >
                  {form.isSubmitting ? (
                    <CircularProgress size={25}/>
                  ) : 'Save'}
                </Button>
              </Box>
            </Grid>
          )}
        </Grid>
      )}
    </Formik>
  );

};

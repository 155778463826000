import { api } from './api';

export interface UserPermissionsMap {
  [key: string]: { [key: string]: boolean }
}

export class ToolshedUser {
  private permissions: UserPermissionsMap = {};

  constructor(json: any) {
    for (const key in json) {
      const permissionsMap: { [key: string]: boolean } = {};
      json[key].forEach((p: string) => permissionsMap[p] = true);
      this.permissions[key] = permissionsMap;
    }
  }

  hasAnyPermissions(): boolean {
    return Object.keys(this.permissions).length > 0;
  }

  hasPermission(target: string, permission: string): boolean {
    return this.permissions[target] && this.permissions[target][permission];
  }

  hasPermissions(permissionsMap: { [key: string]: string | string[] }) {
    for (const key in permissionsMap) {
      const map = this.permissions[key];
      if (!map) {
        return false;
      }

      const mapValue = permissionsMap[key];
      const permissions = Array.isArray(mapValue) ? mapValue : [mapValue];
      for (const permission of permissions) {
        if (!map[permission]) {
          return false;
        }
      }
    }

    return true;
  }

  canRead(target: string): boolean {
    return this.hasPermission(target, 'Read');
  }

  canCreate(target: string): boolean {
    return this.hasPermission(target, 'Create');
  }

  canUpdate(target: string): boolean {
    return this.hasPermission(target, 'Update');
  }

  canDelete(target: string): boolean {
    return this.hasPermission(target, 'Delete');
  }
}

export class UserService {
  private static user = new ToolshedUser({});

  static async getPermissions(): Promise<any> {
    return api.get({ url: '/user' }).then(json => {
      UserService.user = new ToolshedUser(json);
      return json;
    });
  }

  static getUser(): ToolshedUser {
    return UserService.user;
  }

  static canRead(target: string): boolean {
    return UserService.user.canRead(target);
  }

  static canCreate(target: string): boolean {
    return UserService.user.canCreate(target);
  }

  static canUpdate(target: string): boolean {
    return UserService.user.canUpdate(target);
  }

  static canDelete(target: string): boolean {
    return UserService.user.canDelete(target);
  }

  static canGrantItems(): boolean {
    return UserService.canCreate('itemInstances') && UserService.canRead('catalogs') && UserService.canRead('catalogItems');
  }

  static canMigrateFrom(): boolean {
    return UserService.user.hasPermissions({
      dna: 'Read',
      catalogs: 'Read',
      catalogItems: 'Read',
      store: 'Read',
      title: 'Read'
    });
  }
}
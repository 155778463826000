import { CatalogMigrationService } from '../../../../services/catalog-migration';
import type { MigrationModule } from '../../migrations';
import { buildDiffData, buildMigrationActions } from '../../migrations';
import { BrawlPinDiffs } from "./BrawlPinDiffs";
import _ from "lodash";
import type { Dictionary } from "lodash";
import { BrawlPinDiff } from "./brawlPinDiff";
import type { BrawlPin } from '../../../../services/model/brawl-pin';
import { MigrationType } from "../migration-type";

export const brawlPinMigration: MigrationModule<BrawlPinDiff> = {
  id: MigrationType.brawlPins,
  displayName: 'Brawl Pins',
  diffComponent: BrawlPinDiffs,
  crossEnvOnly: true,

  loadData: async (sourceData, targetData, migrationData) => {
    sourceData.brawlPins = await CatalogMigrationService.getBrawlPins(sourceData.env);
    targetData.brawlPins = await CatalogMigrationService.getBrawlPins(targetData.env);

    migrationData.brawlPins = buildDiffData(
      sourceData.brawlPins.map(brawlPin => new BrawlPinDiff(brawlPin)),
      targetData.brawlPins.map(brawlPin => new BrawlPinDiff(brawlPin))
    );
    return migrationData.brawlPins;
  },

  runMigration: async (props, setStatus) => {
    const actions = buildMigrationActions(props.migrationData.brawlPins, props.selections.brawlPins);
    if (actions.add.length + actions.update.length + actions.remove.length < 1) {
      return;
    }

    const mapPinsByName = (pins?: BrawlPin[]): Dictionary<BrawlPin> => pins && _.mapValues(_.groupBy(pins, 'name'), result => result[0]) || {};
    const sourcePinsByName = mapPinsByName(props.sourceData.brawlPins);
    const targetPinsByName = mapPinsByName(props.targetData.brawlPins);

    const pins: BrawlPin[] = actions.add
      .map(name => {
        const pin = sourcePinsByName[name];
        if (!pin) {
          throw new Error(`Pin to create with name ${name} not found in source pins`);
        }
        const clone = _.clone(pin);
        // Since the IDs will be different across environments, we'll clear it here
        // so the new environment will know it's a new pin and set the ID.
        clone.id = '';
        return clone;
      }).concat(
        actions.update
          .map(name => {
            const targetId = targetPinsByName[name]?.id;
            if (!targetId) {
              throw new Error(`Pin to update with name ${name} not found in target pins`);
            }
            const sourcePin = sourcePinsByName[name];
            if (!sourcePin) {
              throw new Error(`Pin to update with name ${name} not found in source pins`);
            }
            const clone = _.clone(sourcePin);
            // We're replacing the existing pin with the updated pin,
            // so give the source pin the ID of the target pin.
            clone.id = targetId;
            return clone;
          })
      );

    let progress = 0;
    for (const pin of pins) {
      setStatus('Migrating Pins', progress);
      await CatalogMigrationService.migrateBrawlPin(props.targetData.env, pin);
      progress += 100 / pins.length;
    }
  }
};

import _ from "lodash";

export interface PermissionModel {
  target: string;
  permissions: Permission[];
}

export interface Role {
  id?: string;
  name: string;
  apiLevel: ApiLevel | '';
  permissions: PermissionModel[];
}

export interface RolesResponse {
  roles: Role[];
}

export interface UserRoles {
  mythicalId: string;
  roles: string[];
}

export enum ApiLevel {
  Client = "Client",
  Server = "Server",
  Admin = "Admin",
}

export enum Permission {
  Read = "Read",
  Create = "Create",
  Update = "Update",
  Delete = "Delete",
  Override = "Override",
}

export const normalizeRoles = (roles: Role[]): Role[] => {
  const normalized = _.orderBy(roles, ['name']);
  normalized
    .forEach(role => {
      normalizeRole(role);
    });
  return normalized;
};

export const normalizeRole = (role: Role) => {
  role.permissions = _.orderBy(role.permissions, ['target']);
  role.permissions
    .forEach(permission => {
      permission.permissions.sort();
    })
};

import { Button, Dialog, DialogActions, DialogContent } from "@material-ui/core";
import { useCallback, useState } from "react";
import { useDispatch } from "react-redux";
import type { ShopTemplate } from "../shopTemplate";
import { ShopTemplateEditor } from "./ShopTemplateEditor";

export interface ShopTemplateDialogProps {
  onClose: () => void;
  template?: ShopTemplate;
  updateTemplate: (template: ShopTemplate) => void;
}

export const ShopTemplateDialog = (props: ShopTemplateDialogProps) => {
  const dispatch = useDispatch();
  const { onClose, template, updateTemplate } = props;
  const [isOpen, setIsOpen] = useState(true);
  const [editTemplate, setEditTemplate] = useState<ShopTemplate | undefined>(props.template);

  const onSave = useCallback(async (template: ShopTemplate) => {
    setEditTemplate(template);
    updateTemplate(template);
    setIsOpen(false);
  },[setEditTemplate, setIsOpen, updateTemplate]);

  return (
    <Dialog
      open={isOpen}
      onClose={() => setIsOpen(false)}
      onExited={props.onClose}
      fullWidth
      maxWidth="sm"
    >
      <DialogContent style={{ padding: 0 }}>
        <ShopTemplateEditor
          template={editTemplate}
          onSave={(values) => onSave(values)}
          onCancel={() => setIsOpen(false)}
        />
      </DialogContent>
    </Dialog>
  );
}
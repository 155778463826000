import { Button, Card, CardContent, CardHeader, Table, TableBody, TableHead, TableCell, TableRow, IconButton, Typography } from '@material-ui/core';
import { useState , useCallback } from 'react';

import type { DropTable } from '../../services/drop-tables';
import { ItemDefinition } from '../../services/item-definitions';
import ItemSelectDialog from '../ItemDefinition/ItemSelectDialog';
import DeleteIcon from '@material-ui/icons/Delete';
import { FastField } from 'formik';
import { TextField } from 'formik-material-ui';

export interface LevelItemGrant {
  catalogName: string;
  itemId: string;
  quantity: number;
}

interface Props {
  formName: string;
  items: LevelItemGrant[];
  title: string;
  enableQuantity?: boolean;
  enableCatalogName?: boolean;
  onAdd: (item: LevelItemGrant) => void;
  onRemove: (index: number) => void;
}

export const BlankoLevelItemGrantsForm = (props: Props) => {
  const { items, onAdd, onRemove } = props;

  const [addItemDialogOpen, setAddItemDialogOpen] = useState(false);

  const onItemsSelected = useCallback((items: (ItemDefinition | DropTable)[]) => {
    items.forEach(item => {
      if (item instanceof ItemDefinition) {
        onAdd({ catalogName: item.catalogName, itemId: item.itemId, quantity: 1 });
      }
    });
  }, [onAdd]);

  return (<>
    <Card variant="outlined">
      <CardHeader
        title={props.title}
        action={
          <Button color="primary" variant="contained" onClick={() => setAddItemDialogOpen(true)}>
            Add
          </Button>
        }
      />
      <CardContent>
        {items.length > 0 && (
          <Table size="small">
            <TableHead>
              <TableRow>
                {<TableCell> </TableCell>}
                {props.enableQuantity && <TableCell>Quantity</TableCell>}
                {props.enableCatalogName && <TableCell>Catalog</TableCell>}
                <TableCell>ID</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {items.map((item, index) => (
                <TableRow key={item.itemId}>
                  <TableCell padding="none" style={{ width: 1 }}>
                    <IconButton onClick={() => onRemove(index)}>
                      <DeleteIcon />
                    </IconButton>
                  </TableCell>
                  {props.enableQuantity && (
                    <TableCell style={{ width: 100 }}>
                      <FastField
                        component={TextField}
                        name={`${props.formName}[${index}].quantity`}
                        type="text"
                        label=""
                        fullWidth
                      />
                    </TableCell>
                  )}
                  {props.enableCatalogName && <TableCell style={{ width: '1px', whiteSpace: 'nowrap' }}>{item.catalogName}</TableCell>}
                  <TableCell>{item.itemId}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        )}

        {items.length < 1 && (
          <Typography color="textSecondary">
            No items added yet.
          </Typography>
        )}
      </CardContent>
    </Card>

    {addItemDialogOpen && (
      <ItemSelectDialog
        multi
        showCatalogSelect
        addedItems={items.map(item => item.itemId)}
        onSelect={onItemsSelected}
        onClose={() => setAddItemDialogOpen(false)}
      />
    )}
  </>);
};
import {createReducer} from 'typesafe-actions';
import type { TablePageState} from '../utils-ts';
import {arrayToMap, defaultTablePageState} from '../utils-ts';
import {
  getAllTransactionItemsAsync,
  getAllTransactionsAsync,
  invalidateTransactionsPage,
  refundTransactionAsync
} from './actions';
import type {TransactionAggregate, TransactionItem} from "../../services/transactions";
import type { Reducer } from 'redux';

export interface TransactionsState {
  byId: { [key: string]: TransactionAggregate; };
  page: TablePageState<string, TransactionAggregate>;
  itemsPage: TablePageState<string, TransactionItem>;
  itemsById: { [key: string]: TransactionItem; };
}

const initialState: TransactionsState = {
  byId: {},
  page: defaultTablePageState(),
  itemsPage: defaultTablePageState(),
  itemsById: {}
};

const transactionsReducer: Reducer<TransactionsState> = createReducer<TransactionsState>(initialState)
  .handleAction(getAllTransactionsAsync.request, (state, action) => ({
    ...state,
    page: {
      ...state.page,
      isLoading: true,
      invalidated: false,
      page: action.payload.page
    }
  }))
  .handleAction(getAllTransactionsAsync.success, (state, action) => ({
    ...state,
    isLoading: false,
    byId: arrayToMap(action.payload.items, v => v.id),
    page: {
      ...state.page,
      isLoading: false,
      ids: action.payload.items.map(v => v.id),
      totalCount: action.payload.totalCount
    }
  }))
  .handleAction(getAllTransactionsAsync.failure, state => ({
    ...state,
    page: {
      ...state.page,
      isLoading: false
    }
  }))
  .handleAction(getAllTransactionItemsAsync.request, (state, action) => ({
    ...state,
    itemsPage: {
      ...state.itemsPage,
      isLoading: true,
      invalidated: false,
      page: action.payload.page
    }
  }))
  .handleAction(getAllTransactionItemsAsync.success, (state, action) =>
    ({
      ...state,
      itemsById: arrayToMap(action.payload.items, v => v.id),
      itemsPage: {
        ...state.itemsPage,
        isLoading: false,
        ids: action.payload.items.map(v => v.id),
        totalCount: action.payload.totalCount
      }
    }))
  .handleAction(getAllTransactionItemsAsync.failure, state =>
    ({
      ...state,
      itemsPage: {
        ...state.itemsPage,
        isLoading: false
      }
    }))
  .handleAction(invalidateTransactionsPage, state => ({
    ...state,
    page: {
      ...state.page,
      invalidated: true
    }
  }))
  .handleAction(refundTransactionAsync.request, (state) => ({
    ...state,
    page: {
      ...state.page,
      isLoading: true,
      invalidated: false
    }
  }))
  .handleAction(refundTransactionAsync.success, (state) => ({
    ...state,
    page: {
      ...state.page,
      isLoading: false
    }
  }))
  .handleAction(refundTransactionAsync.failure, (state) => ({
    ...state,
    page: {
      ...state.page,
      isLoading: false
    }
  }));

export default transactionsReducer;

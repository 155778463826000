import { Accordion, AccordionDetails, AccordionSummary, Box, Card, CardContent, Grid } from "@material-ui/core";
import { useCallback, useMemo, useState } from "react";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { JunctionEventInfo } from "../../../live-events/live-events-types";
import { seasonalDataComparer } from "../seasonal-data/seasonal-data-comparer";
import { LiveEventsDiffViewProps } from "../live-event/LiveEventsDiffView";
import ReactDiffViewer, { DiffMethod } from 'react-diff-viewer';

export interface JunctionEventInfoDiffViewProps extends LiveEventsDiffViewProps {
  darkMode: boolean;
}

export const JunctionEventInfoDiffView = (props: JunctionEventInfoDiffViewProps) => {
  const { beforeChanges, afterChanges, previous, current, darkMode } = props;
  const classes = seasonalDataComparer.getStyles();

  const [junctionsExpanded, setJunctionsExpanded] = useState(false);

  const showJunctionEventInfosHeader = useMemo(() => {
    return beforeChanges?.junctionEventInfos || afterChanges.junctionEventInfos
  }, [beforeChanges, afterChanges]);

  const renderJunctionEventInfos = useCallback((currentJunctions: JunctionEventInfo[], previousJunctions?: JunctionEventInfo[]) => {
    const safeBefore = previousJunctions ? previousJunctions : [] as JunctionEventInfo[];

    return (
      <ReactDiffViewer
        oldValue={JSON.stringify(previousJunctions, null, 2)}
        newValue={JSON.stringify(currentJunctions, null, 2)}
        splitView
        useDarkTheme={darkMode}
        showDiffOnly
        compareMethod={DiffMethod.LINES}
        styles={{
          variables: {
            dark: {
              codeFoldContentColor: '#fff'
            }
          }
        }}
      />
    );

  }, [darkMode]);

  return (
    <>
      {showJunctionEventInfosHeader && (
        <Accordion
          expanded={junctionsExpanded}
          TransitionProps={{ unmountOnExit: true }}
          onChange={() => setJunctionsExpanded(!junctionsExpanded)}
          className={classes.accordianSummary}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            className={classes.accordianSummary}
          >
            <Grid container alignItems="center" spacing={2}>
              <Grid item xs={12} className={classes.accordionTitle}>
                Junction Events
              </Grid>
            </Grid>
          </AccordionSummary>
          <AccordionDetails style={{ padding: 0 }}>
            <Box width="100%">
              {renderJunctionEventInfos(current.junctionEventInfos, previous?.junctionEventInfos)}
            </Box>
          </AccordionDetails>
        </Accordion>
      )}
    </>
  );
};
import { Component } from 'react';
import { TextField } from "@material-ui/core";

interface PriceFieldProps {
  value: string;
  onChange: (value: string) => void;
  integer?: boolean;
  readOnly?: boolean;
}

interface PriceFieldState {
  value: string;
  error: string;
}

export default class PriceField extends Component<PriceFieldProps, PriceFieldState> {
  constructor(props: PriceFieldProps) {
    super(props);
    this.state = {
      value: props.value,
      error: this.validate(props.value)
    };
  }

  componentDidUpdate(prevProps: PriceFieldProps) {
    if (prevProps.value !== this.props.value) {
      this.setState({
        value: this.props.value,
        error: this.validate(this.props.value)
      })
    }
  }

  onChange = (event: any) => {
    const value = (event.target.value as string).trim();
    this.setState({ value, error: this.validate(value) });
    this.props.onChange(value);
  };

  validate(value: string): string {
    if (value) {
      const price = parseFloat(value);
      if (isNaN(price) || price < 0) {
        return 'Must be a positive number';
      }
      if (this.props.integer && !Number.isInteger(price)) {
        return 'Must be an integer';
      }
    }
    return '';
  }

  render() {
    return (
      <TextField
        type="text"
        label=""
        fullWidth
        inputProps={{
          autoComplete: 'off'
        }}
        value={this.state.value}
        onChange={this.onChange}
        error={!!this.state.error}
        helperText={this.state.error}
        InputProps={{ readOnly: this.props.readOnly }}
      />
    );
  }
}
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, InputLabel, makeStyles, TextField, Theme } from '@material-ui/core';
import { ChangeEvent, useMemo , useCallback, useState } from 'react';
import { ShopTab, ShopTabLinkImage } from '../shop';
import { createShopTab, updateShopTab } from '../shopsApi';
import _ from 'lodash';
import { usePushNotification } from '../../../contexts/AppNotificationContext';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { LinkImagesEditor } from './LinkImagesEditor';

const useStyles = makeStyles((theme: Theme) => ({
  linkImagesTitle: {
    marginTop: 20,
    marginBottom: 4,
    width: '100%'
  }
}));

export interface ShopTabEditDialog {
  shopTab: ShopTab | null;
  shopVersionId: string;
  onChange: (tab: ShopTab) => void;
  onClose: () => void;
}

export const ShopTabEditDialog = (props: ShopTabEditDialog) => {
  const classes = useStyles();
  const pushNotification = usePushNotification();
  const { shopTab, shopVersionId, onChange, onClose } = props;
  const [open, setOpen] = useState(true);
  const [name, setName] = useState(props.shopTab?.name || '');
  const [linkImages, setLinkImages] = useState<ShopTabLinkImage[] | undefined>(props.shopTab ? _.cloneDeep(props.shopTab.linkImages) : []);
  const [nameError, setNameError] = useState('');
  const isCreating = useMemo(() => {
    return props.shopTab === null;
  }, [props]);

  const onNameChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    const newName = e.target.value;
    setName(newName);
    setNameError(newName.trim() === '' ? 'A name is required' : '');
  }, []);

  const onImagesChange = useCallback((linkImages: ShopTabLinkImage[]) => {
    setLinkImages(linkImages);
  }, []);

  const onSubmit = useCallback(() => {
    const shopTab: ShopTab = {
      id: props.shopTab?.id || '',
      items: [],
      linkImages,
      name,
      position: props.shopTab?.position || 0,
      shopVersionId,
    };

    if (isCreating) {
      createShopTab(shopTab)
        .then(createdTab => {
          onChange(createdTab);
          onClose()
        })
        .catch(err => pushNotification({ type: 'error', message: `Failed to create tab: ${err.message}` }));
    } else {
      updateShopTab(shopTab)
        .then(updatedTab => {
          onChange(updatedTab);
          onClose()
        })
        .catch(err => pushNotification({ type: 'error', message: `Failed to update tab: ${err.message}` }));
    }
  }, [isCreating, linkImages, name, props, shopVersionId, onChange, onClose, pushNotification]);

  return (
    <Dialog
      open={open}
      onClose={() => setOpen(false)}
      onExited={props.onClose}
      fullWidth
      maxWidth="md"
    >
      <DialogTitle>{isCreating ? 'Add' : 'Update'} Shop Tab</DialogTitle>
      <DialogContent>
        <TextField
          label="Name"
          fullWidth
          error={Boolean(nameError)}
          helperText={nameError}
          value={name}
          onChange={onNameChange}
        />
        <InputLabel className={classes.linkImagesTitle}>
          Link Images
        </InputLabel>
        <Box p={1}>
          <Grid container spacing={1}>
            <Box pt={1}>
              <DndProvider backend={HTML5Backend}>
                {shopTab &&
                  <LinkImagesEditor
                    shopTab={shopTab}
                    onChange={images => onImagesChange(images)}
                  />
                }
              </DndProvider>
            </Box>
          </Grid>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button
          color="primary"
          variant="contained"
          onClick={onSubmit}
          disabled={name === '' || Boolean(nameError)}
        >
          {isCreating ? 'Add' : 'Save'}
        </Button>
        <Button onClick={onClose} color="primary">
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};

import type { ComponentType } from 'react';
import type {
  BlankoAssetDiff,
  BlankoClassesDiff,
  BlankoDnaDiff,
  BlankoProgressionsDiff,
  CatalogItemDiff,
  DiffItem,
  DropTableDiff,
  GemRushDiff,
  GeoBlockingDiff,
  HiddenItem,
  SeasonalDataDiff,
  StoreDiff,
  TextLanguageDiff,
  TitleDataDiff,
  TitleFileDiff,
  TranslationDiff
} from '../../pages/CatalogMigration/types';
import type { Catalog } from '../../services/catalogs';
import type { BlankoClass } from '../../services/model/blanko';
import type { TitleFile } from '../../services/model/title-file';
import type { Store } from '../../services/stores';
import type { Title } from '../../services/title-data';
import type { BlankoGemRushAndFuse } from '../gem-rush/blankoGemRush';
import type { Shop, ShopVersion } from '../shops/shop';
import type { ShopVersionDiff } from './modules/shop-version/shop-version-diff';
import type { Role } from "../roles-permissions/rolesPermissions";
import type { RoleDiff } from "./modules/roles/roleDiff";
import type { BrawlPinDiff } from './modules/brawl-pins/brawlPinDiff';
import type { BrawlPin, BrawlPinRulesPublishInfo } from '../../services/model/brawl-pin';
import type { TitleDataPinRuleDiff } from './modules/brawl-pin-title-data/titleDataPinRuleDiff';
import { ItemNote } from "../items/item-types";
import { ItemNoteDiff } from "../../pages/CatalogMigration/types";
import { LiveEventDiff } from "./modules/live-event/liveEventDiff";
import { LiveEvent } from "../live-events/live-events-types";
import { GeoBlockedFeatureMap } from '../geoblocking/geo-blocking-types';
import { MigrationType } from "./modules/migration-type";
import { ChallengeTriggerDiff } from './modules/challenge-triggers/ChallengeTriggerDiff';
import { ChallengeTrigger } from '../../services/player-challenges/challenge-templates';

export interface MigrationDependencyItem {
  type: MigrationType;
  id: string;
  message?: string;
}

export interface MigrationDependencyTreeItem {
  id: string;
  dependencies: MigrationDependencyItem[];
  dependents: MigrationDependencyItem[];
}

export class MigrationDependencyTree {
  private tree: { [key: string]: { [key: string]: MigrationDependencyTreeItem } } = {};

  public add(item: MigrationDependencyItem, deps: MigrationDependencyItem[]) {
    const treeItem = this.getOrCreateDependencyItem(item);
    deps.forEach(dep => this.addToDepArray(treeItem.dependencies, dep));
    deps.forEach(dep => this.addDependent(dep, item));
  }

  public addDiffItem(item: DiffItem) {
    this.add(item.dependencyItem, item.dependencies);
  }

  public addDiffItems(items: DiffItem[]) {
    items.forEach(item => this.addDiffItem(item));
  }

  private getOrCreateDependencyItem(item: MigrationDependencyItem) {
    let items = this.tree[item.type];
    if (!items) {
      items = {};
      this.tree[item.type] = items;
    }

    let treeItem = items[item.id];
    if (!treeItem) {
      treeItem = {
        id: item.id,
        dependencies: [],
        dependents: []
      };
      items[item.id] = treeItem;
    }

    return treeItem;
  }

  private addToDepArray(arr: MigrationDependencyItem[], dep: MigrationDependencyItem) {
    if (!arr.find(v => v.type === dep.type && v.id === dep.id)) {
      arr.push(dep);
    }
  }

  private addDependent(dependency: MigrationDependencyItem, dependent: MigrationDependencyItem) {
    const treeItem = this.getOrCreateDependencyItem(dependency);
    this.addToDepArray(treeItem.dependents, dependent);
  }

  public getDependencies(item: MigrationDependencyItem, recursive = false): MigrationDependencyItem[] {
    const items = this.tree[item.type];
    if (!items) {
      return [];
    }

    const treeItem = items[item.id];
    const dependencies = treeItem ? treeItem.dependencies.slice() : [];
    if (recursive) {
      const queue = dependencies.slice();
      while (queue.length > 0) {
        const dep = queue.shift();
        if (dep) {
          this.getDependencies(dep, true).forEach(v => dependencies.push(v));
        }
      }
    }

    return dependencies;
  }

  public getTypeDependencyIds(type: string): string[] {
    const treeItems = this.tree[type] ? Object.values(this.tree[type]) : [];
    return treeItems.filter(v => v.dependents.length > 0).map(v => v.id);
  }

  public removeDependenciesExcept(type: string, items: DiffItem[]) {
    const diffItemMap: { [key: string]: boolean } = {};
    items.forEach(item => diffItemMap[`${item.dependencyItem.type}.${item.dependencyItem.id}`] = true);
    for (const key in this.tree) {
      for (const treeItem of Object.values(this.tree[key])) {
        treeItem.dependencies = treeItem.dependencies.filter(v => v.type !== type || diffItemMap[`${v.type}.${v.id}`]);
      }
    }
  }
}

export interface EnvironmentData {
  env: string;
  catalogName: string;
  stores?: Store[];
  catalog?: Catalog;
  titleData?: Title;
  titleFiles?: TitleFile[];
  blankoDnas?: any[];
  blankoAssets?: any[];
  blankoProgressions?: BlankoProgressions[];
  blankoClasses?: BlankoClass[];
  seasonalData?: SeasonalData[];
  translations?: Translation[];
  textLanguages?: TextLanguage[];
  shops?: Shop[];
  shopVersions?: ShopVersion[];
  gemRushes?: BlankoGemRushAndFuse[];
  roles?: Role[];
  brawlPins?: BrawlPin[];
  pinRulesInfo?: BrawlPinRulesPublishInfo;
  itemNotes?: ItemNote[];
  liveEvents?: LiveEvent[];
  geoblocking?: GeoBlockedFeatureMap[];
  challengeTriggers?: ChallengeTrigger[];
}

export interface EnvironmentFeatures {
  gridWidthSegments: number;
}

export interface MigrationDiffData<T extends DiffItem> {
  added: T[];
  changed: T[];
  removed: T[];
}

export interface MigrationData {
  userOptions: { [key: string]: boolean };
  options: { [key in MigrationType]?: boolean };
  depTree: MigrationDependencyTree;
  stores?: MigrationDiffData<StoreDiff>;
  items?: MigrationDiffData<CatalogItemDiff>;
  dropTables?: MigrationDiffData<DropTableDiff>;
  titleDataClient?: MigrationDiffData<TitleDataDiff>;
  titleDataServer?: MigrationDiffData<TitleDataDiff>;
  titleFiles?: MigrationDiffData<TitleFileDiff>;
  blankoDnas?: MigrationDiffData<BlankoDnaDiff>;
  blankoAssets?: MigrationDiffData<BlankoAssetDiff>;
  blankoProgressions?: MigrationDiffData<BlankoProgressionsDiff>;
  blankoClasses?: MigrationDiffData<BlankoClassesDiff>;
  seasonalData?: MigrationDiffData<SeasonalDataDiff>;
  translations?: MigrationDiffData<TranslationDiff>;
  textLanguages?: MigrationDiffData<TextLanguageDiff>;
  shopVersions?: MigrationDiffData<ShopVersionDiff>;
  gemRush?: MigrationDiffData<GemRushDiff>;
  roles?: MigrationDiffData<RoleDiff>;
  brawlPins?: MigrationDiffData<BrawlPinDiff>;
  titleDataPinRules?: MigrationDiffData<TitleDataPinRuleDiff>;
  itemNotes?: MigrationDiffData<ItemNoteDiff>;
  liveEvents?: MigrationDiffData<LiveEventDiff>;
  geoblocking?: MigrationDiffData<GeoBlockingDiff>;
  challengeTriggers?: MigrationDiffData<ChallengeTriggerDiff>;
}

export interface MigrationFrameworkProps {
  migrationSteps: MigrationStep[];
  modules: { [key in MigrationType]?: MigrationModule<DiffItem> };
  runOrder: MigrationType[];
  runMigrationTitle: string;
  getMainTitle: (sourceEnvName: string, sourceCatalog: string, targetEnvName: string, targetCatalog: string) => string;
}

export interface MigrationCommonProps extends MigrationFrameworkProps {
  sourceData: EnvironmentData;
  targetData: EnvironmentData;
  onSourceChange: (data: EnvironmentData) => void;
  onTargetChange: (data: EnvironmentData) => void;
  migrationData: MigrationData;
  onMigrationDataChange: (data: MigrationData) => void;
  selections: { [key in MigrationType]?: { [key: string]: boolean } };
  onSelectionsChange: (selections: { [key in MigrationType]?: { [key: string]: boolean } }) => void;
  onPrevStep: () => void;
  onNextStep: () => void;
  onSetStep: (id: string) => void;
  hiddenItems: HiddenItem[];
  setHiddenItems: (items: HiddenItem[]) => void;
}

export interface MigrationDependencyItemErrors {
  missingDeps: MigrationDependencyItem[];
  hideFixable?: boolean;
}

export interface MigrationDependencyErrors {
  [key: string]: MigrationDependencyItemErrors;
}

export type MigrationDiffCommonProps = MigrationCommonProps & {
  depErrors: MigrationDependencyErrors;
  onFixDependencies: (items: DiffItem[]) => void;
}

export interface MigrationStep {
  id: string;
  title: string;
  component: ComponentType<MigrationCommonProps>;
}

export interface MigrationModule<T extends DiffItem> {
  id: MigrationType;
  displayName: string;
  loadData: (sourceData: EnvironmentData, targetData: EnvironmentData, migrationData: MigrationData) => Promise<MigrationDiffData<T> | null>;
  runMigration: (props: MigrationCommonProps, setStatus: (status: string, progress?: number) => void) => Promise<void>;
  diffComponent: ComponentType<MigrationDiffCommonProps>;
  preloadSteps?: MigrationStep[];
  // If true, migrations will only be enabled if migrating between two different environments
  crossEnvOnly: boolean;
  // Whether the diff item should be selected by default
  inDefaultSelection?: (diffItem: DiffItem) => boolean;
}

export function buildDiffData<T extends DiffItem>(sourceItems: T[], targetItems: T[]): MigrationDiffData<T> {
  const diffData: MigrationDiffData<T> = {
    added: [],
    changed: [],
    removed: []
  };

  const targetItemsMap: { [key: string]: T } = {};
  targetItems.forEach(item => targetItemsMap[item.diffId] = item);

  sourceItems.forEach(item => {
    const targetItem = targetItemsMap[item.diffId];
    if (!targetItem) {
      diffData.added.push(item);
    } else if (!item.isEqual(targetItem)) {
      item.diffPrev = targetItem;
      item.diffJsonPrev = targetItem.diffJson;
      diffData.changed.push(item);
    }

    delete targetItemsMap[item.diffId];
  });

  // TODO: Allow removing items one day.
  // This requires attempting to discover potential dependents of removed items (the opposite of dependencies)
  // Objects also need to be removed in reverse order, to make sure children are removed before parents

  // diffData.removed = Object.values(targetItemsMap);

  return diffData;
}

// Copy from the old system. Make it easier to re-use the old code
export interface MigrationActions {
  add: string[];
  remove: string[];
  update: string[];
}

export function buildMigrationActions(diffData: MigrationDiffData<DiffItem> | undefined, selectionMap: { [key: string]: boolean } | undefined): MigrationActions {
  if (!diffData || !selectionMap) {
    return { add: [], remove: [], update: [] };
  }

  return {
    add: diffData.added.map(v => v.diffId).filter(v => Boolean(selectionMap[v])),
    remove: diffData.removed.map(v => v.diffId).filter(v => Boolean(selectionMap[v])),
    update: diffData.changed.map(v => v.diffId).filter(v => Boolean(selectionMap[v]))
  };
}

import { Box, Button, Card, CardContent, CardHeader, CircularProgress, Dialog, DialogContent, FormControl, Grid, IconButton, InputAdornment, LinearProgress, MenuItem, TextField as MuiTextField, Tooltip, Typography } from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import type { FormikHelpers, FormikProps } from 'formik';
import { Field, Formik } from 'formik';
import { Select, TextField } from 'formik-material-ui';
import { useEffect, useState } from 'react';
import { api } from '../../../services/api';
import type { DropTable } from '../../../services/drop-tables';
import { ItemDefinition } from '../../../services/item-definitions';
import { createValidator, Validators } from '../../../utils/forms';
import ItemSelectDialog from '../../../pages/ItemDefinition/ItemSelectDialog';
import type { MassGrantResultData } from './MassGrantResults';
import { MassGrantResults } from './MassGrantResults';
import { CurrencyService } from "../../../services/currency";
import { pushAppNotification } from "../../../shared/hooks/useAppNotification";

interface FormValues {
  grantType: string;
  itemId: string;
  currency: {
    code: string;
    name: string;
    amount: string;
  }[];
}

const itemValidator = createValidator<FormValues>({ itemId: Validators.notBlank() });
const currencyValidator = createValidator<FormValues>({
  currency: Validators.array(createValidator({
    amount: Validators.blank(Validators.integer(0))
  }))
});

const formValidator = (values: FormValues) => {
  if (values.grantType === 'item') {
    return itemValidator(values);
  } else if (values.grantType === 'currency' || values.grantType === 'currency-top') {
    return currencyValidator(values);
  }

  return undefined;
};

export const MassGrants = () => {
  const [emails, setEmails] = useState('');
  const [emailsError, setEmailsError] = useState('');
  const [itemSelectDialogOpen, setItemSelectDialogOpen] = useState(false);
  const [isGranting, setIsGranting] = useState(false);
  const [progress, setProgress] = useState(0);
  const [grantResults, setGrantResults] = useState<MassGrantResultData | undefined>();
  const [selectedItem, setSelectedItem] = useState<ItemDefinition | undefined>();
  const [initialFormValues, setInitialFormValues] = useState<FormValues>({
    grantType: 'item',
    itemId: '',
    currency: [],
  });
  useEffect(() => {
    CurrencyService.getOrLoadCurrencies()
      .then(currencyList => setInitialFormValues(ifv => ({
        ...ifv,
        currency: currencyList.getCurrencies().map(currency => ({
          code: currency.code,
          name: currency.name,
          amount: ''
        })),
      })))
      .catch(err => pushAppNotification({type: 'error', message: `Failed to get currency list: ${err.message}`}));
  }, []);

  const onEmailsChange = (emails: string) => {
    let emailsError = '';
    if (!emails.trim()) {
      emailsError = 'Cannot be blank';
    }
    setEmails(emails);
    setEmailsError(emailsError);
  };

  const onItemSelect = (items: (ItemDefinition | DropTable)[], form: FormikProps<FormValues>) => {
    if (items.length === 1 && items[0] instanceof ItemDefinition) {
      setSelectedItem(items[0]);
      form.setFieldValue('itemId', items[0].itemId);
    }
  };

  const batchGrants = async (emails: string[], batchSize: number, grantFn: (emails: string[]) => Promise<MassGrantResultData>) => {
    setIsGranting(true);
    setProgress(0);
    setGrantResults(undefined);

    let batch: string[] = [];
    const aggrResult: MassGrantResultData = {
      successCount: 0,
      errors: {}
    };

    for (let i = 0; i < emails.length; i++) {
      batch.push(emails[i]);
      if (batch.length === batchSize || i === emails.length - 1) {
        try {
          const result = await grantFn(batch);
          result.successCount = batch.length - Object.keys(result.errors).length;
          aggrResult.successCount += result.successCount;
          aggrResult.errors = { ...aggrResult.errors, ...result.errors };
          setProgress((i + 1) * 100 / emails.length);
        } catch (e) {
          const emailsRemaining = [...batch, ...emails.slice(i)];
          emailsRemaining.forEach(email => aggrResult.errors[email] = `API error: ${e.message}`);
          i = emails.length;
        }
        batch = [];
      }
    }

    return aggrResult;
  };

  const onSubmit = (values: FormValues, helpers: FormikHelpers<FormValues>) => {
    let promise: Promise<MassGrantResultData | null> = Promise.resolve(null);

    const emailList = emails.trim().split('\n').map(v => v.trim().toLowerCase()).filter(v => !!v);
    if (values.grantType === 'item' && selectedItem) {
      promise = batchGrants(emailList, 10, emails => {
        return api.put({ url: '/players/grant-item', body: { catalogName: selectedItem.catalogName, itemId: selectedItem.itemId, emails } })
      });
    } else if (values.grantType === 'currency' || values.grantType === 'currency-top') {
      const currencies: { [key: string]: number; } = {};
      values.currency.forEach(currency => {
        const amount = parseInt(currency.amount);
        if (amount > 0) {
          currencies[currency.code] = amount;
        }
      });

      if (Object.keys(currencies).length > 0) {
        promise = batchGrants(emailList, 100, emails => {
          return api.put({ url: '/players/grant-currency', body: { currencies, emails, topOff: values.grantType === 'currency-top' } })
        });
      }
    }

    promise.then(data => {
      setIsGranting(false);
      setGrantResults(data || undefined);
      helpers.setSubmitting(false);
    });
  };

  const renderGrantForm = (form: FormikProps<FormValues>) => {
    if (form.values.grantType === 'item') {
      return (<>
        <Grid item xs={12}>
          <Field
            component={TextField}
            name="itemId"
            type="text"
            label="Item"
            fullWidth
            InputProps={{
              readOnly: true,
              endAdornment: (
                <InputAdornment position="end">
                  <Tooltip title="Select item...">
                    <IconButton onClick={() => setItemSelectDialogOpen(true)}>
                      <MenuIcon />
                    </IconButton>
                  </Tooltip>
                </InputAdornment>
              )
            }}
          />
        </Grid>

        {itemSelectDialogOpen && (
          <ItemSelectDialog
            showCatalogSelect
            onSelect={items => onItemSelect(items, form)}
            onClose={() => setItemSelectDialogOpen(false)}
          />
        )}
      </>);
    } else if (form.values.grantType === 'currency' || form.values.grantType === 'currency-top') {
      return (<>
        {form.values.currency.map((currency, index) => (
          <Grid key={currency.code} item xs={12}>
            <Field
              component={TextField}
              name={`currency[${index}].amount`}
              type="text"
              label={currency.name}
              fullWidth
            />
          </Grid>
        ))}
      </>);
    }

    return null;
  };

  return (<>
    <Grid container spacing={3}>
      <Grid item xs={12} xl={6}>
        <Card>
          <CardContent>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <MuiTextField
                  type="text"
                  label="Player emails (one per line)"
                  value={emails}
                  onChange={event => onEmailsChange(event.target.value)}
                  error={!!emailsError}
                  helperText={emailsError}
                  multiline
                  rows={10}
                  rowsMax={10}
                  fullWidth
                />
              </Grid>
              <Formik<FormValues>
                initialValues={initialFormValues}
                enableReinitialize
                validate={formValidator}
                onSubmit={onSubmit}
              >
                {form => (<>
                  <Grid item xs={12}>
                    <FormControl fullWidth>
                      <Field component={Select} name="grantType">
                        <MenuItem value="item">Grant item</MenuItem>
                        <MenuItem value="currency">Grant currency</MenuItem>
                        <MenuItem value="currency-top">Top off currency</MenuItem>
                      </Field>
                    </FormControl>
                  </Grid>

                  {renderGrantForm(form)}

                  <Grid item xs={12}>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={form.submitForm}
                      disabled={form.isSubmitting}
                    >
                      {form.isSubmitting ? (
                        <CircularProgress size={25} />
                      ) : 'Grant'}
                    </Button>
                  </Grid>
                </>)}
              </Formik>
            </Grid>
          </CardContent>
        </Card>
      </Grid>

      {grantResults && (
        <Grid item xs={12} xl={6}>
          <Card>
            <CardHeader title="Grant results" />
            <CardContent>
              <MassGrantResults results={grantResults} />
            </CardContent>
          </Card>
        </Grid>
      )}
    </Grid>

    <Dialog
      open={isGranting}
      fullWidth
      maxWidth="sm"
    >
      <DialogContent>
        <Box textAlign="center">
          <Box my={3}>
            <LinearProgress variant="determinate" color="secondary" value={progress} />
            <Typography variant="subtitle2">{`${Math.floor(progress)}%`}</Typography>
          </Box>
          <Typography>Granting... This may take a while.</Typography>
          <Typography>Please do not close this tab.</Typography>
        </Box>
      </DialogContent>
    </Dialog>
  </>);
};

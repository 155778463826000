import type {Column} from "material-table";
import type {ScheduleCalendar} from "./season-challenge-schedule-calendar";
import {seasonRewardsToSmallString} from "./season-reward-path-tiers";
import type {Challenge} from "./challenges";
import type {Template} from "./challenge-templates";
import CheckBoxIcon from '@material-ui/icons/CheckBox';

export interface ChallengeMap {
  [index: string]: Challenge
}

export interface TemplateMap {
  [index: string]: Template
}

export function getDailyColumns(challengeMap: ChallengeMap, templateMap: TemplateMap): Column<ScheduleCalendar>[] {
  return [
    {
      title: 'Day Number', field: 'calendar_number', defaultSort: 'asc',
      customSort: (a, b) => {
        if (a.calendar_number && b.calendar_number) {
          const sortResult = a.calendar_number - b.calendar_number;
          if (sortResult === 0) {
            return a.sort_order - b.sort_order;
          }
          return sortResult;
        } else if (a.calendar_number) {
          return 1;
        } else if (b.calendar_number) {
          return -1;
        }
        return a.sort_order - b.sort_order;
      }
    },
    {title: 'Sort Order', field: 'sort_order', sorting: false},
    {title: 'Challenge', field: 'challenge_name', searchable: true},
    {
      title: 'Rewards', sorting: false, render: (challenge) => {
        if (!challengeMap[challenge.challenge_id]) return "Unknown Challenge";
        return challenge.rewards !== null ? seasonRewardsToSmallString(challenge.rewards) : [];
      }
    },
    {
      title: 'Template Name', field: 'template_id',
      render: (challenge) => {
        if (!challengeMap[challenge.challenge_id] || !templateMap[challengeMap[challenge.challenge_id].template_id]) {
          return "Unknown Template";
        }
        return templateMap[challengeMap[challenge.challenge_id].template_id].template_name;
      },
      customFilterAndSearch: (filter: string, challenge) => {
        if (!challengeMap[challenge.challenge_id] || !templateMap[challengeMap[challenge.challenge_id].template_id]) return false;
        return templateMap[challengeMap[challenge.challenge_id].template_id].template_name.toLowerCase().indexOf(filter.toLowerCase()) > -1;
      },
      customSort: (a, b) => {
        if (!challengeMap[a.challenge_id] ||
          !templateMap[challengeMap[a.challenge_id].template_id] ||
          !challengeMap[b.challenge_id] ||
          !templateMap[challengeMap[b.challenge_id].template_id]) {
          return 0;
        }
        return templateMap[challengeMap[a.challenge_id].template_id].template_name
          .localeCompare(templateMap[challengeMap[b.challenge_id].template_id].template_name);
      }
    },
    {title: 'Community', field: 'community_challenge', render: (instance) => {return instance.community_challenge ? 'TRUE' : 'FALSE'}},
    {title: 'Instance Id', field: 'instance_id'},
    {title: 'Challenge Id', field: 'challenge_id'},
  ];
}

export function getWeeklyColumns(challengeMap: ChallengeMap, templateMap: TemplateMap): Column<ScheduleCalendar>[] {
  return [
    {title: 'Week Number', field: 'calendar_number', defaultSort: 'asc',
      customSort: (a, b) => {
        if (a.calendar_number && b.calendar_number) {
          const sortResult = a.calendar_number - b.calendar_number;
          if (sortResult === 0) {
            return a.sort_order - b.sort_order;
          }
          return sortResult;
        } else if (a.calendar_number) {
          return 1;
        } else if (b.calendar_number) {
          return -1;
        }
        return a.sort_order - b.sort_order;
      }
    },
    {title: 'Sort Order', field: 'sort_order', sorting: false},
    {title: 'Challenge', field: 'challenge_name', searchable: true},
    {
      title: 'Rewards', sorting: false, render: (challenge) => {
        if (!challengeMap[challenge.challenge_id]) return "Unknown Challenge";
        return challenge.rewards != null ? seasonRewardsToSmallString(challenge.rewards) : [];
      }
    },
    {
      title: 'Template Name', field: 'template_id',
      render: (challenge) => {
        if (!challengeMap[challenge.challenge_id] || !templateMap[challengeMap[challenge.challenge_id].template_id]) {
          return "Unknown Template";
        }
        return templateMap[challengeMap[challenge.challenge_id].template_id].template_name;
      },
      customFilterAndSearch: (filter: string, challenge) => {
        if (!challengeMap[challenge.challenge_id] || !templateMap[challengeMap[challenge.challenge_id].template_id]) return false;
        return templateMap[challengeMap[challenge.challenge_id].template_id].template_name.toLowerCase().indexOf(filter.toLowerCase()) > -1;
      },
      customSort: (a, b) => {

        if (!challengeMap[a.challenge_id] ||
          !templateMap[challengeMap[a.challenge_id].template_id] ||
          !challengeMap[b.challenge_id] ||
          !templateMap[challengeMap[b.challenge_id].template_id]) {
          return 0;
        }
        return templateMap[challengeMap[a.challenge_id].template_id].template_name
          .localeCompare(templateMap[challengeMap[b.challenge_id].template_id].template_name);
      }
    },
    {title: 'Community', field: 'community_challenge', render: (instance) => {return instance.community_challenge ? 'TRUE' : 'FALSE'}},
    {title: 'Instance Id', field: 'instance_id'},
    {title: 'Challenge Id', field: 'challenge_id'},
  ] as Column<ScheduleCalendar>[];
}

export function getSeasonalColumns(challengeMap: ChallengeMap, templateMap: TemplateMap): Column<ScheduleCalendar>[] {
  return [
    {title: 'Challenge', field: 'challenge_name', searchable: true},
    {
      title: 'Rewards', sorting: false, render: (challenge) => {
        if (!challengeMap[challenge.challenge_id]) return "Unknown Challenge";
        return challenge.rewards !== null ? seasonRewardsToSmallString(challenge.rewards) : [];
      }
    },
    {
      title: 'Template Name', field: 'template_id',
      render: (challenge) => {
        if (!challengeMap[challenge.challenge_id] || !templateMap[challengeMap[challenge.challenge_id].template_id]) {
          return "Unknown Template";
        }
        return templateMap[challengeMap[challenge.challenge_id].template_id].template_name;
      },
      customFilterAndSearch: (filter: string, challenge) => {
        if (!challengeMap[challenge.challenge_id] || !templateMap[challengeMap[challenge.challenge_id].template_id]) return false;
        return templateMap[challengeMap[challenge.challenge_id].template_id].template_name.toLowerCase().indexOf(filter.toLowerCase()) > -1;
      },
      customSort: (a, b) => {

        if (!challengeMap[a.challenge_id] ||
          !templateMap[challengeMap[a.challenge_id].template_id] ||
          !challengeMap[b.challenge_id] ||
          !templateMap[challengeMap[b.challenge_id].template_id]) {
          return 0;
        }
        return templateMap[challengeMap[a.challenge_id].template_id].template_name
          .localeCompare(templateMap[challengeMap[b.challenge_id].template_id].template_name);
      }
    },
    {
      title: 'Is Limited',
      field: 'is_limited',
      // eslint-disable-next-line react/display-name
      render: (instance) => {
        if (instance.is_limited) {
          return (<CheckBoxIcon/>);
        }
        return null;
      }
    },
    {title: 'Limited Start', field: 'limited_start', defaultSort: 'asc',
      customSort: (a, b) => {
        const aLimitedStart = a.limited_start === undefined ? -1 : a.limited_start;
        const aLimitedEnd = a.limited_end === undefined ? 99999 : a.limited_end;
        const bLimitedStart = b.limited_start === undefined ? -1 : b.limited_start;
        const bLimitedEnd = b.limited_end === undefined ? 99999 : b.limited_end;
        let sortResult = aLimitedStart - bLimitedStart;
        if (sortResult === 0) {
          sortResult = aLimitedEnd - bLimitedEnd;
          if (sortResult === 0) {
            return a.sort_order - b.sort_order;
          }
        }
        return sortResult;
      }
    },
    {title: 'Limited End', field: 'limited_end', sorting: false},
    {title: 'Sort Order', field: 'sort_order', sorting: false},
    {title: 'Community', field: 'community_challenge', render: (instance) => {return instance.community_challenge ? 'TRUE' : 'FALSE'}},
    {title: 'Instance Id', field: 'instance_id'},
    {title: 'Challenge Id', field: 'challenge_id'},
  ] as Column<ScheduleCalendar>[];
}

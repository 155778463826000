import type { KeyboardDateTimePickerProps as FormikMUIKeyboardDateTimePickerProps } from "formik-material-ui-pickers";
import { KeyboardDateTimePicker as FormikMUIKeyboardDateTimePicker } from "formik-material-ui-pickers";
import type { KeyboardDateTimePickerProps as MUIKeyboardDateTimePickerProps } from "@material-ui/pickers";
import { KeyboardDateTimePicker as MUIKeyboardDateTimePicker } from "@material-ui/pickers";

const format = "yyyy/MM/dd HH:mm:ss";
const constantProps = {
  format,
  ampm: false,
};

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface AdminKeyboardDateTimePickerProps extends Omit<MUIKeyboardDateTimePickerProps, 'format' | 'ampm'> {
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface FormikAdminKeyboardDateTimePickerProps extends Omit<FormikMUIKeyboardDateTimePickerProps, 'format' | 'ampm'> {
}

export const AdminKeyboardDateTimePicker = (props: AdminKeyboardDateTimePickerProps) => {
  return <MUIKeyboardDateTimePicker
    {...props}
    {...constantProps}
  />;
};

export const FormikAdminKeyboardDateTimePicker = (props: FormikAdminKeyboardDateTimePickerProps) => {
  return <FormikMUIKeyboardDateTimePicker
    {...props}
    {...constantProps}
  />;
};

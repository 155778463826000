import IconButton from '@material-ui/core/IconButton';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { FieldInputProps, useFormikContext } from 'formik';
import { useCallback } from 'react';
import { FormContainedItem } from './FormContainedItem';
import { ItemDefFormValues } from './ItemDefinitionForm';

interface Props {
  contents: FormContainedItem[];
  onRemove: (id: string, type: string) => void;
  readOnly?: boolean;
  moveItem: (dragIndex: number, hoverIndex: number,) => void;
}

export const ItemContentsForm = ({ contents, onRemove, readOnly, moveItem }: Props) => {
  const renderItem = useCallback((item: FormContainedItem, index: number) => {
    return (
      <FormContainedItem
        key={index}
        item={item}
        index={index}
        moveItem={moveItem}
        onRemove={onRemove}
        readOnly={readOnly}
        />
    )
  }, [moveItem, onRemove, readOnly]);

  return (<>
    <Table size="small">
      <TableHead>
        <TableRow>
          {!readOnly && <TableCell> </TableCell>}
          <TableCell>Type</TableCell>
          <TableCell>Quantity</TableCell>
          <TableCell>ID</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {contents.map((item, index) => renderItem(item, index))}
      </TableBody>
    </Table>
  </>);
};

import type { Theme} from '@material-ui/core';
import { Box, Chip, CircularProgress, Divider, Grid, IconButton, InputAdornment, Link, ListItemIcon, makeStyles, Menu, MenuItem, Paper, Select, TextField, Tooltip } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import ErrorIcon from '@material-ui/icons/Error';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import PublishIcon from '@material-ui/icons/Publish';
import StarIcon from '@material-ui/icons/Star';
import StarBorderIcon from '@material-ui/icons/StarBorder';
import BlockIcon from '@material-ui/icons/Block';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useDrag, useDrop } from 'react-dnd';
import { getEmptyImage } from 'react-dnd-html5-backend';
import { useDropzone } from 'react-dropzone';
import { useDispatch } from 'react-redux';
import { setAppNotification } from '../../redux/app/actions';
import { useTypedSelector } from '../../redux/reducers';
import { arrayReplaceOrPush } from '../../redux/utils-ts';
import type { ApiError } from '../../services/api';
import type { ItemDefinition } from '../../services/item-definitions';
import type { Store, StoreItem, StoreTab, StoreTabItem } from '../../services/stores';
import { StoresService } from '../../services/stores';
import StoreImageUploadDialog from './StoreImageUploadDialog';
import { RouterLink } from '../../types';
import { BuiltInCurrencyEnum } from '../../services/model/currency';

const useStyles = makeStyles((theme: Theme) => ({
  item: {
    height: '100%',
    position: 'relative',
    backgroundColor: 'rgba(0, 0, 0, 0.2)',
    '&:hover $resizeOverlay': {
      backgroundColor: theme.palette.type === 'dark' ? 'rgba(255, 255, 255, 0.2)' : 'rgba(0, 0, 0, 0.2)'
    }
  },
  itemImage: {
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center'
  },
  itemImageOverlay: {
    backgroundColor: theme.palette.type === 'dark' ? 'rgba(0, 0, 0, 0.6)' : 'rgba(255, 255, 255, 0.6)'
  },
  itemImageOverlayText: {
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden'
  },
  itemOverlay: {
    backgroundColor: theme.palette.type === 'dark' ? 'rgba(0, 0, 0, 0.6)' : 'rgba(255, 255, 255, 0.6)',
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    textAlign: 'center'
  },
  resizeOverlay: {
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    width: 10,
    backgroundColor: theme.palette.type === 'dark' ? 'rgba(255, 255, 255, 0)' : 'rgba(0, 0, 0, 0)',
    cursor: 'ew-resize',
    overflow: 'hidden',
    transition: 'background-color 0.5s'
  }
}));

export interface EditorItem {
  tabItem: StoreTabItem;
  storeItem: StoreItem;
  catalogItem: ItemDefinition;
  currency: string;
  price: string;
  priceError: string;
  isNew: boolean;
}

interface Props {
  index: number;
  catalogName: string;
  store: Store;
  storeTab: StoreTab;
  item: EditorItem;
  onTabItemChange: (tabItem: StoreTabItem) => void;
  onCurrencyChange: (currency: string) => void;
  onPriceChange: (price: string) => void;
  onRemove: () => void;
  onToggleHero: () => void;
  onToggleNonInteractive: () => void;
  moveItem: (from: number) => boolean;
}

const StoreTabItemEditor = (props: Props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const dndHandleRef = useRef(null);
  const dndDropCooldown = useRef<boolean>(false);
  const resizeContainerRef = useRef<HTMLDivElement>(null);

  const { tabItem } = props.item;

  const currencyList = useTypedSelector(state => state.currencies.list);
  const menuAnchor = useRef(null);
  const [menuOpen, setMenuOpen] = useState(false);
  const [imageUploadDialogOpen, setImageUploadDialogOpen] = useState(false);
  const [isUploadingImage, setUploadingImage] = useState(false);

  const { catalogName, store, storeTab, onTabItemChange } = props;
  const uploadImage = useCallback((file: File) => {
    if (!isUploadingImage && !props.item.isNew) {
      setUploadingImage(true);
      StoresService.uploadStoreItemImage(catalogName, store.storeId, storeTab.name, tabItem.itemId, file).then(item => {
        storeTab.items = arrayReplaceOrPush(storeTab.items, item, v => v.itemId === item.itemId);
        onTabItemChange(item);
        dispatch(setAppNotification({ type: 'success', message: 'Image uploaded' }));
      }).catch((e: ApiError) => {
        dispatch(setAppNotification({ type: 'error', message: e.message }));
      }).then(() => {
        setUploadingImage(false);
      });
    }
  }, [catalogName, store, storeTab, onTabItemChange, dispatch, tabItem, isUploadingImage, props.item.isNew]);

  const onImageFileDrop = useCallback(acceptedFiles => {
    if (acceptedFiles.length === 1) {
      uploadImage(acceptedFiles[0]);
    }
  }, [uploadImage]);
  const { getRootProps, isDragActive } = useDropzone({ onDrop: onImageFileDrop, noClick: true, accept: 'image/*', multiple: false, maxFiles: 1, noKeyboard: true });

  // DnD movement
  const [, dndDrop] = useDrop({
    accept: 'StoreTabItem',
    hover(item: { type: string, index: number }) {
      if (!dndHandleRef.current) {
        return;
      }
      if (item.index === props.index || dndDropCooldown.current) {
        return;
      }

      // Temporary fix for DnD position flicker. Prevent items for swapping positions faster than once per second.
      dndDropCooldown.current = true;
      window.setTimeout(() => dndDropCooldown.current = false, 1000);

      if (props.moveItem(item.index)) {
        item.index = props.index;
      }
    }
  });

  const [{ isDragging }, dndDrag] = useDrag({
    item: { type: 'StoreTabItem', index: props.index },
    canDrag: () => !tabItem.hero,
    collect: monitor => ({
      isDragging: monitor.isDragging()
    })
  });

  // DnD resize
  const [{ isResizing }, dndResizeDrag, dndResizeDragPreview] = useDrag({
    item: { type: 'StoreTabItemResize', index: props.index, initialWidth: tabItem.gridWidth, ref: resizeContainerRef },
    collect: monitor => ({ isResizing: monitor.isDragging() }),
    end(item) {
      if (item && item.ref.current) {
        item.ref.current.style.width = '';
      }
    }
  });

  useEffect(() => {
    dndResizeDragPreview(getEmptyImage(), { captureDraggingState: true });
  }, [dndResizeDragPreview]);

  dndDrag(dndDrop(dndHandleRef));

  return (<>
    <Paper elevation={0} className={classes.item} {...getRootProps()}>
      <Paper ref={resizeContainerRef} style={{ opacity: isDragging ? 0 : 1 }}>
        <div ref={dndHandleRef} className={classes.itemImage} style={{ backgroundImage: tabItem.imageUrl ? `url(${tabItem.imageUrl})` : undefined, height: 250 }}>
          <Box className={classes.itemImageOverlay} p={1}>
            <Grid container spacing={1}>
              <Grid item xs className={classes.itemImageOverlayText}>
                <Link component={RouterLink} to={`/catalogs/${props.catalogName}/items/${props.item.tabItem.itemId}`}>
                  {props.item.tabItem.itemId}
                </Link><br />
                {props.item.catalogItem.displayName}
              </Grid>
              {tabItem.nonInteractive && (
                <Grid item xs="auto">
                  <Chip label="Non-Interactive" size="small" color="primary" />
                </Grid>
              )}
            </Grid>
          </Box>
        </div>
        <Box p={1}>
          <Grid container spacing={1} alignItems="center">
            <Grid item xs="auto">
              <Select
                value={props.item.currency}
                onChange={event => props.onCurrencyChange(event.target.value as string)}
              >
                {currencyList.getCurrencies().map(currency => (
                  <MenuItem key={currency.code} value={currency.code}>{currency.code}</MenuItem>
                ))}
                <MenuItem value={BuiltInCurrencyEnum.USDollar}>$</MenuItem>
              </Select>
            </Grid>
            <Grid item xs>
              <TextField
                value={props.item.price}
                onChange={event => props.onPriceChange(event.target.value as string)}
                error={!!props.item.priceError}
                InputProps={props.item.priceError ? {
                  endAdornment: (
                    <InputAdornment position="end">
                      <Tooltip title={props.item.priceError}>
                        <ErrorIcon fontSize="small" color="error" />
                      </Tooltip>
                    </InputAdornment>
                  )
                } : undefined}
                inputProps={{
                  autoComplete: 'off'
                }}
                fullWidth
              />
            </Grid>
            <Grid item xs="auto">
              <IconButton ref={menuAnchor} onClick={() => setMenuOpen(true)}>
                <MoreVertIcon />
              </IconButton>
            </Grid>
          </Grid>
        </Box>

        {(isDragActive || isUploadingImage) && (
          <Box className={classes.itemOverlay}>
            <Grid container alignItems="center" style={{ height: '100%' }}>
              <Grid item xs={12}>
                {isUploadingImage ? (
                  <CircularProgress />
                ) : (
                  <>{props.item.isNew ? 'Cannot upload. Save the item first' : 'Upload image'}</>
                )}
              </Grid>
            </Grid>
          </Box>
        )}
        {!tabItem.hero && (
          <div className={classes.resizeOverlay} style={{ opacity: isResizing ? 0 : 1 }} ref={dndResizeDrag}>
          </div>
        )}
      </Paper>
    </Paper>

    {menuOpen && (
      <Menu onClose={() => setMenuOpen(false)} anchorEl={menuAnchor.current} open>
        {tabItem.hero ? (
          <MenuItem onClick={() => { setMenuOpen(false); props.onToggleHero(); }}>
            <ListItemIcon>
              <StarBorderIcon />
            </ListItemIcon>
            Remove hero
          </MenuItem>
        ) : (
          <MenuItem onClick={() => { setMenuOpen(false); props.onToggleHero(); }}>
            <ListItemIcon>
              <StarIcon />
            </ListItemIcon>
            Make hero
          </MenuItem>
        )}
        <MenuItem onClick={() => { setMenuOpen(false); setImageUploadDialogOpen(true); }} disabled={props.item.isNew}>
          <ListItemIcon>
            <PublishIcon />
          </ListItemIcon>
          Upload image
        </MenuItem>
        <MenuItem onClick={() => { setMenuOpen(false); props.onToggleNonInteractive(); }}>
          {tabItem.nonInteractive ? (<>
            <ListItemIcon>
              <BlockIcon />
            </ListItemIcon>
            Remove non-interactive
          </>) : (<>
            <ListItemIcon>
              <BlockIcon />
            </ListItemIcon>
            Set non-interactive
          </>)}
        </MenuItem>
        <Box my={1}>
          <Divider />
        </Box>
        <MenuItem onClick={() => props.onRemove()}>
          <ListItemIcon>
            <DeleteIcon />
          </ListItemIcon>
          Remove
        </MenuItem>
      </Menu>
    )}

    {imageUploadDialogOpen && (
      <StoreImageUploadDialog
        onClose={() => setImageUploadDialogOpen(false)}
        onUpload={uploadImage}
      />
    )}
  </>);
}

export default StoreTabItemEditor;
import { Box, Button, CircularProgress } from '@material-ui/core';
import download from 'downloadjs';
import { Component } from 'react';
import type { ConnectedProps } from 'react-redux';
import { connect } from 'react-redux';
import KeyValueEditor from '../../components/KeyValueEditor';
import { updateClientTitleAsync } from '../../redux/title-data/actions';
import type { Title } from '../../services/title-data';
import { UserService } from '../../services/user';

interface OwnProps {
  title?: Title;
}

const mapDispatch = {
  requestUpdateClientTitle: updateClientTitleAsync.request
};

const connector = connect(null, mapDispatch);

type Props = ConnectedProps<typeof connector> & OwnProps;

class TitleData extends Component<Props> {
  onSave = (data: { [key: string]: string | null; }) => {
    if (!data || !this.props.title) {
      return;
    }

    this.props.requestUpdateClientTitle(data);
  };

  onExport = () => {
    if (!this.props.title) {
      return;
    }

    download(JSON.stringify(this.props.title.titleData), 'TitleDataClient.json', 'application/json');
  }

  render() {
    if (!this.props.title) {
      return (
        <Box textAlign="center">
          <CircularProgress />
        </Box>
      );
    } else {
      return (<>
        <Box mb={2} textAlign="right">
          <Button variant="contained" color="primary" onClick={this.onExport}>Export Client Data</Button>
        </Box>
        <KeyValueEditor data={this.props.title.titleData} onSave={this.onSave} readOnly={!UserService.canUpdate('title')} />
      </>);
    }
  }
}

export default connector(TitleData);
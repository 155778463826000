import { Box, Typography } from '@material-ui/core';
import { DiffsPanelsContainer } from '../DiffsPanels';
import type { DropTableDiff, ItemDiffProps } from '../../types';

type DropTablesProps = ItemDiffProps<DropTableDiff>

export const DropTables = ({
  sourceItems,
  targetItems,
  actions,
  onSelectionChange
}: DropTablesProps) => (<>
  <Typography variant="h6">
    Drop tables
  </Typography>

  <Box mt={2}>
    {sourceItems && targetItems && (
      <DiffsPanelsContainer
        sourceItems={sourceItems}
        targetItems={targetItems}
        selectedIds={actions}
        columns={[
          { title: 'Item ID', field: 'diffId', defaultSort: 'asc' }
        ]}
        onSelectionChange={onSelectionChange}
      />
    )}
  </Box>
</>);

import { CatalogItemDiff, DiffItem } from '../../../../pages/CatalogMigration/types';
import type { MigrationActions } from '../../../../redux/catalog-migration/reducer';
import { CatalogMigrationService } from '../../../../services/catalog-migration';
import { Catalog } from '../../../../services/catalogs';
import type { MigrationModule } from '../../migrations';
import { buildMigrationActions, buildDiffData } from '../../migrations';
import { ItemsDiffs } from './ItemsDiffs';
import { MigrationType } from "../migration-type";

export const itemMigration: MigrationModule<CatalogItemDiff> = {
  id: MigrationType.item,
  displayName: 'Catalog items',
  diffComponent: ItemsDiffs,
  crossEnvOnly: false,
  inDefaultSelection: (diffItem: DiffItem) => {
    if (!(diffItem instanceof CatalogItemDiff)) {
      throw new Error(`Expected CatalogItemDiff but received ${diffItem}`);
    }
    return !diffItem.item.pipelineGenerated;
  },

  loadData: async (sourceData, targetData, migrationData) => {
    if (!sourceData.catalog) {
      sourceData.catalog = await CatalogMigrationService.getCatalog(sourceData.env, sourceData.catalogName);
    }
    if (!targetData.catalog) {
      targetData.catalog = await CatalogMigrationService.getCatalog(targetData.env, targetData.catalogName);
    }

    if (sourceData.catalog.items && targetData.catalog.items) {
      migrationData.items = buildDiffData(
        sourceData.catalog.items.map(item => new CatalogItemDiff(item)),
        targetData.catalog.items.map(item => new CatalogItemDiff(item))
      );

      return migrationData.items;
    }

    return null;
  },

  runMigration: async (props, setStatus) => {
    const sourceCatalog = props.sourceData.catalog;
    const targetCatalog = props.targetData.catalog;
    if (!sourceCatalog || !sourceCatalog.items || !sourceCatalog.dropTables || !targetCatalog || !targetCatalog.items || !targetCatalog.dropTables) {
      throw new Error('Catalogs not loaded. Items or drop tables not found.');
    }

    function mergeItems<T>(source: T[], target: T[], actions: MigrationActions, idMapper: (item: T) => string): T[] {
      if (!actions || actions.add.length + actions.remove.length + actions.update.length < 1) {
        return target;
      }

      const sourceMap: { [key: string]: T } = {};
      const targetMap: { [key: string]: T } = {};
      source.forEach(item => sourceMap[idMapper(item)] = item);
      target.forEach(item => targetMap[idMapper(item)] = item);

      const mergedItems: T[] = [];

      actions.add.forEach(itemId => mergedItems.push(sourceMap[itemId]));
      actions.update.forEach(itemId => {
        mergedItems.push(sourceMap[itemId])
        delete targetMap[itemId];
      });
      actions.remove.forEach(itemId => delete targetMap[itemId]);

      // At this point, targetMap should contain the items that should be preserved (weren't selected for update or remove)
      return mergedItems.concat(Object.values(targetMap));
    }

    const catalogItems = mergeItems(sourceCatalog.items, targetCatalog.items, buildMigrationActions(props.migrationData.items, props.selections.item), item => item.itemId);
    const dropTables = mergeItems(sourceCatalog.dropTables, targetCatalog.dropTables, buildMigrationActions(props.migrationData.dropTables, props.selections.droptable), table => table.id);

    // Nothing to update.
    if (catalogItems === targetCatalog.items && dropTables === targetCatalog.dropTables) {
      return;
    }

    const catalog = new Catalog({ name: targetCatalog.name, primaryCatalog: false });
    catalog.items = catalogItems;
    catalog.dropTables = dropTables;

    setStatus('Migrating catalog items');
    await CatalogMigrationService.importCatalog(props.targetData.env, catalog);
  }
};

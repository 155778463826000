import type {RootState} from '../reducers';

export function selectAllTransactionsPage(state: RootState) {
  return {
    ...state.transactions.page,
    data: state.transactions.page.ids.map(id => state.transactions.byId[id])
  };
}

export function selectAllTransactionItemsPage(state: RootState) {
  return {
    ...state.transactions.itemsPage,
    data: state.transactions.itemsPage.ids.map(id => state.transactions.itemsById[id])
  };
}

import { FormControl, Grid, InputLabel, MenuItem, Select } from '@material-ui/core';
import * as React from 'react';
import type { AppConfig } from '../../../../config';

interface EnvironmentSelectorProps {
  environmentId: string;
  compatibleConfigs: AppConfig[];
  environmentSelectorEnabled?: boolean;
  catalogSelect: React.ReactNode;
  handleChange(event: React.ChangeEvent<{
    name?: string | undefined;
    value: unknown;
  }>): void;
}

export const EnvironmentSelector = ({
  environmentId,
  compatibleConfigs,
  environmentSelectorEnabled,
  catalogSelect,
  handleChange
}: EnvironmentSelectorProps) => (<>
  <Grid container spacing={2} alignItems="center">
    {environmentSelectorEnabled && (
      <Grid item xs="auto">
        <FormControl>
          <InputLabel>Env.</InputLabel>
          <Select value={environmentId} onChange={handleChange}>
            {compatibleConfigs.map(config => (
              <MenuItem key={config.env} value={config.env}>{config.name}</MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
    )}
    <Grid item xs>
      {catalogSelect}
    </Grid>
  </Grid>
</>);

import { Box, CircularProgress, Typography } from '@material-ui/core';
import { useCallback, useEffect, useMemo, useState } from 'react';
import type { RouteComponentProps } from 'react-router';
import type { LinkTabConfig } from '../../../components/LinkTabs';
import { LinkTabs } from '../../../components/LinkTabs';
import { PlayerBlankos } from './PlayerBlankos';
import { PlayerData } from './PlayerData';
import { PlayerInfo } from './PlayerInfo';
import { PlayerItems } from './PlayerItems';
import { PlayerSeasonPass } from './PlayerSeasonPass';
import { PlayerSocial } from './PlayerSocial';
import { UserService } from '../../../services/user';
import TransactionsGrid from "../../../pages/Transactions/TransactionGrid";
import { Connections } from './Connections';
import { Rewards } from './Rewards';
import { CurrencyHistory } from './CurrencyHistory';
import type { Player } from "../players";
import { PlayersService } from "../players";
import { pushAppNotification } from "../../../shared/hooks/useAppNotification";
import { AdminRequests } from "../../../components/AdminRequests/AdminRequests";
import { Blocks } from "../../blocks/Blocks";
import { PublishedBlocks } from "../../blocks/PublishedBlocks";
import { UserRolesEditor } from "../../roles-permissions/UserRolesEditor";
import { GumballHistory } from "../../gumball-history/components/GumballHistory";
import { PlayerChallengeProgressPage } from "./PlayerChallengeProgressPage";

type Props = RouteComponentProps<{ playerId: string; }>;

export const PlayerPage = (props: Props) => {
  const playerId = props.match.params.playerId;
  const [player, setPlayer] = useState<Player | undefined>();

  const fetchPlayer = useCallback(() => {
    PlayersService.getPlayer(playerId)
      .then(play => setPlayer(play))
      .catch(err => pushAppNotification({type: 'error', message: `Failed to get player: ${err.message}`}));
  }, [playerId]);
  useEffect(() => {
    fetchPlayer();
  }, [fetchPlayer]);

  const tabs: LinkTabConfig[] = useMemo(() => {
    if (!player) {
      return [];
    }
    const tabs: LinkTabConfig[] = [
      {
        url: '', label: 'Info', render: function render() {
          return (<>
            {player && <PlayerInfo refreshPlayer={fetchPlayer} setPlayer={setPlayer} player={player}/>}
          </>);
        }
      }
    ];

    if (UserService.canRead('playerData')) {
      tabs.push({
        url: '/data', label: 'Data', render: function render() {
          return <PlayerData player={player} setPlayer={setPlayer}/>;
        }
      });
    }
    if (UserService.canRead('player')) {
      tabs.push({
        url: '/currencyhistory',
        label: 'Currency History',
        render: function render() {
          return <CurrencyHistory playerId={playerId}/>;
        }
      });
    }
    if (UserService.canRead('itemInstances')) {
      tabs.push({
        url: '/items', label: 'Items', render: function render() {
          return <PlayerItems playerId={playerId}/>;
        }
      });
    }
    if (UserService.canRead('playerSeasonalData')) {
      tabs.push({
        url: '/season-pass',
        label: 'Season Pass',
        render: function render() {
          return <PlayerSeasonPass externalId={playerId}/>;
        }
      });
    }
    if (UserService.canRead('blocks')) {
      tabs.push({
        url: '/blocks', label: 'Blocks', render: function render() {
          return <Blocks player={player}/>;
        }
      });
    }
    if (UserService.canRead('publishedBlocks')) {
      tabs.push({
        url: '/published-blocks',
        label: 'Published blocks',
        render: function render() {
          return <PublishedBlocks player={player}/>;
        }
      });
    }
    if (UserService.canRead('blankos')) {
      tabs.push({
        url: '/blankos', label: 'Blankos', render: function render() {
          return <PlayerBlankos player={player}/>;
        }
      });
    }
    if (UserService.canRead('auditLogs')) {
      tabs.push({
        url: '/logs', label: 'Logs', render: function render() {
          return <AdminRequests player={player}/>;
        }
      });
    }
    if (UserService.canRead('commerce')) {
      tabs.push({
        url: '/transactions', label: 'Purchases', render: function render() {
          return <TransactionsGrid player={player}/>;
        }
      });
    }
    if (UserService.canRead('social')) {
      tabs.push({
        url: '/social', label: 'Social', render: function render() {
          return <PlayerSocial playerId={playerId}/>;
        }
      });
    }
    if (UserService.canRead('rewards')) {
      tabs.push({
        url: '/rewards', label: 'Rewards', render: function render() {
          return <Rewards playerId={playerId}/>;
        }
      })
    }
    if (UserService.canRead('accountLinking')) {
      tabs.push({
        url: '/connections',
        label: 'Connections',
        render: function render() {
          return <Connections playerId={playerId}/>;
        }
      })
    }
    if (UserService.canRead('roles')) {
      tabs.push({
        url: '/roles',
        label: 'Roles',
        render: function render() {
          return <UserRolesEditor mythicalId={player.mythicalId}/>;
        }
      })
    }
    if (UserService.canRead('itemInstances')) {
      tabs.push({
        url: '/gumball-history',
        label: 'Gumball History',
        render: function render() {
          return <GumballHistory playerId={playerId} />;
        }
      })
    }
    if (UserService.canRead('playerSeasonChallengeProgress')) {
      tabs.push({
        url: '/challenge-progress',
        label: 'Challenge Progress',
        render: function render() {
          return <PlayerChallengeProgressPage player={player}/>;
        }
      })
    }
    return tabs;
  }, [fetchPlayer, player, playerId]);

  if (!player || player.externalId !== playerId) {
    return (
      <Box textAlign="center">
        <CircularProgress/>
      </Box>
    );
  } else {
    return (<>
      <Box>
        <Typography variant="h6">
          Player: {player.getDisplayName()}
        </Typography>
      </Box>
      <LinkTabs tabs={tabs} scrollable {...props} />
    </>);
  }
};

import { Box, CircularProgress, Typography } from '@material-ui/core';
import { useCallback, useEffect, useState } from 'react';
import type { DiffItem } from '../../../pages/CatalogMigration/types';
import {
  isDependencyHidden,
  isDiffItemHidden,
  TextLanguageDiff,
  TranslationDiff
} from '../../../pages/CatalogMigration/types';
import { CatalogMigrationService } from '../../../services/catalog-migration';
import { pushAppNotification } from '../../../shared/hooks/useAppNotification';
import type { MigrationCommonProps, MigrationDependencyItem, MigrationDiffData } from '../migrations';
import { buildDiffData } from '../migrations';
import { MigrationType } from "../modules/migration-type";

export const MigrationDataLoader = (props: MigrationCommonProps) => {
  const { onSourceChange, onTargetChange, onMigrationDataChange, onSelectionsChange, onNextStep, modules } = props;

  const [status, setStatus] = useState('');
  const [loading, setLoading] = useState(false);
  const canSelect = useCallback((item: DiffItem) => {
    return !isDiffItemHidden(item, props.hiddenItems);
  }, [props.hiddenItems]);

  const canSelectDependency = useCallback((item: MigrationDependencyItem) => {
    return !isDependencyHidden(item, props.hiddenItems);
  }, [props.hiddenItems]);

  useEffect(() => {
    if (loading) {
      return;
    }

    setLoading(true);

    const loadData = async () => {
      const sourceData = { ...props.sourceData };
      const targetData = { ...props.targetData };
      const migrationData = { ...props.migrationData };
      const selections = { ...props.selections };
      const loadTypes = { ...migrationData.options };
      const typesWithNoData: MigrationType[] = [];
      const loadQueue: MigrationType[] = [];

      const blankoIds: string[] = [];

      // Figure out if any more data needs to be loaded based on dependencies
      const addLoadTypesFromDependencies = (diffs: DiffItem[]) => {
        diffs.forEach(diff => {
          diff.dependencies.forEach(dep => {
            if (!loadTypes[dep.type]) {
              loadTypes[dep.type] = true;
              loadQueue.push(dep.type);
            }
          })
        })
      }

      // Build the dependency tree and selection map
      function addDiffData<T extends DiffItem>(type: MigrationType, data: MigrationDiffData<T>) {
        const addedAndChanged = data.added.concat(data.changed);
        // Add new or changed items to the dependency tree
        migrationData.depTree.addDiffItems(addedAndChanged);
        // We now know exactly what changed in this "type". Remove any dependencies that we know did not change.
        migrationData.depTree.removeDependenciesExcept(type, addedAndChanged);
        addLoadTypesFromDependencies(addedAndChanged);

        if (addedAndChanged.length > 0) {
          const selectionMap: { [key: string]: boolean } = {};
          if (migrationData.options[type]) { // Select all items if this is a user-selected type
            addedAndChanged.forEach(item => {
              selectionMap[item.dependencyItem.id] = canSelect(item) && (modules[type]?.inDefaultSelection?.(item) !== false);
            });
          }
          selections[type] = selectionMap;
        } else if (addedAndChanged.length + data.removed.length < 1) {
          typesWithNoData.push(type);
        }
      }

      // Add the user-selected types to the load queue
      for (const id of props.runOrder) {
        if (loadTypes[id]) {
          loadQueue.push(id);
        }
      }

      // Load everything...
      while (loadQueue.length > 0) {
        const loadType = loadQueue.shift() as MigrationType;

        const migrationModule = props.modules[loadType];
        if (migrationModule) {
          // If the objects can't be migrated within the same environment, skip...
          if (sourceData.env === targetData.env && migrationModule.crossEnvOnly) {
            typesWithNoData.push(loadType);
            continue;
          }

          setStatus(migrationModule.displayName);
          const data = await migrationModule.loadData(sourceData, targetData, migrationData);
          if (data) {
            addDiffData(loadType, data);
          }
        } else {
          console.error(`Migration module not found: ${loadType}`);
        }

        // If any blankos are being migrated, load DNAs as well
        if (loadType === 'item' && sourceData.catalog?.items && targetData.catalog?.items && migrationData.items) {
          const uuidRegex = /^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/i;
          migrationData.items.added.concat(migrationData.items.changed).forEach(diff => {
            if (diff.item.itemClass === 'Blanko' && uuidRegex.test(diff.item.itemId)) {
              blankoIds.push(diff.item.itemId);
            }
          });

          if (blankoIds.length > 0 && !loadTypes.dna) {
            loadTypes.dna = true;
            loadQueue.push(MigrationType.dna);
          }
          if (blankoIds.length > 0 && !loadTypes.blankoProgression) {
            loadTypes.blankoProgression = true;
            loadQueue.push(MigrationType.blankoProgression);
          }
        }

        // If any blankos are migrated, set up blanko-dna dependencies
        // These dependencies are special because right now we have to assume a blanko depends on all versions of a DNA
        if (loadType === 'dna' && migrationData.blankoDnas && blankoIds.length > 0) {
          migrationData.blankoDnas.added.concat(migrationData.blankoDnas.changed).forEach(diff => {
            migrationData.depTree.add({ type: MigrationType.item, id: diff.dna.Id }, [{ type: MigrationType.dna, id: diff.diffId }]);
          });
        }

        // If any blankos are migrated, set up blanko-progression dependencies
        if (loadType === 'blankoProgression' && migrationData.blankoProgressions && blankoIds.length > 0) {
          migrationData.blankoProgressions.added.concat(migrationData.blankoProgressions.changed).forEach(diff => {
            diff.blankoProgressions.dnaIds.forEach(dna => {
              migrationData.depTree.add({ type: MigrationType.item, id: dna }, [{ type: MigrationType.blankoProgression, id: diff.diffId }]);
            });
          });
        }

        if (loadType === 'translation') {
          setStatus('Translations');
          sourceData.translations = await CatalogMigrationService.getTranslations(sourceData.env, sourceData.catalogName);
          targetData.translations = await CatalogMigrationService.getTranslations(targetData.env, targetData.catalogName);

          migrationData.translations = buildDiffData(
            sourceData.translations.map(v => new TranslationDiff(v)),
            targetData.translations.map(v => new TranslationDiff(v))
          );

          addDiffData(loadType, migrationData.translations);
        } else if (loadType === 'textLanguage') {
          setStatus('Translations');
          sourceData.textLanguages = await CatalogMigrationService.getTextLanguages(sourceData.env, sourceData.catalogName);
          targetData.textLanguages = await CatalogMigrationService.getTextLanguages(targetData.env, targetData.catalogName);

          migrationData.textLanguages = buildDiffData(
            sourceData.textLanguages.map(v => new TextLanguageDiff(v)),
            targetData.textLanguages.map(v => new TextLanguageDiff(v))
          );

          addDiffData(loadType, migrationData.textLanguages);
        }
      }

      // Any dependency types loaded?
      if (Object.keys(loadTypes).length > Object.keys(migrationData.options).length) {
        // Go through every user-selected type...
        for (const type in migrationData.options) {
          // Through every item...
          for (const id in Object.keys(selections[type as MigrationType] as {[key: string]: boolean} || {})) {
            // And select only the dependencies
            for (const dep of migrationData.depTree.getDependencies({ type: type as MigrationType, id }, true)) {
              const selection = selections[dep.type];
              if (selection) {
                selection[dep.id] = canSelectDependency(dep);
              }
            }
          }
        }
      }

      // Remove any object types that had no changes
      typesWithNoData.forEach(type => loadTypes[type] = false);

      migrationData.userOptions = migrationData.options;
      migrationData.options = loadTypes;

      onSourceChange(sourceData);
      onTargetChange(targetData);
      onMigrationDataChange(migrationData);
      onSelectionsChange(selections);
      onNextStep();
    }

    loadData().catch(e => {
      console.error(e);
      pushAppNotification({ type: 'error', message: 'Failed to load migration data' });
    });
  }, [loading, onSourceChange, onTargetChange, onMigrationDataChange, onSelectionsChange, onNextStep,
    props.migrationData, props.sourceData, props.targetData, props.selections, props.modules, props.runOrder,
    canSelect, canSelectDependency, modules]);

  return (
    <Box textAlign="center">
      <Typography>
        Hang on... collecting even more data...
      </Typography>
      <Box mt={2}>
        <CircularProgress />
      </Box>
      <Box mt={2}>
        {status}
      </Box>
    </Box>
  )
}

import type { ApiError } from './api';
import { api } from './api';
import { MythicalAccount } from './model/mythical-account';

export interface MythicalAccountsPageRequest {
  search: string;
  page: number;
  pageSize: number;
}

export interface BetaGrantResponse {
  playersAddedCount: number;
  notAddedReasons: { [key: string]: string };
}

export class MythicalAccountsService {
  static async getMythicalAccounts(request: MythicalAccountsPageRequest): Promise<MythicalAccount[]> {
    return api.get<any[]>({ url: '/players/accounts', query: request })
      .then(accounts => accounts.map(v => new MythicalAccount(v)))
      .catch((e: ApiError) => {
        e.message = `Failed to get accounts. ${e.message}`;
        throw e;
      });
  }

  static async getMythicalAccount(id: string): Promise<MythicalAccount> {
    return api.get({ url: `/players/accounts/${id}` })
      .then(account => new MythicalAccount(account))
      .catch((e: ApiError) => {
        e.message = `Failed to get account. ${e.message}`;
        throw e;
      });
  }

  static async updateMythicalAccount(account: MythicalAccount): Promise<MythicalAccount> {
    const body = {
      firstName: account.firstName,
      lastName: account.lastName,
      email: account.email,
      displayName: account.displayName
    };
    return api.put({ url: `/players/accounts/${account.id}`, body })
      .then(account => new MythicalAccount(account))
      .catch((e: ApiError) => {
        e.message = `Failed to update account. ${e.message}`;
        throw e;
      });
  }

  static async sendVerificationEmail(account: MythicalAccount): Promise<any> {
    return api.put({ url: `/players/accounts/${account.id}/verification-email`, body: {} })
      .catch((e: ApiError) => {
        e.message = `Failed to send verification email. ${e.message}`;
        throw e;
      });
  }

  static async sendPasswordResetEmail(account: MythicalAccount): Promise<any> {
    return api.put({ url: `/players/accounts/${account.id}/reset-password-email`, body: {} })
      .catch((e: ApiError) => {
        e.message = `Failed to send password reset email. ${e.message}`;
        throw e;
      });
  }

  static async updateBetaAccess(emails: string[], allowAccess: boolean): Promise<BetaGrantResponse> {
    return api.post({ url: '/players/accounts/beta-access', body: { emails, allowAccess } })
      .catch((e: ApiError) => {
        e.message = `Failed to update beta access. ${e.message}`;
        throw e;
      });
  }
}
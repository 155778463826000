import { useCallback, useMemo, useState } from "react";
import { Box, Button, Grid } from "@material-ui/core";
import type { Column } from "material-table";
import DeleteIcon from "@material-ui/icons/Delete";
import MaterialTable, { MenuAction } from "../../../components/MaterialTable";
import type { BrawlPinUnlockCondition } from "../../../services/model/brawl-pin";
import _ from "lodash";
import { UserService } from "../../../services/user";
import { BrawlPinTierViewModel } from "./BrawlPinsViewModels";
import { UnlockConditions } from "./UnlockConditions";
import { AddEditUnlockConditionDialog } from "./AddEditUnlockConditionDialog";

/**
 * Concatenates the parameters together into a single comma-separated string.
 */
export const getUnlockConditionParams = (condition: BrawlPinUnlockCondition): string => {
  return (condition.parameters && !_.isEmpty(condition.parameters))
    ? Object.keys(condition.parameters).map((key: string) =>
      `${key}=${condition.parameters ? _.isString(condition.parameters[key]) ? `'${condition.parameters[key]}'` : condition.parameters[key] : ''}`).join(', ') : '-';
}

interface ConditionGroup {
  index: number;
  unlockConditions: BrawlPinUnlockCondition[];
}

interface Props {
  tierViewModel: BrawlPinTierViewModel;
  deleteConditionGroupFromTier: (tier: BrawlPinTierViewModel, groupIndex: number) => void;
  deleteConditionFromTierGroup: (condition: BrawlPinUnlockCondition, tier: BrawlPinTierViewModel, groupIndex: number) => void;
  upsertUnlockConditionForTierGroup: (condition: BrawlPinUnlockCondition, tier: BrawlPinTierViewModel, groupIndex: number, checkUnlockType: boolean) => void;
  performActionWithValidation: (action: () => void) => void;
}

export const ConditionGroups = (props: Props) => {
  const {
    tierViewModel,
    deleteConditionGroupFromTier,
    deleteConditionFromTierGroup,
    performActionWithValidation,
    upsertUnlockConditionForTierGroup
  } = props;

  const [showAddConditionDialog, setShowAddConditionDialog] = useState(false);

  const handleClose = useCallback(() => {
    setShowAddConditionDialog(false);
  }, [])

  const deleteConditionGroup = useCallback((conditionGroup: ConditionGroup) => {
    deleteConditionGroupFromTier(tierViewModel, conditionGroup.index);
  }, [tierViewModel, deleteConditionGroupFromTier]);

  const conditionGroupMenuActions: MenuAction<ConditionGroup>[] = useMemo(() => {
    const menuActions: MenuAction<ConditionGroup>[] = [];
    if (UserService.canDelete('brawlPins')) {
      menuActions.push({ type: 'button', icon: DeleteIcon, label: 'Delete Condition Group', onClick: deleteConditionGroup });
    }
    return menuActions;
  }, [deleteConditionGroup]);


  const conditionGroups = useMemo(() => {
    if (!tierViewModel)
      return [];

    return tierViewModel.unlockConditionGroups.map((ucg, index) => {
      return {
        index: index,
        unlockConditions: ucg
      };
    })
  }, [tierViewModel]);

  const createUnlockConditionAndGroup = useCallback((condition: BrawlPinUnlockCondition, checkUnlockType: boolean): void => {
    const groupIndex = conditionGroups.length;
    upsertUnlockConditionForTierGroup(condition, tierViewModel, groupIndex, checkUnlockType);
  }, [conditionGroups, tierViewModel, upsertUnlockConditionForTierGroup]);

  const deleteConditionFromGroup = useCallback((condition: BrawlPinUnlockCondition, groupIndex: number) => {
    deleteConditionFromTierGroup(condition, tierViewModel, groupIndex);
  }, [tierViewModel, deleteConditionFromTierGroup]);

  const upsertUnlockConditionForGroup = useCallback((condition: BrawlPinUnlockCondition, groupIndex: number, checkUnlockType: boolean): void => {
    upsertUnlockConditionForTierGroup(condition, tierViewModel, groupIndex, checkUnlockType);
  }, [tierViewModel, upsertUnlockConditionForTierGroup]);

  const groupTableColumns: Column<ConditionGroup>[] = useMemo(() => {
    return [
      {
        render: conditionGroup => (<>
          <UnlockConditions
            groupIndex={conditionGroup.index}
            unlockConditions={conditionGroup.unlockConditions}
            deleteConditionFromGroup={deleteConditionFromGroup}
            performActionWithValidation={performActionWithValidation}
            upsertUnlockConditionForGroup={upsertUnlockConditionForGroup}
          />
        </>
        )
      }
    ];
  }, [deleteConditionFromGroup, performActionWithValidation, upsertUnlockConditionForGroup]);

  return (<>
    <Grid item xs={12}>
      <Box mb={2}>
        <Grid container justify="flex-end">
          {UserService.canCreate('brawlPins') && (<>
            <Grid item>
              <Button
                variant="contained"
                size="small"
                color="primary"
                onClick={() => performActionWithValidation(() => setShowAddConditionDialog(true))}
              >
                Add Condition Group
              </Button>
            </Grid>
          </>)}
        </Grid>
      </Box>
      <MaterialTable
        columns={groupTableColumns}
        data={conditionGroups}
        options={{
          header: false,
          paging: false,
          search: false,
          toolbar: false,
          sorting: false,
          showTitle: false
        }}
        menuActions={conditionGroupMenuActions}
      />
    </Grid>

    {(showAddConditionDialog) && <AddEditUnlockConditionDialog
      existingCondition={undefined}
      upsertUnlockCondition={createUnlockConditionAndGroup}
      onClose={handleClose}
    />}
  </>
  );
};

import { Box, CircularProgress } from '@material-ui/core';
import DataMapEditor from '../../components/DataMapEditor';
import type { Blanko } from '../../services/blankos';
import type { PlayerDataField } from '../players/players';
import { UserService } from '../../services/user';
import { BlankosService } from '../../services/blankos';
import { pushAppNotification } from '../../shared/hooks/useAppNotification';
import { useCallback, useState } from 'react';

type Props = {
  incomingBlanko?: Blanko;
}

export const BlankoData = ({ incomingBlanko }: Props) => {

  const [blanko, setBlanko] = useState<Blanko | undefined>(incomingBlanko);

  const refreshPage = useCallback(async () => {
    if (blanko) {
      const refreshed = await BlankosService.getBlanko(blanko.id);
      setBlanko(refreshed);
    }
  }, [blanko]);

  const onSave = useCallback(async (data: { [key: string]: PlayerDataField }) => {

    if (blanko) {
      const dataUpdate: { [key: string]: PlayerDataField | null } = {};

      for (const key in data) {
        const oldField = blanko.blankoData[key];
        const newField = data[key];
        if (!oldField || !fieldsEqual(oldField, newField)) {
          dataUpdate[key] = newField;
        }
      }

      for (const key in blanko.blankoData) {
        if (!data[key]) {
          dataUpdate[key] = null;
        }
      }

      await BlankosService.updateBlankoData(blanko.id, dataUpdate);
      await refreshPage();
      pushAppNotification({ type: 'success', message: `Blanko data updated` });
    }
  }, [blanko, refreshPage]);

  const fieldsEqual = (oldField: PlayerDataField, newField: PlayerDataField) => {
    return oldField.ownerPermission === newField.ownerPermission &&
      oldField.othersPermission === newField.othersPermission &&
      oldField.value === newField.value;
  }

  return blanko ?
    (<DataMapEditor data={blanko.blankoData} onSave={onSave} readOnly={!UserService.canUpdate('blankoData')} />)
    :
    (<Box textAlign="center">
      <CircularProgress />
    </Box>)
}
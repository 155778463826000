type FormError<T> = string | undefined | void | { [K in keyof T]?: FormError<T[K]> };
type ValidatorFn<T> = (value: T) => FormError<T> | FormError<T>[];
type ValidatorFns<T> = { [K in keyof T]?: ValidatorFn<T[K]> };
type FlatErrors<T> = { [K in keyof T]?: FormError<T[K]> };

export function createValidator<T>(validators: ValidatorFns<T>, customValidator?: (values: T, errors: FlatErrors<T>) => FlatErrors<T> | undefined) {
  return (values: T): FlatErrors<T> | undefined => {
    const errors: FlatErrors<T> = {};
    let hasErrors = false;
    for (const key in validators) {
      const fn = validators[key];
      if (fn) {
        const error = fn(values[key]);
        if (error) {
          hasErrors = true;
          errors[key] = error;
        }
      }
    }

    if (customValidator) {
      const customErrors = customValidator(values, errors);
      for (const key in customErrors) {
        if (customErrors[key]) {
          hasErrors = true;
          errors[key] = customErrors[key];
        }
      }
    }

    return hasErrors ? errors : undefined;
  };
}

export function combineValidators<T>(validators: ((values: T) => FlatErrors<T> | undefined)[]) {
  return (values: T): FlatErrors<T> | undefined => {
    let hasErrors = false;
    let errors: FlatErrors<T> = {};
    validators.forEach(validator => {
      const innerErrors = validator(values);
      if (innerErrors) {
        errors = { ...errors, ...innerErrors };
        hasErrors = true;
      }
    });

    return hasErrors ? errors : undefined;
  };
}

export class Validators {
  static blank(validator: ValidatorFn<string>): ValidatorFn<string> {
    return (value: string) => {
      return !value || !value.trim() ? undefined : validator(value);
    };
  }

  static notBlank(): ValidatorFn<string> {
    return (value: string) => {
      return value && value.trim() ? undefined : 'Cannot be blank';
    };
  }

  static maxSize(max: number): ValidatorFn<string> {
    return (value: string) => {
      return value.length <= max ? undefined : `Max length of ${max}`;
    };
  }

  static number(min?: number, max?: number, integer?: boolean): ValidatorFn<string> {
    let error: string;
    let fn: (n: number) => boolean = () => true;
    if (typeof min === 'number') {
      if (typeof max === 'number') {
        error = `Must be between ${min} and ${max}`;
        fn = n => n >= min && n <= max;
      } else {
        error = `Must be ${min} or greater`;
        fn = n => n >= min;
      }
    } else if (typeof max === 'number') {
      error = `Must be ${max} or less`;
      fn = n => n <= max;
    }

    return (value: string) => {
      const n = parseFloat(value);
      if (isNaN(n)) {
        return 'Must be a number';
      } else if (integer && (!Number.isInteger(n) || !/^-?\d+$/i.test(value.trim()))) {
        return 'Must be an integer';
      } else if (!fn(n)) {
        return error;
      }
    };
  }

  static integer(min?: number, max?: number): ValidatorFn<string> {
    return Validators.number(min, max, true);
  }

  static email(): ValidatorFn<string> {
    return (value: string) => {
      if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]+$/i.test(value)) {
        return 'Invalid email';
      }
    };
  }

  static all<T>(validators: ValidatorFn<T>[]): ValidatorFn<T> {
    return (value: T) => {
      for (let i = 0; i < validators.length; i++) {
        const error = validators[i](value);
        if (error) {
          return error;
        }
      }
    };
  }

  static array<V, T extends Array<V>>(validator: ValidatorFn<V>): ValidatorFn<T> {
    return (values: T) => {
      const errors = values.map(value => validator(value));
      return errors.find(v => !!v) ? errors : undefined;
    };
  }
}

export enum MigrationType {
  item = "item",
  droptable = "droptable",
  store = "store",
  shopVersion = "shopVersion",
  dna = "dna",
  blankoProgression = "blankoProgression",
  blankoAsset = "blankoAsset",
  seasonalData = "seasonalData",
  titleDataClient = "titleDataClient",
  titleDataServer = "titleDataServer",
  titleFile = "titleFile",
  gemRush = "gemRush",
  blankoClass = "blankoClass",
  roles = "roles",
  brawlPins = "brawlPins",
  itemNotes = "itemNotes",
  liveEvents = "liveEvents",
  translation = "translation",
  textLanguage = "textLanguage",
  brawlPinTitleData = "brawlPinTitleData",
  geoblocking = "geoblocking",
  challengeTriggers = "challengeTriggers",
  junctionEventInfo = "junctionEventInfo",
}

import { createAsyncAction } from 'typesafe-actions';
import type { CurrencyLimit } from '../../services/currency-limits';

export const getCurrencyLimitsAsync = createAsyncAction(
  'LOAD_CURRENCY_LIMITS_REQUEST',
  'LOAD_CURRENCY_LIMITS_SUCCESS',
  'LOAD_CURRENCY_LIMITS_FAILURE'
)<void, CurrencyLimit[], string>();

export const addCurrencyLimitAsync = createAsyncAction(
  'ADD_CURRENCY_LIMIT_REQUEST',
  'ADD_CURRENCY_LIMIT_SUCCESS',
  'ADD_CURRENCY_LIMIT_FAILURE'
)<CurrencyLimit, CurrencyLimit, string>();

export const deleteCurrencyLimitAsync = createAsyncAction(
  'DELETE_CURRENCY_LIMIT_REQUEST',
  'DELETE_CURRENCY_LIMIT_SUCCESS',
  'DELETE_CURRENCY_LIMIT_FAILURE'
)<CurrencyLimit, CurrencyLimit, string>();

export const updateCurrencyLimitAsync = createAsyncAction(
  'UPDATE_CURRENCY_LIMIT_REQUEST',
  'UPDATE_CURRENCY_LIMIT_SUCCESS',
  'UPDATE_CURRENCY_LIMIT_FAILURE'
)<CurrencyLimit, CurrencyLimit, string>();

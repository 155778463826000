import { Box, Button, Grid, IconButton, Paper, TextField, Tooltip } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import ReplayIcon from '@material-ui/icons/Replay';
import { Component } from 'react';
import type { ConnectedProps } from 'react-redux';
import { connect } from 'react-redux';
import { openConfirmationDialog, setAppNotification } from '../redux/app/actions';
import JsonEditor from './JsonEditor';
import type { RootState } from '../redux/reducers';

interface DataRow {
  id: number;
  key: string;
  keyError: string;
  value: string;
  isDeleted: boolean;
  isNew: boolean;
  jsonEditorOpen: boolean;
}

interface RowProps {
  row: DataRow;
  onUpdate: (row: DataRow) => void;
  readOnly?: boolean;
  darkMode: boolean;
}

interface RowState {
  key: string;
  value: string;
}

class KeyValueRowEditor extends Component<RowProps, RowState>{
  constructor(props: RowProps) {
    super(props);
    this.state = {
      key: props.row.key,
      value: props.row.value
    };
  }

  shouldComponentUpdate(nextProps: RowProps, nextState: RowState) {
    if (this.state === nextState) {
      if (this.props.row === nextProps.row && this.props.darkMode === nextProps.darkMode) {
        return false;
      }
    }
    return true;
  }

  componentDidUpdate(prevProps: RowProps) {
    if (prevProps !== this.props) {
      const { key, value } = this.props.row;
      if (this.state.key !== key) {
        this.setState({ key });
      }
      if (this.state.value !== value) {
        this.setState({ value });
      }
    }
  }

  onKeyChange = (event: any) => {
    this.setState({ key: event.target.value });
  };

  onValueChange = (event: any) => {
    const value = event.target.value as string;
    this.setState({ value });
  };

  render() {
    const row = this.props.row;
    return (
      <Paper elevation={2} style={{ backgroundColor: row.isDeleted ? (this.props.darkMode ? '#380000' : '#fff6f6') : undefined }}>
        <Box m={1}>
          <Grid container spacing={2} alignItems="center">
            {!this.props.readOnly && (
              <Grid item xs="auto">
                {row.isDeleted ? (
                  <Tooltip title="Restore">
                    <IconButton
                      size="small"
                      onClick={() => this.props.onUpdate({ ...this.props.row, isDeleted: false })}
                      tabIndex={-1}
                    >
                      <ReplayIcon />
                    </IconButton>
                  </Tooltip>
                ) : (
                  row.isNew ? <IconButton size="small" disabled><DeleteIcon /></IconButton> : (
                    <Tooltip title="Delete">
                      <IconButton
                        size="small"
                        onClick={() => this.props.onUpdate({ ...this.props.row, isDeleted: true })}
                        tabIndex={-1}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </Tooltip>

                  ))}
              </Grid>
            )}
            <Grid container item xs spacing={2}>
              <Grid item xs={12} md={4} lg={3} xl={2}>
                <TextField
                  label="Key"
                  value={this.state.key}
                  onChange={this.onKeyChange}
                  onBlur={event => this.props.onUpdate({ ...this.props.row, key: event.target.value })}
                  fullWidth
                  disabled={row.isDeleted}
                  error={!!row.keyError}
                  helperText={row.keyError}
                  InputProps={{
                    readOnly: this.props.readOnly
                  }}
                />
              </Grid>
              <Grid container item xs spacing={2} alignItems={row.jsonEditorOpen ? 'flex-start' : 'center'}>
                <Grid item xs>
                  {row.jsonEditorOpen ? (
                    <JsonEditor
                      title="Edit JSON"
                      json={this.state.value}
                      onJsonChange={value => this.setState({ value })}
                      onBlur={() => this.props.onUpdate({ ...this.props.row, value: this.state.value })}
                      readOnly={this.props.readOnly}
                      focus
                    />
                  ) : (
                    <TextField
                      label="Value"
                      value={this.state.value}
                      onChange={this.onValueChange}
                      onBlur={event => this.props.onUpdate({ ...this.props.row, value: event.target.value })}
                      fullWidth
                      disabled={row.isDeleted}
                      InputProps={{
                        readOnly: this.props.readOnly
                      }}
                    />
                  )}
                </Grid>
                <Grid item xs="auto">
                  <Button
                    disabled={row.isDeleted}
                    onClick={() => this.props.onUpdate({ ...this.props.row, jsonEditorOpen: !this.props.row.jsonEditorOpen })}
                    tabIndex={-1}
                  >
                    {row.jsonEditorOpen ? 'Close' : 'JSON Editor'}
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </Paper>
    );
  }
}

const mapStateToProps = (state: RootState) => ({ darkMode: state.app.darkMode });
const mapDispatch = { openConfirmationDialog, setAppNotification };
const connector = connect(mapStateToProps, mapDispatch);

type Props = ConnectedProps<typeof connector> & {
  data: { [key: string]: string; };
  onSave: (data: { [key: string]: string | null; }) => void;
  readOnly?: boolean;
};

interface State {
  rows: DataRow[];
  rowsDeleted: number;
}

class KeyValueEditor extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      rows: this.getRowsFromData(this.props.data),
      rowsDeleted: 0
    };
  }

  componentDidUpdate(prevProps: Props) {
    if (prevProps.data !== this.props.data) {
      this.setState({ rows: this.getRowsFromData(this.props.data), rowsDeleted: 0 });
    }
  }

  getRowsFromData(data: { [key: string]: string; }) {
    const keys = Object.keys(data);
    keys.sort((a, b) => a.localeCompare(b));
    const rows = keys.map((key, index) => this.getRow(index, key, data[key]));
    rows.push(this.getRow(rows.length));
    return rows;
  }

  getRowsDeleted(rows: DataRow[]) {
    return rows.filter(row => row.isDeleted).length;
  }

  getRow(id: number, key = '', field?: string): DataRow {
    const isNew = !key && !field;
    field = field || '';

    return {
      id,
      key,
      keyError: '',
      value: field,
      isDeleted: false,
      isNew,
      jsonEditorOpen: false
    };
  }

  updateRow = (id: number, row: DataRow) => {
    const rows = this.state.rows.slice();
    const oldRow = rows[id];
    rows[id] = row;

    if (row.isDeleted) {
      row.jsonEditorOpen = false;
    } else if (row.jsonEditorOpen && !oldRow.jsonEditorOpen) { // Only allow one JSON editor open at a time
      const otherRow = rows.find(v => v.jsonEditorOpen && v.id !== row.id);
      if (otherRow) {
        rows[otherRow.id] = { ...otherRow, jsonEditorOpen: false };
      }
    }

    if (row.isNew && (row.key || row.value)) {
      row.isNew = false;
      rows.push(this.getRow(rows.length));
    }

    if (row.value && !row.key.trim()) {
      row.keyError = 'This is required';
    } else if (oldRow.key !== row.key && row.key.trim()) {
      row.keyError = '';
    }

    this.setState({ rows, rowsDeleted: this.getRowsDeleted(rows) });
  };

  onSave = () => {
    const rows = this.state.rows.filter(row => !row.isDeleted && !row.isNew);

    if (this.checkValidity(rows)) {
      return;
    }

    const data: { [key: string]: string | null; } = {};
    rows.forEach(row => {
      if (row.value !== this.props.data[row.key]) {
        data[row.key] = row.value;
      }
    });

    this.state.rows.forEach(row => {
      if (row.isDeleted) {
        data[row.key] = null;
      }
    });

    if (Object.keys(data).length < 1) {
      this.props.setAppNotification({ type: 'info', message: 'There was nothing to save' });
      return;
    }

    if (this.state.rowsDeleted > 0) {
      const title = `Save data? ${this.state.rowsDeleted}${this.state.rowsDeleted > 1 ? ' keys' : ' key'} will be deleted`;
      this.props.openConfirmationDialog({ title, action: 'Save' }, () => {
        this.props.onSave(data);
      });
    } else {
      this.props.onSave(data);
    }
  };

  checkValidity(rows: DataRow[]) {
    const rowMap: { [key: string]: DataRow; } = {};
    for (let i = 0; i < rows.length; i++) {
      const row = rows[i];
      if (!row.key.trim()) {
        return true;
      }

      if (rowMap[row.key]) {
        const prevRow = rowMap[row.key];
        this.showUniqueKeyErrors([prevRow.id, row.id]);
        return true;
      }
      rowMap[row.key] = row;
    }

    return false;
  }

  showUniqueKeyErrors = (ids: number[]) => {
    const rows = this.state.rows.slice();
    ids.forEach(id => rows[id] = { ...rows[id], keyError: 'Must be unique' });
    this.setState({ rows });
  };

  render() {
    const rows = this.state.rows.map(row => (
      <Grid key={row.id} item xs={12}>
        <KeyValueRowEditor readOnly={this.props.readOnly} row={row} onUpdate={updatedRow => this.updateRow(row.id, updatedRow)} darkMode={this.props.darkMode} />
      </Grid>
    ));

    return (
      <Grid container spacing={2}>
        {rows}

        {!this.props.readOnly && (
          <Grid item xs={12}>
            <Button variant="contained" color="primary" onClick={this.onSave}>
              Save {this.state.rowsDeleted > 0 && (`(Delete ${this.state.rowsDeleted} ${this.state.rowsDeleted > 1 ? 'keys' : 'key'})`)}
            </Button>
          </Grid>
        )}
      </Grid>
    );
  }
}

export default connector(KeyValueEditor);
/* eslint-disable no-async-promise-executor */
import axios from 'axios';
import config from './config'
import { store } from './redux/store';
import type { UserManager } from 'oidc-client-ts'

const { baseUrl, authUrl, version } = config.apis.mythical;

const instance = axios.create({ 
  baseURL: baseUrl, 
  headers: {
    'Content-Type': 'application/json',
    'Version': version
  } 
});

const authApi = axios.create({ 
  baseURL: authUrl, 
  headers: {
    'Content-Type': 'application/json',
  } 
});

export function saveToken(access_token?: string, refresh_token?: string) {
  sessionStorage.setItem('access_token', access_token || '');
  sessionStorage.setItem('refresh_token', refresh_token || '');
}
export function destroyToken() {
  const refresh_token = sessionStorage.getItem('refresh_token');
  if(refresh_token) {
    authApi.post('api/logout', {'refreshToken': refresh_token}, {withCredentials: false});
  }
  sessionStorage.removeItem('access_token');
  sessionStorage.removeItem('refresh_token');
}
export function refreshToken(auth?: UserManager){
  return new Promise(async(resolve, reject) => {
    const user = await auth?.getUser();
    if(auth && user && (user.expires_in ?? 0) < 30) {
      try{
        console.log('Refreshign oAuth token and retrying...');
        await auth.signinSilent().then(user => {
          if(user) {
            console.log('Token was successfully refreshed'); 
            saveToken(user.access_token, user.refresh_token);
            return resolve(user.access_token);
          } else {
            console.error('No authenticated user returend');
            return reject('Session expired, refresh page to log back in');
          }
        })
          .catch((e) => {
            console.log('Token was not refreshed'); 
          });
      } catch(e) {
        destroyToken();
        console.error('Failed to refresh auth token', e);
        return reject('Session expired, refresh page to log back in');
      }
    }
  });
}

instance.interceptors.response.use(
  (res) => res,
  async function (error) {
    const auth = store.getState().authentication.oAuthClient;
    const user = await auth?.user;
    const status = error.response ? error.response.status : null;
    const request = error.config;
    if (status === 401 && user && (user.expires_in ?? 0) <= 30) {
      await refreshToken(auth?.userManager);
      const access_token = sessionStorage.getItem('access_token');
      axios.defaults.headers.common['Authorization'] = `Bearer ${access_token}`;
      return instance(request);
    }
    // status might be undefined
    if (!status) {
      await refreshToken(auth?.userManager);
      const access_token = sessionStorage.getItem('access_token');
      axios.defaults.headers.common['Authorization'] = `Bearer ${access_token}`;
      return instance(request);
    }
    return Promise.reject(error);
  }
);

instance.interceptors.request.use((config) => {
  const access_token = sessionStorage.getItem('access_token');
  config.headers.Authorization = `Bearer ${access_token}`;
  return config;
}, error => {
  Promise.reject(error)
});

export default instance;
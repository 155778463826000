import { Field, FieldArray, FormikProps } from "formik";
import { JunctionBeam, JunctionNpc, JunctionObject, JunctionObjectItemIdType } from "../live-events-types";
import { Box, Button, Card, CardContent, CardHeader, createStyles, Grid, IconButton, makeStyles, MenuItem, Theme } from "@material-ui/core";
import { FormikSelectWithLabel } from "../../../components/FormikSelectWithLabel";
import DeleteIcon from "@material-ui/icons/Delete";
import { Fragment, useCallback, useState } from "react";
import ItemSelectDialog from "../../../pages/ItemDefinition/ItemSelectDialog";
import { ItemDefinition } from "../../../services/item-definitions";
import { DropTable } from "../../../services/drop-tables";
import { usePushNotification } from "../../../contexts/AppNotificationContext";
import { CheckboxWithLabel, TextField } from "formik-material-ui";
import { JunctionEventInfoViewModel } from "./JunctionEventInfoEditor";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    form: {
      display: 'flex',
      flexDirection: 'column',
      margin: 'auto',
      width: 'fit-content',
      marginBottom: 8,
    },
    formControl: {
      // marginLeft: theme.spacing(3),
      // marginRight: theme.spacing(1),
      minWidth: 30,
    },
  }),
);

const getRGBColors = (valueStr: string): number[] => {
  let r = 0;
  let g = 0;
  let b = 0;
  if (valueStr) {
    const rgbStrs = valueStr.split(',');
    if (rgbStrs.length > 0) {
      const str = rgbStrs[0].trim();
      r = parseInt(str);
    }
    if (rgbStrs.length > 1) {
      const str = rgbStrs[1].trim();
      g = parseInt(str);
    }
    if (rgbStrs.length > 2) {
      const str = rgbStrs[2].trim();
      b = parseInt(str);
    }
  }
  return [r, g, b];
};

const getXYZValues = (valueStr: string): number[] => {
  let x = 0;
  let y = 0;
  let z = 0;
  if (valueStr) {
    const xyzStrs = valueStr.split(',');
    if (xyzStrs.length > 0) {
      const str = xyzStrs[0].trim();
      x = parseFloat(str);
    }
    if (xyzStrs.length > 1) {
      const str = xyzStrs[1].trim();
      y = parseFloat(str);
    }
    if (xyzStrs.length > 2) {
      const str = xyzStrs[2].trim();
      z = parseFloat(str);
    }
  }
  return [x, y, z];
};

export class JunctionObjectViewModel {
  type = JunctionObjectItemIdType.CATALOG_ITEM_ID;
  enabled = true;
  catalogItemId?: string;
  objectId?: string;
  positionX = 0;
  positionY = 0;
  positionZ = 0;
  rotationX = 0;
  rotationY = 0;
  rotationZ = 0;

  constructor(obj: JunctionObject) {
    const positionValues: number[] = getXYZValues(obj.position.trim());
    const rotationValues: number[] = getXYZValues(obj.rotation.trim());
    this.type = obj.type;
    this.enabled = obj.enabled;
    this.catalogItemId = obj.catalogItemId;
    this.objectId = obj.objectId;
    this.positionX = positionValues[0];
    this.positionY = positionValues[1];
    this.positionZ = positionValues[2];
    this.rotationX = rotationValues[0];
    this.rotationY = rotationValues[1];
    this.rotationZ = rotationValues[2];
  }
}

export const toObject = (viewModel: JunctionObjectViewModel): JunctionObject => {
  return {
    type: viewModel.type,
    enabled: viewModel.enabled,
    catalogItemId: viewModel.catalogItemId,
    objectId: viewModel.objectId,
    position: `${viewModel.positionX},${viewModel.positionY},${viewModel.positionZ}`,
    rotation: `${viewModel.rotationX},${viewModel.rotationY},${viewModel.rotationZ}`,
  };
};

export class JunctionNpcViewModel extends JunctionObjectViewModel {
  communicationModalTitle = '';
  communicationModalDescription = '';

  constructor(npc: JunctionNpc) {
    super(npc);
    this.communicationModalTitle = npc.communicationModalTitle;
    this.communicationModalDescription = npc.communicationModalDescription;
  }
}

export const toNpc = (viewModel: JunctionNpcViewModel): JunctionNpc => {
  return {
    ...toObject(viewModel),
    communicationModalTitle: viewModel.communicationModalTitle,
    communicationModalDescription: viewModel.communicationModalDescription,
  };
};

export class JunctionBeamViewModel extends JunctionObjectViewModel {
  colorR = 0;
  colorG = 0;
  colorB = 0;
  radius = 0;

  constructor(beam: JunctionBeam) {
    super(beam);

    const colors: number[] = getRGBColors(beam.color.trim());
    this.colorR = colors[0];
    this.colorG = colors[1];
    this.colorB = colors[2];
    this.radius = beam.radius;
  }
}

export const toBeam = (viewModel: JunctionBeamViewModel): JunctionBeam => {
  return {
    ...toObject(viewModel),
    color: `${viewModel.colorR},${viewModel.colorG},${viewModel.colorB}`,
    radius: viewModel.radius,
  };
};

const defaultObject = (field: 'junctionObjects' | 'junctionNpcs' | 'junctionBeams'): JunctionObjectViewModel | JunctionNpcViewModel | JunctionBeamViewModel => {
  const data: any = {
    type: JunctionObjectItemIdType.CATALOG_ITEM_ID,
    enabled: true,
    catalogItemId: '',
    objectId: '',
    rotation: '',
    position: '',
  };
  if (field === 'junctionNpcs') {
    data.communicationModalTitle = '';
    data.communicationModalDescription = '';
  }
  if (field === 'junctionBeams') {
    data.color = '';
    data.radius = 0;
  }
  return data;
};

export interface Props {
  formikData: FormikProps<JunctionEventInfoViewModel>;
  field: 'junctionObjects' | 'junctionNpcs' | 'junctionBeams';
}

const getTypeName = (field: 'junctionObjects' | 'junctionNpcs' | 'junctionBeams') => {
  switch (field) {
  case "junctionObjects":
    return 'Object';
  case "junctionNpcs":
    return 'NPC';
  case "junctionBeams":
    return 'Beam';
  }
};

export const JunctionObjectEditor = ({formikData, field}: Props) => {
  const classes = useStyles();
  const type = getTypeName(field);
  const pushNotification = usePushNotification();
  const [itemSelectElementIndex, setItemSelectElementIndex] = useState<number>();
  const onItemSelected = useCallback((items: (ItemDefinition | DropTable)[]) => {
    if (items.length !== 1) {
      pushNotification({type: 'error', message: 'Can only select single item'});
      return;
    }
    const item = items[0] as ItemDefinition;
    formikData.setFieldValue(`${field}[${itemSelectElementIndex}].catalogItemId`, item.itemId);
  }, [field, formikData, itemSelectElementIndex, pushNotification]);
  const data = formikData.values[field];
  return <FieldArray name={field}>
    {(arrayHelpers) => (<>
      <Card>
        <CardHeader
          title={`Junction ${type}s`}
          titleTypographyProps={{variant: 'subtitle1'}}
          action={
            <Button variant="contained" color="primary" size="small" onClick={() => arrayHelpers.push(defaultObject(field))}>
              Add Junction {type}
            </Button>
          }
        />
        <CardContent>
          {data.length === 0 && <Grid item>No Junction {type}s.</Grid>}
          {data.map((junctionObject, index: number) => (
            <Fragment key={index}>
              <Card>
                <CardContent>
                  <Grid container>
                    <Box pb={1} width="100%">
                      <Box display="flex">
                        <Box width={200} pr={2}>
                          <Field
                            component={FormikSelectWithLabel}
                            type="text"
                            label="Type"
                            name={`${field}[${index}].type`}
                          >
                            {Object.values(JunctionObjectItemIdType).sort()
                              .map(type => <MenuItem key={type} value={type}>{type}</MenuItem>)
                            }
                          </Field>
                        </Box>
                        {formikData.values[field][index].type === JunctionObjectItemIdType.CATALOG_ITEM_ID && <>
                          <Box width={200}>
                            <Field
                              component={TextField}
                              type="text"
                              label="Item ID"
                              name={`${field}[${index}].catalogItemId`}
                            />
                          </Box>
                          <Box flexGrow="1" mt={2}>
                            <Button
                              type="button"
                              color="primary"
                              size="small"
                              disabled={typeof itemSelectElementIndex === 'number'}
                              variant="text"
                              onClick={() => setItemSelectElementIndex(index)}
                            >
                              Select Item
                            </Button>
                          </Box>
                        </>}
                        {formikData.values[field][index].type === JunctionObjectItemIdType.OBJECT_ID && <>
                          <Box flexGrow="1">
                            <Field
                              component={TextField}
                              type="text"
                              label="Object ID"
                              name={`${field}[${index}].objectId`}
                            />
                          </Box>
                        </>}
                        <Box width={30} ml={2} display="flex" flexDirection="column" justifyContent="center">
                          <IconButton aria-label="delete" size="small" onClick={() => arrayHelpers.remove(index)}>
                            <DeleteIcon/>
                          </IconButton>
                        </Box>
                      </Box>
                    </Box>
                    <Box pb={1} width="100%">
                      <Box display="flex">
                        <Box width={200} pr={2}>
                          <Field
                            component={TextField}
                            type="number"
                            label="Position X"
                            name={`${field}[${index}].positionX`}
                          />
                        </Box>
                        <Box width={200} pr={2}>
                          <Field
                            component={TextField}
                            type="number"
                            label="Position Y"
                            name={`${field}[${index}].positionY`}
                          />
                        </Box>
                        <Box flexGrow="1">
                          <Field
                            component={TextField}
                            type="number"
                            label="Position Z"
                            name={`${field}[${index}].positionZ`}
                          />
                        </Box>
                        <Box display="flex" flexDirection="column" justifyContent="center" className={classes.formControl}>
                          <Field
                            component={CheckboxWithLabel}
                            type="checkbox"
                            name={`${field}[${index}].enabled`}
                            Label={{ label: 'Active' }}
                          />
                        </Box>
                      </Box>
                    </Box>
                    <Box pb={1} width="100%">
                      <Box display="flex">
                        <Box width={200} pr={2}>
                          <Field
                            component={TextField}
                            type="number"
                            label="Rotation X"
                            name={`${field}[${index}].rotationX`}
                          />
                        </Box>
                        <Box width={200} pr={2}>
                          <Field
                            component={TextField}
                            type="number"
                            label="Rotation Y"
                            name={`${field}[${index}].rotationY`}
                          />
                        </Box>
                        <Box flexGrow="1">
                          <Field
                            component={TextField}
                            type="number"
                            label="Rotation Z"
                            name={`${field}[${index}].rotationZ`}
                          />
                        </Box>
                      </Box>
                    </Box>
                    {field === 'junctionNpcs' && <Box pb={1} width="100%">
                      <Box display="flex">
                        <Box width={200} pr={2}>
                          <Field
                            component={TextField}
                            type="text"
                            label="Modal Title"
                            name={`${field}[${index}].communicationModalTitle`}
                          />
                        </Box>
                        <Box width={200} pr={2}>
                          <Field
                            component={TextField}
                            type="text"
                            label="Modal Description"
                            name={`${field}[${index}].communicationModalDescription`}
                          />
                        </Box>
                      </Box>
                    </Box>}
                    {field === 'junctionBeams' && <>
                      <Box pb={1} width="100%">
                        <Box display="flex">
                          <Box width={200} pr={2}>
                            <Field
                              component={TextField}
                              type="number"
                              label="Red Color"
                              name={`${field}[${index}].colorR`}
                            />
                          </Box>
                          <Box width={200} pr={2}>
                            <Field
                              component={TextField}
                              type="number"
                              label="Green Color"
                              name={`${field}[${index}].colorG`}
                            />
                          </Box>
                          <Box width={200} pr={2}>
                            <Field
                              component={TextField}
                              type="number"
                              label="Blue Color"
                              name={`${field}[${index}].colorB`}
                            />
                          </Box>
                        </Box>
                      </Box>
                      <Box pb={1} width="100%">
                        <Box display="flex">
                          <Box width={200} pr={2}>
                            <Field
                              component={TextField}
                              type="number"
                              label="Radius"
                              name={`${field}[${index}].radius`}
                            />
                          </Box>
                        </Box>
                      </Box>
                    </>}
                  </Grid>
                </CardContent>
              </Card>
              <br/>
            </Fragment>
          ))}
        </CardContent>
      </Card>
      {typeof itemSelectElementIndex === 'number' && <ItemSelectDialog
        includeDropTables={true}
        showCatalogSelect
        onSelect={onItemSelected}
        onClose={() => setItemSelectElementIndex(undefined)}
      />}
    </>)}
  </FieldArray>;
};

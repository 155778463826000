import { createAsyncAction, createAction } from 'typesafe-actions';
import type { Store, StoreTab } from '../../../services/stores';

export const getCatalogStoresAsync = createAsyncAction(
  'GET_CATALOG_STORES_REQUEST',
  'GET_CATALOG_STORES_SUCCESS',
  'GET_CATALOG_STORES_FAILURE'
)<undefined, Store[], string>();

export const getCatalogStoreAsync = createAsyncAction(
  'GET_CATALOG_STORE_REQUEST',
  'GET_CATALOG_STORE_SUCCESS',
  'GET_CATALOG_STORE_FAILURE'
)<string, Store, string>();

export const addCatalogStoreAsync = createAsyncAction(
  'ADD_CATALOG_STORE_REQUEST',
  'ADD_CATALOG_STORE_SUCCESS',
  'ADD_CATALOG_STORE_FAILURE'
)<Store, Store, string>();

export const updateCatalogStoreAsync = createAsyncAction(
  'UPDATE_CATALOG_STORE_REQUEST',
  'UPDATE_CATALOG_STORE_SUCCESS',
  'UPDATE_CATALOG_STORE_FAILURE'
)<Store, Store, string>();

export const deleteCatalogStoreAsync = createAsyncAction(
  'DELETE_CATALOG_STORE_REQUEST',
  'DELETE_CATALOG_STORE_SUCCESS',
  'DELETE_CATALOG_STORE_FAILURE'
)<Store, Store, string>();

export const publishCatalogStoreAsync = createAsyncAction(
  'PUBLISH_CATALOG_STORE_REQUEST',
  'PUBLISH_CATALOG_STORE_SUCCESS',
  'PUBLISH_CATALOG_STORE_FAILURE'
)<Store, Store, string>();

export const addStoreTabAsync = createAsyncAction(
  'ADD_STORE_TAB_REQUEST',
  'ADD_STORE_TAB_SUCCESS',
  'ADD_STORE_TAB_FAILURE'
)<string, StoreTab, string>();

export const updateStoreTabAsync = createAsyncAction(
  'UPDATE_STORE_TAB_REQUEST',
  'UPDATE_STORE_TAB_SUCCESS',
  'UPDATE_STORE_TAB_FAILURE'
)<{ name: string, tab: StoreTab }, StoreTab, string>();

export const deleteStoreTabAsync = createAsyncAction(
  'DELETE_STORE_TAB_REQUEST',
  'DELETE_STORE_TAB_SUCCESS',
  'DELETE_STORE_TAB_FAILURE'
)<StoreTab, StoreTab, string>();

export const lockCatalogStoreAsync = createAsyncAction(
  'LOCK_STORE_REQUEST',
  'LOCK_STORE_SUCCESS',
  'LOCK_STORE_FAILURE'
)<Store, Store, string>();

export const unlockCatalogStoreAsync = createAsyncAction(
  'UNLOCK_STORE_REQUEST',
  'UNLOCK_STORE_SUCCESS',
  'UNLOCK_STORE_FAILURE'
)<Store, Store, string>();

export const setStore = createAction('SET_STORE', (store: Store) => store)();
import { Box, Typography } from '@material-ui/core';
import { DiffsPanelsContainer } from '../DiffsPanels';
import type { BlankoAssetDiff, ItemDiffProps } from '../../types';

type BlankoAssestsProps = ItemDiffProps<BlankoAssetDiff>

export const BlankoAssets = ({
  sourceItems,
  targetItems,
  actions,
  onSelectionChange
}: BlankoAssestsProps) => (<>
  <Typography variant="h6">
    Blanko Global Assets
  </Typography>

  <Box mt={2}>
    {sourceItems && targetItems && (
      <DiffsPanelsContainer
        sourceItems={sourceItems}
        targetItems={targetItems}
        disableRemove
        selectedIds={actions}
        columns={[
          { title: 'Name', field: 'name', defaultSort: 'asc' },
          // eslint-disable-next-line react/display-name
          { title: 'Platform', field: 'platform', render: (item: BlankoAssetDiff) => <>{item.platform}, {item.minPlatformVersion}</> }
        ]}
        onSelectionChange={onSelectionChange}
      />
    )}
  </Box>
</>);
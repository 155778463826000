import type { Reducer } from 'redux';
import { createReducer } from 'typesafe-actions';
import config from '../../config';
import type { Catalog } from '../../services/catalogs';
import type { TitleFile } from '../../services/model/title-file';
import type { Store } from '../../services/stores';
import type { Title } from '../../services/title-data';
import { cmFinishMigration, cmGetCatalogsAsync, cmPreloadData, cmSetCatalogName, cmSetEnvId, cmSetMigrationActions, cmSetMigrationProgress, cmSetMigrationStepProgress, cmSetMigrationStepResult, cmSetOptions, cmSetPreloadedData, cmStartMigration } from './actions';

export interface EnvironmentState {
  id: string;
  catalogName?: string;
  catalogs: {
    isLoading: boolean;
    catalogs: Catalog[];
    error?: string;
  },
  catalog: {
    isLoading: boolean;
    catalog: Catalog | null;
  }
  stores: Store[] | null;
  titleData: Title | null;
  titleFiles: TitleFile[] | null;
  blankoAssets: any[] | null;
  blankoDnas: any[] | null;
  seasonalData?: SeasonalData[];
  blankoProgressions?: BlankoProgressions[];
  translations?: Translation[];
  textLanguages?: TextLanguage[];
}

function createEnvReducer(): Reducer<EnvironmentState> {
  const initialState: EnvironmentState = {
    id: config.env,
    catalogs: {
      isLoading: false,
      catalogs: []
    },
    catalog: {
      isLoading: false,
      catalog: null
    },
    stores: null,
    titleData: null,
    titleFiles: null,
    blankoAssets: null,
    blankoDnas: null,
    seasonalData: undefined,
    blankoProgressions: undefined,
    translations: undefined,
    textLanguages: undefined,
  };

  return createReducer(initialState)
    .handleAction(cmSetEnvId, (state, action) => ({
      ...state,
      id: action.payload.id,
      catalog: {
        ...state.catalog,
        catalog: null
      }
    }))
    .handleAction([cmSetOptions, cmSetCatalogName], (state, action) => ({
      ...state,
      catalogName: typeof action.payload.catalogName === 'string' ? action.payload.catalogName : state.catalogName,
      catalog: {
        ...state.catalog,
        catalog: null
      },
      stores: null,
      titleData: null,
      titleFiles: null,
      blankoAssets: null,
      blankoDnas: null,
      seasonalData: undefined,
      blankoProgressions: undefined,
      translations: undefined,
      textLanguages: undefined,
    }))
    .handleAction(cmGetCatalogsAsync.request, state => ({
      ...state,
      catalogs: {
        ...state.catalogs,
        isLoading: true,
        error: undefined
      }
    }))
    .handleAction(cmGetCatalogsAsync.success, (state, action) => ({
      ...state,
      catalogs: {
        ...state.catalogs,
        isLoading: false,
        catalogs: action.payload.catalogs
      }
    }))
    .handleAction(cmGetCatalogsAsync.failure, (state, action) => ({
      ...state,
      catalogs: {
        ...state.catalogs,
        isLoading: false,
        catalogs: [],
        error: action.payload.error
      }
    }))
    .handleAction(cmSetPreloadedData, (state, action) => ({
      ...state,
      catalog: {
        ...state.catalog,
        isLoading: false,
        catalog: action.payload.catalog
      },
      stores: action.payload.stores,
      titleData: action.payload.titleData,
      titleFiles: action.payload.titleFiles,
      blankoDnas: action.payload.blankoDnas,
      blankoAssets: action.payload.blankoAssets,
      seasonalData: action.payload.seasonalData,
      blankoProgressions: action.payload.blankoProgressions,
      translations: action.payload.translations,
      textLanguages: action.payload.textLanguages,
    }));
}

const envReducers = {
  source: createEnvReducer(),
  target: createEnvReducer()
};

export interface MigrationActions {
  add: string[];
  remove: string[];
  update: string[];
}

export interface MigrationResult {
  error?: string;
}

export interface CatalogMigrationState {
  source: EnvironmentState;
  target: EnvironmentState;
  options: CatalogMigrationOptions;
  actions: {
    [key: string]: MigrationActions;
  };
  run: CatalogMigrationRun;
  results: { [key: string]: MigrationResult };
}

const initialState: CatalogMigrationState = {
  source: envReducers.source(undefined, { type: 'init' }),
  target: envReducers.target(undefined, { type: 'init' }),
  options: {
    blankoAssets: false,
    blankoDnas: false,
    bundles: false,
    containers: false,
    dropTables: false,
    items: false,
    blankoProgressions: false,
    seasonalData: false,
    stores: false,
    titleDataClient: false,
    titleDataFiles: false,
    titleDataServer: false,
    translations: false,
  },
  actions: {},
  run: {
    running: false,
    finished: false,
    message: '',
    progress: 0,
    step: {
      message: '',
      progress: 0
    }
  },
  results: {}
}

const innerCatalogMigrationReducer: Reducer<CatalogMigrationState> = createReducer(initialState)
  .handleAction([cmSetEnvId, cmSetCatalogName, cmPreloadData], state => ({
    ...state,
    actions: {},
    run: {
      ...state.run,
      finished: false
    },
    results: {}
  }))
  .handleAction(cmSetOptions, (state, action) => ({
    ...state,
    options: {
      ...state.options,
      ...action.payload,
    },
    actions: {},
    run: {
      ...state.run,
      finished: false
    },
    results: {}
  }))
  .handleAction(cmSetMigrationActions, (state, action) => ({
    ...state,
    actions: {
      ...state.actions,
      [action.payload.id]: action.payload.actions
    }
  }))
  .handleAction(cmStartMigration, state => ({
    ...state,
    run: {
      ...state.run,
      running: true
    },
    results: {}
  }))
  .handleAction(cmFinishMigration, state => ({
    ...state,
    actions: {},
    run: {
      ...state.run,
      running: false,
      finished: true
    }
  }))
  .handleAction(cmSetMigrationProgress, (state, action) => ({
    ...state,
    run: {
      ...state.run,
      ...action.payload,
      step: {
        message: '',
        progress: 0
      }
    }
  }))
  .handleAction(cmSetMigrationStepProgress, (state, action) => ({
    ...state,
    run: {
      ...state.run,
      step: {
        ...action.payload
      }
    }
  }))
  .handleAction(cmSetMigrationStepResult, (state, action) => ({
    ...state,
    results: {
      ...state.results,
      [action.payload.id]: action.payload.result
    }
  }));

const catalogMigrationReducer: Reducer<CatalogMigrationState> = (state: CatalogMigrationState | undefined, action: any): CatalogMigrationState => {
  if (state && action.type.startsWith('cm/') && action && action.payload) {
    for (const env in envReducers) {
      if (env === 'source' || env === 'target') {
        const envReducer = envReducers[env];
        if (!action.payload.env || action.payload.env === env) {
          state = {
            ...state,
            [env]: envReducer(state[env], action)
          };
        }
      }
    }
  }

  return innerCatalogMigrationReducer(state, action);
}

export default catalogMigrationReducer;


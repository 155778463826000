import { combineReducers } from 'redux';
import { createReducer } from 'typesafe-actions';
import type { ItemDefinition, ItemToken } from '../../../services/item-definitions';
import type { APIResource } from '../../utils-ts';
import { setCatalogName } from '../actions';
import { addCatalogItemAsync, clearCatalogItems, createCatalogItemTokenAsync, getCatalogItemAsync, getCatalogItemsAsync, getCatalogItemTokenAsync, updateCatalogItemAsync, updateCatalogItemTokenAsync, updateCatalogItemTokenPropertiesAsync } from './actions';

export interface CurrentItemState {
  isLoading: boolean;
  data?: ItemDefinition;
  token?: ItemToken;
}

const currentItemReducer = createReducer<CurrentItemState>({ isLoading: false })
  .handleAction(getCatalogItemAsync.request, state => ({
    ...state,
    isLoading: true
  }))
  .handleAction(getCatalogItemAsync.success, (state, action) => ({
    ...state,
    isLoading: false,
    data: action.payload,
    token: action.payload.token
  }))
  .handleAction(getCatalogItemAsync.failure, state => ({
    ...state,
    isLoading: false
  }))
  .handleAction(addCatalogItemAsync.success, (state, action) => ({
    ...state,
    data: action.payload,
    token: action.payload.token
  }))
  .handleAction(updateCatalogItemAsync.success, (state, action) => ({
    ...state,
    data: action.payload
  }))
  .handleAction([setCatalogName, clearCatalogItems], state => ({
    ...state,
    isLoading: false,
    data: undefined,
    token: undefined
  }))
  .handleAction([getCatalogItemTokenAsync.success, createCatalogItemTokenAsync.success, updateCatalogItemTokenAsync.success, updateCatalogItemTokenPropertiesAsync.success], (state, action) => ({
    ...state,
    token: action.payload
  }));

const itemsReducer = createReducer<APIResource<ItemDefinition[]>>({ isLoading: false })
  .handleAction(getCatalogItemsAsync.request, state => ({
    ...state,
    isLoading: true
  }))
  .handleAction(getCatalogItemsAsync.success, (state, action) => ({
    ...state,
    isLoading: false,
    data: action.payload
  }))
  .handleAction(getCatalogItemsAsync.failure, state => ({
    ...state,
    isLoading: false
  }))
  .handleAction([setCatalogName, clearCatalogItems], state => ({
    ...state,
    isLoading: false,
    data: null
  }));

export default combineReducers({
  items: itemsReducer,
  currentItem: currentItemReducer
});
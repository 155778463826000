import { Box, LinearProgress, Typography } from '@material-ui/core';
import { useEffect, useState } from 'react';
import { pushAppNotification } from '../../../shared/hooks/useAppNotification';
import type { MigrationCommonProps } from '../migrations';

export const MigrationRunner = (props: MigrationCommonProps) => {
  const { onNextStep } = props;

  const [running, setRunning] = useState(false);
  const [stepName, setStepName] = useState('');
  const [stepStatus, setStepStatus] = useState('');
  const [stepProgress, setStepProgress] = useState(-1);

  useEffect(() => {
    if (running) {
      return;
    }

    setRunning(true);

    const setStatus = (status: string, progress?: number) => {
      setStepStatus(status);
      setStepProgress(progress === undefined ? -1 : progress);
    };

    async function runMigration() {
      try {
        for (const id of props.runOrder) {
          if (!props.migrationData.options[id]) {
            continue;
          }

          const migrationModule = props.modules[id];
          if (!migrationModule) {
            throw new Error(`Migration module not found: ${id}`);
          }

          setStepName(migrationModule.displayName);
          await migrationModule.runMigration(props, setStatus);
        }

        onNextStep();
      } catch (e) {
        console.error(e);
        pushAppNotification({ type: 'error', message: e instanceof Error ? e.message : 'An unknown error occurred' });
      }
    }

    runMigration().catch(err => {
      console.error(err);
    });
  }, [props, running, onNextStep]);

  return (<>
    <Box mt={4} textAlign="center">
      <Typography>{stepName}</Typography>
      <LinearProgress variant="indeterminate" color="secondary" />
    </Box>
    <Box mt={2} textAlign="center">
      <Typography>{stepStatus}</Typography>
      <LinearProgress variant={stepProgress > -1 ? 'determinate' : 'indeterminate'} color="secondary" value={stepProgress} />
    </Box>
  </>)
}
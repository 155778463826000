import type { Reducer } from 'redux';
import { createReducer } from 'typesafe-actions';
import { setAuthenticated,  setToken, setOAuthClient } from './actions';

export interface AuthenticationState {
  authenticated: boolean;
  oAuthClient?: OidcClient;
  token: string | null;
}

const initialState: AuthenticationState = {
  authenticated: false,
  token: null
};

const authenticationReducer: Reducer<AuthenticationState> = createReducer(initialState)
  .handleAction(setAuthenticated, (state, action) => ({
    ...state,
    authenticated: action.payload.authenticated
  }))
  .handleAction(setOAuthClient, (state, action) => ({
    ...state,
    oAuthClient: action.payload.oauthClient
  }))
  .handleAction(setToken, (state, action) => ({
    ...state,
    token: action.payload.token
  }));

export default authenticationReducer;
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@material-ui/core"
import { createRegionFeature } from "../geo-blocking-api"
import { GeoBlockedFeatureMap } from "../geo-blocking-types"

import { Formik, useFormik, validateYupSchema } from 'formik';
import { useCallback } from "react";
import { GeoBlockingForm } from "./GeoBlockingForm";

interface AddGeoBlockingDialogProps {
  onClose: () => void
  show: boolean,
  availableKeys: string[]
}

export const AddGeoBlockingDialog = ({onClose, show, availableKeys}: AddGeoBlockingDialogProps) => {

  const initialFormValues: GeoBlockedFeatureMap = {
    featureId: undefined,
    regions: []
  } 

  const onSubmit = useCallback(async(values: Partial<GeoBlockedFeatureMap>) => {
    await createRegionFeature(values);
    onClose();
  },[onClose]);


  return (
    <>
      <Dialog
        open={show}
    >
        <DialogTitle id="add-dialog-title">Add Geo-Blocked Feature</DialogTitle>
        <GeoBlockingForm data={initialFormValues} onSubmit={onSubmit} onClose={onClose} availableKeys={availableKeys} /> 
      </Dialog>
    </>
  )
}
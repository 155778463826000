import type { Column } from 'material-table';
import type { ApiError } from '../../services/api';
import { api } from '../../services/api';
import { CurrencyService } from '../../services/currency';
import { CurrencyAmount } from '../../services/model/currency';
import type { NakamaPageRequest } from '../../services/model/friend';
import { Friend } from '../../services/model/friend';
import { NakamaUser } from '../../services/model/nakama-user';
import type { PaginatedRequest} from '../../services/model/pagination';
import { PaginatedResponse } from '../../services/model/pagination';
import type {PlayerTag} from "../accounts/accounts";

export interface PlayerDataField {
  ownerPermission: 'none' | 'read' | 'write';
  othersPermission: 'none' | 'read' | 'write';
  value: string;
}

export interface SimpleUpholdWallet {
  hasUphold: boolean;
  hasBalance: boolean;
}

export class Player {
  id = '';
  mythicalId = '';
  playfabId = '';
  externalId = '';
  email = '';
  displayName = '';
  deviceIds: string[] = [];
  myclPlatformLinked = false;
  playerData: { [key: string]: PlayerDataField; } = {};
  lastLogin: Date | null = null;
  bannedUntil: Date | null = null;
  mutedUntil: Date | null = null;
  buildModeEnabled = false;
  virtualCurrencies: { [key: string]: CurrencyAmount; } = {};
  tags: PlayerTag[] = [];

  constructor(json: any) {
    this.id = json.id;
    this.mythicalId = json.mythicalId || '';
    this.playfabId = json.playfabId || '';
    this.externalId = json.externalId || '';
    this.email = json.email || '';
    this.displayName = json.displayName || '';
    this.deviceIds = json.deviceIds || [];
    this.myclPlatformLinked = json.myclPlatformLinked;
    this.playerData = json.playerData;
    this.buildModeEnabled = json.buildModeEnabled;
    this.tags = json.tags;
    if (json.lastLogin > 0) {
      this.lastLogin = new Date(json.lastLogin * 1000);
    }
    if (typeof json.bannedUntil === 'string') {
      this.bannedUntil = new Date(json.bannedUntil);
    }
    if (typeof json.mutedUntil === 'string') {
      this.mutedUntil = new Date(json.mutedUntil);
    }

    if (Array.isArray(json.virtualCurrency)) {
      this.virtualCurrencies = CurrencyService.getCurrencies().buildAmountsMap(json.virtualCurrency.map((v: any) => new CurrencyAmount(v)));
    }
  }

  getDisplayName() {
    return this.displayName || this.email || this.externalId;
  }

  isBanned() {
    return this.bannedUntil && this.bannedUntil.getTime() > new Date().getTime();
  }

  isMuted() {
    return this.mutedUntil && this.mutedUntil.getTime() > new Date().getTime();
  }

  canBuild(){
    return this.buildModeEnabled;
  }
}

export class PlayerNote {
  id = '';
  playerId = '';
  timestamp = new Date();
  message = '';
  ownerPlayerId = '';
  ownerDisplayName = '';

  constructor(json?: any) {
    if (json) {
      this.id = json.id;
      this.playerId = json.playerId;
      this.timestamp = new Date(json.timestamp);
      this.message = json.message;
      this.ownerPlayerId = json.ownerPlayerId || '';
      this.ownerDisplayName = json.ownerDisplayName || '';
    }
  }
}

export class PlayerReport {
  id = '';
  playerId = '';
  externalId = '';
  displayName = '';
  email = '';
  reportedPlayerId = '';
  reportedExternalId = '';
  reportedDisplayName = '';
  reportedEmail = '';
  status = '';
  reason = '';
  description = '';
  note = '';
  active = false;
  updatedTimestamp = '';
  createdTimestamp = '';

  constructor(json?: any) {
    if (json) {
      this.id = json.id;
      this.playerId = json.playerId;
      this.externalId = json.externalId;
      this.displayName = json.displayName;
      this.email = json.email;
      this.reportedPlayerId = json.reportedPlayerId;
      this.reportedExternalId = json.reportedExternalId;
      this.reportedDisplayName = json.reportedDisplayName;
      this.reportedEmail = json.reportedEmail;
      this.status = json.status;
      this.reason = json.reason;
      this.description = json.description;
      this.note = json.note;
      this.active = json.active;
      this.updatedTimestamp = json.updatedTimestamp;
      this.createdTimestamp = json.createdTimestamp;
    }
  }
}

export class MarkInReviewPlayerReportRequest {
  id = '';

  constructor(id: string) {
    this.id = id;
  }
}

export class ResolvePlayerReportRequest {
  id = '';
  note = '';

  constructor(id: string) {
    this.id = id;
  }
}

export class PlayersService {
  static async getPlayers(request: PaginatedRequest): Promise<PaginatedResponse<Player>> {
    // For performance reasons we want to avoid a search of all players,
    // so if search value is empty, we return an empty result;
    if (!request['search']) {
      return Promise.resolve({
        items: [],
        totalCount: 0
      });
    }

    return api.get({ url: '/players', query: request })
      .then(players => new PaginatedResponse(players, player => new Player(player)))
      .catch((e: ApiError) => {
        e.message = `Failed to get players. ${e.message}`;
        throw e;
      });
  }

  static async getPlayer(id: string): Promise<Player> {
    await CurrencyService.loadCurrencies();

    return api.get({ url: `/players/${id}` })
      .then(player => new Player(player))
      .catch((e: ApiError) => {
        e.message = `Failed to get player. ${e.message}`;
        throw e;
      });
  }

  static async updatePlayer(player: Player): Promise<Player> {
    const body = {
      email: player.email,
      displayName: player.displayName,
      deviceIds: player.deviceIds,
      playerData: player.playerData
    };

    return api.put({ url: `/players/${player.externalId}`, body })
      .then(player => new Player(player))
      .catch((e: ApiError) => {
        e.message = `Failed to update player. ${e.message}`;
        throw e;
      });
  }

  static async togglePlayerBuildModeEnabled(player: Player): Promise<Player> {
    return api.put({ url: `/players/${player.externalId}/toggleBuildModeEnabled`})
      .then(player => new Player(player))
      .catch((e: ApiError) => {
        e.message = `Failed to toggle player build mode. ${e.message}`;
        throw e;
      });
  }

  static async updatePlayerBan(player: Player, timestamp: number | null, reason: string | null): Promise<Player> {
    return api.put({ url: `/players/${player.externalId}/ban`, body: { timestamp, reason } })
      .then(player => new Player(player))
      .catch((e: ApiError) => {
        e.message = `Failed to update player ban. ${e.message}`;
        throw e;
      });
  }

  static async updatePlayerMute(player: Player, timestamp: number | null, reason: string | null): Promise<Player> {
    return api.put({ url: `/players/${player.externalId}/mute`, body: { timestamp, reason } })
      .then(player => new Player(player))
      .catch((e: ApiError) => {
        e.message = `Failed to update player mute. ${e.message}`;
        throw e;
      });
  }

  static async getPlayerNotes(playerId: string): Promise<PlayerNote[]> {
    return api.get<any[]>({ url: `/players/${playerId}/notes` })
      .then(notes => notes.map(note => new PlayerNote(note)))
      .catch((e: ApiError) => {
        e.message = `Failed to get player notes. ${e.message}`;
        throw e;
      });
  }

  static async addPlayerNote(note: PlayerNote): Promise<PlayerNote> {
    return api.post({ url: `/players/${note.playerId}/notes`, body: { message: note.message } })
      .then(note => new PlayerNote(note))
      .catch((e: ApiError) => {
        e.message = `Failed to add player note. ${e.message}`;
        throw e;
      });
  }

  static async updatePlayerNote(note: PlayerNote): Promise<PlayerNote> {
    return api.put({ url: `/players/${note.playerId}/notes/${note.id}`, body: { message: note.message } })
      .then(note => new PlayerNote(note))
      .catch((e: ApiError) => {
        e.message = `Failed to update player note. ${e.message}`;
        throw e;
      });
  }

  static async deletePlayerNote(note: PlayerNote): Promise<null> {
    return api.delete({ url: `/players/${note.playerId}/notes/${note.id}` })
      .catch((e: ApiError) => {
        e.message = `Failed to delete player note. ${e.message}`;
        throw e;
      });
  }

  static async getPlayerFriends(playerId: string, request: NakamaPageRequest): Promise<Friend[]> {
    return api.get({ url: `/players/${playerId}/friends`, query: request })
      .then(response => {
        return response.friends.map((friend: Friend) => new Friend(friend));
      }
      )
      .catch((e: ApiError) => {
        e.message = `Failed to get player friends.  ${e.message}`;
        throw e;
      });
  }

  static async removePlayerFriend(playerId: string, nakamaId: string): Promise<null> {
    return api.delete({ url: `/players/${playerId}/friends/${nakamaId}` })
      .catch((e: ApiError) => {
        e.message = `Failed to remove player friend.  ${e.message}`;
        throw e;
      });
  }

  static async getNakamaUser(playerId: string): Promise<NakamaUser> {
    return api.get({ url: `/players/nakama/${playerId}` })
      .then(user => new NakamaUser(user))
      .catch((e: ApiError) => {
        e.message = `Failed to get nakama user.  ${e.message}`;
        throw e;
      });
  }

  static async blockFriendRequests(playerId: string): Promise<null> {
    return api.put({ url: `/players/${playerId}/friends/block` })
      .catch((e: ApiError) => {
        e.message = `Failed to block nakama friend requests user.  ${e.message}`;
        throw e;
      });
  }

  static async unblockFriendRequests(playerId: string): Promise<null> {
    return api.put({ url: `/players/${playerId}/friends/unblock` })
      .catch((e: ApiError) => {
        e.message = `Failed to block nakama friend requests user.  ${e.message}`;
        throw e;
      });
  }

  static async getPlayerReportsPage(request: PaginatedRequest): Promise<PaginatedResponse<PlayerReport>> {
    return api.get<any[]>({ url: '/players/reported', query: request })
      .then(playerReports => new PaginatedResponse(playerReports, playerReport => new PlayerReport(playerReport)))
      .catch((e: ApiError) => {
        e.message = `Failed to get player reports. ${e.message}`;
        throw e;
      });
  }

  static async markInReviewPlayerReport(request: MarkInReviewPlayerReportRequest): Promise<void> {
    return api.put({ url: `/players/reported/${request.id}/mark-in-review`, body: request })
      .catch((e: ApiError) => {
        e.message = `Failed to mark in review player report. ${e.message}`;
        throw e;
      });
  }

  static async resolvePlayerReport(request: ResolvePlayerReportRequest): Promise<void> {
    return api.put({ url: `/players/reported/${request.id}/resolve`, body: request })
      .catch((e: ApiError) => {
        e.message = `Failed to resolve player report. ${e.message}`;
        throw e;
      });
  }

  static async resetPlayerFtue(playerId: string): Promise<any> {
    return api.post<any>({ url: `/players/${playerId}/reset-ftue`, body: {} })
      .catch((e: ApiError) => {
        e.message = `Failed to reset player FTUE. ${e.message}`;
        throw e;
      });
  }

  static async skipPlayerFtue(playerId: string): Promise<any> {
    return api.post<any>({ url: `/players/${playerId}/skip-ftue`, body: {} })
      .catch((e: ApiError) => {
        e.message = `Failed to skip player FTUE. ${e.message}`;
        throw e;
      });
  }

  static async getPlayerConnections(playerId: string): Promise<AccountLinking[]> {
    return api.get<AccountLinking[]>({ url: `/players/${playerId}/account-linking` })
      .catch((e: ApiError) => {
        e.message = `Failed to get account linking. ${e.message}`;
        throw e;
      });
  }

  static async deletePlayerConnection(accountLinkingId: string): Promise<null> {
    return api.delete({ url: `/players/account-linking/${accountLinkingId}` })
      .catch((e: ApiError) => {
        e.message = `Failed to delete account linking. ${e.message}`;
        throw e;
      });
  }

  static async getEntitlementFulfillments(id: string): Promise<EntitlementFulfillment[]> {
    return api.get({ url: `/players/${id}/fulfillment` })
      .catch((e: ApiError) => {
        e.message = `Failed to get fulfillments. ${e.message}`;
        throw e;
      });
  }

  static async getPlayerCurrencyHistory(playerId: string, sortOrder: string, sortBy: Column<CurrencyHistoryPaginatedItem>, page: number, pageSize: number): Promise<CurrencyHistoryPageResponse> {
    const sortByField = sortBy.field?.replace("item.","");
    return api.get({ url: `/players/${playerId}/currencyhistory?playerId=${playerId}&sortBy=${sortByField}&sortOrder=${sortOrder}&page=${page}&pageSize=${pageSize}` })
      .catch((e: ApiError) => {
        e.message = `Failed to get currency history. ${e.message}`;
        throw e;
      });
  }


  static async getUpholdWallet(playerId: string): Promise<SimpleUpholdWallet> {
    return api.get({url: `/players/wallet/uphold/${playerId}`})
      .catch((e: ApiError) => {
        e.message = `Failed to get uphold wallet info. ${e.message}`;
        throw e;
      })
  }

  static async unlinkUpholdWallet(playerId: string): Promise<boolean> {
    return api.post({url: `/players/wallet/unlink/UPHOLD/${playerId}`})
      .catch((e: ApiError) => {
        e.message = `Failed to unlink uphold wallet. ${e.message}`;
        throw e;
      })
  }
}
import type { AnyAction } from 'redux';
import type { CallEffect, PutEffect} from 'redux-saga/effects';
import { call, fork, put, takeLatest } from 'redux-saga/effects';
import type { PayloadAction } from 'typesafe-actions';
import type { CurrencyLimit} from '../../services/currency-limits';
import { CurrencyLimitService } from '../../services/currency-limits';
import { setAppNotification } from '../app/actions';
import { sagaHandleApiError } from '../utils-ts';
import { addCurrencyLimitAsync, deleteCurrencyLimitAsync, getCurrencyLimitsAsync, updateCurrencyLimitAsync } from './actions';

function* addCurrencyLimitSaga(action: ReturnType<typeof addCurrencyLimitAsync.request>) {
  try {
    const currencyLimit: CurrencyLimit = yield call(CurrencyLimitService.createCurrencyLimit, action.payload);
    yield put(addCurrencyLimitAsync.success(currencyLimit));
    yield put(setAppNotification({ type: 'success', message: 'Currency Limit Set' }));
  } catch (e) {
    yield sagaHandleApiError(e, addCurrencyLimitAsync.failure);
  }
}

function* deleteCurrencyLimitSaga(action: ReturnType<typeof deleteCurrencyLimitAsync.request>) {
  try {
    yield call(CurrencyLimitService.deleteCurrencyLimit, action.payload);
    yield put(deleteCurrencyLimitAsync.success(action.payload));
    yield put(setAppNotification({ type: 'success', message: 'Currency Limit Cleared' }));
  } catch (e) {
    yield sagaHandleApiError(e, deleteCurrencyLimitAsync.failure);
  }
}

function* getCurrencyLimitsSaga(): Generator<Generator<PutEffect<AnyAction>, void, string> | CallEffect<CurrencyLimit[]> | PutEffect<PayloadAction<"LOAD_CURRENCY_LIMITS_SUCCESS", CurrencyLimit[]>>, void, CurrencyLimit[]> {
  try {
    const currencyLimits = yield call(CurrencyLimitService.getCurrencyLimits);
    yield put(getCurrencyLimitsAsync.success(currencyLimits));
  } catch (e) {
    yield sagaHandleApiError(e, getCurrencyLimitsAsync.failure);
  }
}

function* updateCurrencyLimitSaga(action: ReturnType<typeof updateCurrencyLimitAsync.request>) {
  try {
    const currencyLimit: CurrencyLimit = yield call(CurrencyLimitService.updateCurrencyLimit, action.payload);    
    yield put(updateCurrencyLimitAsync.success(currencyLimit));
    yield put(setAppNotification({ type: 'success', message: 'Currency Limit Updated' }));
  } catch (e) {
    yield sagaHandleApiError(e, updateCurrencyLimitAsync.failure);
  }
}

function* watchRequestCurrencyLimits() {
  yield takeLatest(getCurrencyLimitsAsync.request, getCurrencyLimitsSaga);
}

function* watchRequestAddCurrencyLimit() {
  yield takeLatest(addCurrencyLimitAsync.request, addCurrencyLimitSaga);
}

function* watchRequestUpdateCurrencyLimit() {
  yield takeLatest(updateCurrencyLimitAsync.request, updateCurrencyLimitSaga);
}

function* watchRequestDeleteCurrencyLimit() {
  yield takeLatest(deleteCurrencyLimitAsync.request, deleteCurrencyLimitSaga);
}

export default function* currencyLimitsSagas() {
  yield fork(watchRequestCurrencyLimits);
  yield fork(watchRequestAddCurrencyLimit);
  yield fork(watchRequestUpdateCurrencyLimit);
  yield fork(watchRequestDeleteCurrencyLimit);
}
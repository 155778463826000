import _ from 'lodash';
import { api, ApiError } from '../../services/api';
import type {
  MarketplaceItemDataTag,
  Shop,
  ShopItem,
  ShopTab,
  ShopTabItem,
  ShopTabLinkImage,
  ShopVersion,
  ShopVersionPlayerAccess
} from './shop';

class ShopTabDto implements ShopTab {
  id = '';
  name = '';
  position = 0;
  shopVersionId = '';
  items = [];
  linkImages = [] as ShopTabLinkImage[];

  constructor(tab: ShopTab) {
    this.id = tab.id;
    this.name = tab.name;
    this.position = tab.position;
    this.shopVersionId = tab.shopVersionId;
    this.linkImages = tab.linkImages ? _.cloneDeep(tab.linkImages) : [];
  }

  toJson(): any {
    return {
      id: this.id,
      name: this.name,
      position: this.position,
      shopVersionId: this.shopVersionId,
      linkImages: this.linkImages,
    };
  }
}

export const getShops = async () => {
  return api.get<Shop[]>({ url: '/shops' });
}

export const getShop = async (id: string) => {
  return api.get<Shop>({ url: `/shops/${id}` });
}

export const createShop = async (name: string) => {
  return api.post<Shop>({ url: '/shops', body: { name } });
}

export const updateShop = async (shop: Shop) => {
  return api.put<Shop>({ url: `/shops/${shop.id}`, body: shop });
}

export const deleteShop = async (id: string) => {
  return api.delete({ url: `/shops/${id}` });
}

export const getShopVersions = async (shopId: string) => {
  return api.get<ShopVersion[]>({ url: '/shops/versions', query: { shopId } });
}

export const getShopVersion = async (versionId: string, includeContents: boolean) => {
  return api.get<ShopVersion>({ url: `/shops/versions/${versionId}`, query: { includeContents } });
}

export const createShopVersion = async (shopId: string, name: string, catalogName: string, startDate?: string, preloadStartDate?: string) => {
  if (startDate) {
    startDate = new Date(startDate).toISOString();
    if (preloadStartDate) {
      preloadStartDate = new Date(preloadStartDate).toISOString();
    }
  }

  return api.post<ShopVersion>({ url: '/shops/versions', body: { shopId, name, catalogName, startDate, preloadStartDate } });
}

export const createShopVersionFull = async (shopVersion: any) => {
  if (typeof shopVersion.startDate === 'string' && Boolean(shopVersion.startDate)) {
    shopVersion.startDate = new Date(shopVersion.startDate).toISOString();
    if (typeof shopVersion.preloadStartDate === 'string' && Boolean(shopVersion.preloadStartDate)) {
      shopVersion.preloadStartDate = new Date(shopVersion.preloadStartDate).toISOString();
    }
  }

  return api.post<ShopVersion>({ url: '/shops/versions', body: shopVersion });
}

export const updateShopVersion = async (shopVersion: ShopVersion) => {
  const body = {
    name: shopVersion.name,
    catalogName: shopVersion.catalogName,
    startDate: shopVersion.startDate ? new Date(shopVersion.startDate).toISOString() : null,
    preloadStartDate: shopVersion.startDate && shopVersion.preloadStartDate ? new Date(shopVersion.preloadStartDate).toISOString() : null
  };
  return api.put<ShopVersion>({ url: `/shops/versions/${shopVersion.id}`, body });
}

export const deleteShopVersion = async (id: string) => {
  return api.delete({ url: `/shops/versions/${id}` });
}

export const createShopTab = async (shopTab: ShopTab) => {
  return api.post<ShopTab>({ url: '/shops/tabs', body: new ShopTabDto(shopTab).toJson() });
}

export const updateShopTab = async (shopTab: ShopTab) => {
  return api.put<ShopTab>({ url: `/shops/tabs/${shopTab.id}`, body: new ShopTabDto(shopTab).toJson() });
}

export const deleteShopTab = async (id: string) => {
  return api.delete({ url: `/shops/tabs/${id}` });
}

export const createShopItem = async (item: Omit<ShopItem, 'id'>) => {
  return api.post<ShopItem>({ url: '/shops/items', body: item });
}

export const updateShopItem = async (item: ShopItem) => {
  return api.put<ShopItem>({ url: `/shops/items/${item.id}`, body: item });
}

export const updateShopItems = async (items: ShopItem[]) => {
  return api.put({ url: '/shops/items', body: items });
}

export const createShopTabItem = async (item: Omit<ShopTabItem, 'id' | 'position'>) => {
  return api.post<ShopTabItem>({ url: '/shops/tab-items', body: item });
}

export const updateShopTabItem = async (item: ShopTabItem) => {
  return api.put<ShopTabItem>({ url: `/shops/tab-items/${item.id}`, body: item });
}

export const deleteShopTabItem = async (id: string) => {
  return api.delete({ url: `/shops/tab-items/${id}` });
}

export const updateShopTabItems = async (tabId: string, tabItems: ShopTabItem[]) => {
  return api.put<ShopTabItem[]>({ url: `/shops/tabs/${tabId}/items`, body: tabItems });
}

export const getMarketplaceItemDataTags = async () => {
  const response = await api.get<{ [name: string]: Omit<MarketplaceItemDataTag, 'name'> }>({ url: `/shops/marketplace/item-data-tags` });
  return Object.entries(response)
    .map(([name, data]) => ({
      ...data,
      name,
    } as MarketplaceItemDataTag));
};

export const uploadInventoryImage = async (image: File) => {
  const imageData = await api.post<{ url: string, publicUrl: string }>({ url: '/inventory/image-upload-url', body: { mimeType: image.type } });
  const options: RequestInit = {
    method: 'PUT',
    headers: new Headers({
      'Content-Type': image.type
    }),
    body: image
  };

  const response = await fetch(imageData.url, options);
  if (!response.ok) {
    throw new ApiError('Image upload failed');
  }

  return imageData.publicUrl;
}

export const uploadShopTabItemImage = async (item: ShopTabItem, image: File) => {
  const uploadedImageUrl = await uploadInventoryImage(image);
  return updateShopTabItem({ ...item, imageUrl: uploadedImageUrl });
}

export const getPlayerShopAccess = async (shopId: string) => {
  return api.get<ShopVersionPlayerAccess[]>({ url: `/shops/${shopId}/player-access` });
}

export const grantPlayerAccess = async (shopVersionId: string, emails: string[]) => {
  return api.post({ url: `/shops/versions/${shopVersionId}/player-access`, body: { playerEmails: emails } });
}

export const revokePlayerAccess = async (accessList: { shopVersionId: string, playerId: string }[]) => {
  const removedAccess: { shopVersionId: string, playerId: string }[] = [];
  for (const access of accessList) {
    try {
      await api.delete({ url: `/shops/versions/${access.shopVersionId}/player-access/${access.playerId}` });
      removedAccess.push(access);
    } catch (e) {
      // Do nothing for now.
    }
  }

  return removedAccess;
}

export const announceShop = async (shopName: string, catalogName: string) => {
  return api.put({ url: `/inventory/catalogs/${catalogName}/stores/${shopName}/publish`, body: {} })
    .catch((e: ApiError) => {
      // This is using the store API for now. If a store isn't found this will throw a 404 but still announce correctly.
      if (e.code !== 40400) {
        throw e;
      }
    });
}

import { Box, Button, CircularProgress, Grid, Link, Typography } from '@material-ui/core';
import type { Column } from 'material-table';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { openConfirmationDialog, setAppNotification } from '../../../redux/app/actions';
import type { ApiError } from '../../../services/api';
import { ShopNameDialog } from '../components/ShopNameDialog';
import type { Shop } from '../shop';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import { deleteShop, getShops } from '../shopsApi';
import { UserService } from '../../../services/user';
import { RouterLink } from '../../../types';
import type { MenuAction } from '../../../components/MaterialTable';
import MaterialTable from '../../../components/MaterialTable';

const shopsColumns: Column<Shop>[] = [
  {
    field: 'name',
    title: 'Name',
    defaultSort: 'asc',
    render(shop) {
      return <Link component={RouterLink} to={`/shops/${shop.id}/timeline`}>{shop.name}</Link>;
    }
  }
];

export const Shops = () => {
  const dispatch = useDispatch();

  const [shops, setShops] = useState<Shop[] | null>(null);
  const [nameDialogOpen, setNameDialogOpen] = useState(false);
  const [editingShop, setEditingShop] = useState<Shop | null>(null);

  useEffect(() => {
    getShops().then(setShops)
      .catch((e: ApiError) => dispatch(setAppNotification({ type: 'error', message: `Failed to load shops. ${e.message}` })));
  }, [dispatch]);

  const onAddClick = useCallback(() => {
    setEditingShop(null);
    setNameDialogOpen(true);
  }, []);

  const onRenameClick = useCallback((shop: Shop) => {
    setEditingShop(shop);
    setNameDialogOpen(true);
  }, []);

  const onDeleteClick = useCallback((shop: Shop) => {
    dispatch(openConfirmationDialog({ title: `Delete shop ${shop.name}?`, action: 'Delete Shop' }, () => {
      deleteShop(shop.id).then(() => {
        if (shops) {
          setShops(shops.filter(v => v.id !== shop.id));
        }
        dispatch(setAppNotification({ type: 'success', message: `Shop deleted: ${shop.name}` }));
      }).catch((e: ApiError) => dispatch(setAppNotification({ type: 'error', message: `Error deleting shop. ${e.message}` })));
    }));
  }, [dispatch, shops]);

  const getMenuActions = useCallback(() => {
    const actions: MenuAction<Shop>[] = [];
    if (UserService.canUpdate('store')) {
      actions.push({ type: 'button', icon: EditIcon, label: 'Rename', onClick: onRenameClick });
    }
    if (UserService.canDelete('store')) {
      actions.push({ type: 'button', icon: DeleteIcon, label: 'Delete', onClick: onDeleteClick });
    }
    return actions;
  }, [onRenameClick, onDeleteClick]);

  const handleShopUpdate = useCallback((shop: Shop) => {
    if (shops) {
      const updatedShops = [...shops];
      const index = shops.findIndex(v => v.id === shop.id);
      if (index > -1) {
        updatedShops[index] = shop;
      } else {
        updatedShops.push(shop);
      }
      setShops(updatedShops);
    }
  }, [shops]);

  if (!shops) {
    return (
      <Box textAlign="center">
        <CircularProgress />
      </Box>
    );
  }

  return (<>
    <Box mb={2}>
      <Grid container alignItems="center" spacing={2}>
        <Grid item xs>
          <Typography variant="h6">
            Shops
          </Typography>
        </Grid>
        <Grid item>
          <Button variant="contained" color="primary" onClick={onAddClick}>
            Add Shop
          </Button>
        </Grid>
        <Grid item>
          <Link component={RouterLink} to={`/shop-templates`}>
            <Button variant="contained" color="primary">
              Manage Templates
            </Button>
          </Link>
        </Grid>
      </Grid>
    </Box>

    <MaterialTable<Shop>
      title=""
      data={shops}
      columns={shopsColumns}
      menuActions={getMenuActions}
    />

    {nameDialogOpen && (
      <ShopNameDialog
        handleShopUpdate={handleShopUpdate}
        onClose={() => setNameDialogOpen(false)}
        shop={editingShop}
        shops={shops}
      />
    )}
  </>);
}
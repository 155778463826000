import { call, put, takeLatest } from 'redux-saga/effects';
import { BlankosService } from '../../services/blankos';
import type { BlankoAbilities, BlankoProgression } from '../../services/model/blanko';
import { setAppNotification } from '../app/actions';
import { forkSagas, sagaHandleApiError } from '../utils-ts';
import { createBlankoProgressionAsync, deleteBlankoProgressionAsync, getBlankoClassesAsync, getBlankoProgressionAsync, getBlankoProgressionsAsync, updateBlankoProgressionAsync } from './actions';

function* getBlankoProgressionsSaga() {
  try {
    const progressions: BlankoProgression[] = yield call(BlankosService.getProgressions);
    yield put(getBlankoProgressionsAsync.success(progressions));
  } catch (e) {
    yield sagaHandleApiError(e, getBlankoProgressionsAsync.failure);
  }
}

function* getBlankoProgressionSaga(action: ReturnType<typeof getBlankoProgressionAsync.request>) {
  try {
    const progression: BlankoProgression = yield call(BlankosService.getProgression, action.payload);
    yield put(getBlankoProgressionAsync.success(progression));
  } catch (e) {
    yield sagaHandleApiError(e, getBlankoProgressionAsync.failure);
  }
}

function* createBlankoProgressionSaga(action: ReturnType<typeof createBlankoProgressionAsync.request>) {
  try {
    const progression: BlankoProgression = yield call(BlankosService.createProgression, action.payload);
    yield put(createBlankoProgressionAsync.success(progression));
    yield put(setAppNotification({ type: 'success', message: 'Progression created' }));
  } catch (e) {
    yield sagaHandleApiError(e, createBlankoProgressionAsync.failure);
  }
}

function* updateBlankoProgressionSaga(action: ReturnType<typeof updateBlankoProgressionAsync.request>) {
  try {
    const progression: BlankoProgression = yield call(BlankosService.updateProgression, action.payload.id, action.payload.json);
    yield put(updateBlankoProgressionAsync.success(progression));
    yield put(setAppNotification({ type: 'success', message: 'Progression updated' }));
  } catch (e) {
    yield sagaHandleApiError(e, updateBlankoProgressionAsync.failure);
  }
}

function* deleteBlankoProgressionSaga(action: ReturnType<typeof deleteBlankoProgressionAsync.request>) {
  try {
    yield call(BlankosService.deleteProgression, action.payload);
    yield put(deleteBlankoProgressionAsync.success(action.payload));
    yield put(setAppNotification({ type: 'success', message: 'Progression deleted' }));
  } catch (e) {
    yield sagaHandleApiError(e, deleteBlankoProgressionAsync.failure);
  }
}

function* getBlankoClassesSaga() {
  try {
    const data: { classes: string[], abilities: BlankoAbilities } = yield call(BlankosService.getBlankoClasses);
    yield put(getBlankoClassesAsync.success(data));
  } catch (e) {
    yield sagaHandleApiError(e, getBlankoClassesAsync.failure);
  }
}

export default function* blankoProgressionsSagas() {
  yield forkSagas([
    function* () {
      yield takeLatest(getBlankoProgressionsAsync.request, getBlankoProgressionsSaga);
    },
    function* () {
      yield takeLatest(getBlankoProgressionAsync.request, getBlankoProgressionSaga);
    },
    function* () {
      yield takeLatest(createBlankoProgressionAsync.request, createBlankoProgressionSaga);
    },
    function* () {
      yield takeLatest(updateBlankoProgressionAsync.request, updateBlankoProgressionSaga);
    },
    function* () {
      yield takeLatest(deleteBlankoProgressionAsync.request, deleteBlankoProgressionSaga);
    },
    function* () {
      yield takeLatest(getBlankoClassesAsync.request, getBlankoClassesSaga);
    }
  ])
}
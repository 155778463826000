import { useEffect, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import type { Column } from 'material-table';
import type { DropTable } from '../../../services/drop-tables';
import { DropTablesService } from "../../../services/drop-tables";
import { Box, Button, CircularProgress, Grid, Link } from '@material-ui/core';
import MaterialTable from '../../../components/MaterialTable';
import DeleteIcon from '@material-ui/icons/Delete';
import ConfirmationDialog from '../../../components/ConfirmationDialog';
import { UserService } from '../../../services/user';
import type { Catalog } from "../../../services/catalogs";
import { pushAppNotification } from "../../../shared/hooks/useAppNotification";

type Props = {
  catalog: Catalog;
}

const renderDropTableLink = (dropTable: DropTable) => {
  const url = `/catalogs/${dropTable.catalogName}/drop-tables/${dropTable.id}`;
  return (
    <Link component={RouterLink} to={url}>{dropTable.id}</Link>
  );
};

export const CatalogDropTables = ({catalog}: Props) => {
  const columns: Column<DropTable>[] = [
    {title: 'ID', field: 'id', defaultSort: 'asc', render: renderDropTableLink}
  ];
  const [confirmationOpen, setConfirmationOpen] = useState(false);
  const [confirmationTitle, setConfirmationTitle] = useState('');
  const [dropTables, setDropTables] = useState<DropTable[]>([]);
  const [loadingDropTables, setLoadingDropTables] = useState(true);
  const [dropTableToDelete, setDropTableToDelete] = useState<DropTable | null>(null);
  const confirmationCallback = () => {
    if (dropTableToDelete) {
      DropTablesService.deleteDropTable(dropTableToDelete)
        .then(() => {
          pushAppNotification({ type: 'success', message: 'Drop table deleted' });
          setDropTables(dropTables.filter(i => i !== dropTableToDelete));
        })
        .catch(err => {
          pushAppNotification({type: 'error', message: `Failed to delete drop table: ${err.message}`});
        });
      setConfirmationOpen(false);
    }
  };

  useEffect(() => {
    setLoadingDropTables(true);
    DropTablesService.getDropTables(catalog.name)
      .then(dts => setDropTables(dts))
      .catch(err => {
        pushAppNotification({type: 'error', message: `Failed to get catalog items: ${err.message}`});
      })
      .finally(() => setLoadingDropTables(false));
  }, [catalog.name]);

  const deleteDropTable = (dropTable: DropTable) => {
    setDropTableToDelete(dropTable);
    setConfirmationOpen(true);
    setConfirmationTitle(`Delete ${dropTable.id}?`);
  };

  return loadingDropTables ? (
    <Box textAlign="center">
      <CircularProgress/>
    </Box>
  ) : (<>
    {UserService.canCreate('catalogItems') && (
      <Box mb={2}>
        <Grid container alignItems="center">
          <Grid item xs/>
          <Grid item>
            <Button
              component={RouterLink}
              variant="contained"
              color="primary"
              to={`/catalogs/${catalog.name}/add-drop-table`}
            >
              Add drop table
            </Button>
          </Grid>
        </Grid>
      </Box>
    )}

    <MaterialTable
      title="Drop tables"
      columns={columns}
      data={dropTables}
      menuActions={UserService.canDelete('catalogItems') ? [
        {type: 'button', icon: DeleteIcon, label: 'Delete', onClick: deleteDropTable}
      ] : undefined}
    />

    <ConfirmationDialog
      title={confirmationTitle}
      open={confirmationOpen}
      onClose={() => setConfirmationOpen(false)}
      onConfirm={confirmationCallback}
    />
  </>);
};

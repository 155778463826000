import { MigrationDiffCommonProps } from "../../migrations";
import { DiffsPanels } from "../../components/diff/DiffsPanels";
import { GeoBlockingDiff } from "../../../../pages/CatalogMigration/types";

export const GeoBlockingDiffs = (props: MigrationDiffCommonProps) => {

  const diffs = props.migrationData.geoblocking;

  if (diffs) {
    return (
      <DiffsPanels<GeoBlockingDiff>
        diffs={diffs}
        columns={[
          { title: 'Feature', field: 'featureId', defaultSort: 'asc' },
          { title: 'Regions', field: 'regions', render(data) {
            return(<>
              {data.regions.join(",")}
            </>); 
            }
          },
        ]}
        selectionMap={props.selections.geoblocking || {}}
        onSelectionChange={map => props.onSelectionsChange({ ...props.selections, geoblocking: map })}
        {...props}
      />
    )
  }

  return null;
};
import type { RowMenuAction, RowMenuActionButton } from ".";
import { MenuItem, ListItemIcon, Box, Divider } from "@material-ui/core";

interface RowMenuItemProps<T> {
  item: T
  action: RowMenuAction<T>
  onClick: (item: T, action: RowMenuActionButton<T>) => void
}

export function RowMenuItem<T>({item, action, onClick}: RowMenuItemProps<T>) {
  switch (action.type) {
  case 'button':
    return (
      <MenuItem
        onClick={() => onClick(item, action)}
        disabled={action.disabled && action.disabled(item)}
      >
        <ListItemIcon>
          <action.icon fontSize="small" />
        </ListItemIcon>
        {action.label}
      </MenuItem>
    );
  case 'divider':
    return (
      <Box my={1}>
        <Divider />
      </Box>
    );
  case 'link':
    return (
      <MenuItem component="a" href={action.href}>
        <ListItemIcon>
          <action.icon fontSize="small" />
        </ListItemIcon>
        {action.label}
      </MenuItem>
    );
  default: 
    return null
  }
}
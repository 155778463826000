import { Box, Button } from '@material-ui/core';
import type { MigrationCommonProps } from '../migrations';

export const MigrationDone = (props: MigrationCommonProps) => {
  return (
    <Box mt={2}>
      <Button color="primary" variant="contained" onClick={() => props.onSetStep('options')}>
        Start another
      </Button>
    </Box>
  );
}
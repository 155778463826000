import type { SyntheticEvent } from 'react';
import { Component } from 'react';
import type { ConnectedProps } from 'react-redux';
import { connect } from 'react-redux';
import type { RootState } from '../../redux/reducers';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core';
import { RewardPathTierDto } from "../../services/player-challenges/season-reward-path-tiers";
import { createRewardPathTierAsync, updateRewardPathTierAsync, clearSeasonRewardPathTierError } from '../../redux/player-challenges/season-reward-path-tiers/actions';
import RewardPathTierControl from './RewardPathTierControl';
import _ from 'lodash';

interface OwnProps {
  seasonNumber: number,
  pathId: string,
  rewardPathTier?: RewardPathTierDto;
  isLastTier: boolean;
  onClose: (tier?: RewardPathTierDto) => void;
}
const mapStateToProps = (state: RootState, props: OwnProps) => ({
  pathTiers: state.seasonRewardPathTiers
});
const mapDispatch = {
  requestCreate: createRewardPathTierAsync.request,
  requestUpdate: updateRewardPathTierAsync.request,
  clearError: clearSeasonRewardPathTierError
};
const connector = connect(mapStateToProps, mapDispatch);

type Props = OwnProps & ConnectedProps<typeof connector>;

interface State {
  rewardPathTier: RewardPathTierDto;
  open: boolean;
}

class SeasonRewardPathTierDialog extends Component<Props, State> {

  constructor(props: Props) {
    super(props);
    this.state = {
      rewardPathTier: !props.rewardPathTier ? new RewardPathTierDto() : _.cloneDeep(props.rewardPathTier),
      open: true
    };
  }

  onDialogClose = () => {
    this.props.clearError();
    this.props.onClose(this.props.rewardPathTier);
  };

  handleCancel = (event: SyntheticEvent) => {
    event.preventDefault();
    this.setState({ open: false })
  }
  handleSubmit = (event: SyntheticEvent) => {
    event.preventDefault();
    const { rewardPathTier, seasonNumber, pathId, requestUpdate, requestCreate } = this.props;
    const { rewardPathTier: newRewardPathTier } = this.state;
    if (rewardPathTier) {
      requestUpdate({ request: { ...newRewardPathTier, seasonNumber, pathId, tierId: rewardPathTier.tierId } });
    } else {
      requestCreate({ request: { ...newRewardPathTier, seasonNumber, pathId } })
    }
  };

  shouldComponentUpdate(nextProps: Props, nextState: State): any {
    if (this.props.pathTiers.isSaving && !nextProps.pathTiers.isSaving && !nextProps.pathTiers.error) {
      this.setState({ open: false });
      return false;
    }
    return true;
  }

  render() {
    const title = !this.props.rewardPathTier ? 'Add Tier' : 'Edit Tier';  
    const isSaving = !!this.props.pathTiers.isSaving;  
    return (
      <Dialog
        open={this.state.open}
        onClose={() => this.setState({ open: false })}
        onExited={this.onDialogClose}
        fullWidth
        maxWidth="xl"
      >
        <form onSubmit={this.handleSubmit}>
          <DialogTitle>{title}</DialogTitle>
          <DialogContent>
            <RewardPathTierControl editMode rewardPathTier={this.state.rewardPathTier} showAddReward isLast={this.props.isLastTier}/>
          </DialogContent>
          <DialogActions>
            <Button type="button" color="primary" disabled={isSaving} variant="contained" onClick={this.handleSubmit}>Save</Button>
            <Button type="button" color="secondary" disabled={isSaving} variant="outlined" onClick={this.handleCancel}>Cancel</Button>
          </DialogActions>
        </form>
      </Dialog>);
  }
}

export default connector(SeasonRewardPathTierDialog);
import { Box, CircularProgress, Typography } from '@material-ui/core';
import { useEffect, useState } from 'react';
import type { RouteComponentProps } from 'react-router';
import type { LinkTabConfig } from '../../../components/LinkTabs';
import { LinkTabs } from '../../../components/LinkTabs';
import { CatalogDropTables } from './CatalogDropTables';
import { CatalogItems } from './CatalogItems';
import { CatalogStores } from './CatalogStores';
import { UserService } from '../../../services/user';
import type { Catalog } from "../../../services/catalogs";
import type { ItemType, ItemDefinition } from "../../../services/item-definitions";
import { CatalogsService } from "../../../services/catalogs";
import { pushAppNotification } from "../../../shared/hooks/useAppNotification";
import { ItemDefinitionsService } from "../../../services/item-definitions";

type Props = RouteComponentProps<{ catalogName: string }>;

export const CatalogPage = (props: Props) => {
  const [catalog, setCatalog] = useState<Catalog | null>(null);
  const [items, setItems] = useState<ItemDefinition[]>([]);
  const [loadingItems, setLoadingItems] = useState(true);

  const getTabs = (catalog: Catalog) => {
    const catalogItemsComponent = (itemType: ItemType) =>
      <CatalogItems
        catalog={catalog} itemType={itemType}
        items={items} loadingItems={loadingItems}
        setItems={setItems}/>;
    const tabs: LinkTabConfig[] = UserService.canRead('catalogItems') ? [
      {url: '', label: 'Items', render: () => catalogItemsComponent('item')},
      {url: '/bundles', label: 'Bundles', render: () => catalogItemsComponent('bundle')},
      {url: '/containers', label: 'Containers', render: () => catalogItemsComponent('container')},
      {
        url: '/drop-tables', label: 'Drop Tables', render: function render() {
          return <CatalogDropTables catalog={catalog}/>;
        }
      }
    ] : [];

    if (UserService.canRead('store')) {
      tabs.push({
        url: '/stores', label: 'Stores', render: function render() {
          return <CatalogStores catalog={catalog}/>;
        }
      });
    }
    return tabs;
  };

  useEffect(() => {
    if (props.match.params.catalogName) {
      setLoadingItems(true);
      ItemDefinitionsService.getItemDefinitions(props.match.params.catalogName)
        .then(itemDefs => setItems(itemDefs))
        .catch(err => {
          pushAppNotification({type: 'error', message: `Failed to get catalog items: ${err.message}`});
        })
        .finally(() => setLoadingItems(false));
    }
  }, [props.match.params.catalogName]);

  useEffect(() => {
    CatalogsService.getCatalog(props.match.params.catalogName)
      .then(cat => setCatalog(cat))
      .catch(err => {
        pushAppNotification({type: 'error', message: `Failed to get catalog: ${err.message}`});
      });
    return () => {
      setCatalog(null);
    };
  }, [props.match.params.catalogName]);

  if (catalog && props.match.params.catalogName === catalog.name) {
    return (<>
      <Box>
        <Typography variant="h6">
          Catalog: {props.match.params.catalogName}
        </Typography>
      </Box>
      <LinkTabs tabs={getTabs(catalog)} {...props} />
    </>);
  } else {
    return (
      <Box textAlign="center">
        <CircularProgress/>
      </Box>
    );
  }
};

import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle, FormControl,
  MenuItem,
  Select
} from "@material-ui/core";
import { useCallback, useMemo, useState } from "react";
import type {PlayerTag} from "../accounts";
import {addTagToPlayerAsync} from "../accountApi";
import {pushAppNotification} from "../../../shared/hooks/useAppNotification";
import _ from "lodash";

interface Props {
  show: boolean
  tags?: PlayerTag[]
  onClose: () => void
  onSubmit: () => void
  onSuccess: () => void
  playerId: string;
}

export const TagSelectDialog = ({ show, tags, onClose, onSubmit, playerId, onSuccess}: Props) => {

  const tagsById = useMemo(() => {
    return _.keyBy(tags, 'id');
  }, [tags]);
  const [isSubmitting, setSubmitting] = useState(false);
  const [selectedTagId, setSelectedTagId] = useState( '');

  const handleChange = (event: { target: { value: any; }; }) => {
    setSelectedTagId(event.target.value);
  };

  const handleSubmit = useCallback(() => {
    addTagToPlayerAsync(playerId, selectedTagId)
      .then(() => {
        pushAppNotification({ type: 'success', message: `Successfully added ${tagsById[selectedTagId].tag} to player` });
        onSuccess();
      }).catch((err) => {
        console.log(err);
        pushAppNotification({ type: 'error', message: `Failed to add ${tagsById[selectedTagId].tag} to player: ${err.message}` });
      });
    setSubmitting(true);
    onSubmit();
    onClose();
  }, [playerId, selectedTagId, onSubmit, onClose, tagsById, onSuccess]);

  return(
    <Dialog
      open={show}
      onClose={onClose}
      onExited={onClose}
      fullWidth
    >
      <DialogTitle>Add Tag</DialogTitle>
      <DialogContent>
        <FormControl fullWidth>
          <Select name="tag"
            label="Tag"
            value={selectedTagId}
            onChange={handleChange}
          >
            {tags && tags.map((tag) =>
              <MenuItem key={tag.id} value={tag.id}>{tag.tag}</MenuItem>
            )}
          </Select>
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button disabled={isSubmitting} variant="contained" color="primary" onClick={handleSubmit}>
          {isSubmitting ? (
            <CircularProgress size={25} />
          ) : 'Add Tag to Player'}
        </Button>
      </DialogActions>
    </Dialog>
  )

}

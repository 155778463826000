import { Box, CircularProgress, FormControl, Grid, InputLabel, MenuItem, Select } from '@material-ui/core';
import { useArtists, useArtistsSortedByName } from '../../features/artists/hooks';

interface Props {
  value: string | null;
  readOnly?: boolean;
  onChange: (value: string | null) => void;
}

const ArtistsSelect = (props: Props) => {
  const { data: artistsById } = useArtists();
  const { data: artists, isLoading, isSuccess } = useArtistsSortedByName();


  const selectedArtist = props.value ? artistsById?.get(props.value) : null;

  if (isLoading) {
    return (
      <CircularProgress size={25} />
    );
  }

  // TODO: Handle fail state
  if (!isSuccess || artists === undefined) return <></>

  return (<>
    <Grid container spacing={1} alignItems="center">
      <Grid item xs>
        <FormControl fullWidth>
          <InputLabel>Artist</InputLabel>
          <Select
            value={props.value && artistsById?.has(props.value) ? props.value : ''}
            onChange={event => props.onChange(event.target.value as string)}
            inputProps={{ readOnly: props.readOnly }}
          >
            <MenuItem value=""><em>No artist</em></MenuItem>
            {artists.map(artist => (
              <MenuItem key={artist.id} value={artist.id}>{artist.name}</MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
      
      {selectedArtist && (
        <Grid item xs={12}>
          <Box mt={2}>
            <img alt="Artist" src={selectedArtist.imageUrl} height="96" />
          </Box>
        </Grid>
      )}
    </Grid>
  </>)
}

export default ArtistsSelect;
import { Dialog, DialogContent, Grid } from '@material-ui/core';
import { BrawlSettingsForm } from './BrawlSettingsForm';
import { useState } from 'react';

interface Props {
  blockId: string,
  brawlMaxPlayers: number,
  brawlMinPlayers: number,
  brawlWeight: number,
  brawlEliminationsPercentage: number,
  brawlPlaylist: string,
  blockMaxPlayers: number,
  blockMinPlayers: number,
  brawlIsNonEliminationCompatible: boolean,
  skillLevelThreshold: number,
  onClose: () => void,
};


export const BrawlSettingsFormDialog = (props: Props) => {
  const [isOpen, setIsOpen] = useState(true);
  return (<>
    <Dialog
      open={isOpen}
      onClose={() => setIsOpen(false)}
      onExited={props.onClose}
      maxWidth="sm"
    >
      <DialogContent style={{ padding: 0 }}>
        <Grid>
          <BrawlSettingsForm
            blockId={props.blockId}
            brawlMinPlayers={props.brawlMinPlayers}
            brawlMaxPlayers={props.brawlMaxPlayers}
            brawlWeight={props.brawlWeight}
            brawlEliminationsPercentage={props.brawlEliminationsPercentage}
            brawlPlaylist={props.brawlPlaylist}
            blockMaxPlayers={props.blockMaxPlayers}
            blockMinPlayers={props.blockMinPlayers}
            brawlIsNonEliminationCompatible={props.brawlIsNonEliminationCompatible}
            skillLevelThreshold={props.skillLevelThreshold}
        />
        </Grid>
      </DialogContent>
    </Dialog>
  </>);
}
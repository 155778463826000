import { getEnvApi } from "./api";
import type { EnvironmentFeatures } from "../features/migrations/migrations";

const defaultEnvironmentFeatures: EnvironmentFeatures = {
  gridWidthSegments: 4,
};

export const getEnvironmentFeatures = async (env: string): Promise<EnvironmentFeatures> => {
  let features: Partial<EnvironmentFeatures>;
  try {
    features = await getEnvApi(env).get({url: '/environment/features'});
  } catch (e) {
    // Endpoint doesn't exist in this environment, use all default values
    features = {};
  }
  return Object.assign({}, defaultEnvironmentFeatures, features);
};

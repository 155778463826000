import type { MigrationDiffCommonProps } from '../../migrations';
import { DiffsPanels } from '../../components/diff/DiffsPanels';

export const ShopVersionDiffs = (props: MigrationDiffCommonProps) => {

  const diffs = props.migrationData.shopVersions;

  if (diffs) {
    return (
      <DiffsPanels
        diffs={diffs}
        columns={[
          { title: 'Shop', render(diff) { return <>{diff.shop.name}</>; } },
          { title: 'Shop version', render(diff) { return <>{diff.shopVersion.name}</>; } },
          { title: 'Start date', render(diff) { return <>{diff.shopVersion.startDate ? new Date(diff.shopVersion.startDate).toLocaleString() : '-'}</>; } },
        ]}
        selectionMap={props.selections.shopVersion || {}}
        onSelectionChange={map => props.onSelectionsChange({ ...props.selections, shopVersion: map })}
        {...props}
      />
    )
  }

  return null;
}

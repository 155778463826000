import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from '@material-ui/core';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { setAppNotification } from '../../../redux/app/actions';
import type { ApiError } from '../../../services/api';
import type { Shop } from '../shop';
import { createShop, updateShop } from '../shopsApi';

interface ShopNameDialogProps {
  handleShopUpdate: (shop: Shop) => void;
  onClose: () => void;
  shop: Shop | null;
  shops: Shop[];
}

export const ShopNameDialog = (props: ShopNameDialogProps) => {
  const { handleShopUpdate } = props;

  const dispatch = useDispatch();

  const [open, setOpen] = useState(true);
  const [name, setName] = useState(props.shop ? props.shop.name : '');
  const [error, setError] = useState('');

  useEffect(() => {
    const trimmedName = name.trim();
    if (!trimmedName) {
      setError('A name is required');
    } else if ((!props.shop || props.shop.name !== trimmedName) && props.shops.findIndex(v => v.name === trimmedName) > -1) {
      setError('A shop with that name already exists');
    } else {
      setError('');
    }
  }, [name, props.shops, props.shop]);

  const onAddRenameClick = useCallback(() => {
    if (props.shop) {
      updateShop({ ...props.shop, name }).then(updatedShop => {
        handleShopUpdate(updatedShop);
        setOpen(false);
        dispatch(setAppNotification({ type: 'success', message: 'Shop updated' }));
      }).catch((e: ApiError) => dispatch(setAppNotification({ type: 'error', message: `Error updating shop. ${e.message}` })));
    } else {
      createShop(name).then(newShop => {
        handleShopUpdate(newShop);
        setOpen(false);
        dispatch(setAppNotification({ type: 'success', message: 'Shop created' }));
      }).catch((e: ApiError) => dispatch(setAppNotification({ type: 'error', message: `Error creating shop. ${e.message}` })));
    }
  }, [dispatch, name, props.shop, handleShopUpdate]);

  return (
    <Dialog
      open={open}
      onClose={() => setOpen(false)}
      onExited={props.onClose}
      fullWidth
      maxWidth="sm"
    >
      <DialogTitle>{props.shop ? 'Rename' : 'Add'} Shop</DialogTitle>
      <DialogContent>
        <TextField
          label="Name"
          fullWidth
          autoFocus
          error={Boolean(error)}
          helperText={error}
          value={name}
          onChange={e => setName(e.target.value)}
        />
      </DialogContent>
      <DialogActions>
        <Button type="submit" color="primary" variant="contained" onClick={onAddRenameClick} disabled={Boolean(error)}>
          {`${props.shop ? 'Rename' : 'Add'} Shop`}
        </Button>
      </DialogActions>
    </Dialog>
  )
}
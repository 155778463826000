import { Box, Button, CircularProgress, Grid, Link } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import ListAltIcon from '@material-ui/icons/ListAlt';
import type { Column } from 'material-table';
import { useCallback, useEffect, useState } from 'react';
import ItemCustomDataDialog from '../../../components/ItemCustomDataDialog';
import type { MenuAction } from '../../../components/MaterialTable';
import MaterialTable from '../../../components/MaterialTable';
import type { DropTable } from '../../../services/drop-tables';
import { ItemDefinition } from '../../../services/item-definitions';
import type { ItemInstance } from '../../../services/model/item-instance';
import { UserService } from '../../../services/user';
import { RouterLink } from '../../../types';
import ItemSelectDialog from '../../../pages/ItemDefinition/ItemSelectDialog';
import { PlayerItemsService } from "../player-items";
import { pushAppNotification } from "../../../shared/hooks/useAppNotification";
import { openAdminConfirmationDialog } from "../../../shared/hooks/useAdminConfirmationDialog";
import { arrayReplaceOrPush } from "../../../redux/utils-ts";

type Props = {
  playerId: string;
};

const columns: Column<ItemInstance>[] = [
  {title: 'Instance ID', field: 'id'},
  {
    title: 'Catalog', field: 'catalogName', render: item => (
      <Link component={RouterLink} to={`/catalogs/${item.catalogName}`}>
        {item.catalogName}
      </Link>
    )
  },
  {
    title: 'Item ID', field: 'itemId', render: item => (
      <Link component={RouterLink} to={`/catalogs/${item.catalogName}/items/${item.itemId}`}>
        {item.itemId}
      </Link>
    )
  },
  {title: 'Name', field: 'displayName', render: item => <>{item.displayName || item.itemId}</>},
  {title: 'dGood #', field: 'dgoodSerial', render: item => <>{item.dgoodSerial || '-'}</>},
  {title: 'Class', field: 'itemClass'},
  {
    title: 'Created/purchased at',
    field: 'purchaseTimestamp',
    defaultSort: 'desc',
    render: item => <>{item.purchaseTimestamp.toLocaleString()}</>
  },
  {
    title: 'Blanko',
    field: 'blankoId',
    render: item => item.blankoId ? (
      <Link component={RouterLink} to={`/blankos/${item.blankoId}`}>
        {item.blankoId}
      </Link>
    ) : null
  }
];

export const PlayerItems = ({playerId}: Props) => {
  const [itemsDialogOpen, setItemsDialogOpen] = useState(false);
  const [customDataItem, setCustomDataItem] = useState<ItemInstance | undefined>();
  const [items, setItems] = useState<ItemInstance[] | undefined>();

  const fetchItems = useCallback(() => {
    PlayerItemsService.getPlayerItems(playerId)
      .then(items => setItems(items))
      .catch(err => pushAppNotification({type: 'error', message: `Failed to get player items: ${err.message}`}));
  }, [playerId]);

  useEffect(fetchItems, [fetchItems]);

  const onGrantItemSelect = (items: (ItemDefinition | DropTable)[]) => {
    const item = items[0];
    if (item instanceof ItemDefinition) {
      PlayerItemsService.grantItem(playerId, item.catalogName, item.itemId)
        .then(() => {
          pushAppNotification({type: 'success', message: `Item granted`});
          fetchItems();
        })
        .catch(err => pushAppNotification({type: 'error', message: `Failed to grant item: ${err.message}`}));
    }
  };

  const onRevokeItem = (item: ItemInstance) => {
    openAdminConfirmationDialog({
      title: `Revoke ${item.displayName || item.itemId}?`, action: 'Revoke item',
      onConfirm: () => {
        PlayerItemsService.revokeItem(playerId, item.id)
          .then(() => {
            pushAppNotification({type: 'success', message: `Item revoked`});
            setItems(items => items && items.filter(i => i.id !== item.id));
          })
          .catch(err => pushAppNotification({type: 'error', message: `Failed to revoke item: ${err.message}`}));
      }
    });
  };

  const actions: MenuAction<ItemInstance>[] = [];
  if (UserService.canRead('itemInstances') || UserService.canUpdate('itemInstances')) {
    actions.push({type: 'button', icon: ListAltIcon, label: 'Custom data', onClick: item => setCustomDataItem(item)});
  }

  if (UserService.canDelete('itemInstances')) {
    if (actions.length > 0) {
      actions.push({type: 'divider'});
    }
    actions.push({type: 'button', icon: DeleteIcon, label: 'Revoke', onClick: onRevokeItem});
  }

  if (!items) {
    return (
      <Box textAlign="center">
        <CircularProgress/>
      </Box>
    );
  } else {
    return (<>
      {UserService.canGrantItems() && (
        <Box mb={2}>
          <Grid container alignItems="center">
            <Grid item xs/>
            <Grid item>
              <Button
                variant="contained"
                color="primary"
                onClick={() => setItemsDialogOpen(true)}
              >
                Grant item
              </Button>
            </Grid>
          </Grid>
        </Box>
      )}

      <MaterialTable
        title="Items"
        storageId="player-items"
        columns={columns}
        data={items}
        options={{
          columnsButton: true,
          pageSize: 20,
          pageSizeOptions: [20, 50, 100]
        }}
        menuActions={actions}
        useRouter
      />

      {itemsDialogOpen && (
        <ItemSelectDialog
          showCatalogSelect
          onSelect={onGrantItemSelect}
          onClose={() => setItemsDialogOpen(false)}
        />
      )}

      {customDataItem && (
        <ItemCustomDataDialog
          item={customDataItem}
          onSave={item => setItems(items => items && arrayReplaceOrPush(items, item, i => i.id === item.id))}
          onClose={() => setCustomDataItem(undefined)}
        />
      )}
    </>);
  }
};

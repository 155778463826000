import type { DiffItem } from '../../../pages/CatalogMigration/types';
import type { MigrationModule } from '../migrations';
import { blankoAssetMigration } from './blanko-asset/blanko-asset-migration';
import { blankoProgressionMigration } from './blanko-progression/blanko-progression-migration';
import { dnaMigration } from './dna/dna-migration';
import { dropTableMigration } from './droptable/droptable-migration';
import { itemMigration } from './item/item-migration';
import { shopVersionMigration } from './shop-version/shop-version-migration';
import { storeMigration } from './store/store-migration';
import { getTitleDataMigration } from './title-data/title-data-migration';
import { titleFileMigration } from './title-files/title-file-migration';
import { gemRushMigration } from './gem-rush/gem-rush-migration';
import { blankoClassMigration } from './blanko-classes/blanko-class-migration';
import { roleMigration } from "./roles/role-migration";
import { brawlPinMigration } from './brawl-pins/brawl-pin-migration';
import { itemNoteMigration } from "./item-note/item-note-migration";
import { geoblockingMigration} from "./geoblocking/geo-blocking-migration";
import { liveEventMigration } from "./live-event/live-event-migration";
import { MigrationType } from "./migration-type";
import { challengeTriggerMigration } from './challenge-triggers/challenge-trigger-migration';

export const migrationModules: { [key in MigrationType]?: MigrationModule<DiffItem> } = {
  item: itemMigration,
  droptable: dropTableMigration,
  store: storeMigration,
  shopVersion: shopVersionMigration,
  dna: dnaMigration,
  blankoProgression: blankoProgressionMigration,
  blankoAsset: blankoAssetMigration,
  titleDataClient: getTitleDataMigration('client'),
  titleDataServer: getTitleDataMigration('server'),
  titleFile: titleFileMigration,
  gemRush: gemRushMigration,
  blankoClass: blankoClassMigration,
  roles: roleMigration,
  brawlPins: brawlPinMigration,
  itemNotes: itemNoteMigration,
  geoblocking: geoblockingMigration,
  liveEvents: liveEventMigration,
  challengeTriggers: challengeTriggerMigration,
};

// Migration modules, in run order. Dependencies matter here.
// TODO: Figure out a way to calculate this based on dependencies.
export const migrationRunOrder: MigrationType[] = [MigrationType.item, MigrationType.droptable, MigrationType.store, MigrationType.shopVersion,
  MigrationType.dna, MigrationType.blankoProgression, MigrationType.blankoAsset, MigrationType.blankoClass, MigrationType.seasonalData,
  MigrationType.titleDataClient, MigrationType.titleDataServer, MigrationType.titleFile, MigrationType.gemRush, MigrationType.roles,
  MigrationType.brawlPins, MigrationType.itemNotes, MigrationType.liveEvents, MigrationType.geoblocking, MigrationType.challengeTriggers];

// Dev sanity check
Object.values(MigrationType).forEach(key => {
  const migrationModule = migrationModules[key];
  if (!migrationModule) {
    return;
  }
  if (key !== migrationModule.id) {
    console.error(`Module key does not match the module ID: ${key} vs. ${migrationModule.id}`);
  }

  if (migrationRunOrder.indexOf(key) < 0) {
    console.error(`Migration module not included in run order: ${key}`);
  }
});

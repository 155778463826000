import type { Reducer } from 'redux';
import { createReducer } from 'typesafe-actions';
import { CurrencyList } from '../../services/model/currency';
import { loadCurrenciesAsync } from './actions';

export interface CurrenciesState {
  list: CurrencyList;
}

const currenciesReducer: Reducer<CurrenciesState> = createReducer({ list: new CurrencyList([]) })
  .handleAction(loadCurrenciesAsync.success, (state, action) => ({
    ...state,
    list: action.payload
  }));

export default currenciesReducer;
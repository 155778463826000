import {
  Box, Button,
  Card, CardActions, CardContent, CardHeader,
  Dialog, DialogActions, DialogContent, DialogTitle,
  FormControl, Grid, IconButton,
  List, ListItem, ListItemIcon, ListItemText,
  TextField
} from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import React, {useCallback, useEffect, useState} from "react";
import {GameQueueService} from "../../services/launcher/game-queue";
import {usePushNotification} from "../../contexts/AppNotificationContext";

interface Props {
  queueName: string;
}
export const GameQueueVip = (props: Props) => {
  const pushNotification = usePushNotification();

  const [addVipDialogVisible, setAddVipDialogVisible] = useState<boolean>(false);
  const [vipsToAdd, setVipsToAdd] = useState<string>("");
  const [vipsValid, setVipsValid] = useState<boolean>(false);
  const [filterValue, setFilterValue] = useState<string>("");
  const [vips, setVips] = useState<string[]>([]);

  const getData = useCallback(async () => {
    const result = await GameQueueService.getVips(props.queueName);
    setVips(result);
  }, [setVips, props.queueName]);

  const getVips = useCallback((): string[] => vips.filter(o => o.indexOf(filterValue) > -1), [filterValue, vips]);

  const closeAddVipDialog = (): void => setAddVipDialogVisible(false);
  const openAddVipDialog = (): void => setAddVipDialogVisible(true);

  const mythicalIdRegex = /^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/i;
  const validateId = (id: string): boolean => mythicalIdRegex.test(id);

  const compressedGuidRegex = /^(.{8})(.{4})(.{4})(.{4})(.{12})$/;
  const guidTemplate = "$1-$2-$3-$4-$5";
  const getVipsList = (vipData: string): string[] => vipData
    .replace(/[;\n]/g, ",")
    .replace(/[\s"-]/g, "")
    .split(",")
    .filter(o => o.length > 0)
    .map(o => o.replace(compressedGuidRegex, guidTemplate));

  const validateVipsToAdd = (vipData: string): boolean => {
    const vipsToValidate = getVipsList(vipData);
    return vipsToValidate.length > 0 && vipsToValidate.every(id => validateId(id));
  };

  const addVips = async (): Promise<void> => {
    try {
      await GameQueueService.addVips(props.queueName, getVipsList(vipsToAdd));
      pushNotification({type: "success", message: "VIP's added"});
    }
    catch {
      pushNotification({type: "error", message: "Failed adding VIP's"});
    }

    await getData();

    setVipsToAdd("");
    closeAddVipDialog();
  };

  const removeVip = async (vipId: string): Promise<void> => {
    try {
      await GameQueueService.deleteVips(props.queueName, [vipId]);
      pushNotification({type: "success", message: "VIP removed"});
    } catch {
      pushNotification({type: "error", message: "Failed to remove VIP"});
    }

    setVips(vips.filter(o => o != vipId));
  };

  const vipsTextFieldChanged = (vips: string): void => {
    const isValid = validateVipsToAdd(vips);
    setVipsValid(isValid);
    setVipsToAdd(vips);
  }

  useEffect(() => { getData().then(); }, [getData]);

  return (
    <>
      <Card>
        <CardContent>
          <CardHeader title={
            <Grid container alignContent={"space-between"} style={{margin: "-16px -16px 0 -16px"}}>
              <Grid item xs={6}>
                VIP List
              </Grid>
              <Grid item xs={6}>
                <FormControl>
                  <TextField placeholder={"Filter"} onChange={(event) => setFilterValue(event.target.value)}/>
                </FormControl>
              </Grid>
            </Grid>
          }/>
          <Box height={800} overflow={"auto"}>
            <List>
              {
                getVips().map(guid => {
                  return (
                    <ListItem key={guid}>
                      <ListItemIcon>
                        <IconButton onClick={() => removeVip(guid)}>
                          <DeleteIcon/>
                        </IconButton>

                      </ListItemIcon>
                      <ListItemText>
                        {guid}
                      </ListItemText>
                    </ListItem>
                  );
                })
              }
            </List>
          </Box>
        </CardContent>
        <CardActions>
          <Button onClick={openAddVipDialog} variant={"contained"} color={"primary"}>Add VIP</Button>
        </CardActions>
      </Card>
      <Dialog open={addVipDialogVisible}>
        <DialogTitle>Add VIP</DialogTitle>
        <DialogContent>
          <em>
            Enter the mythical id&apos;s of players that should bypass queue separated by commas.
          </em>
          <FormControl fullWidth>
            <TextField
              onChange={(event) => vipsTextFieldChanged(event.target.value)} variant={"outlined"}
              multiline={true} rows={5} />
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeAddVipDialog}>Cancel</Button>
          <Button onClick={addVips} disabled={!vipsValid} variant={"contained"} color={"primary"}>Add</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
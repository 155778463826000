import { createAction } from 'typesafe-actions';
import type { NotificationType, Notification } from "../../contexts/AppNotificationContext";

// TODO: remove and rely on the regular path
export type { NotificationType, Notification };

export interface ConfirmationDialogProps {
  title: string;
  details?: string;
  includeNote?: boolean;
  requireNote?: boolean;
  multiLineNote?: boolean;
  action?: string;
  requireCode?: boolean;
  codeLabel?: string;
  code?: string;
}

export const setAppNotification = createAction('SET_APP_NOTIFICATION', (notification: Notification | null) => ({ notification }))();

export const openConfirmationDialog = createAction(
  'OPEN_CONFIRMATION_DIALOG',
  (titleOrProps: string | ConfirmationDialogProps, onConfirm: () => void, onCancel?: () => void) => ({ props: typeof titleOrProps === 'string' ? { title: titleOrProps } : titleOrProps, onConfirm, onCancel })
)();
export const closeConfirmationDialog = createAction('CLOSE_CONFIRMATION_DIALOG')();

export const setDarkMode = createAction('SET_DARK_MODE', (darkMode: boolean) => ({ darkMode }))();

import { Box, Grid, Typography } from '@material-ui/core';
import type { MigrationActions } from '../../../../redux/catalog-migration/reducer';
import { useTypedSelector } from '../../../../redux/reducers';

const reviewItems: { name: string, id: string }[] = [
  { name: 'Items', id: 'items' },
  { name: 'Drop tables', id: 'dropTables' },
  { name: 'Stores', id: 'stores' },
  { name: 'Title data - Client', id: 'titleDataClient' },
  { name: 'Title data - Server', id: 'titleDataServer' },
  { name: 'Title files', id: 'titleDataFiles' },
  { name: 'Blanko DNAs', id: 'blankoDnas' },
  { name: 'Blanko assets', id: 'blankoAssets' },
  { name: 'Blanko progressions', id: 'blankoProgressions' },
  { name: 'Seasonal data', id: 'seasonalData' },
  { name: 'Translations', id: 'translations' },
  { name: 'Text Languages', id: 'textLanguages' },
];

const ReviewStepItemCount = (props: { value: number, warn?: boolean }) => {
  return (
    <Box textAlign="right">
      <Typography color={props.warn && props.value > 0 ? 'primary' : 'textPrimary'}>
        {props.value}
      </Typography>
    </Box>
  )
}

const ReviewStepActions = (props: { title: string, actions: MigrationActions }) => {
  const { title, actions } = props;

  if (actions && actions.add.length + actions.remove.length + actions.update.length > 0) {
    return (<>
      <Typography variant="h6">{title}</Typography>
      <Grid container spacing={1}>
        <Grid item xs={6} lg={2}>
          Changed:
        </Grid>
        <Grid item xs={6} lg={2}>
          <ReviewStepItemCount value={actions.update.length} />
        </Grid>
      </Grid>

      <Grid container spacing={1}>
        <Grid item xs={6} lg={2}>
          Added:
        </Grid>
        <Grid item xs={6} lg={2}>
          <ReviewStepItemCount value={actions.add.length} />
        </Grid>
      </Grid>

      <Grid container spacing={1}>
        <Grid item xs={6} lg={2}>
          Removed:
        </Grid>
        <Grid item xs={6} lg={2}>
          <ReviewStepItemCount value={actions.remove.length} warn />
        </Grid>
      </Grid>
    </>);
  }

  return null;
}

export const ReviewStep = () => {
  const actions = useTypedSelector(state => state.catalogMigration.actions);

  let canRunMigration = false;
  for (const key in actions) {
    if (actions[key].add.length + actions[key].update.length + actions[key].remove.length > 0) {
      canRunMigration = true;
      break;
    }
  }

  return (
    <Box mt={2}>
      {!canRunMigration && (
        <Typography color="textSecondary">
          {`It looks like there's nothing to migrate.`}
        </Typography>
      )}

      {reviewItems.map(item => (
        <Box my={2} key={item.id}>
          <ReviewStepActions title={item.name} actions={actions[item.id]} />
        </Box>
      ))}
    </Box>
  )
}

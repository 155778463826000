import { api, ApiError } from '../../services/api';
import type { BrawlPlaylistSettings } from '../../features/brawl/pages/BrawlPage';

export const updateBrawlSettings = async(
  blockId: string,
  brawlMaxPlayers: number,
  brawlMinPlayers: number,
  brawlWeight: number,
  brawlPlaylist: string,
  brawlEliminationsPercentage: number,
  brawlIsNonEliminationCompatible: boolean,
  skillLevelThreshold: number
) => {
  return api.put({
    url: `/blocks/${blockId}/brawl-settings`,
    body: { brawlMaxPlayers, brawlMinPlayers, brawlWeight, brawlPlaylist, brawlEliminationsPercentage, brawlIsNonEliminationCompatible, skillLevelThreshold }
  });
}

export const getBrawlPlaylistInfo = async () => {
  return api.get<BrawlPlaylistSettings>({ url: '/blocks/brawl-playlists' });
};
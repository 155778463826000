import { DiffItem } from "../../../../pages/CatalogMigration/types";
import { LiveEvent } from "../../../live-events/live-events-types";
import _ from "lodash";
import { MigrationType } from "../migration-type";

export class LiveEventDiff extends DiffItem {
  public name: string;
  constructor(public liveEvent: LiveEvent) {
    super();
    this.diffId = liveEvent.id as string;
    this.name = liveEvent.name;
    this.diffJson = JSON.stringify(liveEvent, null, 2);
    this.dependencyItem = {
      type: MigrationType.liveEvents,
      id: this.diffId,
    };

    this.liveEvent.seasonalData?.forEach(season => {
      (season.seasonRewardPath?.tiers || []).forEach(tier => {
        (tier.rewards || []).forEach(reward => {
          if (reward.param && reward.param.itemId) {
            this.dependencies.push({ type: MigrationType.item, id: reward.param.itemId, message: `${reward.param.itemId} in the ${reward.param.catalogName} catalog is required for reward tier:${tier.name}` });
          }
        });
      });
    });
  }
}


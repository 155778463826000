import { Box, Button, FormControl, Grid, IconButton, InputLabel, MenuItem, Paper, Select, TextField, Tooltip } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import ReplayIcon from '@material-ui/icons/Replay';
import { Component } from 'react';
import type { ConnectedProps } from 'react-redux';
import { connect } from 'react-redux';
import { openConfirmationDialog } from '../redux/app/actions';
import type { PlayerDataField } from '../features/players/players';
import JsonEditor from './JsonEditor';
import type { RootState } from '../redux/reducers';

interface DataRow {
  id: number;
  key: string;
  keyError: string;
  value: string;
  ownerPermission: 'none' | 'read' | 'write';
  othersPermission: 'none' | 'read' | 'write';
  isDeleted: boolean;
  isNew: boolean;
  jsonEditorOpen: boolean;
}

interface RowProps {
  row: DataRow;
  onUpdate: (row: DataRow) => void;
  readOnly?: boolean;
  darkMode: boolean;
}

interface RowState {
  key: string;
  value: string;
}

class DataMapRowEditor extends Component<RowProps, RowState>{
  constructor(props: RowProps) {
    super(props);
    this.state = {
      key: props.row.key,
      value: props.row.value
    };
  }

  componentDidUpdate(prevProps: RowProps) {
    if (prevProps !== this.props) {
      const { key, value } = this.props.row;
      if (this.state.key !== key) {
        this.setState({ key });
      }
      if (this.state.value !== value) {
        this.setState({ value });
      }
    }
  }

  onKeyChange = (event: any) => {
    this.setState({ key: event.target.value });
  }

  onValueChange = (event: any) => {
    const value = event.target.value as string;
    this.setState({ value });
  }

  render() {
    const row = this.props.row;
    const readOnly = this.props.readOnly;
    return (
      <Paper elevation={2} style={{ backgroundColor: row.isDeleted ? (this.props.darkMode ? '#380000' : '#fff6f6') : undefined }}>
        <Box m={2}>
          <Grid container spacing={2} alignItems="center">
            {!readOnly && (
              <Grid item xs="auto">
                {row.isDeleted ? (
                  <Tooltip title="Restore">
                    <IconButton
                      size="small"
                      onClick={() => this.props.onUpdate({ ...this.props.row, isDeleted: false })}
                      tabIndex={-1}
                    >
                      <ReplayIcon />
                    </IconButton>
                  </Tooltip>
                ) : (
                  row.isNew ? <IconButton size="small" disabled><DeleteIcon /></IconButton> : (
                    <Tooltip title="Delete">
                      <IconButton
                        size="small"
                        onClick={() => this.props.onUpdate({ ...this.props.row, isDeleted: true })}
                        tabIndex={-1}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </Tooltip>

                  ))}
              </Grid>
            )}
            <Grid item xs>
              <Grid container spacing={2} alignItems="center">
                <Grid item xs={4} md={6} lg={8}>
                  <TextField
                    label="Key"
                    id={this.state.key}
                    value={this.state.key}
                    onChange={this.onKeyChange}
                    onBlur={event => this.props.onUpdate({ ...this.props.row, key: event.target.value })}
                    fullWidth
                    disabled={row.isDeleted}
                    error={!!row.keyError}
                    helperText={row.keyError}
                    InputProps={{ readOnly }}
                  />
                </Grid>
                <Grid item xs={4} md={3} lg={2}>
                  <FormControl fullWidth>
                    <InputLabel>Owner permission</InputLabel>
                    <Select
                      data-testid={`${this.state.key}-Owner-permission`}
                      value={row.ownerPermission}
                      disabled={row.isDeleted}
                      onChange={event => this.props.onUpdate({ ...this.props.row, ownerPermission: event.target.value as any })}
                      inputProps={{ readOnly }}
                    >
                      <MenuItem value="none">None</MenuItem>
                      <MenuItem value="read">Read</MenuItem>
                      <MenuItem value="write">Write</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={4} md={3} lg={2}>
                  <FormControl fullWidth>
                    <InputLabel>Others permission</InputLabel>
                    <Select
                      data-testid={`${this.state.key}-Others-permission`}
                      value={row.othersPermission}
                      disabled={row.isDeleted}
                      onChange={event => this.props.onUpdate({ ...this.props.row, othersPermission: event.target.value as any })}
                      inputProps={{ readOnly }}
                    >
                      <MenuItem value="none">None</MenuItem>
                      <MenuItem value="read">Read</MenuItem>
                      <MenuItem value="write">Write</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
              <Grid container spacing={2}>
                <Grid item xs>
                  {row.jsonEditorOpen ? (
                    <JsonEditor
                      title="Edit JSON"
                      json={this.state.value}
                      onJsonChange={value => this.setState({ value })}
                      onBlur={() => this.props.onUpdate({ ...this.props.row, value: this.state.value })}
                      focus
                      readOnly={readOnly}
                    />
                  ) : (
                    <TextField
                      label="Value"
                      value={this.state.value}
                      onChange={this.onValueChange}
                      onBlur={event => this.props.onUpdate({ ...this.props.row, value: event.target.value })}
                      fullWidth
                      disabled={row.isDeleted}
                      InputProps={{ readOnly }}
                    />
                  )}
                </Grid>
                <Grid item xs="auto">
                  <Button
                    disabled={row.isDeleted}
                    onClick={() => this.props.onUpdate({ ...this.props.row, jsonEditorOpen: !this.props.row.jsonEditorOpen })}
                    tabIndex={-1}
                  >
                    {row.jsonEditorOpen ? 'Close' : 'JSON Editor'}
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </Paper>
    );
  }
}

const mapStateToProps = (state: RootState) => ({ darkMode: state.app.darkMode });
const mapDispatch = { openConfirmationDialog };
const connector = connect(mapStateToProps, mapDispatch);

type Props = ConnectedProps<typeof connector> & {
  data: { [key: string]: PlayerDataField };
  onSave: (data: { [key: string]: PlayerDataField }) => void;
  readOnly?: boolean;
}

interface State {
  rows: DataRow[];
  rowsDeleted: number;
}

class DataMapEditor extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      rows: this.getRowsFromData(this.props.data),
      rowsDeleted: 0
    };
  }

  componentDidUpdate(prevProps: Props) {
    if (prevProps.data !== this.props.data) {
      this.setState({ rows: this.getRowsFromData(this.props.data), rowsDeleted: 0 });
    }
  }

  getRowsFromData(data: { [key: string]: PlayerDataField }) {
    const keys = Object.keys(data);
    keys.sort((a, b) => a.localeCompare(b));
    const rows = keys.map((key, index) => this.getRow(index, key, data[key]));
    rows.push(this.getRow(rows.length));
    return rows;
  }

  getRowsDeleted(rows: DataRow[]) {
    return rows.filter(row => row.isDeleted).length;
  }

  getRow(id: number, key = '', field?: PlayerDataField): DataRow {
    const isNew = !key && !field;
    field = field || {
      ownerPermission: 'none',
      othersPermission: 'none',
      value: ''
    };

    return {
      id,
      key,
      keyError: '',
      value: field.value,
      ownerPermission: field.ownerPermission,
      othersPermission: field.othersPermission,
      isDeleted: false,
      isNew,
      jsonEditorOpen: false
    };
  }

  updateRow = (id: number, row: DataRow) => {
    const rows = this.state.rows.slice();
    const oldRow = rows[id];
    rows[id] = row;

    if (row.isDeleted) {
      row.jsonEditorOpen = false;
    } else if (row.jsonEditorOpen && !oldRow.jsonEditorOpen) { // Only allow one JSON editor open at a time
      const otherRow = rows.find(v => v.jsonEditorOpen && v.id !== row.id);
      if (otherRow) {
        rows[otherRow.id] = { ...otherRow, jsonEditorOpen: false };
      }
    }

    if (row.isNew && (row.key || row.value)) {
      row.isNew = false;
      rows.push(this.getRow(rows.length));
    }

    if (row.value && !row.key.trim()) {
      row.keyError = 'This is required';
    } else if (oldRow.key !== row.key && row.key.trim()) {
      row.keyError = '';
    }

    this.setState({ rows, rowsDeleted: this.getRowsDeleted(rows) });
  }

  onSave = () => {
    const rows = this.state.rows.filter(row => !row.isDeleted && !row.isNew);

    if (this.checkValidity(rows)) {
      return;
    }

    const data: { [key: string]: PlayerDataField } = {};
    rows.forEach(row => data[row.key] = {
      ownerPermission: row.ownerPermission,
      othersPermission: row.othersPermission,
      value: row.value
    });

    if (this.state.rowsDeleted > 0) {
      const title = `Save data? ${this.state.rowsDeleted}${this.state.rowsDeleted > 1 ? ' keys' : ' key'} will be deleted`;
      this.props.openConfirmationDialog({ title, action: 'Save' }, () => {
        this.props.onSave(data);
      });
    } else {
      this.props.onSave(data);
    }
  }

  checkValidity(rows: DataRow[]) {
    const rowMap: { [key: string]: DataRow } = {};
    for (let i = 0; i < rows.length; i++) {
      const row = rows[i];
      if (!row.key.trim()) {
        return true;
      }

      if (rowMap[row.key]) {
        const prevRow = rowMap[row.key];
        this.showUniqueKeyErrors([prevRow.id, row.id]);
        return true;
      }
      rowMap[row.key] = row;
    }

    return false;
  }

  showUniqueKeyErrors = (ids: number[]) => {
    const rows = this.state.rows.slice();
    ids.forEach(id => rows[id] = { ...rows[id], keyError: 'Must be unique' });
    this.setState({ rows });
  }

  render() {
    const rows = this.state.rows.map(row => (
      <Grid id={`${row.key}-field`} key={row.id} item xs={12}>
        <DataMapRowEditor row={row} onUpdate={updatedRow => this.updateRow(row.id, updatedRow)} readOnly={this.props.readOnly} darkMode={this.props.darkMode} />
      </Grid>
    ));

    return (
      <Grid container spacing={2}>
        {rows}

        {!this.props.readOnly && (
          <Grid item xs={12}>
            <Button variant="contained" color="primary" onClick={this.onSave}>
              Save {this.state.rowsDeleted > 0 && (`(Delete ${this.state.rowsDeleted} ${this.state.rowsDeleted > 1 ? 'keys' : 'key'})`)}
            </Button>
          </Grid>
        )}
      </Grid>
    );
  }
}

export default connector(DataMapEditor);
import { Tab, Tabs, Box } from '@material-ui/core';
import { Component } from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import type { Store } from '../../services/stores';
import StoreTabEditor from './StoreTabEditor';

interface Props {
  catalogName: string;
  store: Store;
  readOnly?: boolean;
}

interface State {
  selectedTab: number;
}

export default class StoreTabs extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      selectedTab: 0
    };
  }

  render() {
    return (<>
      <Tabs
        value={this.state.selectedTab}
        onChange={(event, tab) => this.setState({ selectedTab: tab })}
        variant="scrollable"
        scrollButtons="auto"
      >
        {this.props.store.tabs.map(tab => (
          <Tab key={tab.position} label={tab.name} />
        ))}
      </Tabs>
      <Box pt={1}>
        <DndProvider backend={HTML5Backend}>
          <StoreTabEditor
            catalogName={this.props.catalogName}
            store={this.props.store}
            tab={this.state.selectedTab}
            readOnly={this.props.readOnly}
          />
        </DndProvider>
      </Box>
    </>);
  }
}
import type { IssueRefundRequest, TransactionAggregate, TransactionItem, IssueRefundResponse } from '../../services/transactions';
import {createAction, createAsyncAction} from "typesafe-actions";
import type {PaginatedRequest, PaginatedResponse} from "../../services/model/pagination";

export const getAllTransactionsAsync = createAsyncAction(
  'GET_ALL_TRANSACTIONS_REQUEST',
  'GET_ALL_TRANSACTIONS_SUCCESS',
  'GET_ALL_TRANSACTIONS_FAILURE'
)<PaginatedRequest, PaginatedResponse<TransactionAggregate>, string>();

export const getAllTransactionItemsAsync = createAsyncAction(
  'GET_ALL_TRANSACTION_ITEMS_REQUEST',
  'GET_ALL_TRANSACTION_ITEMS_SUCCESS',
  'GET_ALL_TRANSACTION_ITEMS_FAILURE'
)<PaginatedRequest, PaginatedResponse<TransactionItem>, string>();

export const refundTransactionAsync = createAsyncAction(
  'REFUND_TRANSACTION_REQUEST',
  'REFUND_TRANSACTION_SUCCESS',
  'REFUND_TRANSACTION_FAILURE'
)<IssueRefundRequest, IssueRefundResponse, string>();

export const invalidateTransactionsPage = createAction('INVALIDATE_TRANSACTIONS_PAGE')();


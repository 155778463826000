import type { ApiError } from '../api';
import { api } from '../api';

export class RewardPathTierDto implements RewardPathTier {
  seasonNumber = -1;
  pathId = '';
  tierId = '';
  tierOrder = '';
  tierName = '';
  seasonTokensToNextLevel = '';
  rewards: Reward[] = [];
  rewardsPartyPassPlus: Reward[] = [];
  isRepeatableTier = false;

  constructor(json?: any) {
    if (json) {
      this.pathId = json.pathId;
      this.tierId = json.tierId;
      this.tierOrder = json.tierOrder;
      this.tierName = json.name;
      this.seasonTokensToNextLevel = json.seasonTokensToNextLevel;
      this.rewards = json.rewards.map((reward: any) => new Reward(reward));
      this.rewardsPartyPassPlus = json.rewardsPartyPassPlus.map((reward: any) => new Reward(reward));
      this.isRepeatableTier = json.isRepeatableTier;
    }
  }
}

export enum RewardType {
  GrantCurrency = "GrantCurrency",
  GrantItem = "GrantItem",
  GrantSeasonTokens = "GrantSeasonTokens",
}

export class Reward {
  type = '';
  param: any = {};

  constructor(json?: any) {
    if (json) {
      this.type = json.type;
      this.param = json.param;
    }
  }
}

export function seasonRewardsToSmallString(rewards: Reward[]): string {
  return rewards?.filter((o, index) => index < 4) // only sum up 3 rewards, otherwise string gets too long
    .map(reward => getStringSummaryFromReward(reward))
    .join(', ') || "";
}

function getStringSummaryFromReward(reward: Reward): string {
  let paramsString = '';
  if (reward.type === 'GrantCurrency') {
    paramsString = `Currency: ${reward.param.amount}${reward.param.currency}`;
  } else if (reward.type === 'GrantSeasonTokens') {
    paramsString = `Season Tokens: ${reward.param.amount}`;
  } else if (reward.type === 'GrantItem') {
    paramsString = `Grant Item: ${reward.param.itemId}(${reward.param.catalogName})`;
  }
  return paramsString;
}

export class SeasonRewardPathTiersService {
  static async getRewardPathTiers(seasonNumber: number, pathId: string): Promise<Array<RewardPathTierDto>> {
    return api.get({ url: `/players/seasons/${seasonNumber}/reward-path/${pathId}/path-tiers/` })
      .then(seasons => seasons.map((season: any) => new RewardPathTierDto(season)))
      .catch((e: ApiError) => {
        e.message = `Failed to get season reward path tiers. ${e.message}`;
        throw e;
      });
  }

  static async getRewardPathTier(seasonNumber: number, pathId: string, tierId: string): Promise<RewardPathTierDto> {
    return api.get({ url: `/players/seasons/${seasonNumber}/reward-path/${pathId}/path-tiers/${tierId}` })
      .then(rewardPathTier => new RewardPathTierDto(rewardPathTier))
      .catch((e: ApiError) => {
        e.message = `Failed to get season reward path tier. ${e.message}`;
        throw e;
      });
  }

  static async createRewardPathTier(request: RewardPathTierCreateRequest): Promise<RewardPathTier> {
    const { seasonNumber, pathId } = request;
    return api.post({ url: `/players/seasons/${seasonNumber}/reward-path/${pathId}/path-tiers`, body: request })
      .catch((e: ApiError) => {
        e.message = `Failed to create season reward path tier. ${e.message}`;
        throw e;
      });
  }

  static async updateRewardPathTier(request: RewardPathTierUpdateRequest): Promise<RewardPathTier> {
    const { seasonNumber, pathId, tierId } = request;
    return api.put({ url: `/players/seasons/${seasonNumber}/reward-path/${pathId}/path-tiers/${tierId}`, body: request })
      .catch((e: ApiError) => {
        e.message = `Failed to update season reward path tier. ${e.message}`;
        throw e;
      });
  }

  static async deleteRewardPathTier(seasonNumber: number, pathId: string, tierId: string): Promise<null> {
    return api.delete({ url: `/players/seasons/${seasonNumber}/reward-path/${pathId}/path-tiers/${tierId}` })
      .catch((e: ApiError) => {
        e.message = `Failed to delete season reward path tier. ${e.message}`;
        throw e;
      });
  }
}

import { api } from "../../services/api";
import { ItemNote } from "./item-types";

export const getItemNotes = async (itemId: string) => {
  return api.get<ItemNote[]>({url: '/inventory/item-notes', query: {itemId}});
};

export const createItemNote = async (itemNote: ItemNote) => {
  return api.post<ItemNote>({url: '/inventory/item-notes', body: itemNote});
};

export const updateItemNote = async (itemNote: ItemNote) => {
  return api.put<ItemNote>({url: '/inventory/item-notes', body: itemNote});
};

export const deleteItemNote = async (itemNoteId: string) => {
  return api.delete({url: '/inventory/item-notes', query: {itemNoteId}})
};

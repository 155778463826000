
export class Currency {
  name = '';
  code = '';

  constructor(json?: any) {
    if (json) {
      this.name = json.currencyName;
      this.code = json.currencyCode;
    }
  }
}

export class CurrencyAmount extends Currency {
  amount = 0;

  constructor(json: any);
  constructor(currency: Currency, amount: number);
  constructor(currency: any, amount?: number) {
    super();
    if (currency instanceof Currency) {
      this.name = currency.name;
      this.code = currency.code;
      this.amount = amount || 0;
    } else {
      this.code = currency.currencyCode;
      this.amount = currency.amount;
    }
  }
}

export class CurrencyList {
  private currencyMap: { [key: string]: Currency; };
  private currencies: Currency[];

  constructor(json: any[]) {
    this.currencies = json.map(v => new Currency(v));
    this.currencyMap = {};
    this.currencies.forEach(currency => this.currencyMap[currency.code] = currency);
  }

  isEmpty() {
    return this.currencies.length < 1;
  }

  getCurrency(code: string): Currency {
    const currency = this.currencyMap[code];
    if (!currency) {
      throw new Error(`Unknown currency: ${code}`);
    }
    return currency;
  }

  getCurrencies(): Currency[] {
    return this.currencies;
  }

  getCurrencyCodes(): string[] {
    return this.currencies.map(currency => currency.code);
  }

  buildAmountsMap(currencies: CurrencyAmount[]): { [key: string]: CurrencyAmount; } {
    const currencyMap: { [key: string]: CurrencyAmount; } = {};
    Object.values(this.currencyMap).forEach(currency => currencyMap[currency.code] = new CurrencyAmount(currency, 0));
    currencies.forEach(currency => currencyMap[currency.code] && (currencyMap[currency.code].amount = currency.amount));
    return currencyMap;
  }
}

export enum BuiltInCurrencyEnum {
  USDollar = '$',
  Free = 'FREE',
}

import type { Theme} from "@material-ui/core";
import { Dialog, DialogTitle, DialogContent, Button, DialogActions, makeStyles, createStyles } from "@material-ui/core";
import { FuseForm } from ".";
import { useFormik } from "formik";
import { updateBlankoRushFuseById, uploadRushFuseImage } from "../gemRushApiService";
import { fuseFormSchema } from '../gemRushValidation';
import { useState } from "react";
import { FuseFormGemRushFuse } from "./FuseForm";

interface EditFuseDialogProps {
  formFuse: FuseFormGemRushFuse;
  show: boolean;
  onClose: () => void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    form: {
      display: 'flex',
      flexDirection: 'column',
      margin: 'auto',
      width: 'fit-content',
    },
  }),
);

export const EditFuseDialog = ({
  formFuse,
  show,
  onClose,
}: EditFuseDialogProps) => {
  const [imageFile, setImageFile] = useState<File | null>();
  const classes = useStyles();
  const formikFuses = useFormik({
    initialValues: formFuse,
    validationSchema: fuseFormSchema,
    onSubmit: async values => {
      if(imageFile){
        const imagename = await uploadRushFuseImage(imageFile);
        values.popupImageUrl = imagename;
      }
      await updateBlankoRushFuseById(values)
      onClose();
    }
  });

  const onFileChanged = (file: File | null) => {
    setImageFile(file);
  }

  return (
    <>
      <Dialog
        open={show}
        fullWidth
        onClose={onClose}
        aria-labelledby="rushes-and-fuses-dialog-title"
      >
        <form onSubmit={formikFuses.handleSubmit} className={classes.form}>
          <DialogTitle id="rushes-and-fuses-dialog-title">Fuse Edit</DialogTitle>
          <DialogContent>
            {formikFuses.values &&
              <FuseForm
                formikThings={{ ...formikFuses }}
                onFileChanged={onFileChanged}
            />
            }
          </DialogContent>
          <DialogActions>
            <Button
              type="submit"
              variant="contained"
              disabled={!(formikFuses.isValid && formikFuses.dirty)}
              color="primary"
            >
              Save
            </Button>
            <Button onClick={onClose} color="primary">
              Close
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </>
  );
}
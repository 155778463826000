import type {ApiError} from './api';
import {api} from './api';
import {BrawlPin, BrawlPinTier, BrawlPinUnlockCondition, BuiltinUnlockConditionType, UnlockConditionParameterTypeEnum,
  AwardTypeEnum,
  BadgeCheckTypeEnum,
  BadgeConditionRequirementEnum,
  GameTypeEnum,
  TierLevelEnum,
  BrawlModeEnum,
} from './model/brawl-pin';

class BrawlPinDto implements BrawlPin {
  id = '';
  name = '';
  awardType = AwardTypeEnum.Single;
  brawlMode = BrawlModeEnum.All;
  gameType = GameTypeEnum.Undefined;
  badgeConditionMode = BadgeConditionRequirementEnum.All;
  tiers = [];

  constructor(json?: any) {
    if (json) {
      this.id = json.id;
      this.name = json.name;
      this.awardType = json.awardType;
      this.brawlMode = json.brawlMode || BrawlModeEnum.All;
      this.gameType = json.gameType;
      this.badgeConditionMode = json.badgeConditionMode || BadgeConditionRequirementEnum.All;
      this.tiers = json.tiers.map((t: any) => new BrawlPinTierDto(t));
    }
  }

  toJson(): any {
    return {
      id: this.id,
      name: this.name,
      awardType: this.awardType,
      brawlMode: this.brawlMode,
      gameType: this.gameType,
      badgeConditionMode: this.badgeConditionMode,
      tiers: this.tiers.map(t => (new BrawlPinTierDto(t)).toJson()),
    };
  }
}

class BrawlPinTierDto implements BrawlPinTier {
  id = '';
  level = TierLevelEnum.Gold;
  conditionGroups = [];
  unlockConditions = [];
  rewardItemIds = [];

  constructor(json?: any) {
    if (json) {
      this.id = json.id;
      this.level = json.level;
      this.conditionGroups = json.conditionGroups;
      this.unlockConditions = json.unlockConditions.map((uc: any) => new BrawlPinUnlockConditionDto(uc));
      this.rewardItemIds = json.rewardItemIds;
    }
  }

  toJson(): any {
    return {
      id: this.id,
      level: this.level,
      conditionGroups: this.conditionGroups,
      unlockConditions: this.unlockConditions.map(uc => (new BrawlPinUnlockConditionDto(uc)).toJson()),
      rewardItemIds: this.rewardItemIds,
    };
  }
}

class BrawlPinUnlockConditionDto implements BrawlPinUnlockCondition {
  id = '';
  unlockType = '';
  badgeCheckType = BadgeCheckTypeEnum.AboveEqual;
  gameType = GameTypeEnum.Undefined;
  parameters = null;

  constructor(json?: any) {
    if (json) {
      this.id = json.id;
      this.unlockType = json.unlockType;
      this.badgeCheckType = json.badgeCheckType;
      // if gameType is null, we default to Undefined
      this.gameType = json.gameType || GameTypeEnum.Undefined;
      this.parameters = json.parameters;
    }
  }

  toJson(): any {
    return {
      id: this.id,
      unlockType: this.unlockType,
      badgeCheckType: this.badgeCheckType,
      gameType: this.gameType,
      parameters: this.parameters,
    };
  }
}

export class BrawlPinsService {
  static async getBrawlPins(): Promise<BrawlPin[]> {
    return api.get<BrawlPin[]>({ url: '/brawl-pins' })
      .then(pins => pins.map(p => new BrawlPinDto(p)))
      .catch((e: ApiError) => {
        e.message = `Failed to get brawl pins. ${e.message}`;
        throw e;
      });
  }

  static async getBrawlPin(id: string): Promise<BrawlPin> {
    return api.get({ url: `/brawl-pins/${id}` })
      .then(pin => new BrawlPinDto(pin))
      .catch((e: ApiError) => {
        e.message = `Failed to get brawl pin. ${e.message}`;
        throw e;
      });
  }

  static async createBrawlPin(pinJson: any): Promise<BrawlPin> {
    return api.post({ url: '/brawl-pins', body: pinJson })
      .then(pin => new BrawlPinDto(pin))
      .catch((e: ApiError) => {
        e.message = `Failed to create brawl pin. ${e.message}`;
        throw e;
      });
  }

  static async updateBrawlPin(pin: BrawlPin): Promise<BrawlPin> {
    const dto = new BrawlPinDto(pin);
    return api.put({ url: `/brawl-pins/`, body: dto.toJson() })
      .catch((e: ApiError) => {
        e.message = `Failed to update brawl pin. ${e.message}`;
        throw e;
      });
  }

  static async deleteBrawlPin(id: string): Promise<null> {
    return api.delete({ url: `/brawl-pins/${id}` })
      .catch((e: ApiError) => {
        e.message = `Failed to delete brawl pin. ${e.message}`;
        throw e;
      });
  }
}

/**
 * Someday the following should come from the backend.
 * This info currently comes from:
 * https://mythicalgames.atlassian.net/wiki/spaces/BP/pages/1875083328/Badges+Pins
 */
const builtinUnlockConditionTypes: BuiltinUnlockConditionType[] = [
  {
    name: "Blanko",
    parameters: [
      {
        name: 'DeathType',
        type: UnlockConditionParameterTypeEnum.BlankoDamageType,
      },
      {
        name: 'GradeUsed',
        type: UnlockConditionParameterTypeEnum.String,
      },
      {
        name: 'DamageTaken',
        type: UnlockConditionParameterTypeEnum.Integer,
      },
      {
        name: 'NumberBlankosUsed',
        type: UnlockConditionParameterTypeEnum.Integer,
      },
      {
        name: 'NumberClassesUsed',
        type: UnlockConditionParameterTypeEnum.Integer,
      },
    ],
  },
  {
    name: "Brawl",
    parameters: [
      {
        name: 'NumberStagesComplete',
        type: UnlockConditionParameterTypeEnum.Integer,
      },
      {
        name: 'GameType',
        type: UnlockConditionParameterTypeEnum.GameType,
      },
      {
        name: 'TeamWon',
        type: UnlockConditionParameterTypeEnum.Boolean,
      },
      {
        name: 'TopVibes',
        type: UnlockConditionParameterTypeEnum.Boolean,
      },
      {
        name: 'TopSingleDamage',
        type: UnlockConditionParameterTypeEnum.Boolean,
      },
      {
        name: 'TopDamage',
        type: UnlockConditionParameterTypeEnum.Boolean,
      },
      {
        name: 'TopKO',
        type: UnlockConditionParameterTypeEnum.Boolean,
      },
      {
        name: 'TotalDamage',
        type: UnlockConditionParameterTypeEnum.Integer,
      },
      {
        name: 'Position',
        type: UnlockConditionParameterTypeEnum.Integer,
      },
    ],
  },
  {
    name: "CompletedRound",
    parameters: [
      {
        name: 'CompletedIndex',
        type: UnlockConditionParameterTypeEnum.Integer,
      },
    ],
  },
  {
    name: "CrossedFinishLine",
  },
  {
    name: "DamageDealtThisRound",
  },
  {
    name: "DamageDealtThisBrawl",
    parameters: [
      {
        name: 'Damage',
        type: UnlockConditionParameterTypeEnum.Integer,
      },
    ],
  },
  {
    name: "DifferentGameTypePositions",
  },
  {
    name: "EliminatedOrFinal",
  },
  {
    name: "EmoteBeforeRaceEnd",
    parameters: [
      {
        name: 'EmoteCount',
        type: UnlockConditionParameterTypeEnum.Integer,
      },
    ],
  },
  {
    name: "EmoteTimeAfterKO",
    parameters: [
      {
        name: 'Time',
        type: UnlockConditionParameterTypeEnum.Float,
      },
    ],
  },
  {
    name: "FinishedFTUE",
  },
  {
    name: "FirstCheckpointPosition",
  },
  {
    name: "FirstInAllRounds",
  },
  {
    name: "FirstInConsecutiveRounds",
  },
  {
    name: "HasQualified",
  },
  {
    name: "IsOnSmallestTeam",
  },
  {
    name: "IsPlayerLastQualifier",
  },
  {
    name: "IsTeam",
  },
  {
    name: "IsTeamOrQualifier",
  },
  {
    name: "KO",
    parameters: [
      {
        name: 'KOTarget',
        type: UnlockConditionParameterTypeEnum.Integer,
      },
    ],
  },
  {
    name: "KORevengeTime",
    parameters: [
      {
        name: 'TimeFrame',
        type: UnlockConditionParameterTypeEnum.Float,
      },
    ],
  },
  {
    name: "KOTime",
    parameters: [
      {
        name: 'KOCount',
        type: UnlockConditionParameterTypeEnum.Integer,
      },
      {
        name: 'MaxSeconds',
        type: UnlockConditionParameterTypeEnum.Float,
      },
    ],
  },
  {
    name: "MadeFinal",
  },
  {
    name: "Melee",
    parameters: [
      {
        name: 'HitCount',
        type: UnlockConditionParameterTypeEnum.Integer,
      },
      {
        name: 'Damage',
        type: UnlockConditionParameterTypeEnum.Integer,
      },
      {
        name: 'SingleDamage',
        type: UnlockConditionParameterTypeEnum.Integer,
      },
      {
        name: 'TopHitsWithSingleSwing',
        type: UnlockConditionParameterTypeEnum.Integer,
      },
    ],
  },
  {
    name: "MostVibesConfiscated",
    parameters: [
      {
        name: 'MostVibesConfiscated',
        type: UnlockConditionParameterTypeEnum.Integer,
      },
    ],
  },
  {
    name: "Movement",
  },
  {
    name: "PlayedWithDeveloper",
  },
  {
    name: "PlayedWithInfluencer",
  },
  {
    name: "PlayerKOPosition",
    parameters: [
      {
        name: 'KOPosition',
        type: UnlockConditionParameterTypeEnum.Integer,
      },
    ],
  },
  {
    name: "Position",
    parameters: [
      {
        name: 'Position',
        type: UnlockConditionParameterTypeEnum.Integer,
      },
    ],
  },
  {
    name: "PositionInRounds",
    parameters: [
      {
        name: 'MinPosition',
        type: UnlockConditionParameterTypeEnum.Integer,
      },
      {
        name: 'NumberOfRounds',
        type: UnlockConditionParameterTypeEnum.Integer,
      },
    ],
  },
  {
    name: "Race",
    parameters: [
      {
        name: 'CheckpointsComplete',
        type: UnlockConditionParameterTypeEnum.Integer,
      },
      {
        name: 'LapsComplete',
        type: UnlockConditionParameterTypeEnum.Integer,
      },
      {
        name: 'LeadTime',
        type: UnlockConditionParameterTypeEnum.Float,
      },
    ],
  },
  {
    name: "ResultPositionWithinPercentage",
  },
  {
    name: "ScoreDifferenceToNextPlayer",
    parameters: [
      {
        name: 'ScoreDifference',
        type: UnlockConditionParameterTypeEnum.Integer,
      },
    ],
  },
  {
    name: "Shooting",
    parameters: [
      {
        name: 'HeadshotCount',
        type: UnlockConditionParameterTypeEnum.Integer,
      },
      {
        name: 'HeadshotsTaken',
        type: UnlockConditionParameterTypeEnum.Integer,
      },
      {
        name: 'TotalHeadshotDamage',
        type: UnlockConditionParameterTypeEnum.Integer,
      },
      {
        name: 'HeadshotDamageRecieved',
        type: UnlockConditionParameterTypeEnum.Integer,
      },
      {
        name: 'Accuracy',
        type: UnlockConditionParameterTypeEnum.Float,
      },
    ],
  },
  {
    name: "Vibes",
    parameters: [
      {
        name: 'HasBeenVibeChamp',
        type: UnlockConditionParameterTypeEnum.Boolean,
      },
      {
        name: 'LargestStolen',
        type: UnlockConditionParameterTypeEnum.Integer,
      },
      {
        name: 'TotalCollected',
        type: UnlockConditionParameterTypeEnum.Integer,
      },
      {
        name: 'TotalConfiscated',
        type: UnlockConditionParameterTypeEnum.Integer,
      },
      {
        name: 'TotalStolen',
        type: UnlockConditionParameterTypeEnum.Integer,
      },
      {
        name: 'TopAmountConfiscated',
        type: UnlockConditionParameterTypeEnum.Integer,
      },
      {
        name: 'VibeChampCount',
        type: UnlockConditionParameterTypeEnum.Integer,
      },
      {
        name: 'VibeChampDuration',
        type: UnlockConditionParameterTypeEnum.Float,
      },
    ],
  },
  {
    name: "WeaponsUsed",
    parameters: [
      {
        name: 'WeaponCount',
        type: UnlockConditionParameterTypeEnum.Integer,
      },
    ],
  },
];

export const getBuiltinUnlockConditionTypes = (): BuiltinUnlockConditionType[] => {
  return builtinUnlockConditionTypes;
}

import { TextField } from '@material-ui/core';
import type { Column, EditComponentProps } from 'material-table';
import { useEffect, useState } from 'react';
import MaterialTable from '../../components/MaterialTable';
import { UserService } from '../../services/user';
import { arrayRemove, arrayReplaceOrPush } from "../../redux/utils-ts";
import { usePushNotification } from "../../contexts/AppNotificationContext";

type Props<NoteType extends {[key: string]: any}> = {
  entityId: string;
  permissionEntityType: string;
  title: string;

  getNotes: (entityId: string) => Promise<NoteType[]>;
  createNote: (note: NoteType) => Promise<NoteType>;
  updateNote: (note: NoteType) => Promise<NoteType>;
  deleteNote: (note: NoteType) => Promise<void | null>;

  entityIdField: keyof NoteType;
  noteField: keyof NoteType;
  authorField: keyof NoteType;
  dateField: keyof NoteType;

  renderAuthor?: (note: NoteType) => any;
}

export const EntityNotes = <NoteType extends {[key: string]: any}, >(props: Props<NoteType>) => {
  const pushNotification = usePushNotification();
  const {
    noteField,
    authorField,
    dateField,
    entityIdField,
    renderAuthor,
    entityId,
    permissionEntityType,
    title,
    getNotes,
    createNote,
    updateNote,
    deleteNote,
  } = props;
  const columns: Column<NoteType>[] = [
    {
      title: 'Note',
      field: noteField,
      sorting: false,
      cellStyle: {wordBreak: 'break-all'},
      editComponent: editProps => <MessageInputComponent {...props} {...editProps} />
    },
    {
      title: 'Added by',
      field: authorField,
      sorting: false,
      editable: 'never',
      render: renderAuthor,
    },
    {
      title: 'Date',
      field: dateField,
      type: 'datetime',
      searchable: false,
      editable: 'never',
      defaultSort: 'desc'
    },
  ];

  const [notes, setNotes] = useState<NoteType[] | undefined>();
  useEffect(() => {
    getNotes(entityId)
      .then(notes => setNotes(notes))
      .catch(err => pushNotification({type: 'error', message: `Failed to get notes: ${err.message}`}));
  }, [getNotes, entityId, pushNotification]);

  if (notes) {
    return (<>
      <MaterialTable
        title={title}
        columns={columns}
        data={notes}
        options={{
          addRowPosition: 'first',
          emptyRowsWhenPaging: false,
          toolbarButtonAlignment: 'left'
        }}
        editable={{
          onRowAdd: UserService.canCreate(permissionEntityType) ? note => {
            if ((note[noteField] as string).trim()) {
              note[entityIdField] = entityId as any;
              return createNote(note)
                .then(note => {
                  pushNotification({type: 'success', message: `Note added`});
                  setNotes(notes => notes?.concat([note]));
                })
                .catch(err => pushNotification({
                  type: 'error',
                  message: `Failed to create note: ${err.message}`
                }));
            }
            return Promise.resolve();
          } : undefined,
          onRowUpdate: UserService.canUpdate(permissionEntityType) ? note => {
            if ((note[noteField] as string).trim()) {
              return updateNote(note)
                .then(note => {
                  pushNotification({type: 'success', message: `Note updated`});
                  setNotes(notes => notes && arrayReplaceOrPush(notes, note, n => n.id === note.id));
                })
                .catch(err => pushNotification({
                  type: 'error',
                  message: `Failed to update note: ${err.message}`
                }));
            }
            return Promise.resolve();
          } : undefined,
          onRowDelete: UserService.canDelete(permissionEntityType) ? note => {
            return deleteNote(note)
              .then(() => {
                pushNotification({type: 'success', message: `Note deleted`});
                setNotes(notes => notes && arrayRemove(notes, note));
              })
              .catch(err => pushNotification({
                type: 'error',
                message: `Failed to delete note: ${err.message}`
              }));
          } : undefined
        }}
        localization={{
          body: {
            editRow: {
              deleteText: 'Delete note?'
            }
          }
        }}
      />
    </>);
  }
  return null;
};

const MAX_NOTE_LENGTH = 500;

const MessageInputComponent = <NoteType extends {[key: string]: any}, >(props: EditComponentProps<NoteType> & Props<NoteType>) => {
  const [note, setNote] = useState((props.rowData[props.noteField as string] as string) || '');
  const [error, setError] = useState('');

  const getCharsLeft = (message: string) => {
    return MAX_NOTE_LENGTH - message.length;
  };

  const onChange = (event: any) => {
    const note = (event.target.value as string) || '';
    let error = '';
    if (!note.trim()) {
      error = 'Cannot be blank';
    }
    setNote(note);
    setError(error);
    props.onChange(note);
  };

  return (
    <TextField
      label="Note"
      fullWidth
      autoFocus
      value={note}
      onChange={onChange}
      error={!!error}
      helperText={error || (`${getCharsLeft(note)} characters left`)}
      inputProps={{
        maxLength: 500
      }}
    />
  );
};

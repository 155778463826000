import { useCallback, useEffect, useMemo, useState } from "react";
import {
  Box,
  Button,
  Card,
  CardContent,
  CircularProgress,
  createStyles,
  makeStyles,
  TextField as MTextField,
  Theme} from "@material-ui/core";
import type { RouteComponentProps } from "react-router";
import _ from "lodash";
import { usePushNotification } from "../../contexts/AppNotificationContext";
import { BlankoProgressionSkillDropTable, noSkillDropTableItem } from "../../services/model/blanko";
import { BlankosService } from "../../services/blankos";
import { history } from '../../redux/store'
import JsonEditor from "../../components/JsonEditor";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formControl: {
      marginBottom: theme.spacing(2),
      minWidth: 400,
    },
  }),
);

type Props = RouteComponentProps<{ tableId: string; }>;

export const SkillDropTablePage = ({match}: Props) => {
  const classes = useStyles();
  const pushNotification = usePushNotification();

  const [skillDropTables, setSkillDropTables] = useState<BlankoProgressionSkillDropTable[]>();
  const [skillDropTable, setSkillDropTable] = useState<BlankoProgressionSkillDropTable>();
  const [jsonIsValid, setJsonIsValid] = useState(true);
  const [hasBeenAdded, setHasBeenAdded] = useState(false);

  const isNewDropTable: boolean = useMemo(() => {
    return match.params.tableId === 'new' && !hasBeenAdded;
  }, [hasBeenAdded, match]);

  const saveButtonTitle: string = useMemo(() => {
    return isNewDropTable ? 'Add' : 'Save';
  }, [isNewDropTable]);

  const saveDropTable = useCallback((dropTable: BlankoProgressionSkillDropTable) => {
    const nameIsAvailable = () => {
      if (!skillDropTables || !skillDropTable)
        return false;

      const existingDropTable = skillDropTables.find(dt => dt.name === skillDropTable.name);
      return !existingDropTable || existingDropTable.id === skillDropTable.id;
    };

    if (!nameIsAvailable()) {
      pushNotification({ type: 'error', message: `A skill drop table already exists with name '${dropTable.name}'` });
      return;
    }

    if (isNewDropTable) {
      BlankosService.createProgressionSkillDropTable(dropTable)
        .then(createdTable => {
          setSkillDropTable(createdTable);
          setHasBeenAdded(true);
          pushNotification({ type: 'success', message: 'Skill drop table created' });
        })
        .catch(err => pushNotification({ type: 'error', message: `Failed to create skill drop table: ${err.message}` }));
    } else {
      BlankosService.updateProgressionSkillDropTable(dropTable)
        .then(updatedTable => {
          setSkillDropTable(updatedTable);
          pushNotification({ type: 'success', message: 'Skill drop table updated' });
        })
        .catch(err => pushNotification({ type: 'error', message: `Failed to update skill drop table: ${err.message}` }));
    }
  }, [isNewDropTable, skillDropTable, skillDropTables, pushNotification]);

  useEffect(() => {
    if (skillDropTables)
      return;

    BlankosService.getProgressionSkillDropTables().then(setSkillDropTables)
      .catch(err => pushNotification({ type: 'error', message: `Failed to load skill drop tables: ${err.message}` }));
  }, [skillDropTables, pushNotification]);

  useEffect(() => {
    if (!skillDropTable) {
      if (isNewDropTable) {
        setSkillDropTable({
          id: '',
          name: '',
          value: '',
          deletedAt: null,
        });
      } else {
        BlankosService.getProgressionSkillDropTable(match.params.tableId)
          .then(dropTable => {
            setSkillDropTable(dropTable);
          })
          .catch(err => pushNotification({ type: 'error', message: `Failed to get skill drop table: ${err.message}` }));
      }
    }
  }, [skillDropTable, isNewDropTable, match, pushNotification]);

  const handleSave = useCallback(() => {
    if (!skillDropTable)
      return;

    saveDropTable(skillDropTable);
  }, [skillDropTable, saveDropTable]);

  const handleUpdateField = (event: any, field: keyof BlankoProgressionSkillDropTable) => {
    if (!skillDropTable)
      return;

    setSkillDropTable({
      ...skillDropTable,
      [field]: event?.target?.value,
    });
  };

  if (!skillDropTable) {
    return <Box textAlign="center">
      <CircularProgress/>
    </Box>;
  }

  return (<>
    <Card>
      <CardContent>
        <Box className={classes.formControl}>
          <MTextField
            value={skillDropTable.name}
            label="Skill Drop Table Name"
            type="text"
            name="name"
            onChange={e => handleUpdateField(e, 'name')}
            fullWidth
          />
        </Box>
        <JsonEditor
          title="Value (JSON)"
          json={skillDropTable.value}
          onJsonChange={value => setSkillDropTable({
            ...skillDropTable,
            value: value,
          })}
          onValidityChange={ valid => setJsonIsValid(valid) }
          focus
        />
      </CardContent>
    </Card>
    <Box mt={2}>
      <Button
        variant="contained"
        color="primary"
        onClick={() => handleSave()}
        disabled={
          !skillDropTable.name
          || skillDropTable.name === noSkillDropTableItem
          || !skillDropTable.value
          || !jsonIsValid
          || skillDropTable.deletedAt !== null}
      >
        {saveButtonTitle}
      </Button>
      <Button onClick={() => history.push('/progression-skill-drops')} color="primary">Cancel</Button>
    </Box>
  </>
  );
};

import { JunctionEventInfo, LiveEvent, LiveEventTypeEnum, PlayModeNameType } from "../live-events-types";
import { Box, Button, Card, CardContent, CardHeader, Grid, Link, MenuItem } from "@material-ui/core";
import { Field, Formik, FormikHelpers } from "formik";
import { useCallback, useEffect, useMemo, useState } from "react";
import { CheckboxWithLabel, TextField } from "formik-material-ui";
import { FormikAdminKeyboardDateTimePicker } from "../../../components/AdminKeyboardDateTimePicker";
import { createLiveEvent, getJunctionEventInfos, updateLiveEvent, uploadLiveEventImage } from "../live-events-api";
import { usePushNotification } from "../../../contexts/AppNotificationContext";
import { RouteComponentProps } from "react-router";
import * as Yup from 'yup';
import { JunctionEventInfosTable } from "./JunctionEventInfosTable";
import { Season, SeasonsService } from "../../../services/player-challenges/seasons";
import { SeasonsTable } from "./SeasonsTable";
import { FormikSelectWithLabel } from "../../../components/FormikSelectWithLabel";
import JsonEditor from "../../../components/JsonEditor";
import ImageUpload from "../../../components/ImageUpload";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { LiveEventImagesEditor } from "./LiveEventImagesEditor";
import { RouterLink } from "../../../types";

export interface Props extends RouteComponentProps {
  eventToEdit?: LiveEvent;
  refreshLiveEvents: () => Promise<void>;
}

const defaulCustomDataValue = '{}';

const validationSchema = Yup.object().shape({
  name: Yup.string().required(),
  startTime: Yup.date(),
  endTime: Yup.date().min(Yup.ref('startTime'), 'End Time must be after Start Time'),
  downtimeBufferMinutes: Yup.number().min(0).required(),
  storeName: Yup.string(),
  storeId: Yup.string(),
  liveEventType: Yup.string().oneOf(Object.values(LiveEventTypeEnum)),
});

export const LiveEventEditor = (props: Props) => {
  const {eventToEdit, refreshLiveEvents, history} = props;
  const pushNotification = usePushNotification();

  const [junctionEventInfos, setJunctionEventInfos] = useState<JunctionEventInfo[]>([]);
  const [seasons, setSeasons] = useState<Season[]>([]);
  const [customData, setCustomData] = useState(defaulCustomDataValue);
  const [customDataIsValid, setCustomDataIsValid] = useState(true);
  const [modeSelectTileImageUrl, setModeSelectTileImageUrl] = useState('');
  const [modeSelectTileImageFile, setModeSelectTileImageFile] = useState<File>();
  const [modeSelectBackgroundImageUrl, setModeSelectBackgroundImageUrl] = useState('');
  const [modeSelectBackgroundImageFile, setModeSelectBackgroundImageFile] = useState<File>();
  const [imageUrls, setImageUrls] = useState<string[]>([]);

  const isEditMode = useMemo(() => !!eventToEdit, [eventToEdit]);
  const initialValues = useMemo<LiveEvent>(() => {
    if (eventToEdit) {
      setCustomData(eventToEdit.customData);
      setModeSelectTileImageUrl(
        eventToEdit.modeSelectTileImage
        && eventToEdit.modeSelectTileImage.startsWith('http')
        && eventToEdit.modeSelectTileImage
        || '');
      setModeSelectBackgroundImageUrl(
        eventToEdit.modeSelectBackgroundImage
        && eventToEdit.modeSelectBackgroundImage.startsWith('http')
        && eventToEdit.modeSelectBackgroundImage
        || '');

      return {
        ...eventToEdit,
        storeName: eventToEdit.storeName || '',
        storeId: eventToEdit.storeId || '',
        modeSelectBackgroundImage: eventToEdit.modeSelectBackgroundImage || '',
        modeSelectDescription: eventToEdit.modeSelectDescription || '',
        modeSelectEventTagTitle: eventToEdit.modeSelectEventTagTitle || '',
        modeSelectPlayActionTitle: eventToEdit.modeSelectPlayActionTitle || '',
        modeSelectTileImage: eventToEdit.modeSelectTileImage || '',
        modeSelectTitle: eventToEdit.modeSelectTitle || '',
        playModeName: eventToEdit.playModeName || 'NONE',
      } as LiveEvent;
    }

    setCustomData(defaulCustomDataValue);
    setModeSelectTileImageUrl('');
    setModeSelectBackgroundImageUrl('');
    return {
      name: '',
      liveEventType: LiveEventTypeEnum.Announcement,
      debugStartTime: new Date().toISOString(),
      startTime: new Date().toISOString(),
      endTime: new Date().toISOString(),
      downtimeBufferMinutes: 0,
      active: false,
      prior: false,
      autoActivating: false,
      autoActivationDaysBuffer: 0,
      repeatable: false,
      repeatCount: 0,
      repeatStartGapDaysBuffer: 0,
      repeatCloneDaysBuffer: 0,
      repeatCloneStartTime: null,
      repeatCloneEndTime: null,
      repeatCloneParentId: null,
      repeatCloneChildId: null,
      storeName: '',
      storeId: '',
      playModeName: 'NONE',
      modeSelectTileImage: '',
      modeSelectBackgroundImage: '',
      modeSelectTitle: '',
      modeSelectDescription: '',
      modeSelectEventTagTitle: '',
      modeSelectPlayActionTitle: '',
      customData: defaulCustomDataValue,
      imageUrls: [] as string[],
    } as LiveEvent;
  }, [eventToEdit]);

  const disableRepeatableOptions = useMemo(() => {
    return eventToEdit?.repeatCloneEndTime !== null;
  }, [eventToEdit]);

  const refreshJunctionEventInfos = useCallback(async () => {
    if (!isEditMode || !eventToEdit)
      return;

    getJunctionEventInfos(eventToEdit)
      .then((infos: JunctionEventInfo[]) => setJunctionEventInfos(infos))
      .catch((err: { message: any; }) => pushNotification({type: 'error', message: `Failed to load Junction Events: ${err.message}`}));
  }, [eventToEdit, isEditMode, pushNotification]);

  const refreshSeasons = useCallback(async () => {
    if (!isEditMode || !eventToEdit || !eventToEdit.id)
      return;

    SeasonsService.getSeasons(eventToEdit.id)
      .then((loadedSeasons: Season[]) => setSeasons(loadedSeasons))
      .catch((err: { message: any; }) => pushNotification({type: 'error', message: `Failed to load Seasons: ${err.message}`}));
  }, [eventToEdit, isEditMode, pushNotification]);

  useEffect(() => {
    if (!isEditMode || !eventToEdit)
      return;

    refreshJunctionEventInfos();
    refreshSeasons();
  }, [eventToEdit, isEditMode, refreshJunctionEventInfos, refreshSeasons]);

  const handleTileImageChanged = useCallback((file: File,
    handleChange: {
      /** Classic React change handler, keyed by input name */
      (e: React.ChangeEvent<any>): void;
      /** Preact-like linkState. Will return a handleChange function.  */
      <T = string | React.ChangeEvent<any>>(field: T): T extends React.ChangeEvent<any> ? void : (e: string | React.ChangeEvent<any>) => void;
    }) => {
    //Trigger a change in formik since we aren't using a formik control.
    const c = handleChange('modeSelectTileImage');
    c(file.name);
    setModeSelectTileImageFile(file);
  }, []);

  const handleBackgroundImageChanged = useCallback((file: File,
    handleChange: {
      /** Classic React change handler, keyed by input name */
      (e: React.ChangeEvent<any>): void;
      /** Preact-like linkState. Will return a handleChange function.  */
      <T = string | React.ChangeEvent<any>>(field: T): T extends React.ChangeEvent<any> ? void : (e: string | React.ChangeEvent<any>) => void;
    }) => {
    //Trigger a change in formik since we aren't using a formik control.
    const c = handleChange('modeSelectBackgroundImage');
    c(file.name);
    setModeSelectBackgroundImageFile(file);
  }, []);

  const onImageUrlsChange = useCallback((urls: string[],
    handleChange: {
      /** Classic React change handler, keyed by input name */
      (e: React.ChangeEvent<any>): void;
      /** Preact-like linkState. Will return a handleChange function.  */
      <T = string | React.ChangeEvent<any>>(field: T): T extends React.ChangeEvent<any> ? void : (e: string | React.ChangeEvent<any>) => void;
    }) => {
    //Trigger a change in formik since we aren't using a formik control.
    const c = handleChange('imageUrls');
    setImageUrls(urls);
  }, []);

  const onSubmit = useCallback(async (liveEvent: LiveEvent, formikHelpers: FormikHelpers<LiveEvent>) => {
    if (liveEvent.playModeName === 'NONE') {
      liveEvent.playModeName = '';
    }

    // set the custom data value
    if (customData.length === 0) {
      liveEvent.customData = defaulCustomDataValue;
    } else {
      liveEvent.customData = customData;
    }

    if (modeSelectTileImageFile){
      const imageName = await uploadLiveEventImage(liveEvent, modeSelectTileImageFile);
      liveEvent.modeSelectTileImage = imageName;
    }

    if (modeSelectBackgroundImageFile){
      const imageName = await uploadLiveEventImage(liveEvent, modeSelectBackgroundImageFile);
      liveEvent.modeSelectBackgroundImage = imageName;
    }

    liveEvent.imageUrls = imageUrls;

    if (isEditMode) {
      return updateLiveEvent(liveEvent)
        .then(le => {
          refreshLiveEvents();
          pushNotification({ type: 'success', message: 'Updated live event' });
        })
        .catch(err => pushNotification({type: 'error', message: `Failed to update live event: ${err.message}`}));
    }
    return createLiveEvent(liveEvent)
      .then(le => {
        formikHelpers.setFieldValue('id', le.id);
        pushNotification({type: 'success', message: 'Created live event'});
        refreshLiveEvents()
          .then(() => history.replace(`/live-events/edit/${le.id}`));
      })
      .catch(err => pushNotification({type: 'error', message: `Failed to create live event: ${err.message}`}));
  }, [customData, history, imageUrls, isEditMode, modeSelectBackgroundImageFile, modeSelectTileImageFile, pushNotification, refreshLiveEvents]);

  return <>
    <Card>
      <Formik<LiveEvent>
        initialValues={initialValues}
        onSubmit={onSubmit}
        validationSchema={validationSchema}
      >
        {formikData => (<form onSubmit={formikData.handleSubmit}>
          <CardHeader title={`${isEditMode ? 'Edit' : 'Create'} Live Event`}/>
          <CardContent>
            <Box pb={1} pt={1}>
              <Field
                component={TextField}
                type="text"
                name="name"
                fullWidth
                label="Name"
              />
            </Box>
            <Box pb={1} pt={1}>
              <Field
                fullWidth
                component={FormikSelectWithLabel}
                id="liveEventType"
                name="liveEventType"
                label="Live Event Type"
              >
                {Object.values(LiveEventTypeEnum).sort().map(eventType => (
                  <MenuItem key={eventType} value={eventType}>{eventType}</MenuItem>
                ))}
              </Field>
            </Box>
            <Box pb={1} pt={1}>
              <Field
                component={FormikAdminKeyboardDateTimePicker}
                name="debugStartTime"
                fullWidth
                label="Debug Start Time (used in QA, Development, and Local)"
              />
            </Box>
            <Box pb={1} pt={1}>
              <Field
                component={FormikAdminKeyboardDateTimePicker}
                name="startTime"
                fullWidth
                label="Start Time (used in Production)"
              />
            </Box>
            <Box pb={1} pt={1}>
              <Field
                component={FormikAdminKeyboardDateTimePicker}
                name="endTime"
                fullWidth
                label="End Time"
              />
            </Box>
            <Box pb={1} pt={1}>
              <Field
                component={CheckboxWithLabel}
                type="checkbox"
                name="autoActivating"
                Label={{ label: 'Automatically Activate/Deactivate Event' }}
              />
            </Box>
            <Box pb={1} pt={1}>
              <Field
                component={TextField}
                type="number"
                name="autoActivationDaysBuffer"
                fullWidth
                label="Days Prior to Event Start for Auto Activation"
                inputProps={{
                    step: 1,
                    min: 0,
                }}
              />
            </Box>
            <Box pb={1} pt={1}>
              <Field
                component={CheckboxWithLabel}
                type="checkbox"
                name="repeatable"
                Label={{ label: 'Repeatable Event' }}
                disabled={disableRepeatableOptions}
              />
            </Box>
            {eventToEdit && eventToEdit.repeatCloneStartTime &&
              <Box pb={1} pt={1}>
                Duplication started at: {eventToEdit?.repeatCloneStartTime}<br/>
              </Box>
            }
            {eventToEdit && eventToEdit.repeatCloneEndTime &&
              <Box pb={1} pt={1}>
                Duplication finished at: {eventToEdit?.repeatCloneEndTime}<br/>
              </Box>
            }
            {eventToEdit && eventToEdit.repeatCloneParentId &&
              <Box pb={1} pt={1}>
                <Link component={RouterLink} to={`/live-events/edit/${eventToEdit.repeatCloneParentId}`}>
                  Parent Live Event
                </Link>
              </Box>
            }
            {eventToEdit && eventToEdit.repeatCloneChildId &&
              <Box pb={1} pt={1}>
                <Link component={RouterLink} to={`/live-events/edit/${eventToEdit.repeatCloneChildId}`}>
                  Child Live Event
                </Link>
              </Box>
            }
            <Box pb={1} pt={1}>
              <Field
                component={TextField}
                type="number"
                name="repeatCount"
                fullWidth
                label="Number of Times to Repeat Event"
                disabled={disableRepeatableOptions}
                inputProps={{
                    step: 1,
                    min: 0,
                }}
              />
            </Box>
            <Box pb={1} pt={1}>
              <Field
                component={TextField}
                type="number"
                name="repeatStartGapDaysBuffer"
                fullWidth
                label="Number of Days to Delay Between Repeating Events"
                disabled={disableRepeatableOptions}
                inputProps={{
                    step: 1,
                    min: 0,
                }}
              />
            </Box>
            <Box pb={1} pt={1}>
              <Field
                component={TextField}
                type="number"
                name="repeatCloneDaysBuffer"
                fullWidth
                label="Create Next Repeating Event When Within This Many Days of the End of Current Event"
                disabled={disableRepeatableOptions}
                inputProps={{
                    step: 1,
                    min: 0,
                }}
              />
            </Box>
            <Box pb={1} pt={1}>
              <Field
                component={TextField}
                type="number"
                name="downtimeBufferMinutes"
                fullWidth
                label="Downtime Buffer in Minutes"
                inputProps={{
                    step: 1,
                    min: 0,
                }}
              />
            </Box>
            <Box pb={1} pt={1}>
              <Field
                component={TextField}
                type="text"
                name="storeName"
                fullWidth
                label="Store Name"
              />
            </Box>
            <Box pb={1} pt={1}>
              <Field
                component={TextField}
                type="text"
                name="storeId"
                fullWidth
                label="Store ID"
              />
            </Box>
            <Box pb={1} pt={1}>
              <Field
                fullWidth
                component={FormikSelectWithLabel}
                id="playModeName"
                name="playModeName"
                label={"Play Mode"}
              >
                <MenuItem value={'NONE'}>None</MenuItem>
                {Object.values(PlayModeNameType).sort().map(playMode => (
                  <MenuItem key={playMode} value={playMode}>{playMode}</MenuItem>
                ))}
              </Field>
            </Box>
            <Box pb={1} pt={1}>
              <Field
                component={TextField}
                type="text"
                name="modeSelectTileImage"
                fullWidth
                label="Play Mode - Tile Image"
              />
            </Box>
            {eventToEdit &&
              <Box pb={1} pt={1}>
                <ImageUpload
                  imageUrl={modeSelectTileImageUrl}
                  onFileChange={(file: File) => handleTileImageChanged(file, formikData.handleChange)}
                />
              </Box>
            }
            <Box pb={1} pt={1}>
              <Field
                component={TextField}
                type="text"
                name="modeSelectBackgroundImage"
                fullWidth
                label="Play Mode - Background Image"
              />
            </Box>
            {eventToEdit &&
              <Box pb={1} pt={1}>
                <ImageUpload
                  imageUrl={modeSelectBackgroundImageUrl}
                  onFileChange={(file: File) => handleBackgroundImageChanged(file, formikData.handleChange)}
                />
              </Box>
            }
            <Box pb={1} pt={1}>
              <Field
                component={TextField}
                type="text"
                name="modeSelectTitle"
                fullWidth
                label="Play Mode - Title"
              />
            </Box>
            <Box pb={1} pt={1}>
              <Field
                component={TextField}
                type="text"
                name="modeSelectDescription"
                fullWidth
                label="Play Mode - Description"
              />
            </Box>
            <Box pb={1} pt={1}>
              <Field
                component={TextField}
                type="text"
                name="modeSelectEventTagTitle"
                fullWidth
                label="Play Mode - Event Tag Title"
              />
            </Box>
            <Box pb={1} pt={1}>
              <Field
                component={TextField}
                type="text"
                name="modeSelectPlayActionTitle"
                fullWidth
                label="Play Mode - Play Action Title"
              />
            </Box>
            <Box pb={1} pt={2}>
              <JsonEditor
                title="Custom Data"
                json={customData}
                onJsonChange={value => setCustomData(value)}
                onValidityChange={ valid => setCustomDataIsValid(valid) }
                // onBlur={() => this.props.onUpdate({ ...this.props.row, value: this.state.value })}
                focus
              />
            </Box>
            {eventToEdit &&
              <Box pt={1}>
                <Card>
                  <CardHeader title={(
                    <Grid container spacing={2} alignItems="center">
                      <Grid item>
                        Images
                      </Grid>
                    </Grid>
                  )} />
                  <CardContent>
                    <DndProvider backend={HTML5Backend}>
                      <LiveEventImagesEditor
                        liveEvent={eventToEdit}
                        onChange={images => onImageUrlsChange(images, formikData.handleChange)}
                      />
                    </DndProvider>
                  </CardContent>
                </Card>
              </Box>
            }
          </CardContent>
          <CardContent>
            <Button type="submit" variant="contained" color="primary" disabled={!customDataIsValid}>Save</Button>
            &nbsp;
            <Button type="button" variant="outlined" color="secondary" onClick={() => history.replace('/live-events')}>
              Cancel
            </Button>
          </CardContent>
        </form>)}
      </Formik>
    </Card>
    {eventToEdit && eventToEdit.id && <>
      <br/>
      <Card>
        <CardContent>
          <JunctionEventInfosTable
            {...props}
            junctionEventInfos={junctionEventInfos}
            liveEventId={eventToEdit.id}
            refreshJunctionEventInfos={refreshJunctionEventInfos}
          />
        </CardContent>
      </Card>
      <br/>
      <Card>
        <CardContent>
          <SeasonsTable
            {...props}
            seasons={seasons}
            liveEventId={eventToEdit.id}
            refreshSeasons={refreshSeasons}
          />
        </CardContent>
      </Card>
    </> }
  </>
};

import type { Theme } from "@material-ui/core";
import { Box, ListItem, ListItemText, useTheme , makeStyles, createStyles, Grid, List } from "@material-ui/core";
import {
  Home,
  ListAlt,
  PeopleAlt,
  Landscape,
  VideogameAsset,
  TrendingUp,
  DirectionsRun,
  Today,
  Folder,
  Visibility,
  AttachMoney,
  Launch,
  Redeem,
  Palette,
  Store,
  Gamepad,
  Build,
  VerifiedUser,
  Language, Schedule, Block,
} from "@material-ui/icons";
import config from '../../config';
import { UserService } from "../../services/user";
import blankosLogoLight from './blankos-logo-light.png';
import blankosLogo from './blankos-logo.png';
import { ListItemLink } from "./ListItemLink";
import mythicalLogoLight from './mythical-platform-logo-light.png';
import mythicalLogo from './mythical-platform-logo.png';

const stylesDrawerContent = makeStyles((theme: Theme) =>
  createStyles({
    toolbar: {
      ...theme.mixins.toolbar,
      backgroundColor: theme.palette.type === 'dark' ? '#2a2934' : '#fff',
      textAlign: 'center'
    },
  }),
);

export const DrawerContent = () => {
  const classes = stylesDrawerContent();
  const theme = useTheme();
  const showAccountsPage = config.apis.mythical.authProvider !== 'fusionauth' && UserService.canRead('mythicalAccount');
  return <>
    <Grid container alignItems="center" className={classes.toolbar}>
      <Grid item xs={12}>
        <img src={theme.palette.type === 'dark' ? mythicalLogo : mythicalLogoLight} width={196} height={50} alt="Mythical Logo" />
      </Grid>
    </Grid>
    <List>
      <ListItemLink to="/" primary="Home" icon={<Home />} />
      {UserService.canRead('title') && <ListItemLink to="/title" primary="Title Data" icon={<ListAlt />} />}
      {showAccountsPage &&
        <ListItemLink to="/accounts" primary="Accounts" icon={<PeopleAlt />} />}
      {(!showAccountsPage && (UserService.canRead('player') || UserService.canCreate('playerInventoryBulk'))) &&
        <ListItemLink to="/players" primary="Players" icon={<PeopleAlt />} />}
      {UserService.canRead('catalogs') && <ListItemLink to="/catalogs" primary="Catalogs" icon={<Folder />} />}
      {UserService.canRead('catalogItems') && <ListItemLink to="/artists" primary="Artists" icon={<Palette />} />}
      {UserService.canRead('store') && <ListItemLink to="/shops" primary="Shops" icon={<Store />} />}
      {UserService.canRead('commerce') &&
        <ListItemLink to="/transactions" primary="Purchases" icon={<AttachMoney />} />}
      {UserService.canRead('clientVersion') && <ListItemLink to="/launcher/blankos-win64" primary="Launcher" icon={<Launch />} />}
      {UserService.canRead('rewards') && <ListItemLink to="/rewards" primary="Rewards" icon={<Redeem />} />}
      {UserService.canRead('auditLogs') && <ListItemLink to="/logs" primary="Logs" icon={<Visibility />} />}
      {UserService.canRead('catalogItems') && <ListItemLink to="/ivi-config/item-tokens" primary="IVI Config" icon={<Build />} />}
      {UserService.canRead('roles') && <ListItemLink to="/roles" primary="Roles" icon={<VerifiedUser />} />}
      {UserService.canRead('lokalise') && <ListItemLink to="/localization/branches" primary="Localization" icon={<Language />} />}

      <ListItem>
        <ListItemText>
          <Box mt={2} textAlign="center">
            <img src={theme.palette.type === 'dark' ? blankosLogo : blankosLogoLight} width={110} height={42} alt="Mythical Logo" />
          </Box>
        </ListItemText>
      </ListItem>
      {(UserService.canRead('blocks') || UserService.canRead('publishedBlocks')) &&
        <ListItemLink to={UserService.canRead('blocks') ? '/blocks' : '/published-blocks'} primary="Blocks"
          icon={<Landscape />} />}
      {UserService.canRead('blankos') &&
        <>
          {config.env !== 'prod' && <ListItemLink to="/blankos" primary="Blankos" icon={<VideogameAsset />} />}
          <ListItemLink to="/progressions" primary="Progressions" icon={<TrendingUp />} />
          <ListItemLink to="/gem-rush" primary="Gem Rush" icon={<DirectionsRun />} />
        </>}
      {UserService.canRead('liveEvents') && <ListItemLink to="/live-events" primary="Live Events" icon={<Today />} />}
      {UserService.canRead('blocks') && <ListItemLink to="/brawl-blocks" primary="Playlist" icon={<Gamepad />} />}
      {UserService.canRead('geoblocking') && <ListItemLink to="/geo-blocking" primary="Geo-Blocking" icon={<Block />} />}

    </List>
  </>;
}

import { Dialog, DialogContent, DialogTitle } from '@material-ui/core';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { setAppNotification } from '../redux/app/actions';
import { ItemInstancesService } from '../services/item-instances';
import type { ItemInstance } from '../services/model/item-instance';
import type { PlayerDataField } from '../features/players/players';
import { UserService } from '../services/user';
import DataMapEditor from './DataMapEditor';

interface Props {
  item: ItemInstance;
  onSave: (item: ItemInstance) => void;
  onClose: () => void;
}

const ItemCustomDataDialog = (props: Props) => {
  const dispatch = useDispatch();

  const [open, setOpen] = useState(true);

  const onCustomDataSave = (data: { [key: string]: PlayerDataField }) => {
    ItemInstancesService.updateItemInstanceData(props.item.id, data).then(data => {
      dispatch(setAppNotification({ type: 'success', message: 'Item custom data updated' }));
      setOpen(false);
      const item = props.item.clone();
      item.customData = data;
      props.onSave(item);
    }).catch(e => {
      dispatch(setAppNotification({ type: 'error', message: e.message }));
    })
  }

  return (
    <Dialog
      open={open}
      onClose={() => setOpen(false)}
      onExited={props.onClose}
      fullWidth
      maxWidth="lg"
    >
      <DialogTitle>
        Custom data
      </DialogTitle>
      <DialogContent>
        <DataMapEditor
          data={props.item.customData}
          onSave={onCustomDataSave}
          readOnly={!UserService.canUpdate('itemInstances')}
        />
      </DialogContent>
    </Dialog>
  )
}

export default ItemCustomDataDialog;
import type { ApiError } from '../api';
import { api } from '../api';

export class ChallengeTemplate {
  version?: number = undefined;
  templates = [];
  custom_types = [];

  constructor(json?: any) {
    if (json) {
      this.version = json.version;
      this.templates = json.templates.map((template: any) => new Template(template));
      this.custom_types = json.customTypes.map((customType: any) => new CustomType(customType));
    }
  }

  toJson(): any {
    return {
      version: this.version,
      templates: this.templates,
      customTypes: this.custom_types
    };
  }
}

export class ChallengeTrigger {
  version?: number = undefined;
  template?: Template;
  trigger_type?: string; // ChallengeTriggerEnum
  custom_types = [];

  constructor(json?: any) {
    if(json){
      this.version = json.version;
      this.trigger_type = json.triggerType;
      this.template = new Template(json.template);
      this.custom_types = json.customTypes.map((customType: any) => new CustomType(customType));
    }
  }

  toJson(): any {
    return {
      version: this.version,
      triggerType: this.trigger_type,
      template: this.template?.toJson(),
      customTypes: this.custom_types.map((custom_type: any) => custom_type.toJson()),
    }
  }
}

export class Template {
  template_id = '';
  template_name = '';
  trigger_type = '';
  parameters = [];
  custom_types = [];

  constructor(json?: any) {
    if (json) {
      this.template_id = json.templateId;
      this.template_name = json.templateName;
      this.parameters = json.parameters.map((templateParameter: any) => new TemplateParameter(templateParameter));
    }
  }

  toJson(): any {
    return {
      templateId: this.template_id,
      templateName: this.template_name,
      parameters: this.parameters
    }
  }
}

export class TemplateParameter {
  name = '';
  type = '';

  constructor(json?: any) {
    if (json) {
      this.name = json.name;
      this.type = json.type;
    }
  }

  toJson(): any {
    return {
      name: this.name,
      type: this.type
    }
  }
}

export class CustomType {
  type_name = '';
  values = [];

  constructor(json?: any) {
    if (json) {
      this.type_name = json.typeName;
      this.values = json.values;
    }
  }

  toJson(): any {
    return {
      typeName: this.type_name,
      values: this.values
    }
  }
}


export class ChallengeTemplatesService {
  static async getChallengeTemplates(): Promise<Array<ChallengeTemplate>> {
    return api.get({ url: '/players/challenge-templates' })
      .then(challengeTemplates => challengeTemplates.map((challengeTemplate: any) => new ChallengeTemplate(challengeTemplate)))
      .catch((e: ApiError) => {
        e.message = `Failed to get challenge template. ${e.message}`;
        throw e;
      });
  }

  static async getLatestChallengeTemplate(): Promise<ChallengeTemplate> {
    return api.get({ url: '/players/challenge-templates/latest' })
      .then(json => new ChallengeTemplate(json))
      .catch((e: ApiError) => {
        e.message = `Failed to get latest challenge template. ${e.message}`;
        throw e;
      });
  }

  static async getLatestChallengeTriggers(): Promise<Array<ChallengeTrigger>> {
    return api.get({ url: '/players/challenge-triggers/latest' })
      .then(response => response.map((json: any) => new ChallengeTrigger(json)))
      .catch((e: ApiError) => {
        e.message = `Failed to get latest challenge template. ${e.message}`;
        throw e;
      });
  }

  static async getChallengeTemplate(version: number): Promise<ChallengeTemplate> {
    return api.get({ url: `/players/challenge-templates/${version}` })
      .then(challengeTemplate => new ChallengeTemplate(challengeTemplate))
      .catch((e: ApiError) => {
        e.message = `Failed to get challenge template. ${e.message}`;
        throw e;
      });
  }

  static async createChallengeTemplate(challengeTemplateJson: any): Promise<ChallengeTemplate> {
    return api.post({ url: '/players/challenge-templates', body: challengeTemplateJson })
      .then(challengeTemplate => new ChallengeTemplate(challengeTemplate))
      .catch((e: ApiError) => {
        e.message = `Failed to create challenge template. ${e.message}`;
        throw e;
      });
  }

  static async updateChallengeTemplate(challengeTemplate: ChallengeTemplate): Promise<ChallengeTemplate> {
    return api.put({ url: `/players/challenge-templates/${challengeTemplate.version}`, body: challengeTemplate.toJson() })
      .then(challengeTemplate => new ChallengeTemplate(challengeTemplate))
      .catch((e: ApiError) => {
        e.message = `Failed to update challenge template. ${e.message}`;
        throw e;
      });
  }

  static async deleteChallengeTemplate(version: number): Promise<null> {
    return api.delete({ url: `/players/challenge-templates/${version}` })
      .catch((e: ApiError) => {
        e.message = `Failed to delete challenge template. ${e.message}`;
        throw e;
      });
  }

  static async saveChallengeTrigger(challengeTrigger: ChallengeTrigger): Promise<null> {
    return api.post({ url: `/players/challenge-trigger`, body: challengeTrigger.toJson() })
      .catch((e: ApiError) => {
        e.message = `Failed to save challenge trigger. ${e.message}`;
        throw e;
      });
  }
}
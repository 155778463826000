import { Box, Button, Typography } from '@material-ui/core';
import * as Components from '../Components';

interface CatalogMigrationProps {
  step: number;
  lastStep: number;
  isLoading: boolean;
  migrationRunning: boolean;
  migrationFinished: boolean;
  canRunMigration: boolean;
  nextDisabled: boolean;
  updateStep(dir: number): void;
  runMigration(): void;
}

export const CatalogMigration = ({
  step,
  lastStep,
  isLoading,
  migrationRunning,
  migrationFinished,
  canRunMigration,
  nextDisabled,
  updateStep,
  runMigration
}: CatalogMigrationProps) => {
  if (step > 1 && isLoading) {
    return <Components.Loading />
  }

  if (!migrationRunning && migrationFinished) {
    return <Components.Results />
  }
  return (<>
    <Typography variant="h5">Catalog migration</Typography>

    {step === 1 && (<Components.Options />)}
    {step === 2 && (<Components.Items />)}
    {step === 3 && (<Components.DropTables />)}
    {step === 4 && (<Components.Stores />)}
    {step === 5 && (<Components.TitleData type="client" />)}
    {step === 6 && (<Components.TitleData type="server" />)}
    {step === 7 && (<Components.TitleFiles />)}
    {step === 8 && (<Components.BlankoDna />)}
    {step === 9 && (<Components.BlankoAssets />)}
    {step === 10 && (<Components.SeasonalData />)}
    {step === 11 && (<Components.BlankoProgressions />)}
    {step === 12 && (<Components.Translations />)}
    {step === lastStep && <Components.ReviewStep />}

    <Box mt={2}>
      {step > 1 && (
        <Box display="inline-block" mr={2}>
          <Button variant="outlined" onClick={() => updateStep(-1)}>
            Prev
          </Button>
        </Box>
      )}
      {step < lastStep && (
        <Button variant="contained" color="primary" onClick={() => updateStep(1)} disabled={nextDisabled}>
          Next
        </Button>
      )}

      {step === lastStep && (
        <Button variant="contained" color="primary" disabled={!canRunMigration} onClick={runMigration}>
          Run migration
        </Button>
      )}
    </Box>

    {migrationRunning && <Components.MigrationDialog />}
  </>)
}

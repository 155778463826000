import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Chip from '@material-ui/core/Chip';
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';
import Link from '@material-ui/core/Link';
import type { Theme } from '@material-ui/core/styles';
import { createStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import DeleteIcon from '@material-ui/icons/Delete';
import GetAppIcon from '@material-ui/icons/GetApp';
import StarIcon from '@material-ui/icons/Star';
import type { Column } from 'material-table';
import { Fragment, useCallback, useEffect, useState } from 'react';
import type { RouteComponentProps } from 'react-router-dom';
import { Link as RouterLink } from 'react-router-dom';
import type { MenuAction } from '../../../components/MaterialTable';
import MaterialTable from '../../../components/MaterialTable';
import { Catalog, CatalogsService } from '../../../services/catalogs';
import { UserService } from '../../../services/user';
import { AddCatalogDialog } from './AddCatalogDialog';
import { ImportCatalogDialog } from './ImportCatalogDialog';
import ImportExportIcon from '@material-ui/icons/ImportExport';
import {
  openAdminConfirmationDialog
} from "../../../shared/hooks/useAdminConfirmationDialog";
import { pushAppNotification } from "../../../shared/hooks/useAppNotification";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) => createStyles({
  primaryChip: {
    marginLeft: theme.spacing(2)
  }
}));

type Props = RouteComponentProps;

const getMilestone = (catalogName: string): number => {
  const result = milestonePattern.exec(catalogName);
  if (result) {
    return parseInt(result[1]) || -1;
  }
  return -1;
};

const milestonePattern = /^MS(\d+).*$/;
export const Catalogs = ({history}: Props) => {
  const classes = useStyles();

  const columns: Column<Catalog>[] = [
    {
      title: 'Name',
      field: 'name',
      defaultSort: 'desc',
      render: catalog => (
        <Fragment>
          {UserService.canRead('catalogItems') || UserService.canRead('store') ? (
            <Link component={RouterLink} to={`/catalogs/${catalog.name}`}>
              {catalog.name}
            </Link>
          ) : <>{catalog.name}</>}
          {catalog.primaryCatalog ? (
            <Chip
              className={classes.primaryChip}
              size="small"
              color="primary"
              label="Primary"
            />
          ) : null}
        </Fragment>
      ),
      customSort: (a, b) => {
        const msa = getMilestone(a.name);
        const msb = getMilestone(b.name);
        if (msa === msb) {
          return a.name.localeCompare(b.name);
        } else {
          return msa - msb;
        }
      }
    }
  ];

  const [addDialogOpen, setAddDialogOpen] = useState(false);
  const [importDialogOpen, setImportDialogOpen] = useState(false);
  const [catalogs, setCatalogs] = useState<Catalog[]>([]);

  useEffect(() => {
    CatalogsService.getCatalogs()
      .then(cats => setCatalogs(cats));
  }, []);

  const openMakePrimaryConfirmation = useCallback((catalog: Catalog) => {
    openAdminConfirmationDialog({
      title: `Make ${catalog.name} the primary catalog?`,
      action: 'Make primary',
      onConfirm: () => {
        const newCatalog = new Catalog(catalog.toJson());
        newCatalog.primaryCatalog = true;
        CatalogsService.updateCatalog(newCatalog)
          .then(() => {
            pushAppNotification({ type: 'success', message: 'Catalog set as primary' });
            setCatalogs(catalogs
              .map(cat => {
                const updatedCatalog = new Catalog(cat.toJson());
                updatedCatalog.primaryCatalog = cat.name === catalog.name;
                return updatedCatalog;
              }));
          })
          .catch(err => {
            pushAppNotification({type: 'error', message: `Failed to update catalog: ${err.message}`});
          });
      }
    });
  }, [catalogs]);

  const downloadCatalog = useCallback((catalog: Catalog) => {
    CatalogsService.downloadCatalog(catalog.name).catch(err => {
      pushAppNotification({type: 'error', message: `Failed to download catalog: ${err.message}`});
    });
  }, []);

  const migrateCatalog = useCallback((catalog: Catalog) => {
    history.push(`/catalog-migration?source=${catalog.name}`);
  }, [history]);

  const openDeleteCatalogConfirmation = useCallback((catalog: Catalog) => {
    openAdminConfirmationDialog({
      title: `Delete ${catalog.name}?`,
      action: 'Delete catalog',
      onConfirm: () => {
        CatalogsService.deleteCatalog(catalog.name)
          .then(() => {
            pushAppNotification({ type: 'success', message: 'Catalog deleted' });
            setCatalogs(catalogs.filter(cat => cat.name !== catalog.name));
          })
          .catch(err => {
            pushAppNotification({type: 'error', message: `Failed to delete catalog: ${err.message}`});
          });
      }
    });
  }, [catalogs]);

  const buildMenuActions = useCallback((catalog: Catalog) => {
    const actions: MenuAction<Catalog>[] = !UserService.canUpdate('catalogs') || catalog.primaryCatalog ? [] : [
      {type: 'button', icon: StarIcon, label: 'Make Primary', onClick: openMakePrimaryConfirmation}
    ];
    actions.push({type: 'button', icon: GetAppIcon, label: 'Download', onClick: downloadCatalog});

    if (UserService.canMigrateFrom()) {
      actions.push({type: 'button', icon: ImportExportIcon, label: 'Migrate', onClick: migrateCatalog});
    }

    if (UserService.canDelete('catalogs')) {
      actions.push({type: 'divider'});
      actions.push({type: 'button', icon: DeleteIcon, label: 'Delete', onClick: openDeleteCatalogConfirmation});
    }
    return actions;
  }, [downloadCatalog, migrateCatalog, openDeleteCatalogConfirmation, openMakePrimaryConfirmation]);

  return (catalogs) ? (
    <div>
      <Box mb={2}>
        <Grid container spacing={2} alignItems="center">
          <Grid item xs>
            <Typography variant="h6">
              Catalogs
            </Typography>
          </Grid>
          {UserService.canCreate('catalogs') && (<>
            <Grid item>
              <Button variant="contained" color="primary" onClick={() => setAddDialogOpen(true)}>Add Catalog</Button>
            </Grid>
            <Grid item>
              <Button variant="contained" color="primary" onClick={() => setImportDialogOpen(true)}>Import
                Catalog</Button>
            </Grid>
          </>)}
        </Grid>
      </Box>

      <MaterialTable
        columns={columns}
        data={catalogs}
        menuActions={buildMenuActions}
        options={{
          showTitle: false
        }}
      />

      <AddCatalogDialog
        open={addDialogOpen}
        catalogs={catalogs}
        setCatalogs={setCatalogs}
        onClose={() => setAddDialogOpen(false)}
      />

      {importDialogOpen && (
        <ImportCatalogDialog
          catalogs={catalogs}
          setCatalogs={setCatalogs}
          onClose={() => setImportDialogOpen(false)}
        />
      )}
    </div>
  ) : (
    <Box textAlign="center">
      <CircularProgress/>
    </Box>
  );
};


export class TitleFile {
  fileName = '';
  updatedTimestamp: Date = new Date();
  version = 0;
  uploaderName = '';
  fileUrl = '';

  constructor(json?: any) {
    if (json) {
      this.fileName = json.fileName;
      this.updatedTimestamp = new Date(json.updatedTimestamp);
      this.version = json.version;
      this.uploaderName = json.uploaderName || '';
      this.fileUrl = json.fileUrl;
    }
  }
}
import type {PlayerTag} from "../accounts";
import {useCallback, useState} from "react";
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle, FormControl, FormHelperText, InputLabel, ListItemIcon, MenuItem, Select,
  TextField
} from "@material-ui/core";
import {createTagAsync, updateTagAsync} from "../accountApi";
import {pushAppNotification} from "../../../shared/hooks/useAppNotification";
import type {ApiError} from "../../../services/api";

interface Props {
  show: boolean
  tag: PlayerTag | undefined
  onClose: () => void
  onSubmit: () => void
}

export const TagForm = ({ show, tag, onClose, onSubmit} : Props) => {
  const isAddMode = !Boolean(tag);
  const [tagInput, setTagInput] = useState(tag?.tag ?? '');
  const [tagError, setTagError] = useState('');
  const [visError, setVisError] = useState('');
  const [visibilityInput, setVisibilityInput] = useState(tag?.visibility ?? ['']);
  const [descriptionInput, setDescriptionInput] = useState(tag?.description ?? '')
  const [isSubmitting, setSubmitting] = useState(false);

  const createTag = useCallback((tag: string, visibility: string[], description: string) => {
    const newTag: PlayerTag = {
      id: '',
      tag,
      visibility,
      description
    };
    createTagAsync(newTag)
      .then(() => pushAppNotification({ type: 'success', message: `Successfully created ${tag}`}))
      .catch((e: ApiError) => {
        pushAppNotification( {type: 'error', message: `Failed to create tag ${e.message}`})
        setSubmitting(false)
      })
  }, [])

  const updateTag = useCallback((id: string, tag: string, visibility: string[], description: string) => {
    const updatedTag: PlayerTag = {
      id,
      tag,
      visibility,
      description
    };
    updateTagAsync(updatedTag).then(() => {
      pushAppNotification({ type: 'success', message: `Successfully updated ${tag}`})
    }).catch((e: ApiError) => {
      setSubmitting(false)
      pushAppNotification({ type: 'error', message: `Failed to update tag ${e.message}`})
    })
  }, [])

  const handleSubmit = useCallback( () => {
    const tagName = tagInput.trim();
    const vis = visibilityInput;
    const des = descriptionInput;
    if (!tagName || !vis) {
      if (!tagName) {
        setTagError("A tag name is required")
      }
      if (!vis) {
        setVisError("Visibility is required")
      }
      return
    }
    if (isAddMode) {
      vis.shift()
      createTag(tagName, vis, des)
    } else {
      if (typeof tag === 'object' && tag.id) {
        tag && updateTag(tag.id, tagName, vis, des);
      }
    }
    setSubmitting(true);
    onSubmit();
    onClose();
  }, [tagInput, isAddMode, onSubmit, onClose, createTag, tag, updateTag, visibilityInput, descriptionInput]);

  const handleChange = (event: { target: { value: any; }; }) => {
    setVisibilityInput(event.target.value);
  };

  return (
    <Dialog
      open={show}
      onClose={onClose}
      onExited={onClose}
      fullWidth
    >
      <DialogTitle>{isAddMode ? 'Create ' : 'Update '} Tag</DialogTitle>
      <DialogContent>
        <TextField
          label="Tag Name"
          value={tagInput}
          helperText={tagError}
          error={!!tagError}
          onChange={e => { setTagInput(e.target.value.toUpperCase() as string); setTagError(''); }}
          fullWidth
          required
          InputProps={{ readOnly: isSubmitting, style: { textTransform: "uppercase" } }}
        />
        <Box mt={2}>
          <FormControl fullWidth>
            <InputLabel id="mutiple-select-label">Visibility</InputLabel>
            <Select name="visibility"
              multiple
              label="Visibility"
              value={visibilityInput}
              onChange={e => handleChange(e)}
              error={!!visError}
              required
            >
              <MenuItem value={'NONE'}>NONE</MenuItem>
              <MenuItem value={'PRIVATE'}>PRIVATE</MenuItem>
              <MenuItem value={'PUBLIC'}>PUBLIC</MenuItem>
              <MenuItem value={'FRIENDS'}>FRIENDS</MenuItem>
              <MenuItem value={'PARTY'}>PARTY</MenuItem>
            </Select>
            <FormHelperText>{visError}</FormHelperText>
          </FormControl>
        </Box>
        <Box mt={2}>
          <TextField
            label="Description"
            fullWidth
            multiline
            rows={2}
            rowsMax={2}
            value={descriptionInput}
            onChange={e => { setDescriptionInput(e.target.value as string);}}
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button disabled={isSubmitting} variant="contained" color="primary" onClick={handleSubmit}>
          {isSubmitting ? (
            <CircularProgress size={25} />
          ) : `${isAddMode ? 'Create' : 'Update'} tag`}
        </Button>
      </DialogActions>
    </Dialog>
  );

}
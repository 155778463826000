import download from 'downloadjs';
import type { ApiError } from './api';
import { api } from './api';
import { DropTable } from './drop-tables';
import { ItemDefinition } from './item-definitions';

export class Catalog {
  name = '';
  primaryCatalog = false;
  items: ItemDefinition[] | null = null;
  dropTables: DropTable[] | null = null;

  constructor(json: any) {
    this.name = json.name;
    this.primaryCatalog = json.primaryCatalog;

    if (Array.isArray(json.items)) {
      this.items = json.items.map((v: any) => new ItemDefinition(v));
    }
    if (Array.isArray(json.dropTables)) {
      this.dropTables = json.dropTables.map((v: any) => new DropTable(v));
    }
  }

  hasContents(): boolean {
    return this.items !== null && this.dropTables !== null;
  }

  toJson(): any {
    const json: any = {
      name: this.name,
      primaryCatalog: this.primaryCatalog
    };

    if (this.items !== null && this.dropTables !== null) {
      json.items = this.items.map(item => item.toJson());
      json.dropTables = this.dropTables.map(dropTable => dropTable.toJson());
    }

    return json;
  }
}

export class CatalogsService {
  static async getCatalogs(): Promise<Catalog[]> {
    return api.get<Catalog[]>({ url: '/inventory/catalogs' })
      .then(catalogs => catalogs.map(c => new Catalog(c)))
      .catch((e: ApiError) => {
        e.message = `Failed to get catalogs. ${e.message}`;
        throw e;
      });
  }

  static async getCatalog(catalogName: string): Promise<Catalog> {
    return api.get<Catalog>({ url: `/inventory/catalogs/${catalogName}` })
      .then(catalog => new Catalog(catalog))
      .catch((e: ApiError) => {
        e.message = `Failed to get catalogs. ${e.message}`;
        throw e;
      });
  }

  static async addCatalog(catalog: Catalog): Promise<Catalog> {
    return api.post({
      url: '/inventory/catalogs',
      body: catalog.toJson(),
      query: { includeContents: catalog.hasContents().toString() }
    }).then(catalog => new Catalog(catalog)).catch((e: ApiError) => {
      if (e.code === 40900) {
        e.message = 'Failed to create catalog. Catalog already exists.';
      }
      throw e;
    });
  }

  static async updateCatalog(catalog: Catalog): Promise<Catalog> {
    const body = catalog.toJson();
    delete body.name;
    return api.put<Catalog>({
      url: `/inventory/catalogs/${catalog.name}`,
      body
    }).then(catalog => new Catalog(catalog)).catch((e: ApiError) => {
      e.message = `Failed to update catalog. ${e.message}`;
      throw e;
    });
  }

  static async deleteCatalog(name: string): Promise<null> {
    return api.delete({ url: `/inventory/catalogs/${name}` }).catch((e: ApiError) => {
      e.message = `Failed to delete catalog. ${e.code === 40900 ? 'Catalog is not empty and is currently being used.' : e.message}`;
      throw e;
    });
  }

  static async importCatalog(json: any): Promise<Catalog> {
    return api.post<Catalog>({ url: '/inventory/catalogUpload', body: json })
      .then(catalog => new Catalog(catalog))
      .catch((e: ApiError) => {
        e.message = 'Failed to import catalog.';
        throw e;
      });
  }

  static async downloadCatalog(name: string): Promise<void> {
    try {
      const catalog = await api.get<any>({ url: `/inventory/catalogs/${name}`, query: { includeContents: 'true' } });

      // Sort catalog items/drop tables/contents for diffs and such
      if (Array.isArray(catalog.items)) {
        catalog.items.sort((a: any, b: any) => a.itemId.localeCompare(b.itemId));
        catalog.items.forEach((item: any) => {
          if (Array.isArray(item.tags)) {
            item.tags.sort();
          }

          if (Array.isArray(item.itemBundleContents)) {
            item.itemBundleContents.sort((a: any, b: any) => {
              if (a.itemId && b.itemId) {
                return a.itemId.localeCompare(b.itemId);
              }
              if (a.tableId && b.tableId) {
                return a.tableId.localeCompare(b.tableId);
              }
              return b.tableId ? 1 : -1;
            });
          }

          if (Array.isArray(item.itemContainerContents)) {
            item.itemContainerContents.sort((a: any, b: any) => {
              if (a.itemId && b.itemId) {
                return a.itemId.localeCompare(b.itemId);
              }
              if (a.tableId && b.tableId) {
                return a.tableId.localeCompare(b.tableId);
              }
              return b.tableId ? 1 : -1;
            });
          }
        });
      }

      if (Array.isArray(catalog.dropTables)) {
        catalog.dropTables.sort((a: any, b: any) => a.tableId.localeCompare(b.tableId));
        catalog.dropTables.forEach((table: any) => {
          if (Array.isArray(table.nodes)) {
            table.nodes.sort((a: any, b: any) => {
              if (a.itemId && b.itemId) {
                return a.itemId.localeCompare(b.itemId);
              }
              if (a.tableId && b.tableId) {
                return a.tableId.localeCompare(b.tableId);
              }
              return b.tableId ? 1 : -1;
            });
          }
        });
      }

      download(JSON.stringify(catalog, null, 2), `${name}.json`, 'application/json');
    } catch (e) {
      console.error('Failed to download catalog', e);
      e.message = 'Failed to download catalog.';
      throw e;
    }
  }
}
import { Accordion, AccordionDetails, AccordionSummary, Box, Card, CardContent, Grid } from "@material-ui/core";
import { useCallback, useEffect, useMemo, useState } from "react";
import { seasonalDataComparer } from "./seasonal-data-comparer";
import type { SeasonalDataForComparison, Stringish } from "./seasonal-data-comparer";
import { store } from '../../../../redux/store'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { SeasonalRewardTierDiffView } from "./SeasonalRewardTierDiffView";
import { GlobalChallengesDiffView } from "./GlobalChallengesDiffView";
import { SeasonalChallengeDiffView } from "./SeasonalChallengeDiffView";
import { LiveEventForComparison } from "../live-event/live-event-comparer";

export interface SeasonalDiffViewProps {
  beforeChanges?: LiveEventForComparison;
  afterChanges: LiveEventForComparison;
  previous?: LiveEventForComparison;
  current: LiveEventForComparison;
  renderDateString: (date?: Date | number) => string;
  renderDifferentialChange: (label: string, after?: Stringish, before?: Stringish) => JSX.Element | undefined;
}

export interface SeasonalDiffSubViewProps {
  beforeChanges?: SeasonalDataForComparison;
  afterChanges: SeasonalDataForComparison;
  previous?: SeasonalDataForComparison;
  current: SeasonalDataForComparison;
  renderDateString: (date?: Date | number) => string;
  renderDifferentialChange: (label: string, after?: Stringish, before?: Stringish) => JSX.Element | undefined;
}

export interface ChallengeDiffProps extends SeasonalDiffSubViewProps {
  renderChallengeRewards: (afterRewards: Reward[], beforeRewards?: Reward[], currentRewards?: Reward[], previousRewards?: Reward[]) => JSX.Element | undefined;
}

export type SeasonDictionary = { [key: string]: SeasonalDataForComparison };

export const SeasonalDiffView = (props: SeasonalDiffViewProps) => {
  const { beforeChanges, afterChanges, previous, current, renderDateString, renderDifferentialChange } = props;
  const classes = seasonalDataComparer.getStyles();

  const [seasonsExpanded, setSeasonsExpanded] = useState(false);
  const watchedMode = store.getState().app.darkMode;
  const [darkMode, setDarkMode] = useState(watchedMode);

  useEffect(() => {
    if (watchedMode != darkMode) {
      setDarkMode(watchedMode);
    }
  }, [darkMode, setDarkMode, watchedMode]);

  const renderChallengeRewards = useCallback((afterRewards: Reward[], beforeRewards?: Reward[], currentRewards?: Reward[], previousRewards?: Reward[]) => {
    if (!afterRewards && !beforeRewards) {
      return;
    }

    const afterDiffs = new Set<number>();
    afterRewards?.forEach((_, i) => {
      const rewards = currentRewards?.[i];
      if (rewards) {
        afterDiffs.add(i);
      }
    });

    const beforeDiffs = new Set<number>();
    beforeRewards?.forEach((_, i) => {
      const rewards = previousRewards?.[i];
      if (rewards) {
        beforeDiffs.add(i)
      }
    });

    //Convert to set to remove dupes
    const combinedKeys = new Set<number>([...afterDiffs, ...beforeDiffs]);
    const previousKeys = previousRewards || [];
    const currentKeys = currentRewards || [];
    const combinedExistingKeys = Array.from(new Set([...previousKeys.keys(), ...currentKeys.keys()]));

    return (
      <>
        {
          combinedExistingKeys.map(key => {
            if (combinedKeys.has(key)) {
              const currentItem = currentRewards?.[key];
              const previousItem = previousRewards?.[key];

              const rewardValueString = (reward?: Reward) => {
                return !reward ? '' : JSON.stringify(reward.param);
              };

              return (
                <Grid container key={key}>
                  <Grid className={classes.sectionTitle} item xs={12}>
                    <h4>Reward {key}</h4>
                  </Grid>
                  {renderDifferentialChange("Type", currentItem?.type, previousItem?.type)}
                  {renderDifferentialChange("Value", rewardValueString(currentItem), rewardValueString(previousItem))}
                </Grid>
              )
            }
          })
        }
      </>
    );
  }, [classes, renderDifferentialChange]);

  const showSeasonHeader = useMemo(() => {
    return beforeChanges?.seasonalData || afterChanges.seasonalData;
  }, [beforeChanges, afterChanges]);

  const renderSeasons = useCallback((afterSeasons: SeasonDictionary, beforeSeasons?: SeasonDictionary) => {
    const safeBefore = beforeSeasons ? beforeSeasons : {} as SeasonDictionary;

    const afterDiffs = new Set<string>();
    Object.keys(afterSeasons).forEach((s, i) => {
      const season = current.seasonalData[s];
      if (season) {
        afterDiffs.add(s);
      }
    });

    const beforeDiffs = new Set<string>();
    Object.keys(safeBefore).forEach((s, i) => {
      const challenge = previous?.seasonalData[s];
      if (challenge) {
        beforeDiffs.add(s);
      }
    });

    //Convert to set to remove dupes
    const combinedKeys = new Set<string>([...afterDiffs, ...beforeDiffs]);

    return (
      Array.from(combinedKeys).map(key => {
        const seasonNumber = (Object.keys(previous?.seasonalData || {}).includes(key) ?
          previous?.seasonalData[key].seasonNumber :
          current.seasonalData[key].seasonNumber) || "Missing Key";

        const currentItem = current?.seasonalData?.[seasonNumber];
        const previousItem = previous?.seasonalData?.[seasonNumber];

        if (combinedKeys.has(seasonNumber.toString())) {
          return (
            <Card key={key} className={classes.challengeContainer} variant="outlined" >
              <CardContent>
                <Grid container>
                  <Grid className={classes.sectionTitle} item xs={12}>
                    <h3>Season : {currentItem.seasonName || previousItem?.seasonName}</h3>
                  </Grid>
                  {renderDifferentialChange("Name", currentItem.seasonName, previousItem?.seasonName)}
                  {renderDifferentialChange("Number", currentItem.seasonNumber, previousItem?.seasonNumber)}
                  {renderDifferentialChange("Start", renderDateString(currentItem.seasonStart), renderDateString(previousItem?.seasonStart))}
                  {renderDifferentialChange("End", renderDateString(currentItem.seasonEnd), renderDateString(previousItem?.seasonEnd))}
                  {renderDifferentialChange("UTC Change", currentItem.seasonDayUTCChange, previousItem?.seasonDayUTCChange)}
                  <GlobalChallengesDiffView
                    beforeChanges={beforeChanges?.seasonalData ? beforeChanges?.seasonalData[seasonNumber] : undefined}
                    afterChanges={afterChanges.seasonalData[seasonNumber]}
                    previous={previous?.seasonalData ? previous?.seasonalData[seasonNumber] : undefined}
                    current={current.seasonalData[seasonNumber]}
                    renderChallengeRewards={renderChallengeRewards}
                    renderDateString={renderDateString}
                    renderDifferentialChange={renderDifferentialChange}
                  />

                  <SeasonalRewardTierDiffView
                    beforeChanges={beforeChanges?.seasonalData ? beforeChanges?.seasonalData[seasonNumber] : undefined}
                    afterChanges={afterChanges.seasonalData[seasonNumber]}
                    previous={previous?.seasonalData ? previous?.seasonalData[seasonNumber] : undefined}
                    current={current.seasonalData[seasonNumber]}
                    renderDateString={renderDateString}
                    renderDifferentialChange={renderDifferentialChange}
                  />

                  <SeasonalChallengeDiffView
                    beforeChanges={beforeChanges?.seasonalData ? beforeChanges?.seasonalData[seasonNumber] : undefined}
                    afterChanges={afterChanges.seasonalData[seasonNumber]}
                    previous={previous?.seasonalData ? previous?.seasonalData[seasonNumber] : undefined}
                    current={current.seasonalData[seasonNumber]}
                    renderChallengeRewards={renderChallengeRewards}
                    renderDateString={renderDateString}
                    renderDifferentialChange={renderDifferentialChange}
                  />
                </Grid>
              </CardContent>
            </Card>
          )
        }
      })
    );
  }, [afterChanges, beforeChanges, previous, current, renderDifferentialChange, classes, renderChallengeRewards, renderDateString]);

  return (
    <>
      {showSeasonHeader && (
        <Accordion
          expanded={seasonsExpanded}
          TransitionProps={{ unmountOnExit: true }}
          onChange={() => setSeasonsExpanded(!seasonsExpanded)}
          className={classes.accordianSummary}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            className={classes.accordianSummary}
          >
            <Grid container alignItems="center" spacing={2}>
              <Grid item xs={12} className={classes.accordionTitle}>
                Seasons
              </Grid>
            </Grid>
          </AccordionSummary>
          <AccordionDetails style={{ padding: 0 }}>
            <Box width="100%">
              {renderSeasons(afterChanges.seasonalData, beforeChanges?.seasonalData)}
            </Box>
          </AccordionDetails>
        </Accordion>
      )}
    </>
  );
}
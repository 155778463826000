import { useParams } from "react-router"
import { useState, useCallback, useEffect, useMemo } from "react";
import {
  Box,
  Breadcrumbs,
  Button,
  CircularProgress,
  Grid,
  IconButton,
  Link,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Theme,
  Tooltip,
  Typography,
  makeStyles,
  createStyles
} from "@material-ui/core"
import DeleteIcon from '@material-ui/icons/Delete';
import ErrorIcon from '@material-ui/icons/Error';
import { UserService } from "../../services/user"
import { NavigateNext } from "@material-ui/icons"
import { DeleteFuseDialog, EditFuseDialog } from "./components"
import type { BlankoGemRushFuse } from './blankoGemRush';
import { AddFuseDialog } from "./components/AddFuseDialog"
import { Link as RouterLink } from 'react-router-dom';
import { getBlankoRushFuses } from "./gemRushApiService"
import _ from "lodash";
import { ItemDefinition, ItemDefinitionsService } from "../../services/item-definitions";
import { CatalogMigrationService } from "../../services/catalog-migration";
import config from '../../config';
import { FuseFormGemRushFuse } from "./components/FuseForm";
import { usePushNotification } from "../../contexts/AppNotificationContext";

/**
 * The base class for items that will be displayed in the fuse table.
 */
abstract class TableViewItem {
  canDelete = false;
  fuseId: string | undefined;
  baseDnaId: string | undefined;
  baseDnaUrl: string | undefined;
  upgradeDnaId: string | undefined;
  upgradeDnaUrl: string | undefined;
  fuseAfter: BlankoGemRushFuse | undefined;
  maxQuantity = '';
  remainingQuantity = '';
}

/**
 * This item is used when no fuses have been added yet.
 */
class InitialItem extends TableViewItem {
}

/**
 * This item occurs prior to any fuse that does not have a
 * leading fuse.  Note that the first fuse will always be preceded by
 * a BeforeItem.
 */
class BeforeItem extends TableViewItem {

  constructor(afterFuseItem: FuseItem) {
    super();

    this.upgradeDnaId = afterFuseItem.baseDnaId;
    this.upgradeDnaUrl = afterFuseItem.baseDnaUrl;
  }
}

/**
 * This item occurs after any fuse that does not have a
 * following fuse.  Note that the last fuse will always be followed by
 * an AfterItem.
 */
class AfterItem extends TableViewItem {

  constructor(beforeFuseItem: FuseItem) {
    super();

    this.baseDnaId = beforeFuseItem.upgradeDnaId;
    this.baseDnaUrl = beforeFuseItem.upgradeDnaUrl;
  }
}

/**
 * This is an actual Fuse item.
 */
class FuseItem extends TableViewItem {

  constructor(fuse: BlankoGemRushFuse, baseDnaUrl: string, upgradeDnaUrl: string) {
    super();

    this.canDelete = true;

    this.fuseId = fuse.id;
    this.baseDnaId = fuse.baseDnaId;
    this.baseDnaUrl = baseDnaUrl;
    this.upgradeDnaId = fuse.upgradeDnaId;
    this.upgradeDnaUrl = upgradeDnaUrl;
    this.maxQuantity = fuse.maxQuantity.toString();
    this.remainingQuantity = fuse.remainingQuantity.toString();
  }
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    link: {
      fontFamily: 'Poppins, Helvetica, Arial, sans-serif',
      fontWeight: 500,
      lineHeight: 1.6,
      fontSize: '1.25rem'
    },
  }),
);

/**
 * Sorts the gem rush fuses based on the baseDna->upgradeDna relationship.
 */
const sortGemRushFuses = (fuses: BlankoGemRushFuse[]): BlankoGemRushFuse[] => {
  const sortedFuses = [...fuses];

  // since having 2 fuses is the most common case, we'll handle it here
  if (sortedFuses.length === 2) {
    // if fuse2 points at fuse1, flip them
    if (sortedFuses[1].upgradeDnaId === sortedFuses[0].baseDnaId) {
      sortedFuses[0] = fuses[1];
      sortedFuses[1] = fuses[0];
    }
  } else if (sortedFuses.length > 2) {
    const sourceFuses = [...fuses];

    // build sorted fuses from scratch
    sortedFuses.splice(0, sortedFuses.length);

    try {
      const possibleFirstFuses = sourceFuses
        .filter(fuse => sourceFuses.find(other => other.upgradeDnaId === fuse.baseDnaId) === undefined);
      if (_.isEmpty(possibleFirstFuses)) {
        throw new Error('Fuses were cyclical');
      }
      const firstFuse = possibleFirstFuses[0];
      sortedFuses.push(firstFuse);
      sourceFuses.splice(sourceFuses.findIndex(f => f.id === firstFuse?.id), 1);

      while (!_.isEmpty(sourceFuses)) {
        const lastFuse = sortedFuses[sortedFuses.length - 1];
        const upgradeFuses = sourceFuses
          .filter(fuse => lastFuse.upgradeDnaId === fuse.baseDnaId);
        if (_.isEmpty(upgradeFuses)) {
          throw new Error('Fuse did not have an upgrade');
        }
        const nextFuse = upgradeFuses[0];
        sortedFuses.push(nextFuse);
        sourceFuses.splice(sourceFuses.findIndex(f => f.id === nextFuse?.id), 1);
      }
    } catch (e) {
      // something's wrong, just use the order of source fuses
      sortedFuses.splice(0, sortedFuses.length, ...fuses);
    }
  }

  return sortedFuses;
}

/**
 * Creates a collection of FuseItem objects that are sorted based on the
 * baseDna->upgradeDna relationship.
 * @param fuses The actual gem rush fuses.
 * @param blankoIdsToUrls A map of blanko IDs to their associated image URLs
 * @returns a collection of FuseItem objects that have been sorted based on
 * the baseDna->upgradeDna relationships
 */
const createFuseItems = (fuses: BlankoGemRushFuse[], blankoIdsToUrls: { [key: string]: string }): FuseItem[] => {
  const sortedFuses = sortGemRushFuses(fuses);

  // map each fuse to a view item for table
  return sortedFuses.map(fuse => {
    const baseDnaUrl = blankoIdsToUrls[fuse.baseDnaId];
    const upgradeDnaUrl = blankoIdsToUrls[fuse.upgradeDnaId];
    return new FuseItem(fuse, baseDnaUrl, upgradeDnaUrl);
  });
}

export const GemRushFuses = () => {
  const classes = useStyles();
  const pushNotification = usePushNotification();
  const params = useParams<{rushId: string}>();

  const [addData, setAddData] = useState<FuseFormGemRushFuse>();
  const [blankoIdsToThumbnailUrls, setBlankoIdsToUrls] = useState<{ [key: string]: string }>({});
  const [editData, setEditData] = useState<FuseFormGemRushFuse>();
  const [fuseId, setFuseId] = useState<string>('');
  const [fuses, setFuses] = useState<BlankoGemRushFuse[]>();
  const [primaryCatalogName, setPrimaryCatalogName] = useState<string>();
  const [showAddDialog, setShowAddDialog] = useState(false);
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [showEditDialog, setShowEditDialog] = useState(false);

  const handleClose = useCallback(() => {
    setShowAddDialog(false);
    setShowDeleteDialog(false);
    setShowEditDialog(false);
  }, [])

  /**
   * Creates all of the view items necessary to layout the fuse table. Any fuse that does
   * not have another fuse leading into it (upgradeDna->baseDna relationship) will be
   * preceded by a BeforeItem.  Likewise, any fuse that does have another fuse following
   * it will be followed by an AfterItem.  If there are no fuses, then the only item
   * created is an InitialItem.
   */
  const tableViewItems: TableViewItem[] = useMemo(() => {
    if (!fuses)
      return [];

    const sortedFuses = createFuseItems(fuses, blankoIdsToThumbnailUrls);
    const viewItems: TableViewItem[] = [];

    for (let index = 0; index < sortedFuses.length; index++) {
      const fuseItem = sortedFuses[index];
      const previousFuseItem = index === 0 ? null : sortedFuses[index - 1];
      const nextFuseItem = index === length - 1 ? null : sortedFuses[index + 1];

      // if no previous item OR the previous item doesn't reference current item, add a BeforeItem
      if (!previousFuseItem || previousFuseItem.upgradeDnaId !== fuseItem.baseDnaId) {
        viewItems.push(new BeforeItem(fuseItem));
      }

      viewItems.push(fuseItem);

      // if no next item OR the current item doesn't reference next item, add an AfterItem
      if (!nextFuseItem || nextFuseItem.baseDnaId !== fuseItem.upgradeDnaId) {
        viewItems.push(new AfterItem(fuseItem));
      }
    }

    // if there are no fuses, add an InitialItem
    if (_.isEmpty(viewItems)) {
      viewItems.push(new InitialItem());
    }

    return viewItems;
  }, [blankoIdsToThumbnailUrls, fuses]);

  const getPrimaryCatalogName = useCallback(async () => {
    if (primaryCatalogName)
      return;

    CatalogMigrationService.getCatalogs(config.env)
      .then(catalogs => {
        const primaryCatalog = catalogs.find(c => c.primaryCatalog);
        setPrimaryCatalogName(primaryCatalog?.name);
      })
      .catch(err => pushNotification({ type: 'error', message: `Failed to find primary catalog: ${err.message}` }));
  }, [primaryCatalogName, pushNotification])

  const loadFuses = useCallback(() => {
    if (params) {
      getBlankoRushFuses(params.rushId)
        .then(fuses => setFuses(fuses))
        .catch(err => pushNotification({ type: 'error', message: `Failed to load fuses: ${err.message}` }));
    }
  }, [params, pushNotification])

  const getBlankoImageUrls = useCallback(() => {
    if (!fuses)
      return;

    // create a collection of the blanko definitions that have not yet been loaded
    const blankoIdsToLoad = fuses.reduce<string[]>((blankoIds, fuse) => {
      // if fuse has base DNA and we don't have the thumbnail, add ID to collection
      if (fuse.baseDnaId && !blankoIdsToThumbnailUrls[fuse.baseDnaId]) {
        blankoIds.push(fuse.baseDnaId);
      }

      // if fuse has upgrade DNA and we don't have the thumbnail, add ID to collection
      if (fuse.upgradeDnaId && !blankoIdsToThumbnailUrls[fuse.upgradeDnaId]) {
        blankoIds.push(fuse.upgradeDnaId);
      }

      return blankoIds;
    }, [])

    // if we don't have any blankos to load, we're done
    if (_.isEmpty(blankoIdsToLoad) || !primaryCatalogName)
      return;

    // request all images asynch.  using Promise.allSettled allows for each request
    // to succeed or fail
    const promises: Promise<ItemDefinition>[] = [];
    blankoIdsToLoad.forEach(id => {
      promises.push(ItemDefinitionsService.getItemDefinition(primaryCatalogName, id));
    });
    Promise.allSettled(promises)
      .then(results => {
        const newBlankoIdsToUrls = _.clone(blankoIdsToThumbnailUrls);
        results.forEach((result => {
          if (result.status === 'fulfilled') {
            newBlankoIdsToUrls[result.value.itemId] = result.value.imageThumbnailUrl;
          }
        }));

        // if failed to load any images, display an error
        const rejected = results.filter(r => r.status === 'rejected');
        if (!_.isEmpty(rejected)) {
          pushNotification({ type: 'error', message: 'Failed to load thumbnail' });
        }

        setBlankoIdsToUrls(newBlankoIdsToUrls);
      })
      .catch(err => pushNotification({ type: 'error', message: `Failed to load thumbnails: ${err.message}` }));

  }, [blankoIdsToThumbnailUrls, fuses, primaryCatalogName, pushNotification])

  useEffect(() => {
    // don't request new fuses when showing dialogs
    if (showAddDialog || showEditDialog || showDeleteDialog)
      return;

    loadFuses();
  }, [showAddDialog, showEditDialog, showDeleteDialog, loadFuses])

  useEffect(() => {
    getPrimaryCatalogName();
  }, [getPrimaryCatalogName]);

  useEffect(() => {
    getBlankoImageUrls();

  }, [getBlankoImageUrls])

  const onEdit = useCallback((data: TableViewItem) => {
    // if we have both a base and upgrade DNA, we're editing an existing fuse
    if (data.baseDnaId && data.upgradeDnaId) {
      if (fuses && data.fuseId) {
        const fuse = fuses.find(f => f.id === data.fuseId);
        if (fuse) {
          setEditData({
            baseDnaUrl: data.baseDnaUrl,
            upgradeDnaUrl: data.upgradeDnaUrl,
            ...fuse,
          });
        }
      }
      setShowEditDialog(true);
    } else {
      // We're adding a new Fuse, determine if base or upgrade should be included.
      let baseDnaId = data.baseDnaId;
      let baseDnaUrl = data.baseDnaUrl;
      let upgradeDnaId = data.upgradeDnaId;
      let upgradeDnaUrl = data.upgradeDnaUrl;
      if (tableViewItems) {
        // we know the item will have a base, an upgrade, or neither
        const currentItemIndex = _.indexOf(tableViewItems, data);
        if (currentItemIndex >= 0) {
          if (baseDnaId) {
            // Since we have a base DNA, we know the current item is an AfterItem.
            // See if next time is a BeforeItem so we can populate the upgrade DNA for this add.
            const nextTableItem = tableViewItems[currentItemIndex + 1];
            if (nextTableItem instanceof BeforeItem) {
              upgradeDnaId = nextTableItem.upgradeDnaId;
              upgradeDnaUrl = nextTableItem.upgradeDnaUrl;
            }
          } else {
            // Since we don't have a base DNA, current item is either a BeforeItem or a brand new fuse.
            // If it's a BeforeItem, see if the previous item is an AfterItem so we can
            // populate the base DNA for this add.
            const previousTableItem = tableViewItems[currentItemIndex - 1];
            if (previousTableItem instanceof AfterItem) {
              baseDnaId = previousTableItem.baseDnaId;
              baseDnaUrl = previousTableItem.baseDnaUrl;
            }
          }
        }
      }

      setAddData({
        baseDnaId: baseDnaId || '',
        baseDnaUrl: baseDnaUrl,
        capstone: [],
        id: '',
        maxQuantity: 0,
        remainingQuantity: 0,
        upgradeDnaId: upgradeDnaId || '',
        upgradeDnaUrl: upgradeDnaUrl,
      });
      setShowAddDialog(true);
    }
  }, [fuses, tableViewItems]);

  const onDelete = useCallback((data: TableViewItem) => {
    setShowDeleteDialog(true);
    if (data.fuseId) {
      setFuseId(data.fuseId);
    }
  }, []);

  if (_.isEmpty(tableViewItems)) {
    return <Box textAlign="center">
      <CircularProgress />
    </Box>;
  }

  return(
    <>
      <Box mb={2}>
        <Grid container spacing={2} alignItems="center">
          <Grid item xs>
            <Breadcrumbs separator={<NavigateNext />} aria-label="breadcrumb">
              <Link className={classes.link} component={RouterLink} to="/gem-rush">
                Gem Rush
              </Link>
              <Typography variant="h6" color="textPrimary">Fuses</Typography>
            </Breadcrumbs>
          </Grid>
        </Grid>
      </Box>
      {tableViewItems &&
      // <TableContainer style={{ maxHeight: 440 }}>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell />
              <TableCell>Base DNA</TableCell>
              <TableCell>Upgrade DNA</TableCell>
              <TableCell>Max Quantity</TableCell>
              <TableCell>Remaining Quantity</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {tableViewItems.map((item, index) => (
              <TableRow key={`param-${index}`}>
                <TableCell>
                  {item.canDelete &&
                    <Tooltip title="Delete">
                      <IconButton
                        size="small"
                        onClick={() => onDelete(item)}
                        tabIndex={-1}
                        disabled={!UserService.canDelete('blankos')}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </Tooltip>
                  }
                  {!item.canDelete && index > 0 && index < tableViewItems.length - 1 &&
                    <Tooltip title="Fuses are not connected">
                      <IconButton
                        size="small"
                        onClick={() => onEdit(item)}
                        tabIndex={-1}
                        disabled={!UserService.canCreate('blankos')}
                      >
                        <ErrorIcon color="error" />
                      </IconButton>
                    </Tooltip>
                  }
                </TableCell>
                <TableCell>
                  {item.baseDnaId
                    ? <Tooltip title={item.baseDnaId}>
                      {!item.baseDnaUrl || item.baseDnaUrl === item.baseDnaId
                        ? <Box width={100}>
                          <Link component="button" onClick={() => onEdit(item)} disabled={!UserService.canUpdate('blankos')}>
                            {item.baseDnaId}
                          </Link>
                        </Box>
                        : <Button onClick={() => onEdit(item)} disabled={!UserService.canUpdate('blankos')}>
                          <img src={item.baseDnaUrl} width={64} height={64} alt={item.baseDnaId} />
                        </Button>
                      }
                    </Tooltip>
                    : <Button onClick={() => onEdit(item)} variant="contained" color="primary" disabled={!UserService.canCreate('blankos')}>
                      Add Fuse
                    </Button>
                  }
                </TableCell>
                <TableCell>
                  {item.upgradeDnaId
                    ? <Tooltip title={item.upgradeDnaId}>
                      {!item.upgradeDnaUrl || item.upgradeDnaUrl === item.upgradeDnaId
                        ? <Box width={100}>
                          <Link component="button" onClick={() => onEdit(item)} disabled={!UserService.canUpdate('blankos')}>
                            {item.upgradeDnaId}
                          </Link>
                        </Box>
                        : <Button onClick={() => onEdit(item)} disabled={!UserService.canUpdate('blankos')}>
                          <img src={item.upgradeDnaUrl} width={64} height={64} alt={item.upgradeDnaId} />
                        </Button>
                      }
                    </Tooltip>
                    : item.baseDnaId &&
                      <Button onClick={() => onEdit(item)} variant="contained" color="primary" disabled={!UserService.canCreate('blankos')}>
                        Add Fuse
                      </Button>
                  }
                </TableCell>
                <TableCell>
                  {item.maxQuantity}
                </TableCell>
                <TableCell>
                  {item.remainingQuantity}
                </TableCell>
              </TableRow>
              ))}
          </TableBody>
        </Table>
        // </TableContainer>
      }
      {showAddDialog && addData &&
        <AddFuseDialog
          baseDnaId={addData.baseDnaId}
          baseDnaUrl={addData.baseDnaUrl}
          rushId={params.rushId}
          upgradeDnaId={addData.upgradeDnaId}
          upgradeDnaUrl={addData.upgradeDnaUrl}
          show={showAddDialog} onClose={handleClose}
        />
      }
      {showEditDialog && editData &&
        <EditFuseDialog formFuse={editData} show={showEditDialog} onClose={handleClose} />
      }
      {showDeleteDialog &&
        <DeleteFuseDialog fuse={fuseId} show={showDeleteDialog} onClose={handleClose} />
      }
    </>
  );
}

import { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { cmSetMigrationActions } from '../../../../redux/catalog-migration/actions';
import type { MigrationActions } from '../../../../redux/catalog-migration/reducer';
import { useTypedSelector } from '../../../../redux/reducers';
import { BlankoAssetDiff } from '../../types';
import { BlankoAssets } from './BlankoAssets';

export const BlankoAssetsContainer = () => {
  const dispatch = useDispatch();

  const sourceAssets = useTypedSelector(state => state.catalogMigration.source.blankoAssets);
  const targetAssets = useTypedSelector(state => state.catalogMigration.target.blankoAssets);
  const actions = useTypedSelector(state => state.catalogMigration.actions.blankoAssets);

  const [sourceItems, setSourceItems] = useState<BlankoAssetDiff[] | null>(null);
  const [targetItems, setTargetItems] = useState<BlankoAssetDiff[] | null>(null);

  const onSelectionChange = useCallback(
    (actions: MigrationActions) => dispatch(cmSetMigrationActions({ id: 'blankoAssets', actions })),
    [dispatch]
  );

  useEffect(() => {
    if (sourceAssets && targetAssets) {
      setSourceItems(sourceAssets.map(asset => new BlankoAssetDiff(asset)));
      setTargetItems(targetAssets.map(asset => new BlankoAssetDiff(asset)));
    }
  }, [sourceAssets, targetAssets]);

  return (<BlankoAssets
    sourceItems={sourceItems}
    targetItems={targetItems}
    actions={actions}
    onSelectionChange={onSelectionChange} />);
}
import { Box, Button, Divider, Accordion, AccordionDetails, AccordionSummary, FormControl, FormGroup, FormLabel, Grid, Typography, Card, CardContent, CardHeader, IconButton, Tooltip } from '@material-ui/core';
import { CheckboxWithLabel, TextField } from 'formik-material-ui';
import { Field, FieldArray, useFormikContext } from 'formik';
import { useState, useCallback } from 'react';
import DeleteIcon from '@material-ui/icons/Delete';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { useTypedSelector } from '../../redux/reducers';
import { blankoAttachmentSlots, blankoEmoteSlots, noSkillDropTableItem } from '../../services/model/blanko';
import BlankoAbilityForm from './BlankoAbilityForm';
import type { LevelItemGrant } from './BlankoLevelItemGrantsForm';
import { BlankoLevelItemGrantsForm } from './BlankoLevelItemGrantsForm';
import type { CurrenciesFormValues } from './CurrenciesForm';
import CurrenciesForm from './CurrenciesForm';
import ConfirmationDialog from '../../components/ConfirmationDialog';
import type { DefaultAttachment } from './BlankoLevelDefaultAttachmentsForm';
import { BlankoLevelDefaultAttachmentsForm } from './BlankoLevelDefaultAttachmentsForm';

export interface LevelFormValues {
  attachmentSlots: string[];
  emoteSlots: string[];
  skillPerkSlots: string;
  neutralPerkSlots: string;
  randomNeutralPerks: string;
  personalityAddons: string;
  abilities: { type: string, [key: string]: string }[];
  currency: CurrenciesFormValues;
  items: LevelItemGrant[];
  xpToNextLevel: string;
  defaultAttachments: DefaultAttachment[];
  defaultEmotes: LevelItemGrant[];
}

interface Props {
  formName: string;
  level: LevelFormValues;
  isFirstTier: boolean;
  isFirst: boolean;
  index: number;
  skillDropTableId: string;
  onRemove: () => void;
}

const BlankoLevelEditor = (props: Props) => {
  const [expanded, setExpanded] = useState(false);
  const { onRemove } = props
  const blankoClasses = useTypedSelector(state => state.blankoProgressions.classes.data);
  const [showDeleteDialog, setShowDeleteDialog] = useState(false)
  const form = useFormikContext();
  const selectedClasses = (form.values as any).classes as string[];

  const createNewAbilityForm = () => {
    const values: { type: string, [key: string]: string } = { type: 'abilities' };
    blankoClasses.forEach(c => values[c] = '');
    return values;
  }

  const canGrantItems = !(props.isFirstTier && props.isFirst);
  const onDelete = useCallback(() => {
    setShowDeleteDialog(true);
  }, [])
  const handleClose = useCallback(() => {
    setShowDeleteDialog(false);
  }, [])
  return (
    <>
      {showDeleteDialog &&
        <ConfirmationDialog
          title={`Delete Level ${props.index + 1}`}
          details={`Are you sure you want to delete Level ${props.index + 1} configuration?`}
          open={showDeleteDialog}
          onClose={handleClose}
          onConfirm={onRemove}
        />}
      <Accordion expanded={expanded} onChange={(event, expanded) => setExpanded(expanded)} TransitionProps={{ unmountOnExit: true }}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Box display="flex" flexDirection="row" justifyContent="flex-end">
            <Box mr={2}>
              <Tooltip title="Delete">
                <IconButton
                  size="small"
                  onClick={onDelete}
                >
                  <DeleteIcon />
                </IconButton>
              </Tooltip>
            </Box>
            <Typography variant="h5">
              {`Level ${props.index + 1}`}
            </Typography>
          </Box>
        </AccordionSummary>
        <AccordionDetails style={{ display: 'block' }}>
          <Box mb={2}>
            <Field
              component={TextField}
              name={`${props.formName}.xpToNextLevel`}
              type="string"
              label="XP to next Level"
              required
            />
          </Box>
          <Grid container spacing={1}>
            <Grid item xs={12} lg={6}>
              <Card variant="outlined">
                <CardHeader title="Slots" />
                <CardContent>
                  <Grid container spacing={1}>
                    <Grid item xs={12}>
                      <FormControl fullWidth>
                        <FormLabel>Attachment slots</FormLabel>
                        <FormGroup row>
                          {blankoAttachmentSlots.map(slot => (
                            <Field
                              key={slot}
                              component={CheckboxWithLabel}
                              type="checkbox"
                              name={`${props.formName}.attachmentSlots`}
                              value={slot}
                              Label={{ label: slot }}
                            />
                          ))}
                        </FormGroup>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                      <FormControl fullWidth>
                        <FormLabel>Emote slots</FormLabel>
                        <FormGroup row>
                          {blankoEmoteSlots.map(slot => (
                            <Field
                              key={slot}
                              component={CheckboxWithLabel}
                              type="checkbox"
                              name={`${props.formName}.emoteSlots`}
                              value={slot}
                              Label={{ label: slot }}
                            />
                          ))}
                        </FormGroup>
                      </FormControl>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12} lg={6}>
              <Card variant="outlined">
                <CardHeader title="Currency rewards" />
                <CardContent>
                  <CurrenciesForm
                    formName={`${props.formName}.currency`}
                    value={props.level.currency}
                  />
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12} xl={6}>
              <FieldArray name={`${props.formName}.defaultAttachments`}>
                {({ remove, push }) => (
                  <BlankoLevelDefaultAttachmentsForm
                    formName={`${props.formName}.defaultAttachments`}
                    attachments={props.level.defaultAttachments}
                    onAdd={push}
                    onRemove={remove}
                  />
                )}
              </FieldArray>
            </Grid>
            <Grid item xs={12} xl={6}>
              <FieldArray name={`${props.formName}.defaultEmotes`}>
                {({ remove, push }) => (
                  <BlankoLevelItemGrantsForm
                    formName={`${props.formName}.defaultEmotes`}
                    items={props.level.defaultEmotes}
                    title="Default emotes"
                    onAdd={push}
                    onRemove={remove}
                  />
                )}
              </FieldArray>
            </Grid>
            <Grid item xs={12} xl={6}>
              <Card variant="outlined">
                <CardHeader title="Skills and perks" />
                <CardContent>
                  <Grid container spacing={1}>
                    <Grid item xs={12} lg={4}>
                      <Field
                        component={TextField}
                        name={`${props.formName}.skillPerkSlots`}
                        type="text"
                        label="Power perk slots"
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12} lg={4}>
                      <Field
                        component={TextField}
                        name={`${props.formName}.neutralPerkSlots`}
                        type="text"
                        label="Foundation perk slots"
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12} lg={4}>
                      <Field
                        component={TextField}
                        name={`${props.formName}.randomNeutralPerks`}
                        type="text"
                        label="Hidden foundation perks"
                        fullWidth
                        disabled={props.skillDropTableId === noSkillDropTableItem}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Field
                        component={TextField}
                        name={`${props.formName}.personalityAddons`}
                        type="text"
                        label="Personality addons (comma-separated list)"
                        fullWidth
                      />
                    </Grid>
                  </Grid>

                  {selectedClasses?.length > 0 && (
                    <FieldArray name={`${props.formName}.abilities`}>
                      {({ remove, push }) => (<>
                        {props.level?.abilities?.map((ability, index) => (
                          <Box mt={2} key={index}>
                            <BlankoAbilityForm
                              formName={`${props.formName}.abilities[${index}]`}
                              classes={selectedClasses}
                              type={ability.type}
                              onRemove={() => remove(index)}
                            />
                            <Box mt={2}>
                              <Divider />
                            </Box>
                          </Box>
                        ))}

                        <Box mt={2}>
                          <Button color="primary" variant="contained" onClick={() => push(createNewAbilityForm())}>
                            Add skill or perk
                          </Button>
                        </Box>
                      </>)}
                    </FieldArray>
                  )}

                  {selectedClasses.length < 1 && (
                    <Box mt={2}>
                      <Typography color="textSecondary">
                        Cannot grant skills or perks. No blanko classes selected.
                      </Typography>
                    </Box>
                  )}
                </CardContent>
              </Card>
            </Grid>
            {canGrantItems && (
              <Grid item xs={12} xl={6}>
                <FieldArray name={`${props.formName}.items`}>
                  {({ remove, push }) => (
                    <BlankoLevelItemGrantsForm
                      formName={`${props.formName}.items`}
                      items={props.level.items}
                      title="Items"
                      enableQuantity
                      enableCatalogName
                      onAdd={push}
                      onRemove={remove}
                    />
                  )}
                </FieldArray>
              </Grid>
            )}
          </Grid>
        </AccordionDetails>
      </Accordion>
    </>
  )
}

export default BlankoLevelEditor;
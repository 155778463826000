import type {RootState} from "../../../redux/reducers";
import type { ConnectedProps} from "react-redux";
import {connect} from "react-redux";
import type { SyntheticEvent} from "react";
import { Component, Fragment } from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import {createSeasonChallengeScheduleCalendarAsync, updateSeasonChallengeScheduleCalendarAsync} from "../../../redux/player-challenges/season-challenge-schedule-calendar/actions";
import {Challenge} from '../../../services/player-challenges/challenges';
import { Box, Button, Checkbox, DialogActions, FormControlLabel, Grid, InputLabel, TextField, Typography } from "@material-ui/core";
import {ScheduleCalendar, ScheduleCalendarTypeEnum} from "../../../services/player-challenges/season-challenge-schedule-calendar";
import GlobalChallengeSelector from "../GlobalChallengeSelector";
import {Reward} from '../../../services/player-challenges/season-reward-path-tiers';
import RewardControl from "../../../shared/components/RewardControl";
import {generateUUID} from "../../../utils/uuid";
import _ from "lodash";

type OwnProps = {
  onClose: () => void;
  seasonNumber: number;
  scheduleId: string;
  challenges: Challenge[];
  instanceToEdit?: ScheduleCalendar;
  challengeType: ScheduleCalendarTypeEnum;
}
type Props = OwnProps & ConnectedProps<typeof connector>;

const mapStateToProps = (state: RootState, props: OwnProps) => ({
  seasonChallengeSchedule: state.seasonChallengeSchedule,
  isAddingChallenge: !props.instanceToEdit
});

const mapDispatch = {
  requestUpdateChallengeSchedule: updateSeasonChallengeScheduleCalendarAsync.request,
  requestCreateChallengeSchedule: createSeasonChallengeScheduleCalendarAsync.request
};
const connector = connect(mapStateToProps, mapDispatch);

interface State {
  open: boolean;
  instanceToEdit: ScheduleCalendar;
  globalChallengeSelectorDialogOpen: boolean;
}

class AddEditChallengeScheduleDialog extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      instanceToEdit: props.instanceToEdit ? props.instanceToEdit.clone() : new ScheduleCalendar(props.seasonNumber, props.scheduleId),
      open: true,
      globalChallengeSelectorDialogOpen: false
    };
  }

  onDialogClose = () => {
    this.props.onClose();
  };

  handleSubmit = (event: SyntheticEvent) => {
    const instanceToEdit = this.state.instanceToEdit;
    if (!this.props.instanceToEdit) {
      instanceToEdit.season_number = this.props.seasonNumber;
      instanceToEdit.schedule_id = this.props.scheduleId;
      instanceToEdit.calendar_type = this.props.challengeType;
      instanceToEdit.instance_id = generateUUID();
      this.props.requestCreateChallengeSchedule({
        seasonNumber: this.props.seasonNumber,
        scheduleId: this.props.scheduleId,
        scheduleCalendarJson: instanceToEdit.toJson()
      });
    } else {
      if (instanceToEdit.calendar_type === ScheduleCalendarTypeEnum.UNKNOWN) {
        throw new Error("UNKNOWN ScheduleCalendarTypeEnum is invalid.");
      }
      this.props.requestUpdateChallengeSchedule({
        seasonNumber: this.props.seasonNumber,
        scheduleId: this.props.scheduleId,
        instanceId: instanceToEdit.instance_id,
        scheduleCalendar: instanceToEdit
      });
    }
    this.onDialogClose();
  };

  handleCancel = (event: SyntheticEvent) => {
    event.preventDefault();
    this.onDialogClose();
  }

  render() {
    const instanceToEdit = this.state.instanceToEdit;
    const rewardsToEdit = this.state.instanceToEdit.rewards;
    return (
      <Dialog
        open={this.state.open}
        onClose={this.onDialogClose}
        fullWidth
        maxWidth="sm"
      >
        <form onSubmit={this.handleSubmit}>
          <DialogTitle>{(`${this.props.isAddingChallenge ? 'Add' : 'Edit'} ${_.startCase(_.toLower(this.props.challengeType))} Challenge Schedule`)}</DialogTitle>
          <DialogContent>
            {(this.props.challengeType === ScheduleCalendarTypeEnum.WEEKLY || this.props.challengeType === ScheduleCalendarTypeEnum.DAILY)
              && <Box pb={3}>
                <TextField
                  type="number"
                  inputMode="numeric"
                  fullWidth
                  label={`${this.props.challengeType === ScheduleCalendarTypeEnum.WEEKLY ? 'Week' : 'Day'} Number`}
                  value={instanceToEdit.calendar_number || ''}
                  onChange={event => {
                    instanceToEdit.calendar_number = parseInt(event.target.value);
                    this.setState({instanceToEdit});
                  }}
                />
              </Box>
            }

            <Box pb={3}>
              <TextField
                type="number"
                inputMode="numeric"
                fullWidth
                label={'Sort Order'}
                value={instanceToEdit.sort_order || 0}
                onChange={event => {
                  instanceToEdit.sort_order = parseInt(event.target.value);
                  this.setState({instanceToEdit});
                }}
              />
            </Box>

            <Box pb={1}>
              <InputLabel id="challengeInput">Global Challenge</InputLabel>
            </Box>

            <Box pb={1}>
              {this.state.instanceToEdit && this.state.instanceToEdit.challenge_id && (
                <Typography>
                  {(this.props.challenges.find(o => o.challenge_id === this.state.instanceToEdit.challenge_id) || new Challenge()).challenge_name}
                </Typography>
              )}
            </Box>

            <Box display="flex" flexDirection="column" justifyContent="center">
              <Button type="button" color="secondary" size="small" variant="text"
                onClick={() => this.setState({globalChallengeSelectorDialogOpen: true})}>
                {this.state.instanceToEdit.challenge_id ? "Change Global Challenge" : "Select Global Challenge"}
              </Button>
            </Box>

            <Box pb={1}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={instanceToEdit.party_pass_plus_only}
                    onChange={event => {
                      instanceToEdit.party_pass_plus_only = event.target.checked;
                      this.setState({instanceToEdit});
                    }}
                    name="partyPassPlusOnly"
                    color="primary"
                  />
                }
                label="Party Pass Plus Only"
              />
            </Box>

            <Box pb={1}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={instanceToEdit.resettable_progress}
                    onChange={event => {
                      instanceToEdit.resettable_progress = event.target.checked;
                      this.setState({instanceToEdit});
                    }}
                    name="resettableProgress"
                    color="primary"
                  />
                }
                label="Resettable Progress"
              />
            </Box>

            <Box pb={1}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={instanceToEdit.special}
                    onChange={event => {
                      instanceToEdit.special = event.target.checked;
                      this.setState({instanceToEdit});
                    }}
                    name="special"
                    color="primary"
                  />
                }
                label="Special"
              />
            </Box>

            <Box pb={1}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={instanceToEdit.community_challenge}
                    onChange={event => {
                      instanceToEdit.community_challenge = event.target.checked;
                      this.setState({instanceToEdit});
                    }}
                    name="communityChallenge"
                    color="primary"
                  />
                }
                label="Community Challenge"
              />
            </Box>

            <Box pb={1}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={instanceToEdit.manual_completion}
                    onChange={event => {
                      instanceToEdit.manual_completion = event.target.checked;
                      this.setState({instanceToEdit});
                    }}
                    name="manualCompletion"
                    color="primary"
                  />
                }
                label="Manual Completion"
              />
            </Box>

            <Box pb={1}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={instanceToEdit.live_event_challenge}
                    onChange={event => {
                      instanceToEdit.live_event_challenge = event.target.checked;
                      this.setState({instanceToEdit});
                    }}
                    name="liveEventChallenge"
                    color="primary"
                  />
                }
                label="Live Event Challenge"
              />
            </Box>

            <Box pb={1}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={instanceToEdit.using_live_event_image}
                    onChange={event => {
                      instanceToEdit.using_live_event_image = event.target.checked;
                      this.setState({instanceToEdit});
                    }}
                    name="usingLiveEventImage"
                    color="primary"
                  />
                }
                label="Use Live Event Image"
              />
            </Box>

            <Box pb={3}>
              <TextField
                fullWidth
                multiline={true}
                label="Challenge Name"
                value={instanceToEdit.challenge_name}
                onChange={event => {
                  instanceToEdit.challenge_name = event.target.value;
                  this.setState({instanceToEdit});
                }}
              />
            </Box>

            <Box pb={3}>
              <TextField
                fullWidth
                multiline={true}
                label="Challenge Description"
                value={instanceToEdit.challenge_desc}
                onChange={event => {
                  instanceToEdit.challenge_desc = event.target.value;
                  this.setState({instanceToEdit});
                }}
              />
            </Box>

            <Box pb={3}>
              <TextField
                type="number"
                inputMode="numeric"
                fullWidth
                label="Goal Amount"
                value={instanceToEdit.goal_amount || ''}
                onChange={event => {
                  instanceToEdit.goal_amount = parseInt(event.target.value);
                  this.setState({instanceToEdit});
                }}
              />
            </Box>

            <Box pb={1}>
              <InputLabel id="rewardsInput">Rewards</InputLabel>
            </Box>

            <Box display="flex" flexDirection="column" justifyContent="center">
              {this.state.instanceToEdit.rewards.length === 0 ? <Grid item></Grid> : null}
              {this.state.instanceToEdit.rewards.map((reward: Reward, index: number) => (
                <Fragment key={index}>
                  <Grid container>
                    <Box width="100%" pb={1}>
                      <RewardControl reward={reward} onRewardDelete={() => {
                            const allRewards = this.state.instanceToEdit.rewards;
                            allRewards.splice(index, 1)
                            instanceToEdit.rewards = allRewards;
                            this.setState({instanceToEdit: instanceToEdit});
                      }}/>
                    </Box>
                  </Grid>
                </Fragment>
                  ))}
              <Button type="button" color="secondary" size="small" variant="text"
                onClick={() => {
                          rewardsToEdit.push(new Reward());
                          this.setState({instanceToEdit: instanceToEdit});
                }}>
                Add Reward
              </Button>
            </Box>

            {this.state.globalChallengeSelectorDialogOpen && (
              <GlobalChallengeSelector
                onSelect={(challengeSelected) => {
                  instanceToEdit.challenge_id = challengeSelected.challenge_id;
                  if (!instanceToEdit.instance_id) {
                    instanceToEdit.rewards = challengeSelected.rewards.map(r => r.clone());
                    instanceToEdit.party_pass_plus_only = challengeSelected.party_pass_plus_only;
                    instanceToEdit.resettable_progress = challengeSelected.resettable_progress;
                    instanceToEdit.special = challengeSelected.special;
                    instanceToEdit.challenge_name = challengeSelected.challenge_name;
                    instanceToEdit.challenge_desc = challengeSelected.challenge_desc;
                    instanceToEdit.goal_amount = parseInt(challengeSelected?.parameters?.find(c=>c.name==='GoalAmount')?.value || '');
                  }
                  this.setState({instanceToEdit});
                }}
                onClose={() => this.setState({globalChallengeSelectorDialogOpen: false})}
              />
            )}

            { this.props.challengeType === ScheduleCalendarTypeEnum.SEASONAL &&
              <Fragment>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={instanceToEdit.is_limited}
                      onChange={(e) => {
                        instanceToEdit.is_limited = e.target.checked;
                        this.setState({instanceToEdit});
                      }}
                      name="isLimited"
                      color="primary"
                    />
                  }
                  label="Is Limited"
                />

                <Box pb={3}>
                  <TextField
                    type="number"
                    inputMode="numeric"
                    fullWidth
                    label="Limited Start"
                    value={instanceToEdit.limited_start || ''}
                    onChange={event => {
                      instanceToEdit.limited_start = parseInt(event.target.value);
                      this.setState({instanceToEdit});
                    }}
                  />
                </Box>

                <Box pb={3}>
                  <TextField
                    type="number"
                    inputMode="numeric"
                    fullWidth
                    label="Limited End"
                    value={instanceToEdit.limited_end || ''}
                    onChange={event => {
                      instanceToEdit.limited_end = parseInt(event.target.value);
                      this.setState({instanceToEdit});
                    }}
                  />
                </Box>
              </Fragment>
            }

          </DialogContent>
          <DialogActions>
            <Button type="submit" color="primary" variant="contained">Save</Button>
            <Button type="button" color="secondary" variant="outlined" onClick={this.handleCancel}>Cancel</Button>
          </DialogActions>
        </form>
      </Dialog>
    );
  }
}

const component = connector(AddEditChallengeScheduleDialog);
export { component as AddEditChallengeScheduleDialog };

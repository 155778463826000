import { Component } from 'react';
import { setCatalogName } from '../../redux/catalog/actions';
import { addCatalogDropTableAsync, getCatalogDropTableAsync, updateCatalogDropTableAsync } from '../../redux/catalog/drop-tables/actions';
import type { RootState } from '../../redux/reducers';
import type { ConnectedProps } from 'react-redux';
import { connect } from 'react-redux';
import type { RouteComponentProps } from 'react-router';
import { Box, Typography, CircularProgress } from '@material-ui/core';
import type { DropTable } from '../../services/drop-tables';
import DropTableForm from './DropTableForm';
import { UserService } from '../../services/user';

const mapStateToProps = (state: RootState) => ({ dropTable: state.catalog.dropTables.dropTable });
const mapDispatch = {
  setCatalogName,
  requestDropTable: getCatalogDropTableAsync.request,
  requestAddDropTable: addCatalogDropTableAsync.request,
  requestUpdateDropTable: updateCatalogDropTableAsync.request
}
const connector = connect(mapStateToProps, mapDispatch);

interface RouteProps {
  catalogName: string;
  dropTableId?: string;
}

type Props = RouteComponentProps<RouteProps> & ConnectedProps<typeof connector>;

class DropTablePage extends Component<Props> {
  componentDidMount() {
    this.props.setCatalogName(this.props.match.params.catalogName);
    if (this.props.match.params.dropTableId) {
      this.props.requestDropTable(this.props.match.params.dropTableId);
    }
  }

  componentDidUpdate() {
    if (!this.isEditing() && this.props.dropTable.data) {
      this.props.history.replace(`/catalogs/${this.props.dropTable.data.catalogName}/drop-tables/${this.props.dropTable.data.id}`);
    }
  }

  private isEditing() {
    return !!this.props.match.params.dropTableId;
  }

  getTitle() {
    if (this.isEditing()) {
      if (!this.props.dropTable.data) {
        return '';
      }

      if (UserService.canUpdate('catalogItems')) {
        return `Edit drop table ${this.props.dropTable.data.id}`;
      } else {
        return `Drop table ${this.props.dropTable.data.id}`;
      }
    } else {
      return 'Add drop table';
    }
  }

  onFormSave = (dropTable: DropTable) => {
    if (this.isEditing()) {
      this.props.requestUpdateDropTable(dropTable);
    } else {
      this.props.requestAddDropTable(dropTable);
    }
  }

  render() {
    if (this.isEditing() && (this.props.dropTable.isLoading || !this.props.dropTable.data)) {
      return (
        <Box textAlign="center">
          <CircularProgress />
        </Box>
      );
    } else {
      return (<>
        <Box py={2}>
          <Typography variant="h6">
            {this.getTitle()}
          </Typography>
        </Box>
        <DropTableForm
          catalogName={this.props.match.params.catalogName}
          dropTable={this.props.dropTable.data || undefined}
          onSave={this.onFormSave}
        />
      </>);
    }
  }
}

export default connector(DropTablePage);
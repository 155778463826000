import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, InputAdornment, TextField, Tooltip, Typography } from '@material-ui/core';
import FolderIcon from '@material-ui/icons/Folder';
import { useRef, useState } from 'react';

interface Props {
  onClose: () => void;
  onUpload: (file: File) => void;
}

const StoreImageUploadDialog = (props: Props) => {
  const [isOpen, setOpen] = useState(true);
  const [file, setFile] = useState<File | null>(null);
  const inputRef = useRef<HTMLInputElement>(null);

  const onFileChange = () => {
    if (inputRef.current && inputRef.current.files && inputRef.current.files.length > 0) {
      const file = inputRef.current.files[0];
      setFile(file);
    }
  }

  const onUpload = () => {
    if (file) {
      props.onUpload(file);
      setOpen(false);
    }
  }

  return (
    <Dialog
      open={isOpen}
      onClose={() => setOpen(false)}
      onExited={props.onClose}
      fullWidth
      maxWidth="sm"
    >
      <DialogTitle>Upload image</DialogTitle>
      <DialogContent>
        <input
          type="file"
          accept="image/*"
          style={{ display: 'none' }}
          ref={inputRef}
          onChange={onFileChange}
        />
        <TextField
          label="Image file"
          fullWidth
          InputProps={{
            readOnly: true,
            endAdornment: (
              <InputAdornment position="end">
                <Tooltip title="Select file...">
                  <IconButton onClick={() => inputRef.current && inputRef.current.click()}>
                    <FolderIcon />
                  </IconButton>
                </Tooltip>
              </InputAdornment>
            )
          }}
          value={file ? file.name : ''}
        />
        <Box mt={2}>
          <Typography variant="subtitle2">
            Note: You can also drag &amp; drop images on the store tiles to upload them.
          </Typography>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button color="primary" variant="contained" onClick={onUpload}>
          Upload image
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default StoreImageUploadDialog;
/* eslint-disable @typescript-eslint/ban-types */
import Box from '@material-ui/core/Box';
import Divider from '@material-ui/core/Divider';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import type { SvgIconProps } from '@material-ui/core/SvgIcon';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import type { Action, Localization, MaterialTableProps, Options, Query, QueryResult , Column } from 'material-table';
import OriginalMaterialTable from 'material-table';
import * as React from 'react';
import type { RouteComponentProps} from 'react-router';
import {useLocation, withRouter} from 'react-router';
import type { TablePageState } from '../redux/utils-ts';
import { tableIcons } from '../utils/tableIcons';
import { AdminKeyboardDateTimePicker } from "./AdminKeyboardDateTimePicker";
import _ from "lodash";

// Since creating HoCs out of generic components is impossible. Use a simple/hacky component to access route information when needed
class RouterHackWithoutRouter extends React.Component<RouteComponentProps & { onInit: (route: RouteComponentProps) => void }> {
  componentDidMount() {
    this.props.onInit(this.props);
  }

  render() { return null; }
}

interface TableStoredSettings<T extends object> {
  columnOverrides: { [key: string]: Partial<Column<T>> };
}

const RouterHack = withRouter(RouterHackWithoutRouter);

interface State<T extends object> {
  actionMenuAnchor?: HTMLElement;
  actionMenuItem?: T;
  actions?: (Action<T> | ((rowData: T) => Action<T>))[];
  options: Options;
  localization: Localization;
  route?: RouteComponentProps;
}

export interface MenuActionButton<T> {
  type: 'button';
  icon: React.ComponentType<SvgIconProps>;
  label: string;
  disabled?: (item: T) => boolean;
  onClick: (item: T) => void;
}

export type MenuActionDivider = { type: 'divider'; };

export interface MenuActionLink {
  type: 'link';
  icon: React.ComponentType<SvgIconProps>;
  label: string;
  href: string;
}

export type MenuAction<T> = MenuActionButton<T> | MenuActionDivider | MenuActionLink;

type Props<T extends object> = Omit<MaterialTableProps<T>, 'data' | 'onChangeColumnHidden'> & {
  menuActions?: MenuAction<T>[] | ((item: T) => MenuAction<T>[]);
  data: T[] | ((query: Query<T>) => Promise<QueryResult<T>>) | {
    pageState?: TablePageState<any, T> | null;
    runQuery: (query: Query<T>) => void;
  };
  useRouter?: boolean;
  /**
   * If supplied, user table settings will be persisted in local storage
   */
  storageId?: string;
};

export default class MaterialTable<T extends object> extends React.Component<Props<T>, State<T>> {
  private pageSize = 10;
  private tableRef = React.createRef<any>();
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  private tableQueryResolve: (result: QueryResult<T>) => void = () => { };

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  private reduxRunQuery: (query: Query<T>) => void = () => { };
  private reduxGetDataAsync?: (query: Query<T>) => Promise<QueryResult<T>>;

  private currentPage = 0;
  private currentPageAsyncOverwrite = false; // MaterialTable does not support setting initialPage with async data. This will be used to overwrite the initial async query

  private search = '';
  private sort?: { id: number, dir: 'asc' | 'desc' };

  constructor(props: Props<T>) {
    super(props);

    if (this.props.options && this.props.options.pageSize) {
      this.pageSize = this.props.options.pageSize;
    }

    if (this.props.tableRef) {
      this.tableRef = this.props.tableRef;
    }

    this.state = {
      actions: this.buildActions(),
      options: this.buildOptions(),
      localization: this.buildLocalization()
    };
  }

  componentDidUpdate(prevProps: Props<T>) {
    if (prevProps.options !== this.props.options) {
      this.setState({ options: this.buildOptions() });
    }
    if (prevProps.actions !== this.props.actions || prevProps.menuActions !== this.props.menuActions) {
      this.setState({ actions: this.buildActions() });
    }
    if (prevProps.localization !== this.props.localization) {
      this.setState({ localization: this.buildLocalization() });
    }
    if (prevProps.tableRef !== this.props.tableRef) {
      this.tableRef = this.props.tableRef || React.createRef();
    }

    // Redux data/pagination
    if ('runQuery' in this.props.data) {
      const pageState = this.props.data.pageState;
      const prevPageState = 'runQuery' in prevProps.data ? prevProps.data.pageState : undefined;
      if (prevPageState !== pageState) {
        if (pageState && !pageState.isLoading && (!prevPageState || prevPageState.ids !== pageState.ids)) {
          this.tableQueryResolve({
            data: pageState.data,
            page: pageState.page - 1,
            totalCount: pageState.totalCount
          });
        }

        if (pageState && pageState.invalidated && this.tableRef.current) {
          this.tableRef.current.onQueryChange();
        }
      }
    }
  }

  private buildOptions() {
    const options: Options = this.props.options ? { ...this.props.options } : {};
    if (options.draggable === undefined) {
      options.draggable = false;
    }
    options.pageSize = this.pageSize;
    options.initialPage = Math.min(this.currentPage, this.getLastPage());
    options.searchText = this.search;
    return options;
  }

  private buildActions() {
    const actions = this.props.actions || [];
    if (this.props.menuActions) {
      const menuAction: Action<T> = {
        icon: () => <MoreVertIcon />,
        onClick: (event, item) => {
          if (event.currentTarget instanceof HTMLElement && !Array.isArray(item)) {
            this.setState({ actionMenuAnchor: event.currentTarget, actionMenuItem: item });
          }
        }
      };

      if (Array.isArray(this.props.menuActions)) {
        if (this.props.menuActions.length > 0) {
          actions.push(menuAction);
        }
      } else {
        const menuActionsFn = this.props.menuActions;
        actions.push((item: T) => {
          menuAction.hidden = menuActionsFn(item).length < 1;
          return menuAction;
        });
      }
    }
    return actions.length ? actions : undefined;
  }

  private buildLocalization() {
    const localization: Localization = this.props.localization || {};
    if (!localization.header || !localization.header.actions) {
      localization.header = { ...localization.header, actions: '' };
    }
    return localization;
  }

  handleMenuItemClick = (item: T, action: MenuActionButton<T>) => {
    this.setState({ actionMenuAnchor: undefined, actionMenuItem: undefined });
    action.onClick(item);
  };

  handleChangePage = (page: number) => {
    this.currentPage = page;
    if (this.props.onChangePage) {
      this.props.onChangePage(page);
    }

    this.updateRouteQueryParams();
  }

  handleChangeRowsPerPage = (pageSize: number) => {
    this.pageSize = pageSize;
    this.setState({ options: this.buildOptions() });
    if (this.props.onChangeRowsPerPage) {
      this.props.onChangeRowsPerPage(pageSize);
    }

    this.updateRouteQueryParams();
  };

  handleSearchChange = (search: string) => {
    this.search = search;
    if (this.props.onSearchChange) {
      this.props.onSearchChange(search);
    }

    // Searching can change the current page, another detail MaterialTable fails to expose properly...
    // Luckily, this is javascript...
    if (this.tableRef.current && this.tableRef.current.dataManager) {
      this.currentPage = this.tableRef.current.dataManager.currentPage;
    }

    this.updateRouteQueryParams();
  }

  handleOrderChange = (id: number, dir: 'asc' | 'desc') => {
    if (id > -1) {
      this.sort = { id, dir };
    } else {
      this.sort = undefined;
    }

    if (this.props.onOrderChange) {
      this.props.onOrderChange(id, dir);
    }

    // Changing sort will update the page but MaterialTable doesn't notify us...
    if (this.tableRef.current && this.tableRef.current.dataManager) {
      this.currentPage = this.tableRef.current.dataManager.currentPage;
    }

    this.updateRouteQueryParams();
  }

  getTableData = () => {
    if ('runQuery' in this.props.data) {
      if (this.props.data.runQuery !== this.reduxRunQuery || !this.reduxGetDataAsync) {
        this.reduxRunQuery = this.props.data.runQuery;
        this.currentPageAsyncOverwrite = true;
        this.reduxGetDataAsync = (query: Query<T>) => {
          return new Promise<QueryResult<T>>(resolve => {
            this.tableQueryResolve = resolve;
            if (this.currentPageAsyncOverwrite) {
              this.currentPageAsyncOverwrite = false;
              query.page = this.currentPage;
            }

            // When using async data, MaterialTable's onSearchChange doesn't fire *facepalm*
            this.handleSearchChange(query.search);

            this.reduxRunQuery(query);
          });
        };
      }

      return this.reduxGetDataAsync;
    } else {
      return this.props.data;
    }
  };

  renderMenuItems(item: T) {
    if (this.props.menuActions) {
      const actions = typeof this.props.menuActions === 'function' ? this.props.menuActions(item) : this.props.menuActions;
      return actions.map((action, index) => this.renderMenuItem(item, action, index));
    }
  }

  renderMenuItem = (item: T, action: MenuAction<T>, key: number) => {
    switch (action.type) {
    case 'button':
      return (
        <MenuItem
          key={key}
          onClick={() => this.handleMenuItemClick(item, action)}
          disabled={action.disabled && action.disabled(item)}
        >
          <ListItemIcon>
            <action.icon fontSize="small" />
          </ListItemIcon>
          {action.label}
        </MenuItem>
      );
    case 'divider':
      return (
        <Box my={1} key={key}>
          <Divider />
        </Box>
      );
    case 'link':
      return (
        <MenuItem key={key} component="a" href={action.href}>
          <ListItemIcon>
            <action.icon fontSize="small" />
          </ListItemIcon>
          {action.label}
        </MenuItem>
      );
    }
  };

  onInitRoute = (route: RouteComponentProps) => {

    const query = new URLSearchParams(route.location.search);
    this.currentPage = (parseInt(query.get('page') || '1') || 1) - 1;
    this.search = query.get('search') || '';

    const pageSize = parseInt(query.get('pageSize') || '');
    if (!isNaN(pageSize)) {
      this.pageSize = pageSize;
    }

    const sortId = parseInt(query.get('sortId') || '');
    const sortDir = query.get('sortDir');
    if (!isNaN(sortId) && (sortDir === 'asc' || sortDir === 'desc')) {
      this.sort = { id: sortId, dir: sortDir };

      this.props.columns.forEach((column, index) => {
        column.defaultSort = this.sort && index === this.sort.id ? this.sort.dir : undefined;
      });
    }

    this.setState({
      actions: this.buildActions(),
      options: this.buildOptions(),
      localization: this.buildLocalization(),
      route
    });
  }

  private updateRouteQueryParams() {
    if (this.state.route) {
      const params = new URLSearchParams();
      params.set('page', (this.currentPage + 1).toString());
      params.set('pageSize', this.pageSize.toString());

      if (this.search) {
        params.set('search', this.search);
      }

      if (this.sort) {
        params.set('sortId', this.sort.id.toString());
        params.set('sortDir', this.sort.dir);
      }

      this.state.route.history.replace(`${this.state.route.location.pathname}?${params.toString()}`);
    }
  }

  private getLastPage(): number {
    let rows = 0;
    if (Array.isArray(this.props.data)) {
      rows = this.props.data.length;
    }

    return Math.max(Math.ceil(rows / this.pageSize) - 1, 0);
  }

  private getTableStoredSettings(): TableStoredSettings<T> {
    if (this.props.storageId) {
      const stored = window.localStorage.getItem(`material-table-${this.props.storageId}`);
      if (stored) {
        return JSON.parse(stored);
      }
    }
    return {columnOverrides: {}};
  }

  private storeTableStoredSettings(settings: TableStoredSettings<T>) {
    if (this.props.storageId) {
      window.localStorage.setItem(`material-table-${this.props.storageId}`, JSON.stringify(settings));
    }
  }

  processColumns = (columns: Column<T>[]): Column<T>[] => {
    const tableStoredSettings = this.getTableStoredSettings();
    return columns.map(column => _.cloneDeep(column))
      .map(column => {
        if (column.type === 'datetime') {
          column.editComponent = ({ onChange, value }) =>
            (<AdminKeyboardDateTimePicker onChange={onChange} value={value} />);
        }
        // While 'title' is perhaps less likely to be unique than 'field', 'title' is more commonly present, so is a better key
        if (typeof column.title === 'string' && tableStoredSettings.columnOverrides[column.title]) {
          Object.assign(column, tableStoredSettings.columnOverrides[column.title]);
        }
        return column;
      });
  };

  onChangeColumnHidden = (column: Column<T>, hidden: boolean) => {
    if (typeof column.title === 'string') {
      const tableStoredSettings = this.getTableStoredSettings();
      if (!tableStoredSettings.columnOverrides[column.title]) {
        tableStoredSettings.columnOverrides[column.title] = {};
      }
      tableStoredSettings.columnOverrides[column.title].hidden = hidden;
      this.storeTableStoredSettings(tableStoredSettings);
    }
  };

  render() {
    return (<>
      {(!this.props.useRouter || this.state.route) && (
        <OriginalMaterialTable<T>
          {...this.props}
          columns={this.processColumns(this.props.columns)}
          data={this.getTableData()}
          icons={tableIcons}
          actions={this.state.actions}
          options={this.state.options}
          onChangePage={this.handleChangePage}
          onChangeRowsPerPage={this.handleChangeRowsPerPage}
          onSearchChange={this.handleSearchChange}
          onOrderChange={this.handleOrderChange}
          onChangeColumnHidden={this.onChangeColumnHidden}
          localization={this.state.localization}
          tableRef={this.tableRef}
        />
      )}

      {this.props.menuActions && this.state.actionMenuAnchor && this.state.actionMenuItem && (
        <Menu onClose={() => this.setState({ actionMenuAnchor: undefined, actionMenuItem: undefined })} anchorEl={this.state.actionMenuAnchor} open>
          {this.renderMenuItems(this.state.actionMenuItem)}
        </Menu>
      )}

      {this.props.useRouter && <RouterHack onInit={this.onInitRoute} />}
    </>);
  }
}

import type { Column, Query, QueryResult } from 'material-table';
import { paginatedRequestFromQuery } from '../services/model/pagination';
import type { Player } from '../features/players/players';
import { PlayersService } from '../features/players/players';
import MaterialTable from './MaterialTable';
import { useCallback } from 'react';

type Props = {
  filterPlayers?: (player: Player) => boolean;
  onSelect: (player: Player) => void;
  title?: string;
};

export const PlayerSelectList = ({ filterPlayers, onSelect, title }: Props) => {

  const getPlayersPage = useCallback(async (query: Query<Player>): Promise<QueryResult<Player>> => {
    return new Promise(resolve => {
      const request = paginatedRequestFromQuery(query);
      request.search = query.search.trim();
      request.name = request.search;

      const getPlayers = PlayersService.getPlayers(request);
      getPlayers.then(result => {
        resolve({
          data: result.items,
          page: request.page - 1,
          totalCount: result.totalCount
        });
      });
    });
  }, []);

  const columns: Column<Player>[] = [
    {
      title: 'Name',
      field: 'displayName',
      render: player => (<>
        {player.displayName || player.email || player.externalId}
      </>)
    },
    { title: 'ID', field: 'externalId' },
    { title: 'Mythical ID', field: 'mythicalId', sorting: false },
    { title: 'Last logged in', field: 'lastLogin', defaultSort: 'desc', render: player => <>{player.lastLogin ? player.lastLogin.toLocaleString() : '-'}</> }
  ];

  return (
    <MaterialTable
      title={title || 'Select player'}
      columns={columns}
      data={getPlayersPage}
      options={{
        pageSize: 10,
        debounceInterval: 500,
        thirdSortClick: false
      }}
      onRowClick={(event, player) => player && (!filterPlayers || filterPlayers(player)) && onSelect(player)}
    />
  )
}
import { DiffItem } from "../../../../pages/CatalogMigration/types";
import { ChallengeTrigger } from "../../../../services/player-challenges/challenge-templates";
import { MigrationType } from "../migration-type";

export class ChallengeTriggerDiff extends DiffItem {
  public name: string;

  constructor(trigger: ChallengeTrigger) {
    super();
    this.diffId = `${trigger.trigger_type}:${trigger.version}`;
    this.name = `${trigger.trigger_type}:${trigger.version}`;
    this.dependencyItem = {
      type: MigrationType.challengeTriggers,
      id: `${trigger.trigger_type}:${trigger.version}`,
    };
    // this excludes all id properties
    this.diffJson = JSON.stringify(trigger, (key, val) => key === 'id' ? undefined : val, 2);
  }
}

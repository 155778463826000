import { useCallback, useMemo, useState } from "react";
import {
  Box,
  Button,
  Grid,
  Link} from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import type { Column } from "material-table";
import MaterialTable from "../../../components/MaterialTable";
import type { MenuAction } from "../../../components/MaterialTable";
import type { BrawlPinUnlockCondition } from "../../../services/model/brawl-pin";
import _ from "lodash";
import { UserService } from "../../../services/user";
import { AddEditUnlockConditionDialog } from "./AddEditUnlockConditionDialog";

/**
 * Concatenates the parameters together into a single comma-separated string.
 */
export const getUnlockConditionParams = (condition: BrawlPinUnlockCondition): string => {
  return (condition.parameters && !_.isEmpty(condition.parameters))
    ? Object.keys(condition.parameters).map((key: string) =>
      `${key}=${condition.parameters ? _.isString(condition.parameters[key]) ? `'${condition.parameters[key]}'` : condition.parameters[key] : ''}`).join(', ') : '-';
}

interface Props {
  groupIndex: number;
  unlockConditions: BrawlPinUnlockCondition[];
  deleteConditionFromGroup: (condition: BrawlPinUnlockCondition, groupIndex: number) => void;
  performActionWithValidation: (action: () => void) => void;
  upsertUnlockConditionForGroup: (condition: BrawlPinUnlockCondition, groupIndex: number, checkUnlockType: boolean) => void;
}

export const UnlockConditions = (props: Props) => {
  const { groupIndex, unlockConditions, deleteConditionFromGroup, performActionWithValidation, upsertUnlockConditionForGroup } = props;

  const [showAddConditionDialog, setShowAddConditionDialog] = useState(false);
  const [conditionToEdit, setConditionToEdit] = useState<BrawlPinUnlockCondition | undefined>(undefined);

  const handleClose = useCallback(() => {
    setShowAddConditionDialog(false);
    setConditionToEdit(undefined);
  }, [])

  const deleteCondition = useCallback((condition: BrawlPinUnlockCondition) => {
    deleteConditionFromGroup(condition, groupIndex);
  }, [groupIndex, deleteConditionFromGroup]);

  const conditionMenuActions: MenuAction<BrawlPinUnlockCondition>[] = useMemo(() => {
    const menuActions: MenuAction<BrawlPinUnlockCondition>[] = [];
    if (UserService.canDelete('brawlPins')) {
      menuActions.push({type: 'button', icon: DeleteIcon, label: 'Delete', onClick: deleteCondition});
    }
    return menuActions;
  }, [deleteCondition]);

  const conditionTableColumns: Column<BrawlPinUnlockCondition>[] = useMemo(() => {
    return [
      {
        title: 'Unlock Type',
        render: condition => (
          <Grid container spacing={1} alignItems="center">
            {UserService.canUpdate('brawlPins') && (<>
              <Link component="button" onClick={() => performActionWithValidation(() => setConditionToEdit(condition))}>
                {condition.unlockType}
              </Link>
            </>) || (<>
              <Box>
                {condition.unlockType}
              </Box>
            </>)}
          </Grid>
        )
      },
      {
        title: 'Badge Check Type',
        field: 'badgeCheckType',
      },
      {
        title: 'Game Type',
        field: 'gameType',
      },
      {
        title: 'Parameters',
        render: condition => (
          <Grid container spacing={1} alignItems="center">
            {getUnlockConditionParams(condition)}
          </Grid>
        )
      }
    ];
  }, [performActionWithValidation, setConditionToEdit]);

  const upsertUnlockCondition = useCallback((condition: BrawlPinUnlockCondition, checkUnlockType: boolean): void =>  {
    upsertUnlockConditionForGroup(condition, groupIndex, checkUnlockType);
  }, [groupIndex, upsertUnlockConditionForGroup]);

  return (<>
    <Grid item xs={12}>
      <Box mb={2}>
        <Grid container justify="flex-end">
          {UserService.canCreate('brawlPins') && (<>
            <Grid item>
              <Button variant="contained" color="primary" size="small" onClick={() => performActionWithValidation(() => setShowAddConditionDialog(true))}>Add Unlock Condition</Button>
            </Grid>
          </>)}
        </Grid>
      </Box>
      <MaterialTable
        columns={conditionTableColumns}
        data={unlockConditions}
        options={{
          paging: false,
          search: false,
          toolbar: false,
          sorting: false,
          showTitle: false
        }}
        menuActions={conditionMenuActions}
      />
    </Grid>

    {(showAddConditionDialog || conditionToEdit) && <AddEditUnlockConditionDialog
      existingCondition={conditionToEdit}
      upsertUnlockCondition={upsertUnlockCondition}
      onClose={handleClose}
    />}
  </>
  );
};

import { Box, Button, Card, CardContent, CardHeader, Chip } from "@material-ui/core";
import { useEffect, useState } from "react";
import { PlayersService } from "../players";
import type { Player, SimpleUpholdWallet } from "../players";


interface OwnProps {
  player: Player
}

export const PlayerWallet = (props: OwnProps) => {
  const { player } = props;
  const [getUpholdWallet, setUpholdWallet] = useState<SimpleUpholdWallet | undefined>(undefined);

  useEffect(() => {
    if (getUpholdWallet === undefined) {
      PlayersService.getUpholdWallet(player.id).then(wallet => setUpholdWallet(wallet));
    }
  },[getUpholdWallet, player.id]);

  const unlinkAccount = () => {
    PlayersService.unlinkUpholdWallet(player.id).then(success => {
      if (success) {
        setUpholdWallet(undefined);
      }
    });
  }

  return (<>
    {getUpholdWallet && getUpholdWallet.hasUphold && (
      <Box mt={3}>
        <Card>
          <CardHeader title="Wallet Info" />
          <CardContent>
            {getUpholdWallet.hasBalance && (
              <Box margin="5px">
                This wallet has existing funds.
              </Box>
            )}
            <Button variant="contained" color="primary" onClick={unlinkAccount} >Unlink Uphold Account</Button>
          </CardContent>
        </Card>
      </Box>
    )}
  </>)
};
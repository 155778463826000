import { call, put, takeLatest } from 'redux-saga/effects';
import type { RewardPathTierDto} from '../../../services/player-challenges/season-reward-path-tiers';
import { SeasonRewardPathTiersService } from '../../../services/player-challenges/season-reward-path-tiers';
import { setAppNotification } from '../../app/actions';
import { forkSagas, sagaHandleApiError } from '../../utils-ts';
import {
  deleteRewardPathTierAsync,
  getRewardPathTierAsync,
  getRewardPathTiersAsync,
  updateRewardPathTierAsync,
  createRewardPathTierAsync
} from './actions';

function* fetchRewardPathTiers(action: ReturnType<typeof getRewardPathTiersAsync.request>) {
  try {
    const seasons: RewardPathTierDto[] = yield call(SeasonRewardPathTiersService.getRewardPathTiers, action.payload.seasonNumber, action.payload.pathId);
    yield put(getRewardPathTiersAsync.success(seasons));
  } catch (e) {
    yield sagaHandleApiError(e, getRewardPathTiersAsync.failure);
  }
}

function* fetchRewardPathTier(action: ReturnType<typeof getRewardPathTierAsync.request>) {
  try {
    const season: RewardPathTierDto = yield call(SeasonRewardPathTiersService.getRewardPathTier, action.payload.seasonNumber, action.payload.pathId, action.payload.tierId);
    yield put(getRewardPathTierAsync.success(season));
  } catch (e) {
    yield sagaHandleApiError(e, getRewardPathTierAsync.failure);
  }
}

function* createRewardPathTierSaga(action: ReturnType<typeof createRewardPathTierAsync.request>) {
  try {

    const result: RewardPathTier = yield call(SeasonRewardPathTiersService.createRewardPathTier, action.payload.request);
    yield put(createRewardPathTierAsync.success(result));
    yield put(setAppNotification({ type: 'success', message: 'Reward Path Tier created' }));
  } catch (e) {
    yield sagaHandleApiError(e, createRewardPathTierAsync.failure);
  }
}

function* updateRewardPathTierSaga(action: ReturnType<typeof updateRewardPathTierAsync.request>) {
  try {

    const result: RewardPathTier = yield call(SeasonRewardPathTiersService.updateRewardPathTier, action.payload.request);
    yield put(updateRewardPathTierAsync.success(result));
    yield put(setAppNotification({ type: 'success', message: 'Reward Path Tier updated' }));
  } catch (e) {
    yield sagaHandleApiError(e, updateRewardPathTierAsync.failure);
  }
}

function* deleteRewardPathTierSaga(action: ReturnType<typeof deleteRewardPathTierAsync.request>) {
  try {
    yield call(SeasonRewardPathTiersService.deleteRewardPathTier, action.payload.seasonNumber, action.payload.pathId, action.payload.tierId);
    yield put(deleteRewardPathTierAsync.success(action.payload));
    yield put(setAppNotification({ type: 'success', message: 'Reward Path Tier deleted' }));
  } catch (e) {
    yield sagaHandleApiError(e, deleteRewardPathTierAsync.failure);
  }
}

const sagas = [
  function* () {
    yield takeLatest(getRewardPathTiersAsync.request, fetchRewardPathTiers);
  },
  function* () {
    yield takeLatest(getRewardPathTierAsync.request, fetchRewardPathTier);
  },
  function* () {
    yield takeLatest(createRewardPathTierAsync.request, createRewardPathTierSaga);
  },
  function* () {
    yield takeLatest(updateRewardPathTierAsync.request, updateRewardPathTierSaga);
  },
  function* () {
    yield takeLatest(deleteRewardPathTierAsync.request, deleteRewardPathTierSaga);
  }
];

export default function* rewardPathTiersSagas() {
  yield forkSagas(sagas);
}
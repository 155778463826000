import { CatalogMigrationService } from '../../../../services/catalog-migration';
import type { MigrationModule } from '../../migrations';
import { buildDiffData, buildMigrationActions } from '../../migrations';
import { TitleDataPinRuleDiffs } from "./TitleDataPinRuleDiffs";
import _ from "lodash";
import type { Dictionary } from "lodash";
import type { InvalidRewardInfo} from "./titleDataPinRuleDiff";
import { TitleDataPinRuleDiff } from "./titleDataPinRuleDiff";
import type { BrawlPinRule } from '../../../../services/model/brawl-pin';
import { ItemDefinitionsService } from '../../../../services/item-definitions';
import type { ItemDefinition } from '../../../../services/item-definitions';
import { MigrationType } from "../migration-type";

export const titleDataPublish: MigrationModule<TitleDataPinRuleDiff> = {
  id: MigrationType.brawlPinTitleData,
  displayName: 'Title Data',
  diffComponent: TitleDataPinRuleDiffs,
  crossEnvOnly: false,

  loadData: async (sourceData, targetData, migrationData) => {
    const promises: Promise<any>[] = [];
    promises.push(CatalogMigrationService.getPinRulesSourceInfo(sourceData.env));
    promises.push(CatalogMigrationService.getPinRulesTargetInfo(targetData.env, targetData.catalogName));
    promises.push(ItemDefinitionsService.getItemDefinitions(targetData.catalogName));
    const values = await Promise.all(promises);
    
    // grab our results from async calls
    sourceData.pinRulesInfo = values[0];
    targetData.pinRulesInfo = values[1];
    const itemDefinitions: ItemDefinition[] = values[2];

    if (!sourceData.pinRulesInfo || !itemDefinitions)
      return null;

    // create a list of item IDs for quick lookup
    const existingItemIds = itemDefinitions.map(i => i.itemId);
    
    migrationData.titleDataPinRules = buildDiffData(
      sourceData.pinRulesInfo.pinRules.map(brawlPin => {
        // grab all of the reward IDs for the pin
        const rewardItemIds = [...new Set(brawlPin.Tier.map(t => t.RewardItemID.map(id => id)).flat())];

        // identify reward IDs that do not exist in catalog
        const missingItemIds = rewardItemIds.filter(id => !existingItemIds.includes(id));

        let invalidRewardInfo: InvalidRewardInfo | null = null;
        if (!_.isEmpty(missingItemIds)) {
          // grab the first invalid tier using first invalid reward item ID
          const invalidTier = brawlPin.Tier.find(t => t.RewardItemID.includes(rewardItemIds[0]));
          if (invalidTier) {
            invalidRewardInfo = {
              tierLevel: invalidTier.Level,
              rewardItemId: rewardItemIds[0],
            };
          }
        }

        return new TitleDataPinRuleDiff(brawlPin, invalidRewardInfo);
      }),
      targetData.pinRulesInfo?.pinRules.map(brawlPin => new TitleDataPinRuleDiff(brawlPin, null)) || []
    );
    return migrationData.titleDataPinRules;
  },

  runMigration: async (props, setStatus) => {
    const actions = buildMigrationActions(props.migrationData.titleDataPinRules, props.selections.brawlPinTitleData);
    if (actions.add.length + actions.update.length + actions.remove.length < 1) {
      return;
    }

    const title = props.targetData.pinRulesInfo?.title;
    if (title == null) {
      throw new Error("Why don't we have title data?");
    }

    const mapPinRulesByName = (pinRules?: BrawlPinRule[]): Dictionary<BrawlPinRule> => pinRules && _.mapValues(_.groupBy(pinRules, 'Name'), result => result[0]) || {};
    const sourcePinRulesByName = mapPinRulesByName(props.sourceData.pinRulesInfo?.pinRules);
    const targetPinRulesByName = mapPinRulesByName(props.targetData.pinRulesInfo?.pinRules);

    // whether adding or updating, we always replace existing title data
    actions.add.concat(actions.update).forEach(ruleName => {
      targetPinRulesByName[ruleName] = sourcePinRulesByName[ruleName];
    });

    // convert mapped items back to an array
    const targetPins = Object.values(targetPinRulesByName);

    // stuff the server badge rules into the title data
    title.internalData[`${props.targetData.catalogName}.BadgeRules`] = JSON.stringify(targetPins);
    
    setStatus('Publishing Pin Rules');
    await CatalogMigrationService.updateServerTitleData(props.targetData.env, title.internalData);
  }
};

import { BrawlPin, BrawlPinTier, BrawlPinUnlockCondition, TierLevelEnum } from "../../../services/model/brawl-pin";
import _ from "lodash";

export class BrawlPinTierViewModel {
  tier: BrawlPinTier = {
    id: '',
    level: TierLevelEnum.Gold,
    rewardItemIds: [],
    conditionGroups: [],
    unlockConditions: [],
  };

  // This is a collection of unique sets of unlock conditions.
  unlockConditionGroups: BrawlPinUnlockCondition[][] = [];

  constructor(tier?: BrawlPinTier) {
    if (!tier)
      return;

    this.tier = _.cloneDeep(tier);

    // Use the tier's condition groups and unlock conditions to
    // create unique sets of unlock conditions.  If there are
    // no condition groups, create a single set from the
    // unlock conditions
    this.unlockConditionGroups = !tier.conditionGroups || _.isEmpty(tier.conditionGroups)
      ? [ _.cloneDeep(tier.unlockConditions) ]
      : tier.conditionGroups.map(cg => {
        const indices = cg.split(',');
        return indices.map(uci => _.cloneDeep(tier.unlockConditions[parseInt(uci)]));
      });
  }

  toBrawlPinTier(): BrawlPinTier {
    const tier = _.cloneDeep(this.tier);

    tier.unlockConditions = [];
    tier.conditionGroups = [];

    // storing the index in an object so checking map for existance of item doesn't exclude 0's
    const unlockConditionsIndexMap: { [key: string]: {index: number} } = {};
    this.unlockConditionGroups.forEach(cg => {
      const conditionGroup: number[] = [];
      cg.forEach(uc => {
        // We need to keep track of the index of the unlock condition for quick lookup.
        // Use a stringify'd version of the unlock condition as the key for lookups.
        const ucKey = JSON.stringify(uc);
        if (!unlockConditionsIndexMap[ucKey]) {
          // the current length of unlock condition will be the index
          const conditionIndex = tier.unlockConditions.length;

          // now push the unlock condition into the tier
          tier.unlockConditions.push(uc);

          // update maps with new unlock condition info
          unlockConditionsIndexMap[ucKey] = { index: conditionIndex };

          // push the condition index into the condition index collection
          conditionGroup.push(conditionIndex);
        } else {
          const conditionGroupIndex = unlockConditionsIndexMap[ucKey].index;
          // push the condition index into the condition index collection
          conditionGroup.push(conditionGroupIndex);
        }
      });

      if (!_.isEmpty(conditionGroup)) {
        // convert the condition index collection to a CSV and push into condition groups for tier
        tier.conditionGroups?.push(conditionGroup.join(','))
      }
    });

    return tier;
  }
}

export const getTierViewModels = (pin: BrawlPin): BrawlPinTierViewModel[] => {
  if (!pin)
    return [];

  return pin.tiers.map(t => new BrawlPinTierViewModel(t));
}
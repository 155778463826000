import { Accordion, AccordionDetails, AccordionSummary, Box, Card, CardContent, Grid } from "@material-ui/core";
import { useCallback, useMemo, useState } from "react";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { seasonalDataComparer } from "./seasonal-data-comparer";
import type { SeasonChallengeScheduleForComparison } from "./seasonal-data-comparer";
import type { ChallengeDiffProps } from "./SeasonalDiffView";

//type aliases
type scheduleChallengeDictionary = { [key: number]: { [instanceId: string]: ScheduleChallenge } };

export const SeasonalChallengeDiffView = (props: ChallengeDiffProps) => {
  const { beforeChanges, afterChanges, previous, current, renderDifferentialChange, renderChallengeRewards } = props;
  const classes = seasonalDataComparer.getStyles();

  const [scheduleExpanded, setScheduleExpanded] = useState(false);
  const [dailyExpanded, setDailyExpanded] = useState(false);
  const [weeklyExpanded, setWeeklyExpanded] = useState(false);
  const [seasonalExpanded, setSeasonalExpanded] = useState(false);

  const showChallengeScheduleHeader = useMemo(() => {
    return beforeChanges?.seasonChallengeSchedule || afterChanges.seasonChallengeSchedule
  }, [beforeChanges, afterChanges]);

  const renderScheduleChallenges = useCallback(
    (afterChallenges: { [k: string]: ScheduleChallenge },
      beforeChallenges?: { [k: string]: ScheduleChallenge },
      currentChallenges?: { [k: string]: ScheduleChallenge },
      previousChallanges?: { [k: string]: ScheduleChallenge }) => {
      //Convert to set to remove dupes
      const combinedKeys = new Set<string>([...Object.keys(afterChallenges || {}), ...Object.keys(beforeChallenges || {})]);
      return (
        Array.from(combinedKeys).map(key => {
          const instanceId = (Object.keys(previousChallanges || {}).includes(key) ?
            previousChallanges?.[key].instanceId :
            currentChallenges?.[key].instanceId) || "Missing Key";

          if (combinedKeys.has(instanceId)) {
            const currentItem = currentChallenges?.[instanceId];
            const previousItem = previousChallanges?.[instanceId];
            return (
              <Card key={key} className={classes.challengeContainer} variant="outlined" >
                <CardContent>
                  <Grid container>
                    <Grid className={classes.sectionTitle} item xs={12}>
                      <h3>Challenge : {currentItem?.challengeDesc || previousItem?.challengeDesc}</h3>
                    </Grid>
                    {renderDifferentialChange("Instance Id", currentItem?.instanceId, previousItem?.instanceId)}
                    {renderDifferentialChange("Challenge Id", currentItem?.challengeId, previousItem?.challengeId)}
                    {renderDifferentialChange("Description", currentItem?.challengeDesc, previousItem?.challengeDesc)}
                    {renderDifferentialChange("Limited?", currentItem?.isLimited?.toString(), previousItem?.isLimited?.toString())}
                    {renderDifferentialChange("Limit Start", currentItem?.limitedStart, previousItem?.limitedStart)}
                    {renderDifferentialChange("Limit End", currentItem?.limitedEnd, previousItem?.limitedEnd)}
                    {renderDifferentialChange("PP+ Only", currentItem?.partyPassPlusOnly.toString(), previousItem?.partyPassPlusOnly.toString())}
                    {renderChallengeRewards(afterChallenges?.[key]?.rewards, beforeChallenges?.[key]?.rewards, currentChallenges?.[key]?.rewards, previousChallanges?.[key]?.rewards)}

                  </Grid>
                </CardContent>
              </Card>
            )
          }
        })
      )
    }, [classes, renderChallengeRewards, renderDifferentialChange]);

  const renderChallengeSchedule = useCallback((afterChallengeSchedule: SeasonChallengeScheduleForComparison, beforeChallengeSchedule?: SeasonChallengeScheduleForComparison) => {
    if (!afterChallengeSchedule && !beforeChallengeSchedule) {
      return;
    }

    const currentDailies = current?.seasonChallengeSchedule?.daily || {} as scheduleChallengeDictionary;
    const previousDailies = previous?.seasonChallengeSchedule?.daily || {} as scheduleChallengeDictionary;
    const currentWeeklies = current?.seasonChallengeSchedule?.weekly || {} as scheduleChallengeDictionary;
    const previousWeeklies = previous?.seasonChallengeSchedule?.weekly || {} as scheduleChallengeDictionary;
    const currentSeasonal = current?.seasonChallengeSchedule?.seasonal || {} as { [k: string]: ScheduleChallenge };
    const previousSeasonal = previous?.seasonChallengeSchedule?.seasonal || {} as { [k: string]: ScheduleChallenge };


    const afterDailyDiffs = new Set<string>();
    Object.keys(afterChallengeSchedule?.daily || {}).forEach((c, i) => {
      const day = currentDailies[parseInt(c, 10)];
      if (day) {
        afterDailyDiffs.add(c);
      }
    });
    const beforeDailyDiffs = new Set<string>();
    Object.keys((beforeChallengeSchedule?.daily || {})).forEach((c, i) => {
      const day = previousDailies[parseInt(c, 10)];
      if (day) {
        beforeDailyDiffs.add(c);
      }
    });
    const combinedDiffDailyKeys = Array.from(new Set([...afterDailyDiffs, ...beforeDailyDiffs]));

    const afterWeeklyDiffs = new Set<string>();
    Object.keys(afterChallengeSchedule?.weekly || {}).forEach((c, i) => {
      const day = currentWeeklies[parseInt(c, 10)];
      if (day) {
        afterWeeklyDiffs.add(c);
      }
    });
    const beforeWeeklyDiffs = new Set<string>();
    Object.keys((beforeChallengeSchedule?.weekly || {})).forEach((c, i) => {
      const day = previousWeeklies[parseInt(c, 10)];
      if (day) {
        beforeWeeklyDiffs.add(c);
      }
    });
    const combinedDiffWeeklyKeys = Array.from(new Set([...afterWeeklyDiffs, ...beforeWeeklyDiffs]));

    const afterSeasonalDiffs = new Set<string>();
    Object.keys(afterChallengeSchedule?.seasonal || {}).forEach((c, i) => {
      const day = currentSeasonal[c];
      if (day) {
        afterSeasonalDiffs.add(c);
      }
    });
    const beforeSeasonalDiffs = new Set<string>();
    Object.keys(beforeChallengeSchedule?.seasonal || {} as { [k: string]: ScheduleChallenge }).forEach((c, i) => {
      const day = previousSeasonal[c];
      if (day) {
        beforeSeasonalDiffs.add(c);
      }
    });
    const combinedExistingSeasonalKeys = Array.from(new Set([...afterSeasonalDiffs, ...beforeSeasonalDiffs]));

    return (
      <Box width="95%" marginLeft="30px" marginBottom="15px">
        {combinedDiffDailyKeys && combinedDiffDailyKeys.length > 0 && (
          <Accordion
            expanded={dailyExpanded}
            TransitionProps={{ unmountOnExit: true }}
            onChange={() => setDailyExpanded(!dailyExpanded)}
            className={classes.accordianSummary}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              className={classes.accordianSummary}
            >
              <Grid container alignItems="center" spacing={2}>
                <Grid item xs={12} className={classes.accordionTitle}>
                  Daily Challenges
                </Grid>
              </Grid>
            </AccordionSummary>
            <AccordionDetails style={{ padding: 0 }}>
              <Box width="100%">
                {combinedDiffDailyKeys.map(key => {
                  //foreach of our modified keys find the orignal/currents
                  const intKey = parseInt(key, 10);
                  const currentDaily = current?.seasonChallengeSchedule?.daily?.[intKey];
                  const previousDaily = previous?.seasonChallengeSchedule?.daily?.[intKey];
                  return (
                    <Box width="100%" key={key}>
                      <h3 className={classes.sectionTitle}>Day {key}</h3>
                      {renderScheduleChallenges(afterChallengeSchedule?.daily?.[intKey], beforeChallengeSchedule?.daily?.[intKey], currentDaily, previousDaily)}
                    </Box>
                  );
                })}
              </Box>
            </AccordionDetails>
          </Accordion>
        )}

        {combinedDiffWeeklyKeys && combinedDiffWeeklyKeys.length > 0 && (
          <Accordion
            expanded={weeklyExpanded}
            TransitionProps={{ unmountOnExit: true }}
            onChange={() => setWeeklyExpanded(!weeklyExpanded)}
            className={classes.accordianSummary}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              className={classes.accordianSummary}
            >
              <Grid container alignItems="center" spacing={2}>
                <Grid item xs={12} className={classes.accordionTitle}>
                  Weekly Challenges
                </Grid>
              </Grid>
            </AccordionSummary>
            <AccordionDetails style={{ padding: 0 }}>
              <Box width="100%">
                {combinedDiffWeeklyKeys.map(key => {
                  //foreach of our modified keys find the orignal/currents
                  const intKey = parseInt(key, 10);
                  const currentWeekly = current?.seasonChallengeSchedule?.weekly?.[intKey];
                  const previousWeekly = previous?.seasonChallengeSchedule?.weekly?.[intKey];
                  return (
                    <Box width="100%" key={key}>
                      <h3 className={classes.sectionTitle}>Week {key}</h3>
                      {renderScheduleChallenges(afterChallengeSchedule?.weekly?.[intKey], beforeChallengeSchedule?.weekly?.[intKey], currentWeekly, previousWeekly)}
                    </Box>
                  );
                })}
              </Box>
            </AccordionDetails>
          </Accordion>
        )}

        {combinedExistingSeasonalKeys && (
          <Accordion
            expanded={seasonalExpanded}
            TransitionProps={{ unmountOnExit: true }}
            onChange={() => setSeasonalExpanded(!seasonalExpanded)}
            className={classes.accordianSummary}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              className={classes.accordianSummary}
            >
              <Grid container alignItems="center" spacing={2}>
                <Grid item xs={12} className={classes.accordionTitle}>
                  Seasonal Challenges
                </Grid>
              </Grid>
            </AccordionSummary>
            <AccordionDetails style={{ padding: 0 }}>
              <Box width="100%">
                {renderScheduleChallenges(afterChallengeSchedule?.seasonal, beforeChallengeSchedule?.seasonal, current?.seasonChallengeSchedule?.seasonal, previous?.seasonChallengeSchedule?.seasonal)}
              </Box>
            </AccordionDetails>
          </Accordion>
        )}
      </Box>
    );
  }, [previous, current, classes, renderScheduleChallenges, dailyExpanded, weeklyExpanded, seasonalExpanded]);

  return (
    <>
      {showChallengeScheduleHeader && (
        <Accordion
          expanded={scheduleExpanded}
          TransitionProps={{ unmountOnExit: true }}
          onChange={() => setScheduleExpanded(!scheduleExpanded)}
          className={classes.accordianSummary}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            className={classes.accordianSummary}
          >
            <Grid container alignItems="center" spacing={2}>
              <Grid item xs={12} className={classes.accordionTitle}>
                Challenge Schedule
              </Grid>
            </Grid>
          </AccordionSummary>
          <AccordionDetails style={{ padding: 0 }}>
            <Box width="100%">
              {renderChallengeSchedule(afterChanges.seasonChallengeSchedule, beforeChanges?.seasonChallengeSchedule)}
            </Box>
          </AccordionDetails>
        </Accordion>
      )}
    </>
  )
}
import { Box, Button, Card, CardContent, CircularProgress, FormControl, FormGroup, FormLabel, Grid, MenuItem, Typography } from '@material-ui/core';
import { Field, Formik } from 'formik';
import { CheckboxWithLabel, TextField } from 'formik-material-ui';
import { init } from 'lodash/fp';
import { useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { FormikSelectWithLabel } from '../../components/FormikSelectWithLabel';
import { setAppNotification } from '../../redux/app/actions';
import { getBlankoClassesAsync, getBlankoProgressionAsync, setBlankoProgression } from '../../redux/blanko-progressions/actions';
import { loadCurrenciesAsync } from '../../redux/currencies/actions';
import { useTypedSelector } from '../../redux/reducers';
import type { ApiError } from '../../services/api';
import { BlankosService } from '../../services/blankos';
import { BlankoProgressionSkillDropTable, BlankoProgressionTierOption, noSkillDropTableItem, blankoTierOptions } from '../../services/model/blanko';
import { createValidator, Validators } from '../../utils/forms';
import type { DefaultAttachment } from './BlankoLevelDefaultAttachmentsForm';
import type { LevelFormValues } from './BlankoLevelEditor';
import type { LevelItemGrant } from './BlankoLevelItemGrantsForm';
import type { TierFormValues } from './BlankoTierEditor';
import BlankoTierEditor from './BlankoTierEditor';

interface FormValues {
  name: string;
  classes: string[];
  tiers: TierFormValues[];
  skillDropTableId: string;
}

interface skillDropTableSelectItem {
  id: string;
  title: string;
  disabled: boolean;
}

const formValidator = createValidator<FormValues>({
  skillDropTableId: Validators.notBlank(),
  tiers: Validators.array(createValidator<TierFormValues>({
    levels: Validators.array(createValidator<LevelFormValues>({
      xpToNextLevel: Validators.integer(0),
      skillPerkSlots: Validators.integer(0),
      neutralPerkSlots: Validators.integer(0),
      randomNeutralPerks: Validators.integer(0)
    }))
  }))
})

interface Props {
  progressionId: string;
}

const BlankoProgressionForm = (props: Props) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const { progressionId } = props;
  const [pid, setPid] = useState(progressionId)
  const blankoClasses = useTypedSelector(state => state.blankoProgressions.classes);
  const currencyList = useTypedSelector(state => state.currencies.list);
  const progressionLoading = useTypedSelector(state => state.blankoProgressions.isLoading);
  const progression = useTypedSelector(state => pid !== 'new' ? state.blankoProgressions.byId[pid] : undefined);
  const [skillDropTables, setSkillDropTables] = useState<BlankoProgressionSkillDropTable[]>();

  const [initialValues, setInitialValues] = useState<FormValues | null>(null);
  const [progressionType, setProgressionType] = useState('');

  useEffect(() => {
    dispatch(getBlankoClassesAsync.request());
    dispatch(loadCurrenciesAsync.request());
  }, [dispatch]);

  useEffect(() => {
    if (skillDropTables)
      return;

    BlankosService.getProgressionSkillDropTables().then(setSkillDropTables)
      .catch((e: ApiError) => {
        e.message = `Failed to get skill drop tables. ${e.message}`;
        throw e;
      });
  }, [skillDropTables]);

  const skillDropTableSelectItems: skillDropTableSelectItem[] = useMemo(() => {
    if (!skillDropTables || !progression)
      return [];

    // if the progression is currently referencing a deleted drop table, then
    // we want to include that name in the list suffixed with "(deleted)".
    return skillDropTables
      .filter(dt => dt.deletedAt === null || dt.id === progression.skillDropTableId)
      .map(dt => {
        const suffix = dt.deletedAt === null ? '' : ' (deleted)';
        return {
          id: dt.id,
          title: `${dt.name}${suffix}`,
          disabled: dt.deletedAt !== null,
        }
      });
  }, [progression, skillDropTables]);

  useEffect(() => {
    if (pid !== 'new' && !progressionLoading && (!progression || progression.tiers.length < 1)) {
      dispatch(getBlankoProgressionAsync.request(pid));
    }
  }, [dispatch, pid, progression, progressionLoading]);

  useEffect(() => {
    if (pid !== 'new' && progression && progression.tiers.length > 0 && !currencyList.isEmpty() && skillDropTables) {
      const tiers: (TierFormValues | null)[] = progression.tiers.map(tier => {
        const tierOption = blankoTierOptions.find(v => v.id === tier.name);
        if (!tierOption) {
          return null;
        }

        let burnGumballs = 0;
        let burnCurrencyMap: { [key: string]: number } = {};

        tier.capstone.forEach(req => {
          const param = req.param;
          switch (req.type) {
          case 'BurnGumballs':
            burnGumballs += param.amount;
            break;
          case 'BurnCurrency':
            burnCurrencyMap = param;
            break;
          }
        });

        return {
          tier: tierOption,
          levels: tier.levels.map((level, index) => {

            const attachmentSlots: string[] = [];
            const emoteSlots: string[] = [];
            let skillPerkSlots = 0;
            let neutralPerkSlots = 0;
            let randomNeutralPerks = 0;
            const abilities: { type: string, [key: string]: string }[] = [];
            const currencyMap: { [key: string]: number } = {};
            const personalityAddons: string[] = [];
            const items: LevelItemGrant[] = [];
            const defaultAttachments: DefaultAttachment[] = [];
            const defaultEmotes: LevelItemGrant[] = [];

            level.rewards.forEach(reward => {
              const param = reward.param;
              switch (reward.type) {
              case 'UnlockAccessorySlot':
                attachmentSlots.push(param.slotId);
                break;
              case 'UnlockEmoteSlot':
                emoteSlots.push(param.slotId);
                break;
              case 'UnlockSkillPerkSlots':
                skillPerkSlots += param.count;
                break;
              case 'UnlockNeutralPerkSlots':
                neutralPerkSlots += param.count;
                break;
              case 'GrantCurrency':
                currencyMap[param.currency] = (currencyMap[param.currency] || 0) + param.amount;
                break;
              case 'GrantSkill':
                abilities.push({ type: 'abilities', ...param });
                break;
              case 'GrantSkillPerk':
                abilities.push({ type: 'modifiers', ...param });
                break;
              case 'GrantNeutralPerk':
                abilities.push({ type: 'passives', ...param });
                break;
              case 'GrantRandomNeutralPerk':
                randomNeutralPerks++;
                break;
              case 'AddPersonality':
                personalityAddons.push(param.name);
                break;
              case 'GrantItem': {
                const item = items.find(v => v.itemId === param.itemId);
                if (item) {
                  item.quantity++;
                } else {
                  items.push({ catalogName: param.catalogName, itemId: param.itemId, quantity: 1 });
                }
                break;
              }
              case 'AddDefaultAttachment':
                defaultAttachments.push({ itemId: param.attachmentCatalogId, slots: param.slots });
                break;
              case 'AddDefaultEmote':
                defaultEmotes.push({ catalogName: '', itemId: param.emoteCatalogId, quantity: 1 })
                break;
              default:
                break;
              }
            });

            return {
              attachmentSlots,
              emoteSlots,
              skillPerkSlots: skillPerkSlots.toString(),
              neutralPerkSlots: neutralPerkSlots.toString(),
              randomNeutralPerks: randomNeutralPerks.toString(),
              personalityAddons: personalityAddons.join(', '),
              xpToNextLevel: String(level.xpToNextLevel),
              abilities,
              items,
              defaultAttachments,
              defaultEmotes,
              currency: currencyList.getCurrencies().map(currency => ({
                code: currency.code,
                name: currency.name,
                amount: (currencyMap[currency.code] || 0).toString()
              }))
            };
          }),
          burnGumballs: burnGumballs.toString(),
          burnCurrency: currencyList.getCurrencies().map(currency => ({
            code: currency.code,
            name: currency.name,
            amount: (burnCurrencyMap[currency.code] || 0).toString()
          }))
        };
      })

      let skillDropTableId = noSkillDropTableItem;
      if (progression.skillDropTableId) {
        const dropTable = skillDropTables.find(dt => dt.id === progression.skillDropTableId);
        if (dropTable) {
          skillDropTableId = dropTable.id;
        }
      }
      const initialValues: FormValues = {
        name: progression.name,
        classes: progression.blankoClasses,
        tiers: tiers.filter(v => !!v) as TierFormValues[], // Sometimes Typescript is infuriating
        skillDropTableId,
      };

      setInitialValues(initialValues);
    } else if (!currencyList.isEmpty() && pid === 'new') {
      let initialValues: FormValues = {
        name: '',
        classes: [],
        tiers: [],
        skillDropTableId: noSkillDropTableItem,
      };

      if (progressionType) {
        let options: BlankoProgressionTierOption[] = [];
        if (progressionType === 'mint') {
          options = blankoTierOptions.slice(3, 4);
        } else if (progressionType === 'gem-mint') {
          options = blankoTierOptions.slice(4, 5);
        } else {
          options = blankoTierOptions.slice(0, 3);
        }

        initialValues = {
          name: '',
          classes: [],
          tiers: options.map(option => ({
            tier: option,
            levels: [{
              attachmentSlots: [],
              emoteSlots: [],
              skillPerkSlots: '0',
              neutralPerkSlots: '0',
              randomNeutralPerks: '0',
              personalityAddons: '',
              xpToNextLevel: '0',
              abilities: [],
              items: [],
              defaultAttachments: [],
              defaultEmotes: [],
              currency: currencyList.getCurrencies().map(currency => ({
                code: currency.code,
                name: currency.name,
                amount: '0'
              }))
            }],
            burnGumballs: '0',
            burnCurrency: currencyList.getCurrencies().map(currency => ({
              code: currency.code,
              name: currency.name,
              amount: '0'
            }))
          })),
          skillDropTableId: noSkillDropTableItem,
        };
      }

      setInitialValues(initialValues);
    }
  }, [progression, pid, currencyList, progressionType, skillDropTables]);

  const onSubmit = (values: FormValues) => {
    const json: any = {
      name: values.name,
      blankoClasses: values.classes,
      tiers: [],
      skillDropTableId: values.skillDropTableId === noSkillDropTableItem ? "":values.skillDropTableId,
    };

    values.tiers.forEach((tier, index) => {
      const tierJson: any = {
        id: progression ? progression.tiers[index].id : undefined,
        name: tier.tier.id,
        iconMedium: `Progression${tier.tier.iconName}_Icon_Medium`,
        levels: [],
        capstone: []
      };

      tier.levels.forEach((level, levelIndex) => {
        const lvlNum = levelIndex + 1
        const levelId = `STR_LEVEL_${lvlNum}`
        const id = progression && Boolean(progression.tiers[index].levels[levelIndex]?.id !== undefined) ? progression.tiers[index].levels[levelIndex].id : undefined
        const levelJson: any = {
          id,
          name: levelId,
          shortName: levelId,
          iconLarge: `Progression${tier.tier.iconName}${`Lvl${lvlNum}`}_Large`,
          xpToNextLevel: Number(level.xpToNextLevel),
          rewards: []
        };

        level.attachmentSlots.forEach(slot => {
          levelJson.rewards.push({
            type: 'UnlockAccessorySlot',
            param: {
              slotId: slot
            }
          });
        });

        level.emoteSlots.forEach(slot => {
          levelJson.rewards.push({
            type: 'UnlockEmoteSlot',
            param: {
              slotId: slot
            }
          });
        });

        const skillPerkSlots = parseInt(level.skillPerkSlots) || 0;
        if (skillPerkSlots > 0) {
          levelJson.rewards.push({
            type: 'UnlockSkillPerkSlots',
            param: {
              count: skillPerkSlots
            }
          });
        }

        const neutralPerkSlots = parseInt(level.neutralPerkSlots) || 0;
        if (neutralPerkSlots > 0) {
          levelJson.rewards.push({
            type: 'UnlockNeutralPerkSlots',
            param: {
              count: neutralPerkSlots
            }
          });
        }

        const randomNeutralPerks = parseInt(level.randomNeutralPerks) || 0;
        for (let i = 0; i < randomNeutralPerks; i++) {
          levelJson.rewards.push({
            type: 'GrantRandomNeutralPerk',
            param: {}
          });
        }

        level.currency.forEach(currency => {
          const amount = parseInt(currency.amount) || 0;
          if (amount > 0) {
            levelJson.rewards.push({
              type: 'GrantCurrency',
              param: {
                currency: currency.code,
                amount
              }
            });
          }
        });

        level.abilities.forEach(ability => {
          let type = 'GrantSkill';
          if (ability.type === 'modifiers') {
            type = 'GrantSkillPerk';
          } else if (ability.type === 'passives') {
            type = 'GrantNeutralPerk';
          }

          const param: any = {};
          values.classes.forEach(blankoClass => {
            if (ability[blankoClass]) {
              param[blankoClass] = ability[blankoClass]
            }
          });

          levelJson.rewards.push({ type, param });
        });

        level.personalityAddons.trim().split(',').map(v => v.trim()).filter(v => !!v).forEach(addon => {
          levelJson.rewards.push({
            type: 'AddPersonality',
            param: { name: addon }
          });
        });

        level.items.forEach(item => {
          for (let i = 0; i < item.quantity; i++) {
            levelJson.rewards.push({
              type: 'GrantItem',
              param: { catalogName: item.catalogName, itemId: item.itemId }
            });
          }
        });

        level.defaultAttachments.forEach(attachment => {
          levelJson.rewards.push({
            type: 'AddDefaultAttachment',
            param: { attachmentCatalogId: attachment.itemId, slots: attachment.slots }
          });
        })

        level.defaultEmotes.forEach(emote => {
          levelJson.rewards.push({
            type: 'AddDefaultEmote',
            param: { emoteCatalogId: emote.itemId }
          });
        });

        tierJson.levels.push(levelJson);
      });

      const burnGumballs = parseInt(tier.burnGumballs) || 0;
      if (burnGumballs > 0) {
        tierJson.capstone.push({
          type: 'BurnGumballs',
          param: { amount: burnGumballs }
        });
      }

      const currencyCapstone: any = {
        type: 'BurnCurrency',
        param: {}
      };

      tier.burnCurrency.forEach(currency => {
        const amount = parseInt(currency.amount) || 0;
        if (amount > 0) {
          currencyCapstone.param[currency.code] = amount;
        }
      });

      if (Object.keys(currencyCapstone.param).length > 0) {
        tierJson.capstone.push(currencyCapstone);
      }

      json.tiers.push(tierJson);
    });

    // Call the service here for now. Use sagas later.
    if (progression) {
      return BlankosService.updateProgression(progression.id, json).then(progression => {
        dispatch(setBlankoProgression(progression));
        dispatch(setAppNotification({ type: 'success', message: 'Progression updated' }));
      }).catch((e: ApiError) => {
        dispatch(setAppNotification({ type: 'error', message: e.message }));
      });
    } else {
      return BlankosService.createProgression(json).then(progression => {
        dispatch(setBlankoProgression(progression));
        history.replace(`/progressions/${progression.id}`);
        setPid(progression.id)
        dispatch(setAppNotification({ type: 'success', message: 'Progression created' }));
      }).catch((e: ApiError) => {
        dispatch(setAppNotification({ type: 'error', message: e.message }));
      });
    }
  }

  if (!initialValues
    || !skillDropTables
    || blankoClasses.isLoading
    || currencyList.isEmpty()
    || (pid !== 'new' && (!progression || progression.tiers.length < 1))) {
    return (
      <Box textAlign="center">
        <CircularProgress />
      </Box>
    )
  }

  return (<>
    <Box mb={2}>
      <Typography variant="h6">
        {progression ? progression.name : 'New progression'}
      </Typography>
    </Box>

    {(!progression && !progressionType) && (
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant="subtitle1">
            Select a progression type
          </Typography>
        </Grid>
        <Grid item xs="auto">
          <Button variant="outlined" onClick={() => setProgressionType('base')}>Base</Button>
        </Grid>
        <Grid item xs="auto">
          <Button variant="outlined" onClick={() => setProgressionType('mint')}>Mint</Button>
        </Grid>
        <Grid item xs="auto">
          <Button variant="outlined" onClick={() => setProgressionType('gem-mint')}>Gem Mint</Button>
        </Grid>
      </Grid>
    )}

    {initialValues.tiers.length > 0 && (
      <Formik<FormValues>
        initialValues={initialValues}
        onSubmit={onSubmit}
        validate={formValidator}
      >
        {form => (<>
          <Card>
            <CardContent>
              <Box mb={2}>
                <Field
                  component={TextField}
                  type="text"
                  name="name"
                  label="Name"
                  fullWidth
                />
              </Box>

              <Box mb={2}>
                <Field
                  component={FormikSelectWithLabel}
                  name='skillDropTableId'
                  label='Skill Drop Table'
                  fullWidth
                >
                  <MenuItem key={noSkillDropTableItem} value={noSkillDropTableItem}>None</MenuItem>
                  {skillDropTableSelectItems.sort((a, b) => a.title.localeCompare(b.title)).map(item => (
                    <MenuItem key={item.id} value={item.id} disabled={item.disabled}>{item.title}</MenuItem>
                  ))}
                </Field>

              </Box>

              <FormControl fullWidth>
                <FormLabel>Classes</FormLabel>
                <FormGroup row>
                  {blankoClasses.data.map(blankoClass => (
                    <Field
                      key={blankoClass}
                      component={CheckboxWithLabel}
                      type="checkbox"
                      name="classes"
                      value={blankoClass}
                      Label={{ label: blankoClass }}
                    />
                  ))}
                </FormGroup>
              </FormControl>
            </CardContent>
          </Card>

          <Box mt={2}>
            {form.values.tiers.map((value, index) => (
              <BlankoTierEditor
                key={value.tier.id}
                option={value.tier}
                formName={`tiers[${index}]`}
                value={value}
                isFirst={index === 0}
                isLast={index === form.values.tiers.length - 1}
                skillDropTableId={form.values.skillDropTableId}
              />
            ))}
          </Box>

          <Box mt={2}>
            <Button color="primary" variant="contained" onClick={form.submitForm}>
              Save
            </Button>
          </Box>
        </>)}
      </Formik>
    )}
  </>);
}

export default BlankoProgressionForm;
import {createStyles, makeStyles } from '@material-ui/core';
import Drawer from '@material-ui/core/Drawer';
import Hidden from '@material-ui/core/Hidden';
import type {Theme} from '@material-ui/core/styles';
import { useCallback } from 'react';
import type { ConnectedProps} from 'react-redux';
import {connect} from 'react-redux';
import type {RootState} from '../../redux/reducers';
import {toggleSidebar} from '../../redux/sidebar/actions';

import { DrawerContent } from './DrawerContent';

export const drawerWidth = 240;

const styles = makeStyles((theme: Theme) =>
  createStyles({
    drawer: {
      [theme.breakpoints.up('sm')]: {
        width: drawerWidth,
        flexShrink: 0,
      }
    },
    drawerPaper: theme.palette.type === 'dark' ? {
      backgroundColor: '#2a2934',
      color: '#fff',
      width: drawerWidth,
      borderRight: 'none',
      '& .MuiListItem-button:hover': {
        backgroundColor: 'rgba(255, 255, 255, 0.08)'
      },
      '& .MuiListItemIcon-root': {
        color: '#fff'
      }
    } : {
      backgroundColor: '#fff',
      color: '#000',
      width: drawerWidth,
      borderRight: 'none',
      '& .MuiListItem-button:hover': {
        backgroundColor: 'rgba(0, 0, 0, 0.08)'
      },
      '& .MuiListItemIcon-root': {
        color: '#000'
      }
    }
  }),
);

const mapStateToProps = (state: RootState) => ({mobileOpen: state.sidebar.mobileOpen});
const mapDispatch = {toggleSidebar};
const connector = connect(mapStateToProps, mapDispatch);

type SideBarProps = ConnectedProps<typeof connector>;

const SideBar = ({mobileOpen, toggleSidebar}: SideBarProps) => {
  const classes = styles();
  const handleDrawerToggle = useCallback(() => {
    toggleSidebar();
  }, [toggleSidebar]);

  return (
    <nav className={classes.drawer}>
      <Hidden smUp implementation="css">
        <Drawer
          variant="temporary"
          anchor="left"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          classes={{
            paper: classes.drawerPaper
          }}
          ModalProps={{
            keepMounted: true
          }}
        >
          <DrawerContent />
        </Drawer>
      </Hidden>
      <Hidden xsDown implementation="css">
        <Drawer
          classes={{
            paper: classes.drawerPaper
          }}
          variant="permanent"
          open
        >
          <DrawerContent />
        </Drawer>
      </Hidden>
    </nav>
  );
}

export default connector(SideBar);

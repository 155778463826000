import { useCallback } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, DialogContentText, ListItem, ListItemText } from '@material-ui/core';
import { deleteBlankoRush } from '../gemRushApiService';

interface DeleteRushDialogProps {
  show: boolean
  rush?: {id: string, name: string}
  onClose: () => void
}
export const DeleteRushDialog = ({show, rush, onClose }: DeleteRushDialogProps) => {
  const onConfirm = useCallback(async ()=> {
    rush && (await deleteBlankoRush(rush.id))
    onClose()
  }, [rush, onClose])
  return (
    <div>
      <Dialog
        open={show}
        keepMounted
        onClose={onClose}
        aria-labelledby="delete-dialog-title"
        aria-describedby="delete-dialog-description"
      >
        <DialogTitle id="delete-dialog-title">{"Delete Gem Rush"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="delete-dialog-description">
            Are you sure you want to delete the following Gem Rushes?
          </DialogContentText>
          <ul>
            {rush &&
              <ListItem key={rush.id}>
                <ListItemText primary={rush.name} />
              </ListItem>
            }
          </ul>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} color="primary">
            No
          </Button>
          <Button onClick={onConfirm} color="primary">
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
import { Component } from 'react';
import type { ConnectedProps} from 'react-redux';
import {connect} from 'react-redux';
import {Dialog, DialogContent, Typography} from "@material-ui/core";
import MaterialTable from '../../components/MaterialTable';
import type {TransactionAggregate, TransactionItem} from "../../services/transactions";
import {paginatedRequestFromQuery} from "../../services/model/pagination";
import type {RootState} from "../../redux/reducers";
import {selectAllTransactionItemsPage} from "../../redux/transactions/selectors";
import {getAllTransactionItemsAsync} from "../../redux/transactions/actions";
import type {Column, Query} from "material-table";


const mapStateToProps = (state: RootState) => ({
  transactionItems: selectAllTransactionItemsPage(state)
});
const mapDispatch = {
  requestTransactionItems: getAllTransactionItemsAsync.request
};
const connector = connect(mapStateToProps, mapDispatch);

type Props = ConnectedProps<typeof connector> & {
  transaction: TransactionAggregate | undefined;
  onClose: () => void;
}

interface State {
  open: boolean;
}

class TransactionDetailDialog extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    if (!this.props.transaction) {
      throw new Error('Transaction required.')
    }

    this.state = {
      open: true
    };
  }

  getTransactionItemsPage = (query: Query<TransactionItem>) => {
    const request = paginatedRequestFromQuery(query);
    if (this.props.transaction) {
      request.transactionId = this.props.transaction.id;
    }
    this.props.requestTransactionItems(request);
  };

  getItemsColumns() {
    const columns: Column<TransactionItem>[] = [
      {
        title: 'ID',
        field: 'itemId',
        defaultSort: "asc"
      },
      {
        title: 'Name',
        field: 'itemDisplayName'
      },
      {
        title: 'Class',
        field: 'itemClass'
      }
    ];
    return columns;
  };

  render() {
    return (
      <Dialog
        open={this.state.open}
        onClose={() => this.setState({open: false})}
        onExited={this.props.onClose}
        fullWidth
        maxWidth="md"
      >
        <DialogContent>
          <>
            <MaterialTable
              title={"Item(s) purchased detail:"}
              columns={this.getItemsColumns()}
              data={{
                pageState: this.props.transactionItems,
                runQuery: this.getTransactionItemsPage
              }}
              options={{
                debounceInterval: 500,
                pageSize: 5,
                thirdSortClick: false,
                search: false
              }}
            />

            {this.props.transaction && this.props.transaction.currencySummary && (
              <>
                <br/>
                <Typography variant={"h6"}>
                  Currency purchased:
                </Typography>
                {this.props.transaction.currencySummary}
              </>
            )}
          </>
        </DialogContent>
      </Dialog>
    );
  }
}

export default connector(TransactionDetailDialog);
import { FormControl, InputLabel, MenuItem, Select } from '@material-ui/core'
import config, { configs } from '../../../config'

export interface EnvSelectProps {
  env: string;
  onChange: (env: string) => void;
}

export const EnvSelect = (props: EnvSelectProps) => {
  const envs = Object.values(configs);

  return (
    <FormControl>
      <InputLabel>Env.</InputLabel>
      <Select
        value={props.env}
        onChange={e => props.onChange(e.target.value as string)}
      >
        {envs.map(env => (
          <MenuItem key={env.env} value={env.env}>{env.name}</MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}
import type { RootState } from '../reducers';
import type { EnvOption } from './actions';

export function selectAllCatalogs(state: RootState, env: EnvOption) {
  return state.catalogMigration[env].catalogs;
}

export function selectCatalog(state: RootState, env: EnvOption) {
  return state.catalogMigration[env].catalog;
}

export function selectCatalogName(state: RootState, env: EnvOption) {
  return state.catalogMigration[env].catalogName;
}
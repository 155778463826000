import { Box, Button, Card, CardContent, CardHeader, Typography } from '@material-ui/core';
import { useEffect, useState } from 'react';
import type { Player } from '../players';
import { PlayersService } from '../players';
import { openAdminConfirmationDialog } from "../../../shared/hooks/useAdminConfirmationDialog";
import { pushAppNotification } from "../../../shared/hooks/useAppNotification";

interface Props {
  player: Player;
  refreshPlayer: () => void;
}

export const FtuePanel = (props: Props) => {
  const [ftueState, setFtueState] = useState('');

  useEffect(() => {
    let ftueState = 'None';
    if (props.player.playerData.FtueProgressionState) {
      try {
        ftueState = JSON.parse(props.player.playerData.FtueProgressionState.value);
      } catch (e) {
        ftueState = 'Unknown';
      }
    }
    setFtueState(ftueState);
  }, [props.player]);

  const onResetFtueClick = () => {
    openAdminConfirmationDialog({
      title: 'Reset FTUE?', action: 'Reset FTUE', onConfirm: () => {
        PlayersService.resetPlayerFtue(props.player.externalId)
          .then(() => {
            pushAppNotification({type: 'success', message: `Player FTUE reset`});
            props.refreshPlayer();
          })
          .catch(err => pushAppNotification({type: 'error', message: `Failed to reset player FTUE: ${err.message}`}));
      }
    });
  };

  return (
    <Card>
      <CardHeader title="FTUE"/>
      <CardContent>
        <Typography>
          FTUE state: {ftueState}
        </Typography>
        <Box mt={2}>
          <Button variant="contained" color="primary" onClick={onResetFtueClick} disabled={!props.player.playerData.FtueProgressionState}>
            Reset FTUE
          </Button>
        </Box>
      </CardContent>
    </Card>
  );
};

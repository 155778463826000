import type { ApiError} from '../api';
import {api} from '../api';
import type { Reward } from './challenges';

export class SeasonalChallenge {
  challenges: ScheduledChallenge[] = [];

  constructor(json?: any) {
    if (json) {
      this.challenges = json.challenges.map((challenges: any) => new ScheduledChallenge(challenges));
    }
  }

  toJson(): any {
    return {
      challenges: this.challenges.map(challenges => challenges.toJson())
    };
  }
}
export class ChallengeSchedule {
  season_number = -1;
  schedule_id = '';
  schedule_name = '';
  daily: DailyChallenge[] = [];
  weekly: WeeklyChallenge[] = [];
  seasonal: SeasonalChallenge = new SeasonalChallenge();

  constructor(json?: any) {
    if (json) {
      this.season_number = json.seasonNumber;
      this.schedule_id = json.scheduleId;
      this.schedule_name = json.scheduleName;
      this.daily = json.daily.map((daily: any) => new DailyChallenge(daily));
      this.weekly = json.weekly.map((weekly: any) => new WeeklyChallenge(weekly));
      this.seasonal = new SeasonalChallenge(json.seasonal);
    }
  }

  toJson(): any {
    return {
      seasonNumber: this.season_number,
      scheduleId: this.schedule_id,
      scheduleName: this.schedule_name,
      daily: this.daily.map(daily => daily.toJson()),
      weekly: this.weekly.map(weekly => weekly.toJson()),
      seasonal: this.seasonal.toJson()
    };
  }
}

export class DailyChallenge {
  day_number = 0;
  challenges: ScheduledChallenge[] = [];

  constructor(json?: any) {
    if (json) {
      this.day_number = json.dayNumber;
      this.challenges = json.challenges.map((challenges: any) => new ScheduledChallenge(challenges));
    }
  }

  toJson(): any {
    return {
      dayNumber: this.day_number,
      challenges: this.challenges.map(challenges => challenges.toJson())
    };
  }
}

export class WeeklyChallenge {
  week_number = 0;
  challenges: ScheduledChallenge[] = [];

  constructor(json?: any) {
    if (json) {
      this.week_number = json.weekNumber;
      this.challenges = json.challenges.map((challenges: any) => new ScheduledChallenge(challenges));
    }
  }

  toJson(): any {
    return {
      weekNumber: this.week_number,
      challenges: this.challenges.map(challenges => challenges.toJson())
    };
  }
}

export class ScheduledChallenge {
  schedule_id = '';
  challenge_id = '';
  instance_id = '';
  required_challenges = [];
  is_limited = false;
  limited_start = '';
  limited_end = '';
  rewards: Reward[] = [];
  challenge_desc = '';
  challenge_name = '';
  goal_amount = 0;
  manual_completion = false;
  community_challenge = false;

  constructor(json?: any) {
    if (json) {
      this.schedule_id = json.scheduleId;
      this.challenge_id = json.challengeId;
      this.challenge_desc = json.challengeDesc;
      this.challenge_name = json.challengeName;
      this.instance_id = json.instanceId;
      this.required_challenges = json.requiredChallenges;
      this.is_limited = json.isLimited;
      this.limited_start = json.limitedStart;
      this.limited_end = json.limitedEnd;
      this.rewards = json.rewards;
      this.goal_amount = json.goalAmount;
      this.manual_completion = json.manualCompletion;
      this.community_challenge = json.communityChallenge;
    }
  }

  toJson(): any {
    return {
      scheduleId: this.schedule_id,
      challengeId: this.challenge_id,
      challengeDesc: this.challenge_desc,
      challengeName: this.challenge_name,
      instanceId: this.instance_id,
      requiredChallenges: this.required_challenges,
      isLimited: this.is_limited,
      limitedStart: this.limited_start,
      limitedEnd: this.limited_end,
      rewards: this.rewards,
      goalAmount: this.goal_amount,
      manualCompletion: this.manual_completion,
      communityChallenge: this.community_challenge,
    };
  }
}


export class ChallengeSchedulesService {
  static async getSeasonChallengeSchedule(seasonNumber: number): Promise<ChallengeSchedule> {
    return api.get({url: `/players/seasons/${seasonNumber}/challenge-schedule`})
      .then(challengeSchedule => new ChallengeSchedule(challengeSchedule))
      .catch((e: ApiError) => {
        e.message = `Failed to get season challenge schedule. ${e.message}`;
        throw e;
      });
  }

  static async getCurrentSeasonChallengeSchedule(): Promise<ChallengeSchedule> {
    return api.get({url: '/players/seasons/current/challenge-schedule'})
      .then(challengeSchedule => new ChallengeSchedule(challengeSchedule))
      .catch((e: ApiError) => {
        e.message = `Failed to get current season challenge schedule. ${e.message}`;
        throw e;
      });
  }

  static async createSeasonChallengeSchedule(seasonNumber: number, challengeScheduleJson: any): Promise<ChallengeSchedule> {
    return api.post({url: `/players/seasons/${seasonNumber}/challenge-schedule`, body: challengeScheduleJson})
      .then(challengeSchedule => new ChallengeSchedule(challengeSchedule))
      .catch((e: ApiError) => {
        e.message = `Failed to create season challenge schedule. ${e.message}`;
        throw e;
      });
  }

  static async updateSeasonChallengeSchedule(seasonNumber: number, challengeSchedule: ChallengeSchedule): Promise<ChallengeSchedule> {
    return api.put({url: `/players/seasons/${seasonNumber}/challenge-schedule`, body: challengeSchedule.toJson()})
      .then(challengeSchedule => new ChallengeSchedule(challengeSchedule))
      .catch((e: ApiError) => {
        e.message = `Failed to update season challenge schedule. ${e.message}`;
        throw e;
      });
  }

  static async deleteSeasonChallengeSchedule(seasonNumber: number): Promise<null> {
    return api.delete({url: `/players/seasons/${seasonNumber}/challenge-schedule`})
      .catch((e: ApiError) => {
        e.message = `Failed to delete season challenge schedule. ${e.message}`;
        throw e;
      });
  }
}

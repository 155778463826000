import type { Reducer } from 'redux';
import { createReducer } from 'typesafe-actions'
import type { Notification, ConfirmationDialogProps} from './actions';
import { setAppNotification, openConfirmationDialog, closeConfirmationDialog, setDarkMode } from './actions';

export interface AppState {
  notification: Notification | null;
  confirmation: {
    open: boolean;
    props: ConfirmationDialogProps;
    onConfirm: (note?: string) => void;
    onCancel?: () => void;
  },
  darkMode: boolean;
}

const initialState: AppState = {
  notification: null,
  confirmation: {
    open: false,
    props: { title: '' },
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    onConfirm: (note?: string) => { }
  },
  darkMode: false
};

const appReducer: Reducer<AppState> = createReducer(initialState)
  .handleAction(setAppNotification, (state, action) => ({
    ...state,
    notification: action.payload.notification
  }))
  .handleAction(openConfirmationDialog, (state, action) => ({
    ...state,
    confirmation: {
      open: true,
      ...action.payload
    }
  }))
  .handleAction(closeConfirmationDialog, state => ({
    ...state,
    confirmation: {
      ...state.confirmation,
      open: false
    }
  }))  
  .handleAction(setDarkMode, (state, action) => {
    // TODO: User preferences (+ backend support)
    if (action.payload.darkMode) {
      window.localStorage.darkMode = '1'; 
    } else {
      window.localStorage.removeItem('darkMode');
    }

    return {
      ...state,
      darkMode: action.payload.darkMode
    };
  });

export default appReducer;
import type {Theme} from '@material-ui/core';
import {
  Box,
  Button,
  createStyles,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  makeStyles,
  MenuItem
} from '@material-ui/core';
import {Field, Formik} from 'formik';
import {TextField} from 'formik-material-ui';
import { AwardTypeEnum, BadgeConditionRequirementEnum, BrawlModeEnum, BrawlPin, GameTypeEnum} from '../../../services/model/brawl-pin';
import {BrawlPinsService} from '../../../services/brawl-pins';
import * as Yup from 'yup';
import {useCallback} from 'react';
import {FormikSelectWithLabel} from '../../../components/FormikSelectWithLabel';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    form: {
      display: 'flex',
      flexDirection: 'column',
      margin: 'auto',
      width: 'fit-content',
      marginBottom: 8,
    },
    formControl: {
      marginTop: theme.spacing(2),
      minWidth: 400,
    },
  }),
);

const validationSchema = Yup.object().shape({
  name: Yup.string().required('Required'),
  awardType: Yup.string().required('Required'),
  brawlMode: Yup.string().required('Required'),
  gameType: Yup.string().required('Required'),
  badgeConditionMode: Yup.string().required('Required'),
});

const initialBrawlPin: BrawlPin = {
  id: '',
  name: '',
  awardType: AwardTypeEnum.Single,
  brawlMode: BrawlModeEnum.All,
  gameType: GameTypeEnum.Undefined,
  badgeConditionMode: BadgeConditionRequirementEnum.All,
  tiers: [],
};

interface AddBrawlPinDialogProps {
  onCancel: () => void,
  onSave: () => void,
}

export const AddBrawlPinDialog = ({ onCancel, onSave }: AddBrawlPinDialogProps) => {
  const classes = useStyles();

  const onSubmit = useCallback(async (values: Partial<BrawlPin>) => {
    delete values.id;
    await BrawlPinsService.createBrawlPin(values);
    onSave()
  }, [onSave]);

  return (
    <>
      <Dialog
        open={true}
        fullWidth
        onClose={onCancel}
        aria-labelledby="add-dialog-title"
      >
        <Formik<BrawlPin>
          initialValues={initialBrawlPin}
          validationSchema={validationSchema}
          validateOnMount={true}
          onSubmit={onSubmit}
        >
          {formikData => (<form onSubmit={formikData.handleSubmit}>
            <DialogTitle id="add-dialog-title">Add Brawl Pin</DialogTitle>
            <DialogContent className={classes.form}>
              <Box display="flex" flexDirection="column" justifyContent="center" className={classes.formControl}>
                <Field
                  component={TextField}
                  label={"Name"}
                  name="name"
                  type="text"
                  id="name"
                  value={name}
                />
              </Box>
              <Box mt={2} display="flex" flexDirection="column" justifyContent="center">
                <Field
                  component={FormikSelectWithLabel}
                  id="brawlMode"
                  name="brawlMode"
                  label={"Brawl Mode"}
                >
                  {Object.values(BrawlModeEnum).sort().map(brawlMode => (
                    <MenuItem key={brawlMode} value={brawlMode}>{brawlMode}</MenuItem>
                  ))}
                </Field>
              </Box>
              <Box mt={2} display="flex" flexDirection="column" justifyContent="center">
                <Field
                  component={FormikSelectWithLabel}
                  id="awardType"
                  name="awardType"
                  label={"Award Type"}
                >
                  {Object.values(AwardTypeEnum).sort().map(awardType => (
                    <MenuItem key={awardType} value={awardType}>{awardType}</MenuItem>
                  ))}
                </Field>
              </Box>
              <Box mt={2} display="flex" flexDirection="column" justifyContent="center">
                <Field
                  component={FormikSelectWithLabel}
                  id="gameType"
                  name="gameType"
                  label={"Game Type"}
                >
                  {Object.values(GameTypeEnum).sort().map(gameType => (
                    <MenuItem key={gameType} value={gameType}>{gameType}</MenuItem>
                  ))}
                </Field>
              </Box>
              <Box mt={2} display="flex" flexDirection="column" justifyContent="center">
                <Field
                  component={FormikSelectWithLabel}
                  id="badgeConditionMode"
                  name="badgeConditionMode"
                  label={"Tier Conditions Match Requirement"}
                >
                  {Object.values(BadgeConditionRequirementEnum).map(requirement => (
                    <MenuItem key={requirement} value={requirement}>{requirement}</MenuItem>
                  ))}
                </Field>
              </Box>
            </DialogContent>
            <DialogActions>
              <Button
                type="submit"
                variant="contained"
                disabled={!formikData.isValid}
                color="primary"
              >
                Save
              </Button>
              <Button onClick={onCancel} color="primary">
                Cancel
              </Button>
            </DialogActions>
          </form>)}
        </Formik>
      </Dialog>
    </>
  );
}

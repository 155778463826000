import type { Query } from 'material-table';

export interface PaginatedRequest {
  sortBy: string;
  sortOrder?: string;
  page: number;
  pageSize: number;
  search?: string;
  [key: string]: any;
}

export class PaginatedResponse<T> {
  items: T[];
  totalCount: number;

  constructor(json: any, itemMapper: (json: any) => T) {
    this.totalCount = json.totalCount;
    this.items = json.items.map(itemMapper);
  }
}

// eslint-disable-next-line @typescript-eslint/ban-types
export function paginatedRequestFromQuery<T extends object>(query: Query<T>, defaultSortColumn: keyof T = ('name' as keyof T)): PaginatedRequest {
  return {
    sortBy: query.orderBy && typeof query.orderBy.field === 'string' ? query.orderBy.field : defaultSortColumn as string,
    sortOrder: query.orderDirection || 'asc',
    page: query.page + 1,
    pageSize: query.pageSize,
    search: query.search,
  };
}

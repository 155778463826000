import { openAdminConfirmationDialog } from '../../shared/hooks/useAdminConfirmationDialog';
import type { Column } from 'material-table';
import type { ItemInstance } from '../../services/model/item-instance';
import { ItemDefinition } from '../../services/item-definitions';
import type { DropTable } from '../../services/drop-tables';
import { Box, Grid, Button, Link } from '@material-ui/core';
import type { MenuAction } from '../../components/MaterialTable';
import MaterialTable from '../../components/MaterialTable';
import ItemSelectDialog from '../../pages/ItemDefinition/ItemSelectDialog';
import DeleteIcon from '@material-ui/icons/Delete';
import { UserService } from '../../services/user';
import { RouterLink } from '../../types';
import ListAltIcon from '@material-ui/icons/ListAlt';
import ItemCustomDataDialog from '../../components/ItemCustomDataDialog';
import { useState, useEffect, useCallback } from 'react';
import { BlankosService } from '../../services/blankos';
import type { Blanko } from '../../services/blankos';
import { pushAppNotification } from '../../shared/hooks/useAppNotification';

type Props = {
  blanko?: Blanko;
}

export const BlankoItems = ({ blanko }: Props) => {
  const [itemsDialogOpen, setItemsDialogOpen] = useState(false);
  const [customDataItem, setCustomDataItem] = useState<ItemInstance>();
  const [items, setItems] = useState<ItemInstance[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  const columns: Column<ItemInstance>[] = [
    { title: 'Instance ID', field: 'id' },
    {
      title: 'Catalog', field: 'catalogName', render: item => (
        <Link component={RouterLink} to={`/catalogs/${item.catalogName}`}>
          {item.catalogName}
        </Link>
      )
    },
    {
      title: 'Item ID', field: 'itemId', render: item => (
        <Link component={RouterLink} to={`/catalogs/${item.catalogName}/items/${item.itemId}`}>
          {item.itemId}
        </Link>
      )
    },
    { title: 'Name', field: 'displayName', render: item => <>{item.displayName || item.itemId}</> },
    { title: 'dGood #', field: 'dgoodSerial', render: item => <>{item.dgoodSerial || '-'}</> },
    { title: 'Class', field: 'itemClass' },
    { title: 'Created/purchased at', field: 'purchaseTimestamp', defaultSort: 'desc', render: item => <>{item.purchaseTimestamp.toLocaleString()}</> }
  ];

  const getData = useCallback(async () => {
    setIsLoading(true);
    if (blanko) {
      const items = await BlankosService.getItems(blanko.id);
      setItems(items);
    }
    setIsLoading(false);
  }, [blanko]);

  useEffect(() => {
    getData();
  }, [getData]);

  const getMenuActions = () => {
    const actions: MenuAction<ItemInstance>[] = [];
    if (UserService.canRead('itemInstances') || UserService.canUpdate('itemInstances')) {
      actions.push({ type: 'button', icon: ListAltIcon, label: 'Custom data', onClick: item => setCustomDataItem(item) });
    }

    if (UserService.canDelete('itemInstances')) {
      if (actions.length > 0) {
        actions.push({ type: 'divider' });
      }
      actions.push({ type: 'button', icon: DeleteIcon, label: 'Revoke', onClick: onRevokeItem });
    }
    return actions;
  }

  const onGrantItemSelect = (items: (ItemDefinition | DropTable)[]) => {
    if (!blanko) {
      return;
    }

    const item = items[0];
    if (item instanceof ItemDefinition) {
      BlankosService.grantItems(blanko.id, item.catalogName, [item.itemId]);
    }
  }

  const onRevokeItem = (item: ItemInstance) => {
    openAdminConfirmationDialog({
      title: `Revoke ${item.displayName || item.itemId}?`, action: 'Revoke item',
      onConfirm: () => {
        if (!blanko) {
          return;
        }
        BlankosService.revokeItem(blanko.id, item.id);
      }
    });
  }

  return (<>
    {UserService.canGrantItems() && (
      <Box mb={2}>
        <Grid container alignItems="center">
          <Grid item xs />
          <Grid item>
            <Button
              variant="contained"
              color="primary"
              onClick={() => setItemsDialogOpen(true)}
            >
              Grant item
            </Button>
          </Grid>
        </Grid>
      </Box>
    )}

    <MaterialTable
      title="Items"
      storageId="blanko-items"
      columns={columns}
      data={items}
      isLoading={isLoading}
      options={{
        pageSize: 20,
        pageSizeOptions: [20, 50, 100],
        columnsButton: true
      }}
      menuActions={getMenuActions}
      useRouter
    />

    {itemsDialogOpen && (
      <ItemSelectDialog
        showCatalogSelect
        onSelect={onGrantItemSelect}
        onClose={() => setItemsDialogOpen(false)}
      />
    )}

    {customDataItem && (
      <ItemCustomDataDialog
        item={customDataItem}
        onSave={item => pushAppNotification({ type: 'success', message: `Blanko item updated` })}
        onClose={() => setCustomDataItem(undefined)}
      />
    )}
  </>);
}

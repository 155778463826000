import {useCallback, useEffect, useState} from "react";
import type {ApiError} from "../../../services/api";
import {deleteTagAsync, getTags} from "../accountApi";
import type{PlayerTag} from "../accounts";
import {Box, Button, CircularProgress} from "@material-ui/core";
import {AdminTable} from "../../../shared/components/AdminTable";
import type {RowMenuAction} from "../../../shared/components/AdminTable";
import {UserService} from "../../../services/user";
import {Create, Delete} from "@material-ui/icons";
import type {Column} from "material-table";
import {TagForm} from "../components/TagForm";
import ConfirmationDialog from "../../../components/ConfirmationDialog";
import {pushAppNotification} from "../../../shared/hooks/useAppNotification";

export const Tags = () => {
  const columns: Column<PlayerTag>[] = [
    {
      title: 'Tag',
      field: 'tag'
    },
    {
      title: 'Visibility',
      field: 'visibility',
      render(tag: PlayerTag) { return <>{ tag.visibility.join(", ") }</> }
    },
    {
      title: 'Description',
      field: 'description'
    },
    {
      title: 'Created Date',
      field: 'createdAt',
      render(tag: PlayerTag) { return <>{ tag.createdAt?.toLocaleString() }</> }
    },
    {
      title: 'Modified Date',
      field: 'modifiedAt',
      render(tag: PlayerTag) { return <>{ tag.modifiedAt?.toLocaleString() }</> }
    }

  ]

  const [tags, setTags] = useState<PlayerTag[] | null>(null)
  const [menuItems, setMenuItems] = useState<RowMenuAction<PlayerTag>[] | undefined>()
  const [playerTagFormOpen, setPlayerTagFormOpen] = useState(false)
  const [rowData, setRowData] = useState<PlayerTag | undefined>()
  const [confirmation, setConfirmation] = useState(false)

  useEffect(() => {
    if (tags === null) {
      getTags().then(setTags)
        .catch((e: ApiError) => pushAppNotification({
          type: 'error',
          message: `Failed to load tags. ${e.message}`
        }));
    }
  }, [tags]);

  const getData = useCallback(async () => {
    const data = await getTags();
    setTags(data)
  }, [])

  const onEdit = useCallback((row: PlayerTag) => {
    setPlayerTagFormOpen(true)
    setRowData(row)
  }, [])

  const onAdd = useCallback(() => {
    setPlayerTagFormOpen(true)
  }, [])

  const onRemove = useCallback((row: PlayerTag) => {
    setConfirmation(true)
    setRowData(row)
  }, [])

  const onConfirm = useCallback(() => {
    if (typeof rowData === 'object' && rowData.id) {
      rowData && deleteTagAsync(rowData.id)
        .then(() => {
          setRowData(undefined)
          getData()
          setConfirmation(false)
        }).then(() => pushAppNotification({
          type: 'success',
          message: `Successfully deleted tag. ${rowData.tag}`
        })).catch((e: ApiError) => pushAppNotification({
          type: 'error',
          message: `Failed to delete tag. ${e.message}`
        }));
    }
  }, [rowData, getData])

  const onSubmit = useCallback(() => {
    const timer = setTimeout(() => {
      getData();
    }, 300);
    return () => clearTimeout(timer)
  }, [getData]);

  useEffect(() => {
    const items: RowMenuAction<PlayerTag>[] = []
    UserService.canUpdate('mythicalAccount') && items.push({ type: 'button', icon: Create, label: 'Edit', onClick: onEdit })
    UserService.canUpdate('mythicalAccount') && items.push({ type: 'button', icon: Delete, label: 'Delete', onClick: onRemove })
    setMenuItems(items)
  }, [onEdit, onRemove])

  if (!tags) {
    return (
      <Box textAlign="center">
        <CircularProgress />
      </Box>
    );
  }

  return (<>
    <Box mb={2} textAlign="right">
      <Button variant="contained" color="primary" onClick={onAdd}>
        Add New
      </Button>
    </Box>
    {tags && <AdminTable<PlayerTag>
      title="Tags"
      storageId="tags"
      columns={columns}
      data={tags}
      options={{
        columnsButton: true,
        pageSize: 10,
        pageSizeOptions: [5, 10, 20]
      }}
      menuActions={menuItems}
    />}

    {playerTagFormOpen && (
      <TagForm
        onClose={() => setPlayerTagFormOpen(false)}
        onSubmit={onSubmit}
        show={playerTagFormOpen}
        tag={rowData}
      />
    )}
    {confirmation &&
      <ConfirmationDialog
        title={`Delete ${rowData?.tag}?`}
        details={`Are you sure you want to delete ${rowData?.tag}?`}
        open={confirmation}
        onClose={() => setConfirmation(false)}
        onConfirm={onConfirm}
      />}
  </>);

}
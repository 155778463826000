import type { PaginatedRequest} from "./model/pagination";
import { PaginatedResponse } from "./model/pagination";
import type { ApiError } from "./api";
import { api } from "./api";

export class EntitlementRewards {
  id = '';
  description = '';
  entitlements: Entitlement[] = [];
  active = false;
  type = '';
  urlPath = '';
  startDate: Date | null = null;
  endDate: Date | null = null;

  constructor(json?: any) {
    if (json) {
      this.id = json.id;
      this.description = json.description;
      this.entitlements = json.entitlements || [];
      this.type = json.type;
      this.active = false;
      this.urlPath = json.urlPath;
      if (typeof json.startDate === 'string') {
        this.startDate = new Date(json.startDate);
      }
      if (typeof json.endDate === 'string') {
        this.endDate = new Date(json.endDate);
      }
    }
  }
}

export class Entitlement {
  itemId = '';
  catalogName = '';
  maxAvailableCount = 0;
  currentAvailableCount = 0;
  weight = 0;

  constructor(json?: any) {
    if (json) {
      this.itemId = json.itemId;
      this.catalogName = json.catalogName;
      this.maxAvailableCount = json.maxAvailableCount;
      this.currentAvailableCount = json.currentAvailableCount;
      this.weight = json.weight;
    }
  }
}

export class EntitlementRewardsService {
  static async getEntitlementRewards(request: PaginatedRequest): Promise<PaginatedResponse<EntitlementRewards>> {
    return api.get({ url: '/rewards', query: request })
      .then(rewards => new PaginatedResponse(rewards, rewards => new EntitlementRewards(rewards)))
      .catch((e: ApiError) => {
        e.message = `Failed to get rewards. ${e.message}`;
        throw e;
      });
  }

  static async getEntitlementReward(id: string): Promise<EntitlementRewards> {
    return api.get({ url: `/rewards/${id}` })
      .then(reward => new EntitlementRewards(reward))
      .catch((e: ApiError) => {
        e.message = `Failed to get reward. ${e.message}`;
        throw e;
      });
  }

  static async createEntitlementReward(rewardJson: any): Promise<EntitlementRewards> {
    return api.post({ url: '/rewards', body: rewardJson })
      .then(reward => new EntitlementRewards(reward))
      .catch((e: ApiError) => {
        e.message = `Failed to create reward. ${e.message}`;
        throw e;
      });
  }

  static async updateEntitlementReward(reward: EntitlementRewards): Promise<EntitlementRewards> {
    return api.put({ url: '/rewards', body: reward })
      .then(reward => new EntitlementRewards(reward))
      .catch((e: ApiError) => {
        e.message = `Failed to update reward. ${e.message}`;
        throw e;
      });
  }

  static async deleteEntitlementReward(id: string): Promise<null> {
    return api.delete({ url: `/rewards/${id}` })
      .catch((e: ApiError) => {
        e.message = `Failed to delete reward. ${e.message}`;
        throw e;
      });
  }

  static async getAccountLinks(request: PaginatedRequest): Promise<PaginatedResponse<AccountLinkingResponse>> {
    return api.get({ url: '/rewards/account-linking', query: request })
      .catch((e: ApiError) => {
        e.message = `Failed to get account links. ${e.message}`;
        throw e;
      });
  }

  static async getEntitlementFulfillments(request: PaginatedRequest): Promise<PaginatedResponse<EntitlementFulfillment>> {
    return api.get({ url: '/rewards/fulfillment', query: request })
      .catch((e: ApiError) => {
        e.message = `Failed to get fulfillments. ${e.message}`;
        throw e;
      });
  }

  static async checkForUnclaimedRewards(): Promise<UnclaimedRewardsResponse> {
    return api.post({ url: '/rewards/unclaimed-rewards' })
      .catch((e: ApiError) => {
        e.message = `Failed to get fulfillments. ${e.message}`;
        throw e;
      });
  }

  static async canCheckForUnclaimedRewards(): Promise<UnclaimedRewardsResponse> {
    return api.get({ url: `/rewards/can-check-unclaimed-rewards` })
      .catch((e: ApiError) => {
        e.message = `Failed to check for unclaimed rewards. ${e.message}`;
        throw e;
      });
  }

  static async deleteAccountLink(id: string): Promise<null> {
    return api.delete({ url: `/players/account-linking/${id}` })
      .catch((e: ApiError) => {
        e.message = `Failed to delete account link. ${e.message}`;
        throw e;
      });
  }
}
import Box from '@material-ui/core/Box';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';
import { Component } from 'react';
import type { ConnectedProps } from 'react-redux';
import { connect } from 'react-redux';
import type { RouteComponentProps } from 'react-router';
import type { LinkTabConfig} from '../../components/LinkTabs';
import { LinkTabs } from '../../components/LinkTabs';
import { setCatalogName } from '../../redux/catalog/actions';
import { getCatalogItemAsync, updateCatalogItemAsync } from '../../redux/catalog/items/actions';
import type { RootState } from '../../redux/reducers';
import type { ItemDefinition } from '../../services/item-definitions';
import ItemDefinitionForm from './ItemDefinitionForm';
import ItemTokenForm from './ItemTokenForm';

const mapStateToProps = (state: RootState) => ({
  item: state.catalog.items.currentItem
});
const mapDispatch = {
  setCatalogName,
  requestItem: getCatalogItemAsync.request,
  requestUpdateItem: updateCatalogItemAsync.request
};
const connector = connect(mapStateToProps, mapDispatch);

interface RouteProps {
  catalogName: string;
  itemId: string;
}
type Props = RouteComponentProps<RouteProps> & ConnectedProps<typeof connector>;

class ItemDefinitionPage extends Component<Props> {
  private tabs: LinkTabConfig[] = [
    {
      url: '',
      label: 'Properties',
      render: () => (
        <ItemDefinitionForm
          catalogName={this.props.match.params.catalogName}
          item={this.props.item.data || undefined}
          onSave={this.onFormSave}
        />
      )
    },
    {
      url: '/token',
      label: 'Token',
      render: () => this.props.item.data ? <ItemTokenForm item={this.props.item.data} token={this.props.item.token} /> : <></>
    },
  ];

  componentDidMount() {
    this.props.setCatalogName(this.props.match.params.catalogName);
    this.props.requestItem(this.props.match.params.itemId);
  }

  private getTitle() {
    return this.props.item.data ? (this.props.item.data.displayName || this.props.item.data.itemId) : '';
  }

  onFormSave = (item: ItemDefinition) => {
    this.props.requestUpdateItem(item);
  };

  renderForm() {
    if (this.props.item.isLoading || !this.props.item.data) {
      return (
        <Box textAlign="center">
          <CircularProgress />
        </Box>
      );
    } else {
      return (
        <LinkTabs tabs={this.tabs} {...this.props} />
      );
    }
  }

  render() {
    return (<>
      <Box py={2}>
        <Typography variant="h6">
          {this.getTitle()}
        </Typography>
      </Box>
      {this.renderForm()}
    </>);
  }

}

export default connector(ItemDefinitionPage);
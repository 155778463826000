import { LiveEventsTable } from "./LiveEventsTable";
import { RouteComponentProps } from "react-router";
import { useCallback, useEffect, useState } from "react";
import { LiveEvent } from "../live-events-types";
import { usePushNotification } from "../../../contexts/AppNotificationContext";
import { getLiveEvents } from "../live-events-api";
import { Box, CircularProgress } from "@material-ui/core";
import { LiveEventEditor } from "./LiveEventEditor";

type Props = RouteComponentProps<{ liveEventId?: string; }>;

export const LiveEvents = (props: Props) => {
  const liveEventId = props.match.params.liveEventId;
  const addMode = props.location.pathname.endsWith('/live-events/new');
  const showEditor = !!liveEventId || addMode;
  const [liveEvents, setLiveEvents] = useState<LiveEvent[]>();
  const pushNotification = usePushNotification();

  const refreshLiveEvents = useCallback(async () => {
    return getLiveEvents()
      .then(events => setLiveEvents(events))
      .catch(err => pushNotification({type: 'error', message: `Failed to get live events: ${err.message}`}));
  }, [pushNotification]);

  useEffect(() => {
    refreshLiveEvents();
  }, [refreshLiveEvents]);

  if (!liveEvents) {
    return <Box textAlign="center">
      <CircularProgress/>
    </Box>;
  }
  if (showEditor) {
    if (liveEvents && liveEventId) {
      const liveEventToEdit = liveEvents.find(event => event.id === liveEventId);
      if (!liveEventToEdit) {
        return <Box textAlign="center">
          Live event with id {liveEventId} not found.
        </Box>;
      }
      return <LiveEventEditor {...props} eventToEdit={liveEventToEdit} refreshLiveEvents={refreshLiveEvents}/>;
    }
    return <LiveEventEditor {...props} refreshLiveEvents={refreshLiveEvents}/>;
  }
  return <LiveEventsTable {...props} liveEvents={liveEvents} refreshLiveEvents={refreshLiveEvents} />;
};

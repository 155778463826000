import { CatalogMigrationService } from '../../../../services/catalog-migration';
import type { ShopVersion } from '../../../shops/shop';
import type { MigrationModule } from '../../migrations';
import { buildDiffData } from '../../migrations';
import { ShopVersionDiff } from './shop-version-diff';
import { ShopVersionDiffs } from './ShopVersionDiffs';
import { ShopSelector } from './ShopVersionSelector';
import { getEnvironmentFeatures } from "../../../../services/environment-configuration";
import { MigrationType } from "../migration-type";

const translateGridWidth = (sourceShopVersion: ShopVersion, sourceGridWidthSegments: number, targetGridWidthSegments: number) => {
  const factor = targetGridWidthSegments / sourceGridWidthSegments;
  sourceShopVersion.tabs
    .forEach(tab => tab.items
      .forEach(tabItem => {
        tabItem.gridWidth = Math.ceil(tabItem.gridWidth * factor);
      }));
};

export const shopVersionMigration: MigrationModule<ShopVersionDiff> = {
  id: MigrationType.shopVersion,
  displayName: 'Shops',
  diffComponent: ShopVersionDiffs,
  crossEnvOnly: true,
  preloadSteps: [
    { id: 'shopSelect', title: 'Select shops to migrate', component: ShopSelector }
  ],

  loadData: async (sourceData, targetData, migrationData) => {
    if (!sourceData.shops || !sourceData.shopVersions) {
      throw new Error('Shops or versions not loaded yet.');
    }
    const sourceEnvironmentFeatures = await getEnvironmentFeatures(sourceData.env);
    const targetEnvironmentFeatures = await getEnvironmentFeatures(targetData.env);

    targetData.shops = await CatalogMigrationService.getShops(targetData.env);
    const targetShopMap: { [key: string]: ShopVersion[] } = {};

    const sourceDiffs: ShopVersionDiff[] = [];
    const targetDiffs: ShopVersionDiff[] = [];

    for (let i = 0; i < sourceData.shopVersions.length; i++) {
      sourceData.shopVersions[i] = await CatalogMigrationService.getShopVersion(sourceData.env, sourceData.shopVersions[i].id);
      translateGridWidth(sourceData.shopVersions[i], sourceEnvironmentFeatures.gridWidthSegments, targetEnvironmentFeatures.gridWidthSegments);
    }

    for (const shopVersion of sourceData.shopVersions) {
      const shop = sourceData.shops.find(v => v.id === shopVersion.shopId);
      if (!shop) {
        throw new Error(`Shop not found: ${shopVersion.shopId}`);
      }

      sourceDiffs.push(new ShopVersionDiff(shop, shopVersion));

      const targetShop = targetData.shops.find(v => v.name === shop.name);
      if (targetShop) {
        let targetShopVersions = targetShopMap[shop.name];
        if (!targetShopVersions) {
          targetShopVersions = await CatalogMigrationService.getShopVersions(targetData.env, targetShop.id);
          targetShopMap[shop.name] = targetShopVersions;
        }

        let targetShopVersion = targetShopVersions.find(v => v.name === shopVersion.name);
        if (targetShopVersion) {
          targetShopVersion = await CatalogMigrationService.getShopVersion(targetData.env, targetShopVersion.id);
          targetDiffs.push(new ShopVersionDiff(targetShop, targetShopVersion));
        }
      }
    }

    targetData.shopVersions = targetDiffs.map(v => v.shopVersion);
    migrationData.shopVersions = buildDiffData(sourceDiffs, targetDiffs);
    return migrationData.shopVersions;
  },

  runMigration: async (props, setStatus) => {
    const { targetData, migrationData } = props;

    if (!targetData.shops || !migrationData.shopVersions) {
      throw new Error('Shops or versions not loaded yet.');
    }

    const getFilename = (url: string) => {
      const index = url.lastIndexOf('/');
      return index > -1 ? url.slice(index + 1) : url;
    }

    const selections = props.selections.shopVersion || {};
    const shopVersionDiffs = migrationData.shopVersions.added.concat(migrationData.shopVersions.changed).filter(diff => Boolean(selections[diff.diffId]));

    if (shopVersionDiffs.length < 1) {
      return;
    }

    // Once images are transferred, this will be a map of image filenames -> target image urls
    const imageMap: { [key: string]: string } = {};

    // Find all the new images
    for (const diff of shopVersionDiffs) {
      diff.shopVersion.tabs.forEach(tab => tab.items.forEach(item => {
        if (item.imageUrl) {
          imageMap[getFilename(item.imageUrl)] = item.imageUrl;
        }
      }));

      if (diff.diffPrev instanceof ShopVersionDiff) {
        diff.diffPrev.shopVersion.tabs.forEach(tab => tab.items.forEach(item => {
          if (item.imageUrl) {
            delete imageMap[getFilename(item.imageUrl)];
          }
        }));
      }
    }

    // Transfer any new images first
    const imageCount = Object.values(imageMap).length;
    let i = 1;
    for (const imageFilename in imageMap) {
      setStatus(`Transferring image ${i} of ${imageCount}...`, 50.0 * (i - 1) / imageCount);
      const imageData = await CatalogMigrationService.transferStoreItemImage(targetData.env, imageMap[imageFilename], imageFilename);
      imageMap[imageFilename] = imageData.publicUrl;
      i++;
    }

    // Add back any images already preset in the target env
    for (const diff of shopVersionDiffs) {
      if (diff.diffPrev instanceof ShopVersionDiff) {
        diff.diffPrev.shopVersion.tabs.forEach(tab => tab.items.forEach(item => {
          if (item.imageUrl) {
            imageMap[getFilename(item.imageUrl)] = item.imageUrl;
          }
        }));
      }
    }

    let progress = 50.0;


    // Migrate every shop version
    for (const diff of shopVersionDiffs) {
      setStatus(`Migrating ${diff.shop.name} - ${diff.shopVersion.name}...`, progress);

      // Create the shop first, if it doesn't exist
      let targetShop = targetData.shops.find(v => v.name === diff.shop.name);
      if (!targetShop) {
        targetShop = await CatalogMigrationService.createShop(targetData.env, diff.shop.name);
        targetData.shops.push(targetShop);
      }

      const newVersionJson = { ...diff.shopVersion, shopId: targetShop.id };
      newVersionJson.tabs = newVersionJson.tabs.map(tab => ({
        ...tab,
        items: tab.items.map(item => ({
          ...item,
          imageUrl: item.imageUrl === null ? null : imageMap[getFilename(item.imageUrl)]
        }))
      }));

      const newVersion = await CatalogMigrationService.createShopVersion(targetData.env, newVersionJson);

      // TODO: Handle shop version upserts in the backend, ideally without changing version ids.
      if (diff.diffPrev instanceof ShopVersionDiff) {
        try {
          // Keep existing canary status
          if (diff.diffPrev.shop.canaryVersionId === diff.diffPrev.shopVersion.id) {
            await CatalogMigrationService.updateShop(targetData.env, { ...diff.diffPrev.shop, canaryVersionId: newVersion.id });
          }

          // Keep existing player access
          const playerIds = await CatalogMigrationService.getShopVersionPlayerAccess(targetData.env, diff.diffPrev.shopVersion.id);
          if (playerIds.length > 0) {
            await CatalogMigrationService.grantShopVersionPlayerAccess(targetData.env, newVersion.id, playerIds);
          }

          await CatalogMigrationService.deleteShopVersion(targetData.env, diff.diffPrev.shopVersion.id);
        } catch (e) {
          await CatalogMigrationService.deleteShopVersion(targetData.env, newVersion.id);
          throw e;
        }
      }

      progress += 50.0 / shopVersionDiffs.length;
    }
  }
}

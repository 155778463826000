import { Box, Button, Card, CardContent, CircularProgress, Grid, IconButton, Table, TableBody, TableCell, TableHead, TableRow, Tooltip, Typography } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import WarningIcon from '@material-ui/icons/Warning';
import type { FieldArrayRenderProps, FormikHelpers } from 'formik';
import { Field, FieldArray, Form, Formik } from 'formik';
import { TextField } from 'formik-material-ui';
import { Component } from 'react';
import { DropTable, DropTableNode } from '../../services/drop-tables';
import { ItemDefinition } from '../../services/item-definitions';
import { createValidator, Validators } from '../../utils/forms';
import ItemSelectDialog from '../ItemDefinition/ItemSelectDialog';
import { UserService } from '../../services/user';

interface FormDropTableNode {
  id: string;
  type: 'item' | 'droptable';
  weight: string;
  displayName: string;
}

interface FormValues {
  id: string;
  nodes: FormDropTableNode[];
}

const contentValidators = createValidator<FormDropTableNode>({
  weight: Validators.integer(1)
});

const formValidator = createValidator<FormValues>({
  id: Validators.notBlank()
}, values => {
  const newErrors: any = {};
  if (values.nodes.length > 0) {
    const nodesErrors = values.nodes.map(item => contentValidators(item));
    if (nodesErrors.find(v => !!v)) {
      newErrors.nodes = nodesErrors;
    }
  } else {
    newErrors.nodes = 'You need at least one item';
  }
  return newErrors;
});

interface Props {
  catalogName: string;
  dropTable?: DropTable;
  onSave?: (dropTable: DropTable) => void;
}

interface State {
  initialFormValues: FormValues;
  contentsDialogOpen: boolean;
}

export default class DropTableForm extends Component<Props, State> {
  private setSubmitting: ((submitting: boolean) => void) | undefined;

  constructor(props: Props) {
    super(props);

    this.state = {
      initialFormValues: this.dropTableToFormValues(this.props.dropTable),
      contentsDialogOpen: false
    }
  }

  private dropTableToFormValues(dropTable?: DropTable): FormValues {
    if (!dropTable) {
      return {
        id: '',
        nodes: []
      };
    }

    return {
      id: dropTable.id,
      nodes: dropTable.nodes.map(node => ({
        id: node.id,
        type: node.type,
        weight: node.weight.toString(),
        displayName: node.displayName
      }))
    };
  }

  componentDidUpdate(prevProps: Props) {
    if (prevProps.dropTable !== this.props.dropTable) {
      this.setState({ initialFormValues: this.dropTableToFormValues(this.props.dropTable) });
      if (this.setSubmitting) {
        this.setSubmitting(false);
      }
    }
  }

  onContentsRemove(arrayHelpers: FieldArrayRenderProps, id: string, type: string) {
    const values = arrayHelpers.form.values as FormValues;
    const index = values.nodes.findIndex(v => v.id === id && v.type === type);
    if (index > - 1) {
      arrayHelpers.remove(index);
    }
  }

  onContentsAdd(arrayHelpers: FieldArrayRenderProps, items: (ItemDefinition | DropTable)[]) {
    items.forEach(item => {
      const formNode: FormDropTableNode = item instanceof ItemDefinition ? {
        id: item.itemId,
        weight: '1',
        type: 'item',
        displayName: item.displayName
      } : {
        id: item.id,
        weight: '1',
        type: 'droptable',
        displayName: ''
      };
      arrayHelpers.unshift(formNode);
    });
  }

  onSubmit = (values: FormValues, helpers: FormikHelpers<FormValues>) => {
    const dropTable = new DropTable();
    dropTable.catalogName = this.props.catalogName;
    dropTable.id = values.id;
    dropTable.nodes = values.nodes.map(nodeValues => {
      const node = new DropTableNode();
      node.id = nodeValues.id;
      node.type = nodeValues.type;
      node.weight = parseInt(nodeValues.weight);
      return node;
    });

    if (this.props.onSave) {
      this.props.onSave(dropTable);
    }

    this.setSubmitting = helpers.setSubmitting;
  }

  render() {
    const readOnly = (this.props.dropTable && !UserService.canUpdate('catalogItems')) || (!this.props.dropTable && !UserService.canCreate('catalogItems'));

    return (
      <Formik<FormValues>
        initialValues={this.state.initialFormValues}
        enableReinitialize
        validate={formValidator}
        validateOnMount
        onSubmit={this.onSubmit}
      >
        {({ submitForm, isSubmitting, values, errors }) => (
          <Form>
            <Grid container spacing={3}>
              <Grid item xs={12} md={6}>
                <Card>
                  <CardContent>
                    <Field
                      component={TextField}
                      name="id"
                      type="text"
                      label="ID"
                      fullWidth
                      required
                      InputProps={{
                        readOnly: !!this.props.dropTable
                      }}
                    />
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={12}>
                <Card>
                  <Box p={2}>
                    <Grid container spacing={2} alignItems="center">
                      <Grid item>
                        <Typography variant="h5">
                          Contents
                        </Typography>
                      </Grid>
                      <Grid item xs>
                        {typeof errors.nodes === 'string' && (
                          <Tooltip title={errors.nodes} placement="top">
                            <WarningIcon style={{ display: 'block' }} color="error" />
                          </Tooltip>
                        )}
                      </Grid>
                      {!readOnly && (
                        <Grid item>
                          <Button
                            variant="contained"
                            color="primary"
                            disabled={isSubmitting}
                            onClick={() => this.setState({ contentsDialogOpen: true })}
                          >
                            Add
                          </Button>
                        </Grid>
                      )}
                    </Grid>
                  </Box>
                  <CardContent>
                    <FieldArray name="nodes" render={arrayHelpers => (<>
                      <Table size="small">
                        <TableHead>
                          <TableRow>
                            {!readOnly && <TableCell> </TableCell>}
                            <TableCell>Type</TableCell>
                            <TableCell>Weight</TableCell>
                            <TableCell>ID</TableCell>
                            <TableCell>Name</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {values.nodes.map((item, index) => (
                            <TableRow key={`${item.type}-${item.id}`}>
                              {!readOnly && (
                                <TableCell padding="none" style={{ width: 1 }}>
                                  <IconButton onClick={() => this.onContentsRemove(arrayHelpers, item.id, item.type)}>
                                    <DeleteIcon />
                                  </IconButton>
                                </TableCell>
                              )}
                              <TableCell style={{ whiteSpace: 'nowrap', width: 1 }}>{item.type === 'item' ? 'Item' : 'Drop table'}</TableCell>
                              <TableCell style={{ width: 100 }}>
                                <Field
                                  component={TextField}
                                  name={`nodes[${index}].weight`}
                                  type="text"
                                  label=""
                                  fullWidth
                                  InputProps={{ readOnly }}
                                />
                              </TableCell>
                              <TableCell>{item.id}</TableCell>
                              <TableCell>{item.displayName}</TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                      {this.state.contentsDialogOpen && (
                        <ItemSelectDialog
                          multi
                          includeDropTables
                          addedItems={values.nodes.filter(v => v.type === 'item').map(v => v.id)}
                          addedDropTables={values.nodes.filter(v => v.type === 'droptable').map(v => v.id)}
                          onSelect={items => this.onContentsAdd(arrayHelpers, items)}
                          onClose={() => this.setState({ contentsDialogOpen: false })}
                        />)}
                    </>)} />
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
            {!readOnly && (
              <Box pt={2}>
                <Button color="primary" variant="contained" onClick={submitForm} disabled={isSubmitting}>
                  {isSubmitting ? (
                    <CircularProgress size={25} />
                  ) : 'Save'}
                </Button>
              </Box>
            )}
          </Form>
        )}
      </Formik>
    );
  }
}
import {call, put, takeLatest} from 'redux-saga/effects';
import type {ChallengeSchedule} from '../../../services/player-challenges/season-challenge-schedule';
import { ChallengeSchedulesService} from '../../../services/player-challenges/season-challenge-schedule';
import {setAppNotification} from '../../app/actions';
import {forkSagas, sagaHandleApiError} from '../../utils-ts';
import {createSeasonChallengeScheduleAsync, deleteSeasonChallengeScheduleAsync, getCurrentSeasonChallengeScheduleAsync, getSeasonChallengeScheduleAsync, updateSeasonChallengeScheduleAsync} from './actions';

function* fetchSeasonChallengeSchedule(action: ReturnType<typeof getSeasonChallengeScheduleAsync.request>) {
  try {
    const season: ChallengeSchedule = yield call(ChallengeSchedulesService.getSeasonChallengeSchedule, action.payload);
    yield put(getSeasonChallengeScheduleAsync.success(season));
  } catch (e) {
    yield sagaHandleApiError(e, getSeasonChallengeScheduleAsync.failure);
  }
}

function* fetchCurrentSeasonChallengeSchedule(action: ReturnType<typeof getCurrentSeasonChallengeScheduleAsync.request>) {
  try {
    const season: ChallengeSchedule = yield call(ChallengeSchedulesService.getCurrentSeasonChallengeSchedule);
    yield put(getCurrentSeasonChallengeScheduleAsync.success(season));
  } catch (e) {
    yield sagaHandleApiError(e, getCurrentSeasonChallengeScheduleAsync.failure);
  }
}

function* createSeasonChallengeScheduleSaga(action: ReturnType<typeof createSeasonChallengeScheduleAsync.request>) {
  try {

    const season: ChallengeSchedule = yield call(ChallengeSchedulesService.createSeasonChallengeSchedule, action.payload.seasonNumber, action.payload.challengeScheduleJson);
    yield put(updateSeasonChallengeScheduleAsync.success(season));
    yield put(setAppNotification({type: 'success', message: 'Season Challenge Schedule updated'}));
  } catch (e) {
    yield sagaHandleApiError(e, updateSeasonChallengeScheduleAsync.failure);
  }
}

function* updateSeasonChallengeScheduleSaga(action: ReturnType<typeof updateSeasonChallengeScheduleAsync.request>) {
  try {

    const season: ChallengeSchedule = yield call(ChallengeSchedulesService.updateSeasonChallengeSchedule, action.payload.seasonNumber, action.payload.schedule);
    season.season_number = action.payload.seasonNumber;
    yield put(updateSeasonChallengeScheduleAsync.success(season));
    yield put(setAppNotification({type: 'success', message: 'Season Challenge Schedule updated'}));
  } catch (e) {
    yield sagaHandleApiError(e, updateSeasonChallengeScheduleAsync.failure);
  }
}

function* deleteSeasonChallengeScheduleSaga(action: ReturnType<typeof deleteSeasonChallengeScheduleAsync.request>) {
  try {
    yield call(ChallengeSchedulesService.deleteSeasonChallengeSchedule, action.payload);
    yield put(deleteSeasonChallengeScheduleAsync.success(action.payload));
    yield put(setAppNotification({type: 'success', message: 'Season Challenge Schedule deleted'}));
  } catch (e) {
    yield sagaHandleApiError(e, deleteSeasonChallengeScheduleAsync.failure);
  }
}

const sagas = [
  function* () {
    yield takeLatest(getSeasonChallengeScheduleAsync.request, fetchSeasonChallengeSchedule);
  },
  function* () {
    yield takeLatest(getCurrentSeasonChallengeScheduleAsync.request, fetchCurrentSeasonChallengeSchedule);
  },
  function* () {
    yield takeLatest(createSeasonChallengeScheduleAsync.request, createSeasonChallengeScheduleSaga);
  },
  function* () {
    yield takeLatest(updateSeasonChallengeScheduleAsync.request, updateSeasonChallengeScheduleSaga);
  },
  function* () {
    yield takeLatest(deleteSeasonChallengeScheduleAsync.request, deleteSeasonChallengeScheduleSaga);
  }
];

export default function* seasonChallengeScheduleSagas() {
  yield forkSagas(sagas);
}
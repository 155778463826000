import { createAction, createAsyncAction } from 'typesafe-actions';
import type { Catalog } from '../../services/catalogs';
import type { TitleFile } from '../../services/model/title-file';
import type { Store } from '../../services/stores';
import type { Title } from '../../services/title-data';
import type { MigrationActions, MigrationResult } from './reducer';

export type EnvOption = 'source' | 'target';

export const cmSetEnvId = createAction('cm/SET_ENV_ID', (payload: { env: EnvOption, id: string }) => payload)();

export const cmSetCatalogName = createAction('cm/SET_CATALOG_NAME', (payload: { env: EnvOption, catalogName: string }) => payload)();

export const cmSetOptions = createAction('cm/SET_OPTIONS', (payload: { [key: string]: boolean }) => payload)();

export const cmPreloadData = createAction('cm/PRELOAD_DATA')();

export const cmSetPreloadedData = createAction('cm/SET_PRELOADED_DATA', (payload: {
  env: EnvOption,
  catalog: Catalog,
  stores: Store[] | null,
  titleData: Title | null,
  titleFiles: TitleFile[] | null,
  blankoDnas: any[] | null,
  blankoAssets: any[] | null,
  seasonalData?: SeasonalData[],
  blankoProgressions?: BlankoProgressions[],
  translations?: Translation[],
  textLanguages?: TextLanguage[],
}) => payload)();

export const cmSetMigrationActions = createAction('cm/SET_MIGRATION_ACTIONS', (payload: { id: string, actions: MigrationActions }) => payload)();

export const cmStartMigration = createAction('cm/START_MIGRATION')();

export const cmSetMigrationProgress = createAction('cm/SET_MIGRATION_PROGRESS', (payload: { message: string, progress: number }) => payload)();

export const cmSetMigrationStepProgress = createAction('cm/SET_MIGRATION_STEP_PROGRESS', (payload: { message: string, progress: number }) => payload)();

export const cmSetMigrationStepResult = createAction('cm/SET_MIGRATION_STEP_RESULT', (payload: { id: string, result: MigrationResult }) => payload)();

export const cmFinishMigration = createAction('cm/FINISH_MIGRATION')();

export const cmGetCatalogsAsync = createAsyncAction(
  'cm/GET_CATALOGS_REQUEST',
  'cm/GET_CATALOGS_SUCCESS',
  'cm/GET_CATALOGS_FAILURE'
)<{ env: EnvOption }, { env: EnvOption, catalogs: Catalog[] }, { env: EnvOption, error: string }>();

export const cmGetCatalogAsync = createAsyncAction(
  'cm/GET_CATALOG_REQUEST',
  'cm/GET_CATALOG_SUCCESS',
  'cm/GET_CATALOG_FAILURE'
)<{ env: EnvOption, catalogName: string }, { env: EnvOption, catalog: Catalog }, string>();

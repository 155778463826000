import { Accordion, AccordionDetails, AccordionSummary, Box, Card, CardContent, Grid } from "@material-ui/core";
import { useCallback, useMemo, useState } from "react";
import type { SeasonalDiffSubViewProps } from "./SeasonalDiffView";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { seasonalDataComparer } from "./seasonal-data-comparer";
import type { SeasonRewardPathForComparison } from "./seasonal-data-comparer";

export const SeasonalRewardTierDiffView = (props: SeasonalDiffSubViewProps) => {
  const { beforeChanges, afterChanges, previous, current, renderDifferentialChange } = props;
  const classes = seasonalDataComparer.getStyles();

  const [rewardExpanded, setRewardExpanded] = useState(false);

  const showSeasonRewardTiersHeader = useMemo(() => {
    return beforeChanges?.seasonRewardPath || afterChanges.seasonRewardPath
  }, [beforeChanges, afterChanges]);

  const renderRewardTierRewards = useCallback((tierId: string, isPP: boolean, afterRewards: TierRewards[], beforeRewards?: TierRewards[]) => {
    if (!afterRewards && !beforeRewards) {
      return;
    }
    //determines which reward property to check
    const rewardKey = isPP ? "rewardsPartyPassPlus" : "rewards";

    const afterDiffs = new Set<number>();
    afterRewards?.forEach((_, i) => {
      const rewards = current.seasonRewardPath.tiers[tierId][rewardKey][i];
      if (rewards) {
        afterDiffs.add(i);
      }
    });

    const beforeDiffs = new Set<number>();
    beforeRewards?.forEach((_, i) => {
      const rewards = previous?.seasonRewardPath.tiers[tierId][rewardKey][i];
      if (rewards) {
        beforeDiffs.add(i)
      }
    });

    //Convert to set to remove dupes
    const combinedKeys = new Set<number>([...afterDiffs, ...beforeDiffs]);
    const previousKeys = previous?.seasonRewardPath?.tiers?.[tierId]?.[rewardKey] || [];
    const currentKeys = current?.seasonRewardPath?.tiers?.[tierId]?.[rewardKey] || [];
    const combinedExistingKeys = Array.from(new Set([...previousKeys.keys(), ...currentKeys.keys()]));

    return (
      <>
        {
          combinedExistingKeys.map(key => {
            if (combinedKeys.has(key)) {
              const currentItem = current?.seasonRewardPath?.tiers?.[tierId]?.[rewardKey]?.[key];
              const previousItem = previous?.seasonRewardPath?.tiers?.[tierId]?.[rewardKey]?.[key];

              const rewardValueString = (reward?: TierRewards) => {
                return !reward ? '' : JSON.stringify(reward.param);
              };

              return (
                <Grid container key={key}>
                  <Grid className={classes.sectionTitle} item xs={12}>
                    <h4>{isPP ? "PP+ " : ""} Reward {key}</h4>
                  </Grid>
                  {renderDifferentialChange("Type", currentItem?.type, previousItem?.type)}
                  {renderDifferentialChange("Value", rewardValueString(currentItem), rewardValueString(previousItem))}
                </Grid>
              )
            }
          })
        }
      </>
    );
  }, [classes, current, previous, renderDifferentialChange]);

  const renderRewardTiers = useCallback((afterRewardPath: SeasonRewardPathForComparison, beforeRewardPath?: SeasonRewardPathForComparison) => {
    const safeBefore = beforeRewardPath ? beforeRewardPath : {} as SeasonRewardPathForComparison;
    const currentTiers = current.seasonRewardPath.tiers;
    const previousTiers = previous?.seasonRewardPath?.tiers || {};

    const afterDiffs = new Set<string>();
    Object.keys(afterRewardPath?.tiers || {}).forEach((c, i) => {
      const tier = currentTiers[c];
      if (tier) {
        afterDiffs.add(c);
      }
    });

    const beforeDiffs = new Set<string>();
    Object.keys(safeBefore?.tiers || {}).forEach((c, i) => {
      const tier = previousTiers[c];
      if (tier) {
        beforeDiffs.add(c);
      }
    });
    //Convert to set to remove dupes
    const combinedKeys = new Set<string>([...afterDiffs, ...beforeDiffs]);
    return (
      Array.from(combinedKeys).map(key => {
        const tierId = (Object.keys(previousTiers).includes(key) ?
          previousTiers?.[key]?.tierId :
          currentTiers?.[key]?.tierId) || "Missing Key";

        const currentItem = currentTiers[tierId];
        const previousItem = previousTiers[tierId];

        if (combinedKeys.has(tierId)) {
          return (
            <Card key={key} className={classes.challengeContainer} variant="outlined" >
              <CardContent>
                <Grid container>
                  <Grid className={classes.sectionTitle} item xs={12}>
                    <h3>{currentItem?.name || previousItem?.name}</h3>
                  </Grid>
                  {renderDifferentialChange("Id", currentItem?.tierId, previousItem?.tierId)}
                  {renderDifferentialChange("Name", currentItem?.name, previousItem?.name)}
                  {renderDifferentialChange("Order", currentItem?.tierOrder.toString(), previousItem?.tierOrder.toString())}
                  {renderDifferentialChange("Tokens To Next Level", currentItem?.seasonTokensToNextLevel, previousItem?.seasonTokensToNextLevel)}
                  {renderDifferentialChange("Repeatable?", currentItem?.isRepeatableTier.toString(), previousItem?.isRepeatableTier.toString())}
                  {renderRewardTierRewards(tierId, false, currentItem?.rewards, previousItem?.rewards)}
                  {renderRewardTierRewards(tierId, true, currentItem?.rewardsPartyPassPlus, previousItem?.rewardsPartyPassPlus)}
                </Grid>
              </CardContent>
            </Card>
          )
        }
      })
    );
  }, [previous, current, renderDifferentialChange, classes, renderRewardTierRewards]);


  return (
    <>
      {showSeasonRewardTiersHeader && (
        <Accordion
          expanded={rewardExpanded}
          TransitionProps={{ unmountOnExit: true }}
          onChange={() => setRewardExpanded(!rewardExpanded)}
          className={classes.accordianSummary}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            className={classes.accordianSummary}
          >
            <Grid container alignItems="center" spacing={2}>
              <Grid item xs={12} className={classes.accordionTitle}>
                Reward Tiers
              </Grid>
            </Grid>
          </AccordionSummary>
          <AccordionDetails style={{ padding: 0 }}>
            <Box width="100%">
              {renderRewardTiers(afterChanges.seasonRewardPath, beforeChanges?.seasonRewardPath)}
            </Box>
          </AccordionDetails>
        </Accordion>
      )}
    </>
  );
};
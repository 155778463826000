import { createReducer } from 'typesafe-actions';
import type { APIResource } from '../../utils-ts';
import type { Store } from '../../../services/stores';
import {
  getCatalogStoresAsync,
  addCatalogStoreAsync,
  deleteCatalogStoreAsync,
  getCatalogStoreAsync,
  updateCatalogStoreAsync,
  setStore,
  publishCatalogStoreAsync,
  lockCatalogStoreAsync,
  unlockCatalogStoreAsync
} from './actions';
import { combineReducers } from 'redux';
import { setCatalogName } from '../actions';

const storesReducer = createReducer<APIResource<Store[]>>({ isLoading: false })
  .handleAction(getCatalogStoresAsync.request, state => ({
    ...state,
    isLoading: true
  }))
  .handleAction(getCatalogStoresAsync.success, (state, action) => ({
    ...state,
    isLoading: false,
    data: action.payload
  }))
  .handleAction(getCatalogStoresAsync.failure, state => ({
    ...state,
    isLoading: false
  }))
  .handleAction(addCatalogStoreAsync.success, (state, action) => ({
    ...state,
    data: state.data ? [...state.data, action.payload] : [action.payload]
  }))
  .handleAction(deleteCatalogStoreAsync.success, (state, action) => ({
    ...state,
    data: state.data ? state.data.filter(v => v !== action.payload) : state.data
  }))
  .handleAction(lockCatalogStoreAsync.success, (state, action) => ({
    ...state,
    data: state.data ? state.data.filter(v => v !== action.payload) : state.data
  }))
  .handleAction(unlockCatalogStoreAsync.success, (state, action) => ({
    ...state,
    data: state.data ? state.data.filter(v => v !== action.payload) : state.data
  }))
  .handleAction(setCatalogName, state => ({
    ...state,
    isLoading: false,
    data: null
  }));

const storeReducer = createReducer<APIResource<Store>>({ isLoading: false })
  .handleAction(getCatalogStoreAsync.request, state => ({
    ...state,
    isLoading: true
  }))
  .handleAction(getCatalogStoreAsync.success, (state, action) => ({
    ...state,
    isLoading: false,
    data: action.payload
  }))
  .handleAction(getCatalogStoreAsync.failure, state => ({
    ...state,
    isLoading: false
  }))
  .handleAction([addCatalogStoreAsync.success, updateCatalogStoreAsync.success, setStore, publishCatalogStoreAsync.success], (state, action) => ({
    ...state,
    data: action.payload
  }))
  .handleAction(setCatalogName, state => ({
    ...state,
    isLoading: false,
    data: null
  }));

export default combineReducers({
  stores: storesReducer,
  store: storeReducer
});
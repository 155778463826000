import { Box, Button, Checkbox, FormControl, FormControlLabel } from '@material-ui/core';
import { useCallback } from 'react';
import type { MigrationCommonProps } from '../migrations';
import { brawlPinPublishModules, brawlPinPublishRunOrder } from '../modules/brawl-pin-publish-modules';
import { CatalogSelect } from './CatalogSelect';
import { MigrationModule } from "../migrations";
import { DiffItem } from "../../../pages/CatalogMigration/types";

export type MigrationOptionsProps = MigrationCommonProps;

const options: MigrationModule<DiffItem>[] = brawlPinPublishRunOrder.map(v => brawlPinPublishModules[v])
  .filter((v): v is MigrationModule<DiffItem> => !!v);

export const BrawlPinPublishOptions = (props: MigrationOptionsProps) => {
  const { migrationData, sourceData, targetData, onMigrationDataChange } = props;

  const handleOptionChange = useCallback((id: string, checked: boolean) => {
    onMigrationDataChange({ ...migrationData, options: { ...migrationData.options, [id]: checked } });
  }, [onMigrationDataChange, migrationData]);

  return (<>
    <Box my={2}>
      Publish into
    </Box>

    <CatalogSelect
      env={targetData.env}
      catalogName={targetData.catalogName}
      loggedIn
      onChange={catalogName => props.onTargetChange({ ...targetData, catalogName })}
      defaultToPrimaryCatalog={true}
    />

    <Box my={2}>
      {options.map(option => (
        <Box key={option.id}>
          <FormControl>
            <FormControlLabel
              label={option.displayName}
              control={<Checkbox
                checked={Boolean(migrationData.options[option.id])}
                disabled={sourceData.env === targetData.env && brawlPinPublishModules[option.id]?.crossEnvOnly}
                onChange={(event, checked) => handleOptionChange(option.id, checked)}
              />}
            />
          </FormControl>
        </Box>
      ))}
    </Box>

    <Box mt={2}>
      <Button
        variant="contained"
        color="primary"
        onClick={props.onNextStep}
        disabled={Object.keys(migrationData.options).length < 1}
      >
        Next
      </Button>
    </Box>
  </>);
}

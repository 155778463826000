import { Box, Button, Grid, Typography } from '@material-ui/core';
import { Create, Delete } from '@material-ui/icons';
import _ from 'lodash';
import type { Column } from 'material-table';
import { useCallback, useMemo, useState } from 'react';
import ConfirmationDialog from '../../components/ConfirmationDialog';
import type { Artist } from '../../services/model/artist';
import { UserService } from '../../services/user';
import { AdminTable } from '../../shared';
import type { RowMenuAction } from '../../shared/components/AdminTable';
import { ArtistForm } from './ArtistForm';
import { useArtistsSortedByName, useDeleteArtist } from './hooks';

const COLUMNS: Column<Artist>[] = [
  {
    title: 'ID',
    field: 'id',
    hidden: true,
  },
  {
    title: 'Name',
    field: 'name'
  },
  {
    title: 'Image Filename',
    field: 'imageFilename',
  },
  {
    title: 'Image',
    field: 'imageUrl',
    render: (row) => (
      <Grid item xs={12}>
        <Box mt={2}>
          <img alt="Artist" src={row.imageUrl} height="96" />
        </Box>
      </Grid>)
  },
];

export const Artists = () => {
  const [artistFormOpen, setArtistFormOpen] = useState(false);
  const [rowData, setRowData] = useState<Artist | undefined>();
  const [showConfirmDialog, setShowConfirmDialog] = useState(false);

  const { data: artists, isSuccess } = useArtistsSortedByName();
  const { mutate: deleteArtist, isSuccess: isDeleteSuccess } = useDeleteArtist();

  const onAdd = () => {
    setArtistFormOpen(true);
    setRowData(undefined);
  };

  const onConfirm = useCallback(() => {
    if (rowData === undefined) return;
    deleteArtist({ id: rowData.id });
    setShowConfirmDialog(false);
    setRowData(undefined);
  }, [deleteArtist, rowData]);

  // /** The available menu actions restricted by user access. */
  const menuItems = useMemo<RowMenuAction<Artist>[]>(() => {
    const onEdit = (row: Artist) => {
      setArtistFormOpen(true);
      setRowData(row);
    };

    const onRemove = (row: Artist) => {
      setShowConfirmDialog(true);
      setRowData(row);
    };
    
    return _.compact([
      UserService.canUpdate('catalogItems') && { type: 'button', icon: Create, label: 'Edit', onClick: onEdit },
      UserService.canDelete('catalogItems') && { type: 'button', icon: Delete, label: 'Delete', onClick: onRemove }
    ])}
  , []);

  // TODO: Handle API failure properly.
  if (!isSuccess || artists === undefined) return <></>

  return (<>
    <Box mb={2}>
      <Grid container spacing={2} alignItems="center">
        <Grid item xs>
          <Typography variant="h6">
            Artist
          </Typography>
        </Grid>
        {UserService.canCreate('catalogItems') && (
          <Grid item>
            <Button
              onClick={onAdd}
              variant="contained"
              color="primary"
            >
              Add Artist
            </Button>
          </Grid>
        )}
      </Grid>
    </Box>
    <AdminTable<Artist>
      title=""
      storageId="artists"
      columns={COLUMNS}
      data={artists}
      options={{
        columnsButton: true,
        pageSize: 10,
        pageSizeOptions: [5, 10, 20]
      }}
      menuActions={menuItems}
    />
    {artistFormOpen &&
      <ArtistForm
        show={artistFormOpen}
        artist={rowData}
        onClose={() => setArtistFormOpen(false)}
      />
    }
    {showConfirmDialog &&
      <ConfirmationDialog
        title={`Delete ${rowData?.name}?`}
        details={`Are you sure you want to delete ${rowData?.name}?`}
        open={showConfirmDialog}
        onClose={() => setShowConfirmDialog(false)}
        onConfirm={onConfirm}
      />}
  </>)
}
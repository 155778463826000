import type { CallEffect, SelectEffect, PutEffect } from 'redux-saga/effects';
import { call, fork, put, select, takeLatest } from 'redux-saga/effects';
import type { DropTable} from '../../../services/drop-tables';
import { DropTablesService } from '../../../services/drop-tables';
import { setAppNotification } from '../../app/actions';
import type { RootState } from '../../reducers';
import { sagaHandleApiError } from '../../utils-ts';
import { addCatalogDropTableAsync, getCatalogDropTableAsync, getCatalogDropTablesAsync, updateCatalogDropTableAsync } from './actions';
import type { AnyAction } from 'redux';
import type { PayloadAction } from 'typesafe-actions';

function* fetchCatalogDropTables(): Generator<SelectEffect | CallEffect<DropTable[]> | PutEffect<PayloadAction<"GET_CATALOG_DROP_TABLES_SUCCESS", DropTable[]>> | Generator<PutEffect<AnyAction>, void, string>, void, DropTable[] & RootState> {
  try {
    const state = yield select();
    if (state.catalog.name) {
      const dropTables = yield call(DropTablesService.getDropTables, state.catalog.name);
      yield put(getCatalogDropTablesAsync.success(dropTables));
    }
  } catch (e) {
    yield sagaHandleApiError(e, getCatalogDropTablesAsync.failure);
  }
}

function* fetchCatalogDropTable(action: ReturnType<typeof getCatalogDropTableAsync.request>): Generator<SelectEffect | Generator<PutEffect<AnyAction>, void, string> | CallEffect<DropTable> | PutEffect<PayloadAction<"GET_CATALOG_DROP_TABLE_SUCCESS", DropTable>>, void, DropTable & RootState> {
  try {
    const state = yield select();
    if (state.catalog.name) {
      const dropTable = yield call(DropTablesService.getDropTable, state.catalog.name, action.payload);
      yield put(getCatalogDropTableAsync.success(dropTable));
    }
  } catch (e) {
    yield sagaHandleApiError(e, getCatalogDropTableAsync.failure);
  }
}

function* addCatalogDropTableSaga(action: ReturnType<typeof addCatalogDropTableAsync.request>) {
  try {
    const dropTable: DropTable = yield call(DropTablesService.createDropTable, action.payload);
    yield put(addCatalogDropTableAsync.success(dropTable));
    yield put(setAppNotification({ type: 'success', message: 'Drop table saved' }));
  } catch (e) {
    yield sagaHandleApiError(e, addCatalogDropTableAsync.failure);
  }
}

function* updateCatalogDropTableSaga(action: ReturnType<typeof updateCatalogDropTableAsync.request>) {
  try {
    const dropTable: DropTable = yield call(DropTablesService.updateDropTable, action.payload);
    yield put(updateCatalogDropTableAsync.success(dropTable));
    yield put(setAppNotification({ type: 'success', message: 'Drop table saved' }));
  } catch (e) {
    yield sagaHandleApiError(e, updateCatalogDropTableAsync.failure);
  }
}

function* watchRequestCatalogDropTables() {
  yield takeLatest(getCatalogDropTablesAsync.request, fetchCatalogDropTables);
}

function* watchRequestCatalogDropTable() {
  yield takeLatest(getCatalogDropTableAsync.request, fetchCatalogDropTable);
}

function* watchRequestAddCatalogDropTable() {
  yield takeLatest(addCatalogDropTableAsync.request, addCatalogDropTableSaga);
}

function* watchRequestUpdateCatalogDropTable() {
  yield takeLatest(updateCatalogDropTableAsync.request, updateCatalogDropTableSaga);
}

export default function* catalogDropTablesSagas() {
  yield fork(watchRequestCatalogDropTables);
  yield fork(watchRequestCatalogDropTable);
  yield fork(watchRequestAddCatalogDropTable);
  yield fork(watchRequestUpdateCatalogDropTable);
}
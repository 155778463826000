import { Box, Button, Card, CardActions, CardContent, CardHeader, Dialog, DialogContent, FormControl, FormControlLabel, Grid, LinearProgress, Radio, RadioGroup, TextField, Typography } from '@material-ui/core';
import { useCallback, useState } from 'react';
import { ApiError } from '../../../services/api';
import type { BetaGrantResponse } from '../../../services/mythical-accounts';
import { MythicalAccountsService } from '../../../services/mythical-accounts';
import { BetaAccessGrantResults } from './BetaAccessGrantResults';
import { openAdminConfirmationDialog } from "../../../shared/hooks/useAdminConfirmationDialog";

export const BetaAccess = () => {
  const [grantOrRevoke, setGrantOrRevoke] = useState('grant');
  const [emailsInput, setEmailsInput] = useState('');
  const [emailsInputError, setEmailsInputError] = useState('');
  const [isGranting, setGranting] = useState(false);
  const [progress, setProgress] = useState(0);
  const [grantResults, setGrantResults] = useState<BetaGrantResponse | null>(null);

  const onGrantAccessClick = useCallback(() => {
    if (isGranting) {
      return;
    }

    const emails = emailsInput.trim().split('\n').map(v => v.trim()).filter(v => !!v);
    if (emails.length < 1) {
      setEmailsInputError('Need at least one email');
      return;
    } else {
      setEmailsInputError('');
    }

    const title = `Update beta access for ${emails.length} ${emails.length > 1 ? 'accounts' : 'account'}?`;
    openAdminConfirmationDialog({
      title,
      onConfirm: () => {
        setProgress(0);
        setGranting(true);
        grantAccess(emails, grantOrRevoke === 'grant').then(results => {
          setGrantResults(results);
        }).catch(e => console.error(e)).then(() => {
          setGranting(false);
        });
      }
    });
  }, [isGranting, emailsInput, grantOrRevoke]);

  const grantAccess = async (emails: string[], allowAccess: boolean) => {
    // Try to divide into 10 batches. Min batch size: 5. Max batch size 50.
    let batchSize = Math.max(Math.min(Math.floor(emails.length / 10), 50), 5);
    let batch: string[] = [];
    const aggrResult: BetaGrantResponse = {
      playersAddedCount: 0,
      notAddedReasons: {}
    };
    for (let i = 0; i < emails.length; i++) {
      batch.push(emails[i]);
      if (batch.length === batchSize || i === emails.length - 1) {
        try {
          const result = await MythicalAccountsService.updateBetaAccess(batch, allowAccess);
          aggrResult.playersAddedCount += result.playersAddedCount;
          aggrResult.notAddedReasons = { ...aggrResult.notAddedReasons, ...result.notAddedReasons };
          setProgress((i + 1) * 100 / emails.length);
        } catch (e) {
          let retry = false;
          if (e instanceof ApiError && e.code === 50000 && e.jsonMessage.startsWith('RESOURCE_EXHAUSTED')) {
            batchSize = Math.floor(batchSize / 2);
            if (batchSize >= 5) { // Retry the batch, with a smaller size
              i -= batch.length;
              retry = true;
            }
          }

          if (!retry) {
            const emailsRemaining = [...batch, ...emails.slice(i)];
            emailsRemaining.forEach(email => aggrResult.notAddedReasons[email] = `API error: ${e instanceof ApiError ? e.message : 'Unknown'}`);
            i = emails.length;
          }
        }
        batch = [];
      }
    }

    return aggrResult;
  }

  return (<>
    <Grid container spacing={3}>
      <Grid item xs={12} xl={6}>
        <Card>
          <CardHeader title="Update beta access" />
          <CardContent>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Typography variant="subtitle2">
                  Note: This will not send beta invite emails.
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <FormControl>
                  <RadioGroup value={grantOrRevoke} onChange={e => setGrantOrRevoke(e.target.value)} >
                    <FormControlLabel value="grant" control={<Radio />} label="Grant access" />
                    <FormControlLabel value="revoke" control={<Radio />} label="Revoke access" />
                  </RadioGroup>
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  type="text"
                  label="Emails (one per line)"
                  value={emailsInput}
                  onChange={event => setEmailsInput(event.target.value)}
                  error={!!emailsInputError}
                  helperText={emailsInputError}
                  multiline
                  rows={10}
                  rowsMax={10}
                  fullWidth
                />
              </Grid>
            </Grid>
          </CardContent>
          <CardActions>
            <Button variant="contained" color="primary" onClick={onGrantAccessClick}>
              Update beta access
            </Button>
          </CardActions>
        </Card>
      </Grid>

      {grantResults && (
        <Grid item xs={12} xl={6}>
          <Card>
            <CardHeader title="Results" />
            <CardContent>
              <BetaAccessGrantResults results={grantResults} />
            </CardContent>
          </Card>
        </Grid>
      )}
    </Grid>

    <Dialog
      open={isGranting}
      fullWidth
      maxWidth="sm"
    >
      <DialogContent>
        <Box textAlign="center">
          <Box my={3}>
            <LinearProgress variant="determinate" color="secondary" value={progress} />
            <Typography variant="subtitle2">{`${Math.floor(progress)}%`}</Typography>
          </Box>
          <Typography>Updating access. This may take a while.</Typography>
          <Typography>Please do not close this tab.</Typography>
        </Box>
      </DialogContent>
    </Dialog>
  </>)
}
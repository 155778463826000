import { Component } from 'react';
import type { RouteComponentProps } from 'react-router';
import type { LinkTabConfig} from '../../components/LinkTabs';
import { LinkTabs } from '../../components/LinkTabs';
import { UserService } from '../../services/user';
import SeasonInfo from './SeasonInfo';
import SeasonRewardPath from './SeasonRewardPath';
import SeasonChallengeSchedulePage from "./SeasonChallengeSchedulePage";
import { Box, Typography } from "@material-ui/core";
import { getSeasonAsync } from "../../redux/player-challenges/seasons/actions";
import type { ConnectedProps } from "react-redux";
import { connect } from "react-redux";
import type { RootState } from "../../redux/reducers";
import SeasonPartyPassPlus from './SeasonPartyPassPlus';

const mapStateToProps = (state: RootState) => ({
  seasons: state.seasons
});

const mapDispatch = {
  requestSeason: getSeasonAsync.request
};

const connector = connect(mapStateToProps, mapDispatch);
type Props = RouteComponentProps & ConnectedProps<typeof connector>;

interface State {
  liveEventsId: string;
  seasonNumber: number;
}

class Season extends Component<Props, State> {
  private tabs: LinkTabConfig[];

  constructor(props: Props) {
    super(props);
    const params: any = this.props.match.params;
    const seasonNumber = parseInt(params.seasonNumber);
    this.state = {
      liveEventsId: params.liveEventId,
      seasonNumber: seasonNumber
    }

    this.tabs = [];
    if (UserService.canRead('seasons')) {
      this.tabs.push({ url: '', label: 'Season Info', absolute: false, render: () => <SeasonInfo liveEventsId={params.liveEventId} seasonNumber={seasonNumber} /> });
    }
    if (UserService.canRead('seasons')) {
      this.tabs.push({ url: '/party-pass-plus', label: 'Party Pass+', absolute: false, render: () => <SeasonPartyPassPlus seasonNumber={seasonNumber} /> });
    }
    if (UserService.canRead('seasons')) {
      this.tabs.push({ url: '/reward-path', label: 'Reward Path', absolute: false, render: () => <SeasonRewardPath seasonNumber={seasonNumber} /> });
    }
    if (UserService.canRead('seasons')) {
      this.tabs.push({ url: '/challenge-schedule', label: 'Challenge Schedule', absolute: false, render: () => <SeasonChallengeSchedulePage {...props} /> });
    }
  }

  componentDidMount() {
    this.props.requestSeason({liveEventsId: this.state.liveEventsId, seasonNumber: this.state.seasonNumber});
  }

  renderSeasonName() {
    if (!this.props.seasons || this.props.seasons.isLoading || !this.props.seasons.data) {
      return null;
    }
    const season = this.props.seasons.data.find(o => o.season_number === this.state.seasonNumber);
    if (!season) {
      return null;
    }
    return (<>{season.season_name}</>);
  }

  render() {
    return (
      <Box>
        <Typography variant="h6">
          {this.renderSeasonName()}
        </Typography>
        <LinkTabs tabs={this.tabs} {...this.props} />
      </Box>
    )
  }

}

export default connector(Season);
import { TitleFileDiff } from '../../../../pages/CatalogMigration/types';
import { CatalogMigrationService } from '../../../../services/catalog-migration';
import type { MigrationModule } from '../../migrations';
import { buildDiffData, buildMigrationActions } from '../../migrations';
import { TitleFilesDiffs } from './TitleFilesDiffs';
import { MigrationType } from "../migration-type";

export const titleFileMigration: MigrationModule<TitleFileDiff> = {
  id: MigrationType.titleFile,
  displayName: 'Title data - Files',
  diffComponent: TitleFilesDiffs,
  crossEnvOnly: true,

  loadData: async (sourceData, targetData, migrationData) => {
    sourceData.titleFiles = await CatalogMigrationService.getTitleFiles(sourceData.env);
    targetData.titleFiles = await CatalogMigrationService.getTitleFiles(targetData.env);

    migrationData.titleFiles = buildDiffData(
      sourceData.titleFiles.map(file => new TitleFileDiff(file)),
      targetData.titleFiles.map(file => new TitleFileDiff(file))
    );

    return migrationData.titleFiles;
  },

  runMigration: async (props, setStatus) => {
    const actions = buildMigrationActions(props.migrationData.titleFiles, props.selections.titleFile);

    const filenames = actions.add.concat(actions.update);
    let i = 0;
    for (const filename of filenames) {
      setStatus(`Transferring file ${i + 1} of ${filenames.length}: ${filename}`, i / filenames.length);
      const file = await CatalogMigrationService.getTitleFile(props.sourceData.env, filename);
      const uploadUrl = await CatalogMigrationService.createFile(props.targetData.env, filename, file.version);
      await CatalogMigrationService.transferTitleFile(file.fileUrl, uploadUrl);
      i++;
    }
  }
}

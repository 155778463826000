import {createAsyncAction} from 'typesafe-actions';
import type {DailyMessage} from "../../services/daily-messages";

export const getDailyMessagesAsync = createAsyncAction(
  'GET_DAILY_MESSAGES_REQUEST',
  'GET_DAILY_MESSAGES_SUCCESS',
  'GET_DAILY_MESSAGES_FAILURE'
)<void, DailyMessage[], string>();

export const createDailyMessageAsync = createAsyncAction(
  'CREATE_DAILY_MESSAGE_REQUEST',
  'CREATE_DAILY_MESSAGE_SUCCESS',
  'CREATE_DAILY_MESSAGE_FAILURE'
)<DailyMessage, DailyMessage, string>();

export const updateDailyMessageAsync = createAsyncAction(
  'UPDATE_DAILY_MESSAGE_REQUEST',
  'UPDATE_DAILY_MESSAGE_SUCCESS',
  'UPDATE_DAILY_MESSAGE_FAILURE'
)<DailyMessage, DailyMessage, string>();

export const deleteDailyMessageAsync = createAsyncAction(
  'DELETE_DAILY_MESSAGE_REQUEST',
  'DELETE_DAILY_MESSAGE_SUCCESS',
  'DELETE_DAILY_MESSAGE_FAILURE'
)<DailyMessage, DailyMessage, string>();
import * as React from "react";
import { useEffect, useState } from "react";
import type { Entitlement } from "../../../services/rewards";
import { EntitlementRewards, EntitlementRewardsService } from "../../../services/rewards";
import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  TextField as MTextField,
  Tooltip
} from "@material-ui/core";
import ItemSelectDialog from "../../../pages/ItemDefinition/ItemSelectDialog";
import { ItemDefinition } from "../../../services/item-definitions";
import type { DropTable } from "../../../services/drop-tables";
import type { Column } from "material-table";
import MaterialTable from "../../../components/MaterialTable";
import type { FormikProps } from "formik";
import { Field, Formik } from "formik";
import MenuIcon from "@material-ui/icons/Menu";
import { TextField } from "formik-material-ui";
import { Link } from "react-router-dom";
import { createValidator, Validators } from "../../../utils/forms";
import type { RouteComponentProps } from "react-router";
import { AdminKeyboardDateTimePicker } from "../../../components/AdminKeyboardDateTimePicker";
import { pushAppNotification } from "../../../shared/hooks/useAppNotification";

interface FormValues {
  itemId: string,
  max: string,
  weight: string
}

type Props = RouteComponentProps<{ rewardId: string; }>;

const formValidator = createValidator<FormValues>({
  itemId: Validators.notBlank(),
  max: Validators.integer(0, 999999999),
  weight: Validators.integer(0, 999999999)
}, (values, prevErrors) => {
  const errors: any = {};

  return errors;
});

const types = [{
  name: "TWITCH",
  label: "Twitch"
}, {
  name: "PRIME",
  label: "Amazon Prime"
}, {
  name: "DOTCOM",
  label: "Website"
}, {
  name: "DOTCOMCODE",
  label: "Website Codes"
}, {
  name: "SURVEY",
  label: "Survey"
}];

export const Reward = ({match}: Props) => {
  const [itemSelectDialogOpen, setItemSelectDialogOpen] = useState(false);
  const [reward, setReward] = useState(new EntitlementRewards());
  const [selectedItem, setSelectedItem] = useState<ItemDefinition | null>(null);
  useEffect(() => {
    if (match.params.rewardId) {
      EntitlementRewardsService.getEntitlementReward(match.params.rewardId)
        .then(rew => setReward(rew))
        .catch(err => pushAppNotification({type: 'error', message: `Failed to get reward: ${err.message}`}));
    }
  }, [match.params.rewardId]);

  const onItemSelect = (items: (ItemDefinition | DropTable)[], form: FormikProps<FormValues>) => {
    if (items.length === 1 && items[0] instanceof ItemDefinition) {
      setSelectedItem(items[0]);
      form.setFieldValue('itemId', items[0].itemId);
    }
  };

  const onSubmit = (values: FormValues) => {
    if (selectedItem) {
      const e: Entitlement = {
        itemId: values.itemId,
        catalogName: selectedItem.catalogName,
        maxAvailableCount: parseInt(values.max),
        weight: parseInt(values.weight),
        currentAvailableCount: parseInt(values.max)
      };
      setReward(reward => ({
        ...reward,
        entitlements: reward.entitlements.concat([e]),
      }));
    }
    setSelectedItem(null);
  };

  const columns: Column<Entitlement>[] = [
    {
      title: 'Item',
      field: 'itemId',
      sorting: false

    },
    {
      title: 'Max Available',
      field: 'maxAvailableCount',
      sorting: false
    },
    {
      title: 'Weight',
      field: 'weight',
      sorting: false
    }
  ];

  const renderGrantForm = (form: FormikProps<FormValues>) => {
    return (
      <>
        <Grid item xs={6} lg={4}>
          <Field
            component={TextField}
            name="itemId"
            type="text"
            label="Item"
            fullWidth
            InputProps={{
              readOnly: true,
              endAdornment: (
                <InputAdornment position="end">
                  <Tooltip title="Select item...">
                    <IconButton onClick={() => setItemSelectDialogOpen(true)}>
                      <MenuIcon/>
                    </IconButton>
                  </Tooltip>
                </InputAdornment>
              )
            }}
          />
        </Grid>
        <Grid item xs={6} lg={4}>
          <Field
            component={TextField}
            name="max"
            type="text"
            label="Max"
            fullWidth
          />
        </Grid>
        <Grid item xs={6} lg={4}>
          <Field
            component={TextField}
            name="weight"
            type="text"
            label="Weight"
            fullWidth
          />
        </Grid>
        {itemSelectDialogOpen && (
          <ItemSelectDialog
            showCatalogSelect
            onSelect={items => onItemSelect(items, form)}
            onClose={() => setItemSelectDialogOpen(false)}
          />
        )}
      </>
    );
  };

  const handleUpdateField = (event: any, field: keyof EntitlementRewards) => {
    setReward({
      ...reward,
      [field]: event?.target?.value || event,
    });
  };

  const handleSave = () => {
    if (match.params.rewardId) {
      EntitlementRewardsService.updateEntitlementReward(reward)
        .then(() => pushAppNotification({type: 'success', message: 'Reward updated'}))
        .catch(err => pushAppNotification({type: 'error', message: `Failed to update reward: ${err.message}`}));
    } else {
      EntitlementRewardsService.createEntitlementReward(reward)
        .then(() => pushAppNotification({type: 'success', message: 'Reward created'}))
        .catch(err => pushAppNotification({type: 'error', message: `Failed to create reward: ${err.message}`}));
    }
  };

  if (!reward) {
    return <></>;
  }
  return (
    <Grid container spacing={3}>
      <Grid item xs={12} md={6} lg={8}>
        <Card>
          <CardContent>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <InputLabel>Type</InputLabel>
                <Select
                  value={reward.type}
                  onChange={e => handleUpdateField(e, 'type')}
                >
                  {types.map(type => (
                    <MenuItem key={type.name} value={type.name}>{type.label}</MenuItem>
                  ))}
                </Select>
              </Grid>
              <Grid item xs={12}>
                <MTextField
                  value={reward.description}
                  label="Description"
                  type="text"
                  name="description"
                  onChange={e => handleUpdateField(e, 'description')}
                  fullWidth
                />
              </Grid>
              {reward.type !== "SURVEY" &&
                <Grid item xs={12}>
                  <MTextField
                    label="Url Path (blankos.com/redeem/{path}"
                    value={reward.urlPath}
                    type="text"
                    name="urlPath"
                    onChange={e => handleUpdateField(e, 'urlPath')}
                    fullWidth
                  />
                </Grid>
              }
              <Grid item xs={12}>
                <AdminKeyboardDateTimePicker
                  label="Campaign Start Date (Empty means it's active up until End Date)"
                  fullWidth
                  clearable
                  value={reward.startDate}
                  onChange={e => handleUpdateField(e, 'startDate')}
                />
              </Grid>
              <Grid item xs={12}>
                <AdminKeyboardDateTimePicker
                  label="Campaign End Date (Empty means it's active from Start Date on)"
                  fullWidth
                  clearable
                  value={reward.endDate}
                  onChange={e => handleUpdateField(e, 'endDate')}
                />
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={12} md={6} lg={8}>
        <Card>
          <CardHeader title="Add Item to Reward Bundle"/>
          <CardContent>
            <Grid container spacing={1}>
              <Formik<FormValues>
                initialValues={{
                  itemId: '',
                  max: '',
                  weight: ''
                }}
                validate={formValidator}
                enableReinitialize
                onSubmit={(values, {resetForm}) => {
                  onSubmit(values);
                  resetForm();
                }}
              >
                {form => (<>
                  {renderGrantForm(form)}
                  <Grid item style={{padding: 10}}>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={form.submitForm}
                    >
                      Add
                    </Button>
                  </Grid>
                </>)}
              </Formik>
            </Grid>
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={12} md={6} lg={8}>
        <Card>
          <CardContent>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <MaterialTable
                  title="Items in Bundle"
                  columns={columns}
                  data={reward.entitlements}
                  options={{
                    pageSize: 5,
                    paging: false,
                    search: false
                  }}
                  editable={{
                    onRowDelete: entitlement => {
                      setReward(reward => ({
                        ...reward,
                        entitlements: reward.entitlements.filter(e => e.itemId !== entitlement.itemId),
                      }));
                      return Promise.resolve();
                    }
                  }}
                  localization={{
                    body: {
                      editRow: {
                        deleteText: 'Do you want to remove this item?'
                      }
                    }
                  }}
                />
              </Grid>
            </Grid>
          </CardContent>
        </Card>
        <Grid item xs={4} style={{padding: 10}}>
          <Button
            component={Link}
            to="/rewards"
            variant="contained"
            color="primary"
            onClick={handleSave}
          >
            Save
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
};

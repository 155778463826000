import { api, ApiError } from '../../services/api';
import { DuplicateLiveEventRequest, JunctionEventInfo, LiveEvent } from "./live-events-types";

export const getLiveEvents = async () => {
  return api.get<LiveEvent[]>({url: `/live-events`});
};

export const createLiveEvent = async (liveEvent: LiveEvent) => {
  return api.post<LiveEvent>({url: `/live-events`, body: liveEvent});
};

export const updateLiveEvent = async (liveEvent: LiveEvent) => {
  return api.put<LiveEvent>({url: `/live-events`, body: liveEvent});
};

export const deleteLiveEvent = async (liveEvent: LiveEvent) => {
  return api.delete({url: `/live-events/${liveEvent.id}`});
};

export const duplicateLiveEvent = async (liveEvent: LiveEvent, request: DuplicateLiveEventRequest) => {
  return api.post({url: `/live-events/${liveEvent.id}/duplicate`, body: request});
};

export const announceLiveEventUpdate = async(liveEvent: LiveEvent) => {
  return api.get({url: `/live-events/${liveEvent.id}/notify-update`});
}

export const getJunctionEventInfos = async (liveEvent: LiveEvent) => {
  return api.get<JunctionEventInfo[]>({url: `/live-events/${liveEvent.id}/junction-event-infos`});
};

export const getJunctionEventInfo = async (liveEventsId: string, infoId: string) => {
  return api.get<JunctionEventInfo>({url: `/live-events/${liveEventsId}/junction-event-info/${infoId}`});
};

export const createJunctionEventInfo = async (liveEventsId: string, junctionEventInfo: JunctionEventInfo) => {
  return api.post<JunctionEventInfo>({url: `/live-events/${liveEventsId}/junction-event-info`, body: junctionEventInfo});
};

export const updateJunctionEventInfo = async (liveEventsId: string, junctionEventInfo: JunctionEventInfo) => {
  return api.put<JunctionEventInfo>({url: `/live-events/${liveEventsId}/junction-event-info`, body: junctionEventInfo});
};

export const deleteJunctionEventInfo = async (liveEventsId: string, infoId: string) => {
  return api.delete({url: `/live-events/${liveEventsId}/junction-event-info/${infoId}`});
};

export const uploadLiveEventImage = async (liveEvent: LiveEvent, image: File): Promise<string> => {
  try {
    const { filename, url, publicUrl } = await api.get<{ filename: string, url: string, publicUrl: string }>({
      url: `/live-events/${liveEvent.id}/image-upload-url`,
      query: { mimeType: image.type } });

    const options: RequestInit = {
      method: 'PUT',
      headers: new Headers({
        'Content-Type': image.type
      }),
      body: image
    };

    const response = await fetch(url, options);
    if (!response.ok) {
      throw new Error('');
    }

    return publicUrl;
  } catch (e) {
    throw new ApiError('Failed to upload blanko rush image');
  }
}
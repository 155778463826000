import { Box, Typography } from '@material-ui/core';
import type { ItemDiffProps, StoreDiff } from '../../types';
import { DiffsPanelsContainer } from '../DiffsPanels';

type StoresProps = ItemDiffProps<StoreDiff>

export const Stores = ({
  sourceItems,
  targetItems,
  actions,
  onSelectionChange 
}: StoresProps) => (
  <>
    <Typography variant="h6">
      Stores
    </Typography>
    <Box mt={2}>
      {sourceItems && targetItems && (
        <DiffsPanelsContainer
          sourceItems={sourceItems}
          targetItems={targetItems}
          selectedIds={actions}
          columns={[
              { title: 'Store ID', field: 'diffId', defaultSort: 'asc' }
          ]}
          onSelectionChange={onSelectionChange}
          />
        )}
    </Box>
  </>);

import { buildDiffData, MigrationModule, buildMigrationActions } from "../../migrations";
import { GeoBlockingDiff} from "../../../../pages/CatalogMigration/types";
import { CatalogMigrationService } from "../../../../services/catalog-migration";
import { GeoBlockingDiffs } from "../geoblocking/GeoBlockingDiffs";
import { GeoBlockedFeature, GeoBlockedFeatureIdEnum, GeoBlockedFeatureMap } from "../../../geoblocking/geo-blocking-types";
import _, { Dictionary } from "lodash";
import { MigrationType } from "../migration-type";

const buildMap = (features: GeoBlockedFeature[]) => {
  const results = _.groupBy(features, i => i.featureId);
  const geoMap: GeoBlockedFeatureMap[] = [];
  for (const k in results) {
    //Typescript is so gross sometimes
    geoMap.push({ featureId: k as GeoBlockedFeatureIdEnum, regions: results[k].map(i => i.regionId) })
  }
  return geoMap;
};


export const geoblockingMigration: MigrationModule<GeoBlockingDiff> = {
  id: MigrationType.geoblocking,
  displayName: 'Geo-Blocking Settings',
  diffComponent: GeoBlockingDiffs,
  crossEnvOnly: false,

  loadData: async (sourceData, targetData, migrationData) => {
    if (!sourceData.geoblocking) {
      sourceData.geoblocking = buildMap(await CatalogMigrationService.getAllGeoBlockingFeatures(sourceData.env));
    }
    if (!targetData.geoblocking) {
      targetData.geoblocking = buildMap(await CatalogMigrationService.getAllGeoBlockingFeatures(targetData.env));
    }
    migrationData.geoblocking = buildDiffData(
      sourceData.geoblocking.map(gbd => new GeoBlockingDiff(gbd)),
      targetData.geoblocking.map(gbd => new GeoBlockingDiff(gbd))
    );

    return migrationData.geoblocking;
  },

  runMigration: async (props, setStatus) => {
    const targetEnv = props.targetData.env;
    const actions = buildMigrationActions(props.migrationData.geoblocking, props.selections.geoblocking);
    if (actions.add.length + actions.update.length + actions.remove.length < 1) {
      return;
    }

    const toMapById = (features?: GeoBlockedFeatureMap[]): Dictionary<GeoBlockedFeatureMap> => features && _.mapValues(_.groupBy(features, 'featureId'), result => result[0]) || {};
    const sourceGeoBlocks = toMapById(props.sourceData.geoblocking);

    let progress = 0;
    for (const addId of actions.add) {
      setStatus('Adding Geoblocked features', progress);
      const toCreate = _.clone(sourceGeoBlocks[addId]);
      await CatalogMigrationService.upsertGeoBlockFeature(targetEnv, toCreate);
      progress += 100 / actions.add.length;
    }

    progress = 0;
    for (const updateId of actions.update) {
      setStatus('Updating Geoblocked features', progress);
      const toUpdate = _.clone(sourceGeoBlocks[updateId]);
      await CatalogMigrationService.upsertGeoBlockFeature(targetEnv, toUpdate);
      progress += 100 / actions.add.length;
    }

  },
};
import { Button, Card, CardContent, CardHeader, Dialog, DialogContent, DialogTitle, makeStyles, createStyles, Box, MenuItem, TableContainer, IconButton, Table, TableBody, TableCell, TableHead, TableRow, DialogActions } from '@material-ui/core';
import type { Theme} from '@material-ui/core';
import DeleteIcon from "@material-ui/icons/Delete";
import { Field, FieldArray, Formik } from 'formik';
import type { FormikProps } from 'formik';
import { TierLevelEnum } from '../../../services/model/brawl-pin';
import * as Yup from 'yup';
import { usePushNotification } from '../../../contexts/AppNotificationContext';
import { useCallback, useMemo, useState } from 'react';
import ItemSelectDialog from '../../../pages/ItemDefinition/ItemSelectDialog';
import { ItemDefinition } from '../../../services/item-definitions';
import type { DropTable } from '../../../services/drop-tables';
import _ from 'lodash';
import { FormikSelectWithLabel } from '../../../components/FormikSelectWithLabel';
import { BrawlPinTierViewModel } from './BrawlPinsViewModels';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    form: {
      display: 'flex',
      flexDirection: 'column',
      margin: 'auto',
      width: 'stretch',
      marginBottom: 8,
      minWidth: 400,
    },
    formControl: {
      minWidth: 400,
    },
  }),
);

const validationSchema = Yup.object().shape({
  level: Yup.string().required('Required'),
  rewardItemIds: Yup.array().min(1).required('Required').of(Yup.object().shape({
    id: Yup.string().required('Required'),
  })),
});

interface AddEditBrawlPinTierDialogProps {
  existingTierViewModel?: BrawlPinTierViewModel | undefined,
  upsertBrawlPinTier: (tierViewModel: BrawlPinTierViewModel, checkLevel: boolean) => void,
  onClose: () => void,
}

export interface RewardItem {
  id: string;
}

export interface BrawlPinTierDialogValue {
  id: string,
  level: TierLevelEnum,
  rewardItemIds: RewardItem[],
}

export const AddEditBrawlPinTierDialog = ({ existingTierViewModel, upsertBrawlPinTier, onClose}: AddEditBrawlPinTierDialogProps) => {
  const classes = useStyles();
  const pushNotification = usePushNotification();
  const [itemSelectDialogOpen, setItemSelectDialogOpen] = useState(false);
  const [selectedRewardIndex, setSelectedRewardIndex] = useState(-1);

  const initialTierValue: BrawlPinTierDialogValue = useMemo(() => {
    if (existingTierViewModel) {
      return {
        id: existingTierViewModel.tier.id,
        level: existingTierViewModel.tier.level,
        rewardItemIds: existingTierViewModel.tier.rewardItemIds.map(ri => { return { id: ri } as RewardItem; }) || []
      };
    }

    return {
      id: '',
      level: TierLevelEnum.Gold,
      rewardItemIds: [{
        id: '',
      }],
    }
  }, [existingTierViewModel]);

  const onSubmit = useCallback((values: BrawlPinTierDialogValue) => {
    const tierViewModel = existingTierViewModel || new BrawlPinTierViewModel();
    tierViewModel.tier.level = values.level;
    // remove any empty reward items
    tierViewModel.tier.rewardItemIds = values.rewardItemIds.map(ri => ri.id).filter(r => !!r);

    // need to check for existing tier with same level
    // - if we're creating new tier
    // - if we're updating existing tier and changing the level
    const needToCheckLevels = !existingTierViewModel || existingTierViewModel.tier.level !== values.level;
    try {
      upsertBrawlPinTier(tierViewModel, needToCheckLevels);
      onClose();
    } catch (e) {
      pushNotification({ type: 'error', message: e instanceof Error ? e.message : 'An unknown error occurred' });
    }
  }, [existingTierViewModel, onClose, pushNotification, upsertBrawlPinTier]);

  const onItemSelect = (items: (ItemDefinition | DropTable)[], form: FormikProps<BrawlPinTierDialogValue>) => {
    if (selectedRewardIndex < 0 || !items || items.length !== 1) {
      return;
    }

    const item = items[0];
    if (item instanceof ItemDefinition) {
      form.setFieldValue(`rewardItemIds[${selectedRewardIndex}].id`, item.itemId);
    }
  };

  const dialogTitle = existingTierViewModel ? 'Edit Brawl Pin Tier' : 'Add Brawl Pin Tier';
  return (
    <>
      <Dialog
        open={true}
        fullWidth
        onClose={onClose}
        aria-labelledby="add-dialog-title"
      >
        <Formik<BrawlPinTierDialogValue>
          initialValues={initialTierValue}
          validationSchema={validationSchema}
          validateOnMount={true}
          onSubmit={onSubmit}
        >
          {formikData => (<form onSubmit={formikData.handleSubmit}>
            <DialogTitle id="add-dialog-title">{dialogTitle}</DialogTitle>
            <DialogContent className={classes.form}>
              <Box display="flex" flexDirection="column" justifyContent="center">
                <Field
                  component={FormikSelectWithLabel}
                  id="level"
                  name="level"
                  label={"Level"}
                >
                  {Object.values(TierLevelEnum).map(level => (
                    <MenuItem key={level} value={level}>{level}</MenuItem>
                  ))}
                </Field>
              </Box>
              <Box  mt={4}>
                <Card>
                  <FieldArray name='rewardItemIds'>
                    {arrayHelpers => (<>
                      <CardHeader
                        title="Rewards"
                        titleTypographyProps={{variant: 'subtitle1'}}
                        action={
                          <Button
                            color="primary"
                            variant="contained"
                            size="small"
                            onClick={
                              () => {
                                const existingUndefined = formikData.values.rewardItemIds.find(ri => ri.id === '');
                                if (!existingUndefined) {
                                  arrayHelpers.push({id: ''});
                                }
                            }}
                          >
                            Add Reward Item
                          </Button>
                        }
                      />
                    </>)}
                  </FieldArray>
                  <CardContent>
                    <Box display="flex" flexDirection="column" justifyContent="center">
                      <FieldArray
                        name='rewardItemIds'>
                        {arrayHelpers => (<>
                          <TableContainer style={{maxHeight: 440}}>
                            <Table stickyHeader>
                              <TableHead>
                                <TableRow>
                                  <TableCell>Item ID</TableCell>
                                  <TableCell/>
                                  <TableCell/>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {formikData.values.rewardItemIds.map((rewardItem, index) => (
                                  <TableRow key={`reward-${index}`}>
                                    <TableCell>
                                      {rewardItem.id}
                                    </TableCell>
                                    <TableCell>
                                      <Button
                                        type="button"
                                        color="primary"
                                        size="small"
                                        variant="text"
                                        onClick={() => {
                                          setSelectedRewardIndex(index);
                                          setItemSelectDialogOpen(true);
                                        }}
                                      >
                                        Select Item
                                      </Button>
                                    </TableCell>
                                    <TableCell>
                                      <IconButton onClick={() => arrayHelpers.remove(index)}>
                                        <DeleteIcon/>
                                      </IconButton>
                                    </TableCell>
                                  </TableRow>
                                ))}
                              </TableBody>
                            </Table>
                          </TableContainer>
                        </>)
                        }
                      </FieldArray>
                    </Box>
                  </CardContent>
                </Card>
              </Box>
            </DialogContent>
            <DialogActions>
              <Button
                type="submit"
                variant="contained"
                disabled={!formikData.isValid}
                color="primary"
              >
                Save
              </Button>
              <Button onClick={onClose} color="primary">
                Cancel
              </Button>
            </DialogActions>
            {itemSelectDialogOpen && (
              <FieldArray name='rewardItemIds'>
                {arrayHelpers => (<>
                  <ItemSelectDialog
                    defaultTab={'bundles'}
                    includeDropTables={false}
                    showCatalogSelect
                    onSelect={items => onItemSelect(items, formikData)}
                    onClose={() => {
                      setSelectedRewardIndex(-1);
                      setItemSelectDialogOpen(false)}
                    }
                  />
                </>)}
              </FieldArray>
            )}
          </form>)}
        </Formik>
      </Dialog>
    </>
  );
}

import type { ApiError } from '../api';
import { api } from '../api';

export class Challenge {
  challenge_id = '';
  template_version = '';
  template_id = '';
  challenge_name = '';
  challenge_desc = '';
  parameters: ChallengeParameter[] = [];
  rewards: Reward[] = [];
  party_pass_plus_only = false;
  resettable_progress = false;
  trigger_type: ChallengeTriggerTypeEnum = ChallengeTriggerTypeEnum.GAME;
  special = false;

  constructor(json?: any) {
    if (json) {
      this.challenge_id = json.challengeId;
      this.template_version = json.templateVersion;
      this.template_id = json.templateId;
      this.challenge_name = json.challengeName;
      this.challenge_desc = json.challengeDesc;
      this.parameters = json.parameters.map((challengeParameter: any) => new ChallengeParameter(challengeParameter));
      this.rewards = json.rewards.map((reward: any) => new Reward(reward));
      this.party_pass_plus_only = json.partyPassPlusOnly;
      this.resettable_progress = json.resettableProgress;
      this.trigger_type = json.triggerType;
      this.special = json.special;
    }
  }

  toJson(): any {
    return {
      templateVersion: this.template_version,
      templateId: this.template_id,
      challengeName: this.challenge_name,
      challengeDesc: this.challenge_desc,
      parameters: this.parameters,
      rewards: this.rewards,
      partyPassPlusOnly: this.party_pass_plus_only,
      resettableProgress: this.resettable_progress,
      triggerType: this.trigger_type,
      special: this.special,
    };
  }

  duplicate(): Challenge {
    const copy = new Challenge(this.toJson());
    copy.challenge_id = '';
    copy.challenge_name = `${this.challenge_name}-Copy`;
    return copy;
  }
}

export enum ChallengeTriggerTypeEnum {
  BLANKO_LEVEL = 'BLANKO_LEVEL',
  COMPLETE_CHALLENGE = "COMPLETE_CHALLENGE",
  GAME = 'GAME',
  GAME_CHALLENGE = "GAME_CHALLENGE",
  LOGIN = 'LOGIN',
  SPEND_EARN = "SPEND_EARN",
}

export class ChallengeParameter {
  name = '';
  type = '';
  value = '';

  constructor(json?: any) {
    if (json) {
      this.name = json.name;
      this.type = json.type;
      this.value = json.value;
    }
  }

  toJson(): any {
    return {
      name: this.name,
      type: this.type,
      value: this.value
    }
  }
}

export class Reward {
  type = '';
  param: any = {};

  constructor(json?: any) {
    if (json) {
      this.type = json.type;
      this.param = json.param;
    }
  }

  toJson(): any {
    return {
      type: this.type,
      param: JSON.parse(JSON.stringify(this.param))
    }
  }

  clone(): Reward {
    return new Reward(this.toJson());
  }
}

export class ChallengesService {
  static async getChallenges(): Promise<Array<Challenge>> {
    return api.get({ url: '/players/challenges' })
      .then(seasons => seasons.map((season: any) => new Challenge(season)))
      .catch((e: ApiError) => {
        e.message = `Failed to get challenges. ${e.message}`;
        throw e;
      });
  }

  static async getChallenge(challengeId: string): Promise<Challenge> {
    return api.get({ url: `/players/challenges/${challengeId}` })
      .then(challenge => new Challenge(challenge))
      .catch((e: ApiError) => {
        e.message = `Failed to get challenge. ${e.message}`;
        throw e;
      });
  }

  static async createChallenge(challenge: Challenge): Promise<Challenge> {
    return api.post({ url: '/players/challenges', body: challenge.toJson() })
      .then(challenge => new Challenge(challenge))
      .catch((e: ApiError) => {
        e.message = `Failed to create challenge. ${e.message}`;
        throw e;
      });
  }

  static async updateChallenge(challenge: Challenge): Promise<Challenge> {
    return api.put({ url: `/players/challenges/${challenge.challenge_id}`, body: challenge.toJson() })
      .then(challenge => new Challenge(challenge))
      .catch((e: ApiError) => {
        e.message = `Failed to update challenge. ${e.message}`;
        throw e;
      });
  }

  static async deleteChallenge(challengeId: string): Promise<null> {
    return api.delete({ url: `/players/challenges/${challengeId}` })
      .catch((e: ApiError) => {
        e.message = `Failed to delete challenge. ${e.message}`;
        throw e;
      });
  }
}
import {Box, Button, CircularProgress, Link, Tooltip} from '@material-ui/core';
import type {Column, Query} from 'material-table';
import type { RefObject } from 'react';
import { createRef, Component } from 'react';
import type { ConnectedProps} from 'react-redux';
import {connect} from 'react-redux';
import {Link as RouterLink} from 'react-router-dom';
import type {MenuAction} from '../../components/MaterialTable';
import MaterialTable from '../../components/MaterialTable';
import {getAllTransactionsAsync, invalidateTransactionsPage} from '../../redux/transactions/actions';
import type {RootState} from '../../redux/reducers';
import {paginatedRequestFromQuery} from '../../services/model/pagination';
import type {Player} from '../../features/players/players';
import {selectAllTransactionsPage} from "../../redux/transactions/selectors";
import {UserService} from "../../services/user";
import UndoIcon from "@material-ui/icons/Undo";
import DoneIcon from "@material-ui/icons/Done"
import RemoveIcon from "@material-ui/icons/Remove"
import RefundDialog from "./RefundDialog";
import csvStringify from 'csv-stringify/lib/sync';
import type {TransactionAggregate, TransactionLast30DaysRequest} from "../../services/transactions";
import { TransactionService} from "../../services/transactions";
import download from "downloadjs";
import TransactionDetailDialog from "./TransactionDetailDialog";
import { BuiltInCurrencyEnum } from '../../services/model/currency';

interface OwnProps {
  player?: Player;
}

const mapStateToProps = (state: RootState) => ({
  transactions: selectAllTransactionsPage(state)
});
const mapDispatch = {
  requestTransactions: getAllTransactionsAsync.request,
  invalidateTransactionsPage: invalidateTransactionsPage
};
const connector = connect(mapStateToProps, mapDispatch);

type Props = ConnectedProps<typeof connector> & OwnProps;

interface TransactionsState extends Object {
  showPendingTransactions: boolean;
  showCompletedTransactions: boolean;
  showFailedTransactions: boolean;
  showPastTransactions: boolean;
  transactionDetailDialogOpen: boolean;
  refundDialogOpen: boolean;
  transactionToRefund?: TransactionAggregate;
  csvExportInProgress: boolean;
  detailTransaction?: TransactionAggregate;
}

class TransactionsGrid extends Component<Props, TransactionsState> {
  private readonly tableRef: RefObject<any>;

  constructor(props: Props) {
    super(props);
    this.state = {
      showCompletedTransactions: true,
      showFailedTransactions: true,
      showPastTransactions: true,
      showPendingTransactions: true,
      refundDialogOpen: false,
      transactionDetailDialogOpen: false,
      csvExportInProgress: false
    };
    this.tableRef = createRef();
  }

  getColumns() {
    const columns: Column<TransactionAggregate>[] = [
      {
        title: 'Player Name',
        field: 'playerName',
        render: transaction => {
          if (!transaction.playerName) {
            return (
              <em>Unverified</em>
            );
          }

          return (
            <Link component={RouterLink} to={`/players/${transaction.playerExternalId}`}>
              {transaction.playerName}
            </Link>
          );
        }
      },
      {
        title: 'Date',
        field: 'dateCreated',
        type: 'datetime',
        defaultSort: 'desc'
      },
      {
        title: 'Item(s) Purchased',
        field: 'purchaseSummary',
        sorting: false
      },
      {
        title: 'Amount',
        field: 'amount',
        render: transactionAggregate => {
          return TransactionsGrid.formatCurrency(transactionAggregate.amount, transactionAggregate.currencyCode);
        }
      },
      {
        title: 'Refunded?',
        field: 'transactionRefunded',
        render: transactionAggregate => {
          if (!transactionAggregate.transactionRefunded) {
            return (
              <RemoveIcon style={{fill: "grey"}} />
            );
          }
          return (
            <Tooltip title={transactionAggregate.relatedComment || ''} placement="top">
              <DoneIcon />
            </Tooltip>
          );
        }
      }
    ];

    return columns;
  };

  private static formatCurrency(amount: number, currencyCode: string) {
    if (currencyCode === 'BB' || currencyCode === 'GV') {
      return `${amount} ${currencyCode}`;
    } else if (currencyCode === BuiltInCurrencyEnum.Free) {
      return `${currencyCode}`;
    }
    const formatter = new Intl.NumberFormat(undefined, {
      style: 'currency',
      currency: currencyCode
    });
    return `${formatter.format(amount)}`;
  }

  getTableMenuActions = (transaction: TransactionAggregate) => {
    const actions: MenuAction<TransactionAggregate>[] = [];
    if (UserService.canUpdate('commerce')) {
      actions.push({ type: 'button', icon: UndoIcon, label: 'Refund Purchase', onClick: this.refundTransaction, disabled: item => item.transactionRefunded });
    }
    return actions;
  };

  refundTransaction = (transactionAggregate: TransactionAggregate) => {
    this.setState({
      refundDialogOpen: true,
      transactionToRefund: transactionAggregate
    });
  };

  getTransactionsPage = (query: Query<TransactionAggregate>) => {
    const request = paginatedRequestFromQuery(query);
    if (this.props.player) {
      request.playerId = this.props.player.externalId;
    }
    else {
      request.playerId = '';
    }
    request.showPendingTransactions = this.state.showPendingTransactions;
    request.showCompletedTransactions = this.state.showCompletedTransactions;
    request.showFailedTransactions = this.state.showFailedTransactions;
    request.showPastTransactions = this.state.showPastTransactions;
    request.searchCriteria = query.search.trim();
    this.props.requestTransactions(request);
  };

  export = () => {
    if (this.state.csvExportInProgress) {
      return;
    }
    this.setState({...this.state, csvExportInProgress: true});

    const request: TransactionLast30DaysRequest = (!!this.props.player) ? {playerId: this.props.player.externalId} : {};
    TransactionService.getAllTransactionsLast30Days(request)
      .then((data) => {
        const csvData = csvStringify(data, {
          header: true,
          columns: ['id', 'dateCreated', 'amount', 'currencyCode', 'comment', 'promoCode', 'playerExternalId', 'playerName', 'transactionRefunded', 'relatedComment', 'purchaseSummary']
        });
        this.setState({...this.state, csvExportInProgress: false});
        download(csvData, 'purchasesLast30Days.csv', 'text/csv');
      });

  };

  setShowCompletedTransactions(show: boolean) {
    this.setState({
      ...this.state,
      showCompletedTransactions: show
    });
    this.props.invalidateTransactionsPage();
  };

  setShowPendingTransactions(show: boolean) {
    this.setState({
      ...this.state,
      showPendingTransactions: show
    });
    this.props.invalidateTransactionsPage();
  };

  setShowPastTransactions(show: boolean) {
    this.setState({
      ...this.state,
      showPastTransactions: show
    });
    this.props.invalidateTransactionsPage();
  };

  setShowFailedTransactions(show: boolean) {
    this.setState({
      ...this.state,
      showFailedTransactions: show
    });
    this.props.invalidateTransactionsPage();
  };

  render() {
    const transactions = this.props.transactions;
    return (
      <>
        <Box mb={2} textAlign="right">
          <Button variant="contained" color="primary" onClick={this.export}>
            {this.state.csvExportInProgress && (
              <CircularProgress color={"secondary"} style={{marginRight: "10px"}} />
            )}
            Export Last 30 days as CSV
          </Button>
        </Box>
        <MaterialTable
          tableRef={this.tableRef}
          title={'Purchases'}
          columns={this.getColumns()}
          data={{
            pageState: transactions,
            runQuery: this.getTransactionsPage
          }}
          options={{
            debounceInterval: 500,
            thirdSortClick: false,
            search: false,
            detailPanelType: 'single',
            pageSize: 20,
            pageSizeOptions: [20, 50, 100]
          }}
          menuActions={this.getTableMenuActions}
          onRowClick={(event, rowData) => {
            if (rowData) {
              this.setState({ ...this.state, detailTransaction: rowData, transactionDetailDialogOpen: true });
            }
          }}
        />

        {this.state.transactionDetailDialogOpen && (
          <TransactionDetailDialog
            transaction={this.state.detailTransaction}
            onClose={() => this.setState({ ...this.state, transactionDetailDialogOpen: false })}
          />
        )}

        {this.state.refundDialogOpen && (
          <RefundDialog
            transactionAggregate={this.state.transactionToRefund}
            onClose={() => this.setState({ ...this.state, refundDialogOpen: false })}
          />
        )}
      </>);
  }
}

export default connector(TransactionsGrid);
import { Button, Dialog, DialogContent, DialogTitle } from "@material-ui/core";
import { Fragment, useState } from "react";
import type { MarketplaceItemDataTag } from "../shop";

interface Props {
  tags: MarketplaceItemDataTag[];
  onClose: () => void;
  onSelectTag: (tag: MarketplaceItemDataTag) => void;
}

export const CreateMarketplaceItemDataTagShopTabItemDialog = (props: Props) => {
  const [open, setOpen] = useState(true);
  return <Dialog
    open={open}
    onClose={() => setOpen(false)}
    onExited={props.onClose}
  >
    <DialogContent>
      <DialogTitle>Select marketplace item data tag:</DialogTitle>
      {props.tags.filter(t => t.name !== 'NONE' && t.name !== 'SOLD_OUT').map(tag => (
        <Fragment key={tag.name}><Button
          key={tag.name}
          onClick={() => {
            setOpen(false);
            props.onSelectTag(tag);
          }}
          variant='outlined'
        >
          {tag.name}
        </Button> </Fragment>)
      )}
    </DialogContent>
  </Dialog>;
};

import { Box, Button, FormControl, Grid, InputLabel, MenuItem, Typography } from '@material-ui/core';
import { Field } from 'formik';
import { Select as FormikSelect } from 'formik-material-ui';
import { Fragment } from 'react';
import { useTypedSelector } from '../../redux/reducers';

interface Props {
  formName: string;
  classes: string[];
  type: string;
  onRemove: () => void;
}

const BlankoAbilityForm = (props: Props) => {

  const abilitiesMap = useTypedSelector(state => state.blankoProgressions.abilities);

  const options = abilitiesMap[props.type] || abilitiesMap.abilities;

  return (
    <Grid container spacing={1} alignItems="center">
      <Grid item xs={6}>
        <FormControl>
          <InputLabel>Type</InputLabel>
          <Field
            component={FormikSelect}
            name={`${props.formName}.type`}
          >
            <MenuItem value="abilities">Skill</MenuItem>
            <MenuItem value="modifiers">Power perk</MenuItem>
            <MenuItem value="passives">Foundation perk</MenuItem>
          </Field>
        </FormControl>
      </Grid>
      <Grid item xs={6}>
        <Box textAlign="right">
          <Button color="primary" variant="contained" onClick={props.onRemove}>
            Remove {props.type === 'abilities' ? 'Skill' : 'Perk'}
          </Button>
        </Box>
      </Grid>

      {props.classes.map(blankoClass => (<Fragment key={blankoClass}>
        <Grid item xs={4}>
          <Box mt={2}>
            <Typography>
              {blankoClass}
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={8}>
          <FormControl fullWidth>
            <InputLabel>{props.type === 'abilities' ? 'Skill' : 'Perk'}</InputLabel>
            <Field
              component={FormikSelect}
              name={`${props.formName}.${blankoClass}`}
            >
              <MenuItem value=""><em>No {props.type === 'abilities' ? 'skill' : 'perk'}</em></MenuItem>
              {options.map(option => (
                <MenuItem key={option.id} value={option.id}>{option.name}</MenuItem>
              ))}
            </Field>
          </FormControl>
        </Grid>
      </Fragment>))}
    </Grid>
  );
}

export default BlankoAbilityForm;
import { useTypedSelector } from '../../../../redux/reducers';
import { MigrationDialog } from './MigrationDialog';

export const MigrationDialogContainer = () => {
  const migrationRun = useTypedSelector(state => state.catalogMigration.run);

  return (<MigrationDialog
    run={migrationRun}
    step={migrationRun.step} />);
}

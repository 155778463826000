import { Box, CircularProgress, Grid, IconButton, MenuItem, Select, Typography } from '@material-ui/core';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router';
import { setAppNotification } from '../../../redux/app/actions';
import type { ApiError } from '../../../services/api';
import type { Shop, ShopVersion } from '../shop';
import { getShopVersions } from '../shopsApi';
import { ShopVersionEditor } from './ShopVersionEditor';
import AssessmentIcon from '@material-ui/icons/Assessment';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { green, orange } from '@material-ui/core/colors';

export interface ShopTimelineProps {
  shop: Shop;
}

export const ShopTimeline = (props: ShopTimelineProps) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();

  const [shopVersions, setShopVersions] = useState<ShopVersion[] | null>(null);
  const [shopVersion, setShopVersion] = useState<number>(-1);
  const [liveVersion, setLiveVersion] = useState<number>(-1);
  const [selectVersions, setSelectVersions] = useState<ShopVersion[] | null>(null);

  useEffect(() => {
    getShopVersions(props.shop.id).then(versions => {
      versions.sort((a, b) => {
        if (a.startDate && b.startDate) {
          return new Date(a.startDate).getTime() - (new Date(b.startDate).getTime());
        } else if (!a.startDate && !b.startDate) {
          return a.name.localeCompare(b.name);
        } else {
          return a.startDate ? 1 : -1;
        }
      });
      const now = new Date().getTime();
      let liveVersionIndex = versions.slice().sort((a, b) => (new Date(b.startDate || 0).getTime()) - (new Date(a.startDate || 0).getTime()))
        .findIndex(v => v.startDate && new Date(v.startDate).getTime() < now);

      if (liveVersionIndex > -1) {
        // The search array was sorted descending. Flip the index to match the ascending array used in the UI
        liveVersionIndex = versions.length - 1 - liveVersionIndex;
      }

      setLiveVersion(liveVersionIndex);
      setShopVersions(versions);
    }).catch((e: ApiError) => dispatch(setAppNotification({ type: 'error', message: `Failed to load shop. ${e.message}` })));
  }, [dispatch, props.shop]);

  useEffect(() => {
    if (shopVersions && shopVersions.length > 0 && shopVersion === -1) {
      const query = new URLSearchParams(location.search);
      const queryVersionId = query.get('version');
      const queryVersion = queryVersionId === null ? -1 : shopVersions.findIndex(v => v.id === queryVersionId);
      if (queryVersion > -1) {
        setShopVersion(queryVersion);
      } else if (liveVersion > -1) {
        setShopVersion(liveVersion);
      } else {
        setShopVersion(0);
      }
    }
  }, [shopVersions, liveVersion, shopVersion, location]);

  useEffect(() => {
    if (shopVersions && shopVersion > -1) {
      const lowerBound = Math.max(0, shopVersion - 5);
      const upperBound = Math.min(shopVersions.length, shopVersion + 6);
      setSelectVersions(shopVersions.slice(lowerBound, upperBound));
    } else {
      setSelectVersions(null);
    }
  }, [shopVersions, shopVersion]);

  const navigateToVersion = useCallback((index: number) => {
    if (shopVersions) {
      setShopVersion(index);
      history.replace(`/shops/${props.shop.id}/timeline?version=${shopVersions[index].id}`)
    }
  }, [history, shopVersions, props.shop]);

  const onVersionSelectChange = useCallback((versionId: string) => {
    if (shopVersions) {
      const index = shopVersions.findIndex(v => v.id === versionId);
      if (index > -1) {
        navigateToVersion(index);
      }
    }
  }, [navigateToVersion, shopVersions]);

  if (!shopVersions) {
    return (
      <Box textAlign="center">
        <CircularProgress />
      </Box>
    );
  }

  if (shopVersions.length < 1) {
    return (
      <Box textAlign="center" mt={2}>
        <Typography color="textSecondary">
          No shop versions available yet.
        </Typography>
      </Box>
    )
  }

  return (<>
    <Box mb={2}>
      <Grid container alignItems="center">
        <Grid item xs></Grid>
        <Grid item xs="auto">
          <IconButton disabled={shopVersion < 1} onClick={() => navigateToVersion(shopVersion - 1)}>
            <ChevronLeftIcon />
          </IconButton>
        </Grid>
        <Grid item xs={4}>
          {shopVersion > -1 && selectVersions !== null && (
            <Box textAlign="center">
              <Select
                value={shopVersions[shopVersion].id}
                onChange={e => onVersionSelectChange(e.target.value as string)}
                fullWidth
              >
                {selectVersions.map(version => (
                  <MenuItem key={version.id} value={version.id}>
                    <TimelineHeader
                      shopVersion={version}
                      live={liveVersion > -1 && shopVersions[liveVersion].id === version.id}
                      canary={version.id === props.shop.canaryVersionId}
                    />
                  </MenuItem>
                ))}
              </Select>
            </Box>
          )}
        </Grid>
        <Grid item xs="auto">
          <IconButton disabled={shopVersion >= shopVersions.length - 1} onClick={() => navigateToVersion(shopVersion + 1)}>
            <ChevronRightIcon />
          </IconButton>
        </Grid>
        <Grid item xs></Grid>
      </Grid>
    </Box>

    {shopVersion > -1 && (
      <ShopVersionEditor
        shopVersionId={shopVersions[shopVersion].id}
      />
    )}
  </>);
}

const TimelineHeader = (props: { shopVersion: ShopVersion, live: boolean, canary: boolean }) => {
  return (<>
    {props.live && <CheckCircleIcon fontSize="small" style={{ color: green[500], marginRight: 5 }} />}
    {props.canary && <AssessmentIcon fontSize="small" style={{ color: orange[500], marginRight: 5 }} />}
    {props.shopVersion.name} - {props.shopVersion.startDate === null ? 'Not scheduled' : new Date(props.shopVersion.startDate).toLocaleString()}
  </>);
}
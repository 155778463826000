import type { Theme} from '@material-ui/core';
import { Box, Grid, makeStyles, Typography } from '@material-ui/core';
import ImageIcon from '@material-ui/icons/Image';
import { useState, useCallback } from 'react';
import { useDropzone } from 'react-dropzone';

const useStyles = makeStyles((theme: Theme) => ({
  uploadButton: {
    borderWidth: 3,
    borderStyle: 'dashed',
    borderColor: theme.palette.secondary.main,
    borderRadius: 10,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'contain',
    backgroundPosition: 'center',
    cursor: 'pointer',
    textAlign: 'center',
    '&:hover, &.active': {
      backgroundColor: theme.palette.type === 'dark' ? 'rgba(255, 255, 255, 0.1)' : 'rgba(0, 0, 0, 0.1)'
    }
  }
}));

interface Props {
  imageUrl?: string
  onFileChange: (file: File) => void;
}

const ImageUpload = ({imageUrl, onFileChange}: Props) => {
  const classes = useStyles();

  const [imageData, setImageData] = useState(imageUrl ?? '');

  const onImageDrop = useCallback((files: File[]) => {
    if (files.length === 1) {
      const fileReader = new FileReader();

      fileReader.onload = () => {
        if (typeof fileReader.result === 'string') {
          setImageData(fileReader.result);
          onFileChange(files[0]);
        }
      }

      fileReader.readAsDataURL(files[0]);
    }
  }, [onFileChange])

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop: onImageDrop, accept: 'image/*', multiple: false, maxFiles: 1, noKeyboard: true });

  return (<>
    <input data-testid="image-upload" {...getInputProps()} />
    <Box
      className={`${classes.uploadButton} ${isDragActive ? 'active' : ''}`}
      height={200}
      style={{ backgroundImage: imageData ? `url(${imageData})` : undefined }}
      {...getRootProps()}
    >
      <Grid container alignItems="center" style={{ height: '100%' }}>
        <Grid item xs={12}>
          {imageData ? (
            null
          ) : (<>
            <ImageIcon />
            <Typography>
              Click or drop an image here
            </Typography>
          </>)}
        </Grid>
      </Grid>
    </Box>
  </>);
}

export default ImageUpload;
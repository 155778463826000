import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid } from '@material-ui/core';
import { Field, Form, Formik } from 'formik';
import { TextField } from 'formik-material-ui';
import type { PublishedBlock } from '../../../services/blocks';
import { createValidator, Validators } from '../../../utils/forms';
import { BlocksService } from '../../../services/blocks';
import { useCallback, useState } from 'react';
import {ApiError} from "../../../services/api";
import {usePushNotification} from "../../../contexts/AppNotificationContext";
import {OptionSingleCheckbox} from "../../../pages/CatalogMigration/Components/Options/OptionSingleCheckbox";
import {openAdminConfirmationDialog} from "../../../shared/hooks/useAdminConfirmationDialog";

interface FormValues {
  name: string;
  renameBlock: boolean;
}

const formValidator = createValidator<FormValues>({
  name: Validators.notBlank()
});

export const UpdatePublishedBlockDialog = (props: { block: PublishedBlock, onClose: () => void }) => {
  const [initialFormValues, setInitialFormValues] = useState<FormValues>({
    name: props.block.name,
    renameBlock: false
  });
  const [open, setOpen] = useState(true);
  const [renameBlock, setRenameBlock] = useState(false);
  const pushNotification = usePushNotification();

  const onSubmit = useCallback((values: FormValues) => {
    const base = renameBlock ? `You are also renaming the base block.` : `You are not renaming the base block.`;
    const title = `Rename Published Block ${props.block.name} -> ${values.name}? ${base}`;

    openAdminConfirmationDialog({title: title, onConfirm: () => {
      const block = props.block;
      block.name = values.name;
      BlocksService.updatePublishedBlockName(block, renameBlock).catch((e: ApiError) => pushNotification({
        type: 'error',
        message: `Failed to edit published block name. ${e.message}`
      })).finally(() => {
        { setOpen(false); }
      });
    }, onCancel: () => {
      setOpen(false);
    }});
  }, [props.block, pushNotification, renameBlock]);

  return (<>
    <Dialog
      open={open}
      onClose={() => setOpen(false)}
      onExited={props.onClose}
      fullWidth
      maxWidth="lg"
    >
      <Formik<FormValues>
        initialValues={initialFormValues}
        validate={formValidator}
        validateOnMount={true}
        onSubmit={onSubmit}
      >
        {({ submitForm, isSubmitting, isValid }) => (
          <Form>
            <DialogTitle>Rename block: {props.block.name}</DialogTitle>
            {(<>
              <DialogContent>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Field
                      component={TextField}
                      name="name"
                      type="text"
                      label="Name"
                      fullWidth
                    />
                  </Grid>
                </Grid>
                <OptionSingleCheckbox
                  label={'Rename Base Block'}
                  checked={renameBlock}
                  onClick={() => { setRenameBlock(!renameBlock)}}
                />
              </DialogContent>
              <DialogActions>
                <Button color="primary" variant="contained" disabled={isSubmitting || !isValid} onClick={submitForm}>
                  Rename
                </Button>
              </DialogActions>
            </>)}
          </Form>
        )}
      </Formik>
    </Dialog>
  </>);
}
import { deleteRegionFeatureByFeatureId, getRegionFeatures, prettifyFeatureName } from "../geo-blocking-api";
import { usePushNotification } from "../../../contexts/AppNotificationContext";
import { useCallback, useEffect, useState } from "react";
import { Box, Button, CircularProgress, Grid, Typography } from "@material-ui/core";
import { UserService } from "../../../services/user";
import { Column } from "material-table";
import { AdminTable } from "../../../shared";
import { Create, Delete } from "@material-ui/icons";
import _ from "lodash";
import { GeoBlockedFeatureIdEnum, GeoBlockedFeatureMap } from "../geo-blocking-types";
import { AddGeoBlockingDialog } from "./AddGeoBlockingDialog";
import { EditGeoBlockingDialog } from "./EditGeoBlockingDialog";


const gridColumns: Column<GeoBlockedFeatureMap>[] = [
  {
    title: "Feature",
    field: "featureId",
    render: gbmap => (
      prettifyFeatureName(gbmap.featureId?.toString() || "")
    )
  },
  {
    title: "Regions",
    field: "regions",
    // eslint-disable-next-line react/display-name
    render: gbmap => (
      gbmap.regions.join(", ")
    )
  }
];

export const GeoBlockingPage = () => {
  const pushNotification = usePushNotification();
  const [features, setFeatures] = useState<GeoBlockedFeatureMap[] | null>(null);
  const [openAddDialog, setOpenAddDialog] = useState<boolean>(false);
  const [openEditDialog, setOpenEditDialog] = useState<boolean>(false);
  const [editItem, setEditItem] = useState<GeoBlockedFeatureMap | null>(null);

  const fetchFeatures = useCallback(() => {
    getRegionFeatures().then(regionAndFeature => {
      const results = _.groupBy(regionAndFeature, i => i.featureId);
      const geoMap: GeoBlockedFeatureMap[] = [];
      for (const k in results) {
        //Typescript is so gross sometimes
        geoMap.push({ featureId: GeoBlockedFeatureIdEnum[k as keyof typeof GeoBlockedFeatureIdEnum], regions: results[k].map(i => i.regionId) })
      }
      setFeatures(geoMap)
    })
      .catch(err => pushNotification({ type: 'error', message: `Failed to load geo-blocking settings: ${err.message}` }));
  },[pushNotification]);

  useEffect(() => {
    fetchFeatures();
  }, [fetchFeatures]);

  const onClose = useCallback(() => {
    setOpenAddDialog(false);
    setOpenEditDialog(false);
    setEditItem(null);

    fetchFeatures();
  },[fetchFeatures]);

  const availableKeys = useCallback((): string[] => {
    const featureKeys = features?.map(f => f.featureId);
    const hasOpenKeys = Object.keys(GeoBlockedFeatureIdEnum).filter(ek => featureKeys?.indexOf(GeoBlockedFeatureIdEnum[ek as keyof typeof GeoBlockedFeatureIdEnum]) === -1)
    return hasOpenKeys;
  }, [features]);

  const onAdd = useCallback(() => {
    setOpenAddDialog(true);
  }, []);
  const onEdit = useCallback((item) => {
    setEditItem(item)
    setOpenEditDialog(true);
  }, []);
  const onDelete = useCallback((item) => {
    deleteRegionFeatureByFeatureId(item.featureId)
      .then(f => fetchFeatures())
      .catch(err => pushNotification({ type: 'error', message: `Failed to delete geo-blocking settings: ${err.message}` }));
  }, [fetchFeatures, pushNotification]);

  return <>
    <Box mb={2}>
      <Grid container spacing={2} alignItems="center">
        <Grid item xs>
          <Typography variant="h6">
            Geo-Blocking
          </Typography>
        </Grid>
        {UserService.canCreate('geoblocking') && availableKeys().length > 0 && (
          <Grid item>
            <Button
              onClick={onAdd}
              variant="contained"
              color="primary"
            >
              Add Geo-blocked feature
            </Button>
          </Grid>
        )}
      </Grid>
    </Box>
    {features &&
      <AdminTable<GeoBlockedFeatureMap>
        storageId="geo-blocking"
        columns={gridColumns}
        data={features}
        title=""
        menuActions={[
          { type: 'button', icon: Create, label: 'Edit', onClick: onEdit },
          { type: 'button', icon: Delete, label: 'Delete', onClick: onDelete }
        ]}
        options={{
          columnsButton: true,
          pageSize: 10,
          pageSizeOptions: [5, 10, 20]
        }}
        useRouter />
    }
    {
      !features &&
        <Box textAlign="center">
          <CircularProgress />
        </Box>
    }
    <AddGeoBlockingDialog show={openAddDialog} onClose={onClose} availableKeys={availableKeys()} />
    <EditGeoBlockingDialog show={openEditDialog} onClose={onClose} item={editItem}  />
  </>
};
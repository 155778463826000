import type { TooltipProps } from '@material-ui/core';
import { Tooltip } from '@material-ui/core';
import HelpIcon from '@material-ui/icons/HelpOutline';
import ErrorIcon from '@material-ui/icons/ErrorOutline';
import { useMemo } from 'react';

type TooltipIconProps = Partial<TooltipProps> & {
  icon: 'warn' | 'help'
};

export const TooltipIcon = (props: TooltipIconProps) => {
  const Icon = useMemo(() => {
    if (props.icon === 'warn') {
      return ErrorIcon;
    } else {
      return HelpIcon;
    }
  }, [props.icon]);

  return (
    <Tooltip
      title={props.title || 'Missing title'}
      placement={props.placement || 'right'}
      arrow={props.arrow === undefined ? true : props.arrow}
    >
      <Icon color="secondary" />
    </Tooltip>
  );
}
import { LinkTabConfig, LinkTabs } from "../../../components/LinkTabs";
import { useHistory, useLocation, useRouteMatch } from "react-router";
import { LocalizationBranches } from "./LocalizationBranches";
import { LocalizationPlayerOverrides } from "./LocalizationPlayerOverrides";
import { AddLocalizationKey } from "./AddLocalizationKey";
import { UserService } from "../../../services/user";

export const LocalizationPage = () => {
  const tabs: LinkTabConfig[] = [
    {
      url: '/branches',
      label: 'Branches',
      render() {
        return <LocalizationBranches/>;
      }
    },
    {
      url: '/player-overrides',
      label: 'Player Overrides',
      render() {
        return <LocalizationPlayerOverrides/>;
      }
    },
  ];
  if (UserService.canCreate('lokalise')) {
    tabs.push({
      url: '/add-key',
      label: 'Add Key',
      render() {
        return <AddLocalizationKey/>;
      }
    });
  }
  const history = useHistory();
  const location = useLocation();
  const match = useRouteMatch();
  return <LinkTabs
    history={history}
    location={location}
    match={match}
    tabs={tabs}
  />;
};

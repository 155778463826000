import { Box, Grid, Typography } from "@material-ui/core";
import type { Theme } from "@material-ui/core";
import { useTheme, withStyles } from "@material-ui/styles";
import { useCallback, useEffect, useMemo, useState } from "react";
import { store } from '../../../../redux/store'
import { LiveEventForComparison } from "./live-event-comparer";
import { seasonalDataComparer, Stringish } from "../seasonal-data/seasonal-data-comparer";
import { SeasonalDiffView } from "../seasonal-data/SeasonalDiffView";
import { JunctionEventInfoDiffView } from "../junction-event-info/JunctionEventInfoDiffView";

export interface LiveEventsDiffViewProps {
  beforeChanges?: LiveEventForComparison;
  afterChanges: LiveEventForComparison;
  previous?: LiveEventForComparison;
  current: LiveEventForComparison;
}

export const LiveEventsDiffView = (props: LiveEventsDiffViewProps) => {
  const { beforeChanges, afterChanges, previous, current } = props;
  const classes = seasonalDataComparer.getStyles();

  const watchedMode = store.getState().app.darkMode;
  const [darkMode, setDarkMode] = useState(watchedMode);
  const theme = useTheme<Theme>();

  useEffect(() => {
    if (watchedMode != darkMode) {
      setDarkMode(watchedMode);
    }
  }, [darkMode, setDarkMode, watchedMode]);

  const BeforeTypography = useMemo(() => {
    return withStyles({
      root: {
        background: darkMode ? "#632F34" : "#ffeef0",
        fontSize: "0.875rem",
        padding: "8px"

      }
    })(Typography)
  }, [darkMode]);
  const AfterTypography = useMemo(() => {
    return withStyles({
      root: {
        background: darkMode ? "#044B53" : "#e6ffed",
        fontSize: "0.875rem",
        padding: "8px",
        borderLeft: "1px solid darkgray "
      }
    })(Typography)
  }, [darkMode]);

  const renderStrikethrough = useCallback((areSame: boolean, isAfter?: boolean) => {
    return areSame ? { background: (darkMode ? "#2e303c" : "#fff") } : (isAfter ? {} : { textDecoration: "line-through" });
  }, [darkMode]);

  const renderDifferentialChange = useCallback((label: string, after?: Stringish, before?: Stringish) => {
    const areSame = (before == after);

    return (
      <Grid container>
        <Grid item xs={6}>
          {before && (
            <Grid item xs="auto">
              <BeforeTypography className={classes.beforeAfter} style={renderStrikethrough(areSame)}>
                <Typography className={classes.fieldLabel} component="span">{label} :</Typography>{before.toString()}
              </BeforeTypography>
            </Grid>
          )}
        </Grid>
        <Grid item xs={6}>
          {after && (
            <Grid item xs="auto">
              <AfterTypography className={classes.beforeAfter} style={renderStrikethrough(areSame, true)}>
                <Typography className={classes.fieldLabel} component="span">{label} :</Typography>{after.toString()}
              </AfterTypography>
            </Grid>
          )}
        </Grid>
      </Grid>
    );
  }, [BeforeTypography, AfterTypography, classes, renderStrikethrough]);

  const renderDateString = (date?: Date | number): string => {
    if (date as number) {
      date = new Date(date as number * 1000);
    }
    if (date && date as Date) {
      const dateToUse = date as Date;
      return `${dateToUse.toLocaleDateString()} ${dateToUse.toLocaleTimeString()}`;
    }
    return '';
  };

  const liveEventHasChanges = (liveEvent?: LiveEventForComparison): boolean => {
    return liveEvent != null;
    // return !!(liveEvent && (
    //   season.seasonName ||
    //   season.seasonNumber ||
    //   season.seasonStart ||
    //   season.seasonEnd ||
    //   season.seasonDayUTCChange));
  };

  const showLiveEventHeader = useMemo(() => {
    return liveEventHasChanges(beforeChanges) || liveEventHasChanges(afterChanges);
  }, [beforeChanges, afterChanges]);

  return (
    <Box width="100%">
      <Grid container alignItems="center" className={classes.seasonDiffView}>
        {showLiveEventHeader && (
          <>
            <Grid className={classes.sectionTitle} item xs={12}><h4>Live Event</h4></Grid>
            {renderDifferentialChange("Name", current.name, previous?.name)}
            {renderDifferentialChange("Type", current.liveEventType, previous?.liveEventType)}
            {renderDifferentialChange("Debug Start", current.debugStartTime, previous?.debugStartTime)}
            {renderDifferentialChange("Start", current.startTime, previous?.startTime)}
            {renderDifferentialChange("End", current.endTime, previous?.endTime)}
            {renderDifferentialChange("Downtime Buffer (mins)", current.downtimeBufferMinutes.toString(), previous && previous.downtimeBufferMinutes ? previous.downtimeBufferMinutes.toString() : '')}
            {renderDifferentialChange("Store Name", current.storeName || '', previous?.storeName || '')}
            {renderDifferentialChange("Store ID", current.storeId || '', previous?.storeId || '')}
            {renderDifferentialChange("Play Mode", current.playModeName, previous?.playModeName)}
            {renderDifferentialChange("Play Mode Tile Image", current.modeSelectTileImage, previous?.modeSelectTileImage)}
            {renderDifferentialChange("Play Mode BG Image", current.modeSelectBackgroundImage, previous?.modeSelectBackgroundImage)}
            {renderDifferentialChange("Play Mode Select Title", current.modeSelectTitle, previous?.modeSelectTitle)}
            {renderDifferentialChange("Play Mode Select Title", current.modeSelectDescription, previous?.modeSelectDescription)}
            {renderDifferentialChange("Play Mode Select Title", current.modeSelectEventTagTitle, previous?.modeSelectEventTagTitle)}
            {renderDifferentialChange("Play Mode Select Title", current.modeSelectPlayActionTitle, previous?.modeSelectPlayActionTitle)}
          </>
        )}

        <SeasonalDiffView
          beforeChanges={beforeChanges}
          afterChanges={afterChanges}
          previous={previous}
          current={current}
          renderDateString={renderDateString}
          renderDifferentialChange={renderDifferentialChange}
        />

        <JunctionEventInfoDiffView
          beforeChanges={beforeChanges}
          afterChanges={afterChanges}
          previous={previous}
          current={current}
          darkMode={darkMode}
        />
      </Grid>
    </Box>
  );
}
import * as React from 'react';
import type { Player } from '../../features/players/players';
import { PlayersService } from '../../features/players/players';
import { CircularProgress } from '@material-ui/core';
import { useEffect, useState, useCallback } from 'react';

interface OwnProps {
  playerId: string;
  render: (player: Player) => React.ReactNode;
}

export const PlayerResolver = ({ playerId, render }: OwnProps) => {

  const [player, setPlayer] = useState<Player>();

  const getData = useCallback(async () => {
    const playerResult = await PlayersService.getPlayer(playerId);
    setPlayer(playerResult);
  }, [playerId]);

  useEffect(() => {
    getData();
  }, [getData]);

  return (!player ?
    <CircularProgress size={20} /> :
    <>{(render(player))}</>
  )
}
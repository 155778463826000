import type { ApiError } from './api';
import { api } from './api';
import { CurrencyService } from './currency';

export class CurrencyLimit {
  currencyCode = '';
  limitPeriod: number | null = null;
  limitValue: number | null = null;

  constructor(json?: any) {
    if (json) {
      this.currencyCode = json.currencyCode;
      this.limitPeriod = json.limitPeriod;
      this.limitValue = json.limitValue;
    }
  }

  toJson(): any {
    return {
      currencyCode: this.currencyCode,
      limitPeriod: this.limitPeriod,
      limitValue: this.limitValue,
    };
  }
}

export class CurrencyLimitService {
  static async getCurrencyLimits(): Promise<CurrencyLimit[]> {
    await CurrencyService.loadCurrencies();
    return api.get<any[]>({ url: '/currency-limits' })
      .then(json => json.map(v => new CurrencyLimit(v)))
      .catch((e: ApiError) => {
        e.message = `Failed to get currency limits. ${e.message}`;
        throw e;
      });
  }

  static async createCurrencyLimit(item: CurrencyLimit): Promise<CurrencyLimit> {
    return api.post<CurrencyLimit>({ url: '/currency-limits/', body: item.toJson() })
      .then(item => new CurrencyLimit(item))
      .catch((e: ApiError) => {
        e.message = `Failed to create currency limit. ${e.message}`;
        throw e;
      });
  }

  static async updateCurrencyLimit(item: CurrencyLimit): Promise<CurrencyLimit> {
    return api.put<CurrencyLimit>({ url: `/currency-limits/${item.currencyCode}`, body: item.toJson() })
      .then(item => new CurrencyLimit(item))
      .catch((e: ApiError) => {
        e.message = `Failed to update currency limit. ${e.message}`;
        throw e;
      });
  }

  static async deleteCurrencyLimit(item: CurrencyLimit): Promise<null> {
    return api.delete({ url: `/currency-limits/${item.currencyCode}` }).catch((e: ApiError) => {
      e.message = `Failed to clear currency limit. ${e.message}`;
      throw e;
    });
  }
}
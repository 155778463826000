import { Box, Typography } from '@material-ui/core';
import { DiffsPanelsContainer } from '../DiffsPanels';
import type { BlankoDnaDiff, ItemDiffProps } from '../../types';

type BlankoDnasProps = ItemDiffProps<BlankoDnaDiff>

export const BlankoDnas = ({
  sourceItems,
  targetItems,
  actions,
  onSelectionChange
}: BlankoDnasProps) => (<>
  <Typography variant="h6">
    Blanko DNAs and Assets
  </Typography>

  <Box mt={2}>
    {sourceItems && targetItems && (
      <DiffsPanelsContainer
        sourceItems={sourceItems}
        targetItems={targetItems}
        disableRemove
        selectedIds={actions}
        columns={[
          {
            title: '',
            field: 'imageThumbnailUrl',
            // eslint-disable-next-line react/display-name
            render: row => row.imageThumbnailUrl ? (<img src={row.imageThumbnailUrl} width={64} height={64} />) : (<Box width={64} height={64} />),
          },
          { title: 'ID', field: 'diffId', defaultSort: 'asc' },
          {
            title: 'Blanko name',
            field: 'blankoName',
            // eslint-disable-next-line react/display-name
            render: row => row.existsInTarget === true ? (<span>{row.blankoName}</span>) : (<div>{row.blankoName}<br /><i><span style={{ color: '#ffcc00' }}>NOTE: Blanko does not exist in target catalog and will be skipped.</span></i></div>)
          }
        ]}
        onSelectionChange={onSelectionChange}
      />
    )}
  </Box>
</>);

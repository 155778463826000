export type ItemInstanceOwnerType = 'PLAYER' | 'BLANKO' | 'BLOCK';

export class ItemInstance {
  id = '';
  catalogName = '';
  itemId = '';
  itemClass = '';
  purchaseTimestamp = new Date();
  expiration = '';
  annotation = '';
  bundleParentId = '';
  displayName = '';
  unitPrice = 0;
  customData: any = null;
  ownerType: ItemInstanceOwnerType = 'PLAYER';
  ownerId = '';
  dgoodSerial = 0;
  blankoId = '';

  constructor(json: any) {
    this.id = json.id;
    this.catalogName = json.catalogName;
    this.itemId = json.itemId;
    this.itemClass = json.itemClass || '';
    if (json.purchaseTimestamp) {
      this.purchaseTimestamp = new Date(json.purchaseTimestamp * 1000);
    }
    this.expiration = json.expiration || '';
    this.annotation = json.annotation || '';
    this.bundleParentId = json.bundleParentId || '';
    this.displayName = json.displayName || '';
    this.unitPrice = json.unitPrice || 0;
    this.customData = json.customData || {};
    this.ownerType = json.ownerType;
    if (this.ownerType === 'PLAYER') {
      this.ownerId = json.ownerPlayerId;
    } else {
      this.ownerId = json.ownerBlankoId;
    }
    this.dgoodSerial = json.dgoodSerial || 0;
    this.blankoId = json.blankoId;
  }

  clone(): ItemInstance {
    const json: any = {
      id: this.id,
      catalogName: this.catalogName,
      itemId: this.itemId,
      itemClass: this.itemClass,
      purchaseTimestamp: this.purchaseTimestamp.getTime() / 1000,
      expiration: this.expiration,
      annotation: this.annotation,
      bundleParentId: this.bundleParentId,
      displayName: this.displayName,
      unitPrice: this.unitPrice,
      customData: this.customData,
      ownerType: this.ownerType,
      dgoodSerial: this.dgoodSerial,
      blankoId: this.blankoId
    };

    if (this.ownerType === 'PLAYER') {
      json.ownerPlayerId = this.ownerId;
    } else {
      json.ownerBlankoId = this.ownerId;
    }

    return new ItemInstance(json);
  }
}

export class ItemGrantResponse {
  currencyChange: { [key: string]: number; } = {};
  itemInstances: ItemInstance[] = [];

  constructor(json: any) {
    this.currencyChange = json.currencyChange;
    this.itemInstances = json.itemInstances.map((v: any) => new ItemInstance(v));
  }
}
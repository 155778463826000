import { api } from '../../services/api';
import type { ShopTemplate, ServerTemplateResponse } from './shopTemplate';

export const getShopTemplates = async () => {
  const response = await api.get<ServerTemplateResponse[]>({ url: '/shops/templates' });
  const templates : ShopTemplate[] = [];
  response.forEach((template) => {
    templates.push(templateFromServerResponse(template));
  });
  return templates;
}

export const createShopTemplate = async (template: ShopTemplate) => {
  const toSend = {
    name: template.name,
    template: JSON.stringify(template.templateItem)
  }
  const response = await api.post<ServerTemplateResponse>({ url: '/shops/templates', body: toSend });
  return templateFromServerResponse(response);
}

export const updateShopTemplate = async (template: ShopTemplate) => {
  const toSend = {
    id: template.id,
    name: template.name,
    template: JSON.stringify(template.templateItem)
  }
  const response = await api.put<ServerTemplateResponse>({ url: `/shops/templates/${template.id}`, body: toSend });
  return templateFromServerResponse(response);
}

export const deleteShopTemplate = async (id: string) => {
  return api.delete({ url: `/shops/templates/${id}` });
}

const templateFromServerResponse = (template:ServerTemplateResponse) : ShopTemplate => {
  return {
    id: template.id,
    name: template.name,
    templateItem: JSON.parse(template.template)
  };
};
import { useCallback, useEffect, useMemo, useState } from "react";
import { getAllRoleNames, getUserRoles, updateUserRoles } from "./rolesPermissionsApi";
import type { ApiError } from "../../services/api";
import { pushAppNotification } from "../../shared/hooks/useAppNotification";
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CircularProgress,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  MenuItem
} from "@material-ui/core";
import { Delete } from "@material-ui/icons";
import { UserService } from "../../services/user";
import { SelectWithLabel } from "../../components/SelectWithLabel";
import _ from "lodash";

export interface UserRolesEditorProps {
  mythicalId?: string;
}

export const UserRolesEditor = ({mythicalId}: UserRolesEditorProps) => {
  const [allRoleNames, setAllRoleNames] = useState<string[]>([]);
  const [roleToAdd, setRoleToAdd] = useState('');
  const [roles, setRoles] = useState<string[] | undefined>();
  const [originalRoles, setOriginalRoles] = useState<string[] | undefined>();
  const dirtied = useMemo(() => !_.isEqual(roles, originalRoles), [originalRoles, roles]);
  const setFetchedRoles = useCallback((roles: string[]) => {
    setRoles(roles);
    setOriginalRoles(roles);
  }, []);

  useEffect(() => {
    getAllRoleNames()
      .then(roleNames => setAllRoleNames(roleNames.sort()))
      .catch((e: ApiError) => pushAppNotification({
        type: 'error',
        message: `Failed to get all role names. ${e.message}`
      }));
  }, []);
  useEffect(() => {
    if (mythicalId) {
      getUserRoles(mythicalId)
        .then(userRoles => setFetchedRoles(userRoles.roles.sort()))
        .catch((e: ApiError) => pushAppNotification({
          type: 'error',
          message: `Failed to get user roles. ${e.message}`
        }));
    }
  }, [setFetchedRoles, mythicalId]);
  const updateRoles = useCallback((newRoles: string[]) => {
    if (mythicalId) {
      updateUserRoles({mythicalId, roles: newRoles})
        .then(userRoles => {
          setFetchedRoles(userRoles.roles.sort());
          pushAppNotification({
            type: 'success',
            message: 'Updated user roles'
          });
        })
        .catch((e: ApiError) => pushAppNotification({
          type: 'error',
          message: `Failed to update user roles. ${e.message}`
        }));
    }
  }, [setFetchedRoles, mythicalId]);

  const addRole = useCallback(() => {
    if (roles) {
      const newRoles = roles.concat([roleToAdd]);
      setRoles(newRoles);
      setRoleToAdd('');
    }
  }, [roleToAdd, roles]);
  const removeRole = useCallback((index) => {
    if (roles) {
      const newRoles = [...roles];
      newRoles.splice(index, 1);
      setRoles(newRoles);
    }
  }, [roles]);
  const onSave = useCallback((event: any) => {
    event.preventDefault();
    if (roles) {
      updateRoles(roles);
    }
  }, [roles, updateRoles]);
  const onReset = useCallback(() => {
    setRoles(originalRoles);
  }, [originalRoles]);

  if (!roles) {
    return <Box textAlign="center">
      <CircularProgress/>
    </Box>;
  }
  return <Card>
    <CardContent>
      <Box>
        <List>
          {roles.map((role, index) => (
            <ListItem key={index}>
              {UserService.canUpdate('roles') &&
                <ListItemIcon><Button onClick={() => removeRole(index)}><Delete/></Button></ListItemIcon>
              }
              <ListItemText primary={role}/>
            </ListItem>
          ))}
        </List>
        {UserService.canUpdate('roles') && <>
          <SelectWithLabel
            label="Role to add"
            value={roleToAdd}
            onChange={e => setRoleToAdd(e.target.value as string)}
          >
            {allRoleNames
              .filter(roleName => !roles.includes(roleName))
              .map(roleName => (<MenuItem key={roleName} value={roleName}>{roleName}</MenuItem>))}
          </SelectWithLabel>
          <Button disabled={!roleToAdd} onClick={addRole}>Add Role</Button>
        </>
        }
      </Box>
    </CardContent>
    <CardActions>
      <Button
        type="submit"
        variant="contained"
        color="primary"
        disabled={!dirtied}
        onClick={onSave}
      >
        Save
      </Button>
      <Button
        onClick={onReset}
        disabled={!dirtied}
        color="primary"
      >
        Reset
      </Button>
    </CardActions>
  </Card>;
};

import { DropTableDiff } from '../../../../pages/CatalogMigration/types';
import { CatalogMigrationService } from '../../../../services/catalog-migration';
import type { MigrationModule } from '../../migrations';
import { buildDiffData } from '../../migrations';
import { itemMigration } from '../item/item-migration';
import { DropTablesDiffs } from './DropTablesDiffs';
import { MigrationType } from "../migration-type";

export const dropTableMigration: MigrationModule<DropTableDiff> = {
  id: MigrationType.droptable,
  displayName: 'Catalog drop tables',
  diffComponent: DropTablesDiffs,
  crossEnvOnly: false,

  loadData: async (sourceData, targetData, migrationData) => {
    if (!sourceData.catalog) {
      sourceData.catalog = await CatalogMigrationService.getCatalog(sourceData.env, sourceData.catalogName);
    }
    if (!targetData.catalog) {
      targetData.catalog = await CatalogMigrationService.getCatalog(targetData.env, targetData.catalogName);
    }

    if (sourceData.catalog.dropTables && targetData.catalog.dropTables) {
      migrationData.dropTables = buildDiffData(
        sourceData.catalog.dropTables.map(dropTable => new DropTableDiff(dropTable)),
        targetData.catalog.dropTables.map(dropTable => new DropTableDiff(dropTable))
      );

      return migrationData.dropTables;
    }

    return null;
  },

  runMigration: async (props, setStatus) => {
    // If somehow drop tables need to migrate without catalog items...
    if (!props.migrationData.options.item) {
      await itemMigration.runMigration(props, setStatus);
    }
  }
}

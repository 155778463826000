import { Box, Typography } from '@material-ui/core';
import type { BlankoProgressionsDiff, ItemDiffProps } from '../../types';
import { DiffsPanelsContainer } from '../DiffsPanels';

type BlankoProgressionsProps = ItemDiffProps<BlankoProgressionsDiff>

export const BlankoProgressions = ({
  sourceItems,
  targetItems,
  actions,
  onSelectionChange
}: BlankoProgressionsProps) => (<>
  <Typography variant="h6">
    BlankoProgressions
  </Typography>

  <Box mt={2}>
    {sourceItems && targetItems && (
      <DiffsPanelsContainer
        sourceItems={sourceItems}
        targetItems={targetItems}
        selectedIds={actions}
        columns={[
          { title: 'Name', field: 'name', defaultSort: 'asc' }
        ]}
        onSelectionChange={onSelectionChange}
      />
    )}
  </Box>
</>)
import { Box, Button, CircularProgress, Grid, Link } from '@material-ui/core';
import { green, orange } from '@material-ui/core/colors';
import AssessmentIcon from '@material-ui/icons/Assessment';
import AssessmentOutlinedIcon from '@material-ui/icons/AssessmentOutlined';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import PersonIcon from '@material-ui/icons/Person';
import PublishIcon from '@material-ui/icons/Publish';
import { formatDistanceStrict } from 'date-fns';
import type { Column } from 'material-table';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import type { MenuAction } from '../../../components/MaterialTable';
import MaterialTable from '../../../components/MaterialTable';
import { openConfirmationDialog, setAppNotification } from '../../../redux/app/actions';
import type { ApiError } from '../../../services/api';
import { UserService } from '../../../services/user';
import { RouterLink } from '../../../types';
import type { Shop, ShopVersion } from '../shop';
import { announceShop, deleteShopVersion, getShopVersions, grantPlayerAccess, updateShop } from '../shopsApi';
import { ImportStoreDialog } from './ImportStoreDialog';
import { ShopVersionDialog } from './ShopVersionDialog';
import { PlayerAccessDialog } from "../../../shared/components/PlayerAccessDialog";
import { usePushNotification } from "../../../contexts/AppNotificationContext";

export interface ShopVersionsProps {
  shop: Shop;
}

export const ShopVersions = (props: ShopVersionsProps) => {
  const dispatch = useDispatch();
  const pushNotification = usePushNotification();

  const [shop, setShop] = useState(props.shop);
  const [shopVersions, setShopVersions] = useState<ShopVersion[] | null>(null);
  const [liveVersion, setLiveVersion] = useState<ShopVersion | null>(null);
  const [versionDialogOpen, setVersionDialogOpen] = useState(false);
  const [editingVersion, setEditingVersion] = useState<ShopVersion | null>(null);
  const [cloningVersion, setCloningVersion] = useState(false);
  const [importDialogOpen, setImportDialogOpen] = useState(false);
  const [grantAccessVersion, setGrantAccessVersion] = useState<ShopVersion | null>(null);

  useEffect(() => {
    getShopVersions(shop.id).then(setShopVersions)
      .catch((e: ApiError) => dispatch(setAppNotification({ type: 'error', message: `Failed to load shop. ${e.message}` })));
  }, [dispatch, shop]);

  useEffect(() => {
    if (shopVersions) {
      const now = new Date().getTime();
      setLiveVersion(shopVersions.filter(v => v.startDate !== null)
        .sort((a, b) => (new Date(b.startDate || 0).getTime()) - (new Date(a.startDate || 0).getTime()))
        .find(v => v.startDate && new Date(v.startDate).getTime() < now) || null);
    }
  }, [shopVersions]);

  const shopVersionsColumns = useMemo<Column<ShopVersion>[]>(() => ([
    {
      title: 'Name',
      field: 'name',
      render(shopVersion) {
        return <Link component={RouterLink} to={`/shops/${shopVersion.shopId}/timeline?version=${shopVersion.id}`}>{shopVersion.name}</Link>;
      }
    },
    {
      title: 'Start Date',
      field: 'startDate',
      type: 'datetime',
      defaultSort: 'desc'
    },
    {
      title: 'Preload Date (Time)',
      field: 'preloadStartDate',
      render(shopVersion) {
        if (shopVersion.startDate && shopVersion.preloadStartDate) {
          const startDate = new Date(shopVersion.startDate);
          const preloadStartDate = new Date(shopVersion.preloadStartDate);
          return (<>
            {preloadStartDate.toLocaleString()}
            {startDate.getTime() > preloadStartDate.getTime() && (
              <> ({formatDistanceStrict(startDate, preloadStartDate)})</>
            )}
          </>);
        }
        return null;
      }
    },
    {
      title: 'Catalog',
      field: 'catalogName',
      sorting: false,
      render(shopVersion) {
        return <Link component={RouterLink} to={`/catalogs/${shopVersion.catalogName}`}>{shopVersion.catalogName}</Link>;
      }
    },
    {
      title: 'Live',
      sorting: false,
      render(shopVersion) {
        if (shopVersion === liveVersion) {
          return <CheckCircleIcon style={{ color: green[500] }} />;
        } else {
          return null;
        }
      }
    },
    {
      title: 'Canary',
      sorting: false,
      render(shopVersion) {
        if (shop.canaryVersionId && shop.canaryVersionId === shopVersion.id) {
          return <AssessmentIcon style={{ color: orange[500] }} />;
        } else {
          return null;
        }
      }
    }
  ]), [shop, liveVersion]);

  const onAddVersion = useCallback(() => {
    setEditingVersion(null);
    setCloningVersion(false);
    setVersionDialogOpen(true);
  }, []);

  const onEditVersion = useCallback((shopVersion: ShopVersion) => {
    setEditingVersion(shopVersion);
    setCloningVersion(false);
    setVersionDialogOpen(true);
  }, []);

  const onSetCanary = useCallback((shopVersion: ShopVersion) => {
    updateShop({ ...shop, canaryVersionId: shopVersion.id }).then(updatedShop => {
      setShop(updatedShop);
      dispatch(setAppNotification({ type: 'success', message: 'Canary updated' }));
    }).catch((e: ApiError) => dispatch(setAppNotification({ type: 'error', message: `Error setting canary. ${e.message}` })));
  }, [dispatch, shop]);

  const onRemoveCanary = useCallback(() => {
    updateShop({ ...shop, canaryVersionId: undefined }).then(updatedShop => {
      setShop(updatedShop);
      dispatch(setAppNotification({ type: 'success', message: 'Canary removed' }));
    }).catch((e: ApiError) => dispatch(setAppNotification({ type: 'error', message: `Error removing canary. ${e.message}` })));
  }, [dispatch, shop]);

  const onCloneVersion = useCallback((shopVersion: ShopVersion) => {
    setEditingVersion(shopVersion);
    setCloningVersion(true);
    setVersionDialogOpen(true);
  }, []);

  const onAnnounceVersion = useCallback((shopVersion: ShopVersion) => {
    dispatch(openConfirmationDialog({ title: `Announce ${shop.name} in ${shopVersion.catalogName}?`, action: 'Announce' }, () => {
      announceShop(shop.name, shopVersion.catalogName).then(() => {
        dispatch(setAppNotification({ type: 'success', message: 'Shop announced' }));
      }).catch((e: ApiError) => dispatch(setAppNotification({ type: 'error', message: `Error announcing shop. ${e.message}`})));
    }));
  }, [dispatch, shop]);

  const onDeleteShopVersion = useCallback((shopVersion: ShopVersion) => {
    dispatch(openConfirmationDialog({ title: `Delete ${shopVersion.name}?`, action: 'Delete' }, () => {
      deleteShopVersion(shopVersion.id).then(() => {
        if (shopVersions) {
          setShopVersions(shopVersions.filter(v => v.id !== shopVersion.id));
        }
        dispatch(setAppNotification({ type: 'success', message: 'Shop version removed' }));
      }).catch((e: ApiError) => dispatch(setAppNotification({ type: 'error', message: `Error removing shop version. ${e.message}` })));
    }));
  }, [dispatch, shopVersions]);

  const onShopVersionUpdate = useCallback((shopVersion: ShopVersion) => {
    if (shopVersions) {
      const index = shopVersions.findIndex(v => v.id === shopVersion.id);
      const updatedShopVersions = shopVersions.slice();
      if (index > -1) {
        updatedShopVersions[index] = shopVersion;
      } else {
        updatedShopVersions.push(shopVersion);
      }
      setShopVersions(updatedShopVersions);
    }
  }, [shopVersions]);

  const getMenuActions = useCallback((shopVersion: ShopVersion) => {
    const actions: MenuAction<ShopVersion>[] = [];
    const canCreate = UserService.canCreate('store');
    const canUpdate = UserService.canCreate('store');

    if (canUpdate) {
      actions.push({ type: 'button', icon: EditIcon, label: 'Edit', onClick: onEditVersion });
    }
    if (canCreate) {
      actions.push({ type: 'button', icon: FileCopyIcon, label: 'Clone', onClick: onCloneVersion });
    }

    if (canUpdate) {
      actions.push({ type: 'divider' });
      if (shop.canaryVersionId !== shopVersion.id) {
        actions.push({ type: 'button', icon: AssessmentIcon, label: 'Set Canary', onClick: onSetCanary });
      } else {
        actions.push({ type: 'button', icon: AssessmentOutlinedIcon, label: 'Remove Canary', onClick: onRemoveCanary });
      }

      if (canCreate) {
        actions.push({ type: 'button', icon: PersonIcon, label: 'Grant access', onClick: setGrantAccessVersion });
      }

      actions.push({ type: 'divider' });
      actions.push({ type: 'button', icon: PublishIcon, label: 'Announce', onClick: onAnnounceVersion });
    }

    if (UserService.canDelete('store')) {
      actions.push({ type: 'divider' });
      actions.push({ type: 'button', icon: DeleteIcon, label: 'Delete', onClick: onDeleteShopVersion });
    }
    return actions;
  }, [onEditVersion, onSetCanary, onRemoveCanary, onCloneVersion, onAnnounceVersion, onDeleteShopVersion, shop]);

  if (!shopVersions) {
    return (
      <Box textAlign="center">
        <CircularProgress />
      </Box>
    );
  }

  return (<>
    <Box mb={2}>
      <Grid container alignItems="center" spacing={1}>
        <Grid item xs></Grid>
        <Grid item xs="auto">
          <Button variant="contained" color="primary" onClick={onAddVersion}>
            Add Version
          </Button>
        </Grid>
        <Grid item xs="auto">
          <Button variant="contained" color="primary" onClick={() => setImportDialogOpen(true)}>
            Import Store
          </Button>
        </Grid>
      </Grid>
    </Box>

    <MaterialTable<ShopVersion>
      title=""
      data={shopVersions}
      columns={shopVersionsColumns}
      menuActions={getMenuActions}
    />

    {versionDialogOpen && (
      <ShopVersionDialog
        shopId={shop.id}
        shopVersion={editingVersion}
        onClose={() => setVersionDialogOpen(false)}
        onShopVersionUpdate={onShopVersionUpdate}
        isCloning={cloningVersion}
      />
    )}

    {importDialogOpen && (
      <ImportStoreDialog
        shopId={shop.id}
        onClose={() => setImportDialogOpen(false)}
        onImport={onShopVersionUpdate}
      />
    )}

    {grantAccessVersion !== null && (
      <PlayerAccessDialog
        title={`Add player access to: ${grantAccessVersion.name}`}
        subtitle='Note: Players can only have access to one shop version at a time.'
        onSubmit={(emailList) => {
          grantPlayerAccess(grantAccessVersion.id, emailList).then(() => {
            pushNotification({type: 'success', message: 'Access granted'});
          }).catch((e: ApiError) => pushNotification({type: 'error', message: `Error granting access. ${e.message}`}));
        }}
        onClose={() => setGrantAccessVersion(null)}
      />
    )}
  </>)
}

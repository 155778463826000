import { Box, Button, Grid, makeStyles, Theme, Tooltip } from '@material-ui/core';
import type { ShopTab } from '../shop';

const useStyles = makeStyles((theme: Theme) => ({
  itemImage: {
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center'
  },
}));

export interface ShopTabsEditorImagesProps {
  shopTab: ShopTab;
  handleClick: (shopTab: ShopTab) => void;
}

export const ShopTabsEditorImages = (props: ShopTabsEditorImagesProps) => {
  const classes = useStyles();
  const { shopTab, handleClick } = props;
  return (
    <>
      <Box>
        {shopTab.linkImages?.map((linkImage, index) => (
          <Tooltip key={index} title={linkImage.titleText || ''}>
            <Button onClick={() => handleClick(shopTab)}>
              <img src={linkImage.imageUrl} width={64} height={64} alt={linkImage.titleText || ''} />
            </Button>
          </Tooltip>
        ))}
      </Box>
    </>
  );
}

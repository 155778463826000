import { Grid, TextField } from '@material-ui/core';
import type { Season } from '../../services/player-challenges/seasons';
import { UserService } from '../../services/user';
import {
  getDateFromEpochSeconds,
  getDateFromNullableTime,
  getEpochSecondsFromDate,
  getTimeFromNullableDate
} from "../../utils/dates";
import { AdminKeyboardDateTimePicker } from "../../components/AdminKeyboardDateTimePicker";
import { AdminKeyboardTimePicker } from "../../components/AdminKeyboardTimePicker";

type Props = {
  season: Season,
  setSeason: (season: Season) => void,
  isAdding?: boolean,
};

const SECONDS_PER_DAY = 60 * 60 * 24;

export const SeasonControl = ({season, setSeason, isAdding}: Props) => {

  const calculateSeasonEnd = (days: number, seasonStart: number | undefined): number | undefined => {
    if (seasonStart) {
      const daySeconds = days * SECONDS_PER_DAY;
      return seasonStart + daySeconds;
    }
  };

  const updateSeason = (mutation: (season: Season) => void) => {
    const seasonClone: Season = season.clone();
    mutation(seasonClone);
    setSeason(seasonClone);
  };

  const readOnly = !UserService.canUpdate('seasons');

  const season_number = season.season_number === undefined || isNaN(season.season_number) ? '' : season.season_number;

  return (<>
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <TextField
          label="Season Id"
          fullWidth
          InputProps={{readOnly: !isAdding}}
          type="number"
          onChange={(e) => {
            const value = parseInt(e.target.value);
            updateSeason(season => {
              if (!isNaN(value)) {
                season.season_number = value;
              } else if (e.target.value === '') {
                season.season_number = undefined;
              }
            });
          }}
          value={season_number}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          label="Season Name"
          fullWidth
          InputProps={{readOnly: readOnly}}
          onChange={(e) => {
            updateSeason(season => {
              season.season_name = e.target.value;
            });
          }}
          value={season.season_name}
        />
      </Grid>
    </Grid>
  </>);
};

import { Box, Button, Divider, Grid, Tab, Tabs, Typography } from '@material-ui/core';
import ErrorIcon from '@material-ui/icons/Error';
import { useCallback, useMemo, useState } from 'react';
import type { DiffItem } from '../../../pages/CatalogMigration/types';
import { openAdminConfirmationDialog } from '../../../shared/hooks/useAdminConfirmationDialog';
import type { MigrationCommonProps, MigrationDependencyErrors, MigrationDependencyItem, MigrationDependencyItemErrors, MigrationModule } from '../migrations';
import { MigrationType } from "../modules/migration-type";

export const MigrationDiffsStep = (props: MigrationCommonProps) => {
  const { onSelectionsChange, onNextStep } = props;

  const activeTabs = useMemo(() => {
    const userSelectedModules: MigrationModule<DiffItem>[] = [];
    const dependencyModules: MigrationModule<DiffItem>[] = [];
    for (const id of props.runOrder.slice().reverse()) {
      if (props.migrationData.options[id]) {
        const module = props.modules[id];
        if (module) {
          (props.migrationData.userOptions[id] ? userSelectedModules : dependencyModules).push(module);
        }
      }
    }

    return userSelectedModules.concat(dependencyModules);
  }, [props.migrationData.userOptions, props.migrationData.options, props.modules, props.runOrder]);

  const [selectedTab, setSelectedTab] = useState(activeTabs.length > 0 ? activeTabs[0].id : '');

  const selectedTabIndex = useMemo(() => activeTabs.findIndex(v => v.id === selectedTab), [activeTabs, selectedTab]);

  const isAnyItemSelected = useMemo(() => {
    for (const key in props.selections) {
      if (Object.values(props.selections[key as MigrationType] as {[key: string]: boolean}).indexOf(true) > -1) {
        return true;
      }
    }

    return false;
  }, [props.selections]);

  const depErrors: { [key: string]: MigrationDependencyErrors } = useMemo(() => {
    const errors: { [key: string]: MigrationDependencyErrors } = {};

    for (const tab of activeTabs) {
      const selections = props.selections[tab.id] || {};
      const depErrors: MigrationDependencyErrors = {};

      for (const itemId in selections) {
        if (selections[itemId]) {
          const dependencies = props.migrationData.depTree.getDependencies({ type: tab.id, id: itemId });
          const itemDepErrors: MigrationDependencyItemErrors = { missingDeps: [] };
          for (const dep of dependencies) {
            if (!Boolean((props.selections[dep.type] || {})[dep.id])) {
              itemDepErrors.missingDeps.push(dep);
            }
          }

          if (itemDepErrors.missingDeps.length > 0) {
            depErrors[itemId] = itemDepErrors;
          }
        }
      }

      if (Object.keys(depErrors).length > 0) {
        errors[tab.id] = depErrors;
      }
    }

    return errors;
  }, [props.migrationData.depTree, props.selections, activeTabs]);

  const onDeselectAll = useCallback(() => {
    openAdminConfirmationDialog({
      title: 'Are you sure?',
      action: 'Deselect all',
      onConfirm: () => onSelectionsChange({})
    })
  }, [onSelectionsChange]);

  const onFixDependencies = useCallback((items: DiffItem[]) => {
    const dependencies: MigrationDependencyItem[] = [];
    for (const item of items) {
      for (const dep of props.migrationData.depTree.getDependencies(item.dependencyItem, true)) {
        dependencies.push(dep);
      }
    }

    if (dependencies.length < 1) {
      return;
    }

    const newSelections = { ...props.selections };
    for (const key of Object.keys(newSelections) as MigrationType[]) {
      newSelections[key] = { ...newSelections[key] };
    }

    for (const dep of dependencies) {
      let itemSelections = newSelections[dep.type];
      if (!itemSelections) {
        itemSelections = {};
        newSelections[dep.type] = itemSelections;
      }

      itemSelections[dep.id] = true;
    }

    onSelectionsChange(newSelections);
  }, [onSelectionsChange, props.selections, props.migrationData.depTree]);

  const onFinish = useCallback(() => {
    if (Object.keys(depErrors).length > 0) {
      openAdminConfirmationDialog({
        title: 'Are you sure?',
        details: 'There are still some dependencies that were not selected. This could cause issues.',
        action: 'Finish selecting',
        onConfirm: onNextStep
      })
    } else {
      onNextStep();
    }
  }, [onNextStep, depErrors]);

  if (activeTabs.length < 1) {
    return (<>
      <Box mt={2}>
        <Typography color="textSecondary">
          There is nothing to process.
        </Typography>
      </Box>
      <Box mt={2}>
        <Button color="primary" variant="contained" onClick={() => props.onSetStep('options')}>
          Start another
        </Button>
      </Box>
    </>);
  }

  const selectedTabConfig = activeTabs.find(v => v.id === selectedTab);

  return (<>
    <Box my={2}>
      <Button color="primary" variant="contained" onClick={onDeselectAll} disabled={!isAnyItemSelected}>
        Deselect all
      </Button>
    </Box>

    <Tabs
      value={selectedTab}
      onChange={(e, tab) => setSelectedTab(tab)}
      variant="scrollable"
    >
      {activeTabs.map(tab => (
        <Tab key={tab.id} value={tab.id} label={<DiffTabLabel title={tab.displayName} error={Boolean(depErrors[tab.id])} />} />
      ))}
    </Tabs>
    <Divider />
    <Box mt={2}>
      {selectedTabConfig && (
        <selectedTabConfig.diffComponent
          depErrors={depErrors[selectedTabConfig.id] || {}}
          onFixDependencies={onFixDependencies}
          {...props}
        />
      )}
    </Box>
    <Box mt={4}>
      <Grid container spacing={1}>
        <Grid item xs="auto">
          <Button variant="outlined" onClick={() => setSelectedTab(activeTabs[selectedTabIndex - 1].id)} disabled={selectedTabIndex < 1}>
            Prev
          </Button>
        </Grid>
        {selectedTabIndex < activeTabs.length - 1 && (
          <Grid item xs>
            <Button
              variant="contained"
              color="primary"
              onClick={() => setSelectedTab(activeTabs[selectedTabIndex + 1].id)}
              disabled={selectedTabIndex >= activeTabs.length - 1}
            >
              Next
            </Button>
          </Grid>
        )}
        <Grid item xs="auto">
          <Button color="primary" variant="contained" disabled={!isAnyItemSelected} onClick={onFinish}>
            Finish selecting
          </Button>
        </Grid>
      </Grid>
    </Box>
  </>)
}

const DiffTabLabel = ({ title, error }: { title: string, error: boolean }) => {
  return (
    <Box whiteSpace="nowrap">
      {title}
      {error && <ErrorIcon fontSize="small" color="error" style={{ verticalAlign: 'middle', marginLeft: 5 }} />}
    </Box>
  );
}

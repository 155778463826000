import { useCallback, useEffect, useMemo, useState } from "react";
import {
  addPlayerBranchOverrides,
  getBranches,
  getBranchOverrides,
  removeCanaryBranch,
  removeLiveBranch,
  setCanaryBranch,
  setLiveBranch, syncBranch
} from "../lokalise-api";
import type { Branch, BranchInfo, Branches } from "../lokalise-types";
import { usePushNotification } from "../../../contexts/AppNotificationContext";
import MaterialTable, { MenuAction } from "../../../components/MaterialTable";
import { Column } from "material-table";
import { Box, CircularProgress, Dialog, DialogContent, DialogTitle, Tooltip } from "@material-ui/core";
import { Assessment, CheckCircle, Person, Sync } from "@material-ui/icons";
import { blue, green, orange } from "@material-ui/core/colors";
import { PlayerAccessDialog } from "../../../shared/components/PlayerAccessDialog";
import ConfirmationDialog from "../../../components/ConfirmationDialog";
import { format } from "date-fns";
import { UserService } from "../../../services/user";

export const LocalizationBranches = () => {
  const [branches, setBranches] = useState<Branch[] | undefined>();
  const [branchOverrides, setBranchOverrides] = useState<Branches | undefined>();
  const [branchForAccessGrant, setBranchForAccessGrant] = useState<string | undefined>();
  const [branchToSync, setBranchToSync] = useState<string | undefined>();
  const [branchIsSyncing, setBranchIsSyncing] = useState<boolean>(false);
  const pushNotification = usePushNotification();
  const getOverrides = useCallback(() => {
    getBranchOverrides()
      .then(branchOverrides => setBranchOverrides(branchOverrides))
      .catch(err => pushNotification({type: 'error', message: `Failed to get branch overrides: ${err.message}`}));
  }, [pushNotification]);
  useEffect(() => {
    getBranches()
      .then(branchList => setBranches(branchList.branches))
      .catch(err => pushNotification({type: 'error', message: `Failed to get branches: ${err.message}`}));
    getOverrides();
  }, [getOverrides, pushNotification]);
  const liveBranch = useMemo(() => {
    return branchOverrides?.branches.find(boi => boi.live)?.branchName
      || branchOverrides?.defaultLiveBranch;
  }, [branchOverrides]);
  const canaryBranch = useMemo(() => {
    return branchOverrides?.branches.find(boi => boi.canary)?.branchName;
  }, [branchOverrides]);
  const branchesByName = useMemo(() => {
    return branchOverrides?.branches.reduce((byName, current) => {
      byName[current.branchName] = current;
      return byName;
    }, {} as { [key: string]: BranchInfo });
  }, [branchOverrides]);
  const onSyncBranch = useCallback(() => {
    if (branchToSync) {
      setBranchIsSyncing(true);
      syncBranch(branchToSync)
        .then((response) => {
          if(response?.warnings?.length > 0) {
            pushNotification({type: 'error', message: `Translation keys were skipped that lack a file association: ${response?.warnings.join(",")}`})
          }
          setBranchIsSyncing(false);
        })
        .then(() => getOverrides())
        .catch(err =>{ 
          setBranchIsSyncing(false);
          pushNotification({type: 'error', message: `Failed to sync branch: ${err.message}`})
        });
      setBranchToSync(undefined);
    }
  }, [branchToSync, getOverrides, pushNotification]);

  const columns: Column<Branch>[] = useMemo(() => [
    {
      render(branch) {
        return <>
          {branch.name === liveBranch && (
            <Tooltip title="Live">
              <CheckCircle style={{color: green[500]}}/>
            </Tooltip>
          )}
          {branch.name === canaryBranch && (
            <Tooltip title="Canary">
              <Assessment style={{color: orange[500]}}/>
            </Tooltip>
          )}
          {!!branchesByName?.[branch.name]?.playerEmails.length && (
            <Tooltip title="Player overrides">
              <Person style={{color: blue[500]}}/>
            </Tooltip>
          )}
        </>;
      },
    },
    {
      field: 'name',
      title: 'Name',
    },
    {
      title: 'Last sync',
      render(branch) {
        if (branchesByName?.[branch.name]?.lastSync) {
          return <>{format(new Date(branchesByName[branch.name].lastSync),'yyyy/MM/dd HH:mm:ss')}</>;
        }
        return <i>Never</i>;
      },
    },
    {
      field: 'created_at',
      render(branch) {
        return <>{format(Date.parse(branch.created_at),'yyyy/MM/dd HH:mm:ss')}</>
      },
      title: 'Created At',
      type: 'datetime',
      defaultSort: 'desc',
    },
    {
      field: 'created_by_email',
      title: 'Created By',
    },
  ], [branchesByName, canaryBranch, liveBranch]);

  if (!branches || !branchOverrides) {
    return <Box textAlign="center">
      <CircularProgress/>
    </Box>;
  }

  return <>
    <MaterialTable<Branch>
      title="Branches"
      columns={columns}
      menuActions={(branch) => {
        if (!UserService.canUpdate('lokalise')) {
          return [];
        }
        const menuActions: MenuAction<Branch>[] = [];
        if (branchesByName?.[branch.name]?.lastSync) {
          if (branch.name !== branchOverrides.defaultLiveBranch || branch.name !== liveBranch) {
            menuActions.push({
              type: 'button',
              label: branch.name === liveBranch ? `Remove Live (revert to default ${branchOverrides.defaultLiveBranch})` : 'Make Live',
              onClick: () => {
                if (branch.name === liveBranch || branch.name === branchOverrides.defaultLiveBranch) {
                  removeLiveBranch()
                    .then(() => getOverrides())
                    .catch(err => pushNotification({
                      type: 'error',
                      message: `Failed to remove live branch: ${err.message}`
                    }));
                } else {
                  setLiveBranch(branch.name)
                    .then(() => getOverrides())
                    .catch(err => pushNotification({
                      type: 'error',
                      message: `Failed to set live branch: ${err.message}`
                    }));
                }
              },
              icon: CheckCircle,
            });
          }
          menuActions.push({
            type: 'button',
            label: `${branch.name === canaryBranch ? 'Remove' : 'Make'} Canary`,
            onClick: () => {
              if (branch.name === canaryBranch) {
                removeCanaryBranch()
                  .then(() => getOverrides())
                  .catch(err => pushNotification({
                    type: 'error',
                    message: `Failed to remove canary branch: ${err.message}`
                  }));
              } else {
                setCanaryBranch(branch.name)
                  .then(() => getOverrides())
                  .catch(err => pushNotification({
                    type: 'error',
                    message: `Failed to set canary branch: ${err.message}`
                  }));
              }
            },
            icon: Assessment,
          });
          menuActions.push({
            type: 'button',
            label: 'Grant Access',
            icon: Person,
            onClick: () => setBranchForAccessGrant(branch.name),
          });
          menuActions.push({
            type: 'divider',
          });
        }
        menuActions.push({
          type: 'button',
          label: 'Synchronize',
          icon: Sync,
          onClick: () => setBranchToSync(branch.name),
        });
        return menuActions;
      }}
      data={branches}
    />

    {branchForAccessGrant &&
      <PlayerAccessDialog
        title={`Grant access to ${branchForAccessGrant}`}
        onClose={() => {
          setBranchForAccessGrant(undefined);
        }}
        onSubmit={(emailList) => {
          addPlayerBranchOverrides(branchForAccessGrant, emailList)
            .then(() => getOverrides())
            .catch(err => pushNotification({
              type: 'error',
              message: `Failed to add player branch overrides: ${err.message}`
            }));
        }}
      />}

    {branchToSync &&
      <ConfirmationDialog
        open={!!branchToSync}
        title={`Synchronize ${branchToSync}?`}
        details={'This action cannot be undone'}
        onConfirm={onSyncBranch}
        onClose={() => setBranchToSync(undefined)}
      />}
    {branchIsSyncing && 
      <Dialog
        open={branchIsSyncing}
        fullWidth
      >
        <DialogTitle>Updating translation files...</DialogTitle>
        <DialogContent>
          <Box textAlign={"center"}>
            <CircularProgress size={30}/>
          </Box>
        </DialogContent>
      </Dialog>
    }
  </>;
};

import { MigrationDiffCommonProps } from "../../migrations";
import { DiffsPanels } from "../../components/diff/DiffsPanels";
import { ItemNoteDiff } from "../../../../pages/CatalogMigration/types";

export const ItemNotesDiffs = (props: MigrationDiffCommonProps) => {

  const diffs = props.migrationData.itemNotes;

  if (diffs) {
    return (
      <DiffsPanels<ItemNoteDiff>
        diffs={diffs}
        columns={[
          { title: 'Item ID', field: 'itemId', defaultSort: 'asc' },
          { title: 'Author', field: 'authorEmail' },
          { title: 'Created', field: 'createdAt' }
        ]}
        selectionMap={props.selections.itemNotes || {}}
        onSelectionChange={map => props.onSelectionsChange({ ...props.selections, itemNotes: map })}
        {...props}
      />
    )
  }

  return null;
};

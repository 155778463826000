import type { Role } from "./rolesPermissions";
import { ApiLevel, Permission } from "./rolesPermissions";
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Checkbox,
  IconButton,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from "@material-ui/core";
import type { FormikHelpers, FormikState } from "formik";
import { Field, FieldArray, Formik } from "formik";
import { useCallback, useEffect, useMemo, useState } from "react";
import * as Yup from 'yup';
import { createRole, getAllTargets, updateRole } from "./rolesPermissionsApi";
import type { ApiError } from "../../services/api";
import { pushAppNotification } from "../../shared/hooks/useAppNotification";
import { RemoveCircleOutline } from "@material-ui/icons";
import { TextField } from "formik-material-ui";
import _ from "lodash";
import { FormikSelectWithLabel } from "../../components/FormikSelectWithLabel";

export interface RoleEditorProps {
  role?: Role;
  cloneMode: boolean;
  onClose: () => void;
  onDataChange: () => void;
}

export const RoleEditor = ({role, cloneMode, onClose, onDataChange}: RoleEditorProps) => {

  const [allTargets, setAllTargets] = useState<string[] | undefined>();
  useEffect(() => {
    getAllTargets()
      .then(targets => setAllTargets(targets))
      .catch(err => pushAppNotification({type: 'error', message: `Failed to fetch all targets: ${err.message}`}));
  }, []);

  const isCreateMode = !Boolean(role) || cloneMode;
  const initialValues: Role = useMemo(() => {
    let initVals: Role;
    if (role) {
      if (cloneMode) {
        initVals = _.cloneDeep(role);
        initVals.id = undefined;
        initVals.name = '';
      } else {
        initVals = role;
      }
    } else {
      initVals = {
        name: '',
        apiLevel: '',
        permissions: [],
      };
    }
    return initVals;
  }, [cloneMode, role]);
  const validationSchema = Yup.object().shape({
    name: Yup.string().required().matches(/^ROLE_/),
    apiLevel: Yup.string().required().oneOf(Object.values(ApiLevel)),
    permissions: Yup.array().required().of(Yup.object().shape({
      target: Yup.string().required(),
      permissions: Yup.array().of(Yup.string().oneOf(Object.values(Permission))),
    })),
  });
  const onSubmit = useCallback((data: Role) => {
    if (isCreateMode) {
      createRole(data)
        .then(response => {
          onDataChange();
          pushAppNotification({
            type: 'success',
            message: `Created role ${data.name}`
          });
        })
        .catch((e: ApiError) => pushAppNotification({
          type: 'error',
          message: `Failed to create role. ${e.message}`
        }));
    } else {
      updateRole(data)
        .then(response => {
          onDataChange();
          pushAppNotification({
            type: 'success',
            message: `Updated role ${data.name}`
          });
        })
        .catch((e: ApiError) => pushAppNotification({
          type: 'error',
          message: `Failed to update role. ${e.message}`
        }));
    }
    onClose();
  }, [isCreateMode, onClose, onDataChange]);
  const onPermissionChange = useCallback((index, permission: Permission, checked: boolean, formikData: FormikState<Role> & FormikHelpers<Role>) => {
    const permissionModel = formikData.values.permissions[index];
    if (checked) {
      permissionModel.permissions = permissionModel.permissions.concat([permission]);
    } else {
      permissionModel.permissions = permissionModel.permissions
        .filter(p => p !== permission);
    }
    formikData.setFieldValue('permissions', formikData.values.permissions);
  }, []);
  const getTargetMenuItems = useCallback((currentTarget: string, targetsInUse: string[]) => {
    const targets = new Set((allTargets || []).filter(target => !targetsInUse.includes(target)));
    if (currentTarget) {
      targets.add(currentTarget);
    }
    return [...targets]
      .sort()
      .map(target => <MenuItem key={target} value={target}>{target}</MenuItem>);
  }, [allTargets]);
  return <Card>
    <Formik<Role>
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {formikData => (<form onSubmit={formikData.handleSubmit}>
        <CardHeader title={`${isCreateMode ? (cloneMode ? 'Clone' : 'Add') : 'Edit'} Role`}/>
        <CardContent>
          <Box display="flex" flexDirection="column" justifyContent="center">
            <Field
              component={TextField}
              type="text"
              id="name"
              name="name"
              label={"Role name"}
            />
          </Box>
        </CardContent>
        <CardContent>
          <Box display="flex" flexDirection="column" justifyContent="center">
            <Field
              component={FormikSelectWithLabel}
              id="apiLevel"
              name="apiLevel"
              label={"API Level"}
            >
              {Object.values(ApiLevel)
                .map(apiLevel => <MenuItem key={apiLevel} value={apiLevel}>{apiLevel}</MenuItem>)}
            </Field>
          </Box>
        </CardContent>
        <CardContent>
          <Box display="flex" flexDirection="column" justifyContent="center">
            <FieldArray
              name='permissions'>
              {arrayHelpers => (<>
                <TableContainer style={{maxHeight: 440}}>
                  <Table stickyHeader>
                    <TableHead>
                      <TableRow>
                        <TableCell/>
                        <TableCell>Target</TableCell>
                        {Object.values(Permission).map(perm => (<TableCell key={perm}>{perm}</TableCell>))}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {formikData.values.permissions.map((permission, index) => (
                        <TableRow key={`permission-${index}`}>
                          <TableCell>
                            <IconButton onClick={() => arrayHelpers.remove(index)}>
                              <RemoveCircleOutline/>
                            </IconButton>
                          </TableCell>
                          <TableCell>
                            <Field
                              component={FormikSelectWithLabel}
                              key={`permission-target-${index}`}
                              name={`permissions.${index}.target`}
                              value={permission.target}
                              label={"Target"}
                            >
                              {getTargetMenuItems(permission.target, formikData.values.permissions.map(perm => perm.target))}
                            </Field>
                          </TableCell>
                          {Object.values(Permission).map(perm =>
                            (<TableCell key={perm}>
                              <Checkbox
                                checked={permission.permissions.includes(perm)}
                                onChange={e => onPermissionChange(index, perm, e.target.checked, formikData)}/>
                            </TableCell>))}
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
                <Button
                  color="primary"
                  onClick={() => arrayHelpers.push({target: '', permissions: []})}
                >
                  Add Permission
                </Button>
              </>)
              }
            </FieldArray>
          </Box>
        </CardContent>
        <CardActions>
          <Button
            type="submit"
            variant="contained"
            disabled={formikData.isSubmitting}
            color="primary"
          >
            Save
          </Button>
          <Button onClick={onClose} color="primary">
            Cancel
          </Button>
        </CardActions>
      </form>)}
    </Formik>
  </Card>;
};

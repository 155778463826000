import { useEffect, useMemo, useState } from 'react';
import { Box, Button, CircularProgress, FormControlLabel, Grid, Link, Switch } from '@material-ui/core';
import { Column } from 'material-table';
import DeleteIcon from '@material-ui/icons/Delete';
import { UserService } from '../../services/user';
import { BlankoProgressionSkillDropTable } from '../../services/model/blanko';
import { RouterLink } from '../../types';
import MaterialTable, { MenuAction } from '../../components/MaterialTable';
import { usePushNotification } from '../../contexts/AppNotificationContext';
import { openAdminConfirmationDialog } from '../../shared/hooks/useAdminConfirmationDialog';
import { BlankosService } from '../../services/blankos';
import { ApiError } from '../../services/api';

export const SkillDropTablesPage = () => {
  const pushNotification = usePushNotification();

  const [skillDropTables, setSkillDropTables] = useState<BlankoProgressionSkillDropTable[]>();
  const [showDeleted, setShowDeleted] = useState(false);

  useEffect(() => {
    BlankosService.getProgressionSkillDropTables().then(setSkillDropTables)
      .catch((e: ApiError) => {
        e.message = `Failed to get skill drop tables. ${e.message}`;
        throw e;
      });
  }, []);

  const filteredDropTables: BlankoProgressionSkillDropTable[] = useMemo(() => {
    if (!skillDropTables)
      return [];

    return skillDropTables.filter(dt => showDeleted ? dt.deletedAt !== null : dt.deletedAt === null);
  }, [showDeleted, skillDropTables]);

  const menuActions: MenuAction<BlankoProgressionSkillDropTable>[] = useMemo(() => {
    const deleteBrawlPin = (dropTable: BlankoProgressionSkillDropTable) => {
      openAdminConfirmationDialog({
        title: `Delete ${dropTable.name}?`,
        action: 'Delete',
        onConfirm: () => {
          BlankosService.deleteProgressionSkillDropTable(dropTable.id)
            .then(() => {
              if (!skillDropTables)
                return;

              pushNotification({ type: 'success', message: 'Skill drop table deleted' });
              const remaining = [...skillDropTables].filter(dt => dt.id !== dropTable.id);
              setSkillDropTables(remaining);
            })
            .catch(err => pushNotification({ type: 'error', message: `Failed to delete skill drop table: ${err.message}` }));
        }
      });
    };

    const menuActions: MenuAction<BlankoProgressionSkillDropTable>[] = [];
    if (!showDeleted && UserService.canDelete('blankos')) {
      menuActions.push({ type: 'button', icon: DeleteIcon, label: 'Delete', onClick: deleteBrawlPin });
    }

    return menuActions;
  }, [skillDropTables, showDeleted, pushNotification]);

  const columns: Column<BlankoProgressionSkillDropTable>[] = [
    {
      title: 'Name',
      field: 'name',
      defaultSort: 'asc',
      render: dropTable => <Link component={RouterLink} to={`/progression-skill-drops/${dropTable.id}`}>{dropTable.name}</Link>
    },
    {
      title: 'Value (JSON)',
      field: 'value'
    }
  ];

  if (!skillDropTables) {
    return <Box textAlign="center">
      <CircularProgress />
    </Box>;
  }

  return (<>
    <Box mb={2}>
      <Grid container spacing={2} alignItems="center">
        <Grid item xs>
          <FormControlLabel
            control={
              <Switch
                checked={showDeleted}
                onChange={event => setShowDeleted(event.target.checked)}
                color="primary"
              />
            }
            label="Show deleted"
          />
        </Grid>
        {UserService.canCreate('blankos') && (
          <Grid item>
            <Button
              component={RouterLink}
              variant="contained"
              color="primary"
              to={'/progression-skill-drops/new'}
            >
              Add skill drop table
            </Button>
          </Grid>
        )}
      </Grid>
    </Box>

    <MaterialTable<BlankoProgressionSkillDropTable>
      title=""
      columns={columns}
      menuActions={menuActions}
      data={filteredDropTables}
      options={{
        paging: false,
        search: false,
        toolbar: false,
        showTitle: false
      }}
    />
  </>);
};
import type { RouteComponentProps } from 'react-router';
import type { LinkTabConfig } from '../../../components/LinkTabs';
import { LinkTabs } from '../../../components/LinkTabs';
import { UserService } from '../../../services/user';
import CurrencyLimits from '../../../pages/Currency/CurrencyLimits';
import { MassGrants } from './MassGrants';
import { Players } from './Players';
import { ReportedPlayers } from './ReportedPlayers';
import { BetaAccess } from './BetaAccess';
import { Tags } from "../../accounts/pages/Tags";
import { BulkTagging } from "../../accounts/pages/BulkTagging";

export const MythicalAccountsPage = (props: RouteComponentProps) => {
  const tabs: LinkTabConfig[] = [];

  if (UserService.canRead('player')) {
    tabs.push({url: '/players', label: 'Find player', absolute: true, component: Players});
  }
  if (UserService.canCreate('playerInventoryBulk')) {
    tabs.push({url: '/players/grants', label: 'Bulk grants', absolute: true, component: MassGrants});
  }
  if (UserService.canRead('player')) {
    tabs.push({url: '/players/reported', label: 'Reported', absolute: true, component: ReportedPlayers});
  }
  if (UserService.canRead('currencyLimits')) {
    tabs.push({url: '/players/currency-limits', label: 'Currency Limits', absolute: true, component: CurrencyLimits});
  }
  if (UserService.canUpdate('mythicalAccount')) {
    tabs.push({url: '/accounts/beta-access', label: 'Beta Access', absolute: true, component: BetaAccess});
  }
  if (UserService.canUpdate('mythicalAccount')) {
    tabs.push({url: '/accounts/tags', label: 'Tags', absolute: true, component: Tags});
  }
  if (UserService.canUpdate('mythicalAccount')) {
    tabs.push({url: '/accounts/tags/bulk', label: 'Bulk Tagging', absolute: true, component: BulkTagging});
  }

  return (
    <LinkTabs tabs={tabs} {...props} />
  );
};
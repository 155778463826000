import { Box, CircularProgress, Typography } from '@material-ui/core';
import { useEffect, useCallback, useState } from 'react';
import { useParams, useHistory, useLocation, useRouteMatch} from 'react-router';
import type { LinkTabConfig} from '../../../components/LinkTabs';
import { LinkTabs } from '../../../components/LinkTabs';
import { BlankoData } from '../BlankoData';
import { BlankoInfo } from '../BlankoInfo';
import { BlankoItems } from '../BlankoItems';
import { UserService } from '../../../services/user';
import { BlankosService } from '../../../services/blankos';
import type { Blanko } from '../../../services/blankos';

export const BlankoPage = () => {

  const { blankoId } = useParams<{ blankoId: string }>();
  const [blanko, setBlanko] = useState<Blanko>();

  const history = useHistory();
  const location = useLocation();
  const match = useRouteMatch();

  const tabs: LinkTabConfig[] = [
    // eslint-disable-next-line react/display-name
    { url: '', label: 'Info', render: () => <BlankoInfo blanko={blanko} /> }
  ];

  if (UserService.canRead('blankoData')) {
    // eslint-disable-next-line react/display-name
    tabs.push({ url: '/data', label: 'Data', render: () => <BlankoData incomingBlanko={blanko} /> });
  }

  if (UserService.canRead('itemInstances')) {
    // eslint-disable-next-line react/display-name
    tabs.push({ url: '/items', label: 'Items', render: () => <BlankoItems blanko={blanko} /> });
  }

  const getData = useCallback(async () => {
    const result = await BlankosService.getBlanko(blankoId);
    setBlanko(result);
  }, [blankoId]);

  useEffect(() => {
    getData();
  }, [getData]);


  return blanko ?
    (<>
      <Box>
        <Typography variant="h6">
          Blanko: {blanko.name || blanko.id}
        </Typography>
      </Box>
      <LinkTabs tabs={tabs} history={history} location={location} match={match} />
    </>) :
    (<>
      <Box textAlign="center">
        <CircularProgress />
      </Box>
    </>)
}
import { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { cmSetMigrationActions } from '../../../../redux/catalog-migration/actions';
import { useTypedSelector } from '../../../../redux/reducers';
import type { MigrationActions } from '../../../../redux/catalog-migration/reducer';
import { SeasonalDataDiff } from '../../types';
import { SeasonalData } from './SeasonalData';

export const SeasonalDataContainer = () => {
  const dispatch = useDispatch();

  const sourceSeasonalData = useTypedSelector(state => state.catalogMigration.source.seasonalData);
  const targetSeasonalData = useTypedSelector(state => state.catalogMigration.target.seasonalData);
  const actions = useTypedSelector(state => state.catalogMigration.actions.seasonalData);

  const [sourceItems, setSourceItems] = useState<SeasonalDataDiff[] | null>(null);
  const [targetItems, setTargetItems] = useState<SeasonalDataDiff[] | null>(null);

  const onSelectionChange = useCallback(
    (actions: MigrationActions) => dispatch(cmSetMigrationActions({ id: 'seasonalData', actions })),
    [dispatch]
  );

  useEffect(() => {
    if (!sourceSeasonalData || !targetSeasonalData) {
      return;
    }
    setSourceItems(sourceSeasonalData.map(x => new SeasonalDataDiff(x)));
    setTargetItems(targetSeasonalData.map(x => new SeasonalDataDiff(x)));
  }, [sourceSeasonalData, targetSeasonalData]);

  return (<SeasonalData
    sourceItems={sourceItems}
    targetItems={targetItems}
    actions={actions}
    onSelectionChange={onSelectionChange} />);
}
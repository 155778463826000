import type { CallEffect, PutEffect, SelectEffect} from 'redux-saga/effects';
import { call, fork, put, select, takeLatest } from 'redux-saga/effects';
import type { RootState } from '../../reducers';
import type { Store} from '../../../services/stores';
import { StoresService } from '../../../services/stores';
import {
  getCatalogStoresAsync,
  getCatalogStoreAsync,
  addCatalogStoreAsync,
  updateCatalogStoreAsync,
  deleteCatalogStoreAsync,
  publishCatalogStoreAsync,
  lockCatalogStoreAsync,
  unlockCatalogStoreAsync
} from './actions';
import { sagaHandleApiError } from '../../utils-ts';
import { setAppNotification } from '../../app/actions';
import type { AnyAction, EmptyObject } from 'redux';
import type { PayloadAction } from 'typesafe-actions';

function* fetchCatalogStores(): Generator<Generator<PutEffect<AnyAction>, void, string> | CallEffect<Store[]> | SelectEffect | PutEffect<PayloadAction<"GET_CATALOG_STORES_SUCCESS", Store[]>>, void, EmptyObject & Store[] & RootState> {
  try {
    const state: RootState = yield select();
    if (state.catalog.name) {
      const stores = yield call(StoresService.getStores, state.catalog.name);
      yield put(getCatalogStoresAsync.success(stores));
    }
  } catch (e) {
    yield sagaHandleApiError(e, getCatalogStoresAsync.failure);
  }
}

function* fetchCatalogStore(action: ReturnType<typeof getCatalogStoreAsync.request>): Generator<CallEffect<Store> | SelectEffect | Generator<PutEffect<AnyAction>, void, string> | PutEffect<PayloadAction<"GET_CATALOG_STORE_SUCCESS", Store>>, void, EmptyObject & Store & RootState> {
  try {
    const state: RootState = yield select();
    if (state.catalog.name) {
      const store = yield call(StoresService.getStore, state.catalog.name, action.payload);
      yield put(getCatalogStoreAsync.success(store));
    }
  } catch (e) {
    yield sagaHandleApiError(e, getCatalogStoreAsync.failure);
  }
}

function* addCatalogStoreSaga(action: ReturnType<typeof addCatalogStoreAsync.request>): Generator<Generator<PutEffect<AnyAction>, void, string> | SelectEffect | CallEffect<Store> | PutEffect<PayloadAction<"ADD_CATALOG_STORE_SUCCESS", Store>> | PutEffect<PayloadAction<"SET_APP_NOTIFICATION", Record<string, unknown>>>, void, EmptyObject & Store & RootState> {
  try {
    const state: RootState = yield select();
    if (state.catalog.name) {
      const store = yield call(StoresService.createStore, state.catalog.name, action.payload);
      yield put(addCatalogStoreAsync.success(store));
      yield put(setAppNotification({ type: 'success', message: 'Store saved' }));
    }
  } catch (e) {
    yield sagaHandleApiError(e, addCatalogStoreAsync.failure);
  }
}

function* updateCatalogStoreSaga(action: ReturnType<typeof updateCatalogStoreAsync.request>): Generator<Generator<PutEffect<AnyAction>, void, string> | SelectEffect | CallEffect<Store> | PutEffect<PayloadAction<"UPDATE_CATALOG_STORE_SUCCESS", Store>> | PutEffect<PayloadAction<"SET_APP_NOTIFICATION", Record<string, unknown>>>, void, EmptyObject & Store & RootState> {
  try {
    const state: RootState = yield select();
    if (state.catalog.name) {
      const store = yield call(StoresService.updateStore, state.catalog.name, action.payload);
      yield put(updateCatalogStoreAsync.success(store));
      yield put(setAppNotification({ type: 'success', message: 'Store saved' }));
    }
  } catch (e) {
    yield sagaHandleApiError(e, updateCatalogStoreAsync.failure);
  }
}

function* deleteCatalogStoreSaga(action: ReturnType<typeof deleteCatalogStoreAsync.request>) {
  try {
    const state: RootState = yield select();
    if (state.catalog.name) {
      yield call(StoresService.deleteStore, state.catalog.name, action.payload);
      yield put(deleteCatalogStoreAsync.success(action.payload));
      yield put(setAppNotification({ type: 'success', message: 'Store deleted' }));
    }
  } catch (e) {
    yield sagaHandleApiError(e, deleteCatalogStoreAsync.failure);
  }
}

function* publishCatalogStoreSaga(action: ReturnType<typeof publishCatalogStoreAsync.request>): Generator<CallEffect<Store> | SelectEffect | Generator<PutEffect<AnyAction>, void, string> | PutEffect<PayloadAction<"PUBLISH_CATALOG_STORE_SUCCESS", Store>> | PutEffect<PayloadAction<"SET_APP_NOTIFICATION", Record<string, unknown>>>, void, EmptyObject & RootState & Store> {
  try {
    const state: RootState = yield select();
    if (state.catalog.name) {
      const store = yield call(StoresService.publishStore, state.catalog.name, action.payload);
      yield put(publishCatalogStoreAsync.success(store));
      yield put(setAppNotification({ type: 'success', message: 'Store announced' }));
    }
  } catch (e) {
    yield sagaHandleApiError(e, publishCatalogStoreAsync.failure);
  }
}

function* lockCatalogStoreSaga(action: ReturnType<typeof lockCatalogStoreAsync.request>): Generator<Generator<PutEffect<AnyAction>, void, string> | SelectEffect | CallEffect<Store> | PutEffect<PayloadAction<"LOCK_STORE_SUCCESS", Store>> | PutEffect<PayloadAction<"SET_APP_NOTIFICATION", Record<string, unknown>>>, void, EmptyObject & Store & RootState> {
  try {
    const state: RootState = yield select();
    if (state.catalog.name) {
      const store = yield call(StoresService.lockCatalogStore, state.catalog.name, action.payload);
      yield put(lockCatalogStoreAsync.success(store));
      yield put(setAppNotification({ type: 'success', message: 'Store locked' }))
    }
  } catch (e) {
    yield sagaHandleApiError(e, lockCatalogStoreAsync.failure);
  }
}

function* unlockCatalogStoreSaga(action: ReturnType<typeof unlockCatalogStoreAsync.request>): Generator<CallEffect<Store> | SelectEffect | Generator<PutEffect<AnyAction>, void, string> | PutEffect<PayloadAction<"UNLOCK_STORE_SUCCESS", Store>> | PutEffect<PayloadAction<"SET_APP_NOTIFICATION", Record<string, unknown>>>, void, EmptyObject & Store & RootState> {
  try {
    const state: RootState = yield select();
    if (state.catalog.name) {
      const store = yield call(StoresService.unlockCatalogStore, state.catalog.name, action.payload);
      yield put(unlockCatalogStoreAsync.success(store));
      yield put(setAppNotification({ type: 'success', message: 'Store unlocked' }))
    }
  } catch (e) {
    yield sagaHandleApiError(e, unlockCatalogStoreAsync.failure);
  }
}

function* watchRequestCatalogStores() {
  yield takeLatest(getCatalogStoresAsync.request, fetchCatalogStores);
}

function* watchRequestCatalogStore() {
  yield takeLatest(getCatalogStoreAsync.request, fetchCatalogStore);
}

function* watchRequestAddCatalogStore() {
  yield takeLatest(addCatalogStoreAsync.request, addCatalogStoreSaga);
}

function* watchRequestUpdateCatalogStore() {
  yield takeLatest(updateCatalogStoreAsync.request, updateCatalogStoreSaga);
}

function* watchRequestDeleteCatalogStore() {
  yield takeLatest(deleteCatalogStoreAsync.request, deleteCatalogStoreSaga);
}

function* watchRequestPublishCatalogStore() {
  yield takeLatest(publishCatalogStoreAsync.request, publishCatalogStoreSaga);
}

function* watchRequestLockCatalogStore() {
  yield takeLatest(lockCatalogStoreAsync.request, lockCatalogStoreSaga);
}

function* watchRequestUnlockCatalogStore() {
  yield takeLatest(unlockCatalogStoreAsync.request, unlockCatalogStoreSaga);
}

export default function* catalogStoresSagas() {
  yield fork(watchRequestCatalogStores);
  yield fork(watchRequestCatalogStore);
  yield fork(watchRequestAddCatalogStore);
  yield fork(watchRequestUpdateCatalogStore);
  yield fork(watchRequestDeleteCatalogStore);
  yield fork(watchRequestPublishCatalogStore);
  yield fork(watchRequestLockCatalogStore);
  yield fork(watchRequestUnlockCatalogStore);
}
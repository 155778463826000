import {useCallback, useEffect, useRef, useState} from 'react';
import type { Column, Query, QueryResult } from "material-table";
import {Button, Checkbox, FormControlLabel, Grid} from "@material-ui/core";
import MaterialTable from "../../../components/MaterialTable";
import { paginatedRequestFromQuery } from "../../../services/model/pagination";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import { EntitlementRewardsService } from "../../../services/rewards";
import { pushAppNotification } from "../../../shared/hooks/useAppNotification";
import {openAdminConfirmationDialog} from "../../../shared/hooks/useAdminConfirmationDialog";

export const Fulfillments = () => {
  const [showClaimed, setShowClaimed] = useState(true);
  const [rewardCheckerState, setRewardCheckerState] = useState<UnclaimedRewardsResponse>({ canCheck: true, lastCheckEndTime: "N/A", lastCheckStartTime: "N/A" });
  const tableRef = useRef<MaterialTable<EntitlementFulfillment>>();

  const getFulfillmentsPage = useCallback(async (query: Query<EntitlementFulfillment>): Promise<QueryResult<EntitlementFulfillment>> => {
    return new Promise(resolve => {
      const request = paginatedRequestFromQuery(query);
      request.search = query.search.trim();
      request.name = request.search;

      const getEntitlementFulfillments = EntitlementRewardsService.getEntitlementFulfillments(request);
      getEntitlementFulfillments.then(result => {
        result.items = result.items.filter(item => (showClaimed ? item.state == "FULFILLED" : item.state != "FULFILLED"));
        resolve({
          data: result.items,
          page: request.page - 1,
          totalCount: result.totalCount
        });
      });
    });
  }, [showClaimed]);

  useEffect(() => {
    if (tableRef && tableRef.current) {
      (tableRef.current as any).onQueryChange();
    }
    EntitlementRewardsService.canCheckForUnclaimedRewards().then(res => {
      setRewardCheckerState(res);
    }).catch(err => {
      // Don't alert the user yet because this will always fail until backend MGP-815 is done
      console.error(err.message);
    })
  }, [showClaimed]);

  const columns: Column<EntitlementFulfillment>[] = [
    {
      title: 'ID',
      field: 'id',
    },
    {
      title: 'Player ID',
      field: 'playerId',
      defaultSort: 'asc'
    },
    {
      title: 'Player Name',
      field: 'displayName',
      defaultSort: 'asc'
    },
    {
      title: 'Player Email',
      field: 'email',
      defaultSort: 'asc'
    },
    {
      title: 'Rewards ID',
      field: 'entitlementRewardsId',
      defaultSort: 'asc'
    },
    {
      title: 'Item ID',
      field: 'itemId',
      defaultSort: 'asc'
    },
    {
      title: 'Catalog',
      field: 'catalogName',
      defaultSort: 'asc'
    },
    {
      title: 'State',
      field: 'state',
      defaultSort: 'asc'
    },
    {
      title: 'Service',
      field: 'serviceType',
      defaultSort: 'asc'
    },
  ];

  const unclaimedCheck = useCallback(async () => {
    openAdminConfirmationDialog({
      title: `Intensive Action`,
      action: 'Check For Unclaimed Rewards',
      details: 'This process will check all players for unclaimed rewards, and may take awhile. Proceed?',
      onConfirm: () => {
        setRewardCheckerState({...rewardCheckerState, canCheck: false, lastCheckStartTime: "Now" });
        EntitlementRewardsService.checkForUnclaimedRewards().then(() => {
          pushAppNotification({type: 'success', message: `Queue started - check back later for results.`});
        });
      }
    });
  }, [rewardCheckerState]);

  return (<>
    <Box mb={2}>
      <Grid container spacing={2} alignItems="center">
        <Grid item xs>
          <Typography variant="h6">
            Entitlement Fulfillments
          </Typography>
        </Grid>
      </Grid>
    </Box>
    <Box mb={2}>
      <FormControlLabel
        control={
          <Checkbox
            checked={showClaimed}
            onChange={event => setShowClaimed(event.target.checked)}
          />
        }
        label="Claimed Rewards"
      />
      {!showClaimed &&
        <Button color="primary" variant="contained" onClick={unclaimedCheck} disabled={!rewardCheckerState.canCheck}>
          Check for unclaimed rewards
        </Button>
      }
      {!rewardCheckerState.canCheck &&
        <Box mb={2}>
          Checker currently running. Started at: {rewardCheckerState.lastCheckStartTime}
        </Box>
      }
    </Box>
    <MaterialTable<EntitlementFulfillment>
      title={(showClaimed ? "Claimed Rewards" : "Unclaimed Rewards")}
      storageId="fulfillments"
      columns={columns}
      data={getFulfillmentsPage}
      options={{
        columnsButton: true,
        debounceInterval: 500,
        pageSize: 20,
        pageSizeOptions: [20, 50, 100],
        thirdSortClick: false
      }}
      tableRef={tableRef}
      useRouter
    />
  </>);
};

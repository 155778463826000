/* eslint-disable @typescript-eslint/ban-ts-comment */
import type { Theme} from "@material-ui/core";
import { makeStyles, createStyles, FormHelperText, InputLabel, Select } from "@material-ui/core";
import type { FormikProps } from "formik";
import { CurrencyForm } from './CurrencyForm';
import type { BlankoProgressionTierCapstone, BurnGumballsParams, BurnCurrencyParams } from '../blankoGemRush';
import { CapstoneEnum } from '../blankoGemRush';
import { GumballsForm } from "./GumballsForm";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formControl: {
      marginTop: theme.spacing(2),
      minWidth: 400,
    },

  }),
);
interface CapstoneFormProps {
  index: number
}

export const CapstoneForm = ({ errors, touched, handleChange, handleBlur, values, index }: FormikProps<BlankoProgressionTierCapstone> & CapstoneFormProps) => {
  const { type, param } = values
  const classes = useStyles();
  return (
    <>
      <InputLabel className={classes.formControl} htmlFor="currency-type">Currency Type</InputLabel>
      <Select
        native
        value={type}
        className={classes.formControl}
        onChange={handleChange}
        inputProps={{
          name: `capstone.${index}.type`,
          id: `capstone.${index}.type`,
        }}
      >
        <option value={CapstoneEnum.BurnCurrency}>{CapstoneEnum.BurnCurrency}</option>
        <option value={CapstoneEnum.BurnGumballs}>{CapstoneEnum.BurnGumballs}</option>
      </Select>
      <FormHelperText>
        {(touched?.type && errors?.type)}
      </FormHelperText>
      {type === CapstoneEnum.BurnCurrency && <CurrencyForm 
        index={index}
        values={(param as unknown) as BurnCurrencyParams}
        //@ts-ignore
        errors={errors}
        //@ts-ignore
        touched={touched}
        handleChange={handleChange}
        handleBlur={handleBlur}/>
      }
      {type === CapstoneEnum.BurnGumballs && <GumballsForm 
        index={index}
        values={(param as unknown) as BurnGumballsParams}
        //@ts-ignore
        errors={errors}
        //@ts-ignore
        touched={touched}
        handleChange={handleChange}
        handleBlur={handleBlur}/>
      }
      
    </>)
}

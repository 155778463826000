import type { CallEffect, PutEffect} from 'redux-saga/effects';
import { call, fork, put, takeLatest } from 'redux-saga/effects';
import type { Catalog} from '../../services/catalogs';
import { CatalogsService } from '../../services/catalogs';
import { sagaHandleApiError } from '../utils-ts';
import { getCatalogsAsync } from './actions';
import type { AnyAction } from 'redux';
import type { PayloadAction } from 'typesafe-actions';

function* fetchCatalogs(): Generator<CallEffect<Catalog[]> | PutEffect<PayloadAction<"GET_CATALOGS_SUCCESS", Catalog[]>> | Generator<PutEffect<AnyAction>, void, string>, void, Catalog[]> {
  try {
    const catalogs = yield call(CatalogsService.getCatalogs);
    yield put(getCatalogsAsync.success(catalogs));
  } catch (e) {
    yield sagaHandleApiError(e, getCatalogsAsync.failure);
  }
}

function* watchRequestCatalogs() {
  yield takeLatest(getCatalogsAsync.request, fetchCatalogs);
}

export default function* catalogsSagas() {
  yield fork(watchRequestCatalogs);
}
import {getAllTransactionItemsAsync, getAllTransactionsAsync, refundTransactionAsync} from "./actions";
import type { TransactionAggregate, TransactionItem, IssueRefundResponse } from '../../services/transactions';
import { TransactionService } from '../../services/transactions';
import {forkSagas, sagaHandleApiError} from "../utils-ts";
import {setAppNotification} from "../app/actions";
import type { CallEffect, PutEffect} from "redux-saga/effects";
import {call, put, takeLatest} from "redux-saga/effects";
import type { AnyAction } from "redux";
import type { PayloadAction } from "typesafe-actions";
import type { PaginatedResponse } from "../../services/model/pagination";

function* fetchTransactionsSaga(action: ReturnType<typeof getAllTransactionsAsync.request>): Generator<CallEffect<PaginatedResponse<TransactionAggregate>> | PutEffect<PayloadAction<"GET_ALL_TRANSACTIONS_SUCCESS", PaginatedResponse<TransactionAggregate>>> | Generator<PutEffect<AnyAction>, void, string>, void, PaginatedResponse<TransactionAggregate>> {
  try {
    const transactions = yield call(TransactionService.getAllTransactions, action.payload);
    yield put(getAllTransactionsAsync.success(transactions));
  } catch (e) {
    yield sagaHandleApiError(e, getAllTransactionsAsync.failure);
  }
}

function* fetchTransactionItemsSaga(action: ReturnType<typeof getAllTransactionItemsAsync.request>): Generator<CallEffect<PaginatedResponse<TransactionItem>> | PutEffect<PayloadAction<"GET_ALL_TRANSACTION_ITEMS_SUCCESS", PaginatedResponse<TransactionItem>>> | Generator<PutEffect<AnyAction>, void, string>, void, PaginatedResponse<TransactionItem>> {
  try {
    const items = yield call(TransactionService.getTransactionItems, action.payload);
    yield put(getAllTransactionItemsAsync.success(items));
  } catch (e) {
    yield sagaHandleApiError(e, getAllTransactionItemsAsync.failure);
  }
}

function* refundTransactionSaga(action: ReturnType<typeof refundTransactionAsync.request>): Generator<CallEffect<IssueRefundResponse> | PutEffect<PayloadAction<"REFUND_TRANSACTION_SUCCESS", IssueRefundResponse>> | PutEffect<PayloadAction<"SET_APP_NOTIFICATION", Record<string, unknown>>> |  Generator<PutEffect<AnyAction>, void, string>, void, IssueRefundResponse> {
  try {
    const response = yield call(TransactionService.refundTransaction, action.payload);
    yield put(refundTransactionAsync.success(response));
    yield put(setAppNotification({ type: 'success', message: 'Transaction refunded' }));
  } catch (e) {
    yield sagaHandleApiError(e, refundTransactionAsync.failure);
  }
}

const sagas = [
  function* () {
    yield takeLatest(getAllTransactionsAsync.request, fetchTransactionsSaga);
  },
  function* () {
    yield takeLatest(getAllTransactionItemsAsync.request, fetchTransactionItemsSaga);
  },
  function* () {
    yield takeLatest(refundTransactionAsync.request, refundTransactionSaga);
  }
];

export default function* transactionsSagas() {
  yield forkSagas(sagas);
}
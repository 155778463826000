import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  TextField
} from '@material-ui/core';
import { useCallback, useState } from 'react';
import type { JsonFileUploadValue } from '../../../components/JsonFileUpload';
import JsonFileUpload from '../../../components/JsonFileUpload';
import { Catalog, CatalogsService } from '../../../services/catalogs';
import { arrayReplaceOrPush } from "../../../redux/utils-ts";
import { pushAppNotification } from "../../../shared/hooks/useAppNotification";

type Props = {
  onClose: () => void;
  catalogs: Catalog[];
  setCatalogs: (catalogs: Catalog[]) => void;
};

export const ImportCatalogDialog = ({onClose, catalogs, setCatalogs}: Props) => {
  const [open, setOpen] = useState(true);
  const [name, setName] = useState('');
  const [nameError, setNameError] = useState('');
  const [catalog, setCatalog] = useState<Catalog | null>(null);
  const [catalogJson, setCatalogJson] = useState<JsonFileUploadValue>({});
  const [storesJson, setStoresJson] = useState<JsonFileUploadValue>({});
  const [isSubmitting, setIsSubmitting] = useState(false);

  const setNewCatalogJson = useCallback((newCatalogJson: JsonFileUploadValue) => {
    let newName = '';
    let newCatalog: Catalog | null = null;
    if (newCatalogJson.json && !newCatalogJson.error) {
      if (typeof newCatalogJson.json.CatalogVersion === 'string') { // PlayFab catalog format
        newName = newCatalogJson.json.CatalogVersion;
      } else {
        newCatalog = new Catalog(newCatalogJson.json);
        if (newCatalog.name && newCatalog.hasContents()) {
          newName = newCatalog.name;
        } else {
          newCatalogJson.error = 'Unexpected catalog JSON format.';
        }
      }
    }
    setName(newName);
    setCatalog(newCatalog);
    setCatalogJson(newCatalogJson);
  }, []);

  const onNameChange = useCallback((event: any) => {
    const newName = event.target.value as string;
    if (!newName.trim()) {
      setNameError('Cannot be blank');
    } else {
      setNameError('');
    }
    setName(newName);
  }, []);

  const canSubmit = useCallback(() => {
    return !isSubmitting && !catalogJson.error && !storesJson.error && !nameError;
  }, [catalogJson.error, isSubmitting, nameError, storesJson.error]);

  const onSubmit = useCallback(() => {
    setIsSubmitting(true);
    if (catalog) {
      catalog.primaryCatalog = false;
      catalog.name = name.trim();
      CatalogsService.addCatalog(catalog)
        .then(cat => {
          pushAppNotification({ type: 'success', message: 'Catalog added' });
          setCatalogs(arrayReplaceOrPush(catalogs, cat, v => v.name === cat.name));
          setOpen(false);
        })
        .catch(err => {
          pushAppNotification({type: 'error', message: `Failed to add catalog: ${err.message}`});
        })
        .finally(() => setIsSubmitting(false));
    } else {
      const json = catalogJson.json;

      // Update all references to the name of the catalog
      const catalogName = name.trim();
      json.CatalogVersion = catalogName;
      json.Catalog.forEach((v: any) => v.CatalogVersion = catalogName);

      json.stores = storesJson.json || [];
      CatalogsService.importCatalog(json)
        .then(cat => {
          pushAppNotification({ type: 'success', message: 'Catalog import successful' });
          setCatalogs(arrayReplaceOrPush(catalogs, cat, v => v.name === cat.name));
          setOpen(false);
        })
        .catch(err => {
          pushAppNotification({type: 'error', message: `Failed to import catalog: ${err.message}`});
        })
        .finally(() => setIsSubmitting(false));
    }
  }, [catalog, catalogJson.json, catalogs, name, setCatalogs, storesJson.json]);

  return (
    <Dialog
      open={open}
      onClose={() => setOpen(false)}
      onExited={onClose}
      fullWidth
      maxWidth="sm"
    >
      <DialogTitle>Import Catalog</DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <JsonFileUpload label="Catalog JSON" value={catalogJson} onChange={setNewCatalogJson}/>
          </Grid>
          {catalogJson.json && !catalogJson.error && (
            <Grid item xs={12}>
              <TextField
                label="Name"
                fullWidth
                onChange={onNameChange}
                value={name}
                error={!!nameError}
                helperText={nameError}
              />
            </Grid>
          )}
          {catalogJson.json && !catalogJson.error && !catalog && (
            <Grid item xs={12}>
              <JsonFileUpload
                label="Stores JSON"
                value={storesJson}
                onChange={storesJson => setStoresJson(storesJson)}/>
            </Grid>
          )}
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button color="primary" variant="contained" disabled={!canSubmit()} onClick={onSubmit}>
          {isSubmitting ? (
            <CircularProgress size={25}/>
          ) : 'Import'}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

import { Box, Button, Card, CardContent, CardHeader, CircularProgress, Grid } from '@material-ui/core';
import { Component } from 'react';
import type { ConnectedProps } from 'react-redux';
import { connect } from 'react-redux';
import { openConfirmationDialog, setAppNotification } from '../../redux/app/actions';
import { deleteRewardPathTierAsync, getRewardPathTiersAsync } from '../../redux/player-challenges/season-reward-path-tiers/actions';
import type { RootState } from '../../redux/reducers';
import type { RewardPathTierDto } from '../../services/player-challenges/season-reward-path-tiers';
import { UserService } from '../../services/user';
import RewardPathTierControl from './RewardPathTierControl';
import SeasonRewardPathTierDialog from './SeasonRewardPathTierDialog';

const mapStateToProps = (state: RootState) => ({
  seasonRewardPathTiers: state.seasonRewardPathTiers
});

const mapDispatch = {
  requestGetRewardPathTiers: getRewardPathTiersAsync.request,
  requestDeleteRewardPathTier: deleteRewardPathTierAsync.request,
  openConfirmationDialog,
  setAppNotification
};

const connector = connect(mapStateToProps, mapDispatch);

interface ComponentProps {
  seasonNumber: number,
  pathId: string
}

type Props = ComponentProps & ConnectedProps<typeof connector>;

interface State {
  rewardPathTiers?: RewardPathTierDto[],
  tierDialogOpen: boolean,
  rewardPathTierToEdit?: RewardPathTierDto
}

class SeasonRewardPathTiers extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      rewardPathTiers: undefined,
      tierDialogOpen: false,
      rewardPathTierToEdit: undefined
    }
  }

  componentDidMount() {
    if (this.props.pathId) {
      this.props.requestGetRewardPathTiers({ seasonNumber: this.props.seasonNumber, pathId: this.props.pathId });
    }
  }

  static getDerivedStateFromProps(props: Props, state: State) {
    if (state.rewardPathTiers && state.rewardPathTiers.length === props.seasonRewardPathTiers.data.length) return null;

    return ({ rewardPathTiers: props.seasonRewardPathTiers.data });
  }

  onRewardPathTierDelete = (rewardPathTier: RewardPathTierDto) => {
    this.props.openConfirmationDialog({ title: `Delete Tier: ${rewardPathTier.tierName}?`, action: 'Delete Tier' }, () => {
      this.props.requestDeleteRewardPathTier({ seasonNumber: this.props.seasonNumber, pathId: this.props.pathId, tierId: rewardPathTier.tierId });
    });
  };

  onRewardPathTierEdit = (tier: RewardPathTierDto) => {
    this.setState({
      tierDialogOpen: true,
      rewardPathTierToEdit: tier
    })

  }

  render() {
    const rewardPathTiers = this.state.rewardPathTiers;

    const canAdd: boolean = UserService.canCreate('seasons');

    if (this.props.seasonRewardPathTiers.isLoading || !rewardPathTiers) {
      return (
        <Box textAlign="center">
          <CircularProgress />
        </Box>
      );
    }

    const addButton = (<Button variant="contained" color="primary" onClick={() => this.setState({ ...this.state, tierDialogOpen: true })}>
      New Tier
    </Button>)

    return (<>
      <Grid container spacing={3}>
        <Grid item xs={12} md={12} xl={12}>
          <Box pt={4}>
            <Card>
              <CardHeader title="Tiers" action={canAdd ? addButton : null} />
            </Card>
          </Box>
        </Grid>
        {rewardPathTiers.length === 0 ?
          <Grid item xs={12} md={12} xl={12}>
            <Card>
              <CardContent>
                No Tiers.
              </CardContent>
            </Card>
          </Grid> : null}
        {rewardPathTiers.map((tier, index) => (
          <Grid item xs={12} md={12} xl={12} key={tier.tierId}>
            <Card>
              <CardContent>
                <RewardPathTierControl
                  rewardPathTier={tier}
                  onRewardPathTierDelete={this.onRewardPathTierDelete}
                  onRewardPathTierEdit={this.onRewardPathTierEdit}
                  isLast={index === rewardPathTiers.length - 1}
                />
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>

      {this.state.tierDialogOpen && (
        <SeasonRewardPathTierDialog
          seasonNumber={this.props.seasonNumber}
          pathId={this.props.pathId}
          rewardPathTier={this.state.rewardPathTierToEdit}
          isLastTier={this.state.rewardPathTierToEdit === rewardPathTiers[rewardPathTiers.length - 1]}
          onClose={(tier?: RewardPathTierDto) => this.setState({ ...this.state, tierDialogOpen: false, rewardPathTierToEdit: undefined })}
        />
      )}

      <Box pt={4}>
        <Card>
          <CardHeader title="" action={canAdd ? addButton : null} />
        </Card>
      </Box>


    </>);
  }

}

export default connector(SeasonRewardPathTiers);
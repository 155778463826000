import { useMemo } from 'react';
import { BrawlPinPublishOptions } from '../components/BrawlPinPublishOptions';
import { MigrationDataLoader } from '../components/MigrationDataLoader';
import { MigrationDiffsStep } from '../components/MigrationDiffsStep';
import { MigrationDone } from '../components/MigrationDone';
import { MigrationFramework } from '../components/MigrationFramework';
import { MigrationReviewStep } from '../components/MigrationReviewStep';
import { MigrationRunner } from '../components/MigrationRunner';
import type { MigrationStep } from '../migrations';
import { brawlPinPublishModules, brawlPinPublishRunOrder } from '../modules/brawl-pin-publish-modules';

const brawlPinPublishSteps: MigrationStep[] = [
  { id: 'options', title: '', component: BrawlPinPublishOptions },
  { id: 'loading', title: 'Loading...', component: MigrationDataLoader },
  { id: 'diff', title: 'Select changes', component: MigrationDiffsStep },
  { id: 'review', title: 'Review changes', component: MigrationReviewStep },
  { id: 'run', title: 'Running...', component: MigrationRunner },
  { id: 'results', title: 'Done', component: MigrationDone }
];

export const PublishBrawlPinsPage = () => {
  const commonProps = useMemo(() => ({
    migrationSteps: brawlPinPublishSteps,
    modules: brawlPinPublishModules,
    runOrder: brawlPinPublishRunOrder,
    runMigrationTitle: 'Publish',
    getMainTitle: (sourceEnvName: string, sourceCatalog: string, targetEnvName: string, targetCatalog: string) => {
      return `Publish Brawl Pin Rules into ${targetCatalog}`;
    }
  }), []);

  return (<>
    <MigrationFramework {...commonProps} />
  </>);
}

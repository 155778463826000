import { Box, Button, Checkbox, CircularProgress, Grid, Tooltip } from '@material-ui/core';
import { useCallback, useEffect, useState } from 'react';
import MaterialTable from '../../../../components/MaterialTable';
import type { ApiError } from '../../../../services/api';
import { CatalogMigrationService } from '../../../../services/catalog-migration';
import { pushAppNotification } from '../../../../shared/hooks/useAppNotification';
import type { Shop, ShopVersion } from '../../../shops/shop';
import type { MigrationCommonProps } from '../../migrations';
import { isHidden } from "../../../../pages/CatalogMigration/types";
import { UserService } from "../../../../services/user";
import { Lock, LockOpen } from "@material-ui/icons";
import _ from "lodash";
import { usePushNotification } from "../../../../contexts/AppNotificationContext";

export const ShopSelector = (props: MigrationCommonProps) => {
  const { sourceData, onSourceChange, onPrevStep, onNextStep } = props;

  const [selectedShops, setSelectedShops] = useState<Shop[]>([]);
  const [selectedShop, setSelectedShop] = useState<Shop | null>(null);
  const [shopVersions, setShopVersions] = useState<{ [key: string]: { all: ShopVersion[], selected: ShopVersion[] } }>({});
  const pushNotification = usePushNotification();

  useEffect(() => {
    if (!sourceData.shops) {
      CatalogMigrationService.getShops(sourceData.env).then(shops => onSourceChange({ ...sourceData, shops }))
        .catch((e: ApiError) => pushAppNotification({ type: 'error', message: `Failed to load shops. ${e.message}` }));
    }
  }, [sourceData, onSourceChange]);

  const onPrevClick = useCallback(() => {
    if (selectedShop) {
      const index = selectedShops.findIndex(v => v.id === selectedShop.id);
      if (index > 0) {
        setSelectedShop(selectedShops[index - 1]);
      } else {
        setSelectedShop(null);
      }
    } else {
      onPrevStep();
    }
  }, [onPrevStep, selectedShop, selectedShops]);

  const onNextClick = useCallback(() => {
    const index = selectedShop ? selectedShops.findIndex(v => v.id === selectedShop.id) : -1;
    if (index < selectedShops.length - 1) {
      setSelectedShop(selectedShops[index + 1]);
    } else {
      const allShopVersions: ShopVersion[] = [];
      for (const v of Object.values(shopVersions)) {
        for (const version of v.selected) {
          allShopVersions.push(version);
        }
      }
      onSourceChange({ ...sourceData, shopVersions: allShopVersions });
      onNextStep();
    }
  }, [onNextStep, onSourceChange, selectedShop, selectedShops, shopVersions, sourceData]);

  const onHideShop = (shop: Shop) => {
    CatalogMigrationService.createMigrationHiddenItem({ type: 'shop', id: shop.id, })
      .then(hiddenItem => {
        setSelectedShops(selectedShops.filter(s => s.id !== shop.id));
        props.setHiddenItems(props.hiddenItems.concat([hiddenItem]));
      })
      .catch(err => pushNotification({type: 'error', message: `Failed to hide item: ${err.message}`}));
  };

  const onUnhideShop = (shop: Shop) => {
    const hiddenItem = { type: 'shop', id: shop.id, };
    CatalogMigrationService.deleteMigrationHiddenItem(hiddenItem)
      .then(() => props.setHiddenItems(props.hiddenItems.filter(item => !_.isEqual(hiddenItem, item))))
      .catch(err => pushNotification({type: 'error', message: `Failed to unhide item: ${err.message}`}));
  };

  if (!sourceData.shops) {
    return (
      <Box textAlign="center">
        <CircularProgress />
      </Box>
    );
  }

  return (<>
    {selectedShop ? (
      <ShopVersionSelector
        shop={selectedShop}
        shopVersions={shopVersions[selectedShop.id]?.all}
        onUpdateShopVersions={versions => setShopVersions({ ...shopVersions, [selectedShop.id]: { all: versions, selected: [] } })}
        selected={shopVersions[selectedShop?.id]?.selected || []}
        onUpdateSelected={selected => setShopVersions({ ...shopVersions, [selectedShop.id]: { ...shopVersions[selectedShop.id], selected } })}
        {...props}
      />
    ) : (
      <MaterialTable<Shop>
        title=""
        data={sourceData.shops}
        columns={[
          {
            title: '',
            cellStyle: { width: 1 },
            render(item) {
              return !isHidden('shop', item.id, props.hiddenItems) ? <Checkbox
                style={{ padding: 0 }}
                onClick={() => setSelectedShops(selectedShops.includes(item) ? selectedShops.filter(i => i !== item) : selectedShops.concat([item]))}
                checked={selectedShops.includes(item)}
              /> :
              <Tooltip title='Migration item is hidden'>
                <Lock color='disabled' />
              </Tooltip>;
            }
          },
          {
            field: 'name',
            title: 'Name',
            defaultSort: 'asc'
          }
        ]}
        menuActions={(shop) => UserService.canCreate('migrationConfig') ? [{
          type: 'button',
          icon: isHidden('shop', shop.id, props.hiddenItems) ? LockOpen : Lock,
          label: isHidden('shop', shop.id, props.hiddenItems) ? 'Unhide' : 'Hide',
          onClick: isHidden('shop', shop.id, props.hiddenItems) ? onUnhideShop : onHideShop,
        }] : []}
        options={{
          selection: false,
          showTextRowsSelected: false,
          actionsColumnIndex: 2,
          selectionProps: (row: Shop) => ({
            disabled: isHidden('shop', row.id, props.hiddenItems),
          }),
        }}
        onSelectionChange={setSelectedShops}
      />
    )}

    <Box mt={2}>
      <Grid container spacing={1}>
        <Grid item xs="auto">
          <Button variant="outlined" onClick={onPrevClick}>
            Prev
          </Button>
        </Grid>
        <Grid item xs>
          <Button
            variant="contained"
            color="primary"
            onClick={onNextClick}
          >
            Next
          </Button>
        </Grid>
      </Grid>
    </Box>
  </>)
}

interface ShopVersionSelectorProps extends MigrationCommonProps {
  shop: Shop;
  shopVersions?: ShopVersion[];
  selected: ShopVersion[];
  onUpdateShopVersions: (shopVersions: ShopVersion[]) => void;
  onUpdateSelected: (shopVersions: ShopVersion[]) => void;
}

const ShopVersionSelector = (props: ShopVersionSelectorProps) => {
  const { shopVersions, onUpdateShopVersions, sourceData, shop } = props;
  const pushNotification = usePushNotification();

  const onHideShopVersion = (shopVersion: ShopVersion) => {
    CatalogMigrationService.createMigrationHiddenItem({ type: 'shopVersion', id: shopVersion.id, })
      .then(hiddenItem => {
        if (shopVersions) {
          props.onUpdateSelected(shopVersions.filter(sv => sv.id !== shopVersion.id));
        }
        props.setHiddenItems(props.hiddenItems.concat([hiddenItem]));
      })
      .catch(err => pushNotification({type: 'error', message: `Failed to hide item: ${err.message}`}));
  };

  const onUnhideShopVersion = (shopVersion: ShopVersion) => {
    const hiddenItem = { type: 'shopVersion', id: shopVersion.id, };
    CatalogMigrationService.deleteMigrationHiddenItem(hiddenItem)
      .then(() => props.setHiddenItems(props.hiddenItems.filter(item => !_.isEqual(hiddenItem, item))))
      .catch(err => pushNotification({type: 'error', message: `Failed to unhide item: ${err.message}`}));
  };

  useEffect(() => {
    if (!shopVersions) {
      CatalogMigrationService.getShopVersions(sourceData.env, shop.id).then(versions => {
        onUpdateShopVersions(versions.filter(v => v.catalogName === sourceData.catalogName))
      }).catch((e: ApiError) => pushAppNotification({ type: 'error', message: `Error loading shop versions. ${e.message}` }));
    }
  }, [shopVersions, sourceData, shop, onUpdateShopVersions]);

  if (!shopVersions) {
    return (
      <Box textAlign="center">
        <CircularProgress />
      </Box>
    );
  }

  return (
    <MaterialTable<ShopVersion>
      title={`${shop.name} - ${sourceData.catalogName}`}
      data={shopVersions}
      columns={[
        {
          title: '',
          cellStyle: { width: 1 },
          render(item) {
            return !isHidden('shopVersion', item.id, props.hiddenItems) ? <Checkbox
              style={{ padding: 0 }}
              onClick={() => props.onUpdateSelected(props.selected.includes(item) ? props.selected.filter(i => i !== item) : props.selected.concat([item]))}
              checked={props.selected.includes(item)}
            /> :
            <Tooltip title='Migration item is hidden'>
              <Lock color='disabled' />
            </Tooltip>;
          }
        },
        { title: 'Name', field: 'name' },
        { title: 'Start Date', field: 'startDate', type: 'datetime', defaultSort: 'desc' }
      ]}
      menuActions={(shop) => UserService.canCreate('migrationConfig') ? [{
        type: 'button',
        icon: isHidden('shopVersion', shop.id, props.hiddenItems) ? LockOpen : Lock,
        label: isHidden('shopVersion', shop.id, props.hiddenItems) ? 'Unhide' : 'Hide',
        onClick: isHidden('shopVersion', shop.id, props.hiddenItems) ? onUnhideShopVersion : onHideShopVersion,
      }] : []}
      options={{
        selection: false,
        showTextRowsSelected: false,
        actionsColumnIndex: 3,
        selectionProps: (row: ShopVersion) => ({
          disabled: isHidden('shopVersion', row.id, props.hiddenItems),
        }),
      }}
      onSelectionChange={props.onUpdateSelected}
    />
  );
}

import _ from "lodash";
import type { Reducer} from "react";
import { useCallback , useReducer } from "react";

/** Add to the back of the queue. */
type Push<TData = unknown> = {
  type: "PUSH",
  data: TData;
}

/** Remove the head of the queue. */
type Drop = {
  type: "DROP"
}

/** Empty the queue. */
type Clear = {
  type: "CLEAR"
}

export type QueueAction<TData = unknown> = Push<TData> | Drop | Clear;

const reducer = <TData = unknown>(state: TData[], action: QueueAction<TData>) => {
  switch (action.type) {
  case "PUSH": return _.concat(state, action.data);
  
  case "DROP": return _.drop(state, 1);
  
  case "CLEAR": return [];
  }
}

/** Create a generic queue reducer. */
export const useQueue = <TData = unknown>() => useReducer<Reducer<TData[], QueueAction<TData>>>(reducer, []);
import { Box, FormControlLabel, Grid, Switch } from '@material-ui/core';
import type { Column, DetailPanel } from 'material-table';
import React, { useMemo, useState } from 'react';
import type { DiffItem } from '../../../../pages/CatalogMigration/types';
import type { MigrationDependencyErrors, MigrationDiffCommonProps, MigrationDiffData } from '../../migrations';
import { DiffsPanel } from './DiffsPanel';
import { isDiffItemHidden } from "../../../../pages/CatalogMigration/types";

export type DiffsPanelsProps<T extends DiffItem> = Omit<MigrationDiffCommonProps, 'onSelectionsChange'> & {
  diffs: MigrationDiffData<T>;
  columns: Column<T>[];
  mainColumn?: number;
  enabledMap?: { [key: string]: boolean };
  selectionMap: { [key: string]: boolean };
  depErrors: MigrationDependencyErrors;
  onSelectionChange: (selectionMap: { [key: string]: boolean }) => void;
  onFixDependencies: (items: T[]) => void;
  detailComponentOverride?: ((rowData: T) => React.ReactNode) | (DetailPanel<T> | ((rowData: T) => DetailPanel<T>))[];
  additionalFilter?: (diffItem: T) => boolean;
}

export const DiffsPanels = <T extends DiffItem>(props: React.PropsWithChildren<DiffsPanelsProps<T>>) => {
  const { diffs, selectionMap } = props;

  const [expandedPanel, setExpandedPanel] = useState(0);
  const [filterSelected, setFilterSelected] = useState(false);
  const [filterErrors, setFilterErrors] = useState(false);
  const [showHidden, setShowHidden] = useState(false);

  const filters = useMemo(() => {
    const filters: ((v: T) => boolean)[] = [];
    if (filterErrors) {
      filters.push(v => Boolean(props.depErrors[v.diffId]));
    }
    if (filterSelected) {
      filters.push(v => selectionMap[v.diffId]);
    }
    if (!showHidden) {
      filters.push(v => !isDiffItemHidden(v, props.hiddenItems));
    }
    if (props.additionalFilter) {
      filters.push(props.additionalFilter);
    }
    return filters;
  }, [filterErrors, filterSelected, showHidden, props.additionalFilter, props.depErrors, props.hiddenItems, selectionMap]);

  const filteredDiffs: MigrationDiffData<T> = useMemo(() => {
    if (filters.length > 0) {
      const filteredDiffs = { ...diffs };
      for (const filter of filters) {
        filteredDiffs.added = filteredDiffs.added.filter(filter);
        filteredDiffs.changed = filteredDiffs.changed.filter(filter);
        filteredDiffs.removed = filteredDiffs.removed.filter(filter);
      }
      return filteredDiffs;
    }

    return diffs;
  }, [diffs, filters]);

  return (<>
    <Box mb={2}>
      <Grid container alignItems="center" spacing={2}>
        <Grid item xs="auto">
          <FormControlLabel
            control={
              <Switch
                checked={filterErrors}
                onChange={event => setFilterErrors(event.target.checked)}
                color="primary"
              />
            }
            label="Errors only"
          />
        </Grid>
        <Grid item xs="auto">
          <FormControlLabel
            control={
              <Switch
                checked={filterSelected}
                onChange={event => setFilterSelected(event.target.checked)}
                color="primary"
              />
            }
            label="Selected only"
          />
        </Grid>
        <Grid item xs="auto">
          <FormControlLabel
            control={
              <Switch
                checked={showHidden}
                onChange={event => setShowHidden(event.target.checked)}
                color="primary"
              />
            }
            label="Show hidden"
          />
        </Grid>
        {props.children}
      </Grid>
    </Box>

    <DiffsPanel
      items={filteredDiffs.changed}
      diffType='change'
      expanded={expandedPanel === 1}
      onExpand={() => setExpandedPanel(expandedPanel === 1 ? 0 : 1)}
      detailComponentOverride={props.detailComponentOverride}
      {...props}
      mainColumn={props.mainColumn || 0}
    />
    <DiffsPanel
      items={filteredDiffs.added}
      diffType='add'
      expanded={expandedPanel === 2}
      onExpand={() => setExpandedPanel(expandedPanel === 2 ? 0 : 2)}
      detailComponentOverride={props.detailComponentOverride}
      {...props}
      mainColumn={props.mainColumn || 0}
    />
  </>)
}

/* eslint-disable @typescript-eslint/indent */
import { PaginatedRequest, PaginatedResponse } from "../../services/model/pagination";
import { PlayerChallengeProgressInfo } from "./player-season-challenges-types";
import { api } from "../../services/api";

export const getPlayerChallengeProgressPage = async (playerExternalId: string,
                                                     seasonNumber: number,
                                                     request: PaginatedRequest): Promise<PaginatedResponse<PlayerChallengeProgressInfo>> => {
  return api.post<PaginatedResponse<PlayerChallengeProgressInfo>>({
    url: `/players/${playerExternalId}/challenge-progress/season/${seasonNumber}`,
    body: request,
  });
};

export const updatePlayerChallengeProgress = async (playerExternalId: string,
                                                    challengeInstanceId: string,
                                                    progress: number): Promise<PlayerChallengeProgressInfo> => {
  return api.post<PlayerChallengeProgressInfo>({
    url: `/players/${playerExternalId}/challenge-progress/${challengeInstanceId}`,
    body: {progress,},
  });
};

export const resetPlayerSeasonProgress = async (playerExternalId: string, seasonNumber?: number): Promise<void> => {
  const seasonNumberParam = seasonNumber === undefined || seasonNumber === null ? '' : `/${seasonNumber}`;
  return api.post({
    url: `/players/${playerExternalId}/reset-seasonal-player-path${seasonNumberParam}`,
  });
};

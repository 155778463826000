import { useCallback, useMemo } from "react";
import { JunctionEventInfo } from "../live-events-types";
import { deleteJunctionEventInfo, updateJunctionEventInfo } from "../live-events-api";
import { usePushNotification } from "../../../contexts/AppNotificationContext";
import MaterialTable, { MenuAction } from "../../../components/MaterialTable";
import { Box, Button, CircularProgress, Grid, Link, Tooltip, Typography } from "@material-ui/core";
import { Column } from "material-table";
import { Link as RouterLink } from "react-router-dom";
import { UserService } from "../../../services/user";
import { RouteComponentProps } from "react-router";
import { CheckCircle, CheckCircleOutline } from "@material-ui/icons";
import { green } from "@material-ui/core/colors";
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import { openAdminConfirmationDialog } from "../../../shared/hooks/useAdminConfirmationDialog";

const columns: Column<JunctionEventInfo>[] = [
  {
    title: 'Active',
    render: junctionEventInfo => (
      <Grid container spacing={1} alignItems="center">
        {junctionEventInfo.enabled && (
          <Grid item xs="auto">
            <Tooltip title="Active">
              <CheckCircle style={{ color: green[500] }} />
            </Tooltip>
          </Grid>
        )}
      </Grid>
    )
  },
  {
    title: 'ID',
    field: 'id',
    hidden: true,
  },
  {
    title: 'Name',
    field: 'name',
    render(junctionEventInfo) {
      return <Link component={RouterLink} to={`/live-events/${junctionEventInfo.liveEventsId}/junctions/${junctionEventInfo.id}`}>
        {junctionEventInfo.name}
      </Link>;
    },
  },
  {
    title: 'Potential Rewards',
    field: 'potentialRewards',
    render(junctionEventInfo) {
      const partialRewards = junctionEventInfo.potentialRewards.map(pr => pr.label).join(', ');
      return <Link component={RouterLink} to={`/live-events/${junctionEventInfo.liveEventsId}/junctions/${junctionEventInfo.id}`}>
        {partialRewards}
      </Link>;
    },
  },
  {
    title: 'Objects',
    field: 'junctionObjects',
    render(junctionEventInfo) {
      return <Link component={RouterLink} to={`/live-events/${junctionEventInfo.liveEventsId}/junctions/${junctionEventInfo.id}`}>
        {junctionEventInfo.junctionObjects.length}
      </Link>;
    },
  },
  {
    title: 'NPCs',
    field: 'junctionNpcs',
    render(junctionEventInfo) {
      return <Link component={RouterLink} to={`/live-events/${junctionEventInfo.liveEventsId}/junctions/${junctionEventInfo.id}`}>
        {junctionEventInfo.junctionNpcs.length}
      </Link>;
    },
  },
  {
    title: 'Beams',
    field: 'junctionBeams',
    render(junctionEventInfo) {
      return <Link component={RouterLink} to={`/live-events/${junctionEventInfo.liveEventsId}/junctions/${junctionEventInfo.id}`}>
        {junctionEventInfo.junctionBeams.length}
      </Link>;
    },
  },
];

export interface Props extends RouteComponentProps {
  junctionEventInfos: JunctionEventInfo[];
  liveEventId: string;
  refreshJunctionEventInfos: () => Promise<void>;
}

export const JunctionEventInfosTable = ({history, junctionEventInfos, liveEventId, refreshJunctionEventInfos}: Props) => {
  const pushNotification = usePushNotification();
  const onDelete = useCallback((junctionEventInfo) => {
    return deleteJunctionEventInfo(liveEventId, junctionEventInfo.id)
      .then(() => refreshJunctionEventInfos())
      .catch(err => pushNotification({type: 'error', message: `Failed to delete junction event info: ${err.message}`}));
  }, [liveEventId, pushNotification, refreshJunctionEventInfos]);

  const onEdit = useCallback((junctionEventInfo) => {
    history.push(`/live-events/${junctionEventInfo.liveEventsId}/junctions/${junctionEventInfo.id}`);
  }, [history]);

  const onMakeActive = useCallback((junctionEventInfo: JunctionEventInfo) => {
    openAdminConfirmationDialog({
      title: `'${junctionEventInfo.name}' is an inactive Junction Event.  Make it active?`,
      action: 'OK',
      onConfirm: async () => {
        junctionEventInfo.enabled = true;
        try {
          await updateJunctionEventInfo(junctionEventInfo.liveEventsId, junctionEventInfo);
          pushNotification({type: 'success', message: `'${junctionEventInfo.name}' is active`})
        } catch (e) {
          pushNotification({type: 'error', message: `Failed to make active`})
        } finally {
          refreshJunctionEventInfos();
        }
      },
    });
  }, [pushNotification, refreshJunctionEventInfos]);

  const onMakeInactive = useCallback((junctionEventInfo: JunctionEventInfo) => {
    openAdminConfirmationDialog({
      title: `'${junctionEventInfo.name}' is an active Junction Event.  Make it inactive?`,
      action: 'OK',
      onConfirm: async () => {
        junctionEventInfo.enabled = false;
        try {
          await updateJunctionEventInfo(junctionEventInfo.liveEventsId, junctionEventInfo);
          pushNotification({type: 'success', message: `'${junctionEventInfo.name}' is inactive`})
        } catch (e) {
          pushNotification({type: 'error', message: `Failed to make inactive`})
        } finally {
          refreshJunctionEventInfos();
        }
      },
    });
  }, [pushNotification, refreshJunctionEventInfos]);

  const getTableMenuActions = useMemo(() => (junctionEventInfo: JunctionEventInfo): MenuAction<JunctionEventInfo>[] => {
    const actions: MenuAction<JunctionEventInfo>[] = [];
    if (UserService.canUpdate('liveEvents')) {
      actions.push({ type: 'button', icon: EditIcon, label: 'Edit', onClick: onEdit });

      if (junctionEventInfo.enabled) {
        actions.push({ type: 'button', icon: CheckCircleOutline, label: 'Make Inactive', onClick: onMakeInactive });
      } else {
        actions.push({ type: 'button', icon: CheckCircle, label: 'Make Active', onClick: onMakeActive });
      }
    }
    if (UserService.canDelete('liveEvents')) {
      if (actions.length > 0) {
        actions.push({ type: 'divider' });
      }
      actions.push({ type: 'button', icon: DeleteIcon, label: 'Delete', onClick: onDelete });
    }

    return actions;
  }, [onDelete, onEdit, onMakeActive, onMakeInactive]);

  if (!junctionEventInfos) {
    return <Box textAlign="center">
      <CircularProgress/>
    </Box>;
  }

  return <>
    <Box>
      <Grid container>
        <Grid item xs>
          <Typography variant="h6">
            Junction Events
          </Typography>
        </Grid>
        {UserService.canCreate('liveEvents') && (
          <Grid item>
            <Button variant="contained" color="primary" onClick={() => history.push(`/live-events/${liveEventId}/junctions/new`)}>
              Add Junction Event
            </Button>
          </Grid>
        )}
      </Grid>
      <br/>
      <MaterialTable<JunctionEventInfo>
        title="Junction Events"
        storageId="junction-event-infos"
        options={{
          paging: false,
          search: false,
          toolbar: false,
          sorting: false,
          showTitle: false
        }}
        columns={columns}
        data={junctionEventInfos}
        menuActions={getTableMenuActions}
      />
    </Box>
  </>;
};

import { Grid } from '@material-ui/core';
import type { Player } from '../players';
import { PlayersService } from "../players";
import { ModerationItem } from "./ModerationItem";

type Props = {
  player: Player;
  setPlayer: (player: Player) => void;
}

export const PlayerModeration = ({player, setPlayer}: Props) => {
  return (
    <Grid container spacing={3} alignItems="flex-end">
      <ModerationItem
        player={player}
        setPlayer={setPlayer}
        language={{
          perform: 'ban',
          pastTense: 'banned',
        }}
        untilGetter={player => player.bannedUntil}
        performUpdate={PlayersService.updatePlayerBan}
      />
      <ModerationItem
        player={player}
        setPlayer={setPlayer}
        language={{
          perform: 'mute',
          pastTense: 'muted',
        }}
        untilGetter={player => player.mutedUntil}
        performUpdate={PlayersService.updatePlayerMute}
      />
    </Grid>
  );
};

import type { Theme } from '@material-ui/core';
import { Box, CircularProgress, Grid, IconButton, ListItemIcon, makeStyles, Menu, MenuItem, Paper, TextField, withTheme } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import PublishIcon from '@material-ui/icons/Publish';
import { useCallback, useMemo, useRef, useState } from 'react';
import { useDrag, useDrop } from 'react-dnd';
import { useDropzone } from 'react-dropzone';
import { useDispatch } from 'react-redux';
import { setAppNotification } from '../../../redux/app/actions';
import type { ApiError } from '../../../services/api';
import type { ShopTabLinkImage } from '../shop';
import { uploadInventoryImage } from '../shopsApi';
import { ShopImageUploadDialog } from './ShopImageUploadDialog';

const useStyles = makeStyles((theme: Theme) => ({
  item: {
    height: '100%',
    position: 'relative',
    backgroundColor: 'rgba(0, 0, 0, 0.2)',
    '&:hover $resizeOverlay': {
      backgroundColor: theme.palette.type === 'dark' ? 'rgba(255, 255, 255, 0.2)' : 'rgba(0, 0, 0, 0.2)'
    }
  },
  itemImage: {
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    backgroundSize: 'contain'
  },
  itemImageOverlay: {
    backgroundColor: theme.palette.type === 'dark' ? 'rgba(0, 0, 0, 0.6)' : 'rgba(255, 255, 255, 0.6)'
  },
  itemImageOverlayText: {
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden'
  },
  itemOverlay: {
    backgroundColor: theme.palette.type === 'dark' ? 'rgba(0, 0, 0, 0.6)' : 'rgba(255, 255, 255, 0.6)',
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    textAlign: 'center'
  },
  resizeOverlay: {
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    width: 10,
    backgroundColor: theme.palette.type === 'dark' ? 'rgba(255, 255, 255, 0)' : 'rgba(0, 0, 0, 0)',
    cursor: 'ew-resize',
    overflow: 'hidden',
    transition: 'background-color 0.5s'
  },
  addButton: {
    borderWidth: 3,
    borderStyle: 'dashed',
    borderColor: theme.palette.secondary.main,
    borderRadius: 10,
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: theme.palette.type === 'dark' ? 'rgba(255, 255, 255, 0.1)' : 'rgba(0, 0, 0, 0.1)'
    }
  }
}));

const IMAGE_SIZE = 295;
const LINK_IMAGE_DRAG_ITEM = "LINK_IMAGE_DRAG_ITEM";

export interface EditorItem {
  key: string;
  linkImage: ShopTabLinkImage;
  isNewImageItem: boolean;
}

interface LinkImageItemProps {
  index: number;
  item: EditorItem;
  // onTabItemChange: (tabItem: ShopTabItem) => void;
  onRemove: () => void;
  moveItem: (from: number) => boolean;
  onUpdatedItem: (image: ShopTabLinkImage) => void;
}

export const LinkImageItem = withTheme((props: LinkImageItemProps) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const dndHandleRef = useRef(null);
  const dndDropCooldown = useRef<boolean>(false);

  const menuAnchor = useRef(null);

  const { item, onRemove, onUpdatedItem } = props;

  const [titleText, setTitleText] = useState(item.linkImage.titleText);
  const [imageUrl, setImageUrl] = useState(item.linkImage.imageUrl);
  const [isNewImageItem, setIsNewImageItem] = useState(item.isNewImageItem);
  const [menuOpen, setMenuOpen] = useState(false);
  const [imageUploadDialogOpen, setImageUploadDialogOpen] = useState(false);
  const [isUploadingImage, setUploadingImage] = useState(false);

  const removeItem = useCallback(() => {
    setMenuOpen(false);
    onRemove();
  }, [onRemove]);

  const uploadImage = useCallback((file: File) => {
    if (!isUploadingImage) {
      setUploadingImage(true);
      uploadInventoryImage(file)
        .then(url => {
          setImageUrl(url);
          onUpdatedItem({ imageUrl: url, titleText });
        }).catch((e: ApiError) => {
          dispatch(setAppNotification({ type: 'error', message: `Error uploading item image. ${e.message}` }));
        }).finally(() => {
          setUploadingImage(false);
        });
    }
  }, [isUploadingImage, titleText, dispatch, onUpdatedItem]);

  const updateTitle = useCallback((title: string) => {
    if (isNewImageItem)
      return;
    setTitleText(title);
    onUpdatedItem({ imageUrl, titleText: title });
  }, [imageUrl, isNewImageItem, onUpdatedItem]);

  const onImageFileDrop = useCallback(acceptedFiles => {
    if (acceptedFiles.length === 1) {
      uploadImage(acceptedFiles[0]);
    }
  }, [uploadImage]);
  const { getRootProps, isDragActive } = useDropzone({ onDrop: onImageFileDrop, noClick: true, accept: 'image/*', multiple: false, maxFiles: 1, noKeyboard: true });

  // DnD movement
  const [, dndDrop] = useDrop({
    accept: LINK_IMAGE_DRAG_ITEM,
    hover(item: { type: string, index: number }) {
      if (!dndHandleRef.current) {
        return;
      }
      if (item.index === props.index || dndDropCooldown.current) {
        return;
      }

      // Temporary fix for DnD position flicker. Prevent items for swapping positions faster than once per second.
      dndDropCooldown.current = true;
      window.setTimeout(() => dndDropCooldown.current = false, 1000);

      if (props.moveItem(item.index)) {
        item.index = props.index;
      }
    }
  });

  const [{ isDragging }, dndDrag] = useDrag({
    item: { type: LINK_IMAGE_DRAG_ITEM, index: props.index },
    canDrag: () => !props.item.isNewImageItem,
    collect: monitor => ({
      isDragging: monitor.isDragging()
    })
  });

  dndDrag(dndDrop(dndHandleRef));

  const backgroundImage = useMemo(() => {
    return imageUrl ? `url(${imageUrl})` : undefined;
  }, [imageUrl]);

  return (<>
    <Paper elevation={0} className={classes.item} {...getRootProps()}>
      <Paper style={{ opacity: isDragging ? 0 : 1 }}>
        <Box>
          {!isNewImageItem &&
            <div ref={dndHandleRef} className={classes.itemImage} style={{ backgroundImage, height: IMAGE_SIZE, width: IMAGE_SIZE }} />
          }
          {isNewImageItem &&
            <Box textAlign="center" height={IMAGE_SIZE} width={IMAGE_SIZE} onClick={() => setImageUploadDialogOpen(true)}>
              <Grid container alignItems="center" style={{ height: '100%' }}>
                <Grid item xs={12}>
                  <Box p={3} className={classes.addButton}>
                    <AddIcon fontSize="large" />
                    <Box>Add Image</Box>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          }
          <Box p={1}>
            <Grid
              container
              spacing={1}
              alignItems="center"
              style={{
                justifyContent: 'right',
              }}
            >
              <Grid item xs>
                <TextField
                  value={titleText || ''}
                  onChange={event => updateTitle(event.target.value)}
                  fullWidth
                  label={'Title Text'}
                />
              </Grid>
              {!isNewImageItem &&
                <Grid item xs="auto">
                  <IconButton ref={menuAnchor} onClick={() => setMenuOpen(true)}>
                    <MoreVertIcon />
                  </IconButton>
                </Grid>
              }
            </Grid>
          </Box>
        </Box>

        {(isDragActive || isUploadingImage) && (
          <Box className={classes.itemOverlay}>
            <Grid container alignItems="center" style={{ height: '100%' }}>
              <Grid item xs={12}>
                {isUploadingImage ? (
                  <CircularProgress />
                ) : (
                  <>Upload image</>
                )}
              </Grid>
            </Grid>
          </Box>
        )}
      </Paper>
    </Paper>

    {menuOpen && (
      <Menu onClose={() => setMenuOpen(false)} anchorEl={menuAnchor.current} open>
        <MenuItem onClick={() => { setMenuOpen(false); setImageUploadDialogOpen(true); }}>
          <ListItemIcon>
            <PublishIcon />
          </ListItemIcon>
          Upload image
        </MenuItem>
        <MenuItem onClick={() => removeItem()}>
          <ListItemIcon>
            <DeleteIcon />
          </ListItemIcon>
          Remove
        </MenuItem>
      </Menu>
    )}

    {imageUploadDialogOpen && (
      <ShopImageUploadDialog
        onClose={() => setImageUploadDialogOpen(false)}
        onUpload={uploadImage}
      />
    )}
  </>);
});

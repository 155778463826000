import { Box, IconButton, Paper, Snackbar, useTheme } from "@material-ui/core";
import CloseIcon from '@material-ui/icons/Close';
import { useState } from "react";
import type { Notification } from "../contexts/AppNotificationContext";

type AppSnackbarProps = {
  notification: Notification;
  onExited: () => void;
}

export const AppSnackbar = (props: AppSnackbarProps) => {
  const { notification: { type, message }, onExited } = props;
  const theme = useTheme();
  const [isOpen, setIsOpen] = useState(true);

  return (
    <Snackbar
      open={isOpen}
      onClose={(_event, reason) => {
        if (reason === 'clickaway') return;
        setIsOpen(false);
      }}
      onExited={onExited}
      autoHideDuration={type !== 'error' ? 2 * 1000 : null}
      >
      <Paper
        elevation={6}
        style={{
          backgroundColor: theme.palette[type].main,
        }}
        >
        <Box
          py={2}
          px={3}
          fontWeight={500}
          color={theme.palette.common.white}
          >
          {message}
          <IconButton 
            onClick={() => setIsOpen(false)}
            size="small" 
            color="inherit" 
            style={{
              marginLeft: theme.spacing(2)
            }}
            >
            <CloseIcon fontSize="small" />
          </IconButton>
        </Box>
      </Paper>
    </Snackbar>
  );
}
import type { ApiError} from '../../services/api';
import { api } from '../../services/api';
import { ItemInstance, ItemGrantResponse } from '../../services/model/item-instance';
import type { GumballHistoryEntry } from "../gumball-history/gumball-history";

export class PlayerItemsService {
  static async getPlayerItems(playerId: string): Promise<ItemInstance[]> {
    return api.get<any[]>({ url: `/players/${playerId}/items` })
      .then(items => items.map(v => new ItemInstance(v)))
      .catch((e: ApiError) => {
        e.message = `Failed to get player items. ${e.message}`;
        throw e;
      });
  }

  static async getPlayerItemsEquipped(playerId: string): Promise<ItemInstance[]> {
    return api.get<any[]>({ url: `/players/${playerId}/items-equipped` })
      .then(items => items.map(v => new ItemInstance(v)))
      .catch((e: ApiError) => {
        e.message = `Failed to get player items. ${e.message}`;
        throw e;
      });
  }

  static async grantItem(playerId: string, catalogName: string, itemId: string): Promise<ItemGrantResponse> {
    const body = {
      catalogName,
      itemIds: [itemId]
    };
    return api.post({ url: `/players/${playerId}/items`, body })
      .then(json => new ItemGrantResponse(json))
      .catch((e: ApiError) => {
        e.message = `Failed to grant item. ${e.message}`;
        throw e;
      });
  }

  static async revokeItem(playerId: string, itemId: string): Promise<null> {
    return api.delete({ url: `/players/${playerId}/items/${itemId}` })
      .catch((e: ApiError) => {
        e.message = `Failed to revoke item. ${e.message}`;
        throw e;
      });
  }

  static async getPlayerGumballHistory(playerId: string): Promise<GumballHistoryEntry[]> {
    return api.get({ url: `/players/${playerId}/gumball-history`})
      .catch((e: ApiError) => {
        e.message = `Failed to get gumball history. ${e.message}`;
        throw e;
      });
  }}
import { api } from '../../services/api';
import { BranchList, Branches, LokaliseKey, LokaliseFileListResponse, CreateKeysResponse } from "./lokalise-types";

export const getBranches = async () => {
  return api.get<BranchList>({ url: `/lokalise/projects/branches` });
};

export const getBranchOverrides = async () => {
  return api.get<Branches>({ url: `/lokalise/branch-overrides` });
};

export const setLiveBranch = async (branchName: string) => {
  return api.post<void>({ url: '/lokalise/branch-overrides/live', query: { branchName }});
};

export const setCanaryBranch = async (branchName: string) => {
  return api.post<void>({ url: '/lokalise/branch-overrides/canary', query: { branchName }});
};

export const removeLiveBranch = async () => {
  return api.delete({ url: '/lokalise/branch-overrides/live' });
};

export const removeCanaryBranch = async () => {
  return api.delete({ url: '/lokalise/branch-overrides/canary' });
};

export const addPlayerBranchOverrides = async (branchName: string, playerEmails: string[]) => {
  return api.post({ url: '/lokalise/branch-overrides/player', query: { branchName, playerEmails } });
};

export const removePlayerBranchOverrides = async (playerEmails: string[]) => {
  return api.delete({ url: '/lokalise/branch-overrides/player', query: { playerEmails } });
};

export const syncBranch = async (branchName: string) => {
  return api.post({ url: `/lokalise/projects/synchronize/${branchName}` });
};

export const createKeys = async (branchName: string, keys: LokaliseKey[]) => {
  return api.post<CreateKeysResponse>({ url: `/lokalise/projects/${branchName}/keys`, body: { keys, } });
};

export const getFileList = async (branchName: string) => {
  return api.get<LokaliseFileListResponse>({ url: `/lokalise/projects/${branchName}/files` });
};

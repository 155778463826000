import { Box, Button, Card, CardContent, CircularProgress, Grid, Link, TextField, Typography } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import type { Column } from 'material-table';
import { Component } from 'react';
import type { ConnectedProps } from 'react-redux';
import { connect } from 'react-redux';
import MaterialTable from '../../components/MaterialTable';
import { getCatalogItemsAsync } from '../../redux/catalog/items/actions';
import { loadCurrenciesAsync } from '../../redux/currencies/actions';
import type { RootState } from '../../redux/reducers';
import type { DropTable } from '../../services/drop-tables';
import { ItemDefinition } from '../../services/item-definitions';
import { Store, StoreItem } from '../../services/stores';
import { RouterLink } from '../../types';
import ItemSelectDialog from '../ItemDefinition/ItemSelectDialog';
import PriceField from './PriceField';

const mapStateToProps = (state: RootState) => ({
  currencyList: state.currencies.list,
  catalogItems: state.catalog.items.items
});
const mapDispatch = {
  requestLoadCurrencies: loadCurrenciesAsync.request,
  requestCatalogItems: getCatalogItemsAsync.request
};
const connector = connect(mapStateToProps, mapDispatch);

interface StoreTableRow {
  itemId: string;
  itemDisplayName: string;
  itemClass: string;
  prices: {
    code: string;
    name: string;
    price: string;
  }[];
  rmPrice: string;
  maxSupply: string;
  maxSupplyError: string;
  bufferSupply: string;
  bufferSupplyError: string;
}

type Props = ConnectedProps<typeof connector> & {
  catalogName: string;
  store: Store;
  onSave: (store: Store) => void;
  readOnly?: boolean;
};

interface State {
  contentsDialogOpen: boolean;
  items: StoreTableRow[];
  isSubmitting: boolean;
}

class StoreForm extends Component<Props, State> {
  private columns: Column<StoreTableRow>[];

  constructor(props: Props) {
    super(props);

    this.state = {
      contentsDialogOpen: false,
      items: this.getTableItems(),
      isSubmitting: false
    };

    this.columns = this.getTableColumns();
  }

  componentDidMount() {
    this.props.requestLoadCurrencies();
    if (!this.props.catalogItems.isLoading && !this.props.catalogItems.data) {
      this.props.requestCatalogItems();
    }
  }

  componentDidUpdate(prevProps: Props) {
    if (prevProps.store !== this.props.store) {
      this.setState({ items: this.getTableItems(), isSubmitting: false });
    } else if (prevProps.currencyList !== this.props.currencyList) {
      this.columns = this.getTableColumns();
      this.setState({ items: this.getTableItems() });
    } else if (this.props.catalogItems.data !== prevProps.catalogItems.data) {
      this.setState({ items: this.getTableItems() });
    }

    if (!this.props.catalogItems.isLoading && !this.props.catalogItems.data) {
      this.props.requestCatalogItems();
    }
  }

  getTableItems(): StoreTableRow[] {
    if (!this.props.store || this.props.currencyList.isEmpty() || !this.props.catalogItems.data) {
      return [];
    }

    const itemsMap: { [key: string]: ItemDefinition; } = {};
    this.props.catalogItems.data.forEach(item => itemsMap[item.itemId] = item);

    return this.props.store.items.map(storeItem => {
      const item = itemsMap[storeItem.itemId];

      return {
        itemId: storeItem.itemId,
        itemDisplayName: item ? item.displayName : '',
        itemClass: item ? item.itemClass : '',
        prices: this.props.currencyList.getCurrencies().map(currency => ({
          code: currency.code,
          name: currency.name,
          price: typeof storeItem.virtualCurrencyPrices[currency.code] === 'number' ? storeItem.virtualCurrencyPrices[currency.code].toString() : ''
        })),
        rmPrice: storeItem.rmPrice ? storeItem.rmPrice.toString() : '',
        maxSupply: storeItem.maxSupply === null ? '' : storeItem.maxSupply.toString(),
        maxSupplyError: '',
        bufferSupply: storeItem.bufferSupply === null ? '' : storeItem.bufferSupply.toString(),
        bufferSupplyError: ''
      };
    });
  }

  getTableColumns(): Column<StoreTableRow>[] {
    const columns: Column<StoreTableRow>[] = [
      {
        title: 'ID',
        field: 'itemId',
        sorting: true,
        filtering: true,
        render: row => (
          <Link component={RouterLink} to={`/catalogs/${this.props.catalogName}/items/${row.itemId}`}>
            {row.itemId}
          </Link>
        )
      },
      { title: 'Display name', field: 'itemDisplayName', sorting: true, filtering: true },
      { title: 'Item class', field: 'itemClass', sorting: true, defaultSort: 'asc', filtering: true }
    ];

    this.props.currencyList.getCurrencies().forEach((currency, index) => {
      columns.push({
        title: `${currency.code} price`,
        sorting: false,
        filtering: false,
        cellStyle: {
          width: '150px'
        },
        render: item => (
          <PriceField value={item.prices[index].price} onChange={value => item.prices[index].price = value} readOnly={this.props.readOnly} integer />
        )
      });
    });

    columns.push({
      title: 'RM price',
      sorting: false,
      filtering: false,
      cellStyle: {
        width: '150px'
      },
      render: item => (
        <PriceField value={item.rmPrice} onChange={value => item.rmPrice = value} readOnly={this.props.readOnly} />
      )
    });

    // Performance? What performance?
    columns.push({
      title: 'Max supply',
      sorting: false,
      filtering: false,
      cellStyle: {
        width: '100px'
      },
      render: item => (
        <TextField
          value={item.maxSupply}
          onChange={event => this.onMaxSupplyChange(item, event.target.value)}
          error={!!item.maxSupplyError}
          helperText={item.maxSupplyError}
        />
      )
    });

    columns.push({
      title: 'Buffer supply',
      sorting: false,
      filtering: false,
      cellStyle: {
        width: '100px'
      },
      render: item => (
        <TextField
          value={item.bufferSupply}
          onChange={event => this.onBufferSupplyChange(item, event.target.value)}
          error={!!item.bufferSupplyError}
          helperText={item.bufferSupplyError}
        />
      )
    });

    return columns;
  }

  onContentsRemove(itemId: string) {
    const index = this.state.items.findIndex(v => v.itemId === itemId);
    if (index > -1) {
      const items = this.state.items.slice();
      items.splice(index, 1);
      this.setState({ items });
    }
  }

  onContentsAdd(items: (ItemDefinition | DropTable)[]) {
    const stateItems = this.state.items.slice();
    items.forEach(item => {
      if (item instanceof ItemDefinition) {
        stateItems.push({
          itemId: item.itemId,
          itemDisplayName: item.displayName,
          itemClass: item.itemClass,
          prices: this.props.currencyList.getCurrencies().map(currency => ({
            code: currency.code,
            name: currency.name,
            price: ''
          })),
          rmPrice: '',
          maxSupply: '',
          maxSupplyError: '',
          bufferSupply: '',
          bufferSupplyError: ''
        });
      }
    });
    this.setState({ items: stateItems });
  }

  onMaxSupplyChange = (item: StoreTableRow, value: string) => {
    const index = this.state.items.indexOf(item);
    if (index < 0) {
      return;
    }

    item = { ...item };
    item.maxSupply = value;
    let error = '';
    if (value) {
      const maxSupply = parseInt(value);
      if (isNaN(maxSupply) || maxSupply < 0) {
        error = 'Must be a positive integer';
      }
    }
    item.maxSupplyError = error;

    const items = this.state.items.slice();
    items[index] = item;
    this.setState({ items });
  }

  onBufferSupplyChange = (item: StoreTableRow, value: string) => {
    const index = this.state.items.indexOf(item);
    if (index < 0) {
      return;
    }

    item = { ...item };
    item.bufferSupply = value;
    let error = '';
    if (value) {
      const bufferSupply = parseInt(value);
      if (isNaN(bufferSupply) || bufferSupply < 0) {
        error = 'Must be a positive integer';
      }
    }
    item.bufferSupplyError = error;

    const items = this.state.items.slice();
    items[index] = item;
    this.setState({ items });
  }

  onSubmit = () => {
    const store = new Store();
    store.storeId = this.props.store.storeId;
    store.items = this.state.items.map(itemValues => {
      const item = new StoreItem();
      item.itemId = itemValues.itemId;
      itemValues.prices.forEach(priceItem => {
        const price = parseInt(priceItem.price);
        if (!isNaN(price)) {
          item.virtualCurrencyPrices[priceItem.code] = price;
        }
      });
      const rmPrice = parseFloat(itemValues.rmPrice);
      item.rmPrice = isNaN(rmPrice) ? null : parseFloat(rmPrice.toFixed(2));

      const maxSupply = parseInt(itemValues.maxSupply);
      if (!isNaN(maxSupply) && maxSupply >= 0) {
        item.maxSupply = maxSupply;
      }

      const bufferSupply = parseInt(itemValues.bufferSupply);
      if (!isNaN(bufferSupply) && bufferSupply >= 0) {
        item.bufferSupply = bufferSupply;
      }

      return item;
    });

    this.setState({ isSubmitting: true });

    this.props.onSave(store);
  };

  render() {
    if (this.props.currencyList.isEmpty() || !this.props.catalogItems.data) {
      return (
        <Box textAlign="center">
          <CircularProgress />
        </Box>
      );
    }

    const readOnly = this.props.readOnly;
    return (<>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Card>
            <Box p={2}>
              <Grid container spacing={2} alignItems="center">
                <Grid item>
                  <Typography variant="h5">
                    Contents
                  </Typography>
                </Grid>
                {!readOnly && (
                  <Grid item>
                    <Button
                      variant="contained"
                      color="primary"
                      disabled={this.state.isSubmitting}
                      onClick={() => this.setState({ contentsDialogOpen: true })}
                    >Add</Button>
                  </Grid>
                )}
              </Grid>
            </Box>
            <CardContent>
              <MaterialTable
                columns={this.columns}
                data={this.state.items}
                actions={readOnly ? undefined : [
                  { icon: () => <DeleteIcon />, onClick: (event, data) => !Array.isArray(data) && this.onContentsRemove(data.itemId) }
                ]}
                components={{
                  Container: Box
                }}
                options={{
                  toolbar: false,
                  search: false,
                  paging: true,
                  filtering: true
                }}
              />

              {this.state.contentsDialogOpen && (
                <ItemSelectDialog
                  multi
                  addedItems={Object.values(this.state.items).map(v => v.itemId)}
                  onSelect={items => this.onContentsAdd(items)}
                  onClose={() => this.setState({ contentsDialogOpen: false })}
                />)}
            </CardContent>
          </Card>
        </Grid>
      </Grid>
      <Box pt={2}>
        <Button color="primary" variant="contained" onClick={this.onSubmit} disabled={this.state.isSubmitting}>
          {this.state.isSubmitting ? (
            <CircularProgress size={25} />
          ) : 'Save'}
        </Button>
      </Box>
    </>);
  }
}

export default connector(StoreForm);
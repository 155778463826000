import type {BulkTag, PlayerTag} from "./accounts";
import {api} from "../../services/api";

export const getTags = () => {
  return api.get<PlayerTag[]>({ url: '/players/accounts/tags'});
}

export const createTagAsync = async (data: PlayerTag) => {
  return api.post<PlayerTag>( { url: '/players/accounts/tags', body: data });
}

export const updateTagAsync = async (data: PlayerTag) => {
  return api.put<PlayerTag>({ url: '/players/accounts/tags', body: data });
}

export const deleteTagAsync = async (tagId: string) => {
  return api.delete({ url: `/players/accounts/tags/${tagId}` });
}

export const bulkTagAction = (data: BulkTag) => {
  return api.post<BulkTag>({ url: '/players/accounts/tags/bulk', body: data });
}

export const addTagToPlayerAsync = async (id: string, tagId: string) => {
  return api.put<PlayerTag>({ url: `/players/accounts/${id}/tags/${tagId}`})
}

export const removeTagFromPlayer = (id: string, tagName: string) => {
  return api.delete({ url: `/players/accounts/${id}/tags/${tagName}`})
}
import { BlankoDnaDiff } from '../../../../pages/CatalogMigration/types';
import { CatalogMigrationService } from '../../../../services/catalog-migration';
import type { ItemDefinition } from '../../../../services/item-definitions';
import type { MigrationModule } from '../../migrations';
import { buildMigrationActions , buildDiffData } from '../../migrations';
import { BlankoDnaDiffs } from './BlankoDnasDiffs';
import { MigrationType } from "../migration-type";

export const dnaMigration: MigrationModule<BlankoDnaDiff> = {
  id: MigrationType.dna,
  displayName: 'DNAs',
  diffComponent: BlankoDnaDiffs,
  crossEnvOnly: true,

  loadData: async (sourceData, targetData, migrationData) => {
    sourceData.blankoDnas = await CatalogMigrationService.getBlankoDnas(sourceData.env, sourceData.catalogName);
    targetData.blankoDnas = await CatalogMigrationService.getBlankoDnas(targetData.env, targetData.catalogName);

    if (!sourceData.catalog) {
      sourceData.catalog = await CatalogMigrationService.getCatalog(sourceData.env, sourceData.catalogName);
    }
    if (!targetData.catalog) {
      targetData.catalog = await CatalogMigrationService.getCatalog(targetData.env, targetData.catalogName);
    }

    const sourceItemsMap: { [key: string]: ItemDefinition } = {};
    (sourceData.catalog.items || []).forEach(item => sourceItemsMap[item.itemId] = item);
    const targetItemsMap: { [key: string]: ItemDefinition } = {};
    (targetData.catalog.items || []).forEach(item => targetItemsMap[item.itemId] = item);

    const sourceDiffs = sourceData.blankoDnas.map(dna => {
      const diff = new BlankoDnaDiff(dna, true);
      const item = sourceItemsMap[dna.Id];
      if (item) {
        const targetItem = targetItemsMap[dna.Id];
        if (!targetItem) diff.existsInTarget = false;
        diff.blankoName = item.displayName;
        diff.imageThumbnailUrl = item.imageThumbnailUrl;
      }
      return diff;
    });
    const targetDiffs = targetData.blankoDnas.map(dna => new BlankoDnaDiff(dna, true));
    migrationData.blankoDnas = buildDiffData(sourceDiffs, targetDiffs);
    return migrationData.blankoDnas;
  },

  runMigration: async (props, setStatus) => {
    const actions = buildMigrationActions(props.migrationData.blankoDnas, props.selections.dna);
    if (actions.add.length + actions.update.length + actions.remove.length < 1) {
      return;
    }

    const sourceDnas = props.sourceData.blankoDnas;
    if (!sourceDnas) {
      throw new Error('Blanko DNAs not loaded.');
    }

    const dnaMap: { [key: string]: any } = {};
    sourceDnas.forEach(dna => dnaMap[`${dna.Id}, ver: ${dna.Version}`] = dna);

    const dnas = actions.add.concat(actions.update).map(id => dnaMap[id]);
    let progress = 0;
    for (const dna of dnas) {
      setStatus('Migrating blanko DNAs and Assets', progress);
      await CatalogMigrationService.migrateBlankoDnas(props.targetData.env, props.targetData.catalogName, [dna]);
      progress += 100.0 / dnas.length;
    }
  }
}

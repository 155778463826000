import { Box, Button, Card, CardContent, CardHeader, Grid } from '@material-ui/core';
import { UserService } from '../../services/user';
import RewardControl from './RewardControl';

interface Props {
  title: string;
  showAddReward?: boolean;
  editMode?: boolean;
  rewards: Reward[];
  onRewardAdd: () => void;
  onRewardDelete: (reward: Reward) => void;
  requiredUpdateTarget: string;
}

const RewardListEditor = (props: Props) => {

  const readOnly: boolean = !props.editMode || !UserService.canUpdate(props.requiredUpdateTarget);

  const cardAction = props.showAddReward ? (
    <Button variant="contained" color="primary" size="small" onClick={props.onRewardAdd}>
      Add Reward
    </Button>
  ) : null;

  return (
    <Card>
      <CardHeader title={props.title} titleTypographyProps={{ variant: 'subtitle1' }} action={cardAction} />
      <CardContent>
        {props.rewards.length === 0 ? <Grid item >No Rewards.</Grid> : null}
        {props.rewards.map((reward: Reward, index: number) => (
          <Grid container key={index}>
            <Box pb={1} width="100%">
              <RewardControl readOnly={readOnly} reward={reward} onRewardDelete={props.onRewardDelete} />
            </Box>
          </Grid>
        ))}
      </CardContent>
    </Card>
  )
}

export default RewardListEditor;

import { Box, CircularProgress, Typography } from '@material-ui/core';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation, useParams, useRouteMatch } from 'react-router';
import { LinkTabs } from '../../../components/LinkTabs';
import { setAppNotification } from '../../../redux/app/actions';
import type { ApiError } from '../../../services/api';
import { ShopPlayerAccess } from '../components/ShopPlayerAccess';
import { ShopTimeline } from '../components/ShopTimeline';
import { ShopVersions } from '../components/ShopVersions';
import type { Shop } from '../shop';
import { getShop } from '../shopsApi';


export const ShopPage = () => {
  const { shopId } = useParams<{ shopId: string }>();
  const history = useHistory();
  const location = useLocation();
  const routeMatch = useRouteMatch();

  const dispatch = useDispatch();

  const [shop, setShop] = useState<Shop | null>(null);

  useEffect(() => {
    getShop(shopId).then(setShop)
      .catch((e: ApiError) => dispatch(setAppNotification({ type: 'error', message: `Failed to load shop. ${e.message}` })));
  }, [dispatch, shopId]);

  if (!shop) {
    return (
      <Box textAlign="center">
        <CircularProgress />
      </Box>
    );
  }

  return (<>
    <Box mb={2}>
      <Typography variant="h6">
        {shop.name}
      </Typography>
    </Box>
    <LinkTabs
      tabs={[
        {
          url: '/timeline',
          label: 'Timeline',
          render() {
            return (
              <ShopTimeline shop={shop} />
            );
          }
        },
        {
          url: '/versions',
          label: 'Versions',
          render() {
            return (
              <ShopVersions
                shop={shop}
              />
            );
          }
        },
        {
          url: '/player-access',
          label: 'Player access',
          render() {
            return (
              <ShopPlayerAccess shop={shop} />
            );
          }
        }
      ]}
      history={history}
      location={location}
      match={routeMatch}
    />
  </>);
}
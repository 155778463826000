import { useState, useCallback, useEffect } from 'react';

import type {
  PlayerSeasonPathResponse} from '../../../services/player-challenges/player-path';
import {
  PlayerPathService
} from '../../../services/player-challenges/player-path';
import { PlayerSeasonPath } from './PlayerSeasonPath';
import { PlayerSeasonChallenges } from './PlayerSeasonChallenges';
import SeasonSelect from '../../../pages/SeasonalData/SeasonSelect';
import SeasonOverview from '../../../pages/SeasonalData/SeasonOverview';
import { Box } from "@material-ui/core";
import { usePushNotification } from '../../../contexts/AppNotificationContext';


interface PlayerSeasonPassProps {
  externalId: string;
}

export const PlayerSeasonPass = ({ externalId }: PlayerSeasonPassProps) => {
  const pushNotification = usePushNotification();
  const [playerSeasonPath, setPlayerSeasonPath] = useState<PlayerSeasonPathResponse>();
  const [selectedSeason, setSelectedSeason] = useState<number>();

  const getData = useCallback(async () => {
    const data = (selectedSeason != null ?
      await PlayerPathService.getPlayerPathWithSeasonNumber(externalId, selectedSeason) :
      await PlayerPathService.getPlayerPath(externalId)
    );
    setPlayerSeasonPath(data);
  }, [externalId, selectedSeason]);

  useEffect(() => {
    getData()
      .catch(err => pushNotification({type: 'error', message: `Failed to get prior season auto claims status: ${err.message}`}));

  }, [getData, pushNotification]);

  const handlePPPlusChange = useCallback((value: boolean) => {
    const tempPSP = playerSeasonPath;
    if(tempPSP){
      tempPSP.path.partyPassPlus = value;
      setPlayerSeasonPath(tempPSP);
    }
  },[playerSeasonPath, setPlayerSeasonPath]);

  return (<>
    {playerSeasonPath &&
      <>
        <Box mb={2}>
          <SeasonSelect
            value={playerSeasonPath.seasonNumber}
            onChange={value => setSelectedSeason(value.season_number)}
          />
        </Box>
        <SeasonOverview
          playerSeasonPath={playerSeasonPath}
        />
        <PlayerSeasonPath
          externalId={externalId}
          seasonNumber={playerSeasonPath.seasonNumber}
          path={playerSeasonPath.path}
          pathTierMap={playerSeasonPath.pathTierMap}
          onPPPlusChange={handlePPPlusChange}
        />
        <PlayerSeasonChallenges
          challengeHistory={playerSeasonPath.challengeHistory}
          challengeMap={playerSeasonPath.challengeMap}
          rewardPathTierClaimHistory={playerSeasonPath.rewardPathTierClaimHistory}
          pathTierMap={playerSeasonPath.pathTierMap}
          playerId={externalId}
          reloadCallback={getData}
	      />
      </>
    }
  </>);
}

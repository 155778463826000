import { Typography } from '@material-ui/core';
import { ResultItem } from './ResultItem';
import type { MigrationResult } from '../../../../redux/catalog-migration/reducer';

interface ResultsProps {
  resultItems: CatalogMigrationItem[];
  results: { [key: string]: MigrationResult };
}

export const Results = ({
  resultItems,
  results
}: ResultsProps) => (<>
  <Typography variant="h5">Catalog migration results</Typography>
  {resultItems.map(item => (
    <ResultItem
      key={item.id}
      item={item}
      result={results[item.id]} />
  ))}
</>);

import { fork } from 'redux-saga/effects';
import authenticationSagas from './authentication/sagas';
import catalogSagas from './catalog/sagas';
import catalogsSagas from './catalogs/sagas';
import titleSagas from './title-data/sagas';
import currenciesSagas from './currencies/sagas';
import currencyLimitsSagas from './currency-limits/sagas';
import transactionsSagas from "./transactions/sagas";
import dailyMessagesSagas from "./motd/sagas";
import catalogMigrationSagas from './catalog-migration/sagas';
import seasonsSagas from './player-challenges/seasons/sagas';
import challengeTemplatesSagas from './player-challenges/challenge-templates/sagas';
import challengesSagas from './player-challenges/challenges/sagas';
import seasonChallengeScheduleCalendarSagas from './player-challenges/season-challenge-schedule-calendar/sagas';
import seasonChallengeScheduleSagas from './player-challenges/season-challenge-schedule/sagas';
import seasonRewardPathSagas from './player-challenges/season-reward-path/sagas';
import seasonRewardPathTiersSagas from './player-challenges/season-reward-path-tiers/sagas';
import blankoProgressionsSagas from './blanko-progressions/sagas';
import { appSagas } from './app/sagas';

export default function* rootSagas() {
  yield fork(authenticationSagas);
  yield fork(catalogsSagas);
  yield fork(catalogSagas);
  yield fork(titleSagas);
  yield fork(currenciesSagas);
  yield fork(currencyLimitsSagas);
  yield fork(transactionsSagas);
  yield fork(dailyMessagesSagas);
  yield fork(catalogMigrationSagas);
  yield fork(seasonsSagas);
  yield fork(challengeTemplatesSagas);
  yield fork(challengesSagas);
  yield fork(seasonChallengeScheduleCalendarSagas);
  yield fork(seasonChallengeScheduleSagas);
  yield fork(seasonRewardPathSagas);
  yield fork(seasonRewardPathTiersSagas);
  yield fork(blankoProgressionsSagas);
  yield fork(appSagas);
}
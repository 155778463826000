import { ConnectedRouter } from 'connected-react-router'
import type { User } from 'oidc-client-ts';
import { UserManager } from 'oidc-client-ts';
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import App from './App'
import axios, { destroyToken, saveToken } from './axios'
import config from './config'
import { setOAuthClient } from './redux/authentication/actions'
import { history, store } from './redux/store'

const { authUrl, clientId, baseUrl, version } = config.apis.mythical;

const usrmgr = new UserManager({
  authority: authUrl,
  client_id: clientId,
  redirect_uri: `${window.location.origin}/api/auth`,
  scope: 'openid offline_access',
  automaticSilentRenew: false
});

//Listen for user(& token) refresh and persist to store
//so it is available
usrmgr.events.addUserLoaded((user) => {
  const auth = store.getState().authentication.oAuthClient;
  if (auth) {
    auth.user = user;
    persistAuth(auth);
  }
});

const bootstrapAuth = async (authClient: UserManager) => {
  let user: User | null = null;
  if (window.location.pathname === '/api/auth') {
    user = await authClient.signinCallback();
    if (user) {
      const userState = user.state as any;
      if (userState?.redirect) {
        history.replace(userState.redirect);
      }
    }
  } else if (window.location.pathname === '/api/auth-migration') {
    // Migration auth
    await authClient.signinPopupCallback();
    return false; // The popup will be destroyed right away. No need to render anything.
  } else {
    user = await authClient.getUser();
  }

  if (!user) {
    //No user, so redirect to signin.
    destroyToken();
    await authClient.signinRedirect({ state: { redirect: `${window.location.pathname || ''}${window.location.search || ''}` } });
  } else {
    //save off our authClient
    const oidcClient = {
      userManager: authClient,
      user: user
    };
    persistAuth(oidcClient);
  }

  await authClient.clearStaleState();

  return Boolean(user);
};

const persistAuth = (oidcClient: OidcClient) => {
  store.dispatch(setOAuthClient(oidcClient));
  //save our tokens!
  saveToken(oidcClient.user?.access_token, oidcClient.user?.refresh_token);
};

axios.defaults.baseURL = baseUrl;
axios.defaults.headers.common['Content-Type'] = 'application/json';
axios.defaults.headers.common['Version'] = version;

bootstrapAuth(usrmgr).then(loggedIn => {
  if (loggedIn) {
    document.title += ` - ${config.name}`;

    ReactDOM.render(
      <Provider store={store}>
        <ConnectedRouter history={history}>
          <App />
        </ConnectedRouter>
      </Provider>,
      document.getElementById('root')
    );
  }
});
import download from 'downloadjs';
import { api, ApiError } from './api';
import type { PaginatedRequest} from './model/pagination';
import { PaginatedResponse } from './model/pagination';

export class Block {
  id = '';
  name = '';
  ownerId = '';
  ownerName = '';
  creatorId = '';
  creatorName = '';
  primaryGameMode = '';
  secondaryGameModes: string[] = [];
  minPlayers = 0;
  maxPlayers = 0;
  duration = 0;
  playType = '';
  customData = '';
  attachmentConfigs: any[] = [];
  published = false;
  fileUploaded = false;
  blockFileUrl = '';
  revision = 0;
  md5 = '';
  mythical = false;
  locked = false;
  tags: string[] = [];
  brawlMaxPlayers = 0;
  brawlMinPlayers = 0;
  brawlWeight = 0;
  brawlEliminationsPercentage = 0;
  brawlPlaylist = "NONE";
  brawlIsNonEliminationCompatible = false;
  updatedTimeStamp = new Date();
  skillLevelThreshold = 0;

  constructor(json?: any) {
    if (json) {
      this.id = json.id;
      this.name = json.name;
      this.creatorId = json.creatorId;
      this.creatorName = json.creatorName;
      this.ownerId = json.ownerId;
      this.ownerName = json.ownerName;
      this.primaryGameMode = json.primaryGameMode;
      this.secondaryGameModes = json.secondaryGameModes || [];
      this.minPlayers = json.minPlayers;
      this.maxPlayers = json.maxPlayers;
      this.duration = json.duration;
      this.playType = json.playType;
      this.customData = json.customData;
      this.tags = json.tags;
      this.attachmentConfigs = json.attachmentConfigs || [];
      this.brawlMaxPlayers = json.brawlMaxPlayers || 0;
      this.brawlMinPlayers = json.brawlMinPlayers || 0;
      this.brawlWeight = json.brawlWeight || 0;
      this.brawlEliminationsPercentage = json.brawlEliminationsPercentage || 0;
      this.brawlPlaylist = json.brawlPlaylist || "NONE";
      this.brawlIsNonEliminationCompatible = json.brawlIsNonEliminationCompatible || false;
      this.skillLevelThreshold = json.skillLevelThreshold || 0;
      if (typeof json.published === 'boolean') {
        this.published = json.published;
      }
      if (typeof json.fileUploaded === 'boolean') {
        this.fileUploaded = json.fileUploaded;
      }
      this.blockFileUrl = json.blockFileUrl || '';
      this.revision = json.revision || 0;
      this.md5 = json.md5 || '';
      if (typeof json.mythical === 'boolean') {
        this.mythical = json.mythical;
      }
      if (typeof json.locked === 'boolean') {
        this.locked = json.locked;
      }
      this.updatedTimeStamp = new Date(json.updatedTimestamp);
    }
  }

  toJson(): any {
    return {
      id: this.id,
      name: this.name,
      creatorId: this.creatorId,
      ownerId: this.ownerId,
      primaryGameMode: this.primaryGameMode,
      secondaryGameModes: this.secondaryGameModes,
      minPlayers: this.minPlayers,
      maxPlayers: this.maxPlayers,
      duration: this.duration,
      playType: this.playType,
      customData: this.customData,
      attachmentConfigs: this.attachmentConfigs,
      mythical: this.mythical,
      locked: this.locked,
      tags: this.tags,
      brawlMaxPlayers: this.brawlMaxPlayers,
      brawlMinPlayers: this.brawlMinPlayers,
      brawlWeight: this.brawlWeight,
      brawlEliminationsPercentage: this.brawlEliminationsPercentage,
      brawlPlaylist: this.brawlPlaylist,
      brawlIsNonEliminationCompatible: this.brawlIsNonEliminationCompatible,
      skillLevelThreshold: this.skillLevelThreshold
    };
  }
}

export class PublishedBlock {
  id = '';
  blockId = '';
  creatorId = '';
  creatorName = '';
  ownerId = '';
  ownerName = '';
  name = '';
  primaryGameMode = '';
  secondaryGameModes: string[] = [];
  playType = '';
  minPlayers = 0;
  maxPlayers = 0;
  duration = 0;
  playCount = 0;
  playerUniqueJoinedCount = 0;
  playerTotalJoinedCount = 0;
  playerUniqueCompletedCount = 0;
  playerTotalCompletedCount = 0;
  active = false;
  verified = false;
  featured = false;
  attachmentConfigs: any[] = [];
  customData = '';
  blockFileUrl = '';
  totalReportCount = 0;
  tags: string[] = [];
  brawlMaxPlayers = 0;
  brawlMinPlayers = 0;
  brawlWeight = 0;
  brawlEliminationsPercentage = 0;
  brawlPlaylist = "NONE";
  brawlIsNonEliminationCompatible = false;
  updatedTimeStamp = new Date();
  skillLevelThreshold = 0;

  constructor(json?: any) {
    if (json) {
      this.id = json.id;
      this.blockId = json.blockId;
      this.name = json.name;
      this.creatorId = json.creatorId;
      this.creatorName = json.creatorName;
      this.ownerId = json.ownerId;
      this.ownerName = json.ownerName;
      this.primaryGameMode = json.primaryGameMode;
      this.secondaryGameModes = json.secondaryGameModes || [];
      this.minPlayers = json.minPlayers;
      this.maxPlayers = json.maxPlayers;
      this.duration = json.duration;
      this.playType = json.playType;
      this.playCount = json.playCount;
      this.playerUniqueJoinedCount = json.playerUniqueJoinedCount;
      this.playerTotalJoinedCount = json.playerTotalJoinedCount;
      this.playerUniqueCompletedCount = json.playerUniqueCompletedCount;
      this.playerTotalCompletedCount = json.playerTotalCompletedCount;
      this.customData = json.customData;
      this.attachmentConfigs = json.attachmentConfigs || [];
      if (typeof json.active === 'boolean') {
        this.active = json.active;
      }
      if (typeof json.verified === 'boolean') {
        this.verified = json.verified;
      }
      if (typeof json.featured === 'boolean') {
        this.featured = json.featured;
      }
      this.blockFileUrl = json.blockFileUrl || '';
      this.totalReportCount = json.totalReportCount || 0;
      this.tags = json.tags || [];
      this.brawlMaxPlayers = json.brawlMaxPlayers || 0;
      this.brawlMinPlayers = json.brawlMinPlayers || 0;
      this.brawlWeight = json.brawlWeight || 0;
      this.brawlEliminationsPercentage = json.brawlEliminationsPercentage || 0;
      this.brawlPlaylist = json.brawlPlaylist || "NONE";
      this.brawlIsNonEliminationCompatible = json.brawlIsNonEliminationCompatible || false;
      this.updatedTimeStamp = new Date(json.updatedAt);
      this.skillLevelThreshold = json.skillLevelThreshold || 0;
    }
  }


  clone(): PublishedBlock {
    return new PublishedBlock(this);
  }
}

export class BlockReport {
  id = '';
  blockId = '';
  blockName = '';
  publishedBlockId = '';
  publishedBlockName = '';
  playerId = '';
  playerExternalId = '';
  playerDisplayName = '';
  playerEmail = '';
  status = '';
  reason = '';
  description = '';
  note = '';
  active = false;
  updatedTimestamp = '';
  createdTimestamp = '';

  constructor(json?: any) {
    if (json) {
      this.id = json.id;
      this.blockId = json.blockId;
      this.blockName = json.blockName;
      this.publishedBlockId = json.publishedBlockId;
      this.publishedBlockName = json.publishedBlockName;
      this.playerId = json.playerId;
      this.playerExternalId = json.playerExternalId;
      this.playerDisplayName = json.playerDisplayName;
      this.playerEmail = json.playerEmail;
      this.status = json.status;
      this.reason = json.reason;
      this.description = json.description;
      this.note = json.note;
      this.active = json.active;
      this.updatedTimestamp = json.updatedTimestamp;
      this.createdTimestamp = json.createdTimestamp;
    }
  }
}

export class BlockTagsAutoComplete {
  tags: string[] = [];
  constructor(json?: any) {
    if (json) {
      this.tags = json.tags || [];
    }
  }
}

export class MarkInReviewBlockReportRequest {
  id = '';

  constructor(id: string) {
    this.id = id;
  }
}

export class ResolveBlockReportRequest {
  id = '';
  note = '';

  constructor(id: string) {
    this.id = id;
  }
}

export interface BlockDataFile {
  format: 'json' | 'binary';
  data: any;
}

export class BlocksService {
  static async getBlocks(request: PaginatedRequest): Promise<PaginatedResponse<Block>> {
    return api.get<PaginatedResponse<Block>>({ url: '/blocks', query: request })
      .then(blocks => new PaginatedResponse(blocks, block => new Block(block)))
      .catch((e: ApiError) => {
        e.message = `Failed to get blocks. ${e.message}`;
        throw e;
      });
  }

  static async getBlock(id: string): Promise<Block> {
    return api.get({ url: `/blocks/${id}` })
      .then(block => new Block(block))
      .catch((e: ApiError) => {
        e.message = `Failed to get block. ${e.message}`;
        throw e;
      });
  }

  static async createBlock(blockJson: any): Promise<Block> {
    return api.post({ url: '/blocks', body: blockJson })
      .then(block => new Block(block))
      .catch((e: ApiError) => {
        e.message = `Failed to create block. ${e.message}`;
        throw e;
      });
  }

  static async updateBlock(block: Block): Promise<Block> {
    return api.put({ url: `/blocks/${block.id}`, body: block.toJson() })
      .then(block => new Block(block))
      .catch((e: ApiError) => {
        e.message = `Failed to update block. ${e.message}`;
        throw e;
      });
  }

  static async cloneBlock(id: string, name: string, ownerId: string): Promise<Block> {
    return api.post({ url: `/blocks/${id}/clone`, body: { name, ownerId } })
      .then(block => new Block(block))
      .catch((e: ApiError) => {
        e.message = `Failed to clone block. ${e.message}`;
        throw e;
      });
  }

  static async deleteBlock(id: string): Promise<null> {
    return api.delete({ url: `/blocks/${id}` })
      .catch((e: ApiError) => {
        e.message = `Failed to delete block. ${e.message}`;
        throw e;
      });
  }

  static async downloadBlock(id: string): Promise<void> {
    const block = await BlocksService.getBlock(id);
    const blockJson = block.toJson();
    delete blockJson.id;
    delete blockJson.creatorId;
    delete blockJson.ownerId;

    const blockJsonBlob = new Blob([JSON.stringify(blockJson)]);
    const fileBlobParts: BlobPart[] = [new Uint32Array([42, blockJsonBlob.size]), blockJsonBlob];

    if (block.fileUploaded && block.blockFileUrl) {
      const blockFileJson = await fetch(block.blockFileUrl);
      if (!blockFileJson.ok) {
        throw new ApiError(`Failed to download block file. ${blockFileJson.status === 404 ? 'Block file not found.' : 'Unknown error.'}`);
      }

      const contentType = blockFileJson.headers.get('content-type');
      let fileFormat = 0;
      if (contentType === 'application/json') {
        fileFormat = 0;
      } else if (contentType === 'application/octet-stream') {
        fileFormat = 1;
      } else {
        throw new Error(`Unexpected block data file type: ${contentType}`);
      }

      const blockFileBlob = await blockFileJson.blob();
      fileBlobParts.push(new Uint32Array([blockFileBlob.size, fileFormat]));
      fileBlobParts.push(blockFileBlob);
    } else {
      fileBlobParts.push(new Uint32Array([0]));
    }

    download(new Blob(fileBlobParts), `${block.name}.block`, 'application/octet-stream');
  }

  static async uploadBlockFile(blockId: string, blockDataFile: BlockDataFile) {
    try {
      const uploadBlockFile = await api.get({ url: `/blocks/${blockId}/upload-url`, query: { filename: 'BlockData.json' } });
      const uploadUrl = uploadBlockFile.url;
      const options: RequestInit = {
        method: 'PUT',
        headers: new Headers({
          'Content-Type': blockDataFile.format === 'json' ? 'application/json' : 'application/octet-stream'
        }),
        body: blockDataFile.data
      };

      const response = await fetch(uploadUrl, options);
      if (!response.ok) {
        throw new Error(`Upload status: ${response.status}`);
      }

      await api.put({ url: `/blocks/${blockId}/file/finalize_file_upload`, body: { revision: 0, md5: '' } }).catch((e: ApiError) => {
        e.message = `Failed to finalize upload block file.${e.message}`;
        throw e;
      });
  

    } catch (e) {
      throw new ApiError(`Failed to upload block file. ${e.message}`);
    }
  }

  static async getPublishedBlocks(playerId: string): Promise<PublishedBlock[]> {
    return api.get<any[]>({ url: `/players/${playerId}/published-blocks` })
      .then(blocks => blocks.map(block => new PublishedBlock(block)))
      .catch((e: ApiError) => {
        e.message = `Failed to get published blocks. ${e.message}`;
        throw e;
      });
  }

  static async getPublishedBlocksPage(request: PaginatedRequest): Promise<PaginatedResponse<PublishedBlock>> {
    return api.get<any[]>({ url: '/published-blocks', query: request })
      .then(blocks => new PaginatedResponse(blocks, block => new PublishedBlock(block)))
      .catch((e: ApiError) => {
        e.message = `Failed to get published blocks. ${e.message}`;
        throw e;
      });
  }

  static async getBlockReportsPage(request: PaginatedRequest): Promise<PaginatedResponse<BlockReport>> {
    return api.get<any[]>({ url: '/blocks/reported', query: request })
      .then(blockReports => new PaginatedResponse(blockReports, blockReport => new BlockReport(blockReport)))
      .catch((e: ApiError) => {
        e.message = `Failed to get block reports. ${e.message}`;
        throw e;
      });
  }

  static async markInReviewBlockReport(request: MarkInReviewBlockReportRequest): Promise<void> {
    return api.put({ url: `/blocks/reported/${request.id}/mark-in-review`, body: request })
      .catch((e: ApiError) => {
        e.message = `Failed to mark in review block report. ${e.message}`;
        throw e;
      });
  }

  static async resolveBlockReport(request: ResolveBlockReportRequest): Promise<void> {
    return api.put({ url: `/blocks/reported/${request.id}/resolve`, body: request })
      .catch((e: ApiError) => {
        e.message = `Failed to resolve block report. ${e.message}`;
        throw e;
      });
  }

  static async createPublishedBlock(block: Block): Promise<PublishedBlock> {
    if (block.published) {
      await BlocksService.unpublishBlock(block.id);
    }

    return api.post({ url: '/published-blocks', body: { blockId: block.id }})
      .then(block => new PublishedBlock(block))
      .catch((e: ApiError) => {
        e.message = `Failed to publish block. ${e.message}`;
        throw e;
      });
  }

  static async getPublishedBlock(id: string): Promise<PublishedBlock> {
    return api.get({ url: `/published-blocks/${id}` })
      .then(block => new PublishedBlock(block))
      .catch((e: ApiError) => {
        e.message = `Failed to get published block. ${e.message}`;
        throw e;
      });
  }

  static async unpublishBlock(blockId: string): Promise<string> {
    await api.delete({ url: `/published-blocks/${blockId}` }).catch((e: ApiError) => {
      e.message = `Failed to unpublish block.${e.message}`;
      throw e;
    });
    return blockId;
  }

  static async updatePublishedBlockFeatured(block: PublishedBlock): Promise<PublishedBlock> {
    await api.put({ url: `/published-blocks/${block.id}/featured`, body: { featured: block.featured } }).catch((e: ApiError) => {
      e.message = `Failed to update block.${e.message}`;
      throw e;
    });

    return block.clone();
  }

  static async updatePublishedBlockName(block: PublishedBlock, updateBlock: boolean): Promise<PublishedBlock> {
    await api.put({ url: `/published-blocks/${block.id}/name`, body: { name: block.name, updateBlock: updateBlock } }).catch((e: ApiError) => {
      e.message = `Failed to update block.${e.message}`;
      throw e;
    });

    return block.clone();
  }

  static async getBlockTagsAutoComplete(): Promise<BlockTagsAutoComplete> {
    return api.get({ url: '/blocks/tags/auto_complete' })
      .then(tags => new BlockTagsAutoComplete(tags))
      .catch((e: ApiError) => {
        e.message = `Failed to get auto complete block tags list. ${e.message}`;
        throw e;
      });
  }

  static async savePublishedBlockTags(id: string, blockTags: string[]): Promise<string[]> {
    return api.put<string[]>({ url: `/published-blocks/${id}/tags`, body: blockTags })
      .catch((e: ApiError) => {
        e.message = `Failed to save tags. ${e.message}`;
        throw e;
      });
  }

  
  static async savePublishedBlockWeight(id: string, weight: number): Promise<string[]> {
    return api.put<string[]>({ url: `/published-blocks/${id}/weight`, body: weight })
      .catch((e: ApiError) => {
        e.message = `Failed to save weight. ${e.message}`;
        throw e;
      });
  }

  static async savePublishedBlockBrElimsRequired(id: string, brElimsPercentage: number): Promise<string[]> {
    return api.put<string[]>({ url: `/published-blocks/${id}/br-elims-percentage`, body: brElimsPercentage })
      .catch((e: ApiError) => {
        e.message = `Failed to save br elims. ${e.message}`;
        throw e;
      });
  }

  static async saveBlockTags(id: string, blockTags: string[]): Promise<string[]> {
    return api.put<string[]>({ url: `/blocks/${id}/tags`, body: blockTags })
      .catch((e: ApiError) => {
        e.message = `Failed to save tags. ${e.message}`;
        throw e;
      });
  }
}
import { CircularProgress, FormControl, InputLabel, MenuItem, Select, Typography } from '@material-ui/core';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux'
import { setAppNotification } from '../../../redux/app/actions';
import type { ApiError } from '../../../services/api';
import type { Catalog} from '../../../services/catalogs';
import { CatalogsService } from '../../../services/catalogs';

export interface CatalogSelectProps {
  catalogName: string;
  onCatalogNameChange: (catalogName: string) => void;
}

export const CatalogSelect = (props: CatalogSelectProps) => {
  const dispatch = useDispatch();

  const { catalogName, onCatalogNameChange } = props;

  const [catalogs, setCatalogs] = useState<Catalog[] | null>(null);

  useEffect(() => {
    if (catalogs !== null) {
      return;
    }

    CatalogsService.getCatalogs().then(catalogs => {
      setCatalogs(catalogs);
      if (catalogs.length > 0) {
        if (!catalogName || !catalogs.find(v => v.name === catalogName)) {
          onCatalogNameChange((catalogs.find(v => v.primaryCatalog) || catalogs[0]).name);
        }
      } else {
        onCatalogNameChange('');
      }
    }).catch((e: ApiError) => dispatch(setAppNotification({ type: 'error', message: e.message })));
  }, [dispatch, onCatalogNameChange, catalogs, catalogName]);

  if (!catalogs || (catalogName === '' && catalogs.length > 0)) {
    return <CircularProgress size={15} />;
  }

  if (catalogs.length < 1) {
    return (
      <Typography color="textSecondary">
        No catalogs available.
      </Typography>
    )
  }

  return (
    <FormControl fullWidth>
      <InputLabel>Catalog</InputLabel>
      <Select value={catalogName} onChange={e => onCatalogNameChange(e.target.value as string)}>
        {catalogs.map(catalog => (
          <MenuItem key={catalog.name} value={catalog.name}>{catalog.name}</MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}
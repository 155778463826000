import { ApiError, api } from './api';

export class StoreItem {
  itemId = '';
  virtualCurrencyPrices: { [key: string]: number } = {};
  rmPrice: number | null = null;
  maxSupply: number | null = null;
  bufferSupply: number | null = null;

  constructor(json?: any) {
    if (json) {
      this.itemId = json.itemId;
      try {
        this.virtualCurrencyPrices = json.virtualCurrencyPrices || {};
      // eslint-disable-next-line no-empty
      } catch (e) { }
      this.rmPrice = json.rmPrice;
      this.maxSupply = json.maxSupply;
      this.bufferSupply = typeof json.bufferSupply === 'number' ? json.bufferSupply : null;
    }
  }

  toJson() {
    return {
      itemId: this.itemId,
      virtualCurrencyPrices: this.virtualCurrencyPrices,
      rmPrice: this.rmPrice,
      maxSupply: this.maxSupply,
      bufferSupply: this.bufferSupply
    };
  }
}

export interface StoreTabItem {
  itemId: string;
  hero: boolean;
  gridWidth: number;
  imageFilename?: string;
  imageUrl?: string;
  nonInteractive: boolean;
}

export interface StoreTab {
  name: string;
  position: number;
  items: StoreTabItem[];
}

export class Store {
  storeId = '';
  items: StoreItem[] = [];
  itemsMap: { [key: string]: StoreItem } = {};
  tabs: StoreTab[] = [];
  locked = false;

  constructor(json?: any) {
    if (json) {
      this.storeId = json.StoreId;
      this.items = json.Store.map((v: any) => new StoreItem(v));
      this.tabs = json.tabs;
      this.locked = json.locked;
      this.updateItemsMap();
    }
  }

  updateItemsMap() {
    this.items.forEach(item => this.itemsMap[item.itemId] = item);
  }

  toJson(includeTabs?: boolean) {
    const json: any = {
      StoreId: this.storeId,
      Store: this.items.map(item => item.toJson())
    };

    if (includeTabs) {
      json.tabs = this.tabs;
    }

    return json;
  }

  // Ugh. I really need to move to interfaces.
  clone() {
    const store = new Store();
    store.storeId = this.storeId;
    store.items = this.items;
    store.tabs = this.tabs;
    store.itemsMap = this.itemsMap;
    store.locked = this.locked;
    return store;
  }
}

export class StoresService {
  static async getStores(catalogName: string): Promise<Store[]> {
    return api.get<any[]>({ url: `/inventory/catalogs/${catalogName}/stores` })
      .then(stores => stores.map(v => new Store(v)))
      .catch((e: ApiError) => {
        e.message = `Failed to get stores. ${e.message}`;
        throw e;
      });
  }

  static async getStore(catalogName: string, storeId: string): Promise<Store> {
    return api.get({ url: `/inventory/catalogs/${catalogName}/stores/${storeId}` })
      .then(store => new Store(store))
      .catch((e: ApiError) => {
        e.message = `Failed to get store. ${e.message}`;
        throw e;
      });
  }

  static async createStore(catalogName: string, store: Store): Promise<Store> {
    return api.post({ url: `/inventory/catalogs/${catalogName}/stores`, body: store.toJson(!!store.tabs) })
      .then(store => new Store(store))
      .catch((e: ApiError) => {
        e.message = `Failed to create store. ${e.message}`;
        throw e;
      });
  }

  static async updateStore(catalogName: string, store: Store): Promise<Store> {
    return api.put({ url: `/inventory/catalogs/${catalogName}/stores/${store.storeId}`, body: store.toJson() })
      .then(store => new Store(store))
      .catch((e: ApiError) => {
        e.message = `Failed to update store. ${e.message}`;
        throw e;
      });
  }

  static async deleteStore(catalogName: string, store: Store): Promise<null> {
    return api.delete({ url: `/inventory/catalogs/${catalogName}/stores/${store.storeId}` })
      .catch((e: ApiError) => {
        e.message = `Failed to delete store. ${e.message}`;
        throw e;
      });
  }

  static async publishStore(catalogName: string, store: Store): Promise<Store> {
    return api.put({ url: `/inventory/catalogs/${catalogName}/stores/${store.storeId}/publish`, body: store.toJson() })
      .then(store => new Store(store))
      .catch((e: ApiError) => {
        e.message = `Failed to publish store. ${e.message}`;
        throw e;
      });
  }

  static async upsertStoreItems(catalogName: string, store: Store, items: StoreItem[]): Promise<Store> {
    return api.put({ url: `/inventory/catalogs/${catalogName}/stores/${store.storeId}/items`, body: items.map(item => item.toJson()) })
      .then(store => new Store(store))
      .catch((e: ApiError) => {
        e.message = `Failed to update store. ${e.message}`;
        throw e;
      });
  }

  static async updateStoreTabItemImage(catalogName: string, storeId: string, tabName: string, itemId: string, imageFilename: string): Promise<StoreTabItem> {
    return api.put<StoreTabItem>({ url: `/inventory/catalogs/${catalogName}/stores/${storeId}/tabs/${tabName}/items/${itemId}/image`, body: { imageFilename } })
      .catch((e: ApiError) => {
        e.message = `Failed to update store item image. ${e.message}`;
        throw e;
      });
  }

  static async createStoreTab(catalogName: string, store: Store, name: string): Promise<StoreTab> {
    return api.post<StoreTab>({ url: `/inventory/catalogs/${catalogName}/stores/${store.storeId}/tabs`, body: { name } })
      .catch((e: ApiError) => {
        e.message = `Failed to create store tab. ${e.message}`;
        throw e;
      });
  }

  static async updateStoreTab(catalogName: string, store: Store, name: string, tab: StoreTab): Promise<StoreTab> {
    return api.put<StoreTab>({ url: `/inventory/catalogs/${catalogName}/stores/${store.storeId}/tabs/${name}`, body: { name: tab.name, position: tab.position } })
      .catch((e: ApiError) => {
        e.message = `Failed to update store tab. ${e.message}`;
        throw e;
      });
  }

  static async deleteStoreTab(catalogName: string, store: Store, name: string): Promise<null> {
    return api.delete({ url: `/inventory/catalogs/${catalogName}/stores/${store.storeId}/tabs/${name}` })
      .catch((e: ApiError) => {
        e.message = `Failed to delete store tab. ${e.message}`;
        throw e;
      });
  }

  static async updateStoreTabItems(catalogName: string, store: Store, tabName: string, items: StoreTabItem[]): Promise<any> {
    return api.put({ url: `/inventory/catalogs/${catalogName}/stores/${store.storeId}/tabs/${tabName}/items`, body: items })
      .catch((e: ApiError) => {
        e.message = `Failed to update store tab items. ${e.message}`;
        throw e;
      });
  }

  static async uploadStoreItemImage(catalogName: string, storeId: string, tabName: string, itemId: string, image: File): Promise<StoreTabItem> {
    try {
      const imageData = await api.post({ url: '/inventory/image-upload-url', body: { mimeType: image.type } });
      const options: RequestInit = {
        method: 'PUT',
        headers: new Headers({
          'Content-Type': image.type
        }),
        body: image
      };

      const response = await fetch(imageData.url, options);
      if (!response.ok) {
        throw new Error('');
      }

      return this.updateStoreTabItemImage(catalogName, storeId, tabName, itemId, imageData.filename);
    } catch (e) {
      throw new ApiError(`Failed to upload store image. ${e.message}`);
    }
  }

  static async lockCatalogStore(catalogName: string, store: Store): Promise<Store> {
    return api.put({ url: `/inventory/catalogs/${catalogName}/stores/${store.storeId}/lock` })
      .catch((e: ApiError) => {
        e.message = `Failed to lock store. ${e.message}`;
        throw e;
      });
  }

  static async unlockCatalogStore(catalogName: string, store: Store): Promise<Store> {
    return api.put({ url: `/inventory/catalogs/${catalogName}/stores/${store.storeId}/unlock` })
      .catch((e: ApiError) => {
        e.message = `Failed to unlock store. ${e.message}`;
        throw e;
      });
  }

}
import { Card, CardContent, CardHeader, Grid, Tooltip } from '@material-ui/core';
import WarningIcon from '@material-ui/icons/Warning';
import { Component } from 'react';
import type { IAnnotation } from 'react-ace';
import AceEditor from 'react-ace';
import type { RootState } from '../redux/reducers';
import type { ConnectedProps } from 'react-redux';
import { connect } from 'react-redux';

const mapStateToProps = (state: RootState) => ({ darkMode: state.app.darkMode });
const connector = connect(mapStateToProps, {});

type Props = ConnectedProps<typeof connector> & {
  title?: string;
  json: any;
  readOnly?: boolean;
  onBlur?: () => void;
  focus?: boolean;
  onJsonChange: (json: string) => void;
  onValidityChange?: (valid: boolean) => void;
}

interface State {
  valid: boolean;
}

class JsonEditor extends Component<Props, State> {
  private json: string;

  constructor(props: Props) {
    super(props);
    this.json = this.formatJson(this.props.json);
    this.props.onJsonChange(this.json);
    this.state = {
      valid: false
    };
  }

  private formatJson(json: any) {
    if (typeof json === 'string') {
      try {
        json = JSON.parse(json);
      } catch (e) {
        // No problem.
        return json;
      }
    }

    return JSON.stringify(json, null, '\t');
  }

  onChange = (json: string) => {
    this.json = json;
    this.props.onJsonChange(json);
  }

  onValidate = (annotations: IAnnotation[]) => {
    let valid = true;
    for (let i = 0; i < annotations.length; i++) {
      if (annotations[i].type === 'error') {
        valid = false;
        break;
      }
    }

    if (valid !== this.state.valid) {
      this.setState({ valid });
      if (this.props.onValidityChange) {
        this.props.onValidityChange(valid);
      }
    }
  }

  render() {
    return (
      <Card>
        <CardHeader title={(
          <Grid container spacing={2} alignItems="center">
            <Grid item>
              {this.props.title || 'JSON Editor'}
            </Grid>
            {!this.state.valid && (
              <Grid item>
                <Tooltip title="Invalid JSON" placement="top">
                  <WarningIcon style={{ display: 'block' }} color="error" />
                </Tooltip>
              </Grid>
            )}
          </Grid>
        )} />
        <CardContent>
          <AceEditor
            mode="json"
            theme={this.props.darkMode ? 'dracula' : 'chrome'}
            value={this.json}
            onChange={this.onChange}
            style={{
              width: '100%',
              height: 300
            }}
            setOptions={{
              useWorker: true
            }}
            onValidate={this.onValidate}
            {...this.props}
          />
        </CardContent>
      </Card>
    );
  }
}

export default connector(JsonEditor);
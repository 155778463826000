import { Field } from 'formik';
import { useCallback, useState } from 'react'
import MenuIcon from '@material-ui/icons/Menu';
import { IconButton, InputAdornment, Tooltip } from '@material-ui/core';
import ItemSelectDialog from './ItemSelectDialog';
import { TextField } from 'formik-material-ui';
import { ItemDefinition } from '../../services/item-definitions';
import type { DropTable } from '../../services/drop-tables';
import CloseIcon from '@material-ui/icons/Close';

export interface ItemSelectFieldProps {
  label: string;
  fieldName: string;
  setFieldValue: (field: string, value: string) => void;
  readOnly?: boolean;
}

export const ItemSelectField = (props: ItemSelectFieldProps) => {
  const { setFieldValue } = props;

  const [dialogOpen, setDialogOpen] = useState(false);

  const onItemSelect = useCallback((items: (ItemDefinition | DropTable)[]) => {
    if (items.length > 0 && items[0] instanceof ItemDefinition) {
      setFieldValue(props.fieldName, items[0].itemId);
    }
  }, [props.fieldName, setFieldValue]);

  return (<>
    <Field
      component={TextField}
      name={props.fieldName}
      type="text"
      label={props.label}
      fullWidth
      InputProps={{
        readOnly: true,
        endAdornment: props.readOnly ? undefined : (<>
          <InputAdornment position="end">
            <Tooltip title="Select item...">
              <IconButton onClick={() => setDialogOpen(true)}>
                <MenuIcon />
              </IconButton>
            </Tooltip>
          </InputAdornment>
          <InputAdornment position="end">
            <Tooltip title="Clear item">
              <IconButton onClick={() => setFieldValue(props.fieldName, '')}>
                <CloseIcon />
              </IconButton>
            </Tooltip>
          </InputAdornment>
        </>)
      }}
    />

    {dialogOpen && (
      <ItemSelectDialog
        onSelect={onItemSelect}
        onClose={() => setDialogOpen(false)}
      />
    )}
  </>);
}
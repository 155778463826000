import type { Notification } from '../../redux/app/actions';

let notificationCallback: ((notification: Notification) => void) | null = null;

/** @deprecated Use usePushNotification from AppNotificationContext instead. */
export function pushAppNotification(notification: Notification) {
  if (notificationCallback) {
    notificationCallback(notification);
  }
}

/** @deprecated This is handled by AppNotificationContext. */
export function useAppNotification(handleNotification: (notification: Notification) => void) {
  notificationCallback = handleNotification;
}
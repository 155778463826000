import type { DropTable } from '../../services/drop-tables';
import type { ItemDefinition } from '../../services/item-definitions';
import type { Store } from '../../services/stores';
import _ from 'lodash';
import type { TitleFile } from '../../services/model/title-file';
import type { MigrationActions } from '../../redux/catalog-migration/reducer';
import type { MigrationDependencyItem } from '../../features/migrations/migrations';
import type { BlankoGemRushAndFuse } from '../../features/gem-rush/blankoGemRush';
import type { BlankoClass, BlankoClassAbilityType } from '../../services/model/blanko';
import { ItemNote } from "../../features/items/item-types";
import {GeoBlockedFeature, GeoBlockedFeatureMap} from "../../features/geoblocking/geo-blocking-types";
import { format } from "date-fns";
import { MigrationType } from "../../features/migrations/modules/migration-type";

export abstract class DiffItem {
  diffId = '';
  diffJson = '';

  // Weird, but it'll do...
  diffJsonPrev = '';
  diffPrev: DiffItem | null = null;

  dependencyItem: MigrationDependencyItem = { type: '' as MigrationType, id: '' };
  dependencies: MigrationDependencyItem[] = [];

  isEqual(other: DiffItem): boolean {
    return _.isEqual(this.diffJson, other.diffJson);
  }
}

export interface HiddenItem {
  type: string;
  id: string;
}

export const toHiddenItem = (item: DiffItem): HiddenItem => {
  return {
    type: item.dependencyItem.type,
    id: item.dependencyItem.id,
  };
};

export const isHidden = (type: string, id: string, hiddenItems: HiddenItem[]) => {
  const hiddenItem = { type, id, };
  return !!hiddenItems.find(hi => _.isEqual(hi, hiddenItem));
};

export const isDiffItemHidden = (item: DiffItem, hiddenItems: HiddenItem[]) => {
  const hiddenItem = toHiddenItem(item);
  return !!hiddenItems.find(hi => _.isEqual(hi, hiddenItem));
};

export const isDependencyHidden = (item: MigrationDependencyItem, hiddenItems: HiddenItem[]) => {
  const hiddenItem = {
    type: item.type,
    id: item.id,
  };
  return !!hiddenItems.find(hi => _.isEqual(hi, hiddenItem));
};

export interface ItemDiffProps<T extends DiffItem> {
  sourceItems: T[] | null,
  targetItems: T[] | null,
  actions: MigrationActions,
  onSelectionChange(actions: MigrationActions): void
}

export class CatalogItemDiff extends DiffItem {
  displayName: string;
  itemType: string;

  constructor(public item: ItemDefinition) {
    super();
    this.diffId = this.item.itemId;
    this.displayName = this.item.displayName;
    this.itemType = 'Item';
    const diffJson: any = {
      itemClass: this.item.itemClass,
      itemName: this.item.itemName,
      displayName: this.item.displayName,
      description: this.item.description,
      subtitle: this.item.subtitle,
      imageUrl: this.item.imageUrl,
      imageThumbnailUrl: this.item.imageThumbnailUrl,
      stackable: this.item.stackable,
      tradable: this.item.tradable,
      starterPack: this.item.starterPack,
      itemGrantType: this.item.itemGrantType,
      tokenForCharacter: this.item.tokenForCharacter,
      limitedEdition: this.item.limitedEdition,
      customData: this.item.customData,
      tags: this.item.tags,
      virtualCurrencyPrices: this.item.virtualCurrencyPrices,
      consumable: this.item.consumable,
      consumableUsageCount: this.item.consumableUsageCount,
      consumableUsagePeriod: this.item.consumableUsagePeriod,
      bundle: this.item.bundle,
      container: this.item.container,
      notes: this.item.notes
    };

    if (this.item.bundle || this.item.container) {
      const contents = (this.item.bundle ? this.item.itemBundleContents : this.item.itemContainerContents).map(v => ({
        type: v.type,
        id: v.id,
        quantity: v.quantity
      }));

      contents.sort((a, b) => {
        return a.type === b.type ? a.id.localeCompare(b.id) : a.type.localeCompare(b.type);
      });

      diffJson.contents = contents;
      diffJson.currencyContents = this.item.currencyContents;

      this.itemType = this.item.bundle ? 'Bundle' : 'Container';

      this.dependencies = contents.map(v => ({ type: v.type as MigrationType, id: v.id }));
    }

    this.diffJson = JSON.stringify(diffJson, null, 2);

    this.dependencyItem = { type: MigrationType.item, id: item.itemId };
  }
}

export class DropTableDiff extends DiffItem {
  constructor(public dropTable: DropTable) {
    super();
    this.diffId = dropTable.id;

    const nodes = dropTable.nodes.map(node => ({
      type: node.type,
      id: node.id,
      weight: node.weight,
      displayName: node.displayName
    }));

    nodes.sort((a, b) => {
      return a.type === b.type ? a.id.localeCompare(b.id) : a.type.localeCompare(b.type);
    });

    const diffJson = {
      nodes
    };

    this.diffJson = JSON.stringify(diffJson, null, 2);

    this.dependencyItem = { type: MigrationType.droptable, id: dropTable.id };
    this.dependencies = nodes.map(node => ({ type: node.type as MigrationType, id: node.id }));
  }
}

export class StoreDiff extends DiffItem {
  constructor(public store: Store) {
    super();
    this.diffId = store.storeId;

    const json: any = {};
    store.items.sort((a, b) => a.itemId.localeCompare(b.itemId));
    json.items = store.items.map(item => ({ ...item.toJson(), maxSupply: undefined, bufferSupply: undefined }));

    store.tabs.sort((a, b) => a.position - b.position);
    json.tabs = store.tabs.map(tab => ({
      ...tab,
      items: tab.items.map(item => ({
        ...item,
        imageUrl: undefined
      }))
    }));

    this.diffJson = JSON.stringify(json, null, 2);

    this.dependencyItem = { type: MigrationType.store, id: store.storeId };
    this.dependencies = store.items.map(item => ({ type: MigrationType.item, id: item.itemId }));
  }
}

export class TitleDataDiff extends DiffItem {
  constructor(key: string, value: string, isClient = true) {
    super();
    this.diffId = key;
    try {
      this.diffJson = JSON.stringify(JSON.parse(value), null, 2);
    } catch (e) {
      // This is okay. Title data value must not be a JSON.
      this.diffJson = value;
    }

    this.dependencyItem = { type: isClient ? MigrationType.titleDataClient : MigrationType.titleDataServer, id: key };
  }
}

export class TitleFileDiff extends DiffItem {
  public version: number;

  constructor(public file: TitleFile) {
    super();
    this.diffId = file.fileName;
    this.diffJson = JSON.stringify({ version: file.version }, null, 2);
    this.version = file.version;

    this.dependencyItem = { type: MigrationType.titleFile, id: file.fileName };
  }

  isEqual(other: DiffItem): boolean {
    if (other instanceof TitleFileDiff) {
      return this.file.version <= other.file.version;
    }
    return super.isEqual(other);
  }
}

export class BlankoDnaDiff extends DiffItem {
  blankoName = '';
  imageThumbnailUrl = '';
  existsInTarget = true;

  constructor(public dna: any, includeBlankoAssets: boolean) {
    super();
    this.diffId = `${dna.Id}, ver: ${dna.Version}`;
    let assets: any[] | undefined = undefined;
    if (includeBlankoAssets && dna.blankoAssets) {
      assets = dna.blankoAssets.map((asset: { name: string; platform: string; minPlatformVersion: string; fileVersion: number; assetType: number; }) => ({
        name: asset.name,
        assetType: asset.assetType,
        platform: asset.platform,
        minPlatformVersion: asset.minPlatformVersion,
        fileVersion: asset.fileVersion
      }));

      assets = _.uniqWith(assets, _.isEqual); // de-dupe assets
      assets = _.sortBy(assets, ['name', 'platform', 'minPlatformVersion', 'fileVersion']);
    }

    const jsonDiff: any = {
      ...dna,
      blankoAssets: assets
    }

    this.diffJson = JSON.stringify(jsonDiff, null, 2);

    this.dependencyItem = { type: MigrationType.dna, id: this.diffId };
  }

  isEqual(other: DiffItem): boolean {
    return _.isEqual(this.diffJson, other.diffJson);
  }
}

export class BlankoAssetDiff extends DiffItem {
  name: string;
  platform: string;
  minPlatformVersion: string;

  constructor(public asset: any) {
    super();
    this.diffId = `${asset.name},${asset.platform},${asset.minPlatformVersion}`;
    this.diffJson = JSON.stringify({ ...asset, payload: undefined }, null, 2);
    this.name = asset.name;
    this.platform = asset.platform;
    this.minPlatformVersion = asset.minPlatformVersion;

    this.dependencyItem = { type: MigrationType.blankoAsset, id: this.diffId };
  }

  isEqual(other: DiffItem): boolean {
    return _.isEqual(this.diffJson, other.diffJson);
  }
}

export class SeasonalDataDiff extends DiffItem {
  name: string;
  number: number;

  constructor(public seasonalData: SeasonalData) {
    super();
    this.diffId = seasonalData.seasonNumber.toString();
    this.diffJson = JSON.stringify(seasonalData, null, 2);
    this.name = seasonalData.seasonName;
    this.number = seasonalData.seasonNumber;

    this.dependencyItem = { type: MigrationType.seasonalData, id: this.diffId };
    this.dependencies = [];

    (this.seasonalData.seasonRewardPath?.tiers || []).forEach(tier => {
      (tier.rewards || []).forEach(reward => {
        if (reward.param && reward.param.itemId) {
          this.dependencies.push({ type: MigrationType.item, id: reward.param.itemId, message: `${reward.param.itemId} in the ${reward.param.catalogName} catalog is required for reward tier:${tier.name}` });
        }
      });
    });
  }
}

export class BlankoProgressionsDiff extends DiffItem {
  name: string;

  constructor(public blankoProgressions: BlankoProgressions) {
    super();
    this.diffId = blankoProgressions.id;
    this.diffJson = JSON.stringify({
      ...blankoProgressions,
      createdAt: undefined,
      updatedAt: undefined,
      deletedAt: undefined
    }, null, 2);
    this.name = blankoProgressions.name;

    this.dependencyItem = { type: MigrationType.blankoProgression, id: this.diffId };
    // Un comment once classes make it to Prod.
    // blankoProgressions.blankoClasses.forEach(className => {
    //   this.dependencies.push({type:'blankoClass', id: className});
    // });
    
  }
}

export class BlankoClassesDiff extends DiffItem {

  constructor(public blankoClasses: BlankoClass) {
    super();
    this.diffId = blankoClasses.name;
    this.diffJson = JSON.stringify({
      ...blankoClasses
    }, null, 2);
    this.dependencyItem = { type: MigrationType.blankoClass, id: this.diffId };
  }
}

export class TranslationDiff extends DiffItem {
  key: string;
  languageCode: string;

  constructor(public translation: Translation) {
    super();
    this.diffId = `${translation.key}.${translation.languageCode}`;
    this.diffJson = JSON.stringify(translation, null, 2);
    this.key = translation.key;
    this.languageCode = translation.languageCode;

    this.dependencyItem = { type: MigrationType.translation, id: this.diffId };
    this.dependencies = [{ type: MigrationType.textLanguage, id: translation.languageCode }];
  }
}

export class TextLanguageDiff extends DiffItem {
  code: string;

  constructor(public textLanguage: TextLanguage) {
    super();
    this.diffId = textLanguage.code;
    this.diffJson = JSON.stringify(textLanguage, null, 2);
    this.code = textLanguage.code;

    this.dependencyItem = { type: MigrationType.textLanguage, id: this.diffId };
  }
}

export class GemRushDiff extends DiffItem {
  public name: string;

  constructor(public rush: BlankoGemRushAndFuse) {
    super();
    this.diffId = rush.id;
    const diffJson: any = {
      name: rush.name,
      startDate: rush.startDate,
      endDate: rush.endDate,
      active: false,
      fuses: rush.fuses.map((fuse => {
        return {
          baseDnaId: fuse.baseDnaId,
          upgradeDnaId: fuse.upgradeDnaId,
          capstopne: fuse.capstone,
          imageName: this.getImageFilename(fuse.popupImageUrl || "")
        }
      }))
    };

    const blankoIds:string[] = [];
    rush.fuses.forEach(f => {
      blankoIds.push(f.baseDnaId);
      blankoIds.push(f.upgradeDnaId); 
    });

    this.diffJson = JSON.stringify(diffJson, null, 2);
    this.name = rush.name;

    this.dependencyItem = { type: MigrationType.gemRush, id: this.diffId };
    this.dependencies = [];
    blankoIds.filter((value, index, arr) => {
      return arr.indexOf(value) === index; //de-dupe
    }).forEach(bid => this.dependencies.push({
      type: MigrationType.item,
      id: bid
    }));
  }

  private getImageFilename(url: string) {
    const index = url.lastIndexOf('/');
    return index > -1 ? url.slice(index + 1) : url;
  }
}

export class ItemNoteDiff extends DiffItem {
  public itemId: string;
  public authorEmail: string;
  public createdAt: string;

  constructor(itemNote: ItemNote) {
    super();
    this.diffId = itemNote.id as string;
    const diffJson: any = {
      ...itemNote,
      updatedAt: undefined,
    };
    this.itemId = itemNote.itemId;
    this.authorEmail = itemNote.authorEmail;
    this.createdAt = format(new Date(itemNote.createdAt as string), 'yyyy/MM/dd HH:mm:ss');
    this.diffJson = JSON.stringify(diffJson, null, 2);
    this.dependencyItem = { type: MigrationType.itemNotes, id: this.diffId };
    this.dependencies = [{
      type: MigrationType.item, id: itemNote.itemId,
    }];
  }
}

export class GeoBlockingDiff extends DiffItem {
  public regions: string[];
  public featureId: string
  constructor(gbFeature: GeoBlockedFeatureMap) {
    super();
    this.regions = gbFeature.regions;
    this.featureId = `${gbFeature.featureId}`;
    this.diffId = `${gbFeature.featureId}`;
    const diffJson: any = {
      ...gbFeature,
    };
   
    this.diffJson = JSON.stringify(diffJson, null, 2);
    this.dependencyItem = { type: MigrationType.geoblocking, id: this.diffId };
  }
}

import { createAsyncAction } from 'typesafe-actions';
import type { Challenge } from '../../../services/player-challenges/challenges';

export const getChallengesAsync = createAsyncAction(
  'GET_CHALLENGES_REQUEST',
  'GET_CHALLENGES_SUCCESS',
  'GET_CHALLENGES_FAILURE'
)<void, Challenge[], string>();

export const createChallengeAsync = createAsyncAction(
  'CREATE_CHALLENGE_REQUEST',
  'CREATE_CHALLENGE_SUCCESS',
  'CREATE_CHALLENGE_FAILURE'
)<Challenge, Challenge, string>();

export const getChallengeAsync = createAsyncAction(
  'GET_CHALLENGE_REQUEST',
  'GET_CHALLENGE_SUCCESS',
  'GET_CHALLENGE_FAILURE'
)<string, Challenge, string>();

export const updateChallengeAsync = createAsyncAction(
  'UPDATE_CHALLENGE_REQUEST',
  'UPDATE_CHALLENGE_SUCCESS',
  'UPDATE_CHALLENGE_FAILURE'
)<Challenge, Challenge, string>();

export const deleteChallengeAsync = createAsyncAction(
  'DELETE_CHALLENGE_REQUEST',
  'DELETE_CHALLENGE_SUCCESS',
  'DELETE_CHALLENGE_FAILURE'
)<string, string, string>();

export const addNewChallengeAsync = createAsyncAction(
  'ADD_NEW_CHALLENGE_REQUEST',
  'ADD_NEW_CHALLENGE_SUCCESS',
  'ADD_NEW_CHALLENGE_FAILURE'
)<Challenge, Challenge, string>();

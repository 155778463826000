import { Component } from "react";
import type {RouteComponentProps} from "react-router";
import TransactionsGrid from "./TransactionGrid";

type Props = RouteComponentProps;

export default class TransactionsPage extends Component<Props> {

  render() {
    return <TransactionsGrid />
  }
}

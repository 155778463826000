import { api } from './api';
import { CurrencyList } from './model/currency';

export class CurrencyService {
  private static isFetchingCurrencies = false;
  private static currencies?: CurrencyList;

  static async loadCurrencies(): Promise<void> {
    if (!CurrencyService.currencies && !CurrencyService.isFetchingCurrencies) {
      CurrencyService.isFetchingCurrencies = true;
      try {
        CurrencyService.currencies = new CurrencyList(await api.get<any[]>({ url: '/currencies' }));
      } catch (e) {
        e.message = `Failed to get currencies. ${e.message}`;
        throw e;
      }
      CurrencyService.isFetchingCurrencies = false;
    }
  }

  static getCurrencies(): CurrencyList {
    return CurrencyService.currencies || new CurrencyList([]);
  }

  static getOrLoadCurrencies(): Promise<CurrencyList> {
    return CurrencyService.loadCurrencies().then(CurrencyService.getCurrencies);
  }
}
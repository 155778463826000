import { Box, Button, Card, CardContent, CardHeader, CircularProgress, FormControl, Grid, InputLabel, Link, MenuItem, Typography } from '@material-ui/core';
import type { FormikHelpers } from 'formik';
import { Field, Formik } from 'formik';
import { Select, TextField } from 'formik-material-ui';
import { Link as RouterLink } from 'react-router-dom';
import { Blanko, BlankosService } from '../../services/blankos';
import type { BlankoProgressionLevel, BlankoProgression } from '../../services/model/blanko';
import { blankoTierOptions } from '../../services/model/blanko';
import { UserService } from '../../services/user';
import { PlayersService } from '../players/players';
import { createValidator, Validators } from '../../utils/forms';
import { useEffect, useCallback, useState } from 'react';
import type { Player } from '../players/players';
import {usePushNotification} from "../../contexts/AppNotificationContext";

type OwnProps = {
  blanko?: Blanko;
}

interface FormValues {
  name: string;
  dnaId: string;
  experience: string;
  shelfLocation: string;
  dgoodSerial: string;
  tieredProgressionId: string;
  tierId: string;
  levelId: string;
  accessorySlots: string;
  emoteSlots: string;
  neutralPerkSlots: string;
  skillPerkSlots: string;
  blankoClass: string;
  personalityAddons: string;
}

const formValidator = createValidator<FormValues>({
  name: Validators.notBlank(),
  neutralPerkSlots: Validators.integer(0),
  skillPerkSlots: Validators.integer(0),
  experience: Validators.integer(0)
});

export const BlankoInfo = ({ blanko }: OwnProps) => {

  const [initialFormValues, setInitialFormValues] = useState<FormValues>();
  const [submitting, setSubmitting] = useState<((submitting: boolean) => void) | undefined>();
  const [player, setPlayer] = useState<Player>({} as Player);
  const [progressionsLoading, setProgressionsLoading] = useState(false);
  const [currentProgression, setCurrentProgression] = useState<BlankoProgression>();
  const [progressions, setProgressions] = useState<BlankoProgression[]>([]);
  const readOnly = !UserService.canUpdate('blankos');
  const pushNotification = usePushNotification()

  const blankoToFormValues = useCallback((): FormValues => {
    if (blanko) {
      return {
        name: blanko.name,
        dnaId: blanko.dnaId,
        experience: blanko.experience.toString(),
        shelfLocation: blanko.shelfLocation.toString(),
        dgoodSerial: blanko.dgoodSerial ? blanko.dgoodSerial.toString() : '-',
        tieredProgressionId: blanko.tieredProgressionId,
        tierId: blanko.tierId,
        levelId: blanko.levelId,
        accessorySlots: blanko.accessorySlots.join(', '),
        emoteSlots: blanko.emoteSlots.join(', '),
        neutralPerkSlots: blanko.neutralPerkSlots.toString(),
        skillPerkSlots: blanko.skillPerkSlots.toString(),
        blankoClass: blanko.blankoClass,
        personalityAddons: blanko.personalityAddons.join(', ')
      };
    } else {
      return {
        name: '',
        dnaId: '',
        experience: '',
        shelfLocation: '',
        dgoodSerial: '-',
        tieredProgressionId: '',
        tierId: '',
        levelId: '',
        accessorySlots: '',
        emoteSlots: '',
        neutralPerkSlots: '0',
        skillPerkSlots: '0',
        blankoClass: '',
        personalityAddons: ''
      };
    }
  }, [blanko]);


  const getData = useCallback(async () => {
    if (blanko && UserService.canRead('player')) {
      const playerResult = await PlayersService.getPlayer(blanko.ownerExternalId)
      setPlayer(playerResult);
    }
    const progressionsResult = await BlankosService.getProgressions();
    setProgressions(progressionsResult);

    const formValues = blankoToFormValues();
    setInitialFormValues(formValues);
  }, [blanko, blankoToFormValues]);

  useEffect(() => {
    getData();
  }, [getData]);

  const onSubmit = useCallback(async (values: FormValues, helpers: FormikHelpers<FormValues>) => {
    if (blanko) {
      const newBlanko = new Blanko({
        id: blanko.id,
        tieredProgressionId: values.tieredProgressionId,
        tierId: values.tierId,
        levelId: values.levelId,
        experience: parseInt(values.experience) || 0,
        accessorySlots: fieldToStringArray(values.accessorySlots),
        emoteSlots: fieldToStringArray(values.emoteSlots),
        neutralPerkSlots: parseInt(values.neutralPerkSlots) || 0,
        skillPerkSlots: parseInt(values.skillPerkSlots) || 0,
        personalityParameters: { animClips: {} },
        blankoClass: values.blankoClass,
        personalityAddons: fieldToStringArray(values.personalityAddons)
      });

      await BlankosService.updateBlanko(newBlanko);
      setSubmitting(helpers.setSubmitting);
      pushNotification({ type: 'success', message: `Blanko info updated` });
    }
  }, [blanko, pushNotification]);

  const fieldToStringArray = (value: string) => {
    return value.trim().split(',').map(v => v.trim()).filter(v => !!v);
  }

  const getTierName = (locName: string) => {
    const option = blankoTierOptions.find(v => v.id === locName);
    return option ? option.name : locName;
  }

  const getLevelName = (locName: string) => {
    const lvlNum = locName.match(/(\d+)/)
    return lvlNum ? `Level ${lvlNum[0]}` : locName;
  }

  const updateCurrentProgression = useCallback(async (progId: string) => {
    const progression = progressions.find(p => p.id === progId);
    if (progression && progression.tiers.length < 1) {
      setProgressionsLoading(true);
      const newProg = await BlankosService.getProgression(progId);
      setCurrentProgression(newProg);
      setProgressionsLoading(false);
    }
  }, [progressions]);

  const updateMetadata = useCallback(async (blankoId: string) => {
    await BlankosService.updateMetadata(blankoId);
  }, []);

  const generateSelfie = useCallback(async (blankoId: string, dnaId: string) => {
    await BlankosService.generateSelfie(blankoId, dnaId);
  }, []);

  return (<>
    {!blanko &&
      <Box textAlign="center">
        <CircularProgress />
      </Box>
    }
    {blanko && progressions && initialFormValues &&
      <Formik<FormValues>
        initialValues={initialFormValues}
        enableReinitialize
        validate={formValidator}
        validateOnMount={true}
        onSubmit={onSubmit}
      >
        {({ submitForm, isSubmitting, values, setFieldValue }) => {

        if (progressionsLoading) {
          return null;
        }

        const progression = currentProgression && currentProgression.id == values.tieredProgressionId ? currentProgression : progressions.find(p => p.id === values.tieredProgressionId);
        const tier = progression ? progression.tiers.find(t => t.id === values.tierId) : null;

        if (progression) {
          if (!tier && progression.tiers.length > 0) {
            setFieldValue('tierId', progression.tiers[0].id);
            return null;
          }
          if (progression.blankoClasses.length > 0 && progression.blankoClasses.indexOf(values.blankoClass) < 0) {
            setFieldValue('blankoClass', progression.blankoClasses[0]);
            return null;
          }
        }

        let level: BlankoProgressionLevel | null = null;
        if (tier) {
          level = tier.levels.find(l => l.id === values.levelId) || null;
          if (!level) {
            setFieldValue('levelId', tier.levels[0].id);
            return null;
          }
        }

        const switchingProgression = blanko ? blanko.tieredProgressionId !== values.tieredProgressionId : false;
        return (
          <Grid container spacing={3}>
            <Grid item xs={12} md={6} xl={4}>
              <Card>
                <CardHeader title="Info" />
                <CardContent>
                  <Grid container spacing={1}>
                    {UserService.canRead('player') && (
                      <Grid item xs={12}>
                        <InputLabel shrink>Owner</InputLabel>
                        {player ? (
                          <Link variant="subtitle1" component={RouterLink} to={`/players/${blanko.ownerExternalId}`}>
                            {player.displayName || blanko.ownerExternalId}
                          </Link>
                        ) : (
                          <CircularProgress size={20} />
                        )}
                      </Grid>
                    )}
                    <Grid item xs={12}>
                      <Field
                        component={TextField}
                        name="name"
                        type="text"
                        label="Name"
                        fullWidth
                        required
                        InputProps={{
                          readOnly: true
                        }}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Field
                        component={TextField}
                        name="dgoodSerial"
                        type="text"
                        label="dGood #"
                        fullWidth
                        InputProps={{
                          readOnly: true
                        }}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Field
                        component={TextField}
                        name="dnaId"
                        type="text"
                        label="DNA"
                        fullWidth
                        InputProps={{ readOnly: true }}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Field
                        component={TextField}
                        name="shelfLocation"
                        type="text"
                        label="Shelf location"
                        fullWidth
                        InputProps={{ readOnly: true }}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={() => {
                          updateMetadata(blanko.id).then(r => pushNotification({ type: "success", message: "Updated metadata" }));
                        }}
                      >
                        Refresh Metadata
                      </Button>
                      <Button
                        style={{marginLeft: 10}}
                        variant="contained"
                        color="primary"
                        onClick={() => {
                          generateSelfie(blanko.id, blanko.dnaId).then(r => pushNotification({ type: "success", message: "Requested selfie generation" }));
                        }}
                      >
                        Generate Selfie
                      </Button>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12} md={6} xl={4}>
              <Card>
                <CardHeader title="Progression" />
                <CardContent>
                  <Grid container spacing={1}>
                    <Grid item xs={12}>
                      <FormControl fullWidth>
                        <InputLabel>Progression table</InputLabel>
                        <Field
                          component={Select}
                          name="tieredProgressionId"
                          inputProps={{ readOnly }}
                          value="none"
                        >
                          <MenuItem value='none' key='none'><em>No progression</em></MenuItem>
                          {progressions.length > 0 && (
                            progressions.map(progression => (
                              <MenuItem key={progression.id} value={progression.id || 'N/A'}
                                onClick={() => {
                                  if (progression && progression.id) {
                                    updateCurrentProgression(progression.id)
                                  }
                                }
                                }>{progression.name || 'N/A'}</MenuItem>
                            ))
                          )}
                        </Field>
                      </FormControl>
                    </Grid>

                    {progression && (
                      <Grid item xs={12}>
                        <FormControl fullWidth>
                          <InputLabel>Class</InputLabel>
                          <Field
                            component={Select}
                            name="blankoClass"
                            inputProps={{ readOnly }}
                          >
                            {progression.blankoClasses.map(blankoClass => (
                              <MenuItem key={blankoClass} value={blankoClass}>{blankoClass}</MenuItem>
                            ))}
                          </Field>
                        </FormControl>
                      </Grid>
                    )}

                    {switchingProgression && (
                      <Grid item xs={12}>
                        <Box my={1}>
                          <Typography variant="subtitle1" color="primary">
                            Switching progression tables will reset the blanko.
                          </Typography>
                        </Box>
                      </Grid>
                    )}

                    {!switchingProgression && (<>
                      {progression && tier && (<>
                        <Grid item xs={6}>
                          <FormControl fullWidth>
                            <InputLabel>Grade</InputLabel>
                            <Field
                              component={Select}
                              name="tierId"
                              inputProps={{ readOnly }}
                            >
                              {progression.tiers.map(tier => (
                                <MenuItem key={tier.id} value={tier.id}>{getTierName(tier.name)}</MenuItem>
                              ))}
                            </Field>
                          </FormControl>
                        </Grid>
                        <Grid item xs={6}>
                          <FormControl fullWidth>
                            <InputLabel>Level</InputLabel>
                            <Field
                              component={Select}
                              name="levelId"
                              inputProps={{ readOnly }}
                            >
                              {tier.levels.map(level => (
                                <MenuItem key={level.id} value={level.id}>{getLevelName(level.name)}</MenuItem>
                              ))}
                            </Field>
                          </FormControl>
                        </Grid>
                      </>)}
                      <Grid item xs={12}>
                        <Field
                          component={TextField}
                          name="experience"
                          type="text"
                          label="Experience"
                          fullWidth
                          InputProps={{ readOnly }}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <Field
                          component={TextField}
                          name="accessorySlots"
                          type="text"
                          label="Accessory slots"
                          fullWidth
                          InputProps={{ readOnly }}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <Field
                          component={TextField}
                          name="emoteSlots"
                          type="text"
                          label="Emote slots"
                          fullWidth
                          InputProps={{ readOnly }}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <Field
                          component={TextField}
                          name="personalityAddons"
                          type="text"
                          label="Personality addons"
                          fullWidth
                          InputProps={{ readOnly }}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <Field
                          component={TextField}
                          name="skillPerkSlots"
                          type="text"
                          label="Skill perk slots"
                          fullWidth
                          InputProps={{ readOnly }}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <Field
                          component={TextField}
                          name="neutralPerkSlots"
                          type="text"
                          label="Neutral perk slots"
                          fullWidth
                          InputProps={{ readOnly }}
                        />
                      </Grid>
                    </>)}
                  </Grid>
                </CardContent>
              </Card>
            </Grid>

            {!readOnly && (
              <Grid item xs={12}>
                <Button
                  variant="contained"
                  color="primary"
                  disabled={isSubmitting}
                  onClick={submitForm}
                >
                  {isSubmitting ? (
                    <CircularProgress size={25} />
                  ) : 'Save'}
                </Button>
              </Grid>
            )}
          </Grid>)
      }}
      </Formik>
    }
  </>);
}
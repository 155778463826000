import { createAsyncAction, createAction } from 'typesafe-actions';
import type { RewardPathTierDto } from '../../../services/player-challenges/season-reward-path-tiers';

export const getRewardPathTiersAsync = createAsyncAction(
  'GET_SEASON_REWARD_PATH_TIER_REWARD_PATH_TIERS_REQUEST',
  'GET_SEASON_REWARD_PATH_TIERS_SUCCESS',
  'GET_SEASON_REWARD_PATH_TIERS_FAILURE'
)<{ seasonNumber: number, pathId: string }, RewardPathTierDto[], string>();

export const createRewardPathTierAsync = createAsyncAction(
  'CREATE_SEASON_REWARD_PATH_TIER_REQUEST',
  'CREATE_SEASON_REWARD_PATH_TIER_SUCCESS',
  'CREATE_SEASON_REWARD_PATH_TIER_FAILURE'
)<{ request: RewardPathTierCreateRequest }, RewardPathTier, string>();

export const getRewardPathTierAsync = createAsyncAction(
  'GET_SEASON_REWARD_PATH_TIER_REQUEST',
  'GET_SEASON_REWARD_PATH_TIER_SUCCESS',
  'GET_SEASON_REWARD_PATH_TIER_FAILURE'
)<{ seasonNumber: number, pathId: string, tierId: string }, RewardPathTierDto, string>();

export const updateRewardPathTierAsync = createAsyncAction(
  'UPDATE_SEASON_REWARD_PATH_TIER_REQUEST',
  'UPDATE_SEASON_REWARD_PATH_TIER_SUCCESS',
  'UPDATE_SEASON_REWARD_PATH_TIER_FAILURE'
)<{ request: RewardPathTierUpdateRequest }, RewardPathTier, string>();

export const deleteRewardPathTierAsync = createAsyncAction(
  'DELETE_SEASON_REWARD_PATH_TIER_REQUEST',
  'DELETE_SEASON_REWARD_PATH_TIER_SUCCESS',
  'DELETE_SEASON_REWARD_PATH_TIER_FAILURE'
)<{ seasonNumber: number, pathId: string, tierId: string }, { seasonNumber: number, pathId: string, tierId: string }, string>();


export const clearSeasonRewardPathTierError = createAction('SEASON_REWARD_PATH_CLEAR_ERROR')();
import { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { cmSetMigrationActions } from '../../../../redux/catalog-migration/actions';
import type { MigrationActions } from '../../../../redux/catalog-migration/reducer';
import { useTypedSelector } from '../../../../redux/reducers';
import { BlankoProgressionsDiff } from '../../types';
import { BlankoProgressions } from './BlankoProgressions';

export const BlankoProgressionsContainer = () => {
  const dispatch = useDispatch();

  const sourceProgressions = useTypedSelector(state => state.catalogMigration.source.blankoProgressions);
  const targetProgressions = useTypedSelector(state => state.catalogMigration.target.blankoProgressions);
  const actions = useTypedSelector(state => state.catalogMigration.actions.blankoProgressions);

  const [sourceItems, setSourceItems] = useState<BlankoProgressionsDiff[] | null>(null);
  const [targetItems, setTargetItems] = useState<BlankoProgressionsDiff[] | null>(null);

  const onSelectionChange = useCallback(
    (actions: MigrationActions) => dispatch(cmSetMigrationActions({ id: 'blankoProgressions', actions })),
    [dispatch]
  );

  useEffect(() => {
    if (!sourceProgressions || !targetProgressions) {
      return;
    }
    setSourceItems(sourceProgressions.map(x => new BlankoProgressionsDiff(x)));
    setTargetItems(targetProgressions.map(x => new BlankoProgressionsDiff(x)));
  }, [sourceProgressions, targetProgressions]);

  return (<BlankoProgressions
    sourceItems={sourceItems}
    targetItems={targetItems}
    actions={actions}
    onSelectionChange={onSelectionChange} />);
}
import Box from '@material-ui/core/Box';
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';
import { Component } from 'react';
import type { ConnectedProps } from 'react-redux';
import { connect } from 'react-redux';
import { loadCurrenciesAsync } from '../../redux/currencies/actions';
import { addCurrencyLimitAsync, deleteCurrencyLimitAsync, getCurrencyLimitsAsync, updateCurrencyLimitAsync } from '../../redux/currency-limits/actions';
import type { RootState } from '../../redux/reducers';
import { CurrencyLimit } from '../../services/currency-limits';
import type { CurrencyList } from '../../services/model/currency';
import CurrencyLimitCard, { CurrencyLimitCardActionType, CurrencyLimitCardItem } from './CurrencyLimitCard';

interface State {
  currencyList: CurrencyList,
  currencyLimits: CurrencyLimit[],
  currencyLimitMap: {[key: string]: CurrencyLimit},
  isCurrencyLimitsLoading: boolean
}

const mapStateToProps = (state: RootState) => {
  return ({ 
    currencyList: state.currencies.list, 
    currencyLimits: state.currencyLimits.data,
    currencyLimitMap: state.currencyLimits.data ? Object.assign({}, ...state.currencyLimits.data.map((cl: CurrencyLimit) => ({[cl.currencyCode]: cl}))) : {},    
    isCurrencyLimitsLoading: state.currencyLimits.isLoading,
    darkMode: state.app.darkMode 
  });
};

const mapDispatch = { 
  requestLoadCurrencies: loadCurrenciesAsync.request,
  requestGetCurrencyLimits: getCurrencyLimitsAsync.request,
  requestAddCurrencyLimit: addCurrencyLimitAsync.request,
  requestDeleteCurrencyLimit: deleteCurrencyLimitAsync.request,
  requestUpdateCurrencyLimit: updateCurrencyLimitAsync.request
};
const connector = connect(mapStateToProps, mapDispatch);

type Props = ConnectedProps<typeof connector>;

class CurrencyLimits extends Component<Props, State> {
  componentDidMount() {
    this.props.requestLoadCurrencies();
    this.props.requestGetCurrencyLimits();    
  }

  render() {
    if (this.props.isCurrencyLimitsLoading) {
      return (
        <Box textAlign="center">
          <CircularProgress />
        </Box>
      );
    }    

    const cardItems: CurrencyLimitCardItem[] = [];
    this.props.currencyList.getCurrencies().forEach(c => {
      const serviceCurrencyLimit = this.props.currencyLimitMap[c.code];
      let currencyLimit: CurrencyLimit;
      const title = c.name;
      let action: CurrencyLimitCardActionType = CurrencyLimitCardActionType.SAVE;
      if (!serviceCurrencyLimit) {
        currencyLimit = new CurrencyLimit({ currencyCode: c.code, limitPeriod: null, limitValue: null })
        action = CurrencyLimitCardActionType.CREATE;
      } else {
        currencyLimit = new CurrencyLimit({ 
          currencyCode: serviceCurrencyLimit.currencyCode, 
          limitPeriod: serviceCurrencyLimit.limitPeriod, 
          limitValue: serviceCurrencyLimit.limitValue 
        })
      }
      const cardItem = new CurrencyLimitCardItem(currencyLimit, title, action);
      cardItems.push(cardItem);
    });

    return (<>
      <Grid container spacing={3}>
        { 
          cardItems.map(function (c, i) {
            return (<Grid key={c.currencyLimit.currencyCode} item xs={12} md={6} xl={4}>              
              <CurrencyLimitCard currencyLimitItem={c}  />
            </Grid>)
          })
        }
      </Grid>
    </>);
  }
}

export default connector(CurrencyLimits);
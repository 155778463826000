import { Box, Typography } from '@material-ui/core';
import { DiffsPanelsContainer } from '../DiffsPanels';
import type { ItemDiffProps, TitleDataDiff } from '../../types';

interface TitleDataProps extends ItemDiffProps<TitleDataDiff> {
  type: 'client' | 'server';
}

export const TitleData = ({
  sourceItems,
  targetItems,
  actions,
  type,
  onSelectionChange
}: TitleDataProps) => (<>
  <Typography variant="h6">
    Title data - {type === 'client' ? 'Client' : 'Server'}
  </Typography>

  <Box mt={2}>
    {sourceItems && targetItems && (
      <DiffsPanelsContainer
        sourceItems={sourceItems}
        targetItems={targetItems}
        selectedIds={actions}
        columns={[
          { title: 'Key', field: 'diffId', defaultSort: 'asc' }
        ]}
        onSelectionChange={onSelectionChange}
      />
    )}
  </Box>
</>);

import type { MigrationDiffCommonProps } from '../../migrations';
import { DiffsPanels } from '../../components/diff/DiffsPanels';

export const ChallengeTriggerDiffs = (props: MigrationDiffCommonProps) => {
  const diffs = props.migrationData.challengeTriggers;

  if (diffs) {
    return (
      <DiffsPanels
        diffs={diffs}
        columns={[
          {
            title: 'Challenge Trigger', defaultSort: 'asc', render(item) {
              return (<>
                {item.name}
              </>);
            }
          },
        ]}
        selectionMap={props.selections.challengeTriggers || {}}
        onSelectionChange={map => props.onSelectionsChange({ ...props.selections, challengeTriggers: map })}
        {...props}
      />
    )
  }

  return null;
};

import type { MigrationDiffCommonProps } from '../../migrations';
import { DiffsPanels } from '../../components/diff/DiffsPanels';
import { FormControlLabel, Grid, Switch } from "@material-ui/core";
import React, { useState } from "react";

export const ItemsDiffs = (props: MigrationDiffCommonProps) => {

  const diffs = props.migrationData.items;
  const [showPipelineGenerated, setShowPipelineGenerated] = useState(false);

  if (diffs) {
    return (
      <DiffsPanels
        diffs={diffs}
        columns={[
          { title: 'Item ID', field: 'diffId', defaultSort: 'asc' },
          { title: 'Display name', field: 'displayName' },
          { title: 'Type', field: 'itemType' }
        ]}
        selectionMap={props.selections.item || {}}
        onSelectionChange={map => props.onSelectionsChange({ ...props.selections, item: map })}
        additionalFilter={(item) => {
          return showPipelineGenerated || !item.item.pipelineGenerated;
        }}
        {...props}
      >
        <Grid item xs="auto">
          <FormControlLabel
            control={
              <Switch
                checked={showPipelineGenerated}
                onChange={event => setShowPipelineGenerated(event.target.checked)}
                color="primary"
              />
            }
            label="Show pipeline generated items"
          />
        </Grid>
      </DiffsPanels>
    )
  }

  return null;
};

import {Theme, Tooltip,
  Box,
  Button,
  createStyles,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  makeStyles} from '@material-ui/core';
import {Field, Formik, useFormikContext} from 'formik';
import {CheckboxWithLabel, TextField} from 'formik-material-ui';
import * as Yup from 'yup';
import {useCallback, useEffect, useMemo, useState} from 'react';
import { usePushNotification } from '../../../contexts/AppNotificationContext';
import { DuplicateLiveEventRequest, LiveEvent } from '../live-events-types';
import { duplicateLiveEvent } from '../live-events-api';

const isQAText = "Marking an event as QA will remove duplicate challenges and set all daily/weekly challenges to be day 1, or week 1";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    form: {
      display: 'flex',
      flexDirection: 'column',
      margin: 'auto',
      width: 'fit-content',
      marginBottom: 8,
    },
    formControl: {
      marginTop: theme.spacing(2),
      minWidth: 400,
    },
  }),
);

interface DuplicateLiveEventDialogProps {
  liveEvent: LiveEvent,
  onCancel: () => void,
  onFinished: () => void,
}

interface DialogValues {
  sourceEventName: string,
  newEventName: string,
  repeating: boolean,
  isQA: boolean,
}

const validationSchema = Yup.object().shape({
  newEventName: Yup.string().required(),
});

interface ChangeDialogProps {
  onChange: (formValues: DialogValues) => void;
}
//Hooking additional activities in Formik is a pain. onChange isn't exposed
//this lets us react to changes without intercepting & relaying.
const LiveEventOnChangeDialogComponent = (props: ChangeDialogProps) => {
  const { values } = useFormikContext<DialogValues>();
  const [isQA, setIsQA] = useState<boolean>(false);
  const [repeating, setRepeating] = useState<boolean>(false);

  useEffect(() => {
    if (isQA != values.isQA) {
      setIsQA(values.isQA);
      props.onChange(values);
    } else if (repeating != values.repeating) {
      setRepeating(values.repeating);
      props.onChange(values);
    }
  }, [values, props, isQA, setIsQA, repeating, setRepeating]);

  return null;
}

export const DuplicateLiveEventDialog = ({ liveEvent, onCancel, onFinished }: DuplicateLiveEventDialogProps) => {
  const pushNotification = usePushNotification();
  const classes = useStyles();
  const [isQA, setIsQA] = useState(false);
  const [repeating, setRepeating] = useState(false);

  const initialValues: DialogValues = useMemo(() => {
    const namePrefix = isQA ? 'QA Test - ' : '';
    return {
      sourceEventName: liveEvent.name,
      newEventName: `${namePrefix}${liveEvent.name} (Copy)`,
      repeating: repeating,
      isQA: isQA,
    };
  }, [liveEvent, isQA, repeating]);

  const isRepeatingEvent = useMemo(() => {
    const repeating = liveEvent.repeatable && liveEvent.repeatCount > 0 && liveEvent.repeatCloneEndTime === null;
    setRepeating(repeating);
    return repeating;
  }, [liveEvent]);

  const valueChanged = useCallback((values: DialogValues) => {
    setIsQA(values.isQA);
    setRepeating(values.repeating);
  }, []);

  const onSubmit = useCallback(async (values: Partial<DialogValues>) => {
    if (!values || !values['newEventName'])
      return;

    try {
      const request: DuplicateLiveEventRequest = {
        newLiveEventName: values['newEventName'],
        repeating: values['repeating'] === true,
        isQA: values['isQA'] === true,
      };
      await duplicateLiveEvent(liveEvent, request);
      onFinished();
    } catch (e) {
      pushNotification({type: 'error', message: `Failed to duplicate live event: ${e}`})
    }
  }, [liveEvent, onFinished, pushNotification]);

  return (
    <>
      <Dialog
        open={true}
        fullWidth
        onClose={onCancel}
        aria-labelledby="add-dialog-title"
      >
        <Formik<DialogValues>
          initialValues={initialValues}
          enableReinitialize
          validationSchema={validationSchema}
          validateOnMount={true}
          onSubmit={onSubmit}
        >
          {formikData => (<form onSubmit={formikData.handleSubmit}>
            <LiveEventOnChangeDialogComponent onChange={valueChanged} />
            <DialogTitle id="add-dialog-title">Duplicate Live Event</DialogTitle>
            <DialogContent className={classes.form}>
              <Box display="flex" flexDirection="column" justifyContent="center" className={classes.formControl}>
                <Field
                  component={TextField}
                  label={"Event to Duplicate"}
                  name="sourceEventName"
                  id="sourceEventName"
                  InputProps={{readOnly: true}}
                />
              </Box>
              {isRepeatingEvent &&
                <Box display="flex" flexDirection="column" justifyContent="center" className={classes.formControl}>
                  <Field
                    component={CheckboxWithLabel}
                    type="checkbox"
                    name="repeating"
                    Label={{ label: 'Duplicate as Repeatable Event' }}
                  />
                </Box>
              }
              {!repeating &&
                <>
                  <Box display="flex" flexDirection="column" justifyContent="center" className={classes.formControl}>
                    <Field
                      component={CheckboxWithLabel}
                      type="checkbox"
                      name="isQA"
                      Label={{ label: <Tooltip disableFocusListener title={isQAText} placement="right" arrow><span>QA Event</span></Tooltip> }}
                    />
                  </Box>
                  <Box display="flex" flexDirection="column" justifyContent="center" className={classes.formControl}>
                    <Field
                      component={TextField}
                      label={"New Event Name"}
                      name="newEventName"
                      type="text"
                      id="newEventName"
                    />
                  </Box>
                </>
              }
            </DialogContent>
            <DialogActions>
              <Button
                type="submit"
                variant="contained"
                disabled={!formikData.isValid}
                color="primary"
              >
                OK
              </Button>
              <Button onClick={onCancel} color="primary">
                Cancel
              </Button>
            </DialogActions>
          </form>)}
        </Formik>
      </Dialog>
    </>
  );
}

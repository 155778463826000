import { Box, Button, Grid, TextField, Typography } from '@material-ui/core';
import type { Column } from 'material-table';
import { useEffect, useState } from 'react';
import MaterialTable from '../../../components/MaterialTable';
import type { BetaGrantResponse } from '../../../services/mythical-accounts';
import DoneIcon from '@material-ui/icons/Done';
import ErrorIcon from '@material-ui/icons/Error';

interface GrantErrorRow {
  email: string;
  error: string;
}

const tableColumns: Column<GrantErrorRow>[] = [
  { title: 'Email', field: 'email' },
  { title: 'Error', field: 'error' }
];

interface Props {
  results: BetaGrantResponse;
}

export const BetaAccessGrantResults = (props: Props) => {

  const [errors, setErrors] = useState<GrantErrorRow[]>([]);
  const [emailsText, setEmailsText] = useState('');
  const [viewErrorsAsTable, setViewErrorsAsTable] = useState(true);

  useEffect(() => {
    const errors: GrantErrorRow[] = [];
    for (const email in props.results.notAddedReasons) {
      errors.push({ email, error: props.results.notAddedReasons[email] });
    }
    setErrors(errors);
    setEmailsText(errors.map(v => v.email).join('\n'));
  }, [props.results]);

  const successCount = props.results.playersAddedCount;
  return (<>
    {successCount > 0 && (
      <Grid container spacing={2} alignItems="center">
        <Grid item xs="auto">
          <DoneIcon fontSize="large" htmlColor="#00b116" />
        </Grid>
        <Grid item xs>
          <Typography variant="h6">
            Updated beta access for {successCount} {successCount > 1 ? 'accounts' : 'account'}
          </Typography>
        </Grid>
      </Grid>
    )}
    {errors.length > 0 && (<>
      <Grid container spacing={2} alignItems="center">
        <Grid item xs="auto">
          <ErrorIcon fontSize="large" color="error" />
        </Grid>
        <Grid item xs>
          <Typography variant="h6">
            Failed to update access for {errors.length} {errors.length > 1 ? 'accounts' : 'account'}
          </Typography>
        </Grid>
      </Grid>

      <Box my={2}>
        <Button variant="outlined" onClick={() => setViewErrorsAsTable(!viewErrorsAsTable)}>
          View as {viewErrorsAsTable ? 'text' : 'table'}
        </Button>
      </Box>

      {viewErrorsAsTable ? (
        <MaterialTable
          title="Errors"
          columns={tableColumns}
          data={errors}
        />
      ) : (
        <TextField
          type="text"
          label="Emails"
          value={emailsText}
          multiline
          rows={10}
          rowsMax={10}
          fullWidth
          InputProps={{ readOnly: true }}
        />
      )}
    </>)}
  </>)
}
import { CircularProgress, createStyles, Typography } from '@material-ui/core';
import CssBaseline from '@material-ui/core/CssBaseline';
import type { Theme, WithStyles } from '@material-ui/core/styles';
import { ThemeProvider, withStyles } from '@material-ui/core/styles';
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import ace from 'ace-builds';
import 'ace-builds/src-noconflict/mode-json';
import 'ace-builds/src-noconflict/theme-chrome';
import 'ace-builds/src-noconflict/theme-dracula';
import { Component } from 'react';
import { QueryClient, QueryClientProvider } from "react-query";
import type { ConnectedProps } from 'react-redux';
import { connect } from 'react-redux';
import { Route, Switch } from 'react-router-dom';
import { AdminConfirmationDialog } from './components/AdminConfirmationDialog';
import AppConfirmationDialog from './components/AppConfirmationDialog';
import MainAppBar from './components/MainAppBar';
import SideBar from './components/SideBar/SideBar';
import config from './config';
import { AppNotificationProvider } from "./contexts/AppNotificationContext";
import { Artists } from './features/artists';
import { BlankoPage } from './features/blankos/pages/BlankoPage';
import { BlankosPage } from './features/blankos/pages/BlankosPage';
import { BlockPage } from './features/blocks/pages/BlockPage';
import { BlocksPage } from './features/blocks/pages/BlocksPage';
import { PublishedBlockPage } from "./features/blocks/pages/PublishedBlockPage";
import { BrawlPage } from './features/brawl/pages/BrawlPage';
import { CatalogPage } from './features/catalogs/components/CatalogPage';
import { Catalogs } from "./features/catalogs/components/Catalogs";
import { GemRush, GemRushFuses } from './features/gem-rush';
import { IVIConfigPage } from "./features/ivi-config/pages/IVIConfigPage";
import { MigrationPage } from './features/migrations/pages/MigrationPage';
import { MythicalAccountsPage } from './features/mythical-accounts/components/MythicalAccountsPage';
import { PlayerPage } from './features/players/components/PlayerPage';
import { Reward } from "./features/rewards/components/Reward";
import { RewardsPage } from "./features/rewards/components/RewardsPage";
import { ShopTemplates } from './features/shop-templates/pages/ShopTemplates';
import { ShopPage } from './features/shops/pages/ShopPage';
import { Shops } from './features/shops/pages/Shops';
import BlankoProgressionPage from './pages/BlankoProgressions/BlankoProgressionPage';
import { BlankoProgressions } from './pages/BlankoProgressions/BlankoProgressions';
import CatalogMigrationPage from './pages/CatalogMigration/Pages/CatalogMigrationPage';
import DropTablePage from './pages/DropTable/DropTablePage';
import Home from './pages/Home';
import AddItemDefinitionPage from './pages/ItemDefinition/AddItemDefinitionPage';
import ItemDefinitionPage from './pages/ItemDefinition/ItemDefinitionPage';
import { LauncherPage } from './features/launcher/pages/LauncherPage';
import StorePage from './pages/Store/StorePage';
import TitlePage from './pages/Title/TitlePage';
import TransactionsPage from './pages/Transactions/TransactionsPage';
import { setDarkMode } from './redux/app/actions';
import type { RootState } from './redux/reducers';
import { UserService } from './services/user';
import { darkTheme, lightTheme } from './theme';
import Season from './pages/SeasonalData/Season';
import DateFnsUtils from "@date-io/date-fns";
import { RolesPage } from "./features/roles-permissions/RolesPage";
import { PublishBrawlPinsPage } from './features/migrations/pages/PublishBrawlPinsPage';
import { BrawlPinEditor } from './features/brawl/components/BrawlPinEditor';
import {LogsPage} from "./pages/Logs/LogsPage";
import {LocalizationPage} from "./features/localization/components/LocalizationPage";
import { LiveEventsPage } from './features/live-events/components/LiveEventsPage';
import { JunctionEventInfoEditor } from './features/live-events/components/JunctionEventInfoEditor';
import {GeoBlockingPage} from "./features/geoblocking/components/GeoBlockingPage";
import { SkillDropTablePage } from './pages/BlankoProgressions/SkillDropTablePage';
import { LiveEvents } from './features/live-events/components/LiveEvents';
import Challenges from './pages/SeasonalData/Challenges';

// HACK: Grab ace editor worker js from CDN until we figure out a better way
ace.config.setModuleUrl('ace/mode/json_worker', "https://cdn.jsdelivr.net/npm/ace-builds@1.4.8/src-noconflict/worker-json.js");

const styles = (theme: Theme) => createStyles({
  root: {
    display: 'flex',
  },
  toolbar: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    padding: theme.spacing(3)
  },
  centerContent: {
    flexGrow: 1,
    padding: theme.spacing(3),
    textAlign: 'center'
  }
});

const mapStateToProps = (state: RootState) => ({ authenticated: state.authentication.authenticated, darkMode: state.app.darkMode });
const mapDispatch = { setDarkMode };
const connector = connect(mapStateToProps, mapDispatch);

type Props = WithStyles<typeof styles> & ConnectedProps<typeof connector>;

interface State {
  userLoaded: boolean;
  userLoadError: boolean;
}

class App extends Component<Props, State> {

  constructor(props: Props) {
    super(props);
    this.state = {
      userLoaded: false,
      userLoadError: false
    };
  }

  componentDidMount() {
    UserService.getPermissions().then(() => {
      this.setState({ userLoaded: true });
    }).catch(err => {
      console.error(err);
      this.setState({ userLoadError: true });
    });

    this.props.setDarkMode(!!window.localStorage.darkMode);  // TODO: User preferences (+ backend support)
  }

  renderContent() {
    const classes = this.props.classes;

    if (this.state.userLoadError) {
      return (
        <main className={classes.centerContent}>
          <Typography variant="h6">
            Failed to reach the server.
          </Typography>
        </main>
      );
    }

    if (this.props.authenticated && this.state.userLoaded) {
      return (<MuiPickersUtilsProvider utils={DateFnsUtils}>
        <MainAppBar />
        <SideBar />
        <main className={classes.content}>
          <div className={classes.toolbar} />
          <Switch>
            {UserService.canRead('currencyLimits') && <Route path="/players/currency-limits" component={MythicalAccountsPage} />}
            {UserService.canCreate('playerInventoryBulk') && <Route path="/players/grants" component={MythicalAccountsPage} />}
            {UserService.canRead('player') && <Route path="/players/reported" component={MythicalAccountsPage} />}
            {UserService.canRead('player') && <Route path="/players/:playerId" component={PlayerPage} />}
            {UserService.canRead('publishedBlocks') && <Route path="/published-blocks/:publishedBlockId" component={PublishedBlockPage} />}
            {UserService.canRead('blankos') && <Route path="/blankos/:blankoId" component={BlankoPage} />}
            {UserService.canRead('blocks') && <Route path="/blocks/:blockId" component={BlockPage} />}
            {UserService.canRead('store') && <Route path="/catalogs/:catalogName/stores/:storeId" component={StorePage} />}
            {UserService.canRead('catalogItems') && <Route path="/catalogs/:catalogName/drop-tables/:dropTableId" component={DropTablePage} />}
            {UserService.canRead('catalogItems') && <Route path="/catalogs/:catalogName/items/:itemId" component={ItemDefinitionPage} />}
            {UserService.canCreate('catalogItems') && <Route path="/catalogs/:catalogName/add-drop-table" component={DropTablePage} />}
            {UserService.canCreate('catalogItems') && <Route path="/catalogs/:catalogName/add-item" component={AddItemDefinitionPage} />}
            {UserService.canRead('catalogs') && <Route path="/catalogs/:catalogName" component={CatalogPage} />}
            {UserService.canRead('catalogItems') && <Route path="/artists" component={Artists} />}
            {UserService.canUpdate('roles') && <Route path="/roles/edit/:roleId" component={RolesPage} />}
            {UserService.canCreate('roles') && <Route path="/roles/clone/:roleId" component={RolesPage} />}
            {UserService.canCreate('roles') && <Route path="/roles/new" component={RolesPage} />}
            {UserService.canRead('roles') && <Route path="/roles" component={RolesPage} />}
            {UserService.canUpdate('mythicalAccount') && <Route path="/accounts/beta-access" component={MythicalAccountsPage} />}
            {UserService.canUpdate('mythicalAccount') && <Route path="/accounts/tags" component={MythicalAccountsPage} />}
            {(UserService.canRead('player') || UserService.canCreate('playerInventoryBulk')) && <Route path="/players" component={MythicalAccountsPage} />}
            {config.apis.mythical.authProvider !== 'fusionauth' && UserService.canRead('mythicalAccount') && <Route path="/accounts" component={MythicalAccountsPage} />}
            {UserService.canRead('title') && <Route path="/title" component={TitlePage} />}
            {UserService.canRead('publishedBlocks') && <Route path="/published-blocks" component={BlocksPage} />}
            {UserService.canRead('publishedBlocks') && <Route path="/recommendation-settings" component={BlocksPage} />}
            {UserService.canRead('blocks') && <Route path="/reported-blocks" component={BlocksPage} />}
            {/* If we need to bring this page back we'll need to add blanko pagination to the backend first. */}
            {UserService.canRead('blankos') && config.env !== 'prod' && <Route path="/blankos" component={BlankosPage} />}
            {UserService.canRead('blankos') && <Route path="/progressions/:progressionId" component={BlankoProgressionPage} />}
            {UserService.canRead('blankos') && <Route path="/progressions" component={BlankoProgressions} />}
            {UserService.canRead('blankos') && <Route path="/progression-skill-drops/:tableId" component={SkillDropTablePage} />}
            {UserService.canRead('blankos') && <Route path="/progression-skill-drops" component={BlankoProgressions} />}
            {UserService.canRead('blocks') && <Route path="/blocks" component={BlocksPage} />}
            {UserService.canRead('catalogs') && <Route path="/catalogs" component={Catalogs} />}
            {UserService.canRead('auditLogs') && <Route path="/logs" component={LogsPage} />}
            {UserService.canRead('commerce') && <Route path="/transactions" component={TransactionsPage} />}
            {UserService.canRead('clientVersion') && <Route path="/launcher" component={LauncherPage} />}
            {UserService.canMigrateFrom() && <Route path="/catalog-migration" component={MigrationPage} />}
            {UserService.canMigrateFrom() && <Route path="/catalog-migration-v1" component={CatalogMigrationPage} />}
            {UserService.canRead('rewards') && <Route path="/rewards/reward/create" component={Reward} />}
            {UserService.canRead('rewards') && <Route path="/rewards/account-links" component={RewardsPage} />}
            {UserService.canRead('rewards') && <Route path="/rewards/fulfillments" component={RewardsPage} />}
            {UserService.canRead('rewards') && <Route path="/rewards/:rewardId" component={Reward} />}
            {UserService.canRead('rewards') && <Route path="/rewards" component={RewardsPage} />}
            {UserService.canRead('blankos') && <Route path="/gem-rush/:rushId" component={GemRushFuses} />}
            {UserService.canRead('blankos') && <Route path="/gem-rush" component={GemRush} />}
            {UserService.canRead('catalogItems') && <Route path="/ivi-config/item-tokens" component={IVIConfigPage} />}

            {UserService.canRead('store') && <Route path="/shops/:shopId" component={ShopPage} />}
            {UserService.canRead('store') && <Route path="/shops" component={Shops} />}
            {UserService.canRead('store') && <Route path="/shop-templates" component={ShopTemplates} />}

            {UserService.canRead('publishedBlocks') && <Route path="/brawl-blocks" component={BrawlPage} />}
            {UserService.canRead('blocks') && <Route path="/brawl-blocks-unpublished" component={BrawlPage} />}
            {UserService.canRead('brawlPins') && <Route path="/brawl-pins/:pinId" component={BrawlPinEditor} />}
            {UserService.canRead('brawlPins') && <Route path="/brawl-pins" component={BrawlPage} />}
            {UserService.canRead('brawlPins') && <Route path="/brawl-pins-publish" component={PublishBrawlPinsPage} />}
            {UserService.canRead('lokalise') && <Route path="/localization" component={LocalizationPage} />}
            {UserService.canRead('liveEvents') && <Route path="/live-events/edit/:liveEventId" render={(props) => <LiveEvents {...props} key={Date.now()}/>} />}
            {UserService.canCreate('liveEvents') && <Route path="/live-events/new" component={LiveEvents} />}
            {UserService.canRead('liveEvents') && <Route path="/live-events/:liveEventId/junctions/:junctionEventInfoId" component={JunctionEventInfoEditor} />}
            {UserService.canCreate('liveEvents') && <Route path="/live-events/:liveEventId/junctions/new" component={JunctionEventInfoEditor} />}
            {UserService.canRead('liveEvents') && <Route path="/live-events/:liveEventId/seasons/:seasonNumber" component={Season} />}
            {UserService.canRead('liveEvents') && <Route path="/live-events" component={LiveEventsPage} />}
            {UserService.canRead('geoblocking') && <Route path="/geo-blocking" component={GeoBlockingPage} />}
            <Route path="/" component={Home} />
          </Switch>
        </main>
        <AppConfirmationDialog />
        <AdminConfirmationDialog />
      </MuiPickersUtilsProvider>);
    }

    return (
      <main className={classes.centerContent}>
        <CircularProgress />
      </main>
    );
  }

  render() {
    const classes = this.props.classes;
    return (
      <div className={classes.root}>
        <ThemeProvider theme={this.props.darkMode ? darkTheme : lightTheme}>
          <QueryClientProvider client={new QueryClient()}>
            <CssBaseline />
            <AppNotificationProvider>
              {this.renderContent()}
            </AppNotificationProvider>
          </QueryClientProvider>
        </ThemeProvider>
      </div>
    );
  }
}

export default connector(withStyles(styles)(App));


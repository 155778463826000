import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@material-ui/core";
import { useState } from "react";

interface Props {
  onClose: () => void;
  onSelectItem: () => void;
  onSelectMarketplaceItemDataTag: () => void;
}

export const ShopTabItemCreateDialog = (props: Props) => {
  const [open, setOpen] = useState(true);
  return <Dialog
    open={open}
    onClose={() => setOpen(false)}
    onExited={props.onClose}
  >
    <DialogContent>
      <DialogTitle>Select tab item type:</DialogTitle>
      <DialogActions>
        <Button
          onClick={() => {
            setOpen(false);
            props.onSelectItem();
          }}
          variant='outlined'
        >
          Item
        </Button>
        <Button
          onClick={() => {
            setOpen(false);
            props.onSelectMarketplaceItemDataTag();
          }}
          variant='outlined'
        >
          Marketplace Item Data Tag
        </Button>
      </DialogActions>
    </DialogContent>
  </Dialog>;
};

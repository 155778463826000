import { DiffItem } from '../../../../pages/CatalogMigration/types';
import type { Shop, ShopVersion } from '../../../shops/shop';
import { MigrationType } from "../migration-type";

export class ShopVersionDiff extends DiffItem {
  constructor(public shop: Shop, public shopVersion: ShopVersion) {
    super();
    this.diffId = `${shop.name}.${shopVersion.name}`;

    const itemIdMap: { [key: string]: string } = {};
    shopVersion.items.forEach(item => itemIdMap[item.id] = item.itemId);
    shopVersion.tabs.forEach(tab => tab.items.forEach(item => {
      if (item.shopItemId) {
        item.itemId = itemIdMap[item.shopItemId];
      }
    }));

    const json: any = {
      catalogName: shopVersion.catalogName,
      startDate: shopVersion.startDate,
      preloadStartDate: shopVersion.preloadStartDate
    };
    shopVersion.items.sort((a, b) => a.itemId.localeCompare(b.itemId));
    shopVersion.tabs.sort((a, b) => a.position - b.position);
    json.items = shopVersion.items.map(v => ({ ...v, id: undefined, shopVersionId: undefined }));
    json.tabs = shopVersion.tabs.map(v => ({
      ...v,
      id: undefined,
      position: undefined,
      shopVersionId: undefined,
      items: v.items.map(v => ({
        ...v,
        image: v.imageUrl === null ? null : this.getImageFilename(v.imageUrl),
        imageUrl: undefined,
        id: undefined,
        shopTabId: undefined,
        shopItemId: undefined,
        position: undefined
      }))
    }));
    this.diffJson = JSON.stringify(json, null, 2);

    this.dependencyItem = { type: MigrationType.shopVersion, id: this.diffId };
    this.dependencies = shopVersion.items.map(item => ({ type: MigrationType.item, id: item.itemId }));
  }

  private getImageFilename(url: string) {
    const index = url.lastIndexOf('/');
    return index > -1 ? url.slice(index + 1) : url;
  }
}

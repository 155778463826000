import { FormControl, FormControlLabel, FormGroup, Switch } from '@material-ui/core';
import { Player, PlayersService } from '../players';
import { UserService } from '../../../services/user';
import { pushAppNotification } from "../../../shared/hooks/useAppNotification";

type Props = {
  player: Player;
  setPlayer: (player: Player) => void;
}

export const PlayerPermissions = ({player, setPlayer}: Props) => {
  const onToggleBuildModeClick = () => {
    if (UserService.canUpdate('playerModeration')) {
      PlayersService.togglePlayerBuildModeEnabled(player)
        .then(player => {
          pushAppNotification({type: 'success', message: `Player updated`});
          setPlayer(player);
        })
        .catch(err => pushAppNotification({
          type: 'error',
          message: `Failed to toggle player build mode: ${err.message}`
        }));
    }
  };

  const onToggleBetaClick = () => {
    if (UserService.canUpdate('player')) {
      const newValue = !(player.playerData['System.BetaAccessEnabled']?.value === 'true');
      const updatedPlayer: Player = new Player({
        ...player,
        playerData: {
          ...player.playerData,
          'System.BetaAccessEnabled': {
            ownerPermission: 'read',
            othersPermission: 'none',
            value: newValue.toString(),
          },
        },
      });
      PlayersService.updatePlayer(updatedPlayer)
        .then(player => {
          pushAppNotification({type: 'success', message: `Player updated`});
          setPlayer(player);
        })
        .catch(err => pushAppNotification({
          type: 'error',
          message: `Failed to update beta access state: ${err.message}`
        }));
    }
  };

  return (
    <FormControl fullWidth>
      <FormGroup row>
        <FormControlLabel
          control={
            <Switch
              checked={player.canBuild()}
              onChange={onToggleBuildModeClick}
              color="primary"
            />
          }
          label="Build Mode Enabled"
        />
        <FormControlLabel
          control={
            <Switch
              checked={player.playerData['System.BetaAccessEnabled']?.value === 'true'}
              onChange={onToggleBetaClick}
              color="primary"
            />
          }
          label="Beta"
        />
      </FormGroup>
    </FormControl>
  );
};

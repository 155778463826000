import { RouteComponentProps, useHistory, useLocation, useRouteMatch } from "react-router";
import { LinkTabConfig, LinkTabs } from "../../../components/LinkTabs";
import Challenges from "../../../pages/SeasonalData/Challenges";
import { LiveEvents } from "./LiveEvents";

type Props = RouteComponentProps<{ liveEventId?: string; }>;

export const LiveEventsPage = () => {
  const tabs: LinkTabConfig[] = [];
  tabs.push({ url: '/live-events', label: 'Live Events', absolute: true, component: LiveEvents });
  tabs.push({ url: '/live-events/challenges', label: 'Global Challenges', absolute: true, component: Challenges });

  const history = useHistory();
  const location = useLocation();
  const match = useRouteMatch();

  return (<>
    <LinkTabs tabs={tabs} history={history} location={location} match={match} />
  </>);
};

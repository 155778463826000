import type { SelectProps } from "formik-material-ui";
import { Select } from "formik-material-ui";
import { InputLabel } from "@material-ui/core";
import { useMemo } from "react";
import { getDisplayValue } from "./SelectWithLabel";

const COLOR_ERROR = '#f44336';

/**
 * Fixes the fact that Select doesn't show the label for some reason.
 */
export const FormikSelectWithLabel = (props: SelectProps) => {
  const showError = useMemo(() => {
    return Boolean(props.form.touched[props.field.name] && props.form.errors[props.field.name]);
  }, [props.form.touched, props.form.errors, props.field.name]);
  const populated = Boolean(props.field.value);

  return (<>
    {<InputLabel
      shrink
      htmlFor={props.id}
      style={showError ? {
        color: COLOR_ERROR,
      } : {}}
    >
      {populated && props.label || '\u00A0'}
    </InputLabel>}
    <Select
      {...props}
      error={showError}
      renderValue={(value) => <>{getDisplayValue(value, props.children) ||
        <InputLabel style={showError ? {
          color: COLOR_ERROR,
        } : {}}>{props.label}</InputLabel>}</>}
      displayEmpty={true}
    />
    {showError && <InputLabel
      shrink
      htmlFor={props.id}
      style={{
        marginTop: '6px',
        color: COLOR_ERROR,
      }}
    >
      {props.form.errors[props.field.name]}
    </InputLabel>}
  </>);
};

import type { Role } from "../../../roles-permissions/rolesPermissions";
import { normalizeRole } from "../../../roles-permissions/rolesPermissions";
import { DiffItem } from "../../../../pages/CatalogMigration/types";
import { MigrationType } from "../migration-type";

export class RoleDiff extends DiffItem {
  public name: string;

  constructor(role: Role) {
    super();
    this.diffId = role.name;
    this.name = role.name;
    normalizeRole(role);
    const diffJson = {
      name: role.name,
      apiLevel: role.apiLevel,
      permissions: role.permissions,
    };
    this.dependencyItem = {
      type: MigrationType.roles,
      id: this.name,
    };
    this.diffJson = JSON.stringify(diffJson, null, 2);
  }
}

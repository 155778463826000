import { call, put, takeLatest } from 'redux-saga/effects';
import type { Challenge} from '../../../services/player-challenges/challenges';
import { ChallengesService } from '../../../services/player-challenges/challenges';
import { setAppNotification } from '../../app/actions';
import { forkSagas, sagaHandleApiError } from '../../utils-ts';
import {
  deleteChallengeAsync,
  getChallengeAsync,
  getChallengesAsync,
  updateChallengeAsync,
  createChallengeAsync,
  addNewChallengeAsync
} from './actions';

function* fetchChallenges(action: ReturnType<typeof getChallengesAsync.request>) {
  try {
    const seasons: Challenge[] = yield call(ChallengesService.getChallenges);
    yield put(getChallengesAsync.success(seasons));
  } catch (e) {
    yield sagaHandleApiError(e, getChallengesAsync.failure);
  }
}

function* fetchChallenge(action: ReturnType<typeof getChallengeAsync.request>) {
  try {
    const season: Challenge = yield call(ChallengesService.getChallenge, action.payload);
    yield put(getChallengeAsync.success(season));
  } catch (e) {
    yield sagaHandleApiError(e, getChallengeAsync.failure);
  }
}

function* createChallengeSaga(action: ReturnType<typeof createChallengeAsync.request>) {
  try {
    
    const season: Challenge = yield call(ChallengesService.createChallenge, action.payload);
    yield put(createChallengeAsync.success(season));
    yield put(setAppNotification({ type: 'success', message: 'Challenge created' }));
  } catch (e) {
    yield sagaHandleApiError(e, createChallengeAsync.failure);
  }
}

function* updateChallengeSaga(action: ReturnType<typeof updateChallengeAsync.request>) {
  try {
    
    const season: Challenge = yield call(ChallengesService.updateChallenge, action.payload);
    yield put(updateChallengeAsync.success(season));
    yield put(setAppNotification({ type: 'success', message: 'Challenge updated' }));
  } catch (e) {
    yield sagaHandleApiError(e, updateChallengeAsync.failure);
  }
}

function* deleteChallengeSaga(action: ReturnType<typeof deleteChallengeAsync.request>) {
  try {
    yield call(ChallengesService.deleteChallenge, action.payload);
    yield put(deleteChallengeAsync.success(action.payload));
    yield put(setAppNotification({ type: 'success', message: 'Challenge deleted' }));
  } catch (e) {
    yield sagaHandleApiError(e, deleteChallengeAsync.failure);
  }
}

function* addNewChallengeSaga(action: ReturnType<typeof addNewChallengeAsync.request>) {
  try{
    yield put(addNewChallengeAsync.success(action.payload));
    yield put(setAppNotification({type:'success', message: 'Challenge duplicated'}));
  } catch(e) {
    yield sagaHandleApiError(e, addNewChallengeAsync.failure);
  }
}

const sagas = [
  function* () {
    yield takeLatest(getChallengesAsync.request, fetchChallenges);
  },
  function* () {
    yield takeLatest(getChallengeAsync.request, fetchChallenge);
  },
  function* () {
    yield takeLatest(createChallengeAsync.request, createChallengeSaga);
  },
  function* () {
    yield takeLatest(updateChallengeAsync.request, updateChallengeSaga);
  },
  function* () {
    yield takeLatest(deleteChallengeAsync.request, deleteChallengeSaga);
  },
  function* () {
    yield takeLatest(addNewChallengeAsync.request, addNewChallengeSaga);
  }
];

export default function* challengesSagas() {
  yield forkSagas(sagas);
}
import { Component } from 'react';
import type {RootState} from '../../redux/reducers';
import type { ConnectedProps} from 'react-redux';
import {connect} from 'react-redux';
import type {Column} from 'material-table';
import {Box, Button, CircularProgress, Dialog, DialogContent, Grid, Link, Typography} from '@material-ui/core';
import type {MenuAction} from '../../components/MaterialTable';
import MaterialTable from '../../components/MaterialTable';
import {openConfirmationDialog, setAppNotification} from '../../redux/app/actions';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from "@material-ui/icons/Edit";
import FileCopyIcon from '@material-ui/icons/FileCopy';
import AddChallengeDialog from "./AddChallengeDialog";
import {UserService} from '../../services/user';
import {deleteChallengeAsync, getChallengesAsync, addNewChallengeAsync} from '../../redux/player-challenges/challenges/actions';
import {getLatestChallengeTemplateAsync} from '../../redux/player-challenges/challenge-templates/actions';

import {ChallengesService} from '../../services/player-challenges/challenges';
import type {Challenge} from '../../services/player-challenges/challenges';
import type { ChallengeTrigger, Template} from '../../services/player-challenges/challenge-templates';
import {seasonRewardsToSmallString} from "../../services/player-challenges/season-reward-path-tiers";
import {getCurrentSeasonChallengeScheduleAsync} from "../../redux/player-challenges/season-challenge-schedule/actions";
import type {ChallengeSchedule} from "../../services/player-challenges/season-challenge-schedule";
import { EditGameChallengeDialog } from './EditGameChallengeDialog';


const mapStateToProps = (state: RootState) => {
  return ({
    challenges: state.challenges,
    templates: state.challengeTemplates,
    seasonChallengeSchedule: state.seasonChallengeSchedule
  });
}
const mapDispatcher = {
  requestGetChallengesAsync: getChallengesAsync.request,
  requestGetCurrentSeasonChallengeSchedule: getCurrentSeasonChallengeScheduleAsync.request,
  requestDeleteChallengeAsync: deleteChallengeAsync.request,
  requestAddChallenge: addNewChallengeAsync.request,
  setAppNotification,
  openConfirmationDialog
};
const connector = connect(mapStateToProps, mapDispatcher);

type Props = ConnectedProps<typeof connector>;

interface State {
  isUploading: boolean;
  addChallengeDialogOpen: boolean;
  editGameChallengeOpen: boolean;
  challenges: Challenge[];
  challengeToEdit?: Challenge;
}

class Challenges extends Component<Props, State> {
  private columns: Column<Challenge>[] = [
    {
      title: 'Name', field: 'challenge_name', defaultSort: 'asc', render: (challenge) => {
        return <>
          <Link onClick={() => this.onChallengeEdit(challenge)} style={{cursor: 'pointer'}}>
            {challenge.challenge_name}
          </Link>
        </>;
      },
      customFilterAndSearch: (filter: string, challenge) => {
        if (!challenge) return false;
        return challenge.challenge_name.toLowerCase().indexOf(filter.toLowerCase()) > -1;
      }
    },
    {
      title: 'Description',
      render: (challenge) => (challenge.challenge_desc || '')
        .replace(/\n/g, ' ')
        .replace(/\\n/g, ' ')
    },
    {
      title: 'Template Name', field: 'template_id',
      render: (template) => this.templateMap[template.template_id] ? this.templateMap[template.template_id].template_name : template.template_id,
      customSort: (a, b) => this.templateMap[a.template_id].template_name.localeCompare(this.templateMap[b.template_id].template_name),
      customFilterAndSearch: (filter: string, challenge) => {
        if (!challenge || !this.templateMap[challenge.template_id]) return false;
        return this.templateMap[challenge.template_id].template_name.toLowerCase().indexOf(filter.toLowerCase()) > -1;
      },
    },
    {title: 'Version', field: 'template_version'},
    {title: 'Current Season Usage', render: (challenge) => this.renderChallengeCurrentSeasonUsage(challenge)},
    {title: 'Id', field: 'challenge_id'},
  ];

  private templateMap: { [index: string]: Template } = {};

  constructor(props: Props) {
    super(props);
    if (this.props.templates.latestChallengeTriggers instanceof Array) {
      this.props.templates.latestChallengeTriggers?.forEach((trigger: ChallengeTrigger) => {
        if(trigger.template){
          this.templateMap[trigger.template.template_id] = trigger.template;
        }
      });
    }
    this.state = {
      isUploading: false,
      addChallengeDialogOpen: false,
      editGameChallengeOpen: false,
      challenges: []
    };
  }

  componentDidMount() {
    this.props.requestGetChallengesAsync();
    this.props.requestGetCurrentSeasonChallengeSchedule();
  }

  getTableMenuActions = (challenge: Challenge): MenuAction<Challenge>[] => {
    const actions: MenuAction<Challenge>[] = [];
    if (UserService.canDelete('seasons')) {
      //actions.push({ type: 'divider' });
      actions.push({type: 'button', icon: EditIcon, label: 'Edit', onClick: challenge => this.onChallengeEdit(challenge)});
      actions.push({type: 'button', icon: DeleteIcon, label: 'Delete', onClick: this.onChallengeDelete});
      actions.push({type: 'button', icon: FileCopyIcon, label: 'Duplicate', onClick: challenge =>  this.onChallengeDuplicate(challenge)});
    }
    return actions;
  };

  renderChallengeCurrentSeasonUsage = (challenge: Challenge) => {
    if (!this.props.seasonChallengeSchedule || !this.props.seasonChallengeSchedule.data) {
      return null;
    }
    const currentSeasonSchedule: ChallengeSchedule = this.props.seasonChallengeSchedule.data;
    const daily = currentSeasonSchedule.daily.filter(o => o.challenges.some(p => p.challenge_id === challenge.challenge_id));
    const weekly = currentSeasonSchedule.weekly.filter(o => o.challenges.some(p => p.challenge_id === challenge.challenge_id));
    const seasonal = currentSeasonSchedule.seasonal.challenges.filter(o => o.challenge_id === challenge.challenge_id);
    return (<>
      {daily && daily.length > 0 && (<Box>
        Daily: {daily.length}
      </Box>
      )}
      {weekly && weekly.length > 0 && (<Box>
        Weekly: {weekly.length}
      </Box>
      )}
      {seasonal && seasonal.length > 0 && (<Box>
        Seasonal: {seasonal.length}
      </Box>
      )}
      {!daily.length && !weekly.length && !seasonal.length && (<Box>
        No usages
      </Box>
      )}
    </>);
  };

  onChallengeEdit = (challengeToEdit: Challenge) => {
    this.setState({challengeToEdit, addChallengeDialogOpen: true});
  };

  onChallengeDelete = (challenge: Challenge) => {
    this.props.openConfirmationDialog({title: `Delete Challenge: ${challenge.challenge_name}? WARNING: Deleting this challenge will also delete any schedule instances`, action: 'Delete Challenge'}, () => {
      this.props.requestDeleteChallengeAsync(challenge.challenge_id);
    });
  };

  onChallengeDuplicate = async (challengeToDuplicate: Challenge) => {
    const copy = challengeToDuplicate.duplicate();
    const challengeToEdit = await ChallengesService.createChallenge(copy);
    this.props.requestAddChallenge(challengeToEdit);
    this.setState({challengeToEdit, addChallengeDialogOpen: true});
  };

  render() {
    if (this.props.templates.isLoading && this.props.challenges.isLoading && this.props.challenges.data.length < 1) {
      return (
        <Box textAlign="center">
          <CircularProgress/>
        </Box>
      );
    }

    return (<>
      {/*{UserService.canCreate('season') && (*/}
      <Box mb={2}>
        <Grid container alignItems="center">
          <Grid item xs/>
          <Grid item>
            <Button variant="contained" color="primary" onClick={() => this.setState({editGameChallengeOpen: true})}>
              Edit Game Challenge
            </Button>
          </Grid>
          &nbsp;
          <Grid item>
            <Button variant="contained" color="primary" onClick={() => this.setState({challengeToEdit: undefined, addChallengeDialogOpen: true})}>
              Create New Challenge
            </Button>
          </Grid>
        </Grid>
      </Box>
      {/*)}*/}

      <MaterialTable
        title="Challenges"
        columns={this.columns}
        data={this.props.challenges.data}
        options={{
          pageSize: 10
        }}
        menuActions={this.getTableMenuActions}
      />

      <Dialog
        open={this.state.isUploading}
        fullWidth
        maxWidth="sm"
      >
        <DialogContent>
          <Box textAlign="center">
            <CircularProgress/>
            <Typography>Uploading...</Typography>
            <Typography>Please do not close this tab.</Typography>
          </Box>
        </DialogContent>
      </Dialog>

      {this.state.addChallengeDialogOpen && (
        <AddChallengeDialog
          challengeIdToEdit={this.state.challengeToEdit ? this.state.challengeToEdit.challenge_id : undefined}
          onClose={() => this.setState({addChallengeDialogOpen: false, challengeToEdit: undefined})}
          existingChallenges={this.props.challenges.data}
        />
      )}

      {this.state.editGameChallengeOpen && (
        <EditGameChallengeDialog
          onCancel={() => this.setState({editGameChallengeOpen: false})}
          onSave={() => this.setState({editGameChallengeOpen: false})}
        />
      )}

    </>);
  }
}

export default connector(Challenges);

import { takeEvery } from '@redux-saga/core/effects';
import { pushAppNotification } from '../../shared/hooks/useAppNotification';
import { forkSagas } from '../utils-ts';
import { setAppNotification } from './actions';

function pushNotificationSaga(action: ReturnType<typeof setAppNotification>) {
  if (action.payload.notification) {
    pushAppNotification(action.payload.notification);
  }
}

export function* appSagas() {
  yield forkSagas([
    function* () {
      yield takeEvery(setAppNotification, pushNotificationSaga);
    }
  ]);
}
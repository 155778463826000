import { Grid, Card, CardHeader, CardContent } from '@material-ui/core';

interface RewardOverviewProps {
  rewards: any,
  title: string
}

const RewardOverviewGrid = ({ rewards, title }: RewardOverviewProps) => {
  const sep = " x ";
  return (<>
    <Grid item xs={12} lg={6}>
      <Card>
        <CardHeader title={`${title}`}  />
        <CardContent>
          <Grid>
            {Array.from({ length: rewards.length }).map((_, i) => (
              <Grid item key={i}>
                {rewards[i].id}
                {sep}
                {rewards[i].count}
              </Grid>
            ))}
          </Grid>
        </CardContent>
      </Card>
    </Grid>
  </>);
}

export default RewardOverviewGrid;
import { makeStyles } from "@material-ui/core";
import type { Theme } from "@material-ui/core";

export interface SeasonalDataForComparison {
  seasonNumber: number;
  seasonName: string;
  seasonStart: number;
  seasonEnd: number;
  seasonDayUTCChange: string;
  seasonRewardPath: SeasonRewardPathForComparison;
  seasonChallengeSchedule: SeasonChallengeScheduleForComparison;
  seasonalChallenges: { [key: string]: Challenge };
}

export interface SeasonChallengeScheduleForComparison {
  scheduleId: string;
  scheduleName: string;
  //A dictionary by day that holds a dictionary of challenges by instanceId
  daily: { [dayNum: number]: { [instanceId: string]: ScheduleChallenge } };
  //A dictionary by week that holds a dictionary of challenges by instanceId
  weekly: { [weekNum: number]: { [instanceId: string]: ScheduleChallenge } };
  seasonal: { [instanceId: string]: ScheduleChallenge };
}

export interface SeasonRewardPathForComparison {
  pathId: string;
  tiers: { [key: string]: Tier };
}

export interface SeasonalDataDiffResult {
  updatedFields: SeasonalDataForComparison;
  addedFields: SeasonalDataForComparison;
  oldSeason: SeasonalDataForComparison;
  newSeason: SeasonalDataForComparison;
}

export interface Stringish {
  toString: () => string;
}

export const normalizeSeasonalData = (season?: SeasonalData): SeasonalDataForComparison => {
  if(!season){
    return {} as SeasonalDataForComparison;
  }
  const rewardTiers = season.seasonRewardPath.tiers.map(o => {
    return {
      tierId: o.tierId,
      tierOrder: o.tierOrder,
      seasonTokensToNextLevel: o.seasonTokensToNextLevel,
      name: o.name,
      isRepeatableTier: o.isRepeatableTier,
      rewards: o.rewards.sort(),
      rewardsPartyPassPlus: o.rewardsPartyPassPlus.sort()
    }
  });
  const rewardPaths = {
    pathId: season.seasonRewardPath.pathId,
    tiers: Object.assign({}, ...rewardTiers.map(o => ({ [o.tierId]: o })))
  };
  const challenges = Object.assign({}, ...season.seasonalChallenges.map(o => ({ [o.challengeId]: o })));

  const dailyChallenges = {} as { [k: number]: { [k2: string]: ScheduleChallenge } };;
  season.seasonChallengeSchedule.daily.forEach(day => {
    const dayChallenges = Object.assign({}, ...day.challenges.map(o => ({ [o.instanceId]: o })));
    dailyChallenges[day.dayNumber] = dayChallenges;
  })

  const weeklyChallenges = {} as { [k: number]: { [k2: string]: ScheduleChallenge } };;
  season.seasonChallengeSchedule.weekly.forEach(week => {
    const weekChallenges = Object.assign({}, ...week.challenges.map(o => ({ [o.instanceId]: o })));
    weeklyChallenges[week.weekNumber] = weekChallenges;
  })

  const seasonalChallenges = Object.assign({}, ...season.seasonChallengeSchedule.seasonal.challenges.map(o => ({ [o.instanceId]: o })));
  const scs = {
    scheduleId: season.seasonChallengeSchedule.scheduleId,
    scheduleName: season.seasonChallengeSchedule.scheduleName,
    daily: dailyChallenges,
    weekly: weeklyChallenges,
    seasonal: seasonalChallenges
  } as SeasonChallengeScheduleForComparison;

  return {
    seasonNumber: season.seasonNumber,
    seasonName: season.seasonName,
    seasonStart: season.seasonStart,
    seasonEnd: season.seasonEnd,
    seasonDayUTCChange: season.seasonDayUTCChange,
    seasonChallengeSchedule: scs,
    seasonRewardPath: rewardPaths,
    seasonalChallenges: challenges
  } as SeasonalDataForComparison;

};

export const seasonalDataComparer = {
  getStyles: makeStyles((theme: Theme) => ({
    seasonDiffView: {
      background: theme.palette.background.paper
    },
    beforeAfter: {
      paddingLeft: "5px"
    },
    fieldLabel: {
      fontSize: "0.875rem",
      fontWeight: 500,
      display: "inline-block",
      marginRight: "4px"
    },
    sectionTitle: {
      fontWeight: 600,
      textAlign: "center",
      textDecoration: "underline"
    },
    challengeContainer: {
      background: theme.palette.background.paper,
      margin: "5px"
    },
    accordianSummary: {
      flexDirection: "row-reverse",
      width: "100%",
      margin: "0px",
      '&.Mui-expanded': {
        margin: "0px"
      },
      '& .MuiAccordionSummary-expandIcon.Mui-expanded': {
        transform: 'rotate(0deg)'
      },
      '& .MuiAccordionSummary-expandIcon': {
        transform: 'rotate(-90deg)'
      }
    },
    accordionTitle: {
      marginLeft: "15px"
    }
  }))
}
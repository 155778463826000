import { Component } from 'react';
import type { ConnectedProps } from 'react-redux';
import { connect } from 'react-redux';
import { closeConfirmationDialog } from '../redux/app/actions';
import type { RootState } from '../redux/reducers';
import ConfirmationDialog from './ConfirmationDialog';

const mapStateToProps = (state: RootState) => ({ ...state.app.confirmation });
const mapDispatch = { closeConfirmationDialog };
const connector = connect(mapStateToProps, mapDispatch);

type Props = ConnectedProps<typeof connector>;

class AppConfirmationDialog extends Component<Props> {
  onConfirm = (note?: string) => {
    this.props.closeConfirmationDialog();
    this.props.onConfirm(note);
  }

  onClose = () => {
    if (this.props.onCancel) {
      this.props.onCancel();
    }
    this.props.closeConfirmationDialog();
  }

  render() {
    return (
      <ConfirmationDialog
        open={this.props.open}
        onConfirm={this.onConfirm}
        onClose={this.onClose}
        {...this.props.props}
      />
    );
  }
}

export default connector(AppConfirmationDialog);
import type {RootState} from "../../reducers";
import {ScheduleCalendarTypeEnum} from "../../../services/player-challenges/season-challenge-schedule-calendar";

export function getDailyChallengeScheduleCalendar(state: RootState) {
  if (!state.seasonChallengeScheduleCalendar || !state.seasonChallengeScheduleCalendar.data) {
    return [];
  }
  return state.seasonChallengeScheduleCalendar.data.filter(challenge => challenge.calendar_type === ScheduleCalendarTypeEnum.DAILY);
}

export function getWeeklyChallengeScheduleCalendar(state: RootState) {
  if (!state.seasonChallengeScheduleCalendar || !state.seasonChallengeScheduleCalendar.data) {
    return [];
  }
  return state.seasonChallengeScheduleCalendar.data.filter(challenge => challenge.calendar_type === ScheduleCalendarTypeEnum.WEEKLY);
}

export function getSeasonalChallengeScheduleCalendar(state: RootState) {
  if (!state.seasonChallengeScheduleCalendar || !state.seasonChallengeScheduleCalendar.data) {
    return [];
  }
  return state.seasonChallengeScheduleCalendar.data.filter(challenge => challenge.calendar_type === ScheduleCalendarTypeEnum.SEASONAL
  );
}

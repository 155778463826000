import type {Theme} from '@material-ui/core';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  createStyles,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  makeStyles,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from '@material-ui/core';
import DeleteIcon from "@material-ui/icons/Delete";
import {Field, FieldArray, Formik} from 'formik';
import {TextField} from 'formik-material-ui';
import {BrawlPinUnlockCondition, UnlockConditionParameterTypeEnum,BadgeCheckTypeEnum, GameTypeEnum, BuiltinConditionParameter, BlankoDamageTypeEnum} from '../../../services/model/brawl-pin';
import * as Yup from 'yup';
import {usePushNotification} from '../../../contexts/AppNotificationContext';
import {useCallback, useMemo, useState} from 'react';
import {FormikSelectWithLabel} from '../../../components/FormikSelectWithLabel';
import _ from 'lodash';
import { getBuiltinUnlockConditionTypes } from '../../../services/brawl-pins';
import { AddBuiltinParametersDialog } from './AddBuiltinParametersDialog';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    button: {
      marginLeft: 8,
    },
    form: {
      display: 'flex',
      flexDirection: 'column',
      margin: 'auto',
      marginBottom: 8,
    },
    formControl: {
      marginTop: theme.spacing(2),
      minWidth: 500,
    },
  }),
);

interface AddEditUnlockConditionDialogProps {
  existingCondition?: BrawlPinUnlockCondition | undefined,
  upsertUnlockCondition: (condition: BrawlPinUnlockCondition, checkUnlockType: boolean) => void,
  onClose: () => void,
}

export interface UnlockConditionDialogValue {
  id: string,
  unlockType: string,
  badgeCheckType: BadgeCheckTypeEnum,
  gameType: GameTypeEnum,
  parameters: UnlockConditionDialogParameter[],
}

export interface AddBuiltinParamDialogInfo {
  unlockType: string,
  existingParams: string[],
  onAddParameters: (params: BuiltinConditionParameter[]) => void,
}

const validationSchema = Yup.object().shape({
  unlockType: Yup.string().required('Required'),
  badgeCheckType: Yup.string().required('Required'),
  gameType: Yup.string().required('Required'),
  parameters: Yup.array().of(Yup.object().shape({
    name: Yup.string().required('Required'),
    type: Yup.string().required('Required'),
    integerValue: Yup
      .number()
      .integer('Invalid integer')
      .when("type", {
        is: UnlockConditionParameterTypeEnum.Integer,
        then: Yup.number().required('Required')
      }),
    floatValue: Yup
      .number()
      .when("type", {
        is: UnlockConditionParameterTypeEnum.Float,
        then: Yup.number().required('Required')
      }),
    booleanValue: Yup
      .string()
      .when("type", {
        is: UnlockConditionParameterTypeEnum.Boolean,
        then: Yup.string().required('Required')
      }),
    stringValue: Yup
      .string()
      .when("type", {
        is: UnlockConditionParameterTypeEnum.String,
        then: Yup.string().required('Required')
      }),
    gameTypeValue: Yup
      .string()
      .when("type", {
        is: UnlockConditionParameterTypeEnum.GameType,
        then: Yup.string().required('Required')
      }),
    damageTypeValue: Yup
      .string()
      .when("type", {
        is: UnlockConditionParameterTypeEnum.BlankoDamageType,
        then: Yup.string().required('Required')
      }),
  })),
});

const builtinUnlockConditionTypes = getBuiltinUnlockConditionTypes();

export interface UnlockConditionDialogParameter {
  name: string,
  integerValue: number,
  floatValue: number,
  booleanValue: string,
  stringValue: string,
  gameTypeValue: string,
  damageTypeValue: string,
  type: UnlockConditionParameterTypeEnum,
}

const getStringParamType = (unlockType: string, parameterName: string): UnlockConditionParameterTypeEnum => {
  const unlockCondition = builtinUnlockConditionTypes.find(uc => uc.name === unlockType);
  if (unlockCondition) {
    const parameter = unlockCondition.parameters?.find(p => p.name === parameterName);
    if (parameter) {
      return parameter.type;
    }
  }

  return UnlockConditionParameterTypeEnum.String
}

const isStringParam = (paramType: UnlockConditionParameterTypeEnum): boolean => {
  return paramType === UnlockConditionParameterTypeEnum.String
    || paramType === UnlockConditionParameterTypeEnum.GameType
    || paramType === UnlockConditionParameterTypeEnum.BlankoDamageType;
}

export const AddEditUnlockConditionDialog = ({ existingCondition, upsertUnlockCondition, onClose}: AddEditUnlockConditionDialogProps) => {
  const classes = useStyles();
  const pushNotification = usePushNotification();
  const [addBuiltinParamDialogInfo, setAddBuiltinParamDialogInfo] = useState <AddBuiltinParamDialogInfo>();

  const handleClose = useCallback(() => {
    setAddBuiltinParamDialogInfo(undefined);
  }, [])

  const initialConditionValue: UnlockConditionDialogValue = useMemo(() => {
    if (existingCondition) {
      return {
        id: existingCondition.id,
        unlockType: existingCondition.unlockType,
        badgeCheckType: existingCondition.badgeCheckType,
        gameType: existingCondition.gameType,
        parameters: existingCondition.parameters && Object.keys(existingCondition.parameters).map(key => {
          const type = existingCondition.parameters
            && (_.isString(existingCondition.parameters[key])
              ? getStringParamType(existingCondition.unlockType, key)
              : _.isBoolean(existingCondition.parameters[key])
                ? UnlockConditionParameterTypeEnum.Boolean
                : _.isInteger(existingCondition.parameters[key])
                  ? UnlockConditionParameterTypeEnum.Integer
                  : UnlockConditionParameterTypeEnum.Float)
            || UnlockConditionParameterTypeEnum.Integer;
          return {
            name: key,
            integerValue: existingCondition.parameters && type === UnlockConditionParameterTypeEnum.Integer ? existingCondition.parameters[key] : 0,
            floatValue: existingCondition.parameters && type === UnlockConditionParameterTypeEnum.Float ? existingCondition.parameters[key] : 0.0,
            booleanValue: existingCondition.parameters && type === UnlockConditionParameterTypeEnum.Boolean ? existingCondition.parameters[key].toString() : 'true',
            stringValue: existingCondition.parameters && isStringParam(type) ? existingCondition.parameters[key] : '',
            gameTypeValue: existingCondition.parameters && isStringParam(type) ? existingCondition.parameters[key] : GameTypeEnum.Undefined,
            damageTypeValue: existingCondition.parameters && isStringParam(type) ? existingCondition.parameters[key] : BlankoDamageTypeEnum.Default,
            type,
          } as UnlockConditionDialogParameter
        }) || [],
      };
    }

    return {
      id: '',
      unlockType: '',
      badgeCheckType: BadgeCheckTypeEnum.AboveEqual,
      gameType: GameTypeEnum.Undefined,
      parameters: [],
    }
  }, [existingCondition]);

  const getParameterValue = (parameter: UnlockConditionDialogParameter): any => {
    switch (parameter.type) {
    case UnlockConditionParameterTypeEnum.Boolean: return parameter.booleanValue === 'true';
    case UnlockConditionParameterTypeEnum.BlankoDamageType: return parameter.damageTypeValue;
    case UnlockConditionParameterTypeEnum.Float: return parameter.floatValue;
    case UnlockConditionParameterTypeEnum.GameType: return parameter.gameTypeValue;
    case UnlockConditionParameterTypeEnum.Integer: return parameter.integerValue;
    case UnlockConditionParameterTypeEnum.String: return parameter.stringValue;
    default: throw new Error(`Invalid parameter type: '${parameter.type}'`);
    }
  };

  const onSubmit = useCallback((values: UnlockConditionDialogValue) => {
    const condition: BrawlPinUnlockCondition = {
      id: values.id,
      unlockType: values.unlockType,
      gameType: values.gameType,
      badgeCheckType: values.badgeCheckType,
      parameters: values.parameters.reduce<{[key:string]: any}>((newParams, param) => {
        newParams[param.name] = getParameterValue(param);
        return newParams;
      }, {})
    };
    // need to check for existing condition with same unlock type
    // - if we're creating new condition
    // - if we're updating existing condition and changing the unlock type
    const needToCheckUnlockTypes = !existingCondition || existingCondition.unlockType !== values.unlockType;
    try {
      upsertUnlockCondition(condition, needToCheckUnlockTypes);
      onClose();
    } catch (e) {
      pushNotification({ type: 'error', message: e instanceof Error ? e.message : 'An unknown error occurred' });
    }
  }, [existingCondition, onClose, pushNotification, upsertUnlockCondition]);

  const getBuiltinParams = (unlockType: string): BuiltinConditionParameter[] | undefined => {
    const unlockCondition = builtinUnlockConditionTypes.find(uc => uc.name === unlockType);
    if (unlockCondition) {
      return unlockCondition.parameters;
    }
  }

  const canAddBuiltinParams = (unlockType: string): boolean => {
    return !_.isEmpty(getBuiltinParams(unlockType));
  }

  const dialogTitle = existingCondition ? 'Edit Unlock Condition' : 'Add Unlock Condition';
  return (
    <>
      <Dialog
        open={true}
        fullWidth
        onClose={onClose}
        aria-labelledby="add-dialog-title"
        maxWidth="md"
      >
        <Formik<UnlockConditionDialogValue>
          initialValues={initialConditionValue}
          validationSchema={validationSchema}
          validateOnMount={true}
          onSubmit={onSubmit}
        >
          {formikData => (<form onSubmit={formikData.handleSubmit}>
            <DialogTitle id="add-dialog-title">{dialogTitle}</DialogTitle>
            <DialogContent className={classes.form}>
              <Box display="flex" flexDirection="column" justifyContent="center">
                <Field
                  component={FormikSelectWithLabel}
                  id="unlockType"
                  name="unlockType"
                  label={"Unlock Type"}
                >
                  {builtinUnlockConditionTypes.map(uct => uct.name).sort().map(unlockType => (
                    <MenuItem key={unlockType} value={unlockType}>{unlockType}</MenuItem>
                  ))}
                </Field>
              </Box>
              <Box mt={2} display="flex" flexDirection="column" justifyContent="center">
                <Field
                  component={FormikSelectWithLabel}
                  id="badgeCheckType"
                  name="badgeCheckType"
                  label={"Badge Check Type"}
                >
                  {Object.values(BadgeCheckTypeEnum).sort().map(badgeCheckType => (
                    <MenuItem key={badgeCheckType} value={badgeCheckType}>{badgeCheckType}</MenuItem>
                  ))}
                </Field>
              </Box>
              <Box mt={2} display="flex" flexDirection="column" justifyContent="center">
                <Field
                  component={FormikSelectWithLabel}
                  id="gameType"
                  name="gameType"
                  label={"Game Type"}
                >
                  {Object.values(GameTypeEnum).sort().map(gameType => (
                    <MenuItem key={gameType} value={gameType}>{gameType}</MenuItem>
                  ))}
                </Field>
              </Box>
              <Box  mt={4} className={classes.formControl}>
                <Card>
                  <FieldArray name='parameters'>
                    {arrayHelpers => (<>
                      <CardHeader
                        title="Parameters"
                        titleTypographyProps={{variant: 'subtitle1'}}
                        action={<>
                          <Button
                            className={classes.button}
                            color="primary"
                            disabled={!canAddBuiltinParams(formikData.values.unlockType)}
                            variant="contained"
                            size="small"
                            onClick={
                                () => {
                                  setAddBuiltinParamDialogInfo({
                                    unlockType: formikData.values.unlockType,
                                    existingParams: formikData.values.parameters.map(p => p.name),
                                    onAddParameters: params => {
                                      params.forEach(p => {
                                        arrayHelpers.push({
                                          name: p.name,
                                          integerValue: 0,
                                          floatValue: 0,
                                          booleanValue: 'true',
                                          stringValue: '',
                                          gameTypeValue: GameTypeEnum.Undefined,
                                          damageTypeValue: BlankoDamageTypeEnum.Default,
                                          type: p.type,
                                        })
                                      });
                                    }
                                  });
                            }}
                            >
                            Add Built-ins
                          </Button>
                          <Button
                            className={classes.button}
                            color="primary"
                            variant="contained"
                            size="small"
                            onClick={
                              () => {
                                const existingUndefined = formikData.values.parameters.find(ri => ri.name === '');
                                if (!existingUndefined) {
                                  arrayHelpers.push({
                                    name: '',
                                    integerValue: 0,
                                    floatValue: 0,
                                    booleanValue: 'true',
                                    stringValue: '',
                                    gameTypeValue: GameTypeEnum.Undefined,
                                    damageTypeValue: BlankoDamageTypeEnum.Default,
                                    type: UnlockConditionParameterTypeEnum.Integer,
                                  })
                                }
                            }}
                          >
                            Add Custom
                          </Button>
                        </>}
                      />
                    </>)}
                  </FieldArray>
                  <CardContent>
                    <Box display="flex" flexDirection="column" justifyContent="center">
                      <FieldArray name='parameters'>
                        {arrayHelpers => (<>
                          <TableContainer style={{maxHeight: 440}}>
                            <Table stickyHeader>
                              <TableHead>
                                <TableRow>
                                  <TableCell>Name</TableCell>
                                  <TableCell>Type</TableCell>
                                  <TableCell>Value</TableCell>
                                  <TableCell/>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {formikData.values.parameters.map((param, index) => (
                                  <TableRow key={`param-${index}`}>
                                    <TableCell>
                                      <Field
                                        component={TextField}
                                        name={`parameters.${index}.name`}
                                        type="text"
                                        id={`param-name-${index}`}
                                        value={param.name}
                                      />
                                    </TableCell>
                                    <TableCell>
                                      <Box display="flex" flexDirection="column" justifyContent="center">
                                        <Field
                                          component={FormikSelectWithLabel}
                                          id={`param-type-${index}`}
                                          name={`parameters[${index}].type`}
                                        >
                                          {Object.values(UnlockConditionParameterTypeEnum)
                                          .map(valueType => (
                                            <MenuItem key={valueType} value={valueType}>{valueType}</MenuItem>
                                          ))}
                                        </Field>
                                      </Box>
                                    </TableCell>
                                    {param.type === UnlockConditionParameterTypeEnum.Integer && (
                                      <TableCell>
                                        <Field
                                          component={TextField}
                                          name={`parameters[${index}].integerValue`}
                                          type="number"
                                          id={`param-integer-value-${index}`}
                                          value={param.integerValue}
                                        />
                                      </TableCell>
                                    )}
                                    {param.type === UnlockConditionParameterTypeEnum.Float && (
                                      <TableCell>
                                        <Field
                                          component={TextField}
                                          name={`parameters[${index}].floatValue`}
                                          type="number"
                                          id={`param-float-value-${index}`}
                                          value={param.floatValue}
                                        />
                                      </TableCell>
                                    )}
                                    {param.type === UnlockConditionParameterTypeEnum.String && (
                                      <TableCell>
                                        <Field
                                          component={TextField}
                                          name={`parameters[${index}].stringValue`}
                                          type="text"
                                          id={`param-text-value-${index}`}
                                          value={param.stringValue}
                                        />
                                      </TableCell>
                                    )}
                                    {param.type === UnlockConditionParameterTypeEnum.GameType && (
                                      <TableCell>
                                        <Box display="flex" flexDirection="column" justifyContent="center">
                                          <Field
                                            component={FormikSelectWithLabel}
                                            id={`param-game-type-${index}`}
                                            name={`parameters[${index}].gameTypeValue`}
                                        >
                                            {Object.values(GameTypeEnum)
                                            .map(gameType => (
                                              <MenuItem key={gameType} value={gameType}>{gameType}</MenuItem>
                                            ))}
                                          </Field>
                                        </Box>
                                      </TableCell>
                                    )}
                                    {param.type === UnlockConditionParameterTypeEnum.BlankoDamageType && (
                                      <TableCell>
                                        <Box display="flex" flexDirection="column" justifyContent="center">
                                          <Field
                                            component={FormikSelectWithLabel}
                                            id={`param-damage-type-${index}`}
                                            name={`parameters[${index}].damageTypeValue`}
                                        >
                                            {Object.values(BlankoDamageTypeEnum)
                                            .map(damageType => (
                                              <MenuItem key={damageType} value={damageType}>{damageType}</MenuItem>
                                            ))}
                                          </Field>
                                        </Box>
                                      </TableCell>
                                    )}
                                    {param.type === UnlockConditionParameterTypeEnum.Boolean && (
                                      <TableCell>
                                        <Box display="flex" flexDirection="column" justifyContent="center">
                                          <Field
                                            component={FormikSelectWithLabel}
                                            id={`param-boolean-value-${index}`}
                                            name={`parameters[${index}].booleanValue`}
                                          >
                                            <MenuItem key={'true'} value={'true'}>True</MenuItem>
                                            <MenuItem key={'false'} value={'false'}>False</MenuItem>
                                          </Field>
                                        </Box>
                                      </TableCell>
                                    )}
                                    <TableCell>
                                      <IconButton onClick={() => arrayHelpers.remove(index)}>
                                        <DeleteIcon/>
                                      </IconButton>
                                    </TableCell>
                                  </TableRow>
                                ))}
                              </TableBody>
                            </Table>
                          </TableContainer>
                        </>)
                        }
                      </FieldArray>
                    </Box>
                  </CardContent>
                </Card>
              </Box>
            </DialogContent>
            <DialogActions>
              <Button
                type="submit"
                variant="contained"
                disabled={!formikData.isValid}
                color="primary"
              >
                Save
              </Button>
              <Button onClick={onClose} color="primary">
                Cancel
              </Button>
            </DialogActions>
          </form>)}
        </Formik>
      </Dialog>

      {(addBuiltinParamDialogInfo) && <AddBuiltinParametersDialog
        builtinUnlockConditionTypes={getBuiltinUnlockConditionTypes()}
        existingParameters={addBuiltinParamDialogInfo.existingParams}
        unlockType={addBuiltinParamDialogInfo.unlockType}
        addBuiltinParams={addBuiltinParamDialogInfo.onAddParameters}
        onClose={handleClose}
      />}
    </>
  );
}

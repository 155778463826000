import type { ApiError} from './api';
import { api } from './api';

export class DropTableNode {
  type: 'item' | 'droptable' = 'item';
  id = '';
  weight = 0;
  displayName = '';

  constructor(json?: any) {
    if (json) {
      this.type = json.itemId ? 'item' : 'droptable';
      this.id = json.itemId || json.tableId;
      this.weight = json.weight;
      this.displayName = json.displayName;
    }
  }

  toJson(): any {
    const json: any = {
      weight: this.weight
    };

    if (this.type === 'item') {
      json.itemId = this.id;
    } else {
      json.tableId = this.id;
    }

    return json;
  }
}

export class DropTable {
  id = '';
  catalogName = '';
  nodes: DropTableNode[] = [];

  constructor(json?: any) {
    if (json) {
      this.id = json.tableId;
      this.catalogName = json.catalogName;
      this.nodes = json.nodes.map((v: any) => new DropTableNode(v));
    }
  }

  toJson(): any {
    return {
      tableId: this.id,
      nodes: this.nodes.map(node => node.toJson())
    };
  }
}

export class DropTablesService {
  static async getDropTables(catalogName: string): Promise<DropTable[]> {
    return api.get<any[]>({ url: `/inventory/catalogs/${catalogName}/droptables` })
      .then(dropTables => dropTables.map(dropTable => new DropTable(dropTable)))
      .catch((e: ApiError) => {
        e.message = `Failed to get drop tables. ${e.message}`;
        throw e;
      });
  }

  static async getDropTable(catalogName: string, dropTableId: string): Promise<DropTable> {
    return api.get({ url: `/inventory/catalogs/${catalogName}/droptables/${dropTableId}` })
      .then(dropTable => new DropTable(dropTable))
      .catch((e: ApiError) => {
        e.message = `Failed to get drop table. ${e.message}`;
        throw e;
      });
  }

  static async createDropTable(dropTable: DropTable): Promise<DropTable> {
    return api.post({ url: `/inventory/catalogs/${dropTable.catalogName}/droptables/`, body: dropTable.toJson() })
      .then(dropTable => new DropTable(dropTable))
      .catch((e: ApiError) => {
        e.message = `Failed to create drop table. ${e.message}`;
        throw e;
      });
  }

  static async updateDropTable(dropTable: DropTable): Promise<DropTable> {
    return api.put({ url: `/inventory/catalogs/${dropTable.catalogName}/droptables/${dropTable.id}`, body: dropTable.toJson() })
      .then(dropTable => new DropTable(dropTable))
      .catch((e: ApiError) => {
        e.message = `Failed to update drop table. ${e.message}`;
        throw e;
      });
  }

  static async deleteDropTable(dropTable: DropTable): Promise<null> {
    return api.delete({ url: `/inventory/catalogs/${dropTable.catalogName}/droptables/${dropTable.id}` })
      .catch((e: ApiError) => {
        e.message = `Failed to delete drop table. ${e.message}`;
        throw e;
      });
  }
}
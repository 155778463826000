import type { Reducer } from 'redux';
import { action, createReducer } from 'typesafe-actions';
import type { ChallengeTemplate, ChallengeTrigger } from '../../../services/player-challenges/challenge-templates';
import {
  getChallengeTemplatesAsync,
  getChallengeTriggersAsync,
  updateChallengeTemplateAsync,
  deleteChallengeTemplateAsync,
  getChallengeTemplateAsync,
  createChallengeTemplateAsync, getLatestChallengeTemplateAsync
} from './actions';

export interface ChallengeTemplatesState {
  isLoading: boolean;
  isUpdating: boolean;
  isDeleting?: number;
  data: ChallengeTemplate[];
  latestChallengeTemplate?: ChallengeTemplate;
  latestChallengeTriggers?: ChallengeTrigger[];
}

const initialState: ChallengeTemplatesState = {
  isLoading: false,
  isUpdating: false,
  isDeleting: undefined,
  data: []
};

const challengeTemplatesReducer: Reducer<ChallengeTemplatesState> = createReducer<ChallengeTemplatesState>(initialState)
  .handleAction(getChallengeTemplatesAsync.request, state => ({
    ...state,
    isLoading: true
  }))
  .handleAction(getChallengeTriggersAsync.request, state => ({
    ...state,
  }))
  .handleAction(getChallengeTriggersAsync.success, (state, action) => ({
    ...state,
    isLoading: false,
    latestChallengeTriggers: action.payload
  }))
  .handleAction(getChallengeTriggersAsync.failure, state => ({
    ...state,
    isLoading: false
  }))
  .handleAction(getChallengeTemplatesAsync.success, (state, action) => ({
    ...state,
    isLoading: false,
    data: action.payload
  }))
  .handleAction(getChallengeTemplatesAsync.failure, state => ({
    ...state,
    isLoading: false
  }))
  .handleAction(getLatestChallengeTemplateAsync.request, state => ({
    ...state,
  }))
  .handleAction(getLatestChallengeTemplateAsync.success, (state, action) => ({
    ...state,
    latestChallengeTemplate: action.payload
  }))
  .handleAction(getLatestChallengeTemplateAsync.failure, state => ({
    ...state,
  }))
  .handleAction(getChallengeTemplateAsync.request, (state, action) => ({
    ...state,
    isLoading: false
  }))
  .handleAction(getChallengeTemplateAsync.success, (state, action) => ({
    ...state,
    isLoading: false,
    data: state.data.map(template => template.version === action.payload.version ? action.payload : template)
  }))
  .handleAction(getChallengeTemplateAsync.failure, (state, action) => ({
    ...state,
    isLoading: false
  }))
  .handleAction(createChallengeTemplateAsync.request, (state, action) => ({
    ...state,
    isLoading: true
  }))
  .handleAction(createChallengeTemplateAsync.success, (state, action) => ({
    ...state,
    isLoading: false,
    data: state.data.concat(action.payload)
  }))
  .handleAction(createChallengeTemplateAsync.failure, (state, action) => ({
    ...state,
    isLoading: false
  }))
  .handleAction(updateChallengeTemplateAsync.request, (state, action) => ({
    ...state,
    isUpdating: true
  }))
  .handleAction(updateChallengeTemplateAsync.success, (state, action) => ({
    ...state,
    isUpdating: false,
    data: state.data.map(template => template.version === action.payload.version ? action.payload : template)
  }))
  .handleAction(updateChallengeTemplateAsync.failure, (state, action) => ({
    ...state,
    isUpdating: false
  }))
  .handleAction(deleteChallengeTemplateAsync.request, (state, action) => ({
    ...state,
    isDeleting: action.payload
  }))
  .handleAction(deleteChallengeTemplateAsync.success, (state, action) => {
    const index = state.data.findIndex(template => template.version === action.payload);    
    return ({
      ...state,
      isDeleting: undefined,
      data: (index >= 0 ? state.data.splice(index, 1) : state.data)
    }); 
  })
  .handleAction(deleteChallengeTemplateAsync.failure, (state, action) => ({
    ...state,
    isDeleting: undefined
  }));

export default challengeTemplatesReducer;
import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { cmSetOptions } from '../../../../redux/catalog-migration/actions';
import { CheckboxMulti } from './CheckboxMulti';

interface CheckboxMultiContainerProps {
  label: string;
  values: boolean[];
  disabled?: boolean;
  onClickCallback: (value: boolean) => { [key: string]: boolean };
}

export const CheckboxMultiContainer = ({
  label,
  values,
  disabled,
  onClickCallback }: CheckboxMultiContainerProps) => {
  const dispatch = useDispatch();
  const [checked, setChecked] = useState(false);
  const [indeterminate, setIndeterminate] = useState(false);

  useEffect(() => {
    let checked = true;
    let indeterminate = false;
    for (const value of values) {
      checked = checked && value;
      indeterminate = indeterminate || value;
    }

    if (checked) {
      indeterminate = false;
    }

    setChecked(checked);
    setIndeterminate(indeterminate);
  }, [values]);

  const onClick = () => {
    dispatch(cmSetOptions(onClickCallback(!(checked || indeterminate))));
  }

  return (<CheckboxMulti
    checked={checked}
    indeterminate={indeterminate}
    disabled={disabled}
    label={label}
    onClick={onClick} />)
}
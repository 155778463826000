export interface NakamaPageRequest {
  friendState?: number;
  limit?: number;
  cursor?: string;
}

export class Friend {
  updateTime = new Date();

  stateName = '';
  stateCode: number | null = null;

  id = '';
  username = '';
  displayName = '';
  languageTag = '';
  metadata: any = {};
  userCreateTime = new Date();
  userUpdateTime = new Date();
  online = false;

  constructor(json?: any) {
    if (json) {
      this.updateTime = new Date(json.updateTime);

      if (json.state) {
        this.stateName = json.state.name;
        this.stateCode = json.state.code;
      }

      if (json.user) {
        this.id = json.user.id;
        this.username = json.user.username;
        this.displayName = json.user.displayName;
        this.languageTag = json.user.languageTag;
        this.metadata = JSON.parse(json.user.metadata);
        this.userCreateTime = new Date(json.user.createTime);
        this.userUpdateTime = new Date(json.user.updateTime);
        this.online = json.user.online;
      }
    }
  }
}


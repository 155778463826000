import type { ThemeOptions} from '@material-ui/core/styles';
import { createMuiTheme, responsiveFontSizes } from '@material-ui/core/styles';


export const lightThemeOptions: ThemeOptions = {
  typography: {
    fontFamily: 'Poppins, Helvetica, Arial, sans-serif'
  },
  palette: {
    primary: {
      main: '#a68c4f'
    },
    secondary: {
      main: '#0018d0'
    }
  },
  overrides: {
    MuiAppBar: {
      colorPrimary: {
        backgroundColor: '#fff',
        color: '#000'
      }
    },
    MuiButton: {
      root: {
        lineHeight: 2,
        borderRadius: '20px'
      }
    },
    MuiChip: {
      root: {
        borderRadius: '5px'
      }
    },
    MuiDialogActions: {
      root: {
        padding: '20px'
      }
    },
    MuiLink: {
      root: {
        color: '#0018d0'
      }
    }
  },
  props: {
    MuiCheckbox: {
      color: 'secondary'
    },
    MuiFormLabel: {
      color: 'secondary'
    },
    MuiRadio: {
      color: 'secondary'
    },
    MuiSelect: {
      color: 'secondary'
    },
    MuiTabs: {
      indicatorColor: 'secondary'
    },
    MuiTextField: {
      color: 'secondary'
    }
  }
};

export const darkThemeOptions: ThemeOptions = {
  typography: {
    fontFamily: 'Poppins, Helvetica, Arial, sans-serif'
  },
  palette: {
    type: 'dark',
    primary: {
      main: '#a68c4f'
    },
    secondary: {
      main: '#6e85ba'
    },
    background: {
      paper: '#222129',
      default: '#15141a'
    }
  },
  overrides: {
    MuiAppBar: {
      colorPrimary: {
        backgroundColor: '#222129'
      }
    },
    MuiButton: {
      root: {
        lineHeight: 2,
        borderRadius: '20px'
      }
    },
    MuiChip: {
      root: {
        borderRadius: '5px'
      }
    },
    MuiDialogActions: {
      root: {
        padding: '20px'
      }
    },
    MuiDrawer: {
      paperAnchorLeft: {
        backgroundColor: '#2a2934'
      }
    },
    MuiLink: {
      root: {
        color: '#eed9b2'
      }
    },
    MuiTableCell: {
      root: {
        borderBottomColor: '#41404e'
      }
    }
  },
  props: {
    MuiCheckbox: {
      color: 'primary'
    },
    MuiFormLabel: {
      color: 'secondary'
    },
    MuiRadio: {
      color: 'primary'
    },
    MuiSelect: {
      color: 'secondary'
    },
    MuiTabs: {
      indicatorColor: 'primary'
    },
    MuiTextField: {
      color: 'secondary'
    }
  }
};

const lightTheme = responsiveFontSizes(createMuiTheme(lightThemeOptions));
const darkTheme = responsiveFontSizes(createMuiTheme(darkThemeOptions));

export { lightTheme, darkTheme };
import { ReportedBlocks } from "../ReportedBlocks"

interface myProps {
  blockId: string
}

export const BlockReport = ({blockId} : myProps) => {

  return (<>
    <ReportedBlocks blockId={blockId} />
  </>)
}
import { useState, useEffect, useCallback } from 'react';
import { EditRushDialog, AddRushDialog, DeleteRushDialog } from './components';
import { CssBaseline, Typography, Box, Grid, Button, Link } from '@material-ui/core';
import type { BlankoGemRush } from './blankoGemRush';
import { Delete, Create } from '@material-ui/icons';
import type { Column } from 'material-table';
import { UserService } from '../../services/user';
import { Link as RouterLink } from 'react-router-dom';
import { AdminTable } from '../../shared';
import { getAllBlankoRushes } from './gemRushApiService';

const gridColumns: Column<BlankoGemRush>[] = [
  { 
    title: "ID", 
    field: "id",
    hidden: true,
  },
  { 
    title: "Name", 
    field: "name",
    // eslint-disable-next-line react/display-name
    render: gemRushData => (
      <Link component={RouterLink} to={`/gem-rush/${gemRushData.id}`}>
        {gemRushData.name}
      </Link>
    )
  },
  { 
    title: "Active", 
    field: "active",
    type: "boolean",
  },
  {
    title: "Start Date",
    field: "startDate",
    type: "datetime",
  },
  {
    title: "End Date",
    field: "endDate",
    type: "datetime",
  }
]
export const GemRush = () => {
  const [blankosRushes, setBlankosRushes] = useState<BlankoGemRush[]>()
  const [showAddDialog, setShowAddDialog] = useState(false)
  const [showEditDialog, setShowEditDialog] = useState(false)
  const [showDeleteDialog, setShowDeleteDialog] = useState(false)
  const [rush, setRush] = useState<{id: string, name: string}>()
  const [editData, setEditData] = useState<BlankoGemRush>()
    
  const handleClose = useCallback(() => {
    setShowAddDialog(false);
    setShowEditDialog(false);
    setShowDeleteDialog(false);
  }, [])

  const getData = useCallback(async () => {
    const data = await getAllBlankoRushes()
    setBlankosRushes(data);
  }, [])

  useEffect(() => {
    getData()
  }, [showAddDialog, showEditDialog, showDeleteDialog, getData])
    
  const onAdd = useCallback(() => {
    setShowAddDialog(true);
  }, [])

  const onEdit = useCallback((data: BlankoGemRush) => {
    setShowEditDialog(true)
    setEditData(data)
  }, [])

  const onDelete = useCallback((data: BlankoGemRush) => {
    setShowDeleteDialog(true);
    setRush({id: data.id, name: data.name });
  }, [])
  return(
    <>
      <Box mb={2}>
        <Grid container spacing={2} alignItems="center">
          <Grid item xs>
            <Typography variant="h6">
              Gem Rush
            </Typography>
          </Grid>
          {UserService.canCreate('blankos') && (
            <Grid item>
              <Button
                onClick={onAdd}
                variant="contained"
                color="primary"
              >
                Add Rush
              </Button>
            </Grid>
          )}
        </Grid>
      </Box>
      <CssBaseline />
      {blankosRushes && 
        <AdminTable<BlankoGemRush>
          storageId="gem-rush"
          columns={gridColumns}
          data={blankosRushes}
          title=""
          menuActions={[
            { type: 'button', icon: Create, label: 'Edit', onClick: onEdit },
            { type: 'button', icon: Delete, label: 'Delete', onClick: onDelete }
          ]}
          options={{
            columnsButton: true,
            pageSize: 10,
            pageSizeOptions: [5, 10, 20]
          }}
          useRouter
        />
      }
      {showAddDialog &&
        <AddRushDialog show={showAddDialog} onClose={handleClose} />
      }
      {showEditDialog && editData &&
        <EditRushDialog rush={editData} show={showEditDialog} onClose={handleClose} />
      }
      {showDeleteDialog &&
        <DeleteRushDialog show={showDeleteDialog} rush={rush} onClose={handleClose} />
      }
    </>
  );
}

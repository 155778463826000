import { useCallback, useEffect, useRef, useState } from "react";
import type { Column, Query, QueryResult } from "material-table";
import { paginatedRequestFromQuery } from "../../../services/model/pagination";
import Box from "@material-ui/core/Box";
import { Grid } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import type { MenuAction } from "../../../components/MaterialTable";
import MaterialTable from "../../../components/MaterialTable";
import { UserService } from "../../../services/user";
import DeleteIcon from "@material-ui/icons/Delete";
import { EntitlementRewardsService } from "../../../services/rewards";
import { pushAppNotification } from "../../../shared/hooks/useAppNotification";
import { openAdminConfirmationDialog } from "../../../shared/hooks/useAdminConfirmationDialog";

export const AccountLinks = () => {
  const tableRef = useRef<MaterialTable<Reward>>();
  const [itemWasDeleted, setItemWasDeleted] = useState(false);

  const getAccountLinksPage = useCallback(async (query: Query<AccountLinkingResponse>): Promise<QueryResult<AccountLinkingResponse>> => {
    return new Promise(resolve => {
      const request = paginatedRequestFromQuery(query);
      request.search = query.search.trim();
      request.name = request.search;

      const getAccountLinks = EntitlementRewardsService.getAccountLinks(request);
      getAccountLinks.then(result => {
        resolve({
          data: result.items,
          page: request.page - 1,
          totalCount: result.totalCount
        });
      });
    });
  }, []);

  const columns: Column<AccountLinkingResponse>[] = [
    {
      title: 'ID',
      field: 'id',
    },
    {
      title: 'Player ID',
      field: 'playerId',
      defaultSort: 'asc'
    },
    {
      title: 'Player Name',
      field: 'displayName',
      defaultSort: 'asc'
    },
    {
      title: 'Player Email',
      field: 'email',
      defaultSort: 'asc'
    },
    {
      title: 'Service',
      field: 'serviceType',
      defaultSort: 'asc'
    },
  ];

  const deleteAccountLink = (accountLink: AccountLinkingResponse) => {
    openAdminConfirmationDialog({
      title: `Delete ${accountLink.id}?`, action: 'Delete',
      onConfirm: () => {
        EntitlementRewardsService.deleteAccountLink(accountLink.id)
          .then(() => {
            pushAppNotification({ type: 'success', message: 'Account Link deleted' });
            setItemWasDeleted(true);
          })
          .catch(err => pushAppNotification({type: 'error', message: `Failed to delete account link: ${err.message}`}));
      }
    });
  };

  useEffect(() => {
    if (tableRef && tableRef.current && itemWasDeleted) {
      setItemWasDeleted(false);
      (tableRef.current as any).onQueryChange();
    }
  }, [itemWasDeleted, setItemWasDeleted]);

  const actions: MenuAction<AccountLinkingResponse>[] = [];
  if (UserService.canDelete('rewards')) {
    actions.push({type: 'button', icon: DeleteIcon, label: 'Delete', onClick: deleteAccountLink});
  }

  return (<>
    <Box mb={2}>
      <Grid container spacing={2} alignItems="center">
        <Grid item xs>
          <Typography variant="h6">
            Account Linking
          </Typography>
        </Grid>
      </Grid>
    </Box>
    <MaterialTable
      tableRef={tableRef}
      title="Account Links"
      storageId="account-links"
      columns={columns}
      data={getAccountLinksPage}
      options={{
        columnsButton: true,
        debounceInterval: 500,
        pageSize: 20,
        pageSizeOptions: [20, 50, 100],
        thirdSortClick: false
      }}
      menuActions={actions}
      useRouter
    />
  </>);
};

import { createBrowserHistory, createMemoryHistory } from 'history'
import { applyMiddleware, createStore, compose } from 'redux'
// import { persistStore } from 'redux-persist'
import { routerMiddleware } from 'connected-react-router'
import createSagaMiddleware from 'redux-saga'
import createRootReducer from './reducers';
import rootSaga from './sagas';

const sagaMiddleware = createSagaMiddleware();

declare global {
  interface Window {
    __REDUX_DEVTOOLS_EXTENSION_COMPOSE__?: typeof compose
  }
}

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const isTest = process.env.NODE_ENV === "test";

export const history = isTest
  ? createMemoryHistory({ initialEntries: ['/'] })
  : createBrowserHistory();


export const store = createStore(
  createRootReducer(history),  
  composeEnhancers(applyMiddleware(routerMiddleware(history), sagaMiddleware))
);
// export const persistor = persistStore(store)

sagaMiddleware.run(rootSaga)
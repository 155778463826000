import {api} from "../../services/api";
import { GeoBlockedFeature, GeoBlockedFeatureIdEnum, GeoBlockedFeatureMap } from "./geo-blocking-types";

export const getRegionFeatures = async () => {
  return api.get<GeoBlockedFeature[]>({ url: '/region-features' })
}

export const deleteRegionFeatureByFeatureId = async (featureId: GeoBlockedFeatureIdEnum) => {
  return api.delete({ url: `/region-features/${featureId}` })
}

export const createRegionFeature = async (geoBlock: Partial<GeoBlockedFeatureMap>) => {
  return api.post({ url: `/region-features/${geoBlock.featureId}`, body: geoBlock.regions});
}

export const prettifyFeatureName = (featureName: string) => {
  return featureName.replace("_"," ").toLocaleUpperCase()
}
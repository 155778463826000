import {combineReducers} from 'redux';
import {connectRouter} from 'connected-react-router';

import app from './app/reducer';
import authentication from './authentication/reducer';
import sidebar from './sidebar/reducer';
import catalogs from './catalogs/reducer';
import catalog from './catalog/reducer';
import title from './title-data/reducer';
import currencies from './currencies/reducer';
import currencyLimits from './currency-limits/reducer';
import transactions from './transactions/reducer';
import dailyMessages from './motd/reducer';
import catalogMigration from './catalog-migration/reducer';
import type {History} from 'history';
import type {TypedUseSelectorHook} from 'react-redux';
import {useSelector} from 'react-redux';
import seasons from './player-challenges/seasons/reducer';
import challengeTemplates from './player-challenges/challenge-templates/reducer';
import challenges from './player-challenges/challenges/reducer';
import seasonChallengeScheduleCalendar from './player-challenges/season-challenge-schedule-calendar/reducer';
import seasonChallengeSchedule from './player-challenges/season-challenge-schedule/reducer';
import seasonRewardPath from './player-challenges/season-reward-path/reducer';
import seasonRewardPathTiers from './player-challenges/season-reward-path-tiers/reducer';
import blankoProgressions from './blanko-progressions/reducer';

const createRootReducer = (history: History) => combineReducers({
  router: connectRouter(history),
  app,
  authentication,
  sidebar,
  transactions,
  catalogs,
  catalog,
  title,
  currencies,
  currencyLimits,
  dailyMessages,
  catalogMigration,
  seasons,
  challengeTemplates,
  challenges,
  seasonChallengeScheduleCalendar,
  seasonChallengeSchedule,
  seasonRewardPath,
  seasonRewardPathTiers,
  blankoProgressions,
});

export default createRootReducer;

export type RootState = ReturnType<ReturnType<typeof createRootReducer>>;
export const useTypedSelector: TypedUseSelectorHook<RootState> = useSelector;
import { useHistory, useLocation, useParams, useRouteMatch } from 'react-router';
import type { LinkTabConfig } from '../../../components/LinkTabs';
import { LinkTabs } from '../../../components/LinkTabs';
import { BlockData } from '../components/BlockData';
import { BlockLogs } from '../components/BlockLogs';
import { BlockReport } from '../components/BlockReport';

export const BlockPage = () => {
  const { blockId } = useParams<{ blockId: string }>();
  
  const tabs: LinkTabConfig[] = [];
  tabs.push(
    //No url for data as we want it to load by default
    // eslint-disable-next-line react/display-name
    { url: '', label: 'Data', render: () => <BlockData blockId={blockId} />},
    // eslint-disable-next-line react/display-name
    { url: '/reports', label: 'Reports', render: () => <BlockReport blockId={blockId} /> },
    // eslint-disable-next-line react/display-name
    { url: '/logs', label: 'Logs', render: () => <BlockLogs blockId={blockId} /> }
    
  );

  const history = useHistory();
  const location = useLocation();
  const match = useRouteMatch();

  return (<>
    <LinkTabs tabs={tabs} history={history} location={location} match={match} />
  </>);
};
import { Component } from 'react';
import type { ConnectedProps} from 'react-redux';
import {connect} from 'react-redux';
import {invalidateTransactionsPage, refundTransactionAsync} from "../../redux/transactions/actions";
import {Button, Dialog, DialogActions, DialogContent, DialogTitle} from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import type {TransactionAggregate} from "../../services/transactions";

const mapDispatch = {
  refundTransaction: refundTransactionAsync.request,
  invalidateTransactionsPage: invalidateTransactionsPage
};
const connector = connect(null, mapDispatch);

type Props = ConnectedProps<typeof connector> & {
  transactionAggregate: TransactionAggregate | undefined;
  onClose: () => void;
}

interface State {
  open: boolean;
  comment: string;
  commentError: string;
}

class RefundDialog extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    if (!this.props.transactionAggregate) {
      throw new Error('Transaction required to refund.')
    }

    this.state = {
      open: true,
      comment: '',
      commentError: RefundDialog.validateComment('')
    };
  }

  onSubmit = () => {
    if (!this.props.transactionAggregate) {
      // the check in the constructor will throw an error, but this is required for typescript to not whine
      return;
    }

    if (!!this.state.commentError) {
      return;
    }
    this.props.refundTransaction({
      comment: this.state.comment,
      transactionId: this.props.transactionAggregate.id
    });
    this.props.invalidateTransactionsPage();
    this.setState({ open: false });
  };

  onCommentChange = (event: any) => {
    const comment = event.target.value || '';
    this.setState({ ...this.state, comment, commentError: RefundDialog.validateComment(comment) });
  };

  private static validateComment(comment: string) {
    if (!comment || !comment.trim()) {
      return 'Cannot be blank';
    }
    return '';
  }

  render() {
    return (
      <Dialog
        open={this.state.open}
        onClose={() => this.setState({ open: false })}
        onExited={this.props.onClose}
        fullWidth
        maxWidth="md"
      >
        <form>
          <DialogTitle>Refund transaction?</DialogTitle>
          <DialogContent>
            <TextField
              label="Comment"
              fullWidth
              autoFocus
              value={this.state.comment}
              error={!!this.state.commentError}
              helperText={this.state.commentError}
              onChange={this.onCommentChange}
            />
          </DialogContent>
          <DialogActions>
            <Button color="primary" variant="contained" disabled={!!this.state.commentError} onClick={() => this.onSubmit()}>
              Refund
            </Button>
            <Button color="secondary" variant="contained" onClick={() => this.setState({ open: false })}>
              Cancel
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    );
  }
}

export default connector(RefundDialog);
import { createAsyncAction, createAction } from 'typesafe-actions';
import type { ItemDefinition, ItemToken } from '../../../services/item-definitions';

export const getCatalogItemsAsync = createAsyncAction(
  'GET_CATALOG_ITEMS_REQUEST',
  'GET_CATALOG_ITEMS_SUCCESS',
  'GET_CATALOG_ITEMS_FAILURE'
)<undefined, ItemDefinition[], string>();

export const clearCatalogItems = createAction('CLEAR_CATALOG_ITEMS')();

export const getCatalogItemAsync = createAsyncAction(
  'GET_CATALOG_ITEM_REQUEST',
  'GET_CATALOG_ITEM_SUCCESS',
  'GET_CATALOG_ITEM_FAILURE'
)<string, ItemDefinition, string>();

export const addCatalogItemAsync = createAsyncAction(
  'ADD_CATALOG_ITEM_REQUEST',
  'ADD_CATALOG_ITEM_SUCCESS',
  'ADD_CATALOG_ITEM_FAILURE'
)<ItemDefinition, ItemDefinition, string>();

export const updateCatalogItemAsync = createAsyncAction(
  'UPDATE_CATALOG_ITEM_REQUEST',
  'UPDATE_CATALOG_ITEM_SUCCESS',
  'UPDATE_CATALOG_ITEM_FAILURE'
)<ItemDefinition, ItemDefinition, string>();

export const getCatalogItemTokenAsync = createAsyncAction(
  'GET_CATALOG_ITEM_TOKEN_REQUEST',
  'GET_CATALOG_ITEM_TOKEN_SUCCESS',
  'GET_CATALOG_ITEM_TOKEN_FAILURE'
)<ItemDefinition, ItemToken | undefined, string>();

export const createCatalogItemTokenAsync = createAsyncAction(
  'CREATE_CATALOG_ITEM_TOKEN_REQUEST',
  'CREATE_CATALOG_ITEM_TOKEN_SUCCESS',
  'CREATE_CATALOG_ITEM_TOKEN_FAILURE'
)<{item: ItemDefinition, token: ItemToken}, ItemToken, string>();

export const updateCatalogItemTokenAsync = createAsyncAction(
  'UPDATE_CATALOG_ITEM_TOKEN_REQUEST',
  'UPDATE_CATALOG_ITEM_TOKEN_SUCCESS',
  'UPDATE_CATALOG_ITEM_TOKEN_FAILURE'
)<{item: ItemDefinition, token: ItemToken}, ItemToken, string>();

export const updateCatalogItemTokenPropertiesAsync = createAsyncAction(
  'UPDATE_CATALOG_ITEM_TOKEN_PROPERTIES_REQUEST',
  'UPDATE_CATALOG_ITEM_TOKEN_PROPERTIES_SUCCESS',
  'UPDATE_CATALOG_ITEM_TOKEN_PROPERTIES_FAILURE'
)<{item: ItemDefinition, token: ItemToken}, ItemToken, string>();

export const freezeCatalogItemTokenAsync = createAsyncAction(
  'FREEZE_CATALOG_ITEM_TOKEN_REQUEST',
  'FREEZE_CATALOG_ITEM_TOKEN_SUCCESS',
  'FREEZE_CATALOG_ITEM_TOKEN_FAILURE'
)<ItemDefinition, ItemToken, string>();
import { useEffect, useState } from 'react';
import { DiffsPanels } from './DiffsPanels';
import type { DiffItem } from '../../types';
import type { MigrationActions } from '../../../../redux/catalog-migration/reducer';
import type { Column } from 'material-table';

interface Props<T extends DiffItem> {
  sourceItems: T[];
  targetItems: T[];
  disableRemove?: boolean;
  selectedIds?: MigrationActions;
  columns: Column<T>[];
  onSelectionChange: (actions: MigrationActions) => void;
}

export const DiffsPanelsContainer = <T extends DiffItem>({
  sourceItems,
  targetItems,
  disableRemove,
  selectedIds,
  columns,
  onSelectionChange
}: Props<T>) => {
  const [itemsChanged, setItemsChanged] = useState<T[]>([]);
  const [itemsAdded, setItemsAdded] = useState<T[]>([]);
  const [itemsRemoved, setItemsRemoved] = useState<T[]>([]);
  const [expandedPanel, setExpandedPanel] = useState(0);

  useEffect(() => {
    const itemsChanged: T[] = [];
    const itemsAdded: T[] = [];

    const targetItemsMap: { [key: string]: T } = {};
    targetItems.forEach(item => targetItemsMap[item.diffId] = item);

    sourceItems.forEach(item => {
      const targetItem = targetItemsMap[item.diffId];
      if (!targetItem) {
        itemsAdded.push(item);
      } else if (!item.isEqual(targetItem)) {
        item.diffJsonPrev = targetItem.diffJson;
        itemsChanged.push(item);
      }

      delete targetItemsMap[item.diffId];
    });

    const itemsRemoved = disableRemove ? [] : Object.values(targetItemsMap);

    setItemsChanged(itemsChanged);
    setItemsAdded(itemsAdded);
    setItemsRemoved(itemsRemoved);

    if (!selectedIds) {
      onSelectionChange({
        update: itemsChanged.map(item => item.diffId),
        add: itemsAdded.map(item => item.diffId),
        remove: itemsRemoved.map(item => item.diffId)
      });
    }
  }, [sourceItems, targetItems, disableRemove, selectedIds, onSelectionChange]);

  if (!selectedIds) {
    return null;
  }

  const onUpdateChange = (update: string[]) => selectedIds && onSelectionChange({ ...selectedIds, update })
  const onAddChange = (add: string[]) => selectedIds && onSelectionChange({ ...selectedIds, add })
  const onRemoveChange = (remove: string[]) => selectedIds && onSelectionChange({ ...selectedIds, remove })

  return (<DiffsPanels
    itemsChanged={itemsChanged}
    itemsAdded={itemsAdded}
    itemsRemoved={itemsRemoved}
    expandedPanel={expandedPanel}
    disableRemove={disableRemove}
    selectedIds={selectedIds}
    columns={columns}
    onUpdateChange={onUpdateChange}
    onAddChange={onAddChange}
    onRemoveChange={onRemoveChange}
    setExpandedPanel={setExpandedPanel} />);
}
import { Box, Button, CircularProgress } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import type { Column } from 'material-table';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import MaterialTable from '../../components/MaterialTable';
import { setAppNotification } from '../../redux/app/actions';
import type { ApiError } from '../../services/api';
import { BlankosService } from '../../services/blankos';
import { CatalogsService } from '../../services/catalogs';
import type { DropTable } from '../../services/drop-tables';
import { ItemDefinition, ItemDefinitionsService } from '../../services/item-definitions';
import ItemSelectDialog from '../ItemDefinition/ItemSelectDialog';

interface DnaRow {
  dnaId: string;
  catalogItem?: ItemDefinition;
}

interface Props {
  progressionId: string;
}

const BlankoProgressionDnas = (props: Props) => {
  const dispatch = useDispatch();

  const [rows, setRows] = useState<DnaRow[]>([]);
  const [columns, setColumns] = useState<Column<DnaRow>[]>([]);
  const [showItemDialog, setShowItemDialog] = useState(false);

  useEffect(() => {
    (async () => {
      const catalogs = await CatalogsService.getCatalogs();

      const catalogItems: { [key: string]: ItemDefinition } = {};
      const primaryCatalog = catalogs.find(c => c.primaryCatalog);
      if (primaryCatalog) {
        const items = await ItemDefinitionsService.getItemDefinitions(primaryCatalog.name);
        items.forEach(item => catalogItems[item.itemId] = item);
      }

      const dnas = await BlankosService.getProgressionDnas(props.progressionId);
      setRows(dnas.map(dna => ({ dnaId: dna, catalogItem: catalogItems[dna] })));

      setColumns([
        {
          title: '',
          field: 'imageThumbnailUrl',
          cellStyle: { padding: 0, width: 64, lineHeight: 0.5 },
          // eslint-disable-next-line react/display-name
          render: row => row.catalogItem && row.catalogItem.imageThumbnailUrl ? (
            <img src={row.catalogItem.imageThumbnailUrl} width={64} height={64} alt={row.catalogItem.itemId} />
          ) : (<Box width={64} height={64} />)
        },
        { title: 'DNA', field: 'dnaId', defaultSort: 'asc' },
        {
          title: 'Display name',
          field: 'displayName',
          sorting: false,
          render: row => row.catalogItem ? row.catalogItem.displayName : '',
          customFilterAndSearch: (filter: string, row) => {
            if (!row.catalogItem) {
              return false;
            }
            return row.catalogItem.displayName.toLocaleLowerCase().indexOf(filter) > -1;
          }
        }
      ]);

    })().catch(e => {
      console.error(e);
    });
  }, [props.progressionId]);

  const onAddItems = (items: (ItemDefinition | DropTable)[]) => {
    const newRows: DnaRow[] = [...rows];
    items.forEach(item => {
      if (item instanceof ItemDefinition) {
        newRows.push({ dnaId: item.itemId, catalogItem: item });
      }
    });
    setRows(newRows);
  }

  const removeItems = (removeRowOrRows: DnaRow | DnaRow[]) => {
    const removeRows = Array.isArray(removeRowOrRows) ? removeRowOrRows : [removeRowOrRows];
    const rowMap: { [key: string]: DnaRow } = {};
    rows.forEach(row => rowMap[row.dnaId] = row);
    removeRows.forEach(removeRow => delete rowMap[removeRow.dnaId]);
    setRows(Object.values(rowMap));
  }

  const onSave = () => {
    BlankosService.updateProgressionDnas(props.progressionId, rows.map(row => row.dnaId)).then(() => {
      dispatch(setAppNotification({ type: 'success', message: 'Progression DNAs updated' }));
    }).catch((e: ApiError) => {
      dispatch(setAppNotification({ type: 'error', message: e.message }));
    });
  }

  if (columns.length < 1) {
    return (
      <Box textAlign="center">
        <CircularProgress />
      </Box>
    );
  }

  return (<>
    <Box mb={2} textAlign="right">
      <Button color="primary" variant="contained" onClick={() => setShowItemDialog(true)}>
        Add DNAs
      </Button>
    </Box>

    <MaterialTable
      title="DNAs"
      columns={columns}
      data={rows}
      options={{
        pageSize: 20,
        pageSizeOptions: [20, 50, 100],
        selection: true,
        showTextRowsSelected: false,
        toolbarButtonAlignment: 'left'
      }}
      actions={[
        {
          tooltip: 'Remove DNAs',
          // eslint-disable-next-line react/display-name
          icon: () => <DeleteIcon />,
          onClick: (event, rows) => removeItems(rows)
        }
      ]}
    />

    <Box mt={2}>
      <Button color="primary" variant="contained" onClick={onSave}>
        Save
      </Button>
    </Box>

    {showItemDialog && (
      <ItemSelectDialog
        showBundles={false}
        showContainers={false}
        onClose={() => setShowItemDialog(false)}
        itemFilter={item => item.itemClass === 'Blanko'}
        addedItems={rows.map(row => row.dnaId)}
        showCatalogSelect
        multi
        onSelect={onAddItems}
      />
    )}
  </>)
}

export default BlankoProgressionDnas;
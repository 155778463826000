import { Box, Button, CircularProgress, Grid, Link } from '@material-ui/core';
import type { Column } from 'material-table';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import MaterialTable from '../../components/MaterialTable';
import { deleteBlankoProgressionAsync, getBlankoProgressionsAsync } from '../../redux/blanko-progressions/actions';
import { useTypedSelector } from '../../redux/reducers';
import type { BlankoProgression } from '../../services/model/blanko';
import { UserService } from '../../services/user';
import { RouterLink } from '../../types';
import DeleteIcon from '@material-ui/icons/Delete';
import { openConfirmationDialog } from '../../redux/app/actions';

const columns: Column<BlankoProgression>[] = [
  {
    title: 'Name',
    field: 'name',
    defaultSort: 'asc',
    // eslint-disable-next-line react/display-name
    render: progression => <Link component={RouterLink} to={`/progressions/${progression.id}`}>{progression.name}</Link>
  },
  {
    title: 'ID',
    field: 'id'
  }
];

const BlankoProgressionsPage = () => {
  const dispatch = useDispatch();

  const isLoading = useTypedSelector(state => state.blankoProgressions.isLoading);
  const progressions = useTypedSelector(state => Object.values(state.blankoProgressions.byId));

  useEffect(() => {
    dispatch(getBlankoProgressionsAsync.request());
  }, [dispatch]);

  const onDelete = (progression: BlankoProgression) => {
    dispatch(openConfirmationDialog({ title: `Delete ${progression.name}?`, action: 'Delete progression' }, () => {
      dispatch(deleteBlankoProgressionAsync.request(progression.id));
    }));
  }

  if (isLoading) {
    return (
      <Box textAlign="center">
        <CircularProgress />
      </Box>
    );
  }

  return (<>
    <Box mb={2}>
      <Grid container spacing={2} alignItems="center">
        <Grid item xs />
        {UserService.canCreate('blankos') && (
          <Grid item>
            <Button
              component={RouterLink}
              variant="contained"
              color="primary"
              to={'/progressions/new'}
            >
              Add progression
            </Button>
          </Grid>
        )}
      </Grid>
    </Box>

    <MaterialTable
      title=""
      columns={columns}
      menuActions={[
        { type: 'button', icon: DeleteIcon, label: 'Delete', onClick: onDelete }
      ]}
      data={progressions}
      options={{
        pageSize: 20,
        pageSizeOptions: [20, 50, 100]
      }}
    />
  </>);
}

export default BlankoProgressionsPage;
import { Player } from "../players";
import MaterialTable from "../../../components/MaterialTable";
import { Column } from "material-table";
import { PlayerChallengeProgressInfo } from "../../player-season-challenges/player-season-challenges-types";
import {
  getPlayerChallengeProgressPage, resetPlayerSeasonProgress,
  updatePlayerChallengeProgress
} from "../../player-season-challenges/player-season-challenges-api";
import { paginatedRequestFromQuery } from "../../../services/model/pagination";
import { usePushNotification } from "../../../contexts/AppNotificationContext";
import { UserService } from "../../../services/user";
import { Button, Tooltip } from "@material-ui/core";
import DoneIcon from '@material-ui/icons/Done';
import { Info } from "@material-ui/icons";
import config from "../../../config";
import SeasonSelect from "../../../pages/SeasonalData/SeasonSelect";
import { useCallback, useRef, useState } from "react";
import { Season } from "../../../services/player-challenges/seasons";
import { openAdminConfirmationDialog } from "../../../shared/hooks/useAdminConfirmationDialog";

export interface PlayerChallengeProgressPageProps {
  player: Player;
}

const columns: Column<PlayerChallengeProgressInfo>[] = [
  {
    title: 'Instance ID',
    field: 'instanceId',
    sorting: true,
    editable: 'never',
  },
  {
    title: 'Progress',
    field: 'progress',
    sorting: false,
  },
  {
    title: 'Reward Claimed',
    field: 'rewardClaimed',
    type: 'boolean',
    sorting: false,
    editable: 'never',
  },
  {
    title: 'Challenge Description',
    field: 'challengeDesc',
    sorting: false,
    editable: 'never',
  },
  {
    title: 'Challenge Name',
    field: 'challengeName',
    sorting: false,
    editable: 'never',
  },
  {
    title: 'Season Number',
    field: 'seasonNumber',
    sorting: true,
    editable: 'never',
  },
  {
    title: 'Community Challenge',
    field: 'communityChallengeProgress',
    sorting: false,
    render(info) {
      const progress = info.communityChallenge ? info.communityChallengeProgress.toString() : '-';
      return <>
        {progress}
        {info.communityChallengeFinished &&
          <Tooltip title="Completed">
            <DoneIcon htmlColor="#00b116" />
          </Tooltip>
        }
      </>
    },
  },
  {
    title: 'Created At',
    field: 'createdAt',
    sorting: true,
    editable: 'never',
  },
  {
    title: 'Updated At',
    field: 'updatedAt',
    sorting: true,
    editable: 'never',
  },
];

export const PlayerChallengeProgressPage = ({player}: PlayerChallengeProgressPageProps) => {
  const pushNotification = usePushNotification();
  const [season, setSeason] = useState<Season>();
  const tableRef = useRef();
  const onSeasonSelectChange = useCallback((season: Season) => {
    setSeason(season);
    (tableRef.current as any)?.onQueryChange();
  }, [setSeason, tableRef]);

  let seasonNumber = 0;
  if (season && season.season_number !== undefined && season.season_number !== null) {
    seasonNumber = season.season_number;
  }

  return <>
    <SeasonSelect
      value={season?.season_number}
      onChange={onSeasonSelectChange}
      defaultToActive={true}
    />
    &nbsp;
    &nbsp;
    {season?.active &&
      <Button
        variant='contained'
        color='primary'
        onClick={() => openAdminConfirmationDialog({
            title: 'Clear all progress for this player in the selected season?',
            onConfirm: () => resetPlayerSeasonProgress(player.externalId, seasonNumber)
              .then(() => {
                (tableRef.current as any)?.onQueryChange();
                pushNotification({type: 'success', message: 'Reset player progress for active season'})
              })
              .catch(err => pushNotification({type: 'error', message: `Failed to reset player progress: ${err.message}`})),
          })}
      >
        Reset player progress
      </Button>
    }
    <br/>
    <br/>
    <MaterialTable<PlayerChallengeProgressInfo>
      tableRef={tableRef}
      title={<>
        Challenge Progress &nbsp;
        <Tooltip
          title={"Editing the progress will not trigger completion of a challenge, completion needs to be externally triggered"}>
          <Info/>
        </Tooltip>
      </>}
      columns={columns}
      editable={{
        isEditable: () => UserService.canUpdate('playerSeasonChallengeProgress') && (config.env === 'local' || config.env === 'dev' || config.env === 'qa'),
        onRowUpdate: (newData) => updatePlayerChallengeProgress(player.externalId, newData.instanceId, newData.progress)
          .then(() => pushNotification({type: 'success', message: 'Updated progress'}))
          .catch(err => pushNotification({type: 'error', message: `Failed to update progress: ${err.message}`})),
      }}
      isLoading={typeof season === 'undefined'}
      data={(query => {
          if (typeof season?.season_number === 'undefined') {
            return Promise.resolve({
              data: [],
              page: query.page,
              totalCount: 0,
            });
          }
          return getPlayerChallengeProgressPage(player.externalId, season.season_number, paginatedRequestFromQuery(query, 'instanceId'))
            .catch(err => pushNotification({
              type: 'error',
              message: `Failed to get player challenge progress page: ${err.message}`
            }))
            .then(response => ({
              data: response?.items || [],
              page: query.page,
              totalCount: response?.totalCount || 0,
            }));
        }
      )}
    />
  </>;
};

import { useTypedSelector } from '../../../../redux/reducers';
import { Results } from './Results';

const resultItems: CatalogMigrationItem[] = [
  { name: 'Items & drop tables', id: 'items' },
  { name: 'Stores', id: 'stores' },
  { name: 'Title data - Client', id: 'titleDataClient' },
  { name: 'Title data - Server', id: 'titleDataServer' },
  { name: 'Title files', id: 'titleDataFiles' },
  { name: 'Blanko DNAs', id: 'blankoDnas' },
  { name: 'Blanko assets', id: 'blankoAssets' },
  { name: 'Blanko progressions', id: 'blankoProgressions' },
  { name: 'Seasonal data', id: 'seasonalData' },
  { name: 'Translations', id: 'translations' },
  { name: 'Text Languages', id: 'textLanguages' },
];

export const ResultsContainer = () => {
  const results = useTypedSelector(state => state.catalogMigration.results);

  return (<Results
    resultItems={resultItems}
    results={results} />);
}

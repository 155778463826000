import {call, put, takeLatest} from 'redux-saga/effects';
import type {ScheduleCalendar} from '../../../services/player-challenges/season-challenge-schedule-calendar';
import { SeasonChallengeScheduleCalendarService} from '../../../services/player-challenges/season-challenge-schedule-calendar';
import {setAppNotification} from '../../app/actions';
import {forkSagas, sagaHandleApiError} from '../../utils-ts';
import {createSeasonChallengeScheduleCalendarAsync, deleteSeasonChallengeScheduleCalendarAsync, getSeasonChallengeScheduleCalendarAsync, getSeasonChallengeScheduleCalendarsAsync, updateSeasonChallengeScheduleCalendarAsync} from './actions';

function* fetchSeasonChallengeScheduleCalendars(action: ReturnType<typeof getSeasonChallengeScheduleCalendarsAsync.request>) {
  try {
    const seasons: ScheduleCalendar[] = yield call(SeasonChallengeScheduleCalendarService.getSeasonChallengesScheduleCalenders, action.payload.seasonNumber, action.payload.scheduleId);
    yield put(getSeasonChallengeScheduleCalendarsAsync.success(seasons));
  } catch (e) {
    yield sagaHandleApiError(e, getSeasonChallengeScheduleCalendarsAsync.failure);
  }
}

function* fetchSeasonChallengeScheduleCalendar(action: ReturnType<typeof getSeasonChallengeScheduleCalendarAsync.request>) {
  try {
    const season: ScheduleCalendar = yield call(SeasonChallengeScheduleCalendarService.getSeasonChallengesScheduleCalender,
      action.payload.seasonNumber, action.payload.scheduleId, action.payload.instanceId);
    season.season_number = action.payload.seasonNumber;
    yield put(getSeasonChallengeScheduleCalendarAsync.success(season));
  } catch (e) {
    yield sagaHandleApiError(e, getSeasonChallengeScheduleCalendarAsync.failure);
  }
}

function* createSeasonChallengeScheduleCalendarSaga(action: ReturnType<typeof createSeasonChallengeScheduleCalendarAsync.request>) {
  try {
    const season: ScheduleCalendar = yield call(SeasonChallengeScheduleCalendarService.createSeasonChallengeScheduleCalender,
      action.payload.seasonNumber, action.payload.scheduleId, action.payload.scheduleCalendarJson);
    season.season_number = action.payload.seasonNumber;
    yield put(createSeasonChallengeScheduleCalendarAsync.success(season));
    yield put(setAppNotification({type: 'success', message: 'Season Challenge Schedule Calendar updated'}));
  } catch (e) {
    yield sagaHandleApiError(e, createSeasonChallengeScheduleCalendarAsync.failure);
  }
}

function* updateSeasonChallengeScheduleCalendarSaga(action: ReturnType<typeof updateSeasonChallengeScheduleCalendarAsync.request>) {
  try {
    const season: ScheduleCalendar = yield call(SeasonChallengeScheduleCalendarService.updateSeasonChallengeScheduleCalender,
      action.payload.seasonNumber, action.payload.scheduleId, action.payload.instanceId, action.payload.scheduleCalendar);
    season.season_number = action.payload.seasonNumber;
    yield put(updateSeasonChallengeScheduleCalendarAsync.success(season));
    yield put(setAppNotification({type: 'success', message: 'Season Challenge Schedule Calendar updated'}));
  } catch (e) {
    yield sagaHandleApiError(e, updateSeasonChallengeScheduleCalendarAsync.failure);
  }
}

function* deleteSeasonChallengeScheduleCalendarSaga(action: ReturnType<typeof deleteSeasonChallengeScheduleCalendarAsync.request>) {
  try {
    yield call(SeasonChallengeScheduleCalendarService.deleteSeasonChallengeScheduleCalender,
      action.payload.seasonNumber, action.payload.scheduleId, action.payload.instanceId);
    yield put(deleteSeasonChallengeScheduleCalendarAsync.success(action.payload));
    yield put(setAppNotification({type: 'success', message: 'Season Challenge Schedule Calendar deleted'}));
  } catch (e) {
    yield sagaHandleApiError(e, deleteSeasonChallengeScheduleCalendarAsync.failure);
  }
}

const sagas = [
  function* () {
    yield takeLatest(getSeasonChallengeScheduleCalendarsAsync.request, fetchSeasonChallengeScheduleCalendars);
  },
  function* () {
    yield takeLatest(getSeasonChallengeScheduleCalendarAsync.request, fetchSeasonChallengeScheduleCalendar);
  },
  function* () {
    yield takeLatest(createSeasonChallengeScheduleCalendarAsync.request, createSeasonChallengeScheduleCalendarSaga);
  },
  function* () {
    yield takeLatest(updateSeasonChallengeScheduleCalendarAsync.request, updateSeasonChallengeScheduleCalendarSaga);
  },
  function* () {
    yield takeLatest(deleteSeasonChallengeScheduleCalendarAsync.request, deleteSeasonChallengeScheduleCalendarSaga);
  }
];

export default function* seasonsSagas() {
  yield forkSagas(sagas);
}
import type { MigrationDependencyErrors, MigrationDiffCommonProps } from '../../migrations';
import { DiffsPanels } from '../../components/diff/DiffsPanels';
import { useMemo } from 'react';
import { MigrationType } from "../migration-type";

export const TitleDataPinRuleDiffs = (props: MigrationDiffCommonProps) => {

  const diffs = props.migrationData.titleDataPinRules;

  const depErrors = useMemo(() => {
    const dependencyErrors: MigrationDependencyErrors = {};
    diffs?.added.concat(diffs.changed).filter(diff => diff.invalidReward).forEach(diff => {
      dependencyErrors[diff.diffId] = {
        hideFixable: true,
        missingDeps: [{
          type: MigrationType.item,
          id: diff.diffId,
          message: `Reward item '${diff.invalidReward?.rewardItemId}' in '${diff.invalidReward?.tierLevel}' tier does not exist in target catalog.`,
        }]
      };
    });
    return dependencyErrors;
  }, [diffs]);

  const selectionMap = useMemo(() => {
    const selections = props.selections.brawlPinTitleData;
    if (selections) {
      diffs?.added.concat(diffs.changed).filter(diff => diff.invalidReward).forEach(diff => {
        selections[diff.diffId] = false;
      });
    }
    return selections;
  }, [diffs, props.selections.brawlPinTitleData]);

  const enabledMap = useMemo(() => {
    const enabledItems: { [key: string]: boolean } = {};
    diffs?.added.concat(diffs.changed).filter(diff => diff.invalidReward).forEach(diff => {
      enabledItems[diff.diffId] = false;
    });
    return enabledItems;
  }, [diffs]);

  if (diffs) {
    return (
      <DiffsPanels
        diffs={diffs}
        columns={[
          {
            title: 'Rule Name', field: 'Name', defaultSort: 'asc', render(item) {
              return (<>
                {item.name}
              </>);
            }
          },
        ]}
        enabledMap={enabledMap}
        selectionMap={selectionMap || {}}
        onSelectionChange={map => props.onSelectionsChange({ ...props.selections, brawlPinTitleData: map })}
        {...props}
        depErrors={depErrors}
      />
    )
  }

  return null;
};

import type { ApiError } from './api';
import { api } from './api';
import type { PlayerDataField } from '../features/players/players';

export class ItemInstancesService {
  static async updateItemInstanceData(id: string, data: { [key: string]: PlayerDataField }): Promise<{ [key: string]: PlayerDataField }> {
    return api.put<{ [key: string]: PlayerDataField }>({ url: `/inventory/item-instances/${id}/data`, body: data })
      .catch((e: ApiError) => {
        e.message = `Failed to update item custom data. ${e.message}`;
        throw e;
      });
  }
}
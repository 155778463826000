import { useEffect, useState, useCallback } from 'react';
import * as React from 'react';
import type { Theme} from '@material-ui/core';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, makeStyles, createStyles, Switch } from '@material-ui/core';
import { deactivateBlankoRush, activateBlankoRush, updateBlankoRush } from '../gemRushApiService';
import { RushForm } from '.';
import { useFormik } from 'formik';
import { rushValidationSchema } from '..';
import { format } from 'date-fns';
import type { BlankoGemRush } from '../blankoGemRush';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    form: {
      display: 'flex',
      flexDirection: 'column',
      margin: 'auto',
      width: 'fit-content',
    },
    formControl: {
      marginTop: theme.spacing(2),
    }
  }),
);

interface EditRushDialogProps {
  rush: BlankoGemRush
  onClose: () => void
  show: boolean
}

export const EditRushDialog = ({rush,
  onClose,
  show}: EditRushDialogProps) => {
  const classes = useStyles();
  const [activeRush, setActiveRush] = useState(rush.active);
  const formikRushes = useFormik({
    validationSchema: rushValidationSchema,
    initialValues: {
      id: rush.id,
      name: rush.name,
      startDate: format(new Date(rush.startDate), 'yyyy-MM-dd\'T\'HH:mm:ss'),
      endDate: format(new Date(rush.endDate), 'yyyy-MM-dd\'T\'HH:mm:ss'),
      active: rush.active,
    },
    onSubmit: async values => {
      values.startDate = new Date(values.startDate).toISOString()
      values.endDate = new Date(values.endDate).toISOString()
      await updateBlankoRush(values)
      onClose()
    },
  });
  const handleChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setActiveRush(event.target.checked);
  }, [setActiveRush])
  
  useEffect(() => {
    activeRush ? activateBlankoRush(rush.id) : deactivateBlankoRush(rush.id)
  }, [activeRush, rush])
  
  return (
    <>
      <Dialog
        open={show}
        fullWidth
        onClose={onClose}
        aria-labelledby="rushes-and-fuses-dialog-title"
      >
        <form onSubmit={formikRushes.handleSubmit} className={classes.form}>
          <DialogTitle id="rushes-and-fuses-dialog-title">Rush Edit</DialogTitle>
          <DialogContent className={classes.form}>
            {formikRushes.values && <>
              <div className={classes.form}>
                <div className={classes.formControl}>
                  <b>Activate: </b>
                  <Switch
                    checked={activeRush}
                    onChange={handleChange}
                    name="activeRush"
                    color="primary"
                  />
                </div>
              </div>
              <RushForm {...formikRushes} />
            </> }
          </DialogContent>
          <DialogActions>
            <Button 
              type="submit"
              variant="contained"
              disabled={!(formikRushes.isValid && (formikRushes.dirty || activeRush !== rush.active))}
              color="primary"
            >
              Save
            </Button>
            <Button onClick={onClose} color="primary">
              Close
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </>
  );
}

import type { Reducer } from 'redux';
import { createReducer } from 'typesafe-actions';
import type { Season } from '../../../services/player-challenges/seasons';
import {
  getSeasonsAsync,
  updateSeasonAsync,
  deleteSeasonAsync,
  getSeasonAsync,
  createSeasonAsync,
  clearSeasonError
} from './actions';

export interface SeasonsState {
  isLoading: boolean;
  isSaving: boolean;
  isDeleting?: number;
  error?: string;
  data: Season[];
}

const initialState: SeasonsState = {
  isLoading: false,
  isSaving: false,
  isDeleting: undefined,
  error: undefined,
  data: []
};

const seasonsReducer: Reducer<SeasonsState> = createReducer<SeasonsState>(initialState)
  .handleAction(getSeasonsAsync.request, state => ({
    ...state,
    isLoading: true
  }))
  .handleAction(getSeasonsAsync.success, (state, action) => ({
    ...state,
    isLoading: false,
    data: action.payload
  }))
  .handleAction(getSeasonsAsync.failure, state => ({
    ...state,
    isLoading: false
  }))
  .handleAction(getSeasonAsync.request, (state, action) => ({
    ...state,
    isLoading: true
  }))
  .handleAction(getSeasonAsync.success, (state, action) => {
    const index = state.data.findIndex(season => season.season_number === action.payload.season_number);
    const newData = state.data.slice();
    if (index >= 0) {
      newData[index] = action.payload;
    } else {
      newData.push(action.payload);
    }
    return ({
      ...state,
      isLoading: false,
      data: newData
    })
  })
  .handleAction(getSeasonAsync.failure, (state, action) => ({
    ...state,
    isLoading: false
  }))
  .handleAction(createSeasonAsync.request, (state, action) => ({
    ...state,
    isSaving: true
  }))
  .handleAction(createSeasonAsync.success, (state, action) => {
    return ({
      ...state,
      isSaving: false,
      error: undefined,
      data: state.data.concat(action.payload)
    });
  })
  .handleAction(createSeasonAsync.failure, (state, action) => ({
    ...state,
    isSaving: false,
    error: action.payload
  }))
  .handleAction(updateSeasonAsync.request, (state, action) => ({
    ...state,
    isSaving: true
  }))
  .handleAction(updateSeasonAsync.success, (state, action) => {
    const index = state.data.findIndex(season => season.season_number === action.payload.season_number);
    const newData = state.data.slice();
    if (index >= 0) {
      newData.splice(index, 1, action.payload);
    }

    return ({
      ...state,
      isSaving: false,
      error: undefined,
      data: newData
    });
  })
  .handleAction(updateSeasonAsync.failure, (state, action) => ({
    ...state,
    isSaving: false,
    error: action.payload
  }))
  .handleAction(deleteSeasonAsync.request, (state, action) => ({
    ...state,
    isDeleting: action.payload.seasonNumber
  }))
  .handleAction(deleteSeasonAsync.success, (state, action) => {
    const index = state.data.findIndex(season => season.season_number === action.payload);
    const newData = state.data.slice();
    if (index >= 0) {
      newData.splice(index, 1);
    }
    return ({
      ...state,
      isDeleting: undefined,
      data: newData
    });
  })
  .handleAction(deleteSeasonAsync.failure, (state, action) => ({
    ...state,
    isDeleting: undefined
  }))
  .handleAction(clearSeasonError, state => ({
    ...state,
    error: undefined
  }));


export default seasonsReducer;
import { useCallback, useState } from "react";
import { usePushNotification } from "../../../contexts/AppNotificationContext";
import MaterialTable from "../../../components/MaterialTable";
import { Box, Button, CircularProgress, Grid, Link, Typography } from "@material-ui/core";
import { Column } from "material-table";
import { Link as RouterLink } from "react-router-dom";
import { UserService } from "../../../services/user";
import { RouteComponentProps } from "react-router";
import { Season, SeasonsService } from "../../../services/player-challenges/seasons";
import { AddSeasonDialog } from "./AddSeasonDialog";

const columns: Column<Season>[] = [
  {
    title: 'Number', field: 'seasonNumber', defaultSort: 'desc',
    render: season => <Link component={RouterLink} to={`/live-events/${season.live_events_id}/seasons/${season.season_number}`}>{season.season_number}</Link>
  },
  {
    title: 'Name', field: 'seasonName',
    render: season => <Link component={RouterLink} to={`/live-events/${season.live_events_id}/seasons/${season.season_number}`}>{season.season_name}</Link>
  },
];

export interface Props extends RouteComponentProps {
  seasons: Season[];
  liveEventId: string;
  refreshSeasons: () => Promise<void>;
}

export const SeasonsTable = ({history, seasons, liveEventId, refreshSeasons}: Props) => {
  const pushNotification = usePushNotification();

  const [showAddSeasonDialog, setShowAddSeasonDialog] = useState(false);

  const onDelete = useCallback((season) => {
    return SeasonsService.deleteSeason(liveEventId, season.season_number)
      .then(() => refreshSeasons())
      .catch(err => pushNotification({type: 'error', message: `Failed to delete season: ${err.message}`}));
  }, [liveEventId, pushNotification, refreshSeasons]);

  const onAddSeason = useCallback(() => {
    setShowAddSeasonDialog(false);
    refreshSeasons();
  }, [refreshSeasons]);

  if (!seasons) {
    return <Box textAlign="center">
      <CircularProgress/>
    </Box>;
  }

  return <>
    <Box>
      <Grid container>
        <Grid item xs>
          <Typography variant="h6">
            Seasons
          </Typography>
        </Grid>
        {UserService.canCreate('liveEvents') && (
          <Grid item>
            <Button variant="contained" color="primary" disabled={seasons.length > 0} onClick={() => setShowAddSeasonDialog(true) }>
              Add Season
            </Button>
          </Grid>
        )}
      </Grid>
      <br/>
      <MaterialTable<Season>
        title="Seasons"
        storageId="season-event-infos"
        options={{
          paging: false,
          search: false,
          toolbar: false,
          sorting: false,
          showTitle: false
        }}
        editable={{
          isDeletable: () => UserService.canDelete('liveEvents'),
          onRowDelete: (season) => onDelete(season),
        }}
        columns={columns}
        data={seasons}
      />
    </Box>
    {showAddSeasonDialog && (
      <AddSeasonDialog
        liveEventId={liveEventId}
        onCancel={() => setShowAddSeasonDialog(false)}
        onAdd={onAddSeason}
      />
    )}
  </>;
};

import { createAsyncAction } from 'typesafe-actions';
import type { RewardPath } from '../../../services/player-challenges/season-reward-paths';

export const createRewardPathAsync = createAsyncAction(
  'CREATE_SEASON_REWARD_PATH_REQUEST',
  'CREATE_SEASON_REWARD_PATH_SUCCESS',
  'CREATE_SEASON_FAILURE'
)<{ seasonNumber: number, rewardPathJson: any }, RewardPath, string>();

export const getRewardPathAsync = createAsyncAction(
  'GET_SEASON_REWARD_PATH_REQUEST',
  'GET_SEASON_REWARD_PATH_SUCCESS',
  'GET_SEASON_REWARD_PATH_FAILURE'
)<number, RewardPath, string>();

export const updateRewardPathAsync = createAsyncAction(
  'UPDATE_SEASON_REWARD_PATH_REQUEST',
  'UPDATE_SEASON_REWARD_PATH_SUCCESS',
  'UPDATE_SEASON_REWARD_PATH_FAILURE'
)<{ seasonNumber: number, pathId: string, rewardPath: RewardPath }, RewardPath, string>();

export const deleteRewardPathAsync = createAsyncAction(
  'DELETE_SEASON_REWARD_PATH_REQUEST',
  'DELETE_SEASON_REWARD_PATH_SUCCESS',
  'DELETE_SEASON_REWARD_PATH_FAILURE'
)<{ seasonNumber: number, pathId: string }, number, string>();

import { Grid } from '@material-ui/core';
import { Field } from 'formik';
import { TextField } from 'formik-material-ui';

export type CurrenciesFormValues = {
  code: string;
  name: string;
  amount: string;
}[];

interface Props {
  formName: string;
  value: CurrenciesFormValues;
}

const CurrenciesForm = (props: Props) => {
  return (
    <Grid container spacing={1}>
      {(props.value.map((currency, index) => (
        <Grid item xs={12} key={currency.code}>
          <Field
            component={TextField}
            name={`${props.formName}[${index}].amount`}
            type="text"
            label={currency.name}
          />
        </Grid>
      )))}
    </Grid>
  );
}

export default CurrenciesForm;
import { useCallback } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, DialogContentText } from '@material-ui/core';
import { deleteBlankoRushFuseById } from '../gemRushApiService';

interface DeleteDialogProps {
  show: boolean
  fuse: string
  onClose: () => void
}
export const DeleteFuseDialog = ({show, fuse, onClose }: DeleteDialogProps) => {
  const onConfirm = useCallback(async () => {
    await deleteBlankoRushFuseById(fuse)
    onClose()
  }, [fuse, onClose])
  return (
    <div>
      <Dialog
        open={show}
        keepMounted
        onClose={onClose}
        aria-labelledby="delete-dialog-title"
        aria-describedby="delete-dialog-description"
      >
        <DialogTitle id="delete-dialog-title">{"Delete Fuse"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="delete-dialog-description">
            Are you sure you want to delete this fuse?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} color="primary">
            No
          </Button>
          <Button onClick={onConfirm} color="primary">
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
import {useEffect, useRef, useState} from 'react';
import { Link as RouterLink } from 'react-router-dom';

import DoneIcon from '@material-ui/icons/Done';
import { Box, FormControlLabel, Grid, Link, Switch } from '@material-ui/core';

import type { Column } from 'material-table';
import type { MenuAction } from '../../../components/MaterialTable';
import MaterialTable from '../../../components/MaterialTable';

import type { PlayerReport } from '../../players/players';
import {
  MarkInReviewPlayerReportRequest,
  Player,
  PlayersService,
  ResolvePlayerReportRequest
} from '../../players/players';
import { UserService } from '../../../services/user';
import { pushAppNotification } from "../../../shared/hooks/useAppNotification";
import { openAdminConfirmationDialog } from "../../../shared/hooks/useAdminConfirmationDialog";
import {MaterialTableCallback} from "../../../utils/MaterialTableCallback";

export const ReportedPlayers = () => {
  const [showClosedReportsOnly, setShowClosedReportsOnly] = useState(false);

  const onSetShowClosedReportsOnly = (value: boolean) => {
    setShowClosedReportsOnly(value);
  };

  const [filters, setFilters] = useState<string[]>([]);
  const tableRef = useRef<MaterialTable<Player>>();

  useEffect(() => {
    const array = [];
    if (showClosedReportsOnly){
      array.push("closedOnly");
    }
    setFilters(array);
  }, [showClosedReportsOnly]);

  const clickMarkInReview = (playerReport: PlayerReport) => {
    openAdminConfirmationDialog({
      title: 'Mark In Review?',
      details: getDetailsText(playerReport),
      action: 'Mark In Review',
      onConfirm: () => {
        const request = new MarkInReviewPlayerReportRequest(playerReport.id);
        PlayersService.markInReviewPlayerReport(request)
          .then(() => {
            pushAppNotification({type: 'success', message: 'Report marked as in review'});
          })
          .catch(err => pushAppNotification({
            type: 'error',
            message: `Failed to mark report as in review: ${err.message}`
          }));
      },
    });
  };

  const clickResolveReport = (playerReport: PlayerReport) => {
    openAdminConfirmationDialog({
      title: 'Close Player Report?',
      details: getDetailsText(playerReport),
      includeNote: true,
      requireNote: true,
      action: 'Close Report',
      onConfirm: (note?: string) => {
        const request = new ResolvePlayerReportRequest(playerReport.id);
        if (note) {
          request.note = note;
        }
        PlayersService.resolvePlayerReport(request)
          .then(() => {
            pushAppNotification({type: 'success', message: 'Report closed'});
          })
          .catch(err => pushAppNotification({type: 'error', message: `Failed to close report: ${err.message}`}));
      },
    });
  };

  const getDetailsText = (playerReport: PlayerReport) => {
    return `Player ${
      playerReport.reportedDisplayName || playerReport.reportedExternalId
    } was reported by ${
      playerReport.displayName || playerReport.externalId
    }: ${playerReport.description}`;
  };

  const getTableMenuActions = (playerReport: PlayerReport) => {
    const actions: MenuAction<PlayerReport>[] = [];
    if (UserService.canUpdate('playerReport')) {
      if (playerReport.status === "OPEN") {
        actions.push({type: 'button', icon: DoneIcon, label: 'Mark In Review', onClick: clickMarkInReview});
      } else if (playerReport.status === "IN_REVIEW") {
        actions.push({type: 'button', icon: DoneIcon, label: 'Close Report', onClick: clickResolveReport});
      }
    }
    return actions;
  };

  const getColumns = () => {
    const columns: Column<PlayerReport>[] = [
      {title: 'Status', field: 'status', sorting: true},
      {
        title: 'Reported Player',
        field: 'reportedDisplayName',
        sorting: true,
        render: playerReport => UserService.canRead('player') ? (
          <Link component={RouterLink} to={`/players/${playerReport.reportedExternalId}`}>
            {playerReport.reportedDisplayName || playerReport.reportedExternalId}
          </Link>
        ) : <>{playerReport.reportedDisplayName || playerReport.reportedExternalId}</>
      },
      {title: 'Reason', field: 'reason', sorting: true},
      {title: 'Description', field: 'description', sorting: true},
      {
        title: 'Reporting Player',
        field: 'playerDisplayName',
        sorting: true,
        render: playerReport => UserService.canRead('player') ? (
          <Link component={RouterLink} to={`/players/${playerReport.externalId}`}>
            {playerReport.displayName || playerReport.externalId}
          </Link>
        ) : <>{playerReport.displayName || playerReport.externalId}</>
      },
      {
        title: 'Reported Date',
        field: 'createdTimestamp',
        sorting: true,
        defaultSort: 'desc',
        render: blockReport => <>{new Date(blockReport.createdTimestamp).toLocaleString()}</>
      },
      {title: 'Note', field: 'note', sorting: true, hidden: !showClosedReportsOnly}
    ];

    return columns;
  };

  return (<>
    <Box mb={2}>
      <Grid container alignItems="center">
        <Grid item xs>
          <FormControlLabel
            control={
              <Switch
                checked={showClosedReportsOnly}
                onChange={event => onSetShowClosedReportsOnly(event.target.checked)}
                color="primary"
              />
            }
            label="Show Closed Reports"
          />
        </Grid>
      </Grid>
    </Box>
    <MaterialTableCallback
      title={'Reported Players'}
      columns={getColumns()}
      data={PlayersService.getPlayerReportsPage}
      options={{
        debounceInterval: 500,
        pageSize: 10,
        thirdSortClick: false
      }}
      menuActions={showClosedReportsOnly ? [] : getTableMenuActions}
      filters={filters}
      tableRef={tableRef}
    />
  </>);
};

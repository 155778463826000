import {Box, Divider, Tabs} from '@material-ui/core';
import * as React from 'react';
import type { RouteComponentProps} from 'react-router';
import {Route, Switch} from 'react-router';
import LinkTab from './LinkTab';

export interface LinkTabConfig {
  url: string;
  label: string;
  component?: React.ComponentType<any>;
  render?: (routeProps: RouteComponentProps) => React.ReactNode;
  absolute?: boolean;
}

type Props = RouteComponentProps & {
  tabs: LinkTabConfig[];
  scrollable?: boolean;
};

interface State {
  selectedTab: number;
}

export class LinkTabs extends React.Component<Props, State> {
  private reversedTabs: LinkTabConfig[]; // For route matching

  constructor(props: Props) {
    super(props);
    this.reversedTabs = this.props.tabs.slice().reverse();

    this.state = {
      selectedTab: this.findSelectedTab()
    };
  }

  private findSelectedTab() {
    const matchUrlIndex = this.reversedTabs.findIndex(tab => tab.url && this.props.location.pathname.startsWith(this.getUrl(tab)));
    return matchUrlIndex > -1 ? this.reversedTabs.length - matchUrlIndex - 1 : 0;
  }

  private getUrl(tab: LinkTabConfig) {
    return tab.absolute ? tab.url : this.props.match.url + tab.url;
  }

  componentDidUpdate(prevProps: Props) {
    if (this.props.location.pathname !== prevProps.location.pathname) {
      this.setState({selectedTab: this.findSelectedTab()});
    }
  }

  handleTabChange = (event: any, value: any) => {
    this.setState({selectedTab: value});
  };

  render() {
    this.reversedTabs = this.props.tabs.slice().reverse();
    const linkTabs = this.props.tabs.map(tab => (
      <LinkTab key={tab.url} label={tab.label} to={this.getUrl(tab)} replace={true}/>
    ));

    const routes = this.reversedTabs.map(tab => (
      <Route key={tab.url} path={this.getUrl(tab)} component={tab.component} render={tab.render}/>
    ));

    return (<>
      <Tabs variant={this.props.scrollable ? 'scrollable' : 'standard'} value={this.state.selectedTab} onChange={this.handleTabChange}>
        {linkTabs}
      </Tabs>
      <Divider/>
      <Box py={2}>
        <Switch>
          {routes}
        </Switch>
      </Box>
      {/* HACK: Hide overflow-x on the main container when tabs are scrollable to allow them to... scroll. */}
      {this.props.scrollable && <style>{`main { overflow-x: hidden }`}</style>}
    </>);
  }
}
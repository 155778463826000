import { Button, Card, CardContent, CardHeader, FormControl, FormGroup, FormLabel, IconButton, Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import { Field } from 'formik';
import { CheckboxWithLabel } from 'formik-material-ui';
import { useCallback, useState } from 'react';
import type { DropTable } from '../../services/drop-tables';
import { ItemDefinition } from '../../services/item-definitions';
import { blankoAttachmentSlots } from '../../services/model/blanko';
import ItemSelectDialog from '../ItemDefinition/ItemSelectDialog';


export interface DefaultAttachment {
  itemId: string;
  slots: string[];
}

interface BlankoLevelDefaultAttachmentsFormProps {
  formName: string;
  attachments: DefaultAttachment[];
  onAdd: (item: DefaultAttachment) => void;
  onRemove: (index: number) => void;
}

export const BlankoLevelDefaultAttachmentsForm = (props: BlankoLevelDefaultAttachmentsFormProps) => {
  const { attachments, onAdd, onRemove } = props;

  const [addItemDialogOpen, setAddItemDialogOpen] = useState(false);

  const onItemsSelected = useCallback((items: (ItemDefinition | DropTable)[]) => {
    items.forEach(item => {
      if (item instanceof ItemDefinition) {
        onAdd({ itemId: item.itemId, slots: [] });
      }
    });
  }, [onAdd]);

  return (<>
    <Card variant="outlined">
      <CardHeader
        title="Default attachments"
        action={
          <Button color="primary" variant="contained" onClick={() => setAddItemDialogOpen(true)}>
            Add
          </Button>
        }
      />
      <CardContent>
        {attachments.length > 0 && (
          <Table size="small">
            <TableHead>
              <TableRow>
                {<TableCell> </TableCell>}
                <TableCell>ID</TableCell>
                <TableCell>Slots</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {attachments.map((item, index) => (
                <TableRow key={item.itemId}>
                  <TableCell padding="none" style={{ width: 1 }}>
                    <IconButton onClick={() => onRemove(index)}>
                      <DeleteIcon />
                    </IconButton>
                  </TableCell>
                  <TableCell>{item.itemId}</TableCell>
                  <TableCell>
                    <FormControl fullWidth>
                      <FormGroup row>
                        {blankoAttachmentSlots.map(slot => (
                          <Field
                            key={slot}
                            component={CheckboxWithLabel}
                            type="checkbox"
                            name={`${props.formName}[${index}].slots`}
                            value={slot}
                            Label={{ label: slot }}
                          />
                        ))}
                      </FormGroup>
                    </FormControl>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        )}

        {attachments.length < 1 && (
          <Typography color="textSecondary">
            No items added yet.
          </Typography>
        )}
      </CardContent>
    </Card>

    {addItemDialogOpen && (
      <ItemSelectDialog
        multi
        showCatalogSelect
        addedItems={attachments.map(item => item.itemId)}
        onSelect={onItemsSelected}
        onClose={() => setAddItemDialogOpen(false)}
      />
    )}
  </>);
};
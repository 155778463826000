import type { Theme } from '@material-ui/core';
import { Box, CircularProgress, Divider, Grid, IconButton, InputAdornment, Link, ListItemIcon, makeStyles, Menu, MenuItem, Paper, Select, TextField, Tooltip, withTheme } from '@material-ui/core';
import BlockIcon from '@material-ui/icons/Block';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import ErrorIcon from '@material-ui/icons/Error';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import PublishIcon from '@material-ui/icons/Publish';
import ScheduleIcon from '@material-ui/icons/Schedule';
import StarIcon from '@material-ui/icons/Star';
import StarBorderIcon from '@material-ui/icons/StarBorder';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useDrag, useDrop } from 'react-dnd';
import { getEmptyImage } from 'react-dnd-html5-backend';
import { useDropzone } from 'react-dropzone';
import { useDispatch } from 'react-redux';
import { setAppNotification } from '../../../redux/app/actions';
import type { ApiError } from '../../../services/api';
import type { ItemDefinition } from '../../../services/item-definitions';
import { BuiltInCurrencyEnum, CurrencyList } from '../../../services/model/currency';
import { RouterLink } from '../../../types';
import type { ShopItem, ShopTab, ShopTabItem } from '../shop';
import { uploadShopTabItemImage } from '../shopsApi';
import { ShopImageUploadDialog } from './ShopImageUploadDialog';
import dynamicItemImageLight from './dynamic-item-light.png';
import dynamicItemImageDark from './dynamic-item-dark.png';

const useStyles = makeStyles((theme: Theme) => ({
  item: {
    height: '100%',
    position: 'relative',
    backgroundColor: 'rgba(0, 0, 0, 0.2)',
    '&:hover $resizeOverlay': {
      backgroundColor: theme.palette.type === 'dark' ? 'rgba(255, 255, 255, 0.2)' : 'rgba(0, 0, 0, 0.2)'
    }
  },
  itemImage: {
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center'
  },
  itemImageOverlay: {
    backgroundColor: theme.palette.type === 'dark' ? 'rgba(0, 0, 0, 0.6)' : 'rgba(255, 255, 255, 0.6)'
  },
  itemImageOverlayText: {
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden'
  },
  itemOverlay: {
    backgroundColor: theme.palette.type === 'dark' ? 'rgba(0, 0, 0, 0.6)' : 'rgba(255, 255, 255, 0.6)',
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    textAlign: 'center'
  },
  resizeOverlay: {
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    width: 10,
    backgroundColor: theme.palette.type === 'dark' ? 'rgba(255, 255, 255, 0)' : 'rgba(0, 0, 0, 0)',
    cursor: 'ew-resize',
    overflow: 'hidden',
    transition: 'background-color 0.5s'
  }
}));

export interface EditorItem {
  tabItem: ShopTabItem;
  itemDetail?: EditorItemDetail;
  isNew: boolean;
}

export interface EditorItemDetail {
  shopItem: ShopItem;
  catalogItem: ItemDefinition;
  currency: string;
  price: string;
  priceError: string;
}

interface ShopTabItemEditorProps {
  index: number;
  catalogName: string;
  shopTab: ShopTab;
  item: EditorItem;
  currencyList: CurrencyList;
  onTabItemChange: (tabItem: ShopTabItem) => void;
  onCurrencyChange: (currency: string) => void;
  onPriceChange: (price: string) => void;
  onRemove: () => void;
  onToggleHero: () => void;
  onToggleNonInteractive: () => void;
  moveItem: (from: number) => boolean;
  onEditItem: () => void;
  theme: Theme;
}

export const ShopTabItemEditor = withTheme((props: ShopTabItemEditorProps) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const dndHandleRef = useRef(null);
  const dndDropCooldown = useRef<boolean>(false);
  const resizeContainerRef = useRef<HTMLDivElement>(null);

  const { tabItem, itemDetail } = props.item;

  const menuAnchor = useRef(null);

  const [menuOpen, setMenuOpen] = useState(false);
  const [imageUploadDialogOpen, setImageUploadDialogOpen] = useState(false);
  const [isUploadingImage, setUploadingImage] = useState(false);

  const { currencyList, onTabItemChange } = props;

  const uploadImage = useCallback((file: File) => {
    if (!isUploadingImage && !props.item.isNew) {
      setUploadingImage(true);
      uploadShopTabItemImage(tabItem, file).then(updatedItem => {
        onTabItemChange(updatedItem);
        dispatch(setAppNotification({ type: 'success', message: 'Image uploaded' }));
      }).catch((e: ApiError) => {
        dispatch(setAppNotification({ type: 'error', message: `Error updating item image. ${e.message}` }));
      }).then(() => {
        setUploadingImage(false);
      });
    }
  }, [dispatch, onTabItemChange, isUploadingImage, props.item, tabItem]);

  const onImageFileDrop = useCallback(acceptedFiles => {
    if (acceptedFiles.length === 1) {
      uploadImage(acceptedFiles[0]);
    }
  }, [uploadImage]);
  const { getRootProps, isDragActive } = useDropzone({ onDrop: onImageFileDrop, noClick: true, accept: 'image/*', multiple: false, maxFiles: 1, noKeyboard: true });

  // DnD movement
  const [, dndDrop] = useDrop({
    accept: 'ShopTabItem',
    hover(item: { type: string, index: number }) {
      if (!dndHandleRef.current) {
        return;
      }
      if (item.index === props.index || dndDropCooldown.current) {
        return;
      }

      // Temporary fix for DnD position flicker. Prevent items for swapping positions faster than once per second.
      dndDropCooldown.current = true;
      window.setTimeout(() => dndDropCooldown.current = false, 1000);

      if (props.moveItem(item.index)) {
        item.index = props.index;
      }
    }
  });

  const [{ isDragging }, dndDrag] = useDrag({
    item: { type: 'ShopTabItem', index: props.index },
    canDrag: () => !tabItem.hero,
    collect: monitor => ({
      isDragging: monitor.isDragging()
    })
  });

  // DnD resize
  const [{ isResizing }, dndResizeDrag, dndResizeDragPreview] = useDrag({
    item: { type: 'ShopTabItemResize', index: props.index, initialWidth: tabItem.gridWidth, ref: resizeContainerRef },
    collect: monitor => ({ isResizing: monitor.isDragging() }),
    end(item) {
      if (item && item.ref.current) {
        item.ref.current.style.width = '';
      }
    }
  });

  useEffect(() => {
    dndResizeDragPreview(getEmptyImage(), { captureDraggingState: true });
  }, [dndResizeDragPreview]);

  dndDrag(dndDrop(dndHandleRef));

  const backgroundImage = useMemo(() => {
    if (tabItem.marketplaceItemDataTag) {
      return `url(${props.theme.palette.type === 'dark' ? dynamicItemImageDark : dynamicItemImageLight})`;
    }
    return tabItem.imageUrl ? `url(${tabItem.imageUrl})` : undefined;
  }, [props.theme.palette.type, tabItem.imageUrl, tabItem.marketplaceItemDataTag]);

  return (<>
    <Paper elevation={0} className={classes.item} {...getRootProps()}>
      <Paper ref={resizeContainerRef} style={{ opacity: isDragging ? 0 : 1 }}>
        <div ref={dndHandleRef} className={classes.itemImage} style={{ backgroundImage, height: 250 }}>
          <Box className={classes.itemImageOverlay} p={1}>
            <Grid container spacing={1}>
              <Grid item xs className={classes.itemImageOverlayText}>
                {itemDetail && (<>
                  <Link component={RouterLink} to={`/catalogs/${props.catalogName}/items/${itemDetail.shopItem.itemId}`}>
                    {itemDetail.shopItem.itemId}
                  </Link><br />
                  {itemDetail.catalogItem.displayName}
                </>)}
                {tabItem.marketplaceItemDataTag && (<>{tabItem.marketplaceItemDataTag}</>)}
              </Grid>
              {(itemDetail?.shopItem.startTimestamp !== null || itemDetail?.shopItem.endTimestamp !== null) && (
                <Grid item xs="auto">
                  <Tooltip title="Scheduled">
                    <ScheduleIcon color="primary" />
                  </Tooltip>
                </Grid>
              )}
              {tabItem.hero && (
                <Grid item xs="auto">
                  <Tooltip title="Hero">
                    <StarIcon color="primary" />
                  </Tooltip>
                </Grid>
              )}
              {tabItem.nonInteractive && (
                <Grid item xs="auto">
                  <Tooltip title="Non-interactive">
                    <BlockIcon color="primary" />
                  </Tooltip>
                </Grid>
              )}
            </Grid>
          </Box>
        </div>
        <Box p={1}>
          <Grid
            container
            spacing={1}
            alignItems="center"
            style={{
              justifyContent: 'right',
            }}
          >
            {itemDetail && (<>
              <Grid item xs="auto">
                <Select
                  value={itemDetail.currency}
                  onChange={event => props.onCurrencyChange(event.target.value as string)}
                >
                  {currencyList.getCurrencies().map(currency => (
                    <MenuItem key={currency.code} value={currency.code}>{currency.code}</MenuItem>
                  ))}
                  <MenuItem value={BuiltInCurrencyEnum.USDollar}>$</MenuItem>
                  <MenuItem value={BuiltInCurrencyEnum.Free}>FREE</MenuItem>
                </Select>
              </Grid>
              <Grid item xs>
                <TextField
                  // Force free items to show a price of 0. This will allow a switch back to another currency
                  // without losing their price.  Free price will be set to 0 on save.
                  value={itemDetail.currency === BuiltInCurrencyEnum.Free ? '0' : itemDetail.price}
                  onChange={event => props.onPriceChange(event.target.value as string)}
                  error={!!itemDetail.priceError}
                  InputProps={itemDetail.priceError ? {
                    endAdornment: (
                      <InputAdornment position="end">
                        <Tooltip title={itemDetail.priceError}>
                          <ErrorIcon fontSize="small" color="error" />
                        </Tooltip>
                      </InputAdornment>
                    )
                  } : undefined}
                  inputProps={{
                    autoComplete: 'off'
                  }}
                  fullWidth
                  // disable the price for free items
                  disabled={itemDetail.currency === BuiltInCurrencyEnum.Free}
                />
              </Grid>
            </>)}
            <Grid
              item
              xs="auto"
            >
              <IconButton ref={menuAnchor} onClick={() => setMenuOpen(true)}>
                <MoreVertIcon />
              </IconButton>
            </Grid>
          </Grid>
        </Box>

        {(isDragActive || isUploadingImage) && (
          <Box className={classes.itemOverlay}>
            <Grid container alignItems="center" style={{ height: '100%' }}>
              <Grid item xs={12}>
                {isUploadingImage ? (
                  <CircularProgress />
                ) : (
                  <>{props.item.isNew ? 'Cannot upload. Save the item first' : 'Upload image'}</>
                )}
              </Grid>
            </Grid>
          </Box>
        )}
        {!tabItem.hero && (
          <div className={classes.resizeOverlay} style={{ opacity: isResizing ? 0 : 1 }} ref={dndResizeDrag}>
          </div>
        )}
      </Paper>
    </Paper>

    {menuOpen && (
      <Menu onClose={() => setMenuOpen(false)} anchorEl={menuAnchor.current} open>
        <MenuItem onClick={() => { setMenuOpen(false); props.onEditItem(); }}>
          <ListItemIcon>
            <EditIcon />
          </ListItemIcon>
          Edit
        </MenuItem>
        {tabItem.hero ? (
          <MenuItem onClick={() => { setMenuOpen(false); props.onToggleHero(); }}>
            <ListItemIcon>
              <StarBorderIcon />
            </ListItemIcon>
            Remove hero
          </MenuItem>
        ) : (
          <MenuItem onClick={() => { setMenuOpen(false); props.onToggleHero(); }}>
            <ListItemIcon>
              <StarIcon />
            </ListItemIcon>
            Make hero
          </MenuItem>
        )}
        <MenuItem onClick={() => { setMenuOpen(false); setImageUploadDialogOpen(true); }} disabled={props.item.isNew}>
          <ListItemIcon>
            <PublishIcon />
          </ListItemIcon>
          Upload image
        </MenuItem>
        <MenuItem onClick={() => { setMenuOpen(false); props.onToggleNonInteractive(); }}>
          {tabItem.nonInteractive ? (<>
            <ListItemIcon>
              <BlockIcon />
            </ListItemIcon>
            Remove non-interactive
          </>) : (<>
            <ListItemIcon>
              <BlockIcon />
            </ListItemIcon>
            Set non-interactive
          </>)}
        </MenuItem>
        <Box my={1}>
          <Divider />
        </Box>
        <MenuItem onClick={() => props.onRemove()}>
          <ListItemIcon>
            <DeleteIcon />
          </ListItemIcon>
          Remove
        </MenuItem>
      </Menu>
    )}

    {imageUploadDialogOpen && (
      <ShopImageUploadDialog
        onClose={() => setImageUploadDialogOpen(false)}
        onUpload={uploadImage}
      />
    )}
  </>);
});

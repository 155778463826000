import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  CircularProgress,
  Grid,
  Tooltip,
  Typography,
  InputLabel
} from '@material-ui/core';
import DoneIcon from '@material-ui/icons/Done';
import type { FormikHelpers } from 'formik';
import { Field, Form, Formik } from 'formik';
import { CheckboxWithLabel, TextField } from 'formik-material-ui';
import * as React from 'react';
import type { ConnectedProps } from 'react-redux';
import { connect } from 'react-redux';
import ArtistsSelect from '../../components/Artists/ArtistsSelect';
import config from '../../config';
import { openConfirmationDialog } from '../../redux/app/actions';
import {
  createCatalogItemTokenAsync, freezeCatalogItemTokenAsync,
  getCatalogItemTokenAsync,
  updateCatalogItemTokenAsync,
  updateCatalogItemTokenPropertiesAsync
} from '../../redux/catalog/items/actions';
import type { ItemDefinition, ItemToken } from '../../services/item-definitions';
import { UserService } from '../../services/user';
import { createValidator, Validators } from '../../utils/forms';
import { ItemSelectField } from './ItemSelectField';
import ItemTokenImages from './ItemTokenImages';
import { getNullableDateFromEpochSeconds, getNullableEpochSecondsFromDate } from "../../utils/dates";
import {lightBlue} from "@material-ui/core/colors";
import {AcUnit} from "@material-ui/icons";
import { AdminKeyboardDateTimePicker, FormikAdminKeyboardDateTimePicker } from "../../components/AdminKeyboardDateTimePicker";
import { TooltipIcon } from "../../components/TooltipIcon";

const mapDispatch = {
  requestToken: getCatalogItemTokenAsync.request,
  requestCreateToken: createCatalogItemTokenAsync.request,
  requestUpdateToken: updateCatalogItemTokenAsync.request,
  requestUpdateTokenBbpProperties: updateCatalogItemTokenPropertiesAsync.request,
  requestFreezeItemToken: freezeCatalogItemTokenAsync.request,
  openConfirmationDialog
};
const connector = connect(null, mapDispatch);

interface FormValues {
  tokenName: string;
  category: string;
  sellable: boolean;
  transferable: boolean;
  burnable: boolean;
  issueTimeSpan: string;
  maxSupply: string;
  artistId: string | null;
  itemName: string;
  itemDescription: string;
  seasonName: string;
  collection: string;
  basePrice: string;
  startsAt: number | null;
  endsAt: number | null;
  submitted: SubmitActions;
  fuseFrom: string;
  searchable: boolean;
  withdrawable: boolean;
  recentDropStart?: Date;
}

enum SubmitActions {
  NOT_SET,
  BLOCKCHAIN_IT,
  UPDATE_METADATA,
  UPDATE_PROPERTIES,
  FREEZE
}

const formValidator = createValidator<FormValues>({
  tokenName: Validators.notBlank(),
  category: Validators.notBlank(),
  issueTimeSpan: Validators.blank(Validators.integer(0)),
  maxSupply: Validators.blank(Validators.integer(0)),
  basePrice: Validators.number(0),
  itemName: Validators.notBlank(),
  itemDescription: Validators.all([Validators.notBlank(), Validators.maxSize(255)])
}, values => {
  const issueTimeSpan = parseInt(values.issueTimeSpan);
  const maxSupply = parseInt(values.maxSupply);
  if ((isNaN(issueTimeSpan) || issueTimeSpan <= 0) && (isNaN(maxSupply) || maxSupply <= 0)) {
    const message = 'Either lifespan or max supply must be greater than 0';
    return {
      issueTimeSpan: message,
      maxSupply: message
    };
  }
  return {};
});

type Props = ConnectedProps<typeof connector> & {
  item: ItemDefinition;
  token?: ItemToken;
};

interface State {
  initialFormValues: FormValues;
  isFreezable: boolean;
  isFrozen: boolean;
  tokenState: string;
}

class ItemTokenForm extends React.Component<Props, State> {
  private pollTimeout?: number;

  constructor(props: Props) {
    super(props);
    this.state = {
      initialFormValues: this.tokenToFormValues(this.props.token),
      isFreezable: false,
      isFrozen: false,
      tokenState: ''
    };
  }

  componentDidMount() {
    this.props.requestToken(this.props.item);
    this.setupPollTimeout();
  }

  componentDidUpdate(prevProps: Props) {
    if (prevProps.token !== this.props.token) {
      const token = this.props.token;
      this.setState({initialFormValues: this.tokenToFormValues(this.props.token)});
      if (token) {
        this.setState({"isFrozen": (token.state === 'FROZEN' || token.state === 'PENDING_FREEZE')});
        this.setState({"isFreezable": (token.issueTimeSpan > 0 && token.maxSupply === 0)});
        this.setState({"tokenState": token.state})
      }
      this.setupPollTimeout();
    }
  }

  componentWillUnmount() {
    window.clearTimeout(this.pollTimeout);
  }

  private setupPollTimeout() {
    window.clearTimeout(this.pollTimeout);
    if (this.props.token && this.props.token.state === 'PENDING_CREATE') {
      this.pollTimeout = window.setTimeout(() => this.props.requestToken(this.props.item), 5000);
    }
  }

  private tokenToFormValues(token?: ItemToken): FormValues {
    if (token) {
      return {
        tokenName: token.tokenName,
        category: token.category,
        sellable: token.sellable,
        transferable: token.transferable,
        burnable: token.burnable,
        searchable: token.searchable,
        withdrawable: token.withdrawable,
        issueTimeSpan: token.issueTimeSpan.toString(),
        maxSupply: token.maxSupply.toString(),
        artistId: token.artistId,
        itemName: token.itemName || '',
        itemDescription: token.itemDescription || '',
        seasonName: token.seasonName || '',
        collection: token.collection || '',
        basePrice: token.basePrice.toFixed(2),
        startsAt: token.startsAt,
        endsAt: token.endsAt,
        submitted: SubmitActions.NOT_SET,
        fuseFrom: token.fuseFrom && token.fuseFrom.length > 0 ? token.fuseFrom[0] : '',
        recentDropStart: token.recentDropStart ? new Date(token.recentDropStart * 1000) : undefined
      };
    } else {
      return {
        tokenName: this.getSuggestedTokenName(),
        category: '',
        sellable: true,
        transferable: true,
        burnable: true,
        searchable: false,
        withdrawable: false,
        issueTimeSpan: '365',
        maxSupply: '0',
        artistId: null,
        itemName: '',
        itemDescription: '',
        seasonName: '',
        collection: '',
        basePrice: '',
        startsAt: null,
        endsAt: null,
        submitted: SubmitActions.NOT_SET,
        fuseFrom: '',
        recentDropStart: undefined
      };
    }
  }

  private getSuggestedTokenName() {
    const env = config.env;
    return `${(env === 'prod' ? '' : `${env}.`) + this.props.item.catalogName}.${this.props.item.itemId}`;
  }

  onSubmit = (values: FormValues, helpers: FormikHelpers<FormValues>) => {
    const token: ItemToken = {
      tokenName: values.tokenName,
      category: values.category,
      sellable: values.sellable,
      transferable: values.transferable,
      burnable: values.burnable,
      maxSupply: parseInt(values.maxSupply) || 0,
      issueTimeSpan: parseInt(values.issueTimeSpan) || 0,
      currentSupply: 0,
      issuedSupply: 0,
      artistId: values.artistId,
      state: 'PENDING_CREATE',
      itemName: values.itemName.trim() || null,
      itemDescription: values.itemDescription.trim() || null,
      seasonName: values.seasonName.trim() || null,
      collection: values.collection.trim() || null,
      basePrice: parseFloat(values.basePrice) || 0,
      startsAt: values.startsAt,
      endsAt: values.endsAt,
      fuseFrom: values.fuseFrom ? [values.fuseFrom] : undefined,
      searchable: values.searchable,
      withdrawable: values.withdrawable,
      recentDropStart: values.recentDropStart ? Math.floor(values.recentDropStart.getTime() / 1000) : null
    };

    if (this.props.token && values.submitted == SubmitActions.UPDATE_METADATA) {
      this.props.openConfirmationDialog({title: 'Update token metadata?', action: 'Update metadata'}, () => {
        this.props.requestUpdateToken({item: this.props.item, token});
      }, () => {
        helpers.setFieldValue('submitted', SubmitActions.NOT_SET);
        helpers.setSubmitting(false);
      });
    } else if (this.props.token && values.submitted == SubmitActions.UPDATE_PROPERTIES) {
      this.props.openConfirmationDialog({title: 'Update properties?', action: 'Update properties', details: 'Are you sure'}, () => {
        this.props.requestUpdateTokenBbpProperties({item: this.props.item, token});
      }, () => {
        helpers.setFieldValue('submitted', SubmitActions.NOT_SET);
        helpers.setSubmitting(false);
      });
    } else if (this.props.token && values.submitted == SubmitActions.FREEZE) {
      this.props.openConfirmationDialog({title: 'Freeze Token?', action: 'Freeze'}, () => {
        this.props.requestFreezeItemToken(this.props.item);
        this.setState({ "isFrozen": true});
        this.setState({ "isFreezable": false});
        this.setState({"tokenState": "PENDING_FREEZE"});
        helpers.setFieldValue('submitted', SubmitActions.NOT_SET);
        helpers.setSubmitting(false);
      }, () => {
        helpers.setFieldValue('submitted', SubmitActions.NOT_SET);
        helpers.setSubmitting(false);
      });
    } else {
      const details = `Create token ${token.tokenName} for ${this.props.item.itemId}?`;
      this.props.openConfirmationDialog({ title: 'Create token?', details, action: 'Blockchain it!' }, () => {
        this.props.requestCreateToken({ item: this.props.item, token });
        this.setState({"tokenState": "PENDING_CREATE"})
      }, () => {
        helpers.setFieldValue('submitted', SubmitActions.NOT_SET);
        helpers.setSubmitting(false);
      });
    }
  }

  tokenState = () => {
    const state = this.state.tokenState;
    if (state === 'FROZEN' || state === 'PENDING_FREEZE') {
      return (
        <Tooltip title={'Frozen'}>
          <AcUnit style={{ color: lightBlue[500] }} fontSize="large" />
        </Tooltip>
      );
    } else if (state != 'PENDING_CREATE') {
      return (
        <Tooltip title={'Finalized'}>
          <DoneIcon fontSize="large" htmlColor="#00b116" />
        </Tooltip>
      );
    } else {
      return (
        <Tooltip title={'Still finalizing...'}>
          <CircularProgress size={30} />
        </Tooltip>
      );
    }
  }

  hasMarketplaceImages = (item: ItemDefinition) => {
    if (!item.customData)
      return false;

    const marketplace = item.customData.Marketplace;
    if(item.itemClass?.toLowerCase() === 'blanko') {
      return marketplace
        && marketplace.Front
        && marketplace.FrontBoxed
        && marketplace.Left
        && marketplace.LeftBoxed
        && marketplace.Right
        && marketplace.RightBoxed;
    } else {
      return marketplace
        && marketplace.Front
        && marketplace.Left
        && marketplace.Right;
    }
  };

  render() {
    const readOnly = !!this.props.token || !UserService.canCreate('catalogItems');
    const metadataReadOnly = (this.props.token && !UserService.canUpdate('catalogItems')) || (!this.props.token && !UserService.canCreate('catalogItems'));
    const propertiesReadOnly = (this.props.token && !UserService.canUpdate('catalogItems')) || (!this.props.token && !UserService.canCreate('catalogItems'));
    const hasMarketplaceImages = this.hasMarketplaceImages(this.props.item);
    const token = this.props.token;
    const state = this.state.tokenState;

    let tokenStatus: React.ReactNode = null;

    if (token) {
      tokenStatus = (
        <Box>
          {this.tokenState}
        </Box>
      );
    }

    return (
      <Formik<FormValues>
        initialValues={this.state.initialFormValues}
        enableReinitialize
        validate={formValidator}
        onSubmit={this.onSubmit}
      >
        {form => (
          <Form>
            <Grid container spacing={3}>
              <Grid item xs={12} md={6} xl={4}>
                <Card>
                  <CardHeader
                    title="Token"
                    action={tokenStatus}
                  />
                  <CardContent>
                    <Grid container spacing={1}>
                      <Grid item xs={12}>
                        <Field
                          component={TextField}
                          name="tokenName"
                          type="text"
                          label="Token name"
                          fullWidth
                          required
                          disabled={form.isSubmitting || this.state.isFrozen}
                          InputProps={{ readOnly }}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <Field
                          component={TextField}
                          name="category"
                          type="text"
                          label="Category"
                          fullWidth
                          required
                          disabled={form.isSubmitting || this.state.isFrozen}
                          InputProps={{ readOnly }}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <Field
                          component={CheckboxWithLabel}
                          type="checkbox"
                          name="sellable"
                          Label={{ label: 'Sellable' }}
                          disabled={form.isSubmitting || readOnly || this.state.isFrozen}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <Field
                          component={CheckboxWithLabel}
                          type="checkbox"
                          name="transferable"
                          Label={{ label: 'Transferable' }}
                          disabled={form.isSubmitting || readOnly || this.state.isFrozen}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <Field
                          component={CheckboxWithLabel}
                          type="checkbox"
                          name="burnable"
                          Label={{ label: 'Burnable' }}
                          disabled={form.isSubmitting || readOnly || this.state.isFrozen}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <Field
                          component={TextField}
                          name="issueTimeSpan"
                          type="text"
                          label="Lifespan (in days)"
                          fullWidth
                          helperText="Blank or 0 for no time limit"
                          disabled={form.isSubmitting || this.state.isFrozen}
                          InputProps={{ readOnly }}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <Field
                          component={TextField}
                          name="maxSupply"
                          type="text"
                          label="Max supply"
                          fullWidth
                          helperText="Blank or 0 for infinite supply"
                          disabled={form.isSubmitting || this.state.isFrozen}
                          InputProps={{
                            readOnly
                          }}
                        />
                      </Grid>
                    </Grid>
                    {(this.state.isFreezable && !this.state.isFrozen)&& this.props.token && (
                      <Box py={2}>
                        <Button
                          variant="contained"
                          color="primary"
                          disabled={form.isSubmitting}
                          onClick={(e) => {
                            form.setFieldValue('submitted', SubmitActions.FREEZE);
                            return form.submitForm();
                          }}
                        >
                          {form.isSubmitting ? (
                            <CircularProgress size={25} />
                          ) : 'Freeze'}
                        </Button>
                      </Box>
                    )}
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={12} md={6} xl={4}>
                <Card>
                  <CardHeader
                    title="Metadata"
                  />
                  <CardContent>
                    <Grid container spacing={1}>
                      <Grid item xs={12}>
                        <Field
                          component={TextField}
                          name="basePrice"
                          type="text"
                          label="Base price (USD)"
                          fullWidth
                          required
                          InputProps={{ readOnly: metadataReadOnly }}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <Field
                          component={TextField}
                          name="itemName"
                          type="text"
                          label="Item name"
                          fullWidth
                          required
                          InputProps={{ readOnly: metadataReadOnly }}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <Field
                          component={TextField}
                          name="itemDescription"
                          type="text"
                          label="Item description"
                          multiline
                          rows={4}
                          fullWidth
                          required
                          InputProps={{ readOnly: metadataReadOnly }}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <Field
                          component={TextField}
                          name="seasonName"
                          type="text"
                          label="Season name"
                          fullWidth
                          InputProps={{ readOnly: metadataReadOnly }}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <Field
                          component={TextField}
                          name="collection"
                          type="text"
                          label="Collection"
                          fullWidth
                          InputProps={{ readOnly: metadataReadOnly }}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <ItemSelectField
                          label="Fuses from"
                          fieldName="fuseFrom"
                          setFieldValue={form.setFieldValue}
                          readOnly={metadataReadOnly}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <Field
                          component={FormikAdminKeyboardDateTimePicker}
                          name="recentDropStart"
                          label="Recent Drop Start"
                          fullWidth
                          format="yyyy/MM/dd HH:mm:ss"
                          ampm={false}
                          readOnly={metadataReadOnly}
                        />
                      </Grid>
                    </Grid>
                    <ArtistsSelect
                      value={form.values.artistId}
                      readOnly={metadataReadOnly}
                      onChange={value => form.setFieldValue('artistId', value)}
                    />
                    <Box mt={4}>
                      <Typography variant="h6">
                        Item images
                      </Typography>
                      <ItemTokenImages item={this.props.item} />
                    </Box>

                    {this.props.token && !metadataReadOnly && (
                      <Box py={2}>
                        <Button
                          variant="contained"
                          color="primary"
                          disabled={form.isSubmitting}
                          onClick={(e) => {
                            form.setFieldValue('submitted', SubmitActions.UPDATE_METADATA);
                            return form.submitForm();
                          }}
                        >
                          {form.isSubmitting ? (
                            <CircularProgress size={25} />
                          ) : 'Update metadata'}
                        </Button>
                      </Box>
                    )}
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={12} md={6} xl={4}>
                <Card>
                  <CardHeader
                    title="Properties"
                  />
                  <CardContent>
                    <Box>
                      <Grid container spacing={1}>
                        <Grid item xs={12}>
                          <AdminKeyboardDateTimePicker
                            label="Minting Starts At (time shown as local time)"
                            fullWidth
                            clearable
                            InputLabelProps={{ shrink: true }}
                            onChange={(date) => {
                              form.setFieldValue('startsAt', getNullableEpochSecondsFromDate(date as Date));
                            }}
                            value={getNullableDateFromEpochSeconds(form.values.startsAt)}
                            disabled={form.isSubmitting || propertiesReadOnly || this.state.isFrozen}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <AdminKeyboardDateTimePicker
                            label="Minting Ends At (time shown as local time)"
                            fullWidth
                            clearable
                            InputLabelProps={{ shrink: true }}
                            onChange={(date) => {
                              form.setFieldValue('endsAt', getNullableEpochSecondsFromDate(date));
                            }}
                            value={getNullableDateFromEpochSeconds(form.values.endsAt)}
                            disabled={form.isSubmitting || propertiesReadOnly || this.state.isFrozen}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <Box mt={2}>
                            <InputLabel>Leave Start At or Ends At empty for no constraints.</InputLabel>
                          </Box>
                        </Grid>
                        <Grid item xs={12}>
                          <Field
                            component={CheckboxWithLabel}
                            type="checkbox"
                            name="searchable"
                            Label={{ label: 'Searchable' }}
                            disabled={form.isSubmitting || propertiesReadOnly || this.state.isFrozen}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <Field
                            component={CheckboxWithLabel}
                            type="checkbox"
                            name="withdrawable"
                            Label={{ label: 'Withdrawable' }}
                            disabled={form.isSubmitting || propertiesReadOnly || this.state.isFrozen}
                          />
                        </Grid>
                      </Grid>
                    </Box>

                    {!this.state.isFrozen && this.props.token && !propertiesReadOnly && (
                      <Box py={2}>
                        <Button
                          variant="contained"
                          color="primary"
                          disabled={form.isSubmitting}
                          onClick={(e) => {
                            form.setFieldValue('submitted', SubmitActions.UPDATE_PROPERTIES);
                            return form.submitForm();
                          }}
                        >
                          {form.isSubmitting ? (
                            <CircularProgress size={25} />
                          ) : 'Update properties'}
                        </Button>
                      </Box>
                    )}
                  </CardContent>
                </Card>
              </Grid>
            </Grid>

            {!readOnly && (
              <Box py={2}>
                <Button
                  variant="contained"
                  color="primary"
                  disabled={form.isSubmitting || !hasMarketplaceImages}
                  onClick={(e) => {
                    form.setFieldValue('submitted', SubmitActions.BLOCKCHAIN_IT);
                    return form.submitForm();
                  }}
                >
                  {form.isSubmitting ? (
                    <CircularProgress size={25} />
                  ) : 'Blockchain it!'}
                </Button>
                {!hasMarketplaceImages && (
                  <TooltipIcon icon={'warn'} title='Cannot tokenize without marketplace images' />
                )}
              </Box>
            )}
          </Form>
        )}
      </Formik>
    );
  }
}

export default connector(ItemTokenForm);

import type { MigrationDiffCommonProps } from '../../migrations';
import { DiffsPanels } from '../../components/diff/DiffsPanels';

export const GemRushDiffs = (props: MigrationDiffCommonProps) => {
  const diffs = props.migrationData.gemRush;

  if (diffs) {
    return (
      <DiffsPanels
        diffs={diffs}
        columns={[
          {
            title: 'Rush Name', field: 'name', defaultSort: 'asc', render(item) {
              return (<>
                {item.name}
              </>);
            }
          },
        ]}
        selectionMap={props.selections.gemRush || {}}
        onSelectionChange={map => props.onSelectionsChange({ ...props.selections, gemRush: map })}
        {...props}
      />
    )
  }

  return null;
}

import { Box, Button, FormControlLabel, Grid, Link, Switch, Tooltip } from '@material-ui/core';
import { Lock, LockOpen } from "@material-ui/icons";
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import GetAppIcon from '@material-ui/icons/GetApp';
import PublishIcon from '@material-ui/icons/Publish';
import StarIcon from '@material-ui/icons/Star';
import StarBorderIcon from '@material-ui/icons/StarBorder';
import SwapHorizIcon from '@material-ui/icons/SwapHoriz';
import type { Column } from 'material-table';
import { Link as RouterLink } from 'react-router-dom';
import type { MenuAction } from '../../components/MaterialTable';
import MaterialTable from '../../components/MaterialTable';
import config from '../../config';
import type { ApiError } from '../../services/api';
import type { Block} from '../../services/blocks';
import { BlocksService } from '../../services/blocks';
import type { Player } from '../players/players';
import { UserService } from '../../services/user';
import { ImportBlockDialog } from './components/ImportBlockDialog';
import type { UpdateBlockType } from './components/UpdateBlockDialog';
import { UpdateBlockDialog } from './components/UpdateBlockDialog';
import { useState, useEffect, useCallback, useRef } from 'react';
import { openAdminConfirmationDialog } from '../../shared/hooks/useAdminConfirmationDialog';
import { pushAppNotification } from "../../shared/hooks/useAppNotification";
import {MaterialTableCallback} from "../../utils/MaterialTableCallback";

interface OwnProps {
  player?: Player;
}

export const Blocks = (props: OwnProps) => {

  const [updateBlockType, setUpdateBlockType] = useState<UpdateBlockType>('Rename');
  const [updateBlock, setUpdateBlock] = useState<Block>();
  const [importDialog, setImportDialog] = useState(false);
  const [showMythicalOnly, setShowMythicalOnly] = useState(false);
  const [latestBlockUpdated, setLatestBlockUpdated] = useState<Block>();
  const tableRef = useRef<MaterialTable<Block>>();
  const [filters, setFilters] = useState<string[]>([]);

  useEffect(() => {
    const array = [];
    if (showMythicalOnly){
      array.push("mythical");
    }
    setFilters(array);
  }, [latestBlockUpdated, showMythicalOnly]);

  const columns: Column<Block>[] = [
    {
      title: 'ID',
      field: 'id',
      hidden: config.env === 'prod'
    },
    {
      title: 'Name',
      field: 'name',
      defaultSort: 'asc',
      render: block => (
        <Grid container spacing={1} alignItems="center">
          {block.mythical && (
            <Grid item xs="auto">
              <Tooltip title="Mythical">
                <StarIcon color="secondary" />
              </Tooltip>
            </Grid>
          )}
          {block.locked && (
            <Grid item xs="auto">
              <Tooltip title="Locked">
                <Lock color="secondary" />
              </Tooltip>
            </Grid>
          )}
          <Grid item xs>
            <Link component={RouterLink} to={`/blocks/${block.id}`}>
              {block.name}
            </Link>
          </Grid>
        </Grid>
      )
    },
    { title: 'Game mode', field: 'primaryGameMode' },
    { title: 'Mode type', field: 'playType' },
    {
      title: 'Creator',
      field: 'creatorName',
      sorting: false,
      render: block => !props.player ? (
        (UserService.canRead('player') ?
          <Link component={RouterLink} to={`/players/${block.creatorId}`}>
            {block.creatorName || block.creatorId}
          </Link>
          : <>{block.creatorName || block.creatorId}</>)
      ): <></>
    },
    {
      title: 'Owner',
      field: 'ownerName',
      sorting: false,
      render: block => !props.player ? (
        (UserService.canRead('player') ?
          <Link component={RouterLink} to={`/players/${block.ownerId}`}>
            {block.ownerName || block.ownerId}
          </Link>
          : <>{block.ownerName || block.ownerId}</>)
      ): <></>
    },
    {
      title: 'Last Updated',
      field: 'updatedTimeStamp',
      sorting: false,
      render: block => <>{block.updatedTimeStamp.toLocaleString()}</>
    }
  ];

  const getTableMenuActions = (block: Block) => {
    const actions: MenuAction<Block>[] = [];
    if (UserService.canUpdate('blocks')) {
      actions.push({
        type: 'button', icon: block.mythical ? StarBorderIcon : StarIcon, label: block.mythical ? 'Remove Mythical' : 'Set Mythical', onClick: toggleBlockMythical
      });
    }
    if (UserService.canCreate('publishedBlocks')) {
      actions.push({
        type: 'button', icon: PublishIcon, label: 'Publish', onClick: publishBlock
      });
    }
    if (UserService.canUpdate('blocks')) {
      actions.push({
        type: 'button', icon: EditIcon, label: 'Rename', onClick: block => {
          setUpdateBlock(block);
          setUpdateBlockType('Rename');
        }
      });
    }
    if (UserService.canCreate('blocks')) {
      actions.push({
        type: 'button', icon: FileCopyIcon, label: 'Copy', onClick: block => {
          setUpdateBlock(block);
          setUpdateBlockType('Copy');
        }
      });
    }
    if (UserService.canUpdate('blocks')) {
      actions.push({
        type: 'button', icon: SwapHorizIcon, label: 'Transfer', onClick: block => {
          setUpdateBlock(block);
          setUpdateBlockType('Transfer');
        }
      });
    }
    actions.push({
      type: 'button', icon: GetAppIcon, label: 'Download', onClick: downloadBlock
    });
    if (UserService.canUpdate('blocks')) {
      actions.push({
        type: 'button', icon: block.locked ? LockOpen : Lock, label: block.locked ? 'Unlock' : 'Lock', onClick: toggleBlockLocked
      });
    }
    if (UserService.canDelete('blocks')) {
      if (actions.length > 0) {
        actions.push({ type: 'divider' });
      }
      actions.push({
        type: 'button', icon: DeleteIcon, label: 'Delete', onClick: deleteBlock
      });
    }
    return actions;
  };

  const toggleBlockMythical = useCallback((block: Block) => {
    openAdminConfirmationDialog({
      title: block.mythical ? `Remove Mythical from ${block.name}?` : `Set ${block.name} as Mythical?`,
      action: block.mythical ? 'Remove Mythical' : 'Set Mythical',
      details: block.mythical ? '' : 'Mythical blocks are automatically featured when published',
      onConfirm: () => {
        block.mythical = !block.mythical;
        BlocksService.updateBlock(block);
        pushAppNotification({ type: 'success', message: 'Block Updated' });
        setLatestBlockUpdated(block);
      }
    });
  }, []);

  const toggleBlockLocked = useCallback((block: Block) => {
    openAdminConfirmationDialog({
      title: block.locked ? `Unlock ${block.name}?` : `Lock ${block.name}?`,
      action: block.locked ? 'Unlock' : 'Lock',
      details: block.locked ? '' : 'Locked blocks can\'t be published or cloned',
      onConfirm: () => {
        block.locked = !block.locked;
        BlocksService.updateBlock(block);
        pushAppNotification({ type: 'success', message: 'Block Updated' });
        setLatestBlockUpdated(block);
      }
    });
  }, []);

  const publishBlock = useCallback((block: Block) => {
    openAdminConfirmationDialog({
      title: `Publish ${block.name}?`,
      action: 'Publish',
      onConfirm: () => {
        BlocksService.createPublishedBlock(block);
        setLatestBlockUpdated(block);
      }
    });
  }, []);

  const deleteBlock = useCallback((block: Block) => {
    openAdminConfirmationDialog({
      title: `Delete ${block.name}?`,
      action: 'Delete',
      onConfirm: () => {
        BlocksService.deleteBlock(block.id);
        setLatestBlockUpdated(block);
      }
    });
  }, []);

  const downloadBlock = useCallback((block: Block) => {
    BlocksService.downloadBlock(block.id).catch((err: ApiError) => {
      console.error(err);
      pushAppNotification({ type: 'error', message: err.message });
    });
  }, []);

  return (<>
    <Box mb={2}>
      <Grid container alignItems="center">
        <Grid item xs>
          <FormControlLabel
            control={
              <Switch
                checked={showMythicalOnly}
                onChange={event => setShowMythicalOnly(event.target.checked)}
                color="primary"
              />
            }
            label="Mythical only"
          />
        </Grid>
        {UserService.canCreate('blocks') && (
          <Grid item>
            <Button variant="contained" color="primary" onClick={() => setImportDialog(true)}>
              Import Block
            </Button>
          </Grid>
        )}
      </Grid>
    </Box>

    <MaterialTableCallback
      tableRef={tableRef}
      title={showMythicalOnly ? 'We are Mythical' : 'Blocks'}
      storageId="blocks"
      columns={columns}
      data={BlocksService.getBlocks}
      options={{
        columnsButton: true,
        debounceInterval: 500,
        pageSize: 20,
        pageSizeOptions: [20, 50, 100],
        thirdSortClick: false
      }}
      menuActions={getTableMenuActions}
      player={props.player}
      filters={filters}
    />

    {updateBlock && (
      <UpdateBlockDialog
        updateType={updateBlockType}
        player={props.player}
        block={updateBlock}
        onClose={() => setUpdateBlock(undefined)}
      />
    )}

    {importDialog && (
      <ImportBlockDialog player={props.player} onClose={() => setImportDialog(false)} />
    )}
  </>);
};

import {createAsyncAction} from 'typesafe-actions';
import type {ChallengeSchedule} from '../../../services/player-challenges/season-challenge-schedule';

export const createSeasonChallengeScheduleAsync = createAsyncAction(
  'CREATE_SEASON_CHALLENGE_SCHEDULE_REQUEST',
  'CREATE_SEASON_CHALLENGE_SCHEDULE_SUCCESS',
  'CREATE_SEASON_CHALLENGE_SCHEDULE_FAILURE'
)<{ seasonNumber: number, challengeScheduleJson: any }, ChallengeSchedule, string>();

export const getSeasonChallengeScheduleAsync = createAsyncAction(
  'GET_SEASON_CHALLENGE_SCHEDULE_REQUEST',
  'GET_SEASON_CHALLENGE_SCHEDULE_SUCCESS',
  'GET_SEASON_CHALLENGE_SCHEDULE_FAILURE'
)<number, ChallengeSchedule, string>();

export const getCurrentSeasonChallengeScheduleAsync = createAsyncAction(
  'GET_CURRENT_SEASON_CHALLENGE_SCHEDULE_REQUEST',
  'GET_CURRENT_SEASON_CHALLENGE_SCHEDULE_SUCCESS',
  'GET_CURRENT_SEASON_CHALLENGE_SCHEDULE_FAILURE'
)<void, ChallengeSchedule, string>();

export const updateSeasonChallengeScheduleAsync = createAsyncAction(
  'UPDATE_SEASON_CHALLENGE_SCHEDULE_REQUEST',
  'UPDATE_SEASON_CHALLENGE_SCHEDULE_SUCCESS',
  'UPDATE_SEASON_CHALLENGE_SCHEDULE_FAILURE'
)<{ seasonNumber: number, schedule: ChallengeSchedule }, ChallengeSchedule, string>();

export const deleteSeasonChallengeScheduleAsync = createAsyncAction(
  'DELETE_SEASON_CHALLENGE_SCHEDULE_REQUEST',
  'DELETE_SEASON_CHALLENGE_SCHEDULE_SUCCESS',
  'DELETE_SEASON_CHALLENGE_SCHEDULE_FAILURE'
)<number, number, string>();

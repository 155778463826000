export class NakamaUser {
  id = '';
  username = '';
  displayName = '';
  languageTag = '';
  metadata: any = {};
  userCreateTime = new Date();
  userUpdateTime = new Date();
  online = false;
  avatarUrl = '';
  location = '';
  timezone = '';


  constructor(json?: any) {
    if (json) {
      this.id = json.id;
      this.username = json.username;
      this.displayName = json.displayName;
      this.languageTag = json.languageTag;
      this.metadata = JSON.parse(json.metadata);
      this.userCreateTime = new Date(json.createTime);
      this.userUpdateTime = new Date(json.updateTime);
      this.online = json.online;
      this.avatarUrl = json.avatarUrl;
      this.location = json.location;
      this.timezone = json.timezone;
    }
  }
}


export interface Other {
  [key: string]: number
}

export enum CapstoneEnum {
  BurnCurrency = "BurnCurrency",
  BurnGumballs = "BurnGumballs"
}
export interface BurnGumballsParams {
  amount: number
}
export interface BurnCurrencyParams {
  BB: number;
  GV: number;
}


export interface CapstoneBurnCurrency {
  type: CapstoneEnum.BurnCurrency;
  param: BurnCurrencyParams;
}

export interface CapstoneBurnGumballs {
  type: CapstoneEnum.BurnGumballs
  param: BurnGumballsParams
}

export type BlankoProgressionTierCapstone = CapstoneBurnCurrency | CapstoneBurnGumballs 
export interface BlankoGemRushFuse {
  id: string;
  blankoGemRushId?: string;
  baseDnaId: string;
  upgradeDnaId: string;
  maxQuantity: number;
  remainingQuantity: number;
  capstone: BlankoProgressionTierCapstone[];
  fuseTitle?: string;
  fuseDescription?: string;
  popupImageUrl?: string;
}

export interface BlankoGemRush {
  id: string;
  name: string;
  startDate: string;
  endDate: string;
  active: boolean;
}

export interface BlankoGemRushAndFuse extends BlankoGemRush {
  fuses: BlankoGemRushFuse[]
}

import { Box, Button, CircularProgress, Grid, Link, Typography } from "@material-ui/core"
import type { Column } from "material-table";
import { useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import type { MenuAction } from "../../../components/MaterialTable";
import MaterialTable from "../../../components/MaterialTable";
import { setAppNotification } from "../../../redux/app/actions";
import type { ApiError } from "../../../services/api";
import { UserService } from "../../../services/user";
import type { ShopTemplate } from "../shopTemplate";
import { createShopTemplate, deleteShopTemplate, getShopTemplates, updateShopTemplate } from "../shopTemplateApi";
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import { ShopTemplateDialog } from "../components/ShopTemplateDialog";
import { RouterLink } from "../../../types";

const shopTemplateColumns: Column<ShopTemplate>[] = [
  {
    field: 'name',
    title: 'Name',
    defaultSort: 'asc',
  }
];

export const ShopTemplates = () => {
  const dispatch = useDispatch();
  const [templates, setTemplates] = useState<ShopTemplate[] | null>(null);
  const [editTemplate, setEditTemplate] = useState<ShopTemplate | undefined>();
  const [editDialogOpen, setEditDialogOpen] = useState(false);

  useEffect(() => {
    getShopTemplates().then(setTemplates)
      .catch((e: ApiError) => dispatch(setAppNotification({ type: 'error', message: `Failed to load shop templates. ${e.message}` })));
  }, [dispatch]);

  const upsertTemplate = useCallback((template: ShopTemplate) => {
    const serverEndpoint = template.id ? updateShopTemplate : createShopTemplate;
    let existing = templates ? [...templates] : [];
    return serverEndpoint(template).then(saved => {
      if (templates) {
        existing = existing.filter(v => v.id != saved.id);
        existing.push(saved);
        setTemplates(existing);
      } else {
        setTemplates([saved]);
      }
      dispatch(setAppNotification({ type: 'success', message: `Successfully modified a shop template. ` }));
    }).catch((e: ApiError) => dispatch(setAppNotification({ type: 'error', message: `Failed to load shop templates. ${e.message}` })));
  }, [dispatch, templates, setTemplates]);

  const onEdit = useCallback((template: ShopTemplate) => {
    setEditTemplate(template);
    setEditDialogOpen(true);
  }, [setEditTemplate, setEditDialogOpen]);

  const onDeleteClicked = useCallback((template: ShopTemplate) => {
    deleteShopTemplate(template.id).then(() => {
      if (templates) {
        const existing = [...templates].filter(v => v.id != template.id);
        setTemplates(existing);
      }
    }).catch((e: ApiError) => dispatch(setAppNotification({ type: 'error', message: `Failed to load delete template. ${e.message}` })));

  }, [dispatch, templates]);

  const onAddClicked = useCallback(() => {
    setEditTemplate(undefined);
    setEditDialogOpen(true);
  }, [setEditDialogOpen]);

  const getMenuActions = useCallback(() => {
    const actions: MenuAction<ShopTemplate>[] = [];
    if (UserService.canUpdate('store')) {
      actions.push({ type: 'button', icon: EditIcon, label: 'Edit', onClick: onEdit });
    }
    if (UserService.canDelete('store')) {
      actions.push({ type: 'button', icon: DeleteIcon, label: 'Delete', onClick: onDeleteClicked });
    }
    return actions;
  }, [onEdit, onDeleteClicked]);

  return (<>
    <Box mb={2}>
      <Grid container alignItems="center" spacing={2}>
        <Grid item xs>
          <Typography variant="h6">
            Shop Templates
          </Typography>
        </Grid>
        <Grid item>
          <Button variant="contained" color="primary" onClick={onAddClicked}>
            Add Template
          </Button>
        </Grid>
      </Grid>
    </Box>
    {templates === null ? (
      <Box textAlign="center">
        <CircularProgress />
      </Box>
    ) : (
      <>
        <MaterialTable<ShopTemplate>
          title=""
          data={templates}
          columns={shopTemplateColumns}
          menuActions={getMenuActions}
        />
        <Box mt={2}>
          <Grid item xs="auto">
            <Link component={RouterLink} to={`/shops`}>
              <Button color="primary" variant="contained">
                Back to shops
              </Button>
            </Link>
          </Grid>
        </Box>
        {editDialogOpen && (
          <ShopTemplateDialog
            onClose={() => setEditDialogOpen(false)}
            template={editTemplate}
            updateTemplate={(template) => upsertTemplate(template)}
          ></ShopTemplateDialog>
        )}
      </>
    )}
  </>
  )
}
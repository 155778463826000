import { call, put, takeLatest } from 'redux-saga/effects';
import type { Season} from '../../../services/player-challenges/seasons';
import { SeasonsService } from '../../../services/player-challenges/seasons';
import { setAppNotification } from '../../app/actions';
import { forkSagas, sagaHandleApiError } from '../../utils-ts';
import {
  deleteSeasonAsync,
  getSeasonAsync,
  getSeasonsAsync,
  updateSeasonAsync,
  createSeasonAsync,
} from './actions';
import {
  ChallengeSchedule,
  ChallengeSchedulesService
} from "../../../services/player-challenges/season-challenge-schedule";
import {generateUUID} from "../../../utils/uuid";
import {RewardPath, SeasonRewardPathService} from "../../../services/player-challenges/season-reward-paths";

function* fetchSeasons(action: ReturnType<typeof getSeasonsAsync.request>) {
  try {
    const seasons: Season[] = yield call(SeasonsService.getSeasons, action.payload);
    yield put(getSeasonsAsync.success(seasons));
  } catch (e) {
    yield sagaHandleApiError(e, getSeasonsAsync.failure);
  }
}

function* fetchSeason(action: ReturnType<typeof getSeasonAsync.request>) {
  try {
    const season: Season = yield call(SeasonsService.getSeason, action.payload.liveEventsId, action.payload.seasonNumber);
    yield put(getSeasonAsync.success(season));
  } catch (e) {
    yield sagaHandleApiError(e, getSeasonAsync.failure);
  }
}

function* createSeasonSaga(action: ReturnType<typeof createSeasonAsync.request>) {
  try {
    const season: Season = yield call(SeasonsService.createSeason, action.payload.liveEventsId, action.payload.season.toJson());

    if (season.season_number !== undefined) {
      //Creating Reward Path with generated values, we currently are only supporting 1 path and removal and recreation is treated as a new season.
      const rewardPath = new RewardPath();
      rewardPath.path_id = `${season.season_name.replaceAll(' ', '_').toUpperCase()}_PATH`;
      rewardPath.path_name = `${season.season_name  } Path`;
      yield call(SeasonRewardPathService.createSeasonRewardPath, season.season_number, rewardPath.toJson());

      //Creating Challenge Schedule with generated values, we currently are only supporting 1 schedule and UI has no place for this.
      const challengeSchedule = new ChallengeSchedule();
      challengeSchedule.schedule_id = generateUUID();
      challengeSchedule.schedule_name = `${season.season_name  } Schedule`;
      yield call (ChallengeSchedulesService.createSeasonChallengeSchedule, season.season_number, challengeSchedule.toJson());

    }

    yield put(createSeasonAsync.success(season));
    yield put(setAppNotification({ type: 'success', message: 'Season created' }));

  } catch (e) {
    yield sagaHandleApiError(e, createSeasonAsync.failure);
  }
}

function* updateSeasonSaga(action: ReturnType<typeof updateSeasonAsync.request>) {
  try {
    const season: Season = yield call(SeasonsService.updateSeason, action.payload.liveEventsId, action.payload.seasonNumber, action.payload.season);
    yield put(updateSeasonAsync.success(season));
    yield put(setAppNotification({ type: 'success', message: 'Season updated' }));
  } catch (e) {
    yield sagaHandleApiError(e, updateSeasonAsync.failure);
  }
}

function* deleteSeasonSaga(action: ReturnType<typeof deleteSeasonAsync.request>) {
  try {
    yield call(SeasonsService.deleteSeason, action.payload.liveEventsId, action.payload.seasonNumber);
    yield put(deleteSeasonAsync.success(action.payload.seasonNumber));
    yield put(setAppNotification({ type: 'success', message: 'Season deleted' }));
  } catch (e) {
    yield sagaHandleApiError(e, deleteSeasonAsync.failure);
  }
}

const sagas = [
  function* () {
    yield takeLatest(getSeasonsAsync.request, fetchSeasons);
  },
  function* () {
    yield takeLatest(getSeasonAsync.request, fetchSeason);
  },
  function* () {
    yield takeLatest(createSeasonAsync.request, createSeasonSaga);
  },
  function* () {
    yield takeLatest(updateSeasonAsync.request, updateSeasonSaga);
  },
  function* () {
    yield takeLatest(deleteSeasonAsync.request, deleteSeasonSaga);
  },
];

export default function* seasonsSagas() {
  yield forkSagas(sagas);
}
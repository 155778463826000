import { Typography } from '@material-ui/core';
import CheckIcon from '@material-ui/icons/Check';
import type { MigrationResult } from '../../../../redux/catalog-migration/reducer';

interface ResultProps {
  result?: MigrationResult;
  notImplemented?: boolean;
}

export const Result = ({
  result,
  notImplemented
}: ResultProps) => {
  if (notImplemented) {
    return <Typography color="textSecondary">Not implemented yet</Typography>
  }

  if (!result) {
    return <Typography color="textSecondary">Skipped</Typography>
  }

  if (result.error) {
    return <Typography color="error">{result.error}</Typography>
  }

  return <CheckIcon htmlColor="#00b116" />
};

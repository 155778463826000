import { Component } from 'react';
import type {RootState} from '../../redux/reducers';
import type { ConnectedProps} from 'react-redux';
import {connect} from 'react-redux';
import type {RouteComponentProps} from 'react-router';

import type {ChallengeSchedule, ScheduledChallenge} from '../../services/player-challenges/season-challenge-schedule';
import {getSeasonChallengeScheduleAsync} from '../../redux/player-challenges/season-challenge-schedule/actions';
import type {ScheduleCalendarTypeEnum} from "../../services/player-challenges/season-challenge-schedule-calendar";
import {getChallengesAsync} from "../../redux/player-challenges/challenges/actions";
import {Box, Link, Typography} from "@material-ui/core";
import ChallengeScheduleGrid from "./ChallengeScheduleTypes/ChallengeScheduleGrid";

const mapStateToProps = (state: RootState) => ({
  seasonChallengeSchedule: state.seasonChallengeSchedule,
  seasonChallenges: state.challenges
});

const mapDispatch = {
  requestGetSeasonChallengeSchedule: getSeasonChallengeScheduleAsync.request,
  fetchAllChallenges: getChallengesAsync.request
};

interface OwnProps {
  scheduleType: ScheduleCalendarTypeEnum
}

const connector = connect(mapStateToProps, mapDispatch);

type Props = OwnProps & RouteComponentProps & ConnectedProps<typeof connector>;

interface State {
  seasonNumber: number,
  itemToEdit?: ScheduledChallenge,
}

class SeasonChallengeSchedule extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    const params: any = this.props.match.params;
    this.state = {
      seasonNumber: parseInt(params.seasonNumber),
    }
  }

  componentDidMount() {
    this.props.requestGetSeasonChallengeSchedule(this.state.seasonNumber);
    this.props.fetchAllChallenges();
  }

  getDatetimeLocalString(epochSeconds: number) {
    const date = new Date(epochSeconds * 1000);

    const result = `${date.getFullYear()}-${
      (date.getMonth() + 1).toString().padStart(2, '0')}-${
      date.getDate().toString().padStart(2, '0')}T${
      date.getHours().toString().padStart(2, '0')}:${
      date.getMinutes().toString().padStart(2, '0')}`;

    return result;
  }

  render() {
    const schedule: ChallengeSchedule | undefined = this.props.seasonChallengeSchedule.data;
    if (schedule) {
      schedule.season_number = this.state.seasonNumber;
    }

    return (<>
      <Box mb={2} textAlign="right">
        <Typography variant="subtitle2">
          <Link href={'/live-events/challenges'}>Looking for global challenges?</Link>
        </Typography>
      </Box>

      {schedule &&
        this.props.seasonChallenges &&
        this.props.seasonChallenges.data &&
        !this.props.seasonChallenges.isLoading &&
        this.props.seasonChallengeSchedule &&
        this.props.seasonChallengeSchedule.data &&
        !this.props.seasonChallengeSchedule.isLoading &&
        (
          <ChallengeScheduleGrid seasonNumber={this.state.seasonNumber} scheduleId={schedule.schedule_id} challenges={this.props.seasonChallenges.data} calendarType={this.props.scheduleType}/>
        )
      }

    </>
    );
  }
}

export default connector(SeasonChallengeSchedule);
import { Button, CircularProgress, FormControl, Grid, InputLabel, MenuItem, Select, Typography } from '@material-ui/core';
import type { ChangeEvent } from 'react';
import { useCallback, useEffect, useState } from 'react';
import { TooltipIcon } from '../../../components/TooltipIcon';
import { getEnvApi, MythicalMigrationAPI } from '../../../services/api';
import { CatalogMigrationService } from '../../../services/catalog-migration';
import type { Catalog } from '../../../services/catalogs';

export interface CatalogSelectProps {
  env: string;
  catalogName: string;
  sourceEnv?: string;
  sourceCatalogName?: string;
  loggedIn?: boolean;
  onChange: (catalogName: string) => void;
  onLoggedInChange?: (loggedIn: boolean) => void;
  defaultToPrimaryCatalog?: boolean;
}

export const CatalogSelect = (props: CatalogSelectProps) => {
  const { onChange, onLoggedInChange, defaultToPrimaryCatalog } = props;
  const loggedIn = props.loggedIn || false;

  const [catalogs, setCatalogs] = useState<Catalog[] | null>(null);
  const [touched, setTouched] = useState(false);
  const [error, setError] = useState('');

  const setLoggedIn = useCallback((loggedIn: boolean) => {
    if (onLoggedInChange) {
      onLoggedInChange(loggedIn);
    }
  }, [onLoggedInChange]);

  useEffect(() => {
    if (catalogs === null && loggedIn) {
      CatalogMigrationService.getCatalogs(props.env)
        .then(setCatalogs)
        .catch(() => setError('Failed to get catalogs'));
    }
  }, [props.env, catalogs, loggedIn]);

  useEffect(() => {
    const envApi = getEnvApi(props.env);
    if (envApi instanceof MythicalMigrationAPI) {
      setLoggedIn(false);
      envApi.isLoggedIn().then(b => setLoggedIn(b));
    } else {
      setLoggedIn(true);
    }

    setError('');
    setCatalogs(null);
  }, [props.env, setLoggedIn]);

  useEffect(() => {
    if (catalogs === null || !loggedIn) {
      return;
    }

    if (!touched && props.sourceCatalogName && props.sourceCatalogName !== props.catalogName
      && catalogs.find(v => v.name === props.sourceCatalogName)) {
      onChange(props.sourceCatalogName);
    } else if (catalogs.length > 0 && !catalogs.find(v => v.name === props.catalogName)) {
      // start out with first catalog
      let initialCatalog = catalogs[0].name;
      if (defaultToPrimaryCatalog) {
        // look up the primary catalog
        const primaryCatalog = catalogs.find(v => v.primaryCatalog);
        // if we find a primary catalog, we'll use this for initial catalog
        if (primaryCatalog) {
          initialCatalog = primaryCatalog.name;
        }
      }
      onChange(initialCatalog);
    }
  }, [props.catalogName, props.sourceCatalogName, onChange, touched, catalogs, loggedIn, defaultToPrimaryCatalog]);

  const onSelectChange = useCallback((e: ChangeEvent<{ value: unknown; }>) => {
    setTouched(true);
    onChange(e.target.value as string);
  }, [onChange]);

  const onLogIn = useCallback(async () => {
    const envApi = getEnvApi(props.env);
    if (envApi instanceof MythicalMigrationAPI) {
      envApi.logIn()
        .then((isLoggedIn) => setLoggedIn(isLoggedIn))
        .catch((e: Error) => {
          if ((e.message || '').indexOf('closed by user') < 0) {
            setError('There was a problem logging in');
          }
        });
    } else {
      //This only happens when its the same env as source i.e. local.MS36 -> local.MS37
      setLoggedIn(true);
    }
  }, [props.env, setLoggedIn]);

  if (error) {
    return <Typography color="error">{error}</Typography>
  } else if (!loggedIn) {
    return (
      <Grid container spacing={1} alignItems="center">
        <Grid item xs="auto">
          <Button variant="contained" color="primary" size="small" onClick={onLogIn}>
            Log In
          </Button>
        </Grid>
        <Grid item xs>
          <TooltipIcon icon="warn" title="Popup blockers may prevent you from logging in" />
        </Grid>
      </Grid>
    );
  } else if (catalogs === null) {
    return <CircularProgress />;
  } else if (catalogs.length < 1) {
    return <>No catalogs available</>;
  } else {
    return (
      <FormControl>
        <InputLabel>Catalog</InputLabel>
        <Select
          value={props.catalogName}
          onChange={onSelectChange}
        >
          {catalogs.map(catalog => (
            <MenuItem key={catalog.name} value={catalog.name}>{catalog.name}</MenuItem>
          ))}
        </Select>
      </FormControl>
    )
  }
}
import { Box, Button, Checkbox, FormControl, FormControlLabel, Grid, Link } from '@material-ui/core';
import { useCallback, useEffect, useState } from 'react';
import { BlankosFeature, FeatureManager } from '../../../services/features';
import { RouterLink } from '../../../types';
import type { MigrationCommonProps, MigrationModule } from '../migrations';
import { geoblockingMigration } from '../modules/geoblocking/geo-blocking-migration';
import { itemNoteMigration } from '../modules/item-note/item-note-migration';
import { migrationModules, migrationRunOrder } from '../modules/migration-modules';
import { CatalogSelect } from './CatalogSelect';
import { EnvSelect } from './EnvSelect';
import { DiffItem } from "../../../pages/CatalogMigration/types";
import { MigrationType } from '../modules/migration-type';

export type MigrationOptionsProps = MigrationCommonProps;

const baseOptions: MigrationModule<DiffItem>[] = migrationRunOrder.map(v => migrationModules[v])
  .filter((v): v is MigrationModule<DiffItem> => !!v);

export const MigrationOptions = (props: MigrationOptionsProps) => {
  const { sourceData, targetData, migrationData, onMigrationDataChange, onTargetChange } = props;
  const [options, setOptions] = useState(migrationRunOrder.map(v => migrationModules[v]).filter((v): v is MigrationModule<DiffItem> => !!v));
  const [targetLoggedIn, setTargetLoggedIn] = useState(true);
  
  const filterOptionsByFeatureSupport = useCallback(async (env: string) => { 
    if (!targetLoggedIn) {
      return;
    }

    const filteredOptions = baseOptions.slice();

    //Do not allow a migration to an environment that doesn't support the feature, all new migrations should add this check
    const migrationFeatures: { [key in BlankosFeature]?: string } = {
      GEOBLOCKING: geoblockingMigration.id,
      ITEM_NOTES: itemNoteMigration.id
    };

    try {
      const featureManager = new FeatureManager(env);
      let key: BlankosFeature;
      for (key in migrationFeatures) {
        if (!(await featureManager.isActive(key))) {
          const index = filteredOptions.findIndex(x => x.id === migrationFeatures[key]);
          delete filteredOptions[index];
        }
      }
    } catch (e) {
      console.log(e);
      //swallow this, we can't do anything if we can't reach the feature service to begin with.
    }
    setOptions(filteredOptions)
  },[targetLoggedIn]);

  useEffect(() => {
    filterOptionsByFeatureSupport(targetData.env);
  },[filterOptionsByFeatureSupport, targetData.env]);

  const handleOptionChange = useCallback((id: MigrationType, checked: boolean) => {
    const newOptions = { ...migrationData.options };

    if(!checked){
      delete newOptions[id];
    } else{
      newOptions[id] = checked;
    }

    onMigrationDataChange({ ...migrationData, options: newOptions });
  }, [onMigrationDataChange, migrationData]);

  const handleTargetEnvChange = useCallback(async (env: string) => {
    //Changing the environment should default us to "logged out" so we dont attempt to make calls
    //that we can't support.
    if(sourceData.env != env){
      setTargetLoggedIn(false);
    }
    onTargetChange({ ...targetData, env });
    if (sourceData.env === env) {
      const newOptions = { ...migrationData.options };
      for (const migrationModule of Object.values(migrationModules)) {
        if (migrationModule.crossEnvOnly) {
          newOptions[migrationModule.id] = false;
        }
      }
      onMigrationDataChange({ ...migrationData, options: newOptions });
    }
  }, [sourceData, targetData, migrationData, onTargetChange, onMigrationDataChange]);

  return (<>
    <Box my={2}>
      Migrate from
    </Box>

    <CatalogSelect
      env={sourceData.env}
      catalogName={sourceData.catalogName}
      loggedIn
      onChange={catalogName => props.onSourceChange({ ...sourceData, catalogName })}
    />

    <Box my={2}>to</Box>

    <Grid container spacing={2} alignItems="flex-end">
      <Grid item xs="auto">
        <EnvSelect env={targetData.env} onChange={handleTargetEnvChange} />
      </Grid>
      <Grid item xs>
        <CatalogSelect
          env={targetData.env}
          catalogName={targetData.catalogName}
          sourceEnv={sourceData.env}
          sourceCatalogName={sourceData.catalogName}
          onChange={catalogName => props.onTargetChange({ ...targetData, catalogName })}
          loggedIn={targetLoggedIn}
          onLoggedInChange={setTargetLoggedIn}
        />
      </Grid>
    </Grid>

    <Box my={2}>
      {options.map(option => (
        <Box key={option.id}>
          <FormControl>
            <FormControlLabel
              label={option.displayName}
              control={<Checkbox
                checked={Boolean(migrationData.options[option.id])}
                disabled={sourceData.env === targetData.env && migrationModules[option.id]?.crossEnvOnly}
                onChange={(event, checked) => handleOptionChange(option.id, checked)}
              />}
            />
          </FormControl>
        </Box>
      ))}
    </Box>

    <Box mt={2}>
      <Button
        variant="contained"
        color="primary"
        onClick={props.onNextStep}
        disabled={!targetLoggedIn || Object.keys(migrationData.options).length < 1 || (sourceData.env === targetData.env && sourceData.catalogName === targetData.catalogName)}
      >
        Next
      </Button>
    </Box>

    <Box mt={4}>
      Running into issues with the new tool? <Link component={RouterLink} to={`/catalog-migration-v1?source=${sourceData.catalogName}`}>Switch to the old migration tool</Link>.
    </Box>
  </>);
}

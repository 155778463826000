import {createAsyncAction} from 'typesafe-actions';
import type {ScheduleCalendar} from '../../../services/player-challenges/season-challenge-schedule-calendar';

export const getSeasonChallengeScheduleCalendarsAsync = createAsyncAction(
  'GET_SEASON_CHALLENGE_SCHEDULE_CALENDARS_REQUEST',
  'GET_SEASON_CHALLENGE_SCHEDULE_CALENDARS_SUCCESS',
  'GET_SEASON_CHALLENGE_SCHEDULE_CALENDARS_FAILURE'
)<{ seasonNumber: number, scheduleId: string }, ScheduleCalendar[], string>();

export const createSeasonChallengeScheduleCalendarAsync = createAsyncAction(
  'CREATE_SEASON_CHALLENGE_SCHEDULE_CALENDAR_REQUEST',
  'CREATE_SEASON_CHALLENGE_SCHEDULE_CALENDAR_SUCCESS',
  'CREATE_SEASON_CHALLENGE_SCHEDULE_CALENDAR_FAILURE'
)<{ seasonNumber: number, scheduleId: string, scheduleCalendarJson: any }, ScheduleCalendar, string>();

export const getSeasonChallengeScheduleCalendarAsync = createAsyncAction(
  'GET_SEASON_CHALLENGE_SCHEDULE_CALENDAR_REQUEST',
  'GET_SEASON_CHALLENGE_SCHEDULE_CALENDAR_SUCCESS',
  'GET_SEASON_CHALLENGE_SCHEDULE_CALENDAR_FAILURE'
)<{ seasonNumber: number, scheduleId: string, instanceId: string }, ScheduleCalendar, string>();

export const updateSeasonChallengeScheduleCalendarAsync = createAsyncAction(
  'UPDATE_SEASON_CHALLENGE_SCHEDULE_CALENDAR_REQUEST',
  'UPDATE_SEASON_CHALLENGE_SCHEDULE_CALENDAR_SUCCESS',
  'UPDATE_SEASON_CHALLENGE_SCHEDULE_CALENDAR_FAILURE'
)<{ seasonNumber: number, scheduleId: string, instanceId: string, scheduleCalendar: ScheduleCalendar }, ScheduleCalendar, string>();

export const deselectAndRefreshScheduleCalendar = createAsyncAction(
  'DESELECT_AND_REFRESH_SCHEDULE_CALENDAR_REQUEST',
  'DESELECT_AND_REFRESH_SCHEDULE_CALENDAR_SUCCESS',
  'DESELECT_AND_REFRESH_SCHEDULE_CALENDAR_FAILURE'
)<void, ScheduleCalendar, string>();

export const deleteSeasonChallengeScheduleCalendarAsync = createAsyncAction(
  'DELETE_SEASON_CHALLENGE_SCHEDULE_CALENDAR_REQUEST',
  'DELETE_SEASON_CHALLENGE_SCHEDULE_CALENDAR_SUCCESS',
  'DELETE_SEASON_CHALLENGE_SCHEDULE_CALENDAR_FAILURE'
)<{ seasonNumber: number, scheduleId: string, instanceId: string }, { seasonNumber: number, scheduleId: string, instanceId: string }, string>();

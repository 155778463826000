import { usePushNotification } from "../../contexts/AppNotificationContext";
import { ApiError } from "../../services/api";

/** Provide to the onError callback of a react-query hook to report any error as a global app notification and to the console. */
export const useErrorHandler = () => {
  const pushNotification = usePushNotification()
  return (e?: unknown) => {
    const message = e instanceof ApiError ? e.message : "An unknown error occurred";
    pushNotification({ type: "error", message });
    console.error(e);
  }
}
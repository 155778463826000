import type {PlayerTag} from "../accounts";
import type {Column} from "material-table";
import {useCallback, useEffect, useState} from "react";
import type {RowMenuAction} from "../../../shared/components/AdminTable";
import {AdminTable} from "../../../shared/components/AdminTable";
import {UserService} from "../../../services/user";
import {Delete} from "@material-ui/icons";
import {Box, Button, Card, CardContent, CardHeader} from "@material-ui/core";
import ConfirmationDialog from "../../../components/ConfirmationDialog";
import type {Player} from "../../players/players";
import {getTags, removeTagFromPlayer} from "../accountApi";
import type { ApiError } from "../../../services/api";
import { PlayersService } from '../../players/players';
import {pushAppNotification} from "../../../shared/hooks/useAppNotification";
import {TagSelectDialog} from "./TagSelectDialog";

interface Props {
  player: Player;
  onSuccess: () => void;
}

export const PlayerTagsPanel = ( {player, onSuccess}: Props) => {

  const columns: Column<PlayerTag>[] = [
    {
      title: 'Tag',
      field: 'tag'
    },
    {
      title: 'Visibility',
      field: 'visibility',
      render(tag: PlayerTag) { return <>{ tag.visibility.join(", ") }</> }
    }
  ]

  const [tags, setTags] = useState<PlayerTag[] | undefined>(undefined)
  const [menuItems, setMenuItems] = useState<RowMenuAction<PlayerTag>[] | undefined>()
  const [confirmation, setConfirmation] = useState(false)
  const [rowData, setRowData] = useState<PlayerTag | undefined>()
  const [tagSelectDialogOpen, setTagSelectDialogOpen] = useState(false)

  useEffect(() => {
    if (tags === undefined) {
      getTags().then(setTags)
        .catch((e: ApiError) => pushAppNotification({
          type: 'error',
          message: `Failed to load tags. ${e.message}`
        }));
    }
  }, [tags])

  const onRemove = useCallback((row: PlayerTag) => {
    setConfirmation(true)
    setRowData(row)
  }, [])

  useEffect(() => {
    const items: RowMenuAction<PlayerTag>[] = [];
    UserService.canUpdate('player') && items.push({ type: 'button', icon: Delete, label: 'Delete', onClick: onRemove })
    setMenuItems(items)
  }, [onRemove])

  const onAdd = useCallback(() => {
    setTagSelectDialogOpen(true)
  }, [])

  const onConfirm = useCallback(() => {
    if (typeof rowData === 'object' && rowData.tag) {
      rowData && removeTagFromPlayer(player.id, rowData.tag)
        .then(() => {
          onSuccess();
          setRowData(undefined)
          setConfirmation(false)
        }).then(() => pushAppNotification({
          type: 'success',
          message: `Successfully deleted ${rowData.tag} tag from player`
        })).catch((e: ApiError) => pushAppNotification({
          type: 'error',
          message: `Failed to remove tag from player. ${e.message}`
        }));
    }
  }, [rowData, player, onSuccess]);

  return (<>
    <Card>
      <CardHeader title="Tags" />
      <CardContent>
        <Box mb={2} textAlign="right">
          <Button variant="contained" color="primary" onClick={onAdd}>
            Add
          </Button>
          {player.tags && <AdminTable<PlayerTag>
            title=""
            storageId="tags"
            columns={columns}
            data={player.tags}
            options={{
              pageSize: 5,
              search: false,
              toolbar: false
            }}
            menuActions={menuItems}
        />}
        </Box>
      </CardContent>
    </Card>
    {tagSelectDialogOpen && (
      <TagSelectDialog show={tagSelectDialogOpen}
        tags={tags}
        onClose={() => setTagSelectDialogOpen(false)}
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        onSubmit={() => { }}
        onSuccess={onSuccess}
        playerId={player.id}
      />
    )}
    {confirmation &&
      <ConfirmationDialog
        title={`Delete ${rowData?.tag}?`}
        details={`Are you sure you want to remove ${rowData?.tag} from the player?`}
        open={confirmation}
        onClose={() => setConfirmation(false)}
        onConfirm={onConfirm}
      />}
  </>)

}
import { useEffect, useState } from 'react';
import { Box, CircularProgress, FormControlLabel, Grid, Link, Switch, Typography } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import { Link as RouterLink } from 'react-router-dom';
import type { Column } from 'material-table';
import type { MenuAction } from '../../../components/MaterialTable';
import MaterialTable from '../../../components/MaterialTable';
import type { Friend } from '../../../services/model/friend';
import { UserService } from '../../../services/user';
import JsonEditor from '../../../components/JsonEditor';
import { PlayersService } from "../players";
import { pushAppNotification } from "../../../shared/hooks/useAppNotification";
import type { NakamaUser } from "../../../services/model/nakama-user";
import { openAdminConfirmationDialog } from "../../../shared/hooks/useAdminConfirmationDialog";
import { arrayRemove } from "../../../redux/utils-ts";

type Props = {
  playerId: string;
};

export const PlayerSocial = ({playerId}: Props) => {
  const [showBlockedUsers, setShowBlockedUsers] = useState(false);

  const columns: Column<Friend>[] = [
    {
      title: 'Username',
      field: 'username',
      sorting: true,
      cellStyle: {wordBreak: 'break-all'},
      editable: 'never',
      render: friend => {
        if (friend.metadata && friend.metadata.external_id) {
          return (<Link component={RouterLink} to={`/players/${friend.metadata.external_id}`}>
            {friend.username}
          </Link>);
        } else {
          return (friend.username);
        }
      },
    },
    {
      title: 'Display Name',
      field: 'displayName',
      sorting: true,
      cellStyle: {wordBreak: 'break-all'},
      editable: 'never',
    },
    {
      title: 'Friend Status',
      field: 'stateName',
      sorting: true,
      editable: 'never',
    },
    {
      title: 'Online',
      field: 'online',
      type: 'boolean',
      searchable: false,
      editable: 'never',
    }
  ];

  const [friends, setFriends] = useState<Friend[] | undefined>();
  const [nakamaUser, setNakamaUser] = useState<NakamaUser | undefined>();
  useEffect(() => {
    PlayersService.getPlayerFriends(playerId, {friendState: undefined, limit: undefined, cursor: undefined})
      .then(friends => setFriends(friends))
      .catch(err => pushAppNotification({type: 'error', message: `Failed to fetch player friends: ${err.message}`}));
    PlayersService.getNakamaUser(playerId)
      .then(nakamaUser => setNakamaUser(nakamaUser))
      .catch(err => pushAppNotification({
        type: 'error',
        message: `Failed to fetch player nakama user: ${err.message}`
      }));
  }, [playerId]);

  const blockIncomingFriendRequests = (block: boolean) => {
    (block ? PlayersService.blockFriendRequests(playerId) : PlayersService.unblockFriendRequests(playerId))
      .then(() => {
        pushAppNotification({ type: 'success', message: `Friend requests ${block ? '' : 'un'}blocked` });
        setNakamaUser(nakamaUser => nakamaUser && ({
          ...nakamaUser,
          metadata: {...nakamaUser.metadata, block_friend_requests: block}
        }));
      })
      .catch(err => pushAppNotification({
        type: 'error',
        message: `Failed to ${block ? '' : 'un'}block friend requests: ${err.message}`
      }));
  };

  const isBlocked = (friend: Friend) => friend.stateCode === 3;

  const onRemoveFriend = (friend: Friend) => {
    openAdminConfirmationDialog({
      title: `Remove ${isBlocked(friend) ? 'blocked user' : 'friend'} ${friend.username}?`,
      action: `Remove ${isBlocked(friend) ? 'Blocked User' : 'Friend'}`,
      onConfirm: () => {
        pushAppNotification({ type: 'success', message: `${isBlocked(friend) ? 'Blocked user' : 'Friend'} removed` });
        PlayersService.removePlayerFriend(playerId, friend.id)
          .then(() => {
            setFriends(friends => friends && arrayRemove(friends, friend));
          })
          .catch(err => pushAppNotification({
            type: 'error',
            message: `Failed to remove ${isBlocked(friend) ? 'blocked user' : 'friend'}: ${err.message}`
          }));
      }
    });
  };

  const getTableMenuActions = (friend: Friend): MenuAction<Friend>[] => {
    const actions: MenuAction<Friend>[] = [];

    if (UserService.canDelete('social')) {
      actions.push({
        type: 'button',
        icon: DeleteIcon,
        label: isBlocked(friend) ? 'Remove Blocked User' : 'Remove Friend',
        onClick: onRemoveFriend
      })
    }
    return actions;
  };

  const getTableData = (friends: Friend[]): Friend[] => {
    return showBlockedUsers ? friends.filter(isBlocked) : friends.filter(friend => !isBlocked(friend));
  };

  const renderMetadataPanel = (friend: Friend) => {
    if (friend.metadata) {
      return (
        <JsonEditor
          readOnly
          title="Metadata"
          json={friend.metadata}
          // eslint-disable-next-line @typescript-eslint/no-empty-function
          onJsonChange={() => {
          }}
        />
      );
    } else {
      return (
        <Box p={2}>
          <Typography color="textSecondary">
            No metadata available.
          </Typography>
        </Box>
      );
    }
  };

  if (friends && nakamaUser) {
    return (<>
      <Box mb={2}>
        <Grid container alignItems="center">
          <FormControlLabel
            control={
              <Switch
                checked={showBlockedUsers}
                onChange={event => setShowBlockedUsers(event.target.checked)}
                color="primary"
              />
            }
            label="Show Blocked"
          />

          <FormControlLabel
            control={
              <Switch
                checked={nakamaUser.metadata.block_friend_requests || false}
                onChange={event => blockIncomingFriendRequests(event.target.checked)}
                color="primary"
              />
            }
            label="Block All Incoming Friend Requests"
          />
        </Grid>
      </Box>

      <MaterialTable
        title="Friends"
        columns={columns}
        data={getTableData(friends)}
        options={{
          addRowPosition: 'first',
          emptyRowsWhenPaging: false,
          toolbarButtonAlignment: 'left',
          detailPanelColumnAlignment: 'right'
        }}
        menuActions={getTableMenuActions}
        detailPanel={[
          entry => ({
            icon: MoreHorizIcon,
            tooltip: 'View metadata',
            render: renderMetadataPanel
          })
        ]}
      />
    </>);
  } else {
    return (
      <Box textAlign="center">
        <CircularProgress/>
      </Box>
    );
  }

};

import { useEffect, useState } from "react";
import { PlayersService } from "../../players/players";
import type { GumballHistoryEntry } from "../gumball-history";
import { pushAppNotification } from "../../../shared/hooks/useAppNotification";
import { Box, CircularProgress } from "@material-ui/core";
import MaterialTable from "../../../components/MaterialTable";
import type { Column } from "material-table";
import { PlayerItemsService } from "../../players/player-items";

interface Props {
  playerId: string;
}

const columns: Column<GumballHistoryEntry>[] = [
  {
    title: 'Item ID',
    field: 'itemId',
  },
  {
    title: 'Action Type',
    field: 'actionType',
  },
  {
    title: 'Action Info',
    field: 'actionInfo',
  },
  {
    title: 'Timestamp',
    field: 'createdAt',
    type: 'datetime',
  }
];

export const GumballHistory = ({playerId}: Props) => {
  const [gumballHistory, setGumballHistory] = useState<GumballHistoryEntry[]>();
  useEffect(() => {
    PlayerItemsService.getPlayerGumballHistory(playerId)
      .then(gumballHistory => setGumballHistory(gumballHistory))
      .catch(err => pushAppNotification({type: 'error', message: `Failed to get gumball history: ${err.message}`}));
  }, [playerId]);

  if (!gumballHistory) {
    return <Box textAlign="center">
      <CircularProgress/>
    </Box>;
  }
  return <MaterialTable title='Gumball History' columns={columns} data={gumballHistory} />;
};
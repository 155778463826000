import type { Column } from 'material-table';
import { useEffect, useState } from 'react';
import * as React from 'react';
import { useTypedSelector } from '../../../../redux/reducers';
import type { DiffItem } from '../../types';
import { DiffsPanel } from './DiffsPanel';

interface DiffsPanelContainerProps<T extends DiffItem> {
  expanded: boolean;
  title: string;
  diffType: 'change' | 'add' | 'remove';
  items: T[];
  selectedIds: string[];
  columns: Column<T>[];
  onExpand: () => void;
  onSelectionChange: (ids: string[]) => void;
}

export const DiffsPanelContainer = <T extends DiffItem>({
  expanded,
  title,
  diffType,
  items,
  selectedIds,
  columns,
  onExpand,
  onSelectionChange
}: DiffsPanelContainerProps<T>) => {
  const darkMode = useTypedSelector(state => state.app.darkMode);

  const [selectionMap, setSelectionMap] = useState<{ [key: string]: boolean }>({});

  useEffect(() => {
    const selectionMap: { [key: string]: boolean } = {};
    selectedIds.forEach(id => selectionMap[id] = true);
    setSelectionMap(selectionMap);
  }, [selectedIds]);

  const onSelectAllClick = (event: React.MouseEvent) => {
    event.stopPropagation();
    if (items.length > 0) {
      if (selectedIds.length > 0) {
        onSelectionChange([]);
      } else {
        onSelectionChange(items.map(item => item.diffId));
      }
    }
  }

  const onSelectClick = (item: T) => {
    const newSelectionMap = {
      ...selectionMap
    };

    if (newSelectionMap[item.diffId]) {
      delete newSelectionMap[item.diffId];
    } else {
      newSelectionMap[item.diffId] = true;
    }

    setSelectionMap(newSelectionMap);
    onSelectionChange(Object.keys(newSelectionMap));
  }

  return (<DiffsPanel
    expanded={expanded}
    title={title}
    diffType={diffType}
    items={items}
    selectedIds={selectedIds}
    selectionMap={selectionMap}
    columns={columns}
    darkMode={darkMode}
    onExpand={onExpand}
    onSelectClick={onSelectClick}
    onSelectAllClick={onSelectAllClick} />);
}

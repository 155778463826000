import type { Reducer } from 'redux';
import { createReducer } from 'typesafe-actions';
import type { Challenge } from '../../../services/player-challenges/challenges';
import {
  getChallengesAsync,
  updateChallengeAsync,
  deleteChallengeAsync,
  getChallengeAsync,
  createChallengeAsync,
  addNewChallengeAsync
} from './actions';

export interface ChallengesState {
  isLoading: boolean;
  isUpdating: boolean;
  isDeleting?: string;
  data: Challenge[];
}

const initialState: ChallengesState = {
  isLoading: false,
  isUpdating: false,
  isDeleting: undefined,
  data: []
};

const challengesReducer: Reducer<ChallengesState> = createReducer<ChallengesState>(initialState)
  .handleAction(getChallengesAsync.request, state => ({
    ...state,
    isLoading: true
  }))
  .handleAction(getChallengesAsync.success, (state, action) => ({
    ...state,
    isLoading: false,
    data: action.payload
  }))
  .handleAction(getChallengesAsync.failure, state => ({
    ...state,
    isLoading: false
  }))
  .handleAction(getChallengeAsync.request, (state, action) => ({
    ...state,
    isLoading: false
  }))
  .handleAction(getChallengeAsync.success, (state, action) => ({
    ...state,
    isLoading: false,
    data: state.data.map(challenge => challenge.challenge_id === action.payload.challenge_id ? action.payload : challenge)
  }))
  .handleAction(getChallengeAsync.failure, (state, action) => ({
    ...state,
    isLoading: false
  }))
  .handleAction(createChallengeAsync.request, (state, action) => ({
    ...state,
    isLoading: true
  }))
  .handleAction(createChallengeAsync.success, (state, action) => ({
    ...state,
    isLoading: false,
    data: state.data.concat(action.payload)
  }))
  .handleAction(createChallengeAsync.failure, (state, action) => ({
    ...state,
    isLoading: false
  }))
  .handleAction(updateChallengeAsync.request, (state, action) => ({
    ...state,
    isUpdating: true
  }))
  .handleAction(updateChallengeAsync.success, (state, action) => ({
    ...state,
    isUpdating: false,
    data: state.data.map(challenge => challenge.challenge_id === action.payload.challenge_id ? action.payload : challenge)
  }))
  .handleAction(updateChallengeAsync.failure, (state, action) => ({
    ...state,
    isUpdating: false
  }))
  .handleAction(deleteChallengeAsync.request, (state, action) => ({
    ...state,
    isDeleting: action.payload
  }))
  .handleAction(deleteChallengeAsync.success, (state, action) => {
    const index = state.data.findIndex(challenge => challenge.challenge_id === action.payload);
    const newData = state.data.slice();
    if (index >= 0) {
      newData.splice(index, 1);
    }
    return ({
      ...state,
      isDeleting: undefined,
      data: newData
    }); 
  })
  .handleAction(deleteChallengeAsync.failure, (state, action) => ({
    ...state,
    isDeleting: undefined
  }))
  .handleAction(addNewChallengeAsync.request, (state, action) => ({
    ...state,
    isLoading: true
  }))
  .handleAction(addNewChallengeAsync.success, (state, action) => ({
    ...state,
    isLoading:false,
    data: state.data.concat(action.payload)
  }))
  .handleAction(addNewChallengeAsync.failure, (state, action) => ({
    ...state,
    isLoading:false
  }));
export default challengesReducer;
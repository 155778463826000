import _ from "lodash";
import type { Dispatch, ReactNode } from "react";
import { createContext, useCallback, useContext } from "react";
import { AppSnackbar } from "../components/AppSnackbar";
import { useAppNotification } from "../shared/hooks/useAppNotification";
import type { QueueAction } from "../shared/hooks/useQueue";
import { useQueue } from "../shared/hooks/useQueue";

export type NotificationType = 'error' | 'success' | 'info';

export type Notification = {
  type: NotificationType;
  message: string;
}

type AppNotificationContextProps = {
  notifications: Notification[];
  dispatch: Dispatch<QueueAction<Notification>>;
}

export const AppNotificationContext = createContext<AppNotificationContextProps | undefined>(undefined);
AppNotificationContext.displayName = "AppNotification";

type AppNotificationProviderProps = {
  children: ReactNode;
}

/** 
 * Use the Context API to handle app-wide notifications and display them with {@link AppSnackbar}.
 */
export const AppNotificationProvider = (props: AppNotificationProviderProps) => {
  const [notifications, dispatch] = useQueue<Notification>();

  // TODO: Remove when other areas do not rely on deprecated useAppNotification
  useAppNotification((notification) => {
    dispatch({
      type: "PUSH",
      data: notification
    });
  });

  const notification = _.first(notifications);

  return (
    <AppNotificationContext.Provider value={{ notifications, dispatch }}>
      {props.children}
      {notification !== undefined && <AppSnackbar 
        notification={notification} 
        onExited={() => dispatch({ type: 'DROP' })} 
        />}
    </AppNotificationContext.Provider>
  );
}

/**
 * Retrieve a dispatcher to send notifications to the queue.
 * 
 * @throws {Error} If not contained within an AppNotificationContext.
 */
export const usePushNotification = () => {
  const context = useContext(AppNotificationContext);
  if (context === undefined) {
    throw new Error("usePushNotification can only be called within an AppNotificationProvider.");
  }

  const dispatch = context.dispatch;
  return useCallback((notification: Notification) => dispatch({
    type: "PUSH",
    data: notification
  }), [dispatch]);
};

import type { CallEffect, PutEffect} from 'redux-saga/effects';
import { call, put, takeLatest } from 'redux-saga/effects';
import { setAppNotification } from '../app/actions';
import { forkSagas, sagaHandleApiError } from '../utils-ts';
import { createDailyMessageAsync, deleteDailyMessageAsync, getDailyMessagesAsync, updateDailyMessageAsync } from './actions';
import type {DailyMessage} from "../../services/daily-messages";
import { DailyMessageService} from "../../services/daily-messages";
import type { AnyAction } from 'redux';
import type { PayloadAction } from 'typesafe-actions';

function* getDailyMessagesSaga(action: ReturnType<typeof getDailyMessagesAsync.request>): Generator<Generator<PutEffect<AnyAction>, void, string> | CallEffect<DailyMessage[]> | PutEffect<PayloadAction<"GET_DAILY_MESSAGES_SUCCESS", DailyMessage[]>>, void, DailyMessage[]> {
  try {
    const messages = yield call(DailyMessageService.getAllDailyMessages);
    yield put(getDailyMessagesAsync.success(messages));
  } catch (e) {
    yield sagaHandleApiError(e, getDailyMessagesAsync.failure);
  }
}

function* createDailyMessageSaga(action: ReturnType<typeof createDailyMessageAsync.request>): Generator<
CallEffect<DailyMessage> 
| PutEffect<PayloadAction<"CREATE_DAILY_MESSAGE_SUCCESS", DailyMessage>> 
| PutEffect<PayloadAction<"SET_APP_NOTIFICATION", Record<string, unknown>>> 
| Generator<PutEffect<AnyAction>, void, string>, void, DailyMessage> {
  try {
    const message = yield call(DailyMessageService.addDailyMessage, action.payload);
    yield put(createDailyMessageAsync.success(message));
    yield put(setAppNotification({ type: 'success', message: 'Daily Message added' }));
  } catch (e) {
    yield sagaHandleApiError(e, createDailyMessageAsync.failure);
  }
}

function* updateDailyMessageSaga(action: ReturnType<typeof updateDailyMessageAsync.request>): Generator<
CallEffect<DailyMessage> 
| PutEffect<PayloadAction<"UPDATE_DAILY_MESSAGE_SUCCESS", DailyMessage>> 
| PutEffect<PayloadAction<"SET_APP_NOTIFICATION", Record<string, unknown>>> 
| Generator<PutEffect<AnyAction>, void, string>, void, DailyMessage> {
  try {
    const message = yield call(DailyMessageService.updateDailyMessage, action.payload);
    yield put(updateDailyMessageAsync.success(message));
    yield put(setAppNotification({ type: 'success', message: 'Daily Message updated' }));
  } catch (e) {
    yield sagaHandleApiError(e, updateDailyMessageAsync.failure);
  }
}

function* deleteDailyMessageSaga(action: ReturnType<typeof deleteDailyMessageAsync.request>) {
  try {
    yield call(DailyMessageService.deleteDailyMessage, action.payload);
    yield put(deleteDailyMessageAsync.success(action.payload));
    yield put(setAppNotification({ type: 'success', message: 'Daily Message deleted' }));
  } catch (e) {
    yield sagaHandleApiError(e, deleteDailyMessageAsync.failure);
  }
}

const sagas = [
  function* () {
    yield takeLatest(getDailyMessagesAsync.request, getDailyMessagesSaga);
  },
  function* () {
    yield takeLatest(createDailyMessageAsync.request, createDailyMessageSaga);
  },
  function* () {
    yield takeLatest(updateDailyMessageAsync.request, updateDailyMessageSaga);
  },
  function* () {
    yield takeLatest(deleteDailyMessageAsync.request, deleteDailyMessageSaga);
  }
];

export default function* dailyMessagesSagas() {
  yield forkSagas(sagas);
}
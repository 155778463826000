import { useState, useCallback, useEffect, useMemo } from 'react';
import { FormControl, MenuItem, Select, Box } from '@material-ui/core';

import {
  SeasonsService
} from '../../services/player-challenges/seasons';
import type { Season } from '../../services/player-challenges/seasons';
import _ from "lodash";

interface Props {
  value: number | undefined;
  onChange: (season: Season) => void;
  defaultToActive?: boolean;
}

const SeasonSelect = ({ value, onChange, defaultToActive = false } : Props) => {
  const [seasons, setSeasons] = useState<Season[]>([]);
  const seasonsByNumber = useMemo(() => {
    return _.keyBy(seasons, 'season_number');
  }, [seasons]);

  const getSeasons = useCallback(async () => {
    const seasons = await SeasonsService.getAllSeasons();
    setSeasons(seasons.sort((a,b) => {
      const liveCompare = a.live_events_name.localeCompare(b.live_events_name);
      if (liveCompare == 0) {
        if (a.season_number && b.season_number)
          return a.season_number - b.season_number;
        if (a.season_number)
          return 1;
        return -1;
      }
      return liveCompare;
    }));
    if (defaultToActive && typeof value === 'undefined') {
      const active = seasons.find(season => season.active);
      if (active) {
        onChange(active);
      }
    }
  }, [defaultToActive, onChange, value]);

  useEffect(() => {
    getSeasons();
  }, [getSeasons]);

  return (<>
    <FormControl>
      <Select
        value={typeof value === 'undefined' ? '' : value}
        onChange={event => onChange(seasonsByNumber[event.target.value as number])}
      >
        {seasons.map(season => (
          <MenuItem key={season.season_number} value={season.season_number}>{season.live_events_name}: Season {season.season_number} ({season.season_name})</MenuItem>
        ))}
      </Select>
    </FormControl>
  </>)
};

export default SeasonSelect;

import { Box, Typography, CircularProgress } from '@material-ui/core';

export const Loading = () => (
  <Box textAlign="center">
    <Typography>
      Hang on... collecting data...
    </Typography>
    <Box mt={1}>
      <CircularProgress />
    </Box>
  </Box>
);
/* eslint-disable @typescript-eslint/ban-ts-comment */
import { Box, Button, FormHelperText, Grid, InputLabel, TextField, Theme, createStyles, makeStyles } from "@material-ui/core";
import { useCallback, useState } from "react";
import type { FormikProps } from "formik";
import { CapstoneForm } from ".";
import type { BlankoGemRushFuse, CapstoneBurnCurrency} from '../blankoGemRush';
import { CapstoneEnum } from '../blankoGemRush';
import { Add } from "@material-ui/icons";
import ImageUpload from "../../../components/ImageUpload";
import ItemSelectDialog from "../../../pages/ItemDefinition/ItemSelectDialog";
import { ItemDefinition } from "../../../services/item-definitions";
import { DropTable } from "../../../services/drop-tables";
import { usePushNotification } from "../../../contexts/AppNotificationContext";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formControl: {
      marginBottom: theme.spacing(2),
      minWidth: 400,
    },
    quanityFormControl: {
      marginBottom: theme.spacing(2),
      minWidth: 200,
    },
    addButton: {
      display: 'flex',
      flexDirection: 'column',
      margin: 'auto',
      width: 'fit-content',
      marginTop: 10,
      marginBottom: 10
    }
  }),
);

export interface FuseFormGemRushFuse extends BlankoGemRushFuse {
  baseDnaUrl: string | undefined;
  upgradeDnaUrl: string | undefined;
}

export interface FuseFormProps {
  formikThings: FormikProps<FuseFormGemRushFuse>;
  onFileChanged: (file:File) => void;
}

export const FuseForm = ({ formikThings, onFileChanged }: FuseFormProps) => {
  const {errors, touched, handleChange, handleBlur, values, ...formikProps } = formikThings;
  const { baseDnaId, baseDnaUrl, upgradeDnaId, upgradeDnaUrl, maxQuantity, remainingQuantity, capstone, fuseTitle, fuseDescription, popupImageUrl } = values;
  const classes = useStyles();
  const pushNotification = usePushNotification();
  const [itemSelectDialogOpen, setItemSelectDialogOpen] = useState(false);
  const [isUpdatingBase, setIsUpdatingBase] = useState(false);

  const addCapstone = useCallback((e: any) => {
    const emptyCapstone: CapstoneBurnCurrency = {
      type: CapstoneEnum.BurnCurrency,
      param: {
        BB: 0,
        GV: 0
      }

    }
    values.capstone.push(emptyCapstone)
    handleBlur(e)
  }, [values, handleBlur])

  const handleFileChanged = (file: File) => {
    //Trigger a change in formik since we aren't using a formik control.
    const c = handleChange("popupImageUrl");
    c('');
    onFileChanged(file);
  }

  const selectDna = (isUpdatingBase: boolean) => {
    setIsUpdatingBase(isUpdatingBase);
    setItemSelectDialogOpen(true);
  };

  const onItemSelect = useCallback(async (items: (ItemDefinition | DropTable)[]) => {
    if (!items || items.length !== 1) {
      return;
    }

    const item = items[0];
    if (item instanceof ItemDefinition) {
      try {
        if (isUpdatingBase) {
          if (item.itemId !== values.upgradeDnaId) {
            formikProps.setFieldValue('baseDnaId', item.itemId);
            formikProps.setFieldValue('baseDnaUrl', item.imageThumbnailUrl);
          } else {
            throw new Error('Base DNA and Upgrade DNA must be different');
          }
        } else {
          if (item.itemId !== values.baseDnaId) {
            formikProps.setFieldValue('upgradeDnaId', item.itemId);
            formikProps.setFieldValue('upgradeDnaUrl', item.imageThumbnailUrl);
          } else {
            throw new Error('Base DNA and Upgrade DNA must be different');
          }
        }
      } catch (e) {
        pushNotification({ type: 'error', message: e instanceof Error ? e.message : 'An unknown error occurred' });
      }
    }
  }, [formikProps, isUpdatingBase, values, pushNotification]);

  return (
    <>
      <Box hidden>
        <TextField
          fullWidth
          id={`baseDnaId`}
          name={`baseDnaId`}
          type="text"
          label="Base DNA"
          value={baseDnaId} />
        <TextField
          fullWidth
          id={`upgradeDnaId`}
          name={`upgradeDnaId`}
          type="text"
          label="Upgrade DNA"
          value={upgradeDnaId} />
      </Box>
      <Box pb={5}>
        <Grid container spacing={1} alignItems="center">
          <Grid item xs={6}>
            <Box textAlign={"center"}>
              <InputLabel>Base DNA</InputLabel>
              <Box m={1}>
                {baseDnaUrl &&
                  <Button onClick={() => selectDna(true)}>
                    <img src={baseDnaUrl} width={64} height={64} alt={baseDnaId} />
                  </Button>
                }
                {!baseDnaUrl &&
                  <Box height={64}>
                    {baseDnaId}
                  </Box>
                }
              </Box>
              <Button onClick={() => selectDna(true)} variant="contained" color="primary">
                Select Base DNA
              </Button>
            </Box>
          </Grid>
          <Grid item xs={6}>
            <Box textAlign={"center"}>
              <InputLabel>Upgrade DNA</InputLabel>
              <Box m={1}>
                {upgradeDnaUrl &&
                  <Button onClick={() => selectDna(true)}>
                    <img src={upgradeDnaUrl} width={64} height={64} alt={upgradeDnaId} />
                  </Button>
                }
                {!upgradeDnaUrl &&
                  <Box height={64}>
                    {upgradeDnaId}
                  </Box>
                }
              </Box>
              <Button onClick={() => selectDna(false)} variant="contained" color="primary">
                Select Upgrade DNA
              </Button>
            </Box>
          </Grid>
        </Grid>
      </Box>
      <Box>
        <Grid container spacing={1} alignItems="center">
          <Grid item xs={6}>
            <TextField
              fullWidth
              id={`maxQuantity`}
              name={`maxQuantity`}
              type="number"
              label="Max Quantity"
              error={
                touched?.maxQuantity &&
                Boolean(errors?.maxQuantity)
              }
              className={classes.quanityFormControl}
              onChange={handleChange}
              onBlur={handleBlur}
              value={maxQuantity} />
            <FormHelperText>
              {(touched?.maxQuantity && errors?.maxQuantity)}
            </FormHelperText>
          </Grid>
          <Grid item xs={6}>
            <Box ml={1}>
              <TextField
                id={`remainingQuantity`}
                name={`remainingQuantity`}
                type="number"
                label="Remaining Quantity"
                error={
                  touched?.remainingQuantity &&
                  Boolean(errors?.remainingQuantity)
                }
                className={classes.quanityFormControl}
                onChange={handleChange}
                onBlur={handleBlur}
                value={remainingQuantity} />
              <FormHelperText>
                {(touched?.remainingQuantity && errors?.remainingQuantity)}
              </FormHelperText>
            </Box>
          </Grid>
        </Grid>
      </Box>
      <TextField
        id={`fuseTitle`}
        name={`fuseTitle`}
        type="text"
        label="Fuse Title"
        error={
          touched?.fuseTitle &&
          Boolean(errors?.fuseTitle)
        }
        className={classes.formControl}
        onChange={handleChange}
        onBlur={handleBlur}
        value={fuseTitle || ''} />
      <FormHelperText>
        {(touched?.fuseTitle && errors?.fuseTitle)}
      </FormHelperText>
      <TextField
        id={`fuseDescription`}
        name={`fuseDescription`}
        type="text"
        label="Fuse Description"
        error={
          touched?.fuseDescription &&
          Boolean(errors?.fuseDescription)
        }
        className={classes.formControl}
        onChange={handleChange}
        onBlur={handleBlur}
        value={fuseDescription || ''} />
      <FormHelperText>
        {(touched?.fuseDescription && errors?.fuseDescription)}
      </FormHelperText>
      <Box className={classes.formControl} mt={2}>
        <InputLabel>Fuse Image</InputLabel>
        <ImageUpload
          imageUrl={popupImageUrl}
          onFileChange={handleFileChanged}
        />
      </Box>
      {capstone && capstone.length > 0 ? (
        capstone.map((cap, idx) =>
          <CapstoneForm
            key={idx}
            index={idx}
            {...formikProps}
            values={cap}
            //@ts-ignore
            errors={errors?.capstone && errors?.capstone[idx]}
            //@ts-ignore
            touched={touched?.capstone && touched?.capstone[idx]}
            handleChange={handleChange}
            handleBlur={handleBlur}
          />
        )) : null}
      {capstone.length < 2 &&
        <Button
          type="button"
          variant="contained"
          color="primary"
          className={classes.addButton}
          endIcon={<Add>add</Add>}
          onClick={addCapstone}>
          Capstone
        </Button>}

      {itemSelectDialogOpen && (
        <ItemSelectDialog
          includeDropTables={false}
          showBundles={false}
          showContainers={false}
          showCatalogSelect
          onSelect={items => onItemSelect(items)}
          onClose={() => {
            setItemSelectDialogOpen(false)
          }}
        />
      )}
    </>)
}
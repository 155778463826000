import type { AnyAction } from 'redux';
import type { PutEffect } from 'redux-saga/effects';
import { fork, put } from 'redux-saga/effects';
import { ApiError } from '../services/api';
import { setAppNotification } from './app/actions';
import type { PaginatedRequest, PaginatedResponse } from "../services/model/pagination";

export interface APIResource<T> {
  isLoading: boolean;
  data?: T | null;
}

export interface TablePageState<K, V> {
  isLoading: boolean;
  invalidated: boolean;
  ids: K[];
  data: V[];
  page: number;
  totalCount: number;
}

export function defaultTablePageState<K, V>(): TablePageState<K, V> {
  return { isLoading: false, invalidated: false, ids: [], data: [], page: 0, totalCount: 0 };
}

export function invalidatePageState<K, V>(): (oldTablePageState: TablePageState<K, V>) => TablePageState<K, V> {
  return tps => ({
    ...tps,
    invalidated: true,
  });
}

export function tablePageStateFromResponse<Key extends keyof V, V>(response: PaginatedResponse<V>, request: PaginatedRequest, idField: Key): TablePageState<V[Key], V> {
  return {
    data: response.items,
    ids: response.items.map(i => i[idField]),
    page: request.page,
    totalCount: response.totalCount,
    isLoading: false,
    invalidated: false,
  };
}

export function* sagaHandleApiError(e: any, failureAction?: (error: string) => AnyAction): Generator<PutEffect<AnyAction>, void, string> {
  const message = e instanceof ApiError ? e.message : 'An unknown error occurred';
  yield put(setAppNotification({ type: 'error', message }));
  if (failureAction) {
    yield put(failureAction(message));
  }
  console.error(e);
}

export function* forkSagas(sagas: (() => Generator)[]) {
  for (let i = 0; i < sagas.length; i++) {
    yield fork(sagas[i]);
  }
}

export function arrayReplaceOrPush<T>(arr: T[], item: T, findCb: (value: T, index: number) => boolean): T[] {
  const index = arr.findIndex(findCb);
  if (index > -1) {
    const arrCopy = index > 0 ? arr.slice(0, index) : [];
    arrCopy.push(item);
    return index < arr.length - 1 ? arrCopy.concat(arr.slice(index + 1)) : arrCopy;
  } else {
    const arrCopy = arr.slice();
    arrCopy.push(item);
    return arrCopy;
  }
}

export function arrayRemove(arr: any[], value: any) {
  const index = arr.indexOf(value);
  if (index > -1) {
    const arrCopy = arr.slice();
    arrCopy.splice(index, 1);
    return arrCopy;
  } else {
    return arr;
  }
}

export function arrayToMap<V>(arr: V[], getKey: (value: V) => string): { [key: string]: V; } {
  const map: { [key: string]: V; } = {};
  arr.forEach(v => map[getKey(v)] = v);
  return map;
}

export function removeKey(obj: { [key: string]: any; }, key: string) {
  if (Object.prototype.hasOwnProperty.call(obj, key)) {
    const clone = { ...obj };
    delete clone[key];
    return clone;
  } else {
    return obj;
  }
}
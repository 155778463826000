
export class MythicalAccount {
  id = '';
  email = '';
  emailVerified = false;
  firstName = '';
  lastName = '';
  playerId = '';
  attributes: { [key: string]: string } = {};
  displayName = '';

  constructor(json: any) {
    this.id = json.id;
    this.email = json.email || '';
    if (typeof json.emailVerified === 'boolean') {
      this.emailVerified = json.emailVerified;
    } else if (json.playerId) { // Assume email was verified if a player exists for now
      this.emailVerified = true;
    }
    this.firstName = json.firstName || '';
    this.lastName = json.lastName || '';
    this.playerId = json.playerId || '';
    this.displayName = json.displayName || '';
    this.attributes = json.attributes || {};
  }
}
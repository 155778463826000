import {Checkbox, Theme,
  Box,
  Button,
  CircularProgress,
  createStyles,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core';
import {BuiltinConditionParameter, BuiltinUnlockConditionType} from '../../../services/model/brawl-pin';
import {useCallback, useEffect, useState} from 'react';
import _ from 'lodash';
import { Column } from 'material-table';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    button: {
      marginLeft: 8,
    },
    form: {
      display: 'flex',
      flexDirection: 'column',
      margin: 'auto',
      width: 'fit-content',
      marginBottom: 8,
      minWidth: 600,
    },
  }),
);

const columns: Column<BuiltinConditionParameter>[] = [
  {
    title: 'Name',
    field: 'name',
    defaultSort: 'asc',
  },
  {
    title: 'Type',
    field: 'type',
  },
];

export interface AddBuiltinParametersDialogProps {
  builtinUnlockConditionTypes: BuiltinUnlockConditionType[],
  existingParameters: string[],
  unlockType: string,
  addBuiltinParams: (params: BuiltinConditionParameter[]) => void,
  onClose: () => void,
}

interface ParameterListItem {
  param: BuiltinConditionParameter,
  isSelected: boolean,
}

export const AddBuiltinParametersDialog = ({ builtinUnlockConditionTypes, existingParameters, unlockType, addBuiltinParams, onClose }: AddBuiltinParametersDialogProps) => {
  const classes = useStyles();

  const [listItems, setListItems] = useState<ParameterListItem[]>();

  useEffect(() => {
    const unlockCondition = builtinUnlockConditionTypes.find(uc => uc.name === unlockType);
    if (unlockCondition && unlockCondition.parameters) {
      setListItems(unlockCondition.parameters.filter(p => !existingParameters.includes(p.name)).map<ParameterListItem>(p => { return { param: p, isSelected: true }; }));
    }
  }, [builtinUnlockConditionTypes, existingParameters, unlockType]);

  const onAddParameters = useCallback(() => {
    if (!listItems)
      return;

    const selectedParameters = listItems.filter(li => li.isSelected).map(pli => pli.param);
    addBuiltinParams(selectedParameters);
    onClose();
  }, [listItems, addBuiltinParams, onClose]);

  const onItemSelection = useCallback((item: ParameterListItem) => {
    if (!listItems)
      return;

    const newListItems = _.cloneDeep(listItems);
    const updatedItem = newListItems.find(li => li.param.name == item.param.name);
    if (updatedItem) {
      updatedItem.isSelected = !updatedItem.isSelected;
      setListItems(newListItems);
    }
  }, [listItems]);

  if (!listItems) {
    return <Box textAlign="center">
      <CircularProgress />
    </Box>;
  }

  return (
    <>
      <Dialog
        open={true}
        fullWidth
        onClose={onClose}
        aria-labelledby="add-dialog-title"
      >
        <DialogTitle id="add-dialog-title">Add Built-in Parameters</DialogTitle>
        <DialogContent className={classes.form}>
          <TableContainer style={{ maxHeight: 440 }}>
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCell />
                  <TableCell>Name</TableCell>
                  <TableCell>Type</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {listItems.map((item, index) => (
                  <TableRow key={`param-${index}`}>
                    <TableCell>
                      <Checkbox
                        checked={item.isSelected}
                        onChange={e => onItemSelection(item)} />
                    </TableCell>
                    <TableCell>
                      {item.param.name}
                    </TableCell>
                    <TableCell>
                      {item.param.type}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </DialogContent>
        <DialogActions>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            onClick={onAddParameters}
          >
            Add
          </Button>
          <Button onClick={onClose} color="primary">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

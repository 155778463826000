import { Box } from '@material-ui/core';
import type { MigrationDiffCommonProps } from '../../migrations';
import { DiffsPanels } from '../../components/diff/DiffsPanels';

export const BlankoDnaDiffs = (props: MigrationDiffCommonProps) => {

  const diffs = props.migrationData.blankoDnas;

  if (diffs) {
    return (
      <DiffsPanels
        diffs={diffs}
        columns={[
          {
            title: '',
            field: 'imageThumbnailUrl',
            // eslint-disable-next-line react/display-name
            render: row => row.imageThumbnailUrl ? (<img src={row.imageThumbnailUrl} width={64} height={64} />) : (<Box width={64} height={64} />),
          },
          { title: 'ID', field: 'diffId', defaultSort: 'asc' },
          {
            title: 'Blanko name',
            field: 'blankoName',
            // eslint-disable-next-line react/display-name
            render: row => row.existsInTarget === true ? (<>{row.blankoName}</>) : (<div>{row.blankoName}<br /><i><span style={{ color: '#ffcc00' }}>NOTE: Blanko does not exist in target catalog and will be skipped.</span></i></div>)
          }
        ]}
        selectionMap={props.selections.dna || {}}
        onSelectionChange={map => props.onSelectionsChange({ ...props.selections, dna: map })}
        {...props}
      />
    )
  }

  return null;
}

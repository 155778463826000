import { Box, Checkbox, FormControlLabel, Grid, IconButton, TextField } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import { Component } from 'react';
import { Reward, RewardPathTierDto } from '../../services/player-challenges/season-reward-path-tiers';
import { UserService } from '../../services/user';
import RewardListEditor from '../../shared/components/RewardListEditor';


interface ComopnentProps {
  rewardPathTier: RewardPathTierDto,
  showAddReward?: boolean,
  editMode?: boolean,
  isLast?: boolean, // Will be used later. The last tier has some special rules
  onRewardPathTierDelete?: (rewardPathTier: RewardPathTierDto) => void,
  onRewardPathTierEdit?: (rewardPathTier: RewardPathTierDto) => void
}

interface State {
  rewardPathTier: RewardPathTierDto
}

type Props = ComopnentProps;

class RewardPathTierControl extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    const tier = props.rewardPathTier || new RewardPathTierDto();
    this.state = {
      rewardPathTier: tier
    }
  }

  onRewardAdd() {
    const rewardPathTier = this.state.rewardPathTier;
    this.state.rewardPathTier.rewards.push(new Reward());
    this.setState({ rewardPathTier });
  }

  onRewardDelete(reward: Reward) {
    const rewardPathTier = this.state.rewardPathTier;
    const index = rewardPathTier.rewards.indexOf(reward);
    if (index >= 0) {
      rewardPathTier.rewards.splice(index, 1);
      this.setState({ rewardPathTier });
    }
  }

  onPartyPassPlusRewardAdd() {
    const rewardPathTier = this.state.rewardPathTier;
    this.state.rewardPathTier.rewardsPartyPassPlus.push(new Reward());
    this.setState({ rewardPathTier });
  }

  onPartyPassPlusRewardDelete(reward: Reward) {
    const rewardPathTier = this.state.rewardPathTier;
    const index = rewardPathTier.rewardsPartyPassPlus.indexOf(reward);
    if (index >= 0) {
      rewardPathTier.rewardsPartyPassPlus.splice(index, 1);
      this.setState({ rewardPathTier });
    }
  }

  render() {
    const readOnly: boolean = !this.props.editMode || !UserService.canUpdate('seasons');

    const tier = !!this.props.editMode ? this.state.rewardPathTier : this.props.rewardPathTier;

    return (<>
      <Grid container spacing={1}>
        <Grid item xs={5}>
          <TextField
            label="Tier Name"
            fullWidth
            InputProps={{ readOnly: readOnly }}
            onChange={(e) => {
              tier.tierName = e.target.value;
              this.setState({ rewardPathTier: tier });
            }}
            value={tier.tierName}
          />
        </Grid>
        <Grid item xs={5}>
          <TextField
            label="Season Tokens To Next Level"
            fullWidth
            InputProps={{ readOnly: readOnly }}
            onChange={(e) => {
              tier.seasonTokensToNextLevel = e.target.value;
              this.setState({ rewardPathTier: tier });
            }}
            value={tier.seasonTokensToNextLevel}
          />
        </Grid>
        <Grid item xs={1}>
          <TextField
            label="Order"
            fullWidth
            InputProps={{ readOnly: readOnly }}
            onChange={(e) => {
              tier.tierOrder = e.target.value;
              this.setState({ rewardPathTier: tier });
            }}
            value={tier.tierOrder}
          />
        </Grid>
        {this.props.onRewardPathTierEdit || this.props.onRewardPathTierDelete ?
          <Grid item xs={1}>
            <Grid container direction="column" alignItems="flex-end">
              {!this.props.editMode && UserService.canUpdate('seasons') && this.props.onRewardPathTierEdit ?
                <IconButton aria-label="edit" size="small" onClick={() => this.props.onRewardPathTierEdit?.(tier)}>
                  <EditIcon />
                </IconButton>
                : null}
              {!this.props.editMode && UserService.canDelete('seasons') && this.props.onRewardPathTierDelete ?
                <IconButton aria-label="delete" size="small" onClick={() => this.props.onRewardPathTierDelete?.(tier)}>
                  <DeleteIcon />
                </IconButton>
                : null}
            </Grid>
          </Grid>
          : null}
      </Grid>

      <FormControlLabel
        control={
          <Checkbox
            color="primary"
            checked={tier.isRepeatableTier}
            onChange={e => {
              if (!readOnly && this.props.editMode) {
                tier.isRepeatableTier = e.target.checked;
                this.setState({ rewardPathTier: tier });
              }
            }}
          />
        }
        label="Repeatable"
      />

      <Box pt={1}>
        <Grid container spacing={1}>
          {/* <Grid item xs={12} lg={this.props.isLast ? 12 : 6}> */}
          <Grid item xs={12} lg={6}>
            <RewardListEditor
              title="Rewards"
              editMode={this.props.editMode}
              showAddReward={this.props.showAddReward}
              rewards={tier.rewards}
              onRewardAdd={() => this.onRewardAdd()}
              onRewardDelete={r => this.onRewardDelete(r)}
              requiredUpdateTarget="seasons"
            />
          </Grid>
          {/* {!this.props.isLast && ( */}
          <Grid item xs={12} lg={6}>
            <RewardListEditor
              title="Party Pass+ Rewards"
              editMode={this.props.editMode}
              showAddReward={this.props.showAddReward}
              rewards={tier.rewardsPartyPassPlus}
              onRewardAdd={() => this.onPartyPassPlusRewardAdd()}
              onRewardDelete={r => this.onPartyPassPlusRewardDelete(r)}
              requiredUpdateTarget="seasons"
            />
          </Grid>
          {/* )} */}
        </Grid>
      </Box>
    </>);
  }
}

export default RewardPathTierControl;

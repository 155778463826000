import { Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton, InputAdornment, TextField, Tooltip } from '@material-ui/core';
import FolderIcon from '@material-ui/icons/Folder';
import MenuIcon from '@material-ui/icons/Menu';
import { PlayerSelectList } from '../../../components/PlayerSelectList';
import type { BlockDataFile} from '../../../services/blocks';
import { BlocksService } from '../../../services/blocks';
import type { Player } from '../../players/players';
import { useEffect, useCallback, useState, createRef } from 'react';
import { pushAppNotification } from "../../../shared/hooks/useAppNotification";

interface Props {
  player?: Player;
  onClose: () => void;
};

export const ImportBlockDialog = (props: Props) => {
  const inputRef = createRef<HTMLInputElement>();
  const [open, setOpen] = useState(true);
  const [name, setName] = useState('');
  const [nameError, setNameError] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [player, setPlayer] = useState<Player>();
  const [selectingPlayer, setSelectingPlayer] = useState(false);
  const [selectedFile, setSelectedFile] = useState<File>();
  const [blockJson, setBlockJson] = useState<any>();
  const [blockDataFile, setBlockDataFile] = useState<BlockDataFile>();
  const [blockFileError, setBlockFileError] = useState('');

  const onFileChange = () => {
    if (inputRef.current && inputRef.current.files && inputRef.current.files.length > 0) {
      const file = inputRef.current.files[0];
      setSelectedFile(file);

      const fileReader = new FileReader();
      fileReader.onload = () => {
        if (fileReader.result instanceof ArrayBuffer) {
          parseBlockFile(fileReader.result).catch(e => {
            setBlockFileError(e.message);
          });
        }
      }
      fileReader.readAsArrayBuffer(file);
    }
  }

  const parseBlockFile = async (buffer: ArrayBuffer) => {
    const blockDataFile: BlockDataFile = { format: 'json', data: null };
    let blockJson: any;

    let offset = 0;
    const header = new Uint32Array(buffer.slice(offset, offset + 8));
    if (header[0] === 42) { // Magic number. Binary format.
      offset += 8;
      try {
        blockJson = JSON.parse(await (new Blob([buffer.slice(offset, offset + header[1])])).text());
      } catch (e) {
        console.error('Failed to parse block JSON', e);
        throw new Error('Failed to parse block JSON');
      }
      offset += header[1];
      const dataHeader = new Uint32Array(buffer.slice(offset, offset + 8));
      offset += 8;
      if (dataHeader[0] > 0) {
        if (dataHeader[1] === 0) {
          blockDataFile.format = 'json';
          blockDataFile.data = await (new Blob([buffer.slice(offset)])).text();
        } else if (dataHeader[1] === 1) {
          blockDataFile.format = 'binary';
          blockDataFile.data = buffer.slice(offset);
        } else {
          console.error(`Unexpected binary block data file type: ${dataHeader[1]}`);
          throw new Error('Unexpected block file format');
        }
      }
    } else { // Text format, for backwards compatibility
      try {
        blockJson = JSON.parse(await (new Blob([buffer]).text()));
        if (blockJson.blockFileData) {
          blockDataFile.data = blockJson.blockFileData;
          delete blockJson.blockFileData;
        }
      } catch (e) {
        console.error('Failed to parse block JSON', e);
        throw new Error('Failed to parse block JSON');
      }
    }

    setBlockJson(blockJson);
    setBlockDataFile(blockDataFile);
    setBlockFileError('');
    if (blockJson && blockJson.name) {
      onNameChange(blockJson.name);
    }
  }

  const onNameChange = (name: string) => {
    let nameError = '';
    if (!name.trim()) {
      nameError = 'Cannot be blank';
    } else if (!/^[a-zA-Z0-9 ]+$/.test(name)) {
      nameError = 'Block name can only contain letters, spaces, and digits'
    }
    setName(name);
    setNameError(nameError);
  };

  const canSubmit = () => {
    return !nameError && blockJson && !blockFileError && !isSubmitting && (props.player || player);
  };

  const createBlock = useCallback(async () => {
    try {
      const checkPlayer = props.player || player;

      if (!checkPlayer || !blockJson || !blockDataFile || !name) {
        return;
      }
      setIsSubmitting(true);

      blockJson.name = name;
      blockJson.ownerId = checkPlayer.externalId;
      blockJson.creatorId = checkPlayer.externalId;

      const block = await BlocksService.createBlock(blockJson);

      if (blockDataFile && blockDataFile.data) {
        try {
          await BlocksService.uploadBlockFile(block.id, blockDataFile);
        } catch (e) {
          await BlocksService.deleteBlock(block.id);
          throw e;
        }
      }

      block.creatorName = checkPlayer.displayName;
      pushAppNotification({ type: 'success', message: 'Block imported successful' });
      setOpen(false);
    } catch (e) {
      pushAppNotification({ type: 'error', message: e.message });
    }
  }, [blockDataFile, blockJson, player, props.player, name]);

  return (<>
    <Dialog
      open={open}
      onClose={() => setOpen(false)}
      onExited={props.onClose}
      fullWidth
      maxWidth="lg"
    >
      {selectingPlayer ? (
        <DialogContent style={{ padding: 0 }}>
          <PlayerSelectList
            onSelect={player => {
              setPlayer(player);
              setSelectingPlayer(false);
            }}
          />
        </DialogContent>
      ) : (<>
        <DialogTitle>Import Block</DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            {!props.player && (
              <Grid item xs={12}>
                <TextField
                  type="text"
                  label="Block owner"
                  fullWidth
                  value={player ? player.getDisplayName() : ''}
                  InputProps={{
                    readOnly: true,
                    endAdornment: (
                      <InputAdornment position="end">
                        <Tooltip title="Select player...">
                          <IconButton onClick={() => setSelectingPlayer(true)}>
                            <MenuIcon />
                          </IconButton>
                        </Tooltip>
                      </InputAdornment>
                    )
                  }}
                />
              </Grid>
            )}
            <Grid item xs={12}>
              <input
                type="file"
                style={{ display: 'none' }}
                ref={inputRef}
                onChange={onFileChange}
              />
              <TextField
                label="Block file"
                fullWidth
                InputProps={{
                  readOnly: true,
                  endAdornment: (
                    <InputAdornment position="end">
                      <Tooltip title="Select file...">
                        <IconButton onClick={() => inputRef.current && inputRef.current.click()}>
                          <FolderIcon />
                        </IconButton>
                      </Tooltip>
                    </InputAdornment>
                  )
                }}
                value={selectedFile ? selectedFile.name : ''}
                error={!!blockFileError}
                helperText={blockFileError}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Name"
                fullWidth
                onChange={event => onNameChange(event.target.value)}
                value={name}
                error={!!nameError}
                helperText={nameError}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            color="primary"
            variant="contained"
            disabled={!canSubmit()}
            onClick={createBlock}
          >
            {isSubmitting ? (
              <CircularProgress size={25} />
            ) : 'Import'}
          </Button>
        </DialogActions>
      </>)}
    </Dialog>
  </>);
}
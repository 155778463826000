import {api, ApiError} from "../api";

export class GameQueue {
  queueName = '';
  maxSlots = 0;
  enabled = false;
  queueSecondsToLive = 0;
  slotSecondsToLive = 0;
  activePlayerCountUrl = '';
  activePlayerCountCacheSecondsToLive = 0;

  constructor(json?: any) {
    this.queueName = json?.queueName ?? '';
    this.maxSlots = json?.maxSlots ?? 0;
    this.enabled = json?.enabled ?? false;
    this.queueSecondsToLive = json?.queueSecondsToLive ?? 0;
    this.slotSecondsToLive = json?.slotSecondsToLive ?? 0;
    this.activePlayerCountUrl = json?.activePlayerCountUrl ?? '';
    this.activePlayerCountCacheSecondsToLive = json?.activePlayerCountCacheSecondsToLive ?? 0;
  }
}

const baseEndPoint = "/admin/launcher/game-queue";

export class GameQueueService {

  static async getGameQueue(queueName: string): Promise<GameQueue> {
    return api.get({url: `${baseEndPoint}/${queueName}`})
      .then(gameQueue => new GameQueue(gameQueue))
      .catch((e: ApiError) => {
        e.message = `Failed to retrieve game queue. ${e.message}`;
        throw e;
      });
  }

  static async saveGameQueue(queueName: string, gameQueue: GameQueue): Promise<null> {
    return api.put({url: `${baseEndPoint}/${queueName}`, body: gameQueue})
      .then()
      .catch((e: ApiError) => {
        e.message = `Failed to update game queue. ${e.message}`;
        throw e;
      });
  }

  static async enableGameQueue(queueName: string): Promise<null> {
    return api.put({url: `${baseEndPoint}/${queueName}/activate`})
      .then()
      .catch((e: ApiError) => {
        e.message = `Failed to enable game queue. ${e.message}`;
        throw e;
      });
  }

  static async disableGameQueue(queueName: string): Promise<null> {
    return api.put({url: `${baseEndPoint}/${queueName}/deactivate`})
      .then()
      .catch((e: ApiError) => {
        e.message = `Failed to disable game queue. ${e.message}`;
        throw e;
      });
  }

  static async getVips(queueName: string): Promise<string[]> {
    return api.get({url: `${baseEndPoint}/${queueName}/vip`})
      .catch((e: ApiError) => {
        e.message = `Failed to retrieve VIP's. ${e.message}`;
        throw e;
      });
  }

  static async addVips(queueName: string, vips:string[]): Promise<null> {
    return api.post({url: `${baseEndPoint}/${queueName}/vip`, body: vips})
      .catch((e: ApiError) => {
        e.message = `Failed to add VIP's. ${e.message}`;
        throw e;
      });
  }

  static async deleteVips(queueName: string, vips:string[]): Promise<null> {
    return api.delete({url: `${baseEndPoint}/${queueName}/vip`, body: vips})
      .catch((e: ApiError) => {
        e.message = `Failed to delete VIP's. ${e.message}`;
        throw e;
      });
  }
}

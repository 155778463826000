import Tab from '@material-ui/core/Tab';
import * as React from 'react';
import { Link } from 'react-router-dom';

type Props = React.ComponentProps<typeof Tab> & React.ComponentProps<typeof Link>;

export default class LinkTab extends React.Component<Props> {
  render() {
    return (
      <Tab
        component={Link}
        {...this.props}
      />
    );
  }
}
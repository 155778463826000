import { api } from "../../services/api";
import { SeasonAutoClaimsStatus } from "./seasons";

export const queuePriorSeasonAutoClaims = async () => {
  return api.post({url: `/players/seasons/queue-prior-season-auto-claims`});
};

export const getPriorSeasonAutoClaimsStatus = async () => {
  return api.get<SeasonAutoClaimsStatus>({url: `/players/seasons/prior-season-auto-claims-status`});
};

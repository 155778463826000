import { Component } from 'react';
import {Box, IconButton, TextField} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';

import {UserService} from '../../../services/user';
import { PotentialReward } from '../live-events-types';

interface ComopnentProps {
  potentialReward: PotentialReward,
  readOnly?: boolean,
  onDelete: (potentialReward: PotentialReward) => void
}

interface State {
  potentialReward: PotentialReward,
  itemsDialogOpen: boolean
}

type Props = ComopnentProps;

class PotentialRewardControl extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      potentialReward: props.potentialReward,
      itemsDialogOpen: false
    }
  }

  render() {
    const readOnly: boolean = !!this.props.readOnly || !UserService.canUpdate('liveEvents');

    const potentialReward = this.props.potentialReward;

    return (<>
      <Box display="flex">
        <Box flexGrow="1" pr={2}>
          <TextField
            label="Label"
            fullWidth
            InputProps={{readOnly: readOnly}}
            onChange={(e) => {
              potentialReward.label = e.target.value;
              this.setState({potentialReward: potentialReward});
            }}
            value={potentialReward.label}
          />
        </Box>
        <Box flexGrow="1">
          <TextField
            label="Image Path"
            fullWidth
            InputProps={{readOnly: readOnly}}
            onChange={(e) => {
              potentialReward.imagePath = e.target.value;
              this.setState({potentialReward: potentialReward});
            }}
            value={potentialReward.imagePath}
          />
        </Box>
        <Box width={25} />
        {!this.props.readOnly && UserService.canDelete('liveEvents') ?
          <Box width={30} display="flex" flexDirection="column" justifyContent="center">
            <IconButton aria-label="delete" size="small" onClick={() => this.props.onDelete(this.props.potentialReward)}>
              <DeleteIcon/>
            </IconButton>
          </Box>
          : null}
      </Box>
    </>);
  }
}

export default PotentialRewardControl;

import { BlankoAssetDiff } from '../../../../pages/CatalogMigration/types';
import { CatalogMigrationService } from '../../../../services/catalog-migration';
import type { MigrationModule } from '../../migrations';
import { buildDiffData, buildMigrationActions } from '../../migrations';
import { BlankoAssetsDiffs } from './BlankoAssetsDiffs';
import { MigrationType } from "../migration-type";

export const blankoAssetMigration: MigrationModule<BlankoAssetDiff> = {
  id: MigrationType.blankoAsset,
  displayName: 'Global Assets',
  diffComponent: BlankoAssetsDiffs,
  crossEnvOnly: true,

  loadData: async (sourceData, targetData, migrationData) => {
    sourceData.blankoAssets = await CatalogMigrationService.getBlankoAssets(sourceData.env, sourceData.catalogName);
    targetData.blankoAssets = await CatalogMigrationService.getBlankoAssets(targetData.env, targetData.catalogName);

    migrationData.blankoAssets = buildDiffData(
      sourceData.blankoAssets.map(asset => new BlankoAssetDiff(asset)),
      targetData.blankoAssets.map(asset => new BlankoAssetDiff(asset))
    );

    return migrationData.blankoAssets;
  },

  runMigration: async (props, setStatus) => {
    const actions = buildMigrationActions(props.migrationData.blankoAssets, props.selections.blankoAsset);
    if (actions.add.length + actions.update.length + actions.remove.length < 1) {
      return;
    }

    const sourceAssets = props.sourceData.blankoAssets;
    if (!sourceAssets) {
      throw new Error('Blanko assets not loaded.');
    }

    const assetsMap: { [key: string]: any } = {};
    sourceAssets.forEach(asset => assetsMap[`${asset.name},${asset.platform},${asset.minPlatformVersion}`] = asset);

    const assets = actions.add.concat(actions.update).map(id => assetsMap[id]);
    const batches: any[][] = [];
    for (let i = 0; i < assets.length; i += 500) {
      batches.push(assets.slice(i, i + 500));
    }

    let progress = 0;
    for (const batch of batches) {
      setStatus('Migrating blanko assets', progress);
      await CatalogMigrationService.migrateBlankoAssets(props.targetData.env, props.targetData.catalogName, batch);
      progress += 100.0 / batches.length;
    }
  }
}

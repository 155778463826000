import { Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Grid } from '@material-ui/core';
import { Component } from 'react';
import type { ConnectedProps } from 'react-redux';
import { connect } from 'react-redux';
import type { JsonFileUploadValue } from '../../components/JsonFileUpload';
import JsonFileUpload from '../../components/JsonFileUpload';
import type { RootState } from '../../redux/reducers';
import { updateTitleAsync } from '../../redux/title-data/actions';
import { Title } from '../../services/title-data';

const mapStateToProps = (state: RootState) => ({
  title: state.title.title
});
const mapDispatch = {
  requestUpdateTitle: updateTitleAsync.request
};
const connector = connect(mapStateToProps, mapDispatch);

type Props = ConnectedProps<typeof connector> & {
  onClose: () => void;
};

interface State {
  open: boolean;
  titleData: JsonFileUploadValue;
  internalData: JsonFileUploadValue;
}

class ImportCatalogDialog extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      open: true,
      titleData: {},
      internalData: {}
    };
  }

  componentDidUpdate(prevProps: Props) {
    if (prevProps.title.data !== this.props.title.data) {
      this.setState({ open: false });
    }
  }

  canSubmit = () => {
    return !this.state.titleData.error && !this.state.internalData.error;
  };

  onSubmit = () => {
    const title = this.props.title.data;

    const newTitle: Title = new Title({
      titleData: this.state.titleData.json || (title ? title.titleData : {}),
      internalData: this.state.internalData.json || (title ? title.internalData : {}),
      maintenanceData: title ? title.maintenanceData : {}
    });

    this.props.requestUpdateTitle(newTitle);
  };

  render() {
    return (
      <Dialog
        open={this.state.open}
        onClose={() => this.setState({ open: false })}
        onExited={this.props.onClose}
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle>Import Title Data</DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <JsonFileUpload label="Title data (Client data)" value={this.state.titleData} onChange={titleData => this.setState({ titleData })} />
            </Grid>
            <Grid item xs={12}>
              <JsonFileUpload label="Internal data (Server data)" value={this.state.internalData} onChange={internalData => this.setState({ internalData })} />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button color="primary" variant="contained" disabled={!this.canSubmit()} onClick={this.onSubmit}>
            {this.props.title.isUpdating ? (
              <CircularProgress size={25} />
            ) : 'Import'}
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

export default connector(ImportCatalogDialog);
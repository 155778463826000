import { combineReducers } from 'redux';
import { createReducer } from 'typesafe-actions';
import { setCatalogName } from './actions';
import itemsReducer from './items/reducer';
import dropTablesReducer from './drop-tables/reducer';
import storesReducer from './stores/reducer';

const nameReducer = createReducer<string | null>(null)
  .handleAction(setCatalogName, (state, action) => action.payload || null);

export default combineReducers({
  name: nameReducer,
  items: itemsReducer,
  dropTables: dropTablesReducer,
  stores: storesReducer
});
import { Box, Button, Card, CardContent, CardHeader, Grid } from "@material-ui/core";
import { Field, Formik } from "formik";
import { useCallback, useEffect, useMemo, useState } from "react"
import { TitleService } from "../../../services/title-data";
import * as yup from 'yup';
import { CheckboxWithLabel, TextField } from "formik-material-ui";
import { pushAppNotification } from "../../../shared/hooks/useAppNotification";

const settingsKey = "BlockRecommendationSettings";

const formSchema = yup.object({
  averageRatingThreshold: yup.number().required().min(0).max(5),
  numberOfPlaysThreshold: yup.number().required().min(0),
  churnRatingThreshold: yup.number().required().min(0).max(100),
  requireFeatured: yup.bool()
});

interface BlockRecommendationSettings {
  averageRatingThreshold: number;
  numberOfPlaysThreshold: number;
  churnRatingThreshold: number;
  requireFeatured: boolean;
}

export const RecommendationSettings = () => {
  const [recSettings, setRecSettings] = useState<BlockRecommendationSettings | null>(null);

  const getTitleData = useCallback(async () => {
    const titleData = await TitleService.getTitle()
    if (titleData.titleData[settingsKey] !== undefined) {
      const data = JSON.parse(titleData.titleData[settingsKey]);
      setRecSettings(data);
    }
  }, []);

  useEffect(() => {
    if (recSettings === null) {
      getTitleData();
    }
  }, [recSettings, getTitleData]);

  const formValues = useMemo((): BlockRecommendationSettings => {
    return {
      averageRatingThreshold: recSettings?.averageRatingThreshold || 0,
      numberOfPlaysThreshold: recSettings?.numberOfPlaysThreshold || 0,
      churnRatingThreshold: recSettings?.churnRatingThreshold || 0,
      requireFeatured: recSettings?.requireFeatured || false
    }
  }, [recSettings]);

  const onSubmit = useCallback(async (values: BlockRecommendationSettings) => {
    const settings: { [key: string]: string; } = {};
    settings[settingsKey] = JSON.stringify(values);
    try {
      await TitleService.updateClientTitle(settings);
      pushAppNotification({ type: 'success', message: 'Recommendation Settings updated' });
    } catch (e) {
      pushAppNotification({ type: 'error', message: 'Recommendation Settings failed to update' });
    }
    
  }, []);

  return (<>
    <Box mb={2}>
      <Grid container spacing={2}>
        <Grid item xs={12} md={6} xl={4}>
          <Card>
            <CardHeader title="Block Recommendation Settings" />
            <CardContent>
              <Formik<BlockRecommendationSettings>
                initialValues={formValues}
                validationSchema={formSchema}
                enableReinitialize
                onSubmit={onSubmit}
              >
                {form => (<>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <Field
                        component={TextField}
                        name="averageRatingThreshold"
                        type="number"
                        label="Average Rating Threshold(0.0 - 5.0)"
                        fullWidth
                        inputProps={{
                          step: .1,
                          max: 5,
                          min: 0
                        }}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Field
                        component={TextField}
                        name="numberOfPlaysThreshold"
                        type="number"
                        label="Number Of Plays Threshold"
                        fullWidth
                        inputProps={{
                          step: 1,
                          min: 0
                        }}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Field
                        component={TextField}
                        name="churnRatingThreshold"
                        type="number"
                        label="Churn Rating Threshold (0-100%)"
                        fullWidth
                        inputProps={{
                          step: 1,
                          max: 100,
                          min: 0
                        }}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Field
                        component={CheckboxWithLabel}
                        type="checkbox"
                        name="requireFeatured"
                        Label={{ label: 'Require Featured Blocks Only' }}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Button color="primary" variant="contained" onClick={form.submitForm}>Save</Button>
                    </Grid>
                  </Grid>

                </>)}
              </Formik>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Box>
  </>);
}
import { Link } from '@material-ui/core';
import type { Column } from 'material-table';
import { useRef } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import MaterialTable from '../../../components/MaterialTable';
import type { Player } from '../../players/players';
import { PlayersService } from "../../players/players";
import { MaterialTableCallback } from "../../../utils/MaterialTableCallback";

const columns: Column<Player>[] = [
  {
    title: 'Name',
    field: 'displayName',
    render: player => (
      <Link component={RouterLink} to={`/players/${player.externalId}`}>
        {player.displayName || player.email || player.externalId}
      </Link>
    ),
  },
  {title: 'ID', field: 'externalId', sorting: false},
  {title: 'Mythical ID', field: 'mythicalId', sorting: false},
  {
    title: 'Last logged in',
    field: 'lastLogin',
    defaultSort: 'desc',
    render: player => <>{player.lastLogin ? player.lastLogin.toLocaleString() : '-'}</>
  },
  {title: 'VTD', sorting: false, render: function render() { return <>-</>; }}
];

export const Players = () => {

  const tableRef = useRef<MaterialTable<Player>>();

  return (
    <MaterialTableCallback
      title="Players"
      storageId="players"
      columns={columns}
      data={PlayersService.getPlayers}
      options={{
        pageSize: 10,
        columnsButton: true,
        debounceInterval: 500,
        thirdSortClick: false
      }}
      localization={{
        body: {
          // We'd prefer to show a separate message when search value is empty, but
          // the MaterialTable onSearchChange callback is broken and is never called.
          emptyDataSourceMessage: "No records to display (Search Criteria Required)"
        }
      }}
      tableRef={tableRef}
      filters={[]}
    />
  );
};

import { Box, Button, CircularProgress, Grid } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import { Component } from 'react';
import type { ConnectedProps } from 'react-redux';
import { connect } from 'react-redux';
import type { RouteComponentProps } from 'react-router';
import type { LinkTabConfig} from '../../components/LinkTabs';
import { LinkTabs } from '../../components/LinkTabs';
import type { RootState } from '../../redux/reducers';
import { getTitleAsync } from '../../redux/title-data/actions';
import ImportTitleDataDialog from './ImportTitleDataDialog';
import InternalData from './InternalData';
import MaintenanceData from './MaintenanceData';
import TitleData from './TitleData';
import TitleFiles from './TitleFiles';
import { UserService } from '../../services/user';
import DailyMessage from "./DailyMessagesGrid";

const mapStateToProps = (state: RootState) => ({
  title: state.title.title
});

const mapDispatch = {
  requestGetTitle: getTitleAsync.request
};

const connector = connect(mapStateToProps, mapDispatch);

type Props = RouteComponentProps & ConnectedProps<typeof connector>;

interface State {
  importDialogOpen: boolean;
}

class TitlePage extends Component<Props, State> {
  private tabs: LinkTabConfig[] = [
    { url: '', label: 'Client Data', render: () => <TitleData title={this.props.title.data} /> },
    { url: '/internal', label: 'Server Data', render: () => <InternalData title={this.props.title.data} /> },
    { url: '/files', label: 'Files', component: TitleFiles },
    { url: '/maintenance', label: 'Maintenance', render: () => this.props.title.data ? <MaintenanceData title={this.props.title.data} /> : null },
    { url: '/motd', label: 'Message Of The Day', component: DailyMessage },
  ];

  constructor(props: Props) {
    super(props);
    this.state = {
      importDialogOpen: false
    };
  }

  componentDidMount() {
    this.props.requestGetTitle();
  }

  render() {
    const title = this.props.title;
    if (!title.isLoading && title.data) {
      return (<>

        <Box mb={2}>
          <Grid container spacing={2} alignItems="center">
            <Grid item xs>
              <Typography variant="h6">
                Title Data
              </Typography>
            </Grid>
            {UserService.canUpdate('title') && (
              <Grid item>
                <Button variant="contained" color="primary" onClick={() => this.setState({ importDialogOpen: true })}>Import Title Data</Button>
              </Grid>
            )}
          </Grid>
        </Box>

        <LinkTabs tabs={this.tabs} {...this.props} />

        {this.state.importDialogOpen && <ImportTitleDataDialog onClose={() => this.setState({ importDialogOpen: false })} />}
      </>);
    } else {
      return (
        <Box textAlign="center">
          <CircularProgress />
        </Box>
      );
    }
  }
}

export default connector(TitlePage);

import { Component } from 'react';
import {Box, Button, Checkbox, FormControl, FormControlLabel, IconButton, InputLabel, MenuItem, Select, TextField} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import type {Reward} from '../../services/player-challenges/season-reward-path-tiers';
import {UserService} from '../../services/user';
import ItemSelectDialog from '../../pages/ItemDefinition/ItemSelectDialog';
import {ItemDefinition} from '../../services/item-definitions';
import {DropTable} from '../../services/drop-tables';
import { RewardType } from "../../services/player-challenges/season-reward-path-tiers";

interface ComopnentProps {
  reward: Reward,
  readOnly?: boolean,
  onRewardDelete: (reward: Reward) => void
}

interface State {
  reward: Reward,
  itemsDialogOpen: boolean
}

type Props = ComopnentProps;

class RewardControl extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      reward: props.reward,
      itemsDialogOpen: false
    }
  }

  onItemSelected = (items: (ItemDefinition | DropTable)[]): void => {
    if (!items || items.length === 0) {
      return;
    }
    if (items.length > 1) {
      throw new Error('Multi-Selection not allowed.');
    }

    const item = items[0];
    if (item instanceof DropTable) {
      throw new Error('DropTable not supported.');
    }

    const reward = this.state.reward;
    if (item instanceof ItemDefinition) {
      reward.param['catalogName'] = item.catalogName;
      reward.param['itemId'] = item.itemId;
    }

    this.setState({reward: reward});
  }

  render() {
    const readOnly: boolean = !!this.props.readOnly || !UserService.canUpdate('seasons');

    const reward = this.props.reward;

    return (<>
      <Box display="flex">
        <Box width={200} pr={2}>
          <FormControl fullWidth>
            <InputLabel id="reward-type">Reward Type</InputLabel>
            <Select labelId="reward-type" readOnly={readOnly} fullWidth displayEmpty value={reward.type} onChange={(e) => {
              let value = "";
              if (typeof e.target.value === 'string') value = e.target.value;
              if (reward.type !== value) {
                reward.type = value;
                reward.param = {}
              }
              this.setState({reward: reward});
            }}
            >
              {Object.values(RewardType).map(type => (<MenuItem key={type} value={type}>{type}</MenuItem>))}
            </Select>
          </FormControl>
        </Box>
        {reward.type === RewardType.GrantCurrency ?
          <>
            <Box width={100} pr={2}>
              <TextField
                label="Currency"
                fullWidth
                InputProps={{readOnly: readOnly}}
                onChange={(e) => {
                  reward.param['currency'] = e.target.value;
                  this.setState({reward: reward});
                }}
                value={reward.param['currency']}
              />
            </Box>
            <Box width={150}>
              <TextField
                label="Amount"
                fullWidth
                InputProps={{readOnly: readOnly}}
                onChange={(e) => {
                  reward.param['amount'] = e.target.value;
                  this.setState({reward: reward});
                }}
                value={reward.param['amount']}
              />
            </Box>
          </>
          : null
        }
        {reward.type === RewardType.GrantItem ?
          <>
            <Box width={100} pr={2}>
              <TextField
                label="Catalog"
                fullWidth
                InputProps={{readOnly: readOnly}}
                onChange={(e) => {
                  reward.param['catalogName'] = e.target.value;
                  this.setState({reward: reward});
                }}
                value={reward.param['catalogName']}
              />
            </Box>
            <Box flexGrow="1">
              <TextField
                label="Item Id"
                fullWidth
                InputProps={{readOnly: readOnly}}
                onChange={(e) => {
                  reward.param['itemId'] = e.target.value;
                  this.setState({reward: reward});
                }}
                value={reward.param['itemId']}
              />
            </Box>
            {!readOnly && (
              <Box display="flex" flexDirection="column" justifyContent="center">
                <Button type="button" color="primary" size="small" disabled={readOnly || this.state.itemsDialogOpen} variant="text" onClick={() => this.setState({itemsDialogOpen: true})}>Select Item</Button>
              </Box>
            )}
          </>
          : null
        }
        {reward.type === RewardType.GrantSeasonTokens ?
          <>
            <Box width={100} pr={1}>
              <TextField
                label="Amount"
                fullWidth
                InputProps={{readOnly: readOnly}}
                onChange={(e) => {
                  reward.param['amount'] = e.target.value;
                  this.setState({reward: reward});
                }}
                value={reward.param['amount']}
              />
            </Box>
          </>
          : null
        }
        <Box flexGrow="1" />
        {reward.type !== '' &&
          <Box mt={1} ml={2}>
            <FormControlLabel
              control={
                <Checkbox
                  color="primary"
                  checked={reward.param['valuable']}
                  onChange={(e) => {
                    if (!readOnly) {
                      reward.param['valuable'] = e.target.checked;
                      this.setState({ reward: reward });
                    }
                  }}
                />
              }
              label="Valuable"
            />
          </Box>
        }
        {!this.props.readOnly && UserService.canDelete('seasons') ?
          <Box width={30} display="flex" flexDirection="column" justifyContent="center">
            <IconButton aria-label="delete" size="small" onClick={() => this.props.onRewardDelete(this.props.reward)}>
              <DeleteIcon/>
            </IconButton>
          </Box>
          : null}
      </Box>

      {this.state.itemsDialogOpen && (
        <ItemSelectDialog
          includeDropTables={true}
          showCatalogSelect
          onSelect={this.onItemSelected}
          onClose={() => this.setState({itemsDialogOpen: false})}
        />
      )}
    </>);
  }
}

export default RewardControl;

import { Dialog, DialogContent } from '@material-ui/core';
import type { Column } from 'material-table';
import { useEffect, useMemo, useState } from 'react';
import MaterialTable from '../../../components/MaterialTable';
import type { Catalog } from '../../../services/catalogs';
import { CatalogsService } from "../../../services/catalogs";
import type { ItemDefinition } from '../../../services/item-definitions';
import { ItemDefinitionsService } from "../../../services/item-definitions";
import type { CurrencyList } from "../../../services/model/currency";
import { CurrencyService } from "../../../services/currency";
import { pushAppNotification } from "../../../shared/hooks/useAppNotification";
import { PlayerItemsService } from "../player-items";

type Props = {
  playerId: string;
  onClose: () => void;
  onComplete: () => void;
};

export const GrantCurrencyDialog = ({ playerId, onClose, onComplete }: Props) => {
  const [open, setOpen] = useState(true);
  const [catalog, setCatalog] = useState<Catalog | undefined>();
  const [bundles, setBundles] = useState<ItemDefinition[] | undefined>();
  const [currencyList, setCurrencyList] = useState<CurrencyList | undefined>();
  useEffect(() => {
    CurrencyService.getOrLoadCurrencies()
      .then(setCurrencyList)
      .catch(err => pushAppNotification({type: 'error', message: `Failed to load currencies: ${err.message}`}));
  }, []);
  const columns: Column<ItemDefinition>[] = useMemo(() => {
    const columns: Column<ItemDefinition>[] = [{ title: 'ID', field: 'itemId', defaultSort: 'asc' }];
    (currencyList?.getCurrencies() || []).forEach(currency => {
      columns.push({ title: currency.name, sorting: false, render: item => <>{currency.code} {(item.currencyContents[currency.code] || 0).toLocaleString()} </> });
    });
    return columns;
  }, [currencyList]);
  useEffect(() => {
    CatalogsService.getCatalogs()
      .then(catalogs => {
        setCatalog(catalogs.find(cat => cat.primaryCatalog) || catalogs[0]);
      })
      .catch(err => pushAppNotification({type: 'error', message: `Failed to get catalogs: ${err.message}`}));
  }, []);
  useEffect(() => {
    if (catalog) {
      ItemDefinitionsService.getItemDefinitions(catalog.name)
        .then(items => setBundles(items.filter(item => item.bundle && item.itemBundleContents.length < 1)))
        .catch(err => pushAppNotification({type: 'error', message: `Failed to get catalog item definitions: ${err.message}`}));
    }
  }, [catalog]);

  const onItemClick = (item?: ItemDefinition) => {
    if (item && catalog) {
      PlayerItemsService.grantItem(playerId, catalog.name, item.itemId)
        .then(() => {
          pushAppNotification({type: 'success', message: 'Currency granted'})
          onComplete();
        })
        .catch(err => pushAppNotification({type: 'error', message: `Failed to grant currency: ${err.message}`}));
      setOpen(false);
    }
  };

  const isLoading = () => {
    return !catalog || !bundles || !currencyList;
  };

  return (
    <Dialog
      open={open}
      onClose={() => setOpen(false)}
      onExited={onClose}
      fullWidth
      maxWidth="md"
    >
      <DialogContent style={{ padding: 0 }}>
        <MaterialTable
          title="Select a currency bundle"
          columns={columns}
          data={bundles || []}
          options={{
            pageSize: 10
          }}
          isLoading={isLoading()}
          onRowClick={(event, item) => onItemClick(item)}
        />
      </DialogContent>
    </Dialog>
  );
};

import { Box, Typography } from '@material-ui/core';
import { DiffsPanelsContainer } from '../DiffsPanels';
import type { ItemDiffProps, TitleFileDiff } from '../../types';

type TitleFilesProps = ItemDiffProps<TitleFileDiff>

export const TitleFiles = ({
  sourceItems,
  targetItems,
  actions,
  onSelectionChange
}: TitleFilesProps) => (<>
  <Typography variant="h6">
    Title data - Files
  </Typography>

  <Box mt={2}>
    {sourceItems && targetItems && (
      <DiffsPanelsContainer
        sourceItems={sourceItems}
        targetItems={targetItems}
        selectedIds={actions}
        columns={[
          { title: 'Filename', field: 'diffId', defaultSort: 'asc' }
        ]}
        onSelectionChange={onSelectionChange}
      />
    )}
  </Box>
</>);

import { useMemo } from 'react';
import { MigrationDataLoader } from '../components/MigrationDataLoader';
import { MigrationDiffsStep } from '../components/MigrationDiffsStep';
import { MigrationDone } from '../components/MigrationDone';
import { MigrationFramework } from '../components/MigrationFramework';
import { MigrationOptions } from '../components/MigrationOptions';
import { MigrationReviewStep } from '../components/MigrationReviewStep';
import { MigrationRunner } from '../components/MigrationRunner';
import type { MigrationStep } from '../migrations';
import { migrationModules, migrationRunOrder } from '../modules/migration-modules';

const migrationSteps: MigrationStep[] = [
  { id: 'options', title: '', component: MigrationOptions },
  { id: 'loading', title: 'Loading...', component: MigrationDataLoader },
  { id: 'diff', title: 'Select changes', component: MigrationDiffsStep },
  { id: 'review', title: 'Review changes', component: MigrationReviewStep },
  { id: 'run', title: 'Running...', component: MigrationRunner },
  { id: 'results', title: 'Done', component: MigrationDone }
];

export const MigrationPage = () => {
  const commonProps = useMemo(() => ({
    migrationSteps,
    modules: migrationModules,
    runOrder: migrationRunOrder,
    runMigrationTitle: 'Run migration',
    getMainTitle: (sourceEnvName: string, sourceCatalog: string, targetEnvName: string, targetCatalog: string) => {
      const source = `${sourceEnvName || 'Unknown'} ${sourceCatalog}`;
      const destination = `${targetEnvName || 'Unknown'} ${targetCatalog}`;
      return `Migrate ${source} to ${destination}`;
    }
  }), []);

  return (<>
    <MigrationFramework {...commonProps} />
  </>);
}

import { Checkbox, FormControlLabel } from '@material-ui/core';

interface CheckboxMultiProps {
  checked: boolean;
  indeterminate: boolean;
  disabled?: boolean;
  label: string;
  onClick: () => void;
}

export const CheckboxMulti = ({
  checked,
  indeterminate,
  disabled,
  label,
  onClick
}: CheckboxMultiProps) => (<FormControlLabel
  control={
    <Checkbox checked={checked} indeterminate={indeterminate} disabled={disabled} onClick={onClick} />
  }
  label={label}
/>)
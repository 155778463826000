import { Box, Button, CircularProgress, Link } from '@material-ui/core';
import type { Column } from 'material-table';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import MaterialTable from '../../../components/MaterialTable';
import { openConfirmationDialog, setAppNotification } from '../../../redux/app/actions';
import type { ApiError } from '../../../services/api';
import { UserService } from '../../../services/user';
import { RouterLink } from '../../../types';
import type { Shop, ShopVersion } from '../shop';
import { getPlayerShopAccess, getShopVersions, revokePlayerAccess } from '../shopsApi';

interface AccessRow {
  shopVersionId: string;
  shopVersionName: string;
  playerId: string;
  playerExternalId: string;
  playerName: string;
  playerEmail: string;
}

export interface ShopPlayerAccessProps {
  shop: Shop;
}

export const ShopPlayerAccess = (props: ShopPlayerAccessProps) => {
  const dispatch = useDispatch();

  const { shop } = props;
  const [accessList, setAccessList] = useState<AccessRow[] | null>(null);
  const [selectedRows, setSelectedRows] = useState<AccessRow[]>([]);
  const [revokingAccess, setRevokingAccess] = useState(false);

  const accessColumns = useMemo<Column<AccessRow>[]>(() => {
    return [
      {
        title: 'Version',
        field: 'shopVersionName',
        render(row) {
          return <Link component={RouterLink} to={`/shops/${shop.id}/timeline?version=${row.shopVersionId}`}>{row.shopVersionName}</Link>
        }
      },
      {
        title: 'Player name',
        field: 'playerName',
        render(row) {
          return <Link component={RouterLink} to={`/players/${row.playerExternalId}`}>{row.playerName}</Link>
        }
      },
      {
        title: 'Player email',
        field: 'playerEmail'
      }
    ];
  }, [shop]);

  useEffect(() => {
    getShopVersions(shop.id).then(shopVersions => {
      return getPlayerShopAccess(shop.id).then(accessList => {
        const shopVersionMap: { [key: string]: ShopVersion } = {};
        shopVersions.forEach(v => shopVersionMap[v.id] = v);

        setAccessList(accessList.map(access => {
          const shopVersion = shopVersionMap[access.shopVersionId];
          return {
            shopVersionId: access.shopVersionId,
            shopVersionName: shopVersion ? shopVersion.name : access.shopVersionId,
            playerId: access.player.id,
            playerExternalId: access.player.externalId,
            playerName: access.player.displayName,
            playerEmail: access.player.email
          };
        }));
      });
    }).catch((e: ApiError) => dispatch(setAppNotification({ type: 'error', message: `Failed to load shop. ${e.message}` })));
  }, [dispatch, shop]);

  const onRevokeAccess = useCallback(() => {
    if (accessList && selectedRows.length > 0) {
      const title = `Revoke access for ${selectedRows.length} ${selectedRows.length > 1 ? 'players' : 'player'}?`;
      dispatch(openConfirmationDialog({ title, action: 'Revoke access' }, () => {
        setRevokingAccess(true);
        revokePlayerAccess(selectedRows).then(removedAccess => {
          const newAccessList = accessList.filter(v => removedAccess.findIndex(r => r.playerId === v.playerId) < 0);
          setAccessList(newAccessList);
          setSelectedRows([]);
          dispatch(setAppNotification({ type: 'success', message: 'Player access revoked' }));
        }).catch(() => {
          dispatch(setAppNotification({ type: 'error', message: 'Error revoking player access' }));
        }).then(() => setRevokingAccess(false));
      }));
    }
  }, [dispatch, selectedRows, accessList]);

  if (!accessList) {
    return (
      <Box textAlign="center">
        <CircularProgress />
      </Box>
    );
  }

  return (<>
    <MaterialTable<AccessRow>
      title=""
      columns={accessColumns}
      data={accessList}
      options={{
        selection: UserService.canDelete('store'),
        showTextRowsSelected: false,
        pageSize: 20,
        pageSizeOptions: [20, 50, 100]
      }}
      onSelectionChange={setSelectedRows}
    />

    {UserService.canDelete('store') && (
      <Box mt={2}>
        {revokingAccess ? (
          <CircularProgress size={20} />
        ) : (
          <Button color="primary" variant="contained" onClick={onRevokeAccess} disabled={selectedRows.length < 1}>
            Revoke access {selectedRows.length > 0 && (
              <>({`${selectedRows.length} ${selectedRows.length > 1 ? 'players' : 'player'}`})</>
            )}
          </Button>
        )}
      </Box>
    )}
  </>);
}
import { Box, Button, Card, CardContent, CardHeader, Grid, IconButton, Typography } from '@material-ui/core';
import type { FormikHelpers } from 'formik';
import { Field, FieldArray, Formik } from 'formik';
import {CheckboxWithLabel, TextField} from 'formik-material-ui';
import { useCallback, useMemo } from 'react';
import * as yup from 'yup';
import type { CurrencyList } from '../../services/model/currency';
import type { Season, SeasonTokenCost } from '../../services/player-challenges/seasons';
import DeleteIcon from '@material-ui/icons/Delete';


const formSchema = yup.object({
  tiers: yup.array().of(
    yup.object({
      count: yup.number().min(1, 'Must be 1 or greater').required('Required'),
      prices: yup.array().of(yup.object({
        code: yup.string().required(),
        amount: yup.number().min(0, 'Price must be 0 or greater')
      }))
    })
  )
});

interface FormValues {
  purchaseTierSkipEnabled: boolean;
  tiers: {
    count: number;
    prices: {
      code: string;
      amount: string | number;
    }[]
  }[]
}

interface Props {
  season: Season;
  currencyList: CurrencyList;
  onSave: (season: Season) => void;
}

const SeasonTokenCosts = (props: Props) => {
  const { season, onSave } = props;

  const formValues = useMemo((): FormValues => {
    const costs = props.season.season_token_cost.slice();
    costs.sort((a, b) => a.tokenCount - b.tokenCount);

    return {
      purchaseTierSkipEnabled: props.season.purchase_tier_skip_enabled,
      tiers: costs.map(cost => ({
        count: cost.tokenCount,
        prices: props.currencyList.getCurrencyCodes().map(code => ({
          code,
          amount: isNaN(cost.prices[code]) ? '' : cost.prices[code]
        }))
      }))
    };
  }, [props.season, props.currencyList]);

  const getNewTier = useCallback((formValues: FormValues) => ({
    count: formValues.tiers.length > 0 ? formValues.tiers[formValues.tiers.length - 1].count + 1 : 1,
    prices: props.currencyList.getCurrencyCodes().map(code => ({
      code,
      amount: ''
    }))
  }), [props.currencyList]);

  const onSubmit = useCallback((values: FormValues, helpers: FormikHelpers<FormValues>) => {
    const newSeason = season.clone();
    newSeason.purchase_tier_skip_enabled = values.purchaseTierSkipEnabled;
    newSeason.season_token_cost = values.tiers.map(tier => {
      const cost: SeasonTokenCost = {
        tokenCount: tier.count,
        prices: {}
      };

      tier.prices.forEach(price => {
        if (typeof price.amount === 'number') {
          cost.prices[price.code] = price.amount;
        }
      });

      return cost;
    });

    onSave(newSeason);
    helpers.setSubmitting(false);
  }, [season, onSave]);

  return (
    <Formik<FormValues>
      initialValues={formValues}
      enableReinitialize
      validationSchema={formSchema}
      onSubmit={onSubmit}
    >
      {form => (<>
        <FieldArray name="tiers">
          {({ remove, push }) => (<>
            <Card>
              <CardHeader
                title="Season token prices"
                action={
                  <Button color="primary" variant="contained" size="small" onClick={() => push(getNewTier(form.values))}>
                    Add tier
                  </Button>
                }
              />
              <CardContent>
                <Box mb={2}>
                  <Field
                    component={CheckboxWithLabel}
                    name="purchaseTierSkipEnabled"
                    type="checkbox"
                    Label={{ label: "Purchase Tier Skip Enabled" }}
                  />
                </Box>

                {form.values.tiers.map((tier, index) => (
                  <Grid container spacing={1} key={index}>
                    <Grid item xs>
                      <Field
                        component={TextField}
                        name={`tiers[${index}].count`}
                        type="number"
                        label="Token count"
                        fullWidth
                      />
                    </Grid>
                    {tier.prices.map((price, priceIndex) => (
                      <Grid item xs key={price.code}>
                        <Field
                          component={TextField}
                          name={`tiers[${index}].prices[${priceIndex}].amount`}
                          type="number"
                          label={`${price.code} price`}
                          fullWidth
                        />
                      </Grid>
                    ))}
                    <Grid item xs="auto">
                      <IconButton onClick={() => remove(index)}>
                        <DeleteIcon />
                      </IconButton>
                    </Grid>
                  </Grid>
                ))}

                {form.values.tiers.length < 1 && (
                  <Typography color="textSecondary">
                    No token prices yet.
                  </Typography>
                )}

                <Box mt={2}>
                  <Button color="primary" variant="contained" onClick={form.submitForm}>
                    Save
                  </Button>
                </Box>
              </CardContent>
            </Card>
          </>)}
        </FieldArray>
      </>)}
    </Formik>
  )
}

export default SeasonTokenCosts;
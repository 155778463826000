import type {Reducer} from 'redux';
import {createReducer} from 'typesafe-actions';
import {ScheduleCalendar} from '../../../services/player-challenges/season-challenge-schedule-calendar';
import {createSeasonChallengeScheduleCalendarAsync, deleteSeasonChallengeScheduleCalendarAsync, deselectAndRefreshScheduleCalendar, getSeasonChallengeScheduleCalendarAsync, getSeasonChallengeScheduleCalendarsAsync, updateSeasonChallengeScheduleCalendarAsync} from './actions';
import {updateSeasonChallengeScheduleAsync} from "../season-challenge-schedule/actions";

export interface SeasonChallengeScheduleCalendarsState {
  isLoading: boolean;
  isSaving: boolean;
  isDeleting?: string;
  error?: string;
  data: ScheduleCalendar[];
}

const initialState: SeasonChallengeScheduleCalendarsState = {
  isLoading: false,
  isSaving: false,
  isDeleting: undefined,
  error: undefined,
  data: []
};

const seasonsReducer: Reducer<SeasonChallengeScheduleCalendarsState> = createReducer<SeasonChallengeScheduleCalendarsState>(initialState)
  .handleAction(getSeasonChallengeScheduleCalendarsAsync.request, state => ({
    ...state,
    isLoading: true
  }))
  .handleAction(getSeasonChallengeScheduleCalendarsAsync.success, (state, action) => ({
    ...state,
    isLoading: false,
    data: action.payload
  }))
  .handleAction(getSeasonChallengeScheduleCalendarsAsync.failure, state => ({
    ...state,
    isLoading: false
  }))
  .handleAction(getSeasonChallengeScheduleCalendarAsync.request, (state, action) => ({
    ...state,
    isLoading: false
  }))
  .handleAction(getSeasonChallengeScheduleCalendarAsync.success, (state, action) => {
    const index = state.data.findIndex(instance =>
      instance.season_number === action.payload.season_number &&
      instance.schedule_id === action.payload.schedule_id &&
      instance.instance_id === action.payload.instance_id);
    const newData = state.data.slice();
    if (index >= 0) {
      newData[index] = action.payload;
    } else {
      newData.push(action.payload);
    }

    return ({
      ...state,
      isLoading: false,
      data: newData
    });
  })
  .handleAction(getSeasonChallengeScheduleCalendarAsync.failure, (state, action) => ({
    ...state,
    isLoading: false
  }))
  .handleAction(createSeasonChallengeScheduleCalendarAsync.request, (state, action) => ({
    ...state,
    isSaving: true
  }))
  .handleAction(createSeasonChallengeScheduleCalendarAsync.success, (state, action) => ({
    ...state,
    isSaving: false,
    error: undefined,
    data: state.data.concat(action.payload)
  }))
  .handleAction(createSeasonChallengeScheduleCalendarAsync.failure, (state, action) => ({
    ...state,
    isSaving: false,
    error: action.payload
  }))
  .handleAction(updateSeasonChallengeScheduleCalendarAsync.request, (state, action) => ({
    ...state,
    isSaving: true
  }))
  .handleAction(updateSeasonChallengeScheduleCalendarAsync.success, (state, action) => {
    const index = state.data.findIndex(instance =>
      instance.season_number === action.payload.season_number &&
      instance.schedule_id === action.payload.schedule_id &&
      instance.instance_id === action.payload.instance_id);
    const newData = state.data.slice();
    if (index >= 0) {
      newData.splice(index, 1, action.payload);
    }

    return ({
      ...state,
      isSaving: false,
      error: undefined,
      data: newData
    });
  })
  .handleAction(updateSeasonChallengeScheduleCalendarAsync.failure, (state, action) => ({
    ...state,
    isSaving: false,
    error: action.payload
  }))
  .handleAction(deleteSeasonChallengeScheduleCalendarAsync.request, (state, action) => ({
    ...state,
    isDeleting: action.payload.instanceId
  }))
  .handleAction(deleteSeasonChallengeScheduleCalendarAsync.success, (state, action) => {
    const index = state.data.findIndex(instance =>
      instance.season_number === action.payload.seasonNumber &&
      instance.schedule_id === action.payload.scheduleId &&
      instance.instance_id === action.payload.instanceId);
    const newData = state.data.slice();
    if (index >= 0) {
      newData.splice(index, 1);
    }

    return ({
      ...state,
      isDeleting: undefined,
      data: newData
    });
  })
  .handleAction(deleteSeasonChallengeScheduleCalendarAsync.failure, (state, action) => ({
    ...state,
    isDeleting: undefined
  }))
  .handleAction(updateSeasonChallengeScheduleAsync.success, (state, action) => {
    const newData = action.payload;
    const mappedData: ScheduleCalendar[] = [];
    newData.daily.forEach(o => o.challenges.forEach(p => {
      mappedData.push(new ScheduleCalendar(newData.season_number, newData.schedule_id, {
        calendarType: 'DAILY',
        calendarNumber: o.day_number,
        challengeId: p.challenge_id,
        instanceId: p.instance_id,
        requiredChallenges: p.required_challenges,
        isLimited: p.is_limited,
        limitedStart: p.limited_start,
        limitedEnd: p.limited_end,
        rewards: p.rewards,
        challengeDesc: p.challenge_desc,
        challengeName: p.challenge_name,
        goalAmount: p.goal_amount
      }));
    }));
    newData.weekly.forEach(o => o.challenges.forEach(p => {
      mappedData.push(new ScheduleCalendar(newData.season_number, newData.schedule_id, {
        calendarType: 'WEEKLY',
        calendarNumber: o.week_number,
        challengeId: p.challenge_id,
        instanceId: p.instance_id,
        requiredChallenges: p.required_challenges,
        isLimited: p.is_limited,
        limitedStart: p.limited_start,
        limitedEnd: p.limited_end,
        rewards: p.rewards,
        challengeDesc: p.challenge_desc,
        challengeName: p.challenge_name,
        goalAmount: p.goal_amount
      }));
    }));
    newData.seasonal.challenges.forEach(p => {
      mappedData.push(new ScheduleCalendar(newData.season_number, newData.schedule_id, {
        calendarType: 'SEASONAL',
        calendarNumber: 0,
        challengeId: p.challenge_id,
        instanceId: p.instance_id,
        requiredChallenges: p.required_challenges,
        isLimited: p.is_limited,
        limitedStart: p.limited_start,
        limitedEnd: p.limited_end,
        rewards: p.rewards,
        challengeDesc: p.challenge_desc,
        challengeName: p.challenge_name,
        goalAmount: p.goal_amount
      }));
    });
    return ({
      ...state,
      isUpdating: false,
      data: mappedData
    });
  })
  .handleAction(deselectAndRefreshScheduleCalendar.request, state => {
    if (!(state && state.data)) {
      return ({...state});
    }
    return ({
      ...state,
      data: state.data.map(o => new ScheduleCalendar(o.season_number || -1, o.schedule_id, o.toJson()))
    });

  });

export default seasonsReducer;
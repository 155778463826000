import { IconButton, TableCell, TableRow } from "@material-ui/core";
import { FastField, FieldInputProps } from "formik";
import { useRef } from "react";
import { useDrag, useDrop, XYCoord } from "react-dnd";
import { Identifier } from "typescript";
import DeleteIcon from '@material-ui/icons/Delete';
import { TextField } from "formik-material-ui";

const style = {
  cursor: 'move',
}


export interface FormContainedItem {
  id: string;
  quantity: string;
  type: 'item' | 'droptable';
  index: number;
}

interface FormContainedItemProps {
  item: FormContainedItem;
  index: number;
  moveItem: (dragIndex: number, hoverIndex: number,) => void;
  onRemove: (id: string, type: string) => void;
  readOnly?: boolean;
}

export const FormContainedItem = ({ item, index, moveItem, onRemove, readOnly}: FormContainedItemProps) => {
  const dndHandleRef = useRef<HTMLTableRowElement>(null);
  const dndDropCooldown = useRef<boolean>(false);

  // DnD movement
  const [{handlerId}, drop] = useDrop<FormContainedItem, void, {handlerId: Identifier | null}>({
    accept: 'FormContainedItem',
    hover(item: { type: string, index: number }, monitor) {
      if (!dndHandleRef.current) {
        return;
      }
      if (item.index === index || dndDropCooldown.current) {
        return;
      }
      const dragIndex = item.index
      const hoverIndex = index

      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return
      }

      // Determine rectangle on screen
      const hoverBoundingRect = dndHandleRef.current?.getBoundingClientRect()

      // Get vertical middle
      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2

      // Determine mouse position
      const clientOffset = monitor.getClientOffset()

      // Get pixels to the top
      const hoverClientY = (clientOffset as XYCoord).y - hoverBoundingRect.top

      // Only perform the move when the mouse has crossed half of the items height
      // When dragging downwards, only move when the cursor is below 50%
      // When dragging upwards, only move when the cursor is above 50%

      // Dragging downwards
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }

      // Dragging upwards
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }

      // Temporary fix for DnD position flicker. Prevent items for swapping positions faster than once per second.
      dndDropCooldown.current = true;
      window.setTimeout(() => dndDropCooldown.current = false, 1000);

      moveItem(dragIndex, hoverIndex);
      item.index = hoverIndex;
    }
  });

  const [{ isDragging }, drag] = useDrag({
    item: { type: 'FormContainedItem', index: index },
    collect: (monitor: any) => ({
      isDragging: monitor.isDragging(),
    }),
  })

  drag(drop(dndHandleRef)) 
  const rowProps = {
    ref: dndHandleRef,
    style: {...style},
    "data-handler-id": handlerId
  }

  return (
    <TableRow {...rowProps} >
      {!readOnly && (
        <TableCell padding="none" style={{ width: 1 }}>
          <IconButton onClick={() => onRemove(item.id, item.type)}>
            <DeleteIcon />
          </IconButton>
        </TableCell>
      )}
      <TableCell style={{ whiteSpace: 'nowrap', width: 1 }}>{item.type === 'item' ? 'Item' : 'Drop table'}</TableCell>
      <TableCell style={{ width: 100 }}>
        <FastField
          component={TextField}
          type="text"
          name={`contents[${index}].quantity`}
          label=""
          fullWidth
          InputProps={{ readOnly}}
        />
      </TableCell>
      <TableCell>{item.id}</TableCell>
    </TableRow>
  )
};
import { combineReducers } from 'redux';
import { createReducer } from 'typesafe-actions';
import type { DropTable } from '../../../services/drop-tables';
import type { APIResource } from '../../utils-ts';
import { getCatalogDropTableAsync, getCatalogDropTablesAsync, addCatalogDropTableAsync, updateCatalogDropTableAsync, clearCatalogDropTables } from './actions';
import { setCatalogName } from '../actions';

const dropTablesReducer = createReducer<APIResource<DropTable[]>>({ isLoading: false })
  .handleAction(getCatalogDropTablesAsync.request, state => ({
    ...state,
    isLoading: true
  }))
  .handleAction(getCatalogDropTablesAsync.success, (state, action) => ({
    ...state,
    isLoading: false,
    data: action.payload
  }))
  .handleAction(getCatalogDropTablesAsync.failure, state => ({
    ...state,
    isLoading: false
  }))
  .handleAction(addCatalogDropTableAsync.success, state => ({
    ...state,
    data: null
  }))
  .handleAction([setCatalogName, clearCatalogDropTables], state => ({
    ...state,
    isLoading: false,
    data: null
  }))

const dropTableReducer = createReducer<APIResource<DropTable>>({ isLoading: false })
  .handleAction(getCatalogDropTableAsync.request, state => ({
    ...state,
    isLoading: true
  }))
  .handleAction(getCatalogDropTableAsync.success, (state, action) => ({
    ...state,
    isLoading: false,
    data: action.payload
  }))
  .handleAction(getCatalogDropTableAsync.failure, state => ({
    ...state,
    isLoading: false
  }))
  .handleAction([addCatalogDropTableAsync.success, updateCatalogDropTableAsync.success], (state, action) => ({
    ...state,
    data: action.payload
  }))
  .handleAction([setCatalogName, clearCatalogDropTables], state => ({
    ...state,
    isLoading: false,
    data: null
  }))

export default combineReducers({
  dropTables: dropTablesReducer,
  dropTable: dropTableReducer
});
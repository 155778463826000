import {call, put, takeLatest} from 'redux-saga/effects';
import type {ChallengeTemplate, ChallengeTrigger } from '../../../services/player-challenges/challenge-templates';
import { ChallengeTemplatesService} from '../../../services/player-challenges/challenge-templates';
import {setAppNotification} from '../../app/actions';
import {forkSagas, sagaHandleApiError} from '../../utils-ts';
import {createChallengeTemplateAsync, deleteChallengeTemplateAsync, getChallengeTemplateAsync, getChallengeTemplatesAsync, getChallengeTriggersAsync, getLatestChallengeTemplateAsync, updateChallengeTemplateAsync} from './actions';

function* fetchChallengeTemplates(action: ReturnType<typeof getChallengeTemplatesAsync.request>) {
  try {
    const seasons: ChallengeTemplate[] = yield call(ChallengeTemplatesService.getChallengeTemplates);
    yield put(getChallengeTemplatesAsync.success(seasons));
  } catch (e) {
    yield sagaHandleApiError(e, getChallengeTemplatesAsync.failure);
  }
}

function* fetchLatestChallengeTriggers(action: ReturnType<typeof getChallengeTriggersAsync.request>) {
  try {
    const challengeTriggers: ChallengeTrigger[] = yield call(ChallengeTemplatesService.getLatestChallengeTriggers);
    yield put(getChallengeTriggersAsync.success(challengeTriggers));
  } catch (e) {
    yield sagaHandleApiError(e, getChallengeTriggersAsync.failure);
  }
}

function* fetchLatestChallengeTemplate(action: ReturnType<typeof getLatestChallengeTemplateAsync.request>) {
  try {
    const seasons: ChallengeTemplate = yield call(ChallengeTemplatesService.getLatestChallengeTemplate);
    yield put(getLatestChallengeTemplateAsync.success(seasons));
  } catch (e) {
    yield sagaHandleApiError(e, getLatestChallengeTemplateAsync.failure);
  }
}

function* fetchChallengeTemplate(action: ReturnType<typeof getChallengeTemplateAsync.request>) {
  try {
    const season: ChallengeTemplate = yield call(ChallengeTemplatesService.getChallengeTemplate, action.payload);
    yield put(getChallengeTemplateAsync.success(season));
  } catch (e) {
    yield sagaHandleApiError(e, getChallengeTemplateAsync.failure);
  }
}

function* createChallengeTemplateSaga(action: ReturnType<typeof createChallengeTemplateAsync.request>) {
  try {
    
    const season: ChallengeTemplate = yield call(ChallengeTemplatesService.createChallengeTemplate, action.payload);
    yield put(updateChallengeTemplateAsync.success(season));
    yield put(setAppNotification({ type: 'success', message: 'Challenge Template created' }));
  } catch (e) {
    yield sagaHandleApiError(e, updateChallengeTemplateAsync.failure);
  }
}

function* updateChallengeTemplateSaga(action: ReturnType<typeof updateChallengeTemplateAsync.request>) {
  try {
    
    const season: ChallengeTemplate = yield call(ChallengeTemplatesService.updateChallengeTemplate, action.payload);
    yield put(updateChallengeTemplateAsync.success(season));
    yield put(setAppNotification({ type: 'success', message: 'Challenge Template updated' }));
  } catch (e) {
    yield sagaHandleApiError(e, updateChallengeTemplateAsync.failure);
  }
}

function* deleteChallengeTemplateSaga(action: ReturnType<typeof deleteChallengeTemplateAsync.request>) {
  try {
    yield call(ChallengeTemplatesService.deleteChallengeTemplate, action.payload);
    yield put(deleteChallengeTemplateAsync.success(action.payload));
    yield put(setAppNotification({ type: 'success', message: 'Challenge Template deleted' }));
  } catch (e) {
    yield sagaHandleApiError(e, deleteChallengeTemplateAsync.failure);
  }
}

const sagas = [
  function* () {
    yield takeLatest(getChallengeTemplatesAsync.request, fetchChallengeTemplates);
  },
  function* () {
    yield takeLatest(getChallengeTriggersAsync.request, fetchLatestChallengeTriggers);
  },
  function* () {
    yield takeLatest(getLatestChallengeTemplateAsync.request, fetchLatestChallengeTemplate);
  },
  function* () {
    yield takeLatest(getChallengeTemplateAsync.request, fetchChallengeTemplate);
  },
  function* () {
    yield takeLatest(createChallengeTemplateAsync.request, createChallengeTemplateSaga);
  },
  function* () {
    yield takeLatest(updateChallengeTemplateAsync.request, updateChallengeTemplateSaga);
  },
  function* () {
    yield takeLatest(deleteChallengeTemplateAsync.request, deleteChallengeTemplateSaga);
  }
];

export default function* challengeTemplatesSagas() {
  yield forkSagas(sagas);
}
import type { Theme } from '@material-ui/core';
import { Box, Button, CircularProgress, Grid, makeStyles } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import { useCallback, useEffect, useRef, useState } from 'react';
import * as React from 'react';
import { useDrop } from 'react-dnd';
import { useDispatch } from 'react-redux';
import { setAppNotification } from '../../redux/app/actions';
import { getCatalogItemsAsync } from '../../redux/catalog/items/actions';
import { setStore } from '../../redux/catalog/stores/actions';
import { loadCurrenciesAsync } from '../../redux/currencies/actions';
import { useTypedSelector } from '../../redux/reducers';
import type { ApiError } from '../../services/api';
import type { DropTable } from '../../services/drop-tables';
import { ItemDefinition } from '../../services/item-definitions';
import type { Store, StoreTabItem } from '../../services/stores';
import { StoreItem, StoresService } from '../../services/stores';
import ItemSelectDialog from '../ItemDefinition/ItemSelectDialog';
import type { EditorItem } from './StoreTabItemEditor';
import StoreTabItemEditor from './StoreTabItemEditor';
import { BuiltInCurrencyEnum } from '../../services/model/currency';

const useStyles = makeStyles((theme: Theme) => ({
  addButton: {
    borderWidth: 3,
    borderStyle: 'dashed',
    borderColor: theme.palette.secondary.main,
    borderRadius: 10,
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: theme.palette.type === 'dark' ? 'rgba(255, 255, 255, 0.1)' : 'rgba(0, 0, 0, 0.1)'
    }
  }
}));

interface Props {
  catalogName: string;
  store: Store;
  tab: number;
  readOnly?: boolean;
}

const StoreTabEditor = (props: Props) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const gridRef = useRef<HTMLDivElement | null>(null);

  const catalogItems = useTypedSelector(state => state.catalog.items.items.data);
  const currencyList = useTypedSelector(state => state.currencies.list);

  const [isLoading, setLoading] = useState(true);
  const [isSubmitting, setSubmitting] = useState(false);
  const [items, setItems] = useState<EditorItem[]>([]);
  const [addDialogOpen, setAddDialogOpen] = useState(false);

  const buildEditorItem = (tabItem: StoreTabItem, storeItem: StoreItem, catalogItem: ItemDefinition, isNew: boolean): EditorItem => {
    let currency = 'BB';
    let price = '0';
    if (storeItem.rmPrice !== null) {
      currency = BuiltInCurrencyEnum.USDollar;
      price = storeItem.rmPrice.toString();
    } else {
      const keys = Object.keys(storeItem.virtualCurrencyPrices);
      if (keys.length > 0) {
        currency = keys[0];
        price = storeItem.virtualCurrencyPrices[keys[0]].toString();
      }
    }

    const editorItem: EditorItem = { tabItem, storeItem, catalogItem, currency, price, priceError: '', isNew };
    editorItem.priceError = validatePrice(editorItem);
    return editorItem;
  }

  useEffect(() => {
    dispatch(loadCurrenciesAsync.request());
    dispatch(getCatalogItemsAsync.request());
  }, [dispatch, props.catalogName]);

  useEffect(() => {
    if (catalogItems && !currencyList.isEmpty()) {
      const catalogItemsMap: { [key: string]: ItemDefinition; } = {};
      catalogItems.forEach(item => catalogItemsMap[item.itemId] = item);

      const editorItems: EditorItem[] = [];
      const tab = props.store.tabs[props.tab];
      tab.items.forEach(tabItem => {
        const storeItem = props.store.itemsMap[tabItem.itemId];
        if (!storeItem) {
          return;
        }

        const catalogItem = catalogItemsMap[storeItem.itemId];
        const editorItem = buildEditorItem(tabItem, storeItem, catalogItem, false);
        if (tabItem.hero) {
          editorItems.unshift(editorItem);
        } else {
          editorItems.push(editorItem);
        }
      });

      setItems(editorItems);
      setLoading(false);
    } else {
      setLoading(true);
    }
  }, [catalogItems, currencyList, props.store, props.tab])

  const saveItems = (items: EditorItem[]) => {
    for (let i = 0; i < items.length; i++) {
      if (items[i].priceError) {
        return;
      }
    }

    const store = props.store;
    const storeItems: StoreItem[] = [];
    const storeTabItems: StoreTabItem[] = items.map(item => {
      const tabItem = item.tabItem;
      let storeItem = store.itemsMap[tabItem.itemId];
      if (!storeItem) {
        storeItem = new StoreItem();
        storeItem.itemId = tabItem.itemId;
      }
      storeItem.virtualCurrencyPrices = {};
      if (item.currency === BuiltInCurrencyEnum.USDollar) {
        storeItem.rmPrice = parseFloat(item.price);
      } else {
        storeItem.virtualCurrencyPrices[item.currency] = parseInt(item.price);
        storeItem.rmPrice = null;
      }
      storeItems.push(storeItem);
      return tabItem;
    });

    setSubmitting(true);
    StoresService.upsertStoreItems(props.catalogName, store, storeItems)
      .then(updatedStore => {
        return StoresService.updateStoreTabItems(props.catalogName, store, store.tabs[props.tab].name, storeTabItems).then(() => updatedStore);
      })
      .then(updatedStore => {
        updatedStore.tabs[props.tab].items = storeTabItems;
        dispatch(setStore(updatedStore));
        dispatch(setAppNotification({ type: 'success', message: 'Store updated' }));
      }).catch((err: ApiError) => {
        console.error(err);
        dispatch(setAppNotification({ type: 'error', message: err.message }));
      }).then(() => {
        setSubmitting(false);
      });
  }

  const addItems = (addedItems: (ItemDefinition | DropTable)[]) => {
    const newItems = items.slice();
    addedItems.forEach(item => {
      if (item instanceof ItemDefinition) {
        let storeItem = props.store.itemsMap[item.itemId];
        let isNew = false;
        if (!storeItem) {
          storeItem = new StoreItem({ itemId: item.itemId, virtualCurrencyPrices: {}, rmPrice: null });
          isNew = true;
        }

        const tabItem = { itemId: item.itemId, hero: false, gridWidth: 1, nonInteractive: false };
        newItems.push(buildEditorItem(tabItem, storeItem, item, isNew));
      }
    });
    setItems(newItems);
  }

  const handleItemChange = (index: number, item: EditorItem) => {
    item.priceError = validatePrice(item);
    const newItems = items.slice();
    newItems[index] = item;
    setItems(newItems);
  }

  const handleItemRemove = (index: number) => {
    const newItems = items.slice();
    newItems.splice(index, 1);
    setItems(newItems);
  }

  const toggleItemHero = (index: number) => {
    const newItems = items.slice();
    const item = newItems.splice(index, 1)[0];
    item.tabItem = { ...item.tabItem, hero: !item.tabItem.hero };
    if (item.tabItem.hero && newItems.length > 0) {
      newItems[0].tabItem = { ...newItems[0].tabItem, hero: false };
    }
    newItems.unshift(item);
    setItems(newItems);
  }

  const moveItem = useCallback((fromIndex: number, toIndex: number) => {
    if (toIndex === 0 && items[0].tabItem.hero) {
      return false;
    }

    const newItems = items.slice();
    const t = newItems[toIndex];
    newItems[toIndex] = newItems[fromIndex];
    newItems[fromIndex] = t;
    setItems(newItems);
    return true;
  }, [items]);

  const [, dndResizeDrop] = useDrop({
    accept: 'StoreTabItemResize',
    hover(item: { type: string, index: number, initialWidth: number, ref: React.RefObject<HTMLDivElement> }, monitor) {
      if (!gridRef.current) {
        return;
      }

      const offset = monitor.getDifferenceFromInitialOffset();
      if (!offset) {
        return;
      }

      const gridWidth = gridRef.current.getBoundingClientRect().width;
      const tileWidth = gridWidth / 4.0;
      const editorItem = items[item.index];
      const itemWidth = Math.min(Math.max(tileWidth * item.initialWidth + offset.x, tileWidth), gridWidth);

      const newWidth = Math.min(Math.ceil(itemWidth / tileWidth), 4);
      if (newWidth !== editorItem.tabItem.gridWidth) {
        const newItems = items.slice();
        newItems[item.index] = { ...editorItem, tabItem: { ...editorItem.tabItem, gridWidth: newWidth } };
        setItems(newItems);
      }

      // HACK: Low-level access to div width for performance. (rofl, imagine using props)
      if (item.ref && item.ref.current) {
        item.ref.current.style.width = `${itemWidth - 10}px`;
      }
    }
  });

  if (isLoading) {
    return (
      <Box p={2} textAlign="center">
        <CircularProgress />
      </Box>
    );
  }

  return (<>
    <Grid ref={e => { gridRef.current = e; dndResizeDrop(e); }} container spacing={1}>
      {items.map((item, index) => (
        <Grid key={item.tabItem.itemId} item xs={item.tabItem.hero ? 12 : (item.tabItem.gridWidth * 3) as any}>
          <StoreTabItemEditor
            index={index}
            catalogName={props.catalogName}
            store={props.store}
            storeTab={props.store.tabs[props.tab]}
            item={item}
            onCurrencyChange={currency => handleItemChange(index, { ...item, currency })}
            onPriceChange={price => handleItemChange(index, { ...item, price })}
            onTabItemChange={tabItem => handleItemChange(index, { ...item, tabItem })}
            onRemove={() => handleItemRemove(index)}
            onToggleHero={() => toggleItemHero(index)}
            onToggleNonInteractive={() => handleItemChange(index, { ...item, tabItem: { ...item.tabItem, nonInteractive: !item.tabItem.nonInteractive }})}
            moveItem={fromIndex => moveItem(fromIndex, index)}
          />
        </Grid>
      ))}
      <Grid item xs={3}>
        <Box textAlign="center" className={classes.addButton} height="100%" minHeight={250} onClick={() => setAddDialogOpen(true)}>
          <Grid container alignItems="center" style={{ height: '100%' }}>
            <Grid item xs={12}>
              <AddIcon fontSize="large" />
            </Grid>
          </Grid>
        </Box>
      </Grid>
    </Grid>
    <Box mt={3}>
      <Button
        variant="contained"
        color="primary"
        disabled={isSubmitting}
        onClick={() => saveItems(items)}
      >
        {isSubmitting ? (
          <CircularProgress size={25} />
        ) : 'Save'}
      </Button>
    </Box>

    {addDialogOpen && (
      <ItemSelectDialog
        multi
        addedItems={items.map(v => v.storeItem.itemId)}
        onSelect={addItems}
        onClose={() => setAddDialogOpen(false)}
      />
    )}
  </>);
}

function validatePrice(item: EditorItem): string {
  const price = parseFloat(item.price);
  if (isNaN(price) || price <= 0) {
    return 'Must be a number greater than 0';
  }
  if (item.currency !== BuiltInCurrencyEnum.USDollar && !Number.isInteger(price)) {
    return 'Must be an integer';
  }
  return '';
}

export default StoreTabEditor;
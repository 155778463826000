import { buildDiffData, buildMigrationActions, MigrationModule } from "../../migrations";
import { ItemNoteDiff } from "../../../../pages/CatalogMigration/types";
import { ItemNotesDiffs } from "./ItemNotesDiffs";
import { CatalogMigrationService } from "../../../../services/catalog-migration";
import _, { Dictionary } from "lodash";
import { ItemNote } from "../../../items/item-types";
import { MigrationType } from "../migration-type";

export const itemNoteMigration: MigrationModule<ItemNoteDiff> = {
  id: MigrationType.itemNotes,
  displayName: 'Item Notes',
  diffComponent: ItemNotesDiffs,
  crossEnvOnly: true,

  loadData: async (sourceData, targetData, migrationData) => {
    if (!sourceData.itemNotes) {
      sourceData.itemNotes = await CatalogMigrationService.getAllItemNotes(sourceData.env);
    }
    if (!targetData.itemNotes) {
      targetData.itemNotes = await CatalogMigrationService.getAllItemNotes(targetData.env);
    }
    migrationData.itemNotes = buildDiffData(
      sourceData.itemNotes.map(itemNote => new ItemNoteDiff(itemNote)),
      targetData.itemNotes.map(itemNote => new ItemNoteDiff(itemNote))
    );

    return migrationData.itemNotes;
  },

  runMigration: async (props, setStatus) => {
    const targetEnv = props.targetData.env;
    const actions = buildMigrationActions(props.migrationData.itemNotes, props.selections.itemNotes);
    if (actions.add.length + actions.update.length + actions.remove.length < 1) {
      return;
    }

    const toMapById = (itemNotes?: ItemNote[]): Dictionary<ItemNote> => itemNotes && _.mapValues(_.groupBy(itemNotes, 'id'), result => result[0]) || {};
    const sourceItemNotes = toMapById(props.sourceData.itemNotes);

    let progress = 0;
    for (const addId of actions.add) {
      setStatus('Adding Item Notes', progress);
      const toCreate = _.clone(sourceItemNotes[addId]);
      delete toCreate.updatedAt;
      await CatalogMigrationService.createItemNote(targetEnv, toCreate);
      progress += 100 / actions.add.length;
    }

    progress = 0;
    for (const updateId of actions.update) {
      setStatus('Updating Item Notes', progress);
      const toUpdate = _.clone(sourceItemNotes[updateId]);
      delete toUpdate.updatedAt;
      delete toUpdate.createdAt;
      await CatalogMigrationService.updateItemNote(targetEnv, toUpdate);
      progress += 100 / actions.add.length;
    }

    progress = 0;
    for (const removeId of actions.remove) {
      setStatus('Deleting Item Notes', progress);
      await CatalogMigrationService.deleteItemNote(targetEnv, removeId);
      progress += 100 / actions.add.length;
    }
  },
};

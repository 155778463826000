import { api, getEnvApi, MythicalMigrationAPI } from "./api";
import type { ApiError } from "./api";

export interface Feature {
  name: string;
  active: boolean;
}

export enum BlankosFeature {
  SECONDARY_MARKET = "SECONDARY_MARKET",
  GEOBLOCKING = "GEOBLOCKING",
  ITEM_NOTES = "ITEM_NOTES"
}

type FeatureResult = { [key in BlankosFeature]: boolean };

export class FeaturesService {
  static async getFeatures(envId?: string): Promise<FeatureResult | null> {
    const apiToUse = envId ? getEnvApi(envId) as MythicalMigrationAPI : api as MythicalMigrationAPI;
    return apiToUse.get<FeatureResult>({ url: `/features` })
      .catch((e: ApiError) => {
        e.message = `Failed to get features: ${e.message}`;
        throw e;
      });
  }
}

/*****Example Usage for OUTSIDE component/hooks only *****/
/*
const featureManager = new FeatureManager();
console.log(await featureManager.isActive(BlankosFeature.SECONDARY_MARKET));
*/
export class FeatureManager {
  featureSet: { [key in BlankosFeature]?: boolean } = {}
  hasLoaded = false;
  environment?: string;

  constructor(env?: string) {
    this.environment = env;
  }

  public async isActive(feature: BlankosFeature) {
    if (!this.hasLoaded) {
      this.featureSet = await FeaturesService.getFeatures(this.environment) || {};
      this.hasLoaded = true;
    }

    return (feature in this.featureSet) && this.featureSet[feature];
  }
}
import type { FormikProps } from 'formik'
import type { Theme} from '@material-ui/core';
import { TextField, FormHelperText, makeStyles, createStyles } from '@material-ui/core'
import type { BurnCurrencyParams } from '../blankoGemRush';

interface BurnCurrencyFormProps {
  index: number
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formControl: {
      marginTop: theme.spacing(2),
      minWidth: 400,
    },
  }),
);
export const CurrencyForm = ({ errors, touched, handleChange, handleBlur, values, index }: FormikProps<BurnCurrencyParams> & BurnCurrencyFormProps) => {
  const classes = useStyles()
  return <>
    <TextField
      id={`capstone.${index}.param.BB`}
      name={`capstone.${index}.param.BB`}
      type="number"
      label="BB"
      className={classes.formControl}
      onChange={handleChange}
      onBlur={handleBlur}
      value={values.BB}
      error={
        touched?.BB &&
          Boolean(errors?.BB)
      }
    />
    <FormHelperText>
      {(touched?.BB && errors?.BB)}
    </FormHelperText>
    <TextField
      id={`capstone.${index}.param.GV`}
      name={`capstone.${index}.param.GV`}
      type="number"
      label="GV"
      className={classes.formControl}
      onChange={handleChange}
      onBlur={handleBlur}
      value={values.GV}
      error={
        touched?.GV &&
          Boolean(errors?.GV)
      }
    />
    <FormHelperText>
      {(touched?.GV && errors?.GV)}
    </FormHelperText>
  </>
}
import { Component } from 'react';
import type {RootState} from '../../redux/reducers';
import type { ConnectedProps} from 'react-redux';
import {connect} from 'react-redux';
import type {Column} from 'material-table';
import {Box, Button, CircularProgress, Dialog, DialogContent, Grid} from '@material-ui/core';
import MaterialTable from '../../components/MaterialTable';
import AddChallengeDialog from "./AddChallengeDialog";
import {getChallengesAsync} from '../../redux/player-challenges/challenges/actions';
import {getChallengeTriggersAsync} from '../../redux/player-challenges/challenge-templates/actions';
import type {Challenge} from '../../services/player-challenges/challenges';
import type {ChallengeTrigger, Template} from '../../services/player-challenges/challenge-templates';
import DialogTitle from "@material-ui/core/DialogTitle";

interface OwnProps {
  onClose: () => void;
  onSelect?: (items: Challenge) => void;
}

const mapStateToProps = (state: RootState) => {
  return ({
    challenges: state.challenges,
    templates: state.challengeTemplates
  });
}
const mapDispatcher = {
  requestGetChallengesAsync: getChallengesAsync.request,
  requestLatestChallengeTriggers: getChallengeTriggersAsync.request,
};
const connector = connect(mapStateToProps, mapDispatcher);

type Props = OwnProps & ConnectedProps<typeof connector>;

interface State {
  addChallengeDialogOpen: boolean;
  open: boolean;
}

class GlobalChallengeSelector extends Component<Props, State> {
  private templateMap: { [index: string]: Template } = {};
  private columns: Column<Challenge>[] = [
    {
      title: 'Name', field: 'challenge_name', defaultSort: 'asc'
    },
    {title: 'Description', field: 'challenge_desc'},
    {
      title: 'Template Name', field: 'template_id',
      render: (template) => this.templateMap[template.template_id] ? this.templateMap[template.template_id].template_name : template.template_id,
      customSort: (a, b) => this.templateMap[a.template_id].template_name.localeCompare(this.templateMap[b.template_id].template_name)
    },
    {title: 'Version', field: 'template_version'}
  ];

  constructor(props: Props) {
    super(props);
    if (this.props.templates.latestChallengeTriggers instanceof Array) {
      this.props.templates.latestChallengeTriggers?.forEach((trigger: ChallengeTrigger) => {
        if(trigger.template){
          this.templateMap[trigger.template.template_id] = trigger.template;
        }
      });
    }

    this.state = {
      addChallengeDialogOpen: false,
      open: true,
    };
  }

  onSelectRow = (item: Challenge | undefined) => {
    if (this.props.onSelect && item) {
      this.props.onSelect(item);
    }
    this.setState({open: false});
  };

  render() {
    if (this.props.templates.isLoading && this.props.challenges.isLoading && this.props.challenges.data.length < 1 && !this.state.addChallengeDialogOpen) {
      return (
        <Box textAlign="center">
          <CircularProgress/>
        </Box>
      );
    }

    return (<>
      <Dialog
        open={this.state.open}
        onClose={() => this.setState({open: false})}
        onExited={this.props.onClose}
        fullWidth
        maxWidth="md"
      >
        <DialogTitle>Select Challenge</DialogTitle>
        <DialogContent>

          {/*{UserService.canCreate('season') && (*/}
          <Box mb={2}>
            <Grid container alignItems="center">
              <Grid item xs/>
              <Grid item>
                <Button variant="contained" color="primary" onClick={() => this.setState({addChallengeDialogOpen: true})}>
                  Create New Challenge
                </Button>
              </Grid>
            </Grid>
          </Box>
          {/*)}*/}

          <MaterialTable
            title="Challenges"
            columns={this.columns}
            data={this.props.challenges.data}
            options={{
              pageSize: 10
            }}
            onRowClick={(event, item) => this.onSelectRow(item)}
          />

          {this.state.addChallengeDialogOpen && (
            <AddChallengeDialog
              onClose={() => this.setState({addChallengeDialogOpen: false})}
            />
          )}
        </DialogContent>
      </Dialog>

    </>);
  }
}

export default connector(GlobalChallengeSelector);
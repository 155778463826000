import { useEffect, useState } from 'react';
import { Box, Grid, Typography } from '@material-ui/core';
import { PlayersService } from '../players';
import MaterialTable from '../../../components/MaterialTable';

interface RewardsProps {
  playerId: string;
}

export const Rewards = ({ playerId }: RewardsProps) => {
  const [rewards, setRewards] = useState<EntitlementFulfillment[]>([]);

  useEffect(() => {
    PlayersService.getEntitlementFulfillments(playerId).then(setRewards);
  }, [playerId])

  return (<>
    <Box mb={2}>
      <Grid container spacing={2} alignItems="center">
        <Grid item xs>
          <Typography variant="h6">Rewards / Entitlement Fulfillments</Typography>
        </Grid>
      </Grid>
    </Box>
    <MaterialTable
      columns={[
        { title: 'Rewards ID', field: 'entitlementRewardsId', defaultSort: 'asc' },
        { title: 'Item ID', field: 'itemId', defaultSort: 'asc' },
        { title: 'Catalog', field: 'catalogName', defaultSort: 'asc' },
        { title: 'State', field: 'state', defaultSort: 'asc' },
        { title: 'Service', field: 'serviceType', defaultSort: 'asc' },
      ]}
      data={rewards}
      title="Claimed Rewards"
      storageId="claimed-rewards"
      options={{
        columnsButton: true,
        pageSize: 20,
        pageSizeOptions: [20, 50, 100],
        thirdSortClick: false
      }}
    />
  </>)
}

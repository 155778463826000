import type { MigrationDiffCommonProps } from '../../migrations';
import { DiffsPanels } from '../../components/diff/DiffsPanels';
import type { BlankoAssetDiff } from '../../../../pages/CatalogMigration/types';

export const BlankoAssetsDiffs = (props: MigrationDiffCommonProps) => {
  const diffs = props.migrationData.blankoAssets;

  if (diffs) {
    return (
      <DiffsPanels
        diffs={diffs}
        columns={[
          { title: 'Name', field: 'name', defaultSort: 'asc' },
          {
            title: 'Platform',
            field: 'platform',
            render(item: BlankoAssetDiff) {
              return <>{item.platform}, {item.minPlatformVersion}</>
            }
          }
        ]}
        selectionMap={props.selections.blankoAsset || {}}
        onSelectionChange={map => props.onSelectionsChange({ ...props.selections, blankoAsset: map })}
        {...props}
      />
    )
  }

  return null;
}

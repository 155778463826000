import axios from 'axios';
import fp from 'lodash/fp';
import type { ApiError } from './api';
import { api } from './api';
import type { Artist } from './model/artist';

export type ArtistIdentification = {
  id: string;
}

export type ArtistUpdateRequest = ArtistIdentification & {
  artist: Artist;
};

export type ArtistGetImageResponse = {
  filename: string;
  url: string;
};

export type ArtistUploadImageRequest = ArtistGetImageResponse & {
  image: File;
}

/** 
 * Provide an operation to handle coherent error messaging.
 * 
 * @example onError("GET artists") => Error(`Failed to GET artists. ${original error message}`).
 */
const onError = (operation: string) => (e: ApiError) => {
  e.message = `Failed to ${operation}. ${e.message}`;
  throw e;
}

/** Helper function for {@link getArtists} that translates the artists array into a map structure to fetch by id. */
const normalize = () => fp.reduce<Artist, Map<string, Artist>>((normalized, current) => {
  normalized.set(current.id, current);
  return normalized;
}, new Map<string, Artist>())

export const getArtists = (): Promise<Map<string, Artist>> => api.get<Artist[]>({
  url: "/artists"
}).then(normalize()).catch(onError("get artists"));

export const createArtist = (artist: Omit<Artist, 'id'>): Promise<Artist> => api.post({
  url: "/artists",
  body: artist
}).catch(onError("create artist"));

export const updateArtist = ({ id, artist }: ArtistUpdateRequest): Promise<Artist> => api.put<Artist>({
  url: `/artists/${id}`,
  body: artist
}).catch(onError("update artist"));

export const deleteArtist = ({ id }: ArtistIdentification): Promise<null> => api.delete({
  url: `/artists/${id}`
}).catch(onError("delete artist"));

/** Helper function for {@link uploadArtistImage} to get the existing artist image info. */
const getArtistImage = (imageMimeType: string) => api.get<ArtistGetImageResponse>({
  url: '/artists/image-upload-url',
  query: {
    mimeType: imageMimeType
  }
}).catch(onError("get artist image"));

/** Helper function for {@link uploadArtistImage} to do the upload with all necessary data. */
const uploadArtistImageToUrl = ({ url, filename, image }: ArtistUploadImageRequest): Promise<string> => {
  const headers = new Headers({
    'Content-Type': image.type
  });

  return axios.put(url, image, { headers }).then(fp.constant(filename)).catch(onError("upload artist image"));
}

export const uploadArtistImage = (image: File): Promise<string> => getArtistImage(image.type).then(
  (existingImageProps) => uploadArtistImageToUrl({ image, ...existingImageProps })
).catch(onError("upload artist image"));

import _ from "lodash";
import fp from "lodash/fp";
import { useCallback } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { usePushNotification } from "../../contexts/AppNotificationContext";
import type { ApiError } from "../../services/api";
import type { ArtistIdentification } from "../../services/artists";
import { createArtist, deleteArtist, getArtists, updateArtist, uploadArtistImage } from "../../services/artists";
import type { Artist } from "../../services/model/artist";
import { useErrorHandler } from "./useErrorHandler";

type ArtistMap = Map<string, Artist>;

type ArtistContext = {
  previousArtists: ArtistMap;
}

/** 
 * This will handle updating the local data storage and reverting it if the server-side update fails.
 * See https://react-query.tanstack.com/guides/optimistic-updates
*/
const useArtistsOptimisticCache = () => {
  const queryClient = useQueryClient();
  const dispatchError = useErrorHandler();

  const onMutateMap = useCallback(<T extends unknown[]>(mapArtists: (previousArtists: ArtistMap, ...mapArgs: T) => ArtistMap | undefined) =>
    async (...args: T): Promise<ArtistContext | undefined> => {
      await queryClient.cancelQueries([ARTIST_BASE_KEY]);
      const previousArtists = queryClient.getQueryData<ArtistMap>([ARTIST_BASE_KEY, "GET"]);
      if (previousArtists === undefined) return;
      const newArtists = mapArtists(previousArtists, ...args);
      if (newArtists === undefined) return;
      queryClient.setQueryData<ArtistMap>([ARTIST_BASE_KEY, "GET"], newArtists);
      return { previousArtists }
    }, [queryClient]);

  const onError = useCallback((err: ApiError, _variables: unknown, context: ArtistContext | undefined) => {
    dispatchError(err);
    const previousArtists = context?.previousArtists;
    if (_.isEmpty(previousArtists) || previousArtists === undefined) return;
    queryClient.setQueryData<ArtistMap>([ARTIST_BASE_KEY, "GET"], previousArtists);
  }, [dispatchError, queryClient]);

  const onSettled = () => queryClient.invalidateQueries([ARTIST_BASE_KEY, "GET"]);

  return { onMutateMap, onError, onSettled }
}

/** The base cache key for react-query requests around artists. */
export const ARTIST_BASE_KEY = "artists";

export const useArtists = <TData = ArtistMap>({ select }: { select?: (data: ArtistMap) => TData } = {}) => {
  const dispatchError = useErrorHandler();

  return useQuery<ArtistMap, ApiError, TData>([ARTIST_BASE_KEY, "GET"], getArtists, {
    placeholderData: new Map(),
    staleTime: Infinity,
    onError: dispatchError,
    select
  });
}

export const useArtistsSortedByName = () => useArtists({ select: (artistMap) => {
  const values = [...artistMap.values()];
  return values.sort((a, b) => a.name.localeCompare(b.name));
}})

/** Create a new artist and notify the app on success/failure. */
export const useCreateArtist = () => {
  const NEW_ARTIST_KEY = "$new_artist"
  const pushNotification = usePushNotification();
  const queryClient = useQueryClient();
  const { onMutateMap, onError, onSettled } = useArtistsOptimisticCache();

  return useMutation<Artist, ApiError, Omit<Artist, 'id'>, ArtistContext>(
    [ARTIST_BASE_KEY, "CREATE"], 
    createArtist, 
    {
      onMutate: onMutateMap((previousArtists, artist) => {
        const newArtist: Artist = {
          id: NEW_ARTIST_KEY,
          ...artist
        }
        const newArtists = new Map(previousArtists);
        newArtists.set(newArtist.id, newArtist);
        return newArtists;
      }),

      onError: _.flow(
        fp.tap(() => pushNotification({ type: 'error', message: 'Failed to create new artist' })), 
        onError,
        // remove the temporary artist from the query data
        fp.tap(() => {
          const artists = queryClient.getQueryData<ArtistMap>([ARTIST_BASE_KEY]);
          if (!artists?.has(NEW_ARTIST_KEY)) return;
          artists.delete(NEW_ARTIST_KEY);
        }),
      ),

      onSuccess: ({ name }) => {
        pushNotification({ type: 'success', message: `Successfully added artist ${name}`});
      },

      onSettled
    })
}

/** Update an Artist and notify the app on success/failure. */
export const useUpdateArtist = () => {
  const pushNotification = usePushNotification();
  const { onMutateMap, onError, onSettled } = useArtistsOptimisticCache();

  return useMutation<Artist, ApiError, Artist, ArtistContext>(
    [ARTIST_BASE_KEY, 'UPDATE'], 
    (artist) => updateArtist({ id: artist.id, artist }), 
    {
      onMutate: onMutateMap((previousArtists, artist) => {
        const newArtists = new Map(previousArtists);
        newArtists.set(artist.id, artist);
        return newArtists;
      }),

      // flow and tap don't behave nicely with args
      onError: (...args) => {
        const [_error, { name }] = args;
        pushNotification({ type: 'error', message: `Failed to update artist ${name}` });
        onError(...args);
      },
  
      onSuccess: ({ name }) => {
        pushNotification({ type: 'success', message: `Successfully updated artist ${name}`});
      },
  
      onSettled
    });
}

/** Deletes an artist by id and notifies the app on success/failure. */
export const useDeleteArtist = () => {
  const queryClient = useQueryClient();
  const { onMutateMap, onError, onSettled } = useArtistsOptimisticCache();
  const push = usePushNotification();

  return useMutation<null, ApiError, ArtistIdentification, ArtistContext>(
    [ARTIST_BASE_KEY, "DELETE"], 
    deleteArtist, 
    {
      onMutate: onMutateMap((previousArtists, { id }) => {
        const newArtists = new Map(previousArtists);
        newArtists.delete(id);
        return newArtists;
      }),

      onError: (...args) => {
        const [_error, { id }] = args;
        // grab the name from the cache for error message if possible 
        const artists = queryClient.getQueryData<ArtistMap>([ARTIST_BASE_KEY, "GET"]);
        const name = artists?.get(id)?.name;
        const artistStr = name ?? `id:${id}`;
        push({ type: 'error', message: `Failed to delete artist ${artistStr}`});
        onError(...args);
      },

      onSuccess: (_data, { id }, { previousArtists }) => {
        const old = previousArtists.get(id);
        const name = (old !== undefined) ? ` ${old.name}` : undefined;
        push({ type: 'success', message: `Successfully deleted artist${name}`});
      },

      onSettled
    })
}

export const useUploadArtistImage = () => {
  const pushNotification = usePushNotification();
  const onError = useErrorHandler();
  return useMutation<string, ApiError, File>(
    [ARTIST_BASE_KEY, "UPLOAD_IMAGE"], 
    uploadArtistImage, 
    {
      onError,
      onSuccess: (path) => {
        pushNotification({ type: "success", message: `Successfully uploaded image to path ${path}`});
      }
    });
}
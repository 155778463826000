export interface BranchList {
  project_id: string;
  branch: string;
  branches: Branch[];
}

export interface Branch {
  branch_id: number;
  name: string;
  created_at: string;
  created_at_timestamp: number;
  created_by: number;
  created_by_email: string;
}

export interface LokaliseKey {
  key_name: string | { [key in LokalisePlatform]?: string; };
  description?: string;
  platforms: LokalisePlatform[];
  filenames: { [key in LokalisePlatform]?: string; };
  tags?: string[];
  translations: LokaliseTranslation[];
}

export interface LokaliseTranslation {
  language_iso: string;
  translation: string;
}

export enum LokalisePlatform {
  ios = "ios",
  android = "android",
  web = "web",
  other = "other",
}

export interface CreateKeysResponse {
  project_id: string;
  branch: string;
  keys: LokaliseKey[];
  errors: CreateKeyError[];
}

export interface CreateKeyError {
  key_name: { [key in LokalisePlatform]?: string; };
  message: string;
  code: number;
}

export interface LokaliseFile {
  file_id: number;
  filename: string;
  key_count: number;
}

export interface LokaliseFileListResponse {
  project_id: string;
  files: LokaliseFile[];
}

export interface SimpleKey {
  keyName: string;
  description?: string;
  baseTranslation: string;
}

export interface Branches {
  defaultLiveBranch: string;
  branches: BranchInfo[];
}

export interface BranchInfo {
  branchName: string;
  live: boolean;
  canary: boolean;
  lastSync: number;
  playerEmails: string[];
}

import { Component } from 'react';
import type { Store, StoreTab} from '../../services/stores';
import { StoresService } from '../../services/stores';
import type { EditComponentProps, Column } from 'material-table';
import { TextField } from '@material-ui/core';
import MaterialTable from '../../components/MaterialTable';
import { UserService } from '../../services/user';
import { arrayReplaceOrPush, arrayRemove } from '../../redux/utils-ts';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';

interface Props {
  catalogName: string;
  store: Store;
}

interface State {
  tabs: StoreTab[];
}

export default class StoreTabsEditor extends Component<Props, State> {
  private columns: Column<StoreTab>[] = [
    {
      title: 'Name',
      field: 'name',
      sorting: false,
      editComponent: props => <TabNameInputComponent {...props} />
    }
  ];

  constructor(props: Props) {
    super(props);
    this.state = {
      tabs: props.store.tabs
    };
  }

  createTab = (tab: StoreTab) => {
    return StoresService.createStoreTab(this.props.catalogName, this.props.store, tab.name)
      .then(newTab => {
        const tabs = this.state.tabs.slice();
        tabs.push(newTab);
        this.setState({ tabs });
        this.props.store.tabs = tabs;
      });
  }

  updateTab = (tab: StoreTab, oldTab?: StoreTab) => {
    if (!oldTab) {
      return Promise.reject();
    }

    return StoresService.updateStoreTab(this.props.catalogName, this.props.store, oldTab.name, tab)
      .then(updatedTab => {
        const tabs = arrayReplaceOrPush(this.state.tabs, updatedTab, t => t.name === oldTab.name);
        this.setState({ tabs });
        this.props.store.tabs = tabs;
      });
  }

  deleteTab = (tab: StoreTab) => {
    return StoresService.deleteStoreTab(this.props.catalogName, this.props.store, tab.name)
      .then(() => {
        const tabs = arrayRemove(this.props.store.tabs, tab);
        this.setState({ tabs });
        this.props.store.tabs = tabs;
      });
  }

  moveTab = (tab: StoreTab, dir: number) => {
    if ((tab.position < 1 && dir < 0) || (tab.position >= this.props.store.tabs.length - 1 && dir > 0)) {
      return;
    }

    const tabs = this.props.store.tabs.slice();
    tabs[tab.position] = tabs[tab.position + dir];
    tabs[tab.position].position = tab.position;
    tabs[tab.position + dir] = tab;
    tab.position += dir;
    this.setState({ tabs });
    this.props.store.tabs = tabs;
    StoresService.updateStoreTab(this.props.catalogName, this.props.store, tab.name, tab);
  }

  render() {
    return (
      <MaterialTable
        title="Manage tabs"
        columns={this.columns}
        data={this.state.tabs}
        actions={[
          tab => ({
            icon: () => <ArrowUpwardIcon />,
            tooltip: 'Move tab',
            onClick: () => this.moveTab(tab, -1),
          }),
          tab => ({
            icon: () => <ArrowDownwardIcon />,
            tooltip: 'Move tab',
            onClick: () => this.moveTab(tab, 1),
          })
        ]}
        options={{
          paging: false,
          search: false,
          toolbarButtonAlignment: 'left'
        }}
        editable={{
          onRowAdd: UserService.canCreate('store') ? this.createTab : undefined,
          onRowUpdate: UserService.canUpdate('store') ? this.updateTab : undefined,
          onRowDelete: UserService.canDelete('store') ? this.deleteTab : undefined
        }}
        localization={{
          body: {
            editRow: {
              deleteText: 'Delete tab?'
            }
          }
        }}
      />
    )
  }
}

interface TabNameInputState {
  name: string;
  error: string;
}

class TabNameInputComponent extends Component<EditComponentProps<StoreTab>, TabNameInputState> {
  constructor(props: EditComponentProps<StoreTab>) {
    super(props);
    this.state = {
      name: props.rowData.name || '',
      error: ''
    };
  }

  onChange = (event: any) => {
    const name = (event.target.value as string) || '';
    let error = '';
    if (!name.trim()) {
      error = 'Cannot be blank';
    }
    this.setState({ name, error });
    this.props.onChange(name);
  };

  render() {
    return (
      <TextField
        fullWidth
        autoFocus
        value={this.state.name}
        onChange={this.onChange}
        error={!!this.state.error}
        helperText={this.state.error}
        inputProps={{
          maxLength: 100
        }}
      />
    );
  }
}
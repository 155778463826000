import { IconButton, InputAdornment, TextField, Tooltip } from '@material-ui/core';
import FolderIcon from '@material-ui/icons/Folder';
import { createRef, Component } from 'react';

export interface JsonFileUploadValue {
  file?: File;
  json?: any;
  error?: string;
}

interface Props {
  label?: string;
  value: JsonFileUploadValue;
  onChange: (json: JsonFileUploadValue) => void;
}

export default class JsonFileUpload extends Component<Props> {
  private inputRef = createRef<HTMLInputElement>();

  onFileChange = () => {
    if (this.inputRef.current && this.inputRef.current.files && this.inputRef.current.files.length > 0) {
      const file = this.inputRef.current.files[0];
      let error = '';
      if (file.size > 10 * 1024 * 1024) {
        error = 'File cannot be larger than 10MB';
      }

      if (!error) {
        const fileReader = new FileReader();
        fileReader.onload = () => {
          try {
            const json = typeof fileReader.result === 'string' ? JSON.parse(fileReader.result) : null;
            if (json && typeof json === 'object') {
              this.props.onChange({ file, json, error });
            } else {
              throw new Error();
            }
          } catch (e) {
            this.props.onChange({ file, error: 'Not a valid JSON file' });
          }
        }
        fileReader.readAsText(file);
      }
    }
  }

  render() {
    const value = this.props.value;

    return (<>
      <input
        type="file"
        accept="application/json"
        style={{ display: 'none' }}
        ref={this.inputRef}
        onChange={this.onFileChange}
      />
      <TextField
        label={this.props.label || 'File name'}
        fullWidth
        InputProps={{
          readOnly: true,
          endAdornment: (
            <InputAdornment position="end">
              <Tooltip title="Select file...">
                <IconButton onClick={() => this.inputRef.current && this.inputRef.current.click()}>
                  <FolderIcon />
                </IconButton>
              </Tooltip>
            </InputAdornment>
          )
        }}
        value={value.file ? value.file.name : ''}
        error={!!value.error}
        helperText={value.error}
      />
    </>);
  }
}
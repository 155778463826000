import { Link } from '@material-ui/core';
import type { Column } from 'material-table';
import { Link as RouterLink } from 'react-router-dom';
import MaterialTable from '../../components/MaterialTable';
import config from '../../config';
import type { Blanko } from '../../services/blankos';
import type { Player } from '../players/players';
import { UserService } from '../../services/user';
import { BlankosService } from '../../services/blankos';
import { useState, useEffect, useCallback } from 'react';

interface OwnProps {
  player?: Player;
}

export const BlankosList = ({ player }: OwnProps) => {

  const [blankos, setBlankos] = useState<Blanko[]>([]);
  const [isLoading, setIsLoading] = useState(true);

  const columns: Column<Blanko>[] = [
    {
      title: 'ID',
      field: 'id',
      hidden: config.env === 'prod'
    },
    {
      title: 'Name',
      field: 'name',
      render: blanko => (
        <Link component={RouterLink} to={`/blankos/${blanko.id}`}>
          {blanko.name || blanko.id}
        </Link>
      )
    },
    {
      title: 'dGood #',
      field: 'dgoodSerial',
      render: blanko => <>{blanko.dgoodSerial || '-'}</>
    },
    {
      title: 'Owner',
      field: 'ownerName',
      render: blanko => (!player ?
        (UserService.canRead('player') ?
          <Link component={RouterLink} to={`/players/${blanko.ownerExternalId}`}>
            {blanko.ownerName || blanko.ownerExternalId}
          </Link>
          : <>{blanko.ownerName || blanko.ownerExternalId}</>)
        : <></>)
    },
    { title: 'XP', field: 'experience' },
    {
      title: 'Shelf Location',
      field: 'shelfLocation',
      defaultSort: 'asc',
      hidden: !player
    }
  ];

  const canDeleteBlankos = useCallback(() => {
    return player && UserService.canDelete('blankos') && (config.env === 'local' || config.env === 'dev' || config.env === 'qa');
  }, [player]);

  const getData = useCallback(async () => {
    const result = await BlankosService.getBlankos(player ? player.externalId : undefined);
    setBlankos(result);
    setIsLoading(false);
  }, [player]);

  useEffect(() => {
    getData();
  }, [getData]);

  return (<>
    <MaterialTable
      title="Blankos"
      storageId="blankos-list"
      columns={columns}
      data={blankos}
      isLoading={isLoading}
      options={{
        columnsButton: true,
        pageSize: 20,
        pageSizeOptions: [20, 50, 100]
      }}
      localization={{
        body: {
          editRow: {
            deleteText: 'Delete blanko?'
          }
        }
      }}
      editable={canDeleteBlankos() ? {
        onRowDelete: blanko => {
          BlankosService.deleteBlanko(blanko.id);
          return Promise.resolve();
        }
      } : undefined}
      useRouter
    />
  </>)
}

import { Box, Button, CircularProgress, Grid, Link, Typography } from '@material-ui/core';
import DeleteIcon from "@material-ui/icons/Delete";
import type { Column } from 'material-table';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import type { RouteComponentProps } from 'react-router-dom';
import MaterialTable from '../../../components/MaterialTable';
import type { MenuAction } from '../../../components/MaterialTable';
import type { BrawlPin, BrawlPinUnlockCondition } from '../../../services/model/brawl-pin';
import { BrawlPinsService } from '../../../services/brawl-pins';
import type { ApiError } from '../../../services/api';
import { UserService } from '../../../services/user';
import { openAdminConfirmationDialog } from '../../../shared/hooks/useAdminConfirmationDialog';
import { history } from '../../../redux/store';
import { AddBrawlPinDialog } from './AddBrawlPinDialog';
import { getTierRewardIds } from './BrawlPinTiers';
import { usePushNotification } from '../../../contexts/AppNotificationContext';
import { getUnlockConditionParams } from './UnlockConditions';
import { BrawlPinTierViewModel, getTierViewModels } from './BrawlPinsViewModels';

type Props = RouteComponentProps;

export const BrawlPins = (props: Props) => {
  const pushNotification = usePushNotification();

  const [brawlPins, setBrawlPins] = useState<BrawlPin[]>([]);
  const [showAddDialog, setShowAddDialog] = useState(false);
  const [needToUpdatePins, setNeedToUpdatePins] = useState(true);

  const conditionTableColumns: Column<BrawlPinUnlockCondition>[] = useMemo(() => [
    {
      title: 'Unlock Type',
      field: 'unlockType',
    },
    {
      title: 'Badge Check Type',
      field: 'badgeCheckType',
    },
    {
      title: 'Game Type',
      field: 'gameType',
    },
    {
      title: 'Parameters',
      render: condition => (
        <Grid container spacing={1} alignItems="center">
          {getUnlockConditionParams(condition)}
        </Grid>
      )
    },
  ], []);

  const tierTableColumns: Column<BrawlPinTierViewModel>[] = useMemo(() => [
    {
      title: 'Level',
      field: 'tier.level',
    },
    {
      title: 'Reward Bundle',
      render: tier => (
        <Grid container spacing={1} alignItems="center">
          {getTierRewardIds(tier)}
        </Grid>
      )
    },
    {
      title: 'Unlock Condition Groups',
      render: tierViewModel => (<>
        <Grid item xs={12}>
          {tierViewModel.unlockConditionGroups?.map((conditionGroup, index) => (
            <Box
              key={`condition_group_${index}`}
              mt={index > 0 ? 2 : 0}
            >
              <MaterialTable
                columns={conditionTableColumns}
                data={conditionGroup}
                options={{
                  paging: false,
                  search: false,
                  toolbar: false,
                  sorting: false,
                  showTitle: false
                }}
              />
            </Box>
          ))}
        </Grid>
      </>
      )
    },
  ], [conditionTableColumns]);

  const columns: Column<BrawlPin>[] = useMemo(() => [
    {
      title: 'Name',
      field: 'name',
      defaultSort: 'asc',
      render: pin => (
        <Grid container spacing={1} alignItems="center">
          <Link component={RouterLink} to={`/brawl-pins/${pin.id}`}>
            {pin.name}
          </Link>
        </Grid>
      )
    },
    {
      title: 'Brawl Mode',
      field: 'brawlMode',
    },
    {
      title: 'Award Type',
      field: 'awardType',
    },
    {
      title: 'Game Type',
      field: 'gameType',
    },
    {
      title: 'Tiers',
      render: pin => (
        <MaterialTable
          columns={tierTableColumns}
          data={getTierViewModels(pin)}
          options={{
            paging: false,
            search: false,
            toolbar: false,
            sorting: false,
            showTitle: false
          }}
        />
      )
    }
  ], [tierTableColumns]);

  const menuActions: MenuAction<BrawlPin>[] = useMemo(() => {
    const deleteBrawlPin = (pin: BrawlPin) => {
      openAdminConfirmationDialog({
        title: `Delete ${pin.name}?`,
        action: 'Delete',
        onConfirm: () => {
          BrawlPinsService.deleteBrawlPin(pin.id)
            .then(() => {
              if (brawlPins) {
                const existing = [...brawlPins].filter(v => v.id !== pin.id);
                setBrawlPins(existing);
              }
            })
            .catch(err => pushNotification({ type: 'error', message: `Failed to delete reward: ${err.message}` }));
        }
      });
    };

    const menuActions: MenuAction<BrawlPin>[] = [];
    if (UserService.canDelete('brawlPins')) {
      menuActions.push({type: 'button', icon: DeleteIcon, label: 'Delete', onClick: deleteBrawlPin});
    }
    return  menuActions;
  }, [brawlPins, pushNotification]);

  const onPublish = useCallback(() => {
    history.push('/brawl-pins-publish');
  }, []);

  const handleClose = useCallback(() => {
    setShowAddDialog(false);
  }, [])

  useEffect(() => {
    if (needToUpdatePins) {
      setNeedToUpdatePins(false);
      BrawlPinsService.getBrawlPins().then(setBrawlPins)
        .catch((e: ApiError) => {
          e.message = `Failed to get brawl pins. ${e.message}`;
          throw e;
        });
    }
  }, [needToUpdatePins]);

  if (!brawlPins) {
    return <Box textAlign="center">
      <CircularProgress/>
    </Box>;
  }

  return (<>
    <Box mb={2}>
      <Grid container spacing={2} alignItems="center">
        <Grid item xs>
          <Typography variant="h6">
            Brawl Pins
          </Typography>
        </Grid>
        {UserService.canCreate('brawlPins') && (<>
          <Grid item>
            <Button variant="contained" color="primary" onClick={() => onPublish()}>Publish</Button>
          </Grid>
          <Grid item>
            <Button variant="contained" color="primary" onClick={() => setShowAddDialog(true)}>Add Brawl Pin</Button>
          </Grid>
        </>)}
      </Grid>
    </Box>

    <MaterialTable<BrawlPin>
      title=""
      columns={columns}
      data={brawlPins}
      options={{
        debounceInterval: 500,
        pageSize: 20,
        pageSizeOptions: [10, 20, 50, 100],
        thirdSortClick: false
      }}
      menuActions={menuActions}
      useRouter
    />

    {showAddDialog && <AddBrawlPinDialog
      onSave={() => {
        setNeedToUpdatePins(true);
        handleClose();
      }}
      onCancel={handleClose}
    />}

  </>);
};
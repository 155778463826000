import type { DiffItem } from '../../../pages/CatalogMigration/types';
import type { MigrationModule } from '../migrations';
import { titleDataPublish } from './brawl-pin-title-data/title-data-publish';
import { MigrationType } from "./migration-type";

export const brawlPinPublishModules: { [key in MigrationType]?: MigrationModule<DiffItem> } = {
  brawlPinTitleData: titleDataPublish,
};

// Publish modules, in run order. Dependencies matter here.
// TODO: Figure out a way to calculate this based on dependencies.
export const brawlPinPublishRunOrder = [MigrationType.brawlPinTitleData];

// Dev sanity check
Object.values(MigrationType).forEach(key => {
  const migrationModule = brawlPinPublishModules[key];
  if (!migrationModule) {
    return;
  }
  if (key !== migrationModule.id) {
    console.error(`Module key does not match the module ID: ${key} vs. ${migrationModule.id}`);
  }

  if (brawlPinPublishRunOrder.indexOf(key) < 0) {
    console.error(`Publish module not included in run order: ${key}`);
  }
});

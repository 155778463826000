import { Component } from 'react';
import type {RouteComponentProps} from 'react-router';
import type { ConnectedProps } from 'react-redux';
import { connect } from 'react-redux';
import type { RootState } from '../../redux/reducers';
import {UserService} from '../../services/user';

import type {LinkTabConfig} from '../../components/LinkTabs';
import { LinkTabs} from '../../components/LinkTabs';
import { Box, CircularProgress } from '@material-ui/core';
import SeasonChallengeSchedule from './SeasonChallengeSchedule';

import {ScheduleCalendarTypeEnum} from "../../services/player-challenges/season-challenge-schedule-calendar";
import { getLatestChallengeTemplateAsync } from '../../redux/player-challenges/challenge-templates/actions';

const mapStateToProps = (state: RootState) => ({
  templates: state.challengeTemplates
});

const mapDispatch = {
  requestLatestChallengeTemplate: getLatestChallengeTemplateAsync.request
};

const connector = connect(mapStateToProps, mapDispatch);

type Props = RouteComponentProps & ConnectedProps<typeof connector>;

interface State {
  seasonNumber: number;
}

class SeasonChallengeSchedulePage extends Component<Props, State> {
  private tabs: LinkTabConfig[];

  constructor(props: Props) {
    super(props);
    const params: any = this.props.match.params;
    const seasonNumber = parseInt(params.seasonNumber);
    this.state = {
      seasonNumber: seasonNumber
    }

    this.tabs = [];
    if (UserService.canRead('seasons')) {
      this.tabs.push({url: '/challenge-schedule', label: 'Daily', absolute: false, render: () => <SeasonChallengeSchedule {...props} scheduleType={ScheduleCalendarTypeEnum.DAILY}/>});
    }
    if (UserService.canRead('seasons')) {
      this.tabs.push({url: '/challenge-schedule/weekly', label: 'Weekly', absolute: false, render: () => <SeasonChallengeSchedule {...props} scheduleType={ScheduleCalendarTypeEnum.WEEKLY}/>});
    }
    if (UserService.canRead('seasons')) {
      this.tabs.push({url: '/challenge-schedule/seasonal', label: 'Seasonal', absolute: false, render: () => <SeasonChallengeSchedule {...props} scheduleType={ScheduleCalendarTypeEnum.SEASONAL}/>});
    }
  }

  componentDidMount() {
    this.props.requestLatestChallengeTemplate();
  }

  render() {
    if (!this.props.templates.latestChallengeTemplate || this.props.templates.isLoading) {      
      return (
        <Box textAlign="center">
          <CircularProgress />
        </Box>
      );
    }

    return (
      <LinkTabs tabs={this.tabs} {...this.props} />
    )
  }
}

export default connector(SeasonChallengeSchedulePage);
import type { MigrationDiffCommonProps } from '../../migrations';
import { DiffsPanels } from '../../components/diff/DiffsPanels';

export const BlankoClassDiffs = (props: MigrationDiffCommonProps) => {

  const diffs = props.migrationData.blankoClasses;

  if (diffs) {
    return (
      <DiffsPanels
        diffs={diffs}
        columns={[
          {
            title: 'Name', field: 'name', defaultSort: 'asc', render(item) {
              return (<>
                {item.diffId}
              </>);
            }
          }
        ]}
        selectionMap={props.selections.blankoClass || {}}
        onSelectionChange={map => props.onSelectionsChange({ ...props.selections, blankoClass: map })}
        {...props}
      />
    )
  }

  return null;
}

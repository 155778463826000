import { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { cmSetMigrationActions } from '../../../../redux/catalog-migration/actions';
import type { MigrationActions } from '../../../../redux/catalog-migration/reducer';
import { useTypedSelector } from '../../../../redux/reducers';
import { TitleFileDiff } from '../../types';
import { TitleFiles } from './TitleFiles';

export const TitleFilesContainer = () => {
  const dispatch = useDispatch();

  const sourceFiles = useTypedSelector(state => state.catalogMigration.source.titleFiles);
  const targetFiles = useTypedSelector(state => state.catalogMigration.target.titleFiles);
  const actions = useTypedSelector(state => state.catalogMigration.actions.titleDataFiles);

  const [sourceItems, setSourceItems] = useState<TitleFileDiff[] | null>(null);
  const [targetItems, setTargetItems] = useState<TitleFileDiff[] | null>(null);

  const onSelectionChange = useCallback(
    (actions: MigrationActions) => dispatch(cmSetMigrationActions({ id: 'titleDataFiles', actions })),
    [dispatch]
  );

  useEffect(() => {
    if (sourceFiles && targetFiles) {
      setSourceItems(sourceFiles.map(file => new TitleFileDiff(file)));
      setTargetItems(targetFiles.map(file => new TitleFileDiff(file)));
    }
  }, [sourceFiles, targetFiles]);

  return (<TitleFiles
    sourceItems={sourceItems}
    targetItems={targetItems}
    actions={actions}
    onSelectionChange={onSelectionChange} />);
}

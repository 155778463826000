import { Box, Dialog, DialogContent } from '@material-ui/core';
import { MigrationProgress } from './MigrationProgress';

interface MigrationDialogProps {
  run: CatalogMigrationStep;
  step: CatalogMigrationStep;
}

export const MigrationDialog = ({
  run,
  step
}: MigrationDialogProps) => (
  <Dialog open fullWidth maxWidth="sm">
    <DialogContent>
      <Box textAlign="center">
        Running migration
        <MigrationProgress step={run} />
        <MigrationProgress step={step} />
      </Box>
    </DialogContent>
  </Dialog>
);
import { Box, Button, Card, CardContent, CircularProgress, Grid, InputLabel } from '@material-ui/core';
import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';
import type { FormikHelpers } from 'formik';
import { Field, Formik } from 'formik';
import { CheckboxWithLabel, Select, TextField } from 'formik-material-ui';
import { Component } from 'react';
import type { ConnectedProps } from 'react-redux';
import { connect } from 'react-redux';
import { getTitleAsync, updateMaintenanceAsync } from '../../redux/title-data/actions';
import type { Title } from '../../services/title-data';
import { UserService } from '../../services/user';
import * as yup from 'yup';
import { FormikAdminKeyboardDateTimePicker } from "../../components/AdminKeyboardDateTimePicker";

interface OwnProps {
  title: Title;
}

const mapDispatch = {
  requestGetTitle: getTitleAsync.request,
  requestUpdateMaintenance: updateMaintenanceAsync.request
};

const connector = connect(null, mapDispatch);

type Props = ConnectedProps<typeof connector> & OwnProps;

interface FormValues {
  Enabled: boolean;
  RemoveActivePlayers: boolean;
  StartDateTime: Date;
  EndDateTime: Date;
  Type: string;
  InMaintenanceMessage: string;
  UpcomingMaintenanceMessage: string;
  HideMaintenanceEndTime: boolean;
  RestrictAll: boolean;
}

interface State {
  initialFormValues: FormValues;
}

export const formValidator = yup.object().shape({
  Enabled: yup.boolean(),
  HideMaintenanceEndTime: yup.boolean(),
  RestrictAll: yup.boolean(),
  Type: yup.string(),
  UpcomingMaintenanceMessage: yup
    .string()
    .when("Enabled", {
      is: true,
      then: yup.string().required('Must provide message')
    }),
  InMaintenanceMessage: yup
    .string()
    .when("Enabled", {
      is: true,
      then: yup.string().required('Must provide message')
    }),
  StartDateTime: yup
    .date()
    .when(['Enabled', 'HideMaintenanceEndTime'], {
      is: (Enabled: boolean, HideMaintenanceEndTime: boolean) => {
        return Enabled && !HideMaintenanceEndTime
      },
      then: yup.date()
        .max(yup.ref('EndDateTime'), "Start date should be before end date")
        .required('Must provide start date')
        .typeError('Must provide start date'),
      otherwise: yup.date()
        .when('Enabled', {
          is: true,
          then: yup.date()
            .required('Must provide start date')
            .typeError('Must provide start date'),
          otherwise: yup.date()
            .nullable()
        }),
    }),
  EndDateTime: yup
    .date()
    .when(['Enabled', 'HideMaintenanceEndTime'], {
      is: (Enabled: boolean, HideMaintenanceEndTime: boolean) => {
        return Enabled && !HideMaintenanceEndTime
      },
      then: yup.date()
        .min(yup.ref('StartDateTime'), "End date should be after start date")
        .required('Must provide end date')
        .typeError('Must provide end date'),
      otherwise: yup.date()
        .nullable()
    })
}, [['StartDateTime', 'EndDateTime']]);

class MaintenanceData extends Component<Props, State> {
  private setSubmitting: ((submitting: boolean) => void) | undefined;

  constructor(props: Props) {
    super(props);
    this.state = {
      initialFormValues: this.maintenanceToFormValues(this.props.title.maintenanceData)
    };
  }

  private maintenanceToFormValues(data?: { [key: string]: any; }): FormValues {
    if (data && Object.keys(data).length > 0) {
      return {
        Enabled: data["Enabled"] === "true",
        RemoveActivePlayers: data["RemoveActivePlayers"] === "true",
        StartDateTime: new Date(data["StartDateTime"] * 1000),
        EndDateTime: data.EndDateTime ? new Date(data["EndDateTime"] * 1000) : new Date(),
        Type: data["Type"],
        InMaintenanceMessage: data["InMaintenanceMessage"],
        UpcomingMaintenanceMessage: data["UpcomingMaintenanceMessage"],
        HideMaintenanceEndTime: data["HideMaintenanceEndTime"] === "true",
        RestrictAll: data.RestrictAll !== "false",
      };
    } else {
      return {
        Enabled: false,
        RemoveActivePlayers: false,
        StartDateTime: new Date(),
        EndDateTime: new Date(),
        Type: "SCHEDULED",
        InMaintenanceMessage: "",
        UpcomingMaintenanceMessage: "",
        HideMaintenanceEndTime: false,
        RestrictAll: true,
      };
    }
  }

  componentDidUpdate(prevProps: Props) {
    if (prevProps.title.maintenanceData !== this.props.title.maintenanceData) {
      this.setState({ initialFormValues: this.maintenanceToFormValues(this.props.title.maintenanceData) });
      if (this.setSubmitting) {
        this.setSubmitting(false);
      }
    }
  }


  onSubmit = (values: FormValues, helpers: FormikHelpers<FormValues>) => {
    let maintenanceData: any = {};
    if (values.Enabled) {
      maintenanceData = {
        Enabled: values.Enabled,
        RemoveActivePlayers: values.RemoveActivePlayers,
        StartDateTime: values.StartDateTime ? Math.floor(values.StartDateTime.getTime() / 1000) : new Date(),
        Type: values.Type,
        InMaintenanceMessage: values.InMaintenanceMessage,
        UpcomingMaintenanceMessage: values.UpcomingMaintenanceMessage,
        HideMaintenanceEndTime: values.HideMaintenanceEndTime,
        RestrictAll: values.RestrictAll,
      };

      if (!values.HideMaintenanceEndTime) {
        maintenanceData.EndDateTime = Math.floor(values.EndDateTime.getTime() / 1000);
      }
    }

    this.props.requestUpdateMaintenance(maintenanceData);
    this.setSubmitting = helpers.setSubmitting;
  };

  render() {
    const readOnly = !UserService.canUpdate('title');

    if (!this.props.title) {
      return (
        <Box textAlign="center">
          <CircularProgress />
        </Box>
      );
    } else {
      return (
        <Formik<FormValues>
          initialValues={this.state.initialFormValues}
          enableReinitialize
          validationSchema={formValidator}
          validateOnMount={true}
          onSubmit={this.onSubmit}
        >
          {({ submitForm, values, isSubmitting }) => (
            <Grid container spacing={3}>
              <Grid item xs={12} md={6} xl={4}>
                <Card>
                  <CardContent>
                    <Grid container spacing={1}>
                      <Grid item xs={12}>
                        <Field
                          component={CheckboxWithLabel}
                          type="checkbox"
                          name="Enabled"
                          Label={{label: 'Enabled'}}
                          disabled={readOnly}
                        />
                      </Grid>
                      {values.Enabled && (
                        <Grid item xs={12}>
                          <Field
                            component={CheckboxWithLabel}
                            type="checkbox"
                            name="RemoveActivePlayers"
                            Label={{label: 'Remove Active Players'}}
                            disabled={readOnly}
                          />
                        </Grid>
                      )}
                      {values.Enabled && (
                        <Grid item xs={12}>
                          <Field
                            component={CheckboxWithLabel}
                            type="checkbox"
                            name="RestrictAll"
                            Label={{label: 'Restrict All (Do not allow canary access)'}}
                            disabled={readOnly}
                          />
                        </Grid>
                      )}
                      {values.Enabled && (
                        <Grid item xs={12}>
                          <Field
                            component={CheckboxWithLabel}
                            type="checkbox"
                            name="HideMaintenanceEndTime"
                            Label={{label: 'Hide Maintenance End Time'}}
                            disabled={readOnly}
                          />
                        </Grid>
                      )}
                      {values.Enabled && (
                        <Grid item xs={12}>
                          <Field
                            component={FormikAdminKeyboardDateTimePicker}
                            name="StartDateTime"
                            label="Start Date Time"
                            fullWidth
                            required
                            format="yyyy/MM/dd HH:mm:ss"
                            ampm={false}
                            readOnly={readOnly}
                          />
                        </Grid>
                      )}
                      {values.Enabled && !values.HideMaintenanceEndTime && (
                        <Grid item xs={12}>
                          <Field
                            component={FormikAdminKeyboardDateTimePicker}
                            name="EndDateTime"
                            label="End Date Time"
                            fullWidth
                            required
                            format="yyyy/MM/dd HH:mm:ss"
                            ampm={false}
                            readOnly={readOnly}
                          />
                        </Grid>
                      )}
                      {values.Enabled && (
                        <Grid item xs={12}>
                          <FormControl fullWidth>
                            <InputLabel>Type</InputLabel>
                            <Field
                              component={Select}
                              name="Type"
                              inputProps={{
                                readOnly
                              }}
                            >
                              <MenuItem value="SCHEDULED">Scheduled Maintenance</MenuItem>
                              <MenuItem value="EMERGENCY">Emergency Maintenance</MenuItem>
                              <MenuItem value="CLIENTUPDATE">Client Version Update</MenuItem>
                              <MenuItem value="ENDOFALPHA">End of Alpha</MenuItem>
                            </Field>
                          </FormControl>
                        </Grid>
                      )}
                      {values.Enabled && (
                        <Grid item xs={12}>
                          <Field
                            component={TextField}
                            name="InMaintenanceMessage"
                            type="text"
                            label="Message to show when game launches inside maintenance window"
                            fullWidth
                            required
                            InputProps={{
                              readOnly
                            }}
                          />
                        </Grid>
                      )}
                      {values.Enabled && (
                        <Grid item xs={12}>
                          <Field
                            component={TextField}
                            name="UpcomingMaintenanceMessage"
                            type="text"
                            label="Message to show when maintenance window is opening soon"
                            fullWidth
                            required
                            InputProps={{
                              readOnly
                            }}
                          />
                        </Grid>
                      )}
                    </Grid>
                  </CardContent>
                </Card>
              </Grid>
              {!readOnly && (
                <Grid item xs={12}>
                  <Button
                    variant="contained"
                    color="primary"
                    disabled={isSubmitting}
                    onClick={submitForm}
                  >
                    {isSubmitting ? (
                      <CircularProgress size={25} />
                    ) : 'Save'}
                  </Button>
                </Grid>
              )}
            </Grid>
          )}
        </Formik>

      );
    }
  }
}

export default connector(MaintenanceData);

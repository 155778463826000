import { call, put, takeLatest } from 'redux-saga/effects';
import { CurrencyService } from '../../services/currency';
import { forkSagas, sagaHandleApiError } from '../utils-ts';
import { loadCurrenciesAsync } from './actions';

function* loadCurrenciesSaga() {
  try {
    yield call(CurrencyService.loadCurrencies);
    yield put(loadCurrenciesAsync.success(CurrencyService.getCurrencies()));
  } catch (e) {
    yield sagaHandleApiError(e, loadCurrenciesAsync.failure);
  }
}

const sagas = [
  function* () {
    yield takeLatest(loadCurrenciesAsync.request, loadCurrenciesSaga);
  }
];

export default function* blankosSagas() {
  yield forkSagas(sagas);
}
import { Box, Grid, Typography } from '@material-ui/core';
import type { MigrationResult } from '../../../../redux/catalog-migration/reducer';
import { Result } from './Result';

interface ResultItemProps {
  item: CatalogMigrationItem;
  result?: MigrationResult;
}

export const ResultItem = ({
  item,
  result
}: ResultItemProps) => (
  <Box mt={2} key={item.id}>
    <Grid container spacing={1} alignItems="center">
      <Grid item xs={6} lg={2}>
        <Typography>
          {item.name}
        </Typography>
      </Grid>
      <Grid item xs={6} lg={2}>
        <Result result={result} notImplemented={item.notImplemented} />
      </Grid>
    </Grid>
  </Box>);
import {api, ApiError} from "../api";

export enum PatchStatus {
  None = 0,
  NotStarted = 1,
  Processing = 2,
  Completed = 3,
  Failed = 4
}

export enum ReleaseStatus {
  None = 0,
  Unreleased = 1,
  Canary = 2,
  Live = 3,
  Retired = 4,
  Rejected = 5
}

export class ClientVersion {
  id = '';
  name = '';
  clientName = '';
  clientStorageBlobName = '';
  publishDate = new Date();
  creationDate = new Date();
  signedDownloadUrl = '';
  clientArchiveExecutableName = '';
  notes = '';
  live = false;
  canary = false;
  clientStoragePatchName = '';
  patchStatus = PatchStatus.None;
  releaseStatus = ReleaseStatus.None;

  constructor(json: any) {
    this.id = json.id;
    this.name = json.name;
    this.clientName = json.clientName;
    this.clientStorageBlobName = json.clientStorageBlobName;
    this.publishDate = new Date(json.publishDate);
    this.creationDate = new Date(json.creationDate);
    this.signedDownloadUrl = json.signedDownloadUrl;
    this.clientArchiveExecutableName = json.clientArchiveExecutableName;
    this.notes = json.notes;
    this.live = json.live;
    this.canary = json.canary;
    this.clientStoragePatchName = json.clientStoragePatchName;
    this.patchStatus = json.patchStatus;
    this.releaseStatus = json.releaseStatus;
  }
}

const baseEndPoint = "/admin/client-versions";

export class ClientVersionService {
  static async getAllClientVersions(): Promise<ClientVersion[]> {
    return api.get<any[]>({url: baseEndPoint})
      .then(clientVersion => clientVersion.map(v => new ClientVersion(v)))
      .catch((e: ApiError) => {
        e.message = `Failed to get Client Versions. ${e.message}`;
        throw e;
      });
  }

  static async getClientVersionById(id: string): Promise<ClientVersion> {
    return api.get<any[]>({url: `${baseEndPoint}/${id}`})
      .then(clientVersion => new ClientVersion(clientVersion))
      .catch((e: ApiError) => {
        e.message = `Failed to get Client Version. ${e.message}`;
        throw e;
      });
  }

  static async deleteClientVersion(id: string, versionName: string): Promise<null> {
    return api.delete({url: `${baseEndPoint}/${id}/${versionName}`})
      .catch((e: ApiError) => {
        e.message = `Failed to delete client version. ${e.message}`;
        throw e;
      });
  }

  static async activateCanary(id: string): Promise<null> {
    return api.put({url: `${baseEndPoint}/${id}/activate-canary`})
      .catch((e: ApiError) => {
        e.message = `Failed to activate canary flag. ${e.message}`;
        throw e;
      });
  }

  static async revokeCanary(id: string): Promise<null> {
    return api.put({url: `${baseEndPoint}/${id}/revoke-canary`})
      .catch((e: ApiError) => {
        e.message = `Failed to revoke canary flag. ${e.message}`;
        throw e;
      });
  }

  static async activateLive(id: string, name: string): Promise<null> {
    return api.put({url: `${baseEndPoint}/${id}/activate-live/${name}`})
      .catch((e: ApiError) => {
        e.message = `Failed to activate live flag. ${e.message}`;
        throw e;
      });
  }

  static async rollbackLive(clientName: string): Promise<null> {
    return api.put({url: `${baseEndPoint}/${clientName}/rollback-live`})
      .catch((e: ApiError) => {
        e.message = `Failed to rollback live. ${e.message}`;
        throw e;
      });
  }

  static async createPatch(id: string): Promise<ClientVersion> {
    return api.put({url: `${baseEndPoint}/${id}/create-patch`})
      .then(clientVersion => new ClientVersion(clientVersion))
      .catch((e: ApiError) => {
        e.message = `Failed to start patch creation. ${e.message}`;
        throw e;
      });
  }
}


export class AdminRequestEntry {
  id = '';
  mythicalId = '';
  verb = '';
  path = '';
  meta: any = null;
  timestamp = new Date();
  adminPlayerId = '';
  adminDisplayName = '';

  constructor(json: any) {
    this.id = json.id;
    this.mythicalId = json.mythicalId;
    this.verb = json.verb;
    this.path = json.path;
    this.meta = json.meta;
    this.timestamp = new Date(json.timestamp);
    this.adminPlayerId = json.adminPlayerId || '';
    this.adminDisplayName = json.adminDisplayName || '';
  }
}
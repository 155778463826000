import { useEffect, useState, useCallback } from 'react';
import { Box, Grid, Typography, Paper, Table, TableBody, TableCell, TableHead, TableRow, Button } from '@material-ui/core';
import { PlayersService } from '../players';
import { UserService } from '../../../services/user';

interface ConnectionsProps {
  playerId: string;
}

export const Connections = ({ playerId }: ConnectionsProps) => {
  const [connections, setConnections] = useState<AccountLinking[]>([]);

  const canDelete = UserService.canDelete('accountLinking');
  const getConnections = useCallback(() => {
    PlayersService.getPlayerConnections(playerId).then(setConnections)
  }, [playerId]);

  const handleDelete = (id: string) => {
    if (!canDelete) return;
    PlayersService.deletePlayerConnection(id)
      .then(getConnections);
  }

  useEffect(() => {
    getConnections();
  }, [getConnections])

  return (<>
    <Box mb={2}>
      <Grid container spacing={2} alignItems="center">
        <Grid item xs>
          <Typography variant="h6">Connections</Typography>
        </Grid>
      </Grid>
    </Box>
    <Paper>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>ID</TableCell>
            <TableCell>Type</TableCell>
            {canDelete && <TableCell />}
          </TableRow>
        </TableHead>
        <TableBody>
          {connections.map((connection) => (
            <TableRow key={connection.id}>
              <TableCell>{connection.id}</TableCell>
              <TableCell>{connection.serviceType}</TableCell>
              {canDelete &&
                <TableCell>
                  <Button variant="contained" color="primary" onClick={() => handleDelete(connection.id)}>
                    Disconnect
                  </Button>
                </TableCell>
              }
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Paper>
  </>)
}
import {Box, Button, Card, CardContent, CardHeader, Grid, IconButton, InputAdornment, Tooltip} from '@material-ui/core';
import type {FormikHelpers, FormikProps} from 'formik';
import {Field, Formik,} from 'formik';
import {CheckboxWithLabel, TextField} from 'formik-material-ui';
import {useCallback, useMemo, useState} from 'react';

import {useDispatch} from 'react-redux';
import * as yup from 'yup';
import {setAppNotification} from '../../redux/app/actions';
import type {CurrencyList} from '../../services/model/currency';
import type {SeasonPartyPass} from '../../services/player-challenges/season-party-pass-plus';
import {SeasonPartyPassService} from '../../services/player-challenges/season-party-pass-plus';
import MenuIcon from "@material-ui/icons/Menu";
import {ItemDefinition} from "../../services/item-definitions";
import ItemSelectDialog from "../ItemDefinition/ItemSelectDialog";
import type {DropTable} from "../../services/drop-tables";

const buildFormSchema = (requireTierSkips: boolean) => yup.object({
  tierSkips: requireTierSkips ? yup.number().min(1, 'Must skip at least 1 tier').required() : yup.number(),
  prices: yup.array().of(yup.object({
    code: yup.string().required(),
    amount: yup.number().min(0, 'Price must be 0 or greater')
  }))
});

interface FormValues {
  tierSkips: number;
  prices: {
    code: string;
    amount: string | number;
  }[];
  itemId: string;
  storeId: string;
  catalogName: string;
  enabled: boolean;
}

interface Props {
  title: string;
  currencyList: CurrencyList;
  partyPass: SeasonPartyPass;
}

const SeasonPartyPassForm = (props: Props) => {

  const [itemSelectDialogOpen, setItemSelectDialogOpen] = useState(false);
  const dispatch = useDispatch();
  const formSchema = useMemo(() => buildFormSchema(props.partyPass.tierSkips > 0), [props.partyPass]);

  const formValues = useMemo((): FormValues => ({
    tierSkips: props.partyPass.tierSkips,
    prices: props.currencyList.getCurrencyCodes().map(code => ({
      code,
      amount: isNaN(props.partyPass.prices[code]) ? '' : props.partyPass.prices[code]
    })),
    itemId: props.partyPass.itemId,
    catalogName: props.partyPass.catalogName,
    storeId: props.partyPass.storeId,
    enabled: props.partyPass.enabled
  }), [props.partyPass, props.currencyList]);

  const onSubmit = useCallback((values: FormValues, helpers: FormikHelpers<FormValues>) => {
    const newPartyPass: SeasonPartyPass = { ...props.partyPass };

    if (newPartyPass.tierSkips > 0) {
      newPartyPass.tierSkips = values.tierSkips;
    }

    values.prices.forEach(price => {
      if (typeof price.amount === 'number') {
        newPartyPass.prices[price.code] = price.amount;
      } else {
        delete newPartyPass.prices[price.code];
      }
    });

    newPartyPass.itemId = values.itemId;
    newPartyPass.catalogName = values.catalogName;
    newPartyPass.storeId = values.storeId;

    newPartyPass.enabled = values.enabled;

    SeasonPartyPassService.updatePartyPass(newPartyPass)
      .then(() => {
        dispatch(setAppNotification({ type: 'success', message: 'Party pass updated' }));
        helpers.setSubmitting(false);
      })
      .catch(() => dispatch(setAppNotification({ type: 'error', message: 'Error updating party pass' })));
  }, [dispatch, props.partyPass]);

  const onItemSelect = (items: (ItemDefinition | DropTable)[], form: FormikProps<FormValues>) => {
    if (items.length === 1 && items[0] instanceof ItemDefinition) {
      form.setFieldValue('itemId', items[0].itemId);
      form.setFieldValue('catalogName', items[0].catalogName);
    }
  };

  return (
    <Card>
      <CardHeader title={props.title}/>
      <CardContent>
        <Formik<FormValues>
          enableReinitialize={true}
          initialValues={formValues}
          validationSchema={formSchema}
          onSubmit={onSubmit}
        >
          {form => (
            <Grid container spacing={1}>
              {props.partyPass.tierSkips > 0 && (
                <Grid item xs={12}>
                  <Field
                    component={TextField}
                    name="tierSkips"
                    type="number"
                    label="Tier skips"
                    fullWidth
                  />
                </Grid>
              )}
              {form.values.prices.map((price, index) => (
                <Grid key={price.code} item xs={12}>
                  <Field
                    component={TextField}
                    name={`prices[${index}].amount`}
                    type="number"
                    label={`${price.code} price`}
                    fullWidth
                  />
                </Grid>
              ))}
              <Grid item xs={12}>
                <Field
                  component={TextField}
                  name="itemId"
                  type="text"
                  label="Party Pass Catalog Item"
                  fullWidth
                  InputProps={{
                    readOnly: true,
                    endAdornment: (
                      <InputAdornment position="end">
                        <Tooltip title="Select item...">
                          <IconButton onClick={() => setItemSelectDialogOpen(true)}>
                            <MenuIcon/>
                          </IconButton>
                        </Tooltip>
                      </InputAdornment>
                    )
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <Field
                  component={TextField}
                  name="catalogName"
                  type="text"
                  label="Catalog Name"
                  readOnly
                  fullWidth
                />
              </Grid>
              <Grid item xs={12}>
                <Field
                  component={TextField}
                  name="storeId"
                  type="text"
                  label="Store Id"
                  fullWidth
                />
              </Grid>
              <Grid item xs={12}>
                <Field
                  component={CheckboxWithLabel}
                  name="enabled"
                  type="checkbox"
                  Label={{label: "Enabled"}}
                />
              </Grid>
              <Grid item xs={12}>
                <Box mt={2}>
                  <Button color="primary" variant="contained" onClick={form.submitForm}>
                    Save
                  </Button>
                </Box>
              </Grid>
              {itemSelectDialogOpen && (
                <ItemSelectDialog
                  showCatalogSelect
                  onSelect={items => onItemSelect(items, form)}
                  onClose={() => setItemSelectDialogOpen(false)}
                />
              )}
            </Grid>
          )}
        </Formik>
      </CardContent>
    </Card>
  );
}

export default SeasonPartyPassForm;
import React, { useCallback } from 'react';
import { PlayersService } from '../players';
import { AdminTable } from '../../../shared/components/AdminTable';
import type { Column, QueryResult, Query } from 'material-table';

interface CurrencyHistoryProps {
  playerId: string;
}

export const CurrencyHistory = ({ playerId }: CurrencyHistoryProps) => {
  const columns: Column<CurrencyHistoryPaginatedItem>[] = [
    {
      title: "ID",
      field: "item.id",
      hidden: true,
    },
    {
      title: "Currency",
      field: "item.currencyCode",
      cellStyle: { width: 1 }
    },
    {
      title: "Amount",
      field: "item.value",
      // eslint-disable-next-line react/display-name
      render: paginatedItem => <>{Number(paginatedItem.item.value).toLocaleString()}</>,
    },
    {
      title: 'Source',
      sorting: false,
      // eslint-disable-next-line react/display-name
      render: paginatedItem => <>{prettifyReferenceId(paginatedItem.item.referenceId)}</>
    },
    {
      title: "Timestamp",
      field: "item.createTimestamp",
      defaultSort: 'desc',
      // eslint-disable-next-line react/display-name
      render: paginatedItem => <>{new Date(paginatedItem.item.createTimestamp).toLocaleString()}</>
    }
  ];

  const getData = useCallback(async (query: Query<CurrencyHistoryPaginatedItem>): Promise<QueryResult<CurrencyHistoryPaginatedItem>> => {
    return new Promise(resolve => {
      const page = query.page + 1;

      if (!query.orderBy) {
        return;
      }

      PlayersService.getPlayerCurrencyHistory(playerId, query.orderDirection, query.orderBy, page, query.pageSize)
        .then(result => {
          resolve({
            data: result.currencyHistory,
            page: page - 1,
            totalCount: result.totalCount,
          })
        });
    });
  }, [playerId]);

  return (<>
    <AdminTable<CurrencyHistoryPaginatedItem>
      storageId="currency-history"
      columns={columns}
      data={query => getData(query)}
      title="Currency History"
      options={{
        columnsButton: true,
        pageSize: 20,
        pageSizeOptions: [20, 50, 100],
        thirdSortClick: false,
        search: false
      }}
    />
  </>)
}

// TODO: Consider storing this map in title data for easy changes
const referenceIdMap = [
  { regex: /SEASON_PARTY_PASS__INSTANCE_.*?/, name: 'Season challenge reward'},
  { regex: /SEASON_PARTY_PASS__TIER_.*?__n_.*?/, name: 'Party Pass tier reward'},
  { regex: /SEASON_PARTY_PASS__TIER_.*?__n_.*?__PP+/, name: 'Party Pass+ tier reward'},
  { regex: /TIER_SKIP_PURCHASE__.*?/, name: 'Party pass tier skip'},
  { regex: /PARTY_PASS_PURCHASE__.*?/, name: 'Party pass+ upgrade'},
  { regex: /BLANKO_FUSE_SUCCESS_.*?/, name: 'Blanko fuse'},
  { regex: /BLANKO_FUSE_FAILURE_REFUND_.*?/, name: 'Blanko fuse error/rollback'},
  { regex: /BLANK_PROGRESSION_REWARD_.*?_.*?/, name: 'Blanko progression reward'},
  { regex: /BLANKO_UPGRADE_TIER_PURCHASE_.*?/, name: 'GradeUP'},
  { regex: /BLANKO_UPGRADE_TIER_REFUND_.*?/, name: 'GradeUP error/rollback'},
  { regex: /BLANKOS_MASHUP_SPEND__DNA_GUMBALL_.*?_.*?_.*?/, name: 'MashUP'},
  { regex: /BLANKOS_MASHUP_REFUND__DNA_GUMBALL_.*?_.*?_.*?/, name: 'MashUP error/rollback'},
  { regex: /INVENTORY_CURRENCY_GRANT__.*?/, name: 'Bundle grant'},
  { regex: /INVENTORY_CURRENCY_GRANT_ERROR__.*?/, name: 'Bundle grant error/rollback'},
  { regex: /SHOP_PURCHASE__.*?/, name: 'Shop purchase'},
  { regex: /SHOP_PURCHASE_REFUND__.*?/, name: 'Shop purchase error/rollback'},
  { regex: /REFUND_CURRENCY__TRANSACTION_.*?_.*?/, name: 'Refund'},
  { regex: /STORE_PURCHASE__.*?/, name: 'Store purchase'},
  { regex: /SERVER_PLAYER_REMOVE_CURRENCY__.*?/, name: 'Game server'},
  { regex: /CONTAINER_UNLOCK_ROLLBACK__.*?_.*?_.*?/, name: 'Container grant error/rollback'},
  { regex: /ADMIN_BULK_GRANT_.*?/, name: 'Bulk Grant'}
];

function prettifyReferenceId(referenceId: string | null): string { 
  if (referenceId) {
    for (const mapper of referenceIdMap) {
      if (mapper.regex.test(referenceId)) {
        return mapper.name;
      }
    }
  }

  return 'Unknown';
}
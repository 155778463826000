import { createReducer } from 'typesafe-actions';
import type { APIResource} from '../utils-ts';
import type { Catalog } from '../../services/catalogs';
import { getCatalogsAsync } from './actions';
import type { Reducer } from 'redux';

export interface CatalogsState {
  catalogs: APIResource<Catalog[]>;
  isAddingCatalog: boolean;
  isImportingCatalog: boolean;
  importError: string;
  addCatalogError: boolean;
}

const initialState: CatalogsState = {
  catalogs: {
    isLoading: false
  },
  isAddingCatalog: false,
  isImportingCatalog: false,
  importError: '',
  addCatalogError: false
};

const catalogsReducer: Reducer<CatalogsState> = createReducer<CatalogsState>(initialState)
  .handleAction(getCatalogsAsync.request, state => ({
    ...state,
    catalogs: {
      isLoading: true
    }
  }))
  .handleAction(getCatalogsAsync.success, (state, action) => ({
    ...state,
    catalogs: {
      isLoading: false,
      data: action.payload
    }
  }))
  .handleAction(getCatalogsAsync.failure, state => ({
    ...state,
    catalogs: {
      isLoading: false
    }
  }));

export default catalogsReducer;
import type { Reducer } from 'redux';
import { createReducer } from 'typesafe-actions';
import type { RewardPathTierDto } from '../../../services/player-challenges/season-reward-path-tiers';
import {
  getRewardPathTiersAsync,
  getRewardPathTierAsync,
  createRewardPathTierAsync,
  updateRewardPathTierAsync,
  deleteRewardPathTierAsync,
  clearSeasonRewardPathTierError
} from './actions';

export interface RewardPathTiersState {
  isLoading: boolean;
  isSaving: boolean;
  isDeleting?: string;
  error?: string;
  data: RewardPathTierDto[];
}

const initialState: RewardPathTiersState = {
  isLoading: false,
  isSaving: false,
  isDeleting: undefined,
  error: undefined,
  data: []
};

const seasonRewardPathTiersReducer: Reducer<RewardPathTiersState> = createReducer<RewardPathTiersState>(initialState)
  .handleAction(getRewardPathTiersAsync.request, state => ({
    ...state,
    isLoading: true
  }))
  .handleAction(getRewardPathTiersAsync.success, (state, action) => ({
    ...state,
    isLoading: false,
    data: action.payload
  }))
  .handleAction(getRewardPathTiersAsync.failure, state => ({
    ...state,
    isLoading: false
  }))
  .handleAction(getRewardPathTierAsync.request, (state, action) => ({
    ...state,
    isLoading: false
  }))
  .handleAction(getRewardPathTierAsync.success, (state, action) => ({
    ...state,
    isLoading: false,
    data: state.data.map(rewardPathTier => rewardPathTier.tierId === action.payload.tierId ? action.payload : rewardPathTier)
  }))
  .handleAction(getRewardPathTierAsync.failure, (state, action) => ({
    ...state,
    isLoading: false
  }))
  .handleAction(createRewardPathTierAsync.request, (state, action) => ({
    ...state,
    isSaving: true
  }))
  .handleAction(createRewardPathTierAsync.success, (state, action) => ({
    ...state,
    isSaving: false,
    error: undefined,
    data: state.data.concat(action.payload)
  }))
  .handleAction(createRewardPathTierAsync.failure, (state, action) => ({
    ...state,
    isSaving: false,
    error: action.payload
  }))
  .handleAction(updateRewardPathTierAsync.request, (state, action) => ({
    ...state,
    isSaving: true
  }))
  .handleAction(updateRewardPathTierAsync.success, (state, action) => {
    const index = state.data.findIndex(rewardPathTier => rewardPathTier.tierId === action.payload.tierId);
    if (index >= 0) {
      state.data.splice(index, 1, action.payload);
    }

    return ({
      ...state,
      isSaving: false,
      error: undefined,
      data: state.data
    });
  })
  .handleAction(updateRewardPathTierAsync.failure, (state, action) => ({
    ...state,
    isSaving: false,
    error: action.payload
  }))
  .handleAction(deleteRewardPathTierAsync.request, (state, action) => ({
    ...state,
    isDeleting: action.payload.tierId
  }))
  .handleAction(deleteRewardPathTierAsync.success, (state, action) => {
    const index = state.data.findIndex(rewardPathTier => rewardPathTier.tierId === action.payload.tierId);
    if (index >= 0) {
      state.data.splice(index, 1);
    }
    return ({
      ...state,
      isDeleting: undefined,
      data: state.data
    });
  })
  .handleAction(deleteRewardPathTierAsync.failure, (state, action) => ({
    ...state,
    isDeleting: undefined
  }))
  .handleAction(clearSeasonRewardPathTierError, state => ({
    ...state,
    error: undefined
  }));

export default seasonRewardPathTiersReducer;
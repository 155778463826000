import { Checkbox, FormControlLabel } from '@material-ui/core';

interface OptionSingleCheckboxProps {
  label: string;
  checked: boolean;
  disabled?: boolean;
  onClick(): void;
}

export const OptionSingleCheckbox = ({
  label,
  checked,
  disabled,
  onClick
}: OptionSingleCheckboxProps) => (
  <FormControlLabel
    control={<Checkbox disabled={disabled} checked={checked} onClick={onClick} />}
    label={label}
  />
)
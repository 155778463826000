import {Box, Button, Card, CardContent, CardHeader, Grid, TextField as MatTextField } from '@material-ui/core';
import GetAppIcon from '@material-ui/icons/GetApp';
import type { FormikHelpers} from 'formik';
import {Field, Form, Formik} from 'formik';
import {TextField} from 'formik-material-ui';
import type { ChangeEvent} from 'react';
import {Block} from '../../../services/blocks';
import {createValidator, Validators} from '../../../utils/forms';
import JsonEditor from '../../../components/JsonEditor';
import { ReportedBlocks } from "../ReportedBlocks";
import Chip from "@material-ui/core/Chip";
import { Autocomplete } from "@material-ui/lab";
import { BrawlSettingsForm } from '../../../features/brawl/components/BrawlSettingsForm';
import { useEffect, useCallback, useState } from 'react';

interface FormValues {
  id: string;
  name: string;
  ownerId: string;
  ownerName: string;
  creatorId: string;
  creatorName: string;
  primaryGameMode: string;
  secondaryGameModes: string;
  minPlayers: string;
  maxPlayers: string;
  duration: string;
  playType: string;
  brawlIsNonEliminationCompatible: boolean;
}

interface Props {
  block: Block;
  tags: string[];
  onSave: (block: Block) => void;
  readOnly: boolean;
  onTagsSave: (id: string, tags: string[]) => void;
}

const formValidator = createValidator<FormValues>({
  name: Validators.notBlank(),
  minPlayers: Validators.integer(1, 9000),
  maxPlayers: Validators.integer(1, 9000),
  duration: Validators.integer(1, 600),
}, (values, prevErrors) => {
  const errors: any = {};
  if (!prevErrors.minPlayers && !prevErrors.maxPlayers && parseInt(values.minPlayers) > parseInt(values.maxPlayers)) {
    errors.minPlayers = 'Cannot be higher than max players';
    errors.maxPlayers = 'Cannot be lower than min players';
  }
  return errors;
});

export const BlockForm = (props: Props) => {
  const { onTagsSave, block } = props;
  const [submitting, setSubmitting] = useState<((submitting: boolean) => void) | undefined>();
  const [customData, setCustomData] = useState('');
  const [initialFormValues, setInitialFormValues] = useState<FormValues>();
  const [tagValue, setTagValue] = useState('');
  const [tagsValues, setTagsValues] = useState<string[]>([]);

  const blockToFormValues = useCallback((block: Block): FormValues => {
    return {
      id: block.id,
      name: block.name,
      ownerId: block.ownerId,
      ownerName: block.ownerName,
      creatorId: block.creatorId,
      creatorName: block.creatorName,
      primaryGameMode: block.primaryGameMode,
      secondaryGameModes: block.secondaryGameModes.join(', '),
      minPlayers: block.minPlayers.toString(),
      maxPlayers: block.maxPlayers.toString(),
      duration: block.duration.toString(),
      playType: block.playType,
      brawlIsNonEliminationCompatible: block.brawlIsNonEliminationCompatible,
    };
  }, []);

  useEffect(() => {
    setInitialFormValues(blockToFormValues(props.block));
    setTagsValues(props.block?.tags);
  }, [blockToFormValues, props.block]);

  const onSubmit = useCallback((values: FormValues, helpers: FormikHelpers<FormValues>) => {
    const block = props.block;
    const newBlock = new Block();
    newBlock.id = block.id;
    newBlock.ownerId = block.ownerId;
    newBlock.creatorId = block.creatorId;
    newBlock.primaryGameMode = block.primaryGameMode;
    newBlock.secondaryGameModes = block.secondaryGameModes;
    newBlock.playType = block.playType;
    newBlock.attachmentConfigs = block.attachmentConfigs;

    newBlock.name = values.name;
    newBlock.minPlayers = parseInt(values.minPlayers);
    newBlock.maxPlayers = parseInt(values.maxPlayers);
    newBlock.duration = parseInt(values.duration);
    newBlock.brawlIsNonEliminationCompatible = values.brawlIsNonEliminationCompatible;
    newBlock.customData = customData;
    newBlock.tags = tagsValues;

    props.onSave(newBlock);
    setSubmitting(helpers.setSubmitting);
  }, [customData, props, tagsValues]);

  const handleTagAdd = () => {
    if (!tagsValues.find(tag => tagValue === tag)) {
      setTagsValues([...tagsValues, tagValue]);
    } else {
      setTagsValues([tagValue]);
    }
    onTagsSave(block.id, [...tagsValues, tagValue]);
    setTagValue('');
  };

  const handleTagInputChange = (event: any) => {
    const input = event.target;
    const start = input.selectionStart;
    const end = input.selectionEnd;
    setTagValue(input.value.toUpperCase());
    input.setSelectionRange(start, end);
  };

  const handleDelete = (tag: any) => () => {
    const tags = tagsValues.filter(t => t !== tag);
    setTagsValues(tags);
    props.onTagsSave(props.block.id, tags);
  };

  // eslint-disable-next-line @typescript-eslint/ban-types
  const handleAutoTagInputChange = (event: ChangeEvent<{}>, value: any) => {
    setTagValue(value);
  };


  return (<>
    {(initialFormValues &&
      <Formik<FormValues>
        initialValues={initialFormValues}
        enableReinitialize
        validate={formValidator}
        validateOnMount={true}
        onSubmit={onSubmit}
      >
        {({ isSubmitting, submitForm }) => (
          <Form>
            <Grid container spacing={3}>
              <Grid item xs={12} md={6} lg={8}>
                <Card>
                  <CardHeader title="Properties" />
                  <CardContent>
                    <Grid container spacing={1}>
                      <Grid item xs={12}>
                        <Field
                          component={TextField}
                          name="id"
                          type="text"
                          label="ID"
                          fullWidth
                          InputProps={{
                            readOnly: true
                          }}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <Field
                          component={TextField}
                          name="creatorName"
                          type="text"
                          label="Creator Name"
                          fullWidth
                          InputProps={{
                            readOnly: true
                          }}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <Field
                          component={TextField}
                          name="creatorId"
                          type="text"
                          label="Creator ID"
                          fullWidth
                          InputProps={{
                            readOnly: true
                          }}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <Field
                          component={TextField}
                          name="name"
                          type="text"
                          label="Name"
                          fullWidth
                          InputProps={{ readOnly: true }}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <Field
                          component={TextField}
                          name="primaryGameMode"
                          type="text"
                          label="Game mode"
                          fullWidth
                          InputProps={{
                            readOnly: true
                          }}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <Field
                          component={TextField}
                          name="playType"
                          type="text"
                          label="Mode type"
                          fullWidth
                          InputProps={{
                            readOnly: true
                          }}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <Field
                          component={TextField}
                          name="secondaryGameModes"
                          type="text"
                          label="Secondary game modes"
                          fullWidth
                          InputProps={{
                            readOnly: true
                          }}
                        />
                      </Grid>
                      <Grid item xs={6} lg={4}>
                        <Field
                          component={TextField}
                          name="minPlayers"
                          type="text"
                          label="Min. players"
                          fullWidth
                          InputProps={{ readOnly: true }}
                        />
                      </Grid>
                      <Grid item xs={6} lg={4}>
                        <Field
                          component={TextField}
                          name="maxPlayers"
                          type="text"
                          label="Max. players"
                          fullWidth
                          InputProps={{ readOnly: true }}
                        />
                      </Grid>
                      <Grid item xs={12} lg={4}>
                        <Field
                          component={TextField}
                          name="duration"
                          type="text"
                          label="Duration (seconds)"
                          fullWidth
                          InputProps={{ readOnly: true }}
                        />
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={12} md={6} lg={4}>
                <BrawlSettingsForm
                  blockId={props.block.id}
                  brawlMinPlayers={props.block.brawlMinPlayers}
                  brawlMaxPlayers={props.block.brawlMaxPlayers}
                  brawlWeight={props.block.brawlWeight}
                  brawlEliminationsPercentage={props.block.brawlEliminationsPercentage}
                  brawlPlaylist={props.block.brawlPlaylist}
                  blockMaxPlayers={props.block.maxPlayers}
                  blockMinPlayers={props.block.minPlayers}
                  brawlIsNonEliminationCompatible={props.block.brawlIsNonEliminationCompatible}
                  skillLevelThreshold={props.block.skillLevelThreshold}
                />
              </Grid>
              <Grid item xs={12} md={6} lg={4}>
                <Card>
                  <CardHeader title="Tags" />
                  <CardContent>
                    <Grid container spacing={1}>
                      {tagsValues.map((data, index) => {
                        return (
                          <Chip key={index} label={data} onDelete={handleDelete(data)} color="secondary" size="small" style={{
                            marginRight: '10px'
                          }} />
                        )
                      })}
                    </Grid>
                  </CardContent>
                  <CardContent>
                    <Grid container spacing={1}>
                      <Grid item xs={6}>
                        <Autocomplete options={props.tags} freeSolo value={tagValue} onChange={handleAutoTagInputChange}
                          renderInput={params => (
                            <MatTextField
                              {...params}
                              label="Add a Tag"
                              fullWidth
                              value={tagValue}
                              onChange={handleTagInputChange}
                            />
                          )}
                        />
                      </Grid>
                      <Grid item xs={4}>
                        <Button color="primary" variant="contained" disabled={!tagValue || tagValue.length < 1} onClick={handleTagAdd}>Add Tag</Button>
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={12} md={6} lg={4}>
                <Card style={{
                  marginBottom: '24px'
                }}>
                  <CardHeader title="Block file" />
                  <CardContent>
                    {props.block.fileUploaded && props.block.blockFileUrl ? (
                      <Button
                        variant="contained"
                        color="primary"
                        href={props.block.blockFileUrl}
                        startIcon={<GetAppIcon />}
                      >
                        Download
                      </Button>
                    ) : (
                      <Box fontStyle="italic">Block file not available.</Box>
                    )}
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={12}>
                <JsonEditor
                  title="Custom Data"
                  json={props.block.customData}
                  readOnly={true}
                  // eslint-disable-next-line @typescript-eslint/no-empty-function
                  onJsonChange={() => { }}
                  // eslint-disable-next-line @typescript-eslint/no-empty-function
                  onValidityChange={() => { }}
                />
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    )}
  </>);
}
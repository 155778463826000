import type { Theme} from "@material-ui/core";
import { TextField, makeStyles, createStyles } from "@material-ui/core"
import type { FormikProps } from "formik";
import type { BlankoGemRush } from '../blankoGemRush';
import { AdminKeyboardDateTimePicker } from "../../../components/AdminKeyboardDateTimePicker";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formControl: {
      marginTop: theme.spacing(2),
      minWidth: 400,
    },
  }),
);


export const RushForm = ({ errors, touched, handleChange, handleBlur, values, setFieldValue }: FormikProps<BlankoGemRush>) => {
  const classes = useStyles();
  return (
    <>
      <TextField
        id="name"
        name="name"
        type="text"
        label={"Name"}
        error={Boolean(touched.name && errors.name)}
        helperText={errors.name}
        className={classes.formControl}
        onChange={handleChange}
        onBlur={handleBlur}
        value={values.name}
      />
      <AdminKeyboardDateTimePicker
        id="startDate"
        name="startDate"
        label={"Start Date"}
        InputLabelProps={{ shrink: true }}
        error={Boolean(touched.startDate && errors.startDate)}
        helperText={errors.startDate}
        className={classes.formControl}
        onChange={date => setFieldValue('startDate', date)}
        onBlur={handleBlur}
        value={values.startDate}
      />
      <AdminKeyboardDateTimePicker
        id="endDate"
        name="endDate"
        label={"End Date"}
        InputLabelProps={{ shrink: true }}
        error={Boolean(touched.endDate && errors.endDate)}
        helperText={errors.endDate}
        className={classes.formControl}
        onChange={date => setFieldValue('endDate', date)}
        onBlur={handleBlur}
        value={values.endDate}
      />
    </>
  )
}
import type { Theme } from '@material-ui/core';
import { Box, Button, CircularProgress, Grid, makeStyles } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import * as React from 'react';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useDrop } from 'react-dnd';
import { useDispatch } from 'react-redux';
import ItemSelectDialog from '../../../pages/ItemDefinition/ItemSelectDialog';
import { setAppNotification } from '../../../redux/app/actions';
import { ApiError } from '../../../services/api';
import type { DropTable } from '../../../services/drop-tables';
import { ItemDefinition } from '../../../services/item-definitions';
import { BuiltInCurrencyEnum, CurrencyList } from '../../../services/model/currency';
import type { MarketplaceItemDataTag, ShopItem, ShopTabItem, ShopVersion } from '../shop';
import {
  createShopItem,
  createShopTabItem,
  getShopVersion,
  updateShopItems,
  updateShopTabItems
} from '../shopsApi';
import type { EditorItem } from './ShopTabItemEditor';
import { ShopTabItemEditor } from './ShopTabItemEditor';
import { ShopTabItemCreateDialog } from "./ShopTabItemCreateDialog";
import { CreateMarketplaceItemDataTagShopTabItemDialog } from "./CreateMarketplaceItemDataTagShopTabItemDialog";

const ROW_SEGMENTS = 12;
const DEFAULT_GRID_WIDTH = 3;
const MINIMUM_GRID_WIDTH = 3;

const MATERIAL_GRID_SEGMENTS = 12;
const ROW_SEGMENT_FACTOR = MATERIAL_GRID_SEGMENTS / ROW_SEGMENTS;

const useStyles = makeStyles((theme: Theme) => ({
  addButton: {
    borderWidth: 3,
    borderStyle: 'dashed',
    borderColor: theme.palette.secondary.main,
    borderRadius: 10,
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: theme.palette.type === 'dark' ? 'rgba(255, 255, 255, 0.1)' : 'rgba(0, 0, 0, 0.1)'
    }
  }
}));

interface ShopTabEditorProps {
  shopVersion: ShopVersion;
  tab: number;
  currencyList: CurrencyList;
  catalogItems: ItemDefinition[];
  readOnly?: boolean;
  onShopVersionUpdate: (shopVersion: ShopVersion) => void;
  onEditItem: (item: EditorItem) => void;
  marketplaceItemDataTags: MarketplaceItemDataTag[];
}

export const ShopTabEditor = (props: ShopTabEditorProps) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const gridRef = useRef<HTMLDivElement | null>(null);

  const { currencyList, catalogItems, shopVersion, onShopVersionUpdate, marketplaceItemDataTags } = props;

  const [isLoading, setLoading] = useState(true);
  const [isSubmitting, setSubmitting] = useState(false);
  const [items, setItems] = useState<EditorItem[]>([]);
  const [addDialogOpen, setAddDialogOpen] = useState(false);
  const [addItemDialogOpen, setAddItemDialogOpen] = useState(false);
  const [addMarketplaceItemDataTagOpen, setAddMarketplaceItemDataTagOpen] = useState(false);

  const buildEditorItem = (tabItem: ShopTabItem, shopItem: ShopItem, catalogItem: ItemDefinition, isNew: boolean): EditorItem => {
    let currency = 'BB';
    let price = '0';
    if (shopItem.rmPrice !== null) {
      currency = BuiltInCurrencyEnum.USDollar;
      price = shopItem.rmPrice.toString();
    } else {
      const keys = Object.keys(shopItem.virtualCurrencyPrices);
      if (keys.length > 0) {
        currency = keys[0];
        price = shopItem.virtualCurrencyPrices[keys[0]].toString();
      }
    }

    const editorItem: EditorItem = { tabItem, itemDetail: { shopItem, catalogItem, currency, price, priceError: '' }, isNew };
    if (editorItem.itemDetail) { // Stupid typescript
      editorItem.itemDetail.priceError = validatePrice(editorItem);
    }
    return editorItem;
  }

  useEffect(() => {
    if (catalogItems && !currencyList.isEmpty()) {
      const catalogItemsMap: { [key: string]: ItemDefinition; } = {};
      catalogItems.forEach(item => catalogItemsMap[item.itemId] = item);

      const shopItemsMap: { [key: string]: ShopItem } = {};
      shopVersion.items.forEach(item => shopItemsMap[item.id] = item);

      const editorItems: EditorItem[] = [];
      const tab = shopVersion.tabs[props.tab];
      tab.items.forEach(tabItem => {
        let editorItem: EditorItem;
        if (tabItem.shopItemId) {
          const shopItem = shopItemsMap[tabItem.shopItemId];
          if (!shopItem) {
            return;
          }

          const catalogItem = catalogItemsMap[shopItem.itemId];
          editorItem = buildEditorItem(tabItem, shopItem, catalogItem, false);
        } else if (tabItem.marketplaceItemDataTag) {
          editorItem = { tabItem, isNew: false };
        } else {
          return;
        }
        if (tabItem.hero) {
          editorItems.unshift(editorItem);
        } else {
          editorItems.push(editorItem);
        }
      });

      setItems(editorItems);
      setLoading(false);
    } else {
      setLoading(true);
    }
  }, [catalogItems, currencyList, shopVersion, props.tab])

  const saveItems = useCallback(async (items: EditorItem[]) => {
    for (let i = 0; i < items.length; i++) {
      const itemDetail = items[i].itemDetail;
      if (itemDetail) {
        if (itemDetail.priceError) {
          return;
        } else if (itemDetail.currency === BuiltInCurrencyEnum.Free) {
          // now that we're saving, force free items to have a price of 0
          itemDetail.price = '0';
        }
      }
    }

    const shopItems: ShopItem[] = items.map(item => {
      if (!item.itemDetail) {
        return null;
      }
      const shopItem: ShopItem = { ...item.itemDetail.shopItem, virtualCurrencyPrices: {}, rmPrice: null };
      if (item.itemDetail.currency === BuiltInCurrencyEnum.USDollar) {
        shopItem.rmPrice = parseFloat(item.itemDetail.price);
      } else {
        shopItem.virtualCurrencyPrices[item.itemDetail.currency] = parseInt(item.itemDetail.price);
      }
      return shopItem;
    }).filter(shopItem => shopItem) as ShopItem[];

    const tabItems = items.map(item => item.tabItem);
    tabItems.forEach(tabItem => {
      if (tabItem.hero) {
        tabItem.gridWidth = ROW_SEGMENTS;
      }
    });

    setSubmitting(true);
    try {
      await updateShopItems(shopItems);
      await updateShopTabItems(shopVersion.tabs[props.tab].id, tabItems);
      onShopVersionUpdate(await getShopVersion(shopVersion.id, true));
      dispatch(setAppNotification({ type: 'success', message: 'Tab saved' }));
    } catch (e) {
      dispatch(setAppNotification({ type: 'error', message: `Error saving tab. ${e instanceof ApiError ? e.message : 'Unknown error'}` }));
    } finally {
      setSubmitting(false);
    }
  }, [dispatch, onShopVersionUpdate, props.tab, shopVersion]);

  const addMarketplaceItemDataTag = useCallback(async (tag: MarketplaceItemDataTag) => {
    const newItems = items.slice();
    const tabItem = await createShopTabItem({
      shopTabId: shopVersion.tabs[props.tab].id,
      hero: false,
      gridWidth: DEFAULT_GRID_WIDTH,
      imageUrl: null,
      nonInteractive: false,
      customData: null,
      marketplaceEnabled: true,
      marketplaceItemDataTag: tag.name,
    });
    newItems.push({ tabItem, isNew: false });
    setItems(newItems);
  }, [items, props.tab, shopVersion.tabs]);

  const addItems = useCallback(async (addedItems: (ItemDefinition | DropTable)[]) => {
    const newItems = items.slice();

    const shopItemsMap: { [key: string]: ShopItem} = {};
    shopVersion.items.forEach(v => shopItemsMap[v.itemId] = v);

    for (const item of addedItems) {
      if (item instanceof ItemDefinition) {
        let shopItem = shopItemsMap[item.itemId];
        if (!shopItem) {
          shopItem = await createShopItem({
            shopVersionId: shopVersion.id,
            itemId: item.itemId,
            virtualCurrencyPrices: {},
            rmPrice: null,
            cartLimit: 0,
            purchaseLimit: 0,
            geoBlockingFeatures: [],
            startTimestamp: null,
            endTimestamp: null,
            customData: null
          });

          shopVersion.items.push(shopItem);
        }

        const tabItem = await createShopTabItem({
          shopTabId: shopVersion.tabs[props.tab].id,
          shopItemId: shopItem.id,
          hero: false,
          gridWidth: DEFAULT_GRID_WIDTH,
          imageUrl: null,
          nonInteractive: false,
          customData: null,
          marketplaceEnabled: false,
        });

        newItems.push(buildEditorItem(tabItem, shopItem, item, false));
      }
    }

    setItems(newItems);
  }, [props.tab, items, shopVersion]);

  const handleItemChange = (index: number, item: EditorItem) => {
    if (item.itemDetail) {
      item.itemDetail.priceError = validatePrice(item);
    }
    const newItems = items.slice();
    newItems[index] = item;
    setItems(newItems);
  }

  const handleItemRemove = (index: number) => {
    const newItems = items.slice();
    newItems.splice(index, 1);
    setItems(newItems);
  }

  const toggleItemHero = (index: number) => {
    const newItems = items.slice();
    const item = newItems.splice(index, 1)[0];
    item.tabItem = { ...item.tabItem, hero: !item.tabItem.hero };
    if (item.tabItem.hero && newItems.length > 0) {
      newItems[0].tabItem = { ...newItems[0].tabItem, hero: false };
    }
    newItems.unshift(item);
    setItems(newItems);
  }

  const moveItem = useCallback((fromIndex: number, toIndex: number) => {
    if (toIndex === 0 && items[0].tabItem.hero) {
      return false;
    }

    const newItems = items.slice();
    const t = newItems[toIndex];
    newItems[toIndex] = newItems[fromIndex];
    newItems[fromIndex] = t;
    setItems(newItems);
    return true;
  }, [items]);

  const [, dndResizeDrop] = useDrop({
    accept: 'ShopTabItemResize',
    hover(item: { type: string, index: number, initialWidth: number, ref: React.RefObject<HTMLDivElement> }, monitor) {
      if (!gridRef.current) {
        return;
      }

      const offset = monitor.getDifferenceFromInitialOffset();
      if (!offset) {
        return;
      }

      const gridWidth = gridRef.current.getBoundingClientRect().width;
      const tileWidth = gridWidth / ROW_SEGMENTS;
      const editorItem = items[item.index];
      const itemWidth = Math.min(Math.max(tileWidth * item.initialWidth + offset.x, tileWidth * MINIMUM_GRID_WIDTH), gridWidth);

      const newWidth = Math.min(Math.ceil(itemWidth / tileWidth), ROW_SEGMENTS);
      if (newWidth !== editorItem.tabItem.gridWidth) {
        const newItems = items.slice();
        newItems[item.index] = { ...editorItem, tabItem: { ...editorItem.tabItem, gridWidth: newWidth } };
        setItems(newItems);
      }

      // HACK: Low-level access to div width for performance. (rofl, imagine using props)
      if (item.ref && item.ref.current) {
        item.ref.current.style.width = `${itemWidth - 10}px`;
      }
    }
  });

  if (isLoading) {
    return (
      <Box p={2} textAlign="center">
        <CircularProgress />
      </Box>
    );
  }

  return (<>
    <Grid ref={e => { gridRef.current = e; dndResizeDrop(e); }} container spacing={1}>
      {items.map((item, index) => (
        <Grid key={item.tabItem.id} item xs={item.tabItem.hero ? MATERIAL_GRID_SEGMENTS : (item.tabItem.gridWidth * ROW_SEGMENT_FACTOR) as any}>
          <ShopTabItemEditor
            index={index}
            catalogName={props.shopVersion.catalogName}
            shopTab={props.shopVersion.tabs[props.tab]}
            item={item}
            currencyList={currencyList}
            onCurrencyChange={currency => handleItemChange(index, { ...item, itemDetail: (item.itemDetail && { ...item.itemDetail, currency }) })}
            onPriceChange={price => handleItemChange(index, { ...item, itemDetail: (item.itemDetail && { ...item.itemDetail, price }) })}
            onTabItemChange={tabItem => handleItemChange(index, { ...item, tabItem })}
            onRemove={() => handleItemRemove(index)}
            onToggleHero={() => toggleItemHero(index)}
            onToggleNonInteractive={() => handleItemChange(index, { ...item, tabItem: { ...item.tabItem, nonInteractive: !item.tabItem.nonInteractive } })}
            moveItem={fromIndex => moveItem(fromIndex, index)}
            onEditItem={() => props.onEditItem(item)}
          />
        </Grid>
      ))}
      <Grid item xs={3}>
        <Box textAlign="center" className={classes.addButton} height="100%" minHeight={250} onClick={() => setAddDialogOpen(true)}>
          <Grid container alignItems="center" style={{ height: '100%' }}>
            <Grid item xs={12}>
              <AddIcon fontSize="large" />
            </Grid>
          </Grid>
        </Box>
      </Grid>
    </Grid>

    <Box mt={3}>
      <Button
        variant="contained"
        color="primary"
        disabled={isSubmitting}
        onClick={() => saveItems(items)}
      >
        {isSubmitting ? (
          <CircularProgress size={25} />
        ) : 'Save'}
      </Button>
    </Box>

    {addDialogOpen && (
      <ShopTabItemCreateDialog
        onClose={() => setAddDialogOpen(false)}
        onSelectItem={() => setAddItemDialogOpen(true)}
        onSelectMarketplaceItemDataTag={() => setAddMarketplaceItemDataTagOpen(true)}
      />
    )}

    {addItemDialogOpen && (
      <ItemSelectDialog
        multi
        addedItems={items.map(v => v.itemDetail?.shopItem.itemId).filter(id => id) as string[]}
        onSelect={addItems}
        onClose={() => setAddDialogOpen(false)}
      />
    )}

    {addMarketplaceItemDataTagOpen && (
      <CreateMarketplaceItemDataTagShopTabItemDialog
        tags={marketplaceItemDataTags}
        onClose={() => setAddMarketplaceItemDataTagOpen(false)}
        onSelectTag={addMarketplaceItemDataTag}
      />
    )}
  </>);
}

function validatePrice(item: EditorItem): string {
  // don't validate price of free items, we'll force the price to 0 on save
  if (!item.itemDetail || item.itemDetail.currency === BuiltInCurrencyEnum.Free) {
    return '';
  }
  const price = parseFloat(item.itemDetail.price);
  if (isNaN(price) || price <= 0) {
    return 'Must be a number greater than 0';
  }
  if (item.itemDetail.currency !== BuiltInCurrencyEnum.USDollar && !Number.isInteger(price)) {
    return 'Must be an integer';
  }
  return '';
}

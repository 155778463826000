import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { Component } from 'react';
import type { ConnectedProps } from 'react-redux';
import { connect } from 'react-redux';
import type { RouteComponentProps } from 'react-router';
import { setCatalogName } from '../../redux/catalog/actions';
import { addCatalogItemAsync, getCatalogItemAsync } from '../../redux/catalog/items/actions';
import type { RootState } from '../../redux/reducers';
import type { ItemDefinition, ItemType } from '../../services/item-definitions';
import ItemDefinitionForm from './ItemDefinitionForm';

const mapStateToProps = (state: RootState) => ({ item: state.catalog.items.currentItem });
const mapDispatch = {
  setCatalogName,
  requestItem: getCatalogItemAsync.request,
  requestAddItem: addCatalogItemAsync.request
};
const connector = connect(mapStateToProps, mapDispatch);

interface RouteProps {
  catalogName: string;
}
type Props = RouteComponentProps<RouteProps> & ConnectedProps<typeof connector>;

class AddItemDefinitionPage extends Component<Props> {
  componentDidMount() {
    this.props.setCatalogName(this.props.match.params.catalogName);
  }

  componentDidUpdate() {
    if (this.props.item.data) {
      this.props.history.replace(`/catalogs/${this.props.item.data.catalogName}/items/${this.props.item.data.itemId}`);
    }
  }

  private getItemType(): ItemType {
    const query = new URLSearchParams(this.props.location.search);
    const queryItemType = query.get('type') || '';
    if (queryItemType !== 'bundle' && queryItemType !== 'container') {
      return 'item';
    } else {
      return queryItemType;
    }
  }

  private getTitle() {
    return `Add ${this.getItemType()}`;
  }

  onFormSave = (item: ItemDefinition) => {
    this.props.requestAddItem(item);
  };

  renderForm() {
    return (
      <ItemDefinitionForm
        catalogName={this.props.match.params.catalogName}
        itemType={this.getItemType()}
        onSave={this.onFormSave}
      />
    );
  }

  render() {
    return (<>
      <Box py={2}>
        <Typography variant="h6">
          {this.getTitle()}
        </Typography>
      </Box>
      {this.renderForm()}
    </>);
  }

}

export default connector(AddItemDefinitionPage);
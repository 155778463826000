import axios from '../../../src/axios'
import type { BlankoGemRush, BlankoGemRushFuse } from './blankoGemRush'
import type { AxiosResponse } from 'axios'
import { api, ApiError } from '../../services/api';

export const getAllBlankoRushes = () => {
  return axios.get('/blanko-rushes').then((res: AxiosResponse<{ result: BlankoGemRush[]} >) => res.data.result)
}
export const createBlankoRush = async (data: Omit<Partial<BlankoGemRush>, "id" | "active">) => {
  return axios.post('/blanko-rushes', data)
}

export const updateBlankoRush = async (data: BlankoGemRush) => {
  return axios.put(`/blanko-rushes/${data.id}`, data)
}

export const deleteBlankoRush = async (rushId: string) => {
  return axios.delete(`/blanko-rushes/${rushId}`);
}

export const activateBlankoRush = async (rushId: string) => {
  return axios.post(`/blanko-rushes/${rushId}/activate`);
}

export const deactivateBlankoRush = async (rushId: string) => {
  return axios.post(`/blanko-rushes/${rushId}/deactivate`);
}

export const getBlankoRushFuses = async (rushId: string) => {
  return axios.get(`/blanko-rushes/${rushId}/fuses`).then((res: AxiosResponse<{ result: BlankoGemRushFuse[]} >) => res.data.result)
}

export const createBlankoRushFuse = async (rushId: string, data: Omit<Partial<BlankoGemRushFuse>, "id">) => {
  return axios.post(`/blanko-rushes/${rushId}/fuses`, data)
}

export const getBlankoRushFuseById = async (fuseId: string) => {
  return axios.get(`/blanko-rushes/fuses/${fuseId}`)
}

export const updateBlankoRushFuseById = async (data: BlankoGemRushFuse) => {
  return axios.put(`/blanko-rushes/fuses/${data.id}`, data)
}

export const deleteBlankoRushFuseById = async (fuseId: string) => {
  return axios.delete(`/blanko-rushes/fuses/${fuseId}`)
}

export const uploadRushFuseImage = async (image: File): Promise<string> => {
  try {
    const { filename, url, publicUrl } = await api.get<{ filename: string, url: string, publicUrl: string }>({ url: '/blanko-rushes/image-upload-url', query: { mimeType: image.type } });

    const options: RequestInit = {
      method: 'PUT',
      headers: new Headers({
        'Content-Type': image.type
      }),
      body: image
    };

    const response = await fetch(url, options);
    if (!response.ok) {
      throw new Error('');
    }

    return publicUrl;
  } catch (e) {
    throw new ApiError('Failed to upload blanko rush image');
  }
}
import { BlankoProgressionsDiff } from '../../../../pages/CatalogMigration/types';
import { CatalogMigrationService } from '../../../../services/catalog-migration';
import type { MigrationModule } from '../../migrations';
import { buildDiffData } from '../../migrations';
import { BlankoProgressionsDiffs } from './BlankoProgressionsDiffs';
import { MigrationType } from "../migration-type";

export const blankoProgressionMigration: MigrationModule<BlankoProgressionsDiff> = {
  id: MigrationType.blankoProgression,
  displayName: 'Progressions',
  diffComponent: BlankoProgressionsDiffs,
  crossEnvOnly: true,

  loadData: async (sourceData, targetData, migrationData) => {
    sourceData.blankoProgressions = await CatalogMigrationService.getBlankoProgressions(sourceData.env, sourceData.catalogName);
    targetData.blankoProgressions = await CatalogMigrationService.getBlankoProgressions(targetData.env, targetData.catalogName);

    migrationData.blankoProgressions = buildDiffData(
      sourceData.blankoProgressions.map(v => new BlankoProgressionsDiff(v)),
      targetData.blankoProgressions.map(v => new BlankoProgressionsDiff(v))
    );

    return migrationData.blankoProgressions;
  },

  runMigration: async (props, setStatus) => {
    const sourceProgressions = props.sourceData.blankoProgressions;
    const diffs = props.migrationData.blankoProgressions;
    if (!sourceProgressions || !diffs) {
      throw new Error('Blanko progressions not loaded');
    }

    const selection = props.selections.blankoProgression || {};

    setStatus('Migrating progressions');

    const progressions = diffs.added.concat(diffs.changed).filter(v => Boolean(selection[v.diffId])).map(v => v.blankoProgressions);
    if (progressions.length > 0) {
      await CatalogMigrationService.migrateBlankoProgressions(props.targetData.env, props.targetData.catalogName, progressions);
    }
  }
}

import type {Reducer} from 'redux';
import {createReducer} from 'typesafe-actions';
import type {ChallengeSchedule} from '../../../services/player-challenges/season-challenge-schedule';
import {createSeasonChallengeScheduleAsync, deleteSeasonChallengeScheduleAsync, getCurrentSeasonChallengeScheduleAsync, getSeasonChallengeScheduleAsync, updateSeasonChallengeScheduleAsync} from './actions';

export interface SeasonChallengeSchedulesState {
  isLoading: boolean;
  isUpdating: boolean;
  isDeleting?: number;
  data?: ChallengeSchedule | undefined;
}

const initialState: SeasonChallengeSchedulesState = {
  isLoading: false,
  isUpdating: false,
  isDeleting: undefined,
  data: undefined
};

const seasonChallengeScheduleReducer: Reducer<SeasonChallengeSchedulesState> = createReducer<SeasonChallengeSchedulesState>(initialState)
  .handleAction(getSeasonChallengeScheduleAsync.request, (state, action) => ({
    ...state,
    isLoading: false
  }))
  .handleAction(getSeasonChallengeScheduleAsync.success, (state, action) => ({
    ...state,
    isLoading: false,
    data: action.payload
  }))
  .handleAction(getSeasonChallengeScheduleAsync.failure, (state, action) => ({
    ...state,
    isLoading: false
  }))
  .handleAction(getCurrentSeasonChallengeScheduleAsync.request, (state, action) => ({
    ...state,
    isLoading: false
  }))
  .handleAction(getCurrentSeasonChallengeScheduleAsync.success, (state, action) => ({
    ...state,
    isLoading: false,
    data: action.payload
  }))
  .handleAction(getCurrentSeasonChallengeScheduleAsync.failure, (state, action) => ({
    ...state,
    isLoading: false
  }))
  .handleAction(createSeasonChallengeScheduleAsync.request, (state, action) => ({
    ...state,
    isLoading: true
  }))
  .handleAction(createSeasonChallengeScheduleAsync.success, (state, action) => ({
    ...state,
    isLoading: false,
    data: action.payload
  }))
  .handleAction(createSeasonChallengeScheduleAsync.failure, (state, action) => ({
    ...state,
    isLoading: false
  }))
  .handleAction(updateSeasonChallengeScheduleAsync.request, (state, action) => ({
    ...state,
    isUpdating: true
  }))
  .handleAction(updateSeasonChallengeScheduleAsync.success, (state, action) => ({
    ...state,
    isUpdating: false,
    data: action.payload
  }))
  .handleAction(updateSeasonChallengeScheduleAsync.failure, (state, action) => ({
    ...state,
    isUpdating: false
  }))
  .handleAction(deleteSeasonChallengeScheduleAsync.request, (state, action) => ({
    ...state,
    isDeleting: action.payload
  }))
  .handleAction(deleteSeasonChallengeScheduleAsync.success, (state, action) => ({
    ...state,
    isDeleting: undefined,
    data: undefined
  }))
  .handleAction(deleteSeasonChallengeScheduleAsync.failure, (state, action) => ({
    ...state,
    isDeleting: undefined
  }));

export default seasonChallengeScheduleReducer;
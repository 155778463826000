import { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { cmSetMigrationActions } from '../../../../redux/catalog-migration/actions';
import type { MigrationActions } from '../../../../redux/catalog-migration/reducer';
import { useTypedSelector } from '../../../../redux/reducers';
import type { ItemDefinition } from '../../../../services/item-definitions';
import { BlankoDnaDiff } from '../../types';
import { BlankoDnas } from './BlankoDnas';

export const BlankoDnaContainer = () => {
  const dispatch = useDispatch();

  const sourceCatalog = useTypedSelector(state => state.catalogMigration.source.catalog.catalog);
  const targetCatalog = useTypedSelector(state => state.catalogMigration.target.catalog.catalog);
  const sourceDnas = useTypedSelector(state => state.catalogMigration.source.blankoDnas);
  const targetDnas = useTypedSelector(state => state.catalogMigration.target.blankoDnas);
  const actions = useTypedSelector(state => state.catalogMigration.actions.blankoDnas);

  const [sourceItems, setSourceItems] = useState<BlankoDnaDiff[] | null>(null);
  const [targetItems, setTargetItems] = useState<BlankoDnaDiff[] | null>(null);

  const onSelectionChange = useCallback(
    (actions: MigrationActions) => dispatch(cmSetMigrationActions({ id: 'blankoDnas', actions })),
    [dispatch]
  );

  useEffect(() => {
    if (sourceCatalog && sourceCatalog.items && sourceDnas && targetDnas) {
      const itemsMap: { [key: string]: ItemDefinition } = {};
      sourceCatalog.items.forEach(item => itemsMap[item.itemId] = item);

      const targetItemsMap: { [key: string]: ItemDefinition } = {};
      if (targetCatalog && targetCatalog.items) {
        targetCatalog.items.forEach(item => targetItemsMap[item.itemId] = item);
      }

      let includeBlankoAssets = true;
      if (sourceDnas.length > 0 && targetDnas.length > 0 && (!sourceDnas[0].blankoAssets || !targetDnas[0].blankoAssets)) {
        console.log('Incompatibilty detected. Removing assets from DNAs...');
        includeBlankoAssets = false;
      }

      setSourceItems(sourceDnas.map(dna => {
        const diff = new BlankoDnaDiff(dna, includeBlankoAssets);
        const item = itemsMap[dna.Id];
        if (item) {
          const targetItem = targetItemsMap[dna.Id];
          if (!targetItem) diff.existsInTarget = false;
          diff.blankoName = item.displayName;
          diff.imageThumbnailUrl = item.imageThumbnailUrl;
        }
        return diff;
      }));
      setTargetItems(targetDnas.map(dna => new BlankoDnaDiff(dna, includeBlankoAssets)));
    }
  }, [sourceDnas, targetDnas, sourceCatalog, targetCatalog]);

  return (<BlankoDnas
    sourceItems={sourceItems}
    targetItems={targetItems}
    actions={actions}
    onSelectionChange={onSelectionChange} />);
}

import { Box, Button, CircularProgress, Grid, Typography } from '@material-ui/core';
import { Component } from 'react';
import type { ConnectedProps } from 'react-redux';
import { connect } from 'react-redux';
import type { RouteComponentProps } from 'react-router';
import { setCatalogName } from '../../redux/catalog/actions';
import { getCatalogStoreAsync, updateCatalogStoreAsync } from '../../redux/catalog/stores/actions';
import type { RootState } from '../../redux/reducers';
import type { Store } from '../../services/stores';
import { UserService } from '../../services/user';
import StoreForm from './StoreForm';
import StoreTabs from './StoreTabs';
import StoreTabsEditor from './StoreTabsEditor';
import {Lock} from "@material-ui/icons";

const mapStateToProps = (state: RootState) => ({ reduxCatalogName: state.catalog.name, store: state.catalog.stores.store });
const mapDispatch = {
  setCatalogName,
  requestStore: getCatalogStoreAsync.request,
  requestUpdateStore: updateCatalogStoreAsync.request
};
const connector = connect(mapStateToProps, mapDispatch);

interface RouteProps {
  catalogName: string;
  storeId: string;
}
type Props = RouteComponentProps<RouteProps> & ConnectedProps<typeof connector>;

interface State {
  showTabsEditor: boolean;
}

class StorePage extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      showTabsEditor: false
    };
  }

  componentDidMount() {
    this.props.setCatalogName(this.props.match.params.catalogName);
    this.props.requestStore(this.props.match.params.storeId);
  }

  componentDidUpdate() {
    if (!this.props.match.params.storeId && this.props.store.data) {
      this.props.history.replace(`/catalogs/${this.props.match.params.catalogName}/stores/${this.props.store.data.storeId}`);
    }
  }

  getTitle() {
    if (!this.props.store.data) {
      return '';
    }

    if (UserService.canUpdate('store')) {
      return `Edit store ${this.props.store.data.storeId}`;
    } else {
      return `Store ${this.props.store.data.storeId}`;
    }
  }

  onFormSave = (store: Store) => {
    this.props.requestUpdateStore(store);
  }

  render() {
    const store = this.props.store;
    if (!this.props.reduxCatalogName || store.isLoading || !store.data) {
      return (
        <Box textAlign="center">
          <CircularProgress />
        </Box>
      );
    } else {
      if (store.data.locked && !UserService.canUpdate('storeLock')) {
        return (
          <Box textAlign="center">
            <Lock/>
            <Typography variant="h6">
              Access to this store is currently locked.
            </Typography>
          </Box>
        );
      } else {
        const readOnly = !UserService.canUpdate('store');
        const catalogName = this.props.match.params.catalogName;
        return (<>
          <Box py={2}>
            <Grid container>
              <Grid item xs>
                <Typography variant="h6">
                  {this.getTitle()}
                </Typography>
              </Grid>
              {UserService.canUpdate('store') && (
                <Grid item xs="auto">
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => this.setState({showTabsEditor: !this.state.showTabsEditor})}
                  >
                    {this.state.showTabsEditor ? 'Back to store' : 'Manage tabs'}
                  </Button>
                </Grid>
              )}
            </Grid>
          </Box>
          {this.state.showTabsEditor ? (
            <StoreTabsEditor catalogName={catalogName} store={store.data}/>
          ) : (store.data.tabs.length > 0 ? (
            <StoreTabs catalogName={catalogName} store={store.data} readOnly={!UserService.canUpdate('store')}/>
          ) : (
            <StoreForm
              catalogName={this.props.match.params.catalogName}
              store={store.data}
              onSave={this.onFormSave}
              readOnly={readOnly}
            />
          ))}
        </>);
      }
    }
  }
}

export default connector(StorePage);
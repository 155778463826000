import { DiffItem } from "../../../../pages/CatalogMigration/types";
import type { BrawlPinRule } from "../../../../services/model/brawl-pin";
import { MigrationType } from "../migration-type";

export interface InvalidRewardInfo {
  tierLevel: string;
  rewardItemId: string;
}

export class TitleDataPinRuleDiff extends DiffItem {
  public name: string;
  public invalidReward: InvalidRewardInfo | null;

  constructor(pinRule: BrawlPinRule, invalidReward: InvalidRewardInfo | null) {
    super();
    this.diffId = pinRule.Name;
    this.name = pinRule.Name;
    this.invalidReward = invalidReward;
    this.dependencyItem = {
      type: MigrationType.brawlPinTitleData,
      id: pinRule.Name,
    };
    this.diffJson = JSON.stringify(pinRule, null, 2);
  }
}

import { useCallback, useMemo, useState } from "react";
import {
  Box,
  Button,
  Grid,
  Link,
  Typography} from "@material-ui/core";
import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward";
import ArrowUpwardIcon from "@material-ui/icons/ArrowUpward";
import DeleteIcon from "@material-ui/icons/Delete";
import type { Column } from "material-table";
import MaterialTable from "../../../components/MaterialTable";
import type { MenuAction } from "../../../components/MaterialTable";
import { BrawlPin, BrawlPinUnlockCondition } from "../../../services/model/brawl-pin";
import _ from "lodash";
import { UserService } from "../../../services/user";
import { AddEditBrawlPinTierDialog } from "./AddEditBrawlPinTierDialog";
import { BrawlPinTierViewModel, getTierViewModels } from "./BrawlPinsViewModels";
import { ConditionGroups } from "./ConditionGroups";

/**
 * Concatenates the reward IDs together into a single comma-separated string.
 */
export const getTierRewardIds = (tierViewModel: BrawlPinTierViewModel): string => {
  return tierViewModel?.tier.rewardItemIds?.join(', ') || '-';
}

interface Props {
  brawlPin: BrawlPin;
  performActionWithValidation: (action: () => void) => void;
  // methods for managing tiers
  deleteTier: (tierViewModel: BrawlPinTierViewModel) => void;
  moveTierDown: (tierViewModel: BrawlPinTierViewModel) => void;
  moveTierUp: (tierViewModel: BrawlPinTierViewModel) => void;
  upsertBrawlPinTier:  (tierViewModel: BrawlPinTierViewModel, checkLevel: boolean) => void;
  // methods for managing unlock conditions
  deleteConditionFromTierGroup: (condition: BrawlPinUnlockCondition, tierViewModel: BrawlPinTierViewModel, groupIndex: number) => void;
  deleteConditionGroupFromTier: (tierViewModel: BrawlPinTierViewModel, groupIndex: number) => void;
  upsertUnlockConditionForTierGroup: (condition: BrawlPinUnlockCondition, tierViewModel: BrawlPinTierViewModel, groupIndex: number, checkUnlockType: boolean) => void;
}

export const BrawlPinTiers = (props: Props) => {
  const {
    brawlPin,
    deleteConditionFromTierGroup,
    deleteConditionGroupFromTier,
    deleteTier,
    moveTierDown,
    moveTierUp,
    upsertBrawlPinTier,
    upsertUnlockConditionForTierGroup,
    performActionWithValidation,
  } = props;

  const [showAddTierDialog, setShowAddTierDialog] = useState(false);
  const [tierToEdit, setTierToEdit] = useState<BrawlPinTierViewModel | undefined>(undefined);

  const tierMenuActions: MenuAction<BrawlPinTierViewModel>[] = useMemo(() => {
    const menuActions: MenuAction<BrawlPinTierViewModel>[] = [];
    if (UserService.canUpdate('brawlPins')) {
      menuActions.push({ type: 'button', icon: ArrowUpwardIcon, label: 'Move Up', onClick: moveTierUp });
      menuActions.push({ type: 'button', icon: ArrowDownwardIcon, label: 'Move Down', onClick: moveTierDown });
    }
    if (UserService.canDelete('brawlPins')) {
      menuActions.push({ type: 'button', icon: DeleteIcon, label: 'Delete', onClick: deleteTier });
    }
    return menuActions;
  }, [deleteTier, moveTierDown, moveTierUp]);

  const tierTableColumns: Column<BrawlPinTierViewModel>[] = useMemo(() => {
    return [
      {
        title: 'Level',
        render: tierViewModel => (
          <Grid container spacing={1} alignItems="center">
            {UserService.canUpdate('brawlPins') && (<>
              <Link component="button" onClick={() => performActionWithValidation(() => setTierToEdit(tierViewModel))}>
                {tierViewModel.tier.level}
              </Link>
            </>) || (<>
              <Box>
                {tierViewModel.tier.level}
              </Box>
            </>)}
          </Grid>
        )
      },
      {
        title: 'Reward Bundle',
        render: tierViewModel => (
          <Grid container spacing={1} alignItems="center">
            {getTierRewardIds(tierViewModel)}
          </Grid>
        )
      },
      {
        title: 'Unlock Condition Groups',
        render: tierViewModel => (<>
          <ConditionGroups
            tierViewModel={tierViewModel}
            deleteConditionGroupFromTier={deleteConditionGroupFromTier}
            deleteConditionFromTierGroup={deleteConditionFromTierGroup}
            upsertUnlockConditionForTierGroup={upsertUnlockConditionForTierGroup}
            performActionWithValidation={performActionWithValidation}
          />
        </>
        )
      }
    ]
  }, [deleteConditionFromTierGroup, deleteConditionGroupFromTier, performActionWithValidation, upsertUnlockConditionForTierGroup]);

  const handleClose = useCallback(() => {
    setShowAddTierDialog(false);
    setTierToEdit(undefined);
  }, [])

  return (<>
    <Grid item xs={12}>
      <Box mb={2}>
        <Grid container spacing={2} alignItems="center">
          <Grid item xs>
            <Typography variant="h6">
              Tiers
            </Typography>
          </Grid>
          {UserService.canCreate('brawlPins') && (<>
            <Grid item>
              <Button variant="contained" color="primary" onClick={() => performActionWithValidation(() => setShowAddTierDialog(true))}>Add Tier</Button>
            </Grid>
          </>)}
        </Grid>
      </Box>

      <MaterialTable<BrawlPinTierViewModel>
        columns={tierTableColumns}
        data={getTierViewModels(brawlPin)}
        options={{
          paging: false,
          search: false,
          toolbar: false,
          sorting: false,
          showTitle: false
        }}
        menuActions={tierMenuActions}
        useRouter
      />
    </Grid>
    {(showAddTierDialog || tierToEdit) && <AddEditBrawlPinTierDialog
      existingTierViewModel={tierToEdit}
      upsertBrawlPinTier={upsertBrawlPinTier}
      onClose={handleClose}
    />}
  </>
  );
};

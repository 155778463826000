export interface GeoBlockedFeature {
  featureId: string;
  regionId: string;
}

export interface GeoRegionEntry {
  id: string,
  label: string
}

export enum GeoBlockedFeatureIdEnum {
  reward_chests = "reward_chests"
}

export interface GeoBlockedFeatureMap {
  featureId?: GeoBlockedFeatureIdEnum;
  regions: string[];
}
import type { InputProps } from "@material-ui/core";
import { TextField } from "@material-ui/core";
import type { Control, Path } from "react-hook-form";
import { useController } from "react-hook-form";

export type Props<TForm> = {
  label: string | undefined;
  name: Path<TForm>;
  control: Control<TForm, unknown>;
  required: boolean | undefined;
  InputProps: Partial<InputProps>;
}

/** An MU TextField that can be bound into react-hook-form. */
export const ControlledTextField = <TForm,>(props: Props<TForm>) => {
  const { label, required, InputProps, ...controllerProps } = props;
  const { field: { ref, ...field }, fieldState } = useController<TForm>(controllerProps);

  const isError = fieldState.isDirty && fieldState.error !== undefined;
  const errorMessage = isError ? fieldState.error?.message : undefined;

  return <TextField
    {...field}
    label={label}
    helperText={errorMessage}
    error={isError}
    inputRef={ref}
    required={required}
    InputProps={InputProps}
    fullWidth 
  />
}
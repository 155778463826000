import {ApiError, api} from "./api";

export class DailyMessage {
  id = '';
  title = '';
  body = '';
  mediaUrl = '';
  file?: File;
  messageDate: Date = new Date();
  endDate: Date = new Date();

  dailyMessageItems: DailyMessageItem[] = [];
}

export interface DailyMessageItem {
  id: string;
  dailyMessageId: string;
  itemId: string;
}

export class DailyMessageService {
  static async getAllDailyMessages(): Promise<DailyMessage[]> {
    return api.get<DailyMessage[]>({ url: `/daily-message/all` })
      .catch((e: ApiError) => {
        e.message = `Failed to get all daily messages. ${e.message}`;
        throw e;
      });
  }

  static async addDailyMessage(message: DailyMessage): Promise<DailyMessage> {
    return api.post<DailyMessage>({ url: '/daily-message/', body: message })
      .catch((e: ApiError) => {
        e.message = `Failed to add daily message. ${e.message}`;
        throw e;
      });
  }

  static async updateDailyMessage(message: DailyMessage): Promise<DailyMessage> {
    return api.put<DailyMessage>({ url: `/daily-message/${message.id}`, body: message })
      .catch((e: ApiError) => {
        e.message = `Failed to update daily message. ${e.message}`;
        throw e;
      });
  }

  static async deleteDailyMessage(message: DailyMessage): Promise<null> {
    return api.delete({ url: `/daily-message/${message.id}` })
      .catch((e: ApiError) => {
        e.message = `Failed to delete daily message. ${e.message}`;
        throw e;
      });
  }

  static async uploadDailyMessageImage(image: File): Promise<string> {
    try {
      const { filename, url, publicUrl } = await api.get<{ filename: string, url: string, publicUrl: string }>({ url: '/daily-message/image-upload-url', query: { mimeType: image.type } });

      const options: RequestInit = {
        method: 'PUT',
        headers: new Headers({
          'Content-Type': image.type
        }),
        body: image
      };

      const response = await fetch(url, options);
      if (!response.ok) {
        throw new Error('');
      }

      return publicUrl;
    } catch (e) {
      throw new ApiError('Failed to upload daily message image');
    }
  }
}
import type {Column} from "material-table";
import {Grid, Tooltip} from "@material-ui/core";
import type {MenuAction} from "../../components/MaterialTable";
import MaterialTable from "../../components/MaterialTable";
import {openAdminConfirmationDialog} from '../../shared/hooks/useAdminConfirmationDialog';
import {
  Assessment,
  AssessmentOutlined,
  ChangeHistory,
  CheckCircle,
  Close,
  History,
  ThumbDownOutlined
} from "@material-ui/icons";
import {UserService} from "../../services/user";
import {cyan, green, grey, orange, red} from "@material-ui/core/colors";
import React, {useCallback, useEffect, useState} from 'react';
import {usePushNotification} from "../../contexts/AppNotificationContext";
import {ClientVersion, ClientVersionService, PatchStatus, ReleaseStatus} from "../../services/launcher/client-version";
import {ApiError} from "../../services/api";

type Props = {
  gridTitleText: string;
  clientNameFilter: string;
};

const getPatchStatusColor = (patchStatus: PatchStatus): string => {
  switch (patchStatus) {
  case PatchStatus.None: default: return 'transparent';
  case PatchStatus.NotStarted: return 'transparent';
  case PatchStatus.Processing: return grey[500];
  case PatchStatus.Completed: return cyan[500];
  case PatchStatus.Failed: return red[500];
  }
};

const getPatchTitle = (patchStatus: PatchStatus): string => {
  switch (patchStatus) {
  case PatchStatus.None: default: return "";
  case PatchStatus.NotStarted: return "";
  case PatchStatus.Processing: return "Processing patch (not ready)";
  case PatchStatus.Completed: return "Patch to this release is READY";
  case PatchStatus.Failed: return "Error occurred creating patch";
  }
};

export const ClientVersionList = ({ gridTitleText, clientNameFilter }: Props) => {

  const [clientVersions, setClientVersions] = useState<ClientVersion[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const pushNotification = usePushNotification();

  const getClientVersions = useCallback((): Array<ClientVersion> => {
    return clientVersions.filter(v => v.clientName === clientNameFilter && v.releaseStatus !== ReleaseStatus.Rejected);
  }, [clientVersions, clientNameFilter]);

  const columns: Column<ClientVersion>[] = [
    {
      render: clientVersion => (
        <Grid container spacing={1} alignItems="center">
          {clientVersion.patchStatus !== PatchStatus.None && (
            <Grid item xs="auto">
              <Tooltip title={getPatchTitle(clientVersion.patchStatus)}>
                <ChangeHistory style={{ color: getPatchStatusColor(clientVersion.patchStatus) }} />
              </Tooltip>
            </Grid>
          )}
          {clientVersion.releaseStatus === ReleaseStatus.Live && (
            <Grid item xs="auto">
              <Tooltip title="Live">
                <CheckCircle style={{ color: green[500] }} />
              </Tooltip>
            </Grid>
          )}
          {clientVersion.releaseStatus === ReleaseStatus.Canary && (
            <Grid item xs="auto">
              <Tooltip title="Canary">
                <Assessment style={{ color: orange[500] }} />
              </Tooltip>
            </Grid>
          )}
        </Grid>
      )
    },
    {
      title: 'Version',
      field: 'name',
      sorting: false
    },
    {
      title: 'Publish Date',
      field: 'publishDate',
      sorting: false,
      render: clientVersion => !!!clientVersion.publishDate || isNaN(clientVersion.publishDate.getTime())
        ? <em>- not published -</em>
        : <>{clientVersion.publishDate.toLocaleString()}</>
    },
    {
      title: 'Creation Date',
      field: 'creationDate',
      sorting: false,
      render: clientVersion => <>{clientVersion.creationDate.toLocaleString()}</>
    },
    {
      title: 'ID',
      field: 'id',
      sorting: false,
    }
  ];

  const getMenuActions = (clientVersion: ClientVersion) => {
    const actions: MenuAction<ClientVersion>[] = [];

    if (canPromoteLive(clientVersion)) {
      actions.push({
        type: 'button',
        icon: CheckCircle,
        label: 'Promote Live',
        onClick: confirmActivateLive
      });
    }

    if (canRollbackLive(clientVersion)) {
      actions.push({
        type: 'button',
        icon: History,
        label: 'Rollback Live to Previous Version',
        onClick: confirmRollbackLive
      });
    }

    if (canActivateCanary(clientVersion)) {
      actions.push({
        type: 'button',
        icon: Assessment,
        label: 'Activate Canary',
        onClick: confirmActivateCanary
      });
    }

    if (canRevokeCanary(clientVersion)) {
      actions.push({
        type: 'button',
        icon: AssessmentOutlined,
        label: 'Revoke Canary',
        onClick: confirmRevokeCanary
      });
    }

    if (canReject(clientVersion)) {
      actions.push({
        type: 'button',
        icon: ThumbDownOutlined,
        label: 'Reject Build',
        onClick: confirmRejectBuild
      });
    }

    if (actions.length > 0) {
      actions.push({
        type: 'button',
        icon: Close,
        label: 'Close Menu',
        onClick: () => { return; }
      });
    }

    return actions;
  };

  const canPromoteLive = (clientVersion: ClientVersion) => {
    if (!UserService.canUpdate('clientVersionLive')) return false;
    if (clientVersion.releaseStatus == ReleaseStatus.Canary) return true;

    return false;
  }

  const canRollbackLive = (clientVersion: ClientVersion) => {
    if (!UserService.canUpdate('clientVersionLive')) return false;

    const clientVersionData = getClientVersions();
    if (clientVersionData.length == 1) return false;

    const liveIsLastElement = clientVersionData.slice(-1)[0].id === clientVersion.id;
    if (liveIsLastElement) return false;

    if (clientVersion.releaseStatus == ReleaseStatus.Live) return true;

    return false;
  }

  const canActivateCanary = (clientVersion: ClientVersion) => {
    if (!UserService.canUpdate('clientVersionCanary')) return false;
    if (clientVersion.releaseStatus == ReleaseStatus.Unreleased) return true;

    return false;
  }

  const canRevokeCanary = (clientVersion: ClientVersion) => {
    if (!UserService.canUpdate('clientVersionCanary')) return false;
    if (clientVersion.releaseStatus == ReleaseStatus.Canary) return true;

    return false;
  }

  const canReject = (clientVersion: ClientVersion) => {
    if (!UserService.canDelete('clientVersion')) return false;
    if (clientVersion.releaseStatus == ReleaseStatus.Canary) return true;
    if (clientVersion.releaseStatus == ReleaseStatus.Unreleased) return true;

    return false;
  }

  const notifyError = useCallback((error: ApiError) => pushNotification({ type: 'error', message: error.message }), [pushNotification]);
  const notifySuccess = useCallback((message: string) => pushNotification({ type: 'success', message: message }), [pushNotification]);

  const notify = useCallback((data: any) => {
    if (data instanceof ApiError) {
      notifyError(data);
      return;
    }

    if (typeof data === "string") {
      notifySuccess(data);
    }
  }, [notifyError, notifySuccess]);

  const refreshData = useCallback(async () => {
    setIsLoading(true);
    try {
      const clientVersionsResult = await ClientVersionService.getAllClientVersions();
      setClientVersions(clientVersionsResult);
      setIsLoading(false);
    }
    catch(e) {
      notify(e);
    }
  }, [notify]);

  const confirmActivateCanary = (clientVersion: ClientVersion) => {
    const title = `Activate Canary for ${clientVersion.name}?`;
    const action = 'Yes';
    const details = 'This will deactivate any other canary versions, are you sure?';
    const onConfirm = () => activateCanary(clientVersion);
    openAdminConfirmationDialog({ title, details, action, onConfirm });
  };

  const activateCanary = useCallback(async (clientVersion: ClientVersion) => {
    try {
      await ClientVersionService.activateCanary(clientVersion.id);
      notify(`Activated Canary for ${clientVersion.name}.`)
    }
    catch(e) {
      notify(e);
    }
    finally {
      await refreshData();
    }
  }, [notify, refreshData]);

  const confirmRevokeCanary = (clientVersion: ClientVersion) => {
    const title = `Revoke Canary for ${clientVersion.name}?`;
    const action = 'Revoke';
    const details = '';
    const onConfirm = () => revokeCanary(clientVersion);
    openAdminConfirmationDialog({ title, details, action, onConfirm });
  };

  const revokeCanary = useCallback(async (clientVersion: ClientVersion) => {
    try {
      await ClientVersionService.revokeCanary(clientVersion.id);
      notify(`Revoked Canary for ${clientVersion.name}.`)
    }
    catch(e) {
      notify(e);
    }
    finally {
      await refreshData();
    }
  }, [notify, refreshData]);

  const confirmActivateLive = (clientVersion: ClientVersion) => {
    const title = `Promote ${clientVersion.name} to Live?`;
    const action = 'Promote Live';
    const details = `This action will retire the current live version, and make ${clientVersion.name} live. Enter the build version ${clientVersion.name} in the box below and click "Promote Live" to confirm.`;
    const requireCode = true;
    const code = clientVersion.name;
    const codeLabel = `Enter ${code}`;
    const onConfirm = () => finalConfirmActivateLive(clientVersion);
    openAdminConfirmationDialog({ title, details, action, requireCode, codeLabel, code, onConfirm });
  };

  const finalConfirmActivateLive = (clientVersion: ClientVersion) => {
    const title = `Confirmation: ${clientVersion.name}`;
    const details = `Final check, ${clientVersion.name} will go live to real users, are you sure?`;
    const action = 'Yes'
    const onConfirm = () => activateLive(clientVersion);
    openAdminConfirmationDialog({ title, details, action, onConfirm });
  };

  const activateLive = useCallback(async (clientVersion: ClientVersion) => {
    try {
      await ClientVersionService.activateLive(clientVersion.id, clientVersion.name);
      notify(`Marked ${clientVersion.name} Live`);
    }
    catch(e) {
      notify(e);
    }
    finally {
      await refreshData();
    }
  }, [notify, refreshData]);

  const confirmRollbackLive = (liveVersion: ClientVersion) => {
    const clientVersion = getPreviousVersion(liveVersion.id);

    const title = `Rollback Live to ${clientVersion.name}`;
    const action = 'Rollback';
    const details = `This action will un-release the current live version and make ${clientVersion.name} live. Enter the build version ${clientVersion.name} in the box below and click "Rollback" to confirm.`;
    const requireCode = true;
    const code = clientVersion.name;
    const codeLabel = `Enter ${code}`;
    const onConfirm = () => finalConfirmRollbackLive(clientVersion);
    openAdminConfirmationDialog({ title, details, action, requireCode, codeLabel, code, onConfirm });
  };

  const finalConfirmRollbackLive = (clientVersion: ClientVersion) => {
    const title = `Confirmation: ${clientVersion.name}`;
    const details = `Final check, ${clientVersion.name} will go live to real users, are you sure?`;
    const action = 'Yes';
    const onConfirm = () => rollbackLive(clientVersion);
    openAdminConfirmationDialog({ title, details, action, onConfirm });
  };

  const rollbackLive = useCallback(async (clientVersion: ClientVersion) => {
    try {
      await ClientVersionService.rollbackLive(clientVersion.clientName);
      notify(`Live version rolled back to ${clientVersion.name}`);
    }
    catch (e) {
      notify(e);
    }
    finally {
      await refreshData();
    }
  }, [notify, refreshData]);

  const getPreviousVersion = (id: string): ClientVersion => {
    const clientVersionData = getClientVersions();
    for (let i = 0; i < clientVersionData.length; i++) {
      if (clientVersionData[i].id == id) {
        return clientVersionData[i + 1];
      }
    }
    throw new Error("Could not locate version to rollback");
  };

  const confirmRejectBuild = (clientVersion: ClientVersion) => {
    const title = `Reject Build ${clientVersion.name}?`;
    const action = 'Reject';
    const details = `This will PERMANENTLY remove this build. Enter the build version ${clientVersion.name} in the box below to confirm.`;
    const requireCode = true;
    const code = clientVersion.name;
    const codeLabel = `Enter ${code}`;
    const onConfirm = () => deleteClientVersion(clientVersion);
    openAdminConfirmationDialog({ title, details, action, requireCode, codeLabel, code, onConfirm });
  };

  const deleteClientVersion = useCallback(async (clientVersion: ClientVersion) => {

    try {
      await ClientVersionService.deleteClientVersion(clientVersion.id, clientVersion.name);
      notify(`Rejected client version ${clientVersion.name}`);
    }
    catch(e) {
      notify(e);
    }
    finally {
      await refreshData();
    }
  }, [notify, refreshData]);

  useEffect(() => { refreshData().catch(); }, [refreshData]);

  return (<>
    <MaterialTable
      title={gridTitleText}
      columns={columns}
      data={getClientVersions()}
      isLoading={isLoading}
      options={{
        columnsButton: true,
        pageSize: 20,
        pageSizeOptions: [20, 50, 100]
      }}
      menuActions={getMenuActions}
    />
  </>);
}
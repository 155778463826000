import type { SelectProps } from "@material-ui/core";
import { InputLabel, Select } from "@material-ui/core";
import _ from "lodash";
import type { ReactNode } from "react";

/**
 * Fixes the fact that Select doesn't show the label for some reason.
 */
export const SelectWithLabel = (props: SelectProps) => {
  const populated = Boolean(props.value);

  return (<>
    {<InputLabel
      shrink
      htmlFor={props.id}
    >
      {populated && props.label || '\u00A0'}
    </InputLabel>}
    <Select
      {...props}
      renderValue={(value) => <>{getDisplayValue(value, props.children) || <InputLabel>{props.label}</InputLabel>}</>}
      displayEmpty={true}
    />
  </>);
};

export const getDisplayValue = (value: any, children: ReactNode) => {
  if (value && children) {
    // When the children object is an array, elements of the array can either
    // be select items or arrays of select items.  So in the array case,
    // we'll flatten into an array of select items.
    // If the chilren object is not an array, then it's a single select item,
    // so we'll put it into an array and treat it like the array case.
    const childArray: any[] = Array.isArray(children) ? _.flatten(children) : [children];

    // If we find a select item that matches the value, then grab the select item title
    // which is stored in props.children.
    const foundItem = childArray.find(child => child.props?.value === value)?.props.children;
    if (foundItem) {
      return foundItem;
    }
  }

  // if we make it this far, just return the value
  return value;
};

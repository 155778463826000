import { Box, Button, Grid, Typography } from '@material-ui/core';
import { useCallback, useMemo } from 'react';
import { openAdminConfirmationDialog } from '../../../shared/hooks/useAdminConfirmationDialog';
import type { MigrationCommonProps } from '../migrations';
import { MigrationType } from "../modules/migration-type";

export const MigrationReviewStep = (props: MigrationCommonProps) => {
  const { onNextStep } = props;

  const reviewComponents = useMemo(() => {
    return Object.entries(props.modules).map(([id, module]) => {
      const count = Object.values(props.selections[id as MigrationType] || {}).filter(v => v).length;
      if (count > 0) {
        return (
          <Grid key={id} container spacing={1}>
            <Grid item xs={6} lg={2}>
              <Typography variant="h6">
                {module.displayName}
              </Typography>
            </Grid>
            <Grid item xs={6} lg={2}>
              <Typography variant="h6">
                {count}
              </Typography>
            </Grid>
          </Grid>
        );
      } else {
        return null;
      }
    }).filter(v => v !== null);
  }, [props.selections, props.modules]);

  const onRunClick = useCallback(() => {
    openAdminConfirmationDialog({
      title: `${props.runMigrationTitle}?`,
      details: 'This cannot be cancelled or undone! This may also take a while. Please don\'t close this window while the process is running.',
      action: `${props.runMigrationTitle}`,
      onConfirm: onNextStep
    });
  }, [onNextStep, props.runMigrationTitle]);

  return (<>
    <Box mt={2}>
      {reviewComponents}
    </Box>

    <Box mt={4}>
      <Grid container spacing={1}>
        <Grid item xs="auto">
          <Button variant="outlined" onClick={props.onPrevStep}>
            Prev
          </Button>
        </Grid>
        <Grid item xs>
          <Button
            variant="contained"
            color="primary"
            onClick={onRunClick}
          >
            {props.runMigrationTitle}
          </Button>
        </Grid>
      </Grid>
    </Box>
  </>);
}

import { Box, Grid, Card, CardHeader, CardContent } from '@material-ui/core';
import type {
  PlayerSeasonPathResponse
} from '../../services/player-challenges/player-path';
import RewardOverviewGrid from './RewardOverviewGrid';

interface Props {
  playerSeasonPath: PlayerSeasonPathResponse;
}

const SeasonOverview = ({ playerSeasonPath }: Props) => {

  const checkForRewards = (pool: string[]): any => {
    const pathTierMap = playerSeasonPath.pathTierMap;
    const rewardTotals: any[] = [];

    for (let i = 0; i < pool.length; i++) {
      if (pathTierMap[pool[i]] != null) {
        const currentReward = pathTierMap[pool[i]];
        const rewardPool = currentReward.rewards.concat(currentReward.rewardsPartyPassPlus != null ? currentReward.rewardsPartyPassPlus : []);
        for (let x = 0; x < rewardPool.length; x++) {
          const currentLook: any = rewardPool[x];
          if (rewardTotals.filter((r: any) => r.id === currentLook.param.itemId).length > 0) {
            rewardTotals.find((r: any) => r.id === currentLook.param.itemId).count =
            rewardTotals.find((r: any) => r.id === currentLook.param.itemId).count + 1;
          } else if (rewardTotals.filter((r: any) => r.id === `${currentLook.param.currency  } ${  currentLook.param.amount}`).length > 0) {
            rewardTotals.find((r: any) => r.id === `${currentLook.param.currency  } ${  currentLook.param.amount}`).count =
            rewardTotals.find((r: any) => r.id === `${currentLook.param.currency  } ${  currentLook.param.amount}`).count + 1;
          } else {
            rewardTotals.push({
              id: currentLook.type === "GrantItem" ? currentLook.param.itemId : `${currentLook.param.currency  } ${  currentLook.param.amount}`,
              count: 1
            });
          }
        }
      }
    }

    return rewardTotals;
  };

  const claimedRewards = checkForRewards(playerSeasonPath.path.claimedTierIds);
  const unclaimedRewards = checkForRewards(playerSeasonPath.path.unclaimedTierIds);

  return (<>
    <Box mb={2}>
      <Card>
        <CardHeader title={`Reward Summary for Season: ${playerSeasonPath.seasonNumber}`} />
        <CardContent>
          <Grid container spacing={1}>
            <RewardOverviewGrid
              rewards={claimedRewards}
              title="Claimed"
            />
            <RewardOverviewGrid
              rewards={unclaimedRewards}
              title="Unclaimed"
            />
          </Grid>
        </CardContent>
      </Card>
    </Box>
  </>);
}

export default SeasonOverview;
import { call, put, takeLatest } from 'redux-saga/effects';
import type { RewardPath} from '../../../services/player-challenges/season-reward-paths';
import { SeasonRewardPathService } from '../../../services/player-challenges/season-reward-paths';
import { setAppNotification } from '../../app/actions';
import { forkSagas, sagaHandleApiError } from '../../utils-ts';
import {
  deleteRewardPathAsync,
  getRewardPathAsync,
  createRewardPathAsync,
  updateRewardPathAsync
} from './actions';

function* fetchRewardPath(action: ReturnType<typeof getRewardPathAsync.request>) {
  try {
    const rewardPath: RewardPath = yield call(SeasonRewardPathService.getSeasonRewardPath, action.payload);
    yield put(getRewardPathAsync.success(rewardPath));
  } catch (e) {
    yield sagaHandleApiError(e, getRewardPathAsync.failure);
  }
}

function* createRewardPathSaga(action: ReturnType<typeof createRewardPathAsync.request>) {
  try {
    
    const rewardPath: RewardPath = yield call(SeasonRewardPathService.createSeasonRewardPath, action.payload.seasonNumber, action.payload.rewardPathJson);
    yield put(updateRewardPathAsync.success(rewardPath));
    yield put(setAppNotification({ type: 'success', message: 'Season Reward Path updated' }));
  } catch (e) {
    yield sagaHandleApiError(e, updateRewardPathAsync.failure);
  }
}

function* updateRewardPathSaga(action: ReturnType<typeof updateRewardPathAsync.request>) {
  try {
    
    const rewardPath: RewardPath = yield call(SeasonRewardPathService.updateSeasonRewardPath, action.payload.seasonNumber, action.payload.pathId, action.payload.rewardPath);
    yield put(updateRewardPathAsync.success(rewardPath));
    yield put(setAppNotification({ type: 'success', message: 'Season Reward Path updated' }));
  } catch (e) {
    yield sagaHandleApiError(e, updateRewardPathAsync.failure);
  }
}

function* deleteRewardPathSaga(action: ReturnType<typeof deleteRewardPathAsync.request>) {
  try {
    yield call(SeasonRewardPathService.deleteSeasonRewardPath, action.payload.seasonNumber, action.payload.pathId);
    yield put(deleteRewardPathAsync.success(action.payload.seasonNumber));
    yield put(setAppNotification({ type: 'success', message: 'Season Reward Path deleted' }));
  } catch (e) {
    yield sagaHandleApiError(e, deleteRewardPathAsync.failure);
  }
}

const sagas = [
  function* () {
    yield takeLatest(getRewardPathAsync.request, fetchRewardPath);
  },
  function* () {
    yield takeLatest(createRewardPathAsync.request, createRewardPathSaga);
  },
  function* () {
    yield takeLatest(updateRewardPathAsync.request, updateRewardPathSaga);
  },
  function* () {
    yield takeLatest(deleteRewardPathAsync.request, deleteRewardPathSaga);
  }
];

export default function* seasonsSagas() {
  yield forkSagas(sagas);
}
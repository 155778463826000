import { Box, FormControlLabel, Grid, Link, Switch, Tooltip } from '@material-ui/core';
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';
import StarIcon from '@material-ui/icons/Star';
import StarBorderIcon from '@material-ui/icons/StarBorder';
import EditIcon from '@material-ui/icons/Edit';
import type { Column } from 'material-table';
import {useState, useEffect, useRef, useCallback} from 'react';
import { Link as RouterLink } from 'react-router-dom';
import type { MenuAction } from '../../components/MaterialTable';
import MaterialTable from '../../components/MaterialTable';
import config from '../../config';
import type { PublishedBlock } from '../../services/blocks';
import { BlocksService } from '../../services/blocks';
import type { Player } from '../players/players';
import { UserService } from '../../services/user';
import { openAdminConfirmationDialog } from '../../shared/hooks/useAdminConfirmationDialog';
import {UpdatePublishedBlockDialog} from "./components/UpdatePublishedBlockDialog";
import {MaterialTableCallback} from "../../utils/MaterialTableCallback";

interface OwnProps {
  player?: Player;
}

export const PublishedBlocks = (props: OwnProps) =>  {

  const [showVerifiedOnly, setShowVerifiedOnly] = useState(false);
  const [showFeaturedOnly, setShowFeaturedOnly] = useState(false);
  const [latestUpdatedBlock, setLatestUpdatedBlock] = useState<PublishedBlock>();
  const [updateBlock, setUpdateBlock] = useState<PublishedBlock>();
  const tableRef = useRef<MaterialTable<PublishedBlock>>();
  const [filters, setFilters] = useState<string[]>([]);

  useEffect(() => {
    const array = [];
    if (showFeaturedOnly){
      array.push("featured");
    }
    if (showVerifiedOnly){
      array.push("verified");
    }
    setFilters(array);
  }, [latestUpdatedBlock, showFeaturedOnly, showVerifiedOnly]);

  const columns: Column<PublishedBlock>[] = [
    {
      title: 'ID',
      field: 'id',
      hidden: config.env === 'prod'
    },
    {
      title: 'Name',
      field: 'name',
      render: block => (
        <Grid container spacing={1} alignItems="center">
          {block.verified && (
            <Grid item xs="auto">
              <Tooltip title="Verified">
                <CheckIcon color="secondary" />
              </Tooltip>
            </Grid>
          )}
          {block.featured && (
            <Grid item xs="auto">
              <Tooltip title="Featured">
                <StarIcon color="secondary" />
              </Tooltip>
            </Grid>
          )}
          <Grid item xs>
            <Link component={RouterLink} to={`/published-blocks/${block.id}`}>
              {block.name}
            </Link>
          </Grid>
        </Grid>
      )
    },
    { title: 'Game mode', field: 'primaryGameMode', sorting: false },
    { title: 'Mode type', field: 'playType', sorting: false },
    { title: 'Play count', field: 'playCount', defaultSort: 'desc' },
    { title: 'Unique join count', field: 'playerUniqueJoinedCount', sorting: false },
    { title: 'Total join count', field: 'playerTotalJoinedCount', sorting: false },
    { title: 'Unique completed count', field: 'playerUniqueCompletedCount', sorting: false },
    { title: 'Total completed count', field: 'playerTotalCompletedCount', sorting: false },
    {
      title: 'Churn',
      sorting: false,
      render: block => (<>
        {block.playerTotalJoinedCount !== 0 ? `${((block.playerTotalJoinedCount - block.playerTotalCompletedCount) * 100.0 / block.playerTotalJoinedCount).toFixed(2)}%` : '-'}
      </>)
    },
    {
      title: 'Report count',
      field: 'totalReportCount',
      sorting: false,
      render: publishedBlock => (
        <Link component={RouterLink} to={`/blocks/${publishedBlock.blockId}`}>
          {publishedBlock.totalReportCount}
        </Link>
      )
    },
    // eslint-disable-next-line react/display-name
    { title: 'Last played', sorting: false, render: () => <>-</> },
    {
      title: 'Creator',
      field: 'creatorName',
      sorting: false,
      render: publishedBlock => !props.player ? (
        (UserService.canRead('player') ?
          <Link component={RouterLink} to={`/players/${publishedBlock.creatorId}`}>
            {publishedBlock.creatorName || publishedBlock.creatorId}
          </Link>
          : <>{publishedBlock.ownerName || publishedBlock.ownerId}</>)
      ) : <></>
    },
    {
      title: 'Owner',
      field: 'ownerName',
      sorting: false,
      render: publishedBlock => !props.player ? (
        (UserService.canRead('player') ?
          <Link component={RouterLink} to={`/players/${publishedBlock.ownerId}`}>
            {publishedBlock.ownerName || publishedBlock.ownerId}
          </Link>
          : <>{publishedBlock.ownerName || publishedBlock.ownerId}</>)
      ) : <></>
    },
    {
      title: 'Last Updated',
      field: 'updatedTimeStamp',
      sorting: false,
      render: publishedBlock => <>{publishedBlock.updatedTimeStamp.toLocaleString()}</>
    }
  ];

  const getMenuActions = (block: PublishedBlock) => {
    const actions: MenuAction<PublishedBlock>[] = [];
    if (UserService.canUpdate('publishedBlocks')) {
      actions.push({ type: 'button', icon: block.featured ? StarBorderIcon : StarIcon, label: block.featured ? 'Remove featured' : 'Set featured', onClick: handleToggleFeaturedClick });
      actions.push({ type: 'button', icon: EditIcon, label: 'Rename', onClick: handleEditNameClick });
    }
    if (UserService.canDelete('publishedBlocks')) {
      if (actions.length > 0) {
        actions.push({ type: 'divider' });
      }
      actions.push({ type: 'button', icon: CloseIcon, label: 'Unpublish', onClick: handleUnpublishClick });
    }
    return actions;
  };

  const handleEditNameClick = useCallback((block: PublishedBlock) => {
    setUpdateBlock(block);
  }, []);

  const handleToggleFeaturedClick = useCallback((block: PublishedBlock) => {
    openAdminConfirmationDialog({
      title: block.featured ? `Remove ${block.name} from featured blocks?` : `Add ${block.name} to featured blocks?`,
      action: block.featured ? 'Remove featured' : 'Set featured',
      onConfirm: () => {
        block.featured = !block.featured;
        BlocksService.updatePublishedBlockFeatured(block);
        setLatestUpdatedBlock(block);
      }
    });
  }, []);

  const handleUnpublishClick = useCallback((block: PublishedBlock) => {
    openAdminConfirmationDialog({
      title: `Unpublish ${block.name}?`,
      action: 'Unpublish',
      onConfirm: () => {
        BlocksService.unpublishBlock(block.blockId);
        setLatestUpdatedBlock(block);
      }
    });
  }, []);

  return (<>
    {(
      <Box mb={2}>
        <FormControlLabel
          control={
            <Switch
              checked={showVerifiedOnly}
              onChange={event => setShowVerifiedOnly(event.target.checked)}
              color="primary"
            />
          }
          label="Verified only"
        />
        <FormControlLabel
          control={
            <Switch
              checked={showFeaturedOnly}
              onChange={event => setShowFeaturedOnly(event.target.checked)}
              color="primary"
            />
          }
          label="Featured only"
        />
      </Box>
    )}
    {updateBlock && (
      <UpdatePublishedBlockDialog
        block={updateBlock}
        onClose={() => setUpdateBlock(undefined)}
      />
    )}
    <MaterialTableCallback
      title="Published Blocks"
      tableRef={tableRef}
      columns={columns}
      data={BlocksService.getPublishedBlocksPage}
      options={{
        columnsButton: !props.player,
        debounceInterval: 500,
        pageSize: 20,
        pageSizeOptions: [20, 50, 100],
        thirdSortClick: false
      }}
      menuActions={getMenuActions}
      filters={filters}
    />
  </>);
}
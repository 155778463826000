
export interface AdminConfirmationDialogProps {
  title: string;
  details?: string;
  action?: string;
  code?: string;
  codeLabel?: string;
  includeNote?: boolean;
  requireNote?: boolean;
  requireCode?: boolean;
  multiLineNote?: boolean;
  onConfirm: (note?: string) => void;
  onCancel?: () => void;
}

let hookCallback: ((props: AdminConfirmationDialogProps) => void) | null = null;

export function openAdminConfirmationDialog(props: AdminConfirmationDialogProps) {
  if (hookCallback) {
    hookCallback(props);
  }
}

export function useAdminConfirmationDialog(callback: (props: AdminConfirmationDialogProps) => void) {
  hookCallback = callback;
}

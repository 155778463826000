import * as Yup from 'yup';
import { CapstoneEnum } from './blankoGemRush';

export const fuseFormSchema = Yup.object().shape({
  baseDnaId: Yup.string().uuid('Must provide valid DNA UUID').required('Required'),
  upgradeDnaId: Yup.string().uuid('Must provide valid DNA UUID').required('Required'),
  maxQuantity: Yup.number().required('Required'),
  remainingQuantity: Yup.number().max(Yup.ref("maxQuantity"), 'Cannot be larger then Max Quantity').required('Required'),
  capstone: Yup.array().of(
    Yup.object().shape({
      type: Yup.mixed<CapstoneEnum>().oneOf(Object.values(CapstoneEnum)),
      param: Yup.object()
        .when('type', {
          is: CapstoneEnum.BurnGumballs,
          then: Yup.object().shape({
            amount: Yup.number()
          })
        })
        .when('type', {
          is: CapstoneEnum.BurnCurrency,
          then: Yup.object().shape({
            BB: Yup.number(),
            GV: Yup.number()
          })
        })
    })
  )
});

export const rushValidationSchema = Yup.object().shape({
  name: Yup.string().required('Required'),
  startDate: Yup.date().required('Required'),
  endDate: Yup.date().required('Required'),
});
  
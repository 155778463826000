import { Component } from 'react';
import type { RootState } from '../../redux/reducers';
import type { ConnectedProps } from 'react-redux';
import { connect } from 'react-redux';
import { Box, Button, Card, CardContent, CardHeader, CircularProgress, Grid, TextField } from '@material-ui/core';
import { openConfirmationDialog, setAppNotification } from '../../redux/app/actions';

import type { RewardPath } from '../../services/player-challenges/season-reward-paths';
import { getRewardPathAsync, createRewardPathAsync, updateRewardPathAsync, deleteRewardPathAsync } from '../../redux/player-challenges/season-reward-path/actions';
import { UserService } from '../../services/user';
import SeasonRewardPathTiers from './SeasonRewardPathTiers';

interface ComponentProps {
  seasonNumber: number
}

const mapStateToProps = (state: RootState) => ({
  seasonRewardPath: state.seasonRewardPath
});

const mapDispatch = {
  requestGetRewardPath: getRewardPathAsync.request,
  requestCreateRewardPath: createRewardPathAsync.request,
  requestUpdateRewardPath: updateRewardPathAsync.request,
  requestDeleteRewardPath: deleteRewardPathAsync.request,
  openConfirmationDialog,
  setAppNotification
};

const connector = connect(mapStateToProps, mapDispatch);

type Props = ComponentProps & ConnectedProps<typeof connector>;

interface State {
  rewardPath?: RewardPath,
  prevRewardPathData?: RewardPath
}

class SeasonRewardPath extends Component<Props, State> {
  private mockDate = (new Date()).toLocaleString();

  constructor(props: Props) {
    super(props);
    this.state = {
      rewardPath: undefined,
      prevRewardPathData: undefined,
    }
  }

  componentDidMount() {
    this.props.requestGetRewardPath(this.props.seasonNumber);
  }

  componentDidUpdate(prevProps: Props, prevState: State) {
    if (!this.props.seasonRewardPath.isLoading && this.props.seasonRewardPath.data === undefined) {
      this.props.requestGetRewardPath(this.props.seasonNumber);
    }
  }

  static getDerivedStateFromProps(props: Props, state: State) {
    if (!!props.seasonRewardPath.data && !state.rewardPath) {
      return ({ rewardPath: props.seasonRewardPath.data.clone(), prevRewardPathData: props.seasonRewardPath.data });
    }
    if (props.seasonRewardPath.data && state.rewardPath && state.prevRewardPathData !== props.seasonRewardPath.data) {
      return ({ rewardPath: props.seasonRewardPath.data.clone(), prevRewardPathData: props.seasonRewardPath.data });
    }
    return null;
  }

  onRewardPathDelete = (rewardPath: RewardPath) => {
    this.props.openConfirmationDialog({ title: `Delete Path: ${rewardPath.path_id} - ${rewardPath.path_name}?`, action: 'Delete Path' }, () => {
      this.props.requestDeleteRewardPath({ seasonNumber: this.props.seasonNumber, pathId: rewardPath.path_id });
    });
  };


  render() {
    const rewardPath = this.state.rewardPath;
    const isSaving =  this.props.seasonRewardPath.isSaving;
    const readOnly = !UserService.canUpdate('seasons');
    let pathIdLocked = true;

    if (!this.props.seasonRewardPath.data || '' === this.props.seasonRewardPath.data.path_id) {
      pathIdLocked = false;
    }

    if (this.props.seasonRewardPath.isLoading || !rewardPath) {
      return (
        <Box textAlign="center">
          <CircularProgress />
        </Box>
      );
    }

    return (<>
      <Grid container spacing={3}>
        <Grid item xs={12} md={12} xl={12}>
          <Card>
            <CardHeader title="Path" />
            <CardContent>
              <>
                <Grid container spacing={1}>
                  <Grid item xs={12}>
                    <TextField
                      label="Path Id"
                      fullWidth
                      InputProps={{ readOnly: pathIdLocked }}
                      onChange={(e) => {
                        rewardPath.path_id = e.target.value;
                        this.setState({ rewardPath: rewardPath })
                      }}
                      value={rewardPath.path_id}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      label="Name"
                      fullWidth
                      InputProps={{ readOnly: readOnly }}
                      onChange={(e) => {
                        rewardPath.path_name = e.target.value;
                        this.setState({ rewardPath: rewardPath })
                      }}
                      value={rewardPath.path_name}
                    />
                  </Grid>
                </Grid>
                <Grid container>
                  <Box pt={1} pr={1}>
                    <Button variant="contained" disabled={isSaving} color="primary" size="small" onClick={() => {
                      if (!pathIdLocked) {
                        this.props.requestCreateRewardPath({ seasonNumber: this.props.seasonNumber, rewardPathJson: rewardPath.toJson() });
                      } else {
                        this.props.requestUpdateRewardPath({ seasonNumber: this.props.seasonNumber, pathId: rewardPath.path_id, rewardPath: rewardPath })
                      }
                    }}>Save</Button>
                  </Box>
                  <Box pt={1} pr={1}>
                    { pathIdLocked && 
                      <Button variant="outlined" disabled={isSaving} color="secondary" size="small" onClick={() => {
                        this.onRewardPathDelete(rewardPath);
                      }}>Delete</Button>
                    }
                  </Box>
                </Grid>
              </>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} md={12} xl={12}>
          { pathIdLocked && <SeasonRewardPathTiers seasonNumber={this.props.seasonNumber} pathId={rewardPath.path_id} /> }
        </Grid>        
      </Grid>
    </>);
  }
}

export default connector(SeasonRewardPath);
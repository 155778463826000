import type { Column } from 'material-table';
import type { MenuAction } from '../../../components/MaterialTable';
import MaterialTable from '../../../components/MaterialTable';
import DeleteIcon from '@material-ui/icons/Delete';
import type { ChallengeHistory, RewardPathTierClaimHistory } from '../../../services/player-challenges/player-path';
import { PlayerPathService , CalendarTypeEnum } from '../../../services/player-challenges/player-path';

import { Box, Card, CardHeader, CardContent, Accordion, AccordionSummary, Typography, AccordionDetails } from '@material-ui/core';
import RewardPathTierControl from '../../../pages/SeasonalData/RewardPathTierControl';
import { ExpandMore } from "@material-ui/icons";
import config, { configs } from '../../../config';
import ConfirmationDialog from '../../../components/ConfirmationDialog'
import { openAdminConfirmationDialog } from '../../../shared/hooks/useAdminConfirmationDialog';

interface PlayerSeasonChallengesProps {
  challengeHistory: ChallengeHistory[];
  challengeMap: Record<string, Challenge>;
  pathTierMap: Record<string, RewardPathTier>;
  rewardPathTierClaimHistory: RewardPathTierClaimHistory[],
  playerId: string,
  reloadCallback: () => Promise<void>
}
export const PlayerSeasonChallenges = ({
  challengeHistory,
  challengeMap,
  pathTierMap,
  rewardPathTierClaimHistory,
  playerId,
  reloadCallback
}: PlayerSeasonChallengesProps) => {
  const columns: Column<ChallengeHistory>[] = [
    { title: 'Type', field: 'calendarType' },
    {
      title: 'Day/Week Number', field: 'calendarNumber',
      render: (a) => a.calendarType.valueOf() !== CalendarTypeEnum.SEASONAL.valueOf() ? a.calendarNumber : '--'
    },
    {
      title: 'Challenge', field: 'challengeId',
      render: (a) => challengeMap[a.challengeId].challengeName,
      customSort: (a, b) => challengeMap[a.challengeId].challengeName.localeCompare(challengeMap[b.challengeId].challengeName),
      customFilterAndSearch: (filter: string, a) => {
        if (!a || !challengeMap[a.challengeId]) return false;
        return challengeMap[a.challengeId].challengeName.toLowerCase().indexOf(filter.toLowerCase()) > -1;
      }
    },
    { title: 'Rewards', sorting: false, render: (a) => seasonRewardsToSmallString(a) },
    { title: 'Completed At (Local)', field: 'completedAt', render: (a) => new Date(a.completedAt).toLocaleString() },
  ];

  const seasonRewardsToSmallString = (challengeHistory: ChallengeHistory): string => {
    const strings: string[] = [];

    if (challengeHistory.seasonalTokensGranted) {
      strings.push(`Season Tokens: ${challengeHistory.seasonalTokensGranted}`);
    }
    if (challengeHistory.currencyGranted) {
      for (const [k, v] of Object.entries(challengeHistory.currencyGranted)) {
        strings.push(`Currency: ${k}${v}`);
      }
    }
    if (challengeHistory.itemsGranted) {
      challengeHistory.itemsGranted.forEach((itemInstance) => {
        strings.push(`Grant Item: ${itemInstance.itemId}(${itemInstance.catalogName})`);
      });
    }

    return strings.filter((o, index) => index < 4) // only sum up 3 rewards, otherwise string gets too long
      .join(', ');
  }

  const deleteChallenge = async (history: ChallengeHistory) => {
    const title = "Delete Challenge History Record";
    const deets = "This will also cleanup ALL audit records related to the challenge type, which can impact other in progress challenges of the same type. "
    +" For QA/Testing purposes only. ";

    openAdminConfirmationDialog({title: title, details: deets, action: "Delete",
      onConfirm: () => {PlayerPathService.deleteChallengeHistory(playerId, history.instanceId).then(reloadCallback)}
    });
  };

  const getTableMenuActions = (_: ChallengeHistory): MenuAction<ChallengeHistory>[] => {
    const actions: MenuAction<ChallengeHistory>[] = [];
    if(config.env != configs.prod.env) {
      actions.push({ type: 'button', icon: DeleteIcon, label: 'Delete', onClick: deleteChallenge });
    }
    return actions;
  }

  const getDisplayPathTier = (rewardPathTierClaimHistory: RewardPathTierClaimHistory, pathTier: RewardPathTier): RewardPathTier => {
    const newPathTier = { ...pathTier };
    newPathTier.rewards = [];
    if (rewardPathTierClaimHistory.currencyGranted) {
      for (const [k, v] of Object.entries(rewardPathTierClaimHistory.currencyGranted)) {
        newPathTier.rewards.push({
          type: "GrantCurrency",
          param: {
            amount: v,
            currency: k
          }
        });
      }
    }

    if (rewardPathTierClaimHistory.itemsGranted) {
      rewardPathTierClaimHistory.itemsGranted.forEach(i => {
        newPathTier.rewards.push({
          type: "GrantItem",
          param: {
            catalogName: i.catalogName,
            itemId: i.itemId
          }
        });
      })
    }

    if (rewardPathTierClaimHistory.seasonalTokensGranted) {
      newPathTier.rewards.push({
        type: "GrantSeasonTokens",
        param: {
          amount: rewardPathTierClaimHistory.seasonalTokensGranted
        }
      });
    }
    return newPathTier;
  }

  return (<>

    <Box mb={2}>
      <Card>
        <CardHeader title="Claimed Tiers History" />
        <CardContent>
          {rewardPathTierClaimHistory.map((rewardPathTierClaimHistory: RewardPathTierClaimHistory, index: number) => {
            const pathTier = pathTierMap[rewardPathTierClaimHistory.tierId];
            const displayPathTier = getDisplayPathTier(rewardPathTierClaimHistory, pathTier)
            return (
              <Accordion TransitionProps={{ unmountOnExit: true }} key={index}>
                <AccordionSummary expandIcon={<ExpandMore />}>
                  <Typography variant="h5">
                    {pathTier.tierName}
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <RewardPathTierControl editMode={false} rewardPathTier={displayPathTier} />
                </AccordionDetails>
              </Accordion>
            );
          })}
        </CardContent>
      </Card>
    </Box>
    <Box mb={2}>
      <Card>
        <CardHeader title="Completed Challenge History" />
        <CardContent>
          <MaterialTable
            title="Challenges"
            columns={columns}
            data={challengeHistory}
            options={{
              pageSize: 10
            }}
            menuActions={getTableMenuActions}
          />
        </CardContent>
      </Card>
    </Box>
  </>);
}
import { Box, Checkbox, Accordion, AccordionDetails, AccordionSummary, Grid } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import type { Column } from 'material-table';
import * as React from 'react';
import ReactDiffViewer, { DiffMethod } from 'react-diff-viewer';
import MaterialTable from '../../../../components/MaterialTable';
import type { DiffItem } from '../../types';

interface DiffsPanelProps<T extends DiffItem> {
  expanded: boolean;
  title: string;
  diffType: 'change' | 'add' | 'remove';
  items: T[];
  selectedIds: string[];
  selectionMap: { [key: string]: boolean };
  columns: Column<T>[];
  darkMode?: boolean;
  onExpand(): void;
  onSelectClick(item: T): void;
  onSelectAllClick(event: React.MouseEvent): void;
}

export const DiffsPanel = <T extends DiffItem>({
  expanded,
  title,
  diffType,
  items,
  selectedIds,
  selectionMap,
  columns,
  darkMode,
  onExpand,
  onSelectClick,
  onSelectAllClick
}: DiffsPanelProps<T>) => (
  <>
    <Accordion
      expanded={expanded}
      disabled={items.length < 1}
      TransitionProps={{ unmountOnExit: true }}
      onChange={onExpand}
      >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        >
        <Grid container alignItems="center" spacing={2}>
          <Grid item xs="auto">
            <Checkbox
              style={{ padding: 0 }}
              onClick={onSelectAllClick}
              onFocus={event => event.stopPropagation()}
              checked={items.length > 0 && selectedIds.length >= items.length}
              indeterminate={selectedIds.length > 0 && selectedIds.length < items.length}
              disabled={items.length < 1}
              />
          </Grid>
          <Grid item xs>
            {title} ({selectedIds.length} / {items.length})
          </Grid>
        </Grid>
      </AccordionSummary>
      <AccordionDetails style={{ padding: 0 }}>
        <Box width="100%">
          <MaterialTable
            title=""
            columns={[
                { 
                  title: '', 
                  cellStyle: { width: 1 },
                  // eslint-disable-next-line react/display-name
                  render: item => <Checkbox style={{ padding: 0 }} checked={!!selectionMap[item.diffId]} onClick={() => onSelectClick(item)} /> 
                },
                ...columns
            ]}
            data={items}
            options={{
                pageSize: 10
            }}
            components={{
                Container: Box
            }}
            detailPanel={item => (
              <ReactDiffViewer
                oldValue={diffType === 'remove' ? item.diffJson : item.diffJsonPrev}
                newValue={diffType === 'remove' ? item.diffJsonPrev : item.diffJson}
                splitView
                useDarkTheme={darkMode}
                showDiffOnly
                compareMethod={DiffMethod.LINES}
                styles={{
                    variables: {
                      dark: {
                        codeFoldContentColor: '#fff'
                      }
                    }
                }}
                />
            )}
            />
        </Box>
      </AccordionDetails>
    </Accordion>
  </>
);

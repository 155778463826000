import { DiffItem } from "../../../../pages/CatalogMigration/types";
import type { BrawlPin } from "../../../../services/model/brawl-pin";
import { MigrationType } from "../migration-type";

export class BrawlPinDiff extends DiffItem {
  public name: string;

  constructor(pin: BrawlPin) {
    super();
    this.diffId = pin.name;
    this.name = pin.name;
    this.dependencyItem = {
      type: MigrationType.brawlPins,
      id: pin.name,
    };
    // this excludes all id properties
    this.diffJson = JSON.stringify(pin, (key, val) => key === 'id' ? undefined : val, 2);
  }
}

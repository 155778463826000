import {LinkTabs} from "../../../components/LinkTabs";
import {useHistory, useLocation, useRouteMatch} from "react-router";
import {ItemTokens} from "./ItemTokens";


export const IVIConfigPage = () => {

  const history = useHistory();
  const location = useLocation();
  const routeMatch = useRouteMatch();

  return (<>
    <LinkTabs
      tabs={[
        {
          url: '/ivi-config/item-tokens',
          label: 'Item Tokens',
          absolute: true,
          render() {
            return (
              <ItemTokens />
            );
          }
        }
      ]}
      history={history}
      location={location}
      match={routeMatch}
    />
  </>);
}
import _ from "lodash";
import type { Title } from "../title-data";

export enum AwardTypeEnum {
  Single = "Single",
  Multiple = "Multiple",
}

export enum BrawlModeEnum {
  All = "All",
  Brawl = "Brawl",
  NonEliminationBrawl = "NonEliminationBrawl",
}

export enum TierLevelEnum {
  Gold = "Gold",
  Silver = "Silver",
  Bronze = "Bronze",
}

export enum UnlockConditionParameterTypeEnum {
  Integer = "Integer",
  Float = "Float",
  Boolean = "Boolean",
  String = "String",
  GameType = "GameType",
  BlankoDamageType = "BlankoDamageType",
}

export interface BuiltinConditionParameter {
  name: string;
  type: UnlockConditionParameterTypeEnum;
}

export interface BuiltinUnlockConditionType {
  name: string;
  parameters?: BuiltinConditionParameter[];
}

export enum BadgeCheckTypeEnum {
  AboveEqual = "AboveEqual",
  BelowEqual = "BelowEqual",
  Equal = "Equal",
}

export enum BadgeConditionRequirementEnum {
  All = "All",
  Any = "Any",
}

export enum GameTypeEnum {
  DartGun = "DartGun",
  Lava = "Lava",
  Race = "Race",
  Tag = "Tag",
  Undefined = "Undefined",
  Vibes = "Vibes",
}

export enum BlankoDamageTypeEnum {
  Cactus = "Cactus",
  DartGun = "DartGun",
  Default = "Default",
  Environment = "Environment",
  Falling = "Falling",
  Lava = "Lava",
  MeleeSkill = "MeleeSkill",
  MeleeWeapon = "MeleeWeapon",
  Skills = "Skills",
}

export interface BrawlPinUnlockCondition {
  id: string;
  unlockType: string;
  badgeCheckType: BadgeCheckTypeEnum;
  gameType: GameTypeEnum;
  parameters: { [key: string]: any } | null;
}

export interface BrawlPinTier {
  id: string;
  level: TierLevelEnum;
  conditionGroups: string[];
  unlockConditions: BrawlPinUnlockCondition[];
  rewardItemIds: string[];
}

export interface BrawlPin {
  id: string;
  name: string;
  awardType: AwardTypeEnum;
  brawlMode: BrawlModeEnum;
  gameType: GameTypeEnum;
  badgeConditionMode: BadgeConditionRequirementEnum;
  tiers: BrawlPinTier[];
}

/**
 * This version of the pin rule unlock condition is used by the brawl
 * pin rules that are stored within TitleData and use Pascal case.
 */
export interface PinRuleUnlockCondition {
  UnlockType: string;
  BadgeCheckType: BadgeCheckTypeEnum;
  Parameter: { [key: string]: any } | null;
}

/**
 * This version of the pin rule tier is used by the brawl pin rules
 * that are stored within TitleData and use Pascal case.
 */
export interface PinRuleTier {
  Level: TierLevelEnum;
  ConditionGroups?: string[];
  UnlockConditions: PinRuleUnlockCondition[];
  RewardItemID: string[];
}

/**
 * This version of the brawl pin rules are stored within TitleData and use Pascal case.
 */
export interface BrawlPinRule {
  Name: string;
  AwardType: AwardTypeEnum;
  BrawlMode: BrawlModeEnum;
  GameType: GameTypeEnum;
  Tier: PinRuleTier[];
}

export interface BrawlPinRulesPublishInfo {
  title: Title | null;
  pinRules: BrawlPinRule[];
}

/**
 * Maps the unlock conditions of a brawl pin to unlock conditions of a pin rule.
 * @param conditions brawl pin unlock conditions
 * @returns Unlock conditions of a pin rule
 */
const brawlPinConditionsToPinRuleConditions = (conditions: BrawlPinUnlockCondition[]): PinRuleUnlockCondition[] => {
  return conditions.map(condition => {
    return {
      UnlockType: condition.unlockType,
      BadgeCheckType: condition.badgeCheckType,
      Parameter: condition.parameters,
    }
  });
}

/**
 * Maps the tiers of a brawl pin to tiers of a pin rule.
 * @param tiers brawl pin tiers
 * @returns Tiers of a pin rule
 */
const brawlPinTiersToPinRuleTiers = (tiers: BrawlPinTier[]): PinRuleTier[] => {
  return tiers.map(tier => {
    return {
      Level: tier.level,
      // only include condition groups when the collection is not empty
      ConditionGroups: _.isEmpty(tier.conditionGroups) ? undefined : tier.conditionGroups,
      UnlockConditions: brawlPinConditionsToPinRuleConditions(tier.unlockConditions),
      RewardItemID: tier.rewardItemIds,
    }
  });
}

/**
 * Maps a brawl pin to a pin rule.
 * @param pin The brawl pin to map to a pin rule
 * @returns The mapped pin rule
 */
export const brawlPinToPinRule = (pin: BrawlPin): BrawlPinRule => {
  // NOTE: Property order matters here.  This is the order that existing
  // rules are defined within TitleData. To maintain backwards compatibility,
  // we need to keep this order.
  // Another option would be to have the normalizeTitleDataPinRule method
  // reorder the properties into a well known order.
  return {
    Name: pin.name,
    AwardType: pin.awardType,
    Tier: brawlPinTiersToPinRuleTiers(pin.tiers),
    GameType: pin.gameType,
    BrawlMode: pin.brawlMode,
  };
}

import { useCallback, useState } from 'react';
import type { AdminConfirmationDialogProps} from '../shared/hooks/useAdminConfirmationDialog';
import { useAdminConfirmationDialog } from '../shared/hooks/useAdminConfirmationDialog';
import ConfirmationDialog from './ConfirmationDialog';

export const AdminConfirmationDialog = () => {
  const [open, setOpen] = useState(false);
  const [dialogProps, setDialogProps] = useState<AdminConfirmationDialogProps | null>(null);

  useAdminConfirmationDialog(props => {
    const propsOnConfirm = props.onConfirm;
    props.onConfirm = (note?: string) => {
      setOpen(false);
      propsOnConfirm(note);
    }

    setDialogProps(props);
    setOpen(true);
  });

  const onClose = useCallback(() => {
    if (dialogProps && dialogProps.onCancel) {
      dialogProps.onCancel();
    }
    setOpen(false);
  }, [dialogProps]);

  if (dialogProps !== null) {
    return (
      <ConfirmationDialog
        open={open}
        onClose={onClose}
        {...dialogProps}
      />
    );
  }

  return null;
}
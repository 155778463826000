import { Box, FormControlLabel, Grid, Link, Switch, Tooltip, Checkbox } from '@material-ui/core';
import CheckIcon from '@material-ui/icons/Check';
import WarningIcon from '@material-ui/icons/Warning';
import StarIcon from '@material-ui/icons/Star';
import GamepadIcon from '@material-ui/icons/Gamepad';
import type { Column, Query, QueryResult } from 'material-table';
import type { RefObject } from 'react';
import { useState, useEffect, useCallback, useRef } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import config from '../../../config';
import type { Block, PublishedBlock } from '../../../services/blocks';
import type { BrawlViewerSettings, BrawlPlaylistSettings } from '../pages/BrawlPage';
import { UserService } from '../../../services/user';
import { getBrawlPlaylistInfo } from "../../../features/blocks/BlockApiService";
import { BrawlSettingsFormDialog } from './BrawlSettingsFormDialog';
import { BlocksService } from '../../../services/blocks';
import MaterialTable from '../../../components/MaterialTable';
import { paginatedRequestFromQuery } from '../../../services/model/pagination';

export const BrawlBlocksPublished = () => {
  const [brawlViewerSettings, setBrawlViewerSettings] = useState<BrawlViewerSettings>({
    showBrTeams: false,
    showBrQualifiers: false,
    showBrFinales: false,
    showEditor: false,
    showUnpublishedOnly: false,
    showNonElimBrawlOnly: false,
    showBrawlOnly: false
  });
  const [brawlPlaylistSettings, setBrawlPlaylistSettings] = useState<BrawlPlaylistSettings>({
    teams: { min: 0, max: 0 },
    qualifiers: { min: 0, max: 0 },
    finales: { min: 0, max: 0 },
    gapMessage: ""
  });
  const [gapFound, setGapFound] = useState(false);
  const [selectedBlock, setSelectedBlock] = useState<Block | PublishedBlock>();

  const columns: Column<Block | PublishedBlock>[] = [
    {
      title: 'ID',
      field: 'id',
      hidden: config.env === 'prod',
      sorting: false,
    },
    {
      title: 'Name',
      field: 'name',
      defaultSort: 'asc',
      // eslint-disable-next-line react/display-name
      render: block => (
        <Grid container spacing={1} alignItems="center">
          {isPublishedBlock(block) && block.verified && (
            <Grid item xs="auto">
              <Tooltip title="Verified">
                <CheckIcon color="secondary" />
              </Tooltip>
            </Grid>
          )}
          {isPublishedBlock(block) && block.featured && (
            <Grid item xs="auto">
              <Tooltip title="Featured">
                <StarIcon color="secondary" />
              </Tooltip>
            </Grid>
          )}
          <Grid item xs>
            {isPublishedBlock(block) && (
              <Link component={RouterLink} to={`/published-blocks/${block.id}`}>
                {block.name}
              </Link>
            )}
            {!isPublishedBlock(block) && (
              <Link component={RouterLink} to={`/blocks/${block.id}`}>
                {block.name}
              </Link>
            )}
          </Grid>
        </Grid>
      )
    },
    {
      title: 'Owner',
      field: 'ownerName',
      sorting: false,
      hidden: true,
      // eslint-disable-next-line react/display-name
      render: block => UserService.canRead('player') ? (
        <Link component={RouterLink} to={`/players/${block.ownerId}`}>
          {block.ownerName || block.ownerId}
        </Link>
      ) : <>{block.ownerName || block.ownerId}</>
    },
    {
      title: 'Creator',
      field: 'creatorName',
      sorting: false,
      hidden: true,
      // eslint-disable-next-line react/display-name
      render: block => UserService.canRead('player') ? (
        <Link component={RouterLink} to={`/players/${block.creatorId}`}>
          {block.creatorName || block.creatorId}
        </Link>
      ) : <>{block.creatorName || block.creatorId}</>
    },
    { title: 'Brawl Playlist', field: 'brawlPlaylist', sorting: false },
    { title: 'Brawl Max Players', field: 'brawlMaxPlayers', sorting: false },
    { title: 'Brawl Min Players', field: 'brawlMinPlayers', sorting: false },
    { title: 'Brawl Eliminations Percentage', field: 'brawlEliminationsPercentage', sorting: false },
    { title: 'Brawl Weight', field: 'brawlWeight', sorting: false },
    { title: 'Game mode', field: 'primaryGameMode', sorting: false, hidden: true },
    { title: 'Mode type', field: 'playType', sorting: false, hidden: true },
    { title: 'Play count', field: 'playCount', defaultSort: 'desc', hidden: true },
    { title: 'Unique join count', field: 'playerUniqueJoinedCount', sorting: false, hidden: true },
    { title: 'Total join count', field: 'playerTotalJoinedCount', sorting: false, hidden: true },
    { title: 'Unique completed count', field: 'playerUniqueCompletedCount', sorting: false, hidden: true },
    { title: 'Total completed count', field: 'playerTotalCompletedCount', sorting: false, hidden: true },
    {
      title: 'Churn',
      sorting: false,
      hidden: true,
      // eslint-disable-next-line react/display-name
      render: block => (<>
        {isPublishedBlock(block) && block.playerTotalJoinedCount !== 0 ? `${((block.playerTotalJoinedCount - block.playerTotalCompletedCount) * 100.0 / block.playerTotalJoinedCount).toFixed(2)}%` : '-'}
      </>)
    },
    {
      title: 'Report count',
      field: 'totalReportCount',
      hidden: true,
      sorting: false,
      // eslint-disable-next-line react/display-name
      render: publishedBlock => (isPublishedBlock(publishedBlock) &&
        <Link component={RouterLink} to={`/blocks/${publishedBlock.blockId}`}>
          {publishedBlock.totalReportCount}
        </Link>
      )
    },
    {
      title: 'Last played',
      sorting: false,
      // eslint-disable-next-line react/display-name
      render: () => <>-</>,
      hidden: true
    },
    {
      title: 'Last Updated',
      field: 'updatedTimeStamp',
      hidden: true,
      sorting: false,
      render: block => <>{block.updatedTimeStamp.toLocaleString()}</>
    },
  ];

  const tableRef = useRef<RefObject<MaterialTable<PublishedBlock | Block>>>();

  const handleBrawlSettingsClick = (block: PublishedBlock | Block) => {
    setSelectedBlock(block);
    setBrawlViewerSettings({ ...brawlViewerSettings, showEditor: true });
  };

  const getBlocks = useCallback(async (query: Query<PublishedBlock | Block>): Promise<QueryResult<PublishedBlock | Block>> => {
    return new Promise(resolve => {
      const request = paginatedRequestFromQuery(query);
      request.search = query.search.trim();
      request.name = request.search;
      request.brawlPlaylists = [];

      if (brawlViewerSettings.showBrTeams) {
        request.brawlPlaylists.push("TEAMS");
      }
      if (brawlViewerSettings.showBrQualifiers) {
        request.brawlPlaylists.push("QUALIFIERS");
      }
      if (brawlViewerSettings.showBrFinales) {
        request.brawlPlaylists.push("FINALES");
      }

      request.brawlPlaylists = (request.brawlPlaylists.length == 0 && brawlViewerSettings.showBrawlOnly) ? ["TEAMS", "QUALIFIERS", "FINALES"] : request.brawlPlaylists;

      request.nonEliminationBrawlOnly = brawlViewerSettings.showNonElimBrawlOnly;
      request.brawlOnly = brawlViewerSettings.showBrawlOnly;

      const getBlocksOrPublished = brawlViewerSettings.showUnpublishedOnly ? BlocksService.getBlocks(request) : BlocksService.getPublishedBlocksPage(request);
      getBlocksOrPublished.then(result => {
        resolve({
          data: result.items,
          page: request.page - 1,
          totalCount: result.totalCount
        });
      });
        
    });
  }, [brawlViewerSettings]);

  const getData = useCallback(async () => {
    const playlistInfo = await getBrawlPlaylistInfo();
    setBrawlPlaylistSettings(playlistInfo);
    setGapFound(playlistInfo.gapMessage !== "");
  }, []);

  useEffect(() => {
    getData();
    if (tableRef && tableRef.current && !brawlViewerSettings.showEditor) {
      (tableRef.current as any).onQueryChange();
    }
  }, [getData, brawlViewerSettings])

  const isPublishedBlock = (block: Block | PublishedBlock): block is PublishedBlock => {
    return (block as PublishedBlock).blockId !== undefined;
  };

  return (<>
    {brawlViewerSettings.showEditor && selectedBlock && (
      <BrawlSettingsFormDialog
        blockId={isPublishedBlock(selectedBlock) ? selectedBlock.blockId : selectedBlock.id}
        brawlMinPlayers={selectedBlock.brawlMinPlayers}
        brawlMaxPlayers={selectedBlock.brawlMaxPlayers}
        brawlWeight={selectedBlock.brawlWeight}
        brawlEliminationsPercentage={selectedBlock.brawlEliminationsPercentage}
        brawlPlaylist={selectedBlock.brawlPlaylist}
        blockMaxPlayers={selectedBlock.maxPlayers}
        blockMinPlayers={selectedBlock.minPlayers}
        brawlIsNonEliminationCompatible={selectedBlock.brawlIsNonEliminationCompatible}
        skillLevelThreshold = {selectedBlock.skillLevelThreshold}
        onClose={() => {
          setBrawlViewerSettings({ ...brawlViewerSettings, showEditor: false });
        }}
      />
    )}
    <Box mb={1}>
      <FormControlLabel
        control={
          <Checkbox
            checked={brawlViewerSettings.showUnpublishedOnly}
            onChange={event => setBrawlViewerSettings({ ...brawlViewerSettings, showUnpublishedOnly: event.target.checked })}
            color="primary"
          />
        }
        label="Show Unpublished Brawls Only"
      />
      <FormControlLabel
        control={
          <Checkbox
            checked={brawlViewerSettings.showNonElimBrawlOnly}
            onChange={event => setBrawlViewerSettings({ ...brawlViewerSettings, showNonElimBrawlOnly: event.target.checked })}
            color="primary"
          />
        }
        label="Non-Elimination Blocks Only"
      />
      <FormControlLabel
        control={
          <Checkbox
            checked={brawlViewerSettings.showBrawlOnly}
            onChange={event => setBrawlViewerSettings({ ...brawlViewerSettings, showBrawlOnly: event.target.checked })}
            color="primary"
          />
        }
        label="Brawl Blocks Only"
      />
    </Box>
    <Box mb={1}>
      <FormControlLabel
        control={
          <Switch
            checked={brawlViewerSettings.showBrTeams}
            onChange={event => setBrawlViewerSettings({ ...brawlViewerSettings, showBrTeams: event.target.checked })}
            color="primary"
          />
        }
        label="Teams"
      />
      <FormControlLabel
        control={
          <Switch
            checked={brawlViewerSettings.showBrQualifiers}
            onChange={event => setBrawlViewerSettings({ ...brawlViewerSettings, showBrQualifiers: event.target.checked })}
            color="primary"
          />
        }
        label="Qualifiers"
      />
      <FormControlLabel
        control={
          <Switch
            checked={brawlViewerSettings.showBrFinales}
            onChange={event => setBrawlViewerSettings({ ...brawlViewerSettings, showBrFinales: event.target.checked })}
            color="primary"
          />
        }
        label="Finales"
      />
    </Box>
    <Box mb={2}>
      Lowest Min Players: [{brawlPlaylistSettings.teams.min} Teams] [{brawlPlaylistSettings.qualifiers.min} Qualifiers] [{brawlPlaylistSettings.finales.min} Finales] <br />
      Highest Max Players: [{brawlPlaylistSettings.teams.max} Teams] [{brawlPlaylistSettings.qualifiers.max} Qualifiers] [{brawlPlaylistSettings.finales.max} Finales] <br />
      <Box style={{ display: `${brawlPlaylistSettings.teams.min - brawlPlaylistSettings.qualifiers.max > 1 ? 'inline-flex' : 'none'}`, alignItems: 'center' }}>
        <WarningIcon color="error" /> Gap between teams and qualifiers
      </Box>
      <Box style={{ display: `${brawlPlaylistSettings.qualifiers.min - brawlPlaylistSettings.finales.max > 1 ? 'inline-flex' : 'none'}`, alignItems: 'center' }}>
        <WarningIcon color="error" /> Gap between qualifiers and finales
      </Box>
      <Box style={{ display: `${brawlPlaylistSettings.teams.max == 0 || brawlPlaylistSettings.qualifiers.max == 0 || brawlPlaylistSettings.finales.max == 0 ? 'inline-flex' : 'none'}`, alignItems: 'center' }}>
        <WarningIcon color="error" /> Max players is set to zero!
      </Box>
      {gapFound &&
        <Grid container alignItems="center" spacing={1}>
          <Grid item>
            <WarningIcon color="error" />
          </Grid>
          <Grid item>
            Gap found if players were: {brawlPlaylistSettings.gapMessage.trim()}
          </Grid>
        </Grid>
      }
    </Box>

    <MaterialTable<Block | PublishedBlock>
      storageId="brawl-blocks-published"
      title={brawlViewerSettings.showUnpublishedOnly ? "Unpublished Blanko Brawl Blocks" : "Published Blanko Brawl Blocks"}
      columns={columns}
      tableRef={tableRef}
      data={getBlocks}
      options={{
        columnsButton: true,
        debounceInterval: 500,
        pageSize: 20,
        pageSizeOptions: [20, 50, 100],
        thirdSortClick: false
      }}
      menuActions={[
        { type: 'button', icon: GamepadIcon, label: 'Edit Brawl Settings', onClick: handleBrawlSettingsClick }
      ]}
      useRouter
    />
  </>);
};

import React, {useCallback, useEffect, useState} from "react";
import {
  Button,
  Card, CardActions, CardContent, CardHeader,
  FormControl,
  Grid,
  InputLabel, MenuItem,
  Select,
  TextField,
} from "@material-ui/core";

import {GameQueueVip} from "./GameQueueVip";
import {GameQueue, GameQueueService} from "../../services/launcher/game-queue";
import {usePushNotification} from "../../contexts/AppNotificationContext";

export const GameQueueView = () => {
  const queueName = "BlankosBlockParty";

  const [gameQueue, setGameQueue] = useState<GameQueue>(new GameQueue());
  const pushNotification = usePushNotification();

  const getData = useCallback(async () => {
    const gameQueueResult = await GameQueueService.getGameQueue(queueName);
    setGameQueue(gameQueueResult);
  }, [setGameQueue]);

  const getEnabledLabel = (): string => gameQueue.enabled ? "ACTIVE" : "INACTIVE";

  const saveGameQueue = async (): Promise<void> => {
    try {
      await GameQueueService.saveGameQueue(queueName, gameQueue);
      pushNotification({type: "success", message: "Game queue updated"});
    } catch {
      pushNotification({type: "error", message: "Failed to update game queue"});
    }
  };

  const toggleQueue = async (enabled: boolean): Promise<void> => {
    if (gameQueue.enabled === enabled) return;

    gameQueue.enabled = enabled;

    enabled
      ? await enableGameQueue()
      : await disableGameQueue();

    setGameQueue({...gameQueue, enabled});
  };

  const enableGameQueue = async() : Promise<void> => {
    try {
      await GameQueueService.enableGameQueue(queueName);
      pushNotification({type: "success", message: "Game queue activated"});
    }
    catch {
      pushNotification({type: "error", message: "Failed to activate game queue"});
    }
  };

  const disableGameQueue = async() : Promise<void> => {
    try {
      await GameQueueService.disableGameQueue(queueName);
      pushNotification({type: "success", message: "Game queue deactivated"});
    }
    catch {
      pushNotification({type: "error", message: "Failed to deactivate game queue"});
    }
  };

  const enableButton = <Button color={"secondary"} onClick={() => toggleQueue(true)}>Activate</Button>;
  const disableButton = <Button color={"secondary"} onClick={() => toggleQueue(false)}>Deactivate</Button>;

  useEffect(() => { getData().then(); }, [getData]);

  return(
    <Grid container spacing={2}>
      <Grid item xs={6}>
        <Card>
          <CardHeader title={`Blankos Block Party Queue (${getEnabledLabel()})`}>
          </CardHeader>
          <CardContent>
            <Grid container spacing={3}>

              <Grid item xs={12}>
                <FormControl>
                  <TextField label={"Queue Name"} value={gameQueue.queueName} InputProps={{readOnly:true}} />
                </FormControl>
              </Grid>

              <Grid item xs={12}>
                <FormControl>
                  <TextField
                    label={"Max players"}
                    value={gameQueue.maxSlots}
                    onChange={(event) => setGameQueue({...gameQueue, maxSlots: parseInt(event.target.value)})}
                  />
                </FormControl>
              </Grid>

              <Grid item xs={12}>
                <FormControl fullWidth>
                  <InputLabel>Inactivity timeout</InputLabel>
                  <Select
                    value={gameQueue.queueSecondsToLive}
                    onChange={(event) => setGameQueue({...gameQueue, queueSecondsToLive: event.target.value as number})}
                  >
                    <MenuItem value={60}>1 minute</MenuItem>
                    <MenuItem value={120}>2 minutes</MenuItem>
                    <MenuItem value={300}>5 minutes</MenuItem>
                    <MenuItem value={600}>10 minutes</MenuItem>
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={12}>
                <FormControl fullWidth>
                  <InputLabel>Grace period</InputLabel>
                  <Select
                    value={gameQueue.slotSecondsToLive}
                    onChange={(event) => setGameQueue({...gameQueue, slotSecondsToLive: event.target.value as number})}
                  >
                    <MenuItem value={60}>1 minute</MenuItem>
                    <MenuItem value={120}>2 minutes</MenuItem>
                    <MenuItem value={300}>5 minutes</MenuItem>
                    <MenuItem value={600}>10 minutes</MenuItem>
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={12}>
                <FormControl>
                  <TextField
                    label={"Active player count URL"}
                    value={gameQueue.activePlayerCountUrl}
                    onChange={(event) => setGameQueue({...gameQueue, activePlayerCountUrl: event.target.value})}
                    fullWidth
                  />
                </FormControl>
              </Grid>

              <Grid item xs={12}>
                <FormControl fullWidth>
                  <InputLabel>Count cache timeout</InputLabel>
                  <Select
                    value={gameQueue.activePlayerCountCacheSecondsToLive}
                    onChange={(event) => setGameQueue({...gameQueue, activePlayerCountCacheSecondsToLive: event.target.value as number})}
                  >
                    <MenuItem value={5}>5 seconds</MenuItem>
                    <MenuItem value={15}>15 seconds</MenuItem>
                    <MenuItem value={30}>30 seconds</MenuItem>
                    <MenuItem value={60}>1 minute</MenuItem>
                    <MenuItem value={120}>2 minutes</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
            </Grid>

          </CardContent>

          <CardActions>
            <Button variant={"contained"} color={"primary"} onClick={() => saveGameQueue()}>Update</Button>
            {gameQueue.enabled ? disableButton : enableButton}
          </CardActions>
        </Card>
      </Grid>
      <Grid item xs={6}>
        <GameQueueVip queueName={queueName} />
      </Grid>
    </Grid>
  );
}
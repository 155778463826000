import { CircularProgress, Typography } from '@material-ui/core';
import { useEffect } from 'react';
import * as React from 'react';
import { useDispatch } from 'react-redux';
import config, { configs } from '../../../../config';
import type { EnvOption } from '../../../../redux/catalog-migration/actions';
import { cmGetCatalogsAsync, cmSetCatalogName, cmSetEnvId } from '../../../../redux/catalog-migration/actions';
import { selectAllCatalogs, selectCatalogName } from '../../../../redux/catalog-migration/selectors';
import { useTypedSelector } from '../../../../redux/reducers';
import { CatalogSelector } from './CatalogSelector';
import { EnvironmentSelector } from './EnvironmentSelector';

interface EnvironmentSelectorContainerProps {
  environmentSource: EnvOption;
  environmentSelectorEnabled?: boolean;
  excludeCatalog?: string;
}

export const EnvironmentSelectorContainer = ({
  environmentSource,
  environmentSelectorEnabled,
  excludeCatalog
}: EnvironmentSelectorContainerProps) => {
  const dispatch = useDispatch();

  const environmentId = useTypedSelector(state => state.catalogMigration[environmentSource].id);
  const catalogs = useTypedSelector(state => selectAllCatalogs(state, environmentSource));
  const catalogName = useTypedSelector(state => selectCatalogName(state, environmentSource));

  useEffect(() => {
    dispatch(cmGetCatalogsAsync.request({ env: environmentSource }));
  }, [dispatch, environmentSource, environmentId]);

  useEffect(() => {
    if (catalogs.catalogs && catalogs.catalogs.length > 0) {
      const catalogNames = catalogs.catalogs.map(c => c.name).filter(name => name !== excludeCatalog);
      if (!catalogName || catalogNames.indexOf(catalogName) < 0) {
        dispatch(cmSetCatalogName({ env: environmentSource, catalogName: catalogNames[0] }));
      }
    }
  }, [dispatch, environmentSource, catalogs, catalogName, excludeCatalog]);

  let catalogSelect: React.ReactNode | null = null;

  if (catalogs.isLoading) {
    catalogSelect = <CircularProgress />;
  } else if (catalogs.error) {
    catalogSelect = <Typography color="error">{catalogs.error}</Typography>;
  } else {
    const catalogNames = catalogs.catalogs.map(c => c.name).filter(name => name !== excludeCatalog);

    if (catalogNames.length < 1) {
      catalogSelect = <>No catalogs available.</>;
    } else if (!catalogName || catalogNames.indexOf(catalogName) < 0) {
      catalogSelect = <CircularProgress />;
    } else {
      const handleCatalogChange = (event: React.ChangeEvent<{
        name?: string | undefined;
        value: unknown;
      }>) => dispatch(cmSetCatalogName({ env: environmentSource, catalogName: event.target.value as string }));

      catalogSelect = <CatalogSelector
        catalogName={catalogName}
        catalogNames={catalogNames}
        handleChange={handleCatalogChange} />
    }
  }

  // Remove any environments that use a different keycloak client id than the current environment
  const compatibleConfigs = Object.values(configs).filter(c => c.apis.mythical.clientId === config.apis.mythical.clientId);

  const handleEnvironmentChange = (event: React.ChangeEvent<{
    name?: string | undefined;
    value: unknown;
  }>) => dispatch(cmSetEnvId({ env: environmentSource, id: event.target.value as string }));

  return (<EnvironmentSelector
    environmentId={environmentId}
    compatibleConfigs={compatibleConfigs}
    environmentSelectorEnabled={environmentSelectorEnabled}
    catalogSelect={catalogSelect}
    handleChange={handleEnvironmentChange} />)
}

import type { MigrationDiffCommonProps } from '../../migrations';
import { DiffsPanels } from '../../components/diff/DiffsPanels';

export const DropTablesDiffs = (props: MigrationDiffCommonProps) => {

  const diffs = props.migrationData.dropTables;

  if (diffs) {
    return (
      <DiffsPanels
        diffs={diffs}
        columns={[
          {
            title: 'Item ID', field: 'diffId', defaultSort: 'asc', render(item) {
              return (<>
                {item.diffId}
              </>);
            }
          }
        ]}
        selectionMap={props.selections.droptable || {}}
        onSelectionChange={map => props.onSelectionsChange({ ...props.selections, droptable: map })}
        {...props}
      />
    )
  }

  return null;
}

import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@material-ui/core"
import { createRegionFeature } from "../geo-blocking-api"
import { GeoBlockedFeatureMap } from "../geo-blocking-types"

import { Formik, useFormik, validateYupSchema } from 'formik';
import { useCallback, useMemo } from "react";
import { GeoBlockingForm } from "./GeoBlockingForm";

interface EditGeoBlockingDialogProps {
  onClose: () => void
  show: boolean,
  item: GeoBlockedFeatureMap | null
}

export const EditGeoBlockingDialog = ({onClose, show, item}: EditGeoBlockingDialogProps) => {

  const initialFormValues: GeoBlockedFeatureMap = {
    featureId: item?.featureId,
    regions: item?.regions || []
  } 

  const onSubmit = useCallback(async(values: Partial<GeoBlockedFeatureMap>) => {
    await createRegionFeature(values);
    onClose();
  },[onClose]);

  return (
    <>
      <Dialog
        open={show}
    >
        <DialogTitle id="edit-dialog-title">Edit Geo-Blocked Feature</DialogTitle>
        <GeoBlockingForm data={initialFormValues} onSubmit={onSubmit} onClose={onClose} />
      </Dialog>
    </>
  )
}
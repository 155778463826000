import type { MigrationDiffCommonProps } from '../../migrations';
import { DiffsPanels } from '../../components/diff/DiffsPanels';

export const StoresDiffs = (props: MigrationDiffCommonProps) => {

  const diffs = props.migrationData.stores;

  if (diffs) {
    return (
      <DiffsPanels
        diffs={diffs}
        columns={[
          { title: 'Store ID', field: 'diffId', defaultSort: 'asc', render(item) { return <>{item.diffId}</>; } }
        ]}
        selectionMap={props.selections.store || {}}
        onSelectionChange={map => props.onSelectionsChange({ ...props.selections, store: map })}
        {...props}
      />
    )
  }

  return null;
}

import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField, Typography } from '@material-ui/core';
import type { ChangeEvent } from 'react';
import { useCallback, useState } from 'react';

export interface PlayerAccessDialogProps {
  title: string;
  subtitle?: string;
  onClose: () => void;
  onSubmit: (emailList: string[]) => void;
}

export const PlayerAccessDialog = ({ title, subtitle, onClose, onSubmit }: PlayerAccessDialogProps) => {

  const [open, setOpen] = useState(true);
  const [emails, setEmails] = useState('');
  const [error, setError] = useState('');

  const onEmailsChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setEmails(e.target.value);
    setError(!e.target.value.trim() ? 'At least one email required' : '');
  }, []);

  const onFormSubmit = useCallback(() => {
    const emailList = emails.trim().split('\n').map(v => v.trim()).filter(v => !!v);
    onSubmit(emailList);
    onClose();
  }, [emails, onClose, onSubmit]);

  return (
    <Dialog
      open={open}
      onClose={() => setOpen(false)}
      onExited={onClose}
      fullWidth
      maxWidth="sm"
    >
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        {subtitle &&
          <Typography>
            {subtitle}
          </Typography>
        }

        <TextField
          label="Player emails (one per line)"
          fullWidth
          multiline
          rows={10}
          rowsMax={10}
          value={emails}
          error={Boolean(error)}
          helperText={error}
          onChange={onEmailsChange}
        />
      </DialogContent>
      <DialogActions>
        <Button
          color="primary"
          variant="contained"
          onClick={onFormSubmit}
          disabled={!emails.trim() || Boolean(error)}
        >
          Grant Access
        </Button>
      </DialogActions>
    </Dialog>
  )
};

import { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { cmSetMigrationActions } from '../../../../redux/catalog-migration/actions';
import type { MigrationActions } from '../../../../redux/catalog-migration/reducer';
import { useTypedSelector } from '../../../../redux/reducers';
import { StoreDiff } from '../../types';
import { Stores } from './Stores';

export const StoresContainer = () => {
  const dispatch = useDispatch();

  const sourceStores = useTypedSelector(state => state.catalogMigration.source.stores);
  const targetStores = useTypedSelector(state => state.catalogMigration.target.stores);
  const actions = useTypedSelector(state => state.catalogMigration.actions.stores);

  const [sourceItems, setSourceItems] = useState<StoreDiff[] | null>(null);
  const [targetItems, setTargetItems] = useState<StoreDiff[] | null>(null);

  const onSelectionChange = useCallback(
    (actions: MigrationActions) => dispatch(cmSetMigrationActions({ id: 'stores', actions })),
    [dispatch]
  );

  useEffect(() => {
    if (sourceStores && targetStores) {
      setSourceItems(sourceStores.map(store => new StoreDiff(store)));
      setTargetItems(targetStores.map(store => new StoreDiff(store)));
    }
  }, [sourceStores, targetStores]);

  return (<Stores
    sourceItems={sourceItems}
    targetItems={targetItems}
    actions={actions}
    onSelectionChange={onSelectionChange} />);
}

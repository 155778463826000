import { TitleDataDiff } from '../../../../pages/CatalogMigration/types';
import { CatalogMigrationService } from '../../../../services/catalog-migration';
import type { MigrationActions, MigrationModule } from '../../migrations';
import { buildDiffData, buildMigrationActions } from '../../migrations';
import { getTitleDataDiffsComponent } from './TitleDataDiffs';
import { MigrationType } from "../migration-type";

const buildTitleDataDiffs = (data: { [key: string]: string }, isClient: boolean): TitleDataDiff[] => {
  const diffs: TitleDataDiff[] = [];
  for (const key in data) {
    diffs.push(new TitleDataDiff(key, data[key], isClient));
  }
  return diffs;
}

function mergeTitleData(source: { [key: string]: string }, target: { [key: string]: string }, actions: MigrationActions): { [key: string]: string | null } {
  if (!actions || actions.add.length + actions.remove.length + actions.update.length < 1) {
    return target;
  }

  const mergedMap: { [key: string]: string | null } = {};
  actions.add.concat(actions.update).forEach(id => mergedMap[id] = source[id]);
  actions.remove.forEach(id => mergedMap[id] = null);
  return mergedMap;
}

export const getTitleDataMigration = (type: string): MigrationModule<TitleDataDiff> => ({
  id: type === 'client' ? MigrationType.titleDataClient : MigrationType.titleDataServer,
  displayName: `Title data - ${type === 'client' ? 'Client' : 'Server'}`,
  diffComponent: getTitleDataDiffsComponent(type),
  crossEnvOnly: true,

  loadData: async (sourceData, targetData, migrationData) => {
    if (!sourceData.titleData) {
      sourceData.titleData = await CatalogMigrationService.getTitleData(sourceData.env);
    }
    if (!targetData.titleData) {
      targetData.titleData = await CatalogMigrationService.getTitleData(targetData.env);
    }

    if (type === 'client') {
      migrationData.titleDataClient = buildDiffData(
        buildTitleDataDiffs(sourceData.titleData.titleData, true),
        buildTitleDataDiffs(targetData.titleData.titleData, true)
      );
      return migrationData.titleDataClient;
    } else {
      migrationData.titleDataServer = buildDiffData(
        buildTitleDataDiffs(sourceData.titleData.internalData, false),
        buildTitleDataDiffs(targetData.titleData.internalData, false)
      );
      return migrationData.titleDataServer;
    }
  },

  runMigration: async (props, setStatus) => {
    const sourceData = props.sourceData.titleData;
    const targetData = props.targetData.titleData;
    if (!sourceData || !targetData) {
      throw new Error('Title data not loaded.');
    }

    if (type === 'client') {
      const actions = buildMigrationActions(props.migrationData.titleDataClient, props.selections.titleDataClient);
      const titleDataClient = mergeTitleData(sourceData.titleData, targetData.titleData, actions);
      if (titleDataClient !== targetData.titleData) {
        setStatus('Migrating client title data');
        await CatalogMigrationService.updateClientTitleData(props.targetData.env, titleDataClient);
      }
    } else {
      const actions = buildMigrationActions(props.migrationData.titleDataServer, props.selections.titleDataServer);
      const titleDataServer = mergeTitleData(sourceData.internalData, targetData.internalData, actions);
      if (titleDataServer !== targetData.internalData) {
        setStatus('Migrating server title data');
        await CatalogMigrationService.updateServerTitleData(props.targetData.env, titleDataServer);
      }
    }
  }
});

export enum LiveEventTypeEnum {
  Announcement = "ANNOUNCEMENT",
  ChallengeList = "CHALLENGE_LIST",
  ChallengeProgression = "CHALLENGE_PROGRESSION",
  PartyPass = "PARTY_PASS",
}

export interface LiveEventBase {
  id?: string;
  name: string;
  liveEventType: LiveEventTypeEnum;
  debugStartTime: string;
  startTime: string;
  endTime: string;
  downtimeBufferMinutes: number;
  active: boolean;
  prior: boolean;
  autoActivating: boolean;
  autoActivationDaysBuffer: number;
  repeatable: boolean;
  repeatCount: number;
  repeatStartGapDaysBuffer: number;
  repeatCloneDaysBuffer: number;
  repeatCloneStartTime: string | null;
  repeatCloneEndTime: string | null;
  repeatCloneParentId: string | null;
  repeatCloneChildId: string | null;
  storeName?: string | null;
  storeId?: string | null;
  playModeName: string;
  modeSelectTileImage: string;
  modeSelectBackgroundImage: string;
  modeSelectTitle: string;
  modeSelectDescription: string;
  modeSelectEventTagTitle: string;
  modeSelectPlayActionTitle: string;
  customData: string;
  imageUrls: string[];
}

export interface LiveEvent extends LiveEventBase {
  seasonalData: SeasonalData[] | undefined;
  junctionEventInfos: JunctionEventInfo[] | undefined;
}

export interface JunctionEventInfo {
  id?: string;
  liveEventsId: string;
  enabled: boolean;
  name: string;
  createdAt?: string;
  updatedAt?: string;
  potentialRewards: PotentialReward[];
  rewards: Reward[];
  junctionObjects: JunctionObject[];
  junctionNpcs: JunctionNpc[];
  junctionBeams: JunctionBeam[];
}

export interface JunctionObject {
  type: JunctionObjectItemIdType;
  enabled: boolean;
  catalogItemId?: string;
  objectId?: string;
  position: string;
  rotation: string;
}

export interface PotentialReward {
  label: string;
  imagePath: string;
}

export interface JunctionNpc extends JunctionObject {
  communicationModalTitle: string;
  communicationModalDescription: string;
}

export interface JunctionBeam extends JunctionObject {
  color: string;
  radius: number;
}

export enum JunctionObjectItemIdType {
  CATALOG_ITEM_ID = "CATALOG_ITEM_ID",
  OBJECT_ID = "OBJECT_ID",
}

export enum PlayModeNameType {
  INVALID = "Invalid",
  BRAWL = "Brawl",
  NON_ELIMINATION_BRAWL = "NonEliminationBrawl",
  JOIN_CODE = "JoinCode",
  PARTY_BUS = "PartyBus",
  JUNCTION = "Junction",
  BUILD = "Build",
}

export interface DuplicateLiveEventRequest {
  newLiveEventName: string;
  repeating: boolean;
  isQA: boolean;
}
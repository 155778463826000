import { useTypedSelector } from '../../../../redux/reducers';
import { Options } from './Options';

export const OptionsContainer = () => {
  const sourceEnvironment = useTypedSelector(state => state.catalogMigration.source.id);
  const targetEnvironment = useTypedSelector(state => state.catalogMigration.target.id);
  const options = useTypedSelector(state => state.catalogMigration.options);
  const sourceCatalogName = useTypedSelector(state => state.catalogMigration.source.catalogName);

  return (<Options
    sourceEnvironment={sourceEnvironment}
    targetEnvironment={targetEnvironment}
    options={options}
    sourceCatalogName={sourceCatalogName} />);
}

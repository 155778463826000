import type { Theme} from '@material-ui/core';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, makeStyles, createStyles } from '@material-ui/core';

import type { BlankoGemRushFuse} from '../blankoGemRush';
import { CapstoneEnum } from '../blankoGemRush';
import { FuseForm } from '.';
import { useFormik } from 'formik';
import { fuseFormSchema } from '..';
import { createBlankoRushFuse, uploadRushFuseImage } from '../gemRushApiService';
import { useState } from 'react';
import { FuseFormGemRushFuse } from './FuseForm';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    form: {
      display: 'flex',
      flexDirection: 'column',
      margin: 'auto',
      width: 'fit-content',
    },
  }),
);

interface AddFuseDialogProps {
  baseDnaId: string;
  baseDnaUrl: string | undefined;
  rushId: string;
  show: boolean;
  upgradeDnaId: string;
  upgradeDnaUrl: string | undefined;
  onClose: () => void;
}

export const AddFuseDialog = ({ baseDnaId, baseDnaUrl, rushId, show, upgradeDnaId, upgradeDnaUrl, onClose}: AddFuseDialogProps) => {
  const [imageFile, setImageFile] = useState<File | null>();
  const classes = useStyles();
  const initialFuse: FuseFormGemRushFuse = {
    id: '',
    baseDnaId,
    baseDnaUrl,
    upgradeDnaId,
    upgradeDnaUrl,
    maxQuantity: 0,
    remainingQuantity: 0,
    fuseTitle: '',
    fuseDescription:'',
    popupImageUrl: '',
    capstone: [
      {
        type: CapstoneEnum.BurnGumballs,
        param: {
          amount: 0,
        }
      }
    ]
  }

  const onFileChanged = (file: File | null) => {
    setImageFile(file);
  }

  const formikFuses = useFormik({
    initialValues: initialFuse,
    validationSchema: fuseFormSchema,
    onSubmit: async (values: Partial<BlankoGemRushFuse>) => {
      delete values.id
      if(imageFile){
        const imagename = await uploadRushFuseImage(imageFile);
        values.popupImageUrl = imagename;
      }

      await createBlankoRushFuse(rushId, values)
      onClose();
    }
  })

  return (
    <>
      <Dialog
        open={show}
        onClose={onClose}
        aria-labelledby="add-dialog-title"
        fullWidth
      >
        <form onSubmit={formikFuses.handleSubmit} className={classes.form}>
          <DialogTitle id="add-dialog-title">Add Fuse</DialogTitle>
          <DialogContent className={classes.form}>
            {formikFuses.values &&
              <FuseForm
                formikThings={{ ...formikFuses }}
                onFileChanged={onFileChanged}
              />
            }
          </DialogContent>
          <DialogActions>
            <Button
              type="submit"
              variant="contained"
              disabled={!(formikFuses.isValid && formikFuses.dirty)}
              color="primary"
            >
              Save
            </Button>
            <Button onClick={onClose} color="primary">
              Close
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </>
  );
}

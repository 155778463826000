import { Box, Button, CircularProgress, Grid, Link } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import PublishIcon from '@material-ui/icons/Publish';
import type { Column } from 'material-table';
import { useEffect, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import type { MenuAction } from '../../../components/MaterialTable';
import MaterialTable from '../../../components/MaterialTable';
import type { Store } from '../../../services/stores';
import { StoresService } from "../../../services/stores";
import { StoreAddOrCloneDialog } from '../../../pages/Store/StoreAddOrCloneDialog';
import { UserService } from '../../../services/user';
import { Lock, LockOpen } from "@material-ui/icons";
import type { Catalog } from "../../../services/catalogs";
import { pushAppNotification } from "../../../shared/hooks/useAppNotification";
import { openAdminConfirmationDialog } from "../../../shared/hooks/useAdminConfirmationDialog";

type Props = {
  catalog: Catalog;
}

export const CatalogStores = ({catalog}: Props) => {
  const canAccessStore = (store: Store) => {
    if (store.locked) {
      return UserService.canUpdate('storeLock');
    } else {
      return true;
    }
  };

  const renderStoreLink = (store: Store) => {
    if (canAccessStore(store)) {
      const url = `/catalogs/${catalog.name}/stores/${store.storeId}`;
      return (
        <Link component={RouterLink} to={url}>{store.storeId}</Link>
      );
    } else {
      return <><Lock/> {store.storeId}</>
    }
  };

  const columns: Column<Store>[] = [
    {title: 'ID', field: 'storeId', defaultSort: 'asc', render: renderStoreLink}
  ];
  const [addStore, setAddStore] = useState(false);
  const [stores, setStores] = useState<Store[]>([]);
  const [loadingStores, setLoadingStores] = useState(true);
  const [cloneStore, setCloneStore] = useState<Store | null>(null);

  useEffect(() => {
    setLoadingStores(true);
    StoresService.getStores(catalog.name)
      .then(stos => setStores(stos))
      .catch(err => {
        pushAppNotification({type: 'error', message: `Failed to get stores: ${err.message}`});
      })
      .finally(() => setLoadingStores(false));
  }, [catalog.name]);

  const deleteStore = (store: Store) => {
    if (catalog) {
      openAdminConfirmationDialog({
        title: `Delete ${store.storeId}?`, action: 'Delete store', onConfirm: () => {
          StoresService.deleteStore(catalog.name, store)
            .then(() => {
              pushAppNotification({ type: 'success', message: 'Store deleted' });
              setStores(stores.filter(sto => sto !== store));
            })
            .catch(err => {
              pushAppNotification({type: 'error', message: `Failed to delete store: ${err.message}`});
            });
        }
      });
    }
  };

  const publishStore = (store: Store) => {
    if (catalog) {
      openAdminConfirmationDialog({
        title: `Announce ${store.storeId}?`, action: 'Announce store', onConfirm: () => {
          StoresService.publishStore(catalog.name, store)
            .then(() => pushAppNotification({type: 'success', message: 'Store announced'}))
            .catch(err => {
              pushAppNotification({type: 'error', message: `Failed to announce store: ${err.message}`});
            });
        }
      });
    }
  };

  const toggleStoreLock = (store: Store) => {
    if (catalog) {
      if (store.locked) {
        openAdminConfirmationDialog({
          title: `Unlock ${store.storeId}?`, action: 'Unlock store', onConfirm: () => {
            StoresService.unlockCatalogStore(catalog.name, store)
              .then(() => pushAppNotification({type: 'success', message: 'Store unlocked'}))
              .catch(err => {
                pushAppNotification({type: 'error', message: `Failed to unlock store: ${err.message}`});
              });
          }
        });
      } else {
        openAdminConfirmationDialog({
          title: `Lock ${store.storeId}?`, action: 'Lock store', onConfirm: () => {
            StoresService.lockCatalogStore(catalog.name, store)
              .then(() => pushAppNotification({type: 'success', message: 'Store locked'}))
              .catch(err => {
                pushAppNotification({type: 'error', message: `Failed to lock store: ${err.message}`});
              });
          }
        });
      }
    }
  };

  const getMenuActions = (store: Store) => {
    const actions: MenuAction<Store>[] = [];
    if (UserService.canCreate('store')) {
      actions.push({type: 'button', icon: FileCopyIcon, label: 'Clone', onClick: setCloneStore});
    }

    if (UserService.canUpdate('store')) {
      if (actions.length > 0) {
        actions.push({type: 'divider'});
      }
      actions.push({type: 'button', icon: PublishIcon, label: 'Announce', onClick: publishStore});
    }

    if (UserService.canDelete('store')) {
      if (actions.length > 0) {
        actions.push({type: 'divider'});
      }
      actions.push({type: 'button', icon: DeleteIcon, label: 'Delete', onClick: deleteStore});
    }

    if (UserService.canUpdate('storeLock')) {
      if (actions.length > 0) {
        actions.push({type: 'divider'});
      }
      if (store.locked) {
        actions.push({type: 'button', icon: LockOpen, label: 'Unlock', onClick: toggleStoreLock});
      } else {
        actions.push({type: 'button', icon: Lock, label: 'Lock', onClick: toggleStoreLock});
      }
    }
    return actions;
  };

  if (loadingStores) {
    return (
      <Box textAlign="center">
        <CircularProgress/>
      </Box>
    );
  } else {
    return (<>
      {UserService.canCreate('store') && (
        <Box mb={2}>
          <Grid container alignItems="center">
            <Grid item xs/>
            <Grid item>
              <Button
                variant="contained"
                color="primary"
                onClick={() => setAddStore(true)}
              >
                Add store
              </Button>
            </Grid>
          </Grid>
        </Box>
      )}

      <MaterialTable
        title="Stores"
        columns={columns}
        data={stores}
        menuActions={getMenuActions}
      />

      {addStore && catalog && (
        <StoreAddOrCloneDialog
          stores={stores}
          setStores={setStores}
          catalog={catalog}
          onClose={() => setAddStore(false)}
        />
      )}

      {cloneStore && catalog && (
        <StoreAddOrCloneDialog
          storeToClone={cloneStore}
          stores={stores}
          setStores={setStores}
          catalog={catalog}
          onClose={() => setCloneStore(null)}
        />
      )}
    </>);
  }
};

import { useCallback, useEffect, useRef, useState } from 'react';
import type { Column, Query, QueryResult } from "material-table";
import { Grid, Link, Tooltip } from "@material-ui/core";
import { Link as RouterLink } from "react-router-dom";
import { UserService } from "../../../services/user";
import type { MenuAction } from "../../../components/MaterialTable";
import MaterialTable from "../../../components/MaterialTable";
import type { Entitlement, EntitlementRewards } from "../../../services/rewards";
import { EntitlementRewardsService } from "../../../services/rewards";
import DeleteIcon from "@material-ui/icons/Delete";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import { paginatedRequestFromQuery } from "../../../services/model/pagination";
import { CheckCircle } from "@material-ui/icons";
import { green } from "@material-ui/core/colors";
import { openAdminConfirmationDialog } from "../../../shared/hooks/useAdminConfirmationDialog";
import { pushAppNotification } from "../../../shared/hooks/useAppNotification";

export const Rewards = () => {
  const tableRef = useRef<MaterialTable<Reward>>();
  const [rewardWasDeleted, setRewardWasDeleted] = useState(false);

  const getRewardsPage = useCallback(async (query: Query<EntitlementRewards>): Promise<QueryResult<EntitlementRewards>> => {
    return new Promise(resolve => {
      const request = paginatedRequestFromQuery(query);
      request.search = query.search.trim();
      request.name = request.search;

      const getEntitlementRewards = EntitlementRewardsService.getEntitlementRewards(request);
      getEntitlementRewards.then(result => {
        resolve({
          data: result.items,
          page: request.page - 1,
          totalCount: result.totalCount
        });
      });
    });
  }, []);

  const subTableColumns: Column<Entitlement>[] = [
    {
      title: 'Item Id',
      field: 'itemId'
    },
    {
      title: 'Catalog',
      field: 'catalogName'
    },
    {
      title: 'Max',
      field: 'maxAvailableCount'
    },
    {
      title: 'Available',
      field: 'currentAvailableCount'
    },
    {
      title: 'Weight',
      field: 'weight'
    }
  ];

  const columns: Column<EntitlementRewards>[] = [
    {
      title: 'ID',
      field: 'id',
      render: reward => (
        <Grid container spacing={1} alignItems="center">
          <Grid item xs>
            <Link component={RouterLink} to={`/rewards/${reward.id}`}>
              {reward.id}
            </Link>
          </Grid>
        </Grid>
      ),
      sorting: false,
    },
    {
      title: 'Active',
      render: item => (
        <Grid container spacing={1} alignItems="center">
          {item.active && (
            <Grid item xs="auto">
              <Tooltip title="Active">
                <CheckCircle style={{color: green[500]}}/>
              </Tooltip>
            </Grid>
          )}
        </Grid>
      ),
      sorting: false,
    },
    {
      title: 'Description',
      field: 'description',
      defaultSort: 'asc',
    },
    {
      title: 'Url Path',
      field: 'urlPath',
      sorting: false,
    },
    {
      title: 'Type',
      field: 'type',
      sorting: false,
    },
    {
      render: reward => (
        <MaterialTable
          columns={subTableColumns}
          data={reward.entitlements}
          options={{
            paging: false,
            search: false,
            toolbar: false,
            sorting: false,
            showTitle: false
          }}
        />
      )
    }
  ];

  const deleteReward = (reward: EntitlementRewards) => {
    openAdminConfirmationDialog({
      title: `Delete ${reward.id}?`,
      action: 'Delete',
      onConfirm: () => {
        EntitlementRewardsService.deleteEntitlementReward(reward.id)
          .then(() => {
            pushAppNotification({ type: 'success', message: 'Reward deleted' });
            setRewardWasDeleted(true);
          })
          .catch(err => pushAppNotification({type: 'error', message: `Failed to delete reward: ${err.message}`}));
      }
    });
  };

  useEffect(() => {
    if (tableRef && tableRef.current && rewardWasDeleted) {
      setRewardWasDeleted(false);
      (tableRef.current as any).onQueryChange();
    }
  }, [rewardWasDeleted, setRewardWasDeleted]);

  const actions: MenuAction<EntitlementRewards>[] = [];
  if (UserService.canDelete('rewards')) {
    actions.push({type: 'button', icon: DeleteIcon, label: 'Delete', onClick: deleteReward});
  }

  return (<>
    <Box mb={2}>
      <Grid container spacing={2} alignItems="center">
        <Grid item xs>
          <Typography variant="h6">
            Entitlement Rewards
          </Typography>
        </Grid>
      </Grid>
    </Box>
    <MaterialTable
      tableRef={tableRef}
      title="Rewards"
      storageId="rewards"
      columns={columns}
      data={getRewardsPage}
      options={{
        columnsButton: true,
        debounceInterval: 500,
        pageSize: 20,
        pageSizeOptions: [20, 50, 100],
        thirdSortClick: false
      }}
      menuActions={actions}
      useRouter
    />
  </>);
};

import { Component } from 'react';
import type { RouteComponentProps } from 'react-router';
import type { LinkTabConfig} from '../../components/LinkTabs';
import { LinkTabs } from '../../components/LinkTabs';
import BlankoProgressionDnas from './BlankoProgressionDnas';
import BlankoProgressionForm from './BlankoProgressionForm';

type Props = RouteComponentProps<{ progressionId: string; }>;

export default class BlankoProgressionPage extends Component<Props> {
  private tabs: LinkTabConfig[] = [];

  constructor(props: Props) {
    super(props);
    const progressionId = this.props.match.params.progressionId;
    this.tabs.push({ url: '', label: 'Progression', render: () => <BlankoProgressionForm progressionId={progressionId} /> });
    if (progressionId !== 'new') {
      this.tabs.push({ url: '/dnas', label: 'DNAs', render: () => <BlankoProgressionDnas progressionId={progressionId} /> });
    }
  }

  render() {
    return (
      <LinkTabs tabs={this.tabs} {...this.props} />
    );
  }
}
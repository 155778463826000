import { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { cmSetMigrationActions } from '../../../../redux/catalog-migration/actions';
import type { MigrationActions } from '../../../../redux/catalog-migration/reducer';
import { selectCatalog } from '../../../../redux/catalog-migration/selectors';
import { useTypedSelector } from '../../../../redux/reducers';
import type { ItemDefinition } from '../../../../services/item-definitions';
import { CatalogItemDiff } from '../../types';
import { Items } from './Items';

export const ItemsContainer = () => {
  const dispatch = useDispatch();

  const sourceCatalog = useTypedSelector(state => selectCatalog(state, 'source')).catalog;
  const targetCatalog = useTypedSelector(state => selectCatalog(state, 'target')).catalog;
  const actions = useTypedSelector(state => state.catalogMigration.actions.items);
  const options = useTypedSelector(state => state.catalogMigration.options);

  const [sourceItems, setSourceItems] = useState<CatalogItemDiff[] | null>(null);
  const [targetItems, setTargetItems] = useState<CatalogItemDiff[] | null>(null);

  const onSelectionChange = useCallback(
    (actions: MigrationActions) => dispatch(cmSetMigrationActions({ id: 'items', actions })),
    [dispatch]
  );

  useEffect(() => {
    if (sourceCatalog && sourceCatalog.items && targetCatalog && targetCatalog.items) {
      const mapItems = (items: ItemDefinition[]) => {
        return items.filter(item => (!item.bundle && !item.container && options.items) || (item.bundle && options.bundles) || (item.container && options.containers))
          .map(item => new CatalogItemDiff(item));
      }

      setSourceItems(mapItems(sourceCatalog.items));
      setTargetItems(mapItems(targetCatalog.items));
    }
  }, [sourceCatalog, targetCatalog, options]);

  return (<Items
    sourceItems={sourceItems}
    targetItems={targetItems}
    actions={actions}
    onSelectionChange={onSelectionChange} />);
}

import { buildDiffData, MigrationModule } from "../../migrations";
import { CatalogMigrationService } from "../../../../services/catalog-migration";
import _ from "lodash";
import { LiveEventDiff } from "./liveEventDiff";
import { LiveEventDiffs } from "./LiveEventDiffs";
import { MigrationType } from "../migration-type";

export const liveEventMigration: MigrationModule<LiveEventDiff> = {
  id: MigrationType.liveEvents,
  displayName: 'Live Events',
  diffComponent: LiveEventDiffs,
  crossEnvOnly: true,

  loadData: async (sourceData, targetData, migrationData) => {
    sourceData.liveEvents = await CatalogMigrationService.getLiveEvents(sourceData.env, sourceData.catalogName);
    targetData.liveEvents = await CatalogMigrationService.getLiveEvents(targetData.env, sourceData.catalogName);

    migrationData.liveEvents = buildDiffData(
      sourceData.liveEvents.map(liveEvent => new LiveEventDiff(liveEvent)),
      targetData.liveEvents.map(liveEvent => new LiveEventDiff(liveEvent))
    );
    return migrationData.liveEvents;
  },

  runMigration: async (props, setStatus) => {
    const selection = props.selections.liveEvents || {};
    const diffs = props.migrationData.liveEvents;
    if (!diffs) {
      throw new Error('Live events not loaded');
    }

    const toMigrate = diffs.added.concat(diffs.changed).filter(v => Boolean(selection[v.diffId])).map(v => v.liveEvent);
    if (toMigrate.length > 0) {
      setStatus('Live events data');
      await CatalogMigrationService.migrateLiveEvents(props.targetData.env, props.targetData.catalogName, toMigrate);
    }
  }
};

import { Button, Card, CardContent, CardHeader, Grid, TextField, Box, Link } from '@material-ui/core';
import { useState } from 'react';
import type { Player } from '../players';
import { MythicalAccountForm } from '../../mythical-accounts/components/MythicalAccountForm';
import { GrantCurrencyDialog } from './GrantCurrencyDialog';
import { EntityNotes } from '../../../shared/components/EntityNotes';
import { PlayerModeration } from './PlayerModeration';
import { UserService } from '../../../services/user';
import { FtuePanel } from './FtuePanel';
import { PlayerPermissions } from './PlayerPermissions';
import { PlayerTagsPanel } from "../../accounts/components/PlayerTagsPanel";
import { PlayerWallet } from './PlayerWallet';
import { Link as RouterLink } from "react-router-dom";
import { PlayerNote, PlayersService } from "../players";

interface Props {
  player: Player;
  refreshPlayer: () => void;
  setPlayer: (player: Player) => void;
}

export const PlayerInfo = ({player, refreshPlayer, setPlayer}: Props) => {
  const mockDate = (new Date()).toLocaleString();
  const [grantCurrencyDialogOpen, setGrantCurrencyDialogOpen] = useState(false);

  return (<>
    <Grid container spacing={3}>
      <Grid item xs={12} md={6} xl={4}>
        <Card>
          <CardHeader title="Account Info"/>
          <CardContent>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <TextField
                  label="Player ID"
                  fullWidth
                  InputProps={{readOnly: true}}
                  value={player.externalId}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="Mythical ID"
                  fullWidth
                  InputProps={{readOnly: true}}
                  value={player.mythicalId}
                />
              </Grid>
            </Grid>
            {UserService.canRead('mythicalAccount') ? (
              <MythicalAccountForm accountId={player.mythicalId} displayName={player.displayName}/>
            ) : (
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <TextField
                    label="Display name"
                    fullWidth
                    InputProps={{readOnly: true}}
                    value={player.displayName}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    label="Email"
                    fullWidth
                    InputProps={{readOnly: true}}
                    value={player.email}
                  />
                </Grid>
              </Grid>
            )}
          </CardContent>
        </Card>
        <Box mt={3}>
          <Card>
            <CardHeader title="Permissions"/>
            <CardContent>
              <PlayerPermissions player={player} setPlayer={setPlayer}/>
            </CardContent>
          </Card>
        </Box>
        <PlayerWallet player={player}/>
      </Grid>
      <Grid item xs={12} md={6} xl={4}>
        <Card>
          <CardHeader title="Currency"/>
          <CardContent>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <TextField
                  label="Value to date"
                  fullWidth
                  InputProps={{readOnly: true}}
                  value="$0.00"
                />
              </Grid>
              {Object.values(player.virtualCurrencies).map(currency => (
                <Grid key={currency.code} item xs={12}>
                  <TextField
                    label={currency.name}
                    fullWidth
                    InputProps={{readOnly: true}}
                    value={currency.amount}
                  />
                </Grid>
              ))}
              {UserService.canGrantItems() && (
                <Grid item xs={12}>
                  <Button variant="contained" color="primary" onClick={() => setGrantCurrencyDialogOpen(true)}>
                    Grant currency
                  </Button>
                </Grid>
              )}
            </Grid>
          </CardContent>
        </Card>
        {UserService.canGrantItems() && (
          <Box mt={3}>
            <FtuePanel player={player} refreshPlayer={refreshPlayer}/>
          </Box>
        )}
        <Box mt={3}>
          <Card>
            <CardHeader title="Moderation"/>
            <CardContent>
              <PlayerModeration player={player} setPlayer={setPlayer}/>
            </CardContent>
          </Card>
        </Box>
      </Grid>
      <Grid item xs={12} md={6} xl={4}>
        <Card>
          <CardHeader title="Activity"/>
          <CardContent>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <TextField
                  label="Last login"
                  fullWidth
                  InputProps={{readOnly: true}}
                  value={player.lastLogin ? player.lastLogin.toLocaleString() : '-'}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="Last payment"
                  fullWidth
                  InputProps={{readOnly: true}}
                  value={mockDate}
                />
              </Grid>
            </Grid>
          </CardContent>
        </Card>
        {UserService.canUpdate('player') && (
          <Box mt={3}>
            <PlayerTagsPanel player={player} onSuccess={() => refreshPlayer()}/>
          </Box>
        )}
      </Grid>
      {UserService.canRead('playerNotes') && (
        <Grid item xs={12}>
          <EntityNotes<PlayerNote>
            entityId={player.externalId}
            title="Player notes"
            permissionEntityType='playerNotes'
            noteField='message'
            authorField='ownerDisplayName'
            dateField='timestamp'
            entityIdField='playerId'
            getNotes={PlayersService.getPlayerNotes}
            createNote={PlayersService.addPlayerNote}
            updateNote={PlayersService.updatePlayerNote}
            deleteNote={PlayersService.deletePlayerNote}
            renderAuthor={note => {
              if (!note) {
                return null;
              }

              return note.ownerPlayerId && note.ownerPlayerId !== player.externalId ? (
                <Link component={RouterLink} to={`/players/${note.ownerPlayerId}`}>
                  {note.ownerDisplayName || note.ownerPlayerId}
                </Link>
              ) : <>{note.ownerDisplayName || note.ownerPlayerId || note.playerId}</>;
            }}
          />
        </Grid>
      )}
    </Grid>

    {grantCurrencyDialogOpen && (
      <GrantCurrencyDialog
        playerId={player.externalId}
        onClose={() => setGrantCurrencyDialogOpen(false)}
        onComplete={() => refreshPlayer()}
      />
    )}
  </>);
};

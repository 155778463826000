import { seasonalDataComparer } from "./seasonal-data-comparer";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { useCallback, useMemo, useState } from "react";
import { Accordion, AccordionDetails, AccordionSummary, Box, Card, CardContent, Grid } from "@material-ui/core";
import { ChallengeDiffProps } from "./SeasonalDiffView";


//type aliases
type challengeDictionary = { [key: string]: Challenge };

export const GlobalChallengesDiffView = (props: ChallengeDiffProps) => {
  const { beforeChanges, afterChanges, previous, current, renderDifferentialChange, renderChallengeRewards } = props;
  const classes = seasonalDataComparer.getStyles();

  const [challengeExpanded, setChallengeExpanded] = useState(false);

  const showSeasonChallengesHeader = useMemo(() => {
    return beforeChanges?.seasonalChallenges || afterChanges.seasonalChallenges
  }, [beforeChanges, afterChanges]);

  const renderChallengeParameters = useCallback((challengeId: string, afterParameters: ChallengeParameter[], beforeParameters?: ChallengeParameter[]) => {
    if (!afterParameters && !beforeParameters) {
      return;
    }

    const afterDiffs = new Set<number>();
    afterParameters?.forEach((unused, i) => {
      const parameter = current.seasonalChallenges[challengeId].parameters[i];
      if (parameter) {
        afterDiffs.add(i);
      }
    });

    const beforeDiffs = new Set<number>();
    beforeParameters?.forEach((unused, i) => {
      const parameter = previous?.seasonalChallenges[challengeId].parameters[i];
      if (parameter) {
        beforeDiffs.add(i)
      }
    });

    //Convert to set to remove dupes
    const combinedKeys = new Set<number>([...afterDiffs, ...beforeDiffs]);
    const previousKeys = previous?.seasonalChallenges?.[challengeId]?.parameters || [];
    const currentKeys = current?.seasonalChallenges?.[challengeId]?.parameters || [];
    const combinedExistingKeys = Array.from(new Set([...previousKeys.keys(), ...currentKeys.keys()]));

    return (
      <>
        {
          combinedExistingKeys.map(key => {
            if (combinedKeys.has(key)) {
              const currentItem = current?.seasonalChallenges?.[challengeId]?.parameters?.[key];
              const previousItem = previous?.seasonalChallenges?.[challengeId]?.parameters?.[key];
              return (
                <Grid container key={key}>
                  <Grid className={classes.sectionTitle} item xs={12}>
                    <h4>Parameter {key}</h4>
                  </Grid>
                  {renderDifferentialChange("Name", currentItem?.name, previousItem?.name)}
                  {renderDifferentialChange("Type", currentItem?.type, previousItem?.type)}
                  {renderDifferentialChange("Value", currentItem?.value, previousItem?.value)}
                </Grid>
              )
            }
          })
        }
      </>
    );
  }, [current, previous, classes, renderDifferentialChange]);

  const renderChallenges = useCallback((afterChallenges: challengeDictionary, beforeChallenges?: challengeDictionary) => {
    const safeBefore = beforeChallenges ? beforeChallenges : {} as challengeDictionary;

    const afterDiffs = new Set<string>();
    Object.keys(afterChallenges).forEach((c, i) => {
      const challenge = current.seasonalChallenges[c];
      if (challenge) {
        afterDiffs.add(c);
      }
    });

    const beforeDiffs = new Set<string>();
    Object.keys(safeBefore).forEach((c, i) => {
      const challenge = previous?.seasonalChallenges[c];
      if (challenge) {
        beforeDiffs.add(c);
      }
    });
    //Convert to set to remove dupes
    const combinedKeys = new Set<string>([...afterDiffs, ...beforeDiffs]);
    return (
      Array.from(combinedKeys).map(key => {
        const challengeId = (Object.keys(previous?.seasonalChallenges || {}).includes(key) ?
          previous?.seasonalChallenges[key].challengeId :
          current.seasonalChallenges[key].challengeId) || "Missing Key";

        const currentItem = current?.seasonalChallenges?.[challengeId];
        const previousItem = previous?.seasonalChallenges?.[challengeId];

        if (combinedKeys.has(challengeId)) {

          return (
            <Card key={key} className={classes.challengeContainer} variant="outlined" >
              <CardContent>
                <Grid container>
                  <Grid className={classes.sectionTitle} item xs={12}>
                    <h3>Challenge : {current.seasonalChallenges?.[key]?.challengeName || previous?.seasonalChallenges?.[key]?.challengeName}</h3>
                  </Grid>
                  {renderDifferentialChange("Name", currentItem?.challengeName, previousItem?.challengeName)}
                  {renderDifferentialChange("Id", currentItem?.challengeId, previousItem?.challengeId)}
                  {renderDifferentialChange("Description", currentItem?.challengeDesc, previousItem?.challengeDesc)}
                  {renderDifferentialChange("PP+", currentItem?.partyPassPlusOnly, previousItem?.partyPassPlusOnly)}
                  {renderDifferentialChange("Template #", currentItem?.templateVersion, previousItem?.templateVersion)}
                  {renderDifferentialChange("Template Id", currentItem?.templateId, previousItem?.templateId)}
                  {renderDifferentialChange("Template Id", currentItem?.triggerType, previousItem?.triggerType)}
                  {renderChallengeRewards(afterChallenges?.[key]?.rewards, safeBefore[key]?.rewards, current.seasonalChallenges?.[key]?.rewards, previous?.seasonalChallenges?.[key]?.rewards)}
                  {renderChallengeParameters(challengeId, afterChallenges?.[key]?.parameters, safeBefore[key]?.parameters)}
                </Grid>
              </CardContent>
            </Card>
          )
        }
      })
    );
  }, [previous, current, renderDifferentialChange, classes, renderChallengeRewards, renderChallengeParameters]);

  return (
    <>
      {showSeasonChallengesHeader && (
        <Accordion
          expanded={challengeExpanded}
          TransitionProps={{ unmountOnExit: true }}
          onChange={() => setChallengeExpanded(!challengeExpanded)}
          className={classes.accordianSummary}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            className={classes.accordianSummary}
          >
            <Grid container alignItems="center" spacing={2}>
              <Grid item xs={12} className={classes.accordionTitle}>
                Challenges
              </Grid>
            </Grid>
          </AccordionSummary>
          <AccordionDetails style={{ padding: 0 }}>
            <Box width="100%">
              {renderChallenges(afterChanges.seasonalChallenges, beforeChanges?.seasonalChallenges)}
            </Box>
          </AccordionDetails>
        </Accordion>
      )}
    </>
  )
}
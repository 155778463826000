import { createRef, Component } from 'react';
import type { RootState } from '../../redux/reducers';
import { getTitleFilesAsync, deleteTitleFileAsync } from '../../redux/title-data/actions';
import type { ConnectedProps } from 'react-redux';
import { connect } from 'react-redux';
import type { Column } from 'material-table';
import type { TitleFile } from '../../services/model/title-file';
import { Box, CircularProgress, Grid, Button, Dialog, DialogContent, Typography } from '@material-ui/core';
import type { MenuAction } from '../../components/MaterialTable';
import MaterialTable from '../../components/MaterialTable';
import { setAppNotification, openConfirmationDialog } from '../../redux/app/actions';
import type { ApiError } from '../../services/api';
import { TitleService } from '../../services/title-data';
import GetAppIcon from '@material-ui/icons/GetApp';
import PublishIcon from '@material-ui/icons/Publish';
import DeleteIcon from '@material-ui/icons/Delete';
import { UserService } from '../../services/user';

const mapStateToProps = (state: RootState) => ({
  files: state.title.files
});
const mapDispatcher = {
  requestTitleFiles: getTitleFilesAsync.request,
  requestDeleteFile: deleteTitleFileAsync.request,
  setAppNotification,
  openConfirmationDialog
};
const connector = connect(mapStateToProps, mapDispatcher);

type Props = ConnectedProps<typeof connector>;

interface State {
  isUploading: boolean;
}

class TitleFiles extends Component<Props, State> {
  private columns: Column<TitleFile>[] = [
    { title: 'Filename', field: 'fileName', defaultSort: 'asc' },
    { title: 'Uploader', field: 'uploaderName' },
    { title: 'Uploaded at', field: 'updatedTimestamp', render: file => <>{file.updatedTimestamp.toLocaleString()}</> },
    { title: 'Version', field: 'version' }
  ];

  private inputRef = createRef<HTMLInputElement>();
  private updatingFile?: TitleFile;

  constructor(props: Props) {
    super(props);
    this.state = {
      isUploading: false
    };
  }

  componentDidMount() {
    this.props.requestTitleFiles();
  }

  getTableMenuActions = (file: TitleFile): MenuAction<TitleFile>[] => {
    const actions: MenuAction<TitleFile>[] = [
      { type: 'link', icon: GetAppIcon, label: 'Download', href: file.fileUrl }
    ];

    if (UserService.canUpdate('title')) {
      actions.push({ type: 'button', icon: PublishIcon, label: 'Upload new version', onClick: () => this.openUploadDialog(file) });
    }

    if (UserService.canDelete('title')) {
      actions.push({ type: 'divider' });
      actions.push({ type: 'button', icon: DeleteIcon, label: 'Delete', onClick: this.onFileDelete });
    }

    return actions;
  };

  openUploadDialog = (updatingFile?: TitleFile) => {
    if (this.inputRef.current) {
      this.updatingFile = updatingFile;
      this.inputRef.current.click();
    }
  };

  onFileChange = () => {
    if (this.inputRef.current && this.inputRef.current.files && this.inputRef.current.files.length > 0) {
      const file = this.inputRef.current.files[0];
      this.uploadFile(file, this.updatingFile)
        .then(() => {
          this.props.setAppNotification({ type: 'success', message: 'File uploaded' });
        })
        .catch((e: ApiError) => {
          this.props.setAppNotification({ type: 'error', message: e.message });
        });
    }
  };

  async uploadFile(file: File, updatingFile?: TitleFile) {
    this.setState({ isUploading: true });
    const uploadUrl = updatingFile ? await TitleService.updateFile(updatingFile) : await TitleService.createFile(file.name);
    await TitleService.uploadFile(file, uploadUrl);
    this.props.requestTitleFiles();
    this.setState({ isUploading: false });
  }

  onFileDelete = (file: TitleFile) => {
    this.props.openConfirmationDialog({ title: `Delete ${file.fileName}?`, action: 'Delete file' }, () => {
      this.props.requestDeleteFile(file);
    });
  };

  render() {
    if (this.props.files.isLoading && this.props.files.data.length < 1) {
      return (
        <Box textAlign="center">
          <CircularProgress />
        </Box>
      );
    }

    return (<>
      <input
        type="file"
        accept="application/json"
        style={{ display: 'none' }}
        ref={this.inputRef}
        onChange={this.onFileChange}
      />

      {UserService.canCreate('title') && (
        <Box mb={2}>
          <Grid container alignItems="center">
            <Grid item xs />
            <Grid item>
              <Button variant="contained" color="primary" onClick={() => this.openUploadDialog()}>
                Upload new file
              </Button>
            </Grid>
          </Grid>
        </Box>
      )}

      <MaterialTable
        title="Files"
        columns={this.columns}
        data={this.props.files.data}
        options={{
          pageSize: 10
        }}
        menuActions={this.getTableMenuActions}
      />

      <Dialog
        open={this.state.isUploading}
        fullWidth
        maxWidth="sm"
      >
        <DialogContent>
          <Box textAlign="center">
            <CircularProgress />
            <Typography>Uploading...</Typography>
            <Typography>Please do not close this tab.</Typography>
          </Box>
        </DialogContent>
      </Dialog>
    </>);
  }
}

export default connector(TitleFiles);
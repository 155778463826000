import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import CircularProgress from '@material-ui/core/CircularProgress';
import FormControl from '@material-ui/core/FormControl';
import Grid from '@material-ui/core/Grid';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';

import type { FormikHelpers } from 'formik';
import { Field, Form, Formik } from 'formik';
import { Select, TextField } from 'formik-material-ui';

import { Component } from 'react';
import type { ConnectedProps } from 'react-redux';
import { connect } from 'react-redux';
import { openConfirmationDialog, setAppNotification } from '../../redux/app/actions';

import { addCurrencyLimitAsync, deleteCurrencyLimitAsync, updateCurrencyLimitAsync } from '../../redux/currency-limits/actions';
import type { RootState } from '../../redux/reducers';

import { CurrencyLimit } from '../../services/currency-limits';
import { UserService } from '../../services/user';
import { createValidator, Validators } from '../../utils/forms';

export enum CurrencyLimitCardActionType { "CREATE", "SAVE" };

export class CurrencyLimitCardItem {
  action: CurrencyLimitCardActionType;
  title: string;
  currencyLimit: CurrencyLimit;

  constructor(currencyLimit: CurrencyLimit, title: string, action: CurrencyLimitCardActionType) {
    this.currencyLimit = currencyLimit;
    this.title = title;
    this.action = action;
  }
}

interface OwnProps {
  currencyLimitItem: CurrencyLimitCardItem
}

interface FormValues {
  code: string;
  period: string;
  periodUnit: string;
  value: string;
  isAdding: boolean;
  isUpdating: boolean;
  isDeleting: boolean;
}

const mapStateToProps = (state: RootState) => ({
});
const mapDispatch = {
  requestAddCurrencyLimit: addCurrencyLimitAsync.request,
  requestUpdateCurrencyLimit: updateCurrencyLimitAsync.request,
  requestDeleteCurrencyLimit: deleteCurrencyLimitAsync.request,
  openConfirmationDialog,
  setAppNotification
};
const connector = connect(mapStateToProps, mapDispatch);

type Props = ConnectedProps<typeof connector> & OwnProps;

interface State {
  initialFormValues: FormValues;
}

const formValidator = createValidator<FormValues>({
  period: Validators.integer(1),
  value: Validators.integer(1)
});

const timeUnits: { name: string, value: number; }[] = [
  { name: 'second', value: 1 },
  { name: 'minute', value: 60 },
  { name: 'hour', value: 3600 },
  { name: 'day', value: 86400 },
  { name: 'week', value: 604800 },
  { name: 'month', value: 2592000 },
  { name: 'year', value: 31536000 }
];

class CurrencyLimitCard extends Component<Props, State> {
  private setSubmitting: ((submitting: boolean) => void) | undefined;

  constructor(props: Props) {
    super(props);
    this.state = {
      initialFormValues: this.currencyLimitToFormValues(this.props.currencyLimitItem)
    }
  }

  private currencyLimitToFormValues(cardItem: CurrencyLimitCardItem): FormValues {
    const f: FormValues = {
      code: cardItem.currencyLimit.currencyCode,
      period: '',
      periodUnit: timeUnits[0].name,
      value: '',
      isAdding: false,
      isUpdating: false,
      isDeleting: false    
    };

    if (cardItem.action === CurrencyLimitCardActionType.CREATE) {
      f.period = '';
      f.value = '';
    } else {
      const period = Number(cardItem.currencyLimit.limitPeriod);
      const unit = timeUnits.slice().reverse().find(v => period % v.value === 0) || timeUnits[0];
      f.period = (period / unit.value).toString();
      f.periodUnit = unit.name;
      f.value = String(cardItem.currencyLimit.limitValue);
    }

    return f;
  }

  componentDidUpdate(prevProps: Props) {
    if (prevProps.currencyLimitItem !== this.props.currencyLimitItem) {
      this.setState({ initialFormValues: this.currencyLimitToFormValues(this.props.currencyLimitItem) });
      if (this.setSubmitting) {
        this.setSubmitting(false);
      }
    }
  }

  onSubmit = (values: FormValues, helpers: FormikHelpers<FormValues>) => {

    const currencyLimit = new CurrencyLimit();
    currencyLimit.currencyCode = values.code;

    if (values.period.trim()) {
      const timeUnit = timeUnits.find(v => v.name === values.periodUnit) || timeUnits[0];
      currencyLimit.limitPeriod = parseInt(values.period) * timeUnit.value;
    }
    currencyLimit.limitValue = parseInt(values.value);

    this.setSubmitting = helpers.setSubmitting;
    if (values.isAdding) {
      this.props.requestAddCurrencyLimit(currencyLimit);
    }
    else if (values.isUpdating) {
      this.props.requestUpdateCurrencyLimit(currencyLimit);
    }
    else if (values.isDeleting) {
      this.props.requestDeleteCurrencyLimit(currencyLimit);
    }
  };

  renderTimeUnits(plural: boolean) {
    return timeUnits.map(v => (
      <MenuItem key={v.name} value={v.name}>{v.name + (plural ? 's' : '')}</MenuItem>
    ));
  }

  render() {
    const canCreate = UserService.canCreate('currencyLimits');
    const canUpdate = UserService.canUpdate('currencyLimits');
    const canDelete = UserService.canDelete('currencyLimits');
    
    const readOnly = !canUpdate;

    return (<>
      <Card>
        <CardHeader title={this.props.currencyLimitItem.title} />
        <CardContent>
          <Formik<FormValues>
            initialValues={this.state.initialFormValues}
            enableReinitialize
            validate={formValidator}
            onSubmit={this.onSubmit}
          >
            {({ submitForm, isSubmitting, values, errors, setFieldValue }) => (
              <Form>
                <Grid item xs={12}>
                  <Field
                    component={TextField}
                    name="code"
                    type="string"
                    label="Currency Code"
                    fullWidth
                    InputProps={{ readOnly: true }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Field
                    component={TextField}
                    name="period"
                    type="string"
                    label="Period"
                    fullWidth
                    InputProps={{ readOnly }}
                  />
                </Grid>
                <Grid item xs={4}>
                  <FormControl fullWidth>
                    <InputLabel> </InputLabel>
                    <Field
                      component={Select}
                      name="periodUnit"
                      inputProps={{ readOnly }}
                    >
                      {this.renderTimeUnits(parseInt(values.periodUnit) !== 1)}
                    </Field>
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <Field
                    component={TextField}
                    name="value"
                    type="string"
                    label="Limit Value"
                    fullWidth
                    InputProps={{ readOnly }}
                  />
                </Grid>
                {!readOnly && (
                  <Box py={2}>
                    <Grid container alignItems="flex-start" justify="space-between" direction="row">
                      { canCreate && this.props.currencyLimitItem.action === CurrencyLimitCardActionType.CREATE ? 
                        <Button
                          name="create"
                          variant="contained"
                          color="primary"
                          disabled={isSubmitting}
                          onClick={() => { values.isAdding = true; submitForm(); }}
                        >
                          {isSubmitting && values.isAdding ? (<CircularProgress size={25} />) : 'Create'}
                        </Button>
                        : null }
                      { canUpdate && this.props.currencyLimitItem.action === CurrencyLimitCardActionType.SAVE ? 
                        <Button
                          name="save"
                          variant="contained"
                          color="primary"
                          disabled={isSubmitting}
                          onClick={() => { values.isUpdating = true; submitForm(); }}
                        >
                          {isSubmitting && values.isUpdating ? (<CircularProgress size={25} />) : 'Save'}
                        </Button>
                        : null }
                      { canDelete && this.props.currencyLimitItem.action === CurrencyLimitCardActionType.SAVE ?
                        <Button           
                          name="delete"                                   
                          variant="contained"
                          color="secondary"
                          disabled={isSubmitting}
                          onClick={() => { values.isDeleting = true; submitForm(); }}
                        >
                          {isSubmitting && values.isDeleting ? (<CircularProgress size={25} />) : 'Clear'}
                        </Button>
                        : null }
                    </Grid>
                  </Box>
                )}
              </Form>
            )}
          </Formik>

        </CardContent>
      </Card>
    </>);
  }
}

export default connector(CurrencyLimitCard);
import { useCallback, useEffect, useState } from "react";
import {
  Box,
  Button,
  Card,
  CardContent,
  CircularProgress,
  InputLabel,
  MenuItem,
  Select,
  TextField as MTextField} from "@material-ui/core";
import type { RouteComponentProps } from "react-router";
import { AwardTypeEnum, BadgeConditionRequirementEnum, BrawlModeEnum, BrawlPinTier, GameTypeEnum } from "../../../services/model/brawl-pin";
import type { BrawlPin, BrawlPinUnlockCondition } from "../../../services/model/brawl-pin";
import _ from "lodash";
import { BrawlPinsService } from "../../../services/brawl-pins";
import { UserService } from "../../../services/user";
import { BrawlPinTiers } from "./BrawlPinTiers";
import { usePushNotification } from "../../../contexts/AppNotificationContext";
import { openAdminConfirmationDialog } from "../../../shared/hooks/useAdminConfirmationDialog";
import { BrawlPinTierViewModel } from "./BrawlPinsViewModels";

type Props = RouteComponentProps<{ pinId: string; }>;

export const BrawlPinEditor = ({match}: Props) => {
  const pushNotification = usePushNotification();

  const [brawlPin, setBrawlPin] = useState<BrawlPin>();
  const [originalPinStr, setOriginalPinStr] = useState('');
  const [pinNeedsSaved, setPinNeedsSaved] = useState(false);

  const savePin = useCallback((pin: BrawlPin) => {
    BrawlPinsService.updateBrawlPin(pin)
      .then(updatedPin => {
        setBrawlPin(updatedPin);
        // this excludes all tableData properties
        setOriginalPinStr(JSON.stringify(updatedPin, (key, val) => key === 'tableData' ? undefined : val));
        pushNotification({ type: 'success', message: 'Brawl Pin Updated' });
      })
      .catch(err => pushNotification({ type: 'error', message: `Failed to update brawl pin: ${err.message}` }));
  }, [pushNotification]);

  const saveTier = useCallback((tier: BrawlPinTier, tierIndex?: number) => {
    if (!brawlPin)
      return;

    // if tier index wasn't specified, find it
    if (tierIndex === undefined) {
      tierIndex = brawlPin.tiers.findIndex(t => t.level === tier.level);
    }

    if (tierIndex >= 0) {
      // now remove the existing brawl pin tier, we'll insert the updated one
      const tiers = [...brawlPin.tiers].filter(t => t.id !== tier.id);

      // add the updated tier
      tiers.splice(tierIndex, 0, tier);

      savePin({ ...brawlPin, tiers });
    }
  }, [brawlPin, savePin]);

  const deleteTier = useCallback((tierViewModel: BrawlPinTierViewModel) => {
    if (!brawlPin)
      return;

    openAdminConfirmationDialog({
      title: `Delete ${tierViewModel.tier.level}?`,
      action: 'Delete',
      onConfirm: () => {
        const tiers = [...brawlPin.tiers].filter(tier => tier.level !== tierViewModel.tier.level);
        savePin({...brawlPin, tiers});
      }
    });
  }, [brawlPin, savePin]);

  const moveTierUp = useCallback((tierViewModel: BrawlPinTierViewModel) => {
    if (!brawlPin)
      return;

    const currentIndex = brawlPin.tiers.findIndex(tier => tier.id === tierViewModel.tier.id);
    if (currentIndex === 0)
      return;

    const tiers = [...brawlPin.tiers];;
    const newIndex = currentIndex - 1;
    [tiers[currentIndex], tiers[newIndex]] = [tiers[newIndex], tiers[currentIndex]]
    savePin({ ...brawlPin, tiers });
  }, [brawlPin, savePin]);

  const moveTierDown = useCallback((tierViewModel: BrawlPinTierViewModel) => {
    if (!brawlPin)
      return;

    const currentIndex = brawlPin.tiers.findIndex(tier => tier.id === tierViewModel.tier.id);
    if (currentIndex === brawlPin.tiers.length - 1)
      return;

    const tiers = [...brawlPin.tiers];;
    const newIndex = currentIndex + 1;
    [tiers[currentIndex], tiers[newIndex]] = [tiers[newIndex], tiers[currentIndex]]
    savePin({ ...brawlPin, tiers });
  }, [brawlPin, savePin]);

  const upsertBrawlPinTier = useCallback((tierViewModel: BrawlPinTierViewModel, checkLevel: boolean) =>  {
    if (!brawlPin) {
      throw new Error('Brawl pin is missing. Unable to update pin tier.');
    }

    if (checkLevel) {
      const existingTier = brawlPin.tiers.find(tier => tier.level === tierViewModel.tier.level);
      if (existingTier) {
        throw new Error(`A tier with level of '${tierViewModel.tier.level}' already exists.`);
      }
    }

    // new tiers will go at the end
    const insertLocation = tierViewModel.tier.id
      ? brawlPin.tiers.findIndex(tier => tier.id === tierViewModel.tier.id)
      : brawlPin.tiers.length;

    saveTier(tierViewModel.toBrawlPinTier(), insertLocation);
  }, [brawlPin, saveTier]);

  const deleteConditionGroupFromTier = useCallback((tierViewModel: BrawlPinTierViewModel, groupIndex: number) => {
    if (!brawlPin)
      return;

    openAdminConfirmationDialog({
      title: `Delete Condition Group?`,
      action: 'Delete',
      onConfirm: () => {
        // work with copy of tier view model
        const viewModelForUpdate = _.cloneDeep(tierViewModel);

        // remove the condition group
        viewModelForUpdate.unlockConditionGroups.splice(groupIndex, 1);

        saveTier(viewModelForUpdate.toBrawlPinTier());
      }
    });
  }, [brawlPin, saveTier]);

  const deleteConditionFromTierGroup = useCallback((condition: BrawlPinUnlockCondition, tierViewModel: BrawlPinTierViewModel, groupIndex: number) => {
    if (!brawlPin)
      return;

    openAdminConfirmationDialog({
      title: `Delete ${condition.unlockType}?`,
      action: 'Delete',
      onConfirm: () => {
        // work with copy of tier view model
        const viewModelForUpdate = _.cloneDeep(tierViewModel);

        // remove the condition from condition group
        viewModelForUpdate.unlockConditionGroups[groupIndex] = [...viewModelForUpdate.unlockConditionGroups[groupIndex]].filter(uc => uc.id !== condition.id);

        saveTier(viewModelForUpdate.toBrawlPinTier());
      }
    });
  }, [brawlPin, saveTier]);

  const upsertUnlockConditionForTierGroup = useCallback((condition: BrawlPinUnlockCondition, tierViewModel: BrawlPinTierViewModel, groupIndex: number, checkUnlockType: boolean) =>  {
    if (!brawlPin) {
      throw new Error('Brawl pin is missing. Unable to update unlock condition.');
    } else if (!tierViewModel) {
      throw new Error('Tier is missing. Unable to update unlock condition.');
    }

    // work with copy of tier view model
    const viewModelForUpsert = _.cloneDeep(tierViewModel);

    // if groupIndex is the length of unlock groups, we're adding a new condition group
    if (groupIndex === viewModelForUpsert.unlockConditionGroups.length) {
      viewModelForUpsert.unlockConditionGroups.push([]);
    }

    const conditionGroup = viewModelForUpsert.unlockConditionGroups[groupIndex];

    if (checkUnlockType) {
      // see if condition group already has an unlock condition with the same lock type
      const existingCondition = conditionGroup.find(uc => uc.unlockType === condition.unlockType);
      if (existingCondition) {
        throw new Error(`A condition with unlock type of '${condition.unlockType}' already exists in this condition group.`);
      }
    }

    // new conditions will go at the end
    let insertLocation = conditionGroup.length;

    // if condition has an id, then we're updating so remove old condition
    if (condition.id) {
      insertLocation = conditionGroup.findIndex(c => c.id === condition.id);
      // now remove the existing condition, we'll insert the updated one
      viewModelForUpsert.unlockConditionGroups[groupIndex] = [...conditionGroup].filter(c => c.id !== condition.id);
    }

    // add the new/updated condition
    viewModelForUpsert.unlockConditionGroups[groupIndex].splice(insertLocation, 0, condition);

    saveTier(viewModelForUpsert.toBrawlPinTier());
  }, [brawlPin, saveTier]);

  useEffect(() => {
    if (match.params.pinId && !brawlPin) {
      BrawlPinsService.getBrawlPin(match.params.pinId)
        .then(pin => {
          setBrawlPin(pin);

          // this excludes all tableData properties
          setOriginalPinStr(JSON.stringify(pin, (key, val) => key === 'tableData' ? undefined : val));
        })
        .catch(err => pushNotification({ type: 'error', message: `Failed to get brawl pin: ${err.message}` }));
    }
  }, [brawlPin, match.params.pinId, pushNotification]);

  useEffect(() => {
    if (brawlPin && originalPinStr) {
      // this excludes all tableData properties
      const brawlPinStr = JSON.stringify(brawlPin, (key, val) => key === 'tableData' ? undefined : val);
      setPinNeedsSaved(brawlPinStr !== originalPinStr);
    }
  }, [brawlPin, originalPinStr]);

  const handleSave = useCallback(() => {
    if (!brawlPin)
      return;

    savePin(brawlPin);
  }, [brawlPin, savePin]);

  /**
   * This method is used by nested components when they want to perform an
   * action that will modify the pin.  If the pin is currently dirty, then
   * the user is prompted about whether to save the current changes before
   * performing the action.
   */
  const performActionWithValidation = useCallback((action: () => void) => {
    if (pinNeedsSaved) {
      openAdminConfirmationDialog({
        title: `Save changes before continuing?`,
        action: 'Save',
        onConfirm: () => {
          handleSave();
          action();
        }
      });
    } else {
      action();
    }
  }, [pinNeedsSaved, handleSave]);

  const handleUpdateField = (event: any, field: keyof BrawlPin) => {
    if (!brawlPin)
      return;

    setBrawlPin({
      ...brawlPin,
      [field]: event?.target?.value || event,
    });
  };

  if (!brawlPin) {
    return <Box textAlign="center">
      <CircularProgress/>
    </Box>;
  }

  return (<>
    <Card>
      <CardContent>
        <MTextField
          value={brawlPin.name}
          label="Brawl Pin Name"
          type="text"
          name="name"
          onChange={e => handleUpdateField(e, 'name')}
          fullWidth
        />
        <Box mt={2} display="flex" flexDirection="column" justifyContent="center">
          <InputLabel>Brawl Mode</InputLabel>
          <Select
            value={brawlPin.brawlMode}
            onChange={e => handleUpdateField(e, 'brawlMode')}
          >
            {Object.values(BrawlModeEnum).map(brawlMode => (
              <MenuItem key={brawlMode} value={brawlMode}>{brawlMode}</MenuItem>
            ))}
          </Select>
        </Box>
        <Box mt={2} display="flex" flexDirection="column" justifyContent="center">
          <InputLabel>Award Type</InputLabel>
          <Select
            value={brawlPin.awardType}
            onChange={e => handleUpdateField(e, 'awardType')}
          >
            {Object.values(AwardTypeEnum).map(awardType => (
              <MenuItem key={awardType} value={awardType}>{awardType}</MenuItem>
            ))}
          </Select>
        </Box>
        <Box mt={2} display="flex" flexDirection="column" justifyContent="center">
          <InputLabel>Game Type</InputLabel>
          <Select
            value={brawlPin.gameType}
            onChange={e => handleUpdateField(e, 'gameType')}
          >
            {Object.values(GameTypeEnum).map(gameType => (
              <MenuItem key={gameType} value={gameType}>{gameType}</MenuItem>
            ))}
          </Select>
        </Box>
        <Box mt={2} display="flex" flexDirection="column" justifyContent="center">
          <InputLabel>Tier Conditions Match Requirement</InputLabel>
          <Select
            value={brawlPin.badgeConditionMode}
            onChange={e => handleUpdateField(e, 'badgeConditionMode')}
          >
            {Object.values(BadgeConditionRequirementEnum).map(requirement => (
              <MenuItem key={requirement} value={requirement}>{requirement}</MenuItem>
            ))}
          </Select>
        </Box>

        {UserService.canUpdate('brawlPins') && (<>
          <Box mt={2}>
            <Button variant="contained" color="primary" onClick={() => handleSave()} disabled={!pinNeedsSaved}>Save</Button>
          </Box>
        </>)}

      </CardContent>
    </Card>

    <Box mt={2}>
      <Card>
        <CardContent>
          <BrawlPinTiers
            brawlPin={brawlPin}
            deleteConditionFromTierGroup={deleteConditionFromTierGroup}
            deleteConditionGroupFromTier={deleteConditionGroupFromTier}
            deleteTier={deleteTier}
            moveTierDown={moveTierDown}
            moveTierUp={moveTierUp}
            performActionWithValidation={performActionWithValidation}
            upsertBrawlPinTier={upsertBrawlPinTier}
            upsertUnlockConditionForTierGroup={upsertUnlockConditionForTierGroup}
          />
        </CardContent>
      </Card>
    </Box>
  </>
  );
};

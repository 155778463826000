import _ from 'lodash';
import type { AnyAction } from 'redux';
import type { PutEffect, CallEffect, SelectEffect } from 'redux-saga/effects';
import { all, call, put, select, takeEvery } from 'redux-saga/effects';
import type { PayloadAction, RootState } from 'typesafe-actions';
import { CatalogMigrationService } from '../../services/catalog-migration';
import { Catalog } from '../../services/catalogs';
import type { TitleFile } from '../../services/model/title-file';
import type { Store, StoreTabItem } from '../../services/stores';
import type { Title } from '../../services/title-data';
import type { ToolshedUser } from '../../services/user';
import { forkSagas, sagaHandleApiError } from '../utils-ts';
import type { EnvOption } from './actions';
import { cmFinishMigration, cmGetCatalogsAsync, cmPreloadData, cmSetMigrationProgress, cmSetMigrationStepProgress, cmSetMigrationStepResult, cmSetPreloadedData, cmStartMigration } from './actions';
import type { CatalogMigrationState, MigrationActions } from './reducer';

interface StoreImageMigration {
  storeId: string;
  tabName: string;
  item: StoreTabItem;
}

const migrationPermissions: { [key: string]: string[] } = {
  dna: ['Read', 'Create', 'Update'],
  catalogs: ['Read', 'Create', 'Update'],
  catalogItems: ['Read', 'Create', 'Update', 'Delete'],
  store: ['Read', 'Create', 'Update', 'Delete'],
  title: ['Read', 'Create', 'Update', 'Delete']
};

function* getEnv(envOption: EnvOption): Generator<SelectEffect, any, RootState> {
  const state = yield select();
  return state.catalogMigration[envOption].id;
}

function* getCatalogsSaga(action: ReturnType<typeof cmGetCatalogsAsync.request>): Generator<Generator<SelectEffect, any, RootState> | CallEffect<ToolshedUser> | PutEffect<PayloadAction<"cm/GET_CATALOGS_FAILURE", {
  env: EnvOption;
  error: string;
}>> | CallEffect<Catalog[]> | PutEffect<PayloadAction<"cm/GET_CATALOGS_SUCCESS", {
  env: EnvOption;
  catalogs: Catalog[];
}>> | Generator<PutEffect<AnyAction>, void, string> | unknown, void, ToolshedUser & Catalog[] & string> {
  try {
    const env = yield getEnv(action.payload.env);
    const user = yield call(CatalogMigrationService.getUser, env);

    if (!user.hasPermissions(migrationPermissions)) {
      yield put(cmGetCatalogsAsync.failure({ env: action.payload.env, error: 'Access denied' }));
      return;
    }

    const catalogs: Catalog[] = yield call(CatalogMigrationService.getCatalogs, env);
    catalogs.sort((a, b) => a.name.localeCompare(b.name));

    yield put(cmGetCatalogsAsync.success({ env: action.payload.env, catalogs }));
  } catch (e) {
    yield sagaHandleApiError(e);
  }
}

// TODO: This one needs much love. 
//       For now passing Any its way to complex right now 
function* preloadData(): Generator<any, void, Store & Catalog[] & 
Store[] & 
any[] &
TitleFile[][] &
SeasonalData[][] & 
BlankoProgressions[][] & 
Translation[][] &
TextLanguage[][] & 
RootState> {
  const migration = (yield select()).catalogMigration;
  if (!migration.source.catalogName) {
    throw new Error('Source catalog not set');
  }

  if (!migration.target.catalogName) {
    throw new Error('Target catalog not set');
  }

  const sourceEnv = migration.source.id;
  const targetEnv = migration.target.id;
  const [sourceCatalog, targetCatalog] = (yield all([
    call(CatalogMigrationService.getCatalog, sourceEnv, migration.source.catalogName),
    call(CatalogMigrationService.getCatalog, targetEnv, migration.target.catalogName)
  ])) as Catalog[]; // Typings with redux-sagas is garbage

  let sourceStores: Store[] | null = null;
  let targetStores: Store[] | null = null;

  if (migration.options.stores) {
    const sourceStoreIds = ((yield call(CatalogMigrationService.getStores, sourceEnv, sourceCatalog.name)) as Store[]).map(store => store.storeId);
    const targetStoreIds = ((yield call(CatalogMigrationService.getStores, targetEnv, targetCatalog.name)) as Store[]).map(store => store.storeId);

    sourceStores = [];
    for (const id of sourceStoreIds) {
      sourceStores.push((yield call(CatalogMigrationService.getStore, sourceEnv, sourceCatalog.name, id)) as Store);
    }

    targetStores = [];
    for (const id of targetStoreIds) {
      targetStores.push((yield call(CatalogMigrationService.getStore, targetEnv, targetCatalog.name, id)) as Store);
    }
  }

  let sourceTitleData: Title | null = null;
  let targetTitleData: Title | null = null;
  let sourceTitleFiles: TitleFile[] | null = null;
  let targetTitleFiles: TitleFile[] | null = null;
  let sourceBlankoDnas: any[] | null = null;
  let targetBlankoDnas: any[] | null = null;
  let sourceBlankoAssets: any[] | null = null;
  let targetBlankoAssets: any[] | null = null;
  let sourceSeasonalData: SeasonalData[] | undefined;
  let targetSeasonalData: SeasonalData[] | undefined;
  let sourceBlankoProgressions: BlankoProgressions[] | undefined;
  let targetBlankoProgressions: BlankoProgressions[] | undefined;
  let sourceTranslations: Translation[] | undefined;
  let targetTranslations: Translation[] | undefined;
  let sourceTextLanguages: TextLanguage[] | undefined;
  let targetTextLanguages: TextLanguage[] | undefined;

  if (sourceEnv !== targetEnv) {
    if (migration.options.titleDataClient || migration.options.titleDataServer) {
      [sourceTitleData, targetTitleData] = (yield all([
        call(CatalogMigrationService.getTitleData, sourceEnv),
        call(CatalogMigrationService.getTitleData, targetEnv),
      ])) as any[];
    }

    if (migration.options.titleDataFiles) {
      [sourceTitleFiles, targetTitleFiles] = (yield all([
        call(CatalogMigrationService.getTitleFiles, sourceEnv),
        call(CatalogMigrationService.getTitleFiles, targetEnv)
      ])) as any[];
    }

    // For now, load DNAs if we're migrating assets as well to support QA -> Prod migrations
    if (migration.options.blankoDnas || migration.options.blankoAssets) {
      [sourceBlankoDnas, targetBlankoDnas] = (yield all([
        call(CatalogMigrationService.getBlankoDnas, sourceEnv, sourceCatalog.name),
        call(CatalogMigrationService.getBlankoDnas, targetEnv, targetCatalog.name)
      ])) as any[];
    }

    if (migration.options.blankoAssets) {
      [sourceBlankoAssets, targetBlankoAssets] = (yield all([
        call(CatalogMigrationService.getBlankoAssets, sourceEnv, sourceCatalog.name),
        call(CatalogMigrationService.getBlankoAssets, targetEnv, targetCatalog.name)
      ])) as any[];

      if (sourceBlankoDnas && targetBlankoDnas && sourceBlankoDnas.length > 0 && targetBlankoDnas.length > 0 && sourceBlankoDnas[0].blankoAssets && !targetBlankoDnas[0].blankoAssets) {
        console.log('Incompatible source/target envs detected. Using source assets from source dnas');
        const dnaAssets: any[] = [];
        sourceBlankoDnas.forEach(dna => (dna.blankoAssets as any[]).forEach(asset => dnaAssets.push(asset)));
        _.uniqWith(dnaAssets, (a, b) => {
          return a.name === b.name &&
            a.platform === b.platform &&
            a.assetType === b.assetType &&
            a.minPlatformVersion === b.minPlatformVersion &&
            a.fileVersion === b.fileVersion;
        }).forEach(asset => sourceBlankoAssets?.push(asset));
      }
    }

    if (migration.options.seasonalData) {
      const seasonalData = [
        call(CatalogMigrationService.getSeasonalData, sourceEnv, sourceCatalog.name),
        call(CatalogMigrationService.getSeasonalData, targetEnv, targetCatalog.name),
      ];

      [sourceSeasonalData, targetSeasonalData] = (yield all(seasonalData)) as SeasonalData[][];
    }

    if (migration.options.blankoProgressions) {
      const blankoProgressions = [
        call(CatalogMigrationService.getBlankoProgressions, sourceEnv, sourceCatalog.name),
        call(CatalogMigrationService.getBlankoProgressions, targetEnv, targetCatalog.name),
      ];

      [sourceBlankoProgressions, targetBlankoProgressions] = (yield all(blankoProgressions)) as BlankoProgressions[][];
    }

    if (migration.options.translations) {
      const translations = [
        call(CatalogMigrationService.getTranslations, sourceEnv, sourceCatalog.name),
        call(CatalogMigrationService.getTranslations, targetEnv, targetCatalog.name),
      ];
      const textLanguages = [
        call(CatalogMigrationService.getTextLanguages, sourceEnv, sourceCatalog.name),
        call(CatalogMigrationService.getTextLanguages, targetEnv, targetCatalog.name),
      ];

      [sourceTranslations, targetTranslations] = (yield all(translations)) as Translation[][];
      [sourceTextLanguages, targetTextLanguages] = (yield all(textLanguages)) as TextLanguage[][];
    }
  }

  yield put(cmSetPreloadedData({
    env: 'source',
    catalog: sourceCatalog,
    stores: sourceStores,
    titleData: sourceTitleData,
    titleFiles: sourceTitleFiles,
    blankoDnas: sourceBlankoDnas,
    blankoAssets: sourceBlankoAssets,
    seasonalData: sourceSeasonalData,
    blankoProgressions: sourceBlankoProgressions,
    translations: sourceTranslations,
    textLanguages: sourceTextLanguages,
  }));

  yield put(cmSetPreloadedData({
    env: 'target',
    catalog: targetCatalog,
    stores: targetStores,
    titleData: targetTitleData,
    titleFiles: targetTitleFiles,
    blankoDnas: targetBlankoDnas,
    blankoAssets: targetBlankoAssets,
    seasonalData: targetSeasonalData,
    blankoProgressions: targetBlankoProgressions,
    translations: targetTranslations,
    textLanguages: targetTextLanguages,
  }));
}

function* runMigration() {
  const migration = ((yield select()) as RootState).catalogMigration;

  const steps: ({ name: string, sections: { (migration: CatalogMigrationState): Generator }[] })[] = [
    { name: 'Items', sections: [migrateItems] },
    { name: 'Stores', sections: [migrateStores] },
    { name: 'Title data', sections: [migrateTitleData] },
    { name: 'Title files', sections: [migrateTitleFiles] },
    { name: 'Blanko DNAs', sections: [migrateBlankoDnas] },
    { name: 'Blanko assets', sections: [migrateBlankoAssets] },
    { name: 'Blanko progressions', sections: [migrateBlankoProgressions, deleteBlankoProgressions] },
    { name: 'Seasonal data', sections: [migrateSeasonalData] },
    { name: 'Translations', sections: [migrateTranslations, deleteTextTranslations, deleteTextLanguages] },
  ];

  let stepCount = 0;
  for (const step of steps) {
    try {
      yield put(cmSetMigrationProgress({ message: step.name, progress: Math.floor(stepCount * 100 / steps.length) }));
      for (const section of step.sections) {
        yield call(section, migration);
      }
      stepCount++;
    } catch (e) {
      console.error(`Error in migration step: ${step.name}`, e);
      break;
    }
  }

  yield put(cmFinishMigration());
}

function mergeItems<T>(source: T[], target: T[], actions: MigrationActions, idMapper: (item: T) => string): T[] {
  if (!actions || actions.add.length + actions.remove.length + actions.update.length < 1) {
    return target;
  }

  const sourceMap: { [key: string]: T } = {};
  const targetMap: { [key: string]: T } = {};
  source.forEach(item => sourceMap[idMapper(item)] = item);
  target.forEach(item => targetMap[idMapper(item)] = item);

  const mergedItems: T[] = [];

  actions.add.forEach(itemId => mergedItems.push(sourceMap[itemId]));
  actions.update.forEach(itemId => {
    mergedItems.push(sourceMap[itemId])
    delete targetMap[itemId];
  });
  actions.remove.forEach(itemId => delete targetMap[itemId]);

  // At this point, targetMap should contain the items that should be preserved (weren't selected for update or remove)
  return mergedItems.concat(Object.values(targetMap));;
}

function* migrateItems(migration: CatalogMigrationState) {
  try {
    const sourceCatalog = migration.source.catalog.catalog;
    const targetCatalog = migration.target.catalog.catalog;
    if (!sourceCatalog || !sourceCatalog.items || !sourceCatalog.dropTables || !targetCatalog || !targetCatalog.items || !targetCatalog.dropTables) {
      throw new Error('Catalogs not loaded. Items or drop tables not found.');
    }

    const catalogItems = mergeItems(sourceCatalog.items, targetCatalog.items, migration.actions.items, item => item.itemId);
    const dropTables = mergeItems(sourceCatalog.dropTables, targetCatalog.dropTables, migration.actions.dropTables, table => table.id);

    // Nothing to update.
    if (catalogItems === targetCatalog.items && dropTables === targetCatalog.dropTables) {
      return;
    }

    const catalog = new Catalog({ name: targetCatalog.name, primaryCatalog: false });
    catalog.items = catalogItems;
    catalog.dropTables = dropTables;

    yield put(cmSetMigrationStepProgress({ message: 'Migrating catalog items', progress: 50 }));
    yield call(CatalogMigrationService.importCatalog, migration.target.id, catalog);
    yield put(cmSetMigrationStepResult({ id: 'items', result: {} }));
  } catch (e) {
    const error: string = (e && typeof e.message === 'string') ? e.message : 'An unknown error occurred';
    yield put(cmSetMigrationStepResult({ id: 'items', result: { error } }));
    throw e;
  }
}

function* migrateStores(migration: CatalogMigrationState) {
  try {
    const actions = migration.actions.stores;
    if (!actions || actions.add.length + actions.update.length + actions.remove.length < 1) {
      return;
    }

    const sourceCatalogName = migration.source.catalogName;
    const sourceStores = migration.source.stores;
    const targetCatalogName = migration.target.catalogName;
    const targetStores = migration.target.stores;

    if (!sourceCatalogName || !sourceStores || !targetCatalogName || !targetStores) {
      throw new Error('Stores not loaded.');
    }

    const isSameEnv = migration.source.id === migration.target.id;
    const images: StoreImageMigration[] = [];

    const sourceStoreMap: { [key: string]: Store } = {};
    sourceStores.forEach(store => sourceStoreMap[store.storeId] = store);

    let progress = 0;
    let progressPerStore = actions.add.length + actions.update.length + actions.remove.length;
    progressPerStore = (isSameEnv ? 100.0 : 50.0) / progressPerStore;

    for (const storeId of actions.add) {
      const store = sourceStoreMap[storeId];
      store.tabs.forEach(tab => {
        tab.items.forEach(item => {
          if (item.imageFilename && item.imageUrl) {
            images.push({ storeId, tabName: tab.name, item });
          }
        });
      });

      yield put(cmSetMigrationStepProgress({ message: `Migrating store: ${storeId}`, progress }));
      yield call(CatalogMigrationService.createStore, migration.target.id, targetCatalogName, store);
      progress += progressPerStore;
    }

    for (const storeId of actions.update) {
      const sourceStore = sourceStoreMap[storeId];
      const targetStore = targetStores.find(s => s.storeId === storeId);
      if (!targetStore) {
        continue;
      }

      sourceStore.tabs.forEach(sourceTab => {
        const targetTab = targetStore.tabs.find(t => t.name === sourceTab.name);
        sourceTab.items.forEach(sourceItem => {
          if (!sourceItem.imageFilename || !sourceItem.imageUrl) {
            return;
          }

          const targetItem = targetTab ? targetTab.items.find(i => i.itemId === sourceItem.itemId) : undefined;
          if (!targetItem || targetItem.imageFilename !== sourceItem.imageFilename) {
            images.push({ storeId, tabName: sourceTab.name, item: sourceItem });
          }
        });
      });

      yield put(cmSetMigrationStepProgress({ message: `Migrating store: ${storeId}`, progress }));
      yield call(CatalogMigrationService.createStore, migration.target.id, targetCatalogName, sourceStore);
      progress += progressPerStore;
    }

    for (const storeId of actions.remove) {
      yield put(cmSetMigrationStepProgress({ message: `Removing store: ${storeId}`, progress }));
      yield call(CatalogMigrationService.deleteStore, migration.target.id, targetCatalogName, storeId);
      progress += progressPerStore;
    }

    if (!isSameEnv && images.length > 0) {
      progress = 50.0;
      const progressPerImage = 50.0 / images.length;

      for (let i = 0; i < images.length; i++) {
        const image = images[i];
        yield put(cmSetMigrationStepProgress({ message: `Transferring image ${i + 1} of ${images.length}`, progress }));
        yield call(CatalogMigrationService.uploadStoreItemImage, migration.target.id, targetCatalogName, image.storeId, image.tabName, image.item);
        progress += progressPerImage;
      }
    }

    yield put(cmSetMigrationStepResult({ id: 'stores', result: {} }));
  } catch (e) {
    const error: string = (e && typeof e.message === 'string') ? e.message : 'An unknown error occurred';
    yield put(cmSetMigrationStepResult({ id: 'stores', result: { error } }));
    throw e;
  }
}

function mergeTitleData(source: { [key: string]: string }, target: { [key: string]: string }, actions: MigrationActions): { [key: string]: string | null } {
  if (!actions || actions.add.length + actions.remove.length + actions.update.length < 1) {
    return target;
  }

  const mergedMap: { [key: string]: string | null } = {};
  actions.add.concat(actions.update).forEach(id => mergedMap[id] = source[id]);
  actions.remove.forEach(id => mergedMap[id] = null);
  return mergedMap;
}

function* migrateTitleData(migration: CatalogMigrationState) {
  if (!migration.actions.titleDataClient && !migration.actions.titleDataServer) {
    return;
  }

  const sourceData = migration.source.titleData;
  const targetData = migration.target.titleData;
  if (!sourceData || !targetData) {
    throw new Error('Title data not loaded.');
  }

  const titleDataClient = mergeTitleData(sourceData.titleData, targetData.titleData, migration.actions.titleDataClient);
  if (titleDataClient !== targetData.titleData) {
    yield put(cmSetMigrationStepProgress({ message: 'Migrating client title data', progress: 0 }));
    try {
      yield call(CatalogMigrationService.updateClientTitleData, migration.target.id, titleDataClient);
      yield put(cmSetMigrationStepResult({ id: 'titleDataClient', result: {} }));
    } catch (e) {
      const error: string = (e && typeof e.message === 'string') ? e.message : 'An unknown error occurred';
      yield put(cmSetMigrationStepResult({ id: 'titleDataClient', result: { error } }));
      throw e;
    }
  }

  const titleDataServer = mergeTitleData(sourceData.internalData, targetData.internalData, migration.actions.titleDataServer);
  if (titleDataServer !== targetData.internalData) {
    yield put(cmSetMigrationStepProgress({ message: 'Migrating server title data', progress: 50 }));
    try {
      yield call(CatalogMigrationService.updateServerTitleData, migration.target.id, titleDataServer);
      yield put(cmSetMigrationStepResult({ id: 'titleDataServer', result: {} }));
    } catch (e) {
      const error: string = (e && typeof e.message === 'string') ? e.message : 'An unknown error occurred';
      yield put(cmSetMigrationStepResult({ id: 'titleDataServer', result: { error } }));
      throw e;
    }
  }
}

function* migrateTitleFiles(migration: CatalogMigrationState) {
  try {
    const actions = migration.actions.titleDataFiles;
    if (!actions || actions.add.length + actions.remove.length + actions.update.length < 1) {
      return;
    }

    const sourceFiles = migration.source.titleFiles;
    const targetFiles = migration.target.titleFiles;
    if (!sourceFiles || !targetFiles) {
      throw new Error('Title files not loaded.');
    }

    let progress = 0;
    const progressPerFile = 100.0 / (actions.add.length + actions.remove.length + actions.update.length);

    for (const filename of actions.add) {
      yield put(cmSetMigrationStepProgress({ message: `Transferring file: ${filename}`, progress }));
      const sourceFile: TitleFile = yield call(CatalogMigrationService.getTitleFile, migration.source.id, filename);
      const uploadUrl: string = yield call(CatalogMigrationService.createFile, migration.target.id, filename, sourceFile.version);
      yield call(CatalogMigrationService.transferTitleFile, sourceFile.fileUrl, uploadUrl);
      progress += progressPerFile;
    }

    for (const filename of actions.update) {
      yield put(cmSetMigrationStepProgress({ message: `Transferring file: ${filename}`, progress }));
      const sourceFile: TitleFile = yield call(CatalogMigrationService.getTitleFile, migration.source.id, filename);
      const uploadUrl: string = yield call(CatalogMigrationService.updateFile, migration.target.id, filename, sourceFile.version);
      yield call(CatalogMigrationService.transferTitleFile, sourceFile.fileUrl, uploadUrl);
      progress += progressPerFile;
    }

    for (const filename of actions.remove) {
      yield put(cmSetMigrationStepProgress({ message: `Removing file: ${filename}`, progress }));
      yield call(CatalogMigrationService.deleteFile, migration.target.id, filename);
      progress += progressPerFile;
    }

    yield put(cmSetMigrationStepResult({ id: 'titleDataFiles', result: {} }));
  } catch (e) {
    const error: string = (e && typeof e.message === 'string') ? e.message : 'An unknown error occurred';
    yield put(cmSetMigrationStepResult({ id: 'titleDataFiles', result: { error } }));
    throw e;
  }
}

function* migrateBlankoDnas(migration: CatalogMigrationState) {
  try {
    const actions = migration.actions.blankoDnas;
    if (!actions || actions.add.length + actions.remove.length + actions.update.length < 1) {
      return;
    }

    const sourceDnas = migration.source.blankoDnas;
    const targetDnas = migration.target.blankoDnas;
    const targetCatalogName = migration.target.catalogName;
    if (!sourceDnas || !targetDnas || !targetCatalogName) {
      throw new Error('Blanko DNAs not loaded.');
    }

    const dnaMap: { [key: string]: any } = {};
    sourceDnas.forEach(dna => dnaMap[`${dna.Id}, ver: ${dna.Version}`] = dna);

    const dnas = actions.add.concat(actions.update).map(id => dnaMap[id]);
    let progress = 0;
    for (const dna of dnas) {
      yield put(cmSetMigrationStepProgress({ message: 'Migrating blanko DNAs and Assets', progress }));
      yield call(CatalogMigrationService.migrateBlankoDnas, migration.target.id, targetCatalogName, [dna]);
      progress += 100.0 / dnas.length;
    }

    yield put(cmSetMigrationStepResult({ id: 'blankoDnas', result: {} }));
  } catch (e) {
    const error: string = (e && typeof e.message === 'string') ? e.message : 'An unknown error occurred';
    yield put(cmSetMigrationStepResult({ id: 'blankoDnas', result: { error } }));
    throw e;
  }
}

function* migrateBlankoAssets(migration: CatalogMigrationState) {
  try {
    const actions = migration.actions.blankoAssets;
    if (!actions || actions.add.length + actions.remove.length + actions.update.length < 1) {
      return;
    }

    const sourceAssets = migration.source.blankoAssets;
    const targetAssets = migration.target.blankoAssets;
    const targetCatalogName = migration.target.catalogName;
    if (!sourceAssets || !targetAssets || !targetCatalogName) {
      throw new Error('Blanko assets not loaded.');
    }

    const assetsMap: { [key: string]: any } = {};
    sourceAssets.forEach(asset => assetsMap[`${asset.name},${asset.platform},${asset.minPlatformVersion}`] = asset);

    const assets = actions.add.concat(actions.update).map(id => assetsMap[id]);
    const batches: any[][] = [];
    for (let i = 0; i < assets.length; i += 500) {
      batches.push(assets.slice(i, i + 500));
    }

    let progress = 0;
    for (const batch of batches) {
      yield put(cmSetMigrationStepProgress({ message: 'Migrating blanko assets', progress }));
      yield call(CatalogMigrationService.migrateBlankoAssets, migration.target.id, targetCatalogName, batch);
      progress += 100.0 / batches.length;
    }

    yield put(cmSetMigrationStepResult({ id: 'blankoAssets', result: {} }));
  } catch (e) {
    const error: string = (e && typeof e.message === 'string') ? e.message : 'An unknown error occurred';
    yield put(cmSetMigrationStepResult({ id: 'blankoAssets', result: { error } }));
    throw e;
  }
}

function* migrateBlankoProgressions(migration: CatalogMigrationState) {
  try {
    const actions = migration.actions.blankoProgressions;
    if (!actions || actions.add.length + actions.update.length < 1) {
      return;
    }

    const sourceProgressions = migration.source.blankoProgressions;
    const targetProgressions = migration.target.blankoProgressions;
    const targetCatalogName = migration.target.catalogName;

    if (!sourceProgressions || !targetProgressions || !targetCatalogName) {
      throw new Error('Blanko progressions not loaded.');
    }

    const ids = [...actions.add, ...actions.update];
    const toMigrate = sourceProgressions.filter(x => ids.some(y => y === x.id));

    yield put(cmSetMigrationStepProgress({ message: 'Migrating blanko progressions', progress: 0 }));
    yield call(CatalogMigrationService.migrateBlankoProgressions, migration.target.id, targetCatalogName, toMigrate);
    yield put(cmSetMigrationStepResult({ id: 'blankoProgressions', result: {} }));

  } catch (e) {
    const error: string = (e && typeof e.message === 'string') ? e.message : 'An unknown error occurred';
    yield put(cmSetMigrationStepResult({ id: 'blankoProgressions', result: { error } }));
    throw e;
  }
}

function* deleteBlankoProgressions(migration: CatalogMigrationState) {
  try {
    const actions = migration.actions.blankoProgressions;
    if (!actions || actions.remove.length < 1) {
      return;
    }

    const sourceProgressions = migration.source.blankoProgressions;
    const targetProgressions = migration.target.blankoProgressions;
    const targetCatalogName = migration.target.catalogName;

    if (!sourceProgressions || !targetProgressions || !targetCatalogName) {
      throw new Error('Blanko progressions not loaded.');
    }

    const toDelete = targetProgressions.filter(x => actions.remove.some(y => y === x.id));

    yield put(cmSetMigrationStepProgress({ message: 'Deleting blanko progressions', progress: 0 }));
    yield call(CatalogMigrationService.deleteBlankoProgressions, migration.target.id, targetCatalogName, toDelete);
    yield put(cmSetMigrationStepResult({ id: 'blankoProgressions', result: {} }));

  } catch (e) {
    const error: string = (e && typeof e.message === 'string') ? e.message : 'An unknown error occurred';
    yield put(cmSetMigrationStepResult({ id: 'blankoProgressions', result: { error } }));
    throw e;
  }
}

function* migrateSeasonalData(migration: CatalogMigrationState) {
  try {
    const actions = migration.actions.seasonalData;
    if (!actions || actions.add.length + actions.update.length < 1) {
      return;
    }

    const sourceSeasonalData = migration.source.seasonalData;
    const targetSeasonalData = migration.target.seasonalData;
    const targetCatalogName = migration.target.catalogName;

    if (!sourceSeasonalData || !targetSeasonalData || !targetCatalogName) {
      throw new Error('Seasonal data not loaded.');
    }

    const ids = [...actions.add, ...actions.update];
    const toMigrate = sourceSeasonalData.filter(x => ids.some(y => y === x.seasonNumber.toString()));

    yield put(cmSetMigrationStepProgress({ message: 'Migrating seasonal data', progress: 0 }));
    yield call(CatalogMigrationService.migrateSeasonalData, migration.target.id, targetCatalogName, toMigrate);
    yield put(cmSetMigrationStepResult({ id: 'seasonalData', result: {} }));

  } catch (e) {
    const error: string = (e && typeof e.message === 'string') ? e.message : 'An unknown error occurred';
    yield put(cmSetMigrationStepResult({ id: 'seasonalData', result: { error } }));
    throw e;
  }
}

function* migrateTranslations(migration: CatalogMigrationState) {
  try {
    const actions = migration.actions.translations;
    if (!actions || actions.add.length + actions.update.length < 1) {
      return;
    }

    const sourceTranslations = migration.source.translations;
    const targetTranslations = migration.target.translations;
    const targetCatalogName = migration.target.catalogName;

    if (!sourceTranslations || !targetTranslations || !targetCatalogName) {
      throw new Error('Translations not loaded.');
    }

    const ids = [...actions.add, ...actions.update];
    const toMigrate = sourceTranslations.filter(x => ids.some(y => y === `${x.key}.${x.languageCode}`));

    yield put(cmSetMigrationStepProgress({ message: 'Migrating translations', progress: 0 }));
    yield call(CatalogMigrationService.migrateTranslations, migration.target.id, targetCatalogName, toMigrate);
    yield put(cmSetMigrationStepResult({ id: 'translations', result: {} }));
    yield put(cmSetMigrationStepResult({ id: 'textLanguages', result: {} }));

  } catch (e) {
    const error: string = (e && typeof e.message === 'string') ? e.message : 'An unknown error occurred';
    yield put(cmSetMigrationStepResult({ id: 'translations', result: { error } }));
    throw e;
  }
}

function* deleteTextTranslations(migration: CatalogMigrationState) {
  try {
    const actions = migration.actions.translations;
    if (!actions || actions.remove.length < 1) {
      return;
    }

    const sourceTranslations = migration.source.translations;
    const targetTranslations = migration.target.translations;
    const targetCatalogName = migration.target.catalogName;

    if (!sourceTranslations || !targetTranslations || !targetCatalogName) {
      throw new Error('Translations not loaded.');
    }

    const toDelete = targetTranslations.filter(x => actions.remove.some(y => y === `${x.key}.${x.languageCode}`));

    yield put(cmSetMigrationStepProgress({ message: 'Deleting text translations', progress: 0 }));
    yield call(CatalogMigrationService.deleteTextTranslations, migration.target.id, targetCatalogName, toDelete);
    yield put(cmSetMigrationStepResult({ id: 'translations', result: {} }));

  } catch (e) {
    const error: string = (e && typeof e.message === 'string') ? e.message : 'An unknown error occurred';
    yield put(cmSetMigrationStepResult({ id: 'translations', result: { error } }));
    throw e;
  }
}

function* deleteTextLanguages(migration: CatalogMigrationState) {
  try {
    const actions = migration.actions.textLanguages;
    if (!actions || actions.remove.length < 1) {
      return;
    }

    const sourceTextLanguages = migration.source.textLanguages;
    const targetTextLanguages = migration.target.textLanguages;
    const targetCatalogName = migration.target.catalogName;

    if (!sourceTextLanguages || !targetTextLanguages || !targetCatalogName) {
      throw new Error('Text Languages not loaded.');
    }

    const toDelete = targetTextLanguages.filter(x => actions.remove.some(y => y === x.code));

    yield put(cmSetMigrationStepProgress({ message: 'Deleting text languages', progress: 0 }));
    yield call(CatalogMigrationService.deleteTextLanguages, migration.target.id, targetCatalogName, toDelete);
    yield put(cmSetMigrationStepResult({ id: 'textLanguages', result: {} }));

  } catch (e) {
    const error: string = (e && typeof e.message === 'string') ? e.message : 'An unknown error occurred';
    yield put(cmSetMigrationStepResult({ id: 'textLanguages', result: { error } }));
    throw e;
  }
}

export default function* catalogMigrationSagas() {
  yield forkSagas([
    function* () {
      yield takeEvery(cmGetCatalogsAsync.request, getCatalogsSaga);
    },
    function* () {
      yield takeEvery(cmPreloadData, preloadData);
    },
    function* () {
      yield takeEvery(cmStartMigration, runMigration);
    }
  ]);
}
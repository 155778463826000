import { JunctionObjectItemIdType, JunctionEventInfo, PotentialReward, JunctionObject, JunctionNpc, JunctionBeam } from "../live-events-types";
import { Box, Button, Card, CardContent, CardHeader } from "@material-ui/core";
import { Field, FieldArray, Formik, FormikHelpers } from "formik";
import { useCallback, useEffect, useMemo, useState } from "react";
import { TextField } from "formik-material-ui";
import RewardListEditor from "../../../shared/components/RewardListEditor";
import { JunctionBeamViewModel, JunctionNpcViewModel, JunctionObjectEditor, JunctionObjectViewModel, toBeam, toNpc, toObject } from "./JunctionObjectEditor";
import { createJunctionEventInfo, getJunctionEventInfo, updateJunctionEventInfo } from "../live-events-api";
import { usePushNotification } from "../../../contexts/AppNotificationContext";
import { RouteComponentProps } from "react-router";
import * as Yup from 'yup';
import { RewardType } from "../../../services/player-challenges/season-reward-path-tiers";
import PotentialRewardListEditor from "./PotentialRewardListEditor";


type Props = RouteComponentProps<{
  junctionEventInfoId?: string;
  liveEventId: string;
}>;

const defaultPotentialReward = (): PotentialReward => ({
  label: '',
  imagePath: '',
});

const defaultReward = (): Reward => ({
  type: '',
  param: {},
});

export interface JunctionEventInfoViewModel {
  id?: string;
  liveEventsId: string;
  enabled: boolean;
  name: string;
  createdAt?: string;
  updatedAt?: string;
  potentialRewards: PotentialReward[];
  rewards: Reward[];
  junctionObjects: JunctionObjectViewModel[];
  junctionNpcs: JunctionNpcViewModel[];
  junctionBeams: JunctionBeamViewModel[];
}

const junctionObjectValidation = () => ({
  type: Yup.string().oneOf(Object.values(JunctionObjectItemIdType)),
  catalogItemId: Yup.string()
    .when('type', {
      is: JunctionObjectItemIdType.CATALOG_ITEM_ID,
      then: Yup.string().required('Item ID is required')
    }),
  objectId: Yup.string()
    .when('type', {
      is: JunctionObjectItemIdType.OBJECT_ID,
      then: Yup.string().required('Object ID is required')
    }),
  positionX: Yup.number().typeError('Required').required(),
  positionY: Yup.number().typeError('Required').required(),
  positionZ: Yup.number().typeError('Required').required(),
  rotationX: Yup.number().typeError('Required').required(),
  rotationY: Yup.number().typeError('Required').required(),
  rotationZ: Yup.number().typeError('Required').required(),
});

const validationSchema = Yup.object().shape({
  name: Yup.string().required(),
  potentialRewards: Yup.array().of(Yup.object().shape({
    label: Yup.string().required(),
    imagePath: Yup.string().required(),
  })),
  rewards: Yup.array().of(Yup.object().shape({
    type: Yup.string().oneOf(Object.values(RewardType)),
    param: Yup.object().required()
  })),
  junctionObjects: Yup.array().of(Yup.object().shape(junctionObjectValidation())),
  junctionNpcs: Yup.array().of(Yup.object().shape({
    ...junctionObjectValidation(),
    communicationModalTitle: Yup.string().required(),
    communicationModalDescription: Yup.string().required(),
  })),
  junctionBeams: Yup.array().of(Yup.object().shape({
    ...junctionObjectValidation(),
    colorR: Yup.number()
      .integer('Must be an integer')
      .min(0, 'Must be 0..255')
      .max(255, 'Must be 0..255')
      .typeError('Required. Must be 0..255')
      .required('Required'),
    colorG: Yup.number()
      .integer('Must be an integer')
      .min(0, 'Must be 0..255')
      .max(255, 'Must be 0..255')
      .typeError('Required. Must be 0..255')
      .required('Required'),
    colorB: Yup.number()
      .integer('Must be an integer')
      .min(0, 'Must be 0..255')
      .max(255, 'Must be 0..255')
      .typeError('Required. Must be 0..255')
      .required('Required'),
    radius: Yup.number().typeError('Required').required('Required'),
  })),
});

const cleanUpJunctionInfo = (junctionObjects: JunctionObjectViewModel[]) => {
  junctionObjects.forEach(jobj => {
    if (jobj.type === JunctionObjectItemIdType.CATALOG_ITEM_ID && !jobj.objectId) {
      jobj.objectId = '';
    }
    if (jobj.type === JunctionObjectItemIdType.OBJECT_ID && !jobj.catalogItemId) {
      jobj.catalogItemId = '';
    }
    // the enabled flag was added later, so we need to make sure missing/nulls are enabled.
    if (jobj.enabled === undefined || jobj.enabled === null) {
      jobj.enabled = true;
    }
  });
};

const toObjectViewModels = (objects: JunctionObject[]): JunctionObjectViewModel[] => {
  const viewModels: JunctionObjectViewModel[] = [];
  objects.forEach(obj => {
    viewModels.push(new JunctionObjectViewModel(obj));
  });
  return viewModels;
};

const fromObjectViewModels = (viewModels: JunctionObjectViewModel[]): JunctionObject[] => {
  const objects: JunctionObject[] = [];
  viewModels.forEach(viewModel => {
    objects.push(toObject(viewModel));
  });
  return objects;
};

const toNpcViewModels = (npcs: JunctionNpc[]): JunctionNpcViewModel[] => {
  const viewModels: JunctionNpcViewModel[] = [];
  npcs.forEach(npc => {
    viewModels.push(new JunctionNpcViewModel(npc));
  });
  return viewModels;
};

const fromNpcViewModels = (viewModels: JunctionNpcViewModel[]): JunctionNpc[] => {
  const npcs: JunctionNpc[] = [];
  viewModels.forEach(viewModel => {
    npcs.push(toNpc(viewModel));
  });
  return npcs;
};

const toBeamViewModels = (beams: JunctionBeam[]): JunctionBeamViewModel[] => {
  const viewModels: JunctionBeamViewModel[] = [];
  beams.forEach(beam => {
    viewModels.push(new JunctionBeamViewModel(beam));
  });
  return viewModels;
};

const fromBeamViewModels = (viewModels: JunctionBeamViewModel[]): JunctionBeam[] => {
  const beams: JunctionBeam[] = [];
  viewModels.forEach(viewModel => {
    beams.push(toBeam(viewModel));
  });
  return beams;
};

const fromViewModel = (viewModel: JunctionEventInfoViewModel): JunctionEventInfo => {
  const info: JunctionEventInfo = {
    id: viewModel.id,
    liveEventsId: viewModel.liveEventsId,
    name: viewModel.name,
    enabled: viewModel.enabled,
    potentialRewards: viewModel.potentialRewards,
    rewards: viewModel.rewards,
    junctionObjects: fromObjectViewModels(viewModel.junctionObjects),
    junctionNpcs: fromNpcViewModels(viewModel.junctionNpcs),
    junctionBeams: fromBeamViewModels(viewModel.junctionBeams),
  };
  return info;
};

export const JunctionEventInfoEditor = ({ history,location, match }: Props) => {
  const pushNotification = usePushNotification();
  const { junctionEventInfoId, liveEventId } = match.params;
  const isEditMode = useMemo(() => !location.pathname.endsWith('/new') && junctionEventInfoId, [junctionEventInfoId, location]);

  const [eventToEdit, setEventToEdit] = useState<JunctionEventInfoViewModel>();

  useEffect(() => {
    if (!isEditMode || !junctionEventInfoId || eventToEdit)
      return;

    getJunctionEventInfo(liveEventId, junctionEventInfoId)
      .then((info: JunctionEventInfo) => {
        const viewModel = {
          id: info.id,
          liveEventsId: info.liveEventsId,
          name: info.name,
          enabled: info.enabled,
          potentialRewards: info.potentialRewards,
          rewards: info.rewards,
          junctionObjects: toObjectViewModels(info.junctionObjects),
          junctionNpcs: toNpcViewModels(info.junctionNpcs),
          junctionBeams: toBeamViewModels(info.junctionBeams),
        } as JunctionEventInfoViewModel;

        setEventToEdit(viewModel);
      })
      .catch((err: { message: any; }) => pushNotification({type: 'error', message: `Failed to load Junction Event: ${err.message}`}));

  }, [eventToEdit, isEditMode, liveEventId, junctionEventInfoId, pushNotification]);

  const initialValues = useMemo<JunctionEventInfoViewModel>(() => {
    if (eventToEdit) {
      const initialEvent = {
        ...eventToEdit,
        liveEventsId: liveEventId,
        createdAt: undefined,
        updatedAt: undefined,
      };

      cleanUpJunctionInfo(initialEvent.junctionObjects);
      cleanUpJunctionInfo(initialEvent.junctionNpcs);
      cleanUpJunctionInfo(initialEvent.junctionBeams);

      return initialEvent;
    }
    return {
      liveEventsId: liveEventId,
      name: '',
      enabled: true,
      potentialRewards: [],
      rewards: [],
      junctionObjects: [],
      junctionNpcs: [],
      junctionBeams: [],
    } as JunctionEventInfoViewModel;
  }, [eventToEdit, liveEventId]);

  const onSubmit = useCallback((viewModel: JunctionEventInfoViewModel, formikHelpers: FormikHelpers<JunctionEventInfoViewModel>) => {
    const info = fromViewModel(viewModel);
    if (isEditMode) {
      return updateJunctionEventInfo(liveEventId, info)
        .then(info => {
          pushNotification({ type: 'success', message: 'Updated junction event' });
        })
        .catch(err => pushNotification({type: 'error', message: `Failed to update junction event: ${err.message}`}));
    }
    return createJunctionEventInfo(liveEventId, info)
      .then(info => {
        history.replace(`/live-events/${liveEventId}/junctions/${info.id}`);
        pushNotification({type: 'success', message: 'Created junction event'});
      })
      .catch(err => pushNotification({type: 'error', message: `Failed to create junction event: ${err.message}`}));
  }, [history, isEditMode, liveEventId, pushNotification]);

  return <Card>
    <Formik<JunctionEventInfoViewModel>
      initialValues={initialValues}
      enableReinitialize
      onSubmit={onSubmit}
      validationSchema={validationSchema}
    >
      {formikData => (<form onSubmit={formikData.handleSubmit}>
        <CardHeader title={`${isEditMode ? 'Edit' : 'Create'} Junction Event`}/>
        <CardContent>
          <Box pb={1} pt={1}>
            <Field
              component={TextField}
              type="text"
              name="name"
              fullWidth
              label="Name"
            />
          </Box>
        </CardContent>
        <CardContent>
          <FieldArray name='potentialRewards'>
            {(arrayHelpers) => (<>
              <PotentialRewardListEditor
                title='Potential Rewards'
                editMode
                showAdd
                potentialRewards={formikData.values.potentialRewards}
                onAdd={() => arrayHelpers.push(defaultPotentialReward())}
                onDelete={(potentialReward) => arrayHelpers.remove(formikData.values.potentialRewards.indexOf(potentialReward))}
              />
            </>)}
          </FieldArray>
        </CardContent>
        <CardContent>
          <FieldArray name='rewards'>
            {(arrayHelpers) => (<>
              <RewardListEditor
                title={'Rewards'}
                editMode
                showAddReward
                rewards={formikData.values.rewards}
                onRewardAdd={() => arrayHelpers.push(defaultReward())}
                onRewardDelete={(reward) => arrayHelpers.remove(formikData.values.rewards.indexOf(reward))}
                requiredUpdateTarget='liveEvents'
              />
            </>)}
          </FieldArray>
        </CardContent>
        <CardContent>
          <JunctionObjectEditor
            field='junctionObjects'
            formikData={formikData}
          />
        </CardContent>
        <CardContent>
          <JunctionObjectEditor
            field='junctionNpcs'
            formikData={formikData}
          />
        </CardContent>
        <CardContent>
          <JunctionObjectEditor
            field='junctionBeams'
            formikData={formikData}
          />
        </CardContent>
        <CardContent>
          <Button type="submit" variant="contained" color="primary">
            Save
          </Button>
          &nbsp;
          <Button type="button" variant="outlined" color="secondary" onClick={() => history.replace(`/live-events/edit/${liveEventId}`)}>
            Cancel
          </Button>
        </CardContent>
      </form>)}
    </Formik>
  </Card>;
};

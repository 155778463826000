import { Box, CircularProgress, Typography } from '@material-ui/core';

import { PublishedBlockForm } from '../components/PublishedBlockForm';
import { BlocksService } from '../../../services/blocks';
import type { PublishedBlock } from '../../../services/blocks';
import { useEffect, useCallback, useState } from 'react';
import { useParams } from 'react-router';

export const PublishedBlockPage = () => {

  const { publishedBlockId } = useParams<{ publishedBlockId: string }>();
  const [publishedBlock, setPublishedBlock] = useState<PublishedBlock>();
  const [tags, setTags] = useState<string[]>();

  const getData = useCallback(async () => {
    const tagsResult = await BlocksService.getBlockTagsAutoComplete();
    setTags(tagsResult.tags);

    const blockResult = await BlocksService.getPublishedBlock(publishedBlockId);
    setPublishedBlock(blockResult);
  }, [publishedBlockId]);

  const onBlockTagsSave = useCallback((id: string, tags: string[]) => {
    BlocksService.savePublishedBlockTags(id, tags);
  }, []);

  useEffect(() => {
    getData();
  }, [getData]);

  return (<>
    {(!publishedBlock || !tags &&
      <Box textAlign="center">
        <CircularProgress />
      </Box>
    )}
    {(publishedBlock && tags &&
      <Box mb={2}>
        <Typography variant="h6">
          Published block: {publishedBlock.name}
        </Typography>
      </Box>
    )}
    {(publishedBlock && tags &&
      <PublishedBlockForm 
        publishedBlock={publishedBlock}
        tags={tags} 
        onTagsSave={onBlockTagsSave}
        onSave={() =>
        // eslint-disable-next-line @typescript-eslint/no-empty-function
          { }
        }
      />
    )}
  </>);
}
import type { ApiError} from "./api";
import {api} from "./api";
import type {PaginatedRequest} from "./model/pagination";
import { PaginatedResponse} from "./model/pagination";

export class Transaction {
  id = '';
  relatedTransactionId = '';
  playerId = '';
  iviTransactionId = '';
  transactionCode = '';
  dateCreated: Date = new Date();
  amount = 0;
  currencyCode = '';
  comment = '';
  promoCode = '';
  items: TransactionItem[] = [];
  currencies: TransactionCurrency[] = []
}

export interface TransactionAggregate {
  id: string;
  relatedTransactionId: string;
  playerId: string;
  iviTransactionId: string;
  transactionCode: string;
  dateCreated: Date;
  amount: number;
  currencyCode: string;
  comment: string;
  promoCode: string;

  playerName: string;
  playerExternalId: string;
  transactionRefunded: boolean;
  relatedComment: string;
  purchaseSummary: string;
  currencySummary: string;
}

export interface TransactionItem {
  id: string;
  transactionId: string;
  itemInstanceId: string;
  itemId: string;
  itemDisplayName: string;
  itemClass: string;
}

export interface TransactionCurrency {
  transactionId: string;
  currencyCode: string;
  amount: number;
}

export class IssueRefundRequest {
  transactionId = '';
  comment = '';
}

export interface IssueRefundResponse {
  message: string;
}

export interface TransactionLast30DaysRequest {
  playerId?: string;
}

export class TransactionService {
  static async getAllTransactions(request: PaginatedRequest): Promise<PaginatedResponse<TransactionAggregate>> {
    return api.get<TransactionAggregate[]>({ url: `/commerce/transactions/page`, query: request })
      .then(transactions => {
        return new PaginatedResponse(transactions, v => v);
      })
      .catch((e: ApiError) => {
        e.message = `Failed to get all transactions. ${e.message}`;
        throw e;
      });
  }

  static async getTransactionItems(request: PaginatedRequest): Promise<PaginatedResponse<TransactionItem>> {
    return api.get<TransactionItem[]>({ url: `/commerce/transactions/${request.transactionId}/items/page`, query: request })
      .then(items => {
        return new PaginatedResponse(items, v => v);
      })
      .catch((e: ApiError) => {
        e.message = `Failed to get all transaction items. ${e.message}`;
        throw e;
      });
  }

  static async getAllTransactionsLast30Days(request: TransactionLast30DaysRequest): Promise<TransactionAggregate[]> {
    return api.get<TransactionAggregate[]>({ url: `/commerce/transactions/export`, query: request })
      .catch((e: ApiError) => {
        e.message = `Failed to get all transactions last 30 days. ${e.message}`;
        throw e;
      });
  }

  static async refundTransaction(request: IssueRefundRequest): Promise<IssueRefundResponse> {
    return api.post<IssueRefundResponse>({ url: `/commerce/refund`, body: request })
      .catch((e: ApiError) => {
        e.message = `Failed to refund transaction. ${e.message}`;
        throw e;
      });
  }
}
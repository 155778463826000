import type {Theme} from '@material-ui/core';
import {
  Box,
  Button,
  createStyles,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  makeStyles} from '@material-ui/core';
import {Field, Formik} from 'formik';
import {TextField} from 'formik-material-ui';
import * as Yup from 'yup';
import {useCallback, useMemo} from 'react';
import { Season, SeasonsService } from '../../../services/player-challenges/seasons';
import { RewardPath, SeasonRewardPathService } from '../../../services/player-challenges/season-reward-paths';
import { ChallengeSchedule, ChallengeSchedulesService } from '../../../services/player-challenges/season-challenge-schedule';
import { generateUUID } from '../../../utils/uuid';
import { usePushNotification } from '../../../contexts/AppNotificationContext';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    form: {
      display: 'flex',
      flexDirection: 'column',
      margin: 'auto',
      width: 'fit-content',
      marginBottom: 8,
    },
    formControl: {
      marginTop: theme.spacing(2),
      minWidth: 400,
    },
  }),
);

const validationSchema = Yup.object().shape({
  season_number: Yup.string().required('Required'),
  season_name: Yup.string().required('Required'),
});

interface AddSeasonDialogProps {
  liveEventId: string,
  onAdd: () => void,
  onCancel: () => void,
}

export const AddSeasonDialog = ({ liveEventId, onAdd, onCancel }: AddSeasonDialogProps) => {
  const pushNotification = usePushNotification();
  const classes = useStyles();

  const initialSeason = useMemo(() => {
    const season = new Season();
    season.live_events_id = liveEventId;
    season.season_number = 0;
    return season;
  }, [liveEventId]);

  const onSubmit = useCallback(async (values: Partial<Season>) => {
    const season = initialSeason.clone();
    season.season_name = values["season_name"] as string;
    season.season_number = values["season_number"] as number;
    try {
      await SeasonsService.createSeason(liveEventId, season.toJson());

      try {
        // Creating Reward Path with generated values, we currently are only supporting 1 path and removal and recreation is treated as a new season.
        const rewardPath = new RewardPath();
        rewardPath.path_id = `${season.season_name.replaceAll(' ', '_').toUpperCase()}_PATH`;
        rewardPath.path_name = `${season.season_name  } Path`;
        await SeasonRewardPathService.createSeasonRewardPath(season.season_number, rewardPath.toJson());

        try {
          // Creating Challenge Schedule with generated values, we currently are only supporting 1 schedule and UI has no place for this.
          const challengeSchedule = new ChallengeSchedule();
          challengeSchedule.schedule_id = generateUUID();
          challengeSchedule.schedule_name = `${season.season_name  } Schedule`;
          await ChallengeSchedulesService.createSeasonChallengeSchedule(season.season_number, challengeSchedule.toJson());

          onAdd();
        } catch (e) {
          // challenge schedule error
          pushNotification({type: 'error', message: `${e}`})
        }
      } catch (e) {
        // reward path error
        pushNotification({type: 'error', message: `${e}`})
      }
    } catch (e) {
      // season error
      pushNotification({type: 'error', message: `${e}`})
    }
  }, [initialSeason, liveEventId, onAdd, pushNotification]);

  return (
    <>
      <Dialog
        open={true}
        fullWidth
        onClose={onCancel}
        aria-labelledby="add-dialog-title"
      >
        <Formik<Season>
          initialValues={initialSeason}
          validationSchema={validationSchema}
          validateOnMount={true}
          onSubmit={onSubmit}
        >
          {formikData => (<form onSubmit={formikData.handleSubmit}>
            <DialogTitle id="add-dialog-title">Add Season</DialogTitle>
            <DialogContent className={classes.form}>
              <Box display="flex" flexDirection="column" justifyContent="center" className={classes.formControl}>
                <Field
                  component={TextField}
                  label={"Season Number"}
                  name="season_number"
                  type="number"
                  id="season_number"
                  inputProps={{
                      step: 1,
                      min: 0,
                  }}
                />
              </Box>
              <Box display="flex" flexDirection="column" justifyContent="center" className={classes.formControl}>
                <Field
                  component={TextField}
                  label={"Season Name"}
                  name="season_name"
                  type="text"
                  id="season_name"
                />
              </Box>
            </DialogContent>
            <DialogActions>
              <Button
                type="submit"
                variant="contained"
                disabled={!formikData.isValid}
                color="primary"
              >
                Add
              </Button>
              <Button onClick={onCancel} color="primary">
                Cancel
              </Button>
            </DialogActions>
          </form>)}
        </Formik>
      </Dialog>
    </>
  );
}

import { Box, FormGroup, Typography } from '@material-ui/core';
import { EnvironmentSelectorContainer } from './EnvironmentSelectorContainer';
import { CheckboxMultiContainer } from './CheckboxMultiContainer';
import { OptionSingleCheckboxContainer } from './OptionSingleCheckboxContainer';

interface OptionsProps {
  sourceEnvironment: string;
  targetEnvironment: string;
  options: CatalogMigrationOptions
  sourceCatalogName?: string;
}

export const Options = ({
  sourceEnvironment,
  targetEnvironment,
  options,
  sourceCatalogName
}: OptionsProps) => (<>
  <Box my={2}>
    Migrate from
  </Box>
  <EnvironmentSelectorContainer
    environmentSource="source"
  />
  <Box my={2}>to</Box>
  <EnvironmentSelectorContainer
    environmentSource="target"
    environmentSelectorEnabled
    excludeCatalog={sourceEnvironment === targetEnvironment ? sourceCatalogName : undefined}
  />

  <Box mt={4}>
    <Typography>Options</Typography>
    {sourceEnvironment === targetEnvironment && (
      <Typography variant="subtitle2" color="textSecondary">
        Some options are disabled when migrating within the same environment.
      </Typography>
    )}

    <CheckboxMultiContainer
      label="Catalog items"
      values={[options.items, options.bundles, options.containers, options.dropTables]}
      onClickCallback={value => ({ items: value, bundles: value, containers: value, dropTables: value })}
    />

    <Box ml={4}>
      <FormGroup row>
        <OptionSingleCheckboxContainer
          label={"Items"}
          checked={options.items}
          payload={{ items: !options.items }}
        />
        <OptionSingleCheckboxContainer
          label={"Bundles"}
          checked={options.bundles}
          payload={{ bundles: !options.bundles }}
        />
        <OptionSingleCheckboxContainer
          label={"Containers"}
          checked={options.containers}
          payload={{ containers: !options.containers }}
        />
        <OptionSingleCheckboxContainer
          label={"Drop tables"}
          checked={options.dropTables}
          payload={{ dropTables: !options.dropTables }}
        />
      </FormGroup>
    </Box>

    <Box>
      <OptionSingleCheckboxContainer label={"Stores"} checked={options.stores} payload={{ stores: !options.stores }} />
    </Box>

    <CheckboxMultiContainer
      label="Title data"
      values={sourceEnvironment === targetEnvironment ? [false] : [options.titleDataClient, options.titleDataServer, options.titleDataFiles]}
      onClickCallback={value => ({ titleDataClient: value, titleDataServer: value, titleDataFiles: value })}
      disabled={sourceEnvironment === targetEnvironment}
    />

    <Box ml={4}>
      <FormGroup row>
        <OptionSingleCheckboxContainer
          label={"Client"}
          checked={sourceEnvironment !== targetEnvironment && options.titleDataClient}
          disabled={sourceEnvironment === targetEnvironment}
          payload={{ titleDataClient: !options.titleDataClient }}
        />
        <OptionSingleCheckboxContainer
          label={"Server"}
          checked={sourceEnvironment !== targetEnvironment && options.titleDataServer}
          disabled={sourceEnvironment === targetEnvironment}
          payload={{ titleDataServer: !options.titleDataServer }}
        />
        <OptionSingleCheckboxContainer
          label={"Files"}
          checked={sourceEnvironment !== targetEnvironment && options.titleDataFiles}
          disabled={sourceEnvironment === targetEnvironment}
          payload={{ titleDataFiles: !options.titleDataFiles }}
        />
      </FormGroup>
    </Box>

    <Box>
      <OptionSingleCheckboxContainer
        label={"Blanko DNAs and Assets"}
        checked={options.blankoDnas}
        disabled={sourceEnvironment === targetEnvironment}
        payload={{ blankoDnas: !options.blankoDnas }}
      />
    </Box>

    <Box>
      <OptionSingleCheckboxContainer
        label={"Global Assets"}
        checked={options.blankoAssets}
        disabled={sourceEnvironment === targetEnvironment}
        payload={{ blankoAssets: !options.blankoAssets }}
      />
    </Box>

    <Box>
      <OptionSingleCheckboxContainer
        label={"Seasonal data"}
        checked={options.seasonalData}
        disabled={sourceEnvironment === targetEnvironment}
        payload={{ seasonalData: !options.seasonalData }}
      />
    </Box>

    <Box>
      <OptionSingleCheckboxContainer
        label={"Blanko progressions"}
        checked={options.blankoProgressions}
        disabled={sourceEnvironment === targetEnvironment}
        payload={{ blankoProgressions: !options.blankoProgressions }}
      />
    </Box>

    <Box>
      <OptionSingleCheckboxContainer
        label={"Translations"}
        checked={options.translations}
        disabled={sourceEnvironment === targetEnvironment}
        payload={{ translations: !options.translations }}
      />
    </Box>
  </Box>
</>);

import { Component } from 'react';
import type { RootState } from '../../redux/reducers';
import type { ConnectedProps } from 'react-redux';
import { connect } from 'react-redux';
import { Box, CircularProgress, Grid , Button, Card, CardContent, CardHeader } from '@material-ui/core';


import type { Season } from '../../services/player-challenges/seasons';
import { getSeasonAsync, updateSeasonAsync } from '../../redux/player-challenges/seasons/actions';
import { UserService } from '../../services/user';
import { SeasonControl } from './SeasonControl';
import { loadCurrenciesAsync } from '../../redux/currencies/actions';
import SeasonTokenCosts from './SeasonTokenCosts';

const mapStateToProps = (state: RootState) => ({
  seasons: state.seasons,
  currencyList: state.currencies.list
});

interface ComponentProps {
  liveEventsId: string,
  seasonNumber: number
}

const mapDispatch = {
  requestGetSeason: getSeasonAsync.request,
  requestUpdateSeason: updateSeasonAsync.request,
  requestLoadCurrencies: loadCurrenciesAsync.request
};

const connector = connect(mapStateToProps, mapDispatch);

type Props = ComponentProps & ConnectedProps<typeof connector>;

interface State {
  season?: Season;
}

class SeasonInfo extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      season: undefined
    }
  }

  componentDidMount() {
    this.props.requestLoadCurrencies();

    const index = this.props.seasons.data.findIndex(season => season.season_number === this.props.seasonNumber);
    if (index < 0) {
      this.props.requestGetSeason({liveEventsId: this.props.liveEventsId, seasonNumber: this.props.seasonNumber});
    } else {
      this.setState({ season: this.props.seasons.data[index] });
    }
  }

  static getDerivedStateFromProps(props: Props, state: State) {
    if (state.season) return null;

    const index = props.seasons.data.findIndex((season: Season) => season.season_number === props.seasonNumber);
    if (index >= 0) {
      return ({ season: props.seasons.data[index] });
    }
    return null;
  }

  onSave(season: Season) {
    this.props.requestUpdateSeason({ liveEventsId: this.props.liveEventsId, seasonNumber: this.props.seasonNumber, season: season });
  }

  render() {
    const season = this.state.season;

    if (this.props.seasons.isLoading || !season) {
      return (
        <Box textAlign="center">
          <CircularProgress />
        </Box>
      );
    }

    const seasonClone = season.clone();

    return (<>
      <Grid container spacing={3}>
        <Grid item xs={12} md={6} xl={4}>
          <Card>
            <CardHeader title="Season Info" />
            <CardContent>
              <SeasonControl season={seasonClone} setSeason={season => this.setState({ season })} />
              {UserService.canUpdate('seasons') && (
                <Box mt={2} mb={2}>
                  <Button variant="contained" color="primary" onClick={() => this.onSave(seasonClone)}>
                    Save
                  </Button>
                </Box>
              )}
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} xl={8}>
          <SeasonTokenCosts
            season={seasonClone}
            currencyList={this.props.currencyList}
            onSave={season => this.onSave(season)}
          />
        </Grid>
      </Grid>
    </>);
  }
}

export default connector(SeasonInfo);
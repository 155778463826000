import { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { cmSetMigrationActions } from '../../../../redux/catalog-migration/actions';
import type { MigrationActions } from '../../../../redux/catalog-migration/reducer';
import { selectCatalog } from '../../../../redux/catalog-migration/selectors';
import { useTypedSelector } from '../../../../redux/reducers';
import type { DropTable } from '../../../../services/drop-tables';
import { DropTableDiff } from '../../types';
import { DropTables } from './DropTables';

export const DropTablesContainer = () => {
  const dispatch = useDispatch();

  const sourceCatalog = useTypedSelector(state => selectCatalog(state, 'source')).catalog;
  const targetCatalog = useTypedSelector(state => selectCatalog(state, 'target')).catalog;
  const actions = useTypedSelector(state => state.catalogMigration.actions.dropTables);

  const [sourceItems, setSourceItems] = useState<DropTableDiff[] | null>(null);
  const [targetItems, setTargetItems] = useState<DropTableDiff[] | null>(null);

  const onSelectionChange = useCallback(
    (actions: MigrationActions) => dispatch(cmSetMigrationActions({ id: 'dropTables', actions })),
    [dispatch]
  );

  useEffect(() => {
    if (sourceCatalog && sourceCatalog.dropTables && targetCatalog && targetCatalog.dropTables) {
      const mapDropTables = (dropTables: DropTable[]) => {
        return dropTables.map(dropTable => new DropTableDiff(dropTable));
      }

      setSourceItems(mapDropTables(sourceCatalog.dropTables));
      setTargetItems(mapDropTables(targetCatalog.dropTables));
    }
  }, [sourceCatalog, targetCatalog]);

  return (<DropTables
    sourceItems={sourceItems}
    targetItems={targetItems}
    actions={actions}
    onSelectionChange={onSelectionChange} />);
}
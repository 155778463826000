import type { ApiError } from '../api';
import { api } from '../api';
import { ChallengeTemplate } from "./challenge-templates";
import { ItemInstance } from "../model/item-instance";

export class PlayerPath {
  currentPathId = '';
  currentTierId = '';
  currentTierOrder = 0;
  currentTokenCount = 0;
  partyPassPlus = false;
  unclaimedTierIds: string[] = [];
  claimedTierIds: string[] = [];

  constructor(json?: any) {
    if (json) {
      this.currentPathId = json.currentPathId;
      this.currentTierId = json.currentTierId;
      this.currentTierOrder = json.currentTierOrder;
      this.currentTokenCount = json.currentTokenCount;
      this.partyPassPlus = json.partyPassPlus;
      this.unclaimedTierIds = json.unclaimedTierIds;
      this.claimedTierIds = json.claimedTierIds;
    }
  }
}

export enum CalendarTypeEnum {
  UNKNOWN = "UNKNOWN",
  DAILY = "DAILY",
  WEEKLY = "WEEKLY",
  SEASONAL = "SEASONAL"
}

export class ChallengeHistory {
  scheduleId = '';
  calendarType: CalendarTypeEnum = CalendarTypeEnum.UNKNOWN;
  calendarNumber?: number;
  challengeId = '';
  instanceId = '';
  itemsGranted?: ItemInstance[];
  currencyGranted?: Record<string, number>;
  seasonalTokensGranted?: number;
  completedAt = '';

  constructor(json?: any) {
    if (json) {
      this.scheduleId = json.scheduleId;
      this.calendarType = json.calendarType;
      this.calendarNumber = json.calendarNumber;
      this.challengeId = json.challengeId;
      this.instanceId = json.instanceId;
      this.itemsGranted = (json.itemsGranted || []).map((i: any) => new ItemInstance(i));
      this.currencyGranted = json.currencyGranted;
      this.seasonalTokensGranted = json.seasonalTokensGranted;
      this.completedAt = json.completedAt;
    }
  }
}

export class RewardPathTierClaimHistory {
  pathId = '';
  tierId = '';
  itemsGranted?: ItemInstance[];
  currencyGranted?: Record<string, number>;
  seasonalTokensGranted?: number;
  completedAt = '';

  constructor(json?: any) {
    if (json) {
      this.pathId = json.pathId;
      this.tierId = json.tierId;
      this.itemsGranted = (json.itemsGranted || []).map((i: any) => new ItemInstance(i));
      this.currencyGranted = json.currencyGranted;
      this.seasonalTokensGranted = json.seasonalTokensGranted;
      this.completedAt = json.completedAt;
    }
  }
}

export class PlayerSeasonPathResponse {
  seasonNumber = -1;
  path: PlayerPath = new PlayerPath();
  challengeHistory: ChallengeHistory[] = [];
  rewardPathTierClaimHistory: RewardPathTierClaimHistory[] = [];
  challengeMap: Record<string, Challenge> = {};
  pathTierMap: Record<string, RewardPathTier> = {};
  challengeTemplate: ChallengeTemplate = new ChallengeTemplate();

  constructor(json?: any) {
    if (json) {
      this.seasonNumber = json.seasonNumber;
      this.path = new PlayerPath(json.path);
      this.challengeHistory = (json.challengeHistory || []).map((i: any) => new ChallengeHistory(i));
      this.rewardPathTierClaimHistory = (json.rewardPathTierClaimHistory || []).map((i: any) => new RewardPathTierClaimHistory(i));
      for (const challenge in json.challengeMap) {
        Object.assign(this.challengeMap, { [challenge]: json.challengeMap[challenge] });
      }
      for (const pathTier in json.pathTierMap) {
        Object.assign(this.pathTierMap, { [pathTier]: json.pathTierMap[pathTier] });
        this.pathTierMap[pathTier].tierName = json.pathTierMap[pathTier].name;
      }
      this.challengeTemplate = new ChallengeTemplate(json.challengeTemplate);
    }
  }
}

export interface PlayerSeasonPathSaveRequest {
  seasonNumber: number;
  pathId: string;
  partyPassPlus: boolean;
}


export class PlayerPathService {

  static async getPlayerPath(externalId: string): Promise<PlayerSeasonPathResponse> {
    return api.get({ url: `/players/${externalId}/player-path` })
      .then(playerSeasonPathResponse => new PlayerSeasonPathResponse(playerSeasonPathResponse))
      .catch((e: ApiError) => {
        e.message = `Failed to get player path response. ${e.message}`;
        throw e;
      });
  }

  static async getPlayerPathWithSeasonNumber(externalId: string, seasonNumber: number): Promise<PlayerSeasonPathResponse> {
    return api.get({ url: `/players/${externalId}/player-path?seasonNumber=${seasonNumber}` })
      .then(playerSeasonPathResponse => new PlayerSeasonPathResponse(playerSeasonPathResponse))
      .catch((e: ApiError) => {
        e.message = `Failed to get player path response. ${e.message}`;
        throw e;
      });
  }

  static async savePlayerPath(externalId: string, data: PlayerSeasonPathSaveRequest): Promise<undefined> {
    return api.post({ url: `/players/${externalId}/player-path`, body: data })
      .catch((e: ApiError) => {
        e.message = `Failed to save player path data. ${e.message}`
        throw e;
      })
  }

  static async deleteChallengeHistory(externalId: string, challengeInstanceId: string) : Promise<undefined> {
    return api.post({url: `/players/${externalId}/reset-challenge/${challengeInstanceId}`})
      .catch((e: ApiError) => {
        e.message = `Failed to delete challenge history. ${e.message}`
        throw e;
      });
  }
}

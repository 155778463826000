import type { FormikProps } from 'formik'
import type { Theme} from '@material-ui/core';
import { TextField, FormHelperText, makeStyles, createStyles } from '@material-ui/core'
import type { BurnGumballsParams } from '../blankoGemRush';

interface BurnCurrencyFormProps {
  index: number
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formControl: {
      marginTop: theme.spacing(2),
      minWidth: 400,
    },
  }),
);
export const GumballsForm = ({ errors, touched, handleChange, handleBlur, values, index }: FormikProps<BurnGumballsParams> & BurnCurrencyFormProps) => {
  const classes = useStyles()
  return <>
    <TextField
      id={`capstone.${index}.param.amount`}
      name={`capstone.${index}.param.amount`}
      type="number"
      label="Amount"
      className={classes.formControl}
      onChange={handleChange}
      onBlur={handleBlur}
      value={values.amount}
      error={
        touched?.amount &&
            Boolean(errors?.amount)
      }
    />
    <FormHelperText>
      {(touched?.amount && errors?.amount)}
    </FormHelperText>
  </>
}

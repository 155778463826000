import type { LinkTabConfig} from '../../../components/LinkTabs';
import { LinkTabs } from '../../../components/LinkTabs';
import { Blocks } from '../Blocks';
import { PublishedBlocks } from '../PublishedBlocks';
import { ReportedBlocks } from '../ReportedBlocks';
import { UserService } from '../../../services/user';
import { RecommendationSettings } from '../components/RecommendationSettings';
import { useHistory, useLocation, useRouteMatch } from 'react-router';

export const BlocksPage = () => {
  const tabs: LinkTabConfig[] = [];
  if (UserService.canRead('blocks')) {
    // eslint-disable-next-line react/display-name
    tabs.push({ url: '/blocks', label: 'Blocks', absolute: true, render: () => <Blocks /> });
  }
  if (UserService.canRead('publishedBlocks')) {
    tabs.push(
      { url: '/published-blocks', label: 'Published', absolute: true, component: PublishedBlocks },
      { url: '/recommendation-settings', label: 'Recommendations', absolute: true, component: RecommendationSettings }
    );
  }
  if (UserService.canRead('blocks')) {
    tabs.push({ url: '/reported-blocks', label: 'Reported', absolute: true, component: ReportedBlocks });
  }

  const history = useHistory();
  const location = useLocation();
  const match = useRouteMatch();

  return (<>
    <LinkTabs tabs={tabs} history={history} location={location} match={match} />
  </>);
};
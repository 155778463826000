import type {ItemToken} from "../iviConfig";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Link from "@material-ui/core/Link";
import {Link as RouterLink} from "react-router-dom";
import {CircularProgress, Tooltip} from "@material-ui/core";
import {AcUnit} from "@material-ui/icons";
import {lightBlue} from "@material-ui/core/colors";
import * as React from "react";
import {useCallback, useEffect, useState} from "react";
import type {Column} from "material-table";
import {useHistory} from "react-router";
import {getItemTokens} from "../iviConfigApi";
import type {ApiError} from "../../../services/api";
import ItemSelectDialog from "../../../pages/ItemDefinition/ItemSelectDialog";
import {ItemDefinition} from "../../../services/item-definitions";
import type {DropTable} from "../../../services/drop-tables";
import Button from "@material-ui/core/Button";
import type {MenuActionLink} from "../../../components/MaterialTable";
import MaterialTable from "../../../components/MaterialTable";
import EditIcon from "@material-ui/icons/Edit";
import {pushAppNotification} from "../../../shared/hooks/useAppNotification";
import { format } from 'date-fns';

const itemTokensColumns: Column<ItemToken>[] = [
  {
    title: '',
    field: 'image',
    sorting: false,
    cellStyle: {padding: 0, width: 64, lineHeight: 0.5},
    render(token: ItemToken) {
      return token.image ? (<img src={token.image} width={64} height={64} alt={token.itemId}/>) : (<Box width={64} height={64} />)
    }
  },
  {
    title: 'ID',
    field: 'itemId',
    render(token) {
      return <Grid container spacing={1} alignItems="center">
        <Grid item xs>
          <Link component={RouterLink} to={`/catalogs/${token.catalog}/items/${token.itemId}`}>
            {token.itemId}
          </Link>
        </Grid>
      </Grid>
    }
  },
  {
    title: 'Token Name',
    field: 'tokenName'
  },
  {
    title: 'Item Name',
    field: 'itemName'
  },
  {
    title: 'Current Supply',
    field: 'currentSupply'
  },
  {
    title: 'Max Supply',
    field: 'maxSupply'
  },
  {
    title: 'Issued Supply',
    field: 'issuedSupply'
  },
  {
    title: 'Recommendation Start',
    render(item) {
      const date = new Date((item.recentDropStart || 0) * 1000);
      return <Grid container spacing={1} alignItems="center">
        {(item.recentDropStart != null) && (
          <Grid item xs="auto">
            {format(date,'yyyy-MM-dd HH:mm:ss')}
          </Grid>
        )}
      </Grid>
    }
  },
  {
    title: 'Frozen',
    render(item) {
      return <Grid container spacing={1} alignItems="center">
        {(item.state === 'FROZEN' || item.state === 'PENDING_FREEZE') && (
          <Grid item xs="auto">
            <Tooltip title="Frozen">
              <AcUnit style={{ color: lightBlue[500] }} />
            </Tooltip>
          </Grid>
        )}
      </Grid>
    }
  },
];

export const ItemTokens = () => {
  const history = useHistory();
  const [itemTokens, setItemTokens] = useState<ItemToken[] | null>(null);
  const [addDialogOpen, setAddDialogOpen] = useState(false);

  useEffect(() => {
    if (itemTokens === null) {
      getItemTokens().then(setItemTokens)
        .catch((e: ApiError) => pushAppNotification({
          type: 'error',
          message: `Failed to load item tokens. ${e.message}`
        }));
    }
  }, [itemTokens])

  const getMenuActions = useCallback((token: ItemToken) => {
    const actions: MenuActionLink[] = [];
    actions.push({type: 'link', icon: EditIcon, label: 'Edit', href: `/catalogs/${token.catalog}/items/${token.itemId}/token`});
    return actions;
  }, [])

  const addItems = useCallback(async (items: (ItemDefinition | DropTable)[]) => {
    if (items.length === 1 && items[0] instanceof ItemDefinition) {
      const item = items[0];
      history.push(`/catalogs/${item.catalogName}/items/${item.itemId}/token`);
    }
  }, [history])

  if (!itemTokens) {
    return (
      <Box textAlign="center">
        <CircularProgress />
      </Box>
    );
  }

  return (<>
    <Box mb={2}>
      <Grid container alignItems="center">
        <Grid item xs />
        <Grid item>
          <Button
            color="primary"
            onClick={() => setAddDialogOpen(true)}
          >
            Create New
          </Button>
        </Grid>
      </Grid>
    </Box>
    <MaterialTable<ItemToken>
      title="Item Tokens"
      data={itemTokens}
      columns={itemTokensColumns}
      menuActions={getMenuActions}
    />
    {addDialogOpen && (
      <ItemSelectDialog
        showCatalogSelect
        onSelect={addItems}
        onClose={() => setAddDialogOpen(false)}
      />
    )}
  </>);
}
import { useCallback, useEffect, useMemo, useState } from "react";
import { LiveEvent, LiveEventTypeEnum } from "../live-events-types";
import { announceLiveEventUpdate, deleteLiveEvent, updateLiveEvent } from "../live-events-api";
import { usePushNotification } from "../../../contexts/AppNotificationContext";
import MaterialTable, { MenuAction } from "../../../components/MaterialTable";
import { Box, Button, CircularProgress, Grid, Link, Tooltip } from "@material-ui/core";
import { Column } from "material-table";
import { format } from "date-fns";
import { Link as RouterLink } from "react-router-dom";
import { UserService } from "../../../services/user";
import { RouteComponentProps } from "react-router";
import { Announcement, CheckCircle, CheckCircleOutline, History, Update } from "@material-ui/icons";
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import FileCopy from '@material-ui/icons/FileCopy';
import { blue, green, orange } from "@material-ui/core/colors";
import { openAdminConfirmationDialog } from "../../../shared/hooks/useAdminConfirmationDialog";
import { DuplicateLiveEventDialog } from "./DuplicateLiveEventDialog";
import { SeasonAutoClaimsStatus } from "../../seasons/seasons";
import { getPriorSeasonAutoClaimsStatus, queuePriorSeasonAutoClaims } from "../../seasons/seasons-api";
import { ApiError } from "../../../services/api";

export interface LiveEventViewModel extends LiveEvent {
  seasonNumber?: number;
}

export interface Props extends RouteComponentProps {
  refreshLiveEvents: () => Promise<void>;
  liveEvents: LiveEvent[];
}

export const LiveEventsTable = (props: Props) => {
  const {history, refreshLiveEvents} = props;
  const pushNotification = usePushNotification();
  const [liveEventToDuplicate, setLiveEventToDuplicate] = useState<LiveEventViewModel>();
  const [priorAutoClaimStatus, setPriorAutoClaimStatus] = useState<SeasonAutoClaimsStatus>();

  const liveEventViewModels = useMemo(() => {
    const viewModels: LiveEventViewModel[] = [];
    props.liveEvents.forEach(liveEvent => {
      viewModels.push({
        ...liveEvent,
        seasonNumber: liveEvent.seasonalData && liveEvent.seasonalData.length > 0 ? liveEvent.seasonalData[0].seasonNumber : undefined,
      });
    });
    return viewModels;
  }, [props]);

  useEffect(() => {
    if (priorAutoClaimStatus)
      return;

    getPriorSeasonAutoClaimsStatus()
      .then(status => setPriorAutoClaimStatus(status))
      .catch(err => pushNotification({type: 'error', message: `Failed to get prior season auto claims status: ${err.message}`}));
  }, [priorAutoClaimStatus, pushNotification]);

  const onEdit = useCallback((liveEvent: LiveEvent) => {
    history.push(`/live-events/edit/${liveEvent.id}`);
  }, [history]);

  const onDelete = useCallback((liveEvent: LiveEvent) => {
    openAdminConfirmationDialog({
      title: `Delete ${liveEvent.name}?`,
      action: 'Delete',
      onConfirm: () => {
        deleteLiveEvent(liveEvent).finally(() => refreshLiveEvents());
      },
    });
  }, [refreshLiveEvents]);

  const onAnnounceUpdate = useCallback((liveEvent: LiveEvent) => {
    openAdminConfirmationDialog({
      title: `Announce Update for ${liveEvent.name}?`,
      action: 'Announce',
      onConfirm: () => { announceLiveEventUpdate(liveEvent) },
    });
  }, []);

  const onMakeActive = useCallback((liveEvent: LiveEvent) => {
    openAdminConfirmationDialog({
      title: `'${liveEvent.name}' is an inactive Live Event.  Make it active?`,
      action: 'OK',
      onConfirm: async () => {
        liveEvent.active = true;
        liveEvent.prior = false;
        try {
          await updateLiveEvent(liveEvent);
          pushNotification({type: 'success', message: `'${liveEvent.name}' is active`})
        } catch (e) {
          pushNotification({type: 'error', message: `Failed to make active`})
        } finally {
          refreshLiveEvents();
        }
      },
    });
  }, [pushNotification, refreshLiveEvents]);

  const onMakeInactive = useCallback((liveEvent: LiveEvent) => {
    openAdminConfirmationDialog({
      title: `'${liveEvent.name}' is an active Live Event.  Make it inactive and prior?`,
      action: 'OK',
      onConfirm: async () => {
        liveEvent.active = false;
        liveEvent.prior = false;
        try {
          await updateLiveEvent(liveEvent);
          pushNotification({type: 'success', message: `'${liveEvent.name}' is inactive`})
        } catch (e) {
          pushNotification({type: 'error', message: `Failed to make inactive`})
        } finally {
          refreshLiveEvents();
        }
        refreshLiveEvents();
      },
    });
  }, [pushNotification, refreshLiveEvents]);

  const onMakePrior = useCallback((liveEvent: LiveEvent) => {
    openAdminConfirmationDialog({
      title: `'${liveEvent.name}' is an inactive Live Event.  Make it prior?`,
      action: 'OK',
      onConfirm: async () => {
        liveEvent.prior = true;
        liveEvent.active = false;
        try {
          await updateLiveEvent(liveEvent);
          pushNotification({type: 'success', message: `'${liveEvent.name}' is now prior`})
        } catch (e) {
          pushNotification({type: 'error', message: `Failed to make prior`})
        } finally {
          refreshLiveEvents();
        }
      },
    });
  }, [pushNotification, refreshLiveEvents]);

  const onLiveEventsChanged = useCallback(() => {
    setLiveEventToDuplicate(undefined);
    refreshLiveEvents();
  }, [refreshLiveEvents]);

  const onQueueAutoClaims = useCallback((liveEvent: LiveEvent) => {
    queuePriorSeasonAutoClaims()
      .then(() => pushNotification({type: 'success', message: `Queued auto claims`}))
      .catch(err => pushNotification({type: 'error', message: `Failed to get prior season auto claims status: ${err.message}`}));
  }, [pushNotification]);

  const getTableMenuActions = useMemo(() => (liveEvent: LiveEvent): MenuAction<LiveEvent>[] => {
    const actions: MenuAction<LiveEvent>[] = [];
    if (UserService.canUpdate('liveEvents')) {
      actions.push({ type: 'button', icon: EditIcon, label: 'Edit', onClick: onEdit });

      const isPartyPass = liveEvent.liveEventType === LiveEventTypeEnum.PartyPass;
      if (liveEvent.active) {
        actions.push({ type: 'button', icon: Announcement, label: 'Announce Update', onClick: onAnnounceUpdate });
        actions.push({ type: 'button', icon: CheckCircleOutline, label: 'Make Inactive', onClick: onMakeInactive });
        if (isPartyPass) {
          actions.push({ type: 'button', icon: History, label: 'Make Prior', onClick: onMakePrior });
        }
      } else if (liveEvent.prior) {
        actions.push({ type: 'button', icon: CheckCircle, label: 'Make Active', onClick: onMakeActive });
        actions.push({ type: 'button', icon: CheckCircleOutline, label: 'Make Inactive', onClick: onMakeInactive });
      } else {
        actions.push({ type: 'button', icon: CheckCircle, label: 'Make Active', onClick: onMakeActive });
        if (isPartyPass) {
          actions.push({ type: 'button', icon: History, label: 'Make Prior', onClick: onMakePrior });
        }
      }

      actions.push({ type: 'button', icon: FileCopy, label: 'Duplicate', onClick: (liveEvent) => setLiveEventToDuplicate(liveEvent) });

      if (liveEvent.prior && priorAutoClaimStatus) {
        actions.push({ type: 'button', disabled: () => priorAutoClaimStatus.allQueued, icon: Update, label: 'Queue auto claims', onClick: onQueueAutoClaims})
      }
    }

    if (UserService.canDelete('liveEvents')) {
      if (actions.length > 0) {
        actions.push({ type: 'divider' });
      }
      actions.push({ type: 'button', icon: DeleteIcon, label: 'Delete', onClick: onDelete });
    }

    return actions;
  }, [priorAutoClaimStatus, onAnnounceUpdate, onDelete, onEdit, onMakeActive, onMakeInactive, onMakePrior, onQueueAutoClaims]);

  const columns: Column<LiveEventViewModel>[] = useMemo(() => {
    return [
      {
        render: liveEvent => (
          <Grid container spacing={1} alignItems="center">
            {liveEvent.active && (
              <Grid item xs="auto">
                <Tooltip title="Active">
                  <CheckCircle style={{ color: green[500] }} />
                </Tooltip>
              </Grid>
            )}
            {liveEvent.prior && (
              <Grid item xs="auto">
                <Tooltip title="Prior">
                  <History style={{ color: blue[500] }} />
                </Tooltip>
              </Grid>
            )}
          </Grid>
        )
      },
      {
        title: 'ID',
        field: 'id',
      },
      {
        title: 'Name',
        field: 'name',
        render(liveEvent) {
          return <Link component={RouterLink} to={`/live-events/edit/${liveEvent.id}`}>
            {liveEvent.name}
          </Link>;
        },
      },
      {
        title: 'Type',
        field: 'liveEventType',
      },
      {
        title: 'Season Number',
        field: 'seasonNumber',
        render(liveEventViewModel) {
          const seasonNumber = liveEventViewModel.seasonNumber === undefined ? '-' : liveEventViewModel.seasonNumber.toString();
          return <>
            {seasonNumber}
          </>
        },
      },
      {
        title: 'Auto claims',
        render: liveEvent => (
          <>{liveEvent.prior &&
            <Grid container spacing={1} alignItems="center">
              {!priorAutoClaimStatus?.allQueued && (
                <Grid item xs="auto">
                  <Tooltip title="Auto claims not queued">
                    <History style={{ color: orange[500] }} />
                  </Tooltip>
                </Grid>
              )}
              {priorAutoClaimStatus?.allQueued && !priorAutoClaimStatus?.allProcessed && (
                <Grid item xs="auto">
                  <Tooltip title="All auto claims queued">
                    <Update style={{ color: blue[500] }} />
                  </Tooltip>
                </Grid>
              )}
              {priorAutoClaimStatus?.allProcessed && (
                <Grid item xs="auto">
                  <Tooltip title="All auto claims fully processed">
                    <CheckCircle style={{ color: blue[500] }} />
                  </Tooltip>
                </Grid>
              )}
            </Grid>}
          </>
        )
      },
      {
        title: 'Auto Activate',
        field: 'autoActivating',
        render(liveEventViewModel) {
          return <>
            {liveEventViewModel.autoActivating ? 'ON' : 'OFF'}
          </>
        },
      },
      {
        title: 'Start Time',
        field: 'startTime',
        defaultSort: 'desc',
        render(liveEvent) {
          return <>{format(new Date(liveEvent.startTime), 'yyyy/MM/dd HH:mm:ss')}</>;
        },
      },
      {
        title: 'End Time',
        field: 'endTime',
        render(liveEvent) {
          return <>{format(new Date(liveEvent.endTime), 'yyyy/MM/dd HH:mm:ss')}</>;
        },
      },
    ];

  }, [priorAutoClaimStatus]);

  if (!liveEventViewModels) {
    return <Box textAlign="center">
      <CircularProgress/>
    </Box>;
  }
  return <>
    <Box>
      <Grid container>
        <Grid item xs/>
        {UserService.canCreate('liveEvents') && (
          <Grid item>
            <Button variant="contained" color="primary" onClick={() => history.push(`/live-events/new`)}>
              Add Live Event
            </Button>
          </Grid>
        )}
      </Grid>
      <br/>
      <MaterialTable<LiveEvent>
        title="Live Events"
        storageId="live-events"
        options={{
          columnsButton: true,
        }}
        columns={columns}
        data={liveEventViewModels}
        menuActions={getTableMenuActions}
      />
    </Box>
    {liveEventToDuplicate && liveEventToDuplicate.id && (
      <DuplicateLiveEventDialog
        liveEvent={liveEventToDuplicate}
        onCancel={() => setLiveEventToDuplicate(undefined)}
        onFinished={onLiveEventsChanged}
      />
    )}
  </>;
};

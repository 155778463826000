import { Box, FormControl, InputLabel, MenuItem, Select, Typography } from '@material-ui/core';
import { useEffect, useState } from 'react';
import type { ItemDefinition } from '../../services/item-definitions';

interface TokenImage {
  id: string;
  url: string;
}

interface Props {
  item: ItemDefinition;
}

const ItemTokenImages = (props: Props) => {

  const [images, setImages] = useState<TokenImage[]>([]);
  const [selectedImage, setSelectedImage] = useState<TokenImage | null>(null);

  const customData = props.item.customData;
  useEffect(() => {
    const newImages: TokenImage[] = [];
    const imageMap = customData?.Marketplace;
    if (imageMap) {
      for (const key in imageMap) {
        newImages.push({ id: key, url: imageMap[key] });
      }
      newImages.sort((a, b) => a.id.localeCompare(b.id));
    }
    setImages(newImages);
    setSelectedImage(newImages.length > 0 ? newImages[0] : null);
  }, [customData]);

  const onSelectChange = (id: string) => {
    setSelectedImage(images.find(v => v.id === id) || null);
  }

  if (images.length < 1) {
    return (
      <Typography color="textSecondary">
        No images found.
      </Typography>
    );
  }

  return (<>
    <FormControl fullWidth>
      <InputLabel>Image ID</InputLabel>
      <Select
        value={(selectedImage || images[0]).id}
        onChange={event => onSelectChange(event.target.value as string)}
      >
        {images.map(image => (
          <MenuItem key={image.id} value={image.id}>{image.id}</MenuItem>
        ))}
      </Select>
    </FormControl>

    {selectedImage && (
      <Box mt={2}>
        <img alt="Preview" src={selectedImage.url} height="128" />
      </Box>
    )}
  </>)
}

export default ItemTokenImages;
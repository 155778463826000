import { createAsyncAction } from 'typesafe-actions';
import type { ChallengeTemplate, ChallengeTrigger } from '../../../services/player-challenges/challenge-templates';

export const getChallengeTemplatesAsync = createAsyncAction(
  'GET_CHALLENGE_TEMPLATES_REQUEST',
  'GET_CHALLENGE_TEMPLATES_SUCCESS',
  'GET_CHALLENGE_TEMPLATES_FAILURE'
)<void, ChallengeTemplate[], string>();

export const getChallengeTriggersAsync = createAsyncAction(
  'GET_CHALLENGE_TRIGGERS_REQUEST',
  'GET_CHALLENGE_TRIGGERS_SUCCESS',
  'GET_CHALLENGE_TRIGGERS_FAILURE'
)<void, ChallengeTrigger[], string>();

export const getLatestChallengeTemplateAsync = createAsyncAction(
  'GET_LATEST_CHALLENGE_TEMPLATE_REQUEST',
  'GET_LATEST_CHALLENGE_TEMPLATE_SUCCESS',
  'GET_LATEST_CHALLENGE_TEMPLATE_FAILURE'
)<void, ChallengeTemplate, string>();

export const createChallengeTemplateAsync = createAsyncAction(
  'CREATE_CHALLENGE_TEMPLATE_REQUEST',
  'CREATE_CHALLENGE_TEMPLATE_SUCCESS',
  'CREATE_CHALLENGE_TEMPLATE_FAILURE'
)<ChallengeTemplate, ChallengeTemplate, string>();

export const getChallengeTemplateAsync = createAsyncAction(
  'GET_CHALLENGE_TEMPLATE_REQUEST',
  'GET_CHALLENGE_TEMPLATE_SUCCESS',
  'GET_CHALLENGE_TEMPLATE_FAILURE'
)<number, ChallengeTemplate, string>();

export const updateChallengeTemplateAsync = createAsyncAction(
  'UPDATE_CHALLENGE_TEMPLATE_REQUEST',
  'UPDATE_CHALLENGE_TEMPLATE_SUCCESS',
  'UPDATE_CHALLENGE_TEMPLATE_FAILURE'
)<ChallengeTemplate, ChallengeTemplate, string>();

export const deleteChallengeTemplateAsync = createAsyncAction(
  'DELETE_CHALLENGE_TEMPLATE_REQUEST',
  'DELETE_CHALLENGE_TEMPLATE_SUCCESS',
  'DELETE_CHALLENGE_TEMPLATE_FAILURE'
)<number, number, string>();

import { Box, Button, Card, CardContent, CardHeader, FormControl, Grid, IconButton, InputAdornment, InputLabel, MenuItem, Typography } from '@material-ui/core';
import { format } from 'date-fns';
import { Field, Formik } from 'formik';
import type {FormikProps} from 'formik';
import { CheckboxWithLabel, Select, TextField } from 'formik-material-ui';
import { useCallback, useMemo, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { setAppNotification } from '../../../redux/app/actions';
import type { ItemDefinition } from '../../../services/item-definitions';
import type { MarketplaceItemDataTag, ShopItem, ShopTabItem } from '../shop';
import { updateShopItem, updateShopTabItem } from '../shopsApi';
import * as yup from 'yup';
import { ShopTemplatePickerDialog } from '../../shop-templates/components/ShopTemplatePickerDialog';
import type { ShopTemplateItem } from '../../shop-templates/shopTemplate';
import { FormikAdminKeyboardDateTimePicker } from "../../../components/AdminKeyboardDateTimePicker";
import { FormikSelectWithLabel } from "../../../components/FormikSelectWithLabel";
import { GeoBlockingFeatureSelector } from "../../../shared/components/GeoBlockingFeatureSelector";
import { GeoBlockedFeatureIdEnum } from "../../geoblocking/geo-blocking-types";

const formSchema = yup.object().shape({
  startTime: yup.date().nullable().when('endTime', {
    is: (endTime: string) => Boolean(endTime),
    then: schema => schema.max(yup.ref('endTime'), 'Must be before the end date')
  }).transform((startTime) => startTime || undefined),
  endTime: yup.date().nullable().when('startTime', {
    is: (startTime: string) => Boolean(startTime),
    then: schema => schema.min(yup.ref('startTime'), 'Must be after the start date')
  }).transform((endTime) => endTime || undefined)
}, [['startTime', 'endTime']]);

interface TileFormValues {
  nameFontSize: number;
  nameFontColor: string;
  artistCollab: string;
  heroText: string;
  heroFontColor: string;
  buttonText: string;
  contentAlign: string;
  contentWidth: number;
  badgeAlign: string;
  bgOverride: boolean;
  nft: boolean;
  marketplaceTagName: string;
  marketplaceSectionName: string;
  marketplaceItemIndex: number;
  hideOnMainListing: boolean;
}

interface FormValues {
  cartLimit: number;
  purchaseLimit: number;
  geoBlockingFeatures: GeoBlockedFeatureIdEnum[];
  description: string;
  bgGradientStart: string;
  bgGradientEnd: string;
  startTime: string | null;
  endTime: string | null;
  tile: TileFormValues;
  marketplaceEnabled: boolean;
}

export interface ShopItemDataEditorProps {
  item?: ShopItem;
  tabItem: ShopTabItem;
  catalogItem?: ItemDefinition;
  onUpdate: (tabItem: ShopTabItem, item?: ShopItem) => void;
  onCancel: () => void;
  marketplaceItemDataTags: MarketplaceItemDataTag[];
}

export const ShopItemDataEditor = (props: ShopItemDataEditorProps) => {
  const dispatch = useDispatch();
  const [templateItem, setTemplateItem] = useState<ShopTemplateItem | null>(null);
  const [templateDialogOpen, setTemplateDialogOpen] = useState(false);
  const { item, tabItem, catalogItem, onUpdate, marketplaceItemDataTags } = props;
  const formRef = useRef<FormikProps<FormValues>>(null);

  const hasMultipleEntries = useMemo(() => {
    return tabItem.marketplaceItemDataTag
      && marketplaceItemDataTags.find(tag => tag.name === tabItem.marketplaceItemDataTag)?.hasMultipleEntries;
  }, [marketplaceItemDataTags, tabItem.marketplaceItemDataTag]);

  const formValues = useMemo((): FormValues => {
    const formValues: FormValues = {
      cartLimit: item?.cartLimit || 0,
      purchaseLimit: item?.purchaseLimit || 0,
      geoBlockingFeatures: item?.geoBlockingFeatures || [],
      description: '',
      bgGradientStart: '',
      bgGradientEnd: '',
      startTime: item?.startTimestamp ? format(new Date(item.startTimestamp), 'yyyy-MM-dd\'T\'HH:mm:ss') : null,
      endTime: item?.endTimestamp ? format(new Date(item.endTimestamp), 'yyyy-MM-dd\'T\'HH:mm:ss') : null,
      tile: {
        nameFontSize: 120,
        nameFontColor: '#FFF',
        artistCollab: '',
        heroText: '',
        heroFontColor: '#000',
        buttonText: '',
        contentAlign: 'left',
        contentWidth: 50,
        badgeAlign: 'left',
        bgOverride: true,
        nft: false,
        marketplaceTagName: '',
        marketplaceSectionName: '',
        marketplaceItemIndex: 1,
        hideOnMainListing: false,
      },
      marketplaceEnabled: tabItem.marketplaceEnabled,
    };

    // Unknowns are going to have to wait
    let customData = item?.customData as any;
    if (customData) {
      let value = customData.BackgroundGradient?.Start;
      formValues.bgGradientStart = typeof value === 'string' ? value : '';
      value = customData.BackgroundGradient?.End;
      formValues.bgGradientEnd = typeof value === 'string' ? value : '';
      value = customData.Description;
      formValues.description = typeof value === 'string' ? value : '';
    }

    customData = tabItem.customData;
    if (customData !== null) {
      if (typeof customData.nameFontSize === 'number') {
        formValues.tile.nameFontSize = customData.nameFontSize;
      }
      if (typeof customData.nameFontColor === 'string') {
        formValues.tile.nameFontColor = customData.nameFontColor;
      }
      if (typeof customData.artistCollab === 'string') {
        formValues.tile.artistCollab = customData.artistCollab;
      }
      if (typeof customData.heroText === 'string') {
        formValues.tile.heroText = customData.heroText;
      }
      if (typeof customData.heroFontColor === 'string') {
        formValues.tile.heroFontColor = customData.heroFontColor;
      }
      if (typeof customData.buttonText === 'string') {
        formValues.tile.buttonText = customData.buttonText;
      }
      if (typeof customData.contentAlign === 'string') {
        formValues.tile.contentAlign = customData.contentAlign;
      }
      if (typeof customData.contentWidth === 'number') {
        formValues.tile.contentWidth = customData.contentWidth * 100;
      }
      if (typeof customData.badgeAlign === 'string') {
        formValues.tile.badgeAlign = customData.badgeAlign;
      }
      if (typeof customData.bgOverride === 'boolean') {
        formValues.tile.bgOverride = customData.bgOverride;
      }
      if (typeof customData.nft === 'boolean') {
        formValues.tile.nft = customData.nft;
      }
      if (typeof customData.marketplaceTagName === 'string') {
        formValues.tile.marketplaceTagName = customData.marketplaceTagName;
      }
      if (typeof customData.marketplaceSectionName === 'string') {
        formValues.tile.marketplaceSectionName = customData.marketplaceSectionName;
      }
      if (typeof customData.marketplaceItemIndex === 'number') {
        formValues.tile.marketplaceItemIndex = customData.marketplaceItemIndex;
      }
      if (typeof customData.hideOnMainListing === 'boolean') {
        formValues.tile.hideOnMainListing = customData.hideOnMainListing;
      }
    }

    if(templateItem){
      formValues.cartLimit = templateItem.cartLimit || 0;
      formValues.purchaseLimit = templateItem.purchaseLimit || 0;
      formValues.startTime = templateItem.startDate ? format(new Date(templateItem.startDate), 'yyyy-MM-dd\'T\'HH:mm:ss'): null;
      formValues.endTime = templateItem.endDate ? format(new Date(templateItem.endDate), 'yyyy-MM-dd\'T\'HH:mm:ss'): null;
      formValues.geoBlockingFeatures = templateItem.geoBlockingFeatures || [];
      formValues.tile.bgOverride = templateItem.backgroundImageOverride;
      formValues.tile.nft = templateItem.nft;
      formValues.tile.hideOnMainListing = templateItem.hideOnMainListing;
      formValues.tile.nameFontColor = templateItem.nameFontColor;
      formValues.tile.nameFontSize = templateItem.nameFontSize;
      formValues.tile.artistCollab = templateItem.artistCollabText;
      formValues.tile.heroText = templateItem.heroText;
      formValues.tile.heroFontColor = templateItem.heroFontColor;
      formValues.tile.buttonText = templateItem.buttonText;
      formValues.tile.contentAlign = templateItem.contentAlignment.toLowerCase();
      formValues.tile.contentWidth = templateItem.contentWidthPercentage;
      formValues.tile.badgeAlign = templateItem.badgeAlignment.toLowerCase();

      //bring over field values that were not part of the template
      if(formRef.current != null){
        const existingValues = formRef.current.values;
        formValues.bgGradientEnd = existingValues.bgGradientEnd;
        formValues.bgGradientStart = existingValues.bgGradientStart;
        formValues.description = existingValues.description;
      }
    }
    return formValues;
  }, [item, tabItem,templateItem]);

  const onApplyTemplate = useCallback((template: ShopTemplateItem) => {
    setTemplateItem(template);
  },[setTemplateItem]);

  const onSubmit = useCallback(async (values: FormValues) => {
    let newItem: ShopItem | undefined = undefined;
    if (item) {
      newItem = {
        ...item,
        cartLimit: values.cartLimit,
        purchaseLimit: values.purchaseLimit,
        startTimestamp: values.startTime ? new Date(values.startTime).toISOString() : null,
        endTimestamp: values.endTime ? new Date(values.endTime).toISOString() : null,
        geoBlockingFeatures: values.geoBlockingFeatures,
      };
      newItem.customData = {};
      if (values.description.trim()) {
        newItem.customData.Description = values.description.trim();
      }
      if (values.bgGradientStart.trim() && values.bgGradientEnd.trim()) {
        newItem.customData.BackgroundGradient = {
          Start: values.bgGradientStart.trim(),
          End: values.bgGradientEnd.trim()
        };
      }
    }

    let newTabItem: ShopTabItem = {
      ...tabItem,
      marketplaceEnabled: values.marketplaceEnabled,
    };
    const storeTileData: any = values.tile.bgOverride ? {} : {
      nameFontSize: values.tile.nameFontSize,
      nameFontColor: values.tile.nameFontColor.trim(),
      artistCollab: values.tile.artistCollab.trim(),
      heroText: values.tile.heroText.trim(),
      heroFontColor: values.tile.heroFontColor.trim(),
      buttonText: values.tile.buttonText.trim(),
      contentAlign: values.tile.contentAlign,
      contentWidth: values.tile.contentWidth / 100.0,
      badgeAlign: values.tile.badgeAlign,
      nft: values.tile.nft,
    };
    storeTileData.bgOverride = values.tile.bgOverride;
    storeTileData.hideOnMainListing = values.tile.hideOnMainListing;
    const marketplaceTileData: any = !values.marketplaceEnabled ? {} : {
      marketplaceTagName: values.tile.marketplaceTagName,
      marketplaceSectionName: values.tile.marketplaceSectionName,
    };
    if (hasMultipleEntries) {
      marketplaceTileData.marketplaceItemIndex = values.tile.marketplaceItemIndex;
    }
    newTabItem.customData = Object.assign({}, storeTileData, marketplaceTileData);

    if (newItem) {
      newItem = await updateShopItem(newItem);
    }
    newTabItem = await updateShopTabItem(newTabItem);

    onUpdate(newTabItem, newItem);
    dispatch(setAppNotification({ type: 'success', message: 'Item updated' }));
  }, [item, tabItem, hasMultipleEntries, onUpdate, dispatch]);

  return (<>
    <Box mb={2}>
      <Grid container alignItems="center"  >
        <Grid item xs>
          <Typography variant="h6">
            Edit {catalogItem && props.item && <>
              {catalogItem.itemName || catalogItem.displayName} ({props.item.itemId})
            </> || <>{tabItem.marketplaceItemDataTag}</>}
          </Typography>
        </Grid>
        {catalogItem && props.item &&
          <Grid item>
            <Button color="primary" variant="contained" onClick={() => setTemplateDialogOpen(true)}>Apply
              Template</Button>
          </Grid>
        }
      </Grid>

    </Box>

    <Formik<FormValues>
      innerRef={formRef}
      initialValues={formValues}
      validationSchema={formSchema}
      enableReinitialize
      onSubmit={onSubmit}
      validateOnMount
    >
      {form => (<>
        <Grid container spacing={2}>
          {props.item &&
            <Grid item xs={12} lg={6}>
              <Card>
                <CardHeader title="Properties"/>
                <CardContent>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <Field
                        component={TextField}
                        name="cartLimit"
                        type="number"
                        label="Cart limit"
                        helperText="Blank or 0 for no limit"
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Field
                        component={TextField}
                        name="purchaseLimit"
                        type="number"
                        label="Purchase limit"
                        helperText="Blank or 0 for no limit"
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Field
                        component={FormikAdminKeyboardDateTimePicker}
                        name="startTime"
                        label="Start date"
                        fullWidth
                        clearable
                        InputLabelProps={{shrink: true}}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Field
                        component={FormikAdminKeyboardDateTimePicker}
                        name="endTime"
                        label="End date"
                        fullWidth
                        clearable
                        InputLabelProps={{shrink: true}}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <GeoBlockingFeatureSelector
                        features={form.values.geoBlockingFeatures}
                        updateFeatures={(features) => form.setFieldValue('geoBlockingFeatures', features)}
                      />
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
          }
          <Grid item xs={12} lg={6}>
            <Card>
              <CardHeader title="Web tile properties" />
              <CardContent>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <Field
                      component={TextField}
                      name="bgGradientStart"
                      type="text"
                      label="BG Gradient Start Color"
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Field
                      component={TextField}
                      name="bgGradientEnd"
                      type="text"
                      label="BG Gradient End Color"
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Field
                      component={TextField}
                      name="description"
                      type="text"
                      label="Description"
                      fullWidth
                      multiline
                      rowsMax={4}
                    />
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} lg={6}>
            <Card>
              <CardHeader title="Store tile properties" />
              <CardContent>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Field
                      component={CheckboxWithLabel}
                      type="checkbox"
                      name="tile.hideOnMainListing"
                      Label={{ label: 'Hide On Main Listing' }}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Field
                      component={CheckboxWithLabel}
                      type="checkbox"
                      name="tile.bgOverride"
                      Label={{ label: 'Background image override' }}
                    />
                  </Grid>
                  {!form.values.tile.bgOverride && (<>
                    <Grid item xs={12}>
                      <Field
                        component={CheckboxWithLabel}
                        type="checkbox"
                        name="tile.nft"
                        Label={{ label: 'NFT' }}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <Field
                        component={TextField}
                        name="tile.nameFontSize"
                        type="number"
                        label="Name font size (pixels)"
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <Field
                        component={TextField}
                        name="tile.nameFontColor"
                        type="text"
                        label="Name font color"
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Field
                        component={TextField}
                        name="tile.artistCollab"
                        type="text"
                        label="Artist collab"
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Field
                        component={TextField}
                        name="tile.heroText"
                        type="text"
                        label="Hero text"
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Field
                        component={TextField}
                        name="tile.heroFontColor"
                        type="text"
                        label="Hero font color"
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Field
                        component={TextField}
                        name="tile.buttonText"
                        type="text"
                        label="Button text"
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <FormControl fullWidth>
                        <InputLabel>Content alignment</InputLabel>
                        <Field
                          component={Select}
                          name="tile.contentAlign"
                        >
                          <MenuItem value="left">Left</MenuItem>
                          <MenuItem value="right">Right</MenuItem>
                        </Field>
                      </FormControl>
                    </Grid>
                    <Grid item xs={4}>
                      <Field
                        component={TextField}
                        name="tile.contentWidth"
                        type="number"
                        label="Content width %"
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <FormControl fullWidth>
                        <InputLabel>Badge alignment</InputLabel>
                        <Field
                          component={Select}
                          name="tile.badgeAlign"
                        >
                          <MenuItem value="left">Left</MenuItem>
                          <MenuItem value="right">Right</MenuItem>
                        </Field>
                      </FormControl>
                    </Grid>
                  </>)}
                </Grid>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} lg={6}>
            <Card>
              <CardHeader title="Marketplace tile properties" />
              <CardContent>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Field
                      component={CheckboxWithLabel}
                      type="checkbox"
                      name="marketplaceEnabled"
                      Label={{ label: 'Marketplace enabled' }}
                    />
                  </Grid>
                  {form.values.marketplaceEnabled && <>
                    <Grid item xs={12}>
                      <Field
                        component={TextField}
                        name="tile.marketplaceTagName"
                        label='Tag name'
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Field
                        component={TextField}
                        name="tile.marketplaceSectionName"
                        label='Section name'
                        fullWidth
                      />
                    </Grid>
                    {hasMultipleEntries && <Grid item xs={12}>
                      <Field
                        component={FormikSelectWithLabel}
                        name="tile.marketplaceItemIndex"
                        label='Item'
                        fullWidth
                      >
                        <MenuItem value={1}>First</MenuItem>
                        <MenuItem value={2}>Second</MenuItem>
                        <MenuItem value={3}>Third</MenuItem>
                      </Field>
                    </Grid>}
                  </>}
                </Grid>
              </CardContent>
            </Card>
          </Grid>
        </Grid>

        <Box mt={2}>
          <Grid container spacing={1}>
            <Grid item xs="auto">
              <Button color="primary" variant="contained" onClick={form.submitForm}>
                Save
              </Button>
            </Grid>
            <Grid item xs="auto">
              <Button variant="outlined" onClick={props.onCancel}>
                Back to shop
              </Button>
            </Grid>
          </Grid>
        </Box>
      </>)}
    </Formik>

    {templateDialogOpen && (
      <ShopTemplatePickerDialog
        onClose={() => setTemplateDialogOpen(false)}
        onApplyTemplate={onApplyTemplate}
      />
    )}
  </>)
};

import { StoreDiff } from '../../../../pages/CatalogMigration/types';
import { CatalogMigrationService } from '../../../../services/catalog-migration';
import type { Store, StoreTabItem } from '../../../../services/stores';
import type { MigrationModule } from '../../migrations';
import { buildMigrationActions , buildDiffData } from '../../migrations';
import { StoresDiffs } from './StoresDiffs';
import { MigrationType } from "../migration-type";

interface StoreImageMigration {
  storeId: string;
  tabName: string;
  item: StoreTabItem;
}

export const storeMigration: MigrationModule<StoreDiff> = {
  id: MigrationType.store,
  displayName: 'Catalog stores',
  diffComponent: StoresDiffs,
  crossEnvOnly: false,

  loadData: async (sourceData, targetData, migrationData) => {
    sourceData.stores = await CatalogMigrationService.getStoresWithItems(sourceData.env, sourceData.catalogName);
    targetData.stores = await CatalogMigrationService.getStoresWithItems(targetData.env, targetData.catalogName);
    migrationData.stores = buildDiffData(
      sourceData.stores.map(store => new StoreDiff(store)),
      targetData.stores.map(store => new StoreDiff(store))
    );
    return migrationData.stores;
  },

  runMigration: async (props, setStatus) => {
    const actions = buildMigrationActions(props.migrationData.stores, props.selections.store);
    if (!actions || actions.add.length + actions.update.length + actions.remove.length < 1) {
      return;
    }

    const sourceCatalogName = props.sourceData.catalogName;
    const sourceStores = props.sourceData.stores;
    const targetCatalogName = props.targetData.catalogName;
    const targetStores = props.targetData.stores;

    if (!sourceCatalogName || !sourceStores || !targetCatalogName || !targetStores) {
      throw new Error('Stores not loaded.');
    }

    const isSameEnv = props.sourceData.env === props.targetData.env;
    const images: StoreImageMigration[] = [];

    const sourceStoreMap: { [key: string]: Store } = {};
    sourceStores.forEach(store => sourceStoreMap[store.storeId] = store);

    let progress = 0;
    let progressPerStore = actions.add.length + actions.update.length + actions.remove.length;
    progressPerStore = (isSameEnv ? 100.0 : 50.0) / progressPerStore;

    for (const storeId of actions.add) {
      const store = sourceStoreMap[storeId];
      store.tabs.forEach(tab => {
        tab.items.forEach(item => {
          if (item.imageFilename && item.imageUrl) {
            images.push({ storeId, tabName: tab.name, item });
          }
        });
      });

      setStatus(`Migrating store: ${storeId}`, progress);
      await CatalogMigrationService.createStore(props.targetData.env, targetCatalogName, store);
      progress += progressPerStore;
    }

    for (const storeId of actions.update) {
      const sourceStore = sourceStoreMap[storeId];
      const targetStore = targetStores.find(s => s.storeId === storeId);
      if (!targetStore) {
        continue;
      }

      sourceStore.tabs.forEach(sourceTab => {
        const targetTab = targetStore.tabs.find(t => t.name === sourceTab.name);
        sourceTab.items.forEach(sourceItem => {
          if (!sourceItem.imageFilename || !sourceItem.imageUrl) {
            return;
          }

          const targetItem = targetTab ? targetTab.items.find(i => i.itemId === sourceItem.itemId) : undefined;
          if (!targetItem || targetItem.imageFilename !== sourceItem.imageFilename) {
            images.push({ storeId, tabName: sourceTab.name, item: sourceItem });
          }
        });
      });

      setStatus(`Migrating store: ${storeId}`, progress);
      await CatalogMigrationService.createStore(props.targetData.env, targetCatalogName, sourceStore);
      progress += progressPerStore;
    }

    for (const storeId of actions.remove) {
      setStatus(`Removing store: ${storeId}`, progress);
      await CatalogMigrationService.deleteStore(props.targetData.env, targetCatalogName, storeId);
      progress += progressPerStore;
    }

    if (!isSameEnv && images.length > 0) {
      progress = 50.0;
      const progressPerImage = 50.0 / images.length;

      for (let i = 0; i < images.length; i++) {
        const image = images[i];
        setStatus(`Transferring image ${i + 1} of ${images.length}`, progress);
        await CatalogMigrationService.uploadStoreItemImage(props.targetData.env, targetCatalogName, image.storeId, image.tabName, image.item);
        progress += progressPerImage;
      }
    }
  }
}

import type { LinkTabConfig} from '../../../components/LinkTabs';
import { LinkTabs } from '../../../components/LinkTabs';
import { useHistory, useLocation, useRouteMatch } from 'react-router';
import { BrawlBlocksPublished } from '../components/BrawlBlocksPublished';
import { BrawlPins } from '../components/BrawlPins';

export interface BrawlPlaylistSettings {
  teams: { min: number, max: number },
  qualifiers: { min: number, max: number },
  finales: { min: number, max: number },
  gapMessage: string
}

export interface BrawlViewerSettings {
  showBrTeams: boolean,
  showBrQualifiers: boolean,
  showBrFinales: boolean,
  showEditor: boolean,
  showUnpublishedOnly: boolean,
  showNonElimBrawlOnly: boolean,
  showBrawlOnly: boolean
}

export const BrawlPage = () => {
  const tabs: LinkTabConfig[] = [];
  tabs.push({ url: '/brawl-blocks', label: 'Brawl Blocks', absolute: true, component: BrawlBlocksPublished });
  tabs.push({ url: '/brawl-pins', label: 'Brawl Pins', absolute: true, component: BrawlPins });

  const history = useHistory();
  const location = useLocation();
  const match = useRouteMatch();

  return (<>
    <LinkTabs tabs={tabs} history={history} location={location} match={match} />
  </>);
};

import {useState, useRef, useCallback, useEffect} from 'react';
import {Link as RouterLink} from 'react-router-dom';
import {Box, FormControlLabel, Grid, Link, Switch} from '@material-ui/core';
import DoneIcon from '@material-ui/icons/Done';
import type {Column, Query, QueryResult} from 'material-table';
import type {MenuAction} from '../../components/MaterialTable';
import MaterialTable from '../../components/MaterialTable';
import type {BlockReport} from '../../services/blocks';
import { MarkInReviewBlockReportRequest, ResolveBlockReportRequest, BlocksService} from '../../services/blocks';
import {paginatedRequestFromQuery} from '../../services/model/pagination';
import type {Player} from '../players/players';
import { UserService } from '../../services/user';
import { openAdminConfirmationDialog } from '../../shared/hooks/useAdminConfirmationDialog';
import {MaterialTableCallback} from "../../utils/MaterialTableCallback";

interface OwnProps {
  player?: Player;
  blockId?: string;
}

export const ReportedBlocks = (props: OwnProps) => {
  const { blockId } = props;
  const [showClosedReportsOnly, setShowClosedReportsOnly] = useState(false);
  const tableRef = useRef<BlockReport>();
  const [filters, setFilters] = useState<string[]>([]);

  useEffect(() => {
    const array = [];
    if (showClosedReportsOnly){
      array.push("closedOnly");
    }
    setFilters(array);
  }, [showClosedReportsOnly]);

  const getTableMenuActions = (blockReport: BlockReport) => {
    const actions: MenuAction<BlockReport>[] = [];
    if (UserService.canUpdate('blockReport')) {
      if (blockReport.status === "OPEN") {
        actions.push({ type: 'button', icon: DoneIcon, label: 'Mark In Review', onClick: clickMarkInReivew });
      } else if (blockReport.status === "IN_REVIEW") {
        actions.push({ type: 'button', icon: DoneIcon, label: 'Close Report', onClick: clickResolveReport });
      }
    }
    return actions;
  };

  const clickMarkInReivew = (blockReport: BlockReport) => {
    openAdminConfirmationDialog({
      title: 'Mark In Review?',
      details: getDetailsText(blockReport),
      action: 'Mark In Review',
      onConfirm: () => {
        const request = new MarkInReviewBlockReportRequest(blockReport.id);
        BlocksService.markInReviewBlockReport(request).then(() => {
          if (tableRef && tableRef.current) {
            (tableRef.current as any).onQueryChange();
          }
        });
      }
    });
  };

  const clickResolveReport = (blockReport: BlockReport) => {
    openAdminConfirmationDialog({
      title: 'Close Block Report?',
      details: getDetailsText(blockReport),
      action: 'Close Report',
      includeNote: true,
      requireNote: true,
      onConfirm: (note?: string) => {
        const request = new ResolveBlockReportRequest(blockReport.id);
        if (note) {
          request.note = note;
        }
        BlocksService.resolveBlockReport(request).then(() => {
          if (tableRef && tableRef.current) {
            (tableRef.current as any).onQueryChange();
          }
        });
      }
    });
  };

  const getDetailsText = (blockReport: BlockReport) => {
    return `Block ${blockReport.blockName} was reported by ${blockReport.playerDisplayName || blockReport.playerExternalId}: ${blockReport.description}`;
  }

  const columns: Column<BlockReport>[] = [
    { title: 'Status', field: 'status', sorting: true },
    {
      title: 'Name',
      field: 'blockName',
      hidden: props.blockId != null,
      render: blockReport => (
        <Link component={RouterLink} to={`/blocks/${blockReport.blockId}`}>
          {blockReport.blockName}
        </Link>
      )
    },
    {
      title: 'Reported By',
      field: 'playerDisplayName',
      sorting: true,
      render: blockReport => UserService.canRead('player') ? (
        <Link component={RouterLink} to={`/players/${blockReport.playerExternalId}`}>
          {blockReport.playerDisplayName || blockReport.playerExternalId}
        </Link>
      ) : <>{blockReport.playerDisplayName || blockReport.playerExternalId}</>
    },
    { title: 'Reason', field: 'reason', sorting: true },
    { title: 'Description', field: 'description', sorting: true },
    {
      title: 'Reported Date',
      field: 'createdTimestamp',
      sorting: true,
      defaultSort: 'desc',
      render: blockReport => <>{new Date(blockReport.createdTimestamp).toLocaleString()}</>
    },
    { title: 'Note', field: 'note', sorting: true, hidden: !showClosedReportsOnly }
  ];

  return (<>
    <Box mb={2}>
      <Grid container alignItems="center">
        <Grid item xs>
          <FormControlLabel
            control={
              <Switch
                checked={showClosedReportsOnly}
                onChange={event => setShowClosedReportsOnly(event.target.checked)}
                color="primary"
              />
            }
            label="Show Closed Reports"
          />
        </Grid>
      </Grid>
    </Box>
    <MaterialTableCallback
      title={'Reported Blocks'}
      tableRef={tableRef}
      columns={columns}
      data={BlocksService.getBlockReportsPage}
      options={{
        debounceInterval: 500,
        pageSize: 10,
        thirdSortClick: false
      }}
      menuActions={showClosedReportsOnly ? [] : getTableMenuActions}
      filters={filters}
      blockId={blockId}
    />
  </>);
}
import { Chip, Tooltip } from '@material-ui/core';
import AppBar from '@material-ui/core/AppBar';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import amber from '@material-ui/core/colors/amber';
import blueGrey from '@material-ui/core/colors/blueGrey';
import green from '@material-ui/core/colors/green';
import red from '@material-ui/core/colors/red';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import Popover from '@material-ui/core/Popover';
import type { Theme, WithStyles} from '@material-ui/core/styles';
import { createStyles, withStyles } from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import AccountCircle from '@material-ui/icons/AccountCircle';
import Brightness3Icon from '@material-ui/icons/Brightness3';
import BrightnessHighIcon from '@material-ui/icons/BrightnessHigh';
import MenuIcon from '@material-ui/icons/Menu';
import { createRef, Component } from 'react';
import type { ConnectedProps } from 'react-redux';
import { connect } from 'react-redux';
import config from '../config';
import { setDarkMode } from '../redux/app/actions';
import { reqLogout } from '../redux/authentication/actions';
import type { RootState } from '../redux/reducers';
import { toggleSidebar } from '../redux/sidebar/actions';
import { drawerWidth } from './SideBar/SideBar';

const styles = (theme: Theme) => createStyles({
  appBar: {
    [theme.breakpoints.up('sm')]: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth,
    },
  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  },
  title: {
    flexGrow: 1
  }
});

const mapStateToProps = (state: RootState) => ({
  oAuthClient: state.authentication.oAuthClient,
  darkMode: state.app.darkMode
});
const mapDispatch = { toggleSidebar, reqLogout, setDarkMode };
const connector = connect(mapStateToProps, mapDispatch);

type Props = WithStyles<typeof styles> & ConnectedProps<typeof connector>;
interface State {
  profileOpen: boolean;
}

const envTags: { [key: string]: { name: string; color: string; }; } = {
  local: { name: 'Local', color: blueGrey['600'] },
  dev: { name: 'Dev', color: green['700'] },
  qa: { name: 'QA', color: amber['900'] },
  loadtest: { name: 'Load testing', color: blueGrey['600'] },
  prod: { name: 'Production', color: red['700'] },
  unknown: { name: 'Unknown', color: blueGrey['500'] }
};

class MainAppBar extends Component<Props, State> {
  private anchorRef = createRef<HTMLDivElement>();

  constructor(props: Props) {
    super(props);
    this.state = {
      profileOpen: false
    };
  }

  toggleDarkMode = () => {
    const darkMode = !this.props.darkMode;
    this.props.setDarkMode(darkMode);
  };

  handleDrawerToggle = () => {
    this.props.toggleSidebar();
  };

  handleProfileClick = () => {
    this.setState({ profileOpen: !this.state.profileOpen });
  };

  renderProfileButton() {
    if (!this.props.oAuthClient || !this.props.oAuthClient.user) {
      return null;
    }

    const classes = this.props.classes;

    // id token contains more info than what the
    // typescript definition says...
    const profile: any = this.props.oAuthClient.user.profile;
    const envTag = envTags[config.env] || envTags.unknown;

    return (<>
      <IconButton
        color="inherit"
        edge="start"
        onClick={this.handleDrawerToggle}
        className={classes.menuButton}
      >
        <MenuIcon />
      </IconButton>
      <Typography variant="h6" noWrap className={classes.title}>
        Block Party Admin
        <Box display="inline-block" ml={2}>
          <Tooltip title="Current environment">
            <Chip
              label={envTag.name} color="primary"
              style={{
                backgroundColor: envTag.color,
                color: '#fff',
                fontWeight: 'bold',
                textShadow: '1px 1px 1px black'
              }}
            />
          </Tooltip>
        </Box>
      </Typography>
      <Tooltip title={this.props.darkMode ? 'Switch to light theme' : 'Switch to dark theme'}>
        <IconButton color="inherit" onClick={this.toggleDarkMode}>
          {this.props.darkMode ? <BrightnessHighIcon /> : <Brightness3Icon />}
        </IconButton>
      </Tooltip>
      <IconButton color="inherit" onClick={this.handleProfileClick}>
        <AccountCircle />
      </IconButton>
      {this.anchorRef.current && <Popover
        anchorEl={this.anchorRef.current}
        open={this.state.profileOpen}
        onClose={() => this.setState({ profileOpen: false })}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <Box p={4}>
          <Typography variant="h6">{profile.name}</Typography>
          <Typography variant="body2">{profile.email}</Typography>
        </Box>
        <Divider />
        <Button fullWidth color="primary" onClick={() => this.props.reqLogout()}>Log Out</Button>
      </Popover>}
    </>);
  }

  render() {
    const classes = this.props.classes;

    return (
      <AppBar position="fixed" elevation={0} className={classes.appBar}>
        <Toolbar ref={this.anchorRef}>
          {this.renderProfileButton()}
        </Toolbar>
      </AppBar>
    );
  }
}

export default connector(withStyles(styles)(MainAppBar));
import { useHistory, useLocation, useRouteMatch } from 'react-router';
import type { LinkTabConfig } from '../../components/LinkTabs';
import { LinkTabs } from '../../components/LinkTabs';
import BlankoProgressionsPage from './BlankoProgressionsPage';
import { SkillDropTablesPage } from './SkillDropTablesPage';

export const BlankoProgressions = () => {
  const tabs: LinkTabConfig[] = [];
  tabs.push({ url: '/progressions', label: 'Progressions', absolute: true, component: BlankoProgressionsPage });
  tabs.push({ url: '/progression-skill-drops', label: 'Skill Drop Tables', absolute: true, component: SkillDropTablesPage });

  const history = useHistory();
  const location = useLocation();
  const match = useRouteMatch();

  return (<>
    <LinkTabs tabs={tabs} history={history} location={location} match={match} />
  </>);
};

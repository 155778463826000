import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router';
import { openConfirmationDialog } from '../../../redux/app/actions';
import { cmPreloadData, cmSetCatalogName, cmStartMigration } from '../../../redux/catalog-migration/actions';
import { selectCatalog } from '../../../redux/catalog-migration/selectors';
import { useTypedSelector } from '../../../redux/reducers';
import { CatalogMigration } from './CatalogMigration';

const LAST_STEP = 13;

const CatalogMigrationPage = () => {
  const dispatch = useDispatch();
  const location = useLocation();

  const [step, setStep] = useState(1);

  const sourceEnv = useTypedSelector(state => state.catalogMigration.source.id);
  const targetEnv = useTypedSelector(state => state.catalogMigration.target.id);
  const sourceCatalog = useTypedSelector(state => selectCatalog(state, 'source'));
  const targetCatalog = useTypedSelector(state => selectCatalog(state, 'target'));
  const migrationRunning = useTypedSelector(state => state.catalogMigration.run.running);
  const migrationFinished = useTypedSelector(state => state.catalogMigration.run.finished);
  const { options, actions } = useTypedSelector(state => state.catalogMigration);

  const nextDisabled = !Object.values(options).some(Boolean);

  let canRunMigration = false;
  if (step === LAST_STEP) {
    for (const key in actions) {
      if (actions[key].add.length + actions[key].update.length + actions[key].remove.length > 0) {
        canRunMigration = true;
        break;
      }
    }
  }

  const canViewStep = (step: number) => {
    const isDifferentEnvironments = sourceEnv !== targetEnv;
    const isDifferent = isDifferentEnvironments || sourceCatalog !== targetCatalog;
    switch (step) {
    case 2: return isDifferent && (options.items || options.bundles || options.containers);
    case 3: return isDifferent && options.dropTables;
    case 4: return isDifferent && options.stores;
    case 5: return isDifferentEnvironments && options.titleDataClient;
    case 6: return isDifferentEnvironments && options.titleDataServer;
    case 7: return isDifferentEnvironments && options.titleDataFiles;
    case 8: return isDifferentEnvironments && options.blankoDnas;
    case 9: return isDifferentEnvironments && options.blankoAssets;
    case 10: return isDifferentEnvironments && options.seasonalData;
    case 11: return isDifferentEnvironments && options.blankoProgressions;
    case 12: return isDifferentEnvironments && options.translations;
    default: return true;
    }
  }

  const updateStep = (dir: number) => {
    let newStep = step + dir;
    while (!canViewStep(newStep)) {
      newStep += dir;
    }

    if (step === 1) {
      dispatch(cmPreloadData());
    }

    setStep(newStep);
  }

  const runMigration = () => {
    dispatch(openConfirmationDialog({
      title: 'Run migration?',
      details: 'This process cannot be cancelled or undone! This may also take a while. Please don\'t close this window while the migration is running.',
      action: 'Run migration'
    }, () => {
      dispatch(cmStartMigration());
    }));
  }

  useEffect(() => {
    const query = new URLSearchParams(location.search);
    const sourceCatalogName = query.get('source');
    if (sourceCatalogName) {
      dispatch(cmSetCatalogName({ env: 'source', catalogName: sourceCatalogName }));
    }
  }, [dispatch, location]);

  return (<CatalogMigration
    step={step}
    lastStep={LAST_STEP}
    isLoading={(sourceCatalog.isLoading || !sourceCatalog.catalog || targetCatalog.isLoading || !targetCatalog.catalog)}
    migrationRunning={migrationRunning}
    migrationFinished={migrationFinished}
    canRunMigration={canRunMigration}
    nextDisabled={nextDisabled}
    updateStep={updateStep}
    runMigration={runMigration} />);
}

export default CatalogMigrationPage;
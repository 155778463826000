import { useDispatch } from 'react-redux';
import { cmSetOptions } from '../../../../redux/catalog-migration/actions';
import { OptionSingleCheckbox } from './OptionSingleCheckbox';

interface OptionSingleCheckboxContainerProps {
  label: string;
  checked: boolean;
  disabled?: boolean;
  payload: { [key: string]: boolean };
}

export const OptionSingleCheckboxContainer = ({
  label,
  checked,
  disabled,
  payload
}: OptionSingleCheckboxContainerProps) => {
  const dispatch = useDispatch();
  const onClick = () => dispatch(cmSetOptions(payload));

  return (<OptionSingleCheckbox
    label={label}
    checked={checked}
    disabled={disabled}
    onClick={onClick} />);
}
import { Box, Button, Grid, Link, TextField, Typography } from '@material-ui/core';
import type { Column, EditComponentProps } from 'material-table';
import { ChangeEvent, useMemo , useCallback, useState } from 'react';
import MaterialTable, { MenuAction } from '../../../components/MaterialTable';
import type { ShopTab } from '../shop';
import {  deleteShopTab, updateShopTab } from '../shopsApi';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import { ShopTabEditDialog } from './ShopTabEditDialog';
import { openAdminConfirmationDialog } from '../../../shared/hooks/useAdminConfirmationDialog';
import { usePushNotification } from '../../../contexts/AppNotificationContext';
import { tab } from '@testing-library/user-event/dist/tab';
import { ShopTabsEditorImages } from './ShopTabsEditorImages';

export interface ShopTabsEditorProps {
  shopVersionId: string;
  tabs: ShopTab[];
  onTabsUpdate: (tabs: ShopTab[]) => void;
}

export const ShopTabsEditor = (props: ShopTabsEditorProps) => {
  const pushNotification = usePushNotification();

  const { shopVersionId, tabs, onTabsUpdate } = props;
  const [showShopTabEditor, setShowShopTabEditor] = useState(false);
  const [shopTabEditorShopTab, setShopTabEditorShopTab] = useState<ShopTab | null>(null);

  const editTab = useCallback((tab: ShopTab | null) => {
    setShowShopTabEditor(true);
    setShopTabEditorShopTab(tab);
    return Promise.resolve();
  }, []);

  const handleEditChange = useCallback((tab: ShopTab) => {
    const newTabs = tabs.slice();
    const index = newTabs.findIndex(v => v.id === tab.id);
    if (index === -1) {
      newTabs.push(tab);
    } else {
      newTabs[index] = tab;
    }
    onTabsUpdate(newTabs);
  }, [tabs, onTabsUpdate]);

  const deleteTab = useCallback((tab: ShopTab) => {
    openAdminConfirmationDialog({
      title: `Delete ${tab.name}?`,
      action: 'Delete',
      onConfirm: () => {
        deleteShopTab(tab.id)
          .then(() => onTabsUpdate(tabs.filter(v => v.id !== tab.id)))
          .catch(err => pushNotification({ type: 'error', message: `Failed to delete tab: ${err.message}` }));
      }
    });
  }, [tabs, onTabsUpdate, pushNotification]);

  const moveTab = useCallback((tab: ShopTab, dir: number) => {
    if ((tab.position < 1 && dir < 0) || (tab.position >= tabs.length - 1 && dir > 0)) {
      return;
    }

    updateShopTab({ ...tab, position: tab.position + dir }).then(() => {
      const newTabs = tabs.slice();
      newTabs[tab.position] = newTabs[tab.position + dir];
      newTabs[tab.position].position = tab.position;
      newTabs[tab.position + dir] = tab;
      tab.position += dir;
      onTabsUpdate(newTabs);
    });

  }, [onTabsUpdate, tabs]);

  const moveTabUp = useCallback((tab: ShopTab) => {
    moveTab(tab, -1);
  }, [moveTab]);

  const moveTabDown = useCallback((tab: ShopTab) => {
    moveTab(tab, 1);
  }, [moveTab]);

  const tabsColumns: Column<ShopTab>[] = useMemo(() => {
    return [
      {
        title: 'Name',
        field: 'name',
        sorting: false,
        render: tab => (
          <Grid container spacing={1} alignItems="center">
            <Link component="button" onClick={() => editTab(tab)}>
              {tab.name}
            </Link>
          </Grid>
        )
      },
      {
        title: 'Link Images',
        render: tab => (
          <ShopTabsEditorImages
            shopTab={tab}
            handleClick={editTab}
          />
        )
      }
    ]
  }, [editTab]);

  const menuActions: MenuAction<ShopTab>[] = useMemo(() => {
    return [
      { type: 'button', icon: ArrowUpwardIcon, label: 'Move Up', onClick: moveTabUp },
      { type: 'button', icon: ArrowDownwardIcon, label: 'Move Down', onClick: moveTabDown },
      { type: 'button', icon: EditIcon, label: 'Edit', onClick: editTab },
      { type: 'button', icon: DeleteIcon, label: 'Delete', onClick: deleteTab }
    ];
  }, [deleteTab, editTab, moveTabDown, moveTabUp]);

  return (
    <>
      <Box mb={2}>
        <Grid container spacing={2} alignItems="center">
          <Grid item xs>
            <Typography variant="h6">
              Manage Tabs
            </Typography>
          </Grid>
          <Grid item>
            <Button variant="contained" color="primary" onClick={() => editTab(null)}>Add Tab</Button>
          </Grid>
        </Grid>
      </Box>
      <MaterialTable<ShopTab>
        title="Manage tabs"
        columns={tabsColumns}
        data={tabs}
        menuActions={menuActions}
        options={{
          paging: false,
          search: false,
          showTitle: false,
          sorting: false,
          toolbar: false,
        }}
      />

      {
        showShopTabEditor && (
          <ShopTabEditDialog
            shopTab={shopTabEditorShopTab}
            shopVersionId={shopVersionId}
            onChange={tab => handleEditChange(tab)}
            onClose={() => setShowShopTabEditor(false)}
          />
        )
      }
    </>
  );
}

const TabNameInputComponent = (props: EditComponentProps<ShopTab>) => {
  const [name, setName] = useState(props.rowData.name || '');
  const [error, setError] = useState('');

  const { onChange } = props;

  const onInputChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    const newName = event.target.value;
    setName(newName);
    setError(newName.trim() === '' ? 'Name is required' : '');
    onChange(newName);
  }, [onChange]);

  return (
    <TextField
      fullWidth
      autoFocus
      onChange={onInputChange}
      error={Boolean(error)}
      helperText={error}
      value={name}
      inputProps={{
        maxLength: 100
      }}
    />
  )
}
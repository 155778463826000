import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';
import Link from '@material-ui/core/Link';
import DeleteIcon from '@material-ui/icons/Delete';
import type { Column } from 'material-table';
import { useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import ConfirmationDialog from '../../../components/ConfirmationDialog';
import MaterialTable from '../../../components/MaterialTable';
import type { ItemDefinition, ItemType } from '../../../services/item-definitions';
import { ItemDefinitionsService } from "../../../services/item-definitions";
import { UserService } from '../../../services/user';
import type { Catalog } from "../../../services/catalogs";
import { pushAppNotification } from "../../../shared/hooks/useAppNotification";
import { capitalize } from "lodash";

type Props = {
  catalog: Catalog;
  itemType: ItemType;
  items: ItemDefinition[];
  loadingItems: boolean;
  setItems: (items: ItemDefinition[]) => void;
};

export const CatalogItems = ({catalog, items, setItems, itemType, loadingItems}: Props) => {
  const [confirmationOpen, setConfirmationOpen] = useState(false);
  const [confirmationTitle, setConfirmationTitle] = useState('');
  const [itemToDelete, setItemToDelete] = useState<ItemDefinition | null>(null);
  const confirmationCallback = () => {
    if (itemToDelete) {
      ItemDefinitionsService.deleteItemDefinition(itemToDelete)
        .then(() => {
          pushAppNotification({ type: 'success', message: 'Item deleted' });
          setItems(items.filter(i => i !== itemToDelete));
        })
        .catch(err => {
          pushAppNotification({type: 'error', message: `Failed to delete catalog item: ${err.message}`});
        });
      setConfirmationOpen(false);
    }
  };
  const filterItems = () => {
    return items.filter(item => item.getType() === itemType);
  };

  const getTableTitle = () => {
    return `${capitalize(itemType)}s`;
  };

  const renderItemLink = (item: ItemDefinition) => {
    const url = `/catalogs/${catalog.name}/items/${item.itemId}`;
    return (
      <Link component={RouterLink} to={url}>{item.itemId}</Link>
    );
  };

  const columns: Column<ItemDefinition>[] = [
    {
      title: '',
      field: 'imageThumbnailUrl',
      cellStyle: {padding: 0, width: 64, lineHeight: 0.5},
      render: item => item.imageThumbnailUrl ? (
        <img src={item.imageThumbnailUrl} width={64} height={64} alt={item.itemId}/>
      ) : (<Box width={64} height={64}/>)
    },
    {
      title: 'ID',
      field: 'itemId',
      defaultSort: 'asc',
      render: renderItemLink,
      cellStyle: {width: 1, whiteSpace: 'nowrap'}
    },
    {title: 'Display name', field: 'displayName', cellStyle: {width: 1, whiteSpace: 'nowrap'}},
    {title: 'Item class', field: 'itemClass', cellStyle: {whiteSpace: 'nowrap'}},
    {
      title: 'Notes',
      field: 'notes',
      cellStyle: {
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        width: '50%',
        maxWidth: 0
      }
    }
  ];

  const deleteItem = (item: ItemDefinition) => {
    setItemToDelete(item);
    setConfirmationOpen(true);
    setConfirmationTitle(`Delete ${item.itemId}?`);
  };

  return loadingItems ? (
    <Box textAlign="center">
      <CircularProgress/>
    </Box>
  ) : (<>
    {UserService.canCreate('catalogItems') && (
      <Box mb={2}>
        <Grid container alignItems="center">
          <Grid item xs/>
          <Grid item>
            <Button
              component={RouterLink}
              variant="contained"
              color="primary"
              to={`/catalogs/${catalog.name}/add-item?type=${itemType}`}
            >
              Add {itemType}
            </Button>
          </Grid>
        </Grid>
      </Box>
    )}

    <MaterialTable
      title={getTableTitle()}
      columns={columns}
      data={filterItems()}
      options={{
        actionsColumnIndex: 1,
        pageSize: 20,
        pageSizeOptions: [20, 50, 100]
      }}
      menuActions={UserService.canDelete('catalogItems') ? [
        {type: 'button', icon: DeleteIcon, label: 'Delete', onClick: deleteItem}
      ] : undefined}
      useRouter
    />

    <ConfirmationDialog
      title={confirmationTitle}
      open={confirmationOpen}
      onClose={() => setConfirmationOpen(false)}
      onConfirm={confirmationCallback}
    />
  </>);
};

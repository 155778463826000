/* eslint-disable @typescript-eslint/ban-types */
import MoreVertIcon from '@material-ui/icons/MoreVert';
import type { Action, MaterialTableProps, Options } from 'material-table';
import MaterialTable from 'material-table';
import { useState, useRef, useEffect, useCallback } from 'react';


import { tableIcons } from '../../../utils/tableIcons';
import type { RowMenuAction } from '.';
import { RowMenu } from '.';
import { useLocation, useHistory } from 'react-router';
import { useLocalStorage } from '../../hooks/useLocalStorage';

interface AdminTableProps<T extends object> extends MaterialTableProps<T> {
  menuActions?: RowMenuAction<T>[] | ((item: T) => RowMenuAction<T>[]);
  useRouter?: boolean
  storageId: string
};

// A custom hook that builds on useLocation to parse
// the query string.
function useQuery() {
  return new URLSearchParams(useLocation().search);
}

export function AdminTable<T extends object>({
  menuActions,
  columns,
  data,
  actions,
  options,
  localization,
  onChangePage,
  onChangeRowsPerPage,
  onSearchChange,
  tableRef,
  onOrderChange,
  useRouter,
  storageId,
  ...props
}: AdminTableProps<T> ) {
  const localTableRef = useRef(tableRef)
  const query = useQuery()
  const location = useLocation()
  const history = useHistory()
  const [initialState, setInitialState] = useLocalStorage(`admin-table-${storageId}`, {})
  const [sortId, setSortId] = useState(Number(query.get('sortId') || -1))
  const [columnsState, setColumns] = useState(useRouter ? columns : initialState.columns)
  const [sortDir, setSortDir] = useState<'asc' | 'desc'>(query.get('sortDir') as ("asc" | "desc") || 'asc')
  const [pageSize, setPageSize] = useState(Number(query.get('pageSize')) || initialState.options?.pageSize || options?.pageSize || 10)
  const [currentPage, setCurrentPage] = useState((Number(query.get('page') || '1') || 1) - 1 || 0);
  const [search, setSearch] = useState(query.get('search') || '');
  const [LocalizationState, setLocalizationState] = useState(localization)
  const [actionsState, setActionsState] = useState(actions);
  const [optionsState, setOptionsState] = useState<Options | undefined>(useRouter ? undefined : initialState.options);
  const [menuAnchor, setMenuAnchor] = useState<HTMLElement | undefined>(undefined)
  const [menuItem, setMenuItem] = useState<T>()
  useEffect(() => {
    let rows = 0;
    if (Array.isArray(data)) {
      rows = data.length;
    }

    const lastPage =  Math.max(Math.ceil(rows / pageSize) - 1, 0)
    const initialPage = Math.min(currentPage, lastPage);
    const a = actions || [];
    if (menuActions) {
      const menuAction: Action<T> = {
        // eslint-disable-next-line react/display-name
        icon: () => <MoreVertIcon />,
        onClick: (event, item) => {
          if (event.currentTarget instanceof HTMLElement && !Array.isArray(item)) {
            setMenuAnchor(event.currentTarget)
            setMenuItem(item);
          }
        }
      };

      if (Array.isArray(menuActions)) {
        if (menuActions.length > 0) {
          a.push(menuAction);
        }
      } else {
        const menuActionsFn = menuActions;
        a.push((item: T) => {
          menuAction.hidden = menuActionsFn(item).length < 1;
          return menuAction;
        });
      }
    }
    
    const l = localization || {};
    if (!l.header || !l.header.actions) {
      l.header = { ...l.header, actions: '' };
    }

    setActionsState(a.length ? a : undefined)
    setOptionsState({...options, draggable: options?.draggable ? options.draggable : false, pageSize, initialPage, searchText: search })
    setLocalizationState(l)
    const mappedColumns = columns.map((column, index) => { 
      if (useRouter && sortId > -1) {
        column.defaultSort = index === sortId ? sortDir : undefined
      }
      return column
    })
    setColumns(mappedColumns)
  }, [localization, pageSize, currentPage, search, actions, menuActions, data, columns, sortId, sortDir, options, useRouter]);
  
  useEffect(() => {
    if (!useRouter) {
      setInitialState({ columns: columnsState , options: optionsState})
    }
  }, [columnsState, optionsState, setInitialState, useRouter, options, columns])
  
  const handleChangePage = useCallback((page: number) => {
    setCurrentPage(page);
    useRouter && query.set('page', (page + 1).toString())
    if (onChangePage) {
      onChangePage(page);
    }
    useRouter && history.replace(`${location.pathname}?${query.toString()}`);
  }, [history, location.pathname, onChangePage, query, useRouter]);

  const handleChangeRowsPerPage = useCallback((pageSize: number) => {
    setPageSize(pageSize);
    useRouter && query.set('pageSize', pageSize.toString())
    if (onChangeRowsPerPage) {
      onChangeRowsPerPage(pageSize);
    }
    
    useRouter && history.replace(`${location.pathname}?${query.toString()}`);
  }, [history, location.pathname, onChangeRowsPerPage, query, useRouter]);

  const handleSearchChange = useCallback((search: string) => {
    setSearch(search);
    if(search && useRouter) {
      query.set('search', search)
    } else {
      query.delete('search')
    }
    if (onSearchChange) {
      onSearchChange(search);
    }

    // Searching can change the current page, another detail MaterialTable fails to expose properly...
    // Luckily, this is javascript...
    if (localTableRef.current && localTableRef.current.dataManager) {
      useRouter && query.set('page', localTableRef.current.dataManager.currentPage + 1)
      setCurrentPage(localTableRef.current.dataManager.currentPage);
    }

    useRouter && history.replace(`${location.pathname}?${query.toString()}`);
  }, [useRouter, query, onSearchChange, history, location.pathname])

  const handleOrderChange = useCallback((id: number, dir: 'asc' | 'desc') => {
    if(id && dir && useRouter) {
      query.set('sortDir', dir)
      query.set('sortId', id.toString())
    } else {
      query.delete('sortDir')
      query.delete('sortId')
    }
    setSortId(id)
    setSortDir(dir)
    if (onOrderChange) {
      onOrderChange(id, dir);
    }
    
    // Changing sort will update the page but MaterialTable doesn't notify us...
    if (localTableRef.current && localTableRef.current.dataManager) {
      useRouter && query.set('page', localTableRef.current.dataManager.currentPage + 1)
      setCurrentPage(localTableRef.current.dataManager.currentPage);
    }

    useRouter && history.replace(`${location.pathname}?${query.toString()}`);
  }, [history, location.pathname, onOrderChange, query, useRouter])

  const handleMenuOnClose = useCallback(() => {
    setMenuAnchor(undefined)
    setMenuItem(undefined)
  }, [])
  
  return (<>
    {optionsState && <MaterialTable<T>
      {...props}
      columns={initialState.columns || columnsState}
      data={data}
      icons={tableIcons}
      actions={actionsState}
      options={initialState.options || optionsState}
      onChangePage={handleChangePage}
      onChangeRowsPerPage={handleChangeRowsPerPage}
      onSearchChange={handleSearchChange}
      onOrderChange={handleOrderChange}
      localization={LocalizationState}
      tableRef={localTableRef}
    />}

    {menuActions && menuAnchor && menuItem && (
      <RowMenu<T> menuActions={menuActions} onClose={handleMenuOnClose} anchorEl={menuAnchor} actionMenuItem={menuItem} />
    )}
  </>);
}

import type { MigrationDiffCommonProps } from '../../migrations';
import { DiffsPanels } from '../../components/diff/DiffsPanels';

export const getTitleDataDiffsComponent = (type: string) => {
  function TitleDataDiffs(props: MigrationDiffCommonProps) {
    const diffs = type === 'client' ? props.migrationData.titleDataClient : props.migrationData.titleDataServer;

    if (diffs) {
      const selectionKey = type === 'client' ? 'titleDataClient' : 'titleDataServer';

      return (
        <DiffsPanels
          diffs={diffs}
          columns={[
            {
              title: 'Key', field: 'diffId', defaultSort: 'asc', render(item) {
                return (<>
                  {item.diffId}
                </>);
              }
            }
          ]}
          selectionMap={props.selections[selectionKey] || {}}
          onSelectionChange={map => props.onSelectionsChange({ ...props.selections, [selectionKey]: map })}
          {...props}
        />
      )
    }

    return null;
  }

  return TitleDataDiffs;
}

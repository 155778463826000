import { Grid, IconButton, Typography } from '@material-ui/core';
import BugReportIcon from '@material-ui/icons/BugReport';
import { useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router';
import config, { configs } from '../../../config';
import type {
  EnvironmentData,
  MigrationCommonProps,
  MigrationData,
  MigrationFrameworkProps,
  MigrationStep
} from '../migrations';
import { MigrationDependencyTree } from '../migrations';
import { migrationModules, migrationRunOrder} from '../modules/migration-modules';
import type { HiddenItem } from "../../../pages/CatalogMigration/types";
import { CatalogMigrationService } from "../../../services/catalog-migration";
import { usePushNotification } from "../../../contexts/AppNotificationContext";
import { MigrationType } from "../modules/migration-type";

export const MigrationFramework = (props: MigrationFrameworkProps) => {
  const location = useLocation();
  const locationQuery = new URLSearchParams(location.search);
  const pushNotification = usePushNotification();

  const [hiddenItems, setHiddenItems] = useState<HiddenItem[]>([]);
  useEffect(() => {
    CatalogMigrationService.getMigrationHiddenItems()
      .then(hiddenItems => setHiddenItems(hiddenItems))
      .catch(err => pushNotification({type: 'error', message: `Failed to get migration hidden items: ${err.message}`}));
  }, [pushNotification]);

  const [steps, setSteps] = useState<MigrationStep[]>(props.migrationSteps.slice());
  const [step, setStep] = useState<MigrationStep>(props.migrationSteps[0]);

  const [sourceData, setSourceData] = useState<EnvironmentData>({
    env: config.env,
    catalogName: locationQuery.get('source') || ''
  });

  const [targetData, setTargetData] = useState<EnvironmentData>({
    env: config.env,
    catalogName: ''
  });

  const [migrationData, setMigrationData] = useState<MigrationData>({
    userOptions: {},
    options: {},
    depTree: new MigrationDependencyTree()
  });

  const [selections, setSelections] = useState<{ [key in MigrationType]?: { [key: string]: boolean } }>({});

  useEffect(() => {
    if (step.id === 'options') {
      setSteps(props.migrationSteps.slice());
      setSourceData(sourceData => ({ env: sourceData.env, catalogName: sourceData.catalogName }));
      setTargetData(targetData => ({ env: targetData.env, catalogName: targetData.catalogName }));
      setMigrationData({ userOptions: {}, options: {}, depTree: new MigrationDependencyTree() });
      setSelections({});
    }
  }, [step, props.migrationSteps]);

  const commonProps: MigrationCommonProps = useMemo(() => ({
    ...props,
    sourceData,
    targetData,
    hiddenItems,
    setHiddenItems,
    onSourceChange: setSourceData,
    onTargetChange: setTargetData,
    migrationData,
    onMigrationDataChange: setMigrationData,
    selections,
    onSelectionsChange: setSelections,
    onPrevStep: () => {
      const index = steps.findIndex(v => v.id === step.id);
      setStep(steps[index > 0 ? index - 1 : 0]);
    },
    onNextStep: () => {
      const index = steps.findIndex(v => v.id === step.id);
      if (index < steps.length - 1) {
        let step = steps[index + 1];

        // Figure out a better place for this. Before loading, check if there are any preload steps to take.
        if (step.id === 'loading') {
          let allPreloadSteps: MigrationStep[] = [];
          for (const id of migrationRunOrder) {
            if (migrationData.options[id]) {
              const preloadSteps = migrationModules[id]?.preloadSteps;
              if (preloadSteps && preloadSteps.length > 0) {
                allPreloadSteps = allPreloadSteps.concat(preloadSteps);
              }
            }
          }

          const newSteps = [props.migrationSteps[0], ...allPreloadSteps, ...props.migrationSteps.slice(1)];
          setSteps(newSteps);
          step = newSteps[index + 1];
        }

        setStep(step);
      }
    },
    onSetStep: (id: string) => {
      if (step.id !== id) {
        const index = steps.findIndex(v => v.id === id);
        if (index > -1) {
          setStep(steps[index]);
        }
      }
    },
  }), [props, sourceData, targetData, hiddenItems, migrationData, selections, steps, step.id]);

  const sourceEnv = configs[sourceData.env];
  const targetEnv = configs[targetData.env];

  return (<>
    <Grid container spacing={1}>
      <Grid item xs>
        <Typography variant="h5">
          {props.getMainTitle(sourceEnv?.name, sourceData.catalogName, targetEnv?.name, targetData.catalogName)}
          {step.title && ` - ${step.title}`}
        </Typography>
      </Grid>
      {/* Until the new tool is more stable, include a button to dump all the migration data to console. */}
      <Grid item xs="auto">
        <IconButton onClick={() => console.log(migrationData, selections, sourceData, targetData)}>
          <BugReportIcon />
        </IconButton>
      </Grid>
    </Grid>

    <step.component {...commonProps} />
  </>);
}

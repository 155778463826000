import type { Reducer } from 'redux';
import { createReducer } from 'typesafe-actions';
import type { CurrencyLimit } from '../../services/currency-limits';
import type { APIResource } from '../utils-ts';
import { addCurrencyLimitAsync, deleteCurrencyLimitAsync, getCurrencyLimitsAsync, updateCurrencyLimitAsync } from './actions';

const currencyLimitsReducer: Reducer<APIResource<CurrencyLimit[]>> = createReducer<APIResource<CurrencyLimit[]>>({ isLoading: false })
  .handleAction(getCurrencyLimitsAsync.request, (state) => ({
    ...state,
    isLoading: true
  }))
  .handleAction(getCurrencyLimitsAsync.success, (state, action) => ({
    ...state,
    isLoading: false,
    data: action.payload
  }))
  .handleAction(getCurrencyLimitsAsync.failure, (state) => ({
    ...state,
    isLoading: false
  }))

  .handleAction(addCurrencyLimitAsync.request, (state) => ({
    ...state,
    isLoading: true
  }))
  .handleAction(addCurrencyLimitAsync.success, (state, action) =>  ({
    ...state,
    data: state.data ? state.data.concat(action.payload) : null,
    isLoading: false
  }))
  .handleAction(addCurrencyLimitAsync.failure, (state) => ({
    ...state,
    isLoading: false
  }))

  .handleAction(deleteCurrencyLimitAsync.request, (state) => ({
    ...state,
    isLoading: true
  }))
  .handleAction(deleteCurrencyLimitAsync.success, (state, action) => {
    const newData = state.data ? state.data.slice(0) : [];
    if (newData) {
      let idx = -1;
      newData.forEach((cl, i) => { if (cl.currencyCode === action.payload.currencyCode) { idx = i; }  });      
      if (idx >= 0) { newData.splice(idx, 1); }
    };
    return ({
      ...state,
      data: newData,
      isLoading: false
    })
  })
  .handleAction(deleteCurrencyLimitAsync.failure, (state) => ({
    ...state,
    isLoading: false
  }))
  
  
  .handleAction(updateCurrencyLimitAsync.request, (state) => ({
    ...state,
    isLoading: true
  }))
  .handleAction(updateCurrencyLimitAsync.success, (state, action) => {
    const newData = state.data ? state.data.slice(0) : [];
    if (newData) {
      newData.forEach((cl, i) => { if (cl.currencyCode === action.payload.currencyCode) { newData[i] = action.payload; }  });      
    };
    return ({
      ...state,
      data: newData,
      isLoading: false
    })
  })
  .handleAction(updateCurrencyLimitAsync.failure, (state) => ({
    ...state,
    isLoading: false
  }));

export default currencyLimitsReducer;
export function getNullableDateFromEpochSeconds(epochSeconds: number | null): Date | null {
  if (epochSeconds === undefined || epochSeconds === null) {
    return null;
  }
  return getDateFromEpochSeconds(epochSeconds);
}

export function getNullableEpochSecondsFromDate(date: Date | null): number | null {
  if (date === undefined || date === null) {
    return null;
  }
  return getEpochSecondsFromDate(date);
}

export function getDateFromEpochSeconds(epochSeconds: number): Date {
  return new Date(epochSeconds * 1000);
}

export function getEpochSecondsFromDate(date: Date): number {
  const utcMilllisecondsSinceEpoch = date.getTime(); // + (date.getTimezoneOffset() * 60 * 1000);
  const utcSecondsSinceEpoch = Math.round(utcMilllisecondsSinceEpoch / 1000);
  return utcSecondsSinceEpoch;
}

export function getDateFromNullableTime(time: string | null): Date | null {
  if (!time) {
    return null;
  }
  const [hours, minutes] = time.split(':').map(n => parseInt(n, 10));
  const date = new Date();
  date.setHours(hours);
  date.setMinutes(minutes);
  date.setSeconds(0);
  return date;
}

export function getTimeFromNullableDate(date: Date | null): string {
  if (date === undefined || date === null) {
    return '';
  }
  return `${date.getHours().toString().padStart(2, '0')}:${
    date.getMinutes().toString().padStart(2, '0')}:${
    date.getSeconds().toString().padStart(2, '0')}`;
}

import type { RefObject } from "react";
import { createRef, Component } from "react";
import type {ChallengeMap, TemplateMap} from "../../../services/player-challenges/season-challenge-schedule-columns";
import { getDailyColumns, getSeasonalColumns, getWeeklyColumns} from "../../../services/player-challenges/season-challenge-schedule-columns";
import type {MenuAction} from "../../../components/MaterialTable";
import MaterialTable from "../../../components/MaterialTable";
import type {ScheduledChallenge} from "../../../services/player-challenges/season-challenge-schedule";
import type {Column} from "material-table";
import type {ScheduleCalendar} from "../../../services/player-challenges/season-challenge-schedule-calendar";
import { ScheduleCalendarTypeEnum} from "../../../services/player-challenges/season-challenge-schedule-calendar";
import type { Template} from "../../../services/player-challenges/challenge-templates";
import {ChallengeTemplate} from "../../../services/player-challenges/challenge-templates";
import {UserService} from "../../../services/user";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import {Box, Button, CircularProgress, Grid} from "@material-ui/core";
import DuplicateChallengesDialog from "../DuplicateChallengesDialog";
import type {Challenge} from "../../../services/player-challenges/challenges";
import type {RootState} from "../../../redux/reducers";
import {getDailyChallengeScheduleCalendar, getSeasonalChallengeScheduleCalendar, getWeeklyChallengeScheduleCalendar} from "../../../redux/player-challenges/season-challenge-schedule-calendar/selectors";
import {deleteSeasonChallengeScheduleCalendarAsync, deselectAndRefreshScheduleCalendar, getSeasonChallengeScheduleCalendarsAsync} from "../../../redux/player-challenges/season-challenge-schedule-calendar/actions";
import {openConfirmationDialog, setAppNotification} from "../../../redux/app/actions";
import type { ConnectedProps} from "react-redux";
import {connect} from "react-redux";
import { AddEditChallengeScheduleDialog } from "./AddEditChallengeScheduleDialog";

const mapStateToProps = (state: RootState) => ({
  seasonChallengeScheduleCalendar: state.seasonChallengeScheduleCalendar,
  templates: state.challengeTemplates,
  dailyChallengeScheduleCalendar: getDailyChallengeScheduleCalendar(state),
  weeklyChallengeScheduleCalendar: getWeeklyChallengeScheduleCalendar(state),
  seasonalChallengeScheduleCalendar: getSeasonalChallengeScheduleCalendar(state)
});

const mapDispatch = {
  requestGetScheduleCalendar: getSeasonChallengeScheduleCalendarsAsync.request,
  requestDeleteScheduleCalendar: deleteSeasonChallengeScheduleCalendarAsync.request,
  deselectAndRefreshScheduleCalendar: deselectAndRefreshScheduleCalendar.request,
  openConfirmationDialog,
  setAppNotification
}

interface OwnProps {
  seasonNumber: number;
  scheduleId: string;
  challenges: Challenge[];
  calendarType: ScheduleCalendarTypeEnum;
}

const connector = connect(mapStateToProps, mapDispatch);

type Props = OwnProps & ConnectedProps<typeof connector>;

interface State {
  addItemDialogOpen: boolean;
  duplicateItemsDialogOpen: boolean;
  itemToEdit?: ScheduleCalendar;
  duplicateChallengesMode: boolean;
  selectedChallengesToDuplicate: ScheduleCalendar[]
}

class ChallengeScheduleGrid extends Component<Props, State> {
  private challengeMap: ChallengeMap = {};
  private templateMap: TemplateMap = {};
  private readonly tableRef: RefObject<MaterialTable<ScheduledChallenge>>;
  private columns: Column<ScheduleCalendar>[] = [];
  private calendarTypeLabel = '';

  constructor(props: Props) {
    super(props);
    this.state = {
      addItemDialogOpen: false,
      duplicateChallengesMode: false,
      selectedChallengesToDuplicate: [],
      duplicateItemsDialogOpen: false
    };
    this.tableRef = createRef();
  }

  componentDidMount() {
    this.props.requestGetScheduleCalendar({seasonNumber: this.props.seasonNumber, scheduleId: this.props.scheduleId});
  }

  componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>) {
    if (this.props.templates) {
      if (this.props.templates.latestChallengeTemplate instanceof ChallengeTemplate) {
        this.props.templates.latestChallengeTemplate?.templates.forEach((template: Template) => {
          this.templateMap[template.template_id] = template;
        });
      }
    }
    if (this.props.challenges) {
      this.props.challenges.forEach(challenge => {
        this.challengeMap[challenge.challenge_id] = challenge;
      });
    }
    switch (this.props.calendarType) {
    case ScheduleCalendarTypeEnum.DAILY:
      this.columns = getDailyColumns(this.challengeMap, this.templateMap);
      this.calendarTypeLabel = "Daily";
      break;
    case ScheduleCalendarTypeEnum.WEEKLY:
      this.columns = getWeeklyColumns(this.challengeMap, this.templateMap);
      this.calendarTypeLabel = "Weekly";
      break;
    case ScheduleCalendarTypeEnum.SEASONAL:
      this.columns = getSeasonalColumns(this.challengeMap, this.templateMap);
      this.calendarTypeLabel = "Seasonal";
      break;
    case ScheduleCalendarTypeEnum.UNKNOWN:
    default:
      console.error("Unknown calendar type");
      break;
    }
  }

  getTableMenuActions = (challenge: ScheduleCalendar): MenuAction<ScheduleCalendar>[] => {
    const actions: MenuAction<ScheduleCalendar>[] = [];
    if (UserService.canDelete('seasons')) {
      actions.push({type: 'button', icon: EditIcon, label: 'Edit', onClick: () => this.onChallengeEdit(challenge)});
      actions.push({type: 'button', icon: DeleteIcon, label: 'Delete', onClick: this.onChallengeDelete});
    }
    return actions;
  };

  onChallengeEdit = (itemToEdit: ScheduleCalendar) => {
    this.setState({itemToEdit, addItemDialogOpen: true});
  };

  onChallengeDelete = (challenge: ScheduleCalendar) => {
    this.props.openConfirmationDialog({title: `Delete Challenge: ${challenge.challenge_name}?`, action: 'Delete Challenge'}, () => {
      this.props.requestDeleteScheduleCalendar({
        seasonNumber: this.props.seasonNumber,
        scheduleId: this.props.scheduleId,
        instanceId: challenge.instance_id
      });
    });
  };

  onDuplicateOrCancelClick = () => {
    this.setState({duplicateChallengesMode: !this.state.duplicateChallengesMode});
    this.deselectAllRows();
  };

  deselectAllRows = () => {
    this.setState({selectedChallengesToDuplicate: []});
    this.props.deselectAndRefreshScheduleCalendar();
  };

  onSelectionChange = (selectedChallengesToDuplicate: ScheduleCalendar[]) => {
    this.setState({selectedChallengesToDuplicate});
  };

  onDuplicateSelectedClick = () => {
    this.setState({duplicateItemsDialogOpen: true});
  };

  onDuplicateDialogClose = () => {
    this.deselectAllRows();
    this.setState({duplicateItemsDialogOpen: false, duplicateChallengesMode: false, selectedChallengesToDuplicate: []});
  };

  render() {
    if (this.props.seasonChallengeScheduleCalendar.isLoading && !this.state.addItemDialogOpen) {
      return (
        <Box textAlign="center">
          <CircularProgress/>
        </Box>
      );
    }

    return <>
      <Box mb={2}>
        <Grid container alignItems="center">
          <Grid item>
            <Box pr={2}>
              <Button variant="outlined" color="secondary" onClick={() => this.onDuplicateOrCancelClick()}>
                {!this.state.duplicateChallengesMode && (<>Duplicate {this.calendarTypeLabel} Challenges</>)}
                {this.state.duplicateChallengesMode && (<>Cancel</>)}
              </Button>
            </Box>
          </Grid>

          {this.state.duplicateChallengesMode && (
            <Grid item>
              <Button variant="contained" color="primary" onClick={() => this.onDuplicateSelectedClick()} disabled={!this.state.selectedChallengesToDuplicate.length}>
                Duplicate Selected
              </Button>
            </Grid>
          )}

          <Grid item xs/>
          <Grid item>
            <Button variant="contained" color="primary" onClick={() => this.setState({itemToEdit: undefined, addItemDialogOpen: true})}>
              Add New {this.calendarTypeLabel} Challenge
            </Button>
          </Grid>
        </Grid>
      </Box>

      <MaterialTable
        tableRef={this.tableRef}
        key={"season_challenges"}
        title={this.state.duplicateChallengesMode ? "Select challenges to duplicate:" : `${this.calendarTypeLabel} Challenges`}
        columns={this.columns}
        data={(this.props.calendarType === ScheduleCalendarTypeEnum.DAILY
          ? this.props.dailyChallengeScheduleCalendar
          : this.props.calendarType === ScheduleCalendarTypeEnum.WEEKLY
            ? this.props.weeklyChallengeScheduleCalendar
            : this.props.calendarType === ScheduleCalendarTypeEnum.SEASONAL
              ? this.props.seasonalChallengeScheduleCalendar
              : [])}
        options={{
          pageSize: 10,
          selection: this.state.duplicateChallengesMode,
          showTextRowsSelected: this.state.duplicateChallengesMode,
          thirdSortClick: false,
          columnsButton: true,
        }}
        storageId={`${this.calendarTypeLabel}-challenges`}
        useRouter
        menuActions={!this.state.duplicateChallengesMode ? this.getTableMenuActions : []}
        onSelectionChange={(rows) => this.onSelectionChange(rows)}
      />

      {this.state.addItemDialogOpen && (
        <AddEditChallengeScheduleDialog
          onClose={() => this.setState({addItemDialogOpen: false, itemToEdit: undefined})}
          seasonNumber={this.props.seasonNumber}
          scheduleId={this.props.scheduleId}
          challenges={this.props.challenges}
          instanceToEdit={this.state.itemToEdit}
          challengeType={this.props.calendarType}
        />
      )}

      {this.state.duplicateItemsDialogOpen && (
        <DuplicateChallengesDialog
          calendarType={this.props.calendarType}
          onClose={() => this.onDuplicateDialogClose()}
          challengesToDuplicate={this.state.selectedChallengesToDuplicate}
          challengeMap={this.challengeMap}
          seasonNumber={this.props.seasonNumber}
        />
      )}

    </>
  }
}

export default connector(ChallengeScheduleGrid);

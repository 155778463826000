import type { CallEffect, PutEffect} from 'redux-saga/effects';
import { call, put, takeLatest } from 'redux-saga/effects';
import type { Title} from '../../services/title-data';
import { TitleService } from '../../services/title-data';
import { forkSagas, sagaHandleApiError } from '../utils-ts';
import { getTitleAsync, updateTitleAsync, getTitleFilesAsync, deleteTitleFileAsync, updateClientTitleAsync, updateServerTitleAsync, updateMaintenanceAsync } from './actions';
import { setAppNotification } from '../app/actions';
import type { AnyAction } from 'redux';
import type { PayloadAction } from 'typesafe-actions';
import type { TitleFile } from '../../services/model/title-file';

function* fetchTitle(action: ReturnType<typeof getTitleAsync.request>): Generator<CallEffect<Title> | PutEffect<PayloadAction<"GET_TITLE_SUCCESS", Title>> | Generator<PutEffect<AnyAction>, void, string>, void, Title>
{
  try {
    const title = yield call(TitleService.getTitle);
    yield put(getTitleAsync.success(title));
  } catch (e) {
    yield sagaHandleApiError(e, getTitleAsync.failure);
  }
}

function* updateTitleSaga(action: ReturnType<typeof updateTitleAsync.request>): Generator<CallEffect<Title> | PutEffect<PayloadAction<"UPDATE_TITLE_SUCCESS", Title>> | PutEffect<PayloadAction<"SET_APP_NOTIFICATION", Record<string, unknown>>> | Generator<PutEffect<AnyAction>, void, string>, void, Title> {
  try {
    const title = yield call(TitleService.updateTitle, action.payload);
    yield put(updateTitleAsync.success(title));
    yield put(setAppNotification({ type: 'success', message: 'Title updated' }));
  } catch (e) {
    yield sagaHandleApiError(e, updateTitleAsync.failure);
  }
}

function* updateClientTitleSaga(action: ReturnType<typeof updateClientTitleAsync.request>): Generator<Generator<PutEffect<AnyAction>, void, string> | CallEffect<{
  [key: string]: string | null;
}> | PutEffect<PayloadAction<"SET_APP_NOTIFICATION", Record<string, unknown>>> | PutEffect<PayloadAction<"UPDATE_CLIENT_TITLE_SUCCESS", {
  [key: string]: string | null;
}>>, void, {
  [key: string]: string | null;
}> {
  try {
    const data = yield call(TitleService.updateClientTitle, action.payload);
    yield put(updateClientTitleAsync.success(data));
    yield put(setAppNotification({ type: 'success', message: 'Title data updated' }));
  } catch (e) {
    yield sagaHandleApiError(e, updateClientTitleAsync.failure);
  }
}

function* updateServerTitleSaga(action: ReturnType<typeof updateServerTitleAsync.request>): Generator<CallEffect<{
  [key: string]: string | null;
}> | PutEffect<PayloadAction<"UPDATE_SERVER_TITLE_SUCCESS", {
  [key: string]: string | null;
}>> | PutEffect<PayloadAction<"SET_APP_NOTIFICATION", Record<string, unknown>>> | Generator<PutEffect<AnyAction>, void, string>, void, {[key: string]: string | null;}> {
  try {
    const data = yield call(TitleService.updateServerTitle, action.payload);
    yield put(updateServerTitleAsync.success(data));
    yield put(setAppNotification({ type: 'success', message: 'Title data updated' }));
  } catch (e) {
    yield sagaHandleApiError(e, updateServerTitleAsync.failure);
  }
}

function* updateMaintenanceSaga(action: ReturnType<typeof updateMaintenanceAsync.request>): Generator<CallEffect<{
  [key: string]: string;
}> | PutEffect<PayloadAction<"UPDATE_MAINTENANCE_SUCCESS", {
  [key: string]: string;
}>> | PutEffect<PayloadAction<"SET_APP_NOTIFICATION", Record<string, unknown>>> |Generator<PutEffect<AnyAction>, void, string>, void, {[key: string]: string;}> {
  try {
    const data = yield call(TitleService.updateMaintenanceData, action.payload);
    yield put(updateMaintenanceAsync.success(data));
    yield put(setAppNotification({ type: 'success', message: 'Maintenance info updated' }));
  } catch (e) {
    yield sagaHandleApiError(e, updateMaintenanceAsync.failure);
  }
}

function* getTitleFilesSaga(): Generator<CallEffect<TitleFile[]> | PutEffect<PayloadAction<"GET_TITLE_FILES_SUCCESS", TitleFile[]>> | Generator<PutEffect<AnyAction>, void, string>, void, TitleFile[]> {
  try {
    const files = yield call(TitleService.getFiles);
    yield put(getTitleFilesAsync.success(files));
  } catch (e) {
    yield sagaHandleApiError(e, getTitleFilesAsync.failure);
  }
}

function* deleteTitleFileSaga(action: ReturnType<typeof deleteTitleFileAsync.request>) {
  try {
    yield call(TitleService.deleteFile, action.payload);
    yield put(deleteTitleFileAsync.success(action.payload));
    yield put(setAppNotification({ type: 'success', message: 'File deleted' }));
  } catch (e) {
    yield sagaHandleApiError(e, deleteTitleFileAsync.failure);
  }
}

const sagas = [
  function* () {
    yield takeLatest(getTitleAsync.request, fetchTitle);
  },
  function* () {
    yield takeLatest(updateTitleAsync.request, updateTitleSaga);
  },
  function* () {
    yield takeLatest(updateClientTitleAsync.request, updateClientTitleSaga);
  },
  function* () {
    yield takeLatest(updateServerTitleAsync.request, updateServerTitleSaga);
  },
  function* () {
    yield takeLatest(updateMaintenanceAsync.request, updateMaintenanceSaga);
  },
  function* () {
    yield takeLatest(getTitleFilesAsync.request, getTitleFilesSaga);
  },
  function* () {
    yield takeLatest(deleteTitleFileAsync.request, deleteTitleFileSaga);
  }
];

export default function* titleSagas() {
  yield forkSagas(sagas);
}
import { format } from 'date-fns';
import type { ApiError} from './api';
import { api } from './api';
import { CurrencyService } from './currency';

export interface ItemToken {
  tokenName: string;
  category: string;
  sellable: boolean;
  transferable: boolean;
  burnable: boolean;
  issueTimeSpan: number;
  maxSupply: number;
  artistId: string | null;
  currentSupply: number;
  issuedSupply: number;
  state: string;
  itemName: string | null;
  itemDescription: string | null;
  seasonName: string | null;
  collection: string | null;
  basePrice: number;
  startsAt: number | null;
  endsAt: number | null;
  fuseFrom?: string[];
  searchable: boolean;
  withdrawable: boolean;
  recentDropStart: number | null;
}

export class ContainedItem {
  type: 'item' | 'droptable' = 'item';
  id = '';
  quantity = 1;
  index = 0;

  constructor(json?: any) {
    if (json) {
      this.type = json.itemId ? 'item' : 'droptable';
      this.id = json.itemId || json.tableId;
      this.quantity = json.quantity;
      this.index = json.index;
    }
  }

  toJson(): any {
    const json: any = {
      quantity: this.quantity
    };

    if (this.type === 'item') {
      json.itemId = this.id;
    } else {
      json.tableId = this.id;
    }
    json.index = this.index;

    return json;
  }
}

export type ItemType = 'item' | 'bundle' | 'container';
export type ItemGrantType = 'DEFAULT' | 'UNIQUE';

export class ItemDefinition {
  itemId = '';
  itemClass = '';
  itemName = '';
  catalogName = '';
  displayName = '';
  description = '';
  subtitle = '';
  imageUrl = '';
  imageThumbnailUrl = '';
  stackable = false;
  tradable = false;
  starterPack = false;
  itemGrantType: ItemGrantType = 'DEFAULT';
  tokenForCharacter = false;
  limitedEdition = false;
  initialLimitedEditionCount = 0;
  customData: any;
  tags: string[] = [];
  virtualCurrencyPrices: { [key: string]: number; } = {};
  consumable = false;
  consumableUsageCount: number | null = null;
  consumableUsagePeriod: number | null = null;
  bundle = false;
  itemBundleContents: ContainedItem[] = [];
  container = false;
  containerLocked = false;
  containerKeyItemId = '';
  itemContainerContents: ContainedItem[] = [];
  currencyContents: { [key: string]: number; } = {};
  token?: ItemToken;
  notes = '';
  releaseDate: string | null = null;
  pipelineGenerated = false;

  constructor(json?: any) {
    if (json) {
      this.itemId = json.itemId;
      this.itemClass = json.itemClass || '';
      this.itemName = json.itemName || '';
      this.catalogName = json.catalogName;
      this.displayName = json.displayName || '';
      this.description = json.description || '';
      this.subtitle = json.subtitle || '';
      this.imageUrl = json.imageUrl || '';
      this.imageThumbnailUrl = json.imageThumbnailUrl || '';
      this.stackable = json.stackable;
      this.tradable = json.tradable;
      this.starterPack = json.starterPack || false;
      this.itemGrantType = json.itemGrantType || 'DEFAULT';
      this.tokenForCharacter = json.tokenForCharacter;
      this.limitedEdition = json.limitedEdition;
      if (this.limitedEdition) {
        this.initialLimitedEditionCount = json.initialLimitedEditionCount;
      }
      if (Array.isArray(json.tags)) {
        this.tags = json.tags;
      }
      this.virtualCurrencyPrices = json.virtualCurrencyPrices || {};
      this.consumable = json.consumable;
      if (this.consumable) {
        if (typeof json.consumableUsageCount === 'number') {
          this.consumableUsageCount = json.consumableUsageCount;
        }
        if (typeof json.consumableUsagePeriod === 'number') {
          this.consumableUsagePeriod = json.consumableUsagePeriod;
        }
      }
      try {
        this.customData = JSON.parse(json.customData);
      // eslint-disable-next-line no-empty
      } catch (e) { }

      this.bundle = json.bundle;
      if (this.bundle) {
        this.currencyContents = json.bundleVirtualCurrencyContents || {};
        this.itemBundleContents = json.itemBundleContents.map((v: any) => new ContainedItem(v));
      }

      this.container = json.container;
      if (this.container) {
        this.containerLocked = json.containerLocked;
        if (this.containerLocked) {
          this.containerKeyItemId = json.containerKeyItemId;
        }
        this.itemContainerContents = json.itemContainerContents.map((v: any) => new ContainedItem(v));
        this.currencyContents = json.containerVirtualCurrencyContents || {};
      }

      if (json.token) {
        this.token = json.token;
      }

      this.notes = json.notes || '';
      this.releaseDate = json.releaseDate ? format(new Date(json.releaseDate), 'yyyy-MM-dd\'T\'HH:mm:ss') : null;
      if (json.pipelineGenerated) {
        this.pipelineGenerated = json.pipelineGenerated;
      }
    }
  }

  getType(): ItemType {
    if (this.bundle) {
      return 'bundle';
    } else if (this.container) {
      return 'container';
    } else {
      return 'item';
    }
  }

  toJson(): any {
    const json: any = {
      itemId: this.itemId,
      itemClass: this.itemClass || null,
      itemName: this.itemName || null,
      displayName: this.displayName || null,
      description: this.description || null,
      subtitle: this.subtitle || null,
      imageUrl: this.imageUrl,
      imageThumbnailUrl: this.imageThumbnailUrl || null,
      stackable: this.stackable,
      tradable: this.tradable,
      starterPack: this.starterPack,
      itemGrantType: this.itemGrantType,
      tokenForCharacter: this.tokenForCharacter,
      limitedEdition: this.limitedEdition,
      initialLimitedEditionCount: this.limitedEdition ? this.initialLimitedEditionCount : null,
      customData: this.customData ? JSON.stringify(this.customData) : null,
      tags: this.tags,
      virtualCurrencyPrices: this.virtualCurrencyPrices,
      consumable: this.consumable,
      consumableUsageCount: this.consumable ? this.consumableUsageCount : null,
      consumableUsagePeriod: this.consumable ? this.consumableUsagePeriod : null,
      bundle: false,
      container: false,
      notes: this.notes || null,
      releaseDate: this.releaseDate ? new Date(this.releaseDate).toISOString() : null,
      pipelineGenerated: this.pipelineGenerated,
    };

    if (this.bundle) {
      json.bundle = true;
      json.itemBundleContents = this.itemBundleContents.map(v => v.toJson());
      json.bundleVirtualCurrencyContents = this.currencyContents;
    } else if (this.container) {
      json.container = true;
      json.itemContainerContents = this.itemContainerContents.map(v => v.toJson());
      json.containerLocked = this.containerLocked;
      json.containerKeyItemId = this.containerLocked ? this.containerKeyItemId : null;
      json.containerVirtualCurrencyContents = this.currencyContents;
    }

    return json;
  }
}

export class ItemDefinitionsService {
  static async getItemDefinitions(catalogName: string): Promise<ItemDefinition[]> {
    return api.get<any[]>({ url: `/inventory/catalogs/${catalogName}/items` })
      .then(items => items.map(item => new ItemDefinition(item)))
      .catch((e: ApiError) => {
        e.message = `Failed to get item definitions. ${e.message}`;
        throw e;
      });
  }

  static async getItemDefinition(catalogName: string, itemId: string): Promise<ItemDefinition> {
    await CurrencyService.loadCurrencies();
    return api.get<any>({ url: `/inventory/catalogs/${catalogName}/items/${itemId}` }).then(item => new ItemDefinition(item)).catch((e: ApiError) => {
      e.message = `Failed to get item definition. ${e.message}`;
      throw e;
    });
  }

  static async createItemDefinition(item: ItemDefinition): Promise<ItemDefinition> {
    return api.post<ItemDefinition>({ url: `/inventory/catalogs/${item.catalogName}/items`, body: item.toJson() })
      .then(item => new ItemDefinition(item))
      .catch((e: ApiError) => {
        e.message = `Failed to create item definition. ${e.message}`;
        throw e;
      });
  }

  static async updateItemDefinition(item: ItemDefinition): Promise<ItemDefinition> {
    return api.put<ItemDefinition>({ url: `/inventory/catalogs/${item.catalogName}/items/${item.itemId}`, body: item.toJson() })
      .then(item => new ItemDefinition(item))
      .catch((e: ApiError) => {
        e.message = `Failed to update item definition. ${e.message}`;
        throw e;
      });
  }

  static async deleteItemDefinition(item: ItemDefinition): Promise<null> {
    return api.delete({ url: `/inventory/catalogs/${item.catalogName}/items/${item.itemId}` }).catch((e: ApiError) => {
      e.message = `Failed to delete item definition. ${e.message}`;
      throw e;
    });
  }

  static async createItemToken(item: ItemDefinition, token: ItemToken): Promise<ItemToken> {
    return api.post<ItemToken>({ url: `/inventory/catalogs/${item.catalogName}/items/${item.itemId}/token`, body: token })
      .catch((e: ApiError) => {
        e.message = `Failed to create item token. ${e.message}`;
        throw e;
      });
  }

  static async updateItemToken(item: ItemDefinition, token: ItemToken): Promise<ItemToken> {
    return api.put<ItemToken>({ url: `/inventory/catalogs/${item.catalogName}/items/${item.itemId}/token`, body: token })
      .catch((e: ApiError) => {
        e.message = `Failed to update item token. ${e.message}`;
        throw e;
      });
  }

  static async updateItemTokenBbpProperties(item: ItemDefinition, token: ItemToken): Promise<ItemToken> {
    return api.put<ItemToken>({ url: `/inventory/catalogs/${item.catalogName}/items/${item.itemId}/token-bbp-properties`, body: token })
      .catch((e: ApiError) => {
        e.message = `Failed to update item token bbp properties. ${e.message}`;
        throw e;
      });
  }

  static async freezeItemToken(item: ItemDefinition): Promise<ItemToken> {
    return api.put({ url: `/inventory/items/${item.itemId}/token/freeze` })
      .catch((e: ApiError) => {
        e.message = `Failed freeze item token. ${e.message}`;
        throw e;
      });
  }

}

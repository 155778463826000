import type { ApiError} from '../api';
import {api} from '../api';

export enum ScheduleCalendarTypeEnum {
  UNKNOWN = "UNKNOWN",
  DAILY = "DAILY",
  WEEKLY = "WEEKLY",
  SEASONAL = "SEASONAL"
}

export class ScheduleCalendar {
  season_number?: number = undefined;
  schedule_id = '';
  calendar_type: ScheduleCalendarTypeEnum = ScheduleCalendarTypeEnum.UNKNOWN;
  calendar_number?: number = undefined;
  challenge_id = '';
  instance_id = '';
  required_challenges = [];
  is_limited = false;
  limited_start?: number = undefined;
  limited_end?: number = undefined;
  party_pass_plus_only = false;
  resettable_progress = false;
  live_event_challenge = false;
  using_live_event_image = false;
  rewards: Reward[] = [];
  challenge_desc = '';
  challenge_name = '';
  goal_amount?: number = undefined;
  manual_completion = false;
  community_challenge = false;
  special = false;
  sort_order = 0;

  constructor(seasonNumber: number, scheduleId: string, json?: any) {
    if (json) {
      this.season_number = seasonNumber;
      this.schedule_id = scheduleId;
      this.calendar_type = json.calendarType;
      this.calendar_number = json.calendarNumber;
      this.challenge_id = json.challengeId;
      this.instance_id = json.instanceId;
      this.required_challenges = json.requiredChallenges;
      this.is_limited = json.isLimited;
      this.limited_start = json.limitedStart;
      this.limited_end = json.limitedEnd;
      this.party_pass_plus_only = json.partyPassPlusOnly;
      this.resettable_progress = json.resettableProgress;
      this.live_event_challenge = json.liveEventChallenge;
      this.using_live_event_image = json.usingLiveEventImage;
      this.rewards = json.rewards;
      this.challenge_desc = json.challengeDesc;
      this.challenge_name = json.challengeName;
      this.goal_amount = json.goalAmount;
      this.manual_completion = json.manualCompletion;
      this.community_challenge = json.communityChallenge;
      this.special = json.special;
      this.sort_order = json.sortOrder;
    }
  }

  toJson(): any {
    return {
      seasonNumber: this.season_number,
      scheduleId: this.schedule_id,
      calendarType: this.calendar_type,
      calendarNumber: this.calendar_number,
      challengeId: this.challenge_id,
      instanceId: this.instance_id,
      requiredChallenges: this.required_challenges,
      isLimited: this.is_limited,
      limitedStart: this.limited_start,
      limitedEnd: this.limited_end,
      partyPassPlusOnly: this.party_pass_plus_only,
      resettableProgress: this.resettable_progress,
      liveEventChallenge: this.live_event_challenge,
      usingLiveEventImage: this.using_live_event_image,
      rewards: this.rewards,
      challengeDesc: this.challenge_desc,
      challengeName: this.challenge_name,
      goalAmount: this.goal_amount,
      manualCompletion: this.manual_completion,
      communityChallenge: this.community_challenge,
      special: this.special,
      sortOrder: this.sort_order,
    };
  }

  clone(): ScheduleCalendar {
    return new ScheduleCalendar(Number(this.season_number), this.schedule_id, this.toJson());
  }
}

export class SeasonChallengeScheduleCalendarService {
  static async getSeasonChallengesScheduleCalenders(seasonNumber: number, scheduleId: string): Promise<Array<ScheduleCalendar>> {
    return api.get({url: `/players/seasons/${seasonNumber}/challenge-schedule/${scheduleId}/calendar/`})
      .then(scheduleCalendars => scheduleCalendars.map((scheduleCalendar: any) => new ScheduleCalendar(seasonNumber, scheduleId, scheduleCalendar)))
      .catch((e: ApiError) => {
        e.message = `Failed to get seasons. ${e.message}`;
        throw e;
      });
  }

  static async getSeasonChallengesScheduleCalender(seasonNumber: number, scheduleId: string, instanceId: string): Promise<ScheduleCalendar> {
    return api.get({url: `/players/seasons/${seasonNumber}/challenge-schedule/${scheduleId}/calendar/${instanceId}` })
      .then(scheduleCalendar => new ScheduleCalendar(seasonNumber, scheduleId, scheduleCalendar))
      .catch((e: ApiError) => {
        e.message = `Failed to get season challenge schedule calendar. ${e.message}`;
        throw e;
      });
  }

  static async createSeasonChallengeScheduleCalender(seasonNumber: number, scheduleId: string, scheduleCalendarJson: any): Promise<ScheduleCalendar> {
    return api.post({url: `/players/seasons/${seasonNumber}/challenge-schedule/${scheduleId}/calendar`, body: scheduleCalendarJson})
      .then(scheduleCalendar => new ScheduleCalendar(seasonNumber, scheduleId, scheduleCalendar))
      .catch((e: ApiError) => {
        e.message = `Failed to create season challenge schedule calendar. ${e.message}`;
        throw e;
      });
  }

  static async updateSeasonChallengeScheduleCalender(seasonNumber: number, scheduleId: string, instanceId: string, scheduleCalendar: ScheduleCalendar): Promise<ScheduleCalendar> {
    return api.put({url: `/players/seasons/${seasonNumber}/challenge-schedule/${scheduleId}/calendar/${instanceId}`, body: scheduleCalendar.toJson()})
      .then(scheduleCalendar => new ScheduleCalendar(seasonNumber, scheduleId, scheduleCalendar))
      .catch((e: ApiError) => {
        e.message = `Failed to update season challenge schedule calendar. ${e.message}`;
        throw e;
      });
  }

  static async deleteSeasonChallengeScheduleCalender(seasonNumber: number, scheduleId: string, instanceId: string): Promise<null> {
    return api.delete({url: `/players/seasons/${seasonNumber}/challenge-schedule/${scheduleId}/calendar/${instanceId}` })
      .catch((e: ApiError) => {
        e.message = `Failed to delete season challenge schedule calendar. ${e.message}`;
        throw e;
      });
  }
}

import { Field, Form, Formik, FormikHelpers } from "formik"
import { Select, TextField } from "formik-material-ui"
import { GeoBlockedFeatureMap, GeoRegionEntry } from "../geo-blocking-types"
import { geoRegions } from "../geo-blocking-data";
import * as Yup from 'yup';
import { DialogContent, DialogActions, Button, MenuItem, FormControl, InputLabel, Grid, TextField as MatTextField } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { prettifyFeatureName } from "../geo-blocking-api";
interface GeoBlockingFormProps {
  data: GeoBlockedFeatureMap,
  onSubmit: (values: GeoBlockedFeatureMap, helpers: FormikHelpers<GeoBlockedFeatureMap>) => void | Promise<any>;
  onClose: () => void;
  availableKeys?: string[];
}

const geoBlockValidationSchema = Yup.object().shape({
  featureId: Yup.string().required(),
  regions: Yup.array().min(1)
});


export const GeoBlockingForm = ({ data, onSubmit, onClose, availableKeys }: GeoBlockingFormProps) => {
  const defaultValues = geoRegions.filter(i => data?.regions.indexOf(i.id) !== -1);
  return <>
    <Formik<GeoBlockedFeatureMap>
      initialValues={data}
      enableReinitialize
      validationSchema={geoBlockValidationSchema}
      validateOnMount={true}
      onSubmit={onSubmit}
    >
      {({ setFieldValue }) => (
        <Form>
          <DialogContent>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <InputLabel>Feature Name</InputLabel>
                  {
                    availableKeys && (
                      <Field
                        component={Select}
                        name="featureId"
                      >
                        {availableKeys.map((key, i) => (
                          <MenuItem key={key} value={key}>{prettifyFeatureName(key)}</MenuItem>
                        ))}
                      </Field>
                    )
                  }
                  {
                    data.featureId && (
                      <Field
                        component={Select}
                        name="featureId"
                        InputProps={{
                          readonly: true
                        }}
                        disabled
                      >
                        {<MenuItem key={data.featureId} value={data.featureId}>{prettifyFeatureName(data.featureId || "")}</MenuItem>}
                      </Field>
                    )
                  }

                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <Autocomplete
                    multiple
                    id="tags-standard"
                    options={geoRegions}
                    getOptionLabel={(option) => option.label}
                    defaultValue={defaultValues}
                    renderInput={(params) => (
                      <MatTextField
                        {...params}
                        fullWidth
                        label="Regions"
                        placeholder="Regions"
                      />
                    )}
                    onChange={(e, value: GeoRegionEntry[]) => {
                      setFieldValue(
                        "regions",
                        value !== null ? value.map(i => i.id) : data.regions
                      );
                    }}
                    style={{ width: "100%" }}
                  />
                </FormControl>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button
              type="submit"
              variant="contained"
              color="primary"
            >
              Save
            </Button>
            <Button onClick={onClose} color="primary">
              Close
            </Button>
          </DialogActions>
        </Form>
      )}
    </Formik>

  </>
};
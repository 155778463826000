import { createAsyncAction } from 'typesafe-actions';
import type { TitleFile } from '../../services/model/title-file';
import type { Title } from '../../services/title-data';

export const getTitleAsync = createAsyncAction(
  'GET_TITLE_REQUEST',
  'GET_TITLE_SUCCESS',
  'GET_TITLE_FAILURE'
)<undefined, Title, string>();

export const updateTitleAsync = createAsyncAction(
  'UPDATE_TITLE_REQUEST',
  'UPDATE_TITLE_SUCCESS',
  'UPDATE_TITLE_FAILURE'
)<Title, Title, string>();

export const updateClientTitleAsync = createAsyncAction(
  'UPDATE_CLIENT_TITLE_REQUEST',
  'UPDATE_CLIENT_TITLE_SUCCESS',
  'UPDATE_CLIENT_TITLE_FAILURE'
)<{ [key: string]: string | null }, { [key: string]: string | null }, string>();

export const updateServerTitleAsync = createAsyncAction(
  'UPDATE_SERVER_TITLE_REQUEST',
  'UPDATE_SERVER_TITLE_SUCCESS',
  'UPDATE_SERVER_TITLE_FAILURE'
)<{ [key: string]: string | null }, { [key: string]: string | null }, string>();

export const updateMaintenanceAsync = createAsyncAction(
  'UPDATE_MAINTENANCE_REQUEST',
  'UPDATE_MAINTENANCE_SUCCESS',
  'UPDATE_MAINTENANCE_FAILURE'
)<{ [key: string]: string }, { [key: string]: string }, string>();

export const getTitleFilesAsync = createAsyncAction(
  'GET_TITLE_FILES_REQUEST',
  'GET_TITLE_FILES_SUCCESS',
  'GET_TITLE_FILES_FAILURE'
)<void, TitleFile[], string>();

export const deleteTitleFileAsync = createAsyncAction(
  'DELETE_TITLE_FILE_REQUEST',
  'DELETE_TITLE_FILE_SUCCESS',
  'DELETE_TITLE_FILE_FAILURE'
)<TitleFile, TitleFile, string>();
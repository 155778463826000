import { CatalogMigrationService } from '../../../../services/catalog-migration';
import type { MigrationModule } from '../../migrations';
import { buildDiffData, buildMigrationActions } from '../../migrations';
import { RoleDiffs } from "./RoleDiffs";
import type { Role } from "../../../roles-permissions/rolesPermissions";
import type { Dictionary } from "lodash";
import _ from "lodash";
import { RoleDiff } from "./roleDiff";
import { MigrationType } from "../migration-type";

export const roleMigration: MigrationModule<RoleDiff> = {
  id: MigrationType.roles,
  displayName: 'Roles',
  diffComponent: RoleDiffs,
  crossEnvOnly: true,

  loadData: async (sourceData, targetData, migrationData) => {
    sourceData.roles = await CatalogMigrationService.getRoles(sourceData.env);
    targetData.roles = await CatalogMigrationService.getRoles(targetData.env);

    migrationData.roles = buildDiffData(
      sourceData.roles.map(role => new RoleDiff(role)),
      targetData.roles.map(role => new RoleDiff(role))
    );
    return migrationData.roles;
  },

  runMigration: async (props, setStatus) => {
    const actions = buildMigrationActions(props.migrationData.roles, props.selections.roles);
    if (actions.add.length + actions.update.length + actions.remove.length < 1) {
      return;
    }

    const toMapByName = (roles?: Role[]): Dictionary<Role> => roles && _.mapValues(_.groupBy(roles, 'name'), result => result[0]) || {};
    const sourceRolesByName = toMapByName(props.sourceData.roles);
    const targetRolesByName = toMapByName(props.targetData.roles);

    const roles: Role[] = actions.add
      .map(name => {
        const role = sourceRolesByName[name];
        if (!role) {
          throw new Error(`Role to create with name ${name} not found in source roles`);
        }
        const clone = _.clone(role);
        clone.id = undefined;
        return clone;
      }).concat(
        actions.update
          .map(name => {
            const targetId = targetRolesByName[name]?.id;
            if (!targetId) {
              throw new Error(`Role to update with name ${name} not found in target roles`);
            }
            const role = sourceRolesByName[name];
            if (!role) {
              throw new Error(`Role to update with name ${name} not found in source roles`);
            }
            const clone = _.clone(role);
            clone.id = targetId;
            return clone;
          })
      );

    let progress = 0;
    for (const role of roles) {
      setStatus('Migrating Roles', progress);
      await CatalogMigrationService.migrateRole(props.targetData.env, role);
      progress += 100 / roles.length;
    }
  }
};

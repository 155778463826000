import type { BlankoGemRushAndFuse, BlankoGemRushFuse } from '../features/gem-rush/blankoGemRush';
import type { Shop, ShopVersion } from '../features/shops/shop';
import { api, ApiError, getEnvApi } from './api';
import { Catalog } from './catalogs';
import type { BlankoClass, BlankoClassAbilityType, BlankoProgression } from './model/blanko';
import { TitleFile } from './model/title-file';
import type { StoreTabItem } from './stores';
import { Store } from './stores';
import { Title } from './title-data';
import { ToolshedUser, UserService } from './user';
import type { Role, RolesResponse } from "../features/roles-permissions/rolesPermissions";
import type { BrawlPin, BrawlPinRule} from './model/brawl-pin';
import { brawlPinToPinRule } from './model/brawl-pin';
import type { HiddenItem } from "../pages/CatalogMigration/types";
import { ItemNote } from "../features/items/item-types";
import { LiveEvent } from "../features/live-events/live-events-types";
import { GeoBlockedFeature, GeoBlockedFeatureMap } from '../features/geoblocking/geo-blocking-types';
import { ChallengeTrigger } from './player-challenges/challenge-templates';
import { ChallengeTriggerTypeEnum } from './player-challenges/challenges';

export class CatalogMigrationService {
  static async getUser(env: string): Promise<ToolshedUser> {
    if (env === 'local') {
      return UserService.getUser();
    }

    return getEnvApi(env).get({ url: '/user' }).then(json => new ToolshedUser(json));
  }

  static async getCatalogs(env: string): Promise<Catalog[]> {
    return getEnvApi(env).get<any[]>({ url: '/inventory/catalogs' })
      .then(catalogs => catalogs.map(c => new Catalog(c)))
      .catch((e: ApiError) => {
        e.message = `Failed to get catalogs. ${e.message}`;
        throw e;
      });
  }

  static async getCatalog(env: string, catalogName: string): Promise<Catalog> {
    return getEnvApi(env).get({ url: `/inventory/catalogs/${catalogName}`, query: { includeContents: true } })
      .then(catalog => new Catalog(catalog))
      .catch((e: ApiError) => {
        e.message = `Failed to get catalog. ${e.message}`;
        throw e;
      });
  }

  static async importCatalog(env: string, catalog: Catalog): Promise<Catalog> {
    return getEnvApi(env).post({ url: '/inventory/catalogs/migrate', body: catalog.toJson(), query: { includeContents: true } })
      .then(catalog => new Catalog(catalog))
      .catch((e: ApiError) => {
        e.message = `Failed to import catalog. ${e.message}`;
        throw e;
      });
  }

  static async getStores(env: string, catalogName: string): Promise<Store[]> {
    return getEnvApi(env).get<any[]>({ url: `/inventory/catalogs/${catalogName}/stores` })
      .then(stores => stores.map(v => new Store(v)))
      .catch((e: ApiError) => {
        e.message = `Failed to get stores. ${e.message}`;
        throw e;
      });
  }

  static async getStore(env: string, catalogName: string, storeId: string): Promise<Store> {
    return getEnvApi(env).get({ url: `/inventory/catalogs/${catalogName}/stores/${storeId}` })
      .then(store => new Store(store))
      .catch((e: ApiError) => {
        e.message = `Failed to get store. ${e.message}`;
        throw e;
      });
  }

  static async getStoresWithItems(env: string, catalogName: string): Promise<Store[]> {
    const storeIds = (await this.getStores(env, catalogName)).map(v => v.storeId);
    const stores: Store[] = [];
    for (const id of storeIds) {
      stores.push(await this.getStore(env, catalogName, id));
    }
    return stores;
  }

  static async createStore(env: string, catalogName: string, store: Store): Promise<Store> {
    return getEnvApi(env).post({ url: `/inventory/catalogs/${catalogName}/stores`, body: store.toJson(!!store.tabs) })
      .then(store => new Store(store))
      .catch((e: ApiError) => {
        e.message = `Failed to create store. ${e.message}`;
        throw e;
      });
  }

  static async deleteStore(env: string, catalogName: string, storeId: string): Promise<null> {
    return getEnvApi(env).delete({ url: `/inventory/catalogs/${catalogName}/stores/${storeId}` })
      .catch((e: ApiError) => {
        e.message = `Failed to delete store. ${e.message}`;
        throw e;
      });
  }

  static async transferStoreItemImage(env: string, imageUrl: string, imageFilename: string) {
    const downloadResponse = await fetch(imageUrl);
    const mimeType = downloadResponse.headers.get('content-type');
    if (!mimeType) {
      throw new Error('Image Content-Type empty. Unknown mime type.');
    }

    const imageData = await getEnvApi(env).post({ url: '/inventory/image-upload-url', body: { filename: imageFilename, mimeType } });
    const options: RequestInit = {
      method: 'PUT',
      headers: new Headers({
        'Content-Type': mimeType
      }),
      body: await downloadResponse.blob()
    };

    const response = await fetch(imageData.url, options);
    if (!response.ok) {
      throw new Error('');
    }

    return imageData;
  }

  static async uploadStoreItemImage(env: string, catalogName: string, storeId: string, tabName: string, item: StoreTabItem): Promise<void> {
    if (!item.imageUrl || !item.imageFilename) {
      return;
    }

    const api = getEnvApi(env);
    try {
      const imageData = await CatalogMigrationService.transferStoreItemImage(env, item.imageUrl, item.imageFilename);

      await api.put({ url: `/inventory/catalogs/${catalogName}/stores/${storeId}/tabs/${tabName}/items/${item.itemId}/image`, body: { imageFilename: imageData.filename } });
    } catch (e) {
      throw new ApiError(`Failed to upload store image. ${JSON.stringify(e)}`);
    }
  }

  static async getTitleData(env: string): Promise<Title> {
    return getEnvApi(env).get({ url: '/title/title' })
      .then(title => new Title(title))
      .catch((e: ApiError) => {
        e.message = `Failed to get title data. ${e.message}`;
        throw e;
      });
  }

  static async updateClientTitleData(env: string, data: { [key: string]: string | null }): Promise<any> {
    return getEnvApi(env).put<any>({ url: '/title/title/client', body: data })
      .catch((e: ApiError) => {
        e.message = `Failed to update client title data.${e.message}`;
        throw e;
      });
  }

  static async updateServerTitleData(env: string, data: { [key: string]: string | null }): Promise<any> {
    return getEnvApi(env).put<any>({ url: '/title/title/server', body: data })
      .catch((e: ApiError) => {
        e.message = `Failed to update server title data.${e.message}`;
        throw e;
      });
  }

  static async getTitleFiles(env: string, fileNames?: string[]): Promise<TitleFile[]> {
    return getEnvApi(env).get<any[]>({ url: '/title/files', query: { fileNames } })
      .then(files => files.map(v => new TitleFile(v)))
      .catch((e: ApiError) => {
        e.message = `Failed to get files. ${e.message}`;
        throw e;
      });
  }

  static async getTitleFile(env: string, fileName: string): Promise<TitleFile> {
    return (await CatalogMigrationService.getTitleFiles(env, [fileName]))[0];
  }

  static async createFile(env: string, fileName: string, version: number): Promise<string> {
    return getEnvApi(env).post<string>({ url: '/title/files', body: { fileName, version } })
      .catch((e: ApiError) => {
        e.message = `Failed to create file. ${e.message}`;
        throw e;
      });
  }

  static async updateFile(env: string, fileName: string, version: number): Promise<string> {
    return getEnvApi(env).put<string>({ url: `/title/files/${fileName}`, body: { version } })
      .catch((e: ApiError) => {
        e.message = `Failed to update file. ${e.message}`;
        throw e;
      });
  }

  static async deleteFile(env: string, fileName: string): Promise<null> {
    return getEnvApi(env).delete({ url: `/title/files/${fileName}` })
      .catch((e: ApiError) => {
        e.message = `Failed to delete file. ${e.message}`;
        throw e;
      });
  }

  static async transferTitleFile(sourceUrl: string, targetUrl: string): Promise<void> {
    const downloadResponse = await fetch(sourceUrl);
    const contentType = downloadResponse.headers.get('content-type');

    const options: RequestInit = {
      method: 'PUT',
      headers: contentType ? new Headers({
        'Content-Type': contentType
      }) : undefined,
      body: await downloadResponse.blob()
    };

    try {
      const response = await fetch(targetUrl, options);
      if (!response.ok) {
        throw new Error(`Status: ${response.status}`);
      }
    } catch (e) {
      throw new ApiError(`Failed to upload file. ${JSON.stringify(e)}`);
    }
  }

  static async getBlankoAssets(env: string, catalogName: string): Promise<any[]> {
    return getEnvApi(env).get<any[]>({ url: `/catalogs/${catalogName}/migration/blanko-assets` })
      .catch((e: ApiError) => {
        e.message = `Failed to get blanko assets. ${e.message}`;
        throw e;
      });
  }

  static async migrateBlankoAssets(env: string, catalogName: string, assets: any[]): Promise<void> {
    await getEnvApi(env).post({ url: `/catalogs/${catalogName}/migration/blanko-assets`, body: assets })
      .catch((e: ApiError) => {
        e.message = `Failed to migrate blanko assets. ${e.message}`;
        throw e;
      });
  }

  static async getBlankoDnas(env: string, catalogName: string): Promise<any[]> {
    return getEnvApi(env).get<any[]>({ url: `/catalogs/${catalogName}/migration/blanko-dnas` })
      .catch((e: ApiError) => {
        e.message = `Failed to get blanko DNAs. ${e.message}`;
        throw e;
      });
  }

  static async migrateBlankoDnas(env: string, catalogName: string, dnas: any[]): Promise<void> {
    await getEnvApi(env).post({ url: `/catalogs/${catalogName}/migration/blanko-dnas`, body: dnas })
      .catch((e: ApiError) => {
        e.message = `Failed to migrate blanko DNAs. ${e.message}`;
        throw e;
      });
  }

  static async getSeasonalData(env: string, catalogName: string): Promise<SeasonalData[]> {
    return getEnvApi(env).get<SeasonalData[]>({ url: `/catalogs/${catalogName}/migration/seasonal-data` })
      .catch((e: ApiError) => {
        e.message = `Failed to get seasonal data. ${e.message}`;
        throw e;
      });
  }

  static async migrateSeasonalData(env: string, catalogName: string, seasonalData: SeasonalData[]): Promise<void> {
    await getEnvApi(env).post({ url: `/catalogs/${catalogName}/migration/seasonal-data`, body: seasonalData })
      .catch((e: ApiError) => {
        e.message = `Failed to migrate seasonal data. ${e.message}`;
        throw e;
      });
  }

  static async getBlankoProgressions(env: string, catalogName: string): Promise<BlankoProgressions[]> {
    return getEnvApi(env).get<any[]>({ url: `/catalogs/${catalogName}/migration/blankos-progressions` })
      .catch((e: ApiError) => {
        e.message = `Failed to get blanko progressions. ${e.message}`;
        throw e;
      });
  }

  static async migrateBlankoProgressions(env: string, catalogName: string, progressions: BlankoProgressions[]): Promise<void> {
    await getEnvApi(env).post({ url: `/catalogs/${catalogName}/migration/blankos-progressions`, body: progressions })
      .catch((e: ApiError) => {
        e.message = `Failed to migrate blanko progressions. ${e.message}`;
        throw e;
      });
  }

  static async deleteBlankoProgressions(env: string, catalogName: string, progressions: BlankoProgressions[]): Promise<void> {
    await getEnvApi(env).delete({ url: `/catalogs/${catalogName}/migration/blankos-progressions`, body: progressions })
      .catch((e: ApiError) => {
        e.message = `Failed to delete blanko progressions. ${e.message}`;
        throw e;
      });
  }

  static async updateProgression(env: string, id: string, json: any): Promise<BlankoProgression> {
    return getEnvApi(env).put<BlankoProgression>({ url: `/blanko-progressions/${id}`, body: json })
      .catch((e: ApiError) => {
        e.message = `Failed to update blanko progression. ${e.message}`;
        throw e;
      });
  }

  static async updateProgressionDnas(env: string, progressionId: string, dnaIds: string[]): Promise<any> {
    return getEnvApi(env).put({ url: `/blanko-progressions/${progressionId}/dnas`, body: dnaIds })
      .catch((e: ApiError) => {
        e.message = `Failed to update blanko progression DNAs. ${e.message}`;
        throw e;
      });
  }


  static async getTranslations(env: string, catalogName: string): Promise<Translation[]> {
    return getEnvApi(env).get<any[]>({ url: `/catalogs/${catalogName}/migration/translations` })
      .catch((e: ApiError) => {
        e.message = `Failed to get translations. ${e.message}`;
        throw e;
      });
  }

  static async migrateTranslations(env: string, catalogName: string, translations: Translation[]): Promise<void> {
    await getEnvApi(env).post({ url: `/catalogs/${catalogName}/migration/translations`, body: translations })
      .catch((e: ApiError) => {
        e.message = `Failed to migrate translations. ${e.message}`;
        throw e;
      });
  }

  static async deleteTextTranslations(env: string, catalogName: string, translations: Translation[]): Promise<void> {
    await getEnvApi(env).delete({ url: `/catalogs/${catalogName}/migration/text-translations`, body: translations })
      .catch((e: ApiError) => {
        e.message = `Failed to delete text translations. ${e.message}`;
        throw e;
      });
  }

  static async getTextLanguages(env: string, catalogName: string): Promise<TextLanguage[]> {
    return getEnvApi(env).get<any[]>({ url: `/catalogs/${catalogName}/migration/text-languages` })
      .catch((e: ApiError) => {
        e.message = `Failed to get text languages. ${e.message}`;
        throw e;
      });
  }

  static async deleteTextLanguages(env: string, catalogName: string, languages: TextLanguage[]): Promise<void> {
    await getEnvApi(env).delete({ url: `/catalogs/${catalogName}/migration/text-languages`, body: languages })
      .catch((e: ApiError) => {
        e.message = `Failed to delete text languages. ${e.message}`;
        throw e;
      });
  }

  static async getShops(env: string) {
    return getEnvApi(env).get<Shop[]>({ url: '/shops' });
  }

  static async getShopVersions(env: string, shopId: string) {
    return getEnvApi(env).get<ShopVersion[]>({ url: '/shops/versions', query: { shopId } });
  }

  static async getShopVersion(env: string, shopVersionId: string) {
    return getEnvApi(env).get<ShopVersion>({ url: `/shops/versions/${shopVersionId}`, query: { includeContents: true } });
  }

  static async createShop(env: string, name: string) {
    return getEnvApi(env).post<Shop>({ url: '/shops', body: { name } });
  }

  static async updateShop(env: string, shop: Shop) {
    return getEnvApi(env).put<Shop>({ url: `/shops/${shop.id}`, body: shop });
  }

  static async createShopVersion(env: string, shopVersion: any) {
    if (typeof shopVersion.startDate === 'string' && Boolean(shopVersion.startDate)) {
      shopVersion.startDate = new Date(shopVersion.startDate).toISOString();
      if (typeof shopVersion.preloadStartDate === 'string' && Boolean(shopVersion.preloadStartDate)) {
        shopVersion.preloadStartDate = new Date(shopVersion.preloadStartDate).toISOString();
      }
    }

    return getEnvApi(env).post<ShopVersion>({ url: '/shops/versions', body: shopVersion });
  }

  static async deleteShopVersion(env: string, id: string) {
    return getEnvApi(env).delete({ url: `/shops/versions/${id}` });
  }

  static async getShopVersionPlayerAccess(env: string, shopVersionId: string) {
    return getEnvApi(env).get<string[]>({ url: `/shops/versions/${shopVersionId}/player-access` });
  }

  static async grantShopVersionPlayerAccess(env: string, shopVersionId: string, playerIds: string[]) {
    return getEnvApi(env).post<any>({ url: `/shops/versions/${shopVersionId}/player-access`, body: { playerIds } });
  }

  static async getGemRushes(env: string) {
    return getEnvApi(env).get<BlankoGemRushAndFuse[]>({url: `/blanko-rushes`});
  }

  static async getGemRushFuses(env: string) {
    return getEnvApi(env).get<BlankoGemRushFuse[]>({url: `/blanko-rushes/fuses`});
  }

  static async migrateGemRush(env: string, catalogName: string, rush: any): Promise<void> {
    await getEnvApi(env).post({ url: `/catalogs/${catalogName}/migration/gem-rush`, body: rush })
      .catch((e: ApiError) => {
        e.message = `Failed to migrate Gem Rush. ${e.message}`;
        throw e;
      });
  }

  static async transferGemRushImage(env: string, imageUrl: string, imageFilename: string) {
    const downloadResponse = await fetch(imageUrl);
    const mimeType = downloadResponse.headers.get('content-type');
    if (!mimeType) {
      throw new Error('Image Content-Type empty. Unknown mime type.');
    }
    const imageData = await getEnvApi(env).get<{ filename: string, url: string, publicUrl: string }>({ url: '/blanko-rushes/image-upload-url', query: { mimeType: mimeType, filename: imageFilename } });

    const options: RequestInit = {
      method: 'PUT',
      headers: new Headers({
        'Content-Type': mimeType
      }),
      body: await downloadResponse.blob()
    };

    const response = await fetch(imageData.url, options);
    if (!response.ok) {
      throw new Error('');
    }

    return imageData;
  }

  static async getBlankoClasses(env: string, catalogName: string) {
    return getEnvApi(env).get<BlankoClass[]>({url:`/catalogs/${catalogName}/migration/blankos-class`});
  }

  static async getBlankoClassAbilityTypes(env: string, catalogName: string) {
    return getEnvApi(env).get<BlankoClassAbilityType[]>({url:`/catalogs/${catalogName}/migration/blankos-ability-class-type`});
  }

  static async migrateBlankoClass(env: string, catalogName: string, classes: any): Promise<void> {
    await getEnvApi(env).post({ url: `/catalogs/${catalogName}/migration/blankos-class`, body: classes })
      .catch((e: ApiError) => {
        e.message = `Failed to migrate Blanko Classes. ${e.message}`;
        throw e;
      });
  }

  static async migrateBlankoClassAbilityTypes(env: string, catalogName: string, abilityTypes: any): Promise<void> {
    await getEnvApi(env).post({ url: `/catalogs/${catalogName}/migration/blankos-ability-class-type`, body: abilityTypes })
      .catch((e: ApiError) => {
        e.message = `Failed to migrate Blanko Class Ability Types. ${e.message}`;
        throw e;
      });
  }

  static async getRoles(env: string) {
    return getEnvApi(env).get<RolesResponse>({url: `/roles`})
      .then(response => response.roles);
  }

  static async migrateRole(env: string, role: Role) {
    if (!role.id) {
      // Create
      return getEnvApi(env).post<Role>({url: `/roles`, body: role});
    }
    // Update
    return getEnvApi(env).put<Role>({url: `/roles`, body: role});
  }

  static async getBrawlPins(env: string) {
    return getEnvApi(env).get<BrawlPin[]>({url: `/brawl-pins`});
  }

  static async migrateBrawlPin(env: string, pin: BrawlPin) {
    if (!pin.id) {
      // Create
      return getEnvApi(env).post<BrawlPin>({url: `/brawl-pins`, body: pin});
    }
    // Update
    return getEnvApi(env).put<BrawlPin>({url: `/brawl-pins`, body: pin});
  }

  /**
   * Takes an optional triggerType to limit which challenge triggers are returned.
   */
  static async getChallengeTriggersByType(env: string, triggerType: ChallengeTriggerTypeEnum) {
    return getEnvApi(env).get<ChallengeTrigger[]>({url: `/players/challenge-triggers/${triggerType}`});
  }

  static async migrateChallengeTrigger(env: string, trigger: ChallengeTrigger) {
    return getEnvApi(env).post<ChallengeTrigger>({url: `/players/challenge-trigger`, body: trigger});
  }

  /**
   * Loads brawl pins, converts them into title data pin rules.
   * @param env Specifies the source environment for loading brawl pins.
   * @return the title data (always null) and the parsed badge rules
   */
  static async getPinRulesSourceInfo(env: string) {
    const brawlPins = await this.getBrawlPins(env);
    const pinRules: BrawlPinRule[] = brawlPins.map(brawlPinToPinRule);
    return { title: null, pinRules };
  }

  /**
   * Loads the target title data and returns both the title data and
   * the parsed badge rules.
   * @param env Specifies the source environment for loading title data.
   * @param catalog Specifies which catalog's badge rules to load.
   * @return the title data and the parsed badge rules
   */
  static async getPinRulesTargetInfo(env: string, catalog: string) {
    // load all title data
    const title = await this.getTitleData(env);
    let pinRules: BrawlPinRule[] = [];
    if (title && title.internalData) {
      // find the badge rules for specified catalog
      const badgeRules = title.internalData[`${catalog}.BadgeRules`];
      if (badgeRules) {
        pinRules = JSON.parse(badgeRules);
      }
    }

    return { title, pinRules };
  }

  static async getMigrationHiddenItems() {
    return api.get<HiddenItem[]>({url: `/migration/hidden-items`});
  }

  static async createMigrationHiddenItem(hiddenItem: HiddenItem) {
    return api.post<HiddenItem>({url: `/migration/hidden-items`, body: hiddenItem});
  }

  static async deleteMigrationHiddenItem(hiddenItem: HiddenItem) {
    await api.delete({url: `/migration/hidden-items`, query: hiddenItem });
  }

  static async getAllItemNotes(env: string) {
    return getEnvApi(env).get<ItemNote[]>({url: `/inventory/item-notes`});
  }

  static async createItemNote(env: string, itemNote: ItemNote) {
    return getEnvApi(env).post({url: `/inventory/item-notes`, body: itemNote});
  }

  static async updateItemNote(env: string, itemNote: ItemNote) {
    return getEnvApi(env).put({url: `/inventory/item-notes`, body: itemNote});
  }

  static async deleteItemNote(env: string, itemNoteId: string) {
    return getEnvApi(env).delete({url: `/inventory/item-notes`, query: {itemNoteId}});
  }

  static async getLiveEvents(env: string, catalogName: string): Promise<LiveEvent[]> {
    return getEnvApi(env).get<LiveEvent[]>({ url: `/catalogs/${catalogName}/migration/live-events` })
      .catch((e: ApiError) => {
        e.message = `Failed to get live events. ${e.message}`;
        throw e;
      });
  }

  static async migrateLiveEvents(env: string, catalogName: string, liveEvents: LiveEvent[]): Promise<void> {
    await getEnvApi(env).post({ url: `/catalogs/${catalogName}/migration/live-events`, body: liveEvents })
      .catch((e: ApiError) => {
        e.message = `Failed to migrate live events. ${e.message}`;
        throw e;
      });
  }

  static async getAllGeoBlockingFeatures(env: string) {
    return getEnvApi(env).get<GeoBlockedFeature[]>({url: `/region-features`});
  }

  static async upsertGeoBlockFeature(env: string, geoBlock: GeoBlockedFeatureMap ) {
    return api.post({ url: `/region-features/${geoBlock.featureId}`, body: geoBlock.regions});
  }
}

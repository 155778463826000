import { api } from '../api';

export interface SeasonPartyPass {
  id: string;
  seasonNumber: number;
  tierSkips: number;
  prices: { [key: string]: number };
  itemId: string;
  catalogName: string;
  storeId: string;
  enabled: boolean;
}

export class SeasonPartyPassService {
  static async getPartyPasses(seasonNumber: number): Promise<SeasonPartyPass[]> {
    return api.get<SeasonPartyPass[]>({ url: `/season-party-pass/season/${seasonNumber}` });
  }

  static async createPartyPass(partyPass: Omit<SeasonPartyPass, "id">): Promise<SeasonPartyPass> {
    return api.post<SeasonPartyPass>({ url: '/season-party-pass', body: partyPass });
  }

  static async updatePartyPass(partyPass: SeasonPartyPass): Promise<SeasonPartyPass> {
    return api.put<SeasonPartyPass>({ url: '/season-party-pass', body: partyPass });
  }

  static async deletePartyPass(id: string): Promise<null> {
    return api.delete({ url: `/season-party-pass/${id}` });
  }
}
import { call, put, select, takeLatest } from 'redux-saga/effects';
import type { ItemDefinition, ItemToken } from '../../../services/item-definitions';
import { ItemDefinitionsService } from '../../../services/item-definitions';
import { setAppNotification } from '../../app/actions';
import type { RootState } from '../../reducers';
import { forkSagas, sagaHandleApiError } from '../../utils-ts';
import {
  addCatalogItemAsync,
  createCatalogItemTokenAsync,
  freezeCatalogItemTokenAsync,
  getCatalogItemAsync,
  getCatalogItemsAsync,
  getCatalogItemTokenAsync,
  updateCatalogItemAsync,
  updateCatalogItemTokenAsync,
  updateCatalogItemTokenPropertiesAsync
} from './actions';

function* fetchCatalogItems() {
  try {
    const state: RootState = yield select();
    if (state.catalog.name) {
      const items: ItemDefinition[] = yield call(ItemDefinitionsService.getItemDefinitions, state.catalog.name);
      yield put(getCatalogItemsAsync.success(items));
    }
  } catch (e) {
    yield sagaHandleApiError(e, getCatalogItemsAsync.failure);
  }
}

function* fetchCatalogItem(action: ReturnType<typeof getCatalogItemAsync.request>) {
  try {
    const state: RootState = yield select();
    if (state.catalog.name) {
      const item: ItemDefinition = yield call(ItemDefinitionsService.getItemDefinition, state.catalog.name, action.payload);
      yield put(getCatalogItemAsync.success(item));
    }
  } catch (e) {
    yield sagaHandleApiError(e, getCatalogItemAsync.failure);
  }
}

function* addCatalogItemSaga(action: ReturnType<typeof addCatalogItemAsync.request>) {
  try {
    const item: ItemDefinition = yield call(ItemDefinitionsService.createItemDefinition, action.payload);
    yield put(addCatalogItemAsync.success(item));
    yield put(setAppNotification({ type: 'success', message: 'Item saved' }));
  } catch (e) {
    yield sagaHandleApiError(e, addCatalogItemAsync.failure);
  }
}

function* updateCatalogItemSaga(action: ReturnType<typeof updateCatalogItemAsync.request>) {
  try {
    const item: ItemDefinition = yield call(ItemDefinitionsService.updateItemDefinition, action.payload);
    yield put(updateCatalogItemAsync.success(item));
    yield put(setAppNotification({ type: 'success', message: 'Item saved' }));
  } catch (e) {
    yield sagaHandleApiError(e, updateCatalogItemAsync.failure);
  }
}

function* fetchCatalogItemToken(action: ReturnType<typeof getCatalogItemTokenAsync.request>) {
  try {
    const item: ItemDefinition = yield call(ItemDefinitionsService.getItemDefinition, action.payload.catalogName, action.payload.itemId);
    yield put(getCatalogItemTokenAsync.success(item.token));
  } catch (e) {
    yield sagaHandleApiError(e, getCatalogItemAsync.failure);
  }
}

function* createCatalogItemTokenSaga(action: ReturnType<typeof createCatalogItemTokenAsync.request>) {
  try {
    const token: ItemToken = yield call(ItemDefinitionsService.createItemToken, action.payload.item, action.payload.token);
    yield put(createCatalogItemTokenAsync.success(token));
    yield put(setAppNotification({ type: 'success', message: 'Token created' }));
  } catch (e) {
    yield sagaHandleApiError(e, createCatalogItemTokenAsync.failure);
  }
}

function* updateCatalogItemTokenSaga(action: ReturnType<typeof updateCatalogItemTokenAsync.request>) {
  try {
    const token: ItemToken = yield call(ItemDefinitionsService.updateItemToken, action.payload.item, action.payload.token);
    yield put(updateCatalogItemTokenAsync.success(token));
    yield put(setAppNotification({ type: 'success', message: 'Token updated' }));
  } catch (e) {
    yield sagaHandleApiError(e, updateCatalogItemTokenAsync.failure);
  }
}

function* updateCatalogItemTokenPropertiesSaga(action: ReturnType<typeof updateCatalogItemTokenPropertiesAsync.request>) {
  try {
    const token: ItemToken = yield call(ItemDefinitionsService.updateItemTokenBbpProperties, action.payload.item, action.payload.token);
    yield put(updateCatalogItemTokenPropertiesAsync.success(token));
    yield put(setAppNotification({ type: 'success', message: 'BBP properties updated' }));
  } catch (e) {
    yield sagaHandleApiError(e, updateCatalogItemTokenPropertiesAsync.failure);
  }
}

function* freezeCatalogItemTokenSaga(action: ReturnType<typeof freezeCatalogItemTokenAsync.request>) {
  try {
    const token: ItemToken = yield call(ItemDefinitionsService.freezeItemToken, action.payload);
    yield put(freezeCatalogItemTokenAsync.success(token));
    yield put(setAppNotification( { type: "success", message: 'Freeze item token success'}))
  } catch (e) {
    yield sagaHandleApiError(e, freezeCatalogItemTokenAsync.failure)
  }
}

export default function* catalogItemsSagas() {
  yield forkSagas([
    function* () {
      yield takeLatest(getCatalogItemsAsync.request, fetchCatalogItems);
    },
    function* () {
      yield takeLatest(getCatalogItemAsync.request, fetchCatalogItem);
    },
    function* () {
      yield takeLatest(addCatalogItemAsync.request, addCatalogItemSaga);
    },
    function* () {
      yield takeLatest(updateCatalogItemAsync.request, updateCatalogItemSaga);
    },
    function* () {
      yield takeLatest(getCatalogItemTokenAsync.request, fetchCatalogItemToken);
    },
    function* () {
      yield takeLatest(createCatalogItemTokenAsync.request, createCatalogItemTokenSaga);
    },
    function* () {
      yield takeLatest(updateCatalogItemTokenAsync.request, updateCatalogItemTokenSaga);
    },
    function* () {
      yield takeLatest(updateCatalogItemTokenPropertiesAsync.request, updateCatalogItemTokenPropertiesSaga);
    },
    function* () {
      yield takeLatest(freezeCatalogItemTokenAsync.request, freezeCatalogItemTokenSaga);
    }
  ])
}
import { api, ApiError } from './api';
import { TitleFile } from './model/title-file';

export class Title {
  titleData: { [key: string]: string; } = {};
  internalData: { [key: string]: string; } = {};
  maintenanceData: { [key: string]: any; } = {};

  constructor(json: any) {
    this.titleData = json.titleData;
    this.internalData = json.internalData;
    this.maintenanceData = json.maintenanceData;
  }
}

export class TitleService {

  static async getTitle(): Promise<Title> {
    return api.get({ url: '/title/title' })
      .then(title => new Title(title))
      .catch((e: ApiError) => {
        e.message = `Failed to get title. ${e.message}`;
        throw e;
      });
  }

  static async updateTitle(title: Title): Promise<Title> {
    const body = {
      titleData: title.titleData,
      internalData: title.internalData,
      maintenanceData: title.maintenanceData
    };

    return api.put({ url: '/title/title/import', body })
      .then(title => new Title(title))
      .catch((e: ApiError) => {
        e.message = `Failed to update title. ${e.message}`;
        throw e;
      });
  }

  static async updateClientTitle(data: { [key: string]: string | null }): Promise<{ [key: string]: string | null }> {
    return api.put<any>({ url: '/title/title/client', body: data })
      .catch((e: ApiError) => {
        e.message = `Failed to update title data.${e.message}`;
        throw e;
      });
  }

  static async updateServerTitle(data: { [key: string]: string | null }): Promise<{ [key: string]: string | null }> {
    return api.put<any>({ url: '/title/title/server', body: data })
      .catch((e: ApiError) => {
        e.message = `Failed to update title data.${e.message}`;
        throw e;
      });
  }

  static async updateMaintenanceData(data: { [key: string]: string }): Promise<{ [key: string]: string }> {
    return api.put<any>({ url: '/title/title/maintenance', body: data })
      .catch((e: ApiError) => {
        e.message = `Failed to update maintenance data.${e.message}`;
        throw e;
      });
  }

  static async getFiles(): Promise<TitleFile[]> {
    return api.get<any[]>({ url: '/title/files' })
      .then(files => files.map(v => new TitleFile(v)))
      .catch((e: ApiError) => {
        e.message = `Failed to get files. ${e.message}`;
        throw e;
      });
  }

  static async createFile(fileName: string): Promise<string> {
    return api.post<string>({ url: '/title/files', body: { fileName, version: 1 } })
      .catch((e: ApiError) => {
        e.message = `Failed to create file. ${e.message}`;
        throw e;
      });
  }

  static async updateFile(file: TitleFile): Promise<string> {
    return api.put<string>({ url: `/title/files/${file.fileName}`, body: { version: file.version + 1 } })
      .catch((e: ApiError) => {
        e.message = `Failed to update file. ${e.message}`;
        throw e;
      });
  }

  static async deleteFile(file: TitleFile): Promise<null> {
    return api.delete({ url: `/title/files/${file.fileName}` })
      .catch((e: ApiError) => {
        e.message = `Failed to delete file. ${e.message}`;
        throw e;
      });
  }

  static async uploadFile(file: File, url: string): Promise<void> {
    const options: RequestInit = {
      method: 'PUT',
      headers: new Headers({
        'Content-Type': file.type
      }),
      body: file
    };

    try {
      const response = await fetch(url, options);
      if (!response.ok) {
        throw new Error(`Status: ${response.status}`);
      }
    } catch (e) {
      throw new ApiError(`Failed to upload file. ${e.message}`);
    }
  }
}
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Grid,
  InputLabel,
  Link,
  TextField as MatTextField
} from '@material-ui/core';
import GetAppIcon from '@material-ui/icons/GetApp';
import type { FormikHelpers} from 'formik';
import {Field, Form, Formik} from 'formik';
import {TextField} from 'formik-material-ui';
import type { ChangeEvent } from 'react';
import {Link as RouterLink} from 'react-router-dom';
import JsonEditor from '../../../components/JsonEditor';
import {PublishedBlock} from '../../../services/blocks';
import Chip from "@material-ui/core/Chip";
import { Autocomplete } from "@material-ui/lab";
import { useEffect, useCallback, useState } from 'react';

interface FormValues {
  id: string;
  name: string;
  creatorId: string;
  ownerId: string;
  primaryGameMode: string;
  secondaryGameModes: string;
  minPlayers: string;
  maxPlayers: string;
  duration: string;
  playType: string;
}

interface Props {
  publishedBlock: PublishedBlock;
  onSave: (publishedBlock: PublishedBlock) => void;
  tags: string[];
  onTagsSave: (id: string, tags: string[]) => void;
}

export const PublishedBlockForm = (props: Props) => {
  const [submitting, setSubmitting] = useState<((submitting: boolean) => void) | undefined>();
  const [customData, setCustomData] = useState('');
  const [initialFormValues, setInitialFormValues] = useState<FormValues>();
  const [tagValue, setTagValue] = useState('');
  const [tagsValues, setTagsValues] = useState<string[]>([]);

  const blockToFormValues = useCallback((block: PublishedBlock): FormValues => {
    return {
      id: block.id,
      name: block.name,
      ownerId: block.ownerId,
      creatorId: block.creatorId,
      primaryGameMode: block.primaryGameMode,
      secondaryGameModes: block.secondaryGameModes.join(', '),
      minPlayers: block.minPlayers.toString(),
      maxPlayers: block.maxPlayers.toString(),
      duration: block.duration.toString(),
      playType: block.playType
    };
  }, []);

  const onSubmit = useCallback((values: FormValues, helpers: FormikHelpers<FormValues>) => {
    const block = props.publishedBlock;
    const newBlock = new PublishedBlock();
    newBlock.id = block.id;
    newBlock.blockId = block.blockId;
    newBlock.ownerId = block.ownerId;
    newBlock.creatorId = block.creatorId;
    newBlock.primaryGameMode = block.primaryGameMode;
    newBlock.secondaryGameModes = block.secondaryGameModes;
    newBlock.playType = block.playType;
    newBlock.attachmentConfigs = block.attachmentConfigs;
    newBlock.active = block.active;

    newBlock.name = values.name;
    newBlock.minPlayers = parseInt(values.minPlayers);
    newBlock.maxPlayers = parseInt(values.maxPlayers);
    newBlock.duration = parseInt(values.duration);
    newBlock.customData = customData;
    newBlock.tags = tagsValues;

    props.onSave(newBlock);
    setSubmitting(helpers.setSubmitting);
  }, [customData, props, tagsValues]);

  const handleTagAdd = () => {
    if (!tagsValues.find(tag => tagValue === tag)) {
      setTagsValues([...tagsValues, tagValue]);
    } else {
      setTagValue(tagValue);
    }
  };

  useEffect(() => {
    props.onTagsSave(props.publishedBlock.id, tagsValues);
    setTagValue('');
  }, [tagsValues, props]);

  const handleTagInputChange = (event: any) => {
    const input = event.target;
    const start = input.selectionStart;
    const end = input.selectionEnd;
    setTagValue(input.value.toUpperCase());
    input.setSelectionRange(start, end);
  };

  const handleDelete = (tag: any) => () => {
    const tags = tagsValues.filter(t => t !== tag);
    setTagsValues(tags);
    props.onTagsSave(props.publishedBlock.id, tags);
  };

  // eslint-disable-next-line @typescript-eslint/ban-types
  const handleAutoTagInputChange = (event: ChangeEvent<{}>, value: any) => {
    setTagValue(value);
  };

  useEffect(() => {
    setInitialFormValues(blockToFormValues(props.publishedBlock));
    setTagsValues(props.publishedBlock?.tags);
  }, [props.publishedBlock, blockToFormValues]);

  return (<>
    {(initialFormValues &&
      <Formik<FormValues>
        initialValues={initialFormValues}
        enableReinitialize
        validateOnMount={true}
        onSubmit={onSubmit}
      >
        {({ isSubmitting, submitForm }) => (
          <Form>
            <Grid container spacing={3}>
              <Grid item xs={12} md={6} lg={8}>
                <Card>
                  <CardHeader title="Properties" />
                  <CardContent>
                    <Grid container spacing={1}>
                      <Grid item xs={12}>
                        <Field
                          component={TextField}
                          name="id"
                          type="text"
                          label="ID"
                          fullWidth
                          InputProps={{
                            readOnly: true
                          }}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <InputLabel shrink>Block</InputLabel>
                        <Link variant="subtitle1" component={RouterLink} to={`/blocks/${props.publishedBlock.blockId}`}>
                          {props.publishedBlock.blockId}
                        </Link>
                      </Grid>
                      <Grid item xs={12}>
                        <Field
                          component={TextField}
                          name="ownerId"
                          type="text"
                          label="Owner"
                          fullWidth
                          InputProps={{
                            readOnly: true
                          }}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <Field
                          component={TextField}
                          name="creatorId"
                          type="text"
                          label="Creator"
                          fullWidth
                          InputProps={{
                            readOnly: true
                          }}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <Field
                          component={TextField}
                          name="name"
                          type="text"
                          label="Name"
                          fullWidth
                          InputProps={{
                            readOnly: true
                          }}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <Field
                          component={TextField}
                          name="primaryGameMode"
                          type="text"
                          label="Game mode"
                          fullWidth
                          InputProps={{
                            readOnly: true
                          }}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <Field
                          component={TextField}
                          name="playType"
                          type="text"
                          label="Mode type"
                          fullWidth
                          InputProps={{
                            readOnly: true
                          }}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <Field
                          component={TextField}
                          name="secondaryGameModes"
                          type="text"
                          label="Secondary game modes"
                          fullWidth
                          InputProps={{
                            readOnly: true
                          }}
                        />
                      </Grid>
                      <Grid item xs={6} lg={4}>
                        <Field
                          component={TextField}
                          name="minPlayers"
                          type="text"
                          label="Min. players"
                          fullWidth
                          InputProps={{
                            readOnly: true
                          }}
                        />
                      </Grid>
                      <Grid item xs={6} lg={4}>
                        <Field
                          component={TextField}
                          name="maxPlayers"
                          type="text"
                          label="Max. players"
                          fullWidth
                          InputProps={{
                            readOnly: true
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} lg={4}>
                        <Field
                          component={TextField}
                          name="duration"
                          type="text"
                          label="Duration (seconds)"
                          fullWidth
                          InputProps={{
                            readOnly: true
                          }}
                        />
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={12} md={6} lg={4}>
                <Card style={{ marginBottom: '24px' }}>
                  <CardHeader title="Block file" />
                  <CardContent>
                    {props.publishedBlock.blockFileUrl ? (
                      <Button
                        variant="contained"
                        color="primary"
                        href={props.publishedBlock.blockFileUrl}
                        startIcon={<GetAppIcon />}
                      >
                        Download
                      </Button>
                    ) : (
                      <Box fontStyle="italic">Block file not available.</Box>
                    )}
                  </CardContent>
                </Card>
                <Card style={{ marginBottom: '24px' }}>
                  <CardHeader title="Tags" />
                  <CardContent>
                    <Grid container spacing={1}>
                      {tagsValues.length > 0 && (
                        <Grid item xs={12}>
                          {tagsValues.map(data => {
                            return (
                              <Chip key={data} label={data} onDelete={handleDelete(data)} color="secondary"
                                size="small" 
                                style={{
                                  marginRight: '10px'
                                }}
                              />
                            )
                          })}
                        </Grid>
                      )}
                      <Grid item xs>
                        <Autocomplete options={props.tags} freeSolo value={tagValue} onChange={handleAutoTagInputChange}
                          renderInput={params => (
                            <MatTextField
                              {...params}
                              label="Add a Tag"
                              fullWidth
                              value={tagValue}
                              onChange={handleTagInputChange}
                            />
                          )}
                        />
                      </Grid>
                      <Grid item xs="auto">
                        <Button color="primary" variant="contained" disabled={!tagValue || tagValue.length < 1} onClick={handleTagAdd}>Add Tag</Button>
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={12}>
                <JsonEditor
                  readOnly
                  title="Custom Data"
                  json={props.publishedBlock.customData}
                  // eslint-disable-next-line @typescript-eslint/no-empty-function
                  onJsonChange={() => { }}
                  // eslint-disable-next-line @typescript-eslint/no-empty-function
                  onValidityChange={() => { }}
                />
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    )}
  </>);
}
import { Field, Form, Formik, FormikProps } from "formik";
import { LokaliseKey, LokalisePlatform, SimpleKey } from "../lokalise-types";
import { Box, Button, Card, CardContent, CircularProgress, MenuItem } from "@material-ui/core";
import { TextField } from "formik-material-ui";
import { FormikSelectWithLabel } from "../../../components/FormikSelectWithLabel";
import { useEffect, useState } from "react";
import { createKeys, getBranches, getFileList } from "../lokalise-api";
import { usePushNotification } from "../../../contexts/AppNotificationContext";

interface FormValues {
  branch: string;
  filename: string;
  key: SimpleKey;
}

const defaultSimpleKey: SimpleKey = {
  keyName: '',
  description: '',
  baseTranslation: '',
};
const initialValues: FormValues = {
  key: defaultSimpleKey,
  filename: '',
  branch: '',
};

const BASE_LANGUAGE_ISO = 'en_US';

const toLokaliseKey = (values: FormValues): LokaliseKey => {
  return {
    key_name: values.key.keyName,
    description: values.key.description,
    filenames: {
      web: values.filename,
      other: values.filename,
    },
    platforms: [LokalisePlatform.web, LokalisePlatform.other],
    translations: [{
      language_iso: BASE_LANGUAGE_ISO,
      translation: values.key.baseTranslation,
    }],
  };
};

export const AddLocalizationKey = () => {
  const pushNotification = usePushNotification();

  return <>
    <Formik<FormValues>
      initialValues={initialValues}
      onSubmit={(values, formikHelpers) => {
        createKeys(values.branch, [toLokaliseKey(values)])
          .then(response => {
            formikHelpers.setSubmitting(false);
            if (response.errors.length === 0) {
              pushNotification({ type: 'success', message: `Created key '${values.key.keyName}'` });
              formikHelpers.setFieldValue('key', defaultSimpleKey);
              (document.querySelector('#key-name-field') as any).focus();
            } else {
              response.errors
                .forEach(error => pushNotification({type: 'error', message: `Failed to create key: ${error.message}`}));
            }
          })
          .catch(err => pushNotification({type: 'error', message: `Failed to create key: ${err.message}`}));
      }}
    >
      {(formikProps) => (
        <AddKeyForm {...formikProps} />
      )}
    </Formik>
  </>;
};

const AddKeyForm = ({values}: FormikProps<FormValues>) => {
  const pushNotification = usePushNotification();
  const [branches, setBranches] = useState<string[] | undefined>();
  const [filenames, setFilenames] = useState<string[] | undefined>();
  useEffect(() => {
    getBranches()
      .then(branchList => setBranches(branchList.branches.map(branch => branch.name)))
      .catch(err => pushNotification({type: 'error', message: `Failed to get branches: ${err.message}`}));
  }, [pushNotification]);
  useEffect(() => {
    setFilenames(undefined);
    if (values.branch) {
      getFileList(values.branch)
        .then(fileList => setFilenames(fileList.files
          .map(file => file.filename)
          .filter(filename => filename !== '__unassigned__')))
        .catch(err => pushNotification({type: 'error', message: `Failed to get filenames: ${err.message}`}));
    }
  }, [pushNotification, values.branch]);

  if (!branches) {
    return <Box textAlign="center">
      <CircularProgress/>
    </Box>;
  }

  return <Form>
    <Card>
      <CardContent>
        <Box>
          <Field
            component={FormikSelectWithLabel}
            type="text"
            name="branch"
            label="Branch"
            fullWidth
          >
            {branches.map(branch => <MenuItem key={branch} value={branch}>{branch}</MenuItem>)}
          </Field>
        </Box>
      </CardContent>
      <CardContent>
        <Box>
          {(!filenames && values.branch) ?
            (<Box textAlign="center">
              <CircularProgress/>
            </Box>) :
            (<Field
              component={FormikSelectWithLabel}
              type="text"
              name="filename"
              label="Filename"
              fullWidth
              disabled={!values.branch}
            >
              {filenames?.map(filename => <MenuItem key={filename} value={filename}>{filename}</MenuItem>)}
            </Field>)
          }
        </Box>
      </CardContent>
    </Card>
    <Box mt={2}>
      <Card>
        <CardContent>
          <Field
            component={TextField}
            type="text"
            id="key-name-field"
            name={`key.keyName`}
            label="Key Name"
            fullWidth
          />
        </CardContent>
        <CardContent>
          <Field
            component={TextField}
            type="text"
            name={`key.description`}
            label="Description"
            fullWidth
          />
        </CardContent>
        <CardContent>
          <Field
            component={TextField}
            type="text"
            name={`key.baseTranslation`}
            label="Base Translation"
            fullWidth
          />
        </CardContent>
      </Card>
    </Box>
    <Box mt={2}>
      <Button type="submit" variant="contained" color="primary">Save Key</Button>
    </Box>
  </Form>;
};

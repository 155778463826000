import type { RouteComponentProps } from 'react-router';
import type { LinkTabConfig } from '../../../components/LinkTabs';
import { LinkTabs } from '../../../components/LinkTabs';
import { UserService } from '../../../services/user';
import { Rewards } from "./Rewards";
import { AccountLinks } from "./AccountLinks";
import { Fulfillments } from "./Fulfillments";
import { Reward } from "./Reward";

type Props = RouteComponentProps;

export const RewardsPage = (props: Props) => {

  const tabs: LinkTabConfig[] = [];
  if (UserService.canRead('rewards')) {
    tabs.push({url: '/rewards', label: 'Rewards', absolute: true, component: Rewards});
  }
  if (UserService.canRead('rewards')) {
    tabs.push({url: '/rewards/account-links', label: 'Account Links', absolute: true, component: AccountLinks});
  }
  if (UserService.canRead('rewards')) {
    tabs.push({url: '/rewards/fulfillments', label: 'Fulfillments', absolute: true, component: Fulfillments});
  }
  if (UserService.canCreate('rewards')) {
    tabs.push({url: '/rewards/reward/create', label: 'Create New', absolute: true, component: Reward});
  }

  return (
    <LinkTabs tabs={tabs} {...props} />
  );
};

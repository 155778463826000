import CircularProgress from '@material-ui/core/CircularProgress';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import type {ReactElement, SyntheticEvent} from 'react';
import React, { Component, Fragment } from 'react';
import type { ConnectedProps} from 'react-redux';
import {connect} from 'react-redux';
import type {RootState} from '../../redux/reducers';
import {createChallengeAsync, getChallengeAsync, updateChallengeAsync} from "../../redux/player-challenges/challenges/actions";
import {
  Challenge,
  ChallengeParameter,
  ChallengeTriggerTypeEnum,
  Reward
} from "../../services/player-challenges/challenges";
import {ChallengeTrigger} from "../../services/player-challenges/challenge-templates";
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Checkbox,
  FormControlLabel,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem, OutlinedInput,
  Select,
  TextField
} from "@material-ui/core";
import ClearIcon from "@material-ui/icons/Clear";
import { getChallengeTriggersAsync} from "../../redux/player-challenges/challenge-templates/actions";
import { selectLatestSeasonalChallengeTrigger} from "../../redux/player-challenges/challenge-templates/selectors";
import type {CustomType, Template, TemplateParameter} from "../../services/player-challenges/challenge-templates";
import {selectChallengeById} from "../../redux/player-challenges/challenges/selectors";
import RewardControl from "../../shared/components/RewardControl";
import {getCurrentSeasonChallengeScheduleAsync} from "../../redux/player-challenges/season-challenge-schedule/actions";
import MaterialTable from "../../components/MaterialTable";
import type {Column} from "material-table";
import type {ChallengeSchedule} from "../../services/player-challenges/season-challenge-schedule";
import {setAppNotification} from '../../redux/app/actions';
import DeleteIcon from "@material-ui/icons/Delete";

interface OwnProps {
  challengeIdToEdit?: string;
  onClose: () => void;
  existingChallenges?: Challenge[];
}

const mapStateToProps = (state: RootState, props: OwnProps) => ({
  challengeToEdit: (props.challengeIdToEdit ? selectChallengeById(state, props.challengeIdToEdit) : undefined),
  latestTriggerConfiguration: selectLatestSeasonalChallengeTrigger(state),
  isAddingChallenge: !props.challengeIdToEdit,
  seasonChallengeSchedule: state.seasonChallengeSchedule
});
const mapDispatch = {
  getChallenge: getChallengeAsync.request,
  requestGetCurrentSeasonChallengeSchedule: getCurrentSeasonChallengeScheduleAsync.request,
  getLatestChallengeTriggers: getChallengeTriggersAsync.request,
  requestAddChallenge: createChallengeAsync.request,
  requestUpdateChallenge: updateChallengeAsync.request,
  setAppNotification
};
const connector = connect(mapStateToProps, mapDispatch);
type Props = OwnProps & ConnectedProps<typeof connector>;

interface State {
  challenge?: Challenge;
  open: boolean;
  loading: boolean;
  currentTemplateId: string;
  challengeParameters: Map<string, ChallengeParameter>;
  challengeName: string;
  challengeDesc: string;
  challengeRewards: Reward[];
  partyPassPlusOnly: boolean;
  resettableProgress: boolean;
  triggerType: ChallengeTriggerTypeEnum;
  special: boolean;
}

const initialState = {
  challenge: new Challenge(),
  open: true,
  loading: true,
  currentTemplateId: '',
  challengeParameters: new Map<string, ChallengeParameter>(),
  challengeName: '',
  challengeDesc: '',
  challengeRewards: [],
  partyPassPlusOnly: false,
  resettableProgress: false,
  triggerType: ChallengeTriggerTypeEnum.GAME,
  special: false,
};

interface CurrentSeasonUsage {
  scheduleType: string;
  calendarNumber: string;
}

class AddChallengeDialog extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {...initialState};
    if (!this.props.isAddingChallenge && this.props.challengeIdToEdit) {
      // fetch the challenge we are editing
      this.props.getChallenge(this.props.challengeIdToEdit);
    }
    // fetch all the challenge templates
    this.props.getLatestChallengeTriggers();
  }

  componentDidUpdate(prevProps: Props, prevState: State) {
    // if the challenge to edit object shows up, that means we are editing and we need to set the challenge to that, and load all the params, reward, etc.
    if (prevProps.challengeToEdit !== this.props.challengeToEdit && this.props.challengeToEdit && this.props.challengeToEdit.parameters) {
      const challengeToEditParams: ChallengeParameter[] = this.props.challengeToEdit.parameters;
      // we've finished loading the challenge to edit, create the map of params from it
      const challengeParameters = new Map<string, ChallengeParameter>(challengeToEditParams.map(obj => [obj.name, obj]))
      this.setState({
        challenge: this.props.challengeToEdit,
        challengeParameters,
        currentTemplateId: this.props.challengeToEdit.template_id,
        challengeName: this.props.challengeToEdit.challenge_name,
        challengeDesc: this.props.challengeToEdit.challenge_desc,
        challengeRewards: this.props.challengeToEdit.rewards || [],
        partyPassPlusOnly: this.props.challengeToEdit.party_pass_plus_only,
        resettableProgress: this.props.challengeToEdit.resettable_progress,
        triggerType: this.props.challengeToEdit.trigger_type,
        special: this.props.challengeToEdit.special,
      });
    }
    if (this.props.latestTriggerConfiguration && prevState.loading) {
      this.setState({loading: false});
    }
  }

  componentDidMount() {
    this.props.requestGetCurrentSeasonChallengeSchedule();
  }

  onDialogClose = () => {
    this.props.onClose();
  };

  onDialogExited = () => {
    this.setState({...initialState});
  };

  parameterValueOnChange = (event: SyntheticEvent, parameter: ChallengeParameter) => {
    const newValue = (event.target as HTMLInputElement).value;
    this.parameterOnChange(newValue, parameter);
  };

  parameterCheckboxValueOnChange = (oldValue: boolean, parameter: ChallengeParameter) => {
    const newValue = (!oldValue).toString(); // flip it and reverse it
    this.parameterOnChange(newValue, parameter);
  }

  parameterSelectFieldOnChange = (newValue: string, parameter: ChallengeParameter) => {
    this.parameterOnChange(newValue, parameter);
  };

  parameterOnChange = (newValue: string, parameter: ChallengeParameter) => {
    const challengeParameters = new Map<string, ChallengeParameter>(this.state.challengeParameters);
    if (challengeParameters.has(parameter.name)) {
      const foundParam = challengeParameters.get(parameter.name);
      challengeParameters.set(parameter.name, new ChallengeParameter({...foundParam, value: newValue}));
    } else {
      challengeParameters.set(parameter.name, new ChallengeParameter({...parameter, value: newValue}));
    }
    this.setState({challengeParameters});
  }

  renderCurrentlySelectedChallengeTemplateParameters = () => {
    if (!this.props.latestTriggerConfiguration) {
      return null;
    }
    const templates: (Template | undefined)[] = this.props.latestTriggerConfiguration.flatMap(x => {
      if(x.template){
        if (x.trigger_type != null) { x.template.trigger_type = x.trigger_type; }
        if (x.custom_types) { x.template.custom_types = x.custom_types; }
        return x.template;
      }
    });
    const selectedTemplate = templates.find(o => o?.template_id === this.state.currentTemplateId);
    if (!selectedTemplate) {
      return null;
    }

    // in edit mode, these params will contain all the values already
    const challengeParams = this.state.challengeParameters;
    const currentlySelectedChallengeTemplateParameterFields: ReactElement[] = [];
    // loop through each parameter, building a simple textbox for now

    let goalAmountParameter: TemplateParameter | undefined = undefined;
    let requiredActionParameter: TemplateParameter | undefined = undefined;
    const templateParams: Map<string,TemplateParameter> = new Map<string, TemplateParameter>();
    selectedTemplate.parameters.forEach((t:TemplateParameter) => {
      if (t.name === "GoalAmount")
        goalAmountParameter = t;
      else if (t.name == "RequiredAction")
        requiredActionParameter = t;
      else
        templateParams.set(t.name, t);
    });

    if(goalAmountParameter) {
      this.buildTemplateParameterField(goalAmountParameter, selectedTemplate.custom_types, challengeParams, currentlySelectedChallengeTemplateParameterFields, false);
    }
    if(requiredActionParameter) {
      this.buildTemplateParameterField(requiredActionParameter, selectedTemplate.custom_types, challengeParams, currentlySelectedChallengeTemplateParameterFields, false);
    }


    if (selectedTemplate.trigger_type === ChallengeTriggerTypeEnum.GAME_CHALLENGE) {
      const availableTemplateParameters = new Map<string, TemplateParameter>();
      templateParams.forEach(p => availableTemplateParameters.set(p.name, p));
      console.log({atp: availableTemplateParameters, cp: challengeParams});
      const cps = Array.from(this.state.challengeParameters.values());
      for (const param of cps) {
        availableTemplateParameters.delete(param.name);
        const templateParam: TemplateParameter | undefined = templateParams.get(param.name);
        if (!templateParam) {
          continue;
        }
        this.buildTemplateParameterField(templateParam, selectedTemplate.custom_types, challengeParams, currentlySelectedChallengeTemplateParameterFields, true);
      }
      currentlySelectedChallengeTemplateParameterFields.push(this.gameChallengeRules(Array.from(availableTemplateParameters.values())));
    }
    else {
      for (const param of Array.from(templateParams.values()) as TemplateParameter[]) {
        this.buildTemplateParameterField(param, selectedTemplate.custom_types, challengeParams, currentlySelectedChallengeTemplateParameterFields, false);
      }
    }
    return currentlySelectedChallengeTemplateParameterFields;
  };

  buildTemplateParameterField = (param: TemplateParameter, customTypes: CustomType[], challengeParams: Map<string, ChallengeParameter>, currentlySelectedChallengeTemplateParameterFields: ReactElement[], isDeletable: boolean) => {
    // look up the parameters in the challenge
    const challengeParam = challengeParams.get(param.name) || new ChallengeParameter(param.toJson());
    if (param.type === "String") {
      currentlySelectedChallengeTemplateParameterFields.push(this.stringTemplateField(challengeParam, isDeletable));
    } else if (param.type === "Int") {
      currentlySelectedChallengeTemplateParameterFields.push(this.intTemplateField(challengeParam, isDeletable));
    } else if (param.type === "Bool") {
      currentlySelectedChallengeTemplateParameterFields.push(this.boolTemplateField(challengeParam, isDeletable));
    } else {
      // check for a custom type and render a dropdown with those values if so
      if (customTypes) {
        const fieldType: CustomType | undefined = customTypes.find(o => o?.type_name === challengeParam.type);
        if (fieldType) {
          currentlySelectedChallengeTemplateParameterFields.push(this.customTemplateField(challengeParam, fieldType.values, isDeletable));
        }
      }
    }
  }

  gameChallengeRules = (availableTemplateParameters: TemplateParameter[]) => {
    console.log({atp: availableTemplateParameters});
    return (<Box pt={1} pb={1}>
      <Box display={'flex'} flexDirection={'row'}>
        <Select
          displayEmpty
          fullWidth
          value={''}
          onChange={event => {
            if (typeof event.target.value === 'string') {
              const newSelectedParams = new Map(this.state.challengeParameters.entries());
              const templateValue = JSON.parse(event.target.value) as TemplateParameter;
              newSelectedParams.set(templateValue.name, new ChallengeParameter({name: templateValue.name, type: templateValue.type, value: ''}));
              this.setState({ challengeParameters: newSelectedParams });
            }
          }}
        >
          <MenuItem value={""} style={{ fontStyle: "italic" }} >Select a rule to be added</MenuItem>
          {availableTemplateParameters.map((p: TemplateParameter) => {
            return (
              <MenuItem key={p.name} value={JSON.stringify(p)}>
                {p.name}
              </MenuItem>
            );
          })}
        </Select>
      </Box>
    </Box>);
  }

  stringTemplateField = (challengeParam: ChallengeParameter, isDeletable: boolean): ReactElement => {
    return (
      <Box key={challengeParam.name} pt={1} pb={1} display={'flex'} flexDirection={'row'}>
        { isDeletable && (<IconButton onClick={() => {
          const newSelectedParams: Map<string, ChallengeParameter> = new Map(this.state.challengeParameters.entries());
          newSelectedParams.delete(challengeParam.name);
          this.setState({ challengeParameters: newSelectedParams });
        }}><DeleteIcon/></IconButton>) }
        <TextField
          fullWidth
          label={challengeParam.name}
          value={challengeParam.value || ''}
          onChange={event => this.parameterValueOnChange(event, challengeParam)}
        />
      </Box>);
  }

  intTemplateField = (challengeParam: ChallengeParameter, isDeletable: boolean): ReactElement => {
    return (
      <Box key={challengeParam.name} pt={1} pb={1} display={'flex'} flexDirection={'row'}>
        { isDeletable && (<IconButton onClick={() => {
          const newSelectedParams: Map<string, ChallengeParameter> = new Map(this.state.challengeParameters.entries());
          newSelectedParams.delete(challengeParam.name);
          this.setState({ challengeParameters: newSelectedParams });
        }}><DeleteIcon/></IconButton>) }
        <TextField
          type="number"
          inputMode="numeric"
          fullWidth
          label={challengeParam.name}
          value={challengeParam.value || ''}
          onChange={event => this.parameterValueOnChange(event, challengeParam)}
        />
      </Box>);
  }

  boolTemplateField = (challengeParam: ChallengeParameter, isDeletable: boolean): ReactElement => {
    return (
      <Box key={challengeParam.name} pt={1} pb={1} display={'flex'} flexDirection={'row'}>
        { isDeletable && (<IconButton onClick={() => {
          const newSelectedParams: Map<string, ChallengeParameter> = new Map(this.state.challengeParameters.entries());
          newSelectedParams.delete(challengeParam.name);
          this.setState({ challengeParameters: newSelectedParams });
        }}><DeleteIcon/></IconButton>) }
        <InputLabel>
          {challengeParam.name}
          <Checkbox
            checked={challengeParam.value === "true"}
            onChange={() => this.parameterCheckboxValueOnChange((challengeParam.value === "true"), challengeParam)}
          />
        </InputLabel>
      </Box>);
  }

  customTemplateField = (challengeParam: ChallengeParameter, fieldValues: string[], isDeletable: boolean): ReactElement => {
    return (
      <Box key={challengeParam.name} pt={1} pb={1} display={'flex'} flexDirection={'row'}>
        { isDeletable && (<IconButton onClick={() => {
            const newSelectedParams: Map<string, ChallengeParameter> = new Map(this.state.challengeParameters.entries());
            newSelectedParams.delete(challengeParam.name);
            this.setState({ challengeParameters: newSelectedParams });
        }}><DeleteIcon/></IconButton>) }
        <Box display={'flex'} flexDirection={'column'} flexGrow={1}>
          <InputLabel id={challengeParam.name} shrink={true} htmlFor={`${challengeParam.name}_name`}>{challengeParam.name}</InputLabel>
          <Box display={'flex'} flexDirection={'row'}>
            <Select
              labelId={challengeParam.name}
              displayEmpty
              fullWidth
              input={
                <OutlinedInput
                  notched
                  name={`${challengeParam.name}_name`}
                  id={`${challengeParam.name}_select`}
                />
              }
              onChange={event => {
                if (typeof event.target.value === 'string') {
                  this.parameterSelectFieldOnChange(event.target.value, challengeParam);
                }
              }}
              value={challengeParam.value || ''}>
              {fieldValues.map((value: string) => {
                return (
                  <MenuItem key={value} value={value}>
                    {value}
                  </MenuItem>
                );
              })}
            </Select>
            <Box mr="4">
              <IconButton onClick={() => this.parameterSelectFieldOnChange("", challengeParam)}>
                <ClearIcon/>
              </IconButton>
            </Box>
          </Box>
        </Box>
      </Box>);
  }

  renderRewards = () => {
    if (!this.props.latestTriggerConfiguration) {
      return null;
    }
    const templates: (Template | undefined)[] = this.props.latestTriggerConfiguration.flatMap(x => {
      if(x.template){
        return x.template;
      }
    });
    const selectedTemplate = templates.find(o => o?.template_id === this.state.currentTemplateId);
    if (!selectedTemplate) {
      return null;
    }
    const addButton = (<Button variant="contained" color="primary" size="small" onClick={() => {
      const rewards = [...this.state.challengeRewards];
      rewards.push(new Reward());
      this.setState({challengeRewards: rewards});
    }}>
      Add Reward
    </Button>);

    return (<Box pt={3}>
      <Card>
        <CardHeader title="Rewards" titleTypographyProps={{variant: 'subtitle1'}} action={addButton}/>
        <CardContent>
          {this.state.challengeRewards.length === 0 ? <Grid item>No Rewards.</Grid> : null}
          {this.state.challengeRewards.map((reward: Reward, index: number) => (
            <Fragment key={index}>
              <Grid container>
                <Box width="100%" pb={1}>
                  <RewardControl reward={reward} onRewardDelete={() => {
                    const allRewards = this.state.challengeRewards;
                    allRewards.splice(index, 1)
                    this.setState({challengeRewards: allRewards});
                  }}/>
                </Box>
              </Grid>
            </Fragment>
          ))}
        </CardContent>
      </Card>
    </Box>);
  };

  renderCurrentSeasonUsageGrid = () => {
    const challenge = this.props.challengeToEdit;
    if (!challenge || !this.props.seasonChallengeSchedule || !this.props.seasonChallengeSchedule.data) {
      return null;
    }

    const columns: Column<CurrentSeasonUsage>[] = [
      {
        title: 'Schedule Type', sorting: true, field: 'scheduleType', defaultSort: 'asc'
      },
      {
        title: 'Calendar Number',
        sorting: true,
        field: 'calendarNumber',
        customSort: (a, b) => {
          if (a.calendarNumber === 'N/A') {
            return 1;
          }
          const parsedA = parseInt(a.calendarNumber);
          const parsedB = parseInt(b.calendarNumber);
          if (parsedA === parsedB) return 0;
          return parsedA > parsedB ? 1 : -1;
        },
      }
    ];

    const currentSeasonSchedule: ChallengeSchedule = this.props.seasonChallengeSchedule.data;
    const daily = currentSeasonSchedule.daily.filter(o => o.challenges.some(p => p.challenge_id === challenge.challenge_id));
    const weekly = currentSeasonSchedule.weekly.filter(o => o.challenges.some(p => p.challenge_id === challenge.challenge_id));
    const seasonal = currentSeasonSchedule.seasonal.challenges.filter(o => o.challenge_id === challenge.challenge_id);
    const rows = daily
      .map(o => ({scheduleType: 'Daily', calendarNumber: o.day_number.toString()})).concat(weekly
        .map(o => ({scheduleType: 'Weekly', calendarNumber: o.week_number.toString()}))
      );
    if (seasonal && seasonal.length > 0) {
      rows.push({scheduleType: 'Seasonal', calendarNumber: 'N/A'});
    }

    if (rows.length === 0) {
      return null;
    }

    return (<Box pt={3}>
      <MaterialTable
        title="Current Season Schedules Using This Challenge"
        columns={columns}
        data={rows}
      />
    </Box>);
  };

  onSubmit = () => {
    // todo error check for valid parameter types/values

    if (!this.state.currentTemplateId) {
      throw Error('Must choose template first.');
    }

    if (!this.state.challengeParameters || this.state.challengeParameters.size <= 0) {
      throw Error('Must enter parameter data first.');
    }

    if (!this.props.latestTriggerConfiguration) {
      throw Error('Must have template configs downloaded');
    }
    //Don't allow challenge name to collide with other challenges.
    const nameMatch = this.props.existingChallenges?.filter((c:Challenge) =>
      c.challenge_name === this.state.challengeName
      && c.challenge_id != this.state.challenge?.challenge_id).length || 0;
    if(nameMatch > 0){
      this.props.setAppNotification({ type: 'error', message: 'Challenge name must be unique' });
      return;
    }

    let triggerType = ChallengeTriggerTypeEnum.GAME;
    let templateVersion = 1;

    const matchingTemplate = this.props.latestTriggerConfiguration.filter(x => x.template?.template_id == this.state.currentTemplateId);
    if(matchingTemplate && matchingTemplate[0]){
      const trigger = matchingTemplate[0];
      triggerType = trigger.trigger_type as ChallengeTriggerTypeEnum;
      templateVersion = trigger.version ?? templateVersion;
    }

    const challenge = this.state.challenge || new Challenge();
    challenge.challenge_name = this.state.challengeName;
    challenge.challenge_desc = this.state.challengeDesc;
    challenge.template_id = this.state.currentTemplateId;
    challenge.template_version = templateVersion.toString();
    challenge.parameters = [];
    challenge.rewards = this.state.challengeRewards;
    challenge.party_pass_plus_only = this.state.partyPassPlusOnly;
    challenge.resettable_progress = this.state.resettableProgress;
    challenge.special = this.state.special;
    challenge.trigger_type = triggerType;
    this.state.challengeParameters.forEach((value, key) => {
      challenge.parameters.push(new ChallengeParameter(value.toJson()))
    })
    if (this.props.isAddingChallenge) {
      this.props.requestAddChallenge(challenge);
    } else {
      this.props.requestUpdateChallenge(challenge);
    }

    this.setState({open: false});
  };


  render() {
    const title = this.props.isAddingChallenge ? 'Add Challenge' : 'Edit Challenge';
    const isLoading = this.state.loading;
    const currentTemplateId = this.state.currentTemplateId;
    const triggerTypes = Object.values(ChallengeTriggerTypeEnum);

    return (
      <Dialog
        open={this.state.open}
        onClose={() => this.setState({open: false})}
        onExited={this.props.onClose}
        fullWidth
        maxWidth="md"
      >
        <DialogTitle>{title}</DialogTitle>
        <DialogContent>

          {isLoading && (
            <Box textAlign="center">
              <CircularProgress/>
            </Box>
          )}

          {!isLoading && (
            <form onSubmit={this.onSubmit}>
              {this.props.latestTriggerConfiguration && (
                <>
                  <Box pb={3}>
                    <InputLabel id="challenge-type">Challenge Type</InputLabel>
                  </Box>
                  <Box pb={3}>
                    <Select
                      labelId="challenge-type"
                      displayEmpty
                      fullWidth
                      onChange={event => {
                        if (typeof event.target.value === 'string') {
                          this.setState({currentTemplateId: event.target.value});
                        }
                      }}
                      value={currentTemplateId}>
                      {this.props.latestTriggerConfiguration.flatMap(x => x.template).map((template?: Template) => {
                        if(template){
                          return (
                            <MenuItem key={template.template_id} value={template.template_id}>
                              {template.template_name}
                            </MenuItem>
                          );
                        }
                      })}
                    </Select>
                  </Box>
                </>
              )}

              {currentTemplateId && (
                <Box>
                  <Box pb={2}>
                    <TextField
                      fullWidth
                      label="Challenge Name"
                      value={this.state.challengeName}
                      onChange={(e) => this.setState({challengeName: e.target.value})}
                    />
                  </Box>
                  <Box pb={2}>
                    <TextField
                      fullWidth
                      multiline={true}
                      label="Challenge Desc"
                      value={this.state.challengeDesc}
                      onChange={(e) => this.setState({challengeDesc: e.target.value})}
                    />
                  </Box>
                  <Box>
                    <Box pb={2}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={this.state.partyPassPlusOnly}
                            onChange={(e) => this.setState({partyPassPlusOnly: e.target.checked})}
                            name="partyPassPlusOnly"
                            color="primary"
                          />
                        }
                        label="Party Pass Plus Only"
                      />
                    </Box>
                  </Box>
                  <Box>
                    <Box pb={2}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={this.state.resettableProgress}
                            onChange={(e) => this.setState({resettableProgress: e.target.checked})}
                            name="resettableProgress"
                            color="primary"
                          />
                        }
                        label="Resettable Progress"
                      />
                    </Box>
                  </Box>
                  <Box>
                    <Box pb={2}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={this.state.special}
                            onChange={(e) => this.setState({special: e.target.checked})}
                            name="special"
                            color="primary"
                          />
                        }
                        label="Special"
                      />
                    </Box>
                  </Box>
                  {this.renderCurrentlySelectedChallengeTemplateParameters()}
                  {this.renderRewards()}
                  {this.renderCurrentSeasonUsageGrid()}
                </Box>
              )}
            </form>
          )}

        </DialogContent>
        <DialogActions>
          <Button color="primary" variant="contained" onClick={() => this.onSubmit()}>
            Save
          </Button>
          <Button variant="outlined" onClick={() => this.setState({open: false})}>
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

export default connector(AddChallengeDialog);

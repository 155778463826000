import { GeoBlockedFeatureIdEnum } from "../geoblocking/geo-blocking-types";

export interface ServerTemplateResponse {
  id: string;
  name: string;
  template: string;
}
export interface ShopTemplate {
  id: string;
  name: string;
  templateItem: ShopTemplateItem
}

export interface ShopTemplateItem {
  cartLimit?: number;
  purchaseLimit?: number;
  geoBlockingFeatures?: GeoBlockedFeatureIdEnum[];
  startDate: Date | null;
  endDate: Date | null;
  backgroundImageOverride: boolean;
  nft: boolean;
  hideOnMainListing: boolean;
  nameFontColor: string;
  nameFontSize: number;
  artistCollabText: string;
  heroText: string;
  heroFontColor: string;
  buttonText: string;
  contentAlignment: TextAlignmentEnum;
  contentWidthPercentage: number;
  badgeAlignment: TextAlignmentEnum;
}

export enum TextAlignmentEnum {
  LEFT = "Left",
  CENTER = "Center",
  RIGHT = "Right"
}

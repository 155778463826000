import { Box, CircularProgress, Dialog, DialogContent } from "@material-ui/core";
import type { Column } from "material-table";
import AutoRenewIcon from '@material-ui/icons/Autorenew';
import MaterialTable from "../../../components/MaterialTable";
import { useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import type { MenuAction } from "../../../components/MaterialTable";
import { setAppNotification } from "../../../redux/app/actions";
import type { ApiError } from "../../../services/api";
import type { ShopTemplate, ShopTemplateItem } from "../shopTemplate";
import { getShopTemplates } from "../shopTemplateApi";

const shopTemplateColumns: Column<ShopTemplate>[] = [
  {
    field: 'name',
    title: 'Name',
    defaultSort: 'asc',
  }
];

export interface ShopTemplatePickerProps {
  onClose: () => void;
  onApplyTemplate: (template: ShopTemplateItem) => void;
}

export const ShopTemplatePickerDialog = (props: ShopTemplatePickerProps) => {
  const dispatch = useDispatch();
  const [templates, setTemplates] = useState<ShopTemplate[] | null>(null);
  const [isOpen, setIsOpen] = useState(true);
  const { onClose, onApplyTemplate } = props

  useEffect(() => {
    getShopTemplates().then(setTemplates)
      .catch((e: ApiError) => dispatch(setAppNotification({ type: 'error', message: `Failed to load shop templates. ${e.message}` })));
  }, [dispatch]);

  const getMenuActions = useCallback(() => {
    const actions: MenuAction<ShopTemplate>[] = [];
    return actions;
  }, []);

  const rowSelected = useCallback((row: ShopTemplate | undefined) => {
    if (row) {
      onApplyTemplate(row.templateItem);
    }
    setIsOpen(false);
  }, [onApplyTemplate]);


  return (<>
    {templates === null ? (
      <Box textAlign="center">
        <CircularProgress />
      </Box>
    ) : (
      <Dialog
        open={isOpen}
        onClose={() => setIsOpen(false)}
        onExited={onClose}
        fullWidth
        maxWidth="sm"
      >
        <DialogContent style={{ padding: 0 }}>
          <MaterialTable<ShopTemplate>
            title=""
            data={templates}
            columns={shopTemplateColumns}
            menuActions={getMenuActions}
            onRowClick={(event, item) => rowSelected(item)}
            options={{
              actionsColumnIndex: 99
            }}
          />
        </DialogContent>
      </Dialog>
    )}
  </>)
}
import { createAsyncAction, createAction } from 'typesafe-actions';
import type { DropTable } from '../../../services/drop-tables';

export const getCatalogDropTablesAsync = createAsyncAction(
  'GET_CATALOG_DROP_TABLES_REQUEST',
  'GET_CATALOG_DROP_TABLES_SUCCESS',
  'GET_CATALOG_DROP_TABLES_FAILURE'
)<undefined, DropTable[], string>();

export const getCatalogDropTableAsync = createAsyncAction(
  'GET_CATALOG_DROP_TABLE_REQUEST',
  'GET_CATALOG_DROP_TABLE_SUCCESS',
  'GET_CATALOG_DROP_TABLE_FAILURE'
)<string, DropTable, string>();

export const addCatalogDropTableAsync = createAsyncAction(
  'ADD_CATALOG_DROP_TABLE_REQUEST',
  'ADD_CATALOG_DROP_TABLE_SUCCESS',
  'ADD_CATALOG_DROP_TABLE_FAILURE'
)<DropTable, DropTable, string>();

export const updateCatalogDropTableAsync = createAsyncAction(
  'UPDATE_CATALOG_DROP_TABLE_REQUEST',
  'UPDATE_CATALOG_DROP_TABLE_SUCCESS',
  'UPDATE_CATALOG_DROP_TABLE_FAILURE'
)<DropTable, DropTable, string>();

export const clearCatalogDropTables = createAction('CLEAR_CATALOG_DROP_TABLES')();
import type { Player } from "../players";
import { Box, Button, FormControl, Grid, InputLabel, MenuItem, Select, TextField, Typography } from "@material-ui/core";
import _ from "lodash";
import { UserService } from "../../../services/user";
import { AdminKeyboardDateTimePicker } from "../../../components/AdminKeyboardDateTimePicker";
import { useState } from "react";
import { openAdminConfirmationDialog } from "../../../shared/hooks/useAdminConfirmationDialog";
import { pushAppNotification } from "../../../shared/hooks/useAppNotification";

interface Language {
  perform: string;
  pastTense: string;
}

interface Props {
  player: Player;
  setPlayer: (player: Player) => void;
  untilGetter: (player: Player) => Date | null;
  performUpdate: (player: Player, timestamp: number | null, reason: string | null) => Promise<Player>;
  language: Language;
}

const timePresentUnit = 86400000; // 1 day
const timePresets: { name: string, value: number; }[] = [
  {name: '1 day', value: 1},
  {name: '3 days', value: 3},
  {name: '7 days', value: 7},
  {name: '30 days', value: 30}
];

export const ModerationItem = ({player, setPlayer, language, performUpdate, untilGetter}: Props) => {
  const [reason, setReason] = useState('');
  const [duration, setDuration] = useState(-1);
  const [until, setUntil] = useState<Date | null>(null);
  const getTimestamp = (duration: number, until: Date | null) => {
    if (duration < 0) {
      return 0;
    }

    let timestamp = 0;
    if (duration === 0) {
      if (!until || isNaN(until.getTime())) {
        return 0;
      }
      timestamp = until.getTime();
    } else {
      timestamp = new Date().getTime() + duration * timePresentUnit;
    }
    return timestamp;
  };

  const onDurationChange = (event: any) => {
    const duration = event.target.value as number;
    setDuration(duration);
    if (duration === 0) {
      setUntil(new Date());
    }
  };

  const canPerform = () => {
    if (duration < 0) {
      return false;
    }
    if (duration === 0 && (!until || until.getTime() <= new Date().getTime())) {
      return false;
    }
    return true;
  };

  const onPerform = () => {
    const timestamp = getTimestamp(duration, until);
    if (!timestamp) {
      return;
    }
    openAdminConfirmationDialog({
      title: `${_.capitalize(language.perform)} ${player.getDisplayName()} until ${new Date(timestamp).toLocaleString()}?`,
      action: `${_.capitalize(language.perform)} player`,
      onConfirm: () => {
        performUpdate(player, Math.floor(timestamp / 1000), reason)
          .then(play => {
            setPlayer(play);
            pushAppNotification({type: 'success', message: `Player ${language.pastTense}`});
          })
          .catch(err => pushAppNotification({
            type: 'error',
            message: `Failed to ${language.perform} player: ${err.message}`
          }));
      }
    });
  };

  const onReverse = () => {
    openAdminConfirmationDialog({
      title: `Un${language.perform} ${player.getDisplayName()}?`,
      action: `Un${language.perform} player`,
      onConfirm: () => {
        performUpdate(player, null, null)
          .then(play => {
            setPlayer(play);
            pushAppNotification({type: 'success', message: `Player un${language.pastTense}`});
          })
          .catch(err => pushAppNotification({
            type: 'error',
            message: `Failed to un${language.perform} player: ${err.message}`
          }));
      }
    });
  };

  const currentUntilState = untilGetter(player);

  if (currentUntilState && currentUntilState.getTime() > new Date().getTime()) {
    return (<>
      <Grid item xs={10}>
        <Typography variant="subtitle1">
          {_.capitalize(language.pastTense)} until: {currentUntilState.toLocaleString()}
        </Typography>
      </Grid>
      {UserService.canUpdate('playerModeration') && (
        <Grid item xs={2}>
          <Button variant="contained" color="primary" onClick={onReverse}>
            Un{language.perform}
          </Button>
        </Grid>
      )}
    </>);
  } else if (!UserService.canUpdate('playerModeration')) {
    return (
      <Grid item xs={12}>
        <Typography variant="subtitle1">
          Player not {language.pastTense}.
        </Typography>
      </Grid>
    );
  } else {
    return (<>
      <Grid item xs={12}>
        <FormControl fullWidth>
          <InputLabel>{_.capitalize(language.perform)} duration</InputLabel>
          <Select value={duration} onChange={onDurationChange}>
            {duration === -1 && <MenuItem value={-1}><em>Select one</em></MenuItem>}
            {timePresets.map(t => (
              <MenuItem key={t.value} value={t.value}>{t.name}</MenuItem>
            ))}
            <MenuItem value={0}>Custom</MenuItem>
          </Select>
        </FormControl>
        {duration === 0 && (
          <Box mt={1}>
            <AdminKeyboardDateTimePicker
              label={`${_.capitalize(language.perform)} until`}
              disablePast
              fullWidth
              value={until}
              onChange={until => setUntil(until as Date)}
            />
          </Box>
        )}
      </Grid>
      <Grid item xs={12}>
        <FormControl fullWidth>
          <TextField
            label={`${_.capitalize(language.perform)} reason (optional)`}
            fullWidth
            onChange={(e) => setReason(e.target.value)}
            value={reason}
          />
        </FormControl>
      </Grid>
      <Grid item xs={12}>
        <Button
          variant="contained"
          color="primary"
          disabled={!canPerform()}
          onClick={onPerform}
        >
          {_.capitalize(language.perform)}
        </Button>
      </Grid>
    </>);
  }
};

import { useCallback, useState , useEffect } from 'react';

import { useDispatch } from 'react-redux';
import { cmSetMigrationActions } from '../../../../redux/catalog-migration/actions';
import type { MigrationActions } from '../../../../redux/catalog-migration/reducer';
import { useTypedSelector } from '../../../../redux/reducers';
import { TranslationDiff } from '../../types';
import { Translations } from './Translations';
import { TextLanguagesContainer } from './TextLanguagesContainer';

export const TranslationsContainer = () => {
  const dispatch = useDispatch();

  const sourceTranslations = useTypedSelector(state => state.catalogMigration.source.translations);
  const targetTranslations = useTypedSelector(state => state.catalogMigration.target.translations);
  const actions = useTypedSelector(state => state.catalogMigration.actions.translations);

  const [sourceItems, setSourceItems] = useState<TranslationDiff[] | null>(null);
  const [targetItems, setTargetItems] = useState<TranslationDiff[] | null>(null);

  const onSelectionChange = useCallback(
    (actions: MigrationActions) => dispatch(cmSetMigrationActions({ id: 'translations', actions })),
    [dispatch]
  );

  useEffect(() => {
    if (!sourceTranslations || !targetTranslations) {
      return;
    }
    setSourceItems(sourceTranslations.map(x => new TranslationDiff(x)));
    setTargetItems(targetTranslations.map(x => new TranslationDiff(x)));
  }, [sourceTranslations, targetTranslations]);

  return (<>
    <TextLanguagesContainer />
    <Translations
      sourceItems={sourceItems}
      targetItems={targetItems}
      actions={actions}
      onSelectionChange={onSelectionChange} />
  </>);
}
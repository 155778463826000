import { Accordion, AccordionDetails, AccordionSummary, Box, Button, Checkbox, Grid, Tooltip } from '@material-ui/core';
import ErrorIcon from '@material-ui/icons/Error';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import type { Column, DetailPanel } from 'material-table';
import type { MouseEvent } from 'react';
import { useCallback, useMemo } from 'react';
import ReactDiffViewer, { DiffMethod } from 'react-diff-viewer';
import { useSelector } from 'react-redux';
import MaterialTable from '../../../../components/MaterialTable';
import type { DiffItem, HiddenItem } from '../../../../pages/CatalogMigration/types';
import type { MigrationDependencyErrors } from '../../migrations';
import { Lock, LockOpen } from "@material-ui/icons";
import { CatalogMigrationService } from "../../../../services/catalog-migration";
import { usePushNotification } from "../../../../contexts/AppNotificationContext";
import _ from "lodash";
import { isDiffItemHidden, toHiddenItem } from "../../../../pages/CatalogMigration/types";
import { UserService } from "../../../../services/user";


const titles = {
  add: 'Added',
  change: 'Changed',
  remove: 'Removed'
}

export interface DiffsPanelProps<T extends DiffItem> {
  items: T[];
  hiddenItems: HiddenItem[];
  setHiddenItems: (hiddenItems: HiddenItem[]) => void;
  diffType: 'change' | 'add' | 'remove';
  expanded: boolean;
  columns: Column<T>[];
  mainColumn: number;
  enabledMap?: { [key: string]: boolean };
  selectionMap: { [key: string]: boolean };
  depErrors: MigrationDependencyErrors;
  onExpand: () => void;
  onSelectionChange: (selectionMap: { [key: string]: boolean }) => void;
  onFixDependencies: (items: T[]) => void;
  detailComponentOverride?: ((rowData: T) => React.ReactNode) | (DetailPanel<T> | ((rowData: T) => DetailPanel<T>))[];
}

export const DiffsPanel = <T extends DiffItem>(props: DiffsPanelProps<T>) => {
  const { diffType, expanded, items, enabledMap, selectionMap, onExpand, onFixDependencies, onSelectionChange  } = props;

  const selectedCount = useMemo(() => items.filter(v => selectionMap[v.diffId]).length, [items, selectionMap]);
  const darkMode = useSelector((state: any) => state.app.darkMode) as boolean;
  const pushNotification = usePushNotification();
  const canSelect = useCallback((item: T) => {
    return !isDiffItemHidden(item, props.hiddenItems);
  }, [props.hiddenItems]);

  const fixDependencies = useCallback((item: T) => {
    onFixDependencies([item]);
  }, [onFixDependencies]);

  const columns = useMemo(() => {
    const column = props.columns[props.mainColumn];
    if (!column) {
      console.error(`Main column not found. Index: ${props.mainColumn}`);
      return props.columns;
    }

    const columns = props.columns.slice();
    const columnRender = column.render || ((item) => (<>{(item as any)[column.field]}</>));
    columns[props.mainColumn] = {
      ...columns[props.mainColumn], render(item: T) {
        const itemDepErrors = props.depErrors[item.diffId];
        return (<>
          {columnRender(item, 'row')}
          {itemDepErrors && (
            <Box mt={2}>
              <Grid container spacing={1} alignItems="center">
                <Grid item xs="auto">
                  <ErrorIcon color="error" fontSize="small" />
                </Grid>
                <Grid item xs="auto">
                  Missing {itemDepErrors.missingDeps.length} {itemDepErrors.missingDeps.length > 1 ? 'dependencies' : 'dependency'}
                </Grid>
                {!itemDepErrors.hideFixable && (
                  <Grid item xs>
                    <Button size="small" variant="outlined" onClick={() => fixDependencies(item)}>
                      Fix
                    </Button>
                  </Grid>
                )}
              </Grid>
              {itemDepErrors && itemDepErrors.missingDeps.length > 0 && (
                <Box mt={2}> {
                  itemDepErrors.missingDeps.filter(i => i.message).map((message, i) =>
                    <Grid key={message.id} container spacing={1} alignItems="center">
                      <Grid  item xs="auto">
                        {message.message}
                      </Grid>
                    </Grid>
                    )}
                </Box>
              )}
            </Box>
          )}
        </>);
      }
    };

    return columns;
  }, [props.columns, props.mainColumn, props.depErrors, fixDependencies]);

  /**
   * We only want the dependency errors that correspond to this panel.
   */
  const depErrors = useMemo(() => {
    const dependencyErrors: MigrationDependencyErrors = {};
    if (props.depErrors) {
      items.forEach(i => {
        const depError = props.depErrors[i.diffId];
        if (depError) {
          dependencyErrors[i.diffId] = depError;
        }
      });
    }
    return dependencyErrors;
  }, [items, props.depErrors]);

  const onSelectClick = useCallback((item: T) => {
    if (canSelect(item)) {
      onSelectionChange({...selectionMap, [item.diffId]: !selectionMap[item.diffId]});
    }
  }, [canSelect, onSelectionChange, selectionMap]);

  const onSelectAllClick = useCallback((event: MouseEvent) => {
    event.stopPropagation();
    const newSelectionMap = { ...selectionMap };
    const value = selectedCount === 0;
    items.forEach(item => {
      if (canSelect(item)) {
        newSelectionMap[item.diffId] = value;
      }
    });
    onSelectionChange(newSelectionMap);
  }, [selectionMap, selectedCount, items, onSelectionChange, canSelect]);

  const onHideItem = (item: T) => {
    CatalogMigrationService.createMigrationHiddenItem(toHiddenItem(item))
      .then(hiddenItem => {
        onSelectionChange({ ...selectionMap, [item.diffId]: false });
        props.setHiddenItems(props.hiddenItems.concat([hiddenItem]));
      })
      .catch(err => pushNotification({type: 'error', message: `Failed to hide item: ${err.message}`}));
  };

  const onUnhideItem = (item: T) => {
    const hiddenItem = toHiddenItem(item);
    CatalogMigrationService.deleteMigrationHiddenItem(hiddenItem)
      .then(() => props.setHiddenItems(props.hiddenItems.filter(item => !_.isEqual(hiddenItem, item))))
      .catch(err => pushNotification({type: 'error', message: `Failed to unhide item: ${err.message}`}));
  };

  return (
    <Accordion
      expanded={expanded}
      disabled={items.length < 1}
      TransitionProps={{ unmountOnExit: true }}
      onChange={onExpand}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
      >
        <Grid container alignItems="center" spacing={2}>
          <Grid item xs="auto">
            <Checkbox
              style={{ padding: 0 }}
              onClick={onSelectAllClick}
              onFocus={event => event.stopPropagation()}
              checked={items.length > 0 && selectedCount >= items.length}
              indeterminate={selectedCount > 0 && selectedCount < items.length}
              disabled={items.length < 1}
            />
          </Grid>
          {!_.isEmpty(depErrors) && (<Grid item xs="auto">
            <ErrorIcon color="error" fontSize="small" />
          </Grid>
          )}
          <Grid item xs>
            {titles[diffType]} ({selectedCount} / {items.length})
          </Grid>
        </Grid>
      </AccordionSummary>
      <AccordionDetails style={{ padding: 0 }}>
        <Box width="100%">
          {items.length > 0 && (
            <MaterialTable<T>
              title=""
              columns={[
                {
                  title: '',
                  cellStyle: { width: 1 },
                  render(item) {
                    return canSelect(item) ? <Checkbox
                      style={{ padding: 0 }}
                      disabled={enabledMap?.[item.diffId] && !canSelect(item)}
                      onClick={() => onSelectClick(item)}
                      checked={selectionMap[item.diffId]}
                    /> :
                    <Tooltip title='Migration item is hidden'>
                      <Lock color='disabled' />
                    </Tooltip>;
                  }
                },
                ...columns
              ]}
              menuActions={(item) => UserService.canCreate('migrationConfig') ? [{
                type: 'button',
                icon: isDiffItemHidden(item, props.hiddenItems) ? LockOpen : Lock,
                label: isDiffItemHidden(item, props.hiddenItems) ? 'Unhide' : 'Hide',
                onClick: isDiffItemHidden(item, props.hiddenItems) ? onUnhideItem : onHideItem,
              }] : []}
              data={items}
              options={{
                pageSize: 10,
                actionsColumnIndex: columns.length + 1,
              }}
              components={{
                Container: Box
              }}
              detailPanel={props.detailComponentOverride || (item => (
                <ReactDiffViewer
                  oldValue={diffType === 'remove' ? item.diffJson : item.diffJsonPrev}
                  newValue={diffType === 'remove' ? item.diffJsonPrev : item.diffJson}
                  splitView
                  useDarkTheme={darkMode}
                  showDiffOnly
                  compareMethod={DiffMethod.LINES}
                  styles={{
                    variables: {
                      dark: {
                        codeFoldContentColor: '#fff'
                      }
                    }
                  }}
                />
              ))}
            />
          )}
        </Box>
      </AccordionDetails>
    </Accordion>
  )
}

import { Box, Button, CircularProgress, Grid } from '@material-ui/core';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { openConfirmationDialog, setAppNotification } from '../../redux/app/actions';
import { CurrencyService } from '../../services/currency';
import type { CurrencyList } from '../../services/model/currency';
import type { SeasonPartyPass} from '../../services/player-challenges/season-party-pass-plus';
import { SeasonPartyPassService } from '../../services/player-challenges/season-party-pass-plus';
import SeasonPartyPassForm from './SeasonPartyPassForm';

interface Props {
  seasonNumber: number;
}

const SeasonPartyPassPlus = (props: Props) => {
  const dispatch = useDispatch();

  const [currencyList, setCurrencyList] = useState<CurrencyList | null>(null);
  const [partyPasses, setPartyPasses] = useState<SeasonPartyPass[] | null>(null);

  const getCurrencyList = useCallback(() => {
    CurrencyService.getOrLoadCurrencies()
      .then(setCurrencyList)
      .catch(err => dispatch(setAppNotification({ type: 'error', message: 'Error getting currencies' })));
  }, [dispatch]);

  const getPartyPasses = useCallback(() => {
    setPartyPasses(null);
    SeasonPartyPassService.getPartyPasses(props.seasonNumber)
      .then(partyPasses => {
        partyPasses.sort((a, b) => a.tierSkips - b.tierSkips)
        setPartyPasses(partyPasses)
      })
      .catch(err => dispatch(setAppNotification({ type: 'error', message: 'Error getting party passes' })));
  }, [dispatch, props.seasonNumber]);

  useEffect(() => {
    getCurrencyList();
    getPartyPasses();
  }, [getCurrencyList, getPartyPasses]);

  const enablePartyPass = useCallback(async () => {
    try {
      await SeasonPartyPassService.createPartyPass({ seasonNumber: props.seasonNumber, tierSkips: 0, prices: {}, enabled: false, itemId: "", storeId: "", catalogName: "" });
      await SeasonPartyPassService.createPartyPass({ seasonNumber: props.seasonNumber, tierSkips: 1, prices: {}, enabled: false, itemId: "", storeId: "", catalogName: "" });
      getPartyPasses();
    } catch (e) {
      dispatch(setAppNotification({ type: 'error', message: 'Error enabling party pass+' }));
    }
  }, [dispatch, props.seasonNumber, getPartyPasses]);

  const disablePartyPass = useCallback(async () => {
    if (!partyPasses) {
      return;
    }

    const passes = partyPasses;
    setPartyPasses(null);

    try {
      for (const partyPass of passes) {
        await SeasonPartyPassService.deletePartyPass(partyPass.id);
      }
    } catch (e) {
      dispatch(setAppNotification({ type: 'error', message: 'Error disabling party pass+' }));
    }

    getPartyPasses();
  }, [dispatch, partyPasses, getPartyPasses]);

  const onDisablePartyPassClick = useCallback(() => {
    dispatch(openConfirmationDialog({
      title: 'Disable party pass+?',
      details: 'All party plus+ information will be removed. This cannot be undone.',
      action: 'Disable Party Pass+'
    }, disablePartyPass));
  }, [dispatch, disablePartyPass]);

  if (!currencyList || !partyPasses) {
    return (
      <Box textAlign="center">
        <CircularProgress />
      </Box>
    )
  }

  if (partyPasses.length < 1) {
    return (
      <Button color="primary" variant="contained" onClick={enablePartyPass}>
        Enable Party Pass+
      </Button>
    );
  }

  if (partyPasses.length === 2) {
    return (<>
      <Grid container spacing={1}>
        <Grid item xs={12} md={6}>
          <SeasonPartyPassForm
            title="Party Pass+"
            currencyList={currencyList}
            partyPass={partyPasses[0]}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <SeasonPartyPassForm
            title="Party Pass+ Bundle"
            currencyList={currencyList}
            partyPass={partyPasses[1]}
          />
        </Grid>
      </Grid>
      <Box mt={2} textAlign="right">
        <Button variant="outlined" onClick={onDisablePartyPassClick}>
          Disable Party Pass+
        </Button>
      </Box>
    </>);
  }

  return <>Unsupported number of party passes found: {partyPasses.length}</>;
}



export default SeasonPartyPassPlus;
import type { ApiError } from '../api';
import { api } from '../api';

export class Season {
  live_events_id = '';
  live_events_name = '';
  season_number?: number = undefined;
  season_name = '';
  active = false;
  canary = false;
  prior = false;
  season_token_cost: SeasonTokenCost[] = [];
  purchase_tier_skip_enabled = false;

  constructor(json?: any) {
    if (json) {
      this.live_events_id = json.liveEventsId;
      this.live_events_name = json.liveEventsName;
      this.season_number = json.seasonNumber;
      this.season_name = json.seasonName;
      this.active = json.active;
      this.canary = json.canary;
      this.prior = json.prior;
      this.season_token_cost = json.seasonTokenCost;
      this.purchase_tier_skip_enabled = json.purchaseTierSkipEnabled;
    }
  }

  toJson(): any {
    return {
      liveEventsId: this.live_events_id,
      liveEventsName: this.live_events_name,
      seasonNumber: this.season_number,
      seasonName: this.season_name,
      active: this.active,
      canary: this.canary,
      prior: this.prior,
      seasonTokenCost: this.season_token_cost,
      purchaseTierSkipEnabled: this.purchase_tier_skip_enabled,
    };
  }

  clone(): Season {
    return new Season(this.toJson());
  }
}

export interface DuplicateSeasonRequest {
  isQA: boolean;
  newSeasonName: string;
  newSeasonNumber: number;
}

export interface SeasonTokenCost {
  tokenCount: number;
  prices: { [key: string]: number };
}


export class SeasonsService {
  static async getAllSeasons(): Promise<Season[]> {
    return api.get({ url: '/live-events/season-event-infos' })
      .then(seasons => seasons.map((season: any) => new Season(season)))
      .catch((e: ApiError) => {
        e.message = `Failed to get all seasons. ${e.message}`;
        throw e;
      });
  }

  static async getSeasons(liveEventsId: string): Promise<Season[]> {
    return api.get({ url: `/live-events/${liveEventsId}/season-event-infos` })
      .then(seasons => seasons.map((season: any) => new Season(season)))
      .catch((e: ApiError) => {
        e.message = `Failed to get seasons. ${e.message}`;
        throw e;
      });
  }

  static async getSeason(liveEventsId: string, seasonNumber: number): Promise<Season> {
    return api.get({ url: `/live-events/${liveEventsId}/season-event-info/${seasonNumber}` })
      .then(season => new Season(season))
      .catch((e: ApiError) => {
        e.message = `Failed to get season. ${e.message}`;
        throw e;
      });
  }

  static async createSeason(liveEventsId: string, seasonJson: any): Promise<Season> {
    return api.post({ url: `/live-events/${liveEventsId}/season-event-info`, body: seasonJson })
      .then(season => new Season(season))
      .catch((e: ApiError) => {
        e.message = `Failed to create season. ${e.message}`;
        throw e;
      });
  }

  static async updateSeason(liveEventsId: string, seasonNumber: number, season: Season): Promise<Season> {
    return api.put({ url: `/live-events/${liveEventsId}/season-event-info`, body: season.toJson() })
      .then(season => new Season(season))
      .catch((e: ApiError) => {
        e.message = `Failed to update season. ${e.message}`;
        throw e;
      });
  }

  static async deleteSeason(liveEventsId: string, seasonNumber: number): Promise<null> {
    return api.delete({ url: `/live-events/${liveEventsId}/season-event-info/${seasonNumber}` })
      .catch((e: ApiError) => {
        e.message = `Failed to delete season. ${e.message}`;
        throw e;
      });
  }
}

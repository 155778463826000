import { CatalogMigrationService } from '../../../../services/catalog-migration';
import type { MigrationModule } from '../../migrations';
import { buildDiffData, buildMigrationActions } from '../../migrations';
import { ChallengeTriggerDiffs } from "./ChallengeTriggerDiffs";
import _ from "lodash";
import { ChallengeTriggerDiff } from "./ChallengeTriggerDiff";
import { MigrationType } from "../migration-type";
import { ChallengeTrigger } from '../../../../services/player-challenges/challenge-templates';
import { ChallengeTriggerTypeEnum } from '../../../../services/player-challenges/challenges';

export const challengeTriggerMigration: MigrationModule<ChallengeTriggerDiff> = {
  id: MigrationType.challengeTriggers,
  displayName: 'Game Challenge Triggers',
  diffComponent: ChallengeTriggerDiffs,
  crossEnvOnly: true,

  loadData: async (sourceData, targetData, migrationData) => {
    sourceData.challengeTriggers = await CatalogMigrationService.getChallengeTriggersByType(sourceData.env, ChallengeTriggerTypeEnum.GAME_CHALLENGE);
    sourceData.challengeTriggers = sourceData.challengeTriggers.splice(1, 1);
    targetData.challengeTriggers = await CatalogMigrationService.getChallengeTriggersByType(targetData.env, ChallengeTriggerTypeEnum.GAME_CHALLENGE);
    targetData.challengeTriggers = targetData.challengeTriggers.splice(0, 1);

    migrationData.challengeTriggers = buildDiffData(
      sourceData.challengeTriggers.map(trigger => new ChallengeTriggerDiff(trigger)),
      targetData.challengeTriggers.map(trigger => new ChallengeTriggerDiff(trigger))
    );
    return migrationData.challengeTriggers;
  },

  runMigration: async (props, setStatus) => {
    const actions = buildMigrationActions(props.migrationData.challengeTriggers, props.selections.challengeTriggers);
    if (actions.add.length + actions.update.length + actions.remove.length < 1) {
      return;
    }

    // create a map of type:version to challenge trigger for source triggers
    const sourceTriggersByTypeAndVersion = props.sourceData.challengeTriggers
      ? props.sourceData.challengeTriggers.reduce<{[key:string]: any}>((triggerMap, trigger) => {
        triggerMap[`${trigger.trigger_type}:${trigger.version}`] = trigger;
        return triggerMap;
      }, {})
      : {};

    // create a map of type:version to challenge trigger for target triggers
    const targetTriggersByTypeAndVersion = props.targetData.challengeTriggers
      ? props.targetData.challengeTriggers.reduce<{[key:string]: any}>((triggerMap, trigger) => {
        triggerMap[`${trigger.trigger_type}:${trigger.version}`] = trigger;
        return triggerMap;
      }, {})
      : {};

    const triggers: ChallengeTrigger[] = actions.add
      .map(typeAndVersion => {
        console.log('typeAndVersion');
        console.log(typeAndVersion);
        const trigger = sourceTriggersByTypeAndVersion[typeAndVersion];
        if (!trigger) {
          throw new Error(`Challenge trigger to create with name:version '${typeAndVersion}' not found in source triggers`);
        }
        const clone = _.clone(trigger);
        return clone;
      }).concat(
        actions.update
          .map(typeAndVersion => {
            const targetTrigger = targetTriggersByTypeAndVersion[typeAndVersion];
            if (!targetTrigger) {
              throw new Error(`Challenge trigger to update with name:version '${typeAndVersion}' not found in target triggers`);
            }
            const sourceTrigger = targetTriggersByTypeAndVersion[typeAndVersion];
            if (!sourceTrigger) {
              throw new Error(`Challenge trigger to update with name:version '${typeAndVersion}' not found in source triggers`);
            }
            const clone = _.clone(sourceTrigger);
            return clone;
          })
      );

    let progress = 0;
    for (const trigger of triggers) {
      setStatus('Migrating Challenge Triggers', progress);
      await CatalogMigrationService.migrateChallengeTrigger(props.targetData.env, trigger);
      progress += 100 / triggers.length;
    }
  }
};

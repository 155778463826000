import type { AppConfig } from '../config';
import config, { configs } from '../config';
import { store } from '../redux/store';
import { destroyToken } from '../axios';
import { setOAuthClient } from '../redux/authentication/actions'
import { UserManager } from 'oidc-client-ts';

export interface APIRequest {
  method?: string;
  url: string;
  query?: { [key: string]: any };
  body?: any;
  token?: string;
}

export class ApiError extends Error {
  jsonMessage: string;

  constructor(message = 'An unknown error occurred', public code?: number) {
    super(message);

    this.jsonMessage = message;
    if (code === 50000) {
      this.message = 'A server error occurred';
    }
  }
}

class API {
  constructor(
    private baseUrl: string
  ) { }

  async request<T>(request: APIRequest) {
    const headers = new Headers({
      'Content-Type': 'application/json',
      'Version': config.apis.mythical.version
    });

    if (request.token) {
      headers.append('Authorization', `Bearer ${request.token}`);
    }

    const options: RequestInit = {
      method: request.method || 'GET',
      headers,
      mode: 'cors'
    };

    if (request.body) {
      options.body = JSON.stringify(request.body);
    }

    let queryString = '';
    if (request.query) {
      for (const key in request.query) {
        if (Object.prototype.hasOwnProperty.call(request.query, key) && request.query[key] !== null && request.query[key] !== undefined) {
          if (queryString) {
            queryString += '&';
          }
          queryString += `${encodeURIComponent(key)}=${encodeURIComponent(request.query[key])}`;
        }
      }

      if (queryString) {
        queryString = `?${queryString}`;
      }
    }

    let response: Response;
    try {
      response = await fetch(this.baseUrl + request.url + queryString, options);
    } catch (e) {
      throw new ApiError();
    }

    if (response.status === 204) {
      return null;
    } else if (response.status === 401) {
      throw new ApiError('Unauthorized', 401);
    } else if (!response.ok) {
      let error: ApiError;
      try {
        error = this.buildApiErrorFromResponseJson(await response.json());
      } catch (e) {
        error = new ApiError();
      }
      throw error;
    } else {
      const responseText = await response.text();
      try {
        const json = JSON.parse(responseText);
        if (json.error) {
          throw this.buildApiErrorFromResponseJson(json);
        }
        if (!json.result) {
          json.result = {};
        }
        return json.result as T;
      } catch (e) {
        throw new ApiError();
      }
    }
  }

  private buildApiErrorFromResponseJson(json: any): ApiError {
    try {
      if (!json.error) {
        return new ApiError();
      }
      const message = typeof json.error.message === 'string' ? json.error.message : undefined;
      const code = typeof json.error.code === 'number' ? json.error.code : undefined;
      return new ApiError(message, code);
    } catch (e) {
      return new ApiError();
    }
  }

  async get<T = any>(request: APIRequest): Promise<T> {
    request.method = 'GET';
    const response = await this.request<T>(request);
    if (response === null) {
      throw new Error('API GET response should not be null');
    }
    return response;
  }

  async post<T = any>(request: APIRequest): Promise<T> {
    request.method = 'POST';
    const response = await this.request<T>(request);
    if (response === null) {
      throw new Error('API POST response should not be null');
    }
    return response;
  }

  async put<T = any>(request: APIRequest): Promise<T> {
    request.method = 'PUT';
    const response = await this.request<T>(request);
    if (response === null) {
      throw new Error('API PUT response should not be null');
    }
    return response;
  }

  async delete(request: APIRequest): Promise<null> {
    request.method = 'DELETE';
    return this.request(request);
  }
}

export class MythicalAPI extends API {
  async request<T>(request: APIRequest) {
    const auth = store.getState().authentication.oAuthClient;
    const user = auth?.user;
    if (user) {
      request.token = user.access_token;
    }

    try {
      const response = await super.request<T>(request);
      return response;
    } catch (e) {
      if (e instanceof ApiError && e.code === 401 && user && (user.expires_in || 0) < 30) {
        try {
          console.log('Request unauthorized. Refreshing token and retrying...');
          await auth?.userManager.signinSilent();
          const updatedUser = await auth?.userManager.getUser();
          request.token = updatedUser?.access_token;
          store.dispatch(setOAuthClient({
            userManager: auth?.userManager,
            user: updatedUser
          }));
          return super.request<T>(request);
        } catch (e2) {
          console.error('Failed to refresh auth token.', e2);
          await auth?.userManager.storeUser(null);
          destroyToken();
          throw new ApiError('Session expired. Refresh page to log back in.');
        }
      }
      console.log(e);
      throw e;
    }
  }
}

export class MythicalMigrationAPI extends API {
  private userManager: UserManager;
  private accessToken = '';

  constructor(config: AppConfig) {
    super(config.apis.mythical.baseUrl);
    this.userManager = new UserManager({
      authority: config.apis.mythical.authUrl,
      client_id: config.apis.mythical.clientId,
      redirect_uri: `${window.location.origin}/api/auth-migration`,
      scope: 'openid offline_access',
      automaticSilentRenew: false
    });
  }

  async isLoggedIn() {
    const user = await this.userManager.getUser();
    return !!user;
  }

  async logIn() {
    const user = await this.userManager.signinPopup();
    this.accessToken = user.access_token;
    return !!user;
  }

  async request<T>(request: APIRequest) {
    if (!this.accessToken) {
      const user = await this.userManager.getUser();
      if (user) {
        this.accessToken = user.access_token;
      }
    }

    if (this.accessToken) {
      request.token = this.accessToken;
    }

    try {
      return await super.request<T>(request);
    } catch (e) {
      if (e instanceof ApiError && e.code === 401) {
        try {
          console.log('MigrationAPI: Request unauthorized. Refreshing token and retrying...');
          const user = await this.userManager.signinPopup();
          if (user) {
            this.accessToken = user.access_token;
            request.token = this.accessToken;
            return super.request<T>(request);
          }
        } catch (e2) {
          console.error('MigrationAPI: Failed to refresh auth token', e2);
          await this.userManager.storeUser(null);
          this.accessToken = '';
          throw new ApiError('Migration session expired');
        }
      }

      console.log(e);
      throw e;
    }
  }
}

export const api = new MythicalAPI(config.apis.mythical.baseUrl);

const envApiMap: { [key: string]: API } = {
  [config.env]: api
};

export const getEnvApi = (env: string): API => {
  let api = envApiMap[env];
  if (!api) {
    const config = configs[env];
    if (!config) {
      throw new Error(`Unknown env config: ${env}`);
    }

    api = new MythicalMigrationAPI(config);
    envApiMap[env] = api;
  }

  return api;
}
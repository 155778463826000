import { JunctionEventInfo, LiveEventBase } from "../../../live-events/live-events-types";
import { normalizeSeasonalData, SeasonalDataForComparison } from "../seasonal-data/seasonal-data-comparer";
import { SeasonDictionary } from "../seasonal-data/SeasonalDiffView";
import { LiveEventDiff } from "./liveEventDiff";

export interface LiveEventForComparison extends LiveEventBase {
  seasonalData: SeasonDictionary;
  junctionEventInfos: JunctionEventInfo[];
}

export interface LiveEventDiffResult {
  updatedFields: LiveEventForComparison;
  addedFields: LiveEventForComparison;
  oldLiveEvent: LiveEventForComparison;
  newLiveEvent: LiveEventForComparison;
}

const normalizeJunctionEventInfo = (junctionEventInfo?: JunctionEventInfo): JunctionEventInfo => {
  if(!junctionEventInfo){
    return {} as JunctionEventInfo;
  }
  return {
    ...junctionEventInfo,
    potentialRewards: junctionEventInfo.potentialRewards.sort(),
    rewards: junctionEventInfo.rewards.sort(),
    junctionObjects: junctionEventInfo.junctionObjects.sort(),
    junctionNpcs: junctionEventInfo.junctionNpcs.sort(),
    junctionBeams: junctionEventInfo.junctionBeams.sort(),
  } as JunctionEventInfo;
};

const normalizeLiveEvent = (liveEventDiff?: LiveEventDiff): LiveEventForComparison => {
  if(!liveEventDiff){
    return {} as LiveEventForComparison;
  }
  const liveEvent = liveEventDiff.liveEvent;
  const seasons = liveEvent.seasonalData ? Object.assign({}, ...liveEvent.seasonalData?.map(sd => ({ [sd.seasonNumber.toString()]: normalizeSeasonalData(sd) }))) : {};
  const junctionEventInfos = liveEvent.junctionEventInfos ? liveEvent.junctionEventInfos?.map(jei => normalizeJunctionEventInfo(jei)).sort() : [];

  const liveEventBase: LiveEventBase = liveEventDiff.liveEvent;
  return {
    ...liveEventBase,
    seasonalData: seasons,
    junctionEventInfos: junctionEventInfos,
  } as LiveEventForComparison;
};

//Converts {a : {b : {c:"1"}}} to "a.b.c : 1"
const flatten = (obj: any) => {
  const object = Object.create(null);
  const path: any = [];
  const isObject = (value: any) => Object(value) === value;

  function dig(obj: any) {
    for (const [key, value] of Object.entries(obj)) {
      path.push(key);
      if (isObject(value)) {
        dig(value);
      }
      else {
        object[path.join('.')] = value;
      }
      path.pop();
    }
  }

  dig(obj);
  return object;
};

const diffFlatten = (oldFlat: any, newFlat: any) => {
  const updated = Object.assign({}, oldFlat);
  const added = Object.assign({}, newFlat);

  /**delete the unUpdated keys*/
  for (const key in newFlat) {
    if (newFlat[key] === oldFlat[key]) {
      delete updated[key];
      delete added[key];
    }
  }

  return [updated, added];
};

//Converts "a.b.c : 1" to {a : {b : {c:"1"}}}
const unflatten = (flattenObject: any) => {
  const unFlatten = Object.create(null);
  for (const [stringKeys, value] of Object.entries(flattenObject)) {
    const chain = stringKeys.split('.')
    let object = unFlatten

    for (const [i, key] of chain.slice(0, -1).entries()) {
      if (!object[key]) {
        const needArray = Number.isInteger(Number(chain[+i + 1]))
        object[key] = needArray ? [] : Object.create(null)
      }
      object = object[key];
    }
    const lastkey = chain.pop();
    if(lastkey) {
      object[lastkey] = value;
    }
  }
  return unFlatten;
}

export const liveEventComparer = {

  compare: (oldLiveEvent: LiveEventDiff, newLiveEvent: LiveEventDiff): LiveEventDiffResult => {
    //Convert arrays to dict where there is a unique key
    //sorts arrays where there is no unique key
    const oldLE = normalizeLiveEvent(oldLiveEvent);
    const newLE = normalizeLiveEvent(newLiveEvent);

    //Converts {a : {b : {c:"1"}}} to "a.b.c : 1"
    const flattenedOldLiveEvent = flatten(oldLE);
    const flattenedNewLiveEvent = flatten(newLE);

    //creates a differential from the flattened items.
    const [updated, added] = diffFlatten(flattenedOldLiveEvent, flattenedNewLiveEvent);
    return {
      updatedFields: unflatten(updated) as LiveEventForComparison,
      addedFields: unflatten(added) as LiveEventForComparison,
      oldLiveEvent: oldLE,
      newLiveEvent: newLE
    };
  },
}
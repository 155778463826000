import type { Reducer } from 'redux';
import { createReducer } from 'typesafe-actions';
import type { BlankoAbilities, BlankoProgression } from '../../services/model/blanko';
import { arrayToMap, removeKey } from '../utils-ts';
import { createBlankoProgressionAsync, deleteBlankoProgressionAsync, getBlankoClassesAsync, getBlankoProgressionAsync, getBlankoProgressionsAsync, setBlankoProgression, updateBlankoProgressionAsync } from './actions';

export interface BlankoProgressionsState {
  isLoading: boolean;
  byId: { [key: string]: BlankoProgression };
  classes: { isLoading: boolean, data: string[] }
  abilities: BlankoAbilities;
}

const initialState: BlankoProgressionsState = {
  isLoading: false,
  byId: {},
  classes: { isLoading: false, data: [] },
  abilities: {
    abilities: [],
    passives: [],
    modifiers: []
  }
};

const blankoProgressionsReducer: Reducer<BlankoProgressionsState> = createReducer(initialState)
  .handleAction(getBlankoProgressionsAsync.request, state => ({
    ...state,
    isLoading: true
  }))
  .handleAction(getBlankoProgressionsAsync.success, (state, action) => ({
    ...state,
    isLoading: false,
    byId: arrayToMap(action.payload, v => v.id)
  }))
  .handleAction(getBlankoProgressionAsync.request, (state, action) => ({
    ...state,
    isLoading: true,
    byId: removeKey(state.byId, action.payload)
  }))
  .handleAction([getBlankoProgressionAsync.success, createBlankoProgressionAsync.success, updateBlankoProgressionAsync.success], (state, action) => ({
    ...state,
    isLoading: false,
    byId: {
      ...state.byId,
      [action.payload.id]: action.payload
    }
  }))
  .handleAction(deleteBlankoProgressionAsync.success, (state, action) => ({
    ...state,
    byId: removeKey(state.byId, action.payload)
  }))
  .handleAction(setBlankoProgression, (state, action) => ({
    ...state,
    byId: {
      ...state.byId,
      [action.payload.id]: action.payload
    }
  }))
  .handleAction(getBlankoClassesAsync.request, state => ({
    ...state,
    classes: {
      ...state.classes,
      isLoading: true
    }
  }))
  .handleAction(getBlankoClassesAsync.success, (state, action) => ({
    ...state,
    classes: {
      ...state.classes,
      isLoading: false,
      data: action.payload.classes
    },
    abilities: action.payload.abilities
  }));

export default blankoProgressionsReducer;
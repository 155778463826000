
export interface BlankoProgressionLevelReward {
  type: string;
  param: any;
}

export interface BlankoProgressionLevel {
  id: string;
  name: string;
  xpToNextLevel: string;
  rewards: BlankoProgressionLevelReward[];
}

export interface BlankoProgressionTierCapstone {
  type: string;
  param: any;
}

export interface BlankoProgressionTier {
  id: string;
  name: string;
  levels: BlankoProgressionLevel[];
  capstone: BlankoProgressionTierCapstone[];
}

export interface BlankoProgression {
  id: string;
  name: string;
  tiers: BlankoProgressionTier[];
  blankoClasses: string[];
  json: any; // TODO: Remove.
  skillDropTableId: string | null;
}

export interface BlankoProgressionTierOption {
  id: string;
  name: string;
  iconName: string;
}

export interface BlankoClass {
  name: string;
  description: string;
  abilityClassType1: number;
  abilityClassType2: number;
}

export interface BlankoClassAbilityType {
  id: number;
  name: string;
}

export interface BlankoAbility {
  id: string;
  name: string;
}

export interface BlankoAbilities {
  abilities: BlankoAbility[];
  modifiers: BlankoAbility[];
  passives: BlankoAbility[];
  [key: string]: BlankoAbility[]; // Hack to support string key access
}

export interface BlankoProgressionSkillDropTable {
  id: string;
  name: string;
  value: string | null;
  deletedAt: number | null;
}

export const blankoTierOptions: BlankoProgressionTierOption[] = [
  { id: 'STR_GRADE_FAIR', name: 'Fair', iconName: 'Fair' },
  { id: 'STR_GRADE_GOOD', name: 'Good', iconName: 'Good' },
  { id: 'STR_GRADE_EXCELLENT', name: 'Excellent', iconName: 'Excellent' },
  { id: 'STR_GRADE_MINT', name: 'Mint', iconName: 'Mint' },
  { id: 'STR_GRADE_GEM_MINT', name: 'Gem Mint', iconName: 'GemMint' }
];

export const blankoAttachmentSlots: string[] = [
  'Back', 'Belt', 'Collar', 'Hat', 'Mask', 'Outfit', 'Shoes', 'Wrists', 'PaintGun', 'OffHand'
];

export const blankoEmoteSlots: string[] = ['Taunt', 'Emotion', 'Attention', 'Dance', 'Victory'];

export const noSkillDropTableItem = 'NONE';
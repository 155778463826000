import {
  Box,
  Button,
  Card,
  CardContent,
  CircularProgress, Dialog, DialogContent,
  FormControl,
  Grid, LinearProgress,
  MenuItem, Select,
  TextField as MuiTextField, Typography
} from "@material-ui/core";
import {useCallback, useEffect, useState} from "react";
import type {ActionTypes, BulkTag, PlayerTag} from "../accounts";
import {bulkTagAction, getTags} from "../accountApi";
import type {ApiError} from "../../../services/api";
import {pushAppNotification} from "../../../shared/hooks/useAppNotification";

export const BulkTagging = () => {

  const [emailInput, setEmailInput] = useState([''])
  const [emailsError, setEmailsError] = useState('')
  const [tags, setTags] = useState<PlayerTag[] | undefined>(undefined)
  const [actionInput, setActionInput] = useState<ActionTypes>('add' ?? 'remove')
  const [actionError, setActionError] = useState('')
  const [isSubmitting, setSubmitting] = useState(false)
  const [tagInput, setTagInput] = useState( '')
  const [isGranting, setGranting] = useState(false)

  useEffect( () => {
    if (tags === undefined) {
      getTags().then(setTags)
        .catch((e: ApiError) => pushAppNotification({
          type: 'error',
          message: `Failed to load tags. ${e.message}`
        }))
    }
  }, [tags])

  const onEmailsChange = useCallback((emails: string) => {
    const emailsList = emails.split(/\r?\n/)
    const emailsListTrim = emailsList.map(e => e.trim())
    setEmailInput(emailsListTrim);
  }, [])

  const handleActionChange = (event: { target: { value: any; }; }) => {
    setActionInput(event.target.value);
  };

  const handleTagChange = (event: { target: { value: any; }; }) => {
    setTagInput(event.target.value);
  };

  const bulkTaggingAction = useCallback((emails: string[], tagId: string, action: ActionTypes) => {
    const bulkTag: BulkTag = {
      emails,
      tagId,
      action
    }
    bulkTagAction(bulkTag).then(() => {
      pushAppNotification({ type: 'success', message: `Successfully added tag to players`})
      setEmailInput([''])
      setTagInput('')
    }).catch((e: ApiError) => {
      pushAppNotification( {type: 'error', message: `Failed to add tag to players. ${e.message}`})
      setSubmitting(false)
    })
    setGranting(false)
  }, [])

  const handleSubmit = useCallback( () => {
    setGranting(true)
    const emails = emailInput
    const tagId = tagInput
    const action = actionInput
    bulkTaggingAction(emails, tagId, action)

  }, [ actionInput, bulkTaggingAction, emailInput, tagInput])

  return(<>
    <Grid container spacing={3}>
      <Grid item xs={12} xl={6}>
        <Card>
          <CardContent>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <MuiTextField
                  type="text"
                  label="Player emails (one per line)"
                  value={emailInput}
                  onChange={event => onEmailsChange(event.target.value)}
                  error={!!emailsError}
                  helperText={emailsError}
                  multiline
                  rows={10}
                  rowsMax={10}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <Select name="action"
                    value={actionInput}
                    onChange={e => handleActionChange(e)}
                    error={!!actionError}
                    required
                  >
                    <MenuItem value="add">Add Tag</MenuItem>
                    <MenuItem value="remove">Remove Tag</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <Select name="tag"
                    label="Tag"
                    value={tagInput}
                    onChange={handleTagChange}
                  >
                    {tags && tags.map((tag) =>
                      <MenuItem key={tag.id} value={tag.id}>{tag.tag}</MenuItem>
                    )}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <Button disabled={isSubmitting} variant="contained" color="primary" onClick={handleSubmit}>
                  {isSubmitting ? (
                    <CircularProgress size={25} />
                  ) : 'Submit'}
                </Button>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
    <Dialog
      open={isGranting}
      fullWidth
      maxWidth="sm"
    >
      <DialogContent>
        <Box textAlign="center">
          <Box my={3}>
          </Box>
          <Typography>Initiating Tagging operations... Please wait.</Typography>
          <Typography>Do not close this tab.</Typography>
        </Box>
      </DialogContent>
    </Dialog>
  </>
  )
}
import { Box, Chip, Link } from '@material-ui/core';
import amber from '@material-ui/core/colors/amber';
import green from '@material-ui/core/colors/green';
import red from '@material-ui/core/colors/red';
import * as React from 'react';
import type { AdminRequestEntry } from '../../services/model/admin-request-entry';
import { Link as RouterLink } from 'react-router-dom';
import { PlayerResolver } from './PlayerResolver';
import type { Player } from '../../features/players/players';

const verbColors: { [key: string]: string; } = {
  'POST': green['700'],
  'PUT': amber['700'],
  'DELETE': red['700']
};

const englishVerbs: { [key: string]: string; } = {
  'POST': 'Created',
  'PUT': 'Updated',
  'DELETE': 'Deleted'
};

interface Props {
  entry: AdminRequestEntry;
}

export const PrettyAdminRequest = ({ entry }: Props) => {

  const renderMessage = (entry: AdminRequestEntry) => {
    const path = entry.path.split('/').map(v => decodeURI(v.trim())).filter(v => !!v);
    if (path[0] === 'inventory' && path[1] === 'catalogs' && path.length > 2) {
      const catalog = path[2];
      if (path[3] === 'items') {
        const itemId = path.length > 4 ? path[4] : entry.meta.itemId;
        return (<>
          {englishVerbs[entry.verb]} item&nbsp;
          {renderItemLink(catalog, itemId)}
          &nbsp;in catalog&nbsp;
          <Link component={RouterLink} to={`/catalogs/${catalog}`}>
            {catalog}
          </Link>
        </>);
      }
    } else if (path[0] === 'players' && path.length > 1 && /^[A-Z0-9]{32}$/.test(path[1])) {
      const playerId = path[1];

      let render: ((player: Player) => React.ReactNode) | null = null;
      if (path.length === 2 && entry.verb === 'PUT') {
        // eslint-disable-next-line react/display-name
        render = player => <>Updated {renderPlayerLink(player)}</>;
      } else if (path[2] === 'items') {
        if (entry.verb === 'POST' && path.length === 3) {
          // eslint-disable-next-line react/display-name
          render = player => <>Granted
            item {renderItemLink(entry.meta.catalogName, entry.meta.itemIds[0])} to {renderPlayerLink(player)}</>;
        } else if (entry.verb === 'DELETE' && path.length === 4) {
          // eslint-disable-next-line react/display-name
          render = player => <>Revoked item {path[3]} from {renderPlayerLink(player)}</>;
        }
      }

      if (render) {
        return <PlayerResolver playerId={playerId} render={render}/>;
      }
    } else if (path[1] === 'title') {
      const titleType = path[2] !== 'import' ? path[2] : 'JSON';
      let titleLink;

      switch (titleType.toLowerCase()) {
      case 'client':
        titleLink = '';
        break;
      case 'server':
        titleLink = 'internal';
        break;
      case 'maintenance':
        titleLink = 'maintenance';
        break;
      default:
        titleLink = 'other';
      }

      let valuesChanged = 0;
      for (const m in entry.meta) {
        valuesChanged++;
      }
      return (<>
        {titleType !== 'JSON' ? englishVerbs[entry.verb] : 'Imported'}&nbsp;
        {valuesChanged}&nbsp;
        {titleLink !== 'other' ? <Link component={RouterLink} to={`/title/${titleLink}`}>{titleType}</Link> : titleType}
        &nbsp;title{valuesChanged > 1 ? 's' : ''}&nbsp;
      </>);
    }

    return <>{decodeURI(entry.path)}</>;
  }

  const renderPlayerLink = (player: Player) => {
    return <Link component={RouterLink} to={`/players/${player.externalId}`}>{player.getDisplayName()}</Link>;
  }

  const renderItemLink = (catalog: string, itemId: string) => {
    return (
      <Link component={RouterLink} to={`/catalogs/${catalog}/items/${itemId}`}>
        {itemId}
      </Link>
    );
  }

  return (<>
    <Box component="span" mr={1}>
      <Chip
        size="small"
        color="primary"
        label={entry.verb}
        style={{backgroundColor: verbColors[entry.verb], fontWeight: 'bold'}}
      />
    </Box>
    {renderMessage(entry)}
  </>)
}
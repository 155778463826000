import { BlankoClassesDiff } from '../../../../pages/CatalogMigration/types';
import { CatalogMigrationService } from '../../../../services/catalog-migration';
import type { MigrationModule } from '../../migrations';
import { buildDiffData, buildMigrationActions } from '../../migrations';
import { BlankoClassDiffs } from './BlankoClassesDiffs';
import type { BlankoClass } from '../../../../services/model/blanko';
import { MigrationType } from "../migration-type";

export const blankoClassMigration: MigrationModule<BlankoClassesDiff> = {
  id: MigrationType.blankoClass,
  displayName: 'Blanko Classes',
  diffComponent: BlankoClassDiffs,
  crossEnvOnly: true,

  loadData: async (sourceData, targetData, migrationData) => {
    sourceData.blankoClasses = await CatalogMigrationService.getBlankoClasses(sourceData.env, sourceData.catalogName);
    targetData.blankoClasses = await CatalogMigrationService.getBlankoClasses(targetData.env, targetData.catalogName);

    migrationData.blankoClasses = buildDiffData(
      sourceData.blankoClasses.map(asset => new BlankoClassesDiff(asset)),
      targetData.blankoClasses.map(asset => new BlankoClassesDiff(asset))
    );

    return migrationData.blankoClasses;
  },

  runMigration: async (props, setStatus) => {
    const actions = buildMigrationActions(props.migrationData.blankoClasses, props.selections.blankoClass);
    if (actions.add.length + actions.update.length + actions.remove.length < 1) {
      return;
    }

    const sourceClasses = props.sourceData.blankoClasses;
    if (!sourceClasses) {
      throw new Error('Blanko Classes not loaded.');
    }

    const bclassMap: { [key: string]: BlankoClass } = {};
    sourceClasses.forEach(bclass => bclassMap[bclass.name] = bclass);

    //We always migrate ability-types transparently.
    const sourceAbilitiyTypes = await CatalogMigrationService.getBlankoClassAbilityTypes(props.sourceData.env, props.sourceData.catalogName);
    await CatalogMigrationService.migrateBlankoClassAbilityTypes(props.targetData.env, props.targetData.catalogName, sourceAbilitiyTypes);

    const bclasses = actions.add.concat(actions.update).map(id => bclassMap[id]);
    let progress = 0;
    setStatus('Migrating Blanko Classes & Ability Types', progress);
    await CatalogMigrationService.migrateBlankoClass(props.targetData.env, props.targetData.catalogName, bclasses);
    progress+= 100;    
  }
}

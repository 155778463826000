import { useCallback, useEffect, useMemo, useState } from "react";
import { getBranchOverrides, removePlayerBranchOverrides } from "../lokalise-api";
import type { Branches } from "../lokalise-types";
import { usePushNotification } from "../../../contexts/AppNotificationContext";
import MaterialTable from "../../../components/MaterialTable";
import { Column } from "material-table";
import { Box, Button, CircularProgress } from "@material-ui/core";
import { UserService } from "../../../services/user";

interface PlayerOverride {
  playerEmail: string;
  branchName: string;
}

export const LocalizationPlayerOverrides = () => {
  const [branchOverrides, setBranchOverrides] = useState<Branches | undefined>();
  const [selectedEmails, setSelectedEmails] = useState<string[]>([]);
  const [removing, setRemoving] = useState(false);
  const pushNotification = usePushNotification();
  const getOverrides = useCallback(() => {
    getBranchOverrides()
      .then(branchOverrides => setBranchOverrides(branchOverrides))
      .catch(err => pushNotification({type: 'error', message: `Failed to get branch overrides: ${err.message}`}));
  }, [pushNotification]);
  const removeOverrides = useCallback(() => {
    setRemoving(true);
    removePlayerBranchOverrides(selectedEmails)
      .then(() => {
        setRemoving(false);
        setSelectedEmails([]);
        getOverrides();
      })
      .catch(err => pushNotification({type: 'error', message: `Failed to remove player overrides: ${err.message}`}));
  }, [getOverrides, pushNotification, selectedEmails]);
  useEffect(() => {
    getOverrides();
  }, [getOverrides]);
  const data = useMemo<PlayerOverride[] | undefined>(() => {
    return branchOverrides?.branches
      .flatMap(boi => boi.playerEmails.map(playerEmail => ({playerEmail, branchName: boi.branchName,})));
  }, [branchOverrides]);

  const columns: Column<PlayerOverride>[] = useMemo(() => [
    {
      field: 'playerEmail',
      title: 'Player Email',
    },
    {
      field: 'branchName',
      title: 'Branch Name',
    },
  ], []);

  if (!data) {
    return <Box textAlign="center">
      <CircularProgress/>
    </Box>;
  }

  return <>
    <MaterialTable<PlayerOverride>
      options={{
        selection: UserService.canDelete('lokalise'),
      }}
      onSelectionChange={selected => setSelectedEmails(selected.map(po => po.playerEmail))}
      title="Player Overrides"
      columns={columns}
      data={data}
    />

    {UserService.canDelete('lokalise') && (
      <Box mt={2}>
        {removing ? (
          <CircularProgress size={20}/>
        ) : (
          <Button color="primary" variant="contained" onClick={removeOverrides} disabled={selectedEmails.length < 1}>
            Remove
            overrides {selectedEmails.length > 0 && (<>({`${selectedEmails.length} ${selectedEmails.length > 1 ? 'players' : 'player'}`})</>
          )}
          </Button>
        )}
      </Box>
    )}
  </>;
};

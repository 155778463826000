import { api, ApiError } from './api';
import type { BlankoAbilities, BlankoAbility, BlankoProgression, BlankoProgressionSkillDropTable } from './model/blanko';
import { ItemInstance } from './model/item-instance';
import type { PlayerDataField } from '../features/players/players';
import { TitleService } from './title-data';

export type BlankoData = { [key: string]: PlayerDataField; };

export interface BlankoPersonalityParameters {
  animClips: { [key: string]: string };
}

export class Blanko {
  id = '';
  name = '';
  ownerId = '';
  ownerExternalId = '';
  ownerName = '';
  dnaId = '';
  experience = 0;
  shelfLocation = 0;
  dgoodSerial = 0;

  blankoData: BlankoData = {};

  tieredProgressionId = '';
  tierId = '';
  levelId = '';
  blankoClass = '';

  accessorySlots: string[] = [];
  emoteSlots: string[] = [];
  neutralPerkSlots = 0;
  skillPerkSlots = 0;
  personalityAddons: string[] = [];

  constructor(json: any) {
    this.id = json.id;
    this.name = json.name;
    this.ownerId = json.ownerId;
    this.ownerExternalId = json.ownerExternalId;
    this.dnaId = json.dnaId;
    this.experience = json.experience;
    this.shelfLocation = json.shelfLocation;
    this.blankoData = json.blankoData;
    this.ownerName = json.ownerName || '';
    this.dgoodSerial = json.dgoodSerial;
    this.tieredProgressionId = json.tieredProgressionId || '';
    this.tierId = json.tierId || '';
    this.levelId = json.levelId || '';
    this.blankoClass = json.blankoClass || '';
    this.accessorySlots = json.accessorySlots || [];
    this.emoteSlots = json.emoteSlots || [];
    this.neutralPerkSlots = json.neutralPerkSlots || 0;
    this.skillPerkSlots = json.skillPerkSlots || 0;
    this.personalityAddons = json.personalityAddons || [];
  }
}

export class BlankosService {
  static async getBlankos(ownerId?: string): Promise<Blanko[]> {
    return api.get<any[]>({ url: '/blankos', query: { ownerId } })
      .then(blankos => blankos.map(v => new Blanko(v)))
      .catch((e: ApiError) => {
        e.message = `Failed to get blankos. ${e.message}`;
        throw e;
      });
  }

  static async getBlanko(id: string): Promise<Blanko> {
    return api.get({ url: `/blankos/${id}` })
      .then(blanko => new Blanko(blanko))
      .catch((e: ApiError) => {
        e.message = `Failed to get blanko. ${e.message}`;
        throw e;
      });
  }

  static async updateMetadata(id: string): Promise<null> {
    return api.put({ url: `/blankos/${id}/metadata` })
      .catch((e: ApiError) => {
        e.message = `Failed to update metadata. ${e.message}`;
        throw e;
      });
  }

  static async generateSelfie(blankoId: string, dnaId: string): Promise<null> {
    return api.post({ url: '/blankos/selfie', body: { blankoId, dnaId } })
      .catch((e: ApiError) => {
        e.message = `Failed to generate selfie. ${e.message}`;
        throw e;
      });
  }

  static async updateBlanko(blanko: Blanko): Promise<Blanko> {
    const body = {
      tieredProgressionId: blanko.tieredProgressionId,
      tierId: blanko.tierId,
      levelId: blanko.levelId,
      experience: blanko.experience,
      accessorySlots: blanko.accessorySlots,
      emoteSlots: blanko.emoteSlots,
      neutralPerkSlots: blanko.neutralPerkSlots,
      skillPerkSlots: blanko.skillPerkSlots,
      personalityAddons: blanko.personalityAddons,
      blankoClass: blanko.blankoClass || null
    };

    return api.put({ url: `/blankos/${blanko.id}`, body })
      .then(blanko => new Blanko(blanko))
      .catch((e: ApiError) => {
        e.message = `Failed to update blanko. ${e.message}`;
        throw e;
      });
  }

  static async updateBlankoData(blankoId: string, data: { [key: string]: PlayerDataField | null }): Promise<BlankoData> {
    return api.put<BlankoData>({ url: `/blankos/${blankoId}/data`, body: data })
      .catch((e: ApiError) => {
        e.message = `Failed to update blanko data. ${e.message}`;
        throw e;
      });
  }

  static async deleteBlanko(blankoId: string): Promise<null> {
    return api.delete({ url: `/blankos/${blankoId}` })
      .catch((e: ApiError) => {
        e.message = `Failed to delete blanko. ${e.message}`;
        throw e;
      });
  }

  static async getItems(blankoId: string): Promise<ItemInstance[]> {
    return api.get<ItemInstance[]>({ url: `/blankos/${blankoId}/items` })
      .then(items => items.map(v => new ItemInstance(v)))
      .catch((e: ApiError) => {
        e.message = `Failed to get blanko items. ${e.message}`;
        throw e;
      });
  }

  static async grantItems(blankoId: string, catalogName: string, itemIds: string[]): Promise<ItemInstance[]> {
    return api.post({ url: `/blankos/${blankoId}/items`, body: { catalogName, itemIds } })
      .then(items => items.itemInstances.map((v: any) => new ItemInstance(v)))
      .catch((e: ApiError) => {
        e.message = `Failed to grant items. ${e.message}`;
        throw e;
      });
  }

  static async revokeItem(blankoId: string, itemId: string): Promise<null> {
    return api.delete({ url: `/blankos/${blankoId}/items/${itemId}` })
      .catch((e: ApiError) => {
        e.message = `Failed to revoke item. ${e.message}`;
        throw e;
      });
  }

  static async grantBlankosDev(playerId: string, unowned: boolean): Promise<Blanko[]> {
    return api.post<any[]>({ url: '/blankos/grant-dev', body: { playerId, unowned } })
      .then(blankos => blankos.map(v => new Blanko(v)))
      .catch((e: ApiError) => {
        e.message = `Failed to grant blankos. ${e.message}`;
        throw e;
      });
  }

  static async getProgressions(): Promise<BlankoProgression[]> {
    return api.get<BlankoProgression[]>({ url: '/blanko-progressions' })
      .catch((e: ApiError) => {
        e.message = `Failed to get blanko progressions. ${e.message}`;
        throw e;
      });
  }

  static async getProgression(id: string): Promise<BlankoProgression> {
    return api.get<BlankoProgression>({ url: `/blanko-progressions/${id}` })
      .catch((e: ApiError) => {
        e.message = `Failed to get blanko progression. ${e.message}`;
        throw e;
      });
  }

  static async createProgression(json: any): Promise<BlankoProgression> {
    return api.post<BlankoProgression>({ url: '/blanko-progressions', body: json })
      .catch((e: ApiError) => {
        e.message = `Failed to create blanko progression. ${e.message}`;
        throw e;
      });
  }

  static async updateProgression(id: string, json: any): Promise<BlankoProgression> {
    return api.put<BlankoProgression>({ url: `/blanko-progressions/${id}`, body: json })
      .catch((e: ApiError) => {
        e.message = `Failed to update blanko progression. ${e.message}`;
        throw e;
      });
  }

  static async deleteProgression(id: string): Promise<null> {
    return api.delete({ url: `/blanko-progressions/${id}` })
      .catch((e: ApiError) => {
        e.message = `Failed to delete blanko progression. ${e.message}`;
        throw e;
      });
  }

  static async getProgressionDnas(progressionId: string): Promise<string[]> {
    return api.get<string[]>({ url: `/blanko-progressions/${progressionId}/dnas` })
      .catch((e: ApiError) => {
        e.message = `Failed to get blanko progression DNAs. ${e.message}`;
        throw e;
      });
  }

  static async updateProgressionDnas(progressionId: string, dnaIds: string[]): Promise<any> {
    return api.put({ url: `/blanko-progressions/${progressionId}/dnas`, body: dnaIds })
      .catch((e: ApiError) => {
        e.message = `Failed to update blanko progression DNAs. ${e.message}`;
        throw e;
      });
  }

  // Snuck abilities in here as well. TODO: Split these up.
  static async getBlankoClasses(): Promise<{ classes: string[], abilities: BlankoAbilities }> {
    const classes = await api.get({ url: '/blanko-classes' })
      .then(response => response.map((v: any) => v.name).filter((v: string) => !v.startsWith('STR_')))
      .catch((e: ApiError) => {
        e.message = `Failed to get blanko classes. ${e.message}`;
        throw e;
      });

    try {
      const titleData = (await TitleService.getTitle()).internalData;
      const json = titleData['Temp.Skills'];
      if (!json) {
        throw new Error('Skills missing in title data');
      }

      const abilities = JSON.parse(json) as BlankoAbilities;
      for (const key in abilities) {
        // Sometimes I hate TypeScript
        ((abilities as any)[key] as BlankoAbility[]).sort((a, b) => a.name.localeCompare(b.name));
      }

      return { classes, abilities };
    } catch (e) {
      throw new ApiError(`Failed to get blanko abilities. ${e.message}`);
    }
  }

  static async getProgressionSkillDropTables(): Promise<BlankoProgressionSkillDropTable[]> {
    return api.get<BlankoProgressionSkillDropTable[]>({ url: '/blanko-progressions/skill-drop-tables' })
      .catch((e: ApiError) => {
        e.message = `Failed to get blanko progression skill drop tables. ${e.message}`;
        throw e;
      });
  }

  static async getProgressionSkillDropTable(id: string): Promise<BlankoProgressionSkillDropTable> {
    return api.get<BlankoProgressionSkillDropTable>({ url: `/blanko-progressions/skill-drop-tables/${id}` })
      .catch((e: ApiError) => {
        e.message = `Failed to get blanko progression skill drop table. ${e.message}`;
        throw e;
      });
  }

  static async createProgressionSkillDropTable(json: any): Promise<BlankoProgressionSkillDropTable> {
    return api.post<BlankoProgressionSkillDropTable>({ url: '/blanko-progressions/skill-drop-tables/create', body: json })
      .catch((e: ApiError) => {
        e.message = `Failed to create blanko progression skill drop table. ${e.message}`;
        throw e;
      });
  }

  static async updateProgressionSkillDropTable(json: any): Promise<BlankoProgressionSkillDropTable> {
    return api.put<BlankoProgressionSkillDropTable>({ url: `/blanko-progressions/skill-drop-tables`, body: json })
      .catch((e: ApiError) => {
        e.message = `Failed to update blanko progression skill drop table. ${e.message}`;
        throw e;
      });
  }

  static async deleteProgressionSkillDropTable(id: string): Promise<null> {
    return api.delete({ url: `/blanko-progressions/skill-drop-tables/${id}` })
      .catch((e: ApiError) => {
        e.message = `Failed to delete blanko progression skill drop table. ${e.message}`;
        throw e;
      });
  }
}
import {Box, CircularProgress, Typography} from '@material-ui/core';
import { useEffect, useCallback, useState } from 'react';
import { useParams } from 'react-router';
import { BlockForm } from './BlockForm';
import { UserService } from '../../../services/user';
import { BlocksService } from '../../../services/blocks';
import type { Block, BlockTagsAutoComplete } from '../../../services/blocks';

interface myProps {
  blockId: string
}

export const BlockData = ({blockId} : myProps) => {
  const [tags, setTags] = useState<BlockTagsAutoComplete>();
  const [block, setBlock] = useState<Block>();

  const getData = useCallback(async () => {
    const tags = await BlocksService.getBlockTagsAutoComplete();
    const block = await BlocksService.getBlock(blockId);
    setTags(tags);
    setBlock(block);
  }, [blockId]);

  const onBlockTagsSave = (id: string, tags: string[]) => {
    BlocksService.saveBlockTags(id, tags);
  }

  useEffect(() => {
    getData();
  }, [getData]);
  
  return (<>
    {(!block || !tags) &&
      <Box textAlign="center">
        <CircularProgress />
      </Box>
    }
    {block &&
      <Box mb={2}>
        <Typography variant="h6">
          Block: {block.name}
        </Typography>
      </Box>
    }
    {block && tags &&
      <BlockForm
        block={block}
        tags={tags.tags}
        onTagsSave={onBlockTagsSave}
        onSave={() =>
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        { }
        }
        readOnly={!UserService.canUpdate('blocks')} />
    }
  </>);
};
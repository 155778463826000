import { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { cmSetMigrationActions } from '../../../../redux/catalog-migration/actions';
import type { MigrationActions } from '../../../../redux/catalog-migration/reducer';
import { useTypedSelector } from '../../../../redux/reducers';
import { TitleDataDiff } from '../../types';
import { TitleData } from './TitleData';

interface TitleDataContainerProps {
  type: 'client' | 'server';
}

export const TitleDataContainer = ({
  type
}: TitleDataContainerProps) => {
  const dispatch = useDispatch();

  const sourceTitleData = useTypedSelector(state => state.catalogMigration.source.titleData);
  const targetTitleData = useTypedSelector(state => state.catalogMigration.target.titleData);
  const actions = useTypedSelector(state => state.catalogMigration.actions[type === 'client' ? 'titleDataClient' : 'titleDataServer']);

  const [sourceItems, setSourceItems] = useState<TitleDataDiff[] | null>(null);
  const [targetItems, setTargetItems] = useState<TitleDataDiff[] | null>(null);

  const onSelectionChange = useCallback(
    (actions: MigrationActions) => dispatch(cmSetMigrationActions({ id: type === 'client' ? 'titleDataClient' : 'titleDataServer', actions })),
    [dispatch, type]
  );

  useEffect(() => {
    if (!sourceTitleData || !targetTitleData) {
      return;
    }

    const mapItems = (itemMap: { [key: string]: string }): TitleDataDiff[] => {
      const diffs: TitleDataDiff[] = [];
      for (const key in itemMap) {
        diffs.push(new TitleDataDiff(key, itemMap[key]));
      }
      return diffs;
    }

    setSourceItems(mapItems(type === 'client' ? sourceTitleData.titleData : sourceTitleData.internalData));
    setTargetItems(mapItems(type === 'client' ? targetTitleData.titleData : targetTitleData.internalData))
  }, [sourceTitleData, targetTitleData, type]);

  return (<TitleData
    sourceItems={sourceItems}
    targetItems={targetItems}
    actions={actions}
    type={type}
    onSelectionChange={onSelectionChange} />);
}

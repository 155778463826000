import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField
} from '@material-ui/core';
import { useState } from 'react';
import { Store, StoresService } from '../../services/stores';
import type { Catalog } from "../../services/catalogs";
import { pushAppNotification } from "../../shared/hooks/useAppNotification";

type Props = {
  storeToClone?: Store;
  stores: Store[];
  setStores: (stores: Store[]) => void;
  catalog: Catalog;
  onClose: () => void;
};

export const StoreAddOrCloneDialog = ({storeToClone, stores, catalog, onClose, setStores}: Props) => {
  const validate = (storeId: string): string => {
    if (!storeId) {
      return 'Cannot be blank';
    }
    if (stores.find(v => v.storeId === storeId)) {
      return 'Store already exists';
    }
    return '';
  };

  const [open, setOpen] = useState(true);
  const [submitting, setSubmitting] = useState(false);
  const [storeId, setStoreId] = useState(storeToClone ? `${storeToClone.storeId}_Clone` : '');
  const [storeIdError, setStoreIdError] = useState(validate(storeId));
  const [touched, setTouched] = useState(false);

  const onChange = (event: any) => {
    setTouched(true);
    const newStoreId = event.target.value;
    setStoreId(newStoreId);
    setStoreIdError(validate(newStoreId));
  };

  const onSubmit = (event: any) => {
    event.preventDefault();
    setTouched(true);
    if (storeIdError || !catalog.name) {
      return;
    }

    setSubmitting(true);
    if (storeToClone) {
      StoresService.getStore(catalog.name, storeToClone.storeId)
        .then(store => {
          const newStore = new Store();
          newStore.storeId = storeId.trim();
          newStore.items = store.items;
          newStore.tabs = store.tabs;
          return StoresService.createStore(catalog.name, newStore);
        })
        .then(clonedStore => {
          pushAppNotification({ type: 'success', message: 'Store cloned' });
          setStores(stores.concat([clonedStore]));
          setOpen(false);
        })
        .catch(err => {
          pushAppNotification({type: 'error', message: `Failed to clone store: ${err.message}`});
        });
    } else {
      const newStore = new Store();
      newStore.storeId = storeId.trim();
      newStore.items = [];
      StoresService.createStore(catalog.name, newStore)
        .then(createdStore => {
          pushAppNotification({ type: 'success', message: 'Store created' });
          setStores(stores.concat([createdStore]));
          setOpen(false);
        })
        .catch(err => {
          pushAppNotification({type: 'error', message: `Failed to add store: ${err.message}`});
        });
    }
  };

  return (
    <Dialog
      open={open}
      onClose={() => setOpen(false)}
      onExited={onClose}
      fullWidth
      maxWidth="md"
    >
      <form onSubmit={onSubmit}>
        <DialogTitle>{storeToClone ? `Clone ${storeToClone.storeId}` : 'Add store'}</DialogTitle>
        <DialogContent>
          <TextField
            type="text"
            label="Store ID"
            fullWidth
            autoFocus
            value={storeId}
            onChange={onChange}
            error={!!storeIdError && touched}
            helperText={storeIdError}
          />
        </DialogContent>
        <DialogActions>
          <Button type="submit" variant="contained" color="primary" disabled={!!storeIdError || submitting}>
            {submitting ? <CircularProgress size={20}/> : (storeToClone ? 'Clone' : 'Add')}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

import { combineReducers } from 'redux';
import { createReducer } from 'typesafe-actions';
import type { TitleFile } from '../../services/model/title-file';
import { Title } from '../../services/title-data';
import { getTitleAsync, getTitleFilesAsync, updateTitleAsync, deleteTitleFileAsync, updateClientTitleAsync, updateServerTitleAsync, updateMaintenanceAsync } from './actions';
import { arrayRemove } from '../utils-ts';

export interface TitleState {
  isLoading: boolean;
  isUpdating: boolean;
  data?: Title;
}

const initialState: TitleState = {
  isLoading: false,
  isUpdating: false
};

const titleReducer = createReducer<TitleState>(initialState)
  .handleAction(getTitleAsync.request, state => ({
    ...state,
    isLoading: true
  }))
  .handleAction(getTitleAsync.success, (state, action) => ({
    ...state,
    isLoading: false,
    data: action.payload
  }))
  .handleAction(getTitleAsync.failure, state => ({
    ...state,
    isLoading: false
  }))
  .handleAction(updateTitleAsync.request, state => ({
    ...state,
    isUpdating: true
  }))
  .handleAction(updateTitleAsync.success, (state, action) => ({
    ...state,
    isUpdating: false,
    data: action.payload
  }))
  .handleAction(updateTitleAsync.failure, state => ({
    ...state,
    isUpdating: false
  }))
  .handleAction(updateClientTitleAsync.success, (state, action) => ({
    ...state,
    data: state.data ? new Title({
      titleData: action.payload,
      internalData: state.data.internalData,
      maintenanceData: state.data.maintenanceData
    }) : state.data
  }))
  .handleAction(updateServerTitleAsync.success, (state, action) => ({
    ...state,
    data: state.data ? new Title({
      titleData: state.data.titleData,
      internalData: action.payload,
      maintenanceData: state.data.maintenanceData
    }) : state.data
  }))
  .handleAction(updateMaintenanceAsync.success, (state, action) => ({
    ...state,
    data: state.data ? new Title({
      titleData: state.data.titleData,
      internalData: state.data.internalData,
      maintenanceData: action.payload
    }) : state.data
  }));


export interface TitleFilesState {
  isLoading: boolean;
  data: TitleFile[];
}

const titleFilesReducer = createReducer<TitleFilesState>({ isLoading: false, data: [] })
  .handleAction(getTitleFilesAsync.request, state => ({
    ...state,
    isLoading: true
  }))
  .handleAction(getTitleFilesAsync.success, (state, action) => ({
    isLoading: false,
    data: action.payload
  }))
  .handleAction(getTitleFilesAsync.failure, state => ({
    ...state,
    isLoading: false
  }))
  .handleAction(deleteTitleFileAsync.success, (state, action) => ({
    ...state,
    data: arrayRemove(state.data, action.payload)
  }));

export default combineReducers({
  title: titleReducer,
  files: titleFilesReducer
});


export interface AppConfig {
  env: string;
  name: string;
  apis: {
    mythical: {
      authProvider: 'keycloak' | 'fusionauth'
      authUrl: string;
      baseUrl: string;
      clientId: string;
      version: string;
    }
  }
}

export const configs: { [key: string]: AppConfig } = {
  local: {
    env: 'local',
    name: 'Local',
    apis: {
      //FusionAuth config
      mythical: {
        authProvider: 'fusionauth',
        authUrl: 'https://auth-internal.mythicalgames.com',
        baseUrl: 'http://localhost:8186',
        clientId: 'fd7da16c-0a4d-4cd6-a663-8d3e269c0780',
        version: '1'
      }
      // Keycloak config
      // mythical: {
      //   authProvider: 'keycloak',
      //   authUrl: 'https://accounts.mythical.games/realms/mythical',
      //   baseUrl: 'http://localhost:8186',
      //   clientId: 'bp-toolshed-parent',
      //   version: '1'
      // }
    },
  },
  dev: {
    env: 'dev',
    name: 'Dev',
    apis: {
      mythical: {
        authProvider: 'fusionauth',
        authUrl: 'https://auth-internal.mythicalgames.com',
        baseUrl: 'https://toolshed.dev.blankos.game',
        clientId: 'd29adbad-c7f1-4822-a93a-b8693fb770c7',
        version: '1'
      }
    }
  },
  qa: {
    env: 'qa',
    name: 'QA',
    apis: {
      mythical: {
        authProvider: 'fusionauth',
        authUrl: 'https://auth-internal.mythicalgames.com',
        baseUrl: 'https://toolshed.qa.blankos.game',
        clientId: '02a48305-b7a6-4c72-afa5-b4d7e47a9525',
        version: '1'
      }
    },
  },
  loadtest: {
    env: 'loadtest',
    name: 'Load testing',
    apis: {
      mythical: {
        authProvider: 'fusionauth',
        authUrl: 'https://auth-internal.mythicalgames.com',
        baseUrl: 'https://toolshed.lt.blankos.game',
        clientId: 'e504ed32-1114-4af9-9404-0ac9a135f356',
        version: '1'
      }
    },
  },
  staging: {
    env: 'staging',
    name: 'Staging',
    apis: {
      mythical: {
        authProvider: 'fusionauth',
        authUrl: 'https://auth.mythicalgames.com',
        baseUrl: 'https://toolshed.staging.blankos.game',
        clientId: '0d5aa3af-6c1d-4530-9b02-2b1ab4d9f785',
        version: '1'
      }
    },
  },
  prod: {
    env: 'prod',
    name: 'Production',
    apis: {
      mythical: {
        authProvider: 'fusionauth',
        authUrl: 'https://auth.mythicalgames.com',
        baseUrl: 'https://toolshed.prod.blankos.game',
        clientId: 'b2abcbc5-f5b0-4c85-8341-e41c7ec57709',
        version: '1'
      }
    },
  }
}


declare global {
  interface Window { _env_: any; }
}

const runningEnvironemnt = window._env_ || "local";

const config = configs[runningEnvironemnt] || configs.local;

export default config;
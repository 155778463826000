import { Box, Typography } from '@material-ui/core';
import type { TextLanguageDiff, ItemDiffProps } from '../../types';
import { DiffsPanelsContainer } from '../DiffsPanels';

type TextLanguagesProps = ItemDiffProps<TextLanguageDiff>

export const TextLanguages = ({
  sourceItems,
  targetItems,
  actions,
  onSelectionChange
}: TextLanguagesProps) => (<>
  <Typography variant="h6">
    Text Languages
  </Typography>

  <Box mt={2}>
    {sourceItems && targetItems && (
      <DiffsPanelsContainer
        sourceItems={sourceItems}
        targetItems={targetItems}
        selectedIds={actions}
        columns={[
          { title: 'Code', field: 'code', defaultSort: 'asc' }
        ]}
        onSelectionChange={onSelectionChange}
      />
    )}
  </Box>
</>)
import type { Reducer } from 'redux';
import { createReducer } from 'typesafe-actions';
import type { RewardPath } from '../../../services/player-challenges/season-reward-paths';
import {
  updateRewardPathAsync,
  deleteRewardPathAsync,
  getRewardPathAsync,
  createRewardPathAsync
} from './actions';

export interface RewardPathsState {
  isLoading: boolean;
  isSaving: boolean;
  isDeleting?: number;
  data?: RewardPath | undefined;
}

const initialState: RewardPathsState = {
  isLoading: false,
  isSaving: false,
  isDeleting: undefined,
  data: undefined
};

const seasonRewardPathReducer: Reducer<RewardPathsState> = createReducer<RewardPathsState>(initialState)
  .handleAction(getRewardPathAsync.request, (state, action) => ({
    ...state,
    isLoading: true
  }))
  .handleAction(getRewardPathAsync.success, (state, action) => ({
    ...state,
    isLoading: false,
    data: action.payload
  }))
  .handleAction(getRewardPathAsync.failure, (state, action) => ({
    ...state,
    isLoading: false
  }))
  .handleAction(createRewardPathAsync.request, (state, action) => ({
    ...state,
    isSaving: true
  }))
  .handleAction(createRewardPathAsync.success, (state, action) => ({
    ...state,
    isSaving: false,
    data: action.payload
  }))
  .handleAction(createRewardPathAsync.failure, (state, action) => ({
    ...state,
    isSaving: false
  }))
  .handleAction(updateRewardPathAsync.request, (state, action) => ({
    ...state,
    isSaving: true
  }))
  .handleAction(updateRewardPathAsync.success, (state, action) => ({
    ...state,
    isSaving: false,
    data: action.payload
  }))
  .handleAction(updateRewardPathAsync.failure, (state, action) => ({
    ...state,
    isSaving: false
  }))
  .handleAction(deleteRewardPathAsync.request, (state, action) => ({
    ...state,
    isDeleting: action.payload.seasonNumber
  }))
  .handleAction(deleteRewardPathAsync.success, (state, action) => ({
    ...state,
    isDeleting: undefined,
    data: undefined
  }))
  .handleAction(deleteRewardPathAsync.failure, (state, action) => ({
    ...state,
    isDeleting: undefined
  }));

export default seasonRewardPathReducer;
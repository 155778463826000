import type { BlankoGemRushAndFuse, BlankoGemRushFuse } from '../../../gem-rush/blankoGemRush';
import { GemRushDiff } from '../../../../pages/CatalogMigration/types';
import { CatalogMigrationService } from '../../../../services/catalog-migration';
import type { MigrationModule } from '../../migrations';
import { buildDiffData, buildMigrationActions } from '../../migrations';
import { GemRushDiffs } from './GemRushDiffs';
import { MigrationType } from "../migration-type";

export const gemRushMigration: MigrationModule<GemRushDiff> = {
  id: MigrationType.gemRush,
  displayName: 'Gem Rush',
  diffComponent: GemRushDiffs,
  crossEnvOnly: true,

  loadData: async (sourceData, targetData, migrationData) => {
    sourceData.gemRushes = await CatalogMigrationService.getGemRushes(sourceData.env);
    targetData.gemRushes = await CatalogMigrationService.getGemRushes(targetData.env);

    const sourceGemRushFuses = await CatalogMigrationService.getGemRushFuses(sourceData.env);
    const targetGemRushFuses = await CatalogMigrationService.getGemRushFuses(targetData.env);

    const arrayToArrayMap = (arr: BlankoGemRushFuse[]) => {
      const arrMap: { [key: string]: BlankoGemRushFuse[] } = {};
      arr.map((obj) => {
        const key = obj.blankoGemRushId || "unknown";
        if (!arrMap[key]) {
          arrMap[key] = [];
        }
        arrMap[key].push(obj);
      });
      return arrMap
    };

    const sourceFuseMap = arrayToArrayMap(sourceGemRushFuses);
    const targetFuseMap = arrayToArrayMap(targetGemRushFuses);

    sourceData.gemRushes.forEach(e => e.fuses = sourceFuseMap[e.id])
    targetData.gemRushes.forEach(e => e.fuses = targetFuseMap[e.id]);

    //strip out bad rushes (missing fuse setups as an example)
    sourceData.gemRushes = sourceData.gemRushes.filter(gr => {
      return !!gr.fuses;
    });
    targetData.gemRushes = targetData.gemRushes.filter(gr => {
      return !!gr.fuses;
    });

    //Build an array of active rush Id's in the target environment
    const activeRushes = targetData.gemRushes.filter(gr => {
      return gr.active
    }).map(rush => {
      return rush.id;
    });
    //Dont allow migration on a target gem rush if it is active (prevent QA -> Prod push from disabling the rush in Prod)
    sourceData.gemRushes = sourceData.gemRushes.filter(gr => {
      return activeRushes.indexOf(gr.id) === -1;
    });

    migrationData.gemRush = buildDiffData(
      sourceData.gemRushes.map(file => new GemRushDiff(file)),
      targetData.gemRushes.map(file => new GemRushDiff(file))
    )
    return migrationData.gemRush;
  },

  runMigration: async (props, setStatus) => {
    const actions = buildMigrationActions(props.migrationData.gemRush, props.selections.gemRush);
    if (actions.add.length + actions.update.length + actions.remove.length < 1) {
      return;
    }

    const sourceRushes = props.sourceData.gemRushes;
    if (!sourceRushes) {
      throw new Error('Gem Rushes not loaded.');
    }

    const getFilename = (url: string) => {
      const index = url.lastIndexOf('/');
      return index > -1 ? url.slice(index + 1) : url;
    }

    const rushMap: { [key: string]: BlankoGemRushAndFuse } = {};
    sourceRushes.forEach(rush => rushMap[rush.id] = rush);

    const rushes = actions.add.concat(actions.update).map(id => rushMap[id]);

    let progress = 0;
    for (const rush of rushes) {
      setStatus('Migrating Gem Rushes and Fuses', progress);
      //only migrate rushes as inactive
      rush.active = false;
      //For migration, make sure remaining quantity = max quantity.
      for (const f of rush.fuses) {
        f.remainingQuantity = f.maxQuantity;
        //migrate fuse images if they exist.
        if (f.popupImageUrl) {
          const imageData = await CatalogMigrationService.transferGemRushImage(props.targetData.env, f.popupImageUrl, getFilename(f.popupImageUrl));
          f.popupImageUrl = imageData.publicUrl;
        }
      };

      await CatalogMigrationService.migrateGemRush(props.targetData.env, props.targetData.catalogName, rush);
      progress += 100 / rushes.length;
    }
  }
}

import type { MigrationDiffCommonProps } from '../../migrations';
import { DiffsPanels } from '../../components/diff/DiffsPanels';
import { LiveEventsDiffView } from './LiveEventsDiffView';
import { LiveEventDiff } from './liveEventDiff';
import { liveEventComparer } from './live-event-comparer';

export const LiveEventDiffs = (props: MigrationDiffCommonProps) => {
  const diffs = props.migrationData.liveEvents;

  const detailComponentOverride = (liveEvent: LiveEventDiff) => {
    const previousLiveEvent = liveEvent.diffPrev as LiveEventDiff
    const comparerResults = liveEventComparer.compare(previousLiveEvent, liveEvent);
    return (<>
      <LiveEventsDiffView
        beforeChanges={comparerResults.updatedFields}
        afterChanges={comparerResults.addedFields}
        previous={comparerResults.oldLiveEvent}
        current={comparerResults.newLiveEvent} />
    </>)
  }

  if (diffs) {
    return (
      <DiffsPanels
        diffs={diffs}
        columns={[
          { title: 'Name', field: 'name', defaultSort: 'asc'  },
          {
            title: 'Type', field: 'liveEventType', defaultSort: 'asc', render(item) {
              return (<>
                {item.liveEvent.liveEventType}
              </>);
            }
          },
        ]}
        selectionMap={props.selections.liveEvents || {}}
        onSelectionChange={map => props.onSelectionsChange({...props.selections, liveEvents: map})}
        detailComponentOverride={detailComponentOverride}
        {...props}
      />
    )
  }

  return null;
};

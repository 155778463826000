import type { ConnectedProps} from "react-redux";
import {connect} from "react-redux";
import { Component } from "react";
import type {RootState} from "../../redux/reducers";
import type {RouteComponentProps} from "react-router";
import {selectAllDailyMessages} from "../../redux/motd/selectors";
import {
  createDailyMessageAsync,
  deleteDailyMessageAsync,
  getDailyMessagesAsync,
  updateDailyMessageAsync
} from "../../redux/motd/actions";
import type {Column, EditComponentProps} from "material-table";
import type {DailyMessage} from "../../services/daily-messages";
import {DailyMessageService} from "../../services/daily-messages";
import MaterialTable from "../../components/MaterialTable";
import {UserService} from "../../services/user";
import {TextField, Box, Button} from "@material-ui/core";
import StoreImageUploadDialog from '../Store/StoreImageUploadDialog';
import ImageUpload from '../../components/ImageUpload';

const mapStateToProps = (state: RootState) => ({
  dailyMessages: selectAllDailyMessages(state)
});

const mapDispatch = {
  requestAllDailyMessages: getDailyMessagesAsync.request,
  requestCreateDailyMessage: createDailyMessageAsync.request,
  requestUpdateDailyMessage: updateDailyMessageAsync.request,
  requestDeleteDailyMessage: deleteDailyMessageAsync.request
};

const connector = connect(mapStateToProps, mapDispatch);

type Props = RouteComponentProps & ConnectedProps<typeof connector>;

class DailyMessagesGrid extends Component<Props> {

  componentDidMount() {
    this.props.requestAllDailyMessages();
  }

  render() {
    const dailyMessages = this.props.dailyMessages.data;
    
    return <>
      <MaterialTable
        title="Daily Messages"
        columns={this.getColumns()}
        data={dailyMessages}
        options={{
          addRowPosition: 'first',
          toolbarButtonAlignment: 'left'
        }}
        editable={{
          onRowAdd: UserService.canCreate('motd') ? message => {
            if (message.title && message.body && message.messageDate && message.endDate) {
              if (message.file){
                DailyMessageService.uploadDailyMessageImage(message.file).then(imageFileName => {
                  this.setState({ imageUrl: imageFileName });
                  message.mediaUrl = imageFileName;
                  this.props.requestCreateDailyMessage(message);
                });
              } else {
                this.props.requestCreateDailyMessage(message);
              }
              return Promise.resolve();
            } else {
              return Promise.reject('All fields required.')
            }
          } : undefined,
          onRowUpdate: UserService.canUpdate('motd') ? message => {
            if (message.title && message.body && message.messageDate && message.endDate) {
              if (message.file){
                DailyMessageService.uploadDailyMessageImage(message.file).then(imageFileName => {
                  this.setState({ imageUrl: imageFileName });
                  message.mediaUrl = imageFileName;
                  this.props.requestUpdateDailyMessage(message);
                });
              } else {
                this.props.requestUpdateDailyMessage(message);
              }
              return Promise.resolve();
            } else {
              return Promise.reject('All fields required.')
            }
          } : undefined,
          onRowDelete: UserService.canDelete('motd') ? message => {
            this.props.requestDeleteDailyMessage(message);
            return Promise.resolve();
          } : undefined
        }}
        localization={{
          body: {
            editRow: {
              deleteText: 'Delete message?'
            }
          }
        }}
      />
    </>;
  }

  getColumns() {
    const columns: Column<DailyMessage>[] = [
      {
        title: 'Title',
        field: 'title',
        sorting: true
      },
      {
        title: 'Body',
        field: 'body',
        sorting: true,
        editComponent: props => <BodyInputComponent {...props} />
      },
      {
        title: 'Start Date',
        field: 'messageDate',
        type: 'datetime',
        searchable: false,
        defaultSort: 'desc'
      },
      {
        title: 'End Date',
        field: 'endDate',
        type: 'datetime',
        searchable: false,
        defaultSort: 'desc'
      },
      {
        title: 'Media',
        field: 'file',
        editComponent: props => <MediaInputComponent {...props} />,
        render: message => (<>
          {message.mediaUrl.length > 0 ? (
            <img height='100px' width='100px' src={message.mediaUrl} />
          ) : <>{message.mediaUrl}</>}
        </>)
      }
    ];
    return columns;
  }
}

interface BodyInputState {
  body: string;
}

interface MediaInputState {
  file: any;
}

class MediaInputComponent extends Component<EditComponentProps<DailyMessage>, MediaInputState> {
  constructor(props: EditComponentProps<DailyMessage>) {
    super(props);
    this.state = {
      file: props.rowData.file
    };
  }

  onChange = (file: File) => {
    this.setState({ file });
    this.props.onChange(file);
  };

  onClick = (event: any) => {
    this.props.rowData.mediaUrl = "";
    this.props.onChange("");
  };

  render() {
    return (
      <Box>
        {this.props.rowData.mediaUrl?.length > 0 ? 
          (<Button variant="contained" color="primary" onClick={this.onClick}>Change Image</Button>) :
          (<ImageUpload onFileChange={this.onChange} />) 
        }
      </Box>
    );
  }
}

class BodyInputComponent extends Component<EditComponentProps<DailyMessage>, BodyInputState> {
  constructor(props: EditComponentProps<DailyMessage>) {
    super(props);
    this.state = {
      body: props.rowData.body || ''
    };
  }

  onChange = (event: any) => {
    const body = (event.target.value as string) || '';
    this.setState({ body });
    this.props.onChange(body);
  };

  render() {
    return (
      <TextField multiline={true} rows={4} fullWidth={true} value={this.state.body} onChange={this.onChange} />
    );
  }
}

export default connector(DailyMessagesGrid);
import { LinkTabs} from "../../../components/LinkTabs";
import { ClientVersionList } from "../ClientVersionList";
import { useHistory, useLocation, useRouteMatch } from 'react-router';
import {GameQueueView} from "../GameQueueView";

export const LauncherPage = () => {
  const history = useHistory();
  const location = useLocation();
  const match = useRouteMatch();
  return (
    <LinkTabs
      history={history}
      location={location}
      match={match}
      tabs={[
        {
          // eslint-disable-next-line react/display-name
          url: '/launcher/blankos-win64', label: 'Blankos (Win64)', absolute: true, render: () =>
            <ClientVersionList
              gridTitleText="Windows Blankos Block Party Client Versions"
              clientNameFilter="Blankos"
            />
        },
        {
          // eslint-disable-next-line react/display-name
          url: '/launcher/blankos-macosx', label: 'Blankos (MacOSX)', absolute: true, render: () =>
            <ClientVersionList
              gridTitleText="MacOSX Blankos Block Party Client Versions"
              clientNameFilter="Blankos-MacOSX"
            />
        },
        {
          // eslint-disable-next-line react/display-name
          url: '/launcher/launcher-win64', label: 'Launcher (Win64)', absolute: true, render: () =>
            <ClientVersionList
              gridTitleText="Windows Launcher Versions"
              clientNameFilter="Launcher"
            />
        },
        {
          // eslint-disable-next-line react/display-name
          url: '/launcher/launcher-macosx', label: 'Launcher (MacOSX)', absolute: true, render: () =>
            <ClientVersionList
              gridTitleText="MacOSX Launcher Versions"
              clientNameFilter="Launcher-MacOSX"
            />
        },
        {
          // eslint-disable-next-line react/display-name
          url: '/launcher/game-queue', label: 'Game Queue', absolute: true, render: () =>
            <GameQueueView />
        }
      ]}
    />
  );
}

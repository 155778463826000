import { Box, Button, Accordion, AccordionDetails, AccordionSummary, Typography } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { Field, FieldArray } from 'formik';
import { TextField } from 'formik-material-ui';
import { useState } from 'react';
import type { BlankoProgression, BlankoProgressionTierOption } from '../../services/model/blanko';
import type { LevelFormValues } from './BlankoLevelEditor';
import BlankoLevelEditor from './BlankoLevelEditor';
import type { CurrenciesFormValues } from './CurrenciesForm';
import CurrenciesForm from './CurrenciesForm';

export interface TierFormValues {
  tier: BlankoProgressionTierOption;
  levels: LevelFormValues[];
  burnCurrency: CurrenciesFormValues;
  burnGumballs: string;
}

interface Props {
  option: BlankoProgressionTierOption;
  formName: string;
  value: TierFormValues;
  isFirst: boolean;
  isLast: boolean;
  skillDropTableId: string;
}

const BlankoTierEditor = (props: Props) => {

  const [expanded, setExpanded] = useState(false);

  const newLevel: LevelFormValues = {
    attachmentSlots: [],
    emoteSlots: [],
    skillPerkSlots: '0',
    neutralPerkSlots: '0',
    randomNeutralPerks: '0',
    personalityAddons: '',
    xpToNextLevel: '0',
    abilities: [],
    items: [],
    currency: [{
      code: "BB",
      name: "Blanko Bucks",
      amount: '0'
    }, {
      code: "GV",
      name: "Golden Vibes",
      amount: '0'
    }],
    defaultAttachments: [],
    defaultEmotes: []
  };

  return (
    <Accordion expanded={expanded} onChange={(event, expanded) => setExpanded(expanded)} TransitionProps={{ unmountOnExit: true }}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography variant="h5">
          {props.option.name}
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Box width="100%">
          {!props.isLast && (
            <Box mb={2}>
              <Typography variant="h6">
                Next grade requirements
              </Typography>

              <CurrenciesForm
                formName={`${props.formName}.burnCurrency`}
                value={props.value.burnCurrency}
              />

              <Box mt={1}>
                <Field
                  component={TextField}
                  name={`${props.formName}.burnGumballs`}
                  type="text"
                  label="Gumballs"
                />
              </Box>
            </Box>
          )}
          <FieldArray
            name={`${props.formName}.levels`}
            render={arrayHelpers => (
              <>
                {props.value.levels && props.value.levels.length > 0 ? (
                  <>
                    <Box mb={2}>
                      <Button variant="contained" color="primary" type="button" onClick={() => arrayHelpers.push(newLevel)}>
                        Add Level
                      </Button>
                    </Box>
                    {props.value.levels.map((level, index) => (
                      <BlankoLevelEditor
                        key={index}
                        formName={`${props.formName}.levels[${index}]`}
                        level={level}
                        isFirstTier={props.isFirst}
                        isFirst={index === 0}
                        index={index}
                        skillDropTableId={props.skillDropTableId}
                        onRemove={() => arrayHelpers.remove(index)}
                        />
                    ))}
                  </>
                ) : (
                  <Button variant="contained" color="primary" type="button" onClick={() => arrayHelpers.push(newLevel)}>
                    Add Level
                  </Button>
                )}
              </>
            )}
          />
        </Box>
      </AccordionDetails>
    </Accordion>
  );
}

export default BlankoTierEditor;
import type {RootState} from "../../redux/reducers";
import type { ConnectedProps} from "react-redux";
import {connect} from "react-redux";
import { Component } from "react";
import {Box, Button, Checkbox, Dialog, DialogContent, FormControlLabel, TextField} from "@material-ui/core";
import DialogTitle from "@material-ui/core/DialogTitle";
import {ScheduleCalendar, ScheduleCalendarTypeEnum} from "../../services/player-challenges/season-challenge-schedule-calendar";
import MaterialTable from "../../components/MaterialTable";
import type {Column} from "material-table";
import type {Challenge} from "../../services/player-challenges/challenges";
import {getSeasonChallengeScheduleAsync, updateSeasonChallengeScheduleAsync} from "../../redux/player-challenges/season-challenge-schedule/actions";
import type {ChallengeSchedule} from "../../services/player-challenges/season-challenge-schedule";
import { DailyChallenge, ScheduledChallenge, WeeklyChallenge} from "../../services/player-challenges/season-challenge-schedule";
import CheckBoxIcon from '@material-ui/icons/CheckBox';

interface OwnProps {
  onClose: () => void;
  seasonNumber: number;
  challengesToDuplicate: ScheduleCalendar[];
  challengeMap: { [index: string]: Challenge };
  calendarType: ScheduleCalendarTypeEnum;
}

const mapStateToProps = (state: RootState) => ({
  seasonalChallengeSchedule: state.seasonChallengeSchedule
});

const mapDispatch = {
  getSeasonChallengeScheduleAsync: getSeasonChallengeScheduleAsync.request,
  updateSeasonChallengeScheduleAsync: updateSeasonChallengeScheduleAsync.request
}

const connector = connect(mapStateToProps, mapDispatch);

type Props = OwnProps & ConnectedProps<typeof connector>;

interface State {
  open: boolean;
  calendarNumberToSet: number | undefined;
  isLimited: boolean | undefined;
  limitedStart: number | undefined;
  limitedEnd: number | undefined;
}

class DuplicateChallengesDialog extends Component<Props, State> {
  private dailyWeeklyColumns: Column<ScheduleCalendar>[] = [
    {
      title: `Updating ${this.props.calendarType === ScheduleCalendarTypeEnum.DAILY ? 'Day' : 'Week'} Number to:`,
      defaultSort: 'asc',
      render: (challenge) => {
        if (!challenge) {
          return null;
        }
        if (!this.state.calendarNumberToSet) {
          return challenge.calendar_number;
        }
        return this.state.calendarNumberToSet;
      },
      customSort: (a, b) => {
        if (!a || a.calendar_number === undefined || !b || b.calendar_number === undefined || a.calendar_number === b.calendar_number) return 0;
        return a.calendar_number > b.calendar_number ? 1 : -1;
      }
    },
    {
      title: `Old ${this.props.calendarType === ScheduleCalendarTypeEnum.DAILY ? 'Day' : 'Week'} Number`,
      defaultSort: 'asc',
      field: 'calendar_number'
    },
    {
      title: 'Challenge', field: 'challenge_id',
      render: (challenge) => {
        if (!this.props.challengeMap[challenge.challenge_id]) return "Unknown Challenge";
        return this.props.challengeMap[challenge.challenge_id].challenge_name;
      }
    }
  ];

  private seasonalColumns: Column<ScheduleCalendar>[] = [
    {
      title: `Updating Is Limited to:`,
      sorting: false,
      render: (challenge) => {
        if (!challenge) {
          return null;
        }
        if (!this.state.isLimited) {
          return null;
        }
        return (<CheckBoxIcon/>);
      }
    },
    {
      title: `Updating Limited Start to:`,
      render: (challenge) => {
        if (!challenge) {
          return null;
        }
        return this.state.limitedStart;
      }
    },
    {
      title: `Updating Limited End to:`,
      render: (challenge) => {
        if (!challenge) {
          return null;
        }
        return this.state.limitedEnd;
      }
    },
    {
      title: 'Challenge', field: 'challenge_id',
      render: (challenge) => {
        if (!this.props.challengeMap[challenge.challenge_id]) return "Unknown Challenge";
        return this.props.challengeMap[challenge.challenge_id].challenge_name;
      }
    }
  ];

  constructor(props: Props) {
    super(props);
    this.state = {
      open: true,
      calendarNumberToSet: undefined,
      isLimited: undefined,
      limitedStart: undefined,
      limitedEnd: undefined
    }
  }

  componentDidMount() {
    if (this.props.seasonNumber > 0) {
      this.props.getSeasonChallengeScheduleAsync(this.props.seasonNumber);
    }
  }

  onDialogClose = () => {
    this.props.onClose();
  };

  onSave = () => {
    // duplicate each challenge schedule passed in, setting the new vars on it from the form
    if (!this.props.seasonalChallengeSchedule || !this.props.seasonalChallengeSchedule.data) {
      return;
    }
    const schedule = this.props.seasonalChallengeSchedule.data as ChallengeSchedule;
    const challenges: any[] = this.props.challengesToDuplicate.map(o => ({
      scheduleId: o.schedule_id,
      challengeId: o.challenge_id,
      instanceId: '',
      requiredChallenges: o.required_challenges,
      isLimited: o.is_limited,
      limitedStart: o.limited_start,
      limitedEnd: o.limited_end,
      rewards: o.rewards,
      challengeDesc: o.challenge_desc,
      challengeName: o.challenge_name,
      goalAmount: o.goal_amount
    }));

    if (this.props.calendarType === ScheduleCalendarTypeEnum.DAILY) {
      const daily: DailyChallenge | undefined = schedule.daily.find(o => o.day_number === this.state.calendarNumberToSet);
      if (!daily) {
        schedule.daily.push(new DailyChallenge({dayNumber: this.state.calendarNumberToSet, challenges}));
      } else {
        daily.challenges.push(...challenges.map(o => new ScheduledChallenge(o)));
      }
    } else if (this.props.calendarType === ScheduleCalendarTypeEnum.WEEKLY) {
      const weekly: WeeklyChallenge | undefined = schedule.weekly.find(o => o.week_number === this.state.calendarNumberToSet);
      if (!weekly) {
        schedule.weekly.push(new WeeklyChallenge({weekNumber: this.state.calendarNumberToSet, challenges}));
      } else {
        weekly.challenges.push(...challenges.map(o => new ScheduledChallenge(o)));
      }
    } else if (this.props.calendarType === ScheduleCalendarTypeEnum.SEASONAL) {
      // seasonal doesn't have "day number" or anything, it's just an array
      schedule.seasonal.challenges.push(...challenges.map(o => new ScheduledChallenge({
        ...o,
        isLimited: this.state.isLimited || false,
        limitedStart: this.state.limitedStart,
        limitedEnd: this.state.limitedEnd
      })));
    }

    this.props.updateSeasonChallengeScheduleAsync({seasonNumber: this.props.seasonNumber, schedule});
    this.setState({open: false});
    this.onDialogClose();
  };

  render() {
    let calendarTypeLabel = '';
    let calendarNumberLabel = '';
    switch (this.props.calendarType) {
    case ScheduleCalendarTypeEnum.DAILY:
      calendarTypeLabel = 'Daily';
      calendarNumberLabel = 'Day';
      break;
    case ScheduleCalendarTypeEnum.WEEKLY:
      calendarTypeLabel = 'Weekly';
      calendarNumberLabel = 'Week';
      break;
    case ScheduleCalendarTypeEnum.SEASONAL:
      calendarTypeLabel = 'Seasonal';
      break;
    }
    return (<>
      <Dialog
        open={this.state.open}
        onClose={this.onDialogClose}
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle>Duplicate {calendarTypeLabel} Challenges</DialogTitle>
        <DialogContent>
          <form onSubmit={this.onSave}>
            <Box pt={4} pb={4}>
              {(this.props.calendarType === ScheduleCalendarTypeEnum.DAILY || this.props.calendarType === ScheduleCalendarTypeEnum.WEEKLY) && (
                <TextField
                  type="number"
                  inputMode="numeric"
                  fullWidth
                  label={`${calendarNumberLabel} Number for the new challenge(s)`}
                  value={this.state.calendarNumberToSet || ''}
                  onChange={event => {
                    const days = parseInt(event.target.value);
                    this.setState({calendarNumberToSet: days});
                  }}
                />
              )}

              {this.props.calendarType === ScheduleCalendarTypeEnum.SEASONAL && (
                <>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={this.state.isLimited || false}
                        onChange={(e) => {
                          this.setState({isLimited: e.target.checked});
                        }}
                        name="isLimited"
                        color="primary"
                      />
                    }
                    label="Is Limited for the new challenge(s)"
                  />

                  <Box pb={3}>
                    <TextField
                      type="number"
                      inputMode="numeric"
                      fullWidth
                      label={`Limited Start for the new challenge(s)`}
                      value={this.state.limitedStart || ''}
                      onChange={event => {
                        const days = parseInt(event.target.value);
                        this.setState({limitedStart: days});
                      }}
                    />
                  </Box>
                  <Box pb={3}>

                    <TextField
                      type="number"
                      inputMode="numeric"
                      fullWidth
                      label={`Limited End for the new challenge(s)`}
                      value={this.state.limitedEnd || ''}
                      onChange={event => {
                        const days = parseInt(event.target.value);
                        this.setState({limitedEnd: days});
                      }}
                    />
                  </Box>
                </>
              )}

            </Box>

            <Box pb={4}>
              <Button variant="contained" color="primary" onClick={this.onSave}>
                Save
              </Button>
            </Box>

            <MaterialTable
              key={"duplicate_table"}
              title="Duplicating the following challenges:"
              options={{
                selection: false,
                search: false,
                thirdSortClick: false
              }}
              columns={this.props.calendarType === ScheduleCalendarTypeEnum.SEASONAL ? this.seasonalColumns : this.dailyWeeklyColumns}
              data={this.props.challengesToDuplicate.map(o => new ScheduleCalendar(this.props.seasonNumber, '', o.toJson()))}
            />

          </form>
        </DialogContent>
      </Dialog>
    </>);
  }
}

export default connector(DuplicateChallengesDialog);
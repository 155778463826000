import { useCallback, useState, useEffect } from 'react';
import { Box, Card, CardHeader, CardContent, FormControlLabel, Checkbox, TextField, Accordion, AccordionSummary, Typography, AccordionDetails } from '@material-ui/core';
import type { PlayerSeasonPathSaveRequest, PlayerPath} from '../../../services/player-challenges/player-path';
import { PlayerPathService } from '../../../services/player-challenges/player-path';
import RewardPathTierControl from '../../../pages/SeasonalData/RewardPathTierControl';
import { ExpandMore } from "@material-ui/icons";

interface PlayerSeasonPathProps {
  externalId: string;
  seasonNumber: number;
  path: PlayerPath;
  pathTierMap: Record<string, RewardPathTier>;
  onPPPlusChange: (value: boolean) => void;
}

export const PlayerSeasonPath = ({
  externalId,
  seasonNumber,
  path: {
    currentPathId,
    currentTierId,
    currentTierOrder,
    currentTokenCount,
    partyPassPlus,
    unclaimedTierIds
  },
  pathTierMap,
  onPPPlusChange
}: PlayerSeasonPathProps) => {
  const [partyPass, setPartyPass] = useState<boolean>(partyPassPlus)

  const handlePartyPassPlusChange = useCallback(async () => {
    const request: PlayerSeasonPathSaveRequest = {
      seasonNumber: seasonNumber,
      pathId: currentPathId,
      partyPassPlus: !partyPass
    }

    await PlayerPathService.savePlayerPath(externalId, request);
    setPartyPass(request.partyPassPlus);
    onPPPlusChange(request.partyPassPlus);
  }, [externalId, seasonNumber, currentPathId, partyPass, onPPPlusChange]);

  //handle use case for when our prop changes.
  useEffect(() =>{
    setPartyPass(partyPassPlus);
  }, [partyPassPlus]);

  return (<>
    <Box mb={2}>
      <Card>
        <CardHeader title={`Season: ${seasonNumber}`} />
        <CardContent>
          <Box mb={2}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={partyPass}
                  onChange={handlePartyPassPlusChange}
                />
              }
              label="Party Pass Plus"
            />
          </Box>
          <Box mb={2}>
            <TextField
              label="Current Path Id"
              value={currentPathId}
              InputProps={{ readOnly: true }}
              fullWidth
            />
          </Box>
          <Box mb={2}>
            <TextField
              label="Current Tier Id"
              value={currentTierId}
              InputProps={{ readOnly: true }}
              fullWidth
            />
          </Box>
          <Box mb={2}>
            <TextField
              label="Current Tier Order"
              value={currentTierOrder}
              InputProps={{ readOnly: true }}
              fullWidth
            />
          </Box>
          <Box mb={2}>
            <TextField
              label="Current Token Count"
              value={currentTokenCount}
              InputProps={{ readOnly: true }}
              fullWidth
            />
          </Box>
        </CardContent>
      </Card>
    </Box>
    <Box mb={2}>
      <Card>
        <CardHeader title="Unclaimed Tiers" />
        <CardContent>
          {unclaimedTierIds.map((pathTierId: string, index: number) => (
            <Accordion TransitionProps={{ unmountOnExit: true }} key={index}>
              <AccordionSummary expandIcon={<ExpandMore />}>
                <Typography variant="h5">
                  {pathTierMap[pathTierId].tierName}
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <RewardPathTierControl editMode={false} rewardPathTier={pathTierMap[pathTierId]} />
              </AccordionDetails>
            </Accordion>
          ))}
        </CardContent>
      </Card>
    </Box>
  </>);
}
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField'
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Component } from 'react';
import { DialogContent } from '@material-ui/core';
import type { ConfirmationDialogProps } from '../redux/app/actions';

export interface Props extends ConfirmationDialogProps {
  open: boolean;
  title: string;
  details?: string;
  includeNote?: boolean;
  requireNote?: boolean;
  note?: string;
  action?: string;
  onClose: () => void;
  onConfirm: (note?: string) => void;
  requireCode?: boolean;
  codeLabel?: string;
  code?: string;
}

interface State {
  note?: string;
  code?: string;
}

export default class ConfirmationDialog extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      note: undefined,
      code: ''
    }
  }
  
  componentDidUpdate(prevProps: Props) {
    if (!this.props.open && this.props.open !== prevProps.open) {
      this.setState({ note: '' });
      this.setState({ code: '' });
    }
  }

  render() {
    const disabled =
      (!!this.props.requireNote && (!this.state.note || this.state.note.trim().length === 0)) ||
      (!!this.props.requireCode && this.state.code != this.props.code);

    return (
      <Dialog
        open={this.props.open}
        onClose={this.props.onClose}
        fullWidth
      >
        <DialogTitle>{this.props.title}</DialogTitle>
        {(this.props.details || this.props.includeNote || this.props.requireCode) && (
          <DialogContent>
            <>
              <Grid container direction={"column"}spacing={2}>
                <Grid item>{this.props.details}</Grid>                
                {this.props.includeNote && (
                  <Grid item>
                    <TextField
                      label="Note"
                      value={this.state.note}
                      onChange={(e) => this.setState({ note: e.target.value })}
                      autoFocus
                      fullWidth
                      multiline={!(typeof this.props.multiLineNote === 'boolean' && !this.props.multiLineNote)}
                      rows={1}                  
                      rowsMax={4}
                      required={this.props.requireNote}
                    />                
                  </Grid>
                )}
                {this.props.requireCode && (
                  <Grid item>
                    <TextField
                      label={this.props.codeLabel}
                      onChange={(e) => this.setState({ code: e.target.value })}
                    />
                  </Grid>
                )}
              </Grid>
            </>
          </DialogContent>
        )}
        <DialogActions>
          <Button variant="contained" color="primary" disabled={ disabled } onClick={() => this.props.onConfirm(this.state.note)}>{this.props.action || 'Yes'}</Button>
          <Button variant="outlined" onClick={this.props.onClose}>Cancel</Button>
        </DialogActions>
      </Dialog>
    );
  }
}

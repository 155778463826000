import { Box, Button, CircularProgress, Grid, Menu, MenuItem } from '@material-ui/core';
import config from '../../../config';
import type { Player } from '../players';
import { BlankosList } from '../../blankos/BlankosList';
import { UserService } from '../../../services/user';
import { BlankosService } from '../../../services/blankos';
import { useEffect, useState, useCallback } from 'react';

type Props = {
  player?: Player;
};

export const PlayerBlankos = ({ player }: Props) => {

  const [grantMenuAnchor, setGrantMenuAnchor] = useState<HTMLElement>();
  const [canGrantBlankos, setCanGrantBlankos] = useState(false);
  const [isGrantingBlankos, setIsGrantingBlankos] = useState(false);

  const grantBlankos = useCallback(async (unowned: boolean) => {
    if (!player) {
      return;
    }

    setIsGrantingBlankos(true);
    await BlankosService.grantBlankosDev(player.externalId, unowned);
    setIsGrantingBlankos(false);
    setGrantMenuAnchor(undefined);
  }, [player]);

  useEffect(() => {
    setCanGrantBlankos(UserService.canCreate('blankos') && (config.env === 'local' || config.env === 'dev' || config.env === 'qa'));
  }, []);

  return (<>
    {!player &&
      <Box textAlign="center">
        <CircularProgress />
      </Box>
    }
    {player && (<>
      {canGrantBlankos && (
        <Box mb={2}>
          <Grid container alignItems="center">
            <Grid item xs />
            <Grid item>
              <Button variant="contained" color="primary" disabled={isGrantingBlankos} onClick={event => setGrantMenuAnchor(event.currentTarget)}>
                {isGrantingBlankos ? <CircularProgress size={25} /> : 'Grant Blankos (Dev)'}
              </Button>

              {grantMenuAnchor && (
                <Menu onClose={() => setGrantMenuAnchor(undefined)} anchorEl={grantMenuAnchor} open>
                  <MenuItem onClick={() => grantBlankos(true)}>Grant unowned</MenuItem>
                  <MenuItem onClick={() => grantBlankos(false)}>Grant all</MenuItem>
                </Menu>
              )}
            </Grid>
          </Grid>
        </Box>
      )}
      <BlankosList player={player} />
    </>)}
  </>);
}
import { Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, Grid, InputLabel, MenuItem, Select, TextField, Typography } from '@material-ui/core';
import type { ChangeEvent } from 'react';
import { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import { setAppNotification } from '../../../redux/app/actions';
import { ItemDefinitionsService } from '../../../services/item-definitions';
import type { Store } from '../../../services/stores';
import { StoresService } from '../../../services/stores';
import type { ShopVersion } from '../shop';
import { createShopVersionFull } from '../shopsApi';
import { CatalogSelect } from './CatalogSelect';

export interface ImportStoreDialogProps {
  shopId: string;
  onClose: () => void;
  onImport: (shopVersion: ShopVersion) => void;
}

export const ImportStoreDialog = (props: ImportStoreDialogProps) => {
  const dispatch = useDispatch();

  const { shopId, onClose, onImport } = props;

  const [open, setOpen] = useState(true);
  const [catalogName, setCatalogName] = useState('');
  const [stores, setStores] = useState<Store[] | null>(null);
  const [storeName, setStoreName] = useState('');
  const [name, setName] = useState('');
  const [nameError, setNameError] = useState('');

  const onNameChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    const newName = e.target.value;
    setName(newName);
    setNameError(newName.trim() === '' ? 'A name is required' : '');
  }, []);

  const onCatalogNameChange = useCallback((catalogName: string) => {
    setCatalogName(catalogName);
    setStores(null);
    setStoreName('');
    StoresService.getStores(catalogName).then(stores => {
      setStores(stores);
      if (stores.length > 0) {
        setStoreName(stores[0].storeId);
      }
    });
  }, []);

  const onSubmit = useCallback(async () => {
    try {
      const catalogItems = await ItemDefinitionsService.getItemDefinitions(catalogName);
      const store = await StoresService.getStore(catalogName, storeName);
      const catalogItemMap: { [key: string]: any } = {};
      catalogItems.forEach(item => {
        if (item.customData && item.customData.Webstore) {
          catalogItemMap[item.itemId] = item.customData.Webstore;
        }
      });

      const shopVersion: any = {
        shopId,
        name,
        catalogName,
        startDate: null,
        tabs: [],
        items: []
      };

      store.items.forEach(storeItem => {
        const customData = catalogItemMap[storeItem.itemId];
        let cartLimit = 0;
        let endTimestamp: string | null = null;
        if (customData) {
          if (typeof customData.CartLimit === 'number') {
            cartLimit = customData.CartLimit;
            delete customData.CartLimit;
          }
          if (typeof customData.TimeLimits?.End === 'string') {
            try {
              endTimestamp = new Date(customData.TimeLimits.End).toISOString();
            } catch (e) {
              // Nothing to do
            }
            delete customData.TimeLimits.End;
          }
        }

        shopVersion.items.push({
          itemId: storeItem.itemId,
          virtualCurrencyPrices: storeItem.virtualCurrencyPrices,
          rmPrice: storeItem.rmPrice,
          cartLimit,
          endTimestamp,
          customData: customData || null
        });
      });

      store.tabs.forEach(storeTab => {
        const shopTab: any = {
          name: storeTab.name,
          items: []
        };

        storeTab.items.forEach(storeTabItem => {
          const shopTabItem: any = {
            hero: storeTabItem.hero,
            gridWidth: storeTabItem.gridWidth,
            imageUrl: storeTabItem.imageUrl || null,
            nonInteractive: storeTabItem.nonInteractive,
            itemId: storeTabItem.itemId
          };

          shopTab.items.push(shopTabItem);
        });
        
        shopVersion.tabs.push(shopTab);
      });

      onImport(await createShopVersionFull(shopVersion));
      dispatch(setAppNotification({ type: 'success', message: 'Store imported' }));
      setOpen(false);
    } catch (e) {
      dispatch(setAppNotification({ type: 'error', message: 'Error importing store' }));
    }
  }, [dispatch, onImport, catalogName, storeName, name, shopId]);

  return (
    <Dialog
      open={open}
      onClose={() => setOpen(false)}
      onExited={props.onClose}
      fullWidth
      maxWidth="sm"
    >
      <DialogTitle>Import Store</DialogTitle>
      <DialogContent>
        <Grid container spacing={1} alignItems="center">
          <Grid item xs={4}>
            <CatalogSelect catalogName={catalogName} onCatalogNameChange={onCatalogNameChange} />
          </Grid>
          <Grid item xs={8}>
            {stores === null && <CircularProgress size={15} />}
            {stores !== null && stores.length < 1 && (
              <Typography color="textSecondary">No stores available.</Typography>
            )}
            {stores !== null && stores.length > 0 && (
              <FormControl fullWidth>
                <InputLabel>Store</InputLabel>
                <Select value={storeName} onChange={e => setStoreName(e.target.value as string)}>
                  {stores.map(store => (
                    <MenuItem key={store.storeId} value={store.storeId}>{store.storeId}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="Name"
              fullWidth
              error={Boolean(nameError)}
              helperText={nameError}
              value={name}
              onChange={onNameChange}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          color="primary"
          variant="contained"
          onClick={onSubmit}
          disabled={catalogName === '' || storeName === '' || name === '' || Boolean(nameError)}
        >
          Import
        </Button>
      </DialogActions>
    </Dialog>
  )
}
import { Button, InputLabel, List, ListItem, ListItemIcon, ListItemText, MenuItem, Select } from "@material-ui/core";
import { Delete } from "@material-ui/icons";
import { SelectWithLabel } from "../../components/SelectWithLabel";
import { GeoBlockedFeatureIdEnum } from "../../features/geoblocking/geo-blocking-types";
import { useCallback, useState } from "react";

interface Props {
  features: GeoBlockedFeatureIdEnum[];
  updateFeatures: (features: GeoBlockedFeatureIdEnum[]) => void;
}

export const GeoBlockingFeatureSelector = ({features, updateFeatures,}: Props) => {
  const [featureToAdd, setFeatureToAdd] = useState('');
  const addFeature = useCallback(() => {
    updateFeatures(features.concat([featureToAdd as GeoBlockedFeatureIdEnum]));
    setFeatureToAdd('');
  }, [featureToAdd, features, updateFeatures]);
  const removeFeature = useCallback((index) => {
    const copy = [...features];
    copy.splice(index, 1);
    updateFeatures(copy);
  }, [features, updateFeatures]);
  return <>
    <InputLabel>Geo-blocking features</InputLabel>
    <List>
      {features.map((feature, index) => (
        <ListItem key={index}>
          <ListItemIcon><Button onClick={() => removeFeature(index)}><Delete/></Button></ListItemIcon>
          <ListItemText primary={feature}/>
        </ListItem>
      ))}
    </List>
    <Select
      value={featureToAdd}
      onChange={e => setFeatureToAdd(e.target.value as string)}
    >
      {Object.values(GeoBlockedFeatureIdEnum)
        .filter(feature => !features.includes(feature))
        .map(feature => (<MenuItem key={feature} value={feature}>{feature}</MenuItem>))}
    </Select>
    <Button disabled={!featureToAdd} onClick={addFeature}>Add Geo-blocking Feature</Button>
  </>;
};

import { ListItem, ListItemIcon, ListItemText } from "@material-ui/core";
import * as React from "react";
import { Link } from "react-router-dom";

interface ListItemLinkProps {
  to: string;
  icon: React.ReactElement;
  primary: React.ReactNode;
}
  
export const ListItemLink = ({to, icon, primary}: ListItemLinkProps) => {
  const renderLink = React.useMemo(
    // eslint-disable-next-line react/display-name
    () => React.forwardRef<HTMLAnchorElement>((itemProps, ref) => <Link to={to} ref={ref} {...itemProps} />),
    [to],
  );
  
  return (
    <li>
      <ListItem button component={renderLink}>
        {icon ? <ListItemIcon>{icon}</ListItemIcon> : null}
        <ListItemText primary={primary}/>
      </ListItem>
    </li>
  );
}
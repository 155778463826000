import { createAsyncAction, createAction } from 'typesafe-actions';
import type { Season } from '../../../services/player-challenges/seasons';

export const getSeasonsAsync = createAsyncAction(
  'GET_SEASONS_REQUEST',
  'GET_SEASONS_SUCCESS',
  'GET_SEASONS_FAILURE'
)<string, Season[], string>();

export const createSeasonAsync = createAsyncAction(
  'CREATE_SEASON_REQUEST',
  'CREATE_SEASON_SUCCESS',
  'CREATE_SEASON_FAILURE'
)<{ liveEventsId: string, season: Season }, Season, string>();

export const getSeasonAsync = createAsyncAction(
  'GET_SEASON_REQUEST',
  'GET_SEASON_SUCCESS',
  'GET_SEASON_FAILURE'
)<{ liveEventsId: string, seasonNumber: number }, Season, string>();

export const updateSeasonAsync = createAsyncAction(
  'UPDATE_SEASON_REQUEST',
  'UPDATE_SEASON_SUCCESS',
  'UPDATE_SEASON_FAILURE'
)<{ liveEventsId: string, seasonNumber: number, season: Season }, Season, string>();

export const deleteSeasonAsync = createAsyncAction(
  'DELETE_SEASON_REQUEST',
  'DELETE_SEASON_SUCCESS',
  'DELETE_SEASON_FAILURE'
)<{ liveEventsId: string, seasonNumber: number }, number, string>();

export const clearSeasonError = createAction('SEASONS_CLEAR_ERROR')();
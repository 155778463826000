import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton, InputAdornment, Tooltip } from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import { Field, Form, Formik } from 'formik';
import { TextField } from 'formik-material-ui';
import { PlayerSelectList } from '../../../components/PlayerSelectList';
import type { Block } from '../../../services/blocks';
import type { Player } from '../../players/players';
import { createValidator, Validators } from '../../../utils/forms';
import { BlocksService } from '../../../services/blocks';
import { useEffect, useCallback, useState } from 'react';

export type UpdateBlockType = 'Rename' | 'Copy' | 'Transfer';

interface FormValues {
  player: string;
  name: string;
}

interface Props {
  updateType: UpdateBlockType;
  player?: Player;
  block: Block;
  onClose: () => void;
}

const formValidator = createValidator<FormValues>({
  name: Validators.notBlank()
});

export const UpdateBlockDialog = (props: Props) => {
  const [initialFormValues, setInitialFormValues] = useState<FormValues>({
    player: '',
    name: props.block.name
  });
  const [open, setOpen] = useState(true);
  const [selectingPlayer, setSelectingPlayer] = useState(false);
  const [selectedPlayer, setSelectedPlayer] = useState<Player>();

  useEffect(() => {
    if (props.player && props.updateType === 'Copy') {
      setSelectingPlayer(false);
      setSelectedPlayer(props.player);
      setInitialFormValues({ ...initialFormValues, player: props.player.displayName || props.player.externalId });
    } else {
      setSelectingPlayer(props.updateType !== 'Rename');
    }
  }, [props.player, props.updateType, initialFormValues]);

  const onSubmit = useCallback((values: FormValues) => {
    if (props.updateType === 'Copy') {
      if (!selectedPlayer) {
        return;
      }
      BlocksService.cloneBlock(props.block.id, values.name, selectedPlayer.externalId);
    } else {
      const block = props.block;
      block.name = values.name;
      if (props.updateType === 'Transfer') {
        if (!selectedPlayer) {
          return;
        }
        block.ownerId = selectedPlayer.externalId;
      }
      BlocksService.updateBlock(block);
    }
    setOpen(false);
  }, [props.updateType, props.block, selectedPlayer]);

  return (<>
    <Dialog
      open={open}
      onClose={() => setOpen(false)}
      onExited={props.onClose}
      fullWidth
      maxWidth="lg"
    >
      <Formik<FormValues>
        initialValues={initialFormValues}
        validate={formValidator}
        validateOnMount={true}
        onSubmit={onSubmit}
      >
        {({ submitForm, isSubmitting, isValid, setFieldValue }) => (
          <Form>
            <DialogTitle>{props.updateType} block: {props.block.name}</DialogTitle>
            {selectingPlayer ? (
              <PlayerSelectList
                filterPlayers={props.updateType === 'Transfer' ? player => player.externalId !== props.block.ownerId : undefined}
                onSelect={player => {
                  setFieldValue('player', player.displayName || player.externalId);
                  setSelectedPlayer(player);
                  setSelectingPlayer(false);
                }}
              />
            ) : (<>
              <DialogContent>
                <Grid container spacing={2}>
                  {props.updateType !== 'Rename' && (
                    <Grid item xs={12}>
                      <Field
                        component={TextField}
                        name="player"
                        type="text"
                        label="Player"
                        fullWidth
                        InputProps={{
                          readOnly: true,
                          endAdornment: (
                            <InputAdornment position="end">
                              <Tooltip title="Select player...">
                                <IconButton onClick={() => setSelectingPlayer(true)}>
                                  <MenuIcon />
                                </IconButton>
                              </Tooltip>
                            </InputAdornment>
                          )
                        }}
                      />
                    </Grid>
                  )}
                  <Grid item xs={12}>
                    <Field
                      component={TextField}
                      name="name"
                      type="text"
                      label="Name"
                      fullWidth
                    />
                  </Grid>
                </Grid>
              </DialogContent>
              <DialogActions>
                <Button color="primary" variant="contained" disabled={isSubmitting || !isValid} onClick={submitForm}>
                  {props.updateType}
                </Button>
              </DialogActions>
            </>)}
          </Form>
        )}
      </Formik>
    </Dialog>
  </>);
}
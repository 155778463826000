import {createReducer} from 'typesafe-actions';
import {arrayToMap, removeKey} from '../utils-ts';
import {
  createDailyMessageAsync,
  deleteDailyMessageAsync,
  getDailyMessagesAsync,
  updateDailyMessageAsync
} from './actions';
import type {DailyMessage} from "../../services/daily-messages";
import type { Reducer } from 'redux';

export interface DailyMessagesState {
  byId: { [key: string]: DailyMessage; };
}

const initialState: DailyMessagesState = {
  byId: {}
};

const dailyMessagesReducer: Reducer<DailyMessagesState> = createReducer<DailyMessagesState>(initialState)
  .handleAction(getDailyMessagesAsync.request, (state) => ({
    ...state
  }))
  .handleAction(getDailyMessagesAsync.success, (state, action) => ({
    ...state,
    byId: arrayToMap(action.payload, v => v.id)
  }))
  .handleAction(getDailyMessagesAsync.failure, (state, action) => ({
    ...state
  }))
  .handleAction([createDailyMessageAsync.success, updateDailyMessageAsync.success], (state, action) => ({
    ...state,
    byId: { ...state.byId, [action.payload.id]: action.payload }
  }))
  .handleAction(deleteDailyMessageAsync.success, (state, action) => ({
    ...state,
    byId: removeKey(state.byId, action.payload.id)
  }));

export default dailyMessagesReducer;
import { Box, Button, CircularProgress, DialogActions, FormControl, InputLabel, MenuItem, Select, Tab, Tabs } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import type { Column } from 'material-table';
import { Component } from 'react';
import type { ConnectedProps } from 'react-redux';
import { connect } from 'react-redux';
import MaterialTable from '../../components/MaterialTable';
import { setCatalogName } from '../../redux/catalog/actions';
import { getCatalogDropTablesAsync } from '../../redux/catalog/drop-tables/actions';
import { getCatalogItemsAsync } from '../../redux/catalog/items/actions';
import { getCatalogsAsync } from '../../redux/catalogs/actions';
import type { RootState } from '../../redux/reducers';
import type { DropTable } from '../../services/drop-tables';
import type { ItemDefinition } from '../../services/item-definitions';
import { AdminTable } from '../../shared';

const mapStateToProps = (state: RootState) => ({
  catalogs: state.catalogs.catalogs,
  items: state.catalog.items.items,
  dropTables: state.catalog.dropTables.dropTables
});
const mapDispatch = {
  requestCatalogs: getCatalogsAsync.request,
  requestCatalogItems: getCatalogItemsAsync.request,
  requestCatalogDropTables: getCatalogDropTablesAsync.request,
  setCatalogName
};
const connector = connect(mapStateToProps, mapDispatch);

interface SelectionsMap {
  items: ItemDefinition[];
  bundles: ItemDefinition[];
  containers: ItemDefinition[];
  droptables: DropTable[];
}

type Props = ConnectedProps<typeof connector> & {
  containerId?: string;
  addedItems?: string[];
  showItems: boolean;
  showBundles: boolean;
  showContainers: boolean;
  addedDropTables?: string[];
  includeDropTables?: boolean;
  multi?: boolean;
  onSelect?: (items: (ItemDefinition | DropTable)[]) => void;
  onClose: () => void;
  showCatalogSelect?: boolean;
  itemFilter?: (item: ItemDefinition) => boolean;
  defaultTab?: string;
};

interface State {
  selectedTab: string;
  addedItems: { [key: string]: boolean; };
  addedDropTables: { [key: string]: boolean; };
  items: ItemDefinition[];
  bundles: ItemDefinition[];
  containers: ItemDefinition[];
  open: boolean;
  selectedCatalog: string;
  selectedCount: number;
}

const tabs: string[] = [
  'items',
  'bundles',
  'containers',
  'droptables',
];

class ItemSelectDialog extends Component<Props, State> {
  static defaultProps = {
    showItems: true,
    showBundles: true,
    showContainers: true
  };

  private itemColumns: Column<ItemDefinition>[] = [
    {
      title: '',
      field: 'imageThumbnailUrl',
      cellStyle: { padding: 0, width: 64, lineHeight: 0.5 },
      removable: false,
      searchable: false,
      sorting: false,
      render: item => item.imageThumbnailUrl ? (
        <img src={item.imageThumbnailUrl} width={64} height={64} alt={item.itemId} />
      ) : (<Box width={64} height={64} />)
    },
    { title: 'ID', field: 'itemId', defaultSort: 'asc', cellStyle: { whiteSpace: 'nowrap' } },
    { title: 'Name', field: 'displayName', cellStyle: { whiteSpace: 'nowrap' } },
    { title: 'Class', field: 'itemClass', cellStyle: { whiteSpace: 'nowrap' } },
    {
      title: 'Notes',
      field: 'notes',
      cellStyle: {
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        width: '50%',
        maxWidth: 0
      }
    }
  ];

  private dropTableColumns: Column<DropTable>[] = [
    { title: 'ID', field: 'id', defaultSort: 'asc' }
  ];

  private selections: SelectionsMap = {
    items: [],
    bundles: [],
    containers: [],
    droptables: []
  };

  constructor(props: Props) {
    super(props);
    
    // Jesus help me.
    const { defaultTab, showItems, showBundles, showContainers } = this.props;
    const selectedTab = defaultTab && tabs.includes(defaultTab) ? defaultTab : showItems ? 'items' : (showBundles ? 'bundles' : (showContainers ? 'containers' : 'droptables'));

    const state: State = {
      selectedTab,
      addedItems: {},
      addedDropTables: {},
      items: [],
      bundles: [],
      containers: [],
      open: true,
      selectedCatalog: '',
      selectedCount: 0
    };

    if (this.props.addedItems) {
      this.props.addedItems.forEach(id => state.addedItems[id] = true);
    }
    if (this.props.addedDropTables) {
      this.props.addedDropTables.forEach(id => state.addedDropTables[id] = true);
    }

    this.state = state;
  }

  componentDidMount() {
    // HACK: Delete material table data mutations
    if (this.props.items.data) {
      this.props.items.data.forEach((item: any) => delete item['tableData']);
    }
    if (this.props.dropTables.data) {
      this.props.dropTables.data.forEach((dropTable: any) => delete dropTable['tableData']);
    }

    this.loadCurrentTabData();

    const catalogs = this.props.catalogs;
    if (this.props.showCatalogSelect && !catalogs.isLoading) {
      this.props.requestCatalogs();
    }

    this.updateItems();
  }

  componentDidUpdate(prevProps: Props, prevState: State) {
    if (prevProps.items.data !== this.props.items.data) {
      this.updateItems();
    }
    if (prevProps.catalogs.data !== this.props.catalogs.data && this.props.catalogs.data && this.props.catalogs.data.length > 0) {
      const catalog = this.props.catalogs.data.find(v => v.primaryCatalog) || this.props.catalogs.data[0];
      this.selectCatalog(catalog.name);
    }
    if (prevState.selectedCatalog !== this.state.selectedCatalog || prevState.selectedTab !== this.state.selectedTab) {
      this.loadCurrentTabData();
      this.selections = {
        items: [],
        bundles: [],
        containers: [],
        droptables: []
      };
      this.updateSelectedCount();
    }
  }

  loadCurrentTabData() {
    if (this.state.selectedTab === 'droptables') {
      if (!this.props.dropTables.data && !this.props.dropTables.isLoading) {
        this.props.requestCatalogDropTables();
      }
    } else {
      const items = this.props.items;
      if (!items.isLoading && !items.data) {
        this.props.requestCatalogItems();
      }
    }
  }

  selectCatalog(catalogName: string) {
    if (this.state.selectedCatalog !== catalogName) {
      this.props.setCatalogName(catalogName);
      this.setState({ selectedCatalog: catalogName });
    }
  }

  onTabChange = (event: any, tab: string) => {
    this.setState({ selectedTab: tab });
  };

  private updateItems() {
    let allItems: ItemDefinition[] = this.props.items.data || [];
    const items: ItemDefinition[] = [];
    const bundles: ItemDefinition[] = [];
    const containers: ItemDefinition[] = [];

    if (allItems.length > 0) {
      if (this.props.containerId) {
        allItems = allItems.filter(item => item.itemId !== this.props.containerId);
      }
      if (this.props.addedItems) {
        allItems = allItems.filter(item => !this.state.addedItems[item.itemId]);
      }
      if (this.props.itemFilter) {
        allItems = allItems.filter(this.props.itemFilter);
      }

      allItems.forEach(item => {
        if (item.bundle) {
          bundles.push(item);
        } else if (item.container) {
          containers.push(item);
        } else {
          items.push(item);
        }
      });
    }

    this.setState({ items, bundles, containers });
  }

  private updateSelectedCount() {
    this.setState({ selectedCount: Object.values(this.selections).reduce((prev, current) => prev + current.length, 0) });
  }

  onSubmit = () => {
    if (this.props.onSelect) {
      this.props.onSelect(Object.values(this.selections).reduce((prev, current) => prev.concat(current), []));
    }
    this.setState({ open: false });
  };

  onSubmitSingle = (item: ItemDefinition | DropTable | undefined) => {
    if (this.props.onSelect && item) {
      this.props.onSelect([item]);
    }
    this.setState({ open: false });
  };

  renderTabContent() {
    if (this.state.selectedTab === 'items' || this.state.selectedTab === 'bundles' || this.state.selectedTab === 'containers') {
      const items = this.props.items;
      const selectedTab = this.state.selectedTab;
      return (
        <AdminTable
          key="items"
          storageId="item-select-dialog"
          title=""
          columns={this.itemColumns}
          data={this.state[selectedTab] || []}
          options={{
            columnsButton: true,
            pageSize: 10,
            selection: this.props.multi,
            showTextRowsSelected: false
          }}
          isLoading={items.isLoading}
          onSelectionChange={(rows) => { this.selections[selectedTab] = rows; this.updateSelectedCount(); }}
          onRowClick={this.props.multi ? undefined : (event, item) => this.onSubmitSingle(item)}
        />
      );
    } else {
      const dropTables = this.props.dropTables;
      return (
        <MaterialTable
          key="dropTables"
          title=""
          columns={this.dropTableColumns}
          data={dropTables.data || []}
          options={{
            pageSize: 10,
            selection: this.props.multi,
            showTextRowsSelected: false
          }}
          isLoading={dropTables.isLoading}
          onSelectionChange={(rows) => { this.selections.droptables = rows; this.updateSelectedCount(); }}
          onRowClick={this.props.multi ? undefined : (event, dropTable) => this.onSubmitSingle(dropTable)}
        />
      );
    }
  }

  renderCatalogSelect() {
    const catalogs = this.props.catalogs;
    if (!catalogs.isLoading && catalogs.data) {
      return (
        <Box p={2}>
          <FormControl fullWidth>
            <InputLabel>Catalog</InputLabel>
            <Select
              value={this.state.selectedCatalog}
              onChange={event => this.selectCatalog(event.target.value as string)}
            >
              {catalogs.data.map(catalog => (
                <MenuItem key={catalog.name} value={catalog.name}>{catalog.name}</MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
      );
    } else {
      return (
        <Box p={2} textAlign="center">
          <CircularProgress />
        </Box>
      );
    }
  }

  renderContent() {
    return (<>
      {this.props.showCatalogSelect && this.renderCatalogSelect()}
      <Tabs value={this.state.selectedTab} variant="fullWidth" onChange={this.onTabChange}>
        {this.props.showItems && <Tab label="Items" value="items" />}
        {this.props.showBundles && <Tab label="Bundles" value="bundles" />}
        {this.props.showContainers && <Tab label="Containers" value="containers" />}
        {this.props.includeDropTables && <Tab label="Drop tables" value="droptables" />}
      </Tabs>
      {this.renderTabContent()}
    </>);
  }

  render() {
    return (
      <Dialog
        open={this.state.open}
        onClose={() => this.setState({ open: false })}
        onExited={this.props.onClose}
        fullWidth
        maxWidth="xl"
      >
        <DialogContent style={{ padding: 0 }}>
          {this.renderContent()}
        </DialogContent>
        {this.props.multi && (
          <DialogActions>
            <Button variant="contained" color="primary" disabled={this.state.selectedCount < 1} onClick={this.onSubmit}>
              {this.state.selectedCount < 1 ? 'Select an item' : (this.state.selectedCount > 1 ? `Add ${this.state.selectedCount} items` : 'Add 1 item')}
            </Button>
          </DialogActions>
        )}
      </Dialog>
    );
  }
}

export default connector(ItemSelectDialog);
import { Box, Button, Card, CardContent, CardHeader, CircularProgress, Divider, FormControl, Grid, InputLabel, MenuItem } from "@material-ui/core";
import { Field, Formik } from "formik";
import { useCallback, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { TextAlignmentEnum } from "../shopTemplate";
import type { ShopTemplate } from "../shopTemplate";
import * as yup from 'yup';
import { CheckboxWithLabel, Select, TextField } from "formik-material-ui";

const formSchema = yup.object({
  name: yup.string().required(),
});

interface ShopTemplateEditorProps {
  template?: ShopTemplate;
  onSave: (template: ShopTemplate) => void;
  onCancel: () => void;
}

export const ShopTemplateEditor = (props: ShopTemplateEditorProps) => {
  const dispatch = useDispatch();
  const [isLoading, setLoading] = useState(true);
  const { template, onSave, onCancel } = props;

  const formValues = useMemo((): ShopTemplate => {
    if (template) {
      //We have a template, make sure fields that require something aren't undefined
      template.templateItem.contentAlignment = template.templateItem.contentAlignment ?? TextAlignmentEnum.LEFT;
      template.templateItem.badgeAlignment = template.templateItem.badgeAlignment || TextAlignmentEnum.LEFT;
      return template;
    }
    const formValues: ShopTemplate = {
      id: '',
      name: '',
      templateItem: {
        cartLimit: 0,
        purchaseLimit: 0,
        geoBlockingFeatures: [],
        startDate: null,
        endDate: null,
        backgroundImageOverride: false,
        nft: false,
        hideOnMainListing: false,
        nameFontColor: '',
        nameFontSize: 0,
        artistCollabText: '',
        heroText: '',
        heroFontColor: '',
        buttonText: '',
        contentAlignment: TextAlignmentEnum.LEFT,
        contentWidthPercentage: 0,
        badgeAlignment: TextAlignmentEnum.LEFT
      }
    };
    return formValues;
  }, [template]);

  const onSubmit = useCallback(async (values: ShopTemplate) => {
    onSave(values);
  }, [onSave]);


  return (
    <Formik<ShopTemplate>
      initialValues={formValues}
      validationSchema={formSchema}
      enableReinitialize
      onSubmit={onSubmit}
    >
      {form => (<>
        <Grid item xs={12} lg={12}>
          <Card>
            <CardHeader title="Shop Template Properties" />
            <CardContent>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Field
                    component={TextField}
                    type="text"
                    name="name"
                    label="Template Name"
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12}>
                  <Field
                    component={TextField}
                    type="number"
                    name="templateItem.cartLimit"
                    label="Cart Limit"
                    fullWidth
                    inputProps={{
                      step: 1,
                      min: 0
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Field
                    component={TextField}
                    type="datetime-local"
                    name="templateItem.startDate"
                    label="Start Date"
                    fullWidth
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Field
                    component={TextField}
                    type="datetime-local"
                    name="templateItem.endDate"
                    label="End Date"
                    fullWidth
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Field
                    component={CheckboxWithLabel}
                    type="checkbox"
                    name="templateItem.backgroundImageOverride"
                    Label={{ label: 'Background Image Override' }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Field
                    component={CheckboxWithLabel}
                    type="checkbox"
                    name="templateItem.nft"
                    Label={{ label: 'NFT' }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Field
                    component={CheckboxWithLabel}
                    type="checkbox"
                    name="templateItem.hideOnMainListing"
                    Label={{ label: 'Hide On Main Listing' }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <Field
                    component={TextField}
                    name="templateItem.nameFontSize"
                    type="number"
                    label="Name font size (pixels)"
                    fullWidth
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <Field
                    component={TextField}
                    name="templateItem.nameFontColor"
                    type="string"
                    label="Name font color"
                    fullWidth
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Field
                    component={TextField}
                    name="templateItem.artistCollabText"
                    type="text"
                    label="Artist collab text"
                    fullWidth
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <Field
                    component={TextField}
                    name="templateItem.heroText"
                    type="text"
                    label="Hero text"
                    fullWidth
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <Field
                    component={TextField}
                    name="templateItem.heroFontColor"
                    type="text"
                    label="Hero font color"
                    fullWidth
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Field
                    component={TextField}
                    name="templateItem.buttonText"
                    type="text"
                    label="Button text"
                    fullWidth
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </Grid>
                <Grid item xs={4}>
                  <FormControl fullWidth>
                    <InputLabel>Content alignment</InputLabel>
                    <Field
                      component={Select}
                      name="templateItem.contentAlignment"
                    >
                      <MenuItem value="Left">Left</MenuItem>
                      <MenuItem value="Right">Right</MenuItem>
                    </Field>
                  </FormControl>
                </Grid>
                <Grid item xs={4}>
                  <Field
                    component={TextField}
                    name="templateItem.contentWidthPercentage"
                    type="number"
                    label="Content width %"
                    fullWidth
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </Grid>
                <Grid item xs={4}>
                  <FormControl fullWidth>
                    <InputLabel>Badge alignment</InputLabel>
                    <Field
                      component={Select}
                      name="templateItem.badgeAlignment"
                    >
                      <MenuItem value="Left">Left</MenuItem>
                      <MenuItem value="Right">Right</MenuItem>
                    </Field>
                  </FormControl>
                </Grid>
                <Grid container alignItems="center" justify="flex-end" spacing={4}>
                  <Grid item>
                    <Box mt={4}>
                      <Button color="primary" variant="contained" onClick={props.onCancel}>Cancel</Button>
                    </Box>
                  </Grid>
                  <Grid item>
                    <Box mt={4}>
                      <Button color="primary" variant="contained" onClick={form.submitForm}>Save</Button>
                    </Box>
                  </Grid>

                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </>)
      }
    </Formik >
  )
}

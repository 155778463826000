import { Box, Typography } from '@material-ui/core';
import type { TranslationDiff, ItemDiffProps } from '../../types';
import { DiffsPanelsContainer } from '../DiffsPanels';

type TranslationsProps = ItemDiffProps<TranslationDiff>

export const Translations = ({
  sourceItems,
  targetItems,
  actions,
  onSelectionChange
}: TranslationsProps) => (<>
  <Typography variant="h6">
    Translations
  </Typography>

  <Box mt={2}>
    {sourceItems && targetItems && (
      <DiffsPanelsContainer
        sourceItems={sourceItems}
        targetItems={targetItems}
        selectedIds={actions}
        columns={[
          { title: 'Key', field: 'key', defaultSort: 'asc' },
          { title: 'Language Code', field: 'languageCode', defaultSort: 'asc' }
        ]}
        onSelectionChange={onSelectionChange}
      />
    )}
  </Box>
</>)